import React from "react";
import { RowActions } from "./RowActions";
import { Status } from "./Status";

const headerCellConfig = {
  resizable: true,
  minWidth: 100,
  flex: 1,
  renderCell: params => <div>{params?.value}</div>,
  headerClassName: "super-app-theme--header",
  hide: false
};

export const registryTableHeaders = [
  {
    headerName: "Name",
    field: "name",
    ...headerCellConfig
  },
  {
    headerName: "Type",
    field: "registry_name",
    ...headerCellConfig
  },
  {
    headerName: "URL",
    field: "url",
    ...headerCellConfig,
    minWidth: 400
  },
  {
    headerName: "Status",
    field: "status",
    ...headerCellConfig,
    renderCell: params => (
      <Status registryId={params?.row?.id} registryStatus={params?.row?.status} />
    )
  },
  {
    headerName: "",
    field: "",
    ...headerCellConfig,
    renderCell: params => <RowActions registryId={params?.row?.id} />,
    maxWidth: 50
  }
];

export const AWS_REGION = [
  // { label: 'af-south-1', value: 'af-south-1' },
  // { label: 'ap-east-1', value: 'ap-east-1' },
  // { label: 'ap-northeast-1', value: 'ap-northeast-1' },
  // { label: 'ap-northeast-2', value: 'ap-northeast-2' },
  // { label: 'ap-northeast-3', value: 'ap-northeast-3' },
  // { label: 'ap-south-1', value: 'ap-south-1' },
  // { label: 'ap-southeast-1', value: 'ap-southeast-1' },
  // { label: 'ap-southeast-2', value: 'ap-southeast-2' },
  // { label: 'ap-southeast-3', value: 'ap-southeast-3' },
  // { label: 'ca-central-1', value: 'ca-central-1' },
  // { label: 'cn-north-1', value: 'cn-north-1' },
  // { label: 'cn-northwest-1', value: 'cn-northwest-1' },
  // { label: 'eu-central-1', value: 'eu-central-1' },
  // { label: 'eu-north-1', value: 'eu-north-1' },
  // { label: 'eu-south-1', value: 'eu-south-1' },
  // { label: 'eu-west-1', value: 'eu-west-1' },
  // { label: 'eu-west-2', value: 'eu-west-2' },
  // { label: 'eu-west-3', value: 'eu-west-3' },
  // { label: 'me-south-1', value: 'me-south-1' },
  // { label: 'sa-east-1', value: 'sa-east-1' },
  // { label: 'sa-east-1', value: 'sa-east-1' },
  { label: "us-east-1", value: "us-east-1" },
  { label: "us-east-2", value: "us-east-2" },
  { label: "us-west-1", value: "us-west-1" },
  { label: "us-west-2", value: "us-west-2" },
  { label: "af-south-1", value: "af-south-1" },
  { label: "ap-east-1", value: "ap-east-1" },
  { label: "ap-southeast-3", value: "ap-southeast-3" },
  { label: "ap-south-1", value: "ap-south-1" },
  { label: "ap-northeast-3", value: "ap-northeast-3" },
  { label: "ap-northeast-2", value: "ap-northeast-2" },
  { label: "ap-northeast-1", value: "ap-northeast-1" },
  { label: "ap-southeast-2", value: "ap-southeast-2" },
  { label: "ap-southeast-1", value: "ap-southeast-1" },
  { label: "ca-central-1", value: "ca-central-1" },
  { label: "eu-central-1", value: "eu-central-1" },
  { label: "eu-west-1", value: "eu-west-1" },
  { label: "eu-west-2", value: "eu-west-2" },
  { label: "eu-south-1", value: "eu-south-1" },
  { label: "eu-west-3", value: "eu-west-3" },
  { label: "eu-north-1", value: "eu-north-1" },
  { label: "me-south-1", value: "me-south-1" },
  { label: "sa-east-1", value: "sa-east-1" },

  { label: "cn-north-1", value: "cn-north-1" },
  { label: "cn-northwest-1", value: "cn-northwest-1" }
];

export const transformPassword = password => {
  if (password === undefined || password === "" || password === "*****" || password === "{*****}") {
    return "";
  }
  return password;
};
