import React from "react";
import { formatDate } from "helper/DateLib";
import AWSLogo from "assets/images/AmazonWebServicesLogo.svg";
import GCPLogo from "assets/images/GcpLogo.svg";
import AzureLogo from "assets/images/AzureLogo.svg";
import { Switch } from "components/simple";
import { useDispatch } from "react-redux";
import {
  actionDeleteModal,
  actionSetScanScheduleModal,
  actionStatusModal,
  runAccountScan
} from "store/entities/cloudAccounts/actions";
import SplitButton from "components/simple/SplitButton";

import styles from "./styles.module.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { routes } from "router";
import { navigate } from "helper/history";

const logos = {
  azure: AzureLogo,
  gcp: GCPLogo,
  aws: AWSLogo,
  aws_assume: AWSLogo
};

const calculateEnabledTime = date => {
  const enabledDate = new Date(date);
  const duration = Date.now() - enabledDate.getTime();
  const hours = Math.floor(Math.abs(duration) / 3600000);
  return `${hours} h`;
};

const ScanCell = params => {
  const dispatch = useDispatch();
  const history = useHistory();

  const options = [
    {
      label: "Edit/update connection",
      action: () => {
        history.push(
          `${routes.settingsEditCloudAccount}/${params.id}?cloud_type=${params.row.cloud_type}`
        );
      }
    },
    {
      label: "Schedule Scan",
      action: () => dispatch(actionSetScanScheduleModal({ show: true, id: params.id }))
    },
    {
      label: "Delete Account",
      action: () => dispatch(actionDeleteModal({ show: true, id: params.id }))
    }
  ];

  return (
    <div className={styles.scanContainer}>
      <div
        aria-label="button"
        className={styles.button}
        onClick={() => dispatch(runAccountScan(params.id))}
      >
        Scan
      </div>
      <SplitButton options={options} />
    </div>
  );
};

const EnabledCell = ({ id, value }) => {
  const dispatch = useDispatch();
  const onChange = () => dispatch(actionStatusModal({ show: true, status: value, id }));
  return <Switch value={value} onChange={onChange} />;
};

export const cloudAccountsColumns = [
  {
    headerName: "Cloud",
    field: "cloud_type",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => {
      return <img src={logos[params.value]} alt={params.value} />;
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Account",
    field: "name",
    resizable: true,
    minWidth: 255,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Connected",
    field: "connect_date",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => {
      return <div>{formatDate(params.value)}</div>;
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Status",
    field: "enabled",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: EnabledCell,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Enabled",
    field: "enabled_date",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => {
      return <div>{params.row.enabled ? moment(params?.value).fromNow() : "0 h"}</div>;
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Last scanned",
    field: "last_scan_date",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => {
      return <div>{formatDate(params.value)}</div>;
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Scan",
    field: "scan",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: ScanCell,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];
