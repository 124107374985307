import React from "react";

import SecretKey from "./Fields/SecretKey";
import AccessKey from "./Fields/AccessKey";
import RegistryRegion from "./Fields/RegistryRegion";

import { AWS_REGION } from "../utils/utils";

export const ECRForm = ({ isEdit, registryId, control, errors }) => {
  return (
    <>
      <RegistryRegion
        errors={errors}
        control={control}
        title="AWS Region*"
        options={AWS_REGION}
        placeholder="Select ECR Region"
      />
      <AccessKey control={control} errors={errors} />
      <SecretKey isEdit={isEdit} control={control} errors={errors} />
    </>
  );
};
