import { useQuery } from "react-query";
import { getAccuknoxWorkspace } from "api/api";
import {
  getDashboardDetails,
  getFindingsAccordianImages,
  getFindingsSummarizedImages,
  getImageRisks,
  getImageSeverityIssue,
  getImagesList
} from "./service";
import { PIE_CHART_COLORS } from "./constants";

export const useDashboardDetailsQuery = () => {
  const workspaceId = getAccuknoxWorkspace();
  return useQuery(
    ["registryScan", "getDashboardDetails", workspaceId],
    () => getDashboardDetails(workspaceId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 60 * 1000,
      select: data => {
        const scannedImagesCount = data?.success_images;
        const pendingImagesCount = data?.pending_images;
        const failedImagesCount = data?.failed_images;
        const queuedImagesCount = data?.in_progress_images;

        const scannedImagesLabel = `${scannedImagesCount} Scanned Images`;
        const failedImagesLabel = `${failedImagesCount} Failed to Scan`;
        const queuedImagesLabel = `${queuedImagesCount} Images in Queue`;

        const imageScanningData = {
          metrics: [
            {
              title: scannedImagesLabel,
              value: scannedImagesCount,
              color: PIE_CHART_COLORS[0]
            },
            {
              title: failedImagesLabel,
              value: failedImagesCount,
              color: PIE_CHART_COLORS[1]
            },
            {
              title: queuedImagesLabel,
              value: queuedImagesCount,
              color: PIE_CHART_COLORS[2]
            }
          ],
          totalImages:
            scannedImagesCount + pendingImagesCount + failedImagesCount + queuedImagesCount
        };

        const scanQueueData = {
          "Not-Started": pendingImagesCount,
          "In-Progress": queuedImagesCount,
          Success: scannedImagesCount,
          Failed: failedImagesCount
        };

        return { imageScanningData, scanQueueData };
      }
    }
  );
};

export const useImageRisksQuery = () => {
  return useQuery(["registryScan", "getImageRisks"], getImageRisks, {
    refetchOnWindowFocus: false,
    select: data => {
      return {
        totalVulnerabilities: data?.result?.severity_assessment?.total_vulnerabilities,
        severities: {
          Critical: data?.result?.severity_assessment?.severities?.critical,
          High: data?.result?.severity_assessment?.severities?.high,
          Medium: data?.result?.severity_assessment?.severities?.medium,
          Low: data?.result?.severity_assessment?.severities?.low,
          Negligible: data?.result?.severity_assessment?.severities?.negligible,
          "No Issue": data?.result?.severity_assessment?.severities?.informational?.no_issue
        }
      };
    }
  });
};

export const useImageSeverityIssueQuery = () => {
  return useQuery(["registryScan", "getImageSeverityIssue"], getImageSeverityIssue, {
    refetchOnWindowFocus: false,
    select: data => {
      return {
        totalImages: data?.result?.severity_issues?.total_vulnerabilities,
        totalMalware: data?.result?.severity_issues?.total_malware,
        totalSensitiveData: data?.result?.severity_issues?.total_sensitive_data,
        severities: {
          Critical: data?.result?.severity_issues?.severities?.critical,
          High: data?.result?.severity_issues?.severities?.high,
          Medium: data?.result?.severity_issues?.severities?.medium,
          Low: data?.result?.severity_issues?.severities?.low,
          Negligible: data?.result?.severity_issues?.severities?.negligible,
          "No Issue": data?.result?.severity_issues?.severities?.informational
        }
      };
    }
  });
};

export const useImagesListQuery = (status, searchTerm) => {
  const workspaceId = getAccuknoxWorkspace();
  const requestBody = {
    page_next: 100,
    page_prev: 1,
    status
  };
  return useQuery(
    ["registryScan", "getDashboardDetails", workspaceId, status],
    () => getImagesList(requestBody),
    {
      refetchOnWindowFocus: false,
      select: data => {
        if (!data.list_of_image) {
          return { list_of_image: [], total_record: 0 };
        } else {
          const newData = {
            list_of_image: data?.list_of_image?.map(image => ({
              ...image,
              name: `${image.name}:${image.tags}`,
              status
            })),
            total_record: data.total_record
          };
          if (searchTerm) {
            const filteredResults = newData?.list_of_image?.filter(({ name }) =>
              name?.toLowerCase().includes(searchTerm?.toLowerCase())
            );
            return { list_of_image: filteredResults, total_record: filteredResults?.length };
          } else {
            return newData;
          }
        }
      }
    }
  );
};

export const useFindingsSummarizedImagesQuery = (page, searchTerm) => {
  return useQuery(
    ["registryScan", "getFindingsSummarizedImages", page],
    () => getFindingsSummarizedImages(page),
    {
      enabled: Boolean(page),
      refetchOnWindowFocus: false,
      select: data => {
        const newData = {
          images: data?.result?.summarized_list?.map((d, index) => ({
            id: index + 1,
            repositoryName: d?.repository_name,
            registryId: d?.registry_id,
            registryName: d?.registry_name,
            severity: {
              severities: {
                Critical: d?.severity?.critical,
                High: d?.severity?.high,
                Medium: d?.severity?.medium,
                Low: d?.severity?.low,
                Negligible: d?.severity?.negligible
              },
              totalMalware: d?.malware_data,
              totalSensitive: d?.sensitive_data
            }
          })),
          totalCount: data?.result?.summarized_list?.length
        };

        if (searchTerm) {
          const filteredResults = newData?.images?.filter(({ repositoryName }) =>
            repositoryName?.toLowerCase().includes(searchTerm?.toLowerCase())
          );
          return { images: filteredResults, totalCount: filteredResults?.length };
        } else {
          return newData;
        }
      }
    }
  );
};

export const useFindingsAccordianImagesQuery = ({ registryId, repositoryName }) => {
  return useQuery(
    ["registryScan", "getFindingsAccordianImages", registryId, repositoryName],
    () => getFindingsAccordianImages({ registryId, repositoryName }),
    {
      enabled: Boolean(registryId && repositoryName),
      refetchOnWindowFocus: false,
      select: data => {
        return data?.result?.listing_images?.map((d, index) => ({
          id: index + 1,
          asset: d?.asset,
          imageName: d?.image_name,
          securityIssues: {
            severities: {
              Critical: d?.total_vulnerabilities?.critical,
              High: d?.total_vulnerabilities?.high,
              Medium: d?.total_vulnerabilities?.medium,
              Low: d?.total_vulnerabilities?.low,
              Negligible: d?.total_vulnerabilities?.negligible
            },
            malware: d?.malware_data,
            sensitive: d?.sensitive_data
          }
        }));
      }
    }
  );
};
