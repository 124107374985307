import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../../components/simple/Loader";
import { useQuery, useBasePath } from "helper/history";

// Components
import { Button, Tabs, ButtonGoBack } from "components/simple";
import TicketForm from "./TicketForm";

import {
  getTicketsPreview,
  clearTicketsPreview,
  createTicket
} from "store/entities/tickets/actions";
import { getTicketsPreviewSelector } from "store/entities/tickets/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import { addNotification } from "store/entities/notifications/actions";

import styles from "./styles.module.scss";
import { routes } from "router";

const CreateTicket = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const params = useParams();
  const ticketConfig_id = params?.ticketConfig_id;
  const baseline_id = params?.baseline_id;
  const isLoading = useSelector(state => createLoadingSelector([createTicket.type])(state));

  const [value, setValue] = useState(0);
  const ids = query.get("ids").split(",");
  const group_by = query.get("groupBy");

  const ticketPreview = useSelector(getTicketsPreviewSelector);
  const [state, setState] = useState([]);

  const current = useBasePath();

  // const dataStructureDatalistCreateTicket = routes?.dataStructureDatalistCreateTicket === current;
  const vulnerabilitiesCreateTicket = routes?.vulnerabilitiesCreateTicket === current;
  const baselineConfigurationCreateTicket = routes?.baselineConfigurationCreateTicket === current;
  useEffect(() => {
    if (baseline_id && baselineConfigurationCreateTicket) {
      const d = {
        ticket_related_type: "control",
        related_objects_ids: ids,
        baseline_id,
        ticket_config: ticketConfig_id,
        ...(group_by ? { group_by } : {})
      };
      dispatch(getTicketsPreview(d));
    } else if (vulnerabilitiesCreateTicket) {
      const d = {
        ticket_related_type: "finding",
        related_objects_ids: ids,
        ticket_config: ticketConfig_id,
        ...(group_by ? { group_by } : {})
      };
      dispatch(getTicketsPreview(d));
    } else {
      const d = {
        ticket_related_type: "data-list",
        related_objects_ids: ids,
        ticket_config: ticketConfig_id,
        ...(group_by ? { group_by } : {})
      };
      dispatch(getTicketsPreview(d));
    }

    return () => {
      setState([]);
      dispatch(clearTicketsPreview());
    };
  }, []);

  useEffect(() => {
    if (ticketPreview?.length) {
      setState(ticketPreview);
    }
  }, [ticketPreview]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChange = (val, i) => {
    const items = [...state];
    let item = { ...items[1] };
    item = val;
    items[i] = item;
    setState([...items]);
  };

  const tabs = state?.map((item, index) => {
    return {
      id: ids[index],
      label: `Ticket ${index + 1}`,
      content: <TicketForm data={item} onChange={val => onChange(val, index)} />
    };
  });

  const validation = () => {
    const res = state?.filter(
      item =>
        !item.title?.trim() ||
        !item.description.trim() ||
        (item?.ticket_profile_type === "freshserviceprofile" ? !item?.priority : false)
    );
    const isValid = Boolean(res?.length);
    if (isValid) {
      dispatch(
        addNotification({ msg: "You need to fill in all required fields", type: "warning" })
      );
    }
    return isValid;
  };

  const onCreate = () => {
    if (!validation()) {
      dispatch(createTicket(state));
    }
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loading}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.btnGroup}>
            <ButtonGoBack />
            <Button icon="plus" className={styles.button} onClick={onCreate} disabled={isLoading}>
              Create
            </Button>
          </div>

          <Tabs tabs={tabs} value={value} handleChange={handleChange} />
        </>
      )}
    </div>
  );
};

export default CreateTicket;
