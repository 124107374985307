/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import { useQuery } from "react-query";
import Select from "react-select";
import "./HybridViewSidebar.scss";
import style from "../styles.module.scss";
import CustomFilterComponent from "./CustomFilterComponent";
// import { usePrimaryLayoutTitle } from "../../contexts/primaryLayoutTitle";
// import service from "../../pages/ClusterManagement/service";
import { useForm, Controller } from "react-hook-form";
import ClusterIcon from "../assets/images/cluster-icon.png";
import PodIcon from "../assets/images/pod-icon.png";
import VMGroupIcon from "../assets/images/new-vmgroup.png";
import VMIcon from "../assets/images/new-vm-icon.png";
import ClusterGrayscaleIcon from "../assets/images/cluster-grayscale.png";
import PodGrayscaleIcon from "../assets/images/pod-grayscale.png";
import VMGroupGrayscaleIcon from "../assets/images/VmGroup-grayscale.png";
import VMGrayscaleIcon from "../assets/images/vm-grayscale.png";
import { CLUSTER, POD, CONTAINER, VMGROUP, VMINSTANCE, PROCESS } from "../constant";
import { debounce } from "lodash";
import { API_VM_FILTER } from "../mockdata";
import { useSearchData, vmNavBar } from "../service/query";
import { searchOptionList } from "../helpers/heightDifferenceForContextMenu";
import { useHistory } from "react-router";
import { routes } from "router/router";
import { kFormatter } from "helper/helpers";
import { Tooltip } from "@mui/material";
const HybridViewSidebar = ({
  selectedview,
  setEntityType,
  clusterData,
  setSelectedClusterId,
  setSelectedNodeId,
  setSelectedPodId,
  isClusterLoading,
  isPodLoading,
  setVmGroupData,
  setVmData,
  switchToNormalView,
  setSelectedView,
  setSelectedInstanceGroup,
  setSelectedInstance,
  setSelectedCluster,
  setSelectedNamespace,
  parentSelected,
  setParentSelected,
  selectedOption,
  setSelectedOption,
  isSelected,
  setIsSelected,
  detailRef,
  setShowViewPolicy,
  selectedFilter,
  setSelectedFilter,
  setSelectpodName,
  setActiveClusterId,
  setThroughCluster,
  MainEntity,
  setMainEntity,
  setSelectedPod,
  selectedPod
}) => {
  // const {
  //   MainEntity,
  //   setMainEntity,
  //   workspaceVal,
  //   paginationResetted,
  //   updatePaginationResetted,
  //   dslTag,
  //   updateTagList
  // } = usePrimaryLayoutTitle();
  const dropdownstyle2 = {
    indicatorsContainer: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled && "#fafafa"
    }),
    control: (styles, { isDisabled }) => ({
      ...styles,
      fontSize: 14,
      backgroundColor: isDisabled && "#fafafa"
    }),
    // menu: styles => ({ ...styles, height: 150, overflowY: 'scroll' }),
    menuList: styles => ({ ...styles, height: 170 }),
    option: (styles, { Selected, isFocused }) => ({
      ...styles,
      fontSize: 13,
      ":hover": {
        backgroundColor: "#f3f3f3"
      },
      backgroundColor: Selected || isFocused ? "#f3f3f3" : "#ffffff",
      color: "#000000"
    }),
    groupHeading: styles => ({ ...styles, color: "#223cc3" }),
    valueContainer: styles => ({ ...styles, marginRight: 15 })
  };

  const [showClusterSubNav, setShowClusterSubNav] = useState("");
  const [showNodeSubNav, setShowNodeSubNav] = useState("");
  const [search, setSearch] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [option, setOption] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [sidebarFilterOptions] = useState([
    // 'All',
    "K8s Cluster"
    // "VM Instances"
  ]);
  const history = useHistory();
  const RedirectToLogs = (params, type) => {
    history.push({
      pathname: routes.alertsSummaryLogs,
      state: { data: params, type: type }
    });
  };
  const switchSubNav = () => {
    if (parentSelected === showClusterSubNav) {
      setShowClusterSubNav("");
    }
    if (selectedOption === showNodeSubNav) {
      setShowNodeSubNav("");
    }
  };
  const { register, control, setValue } = useForm();
  const selectInputRef = useRef();
  const { data: listofSearchOption = [], isLoading: isSearchInputLoading } = useSearchData(search);

  useEffect(() => {
    setSelectedValue(null);
  }, [selectedFilter]);
  useEffect(() => {
    if (selectedFilter === "K8s Cluster") {
      // setSelectedView("Clusters");
      // setEntityType("Clusters");
      setSelectedView(CLUSTER);
    } else if (selectedFilter === "VM Instances") {
      setSelectedView(VMGROUP);
      // setEntityType("vmgroup");
    }
  }, []);

  useEffect(() => {
    detailRef.current.open = true;
    if (MainEntity === "Clusters") {
      setSelectedView(CLUSTER);
      setSelectedCluster({});
      setSelectedPod({});
      setParentSelected("");
      setSelectedOption("");
    } else if (MainEntity === "vmgroup") {
      setSelectedView(VMGROUP);
      setParentSelected("");
      setSelectedOption("");
    }
    setIsSelected("");
  }, [MainEntity]);

  useEffect(() => {
    detailRef.current.open = true;
    if (selectedview === "Clusters") {
      setShowClusterSubNav("");
      setShowNodeSubNav("");
    }
    if (selectedview === "cluster") {
      setShowNodeSubNav("");
    }
  }, [selectedview]);
  const instanceGroupData = vmNavBar();
  const processesData = [
    {
      id: 101,
      process_name: "qbittorrent",
      entity_type: "process"
    },
    {
      id: 102,
      process_name: "chrome",
      entity_type: "process"
    },
    {
      id: 103,
      process_name: "java",
      entity_type: "process"
    },
    {
      id: 104,
      process_name: "gnome",
      entity_type: "process"
    }
  ];

  const toggleParentFilterHandler = index => {
    if (parentSelected === index) {
      return setParentSelected("0");
    }
    setParentSelected(index);
  };

  const toggleParentSubNavFilterHandler = index => {
    if (showClusterSubNav === index) {
      return setShowClusterSubNav("0");
    }
    setShowClusterSubNav(index);
  };

  const toggleFilterHandler = index => {
    if (selectedOption === index) {
      return setSelectedOption("0");
    }
    setSelectedOption(index);
  };

  useEffect(() => {
    const FilteredOption = searchOptionList(selectedFilter, listofSearchOption);
    setOption(FilteredOption);
  }, [JSON.stringify(listofSearchOption)]);

  const viewchange = (entityName, selectvalue, val) => {
    if (entityName === "Clusters") {
      if (selectvalue === val?.clusterId) {
        setSelectedView(CLUSTER);
      } else {
        setSelectedView(POD);
        setEntityType("node");
      }
    } else if (entityName === "node") {
      if (selectvalue === val?.nodeId) {
        setSelectedView(CLUSTER);
      } else {
        setSelectedView(POD);
      }
    } else if (entityName === "InstancesGroup") {
      if (selectvalue === val?.id) {
        setSelectedView(VMGROUP);
      } else {
        setSelectedView(VMINSTANCE);
      }
    }
  };

  const selectedFilterOption = val => {
    if (selectedFilter === "K8s Cluster") {
      if (val?.entity_type === "Pod") {
        setParentSelected(val?.cluster_id);
        setSelectedView(CONTAINER);
        setSelectedCluster({
          value: val?.cluster_id,
          label: val?.cluster_name
        });
        // setSelectedPodId(val?.value);
        setSelectedPod({
          value: val?.value,
          label: val?.label
        });
        setShowViewPolicy(false);
        setShowClusterSubNav(val?.cluster_id);
        setSelectedOption(val?.node_id);
        setIsSelected(val?.value);
        switchToNormalView();
        setVmData([]);
        detailRef.current.open = true;
      } else {
        setSelectedView(POD);
        setSelectedCluster({
          value: val?.value,
          label: val?.label
        });
        setShowClusterSubNav(val?.value);
        setThroughCluster(false);
        setShowViewPolicy(false);
        setParentSelected(val?.value);
        detailRef.current.open = true;
        switchToNormalView();
      }
    } else if (selectedFilter === "VM Instances") {
      if (val?.entity_type === "Instance") {
        setSelectedView(PROCESS);
        setEntityType("process");
        setParentSelected(val?.instance_group_id && val?.instance_group_id);
        setShowClusterSubNav(val?.instance_group_id);
        setShowViewPolicy(false);
        setIsSelected(val?.value);
        setSelectedInstanceGroup({
          value: val?.instance_group_id,
          label: val?.instance_group_name
        });
        setSelectedInstance({ value: val?.value });
        detailRef.current.open = true;
        switchToNormalView();
      } else {
        setSelectedView(VMINSTANCE);
        setEntityType("vm");
        setParentSelected(val?.value);
        setShowClusterSubNav(val?.value);
        setShowViewPolicy(false);
        detailRef.current.open = true;
        switchToNormalView();
        // setSelected(val?.instance_group_id);
        setSelectedInstanceGroup({ value: val?.value });
      }
    }
  };
  useEffect(() => {
    if (selectedValue) setValue("searchOption", selectedValue);
  }, [selectedValue, setValue]);

  const handleOnChange = debounce(e => {
    setSearch(e);
  }, 750);
  return (
    <>
      <div className="hybrid-sidebar">
        <CustomFilterComponent
          sidebarFilterOptions={sidebarFilterOptions}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          setEntityType={setEntityType}
          setSearch={setSearch}
          setSelectedView={setSelectedView}
          setVmGroupData={setVmGroupData}
          instanceGroupData={instanceGroupData}
          switchToNormalView={switchToNormalView}
          detailRef={detailRef}
          setShowViewPolicy={setShowViewPolicy}
        />

        <span className="searchBox">
          <Controller
            {...register("searchOption", {
              //  required: Constants.CREATE_POLICY_INSTANCE_ERROR,
            })}
            control={control}
            defaultValue=""
            render={({ field: { onChange } }) => (
              <Select
                // closeMenuOnSelect={false}
                isLoading={showMenu && isSearchInputLoading ? true : false}
                options={option}
                onChange={value => {
                  onChange(value);
                  setSelectedValue(value);
                  selectedFilterOption(value);
                }}
                onInputChange={e => {
                  setShowMenu(e?.length > 3);
                  handleOnChange(e);
                }}
                placeholder="Search..."
                menuIsOpen={showMenu}
                value={selectedValue}
                styles={dropdownstyle2}
                ref={selectInputRef}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                //  isClearable={selectedValue?.value?true:false}
                // isDisabled={!instanceGroup.value}
              />
            )}
          />
          <svg
            className={`cursor-pointer closeIcon ${
              selectedValue?.value && search?.length === 0 ? "inline-block" : "hidden"
            } `}
            width="13"
            height="13"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setSelectedValue(null);
            }}
          >
            <path d="M0 1.25707L6.74293 8L0 14.7429L1.25707 16L8 9.25707L14.7429 16L16 14.7429L9.25707 8L16 1.25707L14.7429 0L8 6.7432L1.25707 0L0 1.25707Z" />
          </svg>
          {search?.length < 4 && search?.length > 0 && (
            <span className="mt-2 text-xs text-gray-600">Please enter atleast 4 characters</span>
          )}
        </span>

        {selectedFilter === "K8s Cluster" && (
          <details ref={detailRef} className={style.clusterdetails}>
            <summary
              onClick={() => {
                setMainEntity("Cluster");
                setEntityType("Clusters");
                setSelectedView(CLUSTER);
                setShowViewPolicy(false);
              }}
              className="flex items-center font-semibold one"
            >
              Kubernetes Cluster
            </summary>
            <div className="">
              <div>
                {isClusterLoading ? (
                  <div className="mt-3 ml-3">Loading...</div>
                ) : !clusterData || clusterData?.length < 1 ? (
                  <div className="mt-2 ml-5 text-sm font-medium">No data</div>
                ) : (
                  clusterData?.map((val, i) => (
                    <div className="mt-3 mb-2 ml-2 two" key={i}>
                      <li
                        className={`two-summary flex items-center cursor-pointer text-sm ${
                          parentSelected === val?.clusterId ? "active" : "inactive"
                        }`}
                      >
                        <ArrowForwardIcon
                          fontSize="16px"
                          color="#2e2e2e"
                          className={`${
                            parentSelected === val?.clusterId ||
                            showClusterSubNav === val?.clusterId
                              ? "icon-active"
                              : "icon-inactive"
                          }`}
                          onClick={() => {
                            setActiveClusterId(val?.clusterId);
                            setSelectedCluster({ value: val?.clusterId, label: val?.clusterName });
                            toggleParentSubNavFilterHandler(val?.clusterId);
                            // setShowViewPolicy(false);
                          }}
                        />
                        <span
                          className="flex items-center text-sm two-summary"
                          onClick={() => {
                            setSelectedValue(
                              val?.clusterId !== selectedValue ? null : selectedValue
                            );
                            viewchange("Clusters", parentSelected, val, showClusterSubNav);
                            setMainEntity("Cluster");
                            // onClickClusters(val?.clusterId, val?.clusterName);
                            setActiveClusterId(val?.clusterId);
                            setSelectedCluster({
                              value: val?.clusterId,
                              label: val?.clusterName
                            });
                            setSelectedPod({});
                            setSelectedOption("");
                            setSelectedNamespace({});
                            setThroughCluster(false);
                            toggleParentFilterHandler(val?.clusterId);
                            switchToNormalView();
                            switchSubNav();
                            setShowViewPolicy(false);
                            // setSelectedNodeId("");
                            setIsSelected("");
                          }}
                        >
                          {parentSelected === val?.clusterId ? (
                            <img
                              src={ClusterIcon}
                              alt=""
                              width="17px"
                              height="17px"
                              style={{ maxHeight: "17px" }}
                            />
                          ) : (
                            <img
                              src={ClusterGrayscaleIcon}
                              alt=""
                              width="17px"
                              height="17px"
                              style={{ maxHeight: "17px" }}
                            />
                          )}

                          <span className="ml-1.5">
                            {val?.clusterName}
                            <span className="pl-1">({val?.children?.length})</span>
                            <Tooltip title="Total Alerts" placement="right-start" arrow>
                              <span
                                className={`ml-1.5 p-1.5 rounded-full text-xs ${style.alertcount}`}
                                onClick={e => {
                                  if (val?.TotalAlerts) {
                                    e?.stopPropagation();
                                    e?.preventDefault();
                                    let params = {
                                      name: val?.ClusterName,
                                      value: val?.TotalAlerts
                                    };
                                    RedirectToLogs(params, "Cluster");
                                  }
                                }}
                              >
                                {kFormatter(val?.TotalAlerts) || 0}
                              </span>
                            </Tooltip>
                          </span>
                        </span>
                      </li>

                      {/* only show a pod  */}
                      {(parentSelected === val?.clusterId ||
                        showClusterSubNav === val?.clusterId) &&
                        // eslint-disable-next-line no-nested-ternary
                        (isPodLoading ? (
                          <div className="mt-2 ml-4 text-sm"> Loading...</div>
                        ) : val?.children?.length ? (
                          val?.children?.map((podData, index) => (
                            <div className="ml-2 subAccordion " key={index}>
                              <div className="ml-2 three">
                                <div
                                  className="flex mt-3 cursor-pointer fz-13"
                                  key={index}
                                  onClick={() => {
                                    setSelectedView(POD);
                                    setMainEntity("Cluster");
                                    setEntityType("pod");
                                    // setSelectedNodeId(nodeData?.nodeId);
                                    setSelectedPodId(podData?.podId);
                                    setSelectedCluster({
                                      value: val?.clusterId,
                                      label: val?.clusterName
                                    });
                                    // setSelectedNamespaceId(podData?.namespaceId);
                                    setSelectedNamespace({
                                      value: podData?.namespaceId,
                                      label: podData?.namespaceName
                                    });
                                    setSelectedPod({
                                      value: podData?.podId,
                                      label: podData?.podName
                                    });
                                    switchToNormalView();
                                    setIsSelected(podData?.podId);
                                    setShowViewPolicy(false);
                                    setParentSelected(val?.clusterId);
                                    setSelectedValue(podData);
                                    setSelectedView(CONTAINER);
                                    setSelectedValue(
                                      podData?.podId !== selectedValue?.value ? null : selectedValue
                                    );
                                  }}
                                >
                                  {isSelected === podData?.podId ? (
                                    <img
                                      src={PodIcon}
                                      alt=""
                                      width="17px"
                                      height="17px"
                                      className="mt-0.5"
                                      style={{ maxHeight: "17px" }}
                                    />
                                  ) : (
                                    <img
                                      src={PodGrayscaleIcon}
                                      alt=""
                                      width="17px"
                                      height="17px"
                                      className="mt-0.5"
                                      style={{ maxHeight: "17px" }}
                                    />
                                  )}
                                  <span
                                    className={`ml-1.5 ${selectedPod?.value === podData?.podId &&
                                      "active-child"}`}
                                  >
                                    {podData?.podName}
                                    <span className="pl-1">({podData?.ContainerCount})</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="mt-2 ml-5 text-sm font-medium">No data</div>
                        ))}
                    </div>
                  ))
                )}
              </div>
            </div>
          </details>
        )}

        {selectedFilter === "VM Instances" && (
          <div className={style.clusterdetails}>
            <details ref={detailRef}>
              <summary
                className="flex items-center font-semibold one"
                onClick={() => {
                  setSelectedView(VMGROUP);
                  setMainEntity("VM");
                  setEntityType("vmgroup");
                  setShowViewPolicy(false);
                  // setVmGroupData(
                  //   instanceGroupData.isError
                  //     ? []
                  //     : instanceGroupData.isFetched
                  //     ? instanceGroupData?.data?.list_of_instance_group
                  //     : []
                  // );

                  switchToNormalView();
                }}
              >
                VM Instances
              </summary>
              <div className="-ml-3">
                {instanceGroupData.isLoading ? (
                  <div className="mt-2 ml-5 text-sm">Loading...</div>
                ) : instanceGroupData?.isError || instanceGroupData?.data?.total_record < 1 ? (
                  <div className="mt-2 ml-5 text-sm font-medium">No data</div>
                ) : instanceGroupData.isFetched ? (
                  instanceGroupData?.data?.map((val, i) => (
                    <div className="mt-2 ml-2" key={i}>
                      <li
                        className={`text-sm flex items-start cursor-pointer ${
                          parentSelected === val?.id ? "active" : "inactive"
                        }`}
                        key={i}
                      >
                        <ArrowForwardIcon
                          fontSize="16px"
                          color="#2e2e2e"
                          className={`${
                            parentSelected === val?.id || showClusterSubNav === val?.id
                              ? "icon-active"
                              : "icon-inactive"
                          }`}
                          onClick={() => {
                            // if(showInstancesPermission){
                            toggleParentSubNavFilterHandler(val?.id);
                            // setSelectInstanceGroupId(parseInt(val?.id));
                            // }
                          }}
                        />
                        {/* <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6147 13.6666H1.38528C1.01788 13.6666 0.66553 13.5372 0.40574 13.3068C0.145949 13.0765 0 12.7641 0 12.4383L0 1.56152C0 1.23576 0.145949 0.923349 0.40574 0.693004C0.66553 0.462659 1.01788 0.333252 1.38528 0.333252H7.41922C7.54384 0.333468 7.66681 0.358529 7.77903 0.406578C7.89125 0.454628 7.98985 0.524438 8.06753 0.610841L8.67706 1.91885C8.75474 2.00526 8.85334 2.07507 8.96556 2.12312C9.07778 2.17117 9.20075 2.19623 9.32537 2.19644H14.6147C14.7966 2.19644 14.9768 2.22821 15.1448 2.28994C15.3129 2.35166 15.4656 2.44214 15.5943 2.55619C15.7229 2.67025 15.8249 2.80565 15.8946 2.95467C15.9642 3.10369 16 3.26341 16 3.42471V12.4383C16 12.5996 15.9642 12.7593 15.8946 12.9084C15.8249 13.0574 15.7229 13.1928 15.5943 13.3068C15.4656 13.4209 15.3129 13.5114 15.1448 13.5731C14.9768 13.6348 14.7966 13.6666 14.6147 13.6666Z"
                              fill={selectedOption === val?.id ? '#223CC3' : '#DBDFF0'} />
                          </svg> */}
                        <span
                          className="flex ml-2"
                          onClick={() => {
                            // if(showInstancesPermission){
                            // setSelectedView('Instances');
                            setSelectedValue(
                              val?.instances !== selectedValue ? null : selectedValue
                            );
                            viewchange("InstancesGroup", parentSelected, val, showClusterSubNav);
                            toggleParentFilterHandler(val?.id);

                            // setEntityType('vm');
                            setVmData(
                              val?.instances || val?.instances?.length > 0 ? val?.instances : []
                            );
                            setSelectedInstanceGroup({
                              value: val?.id,
                              label: val?.group_name
                            });
                            setSelectedInstance({});
                            switchToNormalView();
                            setIsSelected("");
                            // setMainEntity('VM');
                            setShowViewPolicy(false);
                            switchSubNav();
                            setSelectedValue(
                              val?.id !== selectedValue?.value ? null : selectedValue
                            );
                            // }
                          }}
                        >
                          {parentSelected === val?.id ? (
                            <img
                              src={VMGroupIcon}
                              alt=""
                              width="16px"
                              height="16px"
                              className="mt-0.5"
                              style={{ maxHeight: "16px" }}
                            />
                          ) : (
                            <img
                              src={VMGroupGrayscaleIcon}
                              alt=""
                              width="16px"
                              height="16px"
                              className="mt-0.5"
                              style={{ maxHeight: "16px" }}
                            />
                          )}
                          <span
                            className={`ml-1.5 ${parentSelected === val?.id && "active-child"}`}
                          >
                            {val?.group_name}
                            <span className="pl-1">({val?.instances?.length || 0})</span>
                          </span>
                        </span>
                      </li>
                      <div className="ml-5">
                        {(parentSelected === val?.id || showClusterSubNav === val?.id) &&
                          (val?.instances || val?.instances?.length > 0 ? (
                            val?.instances?.map((val1, ind) => (
                              <div className="subAccordion right-3" key={val1?.id}>
                                <li
                                  className="relative flex items-center mt-2 ml-4 cursor-pointer fz-13 vm-list"
                                  key={val1?.id}
                                  // onClick={() => {
                                  //   setSelectedView('processes');
                                  //   setParentSelected(parseInt(val?.id));
                                  //   toggleFilterHandler(val?.id);
                                  //   setEntityType('process');
                                  //   setVmProcessData(
                                  //     val1.processes
                                  //       ? val1.processes
                                  //       : processesData,
                                  //   );
                                  //   setSelectedInstanceGroup({
                                  //     value: val?.id,
                                  //     label: val?.group_name,
                                  //   });
                                  //   setSelectedInstance({
                                  //     value: val1?.id,
                                  //     label: val1?.instance_name,
                                  //   });
                                  //   switchToNormalView();
                                  //   setMainEntity('VM');
                                  //   setShowViewPolicy(false);
                                  //   setIsSelected(val1?.id);
                                  //   //toggleParentFilterHandler(val?.id);
                                  //   setSelectVmInstanceId(parseInt(val1?.id));
                                  //   setSelectedValue(
                                  //     val1?.id !== selectedValue?.value
                                  //       ? null
                                  //       : selectedValue,
                                  //   );
                                  // }}
                                >
                                  {isSelected === val1?.id ? (
                                    <img
                                      src={VMIcon}
                                      alt=""
                                      width="18px"
                                      height="18px"
                                      className="mt-0.5"
                                      style={{ maxHeight: "18px" }}
                                    />
                                  ) : (
                                    <img
                                      src={VMGrayscaleIcon}
                                      alt=""
                                      width="18px"
                                      height="18px"
                                      className="mt-0.5"
                                      style={{ maxHeight: "18px" }}
                                    />
                                  )}
                                  <span
                                    className={`ml-1.5 ${isSelected === val1?.id &&
                                      "active-child"}`}
                                  >
                                    {val1?.instance_name}
                                  </span>
                                </li>
                              </div>
                            ))
                          ) : (
                            <div className="mt-2 ml-5 text-sm font-medium">No data</div>
                          ))}
                      </div>
                    </div>
                  ))
                ) : (
                  []
                )}
              </div>
            </details>
          </div>
        )}
      </div>
    </>
  );
};

export default HybridViewSidebar;
