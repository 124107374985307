import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Title, Input, Button, ContentContainer, Select } from "components/simple";
import CryptoJS from "crypto-js";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useForm, Controller } from "react-hook-form";
import { helpJira, EDIT_JIRA_LINK } from "screens/ChannelIntegration/constants";
import JiraIcon from "../../assets/img/jira.svg";
import { Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import ErrorText from "components/simple/ErrorText";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "./DeleteModal";
import { getAccuknoxWorkspace } from "api/api";
import {
  useDeleteChannel,
  useJirIntegration,
  useJirUpdate
} from "store/entities/channels/mutation";

function JiraIntegration() {
  const [integrationName, setIntegrationName] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [summary, setSummary] = useState("");
  const [issueType, setIssueType] = useState("Select the options");
  const [disableSave, setDisableSave] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: "onChange" });
  const history = useHistory();
  const dispatch = useDispatch();
  const workspaceVal = getAccuknoxWorkspace();
  const location = useLocation();

  // edit jira
  const decryptSite = location?.state?.Site?.split(",") ?? "";
  const decryptToken = location?.state?.Token?.split(",") ?? "";
  const jiraUrl = CryptoJS.AES.decrypt(decryptSite[0] ?? "", decryptSite[1] ?? "");
  const originalUrl = jiraUrl.toString(CryptoJS.enc.Utf8);
  const jiraToken = CryptoJS.AES.decrypt(decryptToken[0] ?? "", decryptToken[1] ?? "");
  const originalToken = jiraToken.toString(CryptoJS.enc.Utf8);

  const isNotUndefined = val => typeof val !== "undefined";
  const getIntegrationName = location?.state?.IntegrationName;
  const getSiteUrl = originalUrl;
  const getUserEmail = location?.state?.UserEmail;
  const getToken = originalToken;
  const getUserId = location?.state?.UserId;
  const getProjectId = location?.state?.Project;
  const getIssueSummary = location?.state?.IssueSummary;
  const getIssueType = location?.state?.IssueType;
  useEffect(() => {
    if (
      (location?.pathname === EDIT_JIRA_LINK && integrationName) ||
      siteUrl ||
      email ||
      token ||
      userId ||
      projectId ||
      summary ||
      issueType
    ) {
      setValue("integrationName", integrationName);
      setValue("site", siteUrl);
      setValue("userEmail", email);
      setValue("tokenField", token);
      setValue("userID", userId);
      setValue("projectIdField", projectId);
      setValue("issueSummary", summary);
      setValue("issueType", issueType);
    }
  }, [integrationName, siteUrl, email, token, userId, summary, projectId, issueType]);

  useEffect(() => {
    if (location?.pathname === EDIT_JIRA_LINK) {
      setIntegrationName(getIntegrationName);
      setSiteUrl(getSiteUrl);
      setEmail(getUserEmail);
      setToken(getToken);
      setUserId(getUserId);
      setProjectId(getProjectId);
      setSummary(getIssueSummary);
      setIssueType(getIssueType);
    }
  }, []);

  let shouldUpdate =
    (isNotUndefined(integrationName) ||
      isNotUndefined(siteUrl) ||
      isNotUndefined(email) ||
      isNotUndefined(token) ||
      isNotUndefined(userId) ||
      isNotUndefined(projectId) ||
      isNotUndefined(summary) ||
      isNotUndefined(issueType)) &&
    (integrationName !== getIntegrationName ||
      siteUrl !== getSiteUrl ||
      email !== getUserEmail ||
      token !== getToken ||
      userId !== getUserId ||
      projectId !== getProjectId ||
      summary !== getIssueSummary ||
      issueType !== getIssueType);

  useEffect(() => {
    shouldUpdate =
      integrationName !== getIntegrationName ||
      siteUrl !== getSiteUrl ||
      email !== getUserEmail ||
      token !== getToken ||
      userId !== getUserId ||
      projectId !== getProjectId ||
      summary !== getIssueSummary ||
      issueType !== getIssueType;
  }, [integrationName, siteUrl, email, token, userId, projectId, summary, issueType]);

  const jiraTest = useJirIntegration(data => {
    if (data?.response == "Success") {
      dispatch(addNotification({ msg: "Jira Test Successful!", type: "success" }));
      setDisableSave(true);
    } else {
      setDisableSave(false);
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });
  const handleTest = async e => {
    try {
      const SecretKey = (Math.random() + 1).toString(36).substring(7);
      const EncryptedURL = CryptoJS.AES.encrypt(siteUrl, SecretKey).toString();
      const EncryptedJiraUrl = EncryptedURL.concat(",", SecretKey);
      const EncryptedToken = CryptoJS.AES.encrypt(token, SecretKey).toString();
      const tokenEncrypted = EncryptedToken.concat(",", SecretKey);
      const option = "test";
      jiraTest.mutate({
        integrationName,
        summary,
        EncryptedJiraUrl,
        projectId,
        issueType,
        email,
        tokenEncrypted,
        userId,
        option
      });
    } catch (err) {
      // console.log(err)
    }
  };
  const jiraIntegration = useJirIntegration(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Jira Integration Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });
  const jiraUpdate = useJirUpdate(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Jira Updated Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });
  const onSubmit = async e => {
    if (location?.pathname === EDIT_JIRA_LINK) {
      try {
        const SecretKey = (Math.random() + 1).toString(36).substring(7);
        const EncryptedURL = CryptoJS.AES.encrypt(siteUrl, SecretKey).toString();
        const EncryptedJiraUrl = EncryptedURL.concat(",", SecretKey);
        const EncryptedToken = CryptoJS.AES.encrypt(token, SecretKey).toString();
        const tokenEncrypted = EncryptedToken.concat(",", SecretKey);
        const ID = location?.state?.id;
        jiraUpdate.mutate({
          ID,
          integrationName,
          summary,
          EncryptedJiraUrl,
          projectId,
          issueType,
          email,
          tokenEncrypted,
          userId
        });
      } catch (error) {
        // console.log(error)
      }
    } else {
      try {
        const SecretKey = (Math.random() + 1).toString(36).substring(7);
        const EncryptedURL = CryptoJS.AES.encrypt(siteUrl, SecretKey).toString();
        const EncryptedJiraUrl = EncryptedURL.concat(",", SecretKey);
        const EncryptedToken = CryptoJS.AES.encrypt(token, SecretKey).toString();
        const tokenEncrypted = EncryptedToken.concat(",", SecretKey);
        const option = "save";
        jiraIntegration.mutate({
          integrationName,
          summary,
          EncryptedJiraUrl,
          projectId,
          issueType,
          email,
          tokenEncrypted,
          userId,
          option
        });
      } catch (err) {
        // console.log(err)
      }
    }
  };

  const closeHandler = () => {
    setIsOpen(false);
  };
  const deleteJiraChannel = useDeleteChannel(data => {
    history.push("/settings/integrations/list");
    dispatch(addNotification({ msg: "Status: Deleted Jira Integration", type: "success" }));
  });
  const handleDelete = async () => {
    const channeId = 6;
    const ID = location.state.id;
    deleteJiraChannel.mutate({
      channeId,
      ID
    });
  };
  return (
    <ContentContainer className={styles.container}>
      <DeleteModal
        isModalOpen={isOpen}
        onCloseModal={() => closeHandler()}
        onSubmit={() => handleDelete()}
      />
      <div className="flex justify-between py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex gap-1">
          <img src={JiraIcon} alt="icon" />
          <Title>Jira</Title>
        </div>
        <div className="flex justify-center items-center gap-5">
          {location?.pathname === EDIT_JIRA_LINK && (
            <div
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => setIsOpen(true)}
            >
              <DeleteOutlineIcon />
              Delete Channel
            </div>
          )}
          <div
            onClick={() => window.open(helpJira, "_blank", "noopener,noreferrer")}
            className="flex justify-center items-center text-blue-700 font-medium cursor-pointer"
          >
            Help <HelpOutlineIcon className={styles.help_icon} />
          </div>
        </div>
      </div>
      <div className="py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex flex-row gap-5">
          <div className={styles.firstDiv}>
            <Controller
              name="integrationName"
              {...register("integrationName", {
                required: "This Field Cannot be empty",
                maxLength: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="integrationName"
                  label="Integration Name"
                  placeholder="Enter a name for integration"
                  required
                  value={integrationName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setIntegrationName(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.integrationName && errors.integrationName.message} />
            </div>
          </div>
          <div className={styles.firstDiv}>
            <Controller
              name="site"
              {...register("site", {
                required: "Invalid Site URL, Please enter a valid URL",
                pattern: {
                  value: /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/,
                  message: "Enter valid Url."
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="site"
                  label="Site"
                  placeholder="https://accuknoxtemporal.atlassian.net/"
                  required
                  value={siteUrl}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setSiteUrl(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.site && errors.site.message} />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-0 pb-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className=" py-5 flex flex-row gap-5">
          <div className="w-3/5">
            <Controller
              name="userEmail"
              {...register("userEmail", {
                required: "User Email Required",
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z0-9]{2,4}|museum)$/,
                  message: "Entered value does not match email format"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="userEmail"
                  label="User Email"
                  placeholder="Usermail.example.com"
                  required
                  value={email}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setEmail(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.userEmail && errors.userEmail.message} />
            </div>
          </div>
          <div className="w-3/5">
            <Controller
              name="tokenField"
              {...register("tokenField", {
                required: "Token Required",
                maxLength: {
                  value: 200,
                  message: "Maximum length for this field is 200 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="tokenField"
                  label="Token"
                  placeholder="***************"
                  required
                  value={token}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setToken(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.tokenField && errors.tokenField.message} />
            </div>
          </div>
          <div className="w-1/2">
            <Controller
              name="userID"
              {...register("userID", {
                required: "User Id Required",
                maxLength: {
                  value: 60,
                  message: "Maximum length for this field is 60 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="userID"
                  label="User Id"
                  placeholder="60a7XXXXXXXXXX068dae49e"
                  required
                  value={userId}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setUserId(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.userID && errors.userID.message} />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="w-3/5">
            <Controller
              name="projectIdField"
              {...register("projectIdField", {
                required: "Project Id Required",
                maxLength: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="projectIdField"
                  label="Project ID"
                  placeholder="Enter a Project Id"
                  required
                  value={projectId}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setProjectId(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.projectIdField && errors.projectIdField.message} />
            </div>
          </div>
          <div className="w-3/5">
            <Controller
              name="issueSummary"
              {...register("issueSummary", {
                required: "Issue Summary Required",
                maxLength: {
                  value: 80,
                  message: "Maximum length for this field is 80 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="issueSummary"
                  label="Issue Summary"
                  placeholder="Enter the issue"
                  required
                  value={summary}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setSummary(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.issueSummary && errors.issueSummary.message} />
            </div>
          </div>
          <div className="w-1/2">
            <Typography className={styles.typo}>Issue Type</Typography>
            <Controller
              name="issueType"
              {...register("issueType", {
                required: true
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <div style={{ paddingTop: "10px" }}>
                  <Select
                    name="issueType"
                    value={{ label: issueType, value: issueType }}
                    onChange={option => {
                      onChange(option);
                      setIssueType(option?.label);
                    }}
                    placeholder="Select the options"
                    options={[
                      { label: "Story", value: "1" },
                      { label: "Bug", value: "2" }
                    ]}
                    containerClass={styles["action-group__filters-option"]}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between pt-6 pb-4">
        {/* <Button
          onClick={handleSubmit(handleTest)}
          disabled={location?.pathname === EDIT_JIRA_LINK ? !shouldUpdate : !isDirty || !isValid}
        >
          Test
        </Button> */}
        {/* <div className="flex"> */}
        <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={location?.pathname === EDIT_JIRA_LINK ? !shouldUpdate : !isValid}
          type="submit"
        >
          Save
        </Button>
        {/* </div> */}
      </div>
    </ContentContainer>
  );
}

export default JiraIntegration;
