import { navigate } from "helper/history";
import { routes } from "router";
import { subdomainSuffix } from "./constants";

export const getAccessTokenHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`
});
export const getRefreshTokenHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem("refreshToken")}`
});
export const getUserId = () => {
  return localStorage.getItem("userId");
};
export const getCurrentTenant = () => {
  const tenant = sessionStorage.getItem("tenant")
    ? JSON.parse(sessionStorage.getItem("tenant"))
    : JSON.parse(localStorage.getItem("tenant"));
  return tenant;
};
export const transformSubdomain = subdomain => `${subdomain || ""}${subdomainSuffix}`;

// Logout action function
export const logout = (reload = false) => {
  sessionStorage.removeItem("tenant");
  localStorage.removeItem("tenant");
  localStorage.removeItem("token");
  localStorage.removeItem("userId");

  if (reload) {
    window.location.replace(routes.loginScreen);
  } else {
    navigate(routes.loginScreen);
  }
};
