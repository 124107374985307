import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { addNotification } from "../notifications/actions";

import { errorHandler } from "helper/errorHandler";

const getAssetsParams = state => ({
  page: state.assets.params.page,
  pageSize: state.assets.params.pageSize,
  search: state.assets.params.search,
  ordering: state.assets.params.ordering,
  filters: state.assets.params.filters
});

// Watchers //

function* setAssetsParamsWorker() {
  const { page, pageSize, search, ordering, filters } = yield select(getAssetsParams);
  yield put(
    actions.getAssets({
      page,
      pageSize,
      search,
      ordering,
      filters
    })
  );
}
function* watchSetAssetsParamsWorker() {
  yield takeEvery(actions.setAssetsParams, setAssetsParamsWorker);
}

// Get Assets
function* getAssetsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAssets), payload);
    yield put(actions.getAssetsSuccess(response));
  } catch (error) {
    yield put(actions.getAssetsFailed(error));
  }
}
// Get Assets
function* watchGetAssetsWorker() {
  yield takeEvery(actions.getAssets, getAssetsWorker);
}

// Get Assets Mini
function* getAssetsMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAssetsMini), payload);
    yield put(actions.getAssetsMiniSuccess(response));
  } catch (error) {
    yield put(actions.getAssetsMiniFailed(error));
  }
}
// Get Assets Mini
function* watchGetAssetsMiniWorker() {
  yield takeEvery(actions.getAssetsMini, getAssetsMiniWorker);
}

// Get Asset
function* getAssetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAsset), payload);
    yield put(actions.getAssetSuccess(response));
  } catch (error) {
    yield put(actions.getAssetFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// Get Asset
function* watchGetAssetWorker() {
  yield takeEvery(actions.getAsset, getAssetWorker);
}

// Get Asset DataList types
function* getAssetDataListTypesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getDataLists), payload);
    yield put(actions.getAssetDataListTypesSuccess(response));
  } catch (error) {
    yield put(actions.getAssetDataListTypesFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// Get Asset DataList types
function* watchGetAssetDataListTypesWorker() {
  yield takeEvery(actions.getAssetDataListTypes, getAssetDataListTypesWorker);
}

// Get Asset DataList type
function* getAssetDataListTypeWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAssetDataListType), payload);
    yield put(actions.getAssetDataListTypeSuccess(response));
  } catch (error) {
    yield put(actions.getAssetDataListTypeFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// Get Asset DataList types
function* watchGetAssetDataListTypeWorker() {
  yield takeEvery(actions.getAssetDataListType, getAssetDataListTypeWorker);
}

// Get Data List by asset
function* getDataListByAssetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getDataListByAsset), payload);
    yield put(actions.getDataListByAssetSuccess(response));
  } catch (error) {
    yield put(actions.getDataListByAssetFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

function* watchGetDataListByAssetWorker() {
  yield takeEvery(actions.getDataListByAsset, getDataListByAssetWorker);
}

// Get findings by asset
function* getFindingsByAssetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getFindingsByAsset), payload);
    yield put(actions.getFindingsByAssetSuccess(response));
  } catch (error) {
    yield put(actions.getFindingsByAssetFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

function* watchGetFindingsByAssetWorker() {
  yield takeEvery(actions.getFindingsByAsset, getFindingsByAssetWorker);
}

// Get tickets configs
function* getTicketsConfigsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTicketsConfig), payload);
    yield put(actions.getTicketsConfigsSuccess(response));
  } catch (error) {
    yield put(actions.getTicketsConfigsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

function* watchGetTicketsConfigsWorker() {
  yield takeEvery(actions.getTicketsConfigs, getTicketsConfigsWorker);
}

// Set Asset Tag
function* setAssetTagWorker({ payload }) {
  try {
    const id = payload?.id;
    const response = yield call(makeRequest(service.setAssetTag), payload);
    yield put(actions.setAssetTagSuccess(response));
    yield put(actions.getAsset({ id }));
  } catch (error) {
    yield put(actions.setAssetTagFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// Set Asset Tag
function* watchSetAssetTagWorker() {
  yield takeEvery(actions.setAssetTag, setAssetTagWorker);
}

// Set Asset Baseline
function* setAssetBaselineWorker({ payload }) {
  try {
    const id = payload?.id;
    const response = yield call(makeRequest(service.setAssetBaseline), payload);
    yield put(actions.setAssetBaselineSuccess(response));
    yield put(actions.getAsset({ id }));
  } catch (error) {
    yield put(actions.setAssetBaselineFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// Set Asset Baseline
function* watchSetAssetBaselineWorker() {
  yield takeEvery(actions.setAssetBaseline, setAssetBaselineWorker);
}

// Get Asset Filter list
function* getAssetFilterChoicesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAssetFilterChoices), payload);
    yield put(actions.getAssetFilterChoicesSuccess(response));
  } catch (error) {
    yield put(actions.getAssetFilterChoicesFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetAssetFilterChoicesWorker() {
  yield takeEvery(actions.getAssetFilterChoices, getAssetFilterChoicesWorker);
}

// ADD GROUP
function* addGroupWorker({ payload }) {
  try {
    const { search } = yield select(getAssetsParams);
    const response = yield call(makeRequest(service.addGroup), { ...payload, search });
    yield put(actions.addGroupSuccess(response));
    yield put(actions.setAssetsParams());
    yield put(actions.actionGroupModal({ show: false, data: [] }));
    yield put(addNotification({ msg: "Group added successfully" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addGroupFailed(error));
  }
}
function* watchAddGroupWorker() {
  yield takeEvery(actions.addGroup, addGroupWorker);
}

// GET CHARTS DATA
function* getChartsDataWorker({ payload }) {
  try {
    const [assetTypesCount, scansChartData, assetsChartData, findingsChartData] = yield all([
      call(makeRequest(service.getAssetTypesCount)),
      call(makeRequest(service.getScansChartData), payload),
      call(makeRequest(service.getAssetsChartData), payload),
      call(makeRequest(service.getFindingsChartData), payload)
    ]);
    yield put(
      actions.getChartsDataSuccess({
        assetTypesCount,
        scansChartData,
        assetsChartData,
        findingsChartData
      })
    );
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getChartsDataFailed(error));
  }
}

function* watchGetChartsDataWorker() {
  yield takeEvery(actions.getChartsData, getChartsDataWorker);
}

// Watchers End//

export default function* rootSaga() {
  yield all([
    fork(watchGetAssetsWorker),
    fork(watchGetAssetsMiniWorker),
    fork(watchSetAssetsParamsWorker),
    fork(watchGetAssetWorker),
    fork(watchGetAssetDataListTypesWorker),
    fork(watchGetAssetDataListTypeWorker),
    fork(watchSetAssetTagWorker),
    fork(watchSetAssetBaselineWorker),
    fork(watchGetAssetFilterChoicesWorker),
    fork(watchAddGroupWorker),
    fork(watchGetChartsDataWorker),
    fork(watchGetDataListByAssetWorker),
    fork(watchGetFindingsByAssetWorker),
    fork(watchGetTicketsConfigsWorker)
  ]);
}
