import React, { useState } from "react";

export const usePasswordValidation = () => {
  const [lowerCase, setLowerCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [number, setNumber] = useState(false);
  const [minMax, setMinMax] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);

  const validatePassword = {
    lowerCase: v => {
      const lowerCaseRegex = /[a-z]/;
      const lowerCaseValidation = lowerCaseRegex.test(v);
      if (lowerCaseValidation) {
        setLowerCase(true);
      } else {
        setLowerCase(false);
      }
    },
    upperCase: v => {
      const upperCaseRegex = /[A-Z]/;
      const upperCaseValidation = upperCaseRegex.test(v);
      if (upperCaseValidation) {
        setUpperCase(true);
      } else {
        setUpperCase(false);
      }
    },
    specialCharacter: v => {
      const specialCharacterRegex = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g;
      const specialCharacterValidation = specialCharacterRegex.test(v);
      if (specialCharacterValidation) {
        setSpecialCharacter(true);
      } else {
        setSpecialCharacter(false);
      }
    },
    number: v => {
      const numberRegex = /[0-9]/;
      const numberValidation = numberRegex.test(v);
      if (numberValidation) {
        setNumber(true);
      } else {
        setNumber(false);
      }
    },
    minMaxLength: v => {
      const minMaxRegex = /^.{8,15}$/;
      const minMaxValidation = minMaxRegex.test(v);
      if (minMaxValidation) {
        setMinMax(true);
      } else {
        setMinMax(false);
      }
    }
  };

  return { validatePassword, lowerCase, upperCase, number, minMax, specialCharacter };
};
