import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

// Set Groups params
export const setGroupsParams = createAction(constant.SET_GROUPS_PARAMS);

// Set Group-assets params
export const setGroupAssetsParams = createAction(constant.SET_GROUP_ASSETS_PARAMS);

// Get Groups
export const getGroups = createAction(constant.GET_GROUPS);
export const getGroupsSuccess = createAction(constant.GET_GROUPS_SUCCESS);
export const getGroupsFailed = createAction(constant.GET_GROUPS_FAILED);

// Get Group assets
export const getGroupAssets = createAction(constant.GET_GROUP_ASSETS);
export const getGroupAssetsSuccess = createAction(constant.GET_GROUP_ASSETS_SUCCESS);
export const getGroupAssetsFailed = createAction(constant.GET_GROUP_ASSETS_FAILED);

// Get Group
export const getGroup = createAction(constant.GET_GROUP);
export const getGroupSuccess = createAction(constant.GET_GROUP_SUCCESS);
export const getGroupFailed = createAction(constant.GET_GROUP_FAILED);

// Create Group
export const createGroup = createAction(constant.CREATE_GROUP);
export const createGroupSuccess = createAction(constant.CREATE_GROUP_SUCCESS);
export const createGroupFailed = createAction(constant.CREATE_GROUP_FAILED);

// Update Group
export const updateGroup = createAction(constant.UPDATE_GROUP);
export const updateGroupSuccess = createAction(constant.UPDATE_GROUP_SUCCESS);
export const updateGroupFailed = createAction(constant.UPDATE_GROUP_FAILED);

// Delete Group
export const deleteGroup = createAction(constant.DELETE_GROUP);
export const deleteGroupSuccess = createAction(constant.DELETE_GROUP_SUCCESS);
export const deleteGroupFailed = createAction(constant.DELETE_GROUP_FAILED);

// Get Groups Mini
export const getGroupsMini = createAction(constant.GET_GROUPS_MINI);
export const getGroupsMiniSuccess = createAction(constant.GET_GROUPS_MINI_SUCCESS);
export const getGroupsMiniFailed = createAction(constant.GET_GROUPS_MINI_FAILED);
