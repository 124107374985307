import { Box, Divider } from "@mui/material";
import { Title } from "components/simple";
import PolicyTags from "components/simple/PolicyTags";
import React, { useEffect, useState } from "react";
import { NETWORK, SYSTEM } from "screens/MonitorLogs/Constants";
import styles from "./styles.module.scss";
import moment from "moment-timezone";
import { getBorderColorForSeverity } from "screens/MonitorLogs/helper";
import CloseIcon from "@mui/icons-material/Close";

function Summary({ type, data, onClose, updateLocalDSL }) {
  const [SeverityColor, setSeverityColor] = useState("");
  const [SeverityName, setSeverityName] = useState("");
  const falsyValues = ["", null, undefined, "null", "undefined"];
  const logsTimeFormat = "MM-DD-YY HH:mm z";
  useEffect(() => {
    switch (type === NETWORK ? "Critical" : data?.Severity) {
      case "1":
      case "2":
      case "CRITICAL":
      case "Critical":
        setSeverityColor(`${styles.critical_button}`);
        setSeverityName("Critical");
        break;
      case "3":
      case "4":
      case "HIGH":
      case "High":
        setSeverityColor(`${styles.high_button}`);
        setSeverityName("High");
        break;
      case "5":
      case "6":
      case "MEDIUM":
      case "Medium":
        setSeverityColor(`${styles.medium_button}`);
        setSeverityName("Medium");
        break;
      case "7":
      case "8":
      case "LOW":
      case "Low":
        setSeverityColor(`${styles.low_button}`);
        setSeverityName("Low");
        break;
      case "9":
      case "10":
      case "INFORMATIONAL":
      case "Informational":
      case "":
      case undefined:
        setSeverityColor(`${styles.information_button}`);
        setSeverityName("Informational");
        break;
    }
  }, [data]);
  return (
    <Box className="w-full">
      <Divider
        className={`${styles.color_line} w-full mb-2`}
        style={{
          background: getBorderColorForSeverity(type === NETWORK ? "Critical" : data?.Severity)
        }}
      />
      <Box className="flex justify-between gap-2">
        <Box className="flex justify-start items-center gap-2 w-2/3">
          <label className={SeverityColor}>{SeverityName}</label>
          <span className="text-sm mt-1">
            {type === NETWORK && (
              <>
                {moment
                  .unix(data?.time)
                  .tz(moment.tz.guess())
                  .format("LL")
                  .concat(" at ")}
                {moment
                  .unix(data?.time)
                  .tz(moment.tz.guess())
                  .format("HH:mm")}{" "}
                {moment
                  .unix(data?.time)
                  .tz(moment.tz.guess())
                  .fromNow()}
              </>
            )}
            {type === SYSTEM && (
              <>
                {moment
                  .unix(data?.Timestamp)
                  .tz(moment.tz.guess())
                  .format("LL")
                  .concat(" at ")}
                {moment
                  .unix(data?.Timestamp)
                  .tz(moment.tz.guess())
                  .format("HH:mm")}{" "}
                {moment
                  .unix(data?.Timestamp)
                  .tz(moment.tz.guess())
                  .fromNow()}
              </>
            )}
          </span>
        </Box>
        <Box className="flex gap-2"></Box>
        <Box className="text-gray-700 flex justify-end">
          <CloseIcon
            className="cursor-pointer"
            style={{ float: "right", height: "21px" }}
            onClick={() => onClose(false)}
          />
        </Box>
      </Box>
      {type === NETWORK && (
        <Box className="mt-2">
          <Box className="log-details w-full">
            <Title className={`${styles.top_text}`}>
              {falsyValues?.includes(data?.flow?.verdict) ? "DROPPED" : data?.flow?.verdict}
            </Title>
            <Divider />
          </Box>
          <Box className={`${styles.table_content} mt-4`}>
            <Box>
              <Title className={`${styles.sub_titles}`}>Timestamp</Title>
              <span className={`${styles.text_ellipsis}`} style={{ fontSize: "14px" }}>
                {moment
                  .unix(data?.time)
                  .tz(moment.tz.guess())
                  .format(logsTimeFormat)}
              </span>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Cluster Name</Title>
              <span style={{ fontSize: "14px" }}>{data?.cluster_name}</span>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Flow_verdict</Title>
              <p className={`${styles.text_ellipsis}`} style={{ fontSize: "14px" }}>
                {data.flow?.verdict}
              </p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Severity</Title>
              <p style={{ fontSize: "14px" }}>Critical</p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Flow_destination_pod_name</Title>
              <p style={{ fontSize: "14px" }}>{data?.flow?.destination?.pod_name}</p>
            </Box>
            {/* <Box>
              <Title className={`${styles.sub_titles}`}>Flow_source_pod_name</Title>
              <p style={{ fontSize: "14px" }}>{data?.flow?.source?.pod_name}</p>
            </Box> */}
            <Box>
              <Title className={`${styles.sub_titles}`}>Flow_traffic_direction</Title>
              <p style={{ fontSize: "14px" }}>{data?.flow?.traffic_direction}</p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Flow_Summary</Title>
              <span style={{ fontSize: "14px" }}>{data?.flow?.Summary}</span>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Flow_IP_ipVersion</Title>
              <span style={{ fontSize: "14px" }}>{data?.flow?.IP?.ipVersion}</span>
            </Box>
          </Box>
          <Divider className="mt-2" />
        </Box>
      )}
      {type === SYSTEM && (
        <Box className="mt-2">
          <Box className="log-details w-full">
            <Title className={`${styles.top_text}`}>
              {falsyValues?.includes(data?.Message) ? "kubearmor syslog" : data?.Message}
            </Title>
            <Box className="py-2 flex gap-1">
              {data?.Tags?.split(",")?.map((item, index) => (
                <div
                  onClick={() => {
                    if (window.location.pathname === "/monitors-logging/logs-v2") {
                      updateLocalDSL([
                        { text: `ATags ${"match"} ${item}`, key: "ATags", op: "match" }
                      ]);
                    } else {
                      updateLocalDSL([{ text: `ATags:${item}`, key: "ATags", type: "log" }]);
                    }
                    onClose(false);
                  }}
                  className="cursor-pointer"
                >
                  <PolicyTags tagData={[{ id: index, name: item }]} />
                </div>
              ))}
            </Box>
            <Divider />
          </Box>
          <Box className={`${styles.table_content} mt-4`}>
            <Box>
              <Title className={`${styles.sub_titles}`}>Policy Name</Title>
              <p className={`${styles.text_ellipsis}`} style={{ fontSize: "14px" }}>
                {data?.PolicyName}
              </p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Resource</Title>
              <p style={{ fontSize: "14px" }}>{data?.Resource}</p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Source</Title>
              <p className={`${styles.text_ellipsis}`} style={{ fontSize: "14px" }}>
                {data?.Source}
              </p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Action</Title>
              <p style={{ fontSize: "14px" }}>{data?.Action}</p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Result</Title>
              <p style={{ fontSize: "14px" }}>Result</p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Severity</Title>
              <p style={{ fontSize: "14px" }}>{data?.Severity}</p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles}`}>Operation</Title>
              <p style={{ fontSize: "14px" }}>{data?.Operation}</p>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles} mb-[11px]`}>Cluster Name</Title>
              <span style={{ fontSize: "14px" }}>{data?.ClusterName}</span>
            </Box>
            <Box>
              <Title className={`${styles.sub_titles} mb-[10px]`}>Pod Name</Title>
              <span style={{ fontSize: "14px" }}>{data?.PodName}</span>
            </Box>
          </Box>
          <Divider className="mt-2" />
        </Box>
      )}
    </Box>
  );
}

export default Summary;
