import React, { useState } from "react";

import { useDispatch } from "react-redux";
import Select from "@mui/material/Select";
import { useQueryClient } from "react-query";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { addNotification } from "store/entities/notifications/actions";
import { useChangeRegistryStatusMutate } from "store/entities/registry/mutations";

import UpdateStatusModal from "../Modals/UpdateStatusModal/UpdateStatusModal";

export const Status = ({ registryId, registryStatus }) => {
  const [currStatus, setCurrStatus] = React.useState(registryStatus);
  const newStatus = currStatus === "Active" ? "Inactive" : "Active";
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const [isModalShown, setIsModalShown] = useState(false);

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const changeRegistryStatusMutation = useChangeRegistryStatusMutate(
    () => {
      queryClient.invalidateQueries(["getRegistryList"]);
      setIsModalShown(false);
      setCurrStatus(newStatus);
      dispatch(addNotification({ msg: "Status updated Successfully", type: "success" }));
    },
    () => {
      dispatch(addNotification({ msg: "Status Update Failed", type: "error" }));
    }
  );

  const handleStatusUpdate = e => {
    e.preventDefault();
    changeRegistryStatusMutation.mutate({ id: registryId, status: newStatus });
  };

  return (
    <>
      {isModalShown && (
        <UpdateStatusModal
          isOpen={isModalShown}
          onSubmit={handleStatusUpdate}
          onClose={() => setIsModalShown(false)}
          isLoading={isSaveLoading}
          from={currStatus}
          to={newStatus}
        />
      )}
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          value={currStatus}
          onChange={() => setIsModalShown(true)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            height: 40,
            color: "#fff",
            "& .MuiSvgIcon-root": {
              color: "white"
            },
            border: "0px solid white",
            backgroundColor: currStatus === "Active" ? "#1CAC6E" : "#FF8989"
          }}
        >
          <MenuItem value="Inactive">Inactive</MenuItem>
          <MenuItem value="Active">Active</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
