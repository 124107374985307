import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Input from "../Input";

import styles from "./styles.module.scss";

const InputSearch = ({ className, containerClassName, onSearch, value, ...inputProps }) => {
  const timerRef = React.useRef();
  const [searchVal, setSearchVal] = useState("");

  const triggerSearchRequest = text => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      onSearch(text);
    }, 500);
  };
  useEffect(() => {
    setSearchVal(value ?? "");
  }, [value]);

  const handleSearch = v => {
    triggerSearchRequest(v);
    setSearchVal(v);
  };

  return (
    <Input
      {...inputProps}
      type="text"
      placeholder="Search"
      value={searchVal}
      onChange={val => handleSearch(val.target.value)}
      containerClassName={`${styles.searchContainer} ${containerClassName}`}
      className={`${styles.search} ${className}`}
    />
  );
};

InputSearch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  invalid: PropTypes.string
};

InputSearch.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  disabled: false,
  required: false,
  onChange: () => {},
  onBlur: () => {},
  className: "",
  invalid: ""
};

export default InputSearch;
