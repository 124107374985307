import React from "react";

export default function Settings2(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 17"
      fill="none"
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.355 8.70505C13.385 8.48005 13.4 8.24755 13.4 8.00005C13.4 7.76005 13.385 7.52005 13.3475 7.29505L14.87 6.11005C15.005 6.00505 15.0425 5.80255 14.96 5.65255L13.52 3.16255C13.43 2.99755 13.2425 2.94505 13.0775 2.99755L11.285 3.71755C10.91 3.43255 10.5125 3.19255 10.07 3.01255L9.80002 1.10755C9.77002 0.927549 9.62002 0.800049 9.44002 0.800049H6.56002C6.38002 0.800049 6.23752 0.927549 6.20752 1.10755L5.93752 3.01255C5.49502 3.19255 5.09002 3.44005 4.72252 3.71755L2.93002 2.99755C2.76502 2.93755 2.57752 2.99755 2.48752 3.16255L1.05502 5.65255C0.965021 5.81005 0.995021 6.00505 1.14502 6.11005L2.66752 7.29505C2.63002 7.52005 2.60002 7.76755 2.60002 8.00005C2.60002 8.23255 2.61502 8.48005 2.65252 8.70505L1.13002 9.89005C0.995021 9.99505 0.957521 10.1975 1.04002 10.3475L2.48002 12.8375C2.57002 13.0026 2.75752 13.055 2.92252 13.0025L4.71502 12.2825C5.09002 12.5675 5.48752 12.8075 5.93002 12.9875L6.20002 14.8925C6.23752 15.0725 6.38002 15.2 6.56002 15.2H9.44002C9.62002 15.2 9.77002 15.0725 9.79252 14.8925L10.0625 12.9875C10.505 12.8075 10.91 12.5675 11.2775 12.2825L13.07 13.0025C13.235 13.0625 13.4225 13.0026 13.5125 12.8375L14.9525 10.3475C15.0425 10.1825 15.005 9.99505 14.8625 9.89005L13.355 8.70505ZM8.00002 10.7C6.51502 10.7 5.30002 9.48505 5.30002 8.00005C5.30002 6.51505 6.51502 5.30005 8.00002 5.30005C9.48502 5.30005 10.7 6.51505 10.7 8.00005C10.7 9.48505 9.48502 10.7 8.00002 10.7Z"
        fill={props.color}
      />
    </svg>
  );
}
