import React from 'react';
import styled from 'styled-components';

const SpinnerLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: inline-block;
  overflow: hidden;
  background: transparent;

  @keyframes spinner {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

export const Spinner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.2);
  backface-visibility: hidden;
  transform-origin: 0 0;

  div {
    position: absolute;
    width: 65px;
    height: 65px;
    border: 10px solid #4a5568;
    border-top-color: transparent;
    border-radius: 50%;
    box-sizing: content-box;
    animation: spinner 1s linear infinite;
    top: 50px;
    left: 50px;
  }
`;

const SpinnerLoader = () => {
  return (
    <SpinnerLoaderContainer>
      <Spinner>
        <div></div>
      </Spinner>
    </SpinnerLoaderContainer>
  );
};

export default SpinnerLoader;
