import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

// Set search params
export const setCloudParams = createAction(constant.SET_CLOUD_PARAMS);

// Get cloud accounts
export const getCloudAccounts = createAction(constant.GET_CLOUD_ACCOUNTS);
export const getCloudAccountsSuccess = createAction(constant.GET_CLOUD_ACCOUNTS_SUCCESS);
export const getCloudAccountsFailed = createAction(constant.GET_CLOUD_ACCOUNTS_FAILED);

// Get AWS regions
export const getAwsRegions = createAction(constant.GET_AWS_REGIONS);
export const getAwsRegionsSuccess = createAction(constant.GET_AWS_REGIONS_SUCCESS);
export const getAwsRegionsFailed = createAction(constant.GET_AWS_REGIONS_FAILED);

// Add AZURE account
export const addAzureAccount = createAction(constant.ADD_AZURE_ACCOUNT);
export const addAzureAccountSuccess = createAction(constant.ADD_AZURE_ACCOUNT_SUCCESS);
export const addAzureAccountFailed = createAction(constant.ADD_AZURE_ACCOUNT_FAILED);

// Get AZURE account
export const getAzureAccount = createAction(constant.GET_AZURE_ACCOUNT);
export const getAzureAccountSuccess = createAction(constant.GET_AZURE_ACCOUNT_SUCCESS);
export const getAzureAccountFailed = createAction(constant.GET_AZURE_ACCOUNT_FAILED);

// Add Google account
export const addGoogleCloudAccount = createAction(constant.ADD_GOOGLE_CLOUD_ACCOUNT);
export const addGoogleCloudAccountSuccess = createAction(constant.ADD_GOOGLE_CLOUD_ACCOUNT_SUCCESS);
export const addGoogleCloudAccountFailed = createAction(constant.ADD_GOOGLE_CLOUD_ACCOUNT_FAILED);

// Get Google account
export const getGCPAccount = createAction(constant.GET_GCP_ACCOUNT);
export const getGCPAccountSuccess = createAction(constant.GET_GCP_ACCOUNT_SUCCESS);
export const getGCPAccountFailed = createAction(constant.GET_GCP_ACCOUNT_FAILED);

// Add aws cloud manual setup
export const addAwsCloudManualSetup = createAction(constant.ADD_AWS_CLOUD_MANUAL_SETUP);
export const addAwsCloudManualSetupSuccess = createAction(
  constant.ADD_AWS_CLOUD_MANUAL_SETUP_SUCCESS
);
export const addAwsCloudManualSetupFailed = createAction(
  constant.ADD_AWS_CLOUD_MANUAL_SETUP_FAILED
);

// Get AWS manual account
export const getAWSManualAccount = createAction(constant.GET_AWS_MANUAL_ACCOUNT);
export const getAWSManualAccountSuccess = createAction(constant.GET_AWS_MANUAL_ACCOUNT_SUCCESS);
export const getAWSManualAccountFailed = createAction(constant.GET_AWS_MANUAL_ACCOUNT_FAILED);

// Add aws cloud access setup
export const addAwsCloudAccessKey = createAction(constant.ADD_AWS_CLOUD_ACCESS_KEY);
export const addAwsCloudAccessKeySuccess = createAction(constant.ADD_AWS_CLOUD_ACCESS_KEY_SUCCESS);
export const addAwsCloudAccessKeyFailed = createAction(constant.ADD_AWS_CLOUD_ACCESS_KEY_FAILED);

// Get AWS access account
export const getAWSAccessAccount = createAction(constant.GET_AWS_ACCESS_ACCOUNT);
export const getAWSAccessAccountSuccess = createAction(constant.GET_AWS_ACCESS_ACCOUNT_SUCCESS);
export const getAWSAccessAccountFailed = createAction(constant.GET_AWS_ACCESS_ACCOUNT_FAILED);

// Change enabled status
export const changeEnabledStatus = createAction(constant.CHANGE_ENABLED_STATUS);
export const changeEnabledStatusSuccess = createAction(constant.CHANGE_ENABLED_STATUS_SUCCESS);
export const changeEnabledStatusFailed = createAction(constant.CHANGE_ENABLED_STATUS_FAILED);

export const runAccountScan = createAction(constant.RUN_ACCOUNT_SCAN);
export const runAccountScanSuccess = createAction(constant.RUN_ACCOUNT_SCAN_SUCCESS);
export const runAccountScanFailed = createAction(constant.RUN_ACCOUNT_SCAN_FAILED);

// Delete cloud status
export const deleteCloudAccount = createAction(constant.DELETE_CLOUD_ACCOUNT);
export const deleteCloudAccountSuccess = createAction(constant.DELETE_CLOUD_ACCOUNT_SUCCESS);
export const deleteCloudAccountFailed = createAction(constant.DELETE_CLOUD_ACCOUNT_FAILED);

// Update Connection
export const updateConnection = createAction(constant.UPDATE_CONNECTION);
export const updateConnectionSuccess = createAction(constant.UPDATE_CONNECTION_SUCCESS);
export const updateConnectionFailed = createAction(constant.UPDATE_CONNECTION_FAILED);

// Modal actions
export const actionStatusModal = createAction(constant.ACTION_STATUS_MODAL);

export const actionDeleteModal = createAction(constant.ACTION_DELETE_MODAL);

export const actionSetScanScheduleModal = createAction(constant.ACTION_SCAN_MODAL);
