/* eslint-disable camelcase */
import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { addNotification } from "store/entities/notifications/actions";

import {
  getDataListConfigsFiltersData,
  postDataListConfigs,
  editDataListConfigs,
  updDataListsParams,
  setPanelState,
  setDataListsParams
} from "store/entities/datalist/actions";

import { AssetSelect, TargetsSelect } from "helper/AsyncSelects";

import {
  datalistConfigsFiltersDataSelector,
  getPanelState
} from "store/entities/datalist/selectors";

import { ActionButton, Button, Select, Tags, Input } from "components/simple";

import styles from "./styles.module.scss";

const PanelCreateConfig = ({ onLoadData, onCollapse, collapse, editData, onClose, id }) => {
  const dispatch = useDispatch();
  const selectTenant = useSelector(st => st.users.selectTenant);
  const { groupBy } = useSelector(st => st.datalist);
  const panelState = useSelector(getPanelState);

  const { data_types_list, filter_fields_list, display_fields_list } = useSelector(
    datalistConfigsFiltersDataSelector
  );

  useEffect(() => {
    if (editData) {
      const target = editData?.target?.id
        ? {
            value: editData?.target?.id,
            label: editData?.target?.name
          }
        : editData?.target;

      const asset = editData?.asset?.id
        ? {
            value: editData?.asset?.id,
            label: editData?.asset?.title
          }
        : editData?.asset;

      const data_type = data_types_list.find(item => item.value === editData?.data_type);
      const filter_fields = editData?.filter_fields?.map(item => {
        return filter_fields_list?.find(t => t.value === item);
      });
      const display_fields = editData?.display_fields
        ?.map(item => {
          return display_fields_list?.find(t => t.value === item);
        })
        .filter(v => v);
      const name = editData?.name;
      const res = {
        name,
        target,
        asset,
        data_type,
        filter_fields,
        display_fields,
        display_fields_width: editData?.display_fields_width ?? []
      };

      if (data_type?.value !== panelState?.data_type?.value) {
        dispatch(getDataListConfigsFiltersData({ data_type }));
      }

      dispatch(setPanelState(res));
    }
  }, [editData]);

  useEffect(() => {
    dispatch(getDataListConfigsFiltersData());
  }, [selectTenant]);

  useEffect(() => {
    if (groupBy) {
      dispatch(
        setPanelState({
          asset: "",
          filter_fields: [],
          display_fields: []
        })
      );
    }
  }, [groupBy]);

  const onChangeSelect = (val, name) => {
    if (groupBy) {
      dispatch(
        setDataListsParams({
          ordering: "",
          groupBy: null,
          page: 0
        })
      );
    }
    if (name === "data_type") {
      dispatch(getDataListConfigsFiltersData({ data_type: val }));
      dispatch(
        updDataListsParams({
          temporaryData: {
            ...editData,
            display_fields: []
          }
        })
      );
      dispatch(
        setPanelState({
          [name]: val,
          asset: "",
          filter_fields: [],
          display_fields: []
        })
      );
      return;
    }

    dispatch(
      setPanelState({
        [name]: val
      })
    );
  };

  const saveConfig = () => {
    if (!panelState?.name) {
      dispatch(addNotification({ msg: "You must fill in the required fields", type: "warning" }));
      return;
    }

    const res = {
      name: panelState.name,
      data_type: panelState?.data_type?.value || "",
      filter_fields: panelState?.filter_fields?.map(item => item.value),
      display_fields: panelState?.display_fields?.map(item => item.value),
      target: panelState?.target || "",
      asset: panelState?.asset || "",
      table_properties: {
        display_fields_width: panelState?.display_fields_width ?? []
      }
    };

    if (id) {
      dispatch(
        editDataListConfigs({
          id,
          ...res
        })
      );
      const { table_properties, ...updRes } = res;
      onLoadData({
        ...updRes,
        display_fields_width: panelState?.display_fields_width ?? []
      });
    } else {
      dispatch(postDataListConfigs(res));
      onCollapse(false);
    }
  };
  const loadData = () => {
    const res = {
      name: panelState?.name,
      data_type: panelState?.data_type?.value || "",
      filter_fields: panelState?.filter_fields?.map(item => item.value),
      display_fields: panelState?.display_fields?.map(item => item.value),
      target: panelState?.target || "",
      asset: panelState?.asset || "",
      display_fields_width: panelState?.display_fields_width ?? []
    };
    onLoadData(res);
  };

  return (
    <>
      {collapse ? (
        <div className={styles.panelContent}>
          <ActionButton
            type="save"
            text="Save config"
            onClick={() => saveConfig()}
            className={styles.saveConfigButton}
          />
          <div className={styles.panelGrid}>
            <Input
              type="text"
              placeholder="Name"
              label="Name"
              required="true"
              value={panelState.name}
              onChange={val => onChangeSelect(val.target.value, "name")}
              className={styles.input}
            />
          </div>
          <div className={styles.panelGrid}>
            <Select
              containerClass={styles.selectPanel}
              onChange={v => onChangeSelect(v, "data_type")}
              value={panelState.data_type}
              placeholder="Data type"
              label="Data type"
              options={data_types_list}
              isClearable
            />
            <TargetsSelect
              containerClass={styles.selectPanel}
              onChange={v => onChangeSelect(v, "target")}
              value={panelState.target}
              placeholder="Target"
              label="Target"
              isClearable
              cacheOptions={false}
            />

            <AssetSelect
              key={panelState?.data_type?.value}
              data_type={panelState?.data_type?.value || panelState?.data_type?.id}
              containerClass={styles.selectPanel}
              onChange={val => onChangeSelect(val, "asset")}
              label="Asset"
              placeholder="Asset"
              value={panelState.asset}
              isClearable
              cacheOptions={false}
            />
          </div>

          <div className={styles.panelGrid}>
            <Tags
              label="Select fields to filter"
              values={panelState.filter_fields}
              list={filter_fields_list}
              onChange={v => onChangeSelect(v, "filter_fields")}
            />
            <Tags
              label="Select fields to display"
              values={panelState.display_fields}
              list={display_fields_list}
              onChange={v => onChangeSelect(v, "display_fields")}
            />
          </div>

          <div className={styles.buttonGroup}>
            <Button className={styles.button} icon="plus" onClick={() => onCollapse(false)}>
              Collapse
            </Button>
            <Button
              className={styles.button}
              icon="plus"
              variant="outline"
              onClick={() => loadData()}
            >
              Load data
            </Button>

            <Button className={`${styles.button} ${styles.right}`} onClick={() => onClose()}>
              Close
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

PanelCreateConfig.propTypes = {};

export default memo(PanelCreateConfig);
