import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Info } from "@mui/icons-material";
import styled from "styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Edit from "components/simple/Icon/Edit";
import { Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ReplayIcon from "@mui/icons-material/Replay";
import Switch from "./Switch";
import { useStorePostureMutation } from "../service/mutation";
import { useNameSpaceDetail } from "../service/query";

export default function SecurityModal({
  process_posture,
  default_posture,
  network_posture,
  showContextMenuAbove,
  setShowContextMenuAbove,
  showContextMenuLeft,
  setShowContextMenuLeft,
  setShowSecurityPosture,
  clickedNamespace,
  setNamespaceData,
  setClickedNamespace,
  isListView,
  namespaceData,
  xPos,
  yPos,
  handleStorePosture
}) {
  const [processSwitchEnabled, setProcessSwitchEnabled] = useState(false);
  const [networkSwitchEnabled, setNetworkSwitchEnabled] = useState(false);
  const [postureFileValue, setPostureFileValue] = useState("");
  const [postureNetworkValue, setPostureNetworkValue] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [isResetable, setIsResetable] = useState(false);

  const processPosture = processSwitchEnabled ? "Block" : "Audit";
  const networkPosture = networkSwitchEnabled ? "Block" : "Audit";
  const { namespace_id, groupName, namespace } = namespaceData;
  const { data: nameSpaceDetail = [], isLoading: isNameSpaceDetailisLoading } = useNameSpaceDetail(
    namespace_id
  );
  useEffect(() => {
    setPostureFileValue(
      nameSpaceDetail?.kubearmor_file_posture
        ? nameSpaceDetail?.kubearmor_file_posture
        : nameSpaceDetail?.default_posture
    );
    setPostureNetworkValue(
      nameSpaceDetail?.kubearmor_network_posture
        ? nameSpaceDetail?.kubearmor_network_posture
        : nameSpaceDetail?.default_posture
    );
    if (
      (nameSpaceDetail?.kubearmor_file_posture === "Block" &&
        nameSpaceDetail?.kubearmor_file_posture) ||
      nameSpaceDetail?.default_posture === "Block"
    ) {
      setProcessSwitchEnabled(true);
    } else {
      setProcessSwitchEnabled(false);
    }

    if (
      (nameSpaceDetail?.kubearmor_network_posture &&
        nameSpaceDetail?.kubearmor_network_posture === "Block") ||
      nameSpaceDetail?.default_posture === "Block"
    ) {
      setNetworkSwitchEnabled(true);
    } else {
      setNetworkSwitchEnabled(false);
    }
  }, [JSON.stringify(nameSpaceDetail)]);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {}, [JSON.stringify(nameSpaceDetail)]);

  const handleEditReset = type => {
    if (type === "file") {
      const currentToggleValue = !processSwitchEnabled;
      const currentPostureValue = currentToggleValue ? "Block" : "Audit";
      setProcessSwitchEnabled(currentToggleValue);
      setPostureFileValue(currentPostureValue);
      handleStorePosture(currentPostureValue, postureNetworkValue);
    } else {
      const currentToggleValue = !networkSwitchEnabled;
      const currentPostureValue = currentToggleValue ? "Block" : "Audit";
      setNetworkSwitchEnabled(currentToggleValue);
      setPostureNetworkValue(currentPostureValue);
      handleStorePosture(postureFileValue, currentPostureValue);
    }
    setIsEditable(false);
    setIsResetable(!isResetable);
    setShowSecurityPosture(false);
  };
  const handleReset = () => {
    handleStorePosture(default_posture, default_posture);

    setProcessSwitchEnabled(default_posture === "Block" ? true : false);

    setNetworkSwitchEnabled(default_posture === "Block" ? true : false);
    setIsResetable(false);
    setShowSecurityPosture(false);
  };

  return (
    <div>
      <ClickAwayListener
        onClickAway={() => {
          setShowSecurityPosture(false);
          setShowContextMenuAbove({ showAbove: false });
          setShowContextMenuLeft({ showLeft: false });
          setClickedNamespace([]);
          setNamespaceData({});
        }}
      >
        <Container
          style={{
            zIndex: 40,
            // width: '225px',
            position: "fixed",
            top: `${showContextMenuAbove?.showAbove ? "auto" : yPos}`,
            bottom: `${
              showContextMenuAbove?.showAbove
                ? showContextMenuAbove?.newYPosFromTable + 20 + "px"
                : "auto"
            }`,
            left: `${showContextMenuLeft?.showLeft && !isListView ? "auto" : xPos}`,
            right: `${
              showContextMenuLeft?.showLeft && !isListView
                ? showContextMenuLeft?.newXPosFromTable - 20 + "px"
                : "auto"
            }`
          }}
          showContextMenuAbove={showContextMenuAbove?.showAbove}
          showContextMenuLeft={showContextMenuLeft?.showLeft}
        >
          <NamespaceContainer>
            <Typography variant="subtitle2" color={"rgb(48, 101, 198)"}>
              {isListView ? namespace : groupName}
            </Typography>
          </NamespaceContainer>
          <InfoContainer>
            <div className="flex justify-between items-center w-full">
              <Typography variant="subtitle2" fontSize={"12px"}>
                KubeArmor Security Posture (
                {networkSwitchEnabled || processSwitchEnabled ? "Custom" : "Default"})
              </Typography>

              {!isResetable && (
                <Typography
                  variant="subtitle2"
                  fontSize={"12px"}
                  color={"rgb(48, 101, 198)"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setIsEditable(!isEditable);
                    setIsResetable(!isResetable);
                  }}
                >
                  <Edit width={15} height={15} color={"rgb(48, 101, 198)"} />
                  Edit
                </Typography>
              )}

              {isResetable && (
                <Typography
                  variant="subtitle2"
                  fontSize={"12px"}
                  color={"rgb(48, 101, 198)"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer"
                  }}
                  onClick={() => handleReset()}
                >
                  <ReplayIcon width={"15px"} height={"15px"} color={"rgb(48, 101, 198)"} />
                  Reset
                </Typography>
              )}
            </div>
            <NetworkProcessContainer>
              <div className="flex justify-center items-center gap-2 w-full">
                {isEditable && (
                  <Switch
                    color="warning"
                    // checked={processSwitchEnabled}
                    value={processSwitchEnabled}
                    onClick={() => {
                      setProcessSwitchEnabled(!processSwitchEnabled);
                      handleEditReset("file");
                    }}
                    // onChange={() => setProcessSwitchEnabled(!processSwitchEnabled)}
                  />
                )}
                <Typography variant="subtitle2" fontSize={"12px"} sx={{ marginLeft: "-10px" }}>
                  Process / File
                </Typography>
                <Chip
                  icon={<CircleIcon sx={{ fontSize: "5px" }} />}
                  label={processSwitchEnabled ? "Block" : "Audit"}
                  sx={{
                    backgroundColor: `${
                      processSwitchEnabled ? "rgba(253,232,232,0.9)" : "rgba(254,236,220,0.6)"
                    }`,
                    color: `${processSwitchEnabled ? "rgb(180, 35, 24)" : "rgb(181, 71, 8)"}`
                  }}
                />
              </div>
              <div className="flex justify-center items-center gap-2 w-full">
                {isEditable && (
                  <Switch
                    value={networkSwitchEnabled}
                    color="warning"
                    onClick={e => {
                      setNetworkSwitchEnabled(!networkSwitchEnabled);
                      handleEditReset("network");
                    }}
                    // onChange={() => setNetworkSwitchEnabled(!networkSwitchEnabled)}
                  />
                )}
                <Typography variant="subtitle2" fontSize={"12px"}>
                  Network
                </Typography>
                <Chip
                  icon={<CircleIcon sx={{ fontSize: "5px" }} />}
                  label={networkSwitchEnabled ? "Block" : "Audit"}
                  sx={{
                    backgroundColor: `${
                      networkSwitchEnabled ? "rgba(253,232,232,0.9)" : "rgba(254,236,220,0.6)"
                    }`,
                    color: `${networkSwitchEnabled ? "rgb(180, 35, 24)" : "rgb(181, 71, 8)"}`
                  }}
                />
              </div>
            </NetworkProcessContainer>
          </InfoContainer>
        </Container>
      </ClickAwayListener>
    </div>
  );
}

const Container = styled.div`
  ${props => {
    return props.showOnLeft ? "left : 10px;" : "right : 10px;";
  }};
  min-width: 360px;
  max-width: 500px;
  background-color: white;
  z-index: 10;
  border-radius: 10px;
  border-top-width: 5px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px,
    rgb(0 0 0 / 12%) 0px 1px 5px 0px;
`;

const NamespaceContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 8px;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px;
  flex-direction: column;
  margin-bottom: 10px;
`;

const NetworkProcessContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  gap: 5px;
`;
