import React from "react";

export const NetworkInfo = () => {
  return (
    <>
      <h3 className="font-semibold my-2">Network Rule</h3>
      <p className="opacity-75 my-2">
        In the file section, there is currently one match type: matchProtocols. You can define
        specific protocols among TCP, UDP, and ICMP.
      </p>
      <p className="opacity-75 my-2">
        <b>Severity:</b> The severity part is somewhat important. You can specify the severity of a
        given policy from 1 to 10. This severity will appear in alerts when policy violations
        happen.
      </p>
      <p className="opacity-75 my-2">
        <b>Action:</b> The action could be Allow, Audit, or Block. Security policies would be
        handled in a blacklist manner or a whitelist manner according to the action. Thus, you need
        to define the action carefully. In the case of the Audit action, we can use this action for
        policy verification before applying a security policy with the Block action.
      </p>
    </>
  );
};
