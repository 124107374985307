import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Title, Input, Button, ContentContainer, Select, ErrorText } from "components/simple";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useForm, Controller } from "react-hook-form";
import SplunkIcon from "../../assets/img/splunk.svg";
import { Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { helpSplunk } from "screens/ChannelIntegration/constants";
import { useEditSplunkChannel } from "screens/ChannelIntegration/services";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import DeleteModal from "./DeleteModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getAccuknoxWorkspace } from "api/api";
import {
  useDeleteChannel,
  useEditSplunkIntegration,
  useSplunkIntegration
} from "store/entities/channels/mutation";

function EditSplunkIntegration() {
  const [integrationName, setIntegrationName] = useState("");
  const [splunkUrl, setSplunkUrl] = useState("");
  const [token, setToken] = useState("");
  const [index, setIndex] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [source, setSource] = useState("");
  const [splunkType, setSplunkType] = useState("Splunk enterprise");
  const [disableSave, setDisableSave] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid }
  } = useForm({ mode: "onChange" });
  const history = useHistory();
  const location = useLocation();
  const workspaceVal = getAccuknoxWorkspace();
  // edit stuffs
  const decryptSite = location?.state?.url?.split(",") ?? "";
  const decryptToken = location?.state?.Token?.split(",") ?? "";
  const splunk_Url = CryptoJS.AES.decrypt(decryptSite[0] ?? "", decryptSite[1] ?? "");
  const splunkToken = CryptoJS.AES.decrypt(decryptToken[0] ?? "", decryptToken[1] ?? "");

  const isNotUndefined = val => typeof val !== "undefined";
  const IntegrationName = location?.state?.IntegrationName;
  const WebHookUrl = splunk_Url.toString(CryptoJS.enc.Utf8);
  const Index = location?.state?.Index;
  const Source = location?.state?.Source;
  const SourceType = location?.state?.SourceType;
  const Token = splunkToken.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (integrationName || splunkUrl || token || source || index || sourceType) {
      setValue("integrationName", integrationName);
      setValue("splunkUrl", splunkUrl);
      setValue("tokenField", token);
      setValue("sourceName", source);
      setValue("indexName", index);
      setValue("sourceType", sourceType);
    }
  }, [integrationName, splunkUrl, token, index, sourceType]);

  useEffect(() => {
    setIntegrationName(IntegrationName);
    setSplunkUrl(WebHookUrl);
    setToken(Token);
    setSource(Source);
    setIndex(Index);
    setSourceType(SourceType);
  }, []);

  let shouldUpdate =
    (isNotUndefined(integrationName) ||
      isNotUndefined(splunkUrl) ||
      isNotUndefined(token) ||
      isNotUndefined(source) ||
      isNotUndefined(index) ||
      isNotUndefined(sourceType)) &&
    (integrationName !== IntegrationName ||
      splunkUrl !== WebHookUrl ||
      token !== Token ||
      source !== Source ||
      index !== Index ||
      sourceType !== SourceType);

  useEffect(() => {
    shouldUpdate =
      integrationName !== IntegrationName ||
      splunkUrl !== WebHookUrl ||
      token !== Token ||
      source !== Source ||
      index !== Index ||
      sourceType !== SourceType;
  }, [sourceType, index, token, source, integrationName, splunkUrl]);
  const splunkTest = useSplunkIntegration(data => {
    if (data?.response == "Success") {
      dispatch(addNotification({ msg: "Splunk Test Successful!", type: "success" }));
      setDisableSave(true);
    } else {
      setDisableSave(false);
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });

  const handleTest = async e => {
    try {
      const Secretkey = (Math.random() + 1).toString(36).substring(7);
      const EncryptedURL = CryptoJS.AES.encrypt(splunkUrl, Secretkey).toString();
      const splunk_url = EncryptedURL.concat(",", Secretkey);
      const EncryptedToken = CryptoJS.AES.encrypt(token, Secretkey).toString();
      const splunk_token = EncryptedToken.concat(",", Secretkey);
      const channelId = 2;
      const splunk_source = source;
      const splunk_sourceType = sourceType;
      const source_index = index;
      const option = "test";
      splunkTest?.mutate({
        channelId,
        integrationName,
        splunk_url,
        splunk_token,
        splunk_source,
        splunk_sourceType,
        source_index,
        option
      });
    } catch (err) {
      // console.log(err)
    }
  };

  const splunkIntegration = useEditSplunkIntegration(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Splunk Updated Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });
  const onSubmit = async () => {
    try {
      const Secretkey = (Math.random() + 1).toString(36).substring(7);
      const EncryptedURL = CryptoJS.AES.encrypt(splunkUrl, Secretkey).toString();
      const splunk_url = EncryptedURL.concat(",", Secretkey);
      const EncryptedToken = CryptoJS.AES.encrypt(token, Secretkey).toString();
      const splunk_token = EncryptedToken.concat(",", Secretkey);
      const channelId = 2;
      const splunk_source = source;
      const splunk_sourceType = sourceType;
      const source_index = index;
      const option = "update";
      const ID = location?.state?.id;
      splunkIntegration.mutate({
        ID,
        channelId,
        integrationName,
        splunk_url,
        splunk_token,
        splunk_source,
        splunk_sourceType,
        source_index,
        option
      });
    } catch (err) {
      // console.log(err);
    }
  };
  const closeHandler = () => {
    setIsOpen(false);
  };
  const deleteSplunkChannel = useDeleteChannel(data => {
    history.push("/settings/integrations/list");
    dispatch(addNotification({ msg: "Status: Deleted Splunk Integration", type: "success" }));
  });
  const handleDelete = async () => {
    try {
      const ID = location?.state?.id;
      const channeId = 2;
      deleteSplunkChannel.mutate({
        channeId,
        ID
      });
    } catch (err) {
      // console.log(err)
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <DeleteModal
        isModalOpen={isOpen}
        onCloseModal={() => closeHandler()}
        onSubmit={() => handleDelete()}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
          <div className="flex gap-1">
            <img src={SplunkIcon} alt="icon" />
            <Title>Splunk</Title>
          </div>
          <div className="flex justify-center items-center gap-5">
            <div
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => setIsOpen(true)}
            >
              <DeleteOutlineIcon />
              Delete Channel
            </div>
            <div
              onClick={() => window.open(helpSplunk, "_blank", "noopener,noreferrer")}
              className="flex justify-center items-center text-blue-700 font-medium cursor-pointer"
            >
              Help <HelpOutlineIcon className={styles.help_icon} />
            </div>
          </div>
        </div>
        <div className="py-5">
          <div className="flex flex-row gap-5">
            <div className="w-3/6">
              <Controller
                name="integrationName"
                {...register("integrationName", {
                  required: "Integration Name Required",
                  pattern: {
                    value: 20,
                    message: "Maximum length for this field is 20 characters"
                  }
                })}
                defaultValue=""
                control={control}
                render={({ field: { onChange }, value, ref }) => (
                  <Input
                    name="integrationName"
                    label="Integration Name"
                    placeholder="Enter the Integration Name"
                    required
                    value={integrationName}
                    onChange={evt => {
                      onChange(evt.target.value);
                      setIntegrationName(evt.target.value);
                    }}
                  />
                )}
              />
              <div className="m-0.5">
                <ErrorText errorMsg={errors.integrationName && errors.integrationName.message} />
              </div>
            </div>
            <div className="w-full">
              <Controller
                name="splunkUrl"
                {...register("splunkUrl", {
                  required: "Invalid Splunk URL, Please enter a valid URL",
                  pattern: {
                    value: /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/,
                    message: "Enter valid Url."
                  }
                })}
                defaultValue=""
                control={control}
                render={({ field: { onChange }, value, ref }) => (
                  <Input
                    name="splunkUrl"
                    label="Splunk HTTP event collector URL"
                    placeholder="https://mysplunkserver.example.com:8086/server/collector"
                    required
                    value={splunkUrl}
                    onChange={evt => {
                      onChange(evt.target.value);
                      setSplunkUrl(evt.target.value);
                    }}
                  />
                )}
              />
              <div className="m-0.5">
                <ErrorText errorMsg={errors.splunkUrl && errors.splunkUrl.message} />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-0 pb-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
          <div className="py-5 flex flex-row gap-5">
            <div className="w-3/6">
              <Controller
                name="indexName"
                {...register("indexName", {
                  required: "Index Required",
                  pattern: {
                    value: 20,
                    message: "Maximum length for this field is 20 characters"
                  }
                })}
                defaultValue=""
                control={control}
                render={({ field: { onChange }, value, ref }) => (
                  <Input
                    name="indexName"
                    label="Index"
                    placeholder="Enter the Index"
                    required
                    value={index}
                    onChange={evt => {
                      onChange(evt.target.value);
                      setIndex(evt.target.value);
                    }}
                  />
                )}
              />
              <div className="m-0.5">
                <ErrorText errorMsg={errors.indexName && errors.indexName.message} />
              </div>
            </div>
            <div className="w-full">
              <Controller
                name="tokenField"
                {...register("tokenField", {
                  required: "Token Required",
                  maxLength: {
                    value: 200,
                    message: "Maximum length for this field is 200 characters"
                  },
                  pattern: {
                    value: /^$|^\S+.*/,
                    message: "Empty spaces at the beginning is not accepted"
                  }
                })}
                defaultValue=""
                control={control}
                render={({ field: { onChange }, value, ref }) => (
                  <Input
                    name="tokenField"
                    label="Token"
                    placeholder="12345678-1234-1234-5678"
                    required
                    value={token}
                    onChange={evt => {
                      onChange(evt.target.value);
                      setToken(evt.target.value);
                    }}
                  />
                )}
              />
              <div className="m-0.5">
                {errors.tokenField && <ErrorText errorMsg={errors.tokenField.message} />}
              </div>
            </div>
          </div>
          <div className="pt-0 pb-5">
            <div className=" py-5 flex flex-row gap-5">
              <div className="w-1/3">
                <Controller
                  name="sourceName"
                  {...register("sourceName", {
                    required: "Source Required",
                    pattern: {
                      value: 20,
                      message: "Maximum length for this field is 20 characters"
                    }
                  })}
                  defaultValue=""
                  control={control}
                  render={({ field: { onChange }, value, ref }) => (
                    <Input
                      name="sourceName"
                      label="Source"
                      placeholder="Enter the source of Log"
                      required
                      value={source}
                      onChange={evt => {
                        onChange(evt.target.value);
                        setSource(evt.target.value);
                      }}
                    />
                  )}
                />
                <div className="m-0.5">
                  <ErrorText errorMsg={errors.sourceName && errors.sourceName.message} />
                </div>
              </div>
              <div className="w-1/3">
                <Controller
                  name="sourceType"
                  {...register("sourceType", {
                    required: false,
                    maxLength: {
                      value: 20,
                      message: "Maximum length for this field is 20 characters"
                    },
                    pattern: {
                      value: /^$|^\S+.*/,
                      message: "Empty spaces at the beginning is not accepted"
                    }
                  })}
                  defaultValue=""
                  control={control}
                  render={({ field: { onChange }, value, ref }) => (
                    <Input
                      name="sourceType"
                      label="Source Type"
                      placeholder="Enter the Source Type"
                      value={sourceType}
                      onChange={evt => {
                        onChange(evt.target.value);
                        setSourceType(evt.target.value);
                      }}
                    />
                  )}
                />
                <div className="m-0.5">
                  <ErrorText errorMsg={errors.sourceType && errors.sourceType.message} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row justify-between pt-6 pb-4">
          <Button onClick={handleSubmit(handleTest)}>Test</Button>
          <div className="flex">
            <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button onClick={handleSubmit(onSubmit)} disabled={!shouldUpdate} type="button">
              Save
            </Button>
          </div>
        </div>
      </form>
    </ContentContainer>
  );
}

export default EditSplunkIntegration;
