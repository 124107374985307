export const SET_PARAMS_DATALISTS = "SET_PARAMS_DATALISTS";
export const UPD_PARAMS_DATALISTS = "UPD_PARAMS_DATALISTS";

// Change GroupByDataType
export const CHANGE_GROUP_BY_DATA_TYPE = "CHANGE_GROUP_BY_DATA_TYPE";

// SET PANEL STATE
export const SET_PANEL_STATE = "SET_PANEL_STATE";

// Get DATALISTS
export const GET_DATALISTS = "GET_DATALISTS_REQUEST";
export const GET_DATALISTS_SUCCESS = "GET_DATALISTS_SUCCESS";
export const GET_DATALISTS_FAILED = "GET_DATALISTS_FAILED";

// Get DATALISTS _MINI
export const GET_DATALISTS_DATATYPE = "GET_DATALISTS_DATATYPE_REQUEST";
export const GET_DATALISTS_DATATYPE_SUCCESS = "GET_DATALISTS_DATATYPE_SUCCESS";
export const GET_DATALISTS_DATATYPE_FAILED = "GET_DATALISTS_DATATYPE_FAILED";

// Get one datalists
export const GET_DATALIST = "GET_DATALIST_REQUEST";
export const GET_DATALIST_SUCCESS = "GET_DATALIST_SUCCESS";
export const GET_DATALIST_FAILED = "GET_DATALIST_FAILED";

// Edit datalists
export const EDIT_DATALIST = "EDIT_DATALIST_REQUEST";
export const EDIT_DATALIST_SUCCESS = "EDIT_DATALIST_SUCCESS";
export const EDIT_DATALIST_FAILED = "EDIT_DATALIST_FAILED";

// Get datalists risk factors (options)
export const GET_DATALIST_RISK_FACTORS = "GET_DATALIST_RISK_FACTORS_REQUEST";
export const GET_DATALIST_RISK_FACTORS_SUCCESS = "GET_DATALIST_RISK_FACTORS_SUCCESS";
export const GET_DATALIST_RISK_FACTORS_FAILED = "GET_DATALIST_RISK_FACTORS_FAILED";

// Get finding statuses (options)
export const GET_FINDING_STATUSES = "GET_FINDING_STATUSES_REQUEST";
export const GET_FINDING_STATUSES_SUCCESS = "GET_FINDING_STATUSES_SUCCESS";
export const GET_FINDING_STATUSES_FAILED = "GET_FINDING_STATUSES_FAILED";

// Get datalists history
export const GET_DATALIST_HISTORY = "GET_DATALIST_HISTORY_REQUEST";
export const GET_DATALIST_HISTORY_SUCCESS = "GET_DATALIST_HISTORY_SUCCESS";
export const GET_DATALIST_HISTORY_FAILED = "GET_DATALIST_HISTORY_FAILED";

// Get datalists assets
export const GET_DATALIST_ASSETS = "GET_DATALIST_ASSETS_REQUEST";
export const GET_DATALIST_ASSETS_SUCCESS = "GET_DATALIST_ASSETS_SUCCESS";
export const GET_DATALIST_ASSETS_FAILED = "GET_DATALIST_ASSETS_FAILED";

// GET datalists configs
export const GET_DATALIST_CONFIGS = "GET_DATALIST_CONFIGS_REQUEST";
export const GET_DATALIST_CONFIGS_SUCCESS = "GET_DATALIST_CONFIGS_SUCCESS";
export const GET_DATALIST_CONFIGS_FAILED = "GET_DATALIST_CONFIGS_FAILED";

export const GET_DATALIST_CONFIGS_FILTERS_DATA = "GET_DATALIST_CONFIGS_FILTERS_DATA_REQUEST";
export const GET_DATALIST_CONFIGS_FILTERS_DATA_SUCCESS =
  "GET_DATALIST_CONFIGS_FILTERS_DATA_SUCCESS";
export const GET_DATALIST_CONFIGS_FILTERS_DATA_FAILED = "GET_DATALIST_CONFIGS_FILTERS_DATA_FAILED";

// EDIT datalists configs
export const EDIT_DATALIST_CONFIGS = "EDIT_DATALIST_CONFIGS_REQUEST";
export const EDIT_DATALIST_CONFIGS_SUCCESS = "EDIT_DATALIST_CONFIGS_SUCCESS";
export const EDIT_DATALIST_CONFIGS_FAILED = "EDIT_DATALIST_CONFIGS_FAILED";

// POST datalists configs
export const POST_DATALIST_CONFIGS = "POST_DATALIST_CONFIGS_REQUEST";
export const POST_DATALIST_CONFIGS_SUCCESS = "POST_DATALIST_CONFIGS_SUCCESS";
export const POST_DATALIST_CONFIGS_FAILED = "POST_DATALIST_CONFIGS_FAILED";

// DELETE datalists configs
export const DELETE_DATALIST_CONFIGS = "DELETE_DATALIST_CONFIGS_REQUEST";
export const DELETE_DATALIST_CONFIGS_SUCCESS = "DELETE_DATALIST_CONFIGS_SUCCESS";
export const DELETE_DATALIST_CONFIGS_FAILED = "DELETE_DATALIST_CONFIGS_FAILED";

// Edit finding
export const EDIT_FINDING = "EDIT_FINDING_REQUEST";
export const EDIT_FINDING_SUCCESS = "EDIT_FINDING_SUCCESS";
export const EDIT_FINDING_FAILED = "EDIT_FINDING_FAILED";

// MODAL
// Create Target
export const TARGET_MODAL = "TARGET_MODAL_DATALIST";
// Create Group
export const GROUP_MODAL = "GROUP_MODAL_DATALIST";

// ACTIONS

// GET GROUP
export const GET_TO_GROUP = "GET_TO_GROUP_DATALIST_REQUEST";
export const GET_TO_GROUP_SUCCESS = "GET_TO_GROUP_DATALIST_SUCCESS";
export const GET_TO_GROUP_FAILED = "GET_TO_GROUP_DATALIST_FAILED";

// ADD GROUP
export const ADD_TO_GROUP = "ADD_TO_GROUP_DATALIST_REQUEST";
export const ADD_TO_GROUP_SUCCESS = "ADD_TO_GROUP_DATALIST_SUCCESS";
export const ADD_TO_GROUP_FAILED = "ADD_TO_GROUP_DATALIST_FAILED";

// GET TARGET
export const GET_TO_TARGET = "GET_TO_TARGET_DATALIST_REQUEST";
export const GET_TO_TARGET_SUCCESS = "GET_TO_TARGET_DATALIST_SUCCESS";
export const GET_TO_TARGET_FAILED = "GET_TO_TARGET_DATALIST_FAILED";

// GET TARGET TYPES
export const GET_TO_TARGET_TYPES = "GET_TO_TARGET_TYPES_DATALIST_REQUEST";
export const GET_TO_TARGET__TYPES_SUCCESS = "GET_TO_TARGET_TYPES_DATALIST_SUCCESS";
export const GET_TO_TARGET__TYPES_FAILED = "GET_TO_TARGET_TYPES_DATALIST_FAILED";

// ADD TARGET
export const ADD_TO_TARGET = "ADD_TO_TARGET_DATALIST_REQUEST";
export const ADD_TO_TARGET_SUCCESS = "ADD_TO_TARGET_DATALIST_SUCCESS";
export const ADD_TO_TARGET_FAILED = "ADD_TO_TARGET_DATALIST_FAILED";

// EXPORT DATALIST
export const EXPORT_DATALIST = "EXPORT_DATALIST_REQUEST";
export const EXPORT_DATALIST_SUCCESS = "EXPORT_DATALIST_SUCCESS";
export const EXPORT_DATALIST_FAILED = "EXPORT_DATALIST_FAILED";
