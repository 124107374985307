/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, patch, remove } from "api/base";
import { formatDate } from "helper/DateLib";

const generatorFilterUrl = obj => {
  if (!obj) {
    return "";
  }
  let url = "";
  Object.entries(obj).forEach(([key, val]) => {
    if (key === "last_seen" || key === "date_discovered") {
      if (key === "last_seen") {
        const last_seen_after = formatDate(val[0]) || "";
        const last_seen_before = formatDate(val[1]) || "";
        url += `&last_seen_before=${last_seen_before}&last_seen_after=${last_seen_after}`;
      }
      if (key === "date_discovered") {
        const date_discovered_after = formatDate(val[0]) || "";
        const date_discovered_before = formatDate(val[1]) || "";
        url += `&date_discovered_before=${date_discovered_before}&date_discovered_after=${date_discovered_after}`;
      }
    } else {
      url += `&${key}=${val?.value}`;
    }
  });
  return url;
};

export const getDatalists = fields => {
  const { page, pageSize, ordering, search, params, filters, groupBy } = fields;

  let url = "";
  if (params?.asset?.value || params?.asset?.id) {
    url += `&asset_id=${params?.asset?.value || params?.asset?.id}`;
  }
  if (params?.target?.value || params?.target?.id) {
    url += `&target_id=${params?.target?.value || params?.target?.id}`;
  }
  if (params?.data_type) {
    url += `&data_type=${params?.data_type}`;
  }

  if (groupBy?.value) {
    url += `&group_by=${groupBy?.value}`;
  }

  const filterUrl = generatorFilterUrl(filters);

  return get(
    `${API}/data-lists?page=${page + 1 || 1}&ordering=${ordering}&search=${search ??
      ""}&page_size=${pageSize}${url}${filterUrl}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getSubDatalistsTable = fields => {
  const { temporaryData, filters, groupBy, groupByValue, search, pageSize, page, order } = fields;

  let url = "";
  if (temporaryData?.asset?.value || temporaryData?.asset?.id) {
    url += `&asset_id=${temporaryData?.asset?.value || temporaryData?.asset?.id}`;
  }
  if (temporaryData?.target?.value || temporaryData?.target?.id) {
    url += `&target_id=${temporaryData?.target?.value || temporaryData?.target?.id}`;
  }
  if (temporaryData?.data_type) {
    url += `&data_type=${temporaryData?.data_type}`;
  }

  if (groupByValue) {
    url += `&${groupBy?.value}=${groupByValue}`;
  }
  if (search) {
    url += `&search=${search ?? ""}`;
  }

  const filterUrl = generatorFilterUrl(filters);

  return get(
    `${API}/data-lists/collapsed-data?group_by=${groupBy?.value}${url}${filterUrl}&page_size=${pageSize}&page=${page}&ordering=${order}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getDataListsDataType = () => {
  return get(`${API}/data-lists/data-types`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getDatalistConfigs = () => {
  return get(`${API}/data-lists-config`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getDatalistConfigsFiltersData = fields => {
  const data_type = fields?.data_type?.value || "";

  return get(`${API}/data-lists-config/filters-data?data_type=${data_type}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const editDatalistConfigs = fields => {
  const { id, ...rest } = fields;
  return patch(`${API}/data-lists-config/${id}`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const postDatalistConfigs = fields => {
  return post(`${API}/data-lists-config`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteDatalistConfigs = ({ id }) => {
  return remove(
    `${API}/data-lists-config/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getDynamicSelect = async fields => {
  const params = fields?.params;
  const field = fields?.field;
  const search = fields?.search || "";
  const page = fields?.page || "";

  let url = "";

  if (params?.target?.value || params?.target?.id) {
    url += `&target_id=${params?.target?.value || params?.target?.id}`;
  }
  if (params?.asset?.value || params?.asset?.id) {
    url += `&asset_id=${params?.asset?.value || params?.asset?.id}`;
  }
  if (params?.data_type) {
    url += `&data_type=${params?.data_type}`;
  }
  if (search) {
    url += `&field-search=${search ?? ""}`;
  } else {
    url += `&page=${page}`;
  }

  return get(`${API}/data-lists/filter-options?field=${field}${url}`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};

// ACTIONS
export const getGroups = () => {
  return get(`${API}/groups`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
export const addGroup = fields => {
  return post(`${API}/data-lists/add-to-group`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getTargets = () => {
  return get(`${API}/targets`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTargetsTypes = () => {
  return get(`${API}/targets/data-types`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getOneTargetTypes = id => {
  return get(`${API}/target-types/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};

export const addTarget = fields => {
  return post(`${API}/data-lists/add-to-target`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const exportDatalist = ({ fields, displayFields, ids }) => {
  const { ordering, search, params, filters, groupBy } = fields;
  let url = "";

  if (params?.asset?.value || params?.asset?.id) {
    url += `&asset_id=${params?.asset?.value || params?.asset?.id}`;
  }
  if (params?.target?.value || params?.target?.id) {
    url += `&target_id=${params?.target?.value || params?.target?.id}`;
  }

  if (params?.data_type) {
    url += `&data_type=${params?.data_type}`;
  }
  if (groupBy?.value) {
    url += `&group_by=${groupBy?.value}`;
  }
  if (displayFields?.length) {
    let res = "";
    displayFields?.forEach(item => {
      res += `&display_fields=${item}`;
    });
    url += res;
  }

  const filterUrl = generatorFilterUrl(filters);

  if (ids?.length) {
    return post(
      `${API}/data-lists/export`,
      JSON.stringify({
        ids,
        display_fields: displayFields
      }),
      {
        ...contentTypeJson(),
        ...authorization(),
        ...authorizationTenant()
      }
    ).then(res => res.blob());
  }

  return get(
    `${API}/data-lists/export?ordering=${ordering}&search=${search ?? ""}${url}${filterUrl}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  ).then(res => res.blob());
};

export const getOneDataList = id => {
  return get(`${API}/data-lists//${id}`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};
