import React, { useState, useEffect } from "react";
import { Table } from "components/complex";
import { ContentContainer, Search, Select, Title } from "components/simple";
import Loader from "components/simple/Loader";
import styles from "./styles.module.scss";
import BasicModal from "components/simple/Modal/Modal";
import { Alert, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useClustersQuery, useNamespacesQuery } from "store/entities/filters/queries";
import { useGetAutoDiscoveredPoliciesQuery } from "store/entities/policies/queries";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedClusters,
  getSelectedInfraType,
  getSelectedNamespaces,
  getSelectedPolicyType,
  setSelectedClusters,
  setSelectedInfraType,
  setSelectedNamespace,
  setSelectedPolicyStatus,
  setSelectedPolicyType,
  setSelectedCategory
} from "store/entities/filters/slice";
import SelectorLabel from "components/simple/SelectorLabel";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import {
  useApplyPolicyMutation,
  useGetYamlMutation,
  useIgnorePolicyMutation
} from "store/entities/policies/mutations";
import { navigate } from "helper/history";
import { routes } from "router";
import CustomSelect from "components/simple/CustomSelect";
import { getUserId } from "store/entities/auth/utils";

const AutoDiscoveredPolicies = () => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const { data: clusters = {} } = useClustersQuery();
  const selectedClusters = useSelector(getSelectedClusters);

  const { data: namespaces = {} } = useNamespacesQuery(selectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);

  const selectedInfraType = useSelector(getSelectedInfraType);
  const selectedPolicyType = useSelector(getSelectedPolicyType);

  const [selectionModal, setSelectionModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const [pagePrev, setPagePrev] = useState(0); // initial page starts from 0 and not from 1
  const [pageNext, setPageNext] = useState(0 + pageSize);
  const [actionType, setActionType] = useState([]);
  const [policyID, setPolicyID] = useState([]);
  const [updatedBy, setUpdatedBy] = useState();
  const {
    data: autodiscovredPolicies = {},
    isLoading: autodiscovredpoliciesLoading
  } = useGetAutoDiscoveredPoliciesQuery(pagePrev, pageNext, pageSize);

  const onPageChange = newPage => {
    setPagePrev(newPage * pageSize);
    setPageNext((newPage + 1) * pageSize);
  };

  const handleFilter = (value, filterName) => {
    if (filterName === "infra_type") {
      dispatch(setSelectedInfraType(value));
    } else if (filterName === "cluster") {
      dispatch(setSelectedClusters(value));
    } else if (filterName === "namespace") {
      dispatch(setSelectedNamespace(value));
    } else if (filterName === "policy_status") {
      dispatch(setSelectedPolicyStatus(value));
    } else if (filterName === "policy_type") {
      dispatch(setSelectedPolicyType(value));
    } else if (filterName === "category") {
      dispatch(setSelectedCategory(value));
    }
  };
  const tableConfig = {
    hideSelectedRowCount: true,
    checkboxVisible: true
  };
  const { mutate: yamlData, data, isLoading } = useGetYamlMutation();
  const { mutate: applyPolicies, isError: isErrorAddPolicy } = useApplyPolicyMutation();
  const { mutate: ignorePolicy, isError: isErrorIgnorePolicy } = useIgnorePolicyMutation();

  const handleYamlClick = rowId => {
    yamlData(rowId.row.id);
    setSelectionModal(true);
  };

  const history = useHistory();

  const columns = [
    {
      headerName: "Policy Name",
      field: "policy_name",
      minWidth: 220,
      headerClassName: "super-app-theme--header"
    },
    {
      headerName: "Type",
      field: "policy_type",
      minWidth: 150,
      headerClassName: "super-app-theme--header"
    },
    {
      headerName: "Clusters",
      field: "cluster",
      minWidth: 160,
      renderCell: params => <div>{params?.value?.name}</div>,
      headerClassName: "super-app-theme--header"
    },
    {
      headerName: "Namespace",
      field: "namespace",
      minWidth: 180,
      renderCell: params => <div>{params?.value?.name}</div>,
      headerClassName: "super-app-theme--header"
    },
    {
      headerName: "YAML",
      field: "VYAML",
      minWidth: 130,
      renderCell: params => (
        <div
          className="text-sky-600 cursor-pointer underline"
          onClick={e => {
            e.stopPropagation();
            handleYamlClick(params);
          }}
        >
          View YAML
        </div>
      ),
      headerClassName: "super-app-theme--header"
    },
    {
      headerName: "Category",
      field: "category",
      minWidth: 120,
      headerClassName: "super-app-theme--header"
    },
    {
      headerName: "Selector Labels",
      field: "selector_labels",
      minWidth: 240,
      renderCell: params =>
        params.value?.length > 0 ? (
          <SelectorLabel labels={params.value} limit={15} />
        ) : (
          <div>None</div>
        ),
      headerClassName: "super-app-theme--header"
    }
  ];

  const updateModelView = () => {
    setSelectionModal(false);
    setConfirmationModal(false);
  };

  const handleChecked = checkedItem => {
    if (checkedItem.length >= 1) {
      setShowButton(true);
      setPolicyID(checkedItem);
    } else {
      setShowButton(false);
      setPolicyID(checkedItem);
    }
  };

  const toPolicyDetails = (policyId, cluster, namespace) => {
    navigate(routes.policyDetails, { policy_id: policyId, cluster, namespace });
  };

  const onCellClick = p => {
    const policyId = [p?.id];
    const updated = p?.row?.updated_by;
    setPolicyID(policyId);
    setUpdatedBy(updated);
    const { namespace, cluster } = p?.row;
    toPolicyDetails(p?.id, cluster, namespace);
  };
  const actionHandler = value => {
    setActionType(value.label);
  };
  useEffect(() => {
    const obj = {
      policy_id: policyID,
      // updated_by: updatedBy
      updated_by: getUserId()

    };
    if (actionType === "Apply") {
      applyPolicies(obj);
      setConfirmationModal(true);
    } else if (actionType === "Ignore") {
      ignorePolicy(obj);
    }
  }, [yamlData, actionType]);

  return (
    <>
      {isErrorAddPolicy || isErrorIgnorePolicy ? (
        <div>
          <BasicModal isOpen={confirmationModal} toggle={updateModelView} width="50vw">
            <div className="flex justify-center text-xl text-red-700 text-7xl">
              <NewReleasesIcon fontSize="48px" color="#fff" />
            </div>

            <>
              <div className="text-center px-5">
                <p className="mt-3 text-xl font-medium">
                  Error {isErrorAddPolicy ? "Adding Policies" : "Ignoring Policies"}
                </p>
              </div>

              <hr style={{ marginBottom: 16, marginTop: 25 }} />

              <div className="body flex flex-col px-5">
                <div className="flex justify-center">
                  <Button variant="outlined" onClick={() => setConfirmationModal(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </>
          </BasicModal>
        </div>
      ) : (
        <div>
          <BasicModal isOpen={confirmationModal} toggle={updateModelView} width="50vw">
            <div className="flex justify-center text-xl text-green-700 text-7xl">
              <VerifiedIcon fontSize="48px" color="#fff" />
            </div>

            <>
              <div className="text-center px-5">
                <p className="mt-3 text-xl font-medium">Policies applied successfully</p>
                <p className="text-sm">
                  Applied policies are yet to be reviewed and approved. To view click{" "}
                  <strong>Go to Pending Approval</strong>
                </p>
              </div>

              <hr style={{ marginBottom: 16, marginTop: 25 }} />

              <div className="body flex flex-col px-5">
                <div className="flex justify-between">
                  <Button variant="outlined" onClick={() => setConfirmationModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      history.push("/runtime security/policies-pending-approval");
                      setConfirmationModal(false);
                    }}
                  >
                    Go to Pending Approval
                  </Button>
                </div>
              </div>
            </>
          </BasicModal>
        </div>
      )}
      <BasicModal isOpen={selectionModal} toggle={updateModelView} width="50vw">
        <div
          className={`fixed bottom-0 z-20 inset-x-0 $
            "sm:p-6 p-0" sm:inset-0 sm:flex sm:items-center sm:justify-center`}
        >
          <div
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            aria-label="yaml-view-dialog"
          >
            <div className="bg-white overflow-hidden shadow-xl transform transition-all h-auto sm:w-full">
              <div
                className="text-gray-700 p-3 pl-6 flex justify-between"
                style={{ width: "100%", background: "#F2F2F2" }}
              >
                <Typography variant="b3" color="#292F32" fontWeight="600">
                  YAML PREVIEW
                </Typography>{" "}
                <Close onClick={updateModelView} className="cursor-pointer" />
              </div>

              <div
                data-testid="yaml-data"
                style={{ height: "60vh", overflow: "auto", padding: "0px", width: "33vw" }}
              >
                <pre style={{ padding: "18px", fontSize: "14px", color: "#385723" }}>
                  {isLoading ? <Loader /> : data?.content?.Yaml}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </BasicModal>
      <ContentContainer className={styles.container}>
        <div className="flex justify-between">
          <Title>Auto Discovered Policies</Title>
          {showButton && (
            <Select
              value={actionType}
              isSearchable={false}
              placeholder="Action"
              onChange={v => actionHandler(v, "actonType")}
              options={[
                { label: "Apply", value: "Apply" },
                { label: "Ignore", value: "Ignore" }
              ]}
              containerClass={styles["action-group__filter-option"]}
              // hideSelectedOptions={false}
            />
          )}
        </div>

        <section className={styles.action}>
          <div className={styles["action-group"]}>
            <div className={styles["action-group__filter"]}>
              <Select
                value={selectedInfraType}
                onChange={v => handleFilter(v, "infra_type")}
                placeholder="Policy Kind"
                defaultValue={{ label: "K8s", value: "GKE" }}
                options={[
                  { label: "K8s", value: "GKE" }
                  // { label: "VM", value: "VM" }
                ]}
                containerClass={styles["action-group__filter-option"]}
                hideSelectedOptions={false}
              />
              <CustomSelect
                value={selectedClusters}
                onChange={v => handleFilter(v, "cluster")}
                placeholder="Cluster"
                options={clusters?.options}
                containerClass={styles["action-group__filter-option"]}
                isMulti
                hideSelectedOptions={false}
                defaultValue="Cluster"
                // allowSelectAll
              />
              <CustomSelect
                value={selectedNamespaces}
                onChange={v => handleFilter(v, "namespace")}
                placeholder="Namespace"
                options={namespaces?.options}
                containerClass={styles["action-group__filter-option"]}
                isMulti
                hideSelectedOptions={false}
              />
              <CustomSelect
                value={selectedPolicyType}
                onChange={v => handleFilter(v, "policy_type")}
                placeholder="Policy Type"
                options={[
                  { label: "KubeArmor", value: "KubeArmor" },
                  { label: "KubeArmor Host", value: "KubeArmor Host" },
                  { label: "Kubernetes Network", value: "Kubernetes Network" },
                  { label: "Cilium Network", value: "Cilium Network" }
                ]}
                containerClass={styles["action-group__filter-option"]}
                hideSelectedOptions={false}
                isMulti
              />
              <CustomSelect
                value="Category"
                onChange={v => handleFilter(v, "category")}
                placeholder="Filter by Category"
                options={[
                  { label: "Used", value: 1 },
                  { label: "Ignore", value: 2 }
                ]}
                containerClass={styles["action-group__filter-option"]}
                isMulti
                isClearable
              />
            </div>
          </div>
        </section>

        <section>
          <Table
            apiRef={apiRef}
            data={autodiscovredPolicies.table_data}
            columns={columns}
            rowCount={autodiscovredPolicies?.total_record || 0}
            page={pagePrev / pageSize}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onSortModelChange={() => {}}
            onPageChange={onPageChange}
            onPageSizeChange={newPageSize => {
              // when page size is changed we need to set pageprev and pagenext to initial
              setPageSize(newPageSize);
              setPagePrev(0 * newPageSize);
              setPageNext((0 + 1) * newPageSize);
            }}
            onSelectionModelChange={prev => handleChecked(prev)}
            selectionModal={selectionModal}
            onRowClick={(v, e) => onCellClick(v)}
            initialState={{ pinnedColumns: { left: ["__check__"] } }}
            paginationMode="server"
            loading={autodiscovredpoliciesLoading}
            {...tableConfig}
          />
        </section>
      </ContentContainer>
    </>
  );
};

export default AutoDiscoveredPolicies;
