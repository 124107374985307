import React from "react";
import { LightTooltip } from "components/simple";

const ConditionalTooltipWrap = ({ showTooltip, severity, children }) => {
  return showTooltip ? (
    <LightTooltip arrow placement="top" title={severity} key={severity}>
      {children}
    </LightTooltip>
  ) : (
    <>{children}</>
  );
};

export const StackedBar = ({
  data = {},
  dataLength,
  height = 32,
  showTooltip = true,
  showInnerLabel = true,
  severitycolor
}) => {
  return (
    <div className={`flex ${showInnerLabel ? "bg-gray-200" : ""} w-full`}>
      {Object.keys(data).map(severity => (
        <ConditionalTooltipWrap
          showTooltip={showTooltip}
          severity={severity === "informational" ? "no_issue" : severity}
          key={severity}
        >
          {showInnerLabel ? (
            <div
              style={{
                backgroundColor:
                  severitycolor[severity === "informational" ? "no_issue" : severity],
                width: `${100 / dataLength}%`,
                height: `${height}px`
              }}
              className="h-full text-white flex items-center justify-center"
            >
              <p className="m-0 text-sm">{data[severity]}</p>
            </div>
          ) : (
            <div
              style={{
                borderBottom: `8px solid ${
                  severitycolor[severity === "informational" ? "no_issue" : severity]
                }`,
                width: `${100 / dataLength}%`
              }}
              className="h-full text-black flex items-center justify-center"
            >
              {data[severity]}
            </div>
          )}
        </ConditionalTooltipWrap>
      ))}
    </div>
  );
};
