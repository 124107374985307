import React from "react";

import { Button, Input, Title, Modal, ErrorText } from "components/simple";

import styles from "./styles.module.scss";

const AddLabelModal = ({ isOpen, state, onChange, onSubmit, onClose, isLoading }) => {
  const specialChars = /[^\w\s]/gi;
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <Title className={styles.title}>Add label</Title>
      <form onSubmit={onSubmit}>
        <Input
          label="Name"
          value={state.name}
          onChange={e => onChange(e.target.value.toUpperCase(), "name")}
          containerClassName={styles.input}
          required
        />
        {specialChars.test(state?.name) && (
          <div className="relative left-2" style={{ top: "-16px" }}>
            <ErrorText errorMsg={"special characters not allowed"} />
          </div>
        )}
        <Input
          label="Filename Prefix"
          value={state.aws_prefix}
          onChange={e => onChange(e.target.value.toUpperCase(), "aws_prefix")}
          containerClassName={styles.input}
          required
        />
        {specialChars.test(state?.aws_prefix) && (
          <div className="relative left-2" style={{ top: "-16px" }}>
            <ErrorText errorMsg={"special characters not allowed"} />
          </div>
        )}
        <div className={styles.footer}>
          <Button variant="outline" onClick={onClose} className={styles.btn}>
            Back
          </Button>
          <Button
            type="submit"
            isLoading={isLoading}
            className={styles.btn}
            disabled={
              specialChars.test(state?.name) || specialChars.test(state?.aws_prefix) ? true : false
            }
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddLabelModal;
