export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// Set Notifications params
export const SET_NOTIFICATIONS_PARAMS = "SET_NOTIFICATIONS_PARAMS";

// Get Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILED = "GET_NOTIFICATIONS_FAILED";

// Get Modal Notifications
export const GET_MODAL_NOTIFICATIONS = "GET_MODAL_NOTIFICATIONS_REQUEST";
export const GET_MODAL_NOTIFICATIONS_SUCCESS = "GET_MODAL_NOTIFICATIONS_SUCCESS";
export const GET_MODAL_NOTIFICATIONS_FAILED = "GET_MODAL_NOTIFICATIONS_FAILED";

// Get Notification
export const GET_NOTIFICATION = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILED = "GET_NOTIFICATION_FAILED";

// Delete Notification
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILED = "DELETE_NOTIFICATION_FAILED";

// Update Notification
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILED = "UPDATE_NOTIFICATION_FAILED";
