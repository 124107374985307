/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";

import { createLoadingSelector } from "store/entities/loading/selector";

import { Table } from "components/complex";
import SubTable from "./SubTable";
import AddRowModal from "./AddRowModal";
import DetailsModal from "./DetailsModal";

import {
  useGridApiRef,
  visibleGridColumnsSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from "@mui/x-data-grid-pro";

import { ActionButton, Search, Select, CustomDetailPanelToggle } from "components/simple";

import { useSelector, useDispatch } from "react-redux";
// import { addQuery, removeQuery } from "helper/history";
import { addNotification } from "store/entities/notifications/actions";

import {
  setMonitorConfigParams,
  updMonitorConfigParams,
  getMonitorConfigTable,
  addControlMonitorConfigurationTable,
  deleteControlMonitorConfigurationTable,
  deleteMonitorRow
} from "store/entities/monitors/actions";

import {
  getMonitorConfigTableSelector,
  getGroupByListSelect
} from "store/entities/monitors/selectors";

import styles from "./styles.module.scss";

const Data = ({ id, data_type, displayFields }) => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const theme = useTheme();

  const [addRowModalOpen, setAddRowModalOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const {
    page,
    pageSize,
    groupBy,
    orderModel,
    display_fields_width,
    dict,
    data_type: dataType
  } = useSelector(state => state.monitors.monitorConfigParams);

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = React.useRef(selectionModel);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState([]);
  const [selectionModelSubTable, setSelectionModelSubTable] = useState([]); // selections for  Expend Table

  const selectTenant = useSelector(state => state.users.selectTenant);

  const isLoading = useSelector(state =>
    createLoadingSelector([getMonitorConfigTable.type])(state)
  );

  const groupByList = useSelector(st => getGroupByListSelect(st, displayFields));

  const { columns, data, total } = useSelector(state => getMonitorConfigTableSelector(state, null));

  // Next page
  const onPageChange = newPage => {
    prevSelectionModel.current = selectionModel;
    dispatch(
      setMonitorConfigParams({
        page: newPage
      })
    );
  };

  // get default request
  const getRequest = () => {
    if (id) {
      dispatch(
        setMonitorConfigParams({
          id
        })
      );
    } else {
      dispatch(
        setMonitorConfigParams({
          data_type,
          display_fields: groupByList?.map(item => item?.value)
        })
      );
    }
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant, id]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
    setDetailPanelExpandedRowIds([]); // clear expend row
    setSelectionModelSubTable([]);
  }, [page, data]);

  // Actions
  const handleAction = type => {
    if (type === "add") {
      setAddRowModalOpen(true);
      return;
    }
    if (type === "conditions") {
      setDetailsOpen({ id, title: "Conditions" });
      return;
    }

    const selectionModelSubTableRow = selectionModelSubTable
      ?.map(item => item?.selectionModel)
      ?.flat();

    if (!selectionModel?.length && !selectionModelSubTableRow?.length) {
      dispatch(addNotification({ msg: "You must select at least one row", type: "warning" }));
      return;
    }
    const groupByValue = groupBy?.value;

    const myMap = apiRef?.current?.getSelectedRows();

    const values = Array.from(myMap.values());
    const ids = groupByValue
      ? [...new Set([...values?.map(item => item?.group_ids).flat(), ...selectionModelSubTableRow])]
      : selectionModel;

    switch (type) {
      case "plus": {
        dispatch(addControlMonitorConfigurationTable({ id, ids }));
        break;
      }
      case "minus": {
        dispatch(deleteControlMonitorConfigurationTable({ id, ids }));
        break;
      }
      default:
        break;
    }
  };

  const handleSearch = v => {
    dispatch(
      setMonitorConfigParams({
        page: 0,
        search: v
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      let fieldSort = field;

      if (field === "__detail_panel_toggle__") {
        fieldSort = "group_ids";
      }
      if (field === "tags") {
        fieldSort = "has_tags";
      }
      if (field === "tickets__last_comment") {
        fieldSort = "last_comment";
      }
      if (field === "tickets__ids") {
        fieldSort = "tickets_number";
      }
      if (sort === "asc") {
        dispatch(
          setMonitorConfigParams({
            ordering: fieldSort,
            orderModel: s
          })
        );
      } else {
        dispatch(
          setMonitorConfigParams({
            ordering: `-${fieldSort}`,
            orderModel: s
          })
        );
      }
    } else {
      dispatch(
        setMonitorConfigParams({
          ordering: "",
          orderModel: s
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setMonitorConfigParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (params, event) => {
    const { field, value, id: uid } = params;
    event.stopPropagation();

    if (field === "custom" && value) {
      dispatch(deleteMonitorRow({ id: uid }));
    }
  };

  // Select Group By
  const onGroupBy = v => {
    dispatch(
      setMonitorConfigParams({
        ordering: "",
        groupBy: v,
        page: 0
      })
    );
  };

  // Expend
  const onChangeSelectionModelSubTable = (v, rowId) => {
    const res = {
      id: rowId,
      selectionModel: v
    };
    let newSelection = [];

    if (selectionModelSubTable.find(item => item?.id === rowId)) {
      newSelection = selectionModelSubTable?.map(item => {
        if (item?.id === rowId) {
          return res;
        }
        return item;
      });
    } else {
      newSelection = [...selectionModelSubTable, res];
    }

    if (selectionModel.find(item => item === rowId)) {
      const resetSelectForMainTable = selectionModel?.filter(item => item !== rowId);
      setSelectionModel(resetSelectForMainTable);
    }

    setSelectionModelSubTable(newSelection);
  };

  const [expandHeight, setExpandHeight] = useState(150);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => {
      return (
        <SubTable
          row={row}
          onCellClick={onCellClick}
          selectionModelSubTable={selectionModelSubTable}
          setExpandHeight={setExpandHeight}
          expandHeight={expandHeight}
          onChangeSelectionModelSubTable={v => onChangeSelectionModelSubTable(v, row?.id)}
        />
      );
    },
    [selectionModelSubTable, expandHeight]
  );

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    newIds => {
      setDetailPanelExpandedRowIds(newIds.slice(-1));
    },
    [apiRef]
  );

  // Expend End

  useEffect(() => {
    return apiRef.current.subscribeEvent("columnHeaderDragEnd", () => {
      const newOrder = visibleGridColumnsSelector(apiRef.current.state)
        .map(col => col.field)
        ?.filter(item => item !== "__check__" && item !== "__detail_panel_toggle__");
      dispatch(
        updMonitorConfigParams({
          display_fields: newOrder
        })
      );
    });
  }, [apiRef]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  const timerRef = React.useRef();
  const triggerChangeWidth = widthArrat => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      dispatch(
        updMonitorConfigParams({
          display_fields_width: widthArrat
        })
      );
    }, 500);
  };

  const onColumnResize = v => {
    const fields = display_fields_width;
    const prevColWidth = fields ?? [];
    const newCol = [...prevColWidth, { field: v?.colDef?.field, width: v?.width }];
    triggerChangeWidth(getUniqueListBy(newCol, "field"));
  };

  const customStyles = {
    height: 710,
    width: 1,
    "& .super-app-theme--header": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #E7E6F8"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      marginBottom: "0px"
    },
    "& .MuiDataGrid-cell": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-cell.active": {
      backgroundColor: theme.sideMenu.highlight,
      color: theme.sideMenu.textColor
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.filters}>
        <Search onSearch={handleSearch} />
        <div className={styles.filterRow}>
          <div className={styles.selectsGroup}>
            <Select
              containerClass={styles.select}
              onChange={onGroupBy}
              value={groupBy}
              placeholder="Group by"
              options={groupByList}
              isClearable
            />
          </div>
          {data_type ? null : (
            <div className={styles.buttonGroup}>
              <ActionButton type="plus" onClick={handleAction} className={styles.actionButton} />
              <ActionButton type="minus" onClick={handleAction} className={styles.actionButton} />
              <ActionButton
                type="add"
                text="Add Row"
                onClick={handleAction}
                className={styles.actionButton}
              />
              <ActionButton
                type="conditions"
                text="Open Conditions"
                onClick={handleAction}
                className={styles.actionButton}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.table}>
        <Table
          apiRef={apiRef}
          data={isLoading ? [] : data}
          onColumnResize={onColumnResize}
          columns={[
            {
              ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
              headerName: "Group ids",
              field: "__detail_panel_toggle__",
              width: 120,
              sortable: true,
              renderCell: params => {
                return (
                  <span>
                    {params?.row?.group_ids?.length || 0}
                    {params?.row?.group_ids?.length > 1 ? (
                      <CustomDetailPanelToggle {...params} />
                    ) : null}
                  </span>
                );
              }
            },
            ...columns
          ]}
          loading={isLoading}
          onSelectionModelChange={newSelectionModel => {
            if (groupBy) {
              const current = newSelectionModel.slice(-1)[0];
              const res = data?.find(item => item?.id === current);
              if (current) {
                const groupIds = res?.group_ids || [];
                if (selectionModelSubTable?.find(item => item.id === current)) {
                  const rr = selectionModelSubTable?.map(item => {
                    if (item.id === current) {
                      if (newSelectionModel?.find(i => i === item?.id)) {
                        return { ...item, selectionModel: groupIds };
                      }
                      return;
                    }
                    if (newSelectionModel?.find(i => i !== item?.id)) {
                      return { ...item, selectionModel: [] };
                    }
                    return item;
                  });
                  setSelectionModelSubTable(rr);
                } else {
                  setSelectionModelSubTable([
                    ...selectionModelSubTable,
                    { id: current, selectionModel: groupIds }
                  ]);
                }
              } else {
                setSelectionModelSubTable([]);
              }
            }

            setSelectionModel(newSelectionModel);
          }}
          // getRowClassName={param => {
          //   const status = !param?.row?.active ? "--active" : "";
          //   return `super-app-theme--row${status}`;
          // }}
          page={page || 0}
          selectionModel={selectionModel}
          onSortModelChange={model => {
            handleSort(model);
          }}
          sortModel={orderModel}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          onPageChange={onPageChange}
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          onCellClick={(p, e) => onCellClick(p, e)}
          //
          {...(groupBy ? { getDetailPanelHeight: () => expandHeight } : {})}
          {...(groupBy ? { getDetailPanelContent } : {})}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          customStyles={customStyles}
        />
      </div>
      <AddRowModal
        isOpen={addRowModalOpen}
        onCloseModal={() => setAddRowModalOpen(false)}
        data_type={dataType}
        dict={dict}
      />
      <DetailsModal
        open={Boolean(detailsOpen)}
        data={detailsOpen}
        close={() => setDetailsOpen(false)}
        dict={dict}
      />
    </div>
  );
};

Data.propTypes = {};

export default Data;
