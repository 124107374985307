import * as React from "react";
import { useLocation, useParams } from "react-router-dom";

export const navigationRef = React.createRef();

export function navigate(name, params) {
  navigationRef.current?.history.push(name, params);
}

export function goBack() {
  navigationRef.current?.history.goBack();
}

export const addQuery = (key, name) => {
  const history = navigationRef.current?.history;
  const query = new URLSearchParams(history.location.search);
  query.set(key, name);
  history.replace({ ...history.location, search: query.toString() });
};

export const removeQuery = key => {
  const history = navigationRef.current?.history;
  const query = new URLSearchParams(history.location.search);
  query.delete(key);
  history.replace({ ...history.location, search: query.toString() });
};

export const onChangeUrlParams = (key, name) => {
  if (name) {
    addQuery(key, name);
  } else {
    removeQuery(key);
  }
};

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );
};
