import React, { useEffect, useRef } from "react";
import Panzoom from "@panzoom/panzoom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import LightTooltip from "components/simple/LightTooltip";
import styled from "styled-components";
import PolicyMetadataCard from "../PolicyMetadataCard";
import RulesPalette from "../RulesPalette";
import ProcessRuleCard from "../ProcessRuleCard";
import FileRuleCard from "../FileRuleCard";
import NetworkRuleCard from "../NetworkRuleCard";
import {
  useKubeArmorPolicyEditorState,
  updateSelectedCluster,
  updateSelectedNamespace
} from "store/entities/policyEditor/kubeArmorPolicyEditor/slice";
import {
  useCloneComponentState,
  useProcessUploadKubeArmorPolicyYAML,
  useResetComponentState
} from "store/entities/policyEditor/kubeArmorPolicyEditor/hooks";
import { useLocation } from "react-router-dom";
import { PolicyYaml } from "store/entities/policies/queries";
import { useKubeArmorPolicyContext } from "../..";
import { DEFAULT_POLICY_METADATA_FORM_VALUES } from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import { useDispatch } from "react-redux";
import UnsupportedRuleCard from "screens/PolicyEditor/common/components/UnsupportedRuleCard";
import { navigate } from "helper/history";
import { routes } from "router";

const EditorCanvas = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { policyMetadataForm } = useKubeArmorPolicyContext();
  const updateComponentState = useCloneComponentState();
  const resetComponentState = useResetComponentState();
  const { processYAML: processKubeArmorPolicyYAML } = useProcessUploadKubeArmorPolicyYAML();

  const canvasRef = useRef();
  const mainContentRef = useRef();
  const zoomInref = useRef();
  const zoomOutRef = useRef();
  const resetRef = useRef();

  const showRulesPaletteCard = useKubeArmorPolicyEditorState("showRulesPaletteCard");
  const processRule = useKubeArmorPolicyEditorState("processRule");
  const fileRule = useKubeArmorPolicyEditorState("fileRule");
  const networkRule = useKubeArmorPolicyEditorState("networkRule");
  const unsupportedRules = useKubeArmorPolicyEditorState("unsupportedRules");

  const { showProcessRuleCard } = processRule;
  const { showFileRuleCard } = fileRule;
  const { showNetworkRuleCard } = networkRule;

  useEffect(() => {
    const panzoom = Panzoom(mainContentRef.current, {
      minScale: 0.5,
      maxScale: 5,
      cursor: "grab",
      canvas: true
    });

    canvasRef.current.addEventListener("wheel", e => {
      if (!e.target.closest(".panzoom-react-select__menu")) {
        panzoom.zoomWithWheel(e, {
          step: 0.1
        });
      }
    });
    zoomInref.current.addEventListener("click", panzoom.zoomIn);
    zoomOutRef.current.addEventListener("click", panzoom.zoomOut);
    resetRef.current.addEventListener("click", panzoom.reset);

    // Removes all PanZoom-related event listeners during unmounting
    return () => panzoom.destroy();
  }, []);

  const clusterFromCloudWorkloads = location?.state?.selectedCluster;
  const namespaceFromCloudWorkloads = location?.state?.selectedNamespace;

  const clonedPolicyData =
    location?.state?.selectedPolicy && PolicyYaml(location?.state?.selectedPolicy?.policy_id);
  const freshPolicy = location?.state?.from === "fromCreate";
  const policyFromCloudsWorkspace = location?.state?.from === "ClusterManager";

  // Update component state when policy YAML is set by the Network policy editor tab
  const policyYAMLFromNetworkPolicyEditor = location?.state?.policyYAMLFromNetworkPolicyEditor;

  useEffect(() => {
    if (clonedPolicyData) {
      updateComponentState(clonedPolicyData?.data?.yaml, location?.state?.selectedPolicy);
    }
    if (freshPolicy) {
      resetComponentState();
    }
    if (policyFromCloudsWorkspace) {
      resetComponentState();
      dispatch(updateSelectedCluster(clusterFromCloudWorkloads));
      dispatch(updateSelectedNamespace(namespaceFromCloudWorkloads));
      policyMetadataForm.reset({
        ...DEFAULT_POLICY_METADATA_FORM_VALUES,
        cluster: clusterFromCloudWorkloads,
        policyNamespace: namespaceFromCloudWorkloads
      });
    }
  }, [clonedPolicyData?.data, freshPolicy, policyFromCloudsWorkspace]);

  useEffect(() => {
    if (policyYAMLFromNetworkPolicyEditor) {
      processKubeArmorPolicyYAML(policyYAMLFromNetworkPolicyEditor);
      // Clean up location.state as soon it is used
      navigate(routes.policyEditor, {});
    }
  }, [policyYAMLFromNetworkPolicyEditor]);

  return (
    <EditorCanvasContainer>
      <div ref={canvasRef} className="h-full min-w-full w-fit">
        <div ref={mainContentRef} className="flex gap-8">
          <PolicyMetadataCard />
          {showRulesPaletteCard && <RulesPalette />}
          <div className="flex flex-col gap-5">
            {showProcessRuleCard && <ProcessRuleCard />}
            {showFileRuleCard && <FileRuleCard />}
            {showNetworkRuleCard && <NetworkRuleCard />}
            {Boolean(unsupportedRules.length) &&
              unsupportedRules?.map(rule => <UnsupportedRuleCard key={rule} rule={rule} />)}
          </div>
        </div>
      </div>

      {/* Zoom/Pan Button Groups */}
      <div className="absolute bottom-2 right-2 flex gap-1">
        <div className="bg-white flex gap-3 p-2 rounded-xl">
          <LightTooltip title="Zoom in" placement="top" arrow>
            <span className="cursor-pointer" ref={zoomInref}>
              <AddIcon sx={{ color: "primary.main", fontSize: 20 }} />
            </span>
          </LightTooltip>
          <LightTooltip title="Zoom out" placement="top" arrow>
            <span className="cursor-pointer" ref={zoomOutRef}>
              <RemoveIcon sx={{ color: "primary.main", fontSize: 20 }} />
            </span>
          </LightTooltip>
          <LightTooltip title="Fit to screen" placement="top" arrow>
            <span className="cursor-pointer" ref={resetRef}>
              <FullscreenIcon sx={{ color: "primary.main", fontSize: 20 }} />
            </span>
          </LightTooltip>
        </div>
      </div>
    </EditorCanvasContainer>
  );
};

const EditorCanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-image: radial-gradient(circle at center center, #a0aec0 3%, #f7fafc 3%);
  background-size: 60px 60px;
  border-radius: 15px;
  padding: 8px;
`;

export default EditorCanvas;
