/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getSubDatalistsTable } from "store/entities/datalist/service";
import { tableBuilder } from "store/entities/datalist/selectors";

import Loader from "components/simple/Loader";

import { Table } from "components/complex";

import styles from "./styles.module.scss";

const SubTable = ({
  onCellClick,
  row,
  selectionModelSubTable,
  setExpandHeight,
  expandHeight,
  onChangeSelectionModelSubTable,
  display_fields,
  display_fields_width
}) => {
  const [initData, setData] = useState({
    columns: [],
    data: [],
    page: 1,
    hasMore: true
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { columns, data, page, hasMore } = initData;

  const params = useSelector(state => state.datalist);

  const setHeight = length => {
    const rowHeight = 38;
    if (length > 15) {
      setExpandHeight(510);
    } else {
      const height = rowHeight * length + 10;
      setExpandHeight(height);
    }
  };

  const getData = (prevData, nextPage, order) => {
    const groupByKey = params?.groupBy.value;
    const groupByValue = row?.[groupByKey];

    getSubDatalistsTable({
      ...params,
      groupByValue,
      pageSize: 100,
      page: nextPage,
      order: order ?? ""
    })
      .then(res => res.json())
      .then(result => {
        const res = tableBuilder(result, [], params?.groupBy, display_fields_width, true);
        setData({
          ...res,
          data: [...prevData, ...res?.data],
          page: page + 1,
          hasMore: Boolean(result?.next)
        });
        setIsLoading(false);
        setIsLoadingMore(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsLoadingMore(false);
      });
  };

  const onRowsScrollEnd = () => {
    setIsLoadingMore(true);
    if (hasMore) {
      getData(data, page);
    } else {
      setIsLoadingMore(false);
    }
  };

  const clear = () => {
    setData({ columns: [], data: [], page: 1, hasMore: true });
    setIsLoading(true);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    const group_ids = row?.group_ids?.length;
    setHeight(group_ids);
    getData([], 1);
    return () => clear();
  }, [row]);

  const tableConfig = {
    paginationVisible: false,
    hideFooter: true,
    sortingMode: false
  };

  const customStyles = {
    height: `${expandHeight}px `,
    width: 1,
    "& .MuiDataGrid-virtualScroller": {
      // marginTop: "0px !important",
      height: `${expandHeight}px !important`
    },
    "& .MuiDataGrid-columnHeaders": {
      // display: "none",
      height: "50px",
      padding: 0
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #E7E6F8"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#E7E6F8",
      marginBottom: "0px"
    }
  };

  const onChangeSelectionModel = v => {
    onChangeSelectionModelSubTable(v);
  };

  const selectionModel = useMemo(() => {
    return selectionModelSubTable.find(item => item?.id === row?.id)?.selectionModel || [];
  }, [selectionModelSubTable, row]);

  // Sorting handle
  const handleSort = s => {
    let ordering = "";

    if (s.length) {
      const { field, sort } = s[0];
      let fieldSort = field;
      if (field === "__detail_panel_toggle__") {
        fieldSort = "group_ids";
      }

      if (sort === "asc") {
        ordering = fieldSort;
      } else {
        ordering = `-${fieldSort}`;
      }
    } else {
      ordering = "";
    }
    getData([], 1, ordering);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={`${styles.table} ${styles.subTable}`} style={{ height: `${expandHeight}px` }}>
      {isLoadingMore ? <Loader className={styles.loader} /> : null}

      <Table
        data={isLoading ? [] : data}
        onRowsScrollEnd={onRowsScrollEnd}
        onSortModelChange={model => handleSort(model)}
        columns={columns}
        loading={isLoading}
        rowHeight={38}
        headerHeight={50}
        selectionModel={selectionModel}
        onSelectionModelChange={newSelectionModel => {
          onChangeSelectionModel(newSelectionModel);
        }}
        getRowClassName={param => {
          const status = !param?.row?.active ? "--active" : "";
          return `super-app-theme--row${status}`;
        }}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
        onCellClick={(p, e) => onCellClick(p, e)}
        {...tableConfig}
        customStyles={customStyles}
        disableColumnReorder
      />
    </div>
  );
};

SubTable.propTypes = {};

export default SubTable;
