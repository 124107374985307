import { Typography } from "@mui/material";
import { kFormatter } from "helper/helpers";
import React, { useState } from "react";
import styles from "./widgets.module.scss";

import { ContentContainer, LightTooltip, Switch, Title } from "components/simple";
import { Box } from "@mui/system";

export const Widget = ({
  title,
  header,
  isHeader,
  isNetworkHeader,
  children,
  onClickHeader,
  totalCount,
  EgressCount,
  ingressCount,
  onClickofNetwork,
  switchCom,
  created,
  setCreated,
  isHeaderClickable,
  boxClass
}) => {
  return (
    <ContentContainer className={`${boxClass} p-4 flex flex-col gap-4`}>
      <Box className="flex justify-between">
        <Typography className={styles.lable} sx={{ fontSize: 14, fontWeight: 600 }}>
          {title}
        </Typography>
        {switchCom ? (
          <Box className="flex gap-1">
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Show by created date</Typography>
            <Switch
              className="ml-0 mr-1"
              sx={{ position: "relative", top: "-2px" }}
              value={created}
              onChange={() => setCreated(!created)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        ) : null}
      </Box>
      {isHeader ? (
        <Box
          onClick={onClickHeader}
          className={`${isHeaderClickable && styles.headercontainer} ${
            styles.simpleheader
          }  flex items-center justify-between p-2`}
        >
          <Typography paragraph sx={{ color: "#0066FF", fontSize: 14, fontWeight: "", m: 0 }}>
            {header}
          </Typography>
          <Typography variant="h6" sx={{ color: "#0066FF", fontWeight: "600", m: 0 }}>
            {kFormatter(totalCount)}
          </Typography>
        </Box>
      ) : null}
      {isNetworkHeader ? (
        <div className="flex items-center justify-between">
          <div
            onClick={() => onClickofNetwork(kFormatter(EgressCount), "Egress")}
            className={`${styles.headercontainer} w-3/6 mr-1 flex items-center justify-between p-2`}
          >
            <Typography paragraph sx={{ color: "#0066FF", fontSize: 14, fontWeight: "400", m: 0 }}>
              Egress
            </Typography>
            <Typography variant="h6" sx={{ color: "#0066FF", fontWeight: "600", m: 0 }}>
              {kFormatter(EgressCount)}
            </Typography>
          </div>
          <div
            onClick={() => onClickofNetwork(kFormatter(ingressCount), "Ingress")}
            className={`${styles.headercontainer} w-3/6 flex items-center justify-between p-2`}
          >
            <Typography paragraph sx={{ color: "#0066FF", fontSize: 14, fontWeight: "400", m: 0 }}>
              Ingress
            </Typography>
            <Typography variant="h6" sx={{ color: "#0066FF", fontWeight: "600", m: 0 }}>
              {kFormatter(ingressCount)}
            </Typography>
          </div>
        </div>
      ) : null}
      <div className={`${styles.child}`}>{children}</div>
    </ContentContainer>
  );
};
