import { createSlice } from "@reduxjs/toolkit";
import {
  deleteIndividualRuleReducer,
  handleIPBlockRuleFormSubmitReducer,
  handleNamespaceSelectorRuleFormSubmitReducer,
  handlePodSelectorRuleFormSubmitReducer,
  handlePolicyMetadataFormSubmitReducer,
  removeRuleFromPolicyJSONReducer,
  resetStateReducer,
  updatePolicyJSONReducer,
  updateRulesComponentStateReducer
} from "./reducers";
import { useSelector } from "react-redux";
import {
  DEFAULT_POLICY_TEMPLATE,
  DEFAULT_POLICY_YAML,
  IP_BLOCK,
  NAMESPACE_SELECTOR,
  POD_SELECTOR
} from "./constants";

export const NETWORK_POLICY_EDITOR_SLICE = "networkPolicyEditor";
export const NETWORK_POLICY_EDITOR_INITIAL_STATE = {
  selectedCluster: null,
  selectedNamespace: null,
  selectedKind: null,
  policyJSON: DEFAULT_POLICY_TEMPLATE,
  policyYAML: DEFAULT_POLICY_YAML,
  isValidYAML: null,
  activeCard: null,
  isCreatingPolicy: false,
  isUpdatingPolicy: false,
  currentPolicy: {},
  unknownPolicyValues: {},
  unsupportedRules: { ingress: [], egress: [] },
  isEditingPolicyMetadata: true,
  showRulesPaletteCard: false,
  ipBlockRule: {
    ruleData: { ingress: [], egress: [] },
    isEditingRule: { ingress: true, egress: true },
    showRuleCard: { ingress: false, egress: false },
    showAddExcept: { ingress: false, egress: false },
    showAddPort: { ingress: false, egress: false }
  },
  podSelectorRule: {
    ruleData: { ingress: [], egress: [] },
    isEditingRule: { ingress: true, egress: true },
    showRuleCard: { ingress: false, egress: false },
    showAddPort: { ingress: false, egress: false }
  },
  namespaceSelectorRule: {
    ruleData: { ingress: [], egress: [] },
    isEditingRule: { ingress: true, egress: true },
    showRuleCard: { ingress: false, egress: false },
    showAddPort: { ingress: false, egress: false }
  }
};

const networkPolicyEditorSlice = createSlice({
  name: NETWORK_POLICY_EDITOR_SLICE,
  initialState: NETWORK_POLICY_EDITOR_INITIAL_STATE,
  reducers: {
    updateActiveCard: (state, action) => {
      state.activeCard = action.payload;
    },
    updateSelectedCluster: (state, action) => {
      state.selectedCluster = action.payload;
    },
    updateIsEditingPolicyMetadata: (state, action) => {
      state.isEditingPolicyMetadata = action.payload;
    },
    updateSelectedNamespace: (state, action) => {
      state.selectedNamespace = action.payload;
    },
    updateUnknownPolicyValues: (state, action) => {
      state.unknownPolicyValues = action.payload;
    },
    updateUnsupportedRules: (state, action) => {
      state.unsupportedRules = action.payload;
    },
    updateShowRulesPaletteCard: (state, action) => {
      state.showRulesPaletteCard = action.payload;
    },
    updateCurrentPolicy: (state, action) => {
      const currentPolicy = action.payload;
      state.currentPolicy = currentPolicy;
      if (currentPolicy?.id) {
        state.isUpdatingPolicy = true;
      }
    },
    handleAddRule: (state, action) => {
      const { trafficType, ruleType } = action.payload;

      if (ruleType === IP_BLOCK) {
        state.ipBlockRule.showRuleCard[trafficType.toLowerCase()] = true;
        state.ipBlockRule.isEditingRule[trafficType.toLowerCase()] = true;
      } else if (ruleType === POD_SELECTOR) {
        state.podSelectorRule.showRuleCard[trafficType.toLowerCase()] = true;
        state.podSelectorRule.isEditingRule[trafficType.toLowerCase()] = true;
      } else if (ruleType === NAMESPACE_SELECTOR) {
        state.namespaceSelectorRule.showRuleCard[trafficType.toLowerCase()] = true;
        state.namespaceSelectorRule.isEditingRule[trafficType.toLowerCase()] = true;
      }
    },
    updatedSelectedKind: (state, action) => {
      state.selectedKind = action.payload;
    },
    updateIpBlockRuleData: (state, action) => {
      const { trafficType, ruleData } = action.payload;
      state.ipBlockRule.ruleData[trafficType] = ruleData;
    },
    updateIsEditingIPBlockRule: (state, action) => {
      const { trafficType, value } = action.payload;
      state.ipBlockRule.isEditingRule[trafficType] = value;
    },
    updateShowIPBlockRuleCard: (state, action) => {
      const { trafficType, value } = action.payload;
      state.ipBlockRule.showRuleCard[trafficType] = value;
    },
    updateShowExceptIPBlockRule: (state, action) => {
      const { trafficType, value } = action.payload;
      state.ipBlockRule.showAddExcept[trafficType] = value;
    },
    updateShowPortIPBlockRule: (state, action) => {
      const { trafficType, value } = action.payload;
      state.ipBlockRule.showAddPort[trafficType] = value;
    },
    updatePodSelectorRuleData: (state, action) => {
      const { trafficType, ruleData } = action.payload;
      state.podSelectorRule.ruleData[trafficType] = ruleData;
    },
    updateIsEditingPodSelectorRule: (state, action) => {
      const { trafficType, value } = action.payload;
      state.podSelectorRule.isEditingRule[trafficType] = value;
    },
    updateShowPodSelectorRuleCard: (state, action) => {
      const { trafficType, value } = action.payload;
      state.podSelectorRule.showRuleCard[trafficType] = value;
    },
    updateShowPortPodSelectorRule: (state, action) => {
      const { trafficType, value } = action.payload;
      state.podSelectorRule.showAddPort[trafficType] = value;
    },
    updateNamespaceSelectorRuleData: (state, action) => {
      const { trafficType, ruleData } = action.payload;
      state.namespaceSelectorRule.ruleData[trafficType] = ruleData;
    },
    updateIsEditingNamespaceSelectorRule: (state, action) => {
      const { trafficType, value } = action.payload;
      state.namespaceSelectorRule.isEditingRule[trafficType] = value;
    },
    updateShowNamespaceSelectorRuleCard: (state, action) => {
      const { trafficType, value } = action.payload;
      state.namespaceSelectorRule.showRuleCard[trafficType] = value;
    },
    updateShowPortNamespaceSelectorRule: (state, action) => {
      const { trafficType, value } = action.payload;
      state.namespaceSelectorRule.showAddPort[trafficType] = value;
    },
    updatePolicyJSON: updatePolicyJSONReducer,
    resetState: resetStateReducer,
    handlePolicyMetadataFormSubmit: handlePolicyMetadataFormSubmitReducer,
    handleIPBlockRuleFormSubmit: handleIPBlockRuleFormSubmitReducer,
    handlePodSelectorRuleFormSubmit: handlePodSelectorRuleFormSubmitReducer,
    handleNamespaceSelectorRuleFormSubmit: handleNamespaceSelectorRuleFormSubmitReducer,
    removeRuleFromPolicyJSON: removeRuleFromPolicyJSONReducer,
    deleteIndividualRule: deleteIndividualRuleReducer,
    updateRulesComponentState: updateRulesComponentStateReducer
  }
});

export const useNetworkPolicyEditorState = (...keys) => {
  const value = useSelector(state => {
    let value = state[NETWORK_POLICY_EDITOR_SLICE];
    keys.forEach(key => {
      value = value[key];
    });
    return value;
  });
  return value;
};

export const {
  updatePolicyJSON,
  resetState,
  handlePolicyMetadataFormSubmit,
  updateActiveCard,
  updateSelectedCluster,
  updateIsEditingPolicyMetadata,
  updateSelectedNamespace,
  handleAddRule,
  handleIPBlockRuleFormSubmit,
  removeRuleFromPolicyJSON,
  updateShowExceptIPBlockRule,
  updateShowPortIPBlockRule,
  updatedSelectedKind,
  updateIsEditingIPBlockRule,
  deleteIndividualRule,
  updateIpBlockRuleData,
  updatePodSelectorRuleData,
  updateNamespaceSelectorRuleData,
  getSelectedNetworkType,
  handlePodSelectorRuleFormSubmit,
  updateIsEditingPodSelectorRule,
  updateShowPortPodSelectorRule,
  updateIsEditingNamespaceSelectorRule,
  updateShowPortNamespaceSelectorRule,
  handleNamespaceSelectorRuleFormSubmit,
  updateNamespaceRuleData,
  updateUnknownPolicyValues,
  updateUnsupportedRules,
  updateShowRulesPaletteCard,
  updateIsEditingPortRule,
  updateCurrentPolicy,
  updateShowIPBlockRuleCard,
  updateShowPodSelectorRuleCard,
  updateShowNamespaceSelectorRuleCard,
  updateRulesComponentState
} = networkPolicyEditorSlice.actions;

export default networkPolicyEditorSlice.reducer;
