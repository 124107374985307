import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { Button, Title, Modal, Input } from "components/simple";
import styles from "./styles.module.scss";
import rightTick from "../../Auth/assets/right.png";
import wrongCross from "../../Auth/assets/wrong.png";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { closeChangePasswordModal } from "store/entities/users/actions";
import { useChangeUserPasswordMutation } from "store/entities/auth/mutations";
import { usePasswordValidation } from "store/entities/auth/hooks";

const ResetPasswordModal = () => {
  const open = useSelector(state => state.users.resetPasswordModal);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();
  const {
    validatePassword,
    lowerCase,
    upperCase,
    number,
    minMax,
    specialCharacter
  } = usePasswordValidation();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    reset: resetChangePasswordForm
  } = useForm({
    defaultValues: { oldPassword: "", newPassword: "", confirmPassword: "" },
    mode: "onChange"
  });

  const newPasswordValue = watch("newPassword");
  const {
    mutate: changeUserPassword,
    isLoading: isChangingPassword
  } = useChangeUserPasswordMutation();

  const close = () => {
    dispatch(closeChangePasswordModal());
  };

  const onFormSubmit = formValues => {
    const { oldPassword, newPassword } = formValues;
    changeUserPassword(
      { old_password: oldPassword, new_password: newPassword },
      {
        onSuccess: () => {
          resetChangePasswordForm();
          close();
        }
      }
    );
  };

  const renderPasswordValidation = (value, label) => {
    return (
      <div className="flex justify-center items-center gap-[6px]">
        {value ? (
          <img src={rightTick} alt="" width="11px" height="9px" />
        ) : (
          <img src={wrongCross} alt="" width="11px" height="9px" />
        )}
        <p className="text-[#808080] text-[11px]">{label}</p>
      </div>
    );
  };

  const isFormValid = lowerCase && upperCase && number && minMax && specialCharacter && isValid;

  return (
    <Modal width={600} isOpen={open} toggle={close}>
      <Title>Change password</Title>

      <form className={styles.form} onSubmit={handleSubmit(onFormSubmit)}>
        <Controller
          name="oldPassword"
          rules={{
            required: true
          }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="relative">
              <Input
                type={showOldPassword ? "text" : "password"}
                label="Old Password"
                placeholder="Enter your old password"
                value={value}
                onChange={onChange}
                className={styles.input}
                autoFocus
                required
              />
              <div className="text-right absolute right-4 bottom-[5px] cursor-pointer">
                <VisibilityIcon onClick={() => setShowOldPassword(show => !show)} />
              </div>
            </div>
          )}
        />
        <Controller
          name="newPassword"
          rules={{
            required: true,
            validate: validatePassword
          }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="relative">
              <Input
                type={showNewPassword ? "text" : "password"}
                label="New Password"
                placeholder="Enter your new password"
                value={value}
                onChange={onChange}
                className={styles.input}
                required
              />
              <div className="text-right absolute right-4 bottom-[5px] cursor-pointer">
                <VisibilityIcon onClick={() => setShowNewPassword(show => !show)} />
              </div>
              <div className="flex justify-start items-center gap-3 w-full absolute top-16 left-1">
                {renderPasswordValidation(lowerCase, "lower-case")}
                {renderPasswordValidation(upperCase, "upper-case")}
                {renderPasswordValidation(number, "number")}
                {renderPasswordValidation(minMax, "8-15 characters")}
                {renderPasswordValidation(specialCharacter, "special character")}
              </div>
            </div>
          )}
        />
        <Controller
          name="confirmPassword"
          rules={{
            required: "Confirm Password is required",
            validate: value => value === newPasswordValue || "doesn't match"
          }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="relative">
              <Input
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm your password"
                value={value}
                onChange={onChange}
                className={styles.input}
                required
              />
              {Boolean(errors?.confirmPassword?.message) && (
                <div className="absolute right-12 bottom-0 flex items-center justify-center gap-1">
                  <p className="text-sm text-gray-400">{errors?.confirmPassword?.message}</p>
                  <CheckCircleRoundedIcon fontSize="24px" className="text-gray-400" />
                </div>
              )}
              <div className="absolute right-4 bottom-[5px] cursor-pointer">
                <VisibilityIcon onClick={() => setShowConfirmPassword(show => !show)} />
              </div>
            </div>
          )}
        />

        <div className={styles.buttonContainer}>
          <Button variant="outline" onClick={close}>
            Back
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(onFormSubmit)}
            disabled={!isFormValid || isChangingPassword}
          >
            {isChangingPassword ? "Please wait" : "Confirm"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ResetPasswordModal;
