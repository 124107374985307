import React, { useState } from "react";
import { Handle } from "reactflow";
import styles from "./styles.module.scss";
import PodIcon from "../Images/pod.svg";
import expand from "../Images/expand.svg";
import { useObservabilityContext } from "../Context";

function PrimaryNode({ data }) {
  const [isShown, setIsShown] = useState(false);
  const { countOfCommands, selectedPod } = useObservabilityContext();
  return (
    <>
      <div className={`flex flex-col cursor-pointer ${styles.primaryNode}`}>
        <div
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          // eslint-disable-next-line no-return-assign, no-param-reassign
          onClick={() => (data.transparent = true)}
          className={styles.exp}
        >
          {isShown ? <img className="h-3 w-3" src={expand} alt="" /> : countOfCommands}
        </div>
        <div
          className={styles.podimg}
          onClick={() => {
            // eslint-disable-next-line no-param-reassign
            data.image = true;
          }}
        >
          <img src={PodIcon} alt="pod" />
        </div>
        <div className={styles.childName}>{selectedPod?.label}</div>
        {/* <Handle id='21' type="source" position={Position.Right} /> */}
      </div>
      <Handle isConnectable={false} type="source" position="top" id="a" />
      <Handle isConnectable={false} type="source" position="right" id="b" />
      <Handle isConnectable={false} type="source" position="bottom" id="c" />
      <Handle isConnectable={false} type="source" position="left" id="d" />
    </>
  );
}

export default PrimaryNode;
