import { getAccuknoxWorkspace } from "api/api";

export const DEFAULT = "Default";
export const NODE = "Node";

// All Entity Types
export const K8S = "K8s";
export const VM = "VM";

export const POD = "Pod";
export const NAMESPACE = "Namespace";

export const LABEL_TYPE_OPTIONS = [
  { label: DEFAULT, value: DEFAULT },
  { label: NODE, value: NODE }
];

export const ALLOW = "Allow";
export const BLOCK = "Block";
export const AUDIT = "Audit";

export const KIND_OPTIONS = [
  { id: 1, value: "NetworkPolicy", label: "Kubernetes Network" }
  // { id: 2, value: "CilliumNetworkPolicy", label: "CilliumNetworkPolicy" }
];
export const KUBERNETES_NETWORK_POLICY = "NetworkPolicy";
export const CILIUM_NETWORK_POLICY = "CilliumNetworkPolicy";
export const NETWORK_POLICY_KINDS = [KUBERNETES_NETWORK_POLICY];

export const POLICY_SPEC_PROPERTIES = ["ipBlock", "podSelector", "namespaceSelector", "ports"];

export const DEFAULT_POLICY_TEMPLATE = {
  apiVersion: "networking.k8s.io/v1"
};
export const DEFAULT_POLICY_YAML = "apiVersion: networking.k8s.io/v1";

export const DEFAULT_POLICY_METADATA_FORM_VALUES = {
  name: "",
  cluster: "",
  namespace: "",
  selectorLabel: []
};

export const PROTOCOL_OPTIONS = [
  { id: 1, value: "TCP", label: "TCP" },
  { id: 2, value: "UDP", label: "UDP" }
];
export const ROLE_OPTIONS = [{ id: 1, value: "frontend", label: "frontend" }];

export const DEFAULT_IPBLOCK_FORM_VALUES = {
  cidr: "",
  except: [],
  ports: [],
  protocol: null
};
export const DEFAULT_POD_SELECTOR_FORM_VALUES = {
  selectorLabel: [],
  allowAll: false,
  ports: [],
  protocol: null
};
export const DEFAULT_NAMESPACE_SELECTOR_FORM_VALUES = {
  selectorLabel: [],
  ports: [],
  protocol: null
};

export const NETWORK_INFO_CODE = `
apiVersion: networking.k8s.io/v1 # API version for network policy
kind: NetworkPolicy # Kind of resource to create
metadata:
  name: policy-name # Name of the policy
  namespace: policy-namespace # Namespace in which to apply the policy
spec:
  podSelector: # The set of pods the policy should apply to
    matchLabels:
      app: myapp # Label selector to identify the pods
  policyTypes:
  - Ingress # Specifies the direction of network traffic the policy should affect (Ingress or Egress)
  - Egress
  ingress:
  - from:
    - namespaceSelector: # Source namespace selector for incoming traffic
        matchLabels:
          team: myteam # Label selector for the source namespace
    - podSelector: # Source pod selector for incoming traffic
        matchLabels:
          role: frontend # Label selector for the source pods
    ports:
    - protocol: TCP # Protocol for incoming traffic
      port: 80 # Target port for incoming traffic
  egress:
  - to:
    - ipBlock: # Destination IP block for outgoing traffic
        cidr: 10.0.0.0/8 # CIDR notation for the destination IP block
        except: # List of exceptions to the destination IP block
        - 10.96.0.0/12
    - namespaceSelector: # Destination namespace selector for outgoing traffic
        matchLabels:
          team: otherteam # Label selector for the destination namespace
    - podSelector: # Destination pod selector for outgoing traffic
        matchLabels:
          role: backend # Label selector for the destination pods
    ports:
    - protocol: TCP # Protocol for outgoing traffic
      port: 5432 # Target port for outgoing traffic
`;

// Fixtures for all the active card components
export const METADATA = "Metadata";
export const IP_BLOCK_INGRESS = "ipBlockIngress";
export const IP_BLOCK_EGRESS = "ipBlockEgress";
export const POD_SELECTOR_INGRESS = "podSelectorIngress";
export const POD_SELECTOR_EGRESS = "podSelectorEgress";
export const NAMESPACE_SELECTOR_INGRESS = "namespaceSelectorIngress";
export const NAMESPACE_SELECTOR_EGRESS = "namespaceSelectorEgress";

export const IP_BLOCK = "ipBlock";
export const POD_SELECTOR = "podSelector";
export const NAMESPACE_SELECTOR = "namespaceSelector";

export const K8S_NETWORK_RULES = [IP_BLOCK, POD_SELECTOR, NAMESPACE_SELECTOR];

export const TRAFFIC_OPTIONS = [
  { id: 1, label: "Ingress", disabled: false },
  { id: 2, label: "Egress", disabled: false }
];

export const INGRESS = "ingress";
export const EGRESS = "egress";

export const WORKSPACE_ID = getAccuknoxWorkspace();
export const USER_ID = "30d4caa0-0c48-4d2c-874d-0edb1a950a43";
export const USER_FULL_NAME = "Accuknox User";
export const NETWORK_POLICY = "NetworkPolicy";
export const DEFAULT_NETWORK_POLICY_QUERY_KEYS = ["PolicyEditorTool", "NetworkPolicy"];

export const CIDR_REGEX = /^([0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|[1-2][0-9]|3[0-2])$/;
export const PORT_REGEX = /^([1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
