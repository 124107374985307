import {
  API,
  authorization,
  authorizationTenant,
  contentTypeJson,
  getAccuknoxWorkspace
} from "api/api";
import { customPost, postFormRequest, get } from "api/base";
import { useQuery } from "react-query";
import { DATE_FROM, DATE_TO } from "store/entities/issues/registryScan/constants";

const workspaceid = getAccuknoxWorkspace();
const payload = { WorkspaceId: workspaceid };
export const useScanStatus = () => {
  return useQuery(
    ["scanStatus", workspaceid],

    async () => {
      const response = await get(
        `${API}/dashboard?data_type=TOTAL_COMPLIANCE&date_from=${DATE_FROM}&date_to=${DATE_TO}&limit=${10}`,
        {
          ...contentTypeJson(),
          ...authorization(),
          ...authorizationTenant()
        }
      );
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false
    }
  );
};

export const useComplianceSummary = () => {
  return useQuery(
    ["ComplianceSummary", workspaceid],

    async () => {
      const response = await get(
        `${API}/screen?data_type=ASSET_COMPLIANCE_SUMMARY&date_from=${DATE_FROM}&date_to=${DATE_TO}&limit=${10}`,
        {
          ...contentTypeJson(),
          ...authorization(),
          ...authorizationTenant()
        }
      );
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false
    }
  );
};

export const useExecutiveComplianceSummary = () => {
  return useQuery(
    ["ExecutiveComplianceSummary", workspaceid],

    async () => {
      const response = await get(
        `${API}/screen?data_type=EXECUTIVE_COMPLIANCE_SUMMARY&date_from=${DATE_FROM}&date_to=${DATE_TO}&limit=${10}`,
        {
          ...contentTypeJson(),
          ...authorization(),
          ...authorizationTenant()
        }
      );
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false
    }
  );
};

export const useComplianceBasedOnRegion = () => {
  const URL = "/dashboard/asset-region-compliance-summary";
  return useQuery(
    ["ComplianceBasedOnRegion", workspaceid],

    async () => {
      const response = await postFormRequest(URL);
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false
    }
  );
};
//https://cspm.dev.accuknox.com/api/v1/dashboard/asset-region-compliance-summary
