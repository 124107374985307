import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { addNotification } from "../notifications/actions";
import { routes } from "router";
import { navigate } from "helper/history";
import { errorHandler } from "helper/errorHandler";

const getReportsParams = state => ({
  page: state.reports.reportsParams.page,
  pageSize: state.reports.reportsParams.pageSize,
  search: state.reports.reportsParams.search,
  ordering: state.reports.reportsParams.ordering
});

// Workers //

// Set reports params
function* setReportsParamsWorker() {
  const { page, pageSize, search, ordering } = yield select(getReportsParams);
  yield put(
    actions.getReports({
      page,
      pageSize,
      search,
      ordering
    })
  );
}

// Get Reports
function* getReportsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getReports), payload);
    yield put(actions.getReportsSuccess(response));
  } catch (error) {
    yield put(actions.getReportsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Report
function* getReportWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getReport), payload);
    yield put(actions.getReportSuccess(response));
  } catch (error) {
    yield put(actions.getReportFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Create Report
function* createReportWorker({ payload }) {
  const { callback, action, data } = payload;

  try {
    const response = yield call(makeRequest(service.createReport), data);
    yield put(actions.createReportSuccess(response));
    yield put(addNotification({ msg: `Report config created successfully` }));
    if (!action) {
      navigate(routes.reports);
      return;
    }
    if (action === "clear") {
      callback();
      navigate(routes.reportsDetails);
    } else if (action === "continue") {
      navigate(`${routes.reportsDetails}/${response.id}`);
    }
  } catch (error) {
    yield put(actions.createReportFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Edit Report
function* editReportWorker({ payload }) {
  const { callback, action, id, data } = payload;
  try {
    const response = yield call(makeRequest(service.editReport), { id, data });
    yield put(actions.editReportSuccess(response));
    if (action === "continue") yield put(actions.getReport(id));
    yield put(addNotification({ msg: `Report config updated successfully` }));
    if (!action) {
      navigate(routes.reports);
      return;
    }
    if (action === "clear") {
      callback();
      navigate(routes.reportsDetails);
    }
  } catch (error) {
    yield put(actions.editReportFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete Report
function* deleteReportWorker({ payload }) {
  try {
    const { ids, redirect } = payload;
    const response = yield all(ids.map(id => call(makeRequest(service.deleteReport), id)));
    yield put(actions.deleteReportSuccess(response));
    yield put(actions.setReportsParams());
    yield put(addNotification({ msg: `Report config deleted successfully` }));
    if (redirect) {
      navigate(routes.reports);
    }
  } catch (error) {
    yield put(actions.deleteReportFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Run Report
function* runReportWorker({ payload }) {
  try {
    const { list, date, callback } = payload;

    const response = yield all(
      list.map(({ id, name, email, pages, period }) => {
        const data = {
          name,
          email,
          pages: pages.split(","),
          period
        };
        return call(makeRequest(service.runReport), { id, data, date });
      })
    );
    yield put(actions.runReportSuccess(response));
    callback();
    yield put(
      addNotification({ msg: `${list.length > 1 ? "Configs" : "Config"} running successfully` })
    );
  } catch (error) {
    yield put(actions.runReportFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Report pages
function* getReportPagesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getReportPages), payload);
    yield put(actions.getReportPagesSuccess(response));
  } catch (error) {
    yield put(actions.getReportPagesFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Report periods
function* getReportPeriodsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getReportPeriods), payload);
    yield put(actions.getReportPeriodsSuccess(response));
  } catch (error) {
    yield put(actions.getReportPeriodsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Workers end //

// Watchers //

// Set reports params
function* watchSetReportsParamsWorker() {
  yield takeEvery(actions.setReportsParams, setReportsParamsWorker);
}

// Get Reports
function* watchGetReportsWorker() {
  yield takeEvery(actions.getReports, getReportsWorker);
}

// Get Report
function* watchGetReportWorker() {
  yield takeEvery(actions.getReport, getReportWorker);
}

// Create Report
function* watchCreateReportWorker() {
  yield takeEvery(actions.createReport, createReportWorker);
}

// Edit Report
function* watchEditReportWorker() {
  yield takeEvery(actions.editReport, editReportWorker);
}

// Delete Report
function* watchDeleteReportWorker() {
  yield takeEvery(actions.deleteReport, deleteReportWorker);
}

// Run Report
function* watchRunReportWorker() {
  yield takeEvery(actions.runReport, runReportWorker);
}

// Get Report pages
function* watchGetReportPagesWorker() {
  yield takeEvery(actions.getReportPages, getReportPagesWorker);
}

// Get Report periods
function* watchGetReportPeriodsWorker() {
  yield takeEvery(actions.getReportPeriods, getReportPeriodsWorker);
}

export default function* rootSaga() {
  yield all([
    fork(watchSetReportsParamsWorker),
    fork(watchGetReportsWorker),
    fork(watchGetReportWorker),
    fork(watchCreateReportWorker),
    fork(watchEditReportWorker),
    fork(watchDeleteReportWorker),
    fork(watchRunReportWorker),
    fork(watchGetReportPagesWorker),
    fork(watchGetReportPeriodsWorker)
  ]);
}
