/* eslint-disable camelcase */
import React, { useMemo, useState } from "react";
import { Button, Tags } from "components/simple";
import { useSelector, useDispatch } from "react-redux";
import { setBaselineConfigParams, updBaselineConfigParams } from "store/entities/baseline/actions";

import DynamicSelect from "./DynamicSelect";

import styles from "./styles.module.scss";

export const createList = obj => {
  if (obj) {
    return Object.entries(obj).map(([key, val]) => {
      return { value: key, label: val };
    });
  }
  return [];
};

const PanelFields = ({
  className,
  display_fields_options,
  filter_fields_options,
  id,
  onEditFields
}) => {
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(false);

  const { baselineConfigTemporaryData } = useSelector(st => st.baseline.baselineConfigParams);
  const filter_fields_list = useMemo(() => {
    return createList(filter_fields_options) || [];
  }, [filter_fields_options]);

  const display_fields_list = useMemo(() => {
    return createList(display_fields_options) || [];
  }, [display_fields_options]);

  const dictList = filter_fields_options; // dict

  const filters = baselineConfigTemporaryData?.filters;

  const filter_fields = useMemo(() => {
    return (
      baselineConfigTemporaryData?.filter_fields
        ?.map(item => {
          return filter_fields_list?.find(t => t.value === item);
        })
        .filter(item => item) ?? []
    );
  }, [baselineConfigTemporaryData]);

  const display_fields = useMemo(() => {
    return (
      baselineConfigTemporaryData?.display_fields
        ?.map(item => {
          return display_fields_list?.find(t => t.value === item);
        })
        .filter(s => s) ?? []
    );
  }, [baselineConfigTemporaryData]);

  const loadData = () => {
    setCollapse(true);
  };

  const onChangeSelect = (val, name) => {
    dispatch(
      updBaselineConfigParams({
        baselineConfigTemporaryData: {
          ...baselineConfigTemporaryData,
          [name]: val?.map(item => item?.value).filter(s => s)
        }
      })
    );
  };
  // filters
  const onChangeSelectedFilterFields = v => {
    const isEmpty = !Object.values(v).some(x => x);
    const filtr = { ...filters, ...v };
    if (isEmpty) {
      Object.keys(v).some(x => {
        delete filtr[x];
        return true;
      });
    }
    dispatch(
      setBaselineConfigParams({
        ordering: "",
        baselineConfigTemporaryData: {
          ...baselineConfigTemporaryData,
          filters: filtr
        }
      })
    );
  };

  return (
    <div className={`${styles.panel} ${className}`}>
      {!collapse ? (
        <>
          <div className={styles.row2}>
            <Tags
              label="Select fields to filter"
              values={filter_fields}
              list={filter_fields_list}
              onChange={v => onChangeSelect(v, "filter_fields")}
            />
            <Tags
              label="Select fields to display"
              values={display_fields}
              list={display_fields_list}
              onChange={v => onChangeSelect(v, "display_fields")}
            />
          </div>
          <div className={styles.buttonGroup}>
            <Button className={styles.editButton} onClick={() => setCollapse(true)}>
              Collapse
            </Button>
            <Button
              className={styles.editButton}
              icon="plus"
              variant="outline"
              onClick={() => loadData()}
            >
              Load filters
            </Button>

            <Button
              className={`${styles.editButton} ${styles.right}`}
              onClick={() => onEditFields()}
            >
              Save
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.row}>
            {filter_fields?.map((item, index) => {
              return (
                <DynamicSelect
                  key={index}
                  item={item.value}
                  dictList={dictList}
                  id={id}
                  selectedFilterField={filters?.[item.value] || ""}
                  onChange={onChangeSelectedFilterFields}
                />
              );
            })}
          </div>
          <div className={styles.buttonGroup}>
            <Button className={styles.editButton} onClick={() => setCollapse(false)}>
              Edit
            </Button>

            <Button
              className={`${styles.editButton} ${styles.right}`}
              onClick={() => onEditFields()}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

PanelFields.propTypes = {};

export default PanelFields;
