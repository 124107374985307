// eslint-disable-next-line import/no-unresolved
import { useQuery } from "react-query";
import { customPost } from "api/base";
// import { setClusters } from "./slice";
import { useDispatch } from "react-redux";
import { CLUSTER, POD } from "screens/Inventory/ClusterManagement/constant";

export const convertEntityType = name => {
  // eslint-disable-next-line no-nested-ternary
  return name === CLUSTER ? "Cluster" : name === POD ? "Pod" : null;
};

export const useGetLabel = (entityDetails, searchQuery) => {
  const payload = {
    workspace_id: entityDetails?.WorkspaceID,
    page_previous: 0,
    page_next: 100,
    query: searchQuery,
    filter: {
      without: {
        entity_type: convertEntityType(entityDetails.entity_type),
        entity_id: [entityDetails?.ID]
      }
    }
  };
  return useQuery(
    ["getLabel", entityDetails.ID, searchQuery],
    async () => {
      const response = await customPost("/labelmanagement/api/v1/label/get-label", payload);
      const result = await response.json();

      return result.get_labels;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};
