export const updateSelectedVulnerabilityTypeReducer = (state, action) => {
  state.selectedVulnerabilityType = action.payload;
  state.vulnerabilityTablePage = 0;
};

export const updateVulnerabilityTablePageReducer = (state, action) => {
  state.vulnerabilityTablePage = action.payload;
};

export const updateDrawerVulnerabilityReducer = (state, action) => {
  state.drawerVulnerability = action.payload;
};
