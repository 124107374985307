import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { clean } from "screens/MonitorLogs/helper";
import { customPost, postFormData } from "api/base";
import {
  getChannelIntegrationList,
  getChannelIntegrationListV3
} from "screens/ChannelIntegration/channelUrl";
import { addNotification } from "../notifications/actions";

export const useGetChannelList = (onSuccess = () => {}, channel_id) => {
  const dispatch = useDispatch();
  return useQuery(
    ["getChannelList", channel_id],
    async () => {
      const payload = {
        PageId: 1,
        PageSize: 10,
        ChannelID: channel_id
      };

      const response = await customPost(getChannelIntegrationListV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        const [
          jiraDetails,
          slackDetails,
          splunkDetails,
          cloudwatchDetails,
          elasticSearchDetails,
          SplunkAppdetails,
          Rsyslogdetails
        ] = [
          data?.response?.Jira,
          data?.response?.Slack,
          data?.response?.Splunk,
          data?.response?.Cloudwatch,
          data?.response?.Elasticsearch,
          data?.response?.Splunkapp,
          data?.response?.Rsyslog
        ];
        const MergeAllChannel = [].concat(
          slackDetails,
          splunkDetails,
          cloudwatchDetails,
          elasticSearchDetails,
          jiraDetails,
          SplunkAppdetails,
          Rsyslogdetails
        );
        const dataList = MergeAllChannel?.filter(
          element => !(element === null || element === undefined)
        );
        return { dataList: dataList, total: data?.response?.TotalCount };
      },
      onSuccess,
      onError: async data => {
        const res = await data?.json();
        dispatch(addNotification({ msg: res?.Error, type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};
