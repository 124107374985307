import React from "react";

const AccuknoxCluster = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4269 0C22.8545 0 21.3696 0.35175 20.0278 0.966875C22.5159 3.41513 24.0624 6.81581 24.0624 10.5731C24.0624 14.3299 22.5159 17.7306 20.0278 20.1788C21.3692 20.7939 22.8545 21.1457 24.4269 21.1457C30.2662 21.1457 34.9999 16.4119 34.9999 10.5731C34.9999 4.73375 30.2662 0 24.4269 0ZM14.9708 14.8212C12.4841 17.2694 10.9375 20.6701 10.9375 24.4269C10.9375 28.1842 12.4841 31.5849 14.9708 34.0331C13.6303 34.6483 12.1454 35 10.5731 35C4.73375 35 0 30.2663 0 24.4269C0 18.5881 4.73375 13.8543 10.5731 13.8543C12.1454 13.8543 13.6308 14.2061 14.9708 14.8212ZM14.8212 20.0279C17.2694 22.516 20.6701 24.0626 24.4269 24.0626C28.1842 24.0626 31.5849 22.516 34.0331 20.0279C34.6483 21.3698 35 22.8546 35 24.427C35 30.2663 30.2663 35.0001 24.4269 35.0001C18.5881 35.0001 13.8543 30.2663 13.8543 24.427C13.8543 22.8546 14.2061 21.3693 14.8212 20.0279ZM20.1788 14.9708C17.7306 12.4841 14.3299 10.9375 10.5731 10.9375C6.81581 10.9375 3.41513 12.4841 0.966875 14.9708C0.35175 13.6303 0 12.1454 0 10.5731C0 4.73375 4.73375 0 10.5731 0C16.4119 0 21.1457 4.73375 21.1457 10.5731C21.1457 12.1454 20.7939 13.6308 20.1788 14.9708Z"
          fill="#05147D"
        />
      </svg>
    </div>
  );
};

export default AccuknoxCluster;
