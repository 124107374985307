import { Button as Btn, Menu, MenuItem, styled } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";

const PolicyStatusTag = ({ status = "Pending"}) => {
const StatusTag = styled(Btn)(({ status }) => {
  const buttonBg = () => {
    if (status === "Pending") {
      return "#FDC77C";
    }
    if (status === "Active") {
      return "#15AC6E";
    }
    return "#F55353";
  };
  return {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 12px",
    height: "30px",
    width: "96px",
    border: "none",
    background: buttonBg(),
    borderRadius: "15px",
    color: status === "Pending" ? "#000000" : "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
    "&:hover": {
      background: buttonBg()
    },
    "& svg": {
      width: "16px"
    }
  };
});
    return (
    <div>
      <StatusTag
        status={status}
      >
        {status} 
      </StatusTag>
    </div>
  );
};

PolicyStatusTag.propTypes = {
  status: PropTypes.oneOf(["Pending", "Active", "Inactive"]),
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

PolicyStatusTag.defaultProps = {
  status: "Pending",
  onChange: () => {},
  onClick: () => {}
};

export default PolicyStatusTag;
