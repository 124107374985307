import { Box, Typography } from "@mui/material";
import { ContentContainer, Tabs } from "components/simple";
import Piechart from "components/simple/Charts/Piechart";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ComplianceSummary from "./ComplianceSummary";
import ExecutiveComplianceSummary from "./ExecutiveComplianceSummary";
import { chartData, mockData, tableData } from "./mockdata";
import RegionWidget from "./RegionWidget";
import {
  useComplianceSummary,
  useExecutiveComplianceSummary,
  useScanStatus,
  useComplianceBasedOnRegion
} from "./service";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import { getLocation } from "./zones";

function CSPM() {
  const fetchScan = useScanStatus();
  const fetchComplinace = useComplianceSummary();
  const fetchExecutiveCompliance = useExecutiveComplianceSummary();
  // const fetchComplianceRegion = useComplianceBasedOnRegion();
  const [ScanStatus, setScanStatus] = useState([]);
  const [complianceAssets, setComplinaceAssets] = useState([]);
  const [complinaceExecutive, setComplinaceExecutive] = useState([]);
  // const [tablist, setTablist] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [content, setContent] = useState("");
  const mockData1 = [
    { location: [-78.45, 38.13], severity: "pass", content: "N. Virginia" },
    { location: [55.18, 25.15], severity: "pass", content: "mumbai" }
  ];
  //25°15′N 55°18′E
  const mockData2 = [
    { location: [73.56, 40.41], severity: "fail", content: "mumbai" },
    { location: [144.21, 38.09], severity: "fail", content: "mumbai" }
  ];
  // 40°40′N 73°56′W , 38°09′S 144°21′E
  const mockData3 = [
    { location: [18.25, 33.56], severity: "warning", content: "mumbai" },
    { location: [8.41, 50.07], severity: "warning", content: "mumbai" }
  ];
  // 33°56′S 18°25′E ,  50°07′N 8°41′E
  const tabsList = [
    {
      id: 0,
      label: "All",
      content: <RegionWidget data={mockData1} setTooltipContent={setContent} />
    },
    {
      id: 1,
      label: "IAM (68)",
      content: <RegionWidget data={mockData2} setTooltipContent={setContent} />
    },
    {
      id: 2,
      label: "Compute (3)",
      content: <RegionWidget data={mockData3} setTooltipContent={setContent} />
    },
    {
      id: 3,
      label: "Network (74)",
      content: <RegionWidget data={mockData1} setTooltipContent={setContent} />
    },
    {
      id: 4,
      label: "Storage (5)",
      content: <RegionWidget data={mockData2} setTooltipContent={setContent} />
    },
    {
      id: 5,
      label: "Logging (10)",
      content: <RegionWidget data={mockData3} setTooltipContent={setContent} />
    }
  ];
  const handleTabChange = (event, newValue) => {
    event.stopPropagation();
    setActiveTab(newValue);
  };
  useEffect(() => {
    if (
      fetchScan?.data?.result?.total_compliance?.success > 0 ||
      fetchScan?.data?.result?.total_compliance?.failure > 0
    ) {
      setScanStatus([
        {
          name: "success",
          value: fetchScan?.data?.result?.total_compliance?.success,
          color: "#75FF33"
        },
        {
          name: "failure",
          value: fetchScan?.data?.result?.total_compliance?.failure,
          color: "#FF5733"
        }
      ]);
    } else {
      setScanStatus([]);
    }
  }, [JSON.stringify(fetchScan)]);
  useEffect(() => {
    if (
      fetchComplinace?.data?.result?.asset_compliance_summary?.length
      // &&fetchComplinace?.data?.result?.asset_compliance_summary?.IAM?.pass !== 0
    ) {
      const arr = [];
      Object.keys(fetchComplinace?.data?.result?.asset_compliance_summary[0]).forEach((key, i) => {
        if (fetchComplinace?.data?.result?.asset_compliance_summary[0][key]) {
          arr.push({
            id: i,
            Assets: key,
            ...fetchComplinace?.data?.result?.asset_compliance_summary[0][key]
          });
        }
      });
      setComplinaceAssets(arr);
    } else {
      setComplinaceAssets([]);
    }
  }, [JSON.stringify(fetchComplinace?.data?.result?.asset_compliance_summary)]);
  useEffect(() => {
    if (
      fetchExecutiveCompliance?.data?.result?.executive_compliance_summary !== undefined &&
      fetchExecutiveCompliance?.data?.result?.executive_compliance_summary[0]
        ?.total_passed_compliance > 0
    ) {
      if (fetchExecutiveCompliance?.data?.result?.executive_compliance_summary?.length > 0) {
        const arr = fetchExecutiveCompliance?.data?.result?.executive_compliance_summary?.map(
          (e, i) => {
            const compliance = [];
            for (let i = 1; i < 3; i++) {
              compliance?.unshift({
                date: moment(e?.last_scan)
                  .subtract(i, "months")
                  .format("MMM-YY"),
                count: 0
              });
            }
            compliance.push({
              date: moment.utc(e?.last_scan).format("MMM-YY"),
              count: e?.total_passed_compliance
            });
            // console.log(
            //   "compliance",
            //   compliance.map(e => {
            //     return e.count;
            //   })
            // );p
            return {
              id: i,
              cloudname: e?.env,
              lastscanned: moment.utc(e?.last_scan).format("DD MMM YYYY"),
              compliance: compliance,
              trendLine: compliance.map(e => {
                return parseInt(e?.count);
              })
            };
          }
        );
        setComplinaceExecutive(arr);
      } else {
        // console.log("run-22");
        setComplinaceExecutive([]);
      }
    }
  }, [JSON.stringify(fetchExecutiveCompliance)]);
  // useEffect(() => {
  //   if (
  //     fetchComplianceRegion?.data !== undefined &&
  //     Object.keys(fetchComplianceRegion?.data).length > 0 &&
  //     !Object.keys(fetchComplianceRegion?.data).includes("Asset")
  //   ) {
  //     const arr = [];
  //     const keys = Object.keys(fetchComplianceRegion?.data);
  //     keys?.map((e, i) => {
  //       //{ location: [72.5, 18.59], severity: "pass", content: "mumbai" },
  //       let obj = {
  //         pass: fetchComplianceRegion?.data[e]?.pass,
  //         fail: fetchComplianceRegion?.data[e]?.fail,
  //         warning: fetchComplianceRegion?.data[e]?.warning
  //       };
  //       let maxValue = Object.entries(obj).reduce(
  //         (max, entry) => (entry[1] >= max[1] ? entry : max),
  //         [0, -Infinity]
  //       );
  //       const data = [
  //         {
  //           location: getLocation(fetchComplianceRegion?.data[e].Region)?.location,
  //           severity: maxValue[0],
  //           count: fetchComplianceRegion?.data[e].pass,
  //           content: getLocation(fetchComplianceRegion?.data[e].Region)?.city
  //         }
  //       ];
  //       arr.push({
  //         id: i,
  //         label: e,
  //         content: <RegionWidget data={data} setTooltipContent={setContent} />
  //       });
  //     });
  //     setTablist(arr);
  //   }
  // }, [JSON.stringify(fetchComplianceRegion)]);
  return (
    <ContentContainer style={{ height: "90vh" }}>
      <div className="flex flex-col p-10">
        <div className="flex gap-1 mb-4 h-80">
          <Container className="flex flex-col w-1/2 p-3">
            <Typography className="m-2 font-bold">Total Pass V/s Fail Status</Typography>
            <Piechart
              outerRadius={80}
              innerRadius={60}
              cx={150}
              cy={120}
              data={ScanStatus}
              label={true}
            />
          </Container>
          <Container className="flex flex-col w-1/2 p-3">
            <Typography className="m-2 font-bold">Compliance Summary based on Asset</Typography>
            <ComplianceSummary data={complianceAssets} />
          </Container>
        </div>
        <Container className="flex flex-col p-4 ">
          <Box className="m-2 font-bold">Executive Compliance Summary</Box>
          <ExecutiveComplianceSummary data={complinaceExecutive} />
        </Container>
        {/* <Box sx={{ border: "1px solid lightgrey", padding: "10px", width: "35%", margin: "10px" }}>
          Compliance Coverage based on Region
          {tablist.length > 0 ? (
            <>
              <Tabs tabs={tablist} value={activeTab} handleChange={handleTabChange} />
              <Tooltip>{content}</Tooltip>
              <Box className="flex items-center justify-start gap-2">
                <Box className="flex w-3 h-3 bg-[#1489FF] rounded-full"></Box> Pass
                <Box className="flex w-3 h-3 bg-[#D13D3D] rounded-full"></Box> Fail
                <Box className="flex w-3 h-3 bg-[#F5A647] rounded-full"></Box> Warning
              </Box>{" "}
            </>
          ) : (
            <div className="flex items-center justify-center m-24">No data to show</div>
          )}
        </Box> */}
      </div>
    </ContentContainer>
  );
}

export default CSPM;

export const Container = styled.div`
  display: flex;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 0px 10px 0px 10px;
  padding-bottom: 30px;
  @media screen and (min-width: 2256px) {
    padding-bottom: 50px;
  }
`;
