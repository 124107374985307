/*eslint-disable */

import React, { useState } from "react";
import { Button, Title, Modal, Input, ErrorText, Select } from "components/simple";
import styles from "./styles.module.scss";
import { useForm, Controller } from "react-hook-form";
import { Typography, Chip } from "@mui/material";
import { useQueryClient } from "react-query";
import { useCallSaveFilter } from "store/entities/datapipeline/queries";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";

function SaveFilterModal({
  onCloseModal,
  savedFilter,
  isModalOpen,
  filters,
  fcomp,
  updateLocalDSL
}) {
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors }
  } = useForm();
  const [title, setTitle] = useState("");
  const [filterType] = useState("SAVED");
  const [uniquename, setUniquename] = useState(false);
  const [uniquequery, setUniquequery] = useState(false);
  const checkQuery = savedFilter?.response?.Filters?.map(e => e?.FilterQuery);
  const checkName = savedFilter?.response?.Filters?.map(e => e?.Name);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  function savefilternamecheck(name) {
    let result = false;
    checkName?.filter(item => {
      if (item === name) {
        result = true;
      }
      return result;
    });
    setUniquename(result);
    queryClient.invalidateQueries("getAllFilters");
    return result;
  }

  function savefilterquerycheck() {
    const fstring = filters?.map(item => item.text);
    let result1 = false;
    for (let j = 0; j < checkQuery?.length; j++) {
      if (JSON.stringify([checkQuery[j]]) === JSON.stringify(fstring)) {
        result1 = true;
      }
    }
    setUniquequery(result1);
    queryClient.invalidateQueries("getAllFilters");
    return result1;
  }
  const saveFilter = useCallSaveFilter(d => {
    updateLocalDSL([]);
    onCloseModal();
    queryClient.invalidateQueries("getAllFilters");
    dispatch(addNotification({ msg: "Filter Saved" }));
    setTitle("");
  });
  const onSubmit = () => {
    if (!savefilternamecheck(title) && !savefilterquerycheck()) {
      saveFilter.mutate({
        filterQuery: filters?.map(item => item.text),
        name: title,
        filterType: filterType,
        filters: fcomp
      });
    }
  };
  return (
    <Modal isOpen={isModalOpen} toggle={onCloseModal}>
      <Title className={styles.title}>Save Filter</Title>
      <hr />
      <p className="text-xs opacity-80">
        You are about to save a selected filter. Please review your applied filter before saving it.
      </p>
      <div className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            {...register("name", {
              required: "Filter name cannot be empty"
            })}
            defaultValue=""
            control={control}
            render={({ field: { onChange }, value, ref }) => (
              <Input
                name="name"
                label="Name"
                placeholder="Enter Name"
                required
                value={title}
                onChange={evt => {
                  onChange(evt.target.value);
                  setTitle(evt.target.value);
                }}
              />
            )}
          />
          <ErrorText errorMsg={errors.title && errors.title.message} />
          <ErrorText errorMsg={uniquename === true ? "name already exist" : null} />
          <div className="mt-10">
            <div className={styles.savedFilters}>
              <Typography variant="body1" mt={0}>
                Selected Filter
              </Typography>
              <div className={styles.filterBox}>
                {filters?.length
                  ? filters?.map(name => (
                      <Chip
                        label={name["text"]}
                        color="info"
                        style={{ margin: "0px 2px", height: "27px" }}
                      />
                    ))
                  : null}
              </div>
              <ErrorText errorMsg={uniquequery === true ? "filter query already exist" : null} />
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              variant="outline"
              onClick={() => {
                onCloseModal();
                clearErrors();
              }}
              className={styles.btn}
            >
              Cancel
            </Button>
            <Button type="submit" onClick={handleSubmit(onSubmit)} className={styles.btn}>
              Create
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default SaveFilterModal;
