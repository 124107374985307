import moment from "moment";
export const logsTimeFormat = "MM-DD-YY";
export function getDifferenceTime(range, now) {
  if (range && range.from) {
    return moment(range.from);
  }
  const diff = parseInt(range?.split(" ")[1]);
  if (range.includes("min"))
    return moment(now)
      .subtract(diff, "minutes")
      .toDate();
  if (range.includes("hour"))
    return moment(now)
      .subtract(diff, "hours")
      .toDate();
  if (range.includes("day"))
    return moment(now)
      .subtract(diff, "days")
      .toDate();
}

export function getTime(date) {
  return new Date(date).getTime();
}

export function clean(obj) {
  Object.keys(obj)?.forEach(k => obj[k]?.length === 0 && delete obj[k]);
  return obj;
}
const getSeverityName = severity => {
  if (["Critical", "CRITICAL"].includes(severity)) {
    return "1,2";
  } else if (["High", "HIGH"].includes(severity)) {
    return "3, 4";
  } else if (["Medium", "MEDIUM"].includes(severity)) {
    return "5,6,7";
  } else if (["Low", "LOW"].includes(severity)) {
    return "8,9";
  } else if (["Informational", "INFORMATIONAL"].includes(severity)) {
    return "10";
  } else {
    return severity;
  }
};
function containsOnlyNumbers(str) {
  return /^\d+$/.test(str);
}
const convertionNumbers = ["HostPID", "HostPPID", "PID", "PPID", "Timestamp"];
export const buildQString = logdDslTag => {
  // const resultMap = {};
  const resultArray = [];
  const tmp = logdDslTag;
  // let orCount = 0;
  if (tmp) {
    tmp?.map(val => {
      const text = val["text"];
      if (Object.keys(val).includes("string")) {
        const arr = val?.string?.split(",");
        const spliterdArr = arr?.map(item => item);
        const res = spliterdArr?.map(item => ({
          field: item?.split(":")[0],
          value: getSeverityName(item?.split(":")[1]),
          op: "match"
        }));
        resultArray.push(res);
      } else {
        if (text === "OR") {
          resultArray.push("OR:");
        } else {
          if (window.location.pathname === "/monitors-logging/logs-v2") {
            const arr = text?.split(" ");
            const key = arr[0];
            const opValue = arr[1];
            // converted string to number for filters
            const valueFormat = convertionNumbers?.includes(key) ? Number(arr[2]) : arr[2];
            resultArray.push({
              field: `${key}`,
              value: valueFormat,
              op: opValue
            });
          } else {
            const arr = text?.split(":");
            const key = arr[0];
            const valueText = text?.replace(`${key}:`, "");
            // converted string to number for filters
            const valueFormat = convertionNumbers?.includes(key) ? Number(valueText) : valueText;
            resultArray.push({
              field: `${key}`,
              value: valueFormat,
              op: "match"
            });
          }
        }
      }
    });
    return resultArray.flat();
  }
};
export const getBorderColorForSeverity = severity => {
  if (["1", "2", "Critical", "CRITICAL"].includes(severity)) {
    return "#ac0a0a";
  } else if (["3", "4", "High", "HIGH"].includes(severity)) {
    return "#f47d57";
  } else if (["5", "6", , "Medium", "MEDIUM"].includes(severity)) {
    return "#e6e958";
  } else if (["7", "8", "Low", "LOW"].includes(severity)) {
    return "#249d6f";
  } else if (["9", "10", "Informational", "INFORMATIONAL"].includes(severity)) {
    return "#118ab2";
  } else {
    return "#118ab2";
  }
};
export const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const splitKeyValue = obj => {
  const keys = Object.keys(obj);
  const res = [];
  for (let i = 0; i < keys.length; i++) {
    res.push({
      Time: keys[i],
      count: obj[keys[i]]
    });
  }
  return res;
};

export const convertLogsData = (data, clusterLogsData, component) => {
  let result = [];
  if (component === "Cilium") {
    result = data?.map(e => {
      return {
        Time: moment
          .unix(e?.time)
          .tz(moment.tz.guess())
          .format(logsTimeFormat),
        Action: e?.Action
      };
    });
  }
  if (component === "KubeArmor") {
    result = data?.map(e => {
      return {
        Time: moment
          .unix(e?.Timestamp)
          .tz(moment.tz.guess())
          .format(logsTimeFormat),
        Action: e.Action
      };
    });
  }

  let clustersValue = clusterLogsData?.map(e => {
    return {
      Time: moment
        .unix(e?.Timestamp)
        .tz(moment.tz.guess())
        .format(logsTimeFormat),
      count: e?.total
    };
  });
  var counter = {};
  result?.forEach(function(obj) {
    var key = obj.Time;
    counter[key] = (counter[key] || 0) + 1;
  });
  let logValue = splitKeyValue(counter);
  const combined = [...clustersValue, ...logValue];

  const combinedResult = combined?.reduce((acc, obj) => {
    const time = obj?.Time;
    const count = obj?.count;
    acc[time] = (acc[time] || 0) + count;
    return acc;
  }, {});

  const resultArr = Object?.entries(combinedResult)?.map(([time, count]) => ({
    Time: time,
    count
  }));

  return resultArr;
};

export const getChannelID = channel_id => {
  switch (channel_id) {
    case "Slack":
      return "1";
    case "Splunk":
      return "2";
    case "Cloudwatch":
      return "3";
    case "ElasticSearch":
      return "4";
    case "Jira":
      return "6";
    case "Rsyslog":
      return "5";
  }
};
export const getChannelIdToName = channel_id => {
  switch (channel_id) {
    case 1:
      return "Slack";
    case 2:
      return "Splunk";
    case 3:
      return "Cloudwatch";
    case 4:
      return "ElasticSearch";
    case 5:
      return "Rsyslog";
    case 6:
      return "Jira";
  }
};
