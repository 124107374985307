import React from "react";
import PropTypes from "prop-types";

export default function Download(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
    >
      <path
        d="M15.8282 7.30533V0H8.52289V7.30533H2.43511L12.1756 17.0458L21.916 7.30533H15.8282ZM0 19.4809H24.3511V21.916H0V19.4809Z"
        fill={props.color}
      />
    </svg>
  );
}

Download.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Download.defaultProps = {
  className: "",
  style: {},
  color: "#000000",
  width: 25,
  height: 22
};
