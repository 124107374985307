import { createAction } from "@reduxjs/toolkit";
import * as constant from "./constants";

// Get Audit Files
export const getAuditFiles = createAction(constant.GET_AUDIT_FILES);
export const getAuditFilesSuccess = createAction(constant.GET_AUDIT_FILES_SUCCESS);
export const getAuditFilesFailed = createAction(constant.GET_AUDIT_FILES_FAILED);

// Get Audit File Labels
export const getAuditFileLabels = createAction(constant.GET_AUDIT_FILE_LABELS);
export const getAuditFileLabelsSuccess = createAction(constant.GET_AUDIT_FILE_LABELS_SUCCESS);
export const getAuditFileLabelsFailed = createAction(constant.GET_AUDIT_FILE_LABELS_FAILED);

// Get Audit File Assets
export const getAuditFileAssets = createAction(constant.GET_AUDIT_FILE_ASSETS);
export const getAuditFileAssetsSuccess = createAction(constant.GET_AUDIT_FILE_ASSETS_SUCCESS);
export const getAuditFileAssetsFailed = createAction(constant.GET_AUDIT_FILE_ASSETS_FAILED);

// Get Audit File Baselines
export const getAuditFileBaselines = createAction(constant.GET_AUDIT_FILE_BASELINES);
export const getAuditFileBaselinesSuccess = createAction(constant.GET_AUDIT_FILE_BASELINES_SUCCESS);
export const getAuditFileBaselinesFailed = createAction(constant.GET_AUDIT_FILE_BASELINES_FAILED);

// Get Audit File History
export const getAuditFileHistory = createAction(constant.GET_AUDIT_FILE_HISTORY);
export const getAuditFileHistorySuccess = createAction(constant.GET_AUDIT_FILE_HISTORY_SUCCESS);
export const getAuditFileHistoryFailed = createAction(constant.GET_AUDIT_FILE_HISTORY_FAILED);

// Set Audit File
export const setAuditFile = createAction(constant.SET_AUDIT_FILE);
export const setAuditFilesSuccess = createAction(constant.SET_AUDIT_FILE_SUCCESS);
export const setAuditFilesFailed = createAction(constant.SET_AUDIT_FILE_FAILED);
