import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "components/simple";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getAzureAccount, updateConnection } from "store/entities/cloudAccounts/actions";
import { getAccountInfo } from "store/entities/cloudAccounts/selectors";
import { labelsMiniSelector } from "store/entities/labels/selectors";
import { tagsMiniSelector } from "store/entities/tags/selectors";
import { getLabelsMini } from "store/entities/labels/actions";
import { getTagsMini } from "store/entities/tags/actions";

import styles from "../styles.module.scss";
import { routes } from "router";

const accountType = {
  value: "Microsoft Azure",
  label: "Microsoft Azure"
};

const EditAzureAccount = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const accountInfo = useSelector(getAccountInfo);
  const labels = useSelector(labelsMiniSelector);
  const tags = useSelector(tagsMiniSelector);

  const [state, setState] = useState({
    subscriptionID: "",
    directoryID: "",
    keyValue: "",
    applicationID: ""
  });
  const [label, setLabel] = useState(null);
  const [tag, setTag] = useState(null);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  const onCancelHandler = () => {
    history.push(routes.settingsCloudAccounts);
  };

  const onSubmit = e => {
    e.preventDefault();
    const data = {
      application_id: state.applicationID,
      key_value: state.keyValue,
      subscription_id: state.subscriptionID,
      directory_id: state.directoryID,
      label: label.value,
      tag: tag?.value
    };
    dispatch(updateConnection({ accountType: "azure", data, id: params.id }));
  };

  useEffect(() => {
    if (!isEmpty(accountInfo)) {
      setState({
        subscriptionID: accountInfo.subscription_id,
        directoryID: accountInfo.directory_id,
        keyValue: accountInfo.key_value,
        applicationID: accountInfo.application_id
      });
    }
  }, [accountInfo]);

  useEffect(() => {
    if (labels.length && accountInfo.label) {
      const labelValue = labels.find(item => item.value === accountInfo.label);
      setLabel(labelValue);
    }
  }, [labels, accountInfo.label]);

  useEffect(() => {
    if (tags.length && accountInfo.tag) {
      const tagValue = tags.find(item => item.value === accountInfo.tag);
      setTag(tagValue);
    }
  }, [tags, accountInfo.tag]);

  useEffect(() => {
    dispatch(getAzureAccount(params.id));
    dispatch(getLabelsMini());
    dispatch(getTagsMini());
  }, []);

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={styles.fieldContainer}>
        <Select label="Cloud Account Type" value={accountType} isDisabled />
      </div>
      <div className={styles.fieldContainer}>
        <Input
          type="text"
          label="Subscription Id"
          value={state.subscriptionID}
          onChange={val => handleChange(val.target.value, "subscriptionID")}
          disabled
          required
        />
        <Input
          type="text"
          label="Directory Id"
          value={state.directoryID}
          onChange={val => handleChange(val.target.value, "directoryID")}
          disabled
          required
        />
      </div>
      <div className={styles.fieldContainer}>
        <Select
          label="Label"
          placeholder="Select the label"
          options={labels}
          value={label}
          onChange={val => setLabel(val)}
          required
        />
        <Select
          label="Tag"
          placeholder="Select the tag"
          options={tags}
          value={tag}
          onChange={val => setTag(val)}
        />
      </div>
      <div className={styles.fieldContainer}>
        <Input
          type="text"
          label="Key Value"
          value={state.keyValue}
          onChange={val => handleChange(val.target.value, "keyValue")}
          required
        />
        <Input
          type="text"
          label="Application Id"
          value={state.applicationID}
          onChange={val => handleChange(val.target.value, "applicationID")}
          required
        />
      </div>
      <div className={styles.btnContainer}>
        <Button variant="outline" onClick={onCancelHandler}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

export default EditAzureAccount;
