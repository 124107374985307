import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  DEFAULT_FILE_FORM_VALUES,
  DEFAULT_NETWORK_FORM_VALUES,
  DEFAULT_POLICY_METADATA_FORM_VALUES,
  DEFAULT_PROCESS_FORM_VALUES,
  FILE,
  K8S,
  KUBEARMOR_POLICY_KINDS,
  NETWORK,
  POLICY_SPEC_PROPERTIES,
  PROCESS,
  VM
} from "./constants";
import {
  resetState,
  updateCurrentPolicy,
  updateFileRuleData,
  updateIsEditingFileRule,
  updateIsEditingNetworkRule,
  updateIsEditingPolicyMetadata,
  updateIsEditingProcessRule,
  updateNetworkRuleData,
  updatePolicyJSON,
  updateProcessRuleData,
  updateSelectedCluster,
  updateSelectedEntity,
  updateSelectedInstance,
  updateSelectedInstanceGroup,
  updateShowFileMandateParentPath,
  updateShowFileRuleCard,
  updateShowNetworkMandateParentPath,
  updateShowNetworkRuleCard,
  updateShowProcessMandateParentPath,
  updateShowProcessRuleCard,
  updateShowRulesPalette,
  updateUnknownPolicyValues,
  updateUnsupportedRules,
  useKubeArmorPolicyEditorState
} from "./slice";
import {
  YamlToJson,
  getRuleFormValues,
  parsePolicyYAMLToComponentState,
  preProcessUploadedYAML,
  uploadFileTypeValidator,
  parseClonePolicyYAMLToComponentState
} from "./helpers";
import { useDispatch } from "react-redux";
import { useKubeArmorPolicyContext } from "screens/PolicyEditor/KubeArmorPolicy";
import { cloneDeep } from "lodash";
import { addNotification } from "../../notifications/actions";
import { useDropzone } from "react-dropzone";
import { NETWORK_POLICY_KINDS } from "store/entities/policyEditor/NetworkPolicyEditor/constants";
import { updateActiveEditorTab } from "..";
import { navigate } from "helper/history";
import { routes } from "router";

export const usePolicyMetadataForm = () => {
  return useForm({
    mode: "onChange",
    defaultValues: DEFAULT_POLICY_METADATA_FORM_VALUES
  });
};
export const useProcessRuleForm = () => {
  return useForm({
    mode: "onChange",
    defaultValues: DEFAULT_PROCESS_FORM_VALUES
  });
};
export const useFileRuleForm = () => {
  return useForm({
    mode: "onChange",
    defaultValues: DEFAULT_FILE_FORM_VALUES
  });
};
export const useNetworkRuleForm = () => {
  return useForm({
    mode: "onChange",
    defaultValues: DEFAULT_NETWORK_FORM_VALUES
  });
};

export const useEditIndividualRule = () => {
  const dispatch = useDispatch();
  const { processRuleForm, fileRuleForm, networkRuleForm } = useKubeArmorPolicyContext();
  const { processRuleData } = useKubeArmorPolicyEditorState("processRule");
  const { fileRuleData } = useKubeArmorPolicyEditorState("fileRule");
  const { networkRuleData } = useKubeArmorPolicyEditorState("networkRule");
  const policyJSON = useKubeArmorPolicyEditorState("policyJSON");

  const editIndividualRule = (ruleType, rule, index) => {
    const isProcessRule = ruleType === PROCESS.toLowerCase();
    const isFileRule = ruleType === FILE.toLowerCase();
    const isNetworkRule = ruleType === NETWORK.toLowerCase();

    const newPolicyJSON = cloneDeep(policyJSON);
    const formValues = getRuleFormValues(rule, ruleType);

    const deleteRule = (RType, primaryKey, secondaryKey, type) => {
      if (
        newPolicyJSON?.spec?.[RType]?.[primaryKey]?.length === 1 &&
        !newPolicyJSON?.spec?.[RType]?.[secondaryKey]
      ) {
        // this block will execute if there is one 'path'/'dir' and there is no 'dir'/'path'
        delete newPolicyJSON?.spec?.[RType];
        if (isProcessRule) {
          dispatch(updateProcessRuleData([]));
        } else {
          dispatch(updateFileRuleData([]));
        }
      } else if (newPolicyJSON?.spec?.[RType]?.[primaryKey]?.length === 1) {
        // this block will execute if both dir and path present
        delete newPolicyJSON?.spec?.[RType]?.[primaryKey];
        if (isProcessRule) {
          dispatch(updateProcessRuleData([...newPolicyJSON?.spec[RType][secondaryKey]]));
        } else {
          dispatch(updateFileRuleData([...newPolicyJSON?.spec[RType][secondaryKey]]));
        }
      } else {
        // this will execute if multiple 'path'/'dir' present
        let tmp = isProcessRule ? cloneDeep(processRuleData) : cloneDeep(fileRuleData);
        tmp.splice(index, 1);
        if (isProcessRule) {
          dispatch(updateProcessRuleData(tmp));
        } else {
          dispatch(updateFileRuleData(tmp));
        }

        tmp = tmp.filter(each => each[type]);
        newPolicyJSON.spec[RType][primaryKey] = tmp;
      }
    };

    if (isProcessRule || isFileRule) {
      if (isProcessRule) {
        processRuleForm.reset(formValues);
        if (formValues?.fromSource?.length) {
          dispatch(updateShowProcessMandateParentPath(true));
        }
        dispatch(updateIsEditingProcessRule(true));
      } else if (isFileRule) {
        fileRuleForm.reset(formValues);
        if (formValues?.fromSource?.length) {
          dispatch(updateShowFileMandateParentPath(true));
        }
        dispatch(updateIsEditingFileRule(true));
      }

      if (rule?.path) {
        deleteRule(ruleType, "matchPaths", "matchDirectories", "path");
      } else if (rule?.dir) {
        deleteRule(ruleType, "matchDirectories", "matchPaths", "dir");
      }
    }

    if (isNetworkRule) {
      networkRuleForm.reset(formValues);
      if (formValues?.fromSource?.length) {
        dispatch(updateShowNetworkMandateParentPath(true));
      }
      dispatch(updateIsEditingNetworkRule(true));

      if (newPolicyJSON?.spec?.[ruleType]?.matchProtocols?.length === 1) {
        delete newPolicyJSON?.spec?.[ruleType];
        dispatch(updateNetworkRuleData([]));
      } else {
        newPolicyJSON?.spec?.[ruleType]?.matchProtocols?.splice(index, 1);
        const newNetworkRuleData = cloneDeep(networkRuleData);
        newNetworkRuleData?.splice(index, 1);
        dispatch(updateNetworkRuleData(newNetworkRuleData));
      }
    }

    dispatch(updatePolicyJSON(newPolicyJSON));
  };

  return editIndividualRule;
};

export const useResetComponentState = () => {
  const dispatch = useDispatch();
  const {
    policyMetadataForm,
    processRuleForm,
    fileRuleForm,
    networkRuleForm
  } = useKubeArmorPolicyContext();

  return () => {
    // Reset slice state
    dispatch(resetState());

    // Reset all forms state
    policyMetadataForm.reset(DEFAULT_POLICY_METADATA_FORM_VALUES);
    processRuleForm.reset(DEFAULT_PROCESS_FORM_VALUES);
    fileRuleForm.reset(DEFAULT_FILE_FORM_VALUES);
    networkRuleForm.reset(DEFAULT_NETWORK_FORM_VALUES);
  };
};

export const useProcessUploadKubeArmorPolicyYAML = () => {
  const dispatch = useDispatch();
  const resetComponentState = useResetComponentState();
  const { policyMetadataForm } = useKubeArmorPolicyContext();

  const processYAML = policyYAML => {
    try {
      resetComponentState();

      const policyJSON = YamlToJson(policyYAML);

      // Sanity check for unsupported or unknown policy rules or kinds
      if (!KUBEARMOR_POLICY_KINDS.includes(policyJSON?.kind)) {
        if (NETWORK_POLICY_KINDS.includes(policyJSON?.kind)) {
          dispatch(updateActiveEditorTab(1));
          navigate(routes.policyEditor, {
            policyYAMLFromKubeArmorPolicyEditor: policyYAML
          });
        } else {
          dispatch(
            addNotification({
              type: "error",
              msg: "Policy type is not supported"
            })
          );
        }
        resetComponentState();
        return;
      }

      const unsupportedRules = Object.keys(policyJSON?.spec).filter(
        rule => !POLICY_SPEC_PROPERTIES.includes(rule)
      );
      if (unsupportedRules?.length) {
        dispatch(updateUnsupportedRules(unsupportedRules));
      }

      const {
        updatedPolicyJSON,
        metadataFormValues,
        unknownPolicyValues,
        processRuleData,
        fileRuleData,
        networkRuleData
      } = preProcessUploadedYAML(policyJSON);
      dispatch(updateUnknownPolicyValues(unknownPolicyValues));

      if (updatedPolicyJSON) {
        dispatch(updatePolicyJSON(updatedPolicyJSON));
        dispatch(updateShowRulesPalette(true));
      }

      if (processRuleData?.length) {
        dispatch(updateShowProcessRuleCard(true));
        dispatch(updateProcessRuleData(processRuleData));
        dispatch(updateIsEditingProcessRule(false));
      }

      if (fileRuleData?.length) {
        dispatch(updateShowFileRuleCard(true));
        dispatch(updateFileRuleData(fileRuleData));
        dispatch(updateIsEditingFileRule(false));
      }

      if (networkRuleData?.length) {
        dispatch(updateShowNetworkRuleCard(true));
        dispatch(updateNetworkRuleData(networkRuleData));
        dispatch(updateIsEditingNetworkRule(false));
      }

      // Popuplate form values, trigger validation and set the form in-focus
      policyMetadataForm.reset(metadataFormValues);
      policyMetadataForm.trigger();
      policyMetadataForm.setFocus("policyName");
    } catch (error) {
      resetComponentState();
      dispatch(
        addNotification({ type: "error", msg: "There was an error while processing the policy" })
      );
    }
  };

  return { processYAML };
};

export const useUploadPolicyDropzone = () => {
  const { processYAML } = useProcessUploadKubeArmorPolicyYAML();
  const dropzoneProps = useDropzone({
    accept: {
      "text/html": [".yaml", ".yml"]
    },
    noDrag: true,
    maxFiles: 1,
    multiple: false,
    validator: uploadFileTypeValidator
  });
  const { acceptedFiles } = dropzoneProps;

  useEffect(() => {
    if (acceptedFiles?.length) {
      const file = acceptedFiles?.[0];
      const fileExt = file?.path?.split(".").pop();

      Object.assign(file, {
        preview: URL.createObjectURL(file)
      });

      if (fileExt === "yml" || fileExt === "yaml") {
        fetch(file?.preview)
          .then(r => r.text())
          .then(fileContents => {
            processYAML(fileContents);
          });
      }
    }
  }, [acceptedFiles?.length]);

  return dropzoneProps;
};

export const useUpdateComponentState = () => {
  const dispatch = useDispatch();
  const { policyMetadataForm } = useKubeArmorPolicyContext();
  const resetComponentState = useResetComponentState();

  const updateComponentState = (policyContent, policy) => {
    // reset the state whenever user starts editing
    resetComponentState();
    dispatch(updateCurrentPolicy(policy));

    // use the output of this to set default values of the form
    const policyInJSON = YamlToJson(policyContent);
    const formValues = parsePolicyYAMLToComponentState(policyInJSON, policy);
    policyMetadataForm.reset(formValues);
    if (policy?.type === K8S) {
      dispatch(updateSelectedEntity(K8S));
      dispatch(updateSelectedCluster(policy?.cluster));
    } else if (policy?.type === VM) {
      dispatch(updateSelectedEntity(VM));
      dispatch(updateSelectedInstanceGroup(policy?.vm_instance_group));
      dispatch(updateSelectedInstance(policy?.vm_instance));
    }
    dispatch(updatePolicyJSON(policyInJSON));
    dispatch(updateIsEditingPolicyMetadata(false));
    dispatch(updateShowRulesPalette(true));

    const process = policyInJSON?.spec?.process;
    if (process) {
      dispatch(updateShowProcessRuleCard(true));
      dispatch(
        updateProcessRuleData([
          ...(process?.matchDirectories || []),
          ...(process?.matchPaths || [])
        ])
      );
      dispatch(updateIsEditingProcessRule(false));
    }

    const file = policyInJSON?.spec?.file;
    if (file) {
      dispatch(updateShowFileRuleCard(true));
      dispatch(
        updateFileRuleData([...(file?.matchDirectories || []), ...(file?.matchPaths || [])])
      );
      dispatch(updateIsEditingFileRule(false));
    }

    const network = policyInJSON?.spec?.network;
    if (network) {
      dispatch(updateShowNetworkRuleCard(true));
      dispatch(updateNetworkRuleData([...(network?.matchProtocols || [])]));
      dispatch(updateIsEditingNetworkRule(false));
    }
  };

  return updateComponentState;
};
export const useCloneComponentState = () => {
  const dispatch = useDispatch();
  const { policyMetadataForm } = useKubeArmorPolicyContext();
  const resetComponentState = useResetComponentState();

  const updateComponentState = (policyContent, policy) => {
    // reset the state whenever user starts editing
    resetComponentState();
    dispatch(updateCurrentPolicy(policy));

    // use the output of this to set default values of the form
    const policyInJSON = YamlToJson(policyContent);
    const formValues = parseClonePolicyYAMLToComponentState(policyInJSON, policy);
    policyMetadataForm.reset(formValues);
    dispatch(updateSelectedEntity(K8S));
    dispatch(
      updateSelectedCluster({
        id: policy?.cluster_id,
        name: policy?.cluster_name
      })
    );

    dispatch(updatePolicyJSON(policyInJSON));

    dispatch(updateIsEditingPolicyMetadata(true));
    dispatch(updateShowRulesPalette(true));

    const process = policyInJSON?.spec?.process;
    if (process) {
      dispatch(updateShowProcessRuleCard(true));
      dispatch(
        updateProcessRuleData([
          ...(process?.matchDirectories || []),
          ...(process?.matchPaths || [])
        ])
      );
      dispatch(updateIsEditingProcessRule(false));
    }

    const file = policyInJSON?.spec?.file;
    if (file) {
      dispatch(updateShowFileRuleCard(true));
      dispatch(
        updateFileRuleData([...(file?.matchDirectories || []), ...(file?.matchPaths || [])])
      );
      dispatch(updateIsEditingFileRule(false));
    }

    const network = policyInJSON?.spec?.network;
    if (network) {
      dispatch(updateShowNetworkRuleCard(true));
      dispatch(updateNetworkRuleData([...(network?.matchProtocols || [])]));
      dispatch(updateIsEditingNetworkRule(false));
    }
  };

  return updateComponentState;
};
