import { Position, internalsSymbol } from "reactflow";
import moment from "moment";

export const formatDate = date => moment.unix(date).format("MM/DD/YYYY HH:mm A");

function circleXY(id, radius, centerX, centerY) {
  const degrees = id;
  const radians = degrees * (Math.PI / 90);
  const xp = radius * Math.cos(radians) + centerX;
  const yp = radius * Math.sin(radians) + centerY;
  return { xp, yp };
}

function getNodeIntersection(intersectionNode, targetNode, check, diff) {
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition
  } = intersectionNode;
  const targetPosition = targetNode.positionAbsolute;
  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const r = intersectionNodeWidth / 2;
  const sq = (x1 - x2) ** 2 + (y1 - y2) ** 2;
  const t = r / Math.sqrt(sq);
  let x = t * (x1 - x2) + x2;
  let y = t * (y1 - y2) + y2;
  if (check && diff) {
    const { xp, yp } = circleXY(diff, r, x2, y2);
    x = xp;
    y = yp;
  }
  return { x, y };
}

function getHandleCoordsByPosition(node, handlePosition) {
  // all handles are from type source, that's why we use handleBounds.source here
  const handle = node[internalsSymbol].handleBounds.source.find(h => h.position === handlePosition);

  let offsetX = handle.width / 2;
  let offsetY = handle.height / 2;

  switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle.height;
      break;
    default:
      offsetX = 0;
  }

  const x = node.positionAbsolute.x + handle.x + offsetX;
  const y = node.positionAbsolute.y + handle.y + offsetY;

  return [x, y];
}

function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);
  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }
  return Position.Top;
}

export function getEdgeParams(source, target, check, diff) {
  const sourceIntersectionPoint = getNodeIntersection(source, target, check, diff);
  const targetIntersectionPoint = getNodeIntersection(target, source, check, diff + 37);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos
  };
}

const SecondaryHeadNodeStyle = {
  display: "flex",
  textAlign: "center",
  fontSize: "10px",
  border: "1px solid #D7DBDD",
  justifyContent: "center",
  alignItems: "center",
  height: "50px",
  width: "50px",
  borderRadius: "50%",
  zIndex: "1"
};

const SecondarySubNodeStyle = {
  display: "flex",
  justifyContent: "center",
  border: "1px solid #D7DBDD",
  fontSize: "10px",
  alignItems: "center",
  height: "50px",
  width: "50px",
  borderRadius: "50%"
};

export const createNode = data => {
  const { nodes, edges } = data;
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  function calculatePosition(type, id, count) {
    if (type === "parent") {
      return center;
    }
    const degrees = id * (360 / count);
    const radians = degrees * (Math.PI / 180);
    let x = 0;
    let y = 0;
    if (count > 25) {
      x = count * 12 * Math.cos(radians) + center.x;
      y = count * 12 * Math.sin(radians) + center.y;
    } else {
      x = 350 * Math.cos(radians) + center.x;
      y = 350 * Math.sin(radians) + center.y;
    }
    return { x, y };
  }
  function getStyle(type) {
    if (type === "PrimaryNode") {
      return {
        display: "flex",
        textAlign: "center",
        fontSize: "10px",
        border: "1px solid #D7DBDD",
        justifyContent: "center",
        height: "80px",
        width: "80px",
        borderRadius: "50%"
      };
    }
    return {
      display: "flex",
      justifyContent: "center",
      border: "1px solid #D7DBDD",
      fontSize: "10px",
      alignItems: "center",
      height: "50px",
      width: "50px",
      borderRadius: "50%"
    };
  }
  nodes.map(e => {
    e.position = calculatePosition(e.type, e.id, nodes.length);
    e.id = e.type === "parent" ? "parent" : e.id.toString();
    e.type = e.type === "parent" ? "PrimaryNode" : "PrimarySubNode";
    // eslint-disable-next-line no-nested-ternary
    e.data.imageName = e.data.ip
      ? // eslint-disable-next-line no-nested-ternary
        e?.data?.ip.includes("svc")
        ? "svc"
        : e.data.ip.includes(".")
        ? "global"
        : "pod"
      : "pod";
    e.style = getStyle(e.type);
    return e;
  });

  edges.map((e, i) => {
    e.id = e.label.includes("ingress") ? `edge-${e.source}` : `edge-${e.target}`;
    e.source = e.label.includes("ingress") ? e.source.toString() : "parent";
    if (e.label.includes("ingress") || e.label.includes("bind")) {
      e.target = "parent";
      let filterNodes = nodes.find(node => node.id === e.source);
      e.data = {
        ...e.data,
        count: filterNodes?.data.count,
        source: filterNodes?.data.source
      };
    } else {
      e.target = e.target.toString();
      let filterNodes = nodes.find(node => node.id === e.target);
      e.data = {
        ...e.data,
        count: filterNodes?.data.count,
        source: filterNodes?.data.source
      };
    }
    e.style = {
      stroke: "#0500FF",
      strokeDasharray: "5",
      fill: "none",
      pointerEvents: "stroke"
    };
    e.type = "floating";
    if (e.label.includes("bind")) {
      e.data.bindId = i + 1;
      e.markerEnd = {
        type: "arrow",
        width: 20,
        height: 20,
        orient: "auto",
        strokeWidth: 2,
        color: "#0500FF"
      };
    }
    return e;
  });
  return { nodes, edges };
};
const SecondaryNodeStyle = {
  display: "flex",
  justifyContent: "center",
  border: "1px solid #D7DBDD",
  fontSize: "10px",
  alignItems: "center",
  height: "250px",
  width: "250px",
  borderRadius: "50%"
};

function getParentNode(edges, id) {
  let parentNode;
  edges.forEach(e => {
    if (e.target === id) {
      parentNode = e.source;
    } else if (e.source === id) {
      parentNode = e.target;
    }
  });
  return parentNode;
}

export const secondaryNode = data => {
  const { nodes } = data;
  const { edges } = data;
  const center = { x: 100, y: 100 };
  let k = 1;
  let j = 0;
  // eslint-disable-next-line no-shadow
  const secondaryNode = {
    id: "1a",
    type: "SecondaryNode",
    style: SecondaryNodeStyle,
    position: center,
    data: {
      previous: false
    }
  };
  nodes.push(secondaryNode);
  nodes.forEach(e => {
    e.draggable = false;
    if (e.type === "parent") {
      e.type = "SecondaryHeadNode";
      const steps = k * 6;
      // angular distance between elements
      const angle_range = (2 * Math.PI) / steps;
      // every circle is bigger then the previuos of the same amount
      const radius = k * 70;
      const angle = j * angle_range;
      const x = Math.round(center.x + radius * Math.cos(angle));
      const y = Math.round(center.y + radius * Math.sin(angle));
      // eslint-disable-next-line no-plusplus
      j++;
      // e.extent= 'parent';
      e.parentNode = "1a";
      if (j >= steps) {
        // eslint-disable-next-line no-plusplus
        k++;
        j = 0;
      }
      e.position = { x, y };
      e.data.freeze = false;
      e.style = SecondaryHeadNodeStyle;
      e.id = e.id.toString();
    }
    if (e.type === "child") {
      // const child = nodes.filter(node => node.type === "child");
      e.type = "SecondarySubNode";
      const degrees = (parseInt(e.id, 10) + 2) * (360 / nodes.length);
      const radians = degrees * (Math.PI / 180);
      // const calcRadius = nodes.length ** nodes.length;
      const x = 350 * Math.cos(radians) + center.x;
      const y = 350 * Math.sin(radians) + center.y;
      e.position = { x, y };
      e.style = SecondarySubNodeStyle;
      // eslint-disable-next-line no-nested-ternary
      e.data.imageName = e.data.ip
        ? // eslint-disable-next-line no-nested-ternary
          e.data.ip.includes("svc")
          ? "svc"
          : e.data.ip.includes(".")
          ? "global"
          : "pod"
        : "pod";
      e.data.parentNode = getParentNode(edges, e.id).toString();
      e.data.transparent = true;
      e.id = e.id.toString();
      e.parentNode = "1a";
      if (x > 155) {
        e.data.toolPos = "right";
      } else {
        e.data.toolPos = "left";
      }
    }
  });
  edges.forEach((e, i) => {
    e.data.parentNode = e.label.includes("ingress") ? e.target.toString() : e.source.toString();
    e.data.label = e.label;
    e.data.transparent = true;
    e.target = e.label.includes("ingress") ? e.source.toString() : e.target.toString();
    if (!e.label.includes("bind")) {
      e.source = "1a";
    }
    e.style = {
      stroke: "#14FF00",
      strokeWidth: 2,
      strokeDasharray: "5 1",
      pointerEvents: "all",
      fill: "none"
    };
    e.type = "secondaryConnection";
    if (e.label.includes("bind")) {
      e.source = e.target;
      e.data.bindId = i + 1;
      e.markerEnd = {
        type: "arrow",
        width: 15,
        height: 15,
        orient: "auto",
        strokeWidth: 2,
        color: "#14FF00"
      };
    }
  });
  return { nodes, edges };
};

export function keyValueChange(key) {
  if (key === "ip") {
    return "Destination IP/SVC/POD";
  } else if (key === "destination") {
    return "File Path Accessed";
  } else if (key === "action") {
    return "Status";
  } else if (key === "source") {
    return "Source Pod";
  } else {
    return key;
  }
}
