/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, put, remove } from "api/base";

const generatorFilterUrl = obj => {
  if (!obj) {
    return "";
  }
  let url = "";

  Object.entries(obj).forEach(([key, val]) => {
    if (key === "vulnerability") {
      url += `&findings_id=${val?.value || ""}`;
    }
    if (key === "baseline") {
      url += `&baseline_id=${val?.value || ""}`;
    }
    if (key === "scan") {
      url += `&scan_id=${val?.value || ""}`;
    }
  });
  return url;
};

export const getNotifications = fields => {
  const { page, pageSize, ordering, search, filters } = fields;
  const filt = generatorFilterUrl(filters);

  let url = "";
  if (ordering) {
    url += `&ordering=${ordering}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  return get(`${API}/notifications?page=${page + 1 || 1}&page_size=${pageSize}${url}${filt}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getNotification = id => {
  return get(`${API}/notifications/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const updateNotification = fields => {
  const { id, data } = fields;
  return put(`${API}/notifications/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteNotification = id => {
  return remove(
    `${API}/notifications/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getVulnerabilitiesMini = fields => {
  const { page, pageSize, ordering, search } = fields;

  return get(
    `${API}/findings-mini?page=${page + 1 ||
      1}&ordering=${ordering}&search=${search}&page_size=${pageSize}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};
