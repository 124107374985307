import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

export const setVulnerabilitiesParams = createAction(constant.SET_PARAMS_VULNERABILITIES);

export const updVulnerabilitiesParams = createAction(constant.UPD_PARAMS_VULNERABILITIES);

// Get Vulnerabilities
export const getVulnerabilities = createAction(constant.GET_VULNERABILITIES);
export const getVulnerabilitiesSuccess = createAction(constant.GET_VULNERABILITIES_SUCCESS);
export const getVulnerabilitiesFailed = createAction(constant.GET_VULNERABILITIES_FAILED);

// Get one vulnerability
export const getVulnerability = createAction(constant.GET_VULNERABILITY);
export const getVulnerabilitySuccess = createAction(constant.GET_VULNERABILITY_SUCCESS);
export const getVulnerabilityFailed = createAction(constant.GET_VULNERABILITY_FAILED);

// Edit vulnerability
export const editVulnerability = createAction(constant.EDIT_VULNERABILITY);
export const editVulnerabilitySuccess = createAction(constant.EDIT_VULNERABILITY_SUCCESS);
export const editVulnerabilityFailed = createAction(constant.EDIT_VULNERABILITY_FAILED);

// Get vulnerability risk factors (options)
export const getVulnerabilityRiskFactors = createAction(constant.GET_VULNERABILITY_RISK_FACTORS);
export const getVulnerabilityRiskFactorsSuccess = createAction(
  constant.GET_VULNERABILITY_RISK_FACTORS_SUCCESS
);
export const getVulnerabilityRiskFactorsFailed = createAction(
  constant.GET_VULNERABILITY_RISK_FACTORS_FAILED
);

// Get vulnerability history
export const getVulnerabilityHistory = createAction(constant.GET_VULNERABILITY_HISTORY);
export const getVulnerabilityHistorySuccess = createAction(
  constant.GET_VULNERABILITY_HISTORY_SUCCESS
);
export const getVulnerabilityHistoryFailed = createAction(
  constant.GET_VULNERABILITY_HISTORY_FAILED
);

// Get vulnerability assets
export const getVulnerabilityAssets = createAction(constant.GET_VULNERABILITY_ASSETS);
export const getVulnerabilityAssetsSuccess = createAction(
  constant.GET_VULNERABILITY_ASSETS_SUCCESS
);
export const getVulnerabilityAssetsFailed = createAction(constant.GET_VULNERABILITY_ASSETS_FAILED);

// GET vulnerability configs
export const getVulnerabilityConfigs = createAction(constant.GET_VULNERABILITY_CONFIGS);
export const getVulnerabilityConfigsSuccess = createAction(
  constant.GET_VULNERABILITY_CONFIGS_SUCCESS
);
export const getVulnerabilityConfigsFailed = createAction(
  constant.GET_VULNERABILITY_CONFIGS_FAILED
);

export const getVulnerabilityConfigsFiltersData = createAction(
  constant.GET_VULNERABILITY_CONFIGS_FILTERS_DATA
);
export const getVulnerabilityConfigsFiltersDataSuccess = createAction(
  constant.GET_VULNERABILITY_CONFIGS_FILTERS_DATA_SUCCESS
);
export const getVulnerabilityConfigsFiltersDataFailed = createAction(
  constant.GET_VULNERABILITY_CONFIGS_FILTERS_DATA_FAILED
);

// EDIT vulnerability configs
export const editVulnerabilityConfigs = createAction(constant.EDIT_VULNERABILITY_CONFIGS);
export const editVulnerabilityConfigsSuccess = createAction(
  constant.EDIT_VULNERABILITY_CONFIGS_SUCCESS
);
export const editVulnerabilityConfigsFailed = createAction(
  constant.EDIT_VULNERABILITY_CONFIGS_FAILED
);

// POST vulnerability configs
export const postVulnerabilityConfigs = createAction(constant.POST_VULNERABILITY_CONFIGS);
export const postVulnerabilityConfigsSuccess = createAction(
  constant.POST_VULNERABILITY_CONFIGS_SUCCESS
);
export const postVulnerabilityConfigsFailed = createAction(
  constant.POST_VULNERABILITY_CONFIGS_FAILED
);

// delete vulnerability configs
export const deleteVulnerabilityConfigs = createAction(constant.DELETE_VULNERABILITY_CONFIGS);
export const deletetVulnerabilityConfigsSuccess = createAction(
  constant.DELETE_VULNERABILITY_CONFIGS_SUCCESS
);
export const deleteVulnerabilityConfigsFailed = createAction(
  constant.DELETE_VULNERABILITY_CONFIGS_FAILED
);

// Edit finding
export const editFinding = createAction(constant.EDIT_FINDING);
export const editFindingSuccess = createAction(constant.EDIT_FINDING_SUCCESS);
export const editFindingFailed = createAction(constant.EDIT_FINDING_FAILED);

// Edit finding asset
export const editFindingAsset = createAction(constant.EDIT_FINDING_ASSET);
export const editFindingAssetSuccess = createAction(constant.EDIT_FINDING_ASSET_SUCCESS);
export const editFindingAssetFailed = createAction(constant.EDIT_FINDING_ASSET_FAILED);

// Get finding statuses (options)
export const getFindingStatuses = createAction(constant.GET_FINDING_STATUSES);
export const getFindingStatusesSuccess = createAction(constant.GET_FINDING_STATUSES_SUCCESS);
export const getFindingStatusesFailed = createAction(constant.GET_FINDING_STATUSES_FAILED);

// MODAL ACTIONS
export const actionTargetModal = createAction(constant.TARGET_MODAL);
export const actionGroupModal = createAction(constant.GROUP_MODAL);
export const actionTagModal = createAction(constant.TAG_MODAL);

// ACTIONS

// GET TAGS
export const getTags = createAction(constant.GET_TAGS);
export const getTagsSuccess = createAction(constant.GET_TAGS_SUCCESS);
export const getTagsFailed = createAction(constant.GET_TAGS_FAILED);

// ADD TAG
export const addTag = createAction(constant.ADD_TAG);
export const addTagSuccess = createAction(constant.ADD_TAG_SUCCESS);
export const addTagFailed = createAction(constant.ADD_TAG_FAILED);

// GET GROUP
export const getGroups = createAction(constant.GET_TO_GROUP);
export const getGroupsSuccess = createAction(constant.GET_TO_GROUP_SUCCESS);
export const getGroupsFailed = createAction(constant.GET_TO_GROUP_FAILED);

// ADD GROUP
export const addGroup = createAction(constant.ADD_TO_GROUP);
export const addGroupSuccess = createAction(constant.ADD_TO_GROUP_SUCCESS);
export const addGroupFailed = createAction(constant.ADD_TO_GROUP_FAILED);

// GET TARGET
export const getTargets = createAction(constant.GET_TO_TARGET);
export const getTargetsSuccess = createAction(constant.GET_TO_TARGET_SUCCESS);
export const getTargetsFailed = createAction(constant.GET_TO_TARGET_FAILED);

// GET TARGET TYPES
export const getTargetsTypes = createAction(constant.GET_TO_TARGET_TYPES);
export const getTargetsTypesSuccess = createAction(constant.GET_TO_TARGET__TYPES_SUCCESS);
export const getTargetsTypesFailed = createAction(constant.GET_TO_TARGET__TYPES_FAILED);

// ADD TARGET
export const addTarget = createAction(constant.ADD_TO_TARGET);
export const addTargetSuccess = createAction(constant.ADD_TO_TARGET_SUCCESS);
export const addTargetFailed = createAction(constant.ADD_TO_TARGET_FAILED);

// IGNORE FINDING
export const ignoreFinding = createAction(constant.IGNORE_FINDING);
export const ignoreFindingSuccess = createAction(constant.IGNORE_FINDING_SUCCESS);
export const ignoreFindingFailed = createAction(constant.IGNORE_FINDING_FAILED);

// EXPORT
export const exportVulnerability = createAction(constant.EXPORT_VULNERABILITY);
export const exportVulnerabilitySuccess = createAction(constant.EXPORT_VULNERABILITY_SUCCESS);
export const exportVulnerabilityFailed = createAction(constant.EXPORT_VULNERABILITY_FAILED);

// UPD STATUS
export const updateStatusVulnerability = createAction(constant.UPD_STATUS_VULNERABILITY);
export const updateStatusVulnerabilitySuccess = createAction(
  constant.UPD_STATUS_VULNERABILITY_SUCCESS
);
export const updateStatusVulnerabilityFailed = createAction(
  constant.UPD_STATUS_VULNERABILITY_FAILED
);

// UPD CURRENT VULNERABILITY
export const updateCurrentVulnerability = createAction(constant.UPD_CURRENT_VULNERABILITY);
export const updateCurrentVulnerabilitySuccess = createAction(
  constant.UPD_CURRENT_VULNERABILITY_SUCCESS
);
export const updateCurrentVulnerabilityFailed = createAction(
  constant.UPD_CURRENT_VULNERABILITY_FAILED
);
