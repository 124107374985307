import { Button, ButtonGroup, Tooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";

export const ToggleView = ({ isListView, actions, style, toolTipTitle }) => (
  <ButtonGroup className={style}>
    <Button
      sx={{ width: "100px" }}
      type="button"
      onClick={actions.setAsListView}
      variant={isListView ? "contained" : "outlined"}
    >
      List
    </Button>
    {toolTipTitle ? (
      <Tooltip title={toolTipTitle} placement="top" arrow>
        <Button
          sx={{ width: "100px" }}
          onClick={actions.setAsGraphView}
          variant={!isListView ? "contained" : "outlined"}
        >
          Graph
        </Button>
      </Tooltip>
    ) : (
      <Button
        sx={{ width: "100px" }}
        onClick={actions.setAsGraphView}
        variant={!isListView ? "contained" : "outlined"}
      >
        Graph
      </Button>
    )}
  </ButtonGroup>
);

export const Toggle = styled.div`
  width: 100%;
  float: right;
  height: 38px;
  .viewtype {
    border: 0.5px solid #bbb9b9;
    text-align: center;
    font-size: 14px;
    padding: 5px;
    font-weight: 500;
    background-color: #fff;

    &:hover {
      background-color: rgba(249, 250, 251, 1);
      color: #000;
    }
    :focus {
      outline: none;
    }
  }

  .viewtype.list {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 0;
  }
  .viewtype.graph {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 0;
  }

  .selected {
    /* background: #f3f3f7; */
    background: #05147d !important;
    color: #fff !important;
    border-color: #05147d;
    :focus {
      outline: none;
    }
  }
  .zINdex {
    z-index: -1;
  }
`;
