import { createDraftSafeSelector } from "@reduxjs/toolkit";

const auditFiles = state => state.auditFiles;
const auditFile = state => state.auditFiles.auditFile;

export const getAuditFilesSelector = createDraftSafeSelector(auditFiles, state => {
  if (state?.auditFiles?.length) {
    return state?.auditFiles.map(elem => ({ label: elem, value: elem }));
  }
  return [];
});

export const getAuditFileDataSelector = createDraftSafeSelector(auditFile, state => {
  if (state?.labels?.length < 0) {
    return { labels: [] };
  }
  if (state?.assets?.length < 0) {
    return { assets: [] };
  }
  if (state?.baselines?.length < 0) {
    return { baselines: [] };
  }
  if (state?.history?.length < 0) {
    return { history: [] };
  }

  const { labels, assets, baselines, history } = state;

  const formatLabels = labels.map(elem => ({
    id: elem?.id,
    title: elem?.name,
    counter: elem?.baselines_count
  }));

  const formatAssets = assets?.map(elem => ({
    id: elem?.id,
    title: elem?.title,
    counter: elem?.baselines_count
  }));

  const formatBaselines = baselines?.map(elem => ({
    id: elem?.id,
    title: elem?.name,
    counter: elem?.assets_count
  }));

  const formatHistory = history?.map(elem => ({
    id: elem?.id,
    date: elem?.scan_date,
    title: elem?.scan_file,
    counter: elem?.assets_count
  }));

  return {
    labels: formatLabels,
    assets: formatAssets,
    baselines: formatBaselines,
    history: formatHistory
  };
});
