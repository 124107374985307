import React, { useState } from "react";

import { uniqueId } from "lodash";
import { Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import { Table } from "components/complex";
import { ContentContainer } from "components/simple";
import { customStyle, sensitiveDataHeaders } from "./utils";
import { useGetImageDetails } from "store/entities/imageDetails/queries";

export const SensitiveData = ({ assetId }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: sensitiveData = [],
    isLoading: isSensitiveDataLoading,
    refetch: refetchSensitiveData
  } = useGetImageDetails("SENSITIVE_DATA", assetId);

  const apiRef = useGridApiRef();

  return (
    <ContentContainer className="p-4 mt-2">
      <div className="mt-8">
        <Typography variant="h4" fontSize={18} fontWeight={600}>
          RSA private Key
        </Typography>
      </div>
      <div className="mt-3">
        <Table
          page={page}
          apiRef={apiRef}
          pageSize={pageSize}
          checkboxVisible={false}
          customStyles={customStyle}
          onPageChange={p => setPage(p)}
          columns={sensitiveDataHeaders}
          data={sensitiveData}
          getRowId={row => uniqueId()}
          rowCount={sensitiveData?.length}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          getRowClassName={params => `super-app-theme--sensitiveData--${params.row.severity}`}
        />
      </div>
    </ContentContainer>
  );
};
