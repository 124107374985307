export const Severity = [
  { name: "Severity 1", color: "#FD0000" },
  { name: "Severity 2", color: "#FD3000" },
  { name: "Severity 3", color: "#FD6800" },
  { name: "Severity 4", color: "#FD8900" },
  { name: "Severity 5", color: "#FDBA00" },
  { name: "Severity 6", color: "#FDD400" },
  { name: "Severity 7", color: "#f6d20b" },
  { name: "Severity 8", color: "#3fb46b" },
  { name: "Severity 9", color: "#00FDA9" },
  { name: "Severity 10", color: "#00A6FD" }
];

// export const SeverityColors = [
//   "#FD0000",
//   "#FD3000",
//   "#FD6800",
//   "#FD8900",
//   "#FDBA00",
//   "#FDD400",
//   "#EBFD00",
//   "#7FFD00",
//   "#00FDA9",
//   "#00A6FD"
// ];

export const tagKeys = [
  { name: "NIST", color: "#9B72F3" },
  { name: "MITRE", color: "#72F3A6" },
  { name: "PCI-DSS", color: "#F37272" },
  { name: "CIS", color: "#76fc33" },
  { name: "T1003", color: "#69907f" },
  { name: "sysrv-hello", color: "#6d90ce" },
  { name: "malware", color: "#ee82c1" },
  { name: "crypto-mining", color: "#5cc068" },
  { name: "Botnet", color: "#b0767d" },
  { name: "TeamTNT", color: "#1830da" }
];
export const RefreshTime = [
  { label: "off", value: false },
  { label: "5 sec", value: 5000 },
  { label: "10 sec", value: 10000 },
  { label: "30 sec", value: 30000 },
  { label: "1 min", value: 60000 },
  { label: "5 mins", value: 300000 },
  { label: "10 mins", value: 600000 },
  { label: "30 mins", value: 1800000 }
];
export const COLORS = [
  "#9B72F3",
  "#3fb46b",
  "#F37272",
  "#5ec62a",
  "#69907f",
  "#6d90ce",
  "#4e47de",
  "#5d96f8",
  "#ee82c1",
  "#8da201",
  "#960b73",
  "#5cc068",
  "#841c68",
  "#cba061",
  "#b0767d",
  "#5a7549",
  "#8190d5",
  "#50414f",
  "#1830da",
  "#d2b010",
  "#f79fbb",
  "#f90f82",
  "#5bda8a",
  "#cf721c",
  "#cabe6b",
  "#e693e6",
  "#aa3e50",
  "#4aa52d",
  "#3d5ecf",
  "#25f02c",
  "#420e8c",
  "#abaa8",
  "#5a0e7f",
  "#ff8ec6",
  "#70a02b",
  "#13fa04",
  "#715645",
  "#7b4bab",
  "#bf4d0e",
  "#f54141",
  "#6a882b",
  "#612613",
  "#620f5f",
  "#aecb1e",
  "#81dc02",
  "#b61cff",
  "#b6b1dd",
  "#c05966",
  "#b3bec2",
  "#62b507"
];

// export const TagsList = [
//   "MITRE",
//   "NIST-800",
//   "NIST",
//   "PCI-DSS",
//   "T1003",
//   "sysrv-hello",
//   "malware",
//   "crpto-mining",
//   "Botnet",
//   "TeamTNT"
// ];
