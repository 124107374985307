import { BugReport } from "@mui/icons-material";
import React from "react";
import { LightTooltip } from "components/simple";

export const BugChip = ({ fill = true, size, count }) => {
  const isSmall = size === "small";

  return (
    <LightTooltip arrow placement="top" title="Malware">
      <div
        className="font-medium flex justify-center items-center rounded border shadow"
        style={{
          backgroundColor: fill ? "#B60C0C" : "#fff",
          height: isSmall ? "24px" : "28px",
          width: isSmall ? "32px" : "36px",
          padding: isSmall ? "1px 1px 0 0" : "1px 2px 0 0"
        }}
      >
        <BugReport
          sx={{
            color: fill ? "#fff" : "#B60C0C",
            fontSize: isSmall ? "18px" : "24px"
          }}
        />
        <span
          style={{
            color: fill ? "#fff" : "#000",
            fontSize: isSmall ? "7px" : "14px",
            fontWeight: isSmall ? 500 : 600
          }}
        >
          {count}
        </span>
      </div>
    </LightTooltip>
  );
};
