import { useMutation, useQuery } from "react-query";
import { getAccuknoxWorkspace } from "api/api";
import { customPost } from "api/base";
// import { CLUSTER, VMGROUP, VMINSTANCE } from "./Constant/constant";

const workspaceid = getAccuknoxWorkspace();
export const uselistofClusterData = () => {
  const URL = "/cm/api/v1/cluster-management/get-all-clusters-in-workspace";
  const payload = {
    workspace_id: workspaceid || getAccuknoxWorkspace(),
    from_time: [],
    to_time: []
  };
  return useQuery(
    ["clusterslist"],
    async () => {
      const response = await customPost(URL, payload);
      const res = await response.json();
      return res?.result;
    },
    {
      select: data => {
        return data?.map(item => ({
          ...item,
          name: item?.ClusterName,
          entity_type: CLUSTER,
          id: item?.ID
        }));
      },
      refetchOnWindowFocus: false
    }
  );
};

//       refetchOnWindowFocus: false
//     }
//   );
// };
// export const vmNavBar = () => {
//   const URL = "/cm/api/v1/vm-management/vm-navbar";
//   const payload = {
//     from_time: [],
//     to_time: [],
//     workspace_id: workspaceid,
//     query: ""
//   };
//   return useQuery(
//     ["vmlist"],
//     async () => {
//       const response = await customPost(URL, payload);
//       const res = await response.json();
//       return res;
//     },
//     {
//       select: data => {
//         return data?.list_of_instance_group?.map(item => ({
//           ...item,
//           entity_type: VMGROUP,
//           instances: item?.instances
//             ? item?.instances?.map(i => ({
//                 ...i,
//                 entity_type: VMINSTANCE
//               }))
//             : []
//         }));
//       },
//       refetchOnWindowFocus: false
//     }
//   );
// };
// export const useClusterDetail = () => {
//   const mutation = useMutation(payload => {
//     return getClusterDetail(payload);
//   });
//   return mutation;
// };
// export const getAllPolicies = async (
//   page_previous,
//   page_next,
//   workspace_id,
//   cluster_id,
//   namespace_id,
//   type,
//   infra,
//   label_type = "",
//   node_id,
//   pod_id,
//   status
// ) => {
//   let filter = {
//     cluster_id: [1413],
//     namespace_id: [],
//     type: ["System"],
//     infra: "GKE",
//     node_id: [],
//     pod_id: []
//   };
//   // if (infra === "GKE") {
//   //   filter = {
//   //     cluster_id: cluster_id,
//   //     namespace_id: namespace_id,
//   //     type: type,
//   //     infra: infra,
//   //     label_type: label_type,
//   //     node_id: node_id,
//   //     pod_id: pod_id,
//   //     status: status
//   //   };
//   // } else {
//   //   filter = {
//   //     instance_group_id: cluster_id,
//   //     instance_id: namespace_id,
//   //     type: type,
//   //     infra: infra,
//   //     status: status
//   //   };
//   // }
//   if (label_type === "") {
//     delete filter?.label_type;
//   }

//   return customPost("/policymanagement/api/v1/policy/get-all-policies", {
//     // workspace_id: workspace_id,
//     // // owner_id: userId,
//     // page_previous: page_previous,
//     // page_next: page_next,
//     filter: filter,
//     workspace_id: workspace_id || 846,
//     page_previous: page_previous || 0,
//     page_next: page_next || 100
//   });
// };

export const getClusterDetail = async (clusterId, range) => {
  const URL = "/cm/api/v1/cluster-management/get-cluster-entity-info";
  const payload = {
    cluster_id: clusterId,
    from_time: [],
    to_time: []
  };
  const response = await customPost(URL, payload);
  const res = await response.json();
  return res?.result;
};
export const useClustersideBarData = () => {
  const URL = "/cm/api/v1/cluster-management/clusters-in-workspace-filter";
  const payload = {
    from_time: [],
    to_time: [],
    workspace_id: workspaceid
  };
  return useQuery(
    ["clustersfilterdata"],
    async () => {
      const response = await customPost(URL, payload);
      const res = await response.json();
      return res?.result;
    },
    {
      select: data => {
        return data?.map(item => ({
          ...item,
          clusterId: item?.ID,
          clusterName: item?.ClusterName
        }));
      },
      refetchOnWindowFocus: false
    }
  );
};
export const usePodSideBarData = () => {
  const URL = "/cm/api/v1/cluster-management/pods-in-node-filter";
  const payload = {
    from_time: [],
    to_time: [],
    workspace_id: workspaceid,
    Filter: {},
    cluster_id: [],
    namespace_id: [],
    node_id: []
  };
  return useQuery(
    ["podfilterdata"],
    async () => {
      const response = await customPost(URL, payload);
      const res = await response.json();
      return res?.result;
    },
    {
      select: data => {
        return data?.map(item => ({
          ...item,
          podId: item?.ID,
          podName: item?.PodName
        }));
      },

      refetchOnWindowFocus: false
    }
  );
};
export const vmNavBar = () => {
  const URL = "/cm/api/v1/vm-management/vm-navbar";
  const payload = {
    from_time: [],
    to_time: [],
    workspace_id: workspaceid,
    query: ""
  };
  return useQuery(
    ["vmlist"],
    async () => {
      const response = await customPost(URL, payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data?.list_of_instance_group?.map(item => ({
          ...item,
          entity_type: VMGROUP,
          instances: item?.instances
            ? item?.instances?.map(i => ({
                ...i,
                entity_type: VMINSTANCE
              }))
            : []
        }));
      },
      refetchOnWindowFocus: false
    }
  );
};
export const useClusterDetail = () => {
  const mutation = useMutation(payload => {
    return getClusterDetail(payload);
  });
  return mutation;
};
export const getAllPolicies = async (
  page_previous,
  page_next,
  workspace_id,
  cluster_id,
  namespace_id,
  type,
  infra,
  label_type = "",
  node_id,
  pod_id,
  status
) => {
  const filter = {
    cluster_id: [1413],
    namespace_id: [],
    type: ["System"],
    infra: "GKE",
    node_id: [],
    pod_id: []
  };
  // if (infra === "GKE") {
  //   filter = {
  //     cluster_id: cluster_id,
  //     namespace_id: namespace_id,
  //     type: type,
  //     infra: infra,
  //     label_type: label_type,
  //     node_id: node_id,
  //     pod_id: pod_id,
  //     status: status
  //   };
  // } else {
  //   filter = {
  //     instance_group_id: cluster_id,
  //     instance_id: namespace_id,
  //     type: type,
  //     infra: infra,
  //     status: status
  //   };
  // }
  if (label_type === "") {
    delete filter?.label_type;
  }

  return customPost("/policymanagement/api/v1/policy/get-all-policies", {
    // workspace_id: workspace_id,
    // // owner_id: userId,
    // page_previous: page_previous,
    // page_next: page_next,
    filter,
    workspace_id: workspace_id || 846,
    page_previous: page_previous || 0,
    page_next: page_next || 100
  });
};
export const getAutoDiscoveredPolicies = (
  workspaceVal,
  pagePrevious,
  pageNext,
  cluster_id,
  namespace_id,
  type,
  ignore,
  infra = "GKE",
  label_type = "",
  node_id,
  pod_id
) => {
  let filter = {
    cluster_id: [1281],
    namespace_id: [],
    type: ["System"],
    infra: "GKE",
    node_id: [],
    pod_id: []
  };
  if (infra === "GKE") {
    filter = {
      discover: {
        used: ignore.includes("Used"),
        ignore: ignore.includes("Ignore")
      },
      cluster_id,
      namespace_id,
      type,
      infra,
      label_type,
      node_id,
      pod_id
    };
  } else {
    filter = {
      discover: {
        used: ignore.includes("Used"),
        ignore: ignore.includes("Ignore")
      },
      instance_group_id: cluster_id,
      instance_id: namespace_id,
      type,
      infra
    };
  }
  if (label_type === "") {
    delete filter?.label_type;
  }
  return customPost("/policymanagement/api/v1/auto-discover/get-policies", {
    filter,
    workspace_id: workspaceVal || 459,
    page_previous: pagePrevious || 0,
    page_next: pageNext || 100
  });
};
