import React, { useState, useEffect, useRef } from "react";
import Chip from "@mui/material/Chip";
import styled from "styled-components";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import DropItem from "../../simple/DropItem/dropItem";
import { usePrimaryLayoutTitle } from "screens/MonitorLogs/Context/Context";
import { useHistory } from "react-router-dom";
import { useGetDynamicData } from "store/entities/datapipeline/queries";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  getSelectedComponentType,
  getSelectedClusters,
  getSelectedNamespaces
} from "store/entities/filters/slice";
import { Input } from "components/simple";
import { Box, ClickAwayListener, Typography } from "@mui/material";
import SelectedTags from "components/simple/SelectedTags";

export default function DynamicFilterLogs({
  dsl,
  updateLocalDSL,
  keys,
  // keyTypes,
  onSelected,
  edit,
  setEdit,
  isEditing,
  setIsEditing,
  range,
  setPageId,
  podOption,
  namespaceOption
}) {
  const { updateLogList, selected, updateSelected } = usePrimaryLayoutTitle();
  const [myInp, setMyInp] = useState();
  const [key, setKey] = useState("");
  const isInitial = useRef(true);
  let heightLevel = 1;

  const [searchInput, setSearchInput] = useState("");
  const [showdata, setShowdata] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [droplist, setDropList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [leftOffset, setLeftOffset] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);
  const history = useHistory();
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedComponentType = useSelector(getSelectedComponentType);
  useEffect(() => {
    if (dsl && dsl.length !== 0) {
      updateSelected(dsl);
      updateLogList(dsl);
      updateLocalDSL(dsl);
    }
  }, [dsl, updateLogList, updateLocalDSL]);
  useEffect(() => {
    updateLogList([]);
    updateLocalDSL([]);
    updateSelected([]);
  }, [selectedClusters]);

  // API Call for Property value
  const buildFString = key => {
    const resultArray = [];
    if (key) {
      const text = key;
      if (text === "OR") {
        resultArray.push("OR:");
      } else {
        const arr = text.split(":");
        const key = arr[0];
        resultArray.push(`${key}`);
      }
      return resultArray.flat();
    }
  };
  var filterString = buildFString(key);
  const { data, isLoading } = useGetDynamicData(
    data => {
      const filterData = data?.[key]?.filter(item => Boolean(item));
      setDropList(filterData?.flat());
      return data;
    },
    selectedClusters?.map(item => `${item?.value}`),
    selectedNamespaces?.label,
    selectedComponentType?.label,
    filterString,
    range,
    window.location.pathname === "/monitors-logging/logs" ? true : false
  );
  const fetchTransDsl = data?.[key]?.filter(item => Boolean(item));
  const renderValueDropDown = e => {
    const dropListCompact = data?.[key]?.filter(item => Boolean(item));
    let value = e?.toLowerCase();
    let result = [];
    if (value === "") {
      if (key === "Namespace") {
        setDropList(namespaceOption);
      } else if (key === "PodName") {
        setDropList(podOption);
      } else {
        setDropList(dropListCompact?.flat());
      }
    } else {
      result = droplist
        ?.map(e => e)
        .filter(data => {
          if (typeof data === "number") {
            return (
              data
                ?.toString()
                ?.toLowerCase()
                .search(value) != -1
            );
          }
          return data?.toLowerCase().search(value) != -1;
        });
      setDropList(result?.flat());
    }
    setShowdata(showdata);
    setKey(key);
    setShowDropdown(true);
  };
  const renderPropDropDown = e => {
    let options = ["Cluster", "Pod", "Node", "Namespace", "Location"];

    if (keys) {
      options = keys;
    }

    setShowdata([]);

    const _droplist = [];
    const lastSelectedTag = tagList[tagList.length - 1];

    if (selected && selected.length > 0 && lastSelectedTag !== `OR`) {
      //attach OR operator
      // _droplist.push("OR");
    }

    //find if any key in the selected tag => options need to be filtered.
    const keysList = [];
    if (selected) {
      selected.forEach(item => {
        const _key = item["key"];
        if (keysList.indexOf(_key) === -1) {
          keysList.push(_key);
        }
      });
    }
    options?.forEach(d => {
      // if (d.toLowerCase().includes(searchStr.toLowerCase()) && keysList.indexOf(d) === -1) {
      _droplist.push(d);
      // }
    });

    let value = e?.toLowerCase();
    let result = [];
    if (value === "") {
      setDropList(_droplist);
    } else {
      result = droplist
        ?.map(e => e)
        .filter(data => {
          return data?.toLowerCase().search(value) != -1;
        });
      setDropList(result);
    }

    setShowdata(showdata);
    setPopupVisible(true);
    setKey(key);

    setShowDropdown(true);
  };
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      if (key) {
        addSelectedTag(e.target.value, key);
        setSearchInput("");
      } else {
        addSelectedTag(e.target.value, "*");
        setSearchInput("");
      }
    }

    if (e.key === "Backspace") {
      if (key && searchInput === "") {
        setKey("");
      }
    }

    setShowDropdown(false);
  };
  const placeDropdown = () => {
    const pos = document.getElementById("dslInput");
    setLeftOffset(pos.offsetLeft);
  };
  const onTap = e => {
    e.stopPropagation();
    //key is present show suggestions dropdown
    setShowdata([]);
    if (key) {
      renderValueDropDown(e.target.value);
    } else {
      //key is not present show keys dropdown
      renderPropDropDown(e.target.value);
    }

    setSearchInput(e.target.value);
    setPopupVisible(true);

    placeDropdown();
  };

  const closeAll = e => {
    e.stopPropagation();
    // updateLogList([],"close_all")
    // clearTagList()
    updateLogList([]);
    history.replace({ state: {} });
    updateSelected([]);
    updateLocalDSL([]);
    // setPredefined([]);
    setShowDropdown(false);
    setPageId(1);
  };

  const addSelectedTag = (val, keyval) => {
    let local_selected = selected;
    if (local_selected === undefined) {
      local_selected = [];
    }

    if (val === "OR") {
      tagList.push(`OR`);
      // dslFormat.push({ condition: `OR` })

      local_selected.push({ text: `OR` });
      setTimeout(() => {
        updateSelected(local_selected);
        setShowdata([]);

        setKey("");
        setSearchInput("");
        setShowDropdown(false);
      }, 100);
    } else if (val === ">") {
      setTimeout(() => {
        setSearchInput(">");
        setShowDropdown(false);
      }, 100);
    } else if (val === "<") {
      setTimeout(() => {
        setSearchInput("<");
        setShowDropdown(false);
      }, 100);
    } else if (val === "=") {
      setTimeout(() => {
        setSearchInput("=");
        setShowDropdown(false);
      }, 100);
    } else {
      if (keyval !== undefined && keyval !== "" && keyval !== "*") {
        tagList.push(`${keyval}:${val}`);
        // dslFormat.push({ key: keyval, val: val })

        local_selected.push({
          text: `${keyval}:${val}`,
          key: keyval,
          type: "log"
        });
        setTimeout(() => {
          updateSelected(local_selected);
          // onSelected(local_selected);
          setShowdata([]);
          setKey();

          setShowDropdown(false);
          const tmp = _.uniq(local_selected);
          updateLogList(tmp);
        }, 100);
      } else if (keyval === "*") {
        tagList.push(`${keyval}:${val}`);
        // dslFormat.push({ key: keyval, val: val })

        local_selected.push({ text: `${keyval}:${val}` });
        setTimeout(() => {
          setShowdata([]);

          setSearchInput("");
          setShowDropdown(false);
        }, 100);
      } else {
        setTimeout(() => {
          setShowdata([]);

          setKey(val);
          setSearchInput("");
          setShowDropdown(false);

          //setOperator
        }, 100);
      }
    }

    setTagList(tagList);

    //set focus to input
    myInp.focus();

    const height = document.getElementById("selectedTags").clientHeight;

    // if(height/26 == 1){
    heightLevel = Math.round(height / 28);
    // }
  };

  useEffect(() => {
    if (!isInitial.current) {
      setTimeout(() => closeAll(), 100);
    } else {
      isInitial.current = false;
    }
  }, []);

  const closeTag = (e, index) => {
    e.stopPropagation();
    // setPredefined([])
    setPageId(1);
    setShowDropdown(false);
    // if (selected?.length === 1) {
    //   history.replace({ state: {} })
    // }
    const tmp = selected?.filter((val, i) => val && i !== index);

    if (tmp === []) {
      //   clearTagList()
      updateLogList([]);
      updateLocalDSL([]);
      updateSelected([]);
    } else {
      updateLogList(tmp);
      updateLocalDSL(tmp);
      updateSelected(tmp);
    }

    // updateSelected(tmp)
  };
  useEffect(() => {
    onSelected(selected);
    setPageId(1);
  }, [selected]);

  let placeholderText = "Search by cluster, node, pod or service name";
  placeholderText = "Filter";
  const handleClickAway = () => {
    setShowDropdown(false);
  };
  // const result = selected?.reduce((acc, current, item) => {
  //   const arr = acc?.map(data => data?.text);
  //   if (!arr?.includes(current?.text)) {
  //     return acc.concat([current]);
  //   } else {
  //     return acc;
  //   }
  // }, []);
  // console.log(result, "vresult");
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Main className="z-10 flex">
        <Filter
          style={{ height: `${heightLevel * 40}px` }}
          onClick={() => {
            myInp.click();
          }}
        >
          <FilterIcon style={{ width: "4%", marginTop: "0.25rem" }}>
            <FilterAltIcon style={{ width: "1.5em", height: "26px", color: "#A6A8AA" }} />
          </FilterIcon>
          <TagSelected style={{ width: "92%" }} id="selectedTags">
            {selected && (
              <div
                style={{ marginBottom: "2px" }}
                className="relative inline-block float-left py-1 mx-1 text-white rounded-full"
              >
                {selected && (
                  <>
                    <SelectedTags
                      list={selected}
                      closeTag={closeTag}
                      edit={edit}
                      setEdit={setEdit}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                    />
                  </>
                )}
              </div>
            )}

            {key && <GetKey>{key ? key + " :" : ""}</GetKey>}

            <input
              id="dslInput"
              type="text"
              autoComplete="off"
              className="inline-block float-left w-2/5 mt-1 text-gray-900 bg-white rounded cursor-pointer input-field"
              placeholder={placeholderText}
              style={{ height: "26px", width: "200px" }}
              ref={ip => setMyInp(ip)}
              value={searchInput}
              onChange={e => {
                onTap(e);
              }}
              onClick={e => {
                onTap(e);
              }}
              onKeyDown={e => {
                handleKeyDown(e);
              }}
            />
          </TagSelected>

          <TagSelected style={{ width: "0%", margin: "5px" }}>
            {selected && selected.length > 0 && (
              <CloseIcon style={{ width: "1.5em", height: "21px" }} onClick={closeAll} />
            )}
          </TagSelected>
        </Filter>

        {showDropdown && (
          <ShowDrop
            style={{
              zIndex: 1,
              height: "30vh",
              borderRadius: "4px",
              left: leftOffset,
              border: "1px solid rgb(228 228 228)"
            }}
          >
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid rgb(228 228 228)",
                padding: "10px"
              }}
              className="flex justify-between px-2"
            >
              <div
                style={{
                  display: "inline-block",
                  color: "#888c92"
                }}
              >
                PROPERTIES
              </div>
              <div style={{ display: "inline-block" }}>
                <CloseIcon
                  style={{ float: "right", height: "21px" }}
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                />
              </div>
            </div>
            {isLoading ? (
              <Box className="flex flex-col items-center justify-center w-full p-2 py-15 ">
                <Typography className="py-2 text-sm text-center">Loading...</Typography>
                <Typography className="text-sm text-center">
                  Response time may be longer based on the selected TimeRange
                </Typography>
              </Box>
            ) : (
              <>
                {keys?.length === 0 ? (
                  <Box className="flex justify-center p-6 text-center">
                    No Data was found for this filter in the Current Time Range
                  </Box>
                ) : (
                  <Box className="hidden">
                    {keys?.length > 1 && (
                      <Box className="flex px-2 py-3">
                        <Input
                          className="border-opacity-0 w-58"
                          onChange={e => onTap(e)}
                          placeholder="Search"
                          value={searchInput}
                        />
                      </Box>
                    )}
                  </Box>
                )}
                {droplist?.map((item, index) => (
                  <DropItem
                    key={index}
                    len={index}
                    onTap={() => {
                      addSelectedTag(item, key);
                      setSearchInput("");
                      setKey("");
                    }}
                    popupVisible={popupVisible}
                    value={item}
                    data-testid="dropitem"
                  />
                ))}
              </>
            )}
            {fetchTransDsl?.length === 0 && (
              <Box className="flex justify-center p-6 text-center">
                No Data was found for this filter in the Current Time Range
              </Box>
            )}
          </ShowDrop>
        )}
      </Main>
    </ClickAwayListener>
  );
}

export const Main = styled.div`
  font-size: 13px;
  .input-field {
    border: 0;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .css-1a1l6zv-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    font-size: 19px;
  }
`;
export const Filter = styled.div`
  border: 1px solid #dbdff0;
  width: 100%;
  height: 38px;
  border-radius: 0.25rem;
  cursor: pointer;
  background: #fff;
  border-width: 1px;
`;
export const FilterIcon = styled.div`
  display: inline-block;
  float: left;
  padding-right: 8px;
`;
export const TagSelected = styled.div`
  display: inline-block;
  float: left;
`;
export const GetKey = styled.div`
  margin-top: 5px;
  display: inline-block;
  float: left;
  padding: 4px 4px;
`;
export const ShowDrop = styled.div`
  background: #fff;
  border-width: 1px;
  width: 16.666667%;
  margin-top: 3rem;
  transition-delay: 150ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  overflow: auto;
`;
