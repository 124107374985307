import React, { forwardRef } from "react";

import styles from "./styles.module.scss";

const ContentContainer = forwardRef(({ children, className }, ref) => {
  return (
    <div ref={ref} className={`${className} ${styles.container}`}>
      {children}
    </div>
  );
});

export default ContentContainer;
