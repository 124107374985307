import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "../notifications/actions";

const getFilter = state => {
  return {
    page: state.datalist.page,
    pageSize: state.datalist.pageSize,
    ordering: state.datalist.ordering,
    search: state.datalist.search,
    params: state.datalist.temporaryData,
    filters: state.datalist.filters,
    groupBy: state.datalist.groupBy
  };
};

// Workers //

// GET Datalists
function* getDatalistsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getDatalists), payload);
    yield put(actions.getDataListsSuccess(response));
  } catch (error) {
    yield put(actions.getDataListsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// GET Datalists
function* watchGetDatalistsWorker() {
  yield takeEvery(actions.getDataLists, getDatalistsWorker);
}

// GET Datalists Mini
function* getDataListsDataTypeWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getDataListsDataType), payload);
    yield put(actions.getDataListsDataTypeSuccess(response));
  } catch (error) {
    yield put(actions.getDataListsDataTypeFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// GET Datalists Mini
function* watchGetDataListsDataTypeWorker() {
  yield takeEvery(actions.getDataListsDataType, getDataListsDataTypeWorker);
}

// Set Datalists Params
function* setDatalistsParamsWorker() {
  const { page, pageSize, ordering, search, params, filters, groupBy } = yield select(getFilter);
  yield put(
    actions.getDataLists({
      page,
      pageSize,
      ordering,
      search,
      params,
      filters,
      groupBy
    })
  );
}

// Set Datalists Params
function* watchSetDatalistsParamsWorker() {
  yield takeEvery(actions.setDataListsParams, setDatalistsParamsWorker);
}

// GET datalist configs
function* getDatalistConfigsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getDatalistConfigs), payload);
    yield put(actions.getDataListConfigsSuccess(response));
  } catch (error) {
    yield put(actions.getDataListConfigsFailed(error));
  }
}

// GET datalist configs
function* watchGetDatalistConfigsWorker() {
  yield takeEvery(actions.getDataListConfigs, getDatalistConfigsWorker);
}

function* getDatalistConfigsFiltersDataWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getDatalistConfigsFiltersData), payload);
    yield put(actions.getDataListConfigsFiltersDataSuccess(response));
  } catch (error) {
    yield put(actions.getDataListConfigsFiltersDataFailed(error));
  }
}

function* watchGetDatalistConfigsFiltersDataWorker() {
  yield takeEvery(actions.getDataListConfigsFiltersData, getDatalistConfigsFiltersDataWorker);
}

// EDIT datalist configs
function* editDatalistConfigsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editDatalistConfigs), payload);
    yield put(actions.editDataListConfigsSuccess(response?.name || ""));
    yield put(actions.getDataListConfigs());
    yield put(addNotification({ msg: "Datalist configurations successfully modified" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.editDataListConfigsFailed(error));
  }
}

// EDIT datalist configs
function* watchEditDatalistConfigsWorker() {
  yield takeEvery(actions.editDataListConfigs, editDatalistConfigsWorker);
}

// POST datalist configs
function* postDatalistConfigsWorker({ payload }) {
  try {
    const { asset, target, ...rest } = payload;
    const data = {
      ...rest,
      asset_id: asset?.value || null,
      target_id: target?.value || null
    };
    const response = yield call(makeRequest(service.postDatalistConfigs), data);
    yield put(actions.postDataListConfigsSuccess(response));
    yield put(actions.getDataListConfigs());
    //

    const selectConfig = {
      label: response?.name,
      value: response?.id,
      result: {
        ...payload,
        display_fields_width: response?.table_properties?.display_fields_width ?? []
      }
    };

    const res = {
      page: 0,
      selectConfig,
      temporaryData: selectConfig?.result,
      ordering: "",
      search: "",
      filters: {},
      groupBy: null
    };
    yield put(actions.setDataListsParams(res));

    yield put(addNotification({ msg: "Successfully added datalist configurations" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.postDataListConfigsFailed(error));
  }
}

// POST datalist configs
function* watchPostDatalistConfigsWorker() {
  yield takeEvery(actions.postDataListConfigs, postDatalistConfigsWorker);
}

// DELETE datalist configs
function* deleteDatalistConfigsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteDatalistConfigs), payload);
    yield put(actions.deletetDataListConfigsSuccess(response));
    yield put(actions.getDataListConfigs());
    yield put(addNotification({ msg: "Removed datalist configurations successfully" }));
  } catch (error) {
    yield put(actions.deleteDataListConfigsFailed(error));
  }
}

// POST datalist configs
function* watchDeleteDatalistConfigsWorker() {
  yield takeEvery(actions.deleteDataListConfigs, deleteDatalistConfigsWorker);
}

// ACTIONS ====================================

// GET GROUP
function* getGroupsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getGroups), payload);
    yield put(actions.getGroupsSuccess(response));
  } catch (error) {
    yield put(actions.getGroupsFailed(error));
  }
}
// GET GROUP
function* watchGetGroupsWorker() {
  yield takeEvery(actions.getGroups, getGroupsWorker);
}
// ADD GROUP
function* addGroupWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addGroup), payload);
    yield put(actions.addGroupSuccess(response));
    yield put(actions.setDataListsParams());
    yield put(actions.actionGroupModal({ show: false, data: [] }));
    yield put(addNotification({ msg: "Group added successfully" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addGroupFailed(error));
  }
}

// ADD GROUP
function* watchAddGroupWorker() {
  yield takeEvery(actions.addGroup, addGroupWorker);
}

// GET TARGET
function* getTargetsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTargets), payload);
    yield put(actions.getTargetsSuccess(response));
  } catch (error) {
    yield put(actions.getTargetsFailed(error));
  }
}
// GET TARGET
function* watchGetTargetsWorker() {
  yield takeEvery(actions.getTargets, getTargetsWorker);
}

// GET TARGET Types
function* getTargetsTypesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTargetsTypes), payload);
    yield put(actions.getTargetsTypesSuccess(response));
  } catch (error) {
    yield put(actions.getTargetsTypesFailed(error));
  }
}

// GET TARGET TYPES
function* watchGetTargetsTypesWorker() {
  yield takeEvery(actions.getTargetsTypes, getTargetsTypesWorker);
}

// ADD TARGET
function* addTargetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addTarget), payload);
    yield put(actions.addTargetSuccess(response));
    yield put(actions.setDataListsParams());
    yield put(actions.actionTargetModal({ show: false, data: [] }));
    yield put(addNotification({ msg: "Target added successfully" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addTargetFailed(error));
  }
}

// ADD TARGET
function* watchAddTargetWorker() {
  yield takeEvery(actions.addTarget, addTargetWorker);
}

const handleDownload = response => {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = "export.xls";
  link.href = URL.createObjectURL(response);
  link.click();
};

// EXPORT
function* exportDatalistWorker({ payload }) {
  try {
    const fields = yield select(getFilter);
    const response = yield call(service.exportDatalist, {
      fields,
      ...payload
    });
    handleDownload(response);
    yield put(actions.exportDataListSuccess());
  } catch (error) {
    yield put(actions.exportDataListFailed(error));
  }
}

// EXPORT
function* watchExportDatalistWorker() {
  yield takeEvery(actions.exportDataList, exportDatalistWorker);
}
// ACTIONS END ====================================

// Workers End //

export default function* rootSaga() {
  yield all([
    fork(watchGetDatalistsWorker),
    fork(watchSetDatalistsParamsWorker),
    fork(watchGetDataListsDataTypeWorker),

    fork(watchGetDatalistConfigsWorker),
    fork(watchGetDatalistConfigsFiltersDataWorker),
    fork(watchEditDatalistConfigsWorker),
    fork(watchPostDatalistConfigsWorker),
    fork(watchDeleteDatalistConfigsWorker),

    fork(watchGetGroupsWorker),
    fork(watchAddGroupWorker),
    fork(watchGetTargetsWorker),
    fork(watchGetTargetsTypesWorker),
    fork(watchAddTargetWorker),
    fork(watchExportDatalistWorker)
  ]);
}
