import React from "react";

import { useTheme } from "@mui/styles";
import { Typography } from "@mui/material";
import { PieChart, Pie, Cell, LabelList } from "recharts";

import { kFormatter } from "helper/helpers";
import InfoIcon from "components/simple/Icon/Info";
import { ContentContainer, LightTooltip, Loader, Title } from "components/simple";
import { useDashboardDetailsQuery } from "store/entities/issues/registryScan/queries";

export const ImageScanningCard = () => {
  const theme = useTheme();
  const imageScanningQuery = useDashboardDetailsQuery();

  const totalRegisteredImages = imageScanningQuery?.data?.imageScanningData?.totalImages
    ? String(kFormatter(imageScanningQuery?.data?.imageScanningData?.totalImages))
    : 0;

  return (
    <ContentContainer className="p-4 flex flex-col gap-4">
      <div className="flex items-center justify-between w-full">
        <Title>Image Scanning Progress</Title>
        <LightTooltip title="Shows image scanning progress" placement="top" arrow>
          <span>
            <InfoIcon color={theme.palette.primary.light} />
          </span>
        </LightTooltip>
      </div>

      <div className="flex items-center justify-between p-2 bg-blue-100 rounded-lg">
        <Typography paragraph sx={{ color: "primary.main", fontWeight: "500", m: 0 }}>
          Total Registered Images
        </Typography>
        <Typography variant="h6" sx={{ color: "primary.main", fontWeight: "600", m: 0 }}>
          {totalRegisteredImages}
        </Typography>
      </div>

      <div>
        {imageScanningQuery.isLoading ? (
          <Loader />
        ) : (
          <div className="w-full grid grid-cols-2 gap-2">
            <div className="col-span-1 flex flex-col justify-center gap-[6px]">
              {imageScanningQuery.data?.imageScanningData.metrics.slice(0, 6).map((item, index) => (
                <div className="grid grid-cols-6 items-center justify-center text-sm" key={index}>
                  <div className="w-3 h-3 rounded col-span-1" style={{ background: item?.color }} />
                  <p className="col-span-5 m-0">{item?.title}</p>
                </div>
              ))}
            </div>

            <div className="col-span-1 flex justify-end">
              {imageScanningQuery.data?.imageScanningData.metrics?.filter(el => el.value > 0)
                .length !== 0 ? (
                <PieChart width={150} height={150}>
                  <Pie
                    data={imageScanningQuery.data?.imageScanningData.metrics}
                    dataKey="value"
                    nameKey="title"
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={70}
                  >
                    {imageScanningQuery.data?.imageScanningData.metrics?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry?.color} stroke="none" />
                    ))}
                    <LabelList
                      dataKey="value"
                      fill="white"
                      formatter={val => (val === 0 ? null : val)}
                      fontSize="14px"
                      fontWeight="600"
                    />
                  </Pie>
                </PieChart>
              ) : (
                <PieChart width={150} height={150}>
                  <Pie
                    data={[
                      {
                        title: "",
                        value: 1,
                        color: "#DADADA"
                      }
                    ]}
                    dataKey="value"
                    nameKey="title"
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={70}
                  ></Pie>
                </PieChart>
              )}
            </div>
          </div>
        )}
      </div>
    </ContentContainer>
  );
};
