import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

// Set Data-structure labels params
export const setDataStructureLabelsParams = createAction(constant.SET_DATA_STRUCTURE_LABELS_PARAMS);

// Get Labels
export const getLabels = createAction(constant.GET_LABELS);
export const getLabelsSuccess = createAction(constant.GET_LABELS_SUCCESS);
export const getLabelsFailed = createAction(constant.GET_LABELS_FAILED);

// Get Labels List
export const getLabelList = createAction(constant.GET_LABEL_LIST);
export const getLabelgetLabelListSuccess = createAction(constant.GET_LABEL_LIST_SUCCESS);
export const getLabelgetLabelListFailed = createAction(constant.GET_LABEL_LIST_FAILED);

// Get Labels-mini
export const getLabelsMini = createAction(constant.GET_LABELS_MINI);
export const getLabelsMiniSuccess = createAction(constant.GET_LABELS_MINI_SUCCESS);
export const getLabelsMiniFailed = createAction(constant.GET_LABELS_MINI_FAILED);

// Create Label
export const createLabel = createAction(constant.CREATE_LABEL);
export const createLabelSuccess = createAction(constant.CREATE_LABEL_SUCCESS);
export const createLabelFailed = createAction(constant.CREATE_LABEL_FAILED);

// Delete Label
export const deleteLabel = createAction(constant.DELETE_LABEL);
export const deleteLabelSuccess = createAction(constant.DELETE_LABEL_SUCCESS);
export const deleteLabelFailed = createAction(constant.DELETE_LABEL_FAILED);
