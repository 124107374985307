import React, { useState } from "react";

import { routes } from "router";
import { useTheme } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import { Box, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import { navigate } from "helper/history";
import { Table } from "components/complex";
import InfoIcon from "components/simple/Icon/Info";
import {
  useDashboardDetailsQuery,
  useImagesListQuery
} from "store/entities/issues/registryScan/queries";
import { formatTimestampToLocalDate } from "helper/helpers";
import { ContentContainer, Search, CustomPagination } from "components/simple";
import { IMAGE_STATUS_LIST } from "store/entities/issues/registryScan/constants";

import NoTableDataSVG from "../../../../../assets/images/noDataAvailable.svg";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

export const ScanQueue = () => {
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState();
  const [selectedImageStatus, setSelectedImageStatus] = useState(IMAGE_STATUS_LIST[0]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const apiRef = useGridApiRef();
  const imageScanningQuery = useDashboardDetailsQuery();
  const imagesListQuery = useImagesListQuery(selectedImageStatus.value, searchTerm);

  const columnCellConfig = {
    resizable: true,
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  };
  const ScanQueueTableColumns = [
    {
      headerName: "Name",
      field: "name",
      ...columnCellConfig,
      minWidth: 500,
      renderCell: params => (
        <Typography
          paragraph
          fontWeight={600}
          sx={{ color: "primary.light", m: 0, cursor: "pointer" }}
          onClick={() => navigate(routes.registryScanImageDetails, { imageName: params?.value })}
        >
          {params?.value}
        </Typography>
      )
    },
    {
      headerName: "Registry Name",
      field: "registry_name",
      ...columnCellConfig
    },
    {
      headerName: "Status",
      field: "status",
      ...columnCellConfig,
      renderCell: params =>
        params?.value === "Failed" ? (
          <div className="flex gap-1 items-center">
            {params?.value}
            <Tooltip
              title={params?.row?.scan_err}
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 12,
                    color: "#fff",
                    bgcolor: "#474747",
                    borderRadius: "4px",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    boxShadow: theme.shadows[1],
                    "& .MuiTooltip-arrow": {
                      color: "#474747",
                      "&::before": {
                        border: "1px solid #999",
                        backgroundColor: "#474747"
                      }
                    }
                  }
                }
              }}
            >
              <span className="flex items-center">
                <InfoIcon color={theme.palette.primary.light} />
              </span>
            </Tooltip>
          </div>
        ) : (
          params.value
        )
    },
    {
      headerName: "Queued On",
      field: "created_at",
      ...columnCellConfig,
      renderCell: params => <div>{formatTimestampToLocalDate(params?.value)}</div>
    },
    {
      headerName: "Last Progress",
      field: "updated_at",
      ...columnCellConfig,
      renderCell: params => <div>{formatTimestampToLocalDate(params?.value)}</div>
    }
  ];

  return (
    <ContentContainer className="p-4 flex flex-col gap-2">
      <div className="flex justify-between">
        <Search onSearch={setSearchTerm} value={searchTerm} />
      </div>

      <div className="flex gap-2">
        {IMAGE_STATUS_LIST?.map(status => (
          <span className="flex flex-col items-center" key={status.value}>
            <Typography
              variant="h6"
              fontWeight={600}
              sx={{ color: theme => theme.palette.grey[900] }}
            >
              {imageScanningQuery.data?.scanQueueData?.[status.value]}
            </Typography>
            <Box
              component="span"
              sx={{
                padding: "2px 5px",
                cursor: "pointer",
                borderRadius: "4px",
                color: status.value === selectedImageStatus.value ? "#fff" : "#383838",
                bgcolor: status.value === selectedImageStatus.value ? "primary.main" : "#F1F1F1"
              }}
              onClick={() => {
                setSelectedImageStatus(status);
              }}
            >
              <p className="text-[14px] m-0 p-[3px]">{status.label}</p>
            </Box>
          </span>
        ))}
      </div>

      <div>
        <Table
          apiRef={apiRef}
          columns={ScanQueueTableColumns}
          data={imagesListQuery.data?.list_of_image || []}
          rowCount={imagesListQuery.data?.list_of_image?.length || 0}
          page={page - 1}
          pageSize={pageSize}
          // onPageChange={p => setPage(p)}
          // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          // rowsPerPageOptions={[5, 10, 20, 50]}
          checkboxVisible={false}
          components={{
            NoRowsOverlay: () => (
              <Box className="flex flex-col items-center justify-center  w-full h-full">
                <img src={NoTableDataSVG} alt="no-row-available" />
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  No data available!
                </Typography>
              </Box>
            ),
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(imagesListQuery.data?.list_of_image?.length, pageSize),
              onChange: (event, value) => {
                setPage(value);
              },
              page: page,
              totalCount: imagesListQuery.data?.list_of_image?.length || 0,
              selected: pageSize,
              perPageChange: (event, value) => {
                setPageSize(event?.target?.value);
                setPage(1);
              }
            }
          }}
        />
      </div>
    </ContentContainer>
  );
};
