import React from "react";

import { uniqueId } from "lodash";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { Table } from "components/complex";
import {
  getSeverity,
  getSeverityColor
} from "store/entities/issues/riskBasedPriortization/helpers";
import {
  usePrefetchImageMetadataQuery,
  useVulnerabilitiesQuery
} from "store/entities/issues/riskBasedPriortization/queries";
import {
  updateDrawerVulnerability,
  updateVulnerabilityTablePage,
  useRiskBasedPrioritizationState
} from "store/entities/issues/riskBasedPriortization/slice";
import { ContentContainer, MenuToggle, CustomPagination } from "components/simple";
import { useUpdateVulnerabilityStatusMutation } from "store/entities/issues/riskBasedPriortization/mutations";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

export const VulnerabilityTable = ({
  selectedImageName = "",
  setShowDrawer,
  vulnerabilitiesQuery
}) => {
  const dispatch = useDispatch();
  const page = useRiskBasedPrioritizationState("vulnerabilityTablePage");
  const prefetchImageMetadata = usePrefetchImageMetadataQuery();
  const { mutate: updateVulnerabilityStatus } = useUpdateVulnerabilityStatusMutation(
    selectedImageName
  );

  const VULNERABILITIES_TABLE_COLUMNS = [
    {
      headerName: "Vulnerability",
      field: "name",
      resizable: true,
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: params => (
        <Typography
          paragraph
          fontWeight={600}
          sx={{ color: "primary.light", m: 0, cursor: "pointer" }}
          onClick={() => setShowDrawer(true)}
          onMouseEnter={async () => {
            dispatch(updateDrawerVulnerability(params?.row));
            await prefetchImageMetadata(params?.row?.asset?.id);
          }}
        >
          {params?.row?.vulnerability?.name}
        </Typography>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Image",
      field: "image_name",
      resizable: true,
      minWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: params => <div>{params?.row?.asset?.title}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Severity",
      field: "severity",
      resizable: true,
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: params => {
        const score = params?.row?.vulnerability?.misc?.cvss_v3_score;
        return (
          <div className="flex justify-center items-center gap-1">
            <div className="h-3 w-3" style={{ backgroundColor: getSeverityColor(score) }}></div>
            {getSeverity(score)}
          </div>
        );
      },
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Resources",
      field: "resources",
      resizable: true,
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: params => <div>{params?.row?.vulnerability?.misc?.pkg_name}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Vendor Fix",
      field: "vendor_fix",
      resizable: false,
      minWidth: 80,
      flex: 1,
      sortable: false,
      renderCell: params =>
        params?.row?.vulnerability?.misc?.fix_version ? (
          <CheckCircleRoundedIcon sx={{ color: "#72C575" }} />
        ) : (
          ""
        ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Status",
      field: "status",
      resizable: false,
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: params => {
        const status = params?.row?.ack_status;
        return (
          <MenuToggle
            width="130px"
            statusList={["Active", "Accepted Risk"]}
            status={status}
            statusColor={
              status === "Active" ? "#15AC6E" : status === "Accepted Risk" ? "#F55353" : "#000"
            }
            onClick={option => {
              updateVulnerabilityStatus({
                finding_ids: [params?.row?.finding_id],
                status: option
              });
            }}
          />
        );
      },
      headerClassName: "super-app-theme--header",
      hide: false
    }
  ];

  return (
    <ContentContainer className="p-4">
      <Table
        columns={VULNERABILITIES_TABLE_COLUMNS}
        loading={vulnerabilitiesQuery?.isLoading}
        data={vulnerabilitiesQuery?.data?.result?.risk_based_prioritization || []}
        rowCount={vulnerabilitiesQuery?.data?.count || 0}
        page={page}
        // onPageChange={p => dispatch(updateVulnerabilityTablePage(p))}
        pageSize={20}
        // pageSize={vulnerabilitiesQuery?.data?.results?.length}
        rowsPerPageOptions={[20]}
        paginationMode="server"
        getRowId={row => uniqueId()}
        checkboxVisible={false}
        components={{
          Pagination: CustomPagination
        }}
        componentsProps={{
          pagination: {
            count: FindTotalPage(vulnerabilitiesQuery?.data?.count, 20),
            onChange: (event, value) => {
              dispatch(updateVulnerabilityTablePage(value - 1));
            },
            page: page + 1,
            totalCount: vulnerabilitiesQuery?.data?.count || 100 // TODO: hard-coding for now as pagination doesn't work
          }
        }}
      />
    </ContentContainer>
  );
};
