import React, { useState, useRef, useEffect } from "react";
import { TextField, List, ListItem, ListItemText } from "@mui/material";
import PropTypes from "prop-types";

import { Icon } from "components/simple";

import styles from "./styles.module.scss";

const Assets = ({ label, values, list, onChange, searchValue, onSearchChange, className }) => {
  const [isOpen, setOpen] = useState(false);
  const selectedList = values;
  const containerRef = useRef(null);

  const onOpenMenu = () => {
    if (onSearchChange) {
      onSearchChange("");
    }

    setOpen(!isOpen);
  };

  const onAddAsset = item => {
    if (selectedList) {
      if (!selectedList.find(i => i.value === item.value)) {
        onChange([...selectedList, item]);
      }
    } else {
      onChange([item]);
    }
  };

  const onRemoveAsset = id => {
    const newSelectedList = selectedList.filter(asset => asset.value !== id);
    onChange(newSelectedList);
  };

  const handleSearchChange = evt => {
    if (onSearchChange) {
      onSearchChange(evt.target.value);
    }
  };

  useEffect(() => {
    // Close menu on outside click
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      {label && <div className={styles.label}>{label}</div>}
      <div ref={containerRef}>
        <div className={styles.container}>
          {selectedList?.map(asset => (
            <div
              key={asset.value}
              onClick={() => onRemoveAsset(asset.value)}
              className={`${styles.tag} ${styles.selected}`}
            >
              {asset.label}
              <Icon.Close className={styles.removeBtn} color="#d83e3c" width="18" height="18" />
            </div>
          ))}
          <div onClick={onOpenMenu} className={styles.addBtn}>
            <Icon.Plus color="#d83e3c" />
          </div>
        </div>

        {isOpen && (
          <div className={`${styles.modal} ${styles.container}`}>
            <TextField
              className={styles.searchInput}
              value={searchValue}
              onChange={handleSearchChange}
            />
            <List className={styles.searchList}>
              {list &&
                list.map((item, idx) => (
                  <ListItem key={`AssetItem_${idx}`} onClick={() => onAddAsset(item)}>
                    <ListItemText primary={item.label} />
                  </ListItem>
                ))}
            </List>
          </div>
        )}
      </div>
    </div>
  );
};

Assets.propTypes = {
  label: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string
};

Assets.defaultProps = {
  label: "",
  onChange: () => {},
  className: ""
};

export default Assets;
