import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getNotification, updateNotification } from "store/entities/notifications/actions";

import { ContentContainer, Icon, Title } from "components/simple";

import styles from "./styles.module.scss";

const NotificationsDetails = () => {
  const selectTenant = useSelector(s => s.users.selectTenant);
  const notification = useSelector(s => s.notifications.notification);

  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    if (id) {
      dispatch(getNotification(id));
    }
  }, [id, selectTenant]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(notification).length !== 0) {
      if (!notification.read) dispatch(updateNotification({ data: { read: true }, id }));
    }
  }, [notification]);

  return (
    <ContentContainer>
      <Title className={styles.title}>Notification details</Title>
      <div className={styles.content}>
        <div className={styles.row}>
          <div>Subject:</div>
          <div>{notification?.subject || "-"}</div>
        </div>
        <div className={styles.row}>
          <div>Read:</div>
          <div>
            {notification?.read ? <Icon.Check color="#d83e3c" /> : <Icon.Close color="#d83e3c" />}
          </div>
        </div>
        <div className={styles.row}>
          <div>Label:</div>
          <div>{notification?.label?.name || "-"}</div>
        </div>
        <div className={styles.row}>
          <div>Type:</div>
          <div>{notification?.notification_type || "-"}</div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default NotificationsDetails;
