import React from "react";
import styles from "components/simple/Loader/styles.module.scss";

const Customloader = ({ size }) => {
  return (
    <div className={styles.loaderContainer}>
      <div style={{ width: `${size ? size : "34px"}`, height: `${size ? size : "34px"}` }}></div>
      <div style={{ width: `${size ? size : "34px"}`, height: `${size ? size : "34px"}` }}></div>
      <div style={{ width: `${size ? size : "34px"}`, height: `${size ? size : "34px"}` }}></div>
      <div style={{ width: `${size ? size : "34px"}`, height: `${size ? size : "34px"}` }}></div>
    </div>
  );
};

export default Customloader;
