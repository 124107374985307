/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { templatesDataTypeSelect, templateSelector } from "store/entities/settings/selectors";
import {
  addTemplate,
  editTemplate,
  getTemplate,
  getTemplatesDataTypes
} from "store/entities/settings/actions";
import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Title, Button, Input, Select, TextEditor } from "components/simple";

import styles from "./styles.module.scss";

const TicketTemplateDetails = () => {
  const initialState = {
    name: "",
    data_type: "",
    title_template: "",
    dynamic_template: "",
    static_template: ""
  };
  const [state, setState] = useState({ ...initialState });

  const initailFocus = {
    dynamicTemplate: false,
    staticTemplate: false
  };
  const [focus, setFocus] = useState({
    ...initailFocus
  });

  const selectTenant = useSelector(s => s.users.selectTenant);

  const isSaveLoading = useSelector(
    s =>
      createLoadingSelector([addTemplate.type])(s) || createLoadingSelector([editTemplate.type])(s)
  );

  const dataTypesOptions = useSelector(templatesDataTypeSelect);
  const template = useSelector(templateSelector);

  const dispatch = useDispatch();
  const { id } = useParams();

  // get default request
  const getRequest = () => {
    dispatch(getTemplatesDataTypes());
    if (id) {
      dispatch(getTemplate({ id }));
    }
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(template).length !== 0 && id) {
      const { name, data_type, title_template, dynamic_template, static_template } = template;
      const dataTypeVal = dataTypesOptions.find(({ value }) => value === data_type);
      setState({
        name,
        data_type: dataTypeVal,
        title_template,
        dynamic_template,
        static_template
      });
    }
  }, [template, dataTypesOptions]);

  const handleChange = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  const focusToggler = () => {
    setFocus({ ...initailFocus });
  };

  const onTextFocus = name => {
    setFocus({
      ...focus,
      [name]: true
    });
  };

  const saveTemplateHandler = e => {
    e.preventDefault();
    const data = {
      ...state,
      data_type: state.data_type.value
    };
    if (id) {
      dispatch(editTemplate({ data, id }));
      return;
    }
    dispatch(addTemplate(data));
  };

  return (
    <ContentContainer className={styles.container}>
      <Title className={styles.title}>Ticket Template</Title>
      <form onSubmit={saveTemplateHandler}>
        <Input
          label="Name"
          value={state.name}
          onChange={e => handleChange(e.target.value, "name")}
          containerClassName={styles.field}
          onFocus={focusToggler}
          onBlur={focusToggler}
          tooltip="This field will be used in configurations for easier access to templates."
          required
        />
        <Select
          label="Data type"
          value={state.data_type}
          onChange={val => handleChange(val, "data_type")}
          options={dataTypesOptions}
          containerClass={`${styles.field}`}
          tooltip="This template will be associated with selected data type. Eg if you select vulnerability than this config will be available only on vulnerability page."
          required
        />
        <Input
          label="Title template"
          value={state.title_template}
          onChange={e => handleChange(e.target.value, "title_template")}
          containerClassName={`${styles.field} ${styles.withExample}`}
          onFocus={focusToggler}
          onBlur={focusToggler}
          tooltip="After filling variables with data, this wield will be used as ticket title in ticket desk system."
          required
        />
        <TextEditor
          label="Dynamic template:"
          id="dynamicTemplate"
          value={state.dynamic_template}
          handleChange={v => handleChange(v, "dynamic_template")}
          onFocus={onTextFocus}
          optionsList={{ autofocus: focus.dynamicTemplate }}
          containerClass={styles.textEditor}
          tooltip="In case if selected  vulnerability or control  has couple objects in group, you could specify formatting for one of them and we will go thru all objects dynamically and combine data to one description."
        />
        <div className={styles.hint}>
          This template will be used for all objects in group. Example usage for &#123;&#123; ip
          &#125;&#125; &#123;&#123; port &#125;&#125;
        </div>
        <TextEditor
          id="staticTemplate"
          label="Static template:"
          value={state.static_template}
          handleChange={v => handleChange(v, "static_template")}
          onFocus={onTextFocus}
          optionsList={{ autofocus: focus.staticTemplate }}
          containerClass={styles.textEditor}
          tooltip="Data from this field will be used once even if there couple objects in group. Example: You could have couple  assets with same finding, so you could enter solution, description or any other field that same for group and add asset data from dynamic template."
        />
        <div className={styles.hint}>
          This template will be used for all objects in group. Example usage for &#123;&#123; ip
          &#125;&#125; &#123;&#123; port &#125;&#125;
        </div>
        <div className={styles.btnsCont}>
          <Button type="submit" isLoading={isSaveLoading}>
            Save
          </Button>
        </div>
      </form>
    </ContentContainer>
  );
};

TicketTemplateDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default TicketTemplateDetails;
