/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { routes } from "router";
import { onChangeUrlParams, useQuery } from "helper/history";

import { setTagsParams, getTags, deleteTag, createTag } from "store/entities/tags/actions";

import { getTagsSelector } from "store/entities/tags/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Title, Search, Button, CustomPagination, Icon } from "components/simple";
import { Table } from "components/complex";
import AddTagModal from "./AddTagModal";

import styles from "./styles.module.scss";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

const Tags = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");

  const [createTagState, setCreateTagState] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getTags.type])(state));
  const isDeleteLoading = useSelector(state => createLoadingSelector([deleteTag.type])(state));
  const isCreateTagLoading = useSelector(state => createLoadingSelector([createTag.type])(state));

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const { page, pageSize } = useSelector(state => state.tags.tagsParams);

  const { columns, data, total } = useSelector(getTagsSelector);

  // get default request
  const getRequest = () => {
    dispatch(
      setTagsParams({
        page: queryPage ? queryPage - 1 : 0,
        search: ""
      })
    );
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setTagsParams({
        page: 0,
        search: v
      })
    );
  };

  // Next page
  const onPageChange = (event, newPage) => {
    onChangeUrlParams("page", newPage);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setTagsParams({
          page: newPage - 1
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setTagsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
    const { id } = p;

    history.push(`${routes.settingsTagsDetails}/${id}`);
  };

  const deleteTagsHandler = () => {
    dispatch(deleteTag({ ids: selectionModel }));
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  const tableConfig = {
    sortingMode: false
  };

  const closeModalHandler = () => {
    setModalOpen(false);
    setCreateTagState("");
  };

  const createTagHandler = () => {
    if (createTagState) {
      dispatch(createTag({ data: { value: createTagState }, callback: closeModalHandler }));
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Tags</Title>
      <div className={styles.searchRow}>
        <Search onSearch={handleSearch} />

        <div className={styles.btnsContainer}>
          <Button icon="plus" onClick={() => setModalOpen(true)} className={styles.btn}>
            Create tag
          </Button>
          <Button
            onClick={deleteTagsHandler}
            className={styles.btn}
            isLoading={isDeleteLoading}
            disabled={!selectionModel.length}
          >
            Delete
            <Icon.Delete color="#ffff" />
          </Button>
        </div>
      </div>

      <div className={styles.table}>
        <Table
          {...tableConfig}
          data={isLoading ? [] : data}
          columns={columns}
          loading={isLoading}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          page={page || 0}
          selectionModel={selectionModel}
          pageSize={pageSize}
          // rowsPerPageOptions={[5, 10, 20, 50]}
          // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          // onPageChange={onPageChange}
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
          components={{
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(total, pageSize),
              onChange: onPageChange,
              page: page + 1,
              totalCount: total || 0,
              selected: pageSize,
              perPageChange: (event, value) => {
                setPageSize(event?.target?.value);
              }
            }
          }}
        />
      </div>

      <AddTagModal
        onChange={setCreateTagState}
        value={createTagState}
        onCloseModal={closeModalHandler}
        isCreateTagLoading={isCreateTagLoading}
        onSubmit={createTagHandler}
        isModalOpen={isModalOpen}
      />
    </ContentContainer>
  );
};

Tags.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Tags;
