import React from "react";
import PropTypes from "prop-types";

export default function ExpendArrow(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2007 16.5719C10.6235 17.5726 9.17928 17.5726 8.60205 16.5719L0.891698 3.20575C0.314845 2.20575 1.03656 0.95624 2.19101 0.95624L17.6116 0.95624C18.766 0.95624 19.4878 2.20575 18.9109 3.20576L11.2007 16.5719Z"
        stroke={props.color}
      />
    </svg>
  );
}

ExpendArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

ExpendArrow.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 20,
  height: 18
};
