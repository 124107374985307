import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ErrorText = ({ errorMsg }) => {
  return (
    <div className="text-red-500">
      <div className="flex items-center text-xs">
        <span className="pr-1">
          {errorMsg == undefined ? null : (
            <InfoOutlinedIcon fill="#DA1414" sx={{fontSize: "17px"}} className="mt-0.5"/>
          )}
        </span>
        {errorMsg}
      </div>
    </div>
  );
}

export default ErrorText;
