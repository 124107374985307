import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addTag, getTags } from "store/entities/vulnerabilities/actions";
import { getTagsVulnerabilitiesListSelect } from "store/entities/vulnerabilities/selectors";

import { Button, Title, Modal, Select } from "components/simple";

import styles from "./styles.module.scss";

const ExistingTag = ({ open, onBack = () => null, close, data }) => {
  const dispatch = useDispatch();
  const [tag, setTag] = useState("");

  const tagList = useSelector(getTagsVulnerabilitiesListSelect);

  const onSave = () => {
    const res = {
      id: tag?.value,
      ids: data
    };
    dispatch(addTag(res));
  };

  useEffect(() => {
    dispatch(getTags());
  }, []);

  return (
    <Modal isOpen={open} toggle={() => close()}>
      <Title>Add Tag</Title>
      <div className={styles.content}>
        <div className={styles.form}>
          <Select
            containerClass={styles.select}
            onChange={val => setTag(val)}
            value={tag}
            placeholder="Select"
            label="Tag"
            options={tagList}
          />
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={onBack} variant="outline" className={styles.modalButton}>
          Back
        </Button>
        <Button onClick={onSave} className={styles.modalButton} disabled={!tag}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default ExistingTag;
