import { customPost } from "api/base";
// eslint-disable-next-line import/no-unresolved
import { useMutation } from "react-query";
import { addNotification } from "../notifications/actions";
import { useDispatch } from "react-redux";

export const useDeboardClusters = () => {
  return useMutation(["deboardCluster"], async payload => {
    const response = await customPost("/cluster-onboarding/api/v1/deboard-cluster", payload);
    const result = await response.json();

    return result;
  });
};

export const useStoreClusterMutation = () => {
  const dispatch = useDispatch();

  return useMutation(
    ["storeClusterMutation"],
    async payload => {
      const response = await customPost("/cluster-onboarding/api/v1/store-clusters", payload);
      if (!response.ok) {
        throw new Error("Error while storing cluster");
      }
      const result = await response.json();
      return result;
    },
    {
      onSuccess: () => {
        // dispatch(addNotification({ msg: "Cluster onboarded successfully", type: "success" }));
      },
      onError: async data => {
        const response = await data.json();
        if (response.error_code || response.error_message) {
          dispatch(
            addNotification({
              msg: "Cluster Name Already Exists",
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: "There was an error while onboarding the cluster",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useStoreVmOnboardMutation = () => {
  return useMutation(["storeVmOnboardMutation"], async payload => {
    const response = await customPost("/vm-management/api/v1/get-command", payload);
    const result = await response.json();

    return result;
  });
};
