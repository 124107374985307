import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

function Barchart({data}) {
  return (
    <ResponsiveContainer width="99%" height="84%">
    <BarChart
      data={data}
      margin={{
        top: 5,
        right: 0,
        left: 0,
        bottom: -3
      }}
      barSize={30}
      maxBarSize={18}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis stroke="black" style={{ fontSize: '12', fontWeight: '600' }} dataKey="Time" />
      <YAxis stroke="black" style={{ fontSize: '12', fontWeight: '600' }} />
      <Tooltip />
      <defs>
        <linearGradient
          id="colorUv"
          x1="0"
          y1="0"
          x2="0"
          y2="100%"
          spreadMethod="reflect"
        >
          <stop offset="0" stopColor="#021040" />
          <stop offset="1" stopColor="#021040" />
        </linearGradient>
      </defs>
      <Bar cursor="pointer"  dataKey="count" fill="#021040" />
    </BarChart>
    </ResponsiveContainer>
  );
};

export default Barchart;
