/* eslint-disable camelcase */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { routes } from "router";

import { getS3ProfileSelector } from "store/entities/settings/selectors";

import { getS3Profile, deleteS3Profile, addModalS3Profile } from "store/entities/settings/actions";

import { ContentContainer, Title, Button, Icon, Status } from "components/simple";
import AddS3ProfileModal from "./AddS3ProfileModal";

import styles from "./styles.module.scss";
import NoTableRowData from "components/complex/Table/NoTableRowData";

const DataSource = ({ history }) => {
  const dispatch = useDispatch();

  const selectTenant = useSelector(state => state.users.selectTenant);
  const data = useSelector(getS3ProfileSelector);
  const id = data?.id;

  useEffect(() => {
    dispatch(getS3Profile());
  }, [selectTenant]);

  const onDelete = e => {
    e.stopPropagation();
    dispatch(deleteS3Profile());
  };

  const onAdd = () => {
    dispatch(addModalS3Profile(true));
  };

  const onEdit = () => {
    history.push(`${routes.settingsDataSourceEdit}`);
  };

  return (
    <ContentContainer className={styles.content}>
      <div className={styles.header}>
        <Title>S3 Profile</Title>
        {!id ? (
          <Button icon="plus" onClick={onAdd}>
            Add S3 Profile
          </Button>
        ) : null}
      </div>
      <div className={styles.table}>
        <div className={styles.head}>
          <div className={styles.col}>
            <div className={styles.headerTitle}>S3 Name</div>
          </div>
          <div className={styles.col}>
            <div className={styles.headerTitle}>Status</div>
          </div>
        </div>
        <div className={styles.body}>
          {id ? (
            <div className={styles.row} onClick={onEdit}>
              <div className={styles.col}>
                <div className={styles.name}>{data?.aws_s3_bucket_name}</div>
              </div>
              <div className={styles.col}>
                <Status connected />
              </div>
              <div className={styles.col}>
                <div className={styles.delete} onClick={onDelete}>
                  <Icon.Delete />
                </div>
              </div>
            </div>
          ) :
            <NoTableRowData style={{height: "25rem"}} />
          }
        </div>
      </div>
      <AddS3ProfileModal />
    </ContentContainer>
  );
};

DataSource.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default DataSource;
