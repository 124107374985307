import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { addNotification } from "../notifications/actions";
import {
  ACCUKNOX_API,
  authorization,
  authorizationTenant,
  contentTypeJson,
  getTenantId
} from "api/api";
import { customPost, get } from "api/base";
import { getDifferenceTime, getTime } from "screens/MonitorLogs/helper";
import moment from "moment";
import { keyValueChange } from "screens/RuntimeSecurity/AppBehavior/Utils/utils";

export const useGetKeysList = (onSuccess = () => {}, operation) => {
  const dispatch = useDispatch();
  return useQuery(
    ["getKeysList", operation],
    async () => {
      const response = await get(
        `${ACCUKNOX_API}/observabilityapi/v2/keys?operation=${operation}`,
        {
          ...contentTypeJson(),
          ...authorization(),
          ...authorizationTenant(),
          ...getTenantId()
        }
      );
      const data = await response.json();
      return data;
    },
    {
      select: data => {
        const dataKeys = data?.map(item => ({
          value: item?.key,
          label: keyValueChange(item?.key),
          type: item?.type
        }));
        return dataKeys;
      },
      onSuccess,
      onError: async data => {
        dispatch(addNotification({ msg: "Something went wrong!", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};

export const useGetValuesList = (
  onSuccess = () => {},
  cluster_Id,
  pod_name,
  operation,
  key,
  range
) => {
  const dispatch = useDispatch();
  return useQuery(
    ["getValuesList", cluster_Id, pod_name, operation, key, range],
    async () => {
      const now = new Date();
      let from;
      let to;
      if (typeof range === "string") {
        if (range === "All") {
          from = 7549866000;
          to = now.getTime();
        } else {
          from = getDifferenceTime(range, now).getTime();
          to = now.getTime();
        }
      }
      if (typeof range === "object") {
        let modifiedPeriod = { ...range };
        if (moment(range.from).diff(range.to, "miliseconds") < 30) {
          modifiedPeriod.from = moment(range.from).startOf("day");
          modifiedPeriod.to = moment(range.to).endOf("day");
        }
        from = getTime(modifiedPeriod.from);
        to = getTime(modifiedPeriod.to);
      }
      const payload = {
        cluster_id: cluster_Id,
        pod_name: pod_name,
        operation: operation,
        key: key,
        from_time: Math.floor(from / 1000),
        to_time: Math.floor(to / 1000)
      };

      const response = await customPost("/observabilityapi/v2/get-values", payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        const dataFilter = data?.result?.filter(item => Boolean(item));
        const dataVal = dataFilter?.map(itm => ({
          value: itm,
          label: itm
        }));
        return dataVal;
      },
      onSuccess,
      onError: async data => {
        dispatch(addNotification({ msg: "Something went wrong!", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};
