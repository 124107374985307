import { inRange } from "lodash";
import { SEVERITY_COLORS } from "../registryScan/constants";

export const getSeverity = score => {
  if (!score) return "None";
  if (inRange(score, 0.1, 4.0)) return "Low";
  if (inRange(score, 4.0, 7.0)) return "Medium";
  if (inRange(score, 7.0, 9.0)) return "High";
  if (inRange(score, 9.0, 10.1)) return "Critical";
};

export const getSeverityColor = score => {
  const severity = getSeverity(Number(score));

  if (severity === "None") return SEVERITY_COLORS["Negligible"];
  return SEVERITY_COLORS[severity];
};
