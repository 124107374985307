import React from "react";

const NavArrow = ({ className }) => {
  return (
    <div className={className}>
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.5 10.5L1 6L5.5 1.5"
          stroke="#2B3858"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default NavArrow;
