import React from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import "./override.css";

const highlightWithLineNumbers = (code, row) =>
  code
    .split("\n")
    .slice(0, -1)
    .map((line, i) => `${i + row} ${line}`)
    .join("\n");

const CodePreview = ({ snippet, lang }) => {
  if (typeof snippet === "string") {
    return <div className={styles.noDataContainer}>No data</div>;
  }
  return (
    <div className={styles.editorContainer}>
      {snippet.map(item => {
        return (
          <CodeEditor
            className={styles.editor}
            value={highlightWithLineNumbers(item.code, item.start_line)}
            language={lang}
          />
        );
      })}
    </div>
  );
};

CodePreview.propTypes = {
  snippet: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      start_line: PropTypes.number
    })
  ),
  lang: PropTypes.string
};

CodePreview.defaultProps = {
  snippet: [],
  lang: ""
};

export default CodePreview;
