import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "../notifications/actions";

// Watchers //

// Get Dashboard
function* getDashboardWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getDashboard), payload);
    yield put(actions.getDashboardSuccess(response));
  } catch (error) {
    yield put(actions.getDashboardFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Dashboard
function* watchGetDashboardWorker() {
  yield takeEvery(actions.getDashboard, getDashboardWorker);
}

export default function* rootSaga() {
  yield all([fork(watchGetDashboardWorker)]);
}
