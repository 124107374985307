import React from "react";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";
import { styled, useTheme } from "@mui/material";
import { Icon } from "../../../simple";

const ItemLink = styled("div")(({ theme, active }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.sideMenu.textColor,
    fontWeight: "500",
    padding: "10px 12px",
    cursor: "pointer",
    borderRadius: "4px",
    background: active === "true" ? theme.sideMenu.highlight : "transparent",
    marginBottom: "8px",
    "&:hover": {
      background: theme.sideMenu.highlight
    },
    "& span svg path": {
      stroke: theme.sideMenu.logoColor
    }
  };
});

export default function Item({ text, active, icon, onClick }) {
  const IconImg = Icon[icon];
  const theme = useTheme();
  const logoThemeColor = theme.sideMenu.logoColor;
  return (
    <ItemLink onClick={onClick} active={active.toString()}>
      <span className={styles.icon}>
        <IconImg color={logoThemeColor} style={{ color: logoThemeColor }} />
      </span>
      <span className={styles.title}>{text}</span>
    </ItemLink>
  );
}

Item.propTypes = {
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

Item.defaultProps = {
  active: false,
  onClick: () => {}
};
