import React from "react";

import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { formatDate } from "helper/DateLib";

import renderCellExpand from "helper/renderCellExpand";

const assets = state => state.assets?.assets;
const assetDetails = state => state.assets?.assetDetails;
const assetDataList = state => state.assets?.assetDataList;
const filtersList = state => state.assets?.filtersList;
const visualDashboardData = state => state.assets?.visualDashboardData;
const dataListByAsset = state => state.assets?.dataListByAsset;
const findingsByAsset = state => state.assets?.findingsByAsset;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const assetsColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Asset",
    field: "title",
    resizable: true,
    minWidth: 200,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Label",
    field: "label",
    resizable: true,
    minWidth: 200,
    flex: 1,
    renderCell: params => {
      return renderCellExpand({
        ...params,
        value: params?.row?.label?.name
      });
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },

  {
    headerName: "Targets",
    field: "targets",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value?.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },

  {
    headerName: "Baseline",
    field: "compliances",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.compliances;
      return (
        <>
          <div style={{ color: "#9EEC60", fontWeight: "bold" }}>{param?.passed ?? ""}</div>/
          <div style={{ color: "#d83e3c", fontWeight: "bold" }}>{param?.failed ?? ""}</div>
        </>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Total Vulnerabilities",
    field: "findings",
    resizable: true,
    minWidth: 210,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.vulnerabilities;
      const render = () => {
        const res = [];
        if (param?.Medium) {
          res.push(
            <div style={{ color: "#FEBD46", fontWeight: "bold" }}>{param.Medium || ""}</div>
          );
        }
        if (param?.High) {
          res.push(<div style={{ color: "#576ACA", fontWeight: "bold" }}>{param.High || ""}</div>);
        }
        if (param?.Low) {
          res.push(<div style={{ color: "#9EEC60", fontWeight: "bold" }}>{param.Low || ""}</div>);
        }
        if (param?.Critical) {
          res.push(
            <div style={{ color: "#d83e3c", fontWeight: "bold" }}>{param.Critical || ""}</div>
          );
        }
        return res;
      };

      return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "15px" }}>
          {render()}
        </div>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Last Scan date",
    field: "last_scan",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: params => {
      return <div>{formatDate(params?.row?.last_scan)}</div>;
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Asset type",
    field: "obj_type",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Data types",
    field: "data_types",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value?.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Monitors",
    field: "monitors",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value?.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  }
];

export const assetsSelector = createDraftSafeSelector(assets, state => {
  if (!state?.results?.length) {
    return { columns: assetsColumns, data: [], total: 0 };
  }
  const { results, count } = state;
  const data = results;
  return { columns: assetsColumns, data, total: count };
});

export const assetDetailsSelector = createDraftSafeSelector(assetDetails, state => {
  if (!state?.id) {
    return { vulnerabilities: [], baselines: [], details: null, tags: [], linked_assets: [] };
  }

  const obj = state?.vulnerabilities;
  const setColor = k => {
    switch (k) {
      case "Low":
        return "#9EEC60";
      case "Critical":
        return "#EB5958";
      case "High":
        return "#576ACA";
      case "Medium":
        return "#FEBD46";
      default: {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return `#${randomColor}`;
      }
    }
  };

  const vulnerabilities = Object.keys(obj).map(key => {
    return { title: key, key, value: obj[key], color: setColor(key) };
  });

  const res = {
    vulnerabilities,
    compliances: [
      {
        title: "Failed",
        key: "fail_baselines",
        value: state?.compliances?.failed || 0,
        color: "#EB5958"
      },
      {
        title: "Passed",
        key: "pass_baselines",
        value: state?.compliances?.passed || 0,
        color: "#9EEC60"
      }
    ],
    baselines: state?.baselines?.map(item => ({ label: item?.name, value: item?.id })),
    details: {
      name: state?.title,
      label: state?.label?.name,
      tickets: state?.tickets,
      type: state?.obj_type,
      last_seen: formatDate(state?.last_scan, "dddd, MMMM D, YYYY HH:mm A"),
      groups: state.groups.length,
      monitors: state.monitors.length,
      linkedAssets: state.linked_assets.length
    },
    tags: state?.tags?.map(item => ({ label: item?.tag__value, value: item?.tag__id })),
    linked_assets: state?.linked_assets ?? []
  };

  return res;
});

export const assetFilterChoicesSelector = createDraftSafeSelector(filtersList, state => {
  if (!state?.data_types) {
    return { data_types: [], obj_type: [] };
  }
  const data = {
    data_types: state?.data_types.map(([a, b]) => ({ value: a, label: b })),
    obj_type: state?.obj_type.map(([a, b]) => ({ value: a, label: b }))
  };
  return data;
});

export const assetDataListSelector = createDraftSafeSelector(assetDataList, state => {
  const list = Object.entries(state);
  if (!list.length) {
    return [];
  }
  const res = list.map(([id, title]) => ({ title, id }));
  return res;
});

export const assetTypesCountSelector = createDraftSafeSelector(visualDashboardData, state => {
  if (!state.assetTypesCount) return [];
  const list = Object.entries(state.assetTypesCount);
  const res = list.map(([title, value]) => ({ title: capitalizeFirstLetter(title), value }));
  return res;
});

export const assetsChartDataSelector = createDraftSafeSelector(visualDashboardData, state => {
  if (!state.assetsChartData) return [];
  const list = Object.entries(state.assetsChartData);
  const res = list.map(([date, count]) => ({ date, count }));
  return res;
});

export const scansChartDataSelector = createDraftSafeSelector(visualDashboardData, state => {
  if (!state.scansChartData) return [];
  const res = state.scansChartData.map(({ scan_date__date, count }) => ({
    date: scan_date__date,
    count
  }));
  return res;
});

export const findingsChartDataSelector = createDraftSafeSelector(visualDashboardData, state => {
  if (!state.findingsChartData) return [];
  const res = state.findingsChartData.map(({ scans__scan_date__date, count }) => ({
    date: scans__scan_date__date,
    count
  }));
  return res;
});

export const getDataListByAssetSelector = createDraftSafeSelector(dataListByAsset, state => state);

export const getFindingsByAssetSelector = createDraftSafeSelector(findingsByAsset, state => {
  return {
    count: state.count,
    data: state.data,
    ticketsConfigs: state?.ticketsConfigs?.map(item => ({ value: item.id, label: item.name }))
  };
});
