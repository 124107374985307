import { createDraftSafeSelector } from "@reduxjs/toolkit";

const user = state => state.users?.userProfile;

const emailNotificationSubscriptions = state => state.users?.emailNotificationSubscriptions;

export const emailNotificationSubscriptionsSelector = createDraftSafeSelector(
  emailNotificationSubscriptions,
  state => {
    if (!state?.length) {
      return [];
    }
    const res = state?.map(([value, label]) => ({ label, value }));
    return res;
  }
);

export const getUserSelector = createDraftSafeSelector(user, state => {
  return {
    email: state.email,
    first_name: state.first_name,
    id: state.id,
    last_name: state.last_name,
    color_schema: state?.user_profile?.color_schema || "Theme9",
    email_notification_types: state?.user_profile?.email_notification_types?.map(item => ({
      value: item,
      label: item
    })),
    notification_types: state?.user_profile?.notification_types?.map(item => ({
      value: item,
      label: item
    })),
    mfa_device: state?.mfa?.name || state?.mfa?.id || "",
    mfa_id: state?.mfa?.id
  };
});
