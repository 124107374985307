import React from "react";
import Slack from "../assets/img/slack.svg";
import Jira from "../assets/img/jira.svg";
import Splunk from "../assets/img/splunk.svg";
import elasticsearch from "../assets/img/es.svg";
import AwsCloudWatch from "../assets/img/aws.svg";
import Rsyslog from "../assets/img/rsyslog.svg";
import Pagerduty from "../assets/img/disablePagerduty.svg";
import Email from "../assets/img/disableEmail.svg";
import Webhook from "../assets/img/disableWebhook.png";

export const SLACK_LINK = "/settings/integrations/slack";
export const SPLUNK_LINK = "/settings/integrations/splunk";
export const ELASTIC_SEARCH_LINK = "/settings/integrations/elastic-search";
export const JIRA_LINK = "/settings/integrations/jira";
export const AWS_CLOUD_WATCH_LINK = "/settings/integrations/aws-cloudwatch";
export const PAGERDUTY_LINK = "/settings/integrations/pagerduty";
export const WEBHOOK_LINK = "/settings/integrations/webhook";
export const RSYSLOG_LINK = "/settings/integrations/rsyslog";
export const EMAIL_LINK = "/settings/integrations/email";
export const INTEGRATION_LINK = "/settings/integrations/list";
export const INTEGRATIONS = "/settings/integrations";

// edit url's
export const EDIT_SLACK_LINK = "/settings/integrations/list/edit slackchannel";
export const EDIT_SPLUNK_LINK = "/settings/integrations/list/edit splunkchannel";
export const EDIT_ELASTIC_SEARCH_LINK = "/settings/integrations/list/edit elasticsearchchannel";
export const EDIT_JIRA_LINK = "/settings/integrations/list/edit jirachannel";
export const EDIT_AWS_CLOUD_WATCH_LINK = "/settings/integrations/list/edit cloudwatchchannel";
export const EDIT_PAGERDUTY_LINK = "/settings/integrations/list/edit pagerdutychannel";
export const EDIT_WEBHOOK_LINK = "/settings/integrations/list/edit webhookchannel";
export const EDIT_RSYSLOG_LINK = "/settings/integrations/list/edit rsyslogchannel";
export const EDIT_EMAIL_LINK = "/settings/integrations/list/edit emailchannel";

export const helpJira = "https://help.accuknox.com/getting-started/jira-integration/";
export const helpAWS = "https://help.accuknox.com/getting-started/cloudwatch-integration/";
export const helpElastic = "https://help.accuknox.com/getting-started/elastic-integration/";
export const helpSplunk = "https://help.accuknox.com/getting-started/splunk-integration/";
export const helpSlack = "https://help.accuknox.com/getting-started/slack-integration/";

export const notificationData = [
  {
    title: "Slack",
    logo: Slack,
    desc:
      "Ensure effective communication and collaboration within your team by using public and private channels, and get alerts from your Workspace to any of your Slack channels.",
    link: SLACK_LINK,
    disable: false,
    integrate: " Integrate Now ➔"
  },
  {
    title: "JIRA",
    logo: Jira,
    desc:
      "Streamline issue management by using a common platform to work collectively on issues related to your websites, servers, network, etc.",
    link: JIRA_LINK,
    disable: false,
    integrate: " Integrate Now ➔"
  }
];

export const securityEventData = [
  {
    title: "Splunk",
    logo: Splunk,
    desc:
      "Integrating with cloud-based Splunk deployments and enables you to view your Workspace alerts through the Splunk event collector. Splunk is a software platform that searches, analyzes, and visualizes machine-generated data gathered from websites, applications, sensors, and devices",
    link: SPLUNK_LINK,
    disable: false,
    integrate: " Integrate Now ➔"
  },
  {
    title: "Rsyslog",
    logo: Rsyslog,
    desc:
      "RSYSLOG is the rocket-fast system for log processing.It offers high-performance, great security features and a modular design.",
    link: RSYSLOG_LINK,
    disable: false,
    integrate: " Integrate Now ➔"
  },
  {
    title: "AWS CloudWatch",
    logo: AwsCloudWatch,
    desc:
      "AWS2 CloudWatch Component allows messages to be sent to an Amazon CloudWatch metrics The Implementations of the Amazon API is provided by the AWS SDK.",
    link: AWS_CLOUD_WATCH_LINK,
    disable: false,
    integrate: " Integrate Now ➔"
  }
  // {
  //   title: "Elastic Search",
  //   logo: elasticsearch,
  //   desc:
  //     "Conveniently send data to Elasticsearch Service. The Elastic Stack offers unique value in identifying security threats and reducing incident response times.",
  //   link: ELASTIC_SEARCH_LINK,
  //   disable: false,
  //   integrate: " Integrate Now ➔"
  // }
];
export const comingSoon = [
  {
    title: "Pagerduty",
    logo: Pagerduty,
    desc:
      "Generate PagerDuty incidents for alarms from your Workspace, streamline your communication within teams, and achieve higher incident resolution efficiency.",
    link: PAGERDUTY_LINK,
    disable: true,
    integrate: " Coming soon"
  },
  {
    title: "Email",
    logo: Email,
    desc: "Integrate your Workspace to send alerts as Emails to your email account.",
    link: EMAIL_LINK,
    disable: true,
    integrate: " Coming soon"
  },
  {
    title: "Webhooks",
    logo: Webhook,
    desc:
      "Use WebHooks to raise alarms for every status change in your Workspace. Webhooks are user-defined HTTP callbacks that are triggered by selected alerts.",
    link: WEBHOOK_LINK,
    disable: true,
    integrate: " Coming soon"
  }
];

export const customStyles = {
  height: 710,
  width: 1,
  paddingTop: 0,
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "none !important",
    borderTop: "none !important"
  },
  "& .MuiDataGrid-pinnedColumnHeaders": {
    boxShadow: "none !important"
  },
  "& .MuiDataGrid-pinnedColumns": {
    boxShadow: "none !important",
    "& .super-app-theme--row": {
      borderTopLeftRadius: "10px !important",
      borderBottomLeftRadius: "10px !important"
    }
  },
  "& .super-app-theme--header": {
    backgroundColor: "#fff"
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .super-app-theme--row": {
      borderBottom: "none !important",
      borderBottomRightRadius: "10px",
      borderTopRightRadius: "10px"
    }
  },
  "& .super-app-theme--row": {
    backgroundColor: "#fff",
    border: "1px solid #E7E6F8",
    boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
    marginBottom: "5px",
    "&:last-child": {
      marginBottom: 0
    }
  }
};
export const rows = [
  {
    id: 1,
    channel: "Slack",
    integration: "test-slack",
    status: "Active",
    test: "Start Testing"
  },
  {
    id: 2,
    channel: "Splunk",
    integration: "testAccuknox",
    status: "Active",
    test: "Start Testing"
  },
  {
    id: 3,
    channel: "CloudWatch",
    integration: "cloud",
    status: "Active",
    test: "Start Testing"
  },
  {
    id: 4,
    channel: "Jira",
    integration: "Test",
    status: "Active",
    test: "Start Testing"
  },
  {
    id: 5,
    channel: "Elastic",
    integration: "test-elastic",
    status: "Active",
    test: "Start Testing"
  },
  {
    id: 6,
    channel: "Slack",
    integration: "livealerts",
    status: "Active",
    test: "Start Testing"
  },
  {
    id: 7,
    channel: "Splunk",
    integration: "alertstest",
    status: "Slack",
    test: "Start Testing"
  }
];

export const AWS_ACCESS_KEY = `You can specify the access key in My security credential for an IAM user or the AWS account root user (Eg, AKIAIOSFODNN7EXAMPLE)`;
export const AWS_SECRET_KEY = `You can specify the security key in My security credential for an IAM user or the AWS account root user (Eg, wJalrXUtnFEMI/K7MDENG/bP)`;

export const regions = [
  { label: "us-east-2", value: "us-east-2" },
  { label: "us-east-1", value: "us-east-1" },
  { label: "us-west-1", value: "us-west-1" },
  { label: "us-west-2", value: "us-west-2" },
  { label: "af-south-1", value: "af-south-1" },
  { label: "ap-east-1", value: "ap-east-1" },
  { label: "ap-south-1", value: "ap-south-1" },
  { label: "ap-northeast-3", value: "ap-northeast-3" },
  { label: "ap-northeast-2", value: "ap-northeast-2" },
  { label: "ap-southeast-1", value: "ap-southeast-1" },
  { label: "ap-southeast-2", value: "ap-southeast-2" },
  { label: "ap-northeast-1", value: "ap-northeast-1" },
  { label: "ca-central-1", value: "ca-central-1" },
  { label: "cn-north-1", value: "cn-north-1" },
  { label: "cn-northwest-1", value: "cn-northwest-1" },
  { label: "eu-central-1", value: "eu-central-1" },
  { label: "eu-west-1", value: "eu-west-1" },
  { label: "eu-west-2", value: "eu-west-2" },
  { label: "eu-south-1", value: "eu-south-1" },
  { label: "eu-west-3", value: "eu-west-3" },
  { label: "eu-north-1", value: "eu-north-1" },
  { label: "me-south-1", value: "me-south-1" }
];

export const optionsChannels = [
  { value: 1, label: "Slack" },
  { value: 2, label: "Splunk" },
  { value: 3, label: "Cloudwatch" },
  { value: 4, label: "Elasticsearch" },
  { value: 5, label: "Rsyslog" },
  { value: 6, label: "Jira" }
];
