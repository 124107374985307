/* eslint-disable camelcase */
import { API, authorization, authorizationTenant } from "api/api";
import { formatDate } from "helper/DateLib";

import { get } from "api/base";

export const getDashboard = ({ date }) => {
  const date_from = date?.[0] ? formatDate(date[0]) : "2020-01-24";
  const date_to = date?.[1] ? formatDate(date[1]) : "2022-12-24";

  return get(`${API}/dashboard/data?date_from=${date_from}&date_to=${date_to}&limit=10`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
