import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import useCopyToClipboard from "helper/hooks/useCopyToClipboard";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import YamlEditor from "../YamlEditor";

const Container = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.grey[100],
  position: "relative",
  padding: "8px 12px"
}));

const CodeTextWrapper = styled("pre")(({ theme }) => ({
  height: "100%",
  width: "100%",
  margin: 0,
  overflowX: "auto",
  whiteSpace: "wrap",
  "& pre": {
    margin: 0
  }
}));

const CodeBlock = ({ text }) => {
  const [copy] = useCopyToClipboard();
  const dispatch = useDispatch();

  const handleCopyText = async () => {
    const isCopied = await copy(text);
    if (isCopied) {
      dispatch(addNotification({ msg: "Command copied to clipboard", type: "info" }));
    } else {
      dispatch(addNotification({ msg: "Failed to copy command", type: "error" }));
    }
  };

  return (
    <Container>
      <ContentCopyOutlinedIcon
        sx={{ fontSize: "28px", color: "primary.main" }}
        className="absolute top-2 right-4 z-10 shadow-md bg-white p-1 rounded cursor-pointer"
        onClick={handleCopyText}
      />

      <CodeTextWrapper>
        <YamlEditor editable={false} setCode={text} code={text} height="auto" />
      </CodeTextWrapper>
    </Container>
  );
};

CodeBlock.propTypes = {
  text: PropTypes.string.isRequired
};

export default CodeBlock;
