import React from "react";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import renderCellExpand from "helper/renderCellExpand";
import { formatDate } from "helper/DateLib";

const groupsMini = state => state.groups?.groupsMini;
const groups = state => state.groups.groups;
const groupAssets = state => state.groups.groupAssets;

export const groupsMiniListSelector = createDraftSafeSelector(groupsMini, state => {
  if (!state?.results?.length) {
    return [];
  }
  const data = state?.results?.map(item => {
    return {
      value: item?.id,
      label: item?.name
    };
  });
  return data;
});

export const groupsTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Name",
    field: "name",
    resizable: true,
    minWidth: 250,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Tickets",
    field: "tickets",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Assets",
    field: "assets",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Findings",
    field: "findings",
    resizable: true,
    minWidth: 160,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.findings;
      const render = () => {
        return param.map(([key, val]) => {
          let color = "#0000";
          switch (key) {
            case "Medium":
              color = "#FEBD46";
              break;

            case "High":
              color = "#576ACA";
              break;

            case "Low":
              color = "#9EEC60";
              break;

            case "Informational":
              color = "#6678df";
              break;

            case "Critical":
              color = "#d83e3c";
              break;

            default:
              break;
          }
          return <div style={{ color }}>{val || ""}</div>;
        });
      };

      return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", gap: "5px" }}>
          {render()}
        </div>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Baseline",
    field: "baselines",
    resizable: true,
    minWidth: 140,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.baselines;
      return (
        <>
          <div style={{ color: "#9EEC60" }}>{param?.passed ?? ""}</div>/
          <div style={{ color: "#d83e3c" }}>{param?.failed ?? ""}</div>
        </>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Date",
    field: "date",
    resizable: true,
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Time",
    field: "time",
    resizable: true,
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Monitors",
    field: "monitors",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value?.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  }
];

export const getGroupsSelector = createDraftSafeSelector(
  groups,

  state => {
    if (!state?.results?.length) {
      return { data: [], total: 0 };
    }

    const { results, count } = state;
    const data = results.map(item => {
      return {
        id: item.id,
        name: item.name,
        tickets: item.tickets_count.toString(),
        findings: item.findings,
        baselines: item.compliances,
        date: formatDate(item.last_scan),
        time: formatDate(item.last_scan, "hh:mm A"),
        monitors: item?.monitors,
        assets: item?.assets?.length || 0
      };
    });
    return { data, total: count };
  }
);

export const groupAssetsTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Asset",
    field: "title",
    resizable: true,
    minWidth: 250,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Tickets",
    field: "tickets",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Findings",
    field: "findings",
    resizable: true,
    minWidth: 160,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.vulnerabilities;
      const render = () => {
        const res = [];
        if (param?.Medium) {
          res.push(<div style={{ color: "#FEBD46" }}>{param.Medium || ""}</div>);
        }
        if (param?.High) {
          res.push(<div style={{ color: "#576ACA" }}>{param.High || ""}</div>);
        }
        if (param?.Low) {
          res.push(<div style={{ color: "#9EEC60" }}>{param.Low || ""}</div>);
        }
        if (param?.Critical) {
          res.push(<div style={{ color: "#d83e3c" }}>{param.Critical || ""}</div>);
        }
        return res;
      };

      return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "15px" }}>
          {render()}
        </div>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Baseline",
    field: "compliances",
    resizable: true,
    minWidth: 140,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.compliances;
      return (
        <>
          <div style={{ color: "#9EEC60" }}>{param?.passed ?? ""}</div>/
          <div style={{ color: "#d83e3c" }}>{param?.failed ?? ""}</div>
        </>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Date",
    field: "date",
    resizable: true,
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Time",
    field: "time",
    resizable: true,
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const getGroupAssetsSelector = createDraftSafeSelector(groupAssets, state => {
  if (!state?.results?.length) {
    return { data: [], total: 0 };
  }

  const { results, count } = state;
  const data = results.map(item => {
    return {
      id: item.id,
      title: item.title,
      tickets: item.tickets.toString(),
      vulnerabilities: item.vulnerabilities,
      compliances: item.compliances,
      date: formatDate(item.last_scan),
      time: formatDate(item.last_scan, "hh:mm A")
    };
  });
  return { data, total: count };
});
