import { getFormRequest, get, postFormRequest } from "api/base";
import { useQuery } from "react-query";
import moment from "moment";
import { customPost } from "api/base";
import { getTimeRange } from "helper/getTimeRange";
import { getDifferenceTime } from "screens/RuntimeSecurity/CWPP/service";
import { DATE_FROM, DATE_TO } from "store/entities/issues/registryScan/constants";
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
function getTime(date) {
  return new Date(date).getTime();
}
export const useImageSecurityIssues = (workspace, range) => {
  const DATA_TYPE = "SEVERITY_ISSUES";
  const LIMIT = 10;
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  return useQuery(
    ["imageSecurity", workspace, range],

    async () => {
      const response = await get(
        `${API}/dashboard?data_type=${DATA_TYPE}&date_from=${getFromTime}&date_to=${currentTimeMoment}&limit=${LIMIT}`,
        {
          ...contentTypeJson(),
          ...authorization(),
          ...authorizationTenant()
        }
      );
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false,
      select: data => data?.result
    }
  );
};
export const useImageRiskAssessment = (workspace, range) => {
  const DATA_TYPE = "SEVERITY_ASSESSMENT";
  const LIMIT = 10;
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  return useQuery(
    ["imageRisk", workspace, range],

    async () => {
      const response = await get(
        `${API}/dashboard?data_type=${DATA_TYPE}&date_from=${getFromTime}&date_to=${currentTimeMoment}&limit=${LIMIT}`,
        {
          ...contentTypeJson(),
          ...authorization(),
          ...authorizationTenant()
        }
      );
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false,
      select: data => data?.result
    }
  );
};

export const usePolicyCount = (workspace, range, Clusters = []) => {
  const URL = "/policymanagement/dashboard/v2/policy-count";
  const selecCluster = Clusters?.map(cluster => cluster?.value);
  const now = new Date();
  let from;
  let to;
  let payload;
  if (range === "All") {
    payload = {
      from_time: 0,
      to_time: 0
    };
  } else {
    if (typeof range === "string") {
      from = getDifferenceTime(range, now).getTime();
      to = now.getTime();
    } else if (typeof range === "object") {
      from = getTime(range.from);
      to = getTime(range.to);
    }
    payload = {
      from_time: 0,
      to_time: to ? to : 0,
      cluster_id: selecCluster ? selecCluster : []
    };
  }
  return useQuery(
    ["policyCount", workspace, Clusters],

    async () => {
      const response = await customPost(URL, payload);
      const res = await response.json();
      return res;
    },
    { enabled: Boolean(Clusters?.length), refetchOnWindowFocus: false }
  );
};

export const usePodCount = (workspace, Cluster = [], range) => {
  const URL = "/observabilityapi/api/v1/obs/get-top-ingress-egress";
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).unix();
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const convertCurrentTime = moment(currentTimeMoment).unix();
  const selecCluster = Cluster?.map(cluster => cluster?.value);
  let from;
  let to;
  let payload;
  payload = {
    cluster_id: selecCluster ? selecCluster : [],
    resource: "pod_name",
    metrics: {
      ingress: true,
      egress: true
    },
    limit: 5,
    fromTime: getFromTime ? getFromTime : 0,
    toTime: convertCurrentTime ? convertCurrentTime : 0
  };
  // }
  return useQuery(
    ["podCount", workspace, Cluster, range],

    async () => {
      const response = await customPost(URL, payload);
      const res = await response.json();
      return res;
    },
    { enabled: Boolean(Cluster?.length), refetchOnWindowFocus: false }
  );
};
export const useRemidationCount = (range, workspaceId, created) => {
  const currentTime = new Date();
  let from;
  let to;
  if (typeof range === "string") {
    from = moment(getDifferenceTime(range, currentTime).getTime()).format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    );
    to = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  }
  if (typeof range === "object") {
    let modifiedPeriod = { ...range };
    if (moment(range.from).diff(range.to, "seconds") < 30) {
      modifiedPeriod.from = moment(range.from).startOf("day");
      modifiedPeriod.to = moment(range.to).endOf("day");
    }
    from = moment(modifiedPeriod.from).format("YYYY-MM-DDTHH:mm:ss.SSS");
    to = moment(modifiedPeriod.to).format("YYYY-MM-DDTHH:mm:ss.SSS");
  }
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const originData = moment()
    .subtract(40, "years")
    .format("YYYY-MM-DDTHH:mm:ss.SSS");
  const URL = `${API}/dashboard?data_type=PIE_CHARTS_DATA&date_from=${
    created ? from : originData
  }&date_to=${created ? to : currentTimeMoment}`;
  return useQuery(
    ["Remidation ", range, workspaceId, created],

    async () => {
      const response = await get(URL, {
        ...contentTypeJson(),
        ...authorization(),
        ...authorizationTenant()
      });
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false,
      select: data => {
        return data?.result?.pie_charts_data?.tickets;
      }
    }
  );
};
export const fetchClustersList = (
  workspace,
  range,
  ClusterID = [],
  Namespace = [],
  PodName = []
) => {
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).unix();
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const convertCurrentTime = moment(currentTimeMoment).unix();
  let payload;
  const selecCluster = ClusterID?.map(cluster => cluster?.value.toString());
  const selecNamespace = Namespace?.map(namespace => namespace?.label.toString());
  const selecPod = PodName?.map(pod => pod?.label.toString());
  const CLUSTER_URL = "/dashboards/v1/telemetry/system/clusters/count";
  payload = {
    WorkspaceID: workspace?.toString(),
    ClusterID: selecCluster?.length ? selecCluster : [],
    Namespace: selecNamespace?.length ? selecNamespace : [],
    PodName: selecPod?.length ? selecPod : [],
    FromTime: getFromTime ? getFromTime : 0,
    ToTime: convertCurrentTime ? convertCurrentTime : 0
  };
  // }
  return useQuery(
    ["fetchClustersCounts", workspace, ClusterID, Namespace, PodName, range],
    async () => {
      const response = await customPost(CLUSTER_URL, payload);
      return await response.json();
    },
    {
      enabled: Boolean(ClusterID?.length),
      onSuccess: res => {
        return res;
      },
      onError: () => {
        return [];
      },
      refetchOnWindowFocus: false
    }
  );
};
export const fetchNamespaceList = (
  workspace,
  range,
  ClusterID = [],
  Namespace = [],
  PodName = []
) => {
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).unix();
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const convertCurrentTime = moment(currentTimeMoment).unix();
  let payload;
  const selecCluster = ClusterID?.map(cluster => cluster?.value.toString());
  const selecNamespace = Namespace?.map(namespace => namespace?.label.toString());
  const selecPod = PodName?.map(pod => pod?.label.toString());
  const NAMESPACE_URL = "/dashboards/v1/telemetry/system/namespaces/count";
  payload = {
    WorkspaceID: workspace?.toString(),
    ClusterID: selecCluster?.length ? selecCluster : [],
    Namespace: selecNamespace?.length ? selecNamespace : [],
    PodName: selecPod?.length ? selecPod : [],
    FromTime: getFromTime ? getFromTime : 0,
    ToTime: convertCurrentTime ? convertCurrentTime : 0
  };
  // }
  return useQuery(
    ["fetchNamespaceCounts", workspace, ClusterID, Namespace, PodName, range],
    async () => {
      const response = await customPost(NAMESPACE_URL, payload);
      return await response.json();
    },
    {
      enabled: Boolean(ClusterID?.length),
      onSuccess: res => {
        return res;
      },
      onError: () => {
        return [];
      },
      refetchOnWindowFocus: false
    }
  );
};
export const useClustersList = (workspace, accessToken, range) => {
  let payload;
  const filteredRange = getTimeRange(range);
  let from = filteredRange?.from;
  let to = filteredRange?.to;
  payload = {
    workspace_id: workspace,
    from_time: from ? [from.toString()] : [],
    to_time: to ? [to.toString()] : []
  };
  return useQuery(
    ["getAllClusters", range, workspace, accessToken],
    async () => {
      const response = await customPost(
        "/cm/api/v1/cluster-management/clusters-in-workspace-filter",
        payload
      );
      const res = response.json();
      return res;
    },
    {
      select: data => {
        return {
          ...data,
          options: data.result?.map(c => ({ label: c.ClusterName, value: c.ID })) || []
        };
      },
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(workspace)
    }
  );
};
