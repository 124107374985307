import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Title, Input, Button, ContentContainer, ErrorText } from "components/simple";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useForm, Controller } from "react-hook-form";
import SlackIcon from "../../assets/img/slack.svg";
import { useHistory, useLocation } from "react-router-dom";
import { EDIT_SLACK_LINK, helpSlack } from "screens/ChannelIntegration/constants";
import CryptoJS from "crypto-js";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "./DeleteModal";
import { getAccuknoxWorkspace } from "api/api";
import {
  useDeleteChannel,
  useSlackTest,
  useSlackIntegration,
  useEditSlackIntegration
} from "store/entities/channels/mutation";

function SlackIntegration() {
  const [integrationName, setIntegrationName] = useState("");
  const [hookUrl, setHookUrl] = useState("");
  const [senderName, setSenderName] = useState("");
  const [title, setTitle] = useState("");
  const [channelName, setChannelName] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const workspaceVal = getAccuknoxWorkspace();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: "onChange" });
  const history = useHistory();

  // editSLack stuffs
  const isNotUndefined = val => typeof val !== "undefined";
  const decryptWebHook = location?.state?.WebhookUrl?.split(",") ?? "";
  const slack_Url = CryptoJS.AES.decrypt(decryptWebHook[0] ?? "", decryptWebHook[1] ?? "");
  const getIntegrationName = location?.state?.IntegrationName;
  const getHookUrl = slack_Url.toString(CryptoJS.enc.Utf8);
  const getSenderName = location?.state?.SenderName;
  const getTitle = location?.state?.Title;
  const getChannelName = location?.state?.ChannelName;

  useEffect(() => {
    if (
      (location?.pathname === EDIT_SLACK_LINK && integrationName) ||
      hookUrl ||
      senderName ||
      title ||
      channelName
    ) {
      setValue("integrationName", integrationName);
      setValue("hookUrl", hookUrl);
      setValue("senderName", senderName);
      setValue("title", title);
      setValue("channelName", channelName);
    }
  }, [integrationName, hookUrl, senderName, title, channelName]);

  useEffect(() => {
    if (location?.pathname === EDIT_SLACK_LINK) {
      setIntegrationName(getIntegrationName);
      setHookUrl(getHookUrl);
      setSenderName(getSenderName);
      setTitle(getTitle);
      setChannelName(getChannelName);
    }
  }, []);

  let shouldUpdate =
    (isNotUndefined(integrationName) ||
      isNotUndefined(hookUrl) ||
      isNotUndefined(senderName) ||
      isNotUndefined(title) ||
      isNotUndefined(channelName)) &&
    (integrationName !== getIntegrationName ||
      hookUrl !== getHookUrl ||
      senderName !== getSenderName ||
      title !== getTitle ||
      channelName !== getChannelName);

  useEffect(() => {
    shouldUpdate = integrationName !== getIntegrationName || hookUrl !== getHookUrl;
    // eslint-disable-next-line no-unused-expressions
    senderName !== getSenderName || title !== getTitle || channelName !== getChannelName;
  }, [hookUrl, integrationName, senderName, title, channelName]);

  const slackIntegration = useSlackIntegration(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Slack Integration Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });
  const slackTest = useSlackIntegration(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Test Successful!", type: "success" }));
      setDisableSave(true);
    } else {
      dispatch(addNotification({ msg: data?.response, type: "error" }));
      setDisableSave(false);
    }
  });
  const handleTest = async e => {
    try {
      const Option = "test";
      const Secretkey = (Math.random() + 1).toString(36).substring(7);
      const EncryptedURL = CryptoJS.AES.encrypt(hookUrl, Secretkey).toString();
      const webhookUrl = EncryptedURL.concat(",", Secretkey);
      slackTest?.mutate({
        integrationName,
        senderName,
        webhookUrl,
        channelName,
        Option,
        title
      });
    } catch (err) {
      // console.log(err)
    }
  };

  const slackUpdateIntegration = useEditSlackIntegration(data => {
    if (data?.response === "success") {
      dispatch(addNotification({ msg: "Slack updated Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });
  const onSubmit = async e => {
    if (location?.pathname === EDIT_SLACK_LINK) {
      try {
        const Secretkey = (Math.random() + 1).toString(36).substring(7);
        const EncryptedURL = CryptoJS.AES.encrypt(hookUrl, Secretkey).toString();
        const webhookUrl = EncryptedURL.concat(",", Secretkey);
        const ID = location?.state?.id;
        slackUpdateIntegration.mutate({
          ID,
          integrationName,
          senderName,
          webhookUrl,
          channelName,
          title
        });
      } catch (error) {
        // console.log(error)
      }
    } else {
      try {
        const Secretkey = (Math.random() + 1).toString(36).substring(7);
        const EncryptedURL = CryptoJS.AES.encrypt(hookUrl, Secretkey).toString();
        const webhookUrl = EncryptedURL.concat(",", Secretkey);
        const Option = "save";
        slackIntegration.mutate({
          integrationName,
          senderName,
          webhookUrl,
          channelName,
          Option,
          title
        });
      } catch (err) {
        // console.log(err)
      }
    }
  };
  const closeHandler = () => {
    setIsOpen(false);
  };
  const deleteSlackChannel = useDeleteChannel(data => {
    history.push("/settings/integrations/list");
    dispatch(addNotification({ msg: "Deleted Slack Integration", type: "success" }));
  });
  const handleDelete = async () => {
    const ID = location?.state?.id;
    const channeId = 1;
    deleteSlackChannel.mutate({
      channeId,
      ID
    });
  };
  return (
    <ContentContainer className={styles.container}>
      <DeleteModal
        isModalOpen={isOpen}
        onCloseModal={() => closeHandler()}
        onSubmit={() => handleDelete()}
      />
      <div className="flex justify-between py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex gap-1">
          <img src={SlackIcon} alt="icon" />
          <Title>Slack</Title>
        </div>
        <div className="flex justify-center items-center gap-5">
          {location?.pathname === EDIT_SLACK_LINK && (
            <div
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => setIsOpen(true)}
            >
              <DeleteOutlineIcon />
              Delete Channel
            </div>
          )}
          <div
            onClick={() => window.open(helpSlack, "_blank", "noopener,noreferrer")}
            className="flex justify-center items-center text-blue-700 font-medium cursor-pointer"
          >
            Help <HelpOutlineIcon className={styles.help_icon} />
          </div>
        </div>
      </div>
      <div className="py-5">
        <div className="flex flex-row gap-5">
          <div className="w-2/4">
            <Controller
              name="integrationName"
              {...register("integrationName", {
                required: "This Field Cannot be empty",
                maxLength: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="integrationName"
                  label="Integration Name"
                  placeholder="Enter a name for integration"
                  required
                  value={integrationName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setIntegrationName(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.integrationName && errors.integrationName.message} />
            </div>
          </div>
          <div className="w-full">
            <Controller
              name="hookUrl"
              {...register("hookUrl", {
                required: "Invalid Hook URL, Please enter a valid URL",
                pattern: {
                  value: /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/,
                  message: "Enter valid Url."
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="hookUrl"
                  label="Hook URL"
                  placeholder="https://hooks.slack.com/services/T000/B000/XXXXXXX"
                  required
                  value={hookUrl}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setHookUrl(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.hookUrl && errors.hookUrl.message} />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-0 pb-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className=" py-5 flex flex-row gap-5">
          <div className="w-1/3">
            <Controller
              name="senderName"
              {...register("senderName", {
                required: "Sender Name Required",
                pattern: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="senderName"
                  label="Sender Name"
                  placeholder="Enter the Sender Name"
                  required
                  value={senderName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setSenderName(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.senderName && errors.senderName.message} />
            </div>
          </div>
          <div className="w-1/3">
            <Controller
              name="title"
              {...register("title", {
                required: "Title Required"
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="title"
                  label="Title"
                  placeholder="Enter the Title"
                  required
                  value={title}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setTitle(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.title && errors.title.message} />
            </div>
          </div>
          <div className="w-1/3">
            <Controller
              name="channelName"
              {...register("channelName", {
                required: "Channel Name Required",
                maxLength: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="channelName"
                  label="Channel Name"
                  placeholder="Enter the Channel Name"
                  required
                  value={channelName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setChannelName(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.channelName && errors.channelName.message} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between pt-6 pb-4">
        <Button
          onClick={handleSubmit(handleTest)}
          disabled={location?.pathname === EDIT_SLACK_LINK ? !shouldUpdate : !isDirty || !isValid}
        >
          Test
        </Button>
        <div className="flex">
          <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={location?.pathname === EDIT_SLACK_LINK ? !shouldUpdate : !isValid}
            type="submit"
          >
            Save
          </Button>
        </div>
      </div>
    </ContentContainer>
  );
}

export default SlackIntegration;
