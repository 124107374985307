import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Table } from "components/complex";
import {
  Button,
  ContentContainer,
  Modal,
  Search,
  Select,
  Tabs,
  Title,
  Textarea,
  Tags,
  CustomPagination
} from "components/simple";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import PolicyStatusToggle from "components/simple/PolicyStatusToggle";
import ActionMenu from "components/simple/ActionMenu";
import Plus from "components/simple/Icon/Plus";
import HistoryIcon from "@mui/icons-material/History";
import DeleteIcon from "@mui/icons-material/Delete";
import CommentMessagePopover from "components/simple/CommentMessagePopover";
import { navigate } from "helper/history";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "router/router";
import {
  useClustersQuery,
  useGetTagsQuery,
  useNamespacesQuery
} from "store/entities/filters/queries";
import {
  callUpdatePolicyMutation,
  PolicyYaml,
  useGetAllPoliciesCountQuery,
  useGetAllPoliciesQuery,
  useGetUsersQuery
} from "store/entities/policies/queries";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedClusters,
  getSelectedInfraType,
  getSelectedNamespaces,
  getSelectedPolicyStatus,
  getSelectedPolicyType,
  setSelectedClusters,
  setSelectedInfraType,
  setSelectedNamespace,
  setSelectedPolicyStatus,
  setSelectedPolicyType
} from "store/entities/filters/slice";
import renderCellExpand from "helper/renderCellExpand";
import SelectorLabel from "components/simple/SelectorLabel";
import PolicyTags from "components/simple/PolicyTags";
import { Badge, Box, Drawer, IconButton, Typography } from "@mui/material";
import CreatableSelect from "react-select/creatable";
import {
  useAddTagsToPoliciesMutation,
  useChangePolicyStatusMutation,
  useDeletePolicyMutation,
  useMultipleDeletePolicyMutation,
  useDeleteTagsMutation,
  usePolicyIgnoreMutation,
  usePolicyApplyMutation,
  usePolicyStatusMutation
} from "store/entities/policies/mutations";
import { addNotification } from "store/entities/notifications/actions";
import { useQueryClient } from "react-query";
import { debounce, uniq } from "lodash";
import ButtonLink from "components/simple/ButtonLink";
import kubearmor from "./assets/kubearmor.png";
import kubernetes from "./assets/kubernetes.png";
import { Cilium } from "components/simple/Icon/Cilium";
import CustomSelect from "components/simple/CustomSelect";
import CircleIcon from "@mui/icons-material/Circle";
import TemporaryDrawer from "components/simple/Drawer/Drawer";
import { policyInfoHeader, policyInfoRow, policyJsonheader } from "./mockData";
import DrawerTable from "./DrawerTable";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { getAccuknoxWorkspace } from "api/api";
import { useEffect } from "react";
import { AllPolicyTabs, totalTableCount } from "./helper";
import ViewYamlBox from "./components/ViewYamlBox/ViewYamlBox";
import UpdateAvailabilityDialogBox from "./components/UpdatePolicyDialog/ChangesAvailabilityDialogBox";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

const AddTagsModal = ({ open, close, policyId, tags }) => {
  const dispatch = useDispatch();
  const [pagePrev, setPagePrev] = useState(0);
  const [pageSize] = useState(20);
  const [pageNext, setPageNext] = useState(pagePrev + pageSize);
  const [query, setQuery] = useState("");

  const [selectedTags, setSelectedTags] = useState([]);
  const queryClient = useQueryClient();

  const addTags = useAddTagsToPoliciesMutation(() => {
    dispatch(addNotification({ msg: "Tag added to policy", type: "success" }));
    queryClient.invalidateQueries(["allPolicies"]);
    setSelectedTags([]);
    close();
  });

  const handleTagInput = v => {
    setSelectedTags([...v]);
  };

  const { data } = useGetTagsQuery(pagePrev, pageNext, query, policyId, open);
  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Add Tags</Title>
      <Box
        sx={{
          marginTop: "16px"
        }}
      >
        <CreatableSelect
          value={selectedTags}
          onChange={v => handleTagInput(v)}
          placeholder="Select Tags"
          options={data?.options}
          containerClass="mt-4"
          isMulti
        />
      </Box>
      <h5 className="mx-0 my-4 text-lg font-medium">Existing Tags</h5>
      <Box
        sx={{
          marginTop: "16px",
          padding: "12px 8px",
          border: "1px solid #d2d6dc",
          borderRadius: "8px",
          maxHeight: "110px",
          overflowY: "auto"
        }}
      >
        {/* <PolicyTags
          deletable
          onDelete={() => {}}
          tagData={tags?.map(t => ({ id: t.label_id, name: t.name }))}
        /> */}
        {tags?.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {tags?.map(tag => (
              <div key={tag?.id} className={styles.existingTagList}>
                {tag?.name}
              </div>
            ))}
          </div>
        ) : (
          "None"
        )}
      </Box>
      <Box sx={{ marginTop: "16px" }}>
        <Button
          isLoading={addTags?.isLoading}
          onClick={() => {
            addTags.mutate({
              policy_id: policyId,
              tags: selectedTags.map(t => t.value).filter(id => typeof id === "number"),
              newTags: selectedTags.map(t => t.value).filter(name => typeof name === "string")
            });
          }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

const AllPolicies = () => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const toPolicyDetails = (policyId, cluster, namespace, row) => {
    navigate(routes.policyDetails, { policy_id: policyId, cluster, namespace, row });
  };

  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pagePrev, setPagePrev] = useState(0); // initial page starts from 0 and not from 1
  const [pageNext, setPageNext] = useState(0 + pageSize);
  const [showAddTagsModal, setShowAddTagsModal] = useState(false);
  const [showYaml, setShowYaml] = useState(false);
  const [showyamlDiff, setShowyamlDiff] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [selectedPolicyStatus, setSelectedPolicyStatus] = useState("");
  const [currentPolicy, setCurrentPolicy] = useState({});
  const [editComment, setEditComment] = useState("");
  const [showChangePolicyStatusConfirmation, setShowChangePolicyStatusConfirmation] = useState(
    false
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const [showCommitMessageModal, setShowCommitMessageModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const getUsers = useGetUsersQuery();

  const [activeTab, setActiveTab] = useState(0);
  const handleChange = useCallback(
    (event, newValue) => {
      setActiveTab(newValue);
      setPagePrev(0);
      setPageNext(0 + pageSize);
    },
    [activeTab]
  );

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm();
  const workspaceId = getAccuknoxWorkspace();
  const {
    data: allPolicies = {},
    isLoading: policiesLoading,
    refetch: refetchPolicies
  } = useGetAllPoliciesQuery(
    data => {
      const userIds = uniq(data?.list_of_policies?.map(p => p.updated_by));
    },
    pagePrev,
    pageNext,
    pageSize,
    workspaceId,
    selectedPolicyStatus,
    searchTerm
  );
  const {
    data: allPoliciesCount = {},
    isLoading: policiesCountLoading,
    refetch: refetchPoliciesCount
  } = useGetAllPoliciesCountQuery(() => {}, searchTerm);
  const changePolicyStatus = useChangePolicyStatusMutation(() => {
    setShowChangePolicyStatusConfirmation(false);
    dispatch(addNotification({ msg: "Policy status changed successfully", type: "success" }));
    setCurrentPolicy({});
    refetchPolicies();
  });
  const [showDeletePolicyModal, setShowDeletePolicyModal] = useState(false);
  const [showMultipleDeletePolicyModal, setShowMultipleDeletePolicyModal] = useState(false);
  const [showMultipleIgnorePolicyModal, setShowMultipleIgnorePolicyModal] = useState(false);
  const [showMultipleStatusPolicyModal, setShowMultipleStatusPolicyModal] = useState(false);
  const [showMultipleApplyPolicyModal, setShowMultipleApplyPolicyModal] = useState(false);
  const [editYaml, setEditYaml] = useState("");
  const deletePolicy = useDeletePolicyMutation(() => {
    setShowDeletePolicyModal(false);
    dispatch(addNotification({ msg: "Policy deleted successfully", type: "success" }));
    setCurrentPolicy({});
    refetchPolicies();
    refetchPoliciesCount();
  });

  const deleteMultiplePolicy = useMultipleDeletePolicyMutation(() => {
    setShowDeletePolicyModal(false);
    setShowMultipleDeletePolicyModal(false);
    // dispatch(addNotification({ msg: "Policies deleted successfully", type: "success" }));
    setCurrentPolicy({});
    setSelectionModel([]);
    refetchPolicies();
    refetchPoliciesCount();
  });

  const ignorePolicyMutation = usePolicyIgnoreMutation(
    () => {
      // setShowDeletePolicyModal(false);
      setShowMultipleIgnorePolicyModal(false);
      // dispatch(addNotification({ msg: "Policies ignored successfully", type: "success" }));
      setCurrentPolicy({});
      setSelectionModel([]);
      refetchPolicies();
      refetchPoliciesCount();
    },
    () => {
      setShowMultipleIgnorePolicyModal(false);
    }
  );

  const applyPolicyMutation = usePolicyApplyMutation(() => {
    setShowMultipleApplyPolicyModal(false);
    dispatch(addNotification({ msg: "Policies applied successfully", type: "success" }));
    setCurrentPolicy({});
    setSelectionModel([]);
    refetchPolicies();
    refetchPoliciesCount();
  });

  const statusPolicyMutation = usePolicyStatusMutation(() => {
    setShowMultipleStatusPolicyModal(false);
    if (activeTab == 4) {
      dispatch(addNotification({ msg: "Policies status changed to  Active", type: "success" }));
    } else {
      dispatch(addNotification({ msg: "Policies status changed to  Inactive", type: "success" }));
    }
    setCurrentPolicy({});
    setSelectionModel([]);
    refetchPolicies();
    refetchPoliciesCount();
  });

  const deleteTag = useDeleteTagsMutation(() => {
    dispatch(addNotification({ msg: "Tag removed successfully", type: "success" }));
    refetchPolicies();
  });
  const updatePolicy = callUpdatePolicyMutation(() => {
    dispatch(addNotification({ msg: "Policy yaml updated successfully", type: "success" }));
    refetchPolicies();
    setShowCommitMessageModal(false);
    setIsModalOpen(false);
    setEditComment("");
  });

  const handleDeleteTag = (tag_id, policy_id) => {
    deleteTag.mutate({ policy_id, tag_id });
  };

  const handleOnChange = debounce(e => {
    setSearchTerm("%" + e + "%");
  }, 800);

  const actionMenuItems = par => {
    // remove this once network policy editor support is added
    if (par?.row?.policy_kind === "NetworkPolicy") {
      return [
        {
          name: "Inactive",
          // icon: <Plus />,
          onClick: () => {
            setSelectionModel([par?.row?.policy_id]);
            setShowMultipleStatusPolicyModal(true);
          }
        },
        {
          name: "Add Tags",
          // icon: <Plus />,
          onClick: () => {
            setShowAddTagsModal(true);
          }
        },
        {
          name: "View History",
          // icon: <HistoryIcon />,
          onClick: () => {
            navigate(routes.policyHistory, {
              selectedPolicy: { ...par?.row, policy_name: par?.row?.name, id: par?.row?.policy_id }
            });
          }
        },
        {
          name: "Delete",
          // icon: <DeleteIcon />,
          onClick: () => {
            setShowDeletePolicyModal(true);
          }
        }
      ];
    } else {
      return [
        {
          name: "Inactive",
          // icon: <Plus />,
          onClick: () => {
            setSelectionModel([par?.row?.policy_id]);
            setShowMultipleStatusPolicyModal(true);
          }
        },
        {
          name: "Add Tags",
          // icon: <Plus />,
          onClick: () => {
            setShowAddTagsModal(true);
          }
        },
        {
          name: "View History",
          // icon: <HistoryIcon />,
          onClick: () => {
            navigate(routes.policyHistory, {
              selectedPolicy: { ...par?.row, policy_name: par?.row?.name, id: par?.row?.policy_id }
            });
          }
        },
        {
          name: "Clone",
          // icon: <HistoryIcon />,
          onClick: () => {
            navigate(routes.policyEditor, {
              selectedPolicy: par?.row,
              from: "clone"
            });
          }
        },
        {
          name: "Delete",
          // icon: <DeleteIcon />,
          onClick: () => {
            setShowDeletePolicyModal(true);
          }
        }
      ];
    }
  };
  const handleupdatePolicy = () => {
    const policy_Id = selectedPolicy?.policy_id;
    const label_Detail = selectedPolicy?.label_type;
    const label_id = selectedPolicy?.labels.map(label => label.id);
    updatePolicy.mutate({ policy_Id, editYaml, label_Detail, editComment, label_id });
  };
  const tableAllcolumns = [
    {
      headerName: "Policy Name",
      field: "policy_name",
      resizable: true,
      minWidth: 300,
      // flex: 1,
      // renderCell: params => <ButtonLink buttonLable={params?.row?.policy_name} />,
      renderCell: params => {
        const kubearmorPolicy =
          params?.row?.policy_type === "KubeArmor" ||
          params?.row?.policy_type === "KubeArmorPolicy" ||
          params?.row?.policy_kind === "KubeArmorPolicy";

        const kubearmorHost =
          params?.row?.policy_type === "KubeArmor Host" ||
          params?.row?.policy_type === "KubeArmorHostPolicy" ||
          params?.row?.policy_kind === "KubeArmorHostPolicy";

        const ciliumNetwork =
          params?.row?.policy_type === "Cilium Network" ||
          params?.row?.policy_type === "CiliumNetworkPolicy" ||
          params?.row?.policy_kind === "CiliumNetworkPolicy";

        const kubernetesNetwork =
          params?.row?.policy_type === "Kubernetes Network" ||
          params?.row?.policy_type === "NetworkPolicy" ||
          params?.row?.policy_kind === "NetworkPolicy";

        if (kubearmorPolicy) {
          return (
            <div className="flex items-center justify-center">
              <div className="mt-2" onClick={() => setShowDrawer(!showDrawer)}>
                {/* {showDrawer && (
                  <DrawerTable showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
                )} */}
                <img src={kubearmor} alt="kubearmor" width="34px" height="34px" />
              </div>
              <div>
                <div className="pl-4 mt-2">
                  <ButtonLink
                    onClick={e => {
                      e.stopPropagation();
                      setShowYaml(true);
                      setSelectedPolicy(params?.row);
                    }}
                    buttonLable={
                      params?.row.version === 0
                        ? params.row.name
                        : params?.row?.name + (" (v" + params?.row?.version + ")")
                    }
                  />
                </div>
                <div style={{ fontSize: "12px" }} className="pl-4 mt-3">
                  {"KubeArmor"}
                </div>
              </div>
            </div>
          );
        } else if (kubearmorHost) {
          return (
            <div className="flex items-center justify-center">
              <div className="mt-2" onClick={() => setShowDrawer(!showDrawer)}>
                {/* {showDrawer && (
                  <>
                    <DrawerTable showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
                  </>
                )} */}
                {/* <Badge color="error" badgeContent={11} variant={"standard"}> */}
                <img src={kubearmor} alt="kubearmorHost" width="34px" height="34px" />
                {/* </Badge> */}
              </div>
              <div>
                <div className="pl-4 mt-2">
                  <ButtonLink
                    onClick={e => {
                      e.stopPropagation();
                      setShowYaml(true);
                      setSelectedPolicy(params?.row);
                    }}
                    buttonLable={
                      params?.row.version === 0
                        ? params.row.name
                        : params?.row?.name + (" (v" + params?.row?.version + ")")
                    }
                  />{" "}
                </div>
                <div style={{ fontSize: "12px" }} className="pl-4 mt-3">
                  {"KubeArmor Host"}
                </div>
              </div>
            </div>
          );
        } else if (ciliumNetwork) {
          return (
            <div className="flex items-center justify-center pl-1">
              <div className="mt-2" onClick={() => setShowDrawer(!showDrawer)}>
                <Cilium />
              </div>
              <div>
                <div className="pl-4 mt-2">
                  <ButtonLink
                    onClick={e => {
                      e.stopPropagation();
                      setShowYaml(true);
                      setSelectedPolicy(params?.row);
                    }}
                    buttonLable={
                      params?.row.version === 0
                        ? params.row.name
                        : params?.row?.name + (" (v" + params?.row?.version + ")")
                    }
                  />{" "}
                </div>
                <div style={{ fontSize: "12px" }} className="pl-4 mt-3">
                  {"Cilium Network"}
                </div>
              </div>
            </div>
          );
        } else if (kubernetesNetwork) {
          return (
            <div className="flex items-center justify-center pl-1">
              <div className="mt-2" onClick={() => setShowDrawer(!showDrawer)}>
                {/* {showDrawer && (
                  <>
                    <DrawerTable showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
                  </>
                )} */}
                <img src={kubernetes} alt="kubernetes" width="30px" height="30px" />
              </div>
              <div>
                <div className="pl-4 mt-2">
                  <ButtonLink
                    onClick={e => {
                      e.stopPropagation();
                      setShowYaml(true);
                      setSelectedPolicy(params?.row);
                    }}
                    buttonLable={
                      params?.row.version === 0
                        ? params.row.name
                        : params?.row?.name + (" (v" + params?.row?.version + ")")
                    }
                  />{" "}
                </div>
                <div style={{ fontSize: "12px" }} className="pl-4 mt-3">
                  {"Kubernetes Network"}
                </div>
              </div>
            </div>
          );
        }
      },
      headerClassName: "super-app-theme--header",
      hide: false
    },
    // {
    //   headerName: "",
    //   field: "latest_comment",
    //   resizable: false,
    //   maxWidth: 30,
    //   flex: 1,
    //   renderCell: params => {
    //     const props = {
    //       message: params.value.review_msg,
    //       date: params.value.date,
    //       owner_name: getUsers.data?.userIdMap?.[params.value.updated_by]
    //     };
    //     return <CommentMessagePopover {...props} />;
    //   }, // render a button with a popover
    //   headerClassName: "super-app-theme--header",
    //   hide: false,
    //   align: "center"
    // },
    // {
    //   headerName: "Type",
    //   field: "policy_type",
    //   resizable: true,
    //   minWidth: 160,
    //   flex: 1,
    //   renderCell: params => {
    //     const kubearmorPolicy =
    //       params?.row?.policy_type === "KubeArmor" ||
    //       params?.row?.policy_type === "KubeArmorPolicy" ||
    //       params?.row?.policy_kind === "KubeArmorPolicy";

    //     const kubearmorHost =
    //       params?.row?.policy_type === "KubeArmor Host" ||
    //       params?.row?.policy_type === "KubeArmorHostPolicy" ||
    //       params?.row?.policy_kind === "KubeArmorHostPolicy";

    //     const ciliumNetwork =
    //       params?.row?.policy_type === "Cilium Network" ||
    //       params?.row?.policy_type === "CiliumNetworkPolicy" ||
    //       params?.row?.policy_kind === "CiliumNetworkPolicy";

    //     const kubernetesNetwork =
    //       params?.row?.policy_type === "Kubernetes Network" ||
    //       params?.row?.policy_type === "NetworkPolicy" ||
    //       params?.row?.policy_kind === "NetworkPolicy";

    //     if (kubearmorPolicy) {

    //       return (
    //         <div className="flex items-center justify-center">
    //           <div className="mt-2">
    //             <img src={kubearmor} alt="kubearmor" width="34px" height="34px" />
    //           </div>
    //           <div>
    //             <p className="pl-2.5">{params?.value}</p>
    //           </div>
    //         </div>
    //       );
    //     } else if (kubearmorHost) {
    //       return (
    //         <div className="flex items-center justify-center">
    //           <div className="mt-2">
    //             <img src={kubearmor} alt="kubearmorHost" width="34px" height="34px" />
    //           </div>
    //           <div>
    //             <p className="pl-2.5">{params?.value}</p>
    //           </div>
    //         </div>
    //       );
    //     } else if (ciliumNetwork) {
    //       return (
    //         <div className="flex items-center justify-center pl-1">
    //           <div className="mt-2">
    //             <Cilium />
    //           </div>
    //           <div>
    //             <p className="pl-2.5">{params?.value}</p>
    //           </div>
    //         </div>
    //       );
    //     } else if (kubernetesNetwork) {
    //       return (
    //         <div className="flex items-center justify-center pl-1">
    //           <div className="mt-2">
    //             <img src={kubernetes} alt="kubernetes" width="30px" height="30px" />
    //           </div>
    //           <div>
    //             <p className="pl-2.5">{params?.value}</p>
    //           </div>
    //         </div>
    //       );
    //     }
    //   },
    //   headerClassName: "super-app-theme--header",
    //   hide: false
    // },

    {
      headerName: "Category",
      field: "category",
      resizable: true,
      minWidth: 135,
      // flex: 1,
      renderCell: params => (
        <div className="flex flex-col">
          <div className="flex">
            <div className="pr-1">
              {`${params?.value?.charAt(0).toUpperCase() + params?.value?.slice(1)}`}
            </div>
            {params?.row?.changes_available && (
              <div
                className="px-0.5 m-auto text-white cursor-pointer"
                style={{ background: "#005AAC", fontSize: "10px", borderRadius: "3px" }}
                onClick={e => {
                  e.stopPropagation();
                  setShowyamlDiff(true);
                  setSelectedPolicy(params?.row);
                }}
              >
                {"Changes available"}
              </div>
            )}
          </div>
          {params?.row?.applied_at && (
            <div style={{ fontSize: "12px" }}>
              Applied {moment(new Date(params?.row?.applied_at)).fromNow()}
            </div>
          )}
        </div>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      minWidth: 120,
      // flex: 1,
      renderCell: params => {
        if (params.value === "Active") {
          return (
            <div className="flex items-center jusitfy-between">
              <CircleIcon style={{ fill: "#12B76A", fontSize: "14px", paddingRight: "5px" }} />
              {params.value}
              {params?.row?.pending_available && (
                <div
                  className="px-0.5 m-auto text-white ml-1 cursor-pointer"
                  style={{ background: "#E18700", fontSize: "10px", borderRadius: "3px" }}
                  onClick={e => {
                    e.stopPropagation();
                    navigate(routes.policyHistory, {
                      selectedPolicy: {
                        ...params?.row,
                        policy_name: params?.row?.name,
                        id: params?.row?.policy_id
                      }
                    });
                  }}
                >
                  {"Pending"}
                </div>
              )}
            </div>
          );
        } else if (params.value === "Inactive") {
          return (
            <div className="flex items-center jusitfy-between">
              <CircleIcon style={{ fill: "#B71212", fontSize: "14px", paddingRight: "5px" }} />
              {params.value}
              {params?.row?.pending_available && (
                <div
                  className="px-0.5 m-auto text-white ml-1 cursor-pointer"
                  style={{ background: "#E18700", fontSize: "10px", borderRadius: "3px" }}
                  onClick={e => {
                    e.stopPropagation();
                    navigate(routes.policyHistory, {
                      selectedPolicy: {
                        ...params?.row,
                        policy_name: params?.row?.name,
                        id: params?.row?.policy_id
                      }
                    });
                  }}
                >
                  {"Pending"}
                </div>
              )}
            </div>
          );
        } else if (params.value === "Pending") {
          return (
            <div>
              <CircleIcon style={{ fill: "#FFAF37", fontSize: "14px", paddingRight: "5px" }} />
              {params.value}
            </div>
          );
        } else if (params.value === "Ignored") {
          return (
            <div>
              <CircleIcon style={{ fill: "#247ED1", fontSize: "14px", paddingRight: "5px" }} />
              {params.value}
            </div>
          );
        }
      },

      // renderCell: params => (
      //   <PolicyStatusToggle
      //     onChange={() => {
      //       setCurrentPolicy({ ...params.value?.policy_details });
      //     }}
      //     onClick={() => {
      //       setShowChangePolicyStatusConfirmation(true);
      //     }}
      //     status={params.value}
      //   />
      // ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Clusters",
      field: "cluster_name",
      resizable: true,
      minWidth: 100,
      // flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Namespace",
      field: "namespace_name",
      resizable: true,
      minWidth: 130,
      // flex: 1,
      renderCell: params =>
        params?.value === undefined ? <div>-</div> : <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Selector Labels",
      field: "labels",
      resizable: true,
      minWidth: 250,
      // flex: 1,
      renderCell: params =>
        params.value?.length > 0 ? (
          <SelectorLabel labels={params.value} limit={15} />
        ) : (
          <div className="pl-4">None</div>
        ),
      headerClassName: "super-app-theme--header",
      hide: false
    },

    {
      headerName: "Tags",
      field: "policy_tags",
      resizable: true,
      minWidth: 200,
      // flex: 1,
      renderCell: params => {
        return params.row?.tags?.length > 0 ? (
          <PolicyTags
            deletable
            policyId={params?.row?.policy_id}
            onDelete={handleDeleteTag}
            tagData={params.row?.tags?.map(t => ({ id: t.id, name: t.name }))}
          />
        ) : (
          <div className="ml-4">None</div>
        );
      },
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "",
      field: "policy_options",
      resizable: false,
      maxWidth: 50,
      // flex: 1,
      align: "right",
      sortable: false,
      renderCell: params => (
        <ActionMenu
          onClick={() => {
            setCurrentPolicy(params?.row);
          }}
          menuItems={actionMenuItems(params)}
        />
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    }
  ];
  const tableColumns = [
    {
      headerName: "Policy Name",
      field: "policy_name",
      resizable: true,
      minWidth: 300,
      // flex: 1,
      // renderCell: params => <ButtonLink buttonLable={params?.row?.policy_name} />,
      renderCell: params => {
        const kubearmorPolicy =
          params?.row?.policy_type === "KubeArmor" ||
          params?.row?.policy_type === "KubeArmorPolicy" ||
          params?.row?.policy_kind === "KubeArmorPolicy";

        const kubearmorHost =
          params?.row?.policy_type === "KubeArmor Host" ||
          params?.row?.policy_type === "KubeArmorHostPolicy" ||
          params?.row?.policy_kind === "KubeArmorHostPolicy";

        const ciliumNetwork =
          params?.row?.policy_type === "Cilium Network" ||
          params?.row?.policy_type === "CiliumNetworkPolicy" ||
          params?.row?.policy_kind === "CiliumNetworkPolicy";

        const kubernetesNetwork =
          params?.row?.policy_type === "Kubernetes Network" ||
          params?.row?.policy_type === "NetworkPolicy" ||
          params?.row?.policy_kind === "NetworkPolicy";

        if (kubearmorPolicy) {
          return (
            <div className="flex items-center justify-center">
              <div className="mt-2" onClick={() => setShowDrawer(!showDrawer)}>
                {/* {showDrawer && (
                  <DrawerTable showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
                )} */}
                <img src={kubearmor} alt="kubearmor" width="34px" height="34px" />
              </div>
              <div>
                <div className="pl-4 mt-2">
                  <ButtonLink
                    onClick={e => {
                      e.stopPropagation();
                      setShowYaml(true);
                      setSelectedPolicy(params?.row);
                    }}
                    buttonLable={
                      params?.row.version === 0
                        ? params.row.name
                        : params?.row?.name + (" (v" + params?.row?.version + ")")
                    }
                  />
                </div>
                <div style={{ fontSize: "12px" }} className="pl-4 mt-3">
                  {"KubeArmor"}
                </div>
              </div>
            </div>
          );
        } else if (kubearmorHost) {
          return (
            <div className="flex items-center justify-center">
              <div className="mt-2" onClick={() => setShowDrawer(!showDrawer)}>
                {/* {showDrawer && (
                  <>
                    <DrawerTable showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
                  </>
                )} */}
                {/* <Badge color="error" badgeContent={11} variant={"standard"}> */}
                <img src={kubearmor} alt="kubearmorHost" width="34px" height="34px" />
                {/* </Badge> */}
              </div>
              <div>
                <div className="pl-4 mt-2">
                  <ButtonLink
                    onClick={e => {
                      e.stopPropagation();
                      setShowYaml(true);
                      setSelectedPolicy(params?.row);
                    }}
                    buttonLable={
                      params?.row.version === 0
                        ? params.row.name
                        : params?.row?.name + (" (v" + params?.row?.version + ")")
                    }
                  />{" "}
                </div>
                <div style={{ fontSize: "12px" }} className="pl-4 mt-3">
                  {"KubeArmor Host"}
                </div>
              </div>
            </div>
          );
        } else if (ciliumNetwork) {
          return (
            <div className="flex items-center justify-center pl-1">
              <div className="mt-2" onClick={() => setShowDrawer(!showDrawer)}>
                <Cilium />
              </div>
              <div>
                <div className="pl-4 mt-2">
                  <ButtonLink
                    onClick={e => {
                      e.stopPropagation();
                      setShowYaml(true);
                      setSelectedPolicy(params?.row);
                    }}
                    buttonLable={
                      params?.row.version === 0
                        ? params.row.name
                        : params?.row?.name + (" (v" + params?.row?.version + ")")
                    }
                  />{" "}
                </div>
                <div style={{ fontSize: "12px" }} className="pl-4 mt-3">
                  {"Cilium Network"}
                </div>
              </div>
            </div>
          );
        } else if (kubernetesNetwork) {
          return (
            <div className="flex items-center justify-center pl-1">
              <div className="mt-2" onClick={() => setShowDrawer(!showDrawer)}>
                {/* {showDrawer && (
                  <>
                    <DrawerTable showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
                  </>
                )} */}
                <img src={kubernetes} alt="kubernetes" width="30px" height="30px" />
              </div>
              <div>
                <div className="pl-4 mt-2">
                  <ButtonLink
                    onClick={e => {
                      e.stopPropagation();
                      setShowYaml(true);
                      setSelectedPolicy(params?.row);
                    }}
                    buttonLable={
                      params?.row.version === 0
                        ? params.row.name
                        : params?.row?.name + (" (v" + params?.row?.version + ")")
                    }
                  />{" "}
                </div>
                <div style={{ fontSize: "12px" }} className="pl-4 mt-3">
                  {"Kubernetes Network"}
                </div>
              </div>
            </div>
          );
        }
      },
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Category",
      field: "category",
      resizable: true,
      minWidth: 135,
      // flex: 1,
      renderCell: params => (
        <div className="flex flex-col">
          <div className="flex">
            <div className="pr-1">
              {`${params?.value?.charAt(0).toUpperCase() + params?.value?.slice(1)}`}
            </div>
            {params?.row?.changes_available && (
              <div
                className="px-0.5 m-auto text-white cursor-pointer"
                style={{ background: "#005AAC", fontSize: "10px", borderRadius: "3px" }}
                onClick={e => {
                  e.stopPropagation();
                  setShowyamlDiff(true);
                  setSelectedPolicy(params?.row);
                }}
              >
                {"Changes available"}
              </div>
            )}
          </div>
          {params?.row?.applied_at && (
            <div style={{ fontSize: "12px" }}>
              Applied {moment(new Date(params?.row?.applied_at)).fromNow()}
            </div>
          )}
        </div>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Clusters",
      field: "cluster_name",
      resizable: true,
      minWidth: 100,
      // flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Namespace",
      field: "namespace_name",
      resizable: true,
      minWidth: 130,
      // flex: 1,
      renderCell: params =>
        params?.value === undefined ? <div>-</div> : <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Selector Labels",
      field: "labels",
      resizable: true,
      minWidth: 250,
      // flex: 1,
      renderCell: params =>
        params.value?.length > 0 ? (
          <SelectorLabel labels={params.value} limit={15} />
        ) : (
          <div className="pl-4">None</div>
        ),
      headerClassName: "super-app-theme--header",
      hide: false
    },

    {
      headerName: "Tags",
      field: "policy_tags",
      resizable: true,
      minWidth: 200,
      // flex: 1,
      renderCell: params => {
        return params.row?.tags?.length > 0 ? (
          <PolicyTags
            deletable
            policyId={params?.row?.policy_id}
            onDelete={handleDeleteTag}
            tagData={params.row?.tags?.map(t => ({ id: t.id, name: t.name }))}
          />
        ) : (
          <div className="ml-4">None</div>
        );
      },
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "",
      field: "policy_options",
      resizable: false,
      maxWidth: 50,
      // flex: 1,
      align: "right",
      renderCell: params => (
        <ActionMenu
          onClick={() => {
            setCurrentPolicy(params?.row);
          }}
          menuItems={actionMenuItems(params)}
        />
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    }
  ];
  const { data: clusters = {} } = useClustersQuery();
  const selectedClusters = useSelector(getSelectedClusters);
  const { data: namespaces = {} } = useNamespacesQuery(selectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedInfraType = useSelector(getSelectedInfraType);
  const selectedPolicyType = useSelector(getSelectedPolicyType);
  // const selectedPolicyStatus = useSelector(getSelectedPolicyStatus);

  const [selectionModel, setSelectionModel] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [searchedPolicies, setSearchedPolicies] = useState([]);

  useEffect(() => {
    const searchResult = allPolicies?.list_of_policies?.filter(row =>
      row?.name?.toLowerCase().includes(searchInput.toLowerCase())
    );
    setSearchedPolicies(searchResult);
  }, [searchInput]);
  useEffect(() => {
    setPagePrev(0);
    setPageNext(0 + pageSize);
  }, [selectedClusters, selectedNamespaces]);

  useEffect(() => {
    if (namespaces?.options) {
      let filter = selectedNamespaces?.filter(({ value }) =>
        namespaces?.options?.some(data => data.value === value)
      );
      dispatch(setSelectedNamespace(filter));
    }
  }, [namespaces?.options]);

  const handleFilter = (value, filterName) => {
    if (filterName === "infra_type") {
      dispatch(setSelectedInfraType(value));
    } else if (filterName === "cluster") {
      dispatch(setSelectedClusters(value));
    } else if (filterName === "namespace") {
      dispatch(setSelectedNamespace(value));
    } else if (filterName === "policy_type") {
      dispatch(setSelectedPolicyType(value));
    }
  };
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.cluster[0]?.label) {
      handleFilter(location.state?.cluster, "cluster");
    }
    if (location?.state?.cluster[0]?.label && location?.state?.namespace[0]?.label) {
      handleFilter(location.state?.namespace, "namespace");
    }
  }, [location?.state?.cluster]);

  const tableConfig = {
    hideSelectedRowCount: true,
    checkboxVisible: true
  };

  const handleSort = () => {};

  const onPageChange = (e, value) => {
    let newPage = value - 1;
    setPagePrev(newPage * pageSize);
    setPageNext(newPage * pageSize + pageSize);
  };
  const onCellClick = (v, e) => {
    e.stopPropagation();
    const { id, row = {} } = v;
    const { namespace, cluster } = row;
  };

  const toCreatePolicyPage = () => {
    navigate(routes.policyEditor, {
      from: "fromCreate"
    });
  };

  useEffect(() => {
    if (activeTab === 1) {
      setSelectedPolicyStatus("Discovered");
    } else if (activeTab == 2) {
      setSelectedPolicyStatus("Changed");
    } else if (activeTab == 3) {
      setSelectedPolicyStatus("Active");
    } else if (activeTab == 4) {
      setSelectedPolicyStatus("Inactive");
    } else if (activeTab == 5) {
      setSelectedPolicyStatus("Pending");
    } else if (activeTab == 6) {
      setSelectedPolicyStatus("Ignored");
    } else if (activeTab == 7) {
      setSelectedPolicyStatus("Hardening");
    } else if (activeTab == 8) {
      setSelectedPolicyStatus("Custom");
    } else if (activeTab == 0) {
      setSelectedPolicyStatus("");
    }
  }, [activeTab]);

  const cluster_fields_list = useMemo(() => {
    if (selectedClusters?.length) {
      return clusters?.options?.filter(
        item => !selectedClusters?.some(({ label }) => item?.label === label)
      );
    }
    return clusters?.options || [];
  }, [clusters?.options, selectedClusters]);

  const namespace_fields_list = useMemo(() => {
    if (selectedNamespaces?.length) {
      return namespaces?.options?.filter(
        item => !selectedNamespaces?.some(({ value }) => item?.value === value)
      );
    }
    return namespaces?.options || [];
  }, [namespaces?.options, selectedNamespaces]);

  const policy_type_fields_list = useMemo(() => {
    const policyType = [
      { label: "KubeArmor", value: "KubeArmorPolicy" },
      { label: "KubeArmor Host", value: "KubeArmorHostPolicy" },
      { label: "Kubernetes Network", value: "NetworkPolicy" },
      { label: "Cilium Network", value: "CiliumNetworkPolicy" }
    ];
    if (selectedPolicyType.length) {
      return policyType.filter(
        item => !selectedPolicyType.some(({ label }) => item.label === label)
      );
    }
    return policyType;
  }, [selectedPolicyType]);
  const tableActiveColumns = Tab => {
    return Tab === 0 || Tab === 1 || Tab === 5 || Tab === 7 || Tab === 8
      ? tableAllcolumns
      : tableColumns;
  };
  return (
    <>
      <ContentContainer className={`${styles.container} mb-4`}>
        <div className="flex justify-between">
          <Title>Policies</Title>
          <div className={styles["actionbtn-group"]}>
            <Button icon="plus" onClick={toCreatePolicyPage}>
              Create Policy
            </Button>
          </div>
        </div>
        <section className={styles.action}>
          <div className={styles["action-group"]}>
            <div className={styles["action-group-singleSelect"]}>
              <Select
                value={selectedInfraType}
                onChange={v => handleFilter(v, "infra_type")}
                placeholder="Policy Kind"
                defaultValue={{ label: "K8s", value: "GKE" }}
                options={[
                  { label: "K8s", value: "GKE" }
                  // { label: "VM", value: "VM" }
                ]}
                containerClass={styles["action-group-singleSelect-workload"]}
                hideSelectedOptions={false}
                label={"Workloads"}
              />
              <Tags
                className={styles["action-group-singleSelect-policyType"]}
                label="Select Policy Type  to filter"
                values={selectedPolicyType}
                list={policy_type_fields_list}
                onChange={v => handleFilter(v, "policy_type")}
                showAddIcon={!!policy_type_fields_list?.length}
              />
            </div>
            <div className={styles["action-group__filter"]}>
              <Tags
                className={styles["action-group__filter-option"]}
                label="Select Clusters to filter"
                values={selectedClusters}
                list={cluster_fields_list}
                onChange={v => handleFilter(v, "cluster")}
                showAddIcon={!!cluster_fields_list?.length}
              />
              <Tags
                className={styles["action-group__filter-option"]}
                label="Select Namespace to filter"
                values={selectedNamespaces}
                list={namespace_fields_list}
                onChange={v => handleFilter(v, "namespace")}
                showAddIcon={!!namespace_fields_list?.length}
              />
              {/* <CustomSelect
                value={selectedClusters}
                onChange={v => handleFilter(v, "cluster")}
                placeholder="Cluster"
                options={clusters?.options}
                containerClass={styles["action-group__filter-option"]}
                isMulti
                hideSelectedOptions={false}
                label={"Cluster"}
              /> */}
              {/* <CustomSelect
                value={selectedNamespaces}
                onChange={v => handleFilter(v, "namespace")}
                placeholder="Namespace"
                options={namespaces?.options}
                containerClass={styles["action-group__filter-option"]}
                isMulti
                hideSelectedOptions={false}
                label={"Namespace"}
              /> */}

              {/* <CustomSelect
                value={selectedPolicyType}
                onChange={v => handleFilter(v, "policy_type")}
                placeholder="Policy Type"
                options={[
                  { label: "KubeArmor", value: "KubeArmorPolicy" },
                  { label: "KubeArmor Host", value: "KubeArmorHostPolicy" },
                  { label: "Kubernetes Network", value: "NetworkPolicy" },
                  { label: "Cilium Network", value: "CiliumNetworkPolicy" }
                ]}
                containerClass={styles["action-group__filter-option"]}
                hideSelectedOptions={false}
                isMulti
                label={"Policy Type"}
              /> */}

              {/* <CustomSelect
                value={selectedPolicyStatus}
                onChange={v => handleFilter(v, "policy_status")}
                placeholder="Policy Status"
                options={[
                  { label: "Active", value: "Active" },
                  { label: "Inactive", value: "Inactive" },
                  { label: "Pending", value: "Pending" }
                ]}
                containerClass={styles["action-group__filter-option"]}
                hideSelectedOptions={false}
                isMulti
                label={"Status"}
              /> */}
            </div>
          </div>
        </section>
      </ContentContainer>
      {/* {showYaml && (
        <ViewYamlBox
          policyDetail={selectedPolicy}
          setClose={setShowYaml}
          refetch={refetchPolicies}
        />
      )}
      {showyamlDiff && (
        <UpdateAvailabilityDialogBox
          open={showyamlDiff}
          policyDetail={selectedPolicy}
          setOpen={setShowyamlDiff}
          setIsModalOpen={setIsModalOpen}
        />
      )} */}
      <ContentContainer className={styles.container}>
        {/* <Title>All Policies</Title> */}
        <div className="flex justify-between">
          <Search onSearch={e => handleOnChange(e)} />
          {activeTab !== 3 && activeTab !== 4 && (
            <div className={styles["actionbtn-group"]}>
              <Button
                disabled={selectionModel.length === 0}
                applyPolicyMutation
                onClick={() => {
                  setShowMultipleApplyPolicyModal(true);
                }}
              >
                Apply
              </Button>
            </div>
          )}
        </div>
        <div className="flex w-full">
          <div className={styles.btn_Groups}>
            <Tabs
              tabs={AllPolicyTabs(allPoliciesCount) || 0}
              value={activeTab}
              handleChange={handleChange}
            />
          </div>

          <div className={styles.action_group}>
            <IconButton
              onClick={() => {
                setShowMultipleIgnorePolicyModal(true);
              }}
              disabled={selectionModel.length === 0}
              disableRipple
              sx={{ color: "#2c3452" }}
            >
              <DoDisturbIcon fontSize="small" />
              Ignore
            </IconButton>{" "}
            <IconButton
              disabled={selectionModel.length === 0}
              disableRipple
              onClick={() => {
                setShowMultipleDeletePolicyModal(true);
              }}
              sx={{ color: "#2c3452" }}
            >
              <DeleteOutlineIcon fontSize="small" />
              Delete
            </IconButton>{" "}
            <IconButton
              onClick={() => {
                setShowMultipleStatusPolicyModal(true);
              }}
              disabled={selectionModel.length === 0}
              disableRipple
              sx={{ color: "#2c3452" }}
            >
              <PowerSettingsNewIcon fontSize="small" />
              {activeTab !== 4 ? "Make Inactive" : "Make Active"}
            </IconButton>
          </div>
        </div>

        <section>
          <Table
            // apiRef={apiRef}
            getRowId={rows => rows?.policy_id}
            data={searchInput === "" ? allPolicies?.list_of_policies || [] : searchedPolicies}
            columns={tableActiveColumns(activeTab)}
            // rowCount={totalTableCount(activeTab, allPoliciesCount) || 0}
            selectionModel={selectionModel}
            // page={pagePrev / pageSize}
            // pageSize={pageSize}
            // rowsPerPageOptions={[5, 10, 20, 50]}
            onSortModelChange={model => handleSort(model)}
            // onPageChange={onPageChange}
            components={{
              Pagination: CustomPagination
            }}
            componentsProps={{
              pagination: {
                count: FindTotalPage(totalTableCount(activeTab, allPoliciesCount), pageSize),
                onChange: onPageChange,
                page: pagePrev / pageSize + 1,
                totalCount: totalTableCount(activeTab, allPoliciesCount) || 0,
                selected: pageSize,
                perPageChange: (e, newPageSize) => {
                  setPageSize(e?.target?.value);
                  setPagePrev(0 * e?.target?.value);
                  setPageNext(e?.target?.value);
                }
              }
            }}
            onSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
            onCellClick={(p, e) => onCellClick(p, e)}
            // paginationMode="server"
            loading={policiesLoading}
            {...tableConfig}
            initialState={{
              pinnedColumns: {
                left: ["__check__"],
                right: ["actions"]
              }
            }}
          />
        </section>

        <AddTagsModal
          policyId={currentPolicy?.policy_id}
          tags={currentPolicy?.tags}
          open={showAddTagsModal}
          close={() => setShowAddTagsModal(false)}
        />

        <Modal
          isOpen={showChangePolicyStatusConfirmation}
          toggle={() => setShowChangePolicyStatusConfirmation(false)}
        >
          <Title>Confirmation</Title>
          <p>
            Change the status of <strong>{currentPolicy?.name}</strong> from{" "}
            <strong>{currentPolicy?.status}</strong> to&nbsp;
            <strong>{currentPolicy?.status === "Active" ? "Inactive" : "Active"}</strong>?
          </p>
          <div className="flex justify-between">
            <Button
              onClick={() => {
                setShowChangePolicyStatusConfirmation(false);
                setCurrentPolicy({});
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                changePolicyStatus.mutate({
                  policy_id: currentPolicy?.id,
                  status: currentPolicy?.status === "Active" ? "Inactive" : "Active"
                });
              }}
              isLoading={changePolicyStatus.isLoading}
            >
              Confirm
            </Button>
          </div>
        </Modal>

        <Modal isOpen={showDeletePolicyModal} toggle={() => setShowDeletePolicyModal(false)}>
          <Title>Confirmation</Title>
          <p>
            Are you sure you want to delete the policy <strong>{currentPolicy.name}</strong>?
          </p>
          <div className="flex justify-between">
            <Button
              onClick={() => {
                setCurrentPolicy({});
                setShowDeletePolicyModal(false);
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteMultiplePolicy.mutate({ policy_id: [currentPolicy?.policy_id] });
              }}
              isLoading={deletePolicy.isLoading}
            >
              Confirm
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={showMultipleDeletePolicyModal}
          toggle={() => setShowMultipleDeletePolicyModal(false)}
        >
          <Title>Confirmation</Title>
          <p>
            Are you sure you want to delete the {selectionModel.length > 1 ? "policies" : "policy"}{" "}
            ?
          </p>
          <div className="flex justify-between">
            <Button
              onClick={() => {
                // setCurrentPolicy({});
                setShowMultipleDeletePolicyModal(false);
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteMultiplePolicy.mutate({ policy_id: selectionModel });
              }}
              isLoading={deleteMultiplePolicy.isLoading}
            >
              Confirm
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={showMultipleIgnorePolicyModal}
          toggle={() => setShowMultipleIgnorePolicyModal(false)}
        >
          <Title>Confirmation</Title>
          <p>
            Are you sure you want to ignore the {selectionModel.length > 1 ? "policies" : "policy"}{" "}
            ?
          </p>
          <div className="flex justify-between">
            <Button
              onClick={() => {
                // setCurrentPolicy({});
                setShowMultipleIgnorePolicyModal(false);
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                ignorePolicyMutation.mutate({ policy_id: selectionModel });
              }}
              isLoading={ignorePolicyMutation.isLoading}
            >
              Confirm
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={showMultipleStatusPolicyModal}
          toggle={() => setShowMultipleStatusPolicyModal(false)}
        >
          <Title>Confirmation</Title>
          <p>
            Are you sure you want to change the status of{" "}
            {selectionModel.length > 1 ? "policies" : "policy"} ?
          </p>
          <div className="flex justify-between">
            <Button
              onClick={() => {
                // setCurrentPolicy({});
                setShowMultipleStatusPolicyModal(false);
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                statusPolicyMutation.mutate({
                  policy_id: selectionModel,
                  status: activeTab !== 4 ? "Inactive" : "Active"
                });
              }}
              isLoading={statusPolicyMutation.isLoading}
            >
              Confirm
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={showMultipleApplyPolicyModal}
          toggle={() => setShowMultipleApplyPolicyModal(false)}
        >
          <Title>Confirmation</Title>
          <p>
            Are you sure you want to apply the {selectionModel.length > 1 ? "policies" : "policy"} ?
          </p>
          <div className="flex justify-between">
            <Button
              onClick={() => {
                // setCurrentPolicy({});
                setShowMultipleApplyPolicyModal(false);
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                applyPolicyMutation.mutate({ policy_id: selectionModel });
              }}
              isLoading={applyPolicyMutation.isLoading}
            >
              Confirm
            </Button>
          </div>
        </Modal>
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
          <div style={{ borderBottom: "1px solid rgba(0,0,0,0.4)" }}>
            <Typography variant="body2" fontWeight={700} sx={{ marginBottom: "10px" }}>
              Commit Message
            </Typography>
          </div>
          <div className="mt-8">
            <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
              Write commit message (optional)
            </Typography>
            <Controller
              name="commitMessage"
              {...register("commitMessage", {
                required: false
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  value={value}
                  onChange={val => handleChange(val, "commit")}
                  placeholder="Leave a commit message here..."
                />
              )}
            />
          </div>
          <div className="flex items-center justify-end w-full gap-4 mt-4">
            <Button
              variant="outline"
              className={styles.cancelBtn}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button>Update</Button>
          </div>
        </Modal>
        {showYaml && (
          <ViewYamlBox
            policyDetail={selectedPolicy}
            setClose={setShowYaml}
            setEditYaml={setEditYaml}
            editYaml={editYaml}
            setShowCommitMessageModal={() => {
              // handleEditConformation();
              setShowYaml(false);
              setShowCommitMessageModal(!showCommitMessageModal);
              // setShowYaml(false);
            }}
          />
        )}
        {showyamlDiff && (
          <UpdateAvailabilityDialogBox
            open={showyamlDiff}
            policyDetail={selectedPolicy}
            setOpen={setShowyamlDiff}
            setIsModalOpen={setIsModalOpen}
            refetchPolicies={refetchPolicies}
          />
        )}
        {showCommitMessageModal && (
          <Modal
            isOpen={showCommitMessageModal}
            toggle={() => {
              setShowYaml(!showYaml);
              setShowCommitMessageModal(false);
              setEditComment("");
            }}
          >
            <div style={{ borderBottom: "1px solid rgba(0,0,0,0.4)" }}>
              <Typography variant="body2" fontWeight={700} sx={{ marginBottom: "10px" }}>
                Commit Message
              </Typography>
            </div>
            <div className="mt-8">
              <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
                Write commit message (optional)
              </Typography>

              <Textarea
                value={editComment}
                onChange={val => setEditComment(val)}
                placeholder="Leave a commit message here..."
              />
            </div>
            <div className="flex items-center justify-end w-full gap-4 mt-4">
              <Button
                variant="outline"
                className={styles.cancelBtn}
                onClick={() => {
                  setShowYaml(!showYaml);
                  setShowCommitMessageModal(false);
                  setEditComment("");
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  handleupdatePolicy();
                  setEditComment("");
                }}
                isLoading={false}
              >
                Submit
              </Button>
            </div>
          </Modal>
        )}
      </ContentContainer>
    </>
  );
};

export default AllPolicies;
