import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";

Sentry.init({
  dsn: window.__env__.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
    new HttpClientIntegration({
      failedRequestStatusCodes: [[400, 599]]
    }),
    new Sentry.Replay()
  ],
  sendDefaultPii:
    window.__env__.REACT_APP_ENV === "development" || window.__env__.REACT_APP_ENV === "staging"
      ? true
      : false,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: window.__env__.REACT_APP_SENTRY_SESSION_SAMPLE_RATE,
  environment: window.__env__.REACT_APP_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(window.__env__.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? "1.0")
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
