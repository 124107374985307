/* eslint-disable no-nested-ternary */
import { Box, List, Skeleton } from "@mui/material";
import { Icon } from "components/simple";
import React from "react";
import styles from "./styles.module.scss";

const GraphInfo = ({ heading, listData, onClose, isEntityDetailsLoading }) => {
  const boxStyle = {
    position: "absolute",
    top: 10,
    right: 10,
    maxWidth: 300,
    minWidth: 250,
    minHeight: 200,
    borderTop: 10,
    borderRadius: "4px",
    borderColor: "green",
    background: "#F9FCFF",
    boxShadow: 3,
    letterSpacing: "0.08em",
    fontWeight: 400,
    zIndex: 1
  };

  const nodedatas = node => {
    const graphObj = [];
    const keys = Object?.keys(node);
    keys?.forEach(k => {
      if (k !== "name" && k !== "Entity_id") {
        graphObj.push({
          label: k,
          value: node[k]
        });
      }
    });
    return graphObj;
  };

  const nodevalues = nodedatas(listData);
  return (
    <Box sx={boxStyle}>
      <div className={styles.wrapper}>
        <Box className={styles.headertext}> {heading} </Box>
        <div onClick={onClose}>
          <Icon.Close className={styles.removeBtn} color="#A6A8AA" width="15" height="15" />
        </div>
      </div>
      <List>
        {nodevalues.map((infoKey, index) => {
          return (
            <Box
              key={index}
              sx={{ display: "flex", justifyContent: "space-between", alignItem: "center" }}
            >
              <Box
                sx={{
                  pl: 3,
                  py: 2,
                  color: "#5F5E6D",
                  fontSize: "12px",
                  textTransform: "uppercase"
                }}
              >
                {infoKey.label}
              </Box>
              <Box
                sx={{ px: 2, py: 2, fontSize: "12px", wordBreak: "break-all" }}
                className={styles.nodeinfovalue}
              >
                {infoKey.value ? (
                  infoKey.value
                ) : isEntityDetailsLoading ? (
                  <Skeleton variant="rectangular" width={70} height={11} />
                ) : (
                  "--"
                )}
              </Box>
            </Box>
          );
        })}
      </List>
    </Box>
  );
};

export default GraphInfo;
