import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

export const setReportsParams = createAction(constant.SET_REPORTS_PARAMS);

// Get reports
export const getReports = createAction(constant.GET_REPORTS);
export const getReportsSuccess = createAction(constant.GET_REPORTS_SUCCESS);
export const getReportsFailed = createAction(constant.GET_REPORTS_FAILED);

// Get report
export const getReport = createAction(constant.GET_REPORT);
export const getReportSuccess = createAction(constant.GET_REPORT_SUCCESS);
export const getReportFailed = createAction(constant.GET_REPORT_FAILED);

// Create report
export const createReport = createAction(constant.CREATE_REPORT);
export const createReportSuccess = createAction(constant.CREATE_REPORT_SUCCESS);
export const createReportFailed = createAction(constant.CREATE_REPORT_FAILED);

// Edit report
export const editReport = createAction(constant.EDIT_REPORT);
export const editReportSuccess = createAction(constant.EDIT_REPORT_SUCCESS);
export const editReportFailed = createAction(constant.EDIT_REPORT_FAILED);

// Delete report
export const deleteReport = createAction(constant.DELETE_REPORT);
export const deleteReportSuccess = createAction(constant.DELETE_REPORT_SUCCESS);
export const deleteReportFailed = createAction(constant.DELETE_REPORT_FAILED);

// Run report
export const runReport = createAction(constant.RUN_REPORT);
export const runReportSuccess = createAction(constant.RUN_REPORT_SUCCESS);
export const runReportFailed = createAction(constant.RUN_REPORT_FAILED);

// Get report pages
export const getReportPages = createAction(constant.GET_REPORT_PAGES);
export const getReportPagesSuccess = createAction(constant.GET_REPORT_PAGES_SUCCESS);
export const getReportPagesFailed = createAction(constant.GET_REPORT_PAGES_FAILED);

// Get report periods
export const getReportPeriods = createAction(constant.GET_REPORT_PERIODS);
export const getReportPeriodsSuccess = createAction(constant.GET_REPORT_PERIODS_SUCCESS);
export const getReportPeriodsFailed = createAction(constant.GET_REPORT_PERIODS_FAILED);
