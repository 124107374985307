import React, { useEffect, useState } from "react";

import { uniqueId } from "lodash";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import { Table } from "components/complex";
import { ContentContainer } from "components/simple";
import { SeverityFilter } from "./Filters/SeverityFilter";
import { customStyle, resourcesDataHeaders } from "./utils";
import { useGetImageDetails } from "store/entities/imageDetails/queries";

export const Resources = ({ assetId }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: resourcesData = [],
    isLoading: isResourcesDataLoading,
    refetch: refetchResourcesData
  } = useGetImageDetails("RESOURCE_IMAGES", assetId);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let val = {
      Critical: "critical",
      High: "high",
      Medium: "medium",
      Low: "low",
      Negligible: "negligible"
    };

    setFilteredData(
      resourcesData?.filter(row => {
        for (var el in selectedFilters) {
          if (row?.severities[val[selectedFilters[el]]] === 0) return false;
        }
        return true;
      })
    );
  }, [selectedFilters]);

  const apiRef = useGridApiRef();

  return (
    <ContentContainer className="p-4 mt-2">
      <SeverityFilter selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
      <div className="mt-3">
        <Table
          page={page}
          apiRef={apiRef}
          pageSize={pageSize}
          checkboxVisible={false}
          rowsPerPageOptions={[5, 10, 20, 50]}
          customStyles={customStyle}
          getRowId={row => uniqueId()}
          columns={resourcesDataHeaders}
          onPageChange={p => setPage(p)}
          onPageSizeChange={pageSize => setPageSize(pageSize)}
          data={selectedFilters?.length === 0 ? resourcesData : filteredData}
          rowCount={selectedFilters?.length === 0 ? resourcesData?.length : filteredData?.length}
        />
      </div>
    </ContentContainer>
  );
};
