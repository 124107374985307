import React from "react";
import PropTypes from "prop-types";

export default function Logout(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.37678 22.27H9.50226C13.7878 22.27 15.8534 20.52 16.2105 16.6C16.2491 16.19 15.9596 15.82 15.5542 15.78C15.1681 15.74 14.8013 16.05 14.7627 16.46C14.4828 19.6 13.0543 20.77 9.49261 20.77H9.36713C5.4387 20.77 4.04878 19.33 4.04878 15.26V8.73998C4.04878 4.66998 5.4387 3.22998 9.36713 3.22998H9.49261C13.0736 3.22998 14.5021 4.41998 14.7627 7.61998C14.811 8.02998 15.1488 8.33998 15.5542 8.29998C15.9596 8.26998 16.2491 7.89998 16.2202 7.48998C15.892 3.50998 13.8168 1.72998 9.50226 1.72998H9.37678C4.63757 1.72998 2.61061 3.82998 2.61061 8.73998V15.26C2.61061 20.17 4.63757 22.27 9.37678 22.27Z"
        fill={props.color}
      />
      <path
        d="M9.60881 12.75H20.593C20.9887 12.75 21.3169 12.41 21.3169 12C21.3169 11.59 20.9887 11.25 20.593 11.25H9.60881C9.21307 11.25 8.8849 11.59 8.8849 12C8.8849 12.41 9.21307 12.75 9.60881 12.75Z"
        fill={props.color}
      />
      <path
        d="M18.4405 16.1C18.6239 16.1 18.8073 16.03 18.9521 15.88L22.1856 12.53C22.4655 12.24 22.4655 11.76 22.1856 11.47L18.9521 8.11997C18.6722 7.82997 18.2089 7.82997 17.929 8.11997C17.6491 8.40997 17.6491 8.88997 17.929 9.17997L20.6509 12L17.929 14.82C17.6491 15.11 17.6491 15.59 17.929 15.88C18.0641 16.03 18.2571 16.1 18.4405 16.1Z"
        fill={props.color}
      />
    </svg>
  );
}

Logout.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Logout.defaultProps = {
  className: "",
  style: {},
  color: "#fff",
  width: 24,
  height: 24
};
