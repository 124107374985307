import { TablePagination } from "@mui/material";
import { ContentContainer, Title, Select, Modal } from "components/simple";
import Loader from "components/simple/Loader";
import CustomAccordion from "components/simple/Accordion";
import { CommentMessage } from "components/simple/CommentMessagePopover/CommentMessagePopover";
import DifferJsonView from "components/simple/DifferJsonView";
import BasicModal from "components/simple/Modal/Modal";
import PolicyDetailView from "components/simple/PolicyDetailBox";
import PolicyStatus from "components/simple/policyStatus";
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClustersQuery, useNamespacesQuery } from "store/entities/filters/queries";
import {
  getSelectedClusters,
  getSelectedInfraType,
  getSelectedNamespaces,
  getSelectedPolicyType,
  setSelectedCategory,
  setSelectedClusters,
  setSelectedInfraType,
  setSelectedNamespace,
  setSelectedPolicyStatus,
  setSelectedPolicyType
} from "store/entities/filters/slice";
import { addNotification } from "store/entities/notifications/actions";
import {
  useApprovePolicyApi,
  useGetAllPendingPolicies,
  useGetUsersQuery,
  useReviewChangePolicyApi
} from "store/entities/policies/queries";
import styles from "./styles.module.scss";
import { uniq } from "lodash";
// import PolicySelect from "components/simple/PolicySelect";
import CustomSelect from "components/simple/CustomSelect";

const PendingPolicyApproval = ({ policyType = "VM", setPolicyType }) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [page, setPage] = useState(0);
  const [showCommentModel, setShowCommentModel] = useState(false);
  const [policyJson, setPolicyJson] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPolicy, setSelectedPolicy] = useState({});
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const { data: clusters = {} } = useClustersQuery();
  const { data: namespaces = {} } = useNamespacesQuery(selectedClusters);
  const getUsers = useGetUsersQuery();
  const { data: pendingPolicy = {}, isLoading: pendingPolicyisLoading } = useGetAllPendingPolicies(
    data => {
      const userIds = uniq(data?.list_of_policies?.map(p => p?.updated_by));
    },
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
    rowsPerPage
  );
  const callApproval = useApprovePolicyApi(() => {
    dispatch(addNotification({ msg: "Policy Change has been approved", type: "success" }));
  });
  const callReview = useReviewChangePolicyApi(() => {
    dispatch(addNotification({ msg: "Policy has been Reviewed", type: "success" }));
  });
  const selectedInfraType = useSelector(getSelectedInfraType);
  const selectedPolicyType = useSelector(getSelectedPolicyType);
  const handleAccordionChange = panel => (e, newExpanded) => {
    setPolicyJson(panel);
    setExpand(newExpanded ? panel : false);
  };
  const updateModelView = () => {
    setShowCommentModel(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const approveChangesCallBack = (finalreview, reviewCommit, pid) => {
    callApproval.mutate({ policyId: pid, finalreview, prBody: reviewCommit });
  };
  const reviewChangesCallBack = (finalreview, reviewCommit, pid) => {
    callReview.mutate({ finalreview, prBody: reviewCommit, policyId: pid });
  };
  const handleFilter = (value, filterName) => {
    if (filterName === "infra_type") {
      dispatch(setSelectedInfraType(value));
    } else if (filterName === "cluster") {
      dispatch(setSelectedClusters(value));
    } else if (filterName === "namespace") {
      dispatch(setSelectedNamespace(value));
    } else if (filterName === "policy_status") {
      dispatch(setSelectedPolicyStatus(value));
    } else if (filterName === "policy_type") {
      dispatch(setSelectedPolicyType(value));
    } else if (filterName === "category") {
      dispatch(setSelectedCategory(value));
    }
  };

  const getUserNameFromId = id => {
    return id === window.__env__.REACT_APP_ACCUKNOX_DISCOVERY_ENGINE_ID
      ? "AccuKnox Discovery Engine"
      : getUsers?.data?.shortId[id] || getUsers?.data?.longId[id] || "Unknown";
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Pending Approval</Title>
      <section className={styles.action}>
        <div className={styles["action-group"]}>
          <div className={styles["action-group__filter"]}>
            <Select
              value={selectedInfraType}
              onChange={v => handleFilter(v, "infra_type")}
              placeholder="Policy Kind"
              defaultValue={{ label: "K8s", value: "GKE" }}
              options={[
                { label: "K8s", value: "K8s" }
                // { label: "VM", value: "VM" }
              ]}
              containerClass={styles["action-group__filters-option"]}
              // hideSelectedOptions={false}
              // isClearable
            />
            <CustomSelect
              value={selectedClusters}
              onChange={v => handleFilter(v, "cluster")}
              placeholder="Cluster"
              options={clusters?.options}
              containerClass={styles["action-group__filters-option"]}
              isMulti
              hideSelectedOptions={false}
            />
            <CustomSelect
              value={selectedNamespaces}
              onChange={v => handleFilter(v, "namespace")}
              placeholder="Namespace"
              options={namespaces?.options}
              containerClass={styles["action-group__filters-option"]}
              isMulti
              hideSelectedOptions={false}
            />
            <CustomSelect
              value={selectedPolicyType}
              onChange={v => handleFilter(v, "policy type")}
              placeholder="Policy Type"
              options={[
                { label: "KubeArmor", value: "KubeArmorPolicy" },
                { label: "KubeArmor Host", value: "KubeArmorHostPolicy" },
                { label: "Kubernetes Network", value: "NetworkPolicy" },
                { label: "Cilium Network", value: "CiliumNetworkPolicy" }
              ]}
              containerClass={styles["action-group__filters-option"]}
              isMulti
              hideSelectedOptions={false}
            />
          </div>
        </div>
      </section>
      <section>
        <div className="flex justify-end">
          <TablePagination
            count={pendingPolicy?.total_record || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        {pendingPolicyisLoading ? (
          <div className="flex justify-center items-center" style={{ height: "80vh" }}>
            <Loader />
          </div>
        ) : (
          pendingPolicy?.list_of_policies?.map((d, i) => (
            <CustomAccordion
              Accordionkey={d.id}
              key={i}
              expand={expand}
              onChange={handleAccordionChange(d?.id)}
              Summary={
                <PolicyDetailView
                  policy={d}
                  key={d.id}
                  setShowModel={setShowCommentModel}
                  setSelectedPolicy={setSelectedPolicy}
                  userList={getUsers?.data?.userIdMap}
                />
              }
              Details={
                expand === d?.id && d?.policy_version_id ? (
                  <DifferJsonView
                    policyId={d?.id}
                    policyVersionId={d?.policy_version_id}
                    isNeedUserInfo
                    userDetail={
                      <CommentMessage
                        ownerName={getUserNameFromId(d?.updated_by)}
                        date={d?.updated_at || " "}
                        message="No Comments"
                      />
                    }
                  />
                ) : (
                  ""
                )
              }
              highlitedColor="#205bcc"
              paddingDetails="5px 35px 10px 35px"
              summaryBorder="1px solid #DBDFF0"
              summaryRadius="7px"
              accBottom="14px"
              iconSize="1rem"
            />
          ))
        )}
      </section>
      <Modal isOpen={showCommentModel} toggle={() => setShowCommentModel(false)}>
        <PolicyStatus
          updateModelView={updateModelView}
          selectedPolicy={selectedPolicy}
          reviewChangesCallBack={reviewChangesCallBack}
          approveChangesCallBack={approveChangesCallBack}
        />
      </Modal>
    </ContentContainer>
  );
};
export default PendingPolicyApproval;
