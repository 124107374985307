import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

// Set Baseline Params
export const setBaselineParams = createAction(constant.SET_PARAMS_BASELINE);

// Get Baselines
export const getBaselines = createAction(constant.GET_BASELINES);
export const getBaselinesSuccess = createAction(constant.GET_BASELINES_SUCCESS);
export const getBaselinesFailed = createAction(constant.GET_BASELINES_FAILED);

// Get Baseline
export const getBaseline = createAction(constant.GET_BASELINE);
export const getBaselineSuccess = createAction(constant.GET_BASELINE_SUCCESS);
export const getBaselineFailed = createAction(constant.GET_BASELINE_FAILED);

// Get Baseline assets
export const getBaselineAssets = createAction(constant.GET_BASELINE_ASSETS);
export const getBaselineAssetsSuccess = createAction(constant.GET_BASELINE_ASSETS_SUCCESS);
export const getBaselineAssetsFailed = createAction(constant.GET_BASELINE_ASSETS_FAILED);

// Get Baseline analysis mini
export const getBaselineAnalysisMini = createAction(constant.GET_BASELINE_ANALYSIS_MINI);
export const getBaselineAnalysisMiniSuccess = createAction(
  constant.GET_BASELINE_ANALYSIS_MINI_SUCCESS
);
export const getBaselineAnalysisMiniFailed = createAction(
  constant.GET_BASELINE_ANALYSIS_MINI_FAILED
);

// Set Baseline assets
export const setBaselineAssetsParams = createAction(constant.SET_BASELINE_ASSETS_PARAMS);

// Get Baseline control
export const getBaselineControl = createAction(constant.GET_BASELINE_CONTROL);
export const getBaselineControlSuccess = createAction(constant.GET_BASELINE_CONTROL_SUCCESS);
export const getBaselineControlFailed = createAction(constant.GET_BASELINE_CONTROL_FAILED);

// Edit Baseline
export const editBaseline = createAction(constant.EDIT_BASELINE);
export const editBaselineSuccess = createAction(constant.EDIT_BASELINE_SUCCESS);
export const editBaselineFailed = createAction(constant.EDIT_BASELINE_FAILED);

// Edit Baseline Fields
export const editBaselineFields = createAction(constant.EDIT_BASELINE_FIELDS);
export const editBaselineFieldsSuccess = createAction(constant.EDIT_BASELINE_FIELDS_SUCCESS);
export const editBaselineFieldsFailed = createAction(constant.EDIT_BASELINE_FIELDS_FAILED);

// Create Baseline
export const createBaseline = createAction(constant.CREATE_BASELINE);
export const createBaselineSuccess = createAction(constant.CREATE_BASELINE_SUCCESS);
export const createBaselineFailed = createAction(constant.CREATE_BASELINE_FAILED);

// Delete Baseline
export const deleteBaseline = createAction(constant.DELETE_BASELINE);
export const deleteBaselineSuccess = createAction(constant.DELETE_BASELINE_SUCCESS);
export const deleteBaselineFailed = createAction(constant.DELETE_BASELINE_FAILED);

// Get Baseline Tag By Id
export const getBaselineTagById = createAction(constant.GET_BASELINE_TAG_BY_ID);
export const getBaselineTagByIdSuccess = createAction(constant.GET_BASELINE_TAG_BY_ID_SUCCESS);
export const getBaselineTagByIdFailed = createAction(constant.GET_BASELINE_TAG_BY_ID_FAILED);

// Post tag/tags
export const postTags = createAction(constant.POST_TAGS);
export const postTagsSuccess = createAction(constant.POST_TAGS_SUCCESS);
export const postTagsFailed = createAction(constant.POST_TAGS_FAILED);

// Get audit choices
export const getAuditChoices = createAction(constant.GET_AUDIT_CHOICES);
export const getAuditChoicesSuccess = createAction(constant.GET_AUDIT_CHOICES_SUCCESS);
export const getAuditChoicesFailed = createAction(constant.GET_AUDIT_CHOICES_FAILED);

// Get audit choices mini
export const getAuditChoicesMini = createAction(constant.GET_AUDIT_CHOICES_MINI);
export const getAuditChoicesMiniSuccess = createAction(constant.GET_AUDIT_CHOICES_SUCCESS_MINI);
export const getAuditChoicesMiniFailed = createAction(constant.GET_AUDIT_CHOICES_FAILED_MINI);

// Set audit choices params
export const setAuditChoicesParams = createAction(constant.SET_AUDIT_CHOICES_PARAMS);

// Get Baseline Sources
export const getBaselineSources = createAction(constant.GET_BASELINE_SOURCES);
export const getBaselineSourcesSuccess = createAction(constant.GET_BASELINE_SOURCES_SUCCESS);
export const getBaselineSourcesFailed = createAction(constant.GET_BASELINE_SOURCES_FAILED);

// Set Baseline Config Params
export const setBaselineConfigParams = createAction(constant.SET_BASELINE_CONFIG_PARAMS);
// UPD Baseline Config Params
export const updBaselineConfigParams = createAction(constant.UPD_BASELINE_CONFIG_PARAMS);

// getBaselineConfigFilters
export const getBaselineConfigFilters = createAction(constant.GET_BASELINE_CONFIG_FILTERS);
export const getBaselineConfigFiltersSuccess = createAction(
  constant.GET_BASELINE_CONFIG_FILTERS_SUCCESS
);
export const getBaselineConfigFiltersFailed = createAction(
  constant.GET_BASELINE_CONFIG_FILTERS_FAILED
);

export const getBaselineConfigTable = createAction(constant.GET_BASELINE_CONFIG_TABLE);
export const getBaselineConfigTableSuccess = createAction(
  constant.GET_BASELINE_CONFIG_TABLE_SUCCESS
);
export const getBaselineConfigTableFailed = createAction(constant.GET_BASELINE_CONFIG_TABLE_FAILED);

// Set Baseline History Params
export const setBaselineHistoryParams = createAction(constant.SET_BASELINE_HISTORY_PARAMS);

// EXPORT
export const exportBaselineConfigurationTable = createAction(constant.EXPORT_BASELINE_CONFIG_TABLE);
export const exportBaselineConfigurationTableSuccess = createAction(
  constant.EXPORT_BASELINE_CONFIG_TABLE_SUCCESS
);
export const exportBaselineConfigurationTableFailed = createAction(
  constant.EXPORT_BASELINE_CONFIG_TABLE_FAILED
);

// add-control
export const addControlBaselineConfigurationTable = createAction(
  constant.ADD_CONTROL_BASELINE_CONFIG_TABLE
);
export const addControlBaselineConfigurationTableSuccess = createAction(
  constant.ADD_CONTROL_BASELINE_CONFIG_TABLE_SUCCESS
);
export const addControlBaselineConfigurationTableFailed = createAction(
  constant.ADD_CONTROL_BASELINE_CONFIG_TABLE_FAILED
);

// delete-control
export const deleteControlBaselineConfigurationTable = createAction(
  constant.DELETE_CONTROL_BASELINE_CONFIG_TABLE
);
export const deleteControlBaselineConfigurationTableSuccess = createAction(
  constant.DELETE_CONTROL_BASELINE_CONFIG_TABLE_SUCCESS
);
export const deleteControlBaselineConfigurationTableFailed = createAction(
  constant.DELETE_CONTROL_BASELINE_CONFIG_TABLE_FAILED
);

// Get Compare Baselines
export const getCompareBaselines = createAction(constant.GET_COMPARE_BASELINES);
export const getCompareBaselinesSuccess = createAction(constant.GET_COMPARE_BASELINES_SUCCESS);
export const getCompareBaselinesFailed = createAction(constant.GET_COMPARE_BASELINES_FAILED);

// Duplicate Baseline
export const duplicateBaseline = createAction(constant.DUPLICATE_BASELINE);
export const duplicateBaselineSuccess = createAction(constant.DUPLICATE_BASELINE_SUCCESS);
export const duplicateBaselineFailed = createAction(constant.DUPLICATE_BASELINE_FAILED);

// Set  Assign-hosts params
export const setAssignHostsParams = createAction(constant.SET_ASSIGN_HOSTS_PARAMS);

// Set  Assign-groups params
export const setAssignGroupsParams = createAction(constant.SET_ASSIGN_GROUPS_PARAMS);

// Get Assets-mini
export const getBaselineAssetsMini = createAction(constant.GET_ASSETS_MINI);
export const getBaselineAssetsMiniSuccess = createAction(constant.GET_ASSETS_MINI_SUCCESS);
export const getBaselineAssetsMiniFailed = createAction(constant.GET_ASSETS_MINI_FAILED);

// Get Labels List
export const getLabelList = createAction(constant.GET_LABEL_LIST);
export const getLabelListSuccess = createAction(constant.GET_LABEL_LIST_SUCCESS);
export const getLabelListFailed = createAction(constant.GET_LABEL_LIST_FAILED);

// Get groups
export const getBaselineGroups = createAction(constant.GET_BASELINE_GROUPS);
export const getBaselineGroupsSuccess = createAction(constant.GET_BASELINE_GROUPS_SUCCESS);
export const getBaselineGroupsFailed = createAction(constant.GET_BASELINE_GROUPS_FAILED);

// Get groups mini
export const getBaselineGroupsMini = createAction(constant.GET_BASELINE_GROUPS_MINI);
export const getBaselineGroupsMiniSuccess = createAction(constant.GET_BASELINE_GROUPS_MINI_SUCCESS);
export const getBaselineGroupsMiniFailed = createAction(constant.GET_BASELINE_GROUPS_MINI_FAILED);

// Add Baseline asset
export const addBaselineAsset = createAction(constant.ADD_BASELINE_ASSET);
export const addBaselineAssetSuccess = createAction(constant.ADD_BASELINE_ASSET_SUCCESS);
export const addBaselineAssetFailed = createAction(constant.ADD_BASELINE_ASSET_FAILED);

// Delete Baseline asset
export const deleteBaselineAsset = createAction(constant.DELETE_BASELINE_ASSET);
export const deleteBaselineAssetSuccess = createAction(constant.DELETE_BASELINE_ASSET_SUCCESS);
export const deleteBaselineAssetFailed = createAction(constant.DELETE_BASELINE_ASSET_FAILED);

// Add Baseline group
export const addBaselineGroup = createAction(constant.ADD_BASELINE_GROUP);
export const addBaselineGroupSuccess = createAction(constant.ADD_BASELINE_GROUP_SUCCESS);
export const addBaselineGroupFailed = createAction(constant.ADD_BASELINE_GROUP_FAILED);

// Delete Baseline group
export const deleteBaselineGroup = createAction(constant.DELETE_BASELINE_GROUP);
export const deleteBaselineGroupSuccess = createAction(constant.DELETE_BASELINE_GROUP_SUCCESS);
export const deleteBaselineGroupFailed = createAction(constant.DELETE_BASELINE_GROUP_FAILED);
