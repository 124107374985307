import jsyaml from "js-yaml";
import {
  ALLOW,
  AUDIT,
  BLOCK,
  FILE,
  NETWORK,
  PATH_VALIDATION_REGEX,
  PROCESS,
  DEFAULT_POLICY_TEMPLATE,
  KIND_KUBEARMOR_HOST_POLICY,
  NODE,
  KIND_KUBEARMOR_POLICY,
  DEFAULT,
  K8S,
  VM
} from "./constants";

export const JsonToYaml = jsonObject => {
  return jsyaml.dump(jsonObject, {
    noArrayIndent: true
  });
};

export const YamlToJson = yamlDoc => {
  try {
    return jsyaml.load(yamlDoc);
  } catch (error) {
    // console.log(error)
  }
};

export const parseYamlErrors = yamlDoc => {
  try {
    jsyaml.load(yamlDoc);
    return [];
  } catch (e) {
    return [e];
  }
};

export const transformPolicyName = value => {
  return value?.replaceAll(" ", "-")?.toLowerCase();
};

export const getActionPillColor = action => {
  if (action === ALLOW) return "border-green-700 text-green-700 bg-green-100";
  if (action === BLOCK) return "border-red-700 text-red-700 bg-red-100";
  if (action === AUDIT) return "border-gray-700 text-gray-700 bg-gray-100";
};

export const validateExecutionPath = value => {
  return value ? PATH_VALIDATION_REGEX.test(value) || "Please enter a valid execution path" : true;
};

export const validateParentPath = (value, setError) => {
  if (value) {
    if (PATH_VALIDATION_REGEX.test(value)) {
      return true;
    }
    setError("fromSource", {
      type: "validate",
      message: "Please enter a valid parent path"
    });
    return false;
  }
  return true;
};

export const getRuleFormValues = (rule, ruleType) => {
  const isProcessRule = ruleType === PROCESS.toLowerCase();
  const isFileRule = ruleType === FILE.toLowerCase();
  const isNetworkRule = ruleType === NETWORK.toLowerCase();

  const formValues = {};

  if (isProcessRule || isFileRule) {
    formValues.path = rule?.path || rule?.dir;
    formValues.directory = Boolean(rule?.dir);
    formValues.recursive = Boolean(rule?.recursive);
    formValues.ownerOnly = Boolean(rule?.ownerOnly);
    if (isFileRule) {
      formValues.readOnly = Boolean(rule?.readOnly);
    }
    formValues.severity = rule?.severity;
    formValues.action = rule?.action;
    formValues.fromSource = rule?.fromSource?.map(item => ({
      path: item.path,
      label: item.path,
      value: item.path
    }));
  } else if (isNetworkRule) {
    formValues.protocol = rule?.protocol;
    formValues.severity = rule?.severity;
    formValues.action = rule?.action;
    formValues.fromSource = rule?.fromSource?.map(item => ({
      path: item.path,
      label: item.path,
      value: item.path
    }));
  }

  return formValues;
};

export const transformPolicyJSON = policyJSON => {
  let isValidYAML;
  let isCreatingPolicy;

  const policyYAML = JsonToYaml(policyJSON);

  const yamlErrors = parseYamlErrors(policyYAML);
  if (yamlErrors?.length) {
    isValidYAML = false;
  } else {
    isValidYAML = true;
  }

  if (JSON.stringify(policyJSON) === JSON.stringify(DEFAULT_POLICY_TEMPLATE)) {
    isCreatingPolicy = false;
  } else {
    isCreatingPolicy = true;
  }

  return { policyYAML, isValidYAML, isCreatingPolicy };
};

// Generate a random 8-digit Id
export const getRandomId = () => {
  return Math.floor(Math.random() * 100000000);
};

export const preProcessUploadedYAML = policyJSON => {
  const updatedPolicyJSON = { ...policyJSON };
  const metadataFormValues = {};
  let matchLabels = {};
  const unknownPolicyValues = {};
  let processRuleData;
  let fileRuleData;
  let networkRuleData;

  const process = policyJSON?.spec?.process;
  const file = policyJSON?.spec?.file;
  const network = policyJSON?.spec?.network;

  if (policyJSON?.metadata?.name) {
    const policyName = transformPolicyName(policyJSON?.metadata?.name);
    metadataFormValues.policyName = policyName;
  }

  if (policyJSON.kind === KIND_KUBEARMOR_HOST_POLICY) {
    metadataFormValues.labelType = NODE;
    if (policyJSON?.spec?.nodeSelector?.matchLabels) {
      matchLabels = policyJSON?.spec?.nodeSelector?.matchLabels;
    }
  } else if (policyJSON.kind === KIND_KUBEARMOR_POLICY) {
    metadataFormValues.labelType = DEFAULT;
    if (policyJSON?.spec?.selector?.matchLabels) {
      matchLabels = policyJSON?.spec?.selector?.matchLabels;
    }

    if (policyJSON?.metadata?.namespace) {
      const namespace = {
        label: policyJSON?.metadata?.namespace,
        value: policyJSON?.metadata?.namespace
      };
      metadataFormValues.policyNamespace = namespace;
      unknownPolicyValues.namespace = namespace;
    }
  }

  if (Object.keys(matchLabels)) {
    const labels = Object.entries(matchLabels)?.map(([name, value]) => ({
      id: getRandomId(),
      label: `${name}=${value}`,
      name,
      value
    }));

    metadataFormValues.selectorLabel = labels;
    unknownPolicyValues.labels = labels;
  }

  if (policyJSON?.spec?.tags?.length) {
    const tags = policyJSON?.spec?.tags?.map(tag => ({
      id: getRandomId(),
      label: tag,
      value: tag
    }));

    metadataFormValues.policyTags = tags;
    unknownPolicyValues.tags = tags;
  }

  if (policyJSON?.spec?.message) {
    metadataFormValues.policyMessage = policyJSON?.spec?.message;
  }

  if (process) {
    if (process?.matchDirectories?.length) {
      updatedPolicyJSON.spec.process.matchDirectories = process?.matchDirectories?.map(path => ({
        ...path,
        severity: path?.severity || process?.severity,
        action: path?.action || process?.action
      }));
    }
    if (process?.matchPaths?.length) {
      updatedPolicyJSON.spec.process.matchPaths = process?.matchPaths?.map(path => ({
        ...path,
        severity: path?.severity || process?.severity,
        action: path?.action || process?.action
      }));
    }

    if (process?.severity) delete updatedPolicyJSON?.spec?.process?.severity;
    if (process?.action) delete updatedPolicyJSON?.spec?.process?.action;

    processRuleData = [
      ...(updatedPolicyJSON?.spec?.process?.matchDirectories || []),
      ...(updatedPolicyJSON?.spec?.process?.matchPaths || [])
    ];
  }

  if (file) {
    if (file?.matchDirectories?.length) {
      updatedPolicyJSON.spec.file.matchDirectories = file?.matchDirectories?.map(path => ({
        ...path,
        severity: path?.severity || file?.severity,
        action: path?.action || file?.action
      }));
    }
    if (file?.matchPaths?.length) {
      updatedPolicyJSON.spec.file.matchPaths = file?.matchPaths?.map(path => ({
        ...path,
        severity: path?.severity || file?.severity,
        action: path?.action || file?.action
      }));
    }

    if (file?.severity) delete updatedPolicyJSON?.spec?.file?.severity;
    if (file?.action) delete updatedPolicyJSON?.spec?.file?.action;

    fileRuleData = [
      ...(updatedPolicyJSON?.spec?.file?.matchDirectories || []),
      ...(updatedPolicyJSON?.spec?.file?.matchPaths || [])
    ];
  }

  if (network) {
    if (network?.matchProtocols?.length) {
      updatedPolicyJSON.spec.network.matchProtocols = network?.matchProtocols?.map(protocol => ({
        ...protocol,
        severity: protocol?.severity || network?.severity,
        action: protocol?.action || network?.action
      }));
    }

    if (network?.severity) delete updatedPolicyJSON?.spec?.network?.severity;
    if (network?.action) delete updatedPolicyJSON?.spec?.network?.action;

    networkRuleData = [...(updatedPolicyJSON?.spec?.network?.matchProtocols || [])];
  }

  return {
    updatedPolicyJSON,
    metadataFormValues,
    unknownPolicyValues,
    processRuleData,
    fileRuleData,
    networkRuleData
  };
};

export const uploadFileTypeValidator = file => {
  const fileExt = file?.name?.split(".")?.pop();
  if (fileExt !== "yml" && fileExt !== "yaml") {
    return {
      code: "invalid-file-type",
      message: `Invalid file type`
    };
  }
};

export const generateStorePolicyRequestBody = ({
  formValues,
  unknownPolicyValues,
  policyJSON,
  policyYAML,
  selectedEntity,
  selectedCluster,
  selectedInstanceGroup,
  selectedInstance,
  userId,
  workspaceId,
  isUpdatingPolicy
}) => {
  let reqBody = {};
  let namespace = {};
  let labels = [{ ids: [] }];
  let tags = [{ ids: [] }];

  if (formValues?.policyNamespace) {
    if (formValues?.policyNamespace?.id) {
      namespace = { id: formValues?.policyNamespace?.id };
    } else {
      namespace = { new: { name: formValues?.policyNamespace?.value } };
    }
  }

  if (formValues?.selectorLabel?.length) {
    if (isUpdatingPolicy) {
      labels = [{ ids: formValues?.selectorLabel?.map(label => label?.id) }];
    } else {
      // eslint-disable-next-line no-lonely-if
      if (unknownPolicyValues?.labels?.length) {
        const newlyAddedLabels = formValues?.selectorLabel?.filter(label =>
          unknownPolicyValues?.labels?.every(unknwn => unknwn?.id !== label?.id)
        );
        const knownLabelsIds = newlyAddedLabels?.map(label => label?.id);
        const unknownLabels = unknownPolicyValues?.labels?.map(label => ({
          name: label?.name,
          value: label?.value
        }));
        labels = [{ ids: knownLabelsIds }, { new: unknownLabels }];
      } else {
        labels = [{ ids: formValues?.selectorLabel?.map(label => label?.id) }];
      }
    }
  }

  if (formValues?.policyTags?.length) {
    if (isUpdatingPolicy) {
      tags = [{ ids: formValues?.policyTags?.map(tag => tag?.id) }];
    } else {
      // eslint-disable-next-line no-lonely-if
      if (unknownPolicyValues?.tags?.length) {
        const newlyAddedTags = formValues?.policyTags?.filter(tag =>
          unknownPolicyValues?.tags?.every(unknwn => unknwn?.id !== tag?.id)
        );
        const knownTagsIds = newlyAddedTags?.map(tag => tag?.id);
        const unknownTags = unknownPolicyValues?.tags?.map(tag => ({
          name: tag?.value
        }));
        tags = [{ ids: knownTagsIds }, { new: unknownTags }];
      } else {
        tags = [{ ids: formValues?.policyTags?.map(tag => tag?.id) }];
      }
    }
  }

  reqBody = {
    name: policyJSON?.metadata?.name,
    workspace_id: workspaceId,
    domain_id: 1,
    user_id: userId,
    policy_content: policyYAML,
    label: labels,
    tag: tags,
    label_type: formValues?.labelType,
    kind: formValues?.labelType === DEFAULT ? "KubeArmor" : "KubeArmor Host"
  };

  if (selectedEntity === K8S) {
    reqBody = {
      ...reqBody,
      type: K8S,
      cluster_id: selectedCluster?.id,
      namespace
    };
  } else if (selectedEntity === VM) {
    reqBody = {
      ...reqBody,
      type: VM,
      vm_instance_group: { id: selectedInstanceGroup?.id },
      vm_instance: { id: selectedInstance?.id }
    };
  }

  return reqBody;
};

export const parsePolicyYAMLToComponentState = (policyInJSON, currentPolicy) => {
  const formValues = {};

  // Setting policy name
  formValues.policyName = policyInJSON.metadata?.name;

  // Setting policy message
  formValues.policyMessage = policyInJSON?.spec?.message;

  // Setting policy kind
  if (policyInJSON.kind === KIND_KUBEARMOR_HOST_POLICY) {
    formValues.labelType = NODE;
  } else if (policyInJSON.kind === KIND_KUBEARMOR_POLICY) {
    formValues.labelType = DEFAULT;
  }

  // Setting labels
  if (currentPolicy?.label?.length) {
    const labels = currentPolicy?.label?.map(label => ({
      id: label?.id,
      name: label?.name,
      value: label?.value,
      label: `${label?.name}=${label?.value}`
    }));
    formValues.selectorLabel = labels;
  }

  // Setting tags
  if (currentPolicy?.tag?.length) {
    const tags = currentPolicy?.tag?.map(tag => ({
      id: tag?.id,
      value: tag?.name,
      label: tag?.name
    }));
    formValues.policyTags = tags;
  }

  if (currentPolicy?.type === K8S) {
    // setting cluster and namespace
    formValues.cluster = {
      id: currentPolicy?.cluster?.id,
      label: currentPolicy?.cluster?.name,
      value: currentPolicy?.cluster?.name
    };
    formValues.policyNamespace = {
      id: currentPolicy?.namespace?.id,
      label: currentPolicy?.namespace?.name,
      value: currentPolicy?.namespace?.name
    };
  } else if (currentPolicy?.type === VM) {
    // setting Instance Group and Instance
    formValues.instanceGroup = {
      id: currentPolicy?.vm_instance_group?.id,
      label: currentPolicy?.vm_instance_group?.name,
      value: currentPolicy?.vm_instance_group?.name
    };
    formValues.instance = {
      id: currentPolicy?.vm_instance?.id,
      label: currentPolicy?.vm_instance?.name,
      value: currentPolicy?.vm_instance?.name
    };
  }

  return formValues;
};

export const parseClonePolicyYAMLToComponentState = (policyInJSON, currentPolicy) => {
  const formValues = {};
  // Setting policy name
  formValues.policyName = currentPolicy?.name + "-clone";

  // Setting policy message
  formValues.policyMessage = policyInJSON?.spec?.message;

  // Setting policy kind
  if (policyInJSON.kind === KIND_KUBEARMOR_HOST_POLICY) {
    formValues.labelType = NODE;
  } else if (policyInJSON.kind === KIND_KUBEARMOR_POLICY) {
    formValues.labelType = DEFAULT;
  }

  // Setting labels
  if (currentPolicy?.labels?.length) {
    const labels = currentPolicy?.labels?.map(label => ({
      id: label?.id,
      name: label?.name,
      value: label?.value,
      label: `${label?.name}=${label?.value}`
    }));
    formValues.selectorLabel = labels;
  }

  // Setting tags
  if (currentPolicy?.tags?.length) {
    const tags = currentPolicy?.tags?.map(tag => ({
      id: tag?.id,
      value: tag?.name,
      label: tag?.name
    }));
    formValues.policyTags = tags;
  }

  // setting cluster and namespace
  formValues.cluster = {
    id: currentPolicy?.cluster_id,
    label: currentPolicy?.cluster_name,
    value: currentPolicy?.cluster_name
  };
  formValues.policyNamespace = {
    id: currentPolicy?.namespace_id,
    label: currentPolicy?.namespace_name,
    value: currentPolicy?.namespace_name
  };
  return formValues;
};
