import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Button, Title, Modal, Select, Input } from "components/simple";
import { useForm, Controller } from "react-hook-form";
import { Typography } from "@mui/material";
import { useQueryClient } from "react-query";
import { useDeleteSaveFilter, useEditSaveFilter } from "store/entities/datapipeline/queries";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";

function EditQueryModal({
  onCloseModal,
  isModalOpen,
  filterValue,
  fname,
  filter_id,
  fquery,
  fcomp
}) {
  const [filter_name, setFilterName] = useState();
  const [expFilterArr, setExpFilterArr] = useState(["no data"]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm();

  useEffect(() => {
    setFilterName(fname);
    if (fquery === undefined) {
      setExpFilterArr(["no data"]);
    } else {
      const filterArr = [];
      const splitArr = fquery?.split(",");
      splitArr?.map(val => {
        if (val !== "") {
          filterArr.push({ text: val });
        }
      });
      setExpFilterArr(filterArr);
    }
  }, [fname]);

  useEffect(() => {
    if (filter_name) {
      setValue("filterName", filter_name);
    }
  }, [filter_name]);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const saveFilter = useEditSaveFilter(d => {
    onCloseModal();
    queryClient.invalidateQueries("getAllFilters");
    dispatch(addNotification({ msg: "Filter Updated!" }));
  });
  const onSubmit = () => {
    saveFilter.mutate({
      filter_id: filter_id,
      filter_name: filter_name,
      filterQuery: fquery,
      filters: fcomp
    });
  };
  const deleteFilter = useDeleteSaveFilter(e => {
    onCloseModal();
    queryClient.invalidateQueries("getAllFilters");
    dispatch(addNotification({ msg: "Filter Deleted!" }));
  });
  const handleDeleteFilter = () => {
    deleteFilter.mutate({
      filter_id: filter_id
    });
  };
  return (
    <Modal isOpen={isModalOpen} toggle={onCloseModal}>
      <Title className={styles.title}>Edit Query</Title>
      <hr />
      <div className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="filterName"
            {...register("filterName", {
              required: "Filter name cannot be empty"
            })}
            defaultValue={filterValue}
            control={control}
            render={({ field: { onChange }, value, ref }) => (
              <Input
                name="filterName"
                label="Name"
                placeholder="Enter Name"
                required
                value={filter_name}
                onChange={evt => {
                  onChange(evt.target.value);
                  setFilterName(evt.target.value);
                }}
              />
            )}
          />
          <div className="mt-6 mb-2">
            <Typography variant="body2">Query</Typography>
          </div>
          <div className={styles.queryContainer}>
            <table className="py-2 px-2">
              <tbody>
                {expFilterArr?.map((data, index) => (
                  <tr key={index}>
                    <th className={styles.headingNumber}>{index + 1}</th>
                    <td className={styles.query}>{data.text}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-8 flex justify-between">
            <div>
              <Button variant="outline" onClick={() => onCloseModal()} className={styles.btn}>
                Cancel
              </Button>
            </div>
            <div className="flex gap-3">
              <Button
                className={styles.btn}
                onClick={() => {
                  handleDeleteFilter();
                }}
              >
                Delete
              </Button>
              <Button onClick={handleSubmit(onSubmit)} className={styles.btn}>
                Update Query
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default EditQueryModal;
