import React, { useState } from "react";
import {
  DEFAULT_KUBEARMOR_POLICY_QUERY_KEYS,
  DELETE_POLICY_MSG,
  EDIT_POLICY_MSG,
  K8S,
  KIND_KUBEARMOR_HOST,
  KUBEARMOR,
  USER_FULL_NAME,
  USER_ID
} from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import { useKubeArmorPolicyEditorState } from "store/entities/policyEditor/kubeArmorPolicyEditor/slice";
import { useAllPoliciesQuery } from "store/entities/policyEditor/kubeArmorPolicyEditor/queries";
import {
  useApplyPolicyMutation,
  useDeletePolicyMutation,
  useDownloadPoliciesZipMutation
} from "store/entities/policyEditor/kubeArmorPolicyEditor/mutations";
import {
  useResetComponentState,
  useUpdateComponentState
} from "store/entities/policyEditor/kubeArmorPolicyEditor/hooks";
import { downloadFile, formatIsoDateTimeToLocalDate } from "helper/helpers";
import { Table } from "components/complex";
import ActionMenu from "components/simple/ActionMenu";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import DownloadIcon from "@mui/icons-material/Download";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useDispatch } from "react-redux";
import { Modal, FormLabel, Textarea, Button, ContentContainer } from "components/simple";
import { Divider, Typography } from "@mui/material";
import { useQueryClient } from "react-query";
import { addNotification } from "store/entities/notifications/actions";
import { navigate } from "helper/history";
import { allPolicies } from "router/routes";
import renderCellExpand from "helper/renderCellExpand";
import { NETWORK_POLICY } from "store/entities/policyEditor/NetworkPolicyEditor/constants";

const PolicyLibrary = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [showCreatePolicyModal, setShowCreatePolicyModal] = useState(false);
  const [showEditPolicyModal, setShowEditPolicyModal] = useState(false);
  const [showDeletePolicyModal, setShowDeletePolicyModal] = useState(false);
  const [showApplyPolicyModal, setShowApplyPolicyModal] = useState(false);
  const [policyToBeEdited, setPolicyToBeEdited] = useState();
  const [policyToBeDeleted, setPolicyToBeDeleted] = useState();
  const [policyToBeApplied, setPolicyToBeApplied] = useState();
  const [policyCommitMessage, setPolicyCommitMessage] = useState("");

  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const selectedEntity = useKubeArmorPolicyEditorState("selectedEntity");
  const isCreatingPolicy = useKubeArmorPolicyEditorState("isCreatingPolicy");
  const currentPolicy = useKubeArmorPolicyEditorState("currentPolicy");

  const updateComponentState = useUpdateComponentState();
  const resetComponentState = useResetComponentState();

  const allPoliciesQuery = useAllPoliciesQuery({
    selectedEntity,
    kind: [KUBEARMOR, KIND_KUBEARMOR_HOST],
    page,
    perPage: pageSize
  });
  const deletePolicyMutation = useDeletePolicyMutation();
  const applyPolicyMutation = useApplyPolicyMutation();
  const downloadPoliciesZipMutation = useDownloadPoliciesZipMutation();

  const handleDownloadSelectedPolicies = () => {
    const payload = { policy_id: [...selectedPolicies] };
    downloadPoliciesZipMutation.mutate(payload);
  };

  // Create Policy Modal
  const handleOnClickCreatePolicy = () => {
    if (isCreatingPolicy) {
      setShowCreatePolicyModal(true);
    } else {
      resetComponentState();
    }
  };
  const handleCloseCreatePolicyModal = () => {
    setShowCreatePolicyModal(false);
  };
  const handleCreatePolicy = () => {
    resetComponentState();
    setShowCreatePolicyModal(false);
  };

  // Edit Policy Modal
  const handleCloseEditPolicyModal = () => {
    setShowEditPolicyModal(false);
    setPolicyToBeEdited({});
  };
  const handleEditPolicy = policy => {
    updateComponentState(policy?.policy_content, policy);
    setPolicyToBeEdited({});
    setShowEditPolicyModal(false);
  };

  // Delete Policy Modal
  const handleCloseDeletePolicyModal = () => {
    setShowDeletePolicyModal(false);
    setPolicyToBeDeleted({});
  };
  const handleDeletePolicy = () => {
    const payload = {
      workspace_id: policyToBeDeleted?.workspace?.id,
      policy_id: policyToBeDeleted?.id,
      updated_by: USER_ID
    };
    deletePolicyMutation.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ...DEFAULT_KUBEARMOR_POLICY_QUERY_KEYS,
          "getAllPoliciesData"
        ]); // refetching policies
        // Reset the whole form state if the current policy being edited is deleted
        if (currentPolicy?.id === policyToBeDeleted?.id) {
          resetComponentState();
        }
        setShowDeletePolicyModal(false);
        setPolicyToBeDeleted({});
      }
    });
  };

  // Apply Policy Modal
  const handleCloseApplyPolicyModal = () => {
    setShowApplyPolicyModal(false);
    setPolicyToBeApplied({});
  };
  const handleApplyPolicy = () => {
    const payload = {
      policy_id: policyToBeApplied?.id,
      workspace_id: policyToBeApplied?.workspace?.id,
      commit_message: policyCommitMessage,
      created_by: USER_FULL_NAME
    };

    applyPolicyMutation.mutate(payload, {
      onSuccess: () => {
        setPolicyCommitMessage("");
        setShowApplyPolicyModal(false);
        resetComponentState();
        dispatch(addNotification({ type: "success", msg: "Policy applied" }));
        navigate(allPolicies);
      }
    });
  };

  const actionMenuItems = params => {
    return [
      {
        name: "Edit",
        icon: <EditIcon />,
        onClick: () => {
          setPolicyToBeEdited(params?.row);
          if (isCreatingPolicy) {
            setShowEditPolicyModal(true);
          } else {
            handleEditPolicy(params?.row);
          }
        }
      },
      {
        name: "Delete",
        icon: <DeleteIcon />,
        onClick: () => {
          setPolicyToBeDeleted(params?.row);
          setShowDeletePolicyModal(true);
        }
      },
      {
        name: "Apply Policy",
        icon: <PanToolAltIcon />,
        onClick: () => {
          setPolicyToBeApplied(params?.row);
          setShowApplyPolicyModal(true);
        }
      },
      {
        name: "Download Policy",
        icon: <DownloadIcon />,
        onClick: () => {
          const { name, policy_content } = params?.row;
          downloadFile(policy_content, `${name}.yaml`, "application/x-yaml");
        }
      }
    ];
  };

  const TABLE_COLUMNS = [
    {
      headerName: "Policy Name",
      field: "name",
      resizable: true,
      sortable: false,
      minWidth: 160,
      flex: 1,
      renderCell: renderCellExpand,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Policy Type",
      field: "kind",
      resizable: true,
      sortable: false,
      maxWidth: 100,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false,
      align: "center"
    },
    {
      ...(selectedEntity === K8S
        ? {
            headerName: "Clusters",
            field: "cluster",
            resizable: true,
            sortable: false,
            minWidth: 100,
            flex: 1,
            renderCell: params => <div>{params?.value?.name}</div>,
            headerClassName: "super-app-theme--header",
            hide: false
          }
        : {
            headerName: "Instance Group",
            field: "vm_instance_group",
            resizable: true,
            sortable: false,
            minWidth: 100,
            flex: 1,
            renderCell: params => <div>{params?.value?.name}</div>,
            headerClassName: "super-app-theme--header",
            hide: false
          })
    },
    {
      ...(selectedEntity === K8S
        ? {
            headerName: "Namespace",
            field: "namespace",
            resizable: true,
            sortable: false,
            minWidth: 100,
            flex: 1,
            renderCell: params => <div>{params?.value?.name}</div>,
            headerClassName: "super-app-theme--header",
            hide: false
          }
        : {
            headerName: "Instance",
            field: "vm_instance",
            resizable: true,
            sortable: false,
            minWidth: 100,
            flex: 1,
            renderCell: params => <div>{params?.value?.name}</div>,
            headerClassName: "super-app-theme--header",
            hide: false
          })
    },
    {
      headerName: "Workspace",
      field: "workspace",
      resizable: true,
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: params => <div>{params?.value?.name}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Created At",
      field: "date_created",
      resizable: true,
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: params => <div>{formatIsoDateTimeToLocalDate(params?.value)}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "",
      field: "policy_options",
      resizable: false,
      maxWidth: 50,
      flex: 1,
      align: "right",
      renderCell: params => <ActionMenu onClick={() => {}} menuItems={actionMenuItems(params)} />,
      headerClassName: "super-app-theme--header",
      hide: false
    }
  ];

  return (
    <ContentContainer className="relative">
      {showCreatePolicyModal && (
        <Modal isOpen={showCreatePolicyModal} toggle={handleCloseCreatePolicyModal} width={600}>
          <div className="flex flex-col gap-4">
            <Typography fontSize={22} fontWeight={600}>
              Create policy
            </Typography>
            <Divider orientation="horizontal" flexItem />
            <Typography>{EDIT_POLICY_MSG}</Typography>
            <div className="flex justify-between mt-2">
              <Button variant="outline" onClick={handleCloseCreatePolicyModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleCreatePolicy}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {showEditPolicyModal && (
        <Modal isOpen={showEditPolicyModal} toggle={handleCloseEditPolicyModal} width={600}>
          <div className="flex flex-col gap-4">
            <Typography fontSize={22} fontWeight={600}>
              Edit {policyToBeEdited?.name} policy
            </Typography>
            <Divider orientation="horizontal" flexItem />
            <Typography>{EDIT_POLICY_MSG}</Typography>
            <div className="flex justify-between mt-2">
              <Button variant="outline" onClick={handleCloseEditPolicyModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={() => handleEditPolicy(policyToBeEdited)}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {showDeletePolicyModal && (
        <Modal isOpen={showDeletePolicyModal} toggle={handleCloseDeletePolicyModal} width={600}>
          <div className="flex flex-col gap-4">
            <Typography fontSize={22} fontWeight={600}>
              Delete {policyToBeDeleted?.name} policy
            </Typography>
            <Divider orientation="horizontal" flexItem />
            <Typography>{DELETE_POLICY_MSG}</Typography>
            <div className="flex justify-between mt-2">
              <Button variant="outline" onClick={handleCloseDeletePolicyModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleDeletePolicy}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {showApplyPolicyModal && (
        <Modal isOpen={showApplyPolicyModal} toggle={handleCloseApplyPolicyModal} width={600}>
          <div className="flex flex-col gap-4">
            <Typography fontSize={22} fontWeight={600}>
              Apply {policyToBeApplied?.name} policy
            </Typography>
            <Divider orientation="horizontal" flexItem />
            <div className="flex flex-col gap-1">
              <Typography fontSize={18} fontWeight={500}>
                Write a commit message (optional)
              </Typography>
              <Textarea value={policyCommitMessage} onChange={setPolicyCommitMessage} />
            </div>
            <div className="flex justify-between mt-2">
              <Button variant="outline" onClick={handleCloseApplyPolicyModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleApplyPolicy}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <div className="flex justify-between items-center p-3">
        <Button variant="outline" onClick={handleOnClickCreatePolicy}>
          Create Policy
          <AddIcon sx={{ fontSize: "22px", color: "primary.light" }} />
        </Button>
        {Boolean(selectedPolicies?.length) && (
          <Button variant="outline" onClick={handleDownloadSelectedPolicies}>
            Download YAML
            <DownloadIcon sx={{ fontSize: "18px", color: "primary.light" }} />
          </Button>
        )}
      </div>

      <Table
        apiRef={apiRef}
        columns={TABLE_COLUMNS}
        data={allPoliciesQuery.data?.policies?.filter(item => item?.kind !== NETWORK_POLICY)}
        rowCount={allPoliciesQuery.data?.total_count}
        loading={allPoliciesQuery.isLoading}
        page={page}
        pageSize={pageSize}
        onPageChange={p => setPage(p)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50]}
        selectionModel={selectedPolicies}
        onSelectionModelChange={newSelectionModel => setSelectedPolicies(newSelectionModel)}
      />
    </ContentContainer>
  );
};

export default PolicyLibrary;
