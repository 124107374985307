import { getAccuknoxWorkspace } from "api/api";
import { useDispatch } from "react-redux";
import { useGetChannelList } from "store/entities/channels/queries";
import { updateChannelList, useChannelIntegrationState } from "store/entities/channels/slice";

const useChannelList = () => {
  const dispatch = useDispatch();
  const channelFilter = useChannelIntegrationState("channelFilter");
  const workspaceVal = getAccuknoxWorkspace();
  const { isFetching } = useGetChannelList(data => {
    dispatch(updateChannelList(data));
  }, channelFilter?.value);
  return {
    isFetching
  };
};
export default useChannelList;
