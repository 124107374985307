import { Typography } from "@mui/material";

const headerCellConfig = {
  resizable: true,
  minWidth: 100,
  flex: 1,
  renderCell: params => <div>{params?.value}</div>,
  headerClassName: "super-app-theme--header",
  hide: false
};

export const policyJsonheader = [
  {
    headerName: "",
    field: "jsonData",
    renderCell: params => (
      <Typography paragraph fontWeight={400} fontSize={14} sx={{ color: "#2B3858", m: 0 }}>
        {params?.value}
      </Typography>
    ),
    width: 1000
  }
];

export const policyJsonData = {
  total_record: 4,
  list_of_image: [
    {
      id: 10965,
      jsonData: `{
                "Action": "null",
                "ClusterId": 456,
                "ClusterName": "accuknox-e2e-01",
                "ComponentName": "kubearmor",
                "ContainerID": "5f96731082c442903351b6f289a5feff8ee338f2dfee17f1149cf6e24ef3b8c4",
                "ContainerName": "server",
                "Data": "syscall=SYS_SOCKET",
                "HostName": "gke-accuknox-e2e-01-pool-1-65f51f09-bs0p",}`
    }
  ]
};

export const policyInfoHeader = [
  {
    headerName: "Timestamp",
    field: "timestamp",
    ...headerCellConfig,
    renderCell: params => (
      <Typography paragraph fontWeight={400} fontSize={14} sx={{ color: "#2B3858", m: 0 }}>
        {params?.value}
      </Typography>
    ),
    width: 500
  },
  {
    headerName: "Message",
    field: "message",
    ...headerCellConfig,
    renderCell: params => (
      <Typography paragraph fontWeight={400} fontSize={14} sx={{ color: "#2B3858", m: 0 }}>
        {params?.value}
      </Typography>
    ),
    width: 500
  },
  {
    headerName: "Action",
    field: "action",
    ...headerCellConfig,
    renderCell: params => (
      <Typography paragraph fontWeight={400} fontSize={14} sx={{ color: "#2B3858", m: 0 }}>
        {params?.value}
      </Typography>
    ),
    width: 300
  },
  {
    headerName: "Severity",
    field: "severity",
    ...headerCellConfig,
    renderCell: params => (
      <Typography paragraph fontWeight={400} fontSize={14} sx={{ color: "#2B3858", m: 0 }}>
        {params?.value}
      </Typography>
    ),
    width: 300
  },
  {
    headerName: "Operation",
    field: "operation",
    ...headerCellConfig,
    renderCell: params => (
      <Typography paragraph fontWeight={400} fontSize={14} sx={{ color: "#2B3858", m: 0 }}>
        {params?.value}
      </Typography>
    ),
    width: 300
  },
  {
    headerName: "Pod Name",
    field: "podName",
    ...headerCellConfig,
    renderCell: params => (
      <Typography paragraph fontWeight={400} fontSize={14} sx={{ color: "#2B3858", m: 0 }}>
        {params?.value}
      </Typography>
    ),
    width: 300
  },
  {
    headerName: "Result",
    field: "result",
    ...headerCellConfig,
    renderCell: params => (
      <Typography paragraph fontWeight={400} fontSize={14} sx={{ color: "#2B3858", m: 0 }}>
        {params?.value}
      </Typography>
    ),
    width: 300
  }
];

export const policyInfoRow = {
  total_record: 4,
  list_of_image: [
    {
      id: 109587,
      timestamp: "2021-08-17 05:39 PST",
      message: "Alert! Unauthorised...",
      action: "Audit",
      severity: "Critical",
      operation: "Process",
      podName: "Ubuntu",
      result: "Passed"
    },
    {
      id: 109452,
      timestamp: "2021-08-17 05:39 PST",
      message: "Alert! Unauthorised...",
      action: "Audit",
      severity: "Critical",
      operation: "Process",
      podName: "Ubuntu",
      result: "Passed"
    },
    {
      id: 109449,
      timestamp: "2021-08-17 05:39 PST",
      message: "Alert! Unauthorised...",
      action: "Audit",
      severity: "Critical",
      operation: "Process",
      podName: "Ubuntu",
      result: "Passed"
    },
    {
      id: 109455,
      timestamp: "2021-08-17 05:39 PST",
      message: "Alert! Unauthorised...",
      action: "Audit",
      severity: "Critical",
      operation: "Process",
      podName: "Ubuntu",
      result: "Passed"
    },
    {
      id: 569854,
      timestamp: "2021-08-17 05:39 PST",
      message: "Alert! Unauthorised...",
      action: "Audit",
      severity: "Critical",
      operation: "Process",
      podName: "Ubuntu",
      result: "Passed"
    }
  ]
};
