import React, { useEffect } from "react";
import styles from "./styles.module.scss";

import AccuknoxLogoIcon from "../assets/AccuknoxLogo.png";
import VerifiedLogo from "../assets/verificationSuccess.png";
import { Button } from "components/simple";
import { navigate } from "helper/history";
import { routes } from "router";
import { useLocation } from "react-router-dom";

export const ResetPasswordSuccess = () => {
  const location = useLocation();
  const resetPasswordSuccess = location?.state?.success;

  useEffect(() => {
    if (!resetPasswordSuccess) {
      navigate(routes.loginScreen);
    } else {
      navigate(location.pathname, {});
    }
  }, []);

  return (
    <>
      <div className={styles.logo}>
        <img src={AccuknoxLogoIcon} width="311px" height="64px" alt="Accuknox" />
      </div>
      <div className={styles.verified}>
        <img src={VerifiedLogo} width="106px" height="96px" alt="Verification Success" />
      </div>

      <div className={styles.title}>Thank You!</div>

      <div className={styles.description}>Your password has been updated successfully</div>

      <Button className={styles.button} type="submit" onClick={() => navigate(routes.loginScreen)}>
        Continue to Login
      </Button>
    </>
  );
};
