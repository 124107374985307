import React, { useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { FormLabel } from "@mui/material";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import { Icon } from "components/simple";

const DatePickerComponent = ({
  label,
  placeholder,
  onChange,
  value,
  className,
  inputClassname,
  ...rest
}) => {
  const [isForcePickerOpen, setForcePickerOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={className}>
        {label && <FormLabel className={styles.label}>{label}</FormLabel>}
        <div>
          <DatePicker
            open={isForcePickerOpen}
            onClose={() => setForcePickerOpen(false)}
            label={label}
            value={value || null}
            onChange={newValue => {
              onChange(newValue);
            }}
            renderInput={params => {
              return (
                <div
                  ref={params.inputRef}
                  className={`${styles.wrapper} ${inputClassname}`}
                  onClick={() => setForcePickerOpen(true)}
                >
                  <span>{!value ? placeholder : value} </span>
                  <Icon.Calendar />
                </div>
              );
            }}
            {...rest}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

DatePickerComponent.propTypes = {
  className: PropTypes.string,
  inputClassname: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

DatePickerComponent.defaultProps = {
  className: "",
  inputClassname: "",
  placeholder: "XX/XX/XXXX",
  label: "",
  onChange: () => null,
  value: ""
};

export default DatePickerComponent;
