import { API, authorization, authorizationTenant } from "api/api";
import { get } from "api/base";

export const getAuditFiles = () => {
  return get(`${API}/audit-files/`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getAuditFileLabels = fileName => {
  return get(`${API}/controls/control-labels?audit_file=${fileName ?? ""}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getAuditFileAssets = ({ fileName, search }) => {
  return get(
    `${API}/controls/control-assets?page=1&audit_file=${fileName ?? ""}&search=${search ?? ""}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getAuditFileBaselines = ({ fileName, search }) => {
  return get(
    `${API}/controls/control-baselines?page=1&audit_file=${fileName ?? ""}&search=${search ?? ""}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getAuditFileHistory = fileName => {
  return get(`${API}/controls/control-scans?audit_file=${fileName ?? ""}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
