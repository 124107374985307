import React from "react";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { formatDate } from "helper/DateLib";
import renderCellExpand from "helper/renderCellExpand";
import { PRIORITYCOLOR, PriorityIcon, Prioritykeys } from "screens/Remediation/Tickets/helper";

const ticketsPreview = state => state.tickets?.ticketsPreview;

const tickets = state => state.tickets.tickets;
const ticket = state => state.tickets.ticket;
const vulnerabilityTickets = state => {
  return state.tickets?.vulnerabilityTickets;
};
const baselineTickets = state => {
  return state.tickets?.baselineTickets;
};
const ticketComments = state => state.tickets.ticketComments;
const ticketsRelatedTypes = state => state.tickets.ticketsRelatedTypes;
const ticketsConfig = state => state.tickets.ticketsConfig;

const statusList = state => state.tickets.statusList;

export const ticketsTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 5,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Project name",
    field: "project_name",
    resizable: true,
    minWidth: 200,
    maxWidth: 290,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Ticket configuration",
    field: "ticket_configuration",
    resizable: true,
    minWidth: 240,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Ticket Number",
    field: "desk_id",
    resizable: true,
    minWidth: 240,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Priority",
    field: "priority",
    resizable: true,
    minWidth: 110,
    maxWidth: 110,
    flex: 1,
    renderCell: params => (
      <div className="flex items-center">
        {PriorityIcon(Prioritykeys[params?.value], true)}
        <span>{Prioritykeys[params?.value]}</span>
      </div>
    ),
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Ticket title",
    field: "title",
    resizable: true,
    minWidth: 240,
    maxWidth: 240,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Assets",
    field: "assets",
    resizable: true,
    minWidth: 110,
    maxWidth: 110,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Comments",
    field: "comments",
    resizable: true,
    minWidth: 150,
    maxWidth: 150,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Status",
    field: "status",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Date opened",
    field: "created_at",
    resizable: true,
    minWidth: 160,
    maxWidth: 160,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Date closed",
    field: "closed_at",
    resizable: true,
    minWidth: 160,
    maxWidth: 160,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const getTicketsSelector = createDraftSafeSelector(
  tickets,

  state => {
    if (!state?.results?.length) {
      return { data: [], total: 0 };
    }

    const { results, count } = state;
    const data = results.reduce((acc, elem) => {
      return [
        ...acc,
        {
          id: elem.id,
          title: elem.title,
          project_name: elem.project_name,
          ticket_profile_type: elem.ticket_profile_type,
          ticket_configuration: elem?.ticket_configuration?.name,
          desk_id: elem?.desk_key,
          assets: elem.assets.length,
          status: elem.status,
          comments: elem.comments,
          priority: elem.priority,
          created_at: formatDate(elem.created_at, "YYYY-MM-DD HH:mm:ss"),
          closed_at: formatDate(elem.closed_at, "YYYY-MM-DD HH:mm:ss")
        }
      ];
    }, []);
    return { data, total: count };
  }
);

export const getTicketSelector = createDraftSafeSelector(ticket, state => {
  if (Object.getOwnPropertyNames(state).length === 0) {
    return {};
  }
  const { ticket_related_objects, baseline } = state;

  const related_objects = ticket_related_objects?.map(elem => {
    if (elem.ticket_related_type === "finding") {
      return {
        id: elem.related_object.id,
        name: elem.related_object.vulnerability.name,
        type: "finding"
      };
    }

    if (elem.ticket_related_type === "control") {
      return {
        id: baseline,
        name: elem.related_object.control,
        type: "control",
        data: elem.related_object
      };
    }

    if (elem.ticket_related_type === "data-list") {
      return {
        id: elem.related_object.id,
        name: elem.related_object.name,
        type: "data-list"
      };
    }

    return elem.related_object.control.name;
  });
  const res = {
    ...state,
    related_objects,
    project: state?.project_name,
    issue_type: state?.issue_type_name
  };
  return res;
});

export const getVulnerabilityTicketsSelector = createDraftSafeSelector(
  vulnerabilityTickets,
  state => {
    return state;
  }
);

export const getCommentsQuantitySelector = createDraftSafeSelector(vulnerabilityTickets, state => {
  let quantity = 0;
  state.forEach(item => {
    quantity += item.comments.length;
  });
  return quantity;
});

export const getVulnerabilityTicketSelector = createDraftSafeSelector(
  vulnerabilityTickets,
  (_, id) => id,
  (state, id) => {
    return state.find(t => t.id === id);
  }
);

export const getBaselineTicketsSelector = createDraftSafeSelector(baselineTickets, state => {
  return state;
});

export const getBaselineTicketSelector = createDraftSafeSelector(
  baselineTickets,
  (_, id) => id,
  (state, id) => state.find(t => t.id === id)
);

export const getTicketsPreviewSelector = createDraftSafeSelector(ticketsPreview, state => {
  return state;
});

export const ticketCommentsSelector = createDraftSafeSelector(ticketComments, state => {
  if (!state?.results?.length) {
    return [];
  }
  const res = state.results.reduce((acc, elem) => {
    return [
      ...acc,
      {
        data: `${formatDate(elem.created, "YYYY/DD/MM")} 
        ${elem?.user?.first_name || "third party user"} 
        ${elem?.user?.last_name || ""}`,
        comment: elem?.comment,
        id: elem?.id
      }
    ];
  }, []);
  return res;
});

export const ticketsRelatedTypesSelector = createDraftSafeSelector(ticketsRelatedTypes, state => {
  if (!state?.length) {
    return [];
  }

  const res = state.map(([value, label]) => {
    return {
      value,
      label
    };
  });
  return res;
});

export const statusListSelector = createDraftSafeSelector(statusList, state => {
  if (!state?.length) {
    return [];
  }

  const res = state.map(([value, label]) => {
    return {
      value,
      label
    };
  });
  return res;
});

export const ticketsConfigSelector = createDraftSafeSelector(ticketsConfig, state => {
  if (!state?.length) {
    return [];
  }

  const res = state.map(item => {
    return {
      value: item.id,
      label: item.name
    };
  });
  return res;
});
