export const GET_ALL_CLUSTERS_IN_WORKSPACE = [
  {
    ID: 1067,
    ClusterName: "accuknox-e2e-01",
    Location: "us-west4",
    last_updated_time: "10-10-2022 05:33:37 PST",
    WorkspaceID: 147
  }
];

export const CLUSTERS_IN_WORKSPACE_FILTER = [
  {
    ID: 1067,
    ClusterName: "accuknox-e2e-01",
    WorkspaceID: 147
  }
];

export const PODS_IN_NODE_FILTER = [
  {
    ID: 547914,
    PodName: "frontend-76d47b74bd-qcqq6",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547893,
    PodName: "ubuntu-2-deployment-6c7558cf49-xh7wm",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 0
  },
  {
    ID: 547884,
    PodName: "ubuntu-2-deployment-6c7558cf49-xh7wm",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 0
  },
  {
    ID: 547881,
    PodName: "ubuntu-2-deployment-6c7558cf49-xh7wm",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 0
  },
  {
    ID: 547878,
    PodName: "konnectivity-agent-69c59bd867-cnpr6",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547872,
    PodName: "konnectivity-agent-69c59bd867-cnpr6",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 0
  },
  {
    ID: 547866,
    PodName: "adservice-66b6b94b47-zpz2b",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547857,
    PodName: "kubearmor-annotation-manager-797c848b9c-rj24h",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 2
  },
  {
    ID: 547854,
    PodName: "tiller-deploy-7b9cbd46c9-dpqsp",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547845,
    PodName: "kubearmor-l5bkn",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547836,
    PodName: "pdcsi-node-qskrt",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 0
  },
  {
    ID: 547833,
    PodName: "deathstar-57c94d95b9-6ghbq",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547830,
    PodName: "kube-proxy-gke-accuknox-e2e-01-pool-1-69ef7dd5-xxhq",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547827,
    PodName: "kubearmor-host-policy-manager-66f599dcd6-pqt78",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 2
  },
  {
    ID: 547824,
    PodName: "pdcsi-node-7jcmn",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547821,
    PodName: "ubuntu-4-deployment-77fd9b8db-47b26",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547818,
    PodName: "hubble-relay-84c4796685-zbnpz",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547815,
    PodName: "kube-dns-758c7ff655-564t6",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 3
  },
  {
    ID: 547812,
    PodName: "emailservice-9994fb9cb-w8ll4",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547809,
    PodName: "kube-dns-758c7ff655-922qv",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 3
  },
  {
    ID: 547806,
    PodName: "cilium-j6vml",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547800,
    PodName: "cilium-2t6nr",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547794,
    PodName: "deathstar-57c94d95b9-mq8sh",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 0
  },
  {
    ID: 547791,
    PodName: "cilium-n4zvg",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547788,
    PodName: "pdcsi-node-pvprn",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 2
  },
  {
    ID: 547785,
    PodName: "shippingservice-7c746f999b-4z24m",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547782,
    PodName: "cilium-node-init-2q8c2",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547779,
    PodName: "redis-cart-87c657675-nwbdl",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547776,
    PodName: "paymentservice-7fbff68f98-nvndk",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547773,
    PodName: "ubuntu",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547770,
    PodName: "kube-dns-autoscaler-844c9d9448-mfnw8",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547767,
    PodName: "kubearmor-hjx57",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547764,
    PodName: "cilium-node-init-crbks",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547761,
    PodName: "tiefighter",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547758,
    PodName: "kube-proxy-gke-accuknox-e2e-01-pool-1-69ef7dd5-4138",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547755,
    PodName: "recommendationservice-6f7d6fbf97-qvm8s",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547752,
    PodName: "ubuntu-3-deployment-75fdb9d4b8-wggkv",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 0
  },
  {
    ID: 547749,
    PodName: "feeder-service-7d759bb844-47gcv",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 2
  },
  {
    ID: 547746,
    PodName: "productcatalogservice-859c5d89d8-h6g4p",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547743,
    PodName: "loadgenerator-6b5d89bd8b-jhvjz",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547740,
    PodName: "xwing",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547737,
    PodName: "cilium-node-init-2qd8k",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547728,
    PodName: "cartservice-948fbb7d7-9l967",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547725,
    PodName: "checkoutservice-76f5ff5cb7-bdg5l",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547719,
    PodName: "policy-enforcement-agent-6d85447c5d-bxbxm",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547716,
    PodName: "discovery-engine-67557d5bdc-27nvv",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547713,
    PodName: "kubearmor-policy-manager-57675fc7c7-zh4ts",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 2
  },
  {
    ID: 547710,
    PodName: "kube-proxy-gke-accuknox-e2e-01-pool-1-69ef7dd5-0534",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547707,
    PodName: "currencyservice-54fc49b9c9-xkvg8",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547704,
    PodName: "konnectivity-agent-69c59bd867-496wc",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547701,
    PodName: "konnectivity-agent-autoscaler-77cd5c655b-m8d7t",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547698,
    PodName: "elastic-operator-0",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547695,
    PodName: "kubearmor-relay-645667c695-xstb4",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547692,
    PodName: "ubuntu-1-deployment-77dd6c77c4-bm8jx",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547689,
    PodName: "shared-informer-agent-5c8fdf6748-k8pk5",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    ContainerCount: 1
  },
  {
    ID: 547686,
    PodName: "kubearmor-k8zxg",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547683,
    PodName: "konnectivity-agent-69c59bd867-qpq4n",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547680,
    PodName: "cilium-operator-7ff4c7474-692lz",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    ContainerCount: 1
  },
  {
    ID: 547677,
    PodName: "metrics-server-v0.4.5-fb4c49dd6-dfpgb",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 2
  },
  {
    ID: 547674,
    PodName: "ubuntu-5-deployment-5fdf8444c5-jkhqs",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  },
  {
    ID: 547668,
    PodName: "l7-default-backend-69fb9fd9f9-c52wq",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    ContainerCount: 1
  }
];

export const GET_CLUSTER_ENTITY_INFO = {
  cluster_id: 1067,
  NodeCount: 3,
  PodCount: 61,
  NamespaceCount: 28,
  LabelCount: 0,
  PolicyCount: 1,
  AlertsCount: 0
};

export const NODES_IN_CLUSTER = {
  total_record: 3,
  result: [
    {
      ID: 2511,
      NodeName: "gke-accuknox-e2e-01-pool-1-69ef7dd5-4138",
      PodCount: 17,
      ContainerCount: 30,
      NamespaceCount: 28,
      LabelCount: 18,
      PolicyCount: 0,
      AlertsCount: 0,
      Location: "us-west4",
      last_updated_time: "12-31-0000 16:07:02 PST",
      WorkspaceID: 147,
      ClusterID: 1067
    },
    {
      ID: 2287,
      NodeName: "gke-accuknox-e2e-01-pool-1-69ef7dd5-0534",
      PodCount: 29,
      ContainerCount: 6070,
      NamespaceCount: 28,
      LabelCount: 18,
      PolicyCount: 0,
      AlertsCount: 0,
      Location: "us-west4",
      last_updated_time: "12-31-0000 16:07:02 PST",
      WorkspaceID: 147,
      ClusterID: 1067
    },
    {
      ID: 2286,
      NodeName: "gke-accuknox-e2e-01-pool-1-69ef7dd5-xxhq",
      PodCount: 15,
      ContainerCount: 514,
      NamespaceCount: 28,
      LabelCount: 21,
      PolicyCount: 0,
      AlertsCount: 0,
      Location: "us-west4",
      last_updated_time: "12-31-0000 16:07:02 PST",
      WorkspaceID: 147,
      ClusterID: 1067
    }
  ]
};
export const GET_PODS = [
  {
    ID: 547914,
    podname: "frontend-76d47b74bd-qcqq6",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:59 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547893,
    podname: "ubuntu-2-deployment-6c7558cf49-xh7wm",
    namespace: "multiubuntu",
    namespace_id: 9621,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:14 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547884,
    podname: "ubuntu-2-deployment-6c7558cf49-xh7wm",
    namespace: "multiubuntu",
    namespace_id: 9621,
    Location: "us-west4",
    last_updated_time: "11-29-2022 05:30:25 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547881,
    podname: "ubuntu-2-deployment-6c7558cf49-xh7wm",
    namespace: "multiubuntu",
    namespace_id: 9621,
    Location: "us-west4",
    last_updated_time: "11-29-2022 05:29:41 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547878,
    podname: "konnectivity-agent-69c59bd867-cnpr6",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:59 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547872,
    podname: "konnectivity-agent-69c59bd867-cnpr6",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 05:29:38 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547866,
    podname: "adservice-66b6b94b47-zpz2b",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:44 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547857,
    podname: "kubearmor-annotation-manager-797c848b9c-rj24h",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:44 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547854,
    podname: "tiller-deploy-7b9cbd46c9-dpqsp",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:10 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547845,
    podname: "kubearmor-l5bkn",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:41 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547836,
    podname: "pdcsi-node-qskrt",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:56 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547833,
    podname: "deathstar-57c94d95b9-6ghbq",
    namespace: "default",
    namespace_id: 9636,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:42 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547830,
    podname: "kube-proxy-gke-accuknox-e2e-01-pool-1-69ef7dd5-xxhq",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:11 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547827,
    podname: "kubearmor-host-policy-manager-66f599dcd6-pqt78",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:11 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547824,
    podname: "pdcsi-node-7jcmn",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:10 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547821,
    podname: "ubuntu-4-deployment-77fd9b8db-47b26",
    namespace: "multiubuntu",
    namespace_id: 9621,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:53 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547818,
    podname: "hubble-relay-84c4796685-zbnpz",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:14:10 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547815,
    podname: "kube-dns-758c7ff655-564t6",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:59 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547812,
    podname: "emailservice-9994fb9cb-w8ll4",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:59 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547809,
    podname: "kube-dns-758c7ff655-922qv",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:12 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547806,
    podname: "cilium-j6vml",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:59 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547800,
    podname: "cilium-2t6nr",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:23 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547794,
    podname: "deathstar-57c94d95b9-mq8sh",
    namespace: "default",
    namespace_id: 9636,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:54 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547791,
    podname: "cilium-n4zvg",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:09 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547788,
    podname: "pdcsi-node-pvprn",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:22 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547785,
    podname: "shippingservice-7c746f999b-4z24m",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:59 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547782,
    podname: "cilium-node-init-2q8c2",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:41 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547779,
    podname: "redis-cart-87c657675-nwbdl",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:09 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547776,
    podname: "paymentservice-7fbff68f98-nvndk",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:59 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547773,
    podname: "ubuntu",
    namespace: "default",
    namespace_id: 9636,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:44 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547770,
    podname: "kube-dns-autoscaler-844c9d9448-mfnw8",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:56 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547767,
    podname: "kubearmor-hjx57",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:12 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547764,
    podname: "cilium-node-init-crbks",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:25 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547761,
    podname: "tiefighter",
    namespace: "default",
    namespace_id: 9636,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:44 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547758,
    podname: "kube-proxy-gke-accuknox-e2e-01-pool-1-69ef7dd5-4138",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:11 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547755,
    podname: "recommendationservice-6f7d6fbf97-qvm8s",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:22 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547752,
    podname: "ubuntu-3-deployment-75fdb9d4b8-wggkv",
    namespace: "multiubuntu",
    namespace_id: 9621,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:52 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547749,
    podname: "feeder-service-7d759bb844-47gcv",
    namespace: "accuknox-agents-feeder",
    namespace_id: 9654,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:53 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547746,
    podname: "productcatalogservice-859c5d89d8-h6g4p",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:10 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547743,
    podname: "loadgenerator-6b5d89bd8b-jhvjz",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:29 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547740,
    podname: "xwing",
    namespace: "default",
    namespace_id: 9636,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:44 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547737,
    podname: "cilium-node-init-2qd8k",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:24 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547728,
    podname: "cartservice-948fbb7d7-9l967",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:44 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547725,
    podname: "checkoutservice-76f5ff5cb7-bdg5l",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:43 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547719,
    podname: "policy-enforcement-agent-6d85447c5d-bxbxm",
    namespace: "accuknox-agents",
    namespace_id: 9633,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:44 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547716,
    podname: "discovery-engine-67557d5bdc-27nvv",
    namespace: "accuknox-agents",
    namespace_id: 9633,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:55 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547713,
    podname: "kubearmor-policy-manager-57675fc7c7-zh4ts",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:58 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547710,
    podname: "kube-proxy-gke-accuknox-e2e-01-pool-1-69ef7dd5-0534",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:44 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547707,
    podname: "currencyservice-54fc49b9c9-xkvg8",
    namespace: "ad-test",
    namespace_id: 9840,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:58 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547704,
    podname: "konnectivity-agent-69c59bd867-496wc",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:58 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547701,
    podname: "konnectivity-agent-autoscaler-77cd5c655b-m8d7t",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:13 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547698,
    podname: "elastic-operator-0",
    namespace: "default",
    namespace_id: 9636,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:13 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547695,
    podname: "kubearmor-relay-645667c695-xstb4",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:43 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547692,
    podname: "ubuntu-1-deployment-77dd6c77c4-bm8jx",
    namespace: "multiubuntu",
    namespace_id: 9621,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:14:14 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547689,
    podname: "shared-informer-agent-5c8fdf6748-k8pk5",
    namespace: "accuknox-agents",
    namespace_id: 9633,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:57 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2511,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547686,
    podname: "kubearmor-k8zxg",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:57 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547683,
    podname: "konnectivity-agent-69c59bd867-qpq4n",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:13:52 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547680,
    podname: "cilium-operator-7ff4c7474-692lz",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:43 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2286,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547677,
    podname: "metrics-server-v0.4.5-fb4c49dd6-dfpgb",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:57 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547674,
    podname: "ubuntu-5-deployment-5fdf8444c5-jkhqs",
    namespace: "multiubuntu",
    namespace_id: 9621,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:57 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  },
  {
    ID: 547668,
    podname: "l7-default-backend-69fb9fd9f9-c52wq",
    namespace: "kube-system",
    namespace_id: 9660,
    Location: "us-west4",
    last_updated_time: "11-29-2022 19:12:56 PST",
    WorkspaceID: 147,
    ClusterID: 1067,
    NodeID: 2287,
    workload_id: 0,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit"
  }
];
export const WORKLOADS = {
  result: [
    {
      id: 1,
      workload_name: "mysql"
    },
    {
      id: 2,
      workload_name: "pinot"
    },
    {
      id: 3,
      workload_name: "postgres"
    },
    {
      id: 4,
      workload_name: "kafka"
    },
    {
      id: 5,
      workload_name: "ngnix"
    },
    {
      id: 6,
      workload_name: "percona"
    },
    {
      id: 10,
      workload_name: "generic"
    },
    {
      id: 11,
      workload_name: "host"
    },
    {
      id: 12,
      workload_name: "cassandra"
    },
    {
      id: 13,
      workload_name: "wordpress"
    },
    {
      id: 14,
      workload_name: "django"
    },
    {
      id: 15,
      workload_name: "mongodb"
    },
    {
      id: 16,
      workload_name: "mariadb"
    },
    {
      id: 17,
      workload_name: "redis"
    },
    {
      id: 18,
      workload_name: "elasticsearch"
    },
    {
      id: 19,
      workload_name: "pulsar"
    },
    {
      id: 20,
      workload_name: "prometheus"
    },
    {
      id: 21,
      workload_name: "istio"
    },
    {
      id: 22,
      workload_name: "loki"
    },
    {
      id: 23,
      workload_name: "zookeeper"
    },
    {
      id: 24,
      workload_name: "python"
    },
    {
      id: 25,
      workload_name: "java"
    },
    {
      id: 26,
      workload_name: "golang"
    },
    {
      id: 27,
      workload_name: "kibana"
    },
    {
      id: 28,
      workload_name: "splunk"
    }
  ]
};

export const GET_POLICIES = {
  total_record: 187,
  list_of_policies: [
    {
      policy_id: 1577559,
      name: "autopol-egress-iyjyanwatngdlcg",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 13901534,
          name: "app",
          value: "policy-enforcement-agent",
          color: "#38571A"
        },
        {
          id: 12665457,
          name: "environment",
          value: "dev",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 16:01:20 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "accuknox-agents",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1577499,
      name: "autopol-ingress-iacesncubhamtij",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12803748,
          name: "app",
          value: "nc-pod",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 12:23:47 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "java-ms-poc",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1577496,
      name: "autopol-system-3328536263",
      type: "System",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12803748,
          name: "app",
          value: "nc-pod",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 12:23:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "java-ms-poc",
      policy_kind: "KubeArmorPolicy"
    },
    {
      policy_id: 1577493,
      name: "autopol-egress-fhntgwfnnskhqkm",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12803742,
          name: "app",
          value: "java-ms",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 12:23:45 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "java-ms-poc",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1577490,
      name: "autopol-ingress-xcvnpmqvnsbbtlx",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12803742,
          name: "app",
          value: "java-ms",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 12:23:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "java-ms-poc",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1577487,
      name: "autopol-system-2561717285",
      type: "System",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12803742,
          name: "app",
          value: "java-ms",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 12:23:43 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "java-ms-poc",
      policy_kind: "KubeArmorPolicy"
    },
    {
      policy_id: 1577484,
      name: "autopol-egress-ohnvwqzluncjrkq",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12668451,
          name: "class",
          value: "tiefighter",
          color: "#38571A"
        },
        {
          id: 12668454,
          name: "org",
          value: "empire",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 12:07:24 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "default",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1577481,
      name: "autopol-ingress-qxzvvjeqzpmcyns",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12668451,
          name: "class",
          value: "tiefighter",
          color: "#38571A"
        },
        {
          id: 12668454,
          name: "org",
          value: "empire",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 12:07:24 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "default",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1577076,
      name: "autopol-system-23250342",
      type: "System",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12665478,
          name: "node",
          value: "feeder-service",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 11:55:09 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "accuknox-agents",
      policy_kind: "KubeArmorPolicy"
    },
    {
      policy_id: 1577070,
      name: "autopol-egress-hpdmrvleolqnqir",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12665478,
          name: "node",
          value: "feeder-service",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 11:55:04 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "accuknox-agents",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1577061,
      name: "autopol-system-2319741521",
      type: "System",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12665478,
          name: "node",
          value: "feeder-service",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 11:54:55 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "accuknox-agents",
      policy_kind: "KubeArmorPolicy"
    },
    {
      policy_id: 1576518,
      name: "autopol-egress-xphcbjrxxulhyey",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12695005,
          name: "app",
          value: "shippingservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:47 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576521,
      name: "autopol-egress-fkmkomonphkllff",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12695019,
          name: "app",
          value: "adservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:47 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576524,
      name: "autopol-system-741400970",
      type: "System",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 13901636,
          name: "app",
          value: "ubuntu",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:47 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "default",
      policy_kind: "KubeArmorPolicy"
    },
    {
      policy_id: 1576527,
      name: "autopol-egress-brvmwjozlxamlku",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12665478,
          name: "node",
          value: "feeder-service",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:47 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "accuknox-agents-feeder",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576530,
      name: "autopol-ingress-nwoffyxhddkypmh",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12695019,
          name: "app",
          value: "adservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:47 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576488,
      name: "autopol-egress-qqwsawjhmbuowoc",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694942,
          name: "app",
          value: "emailservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576482,
      name: "autopol-egress-grticnayshwixjk",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 14020842,
          name: "app",
          value: "shared-informer-agent",
          color: "#38571A"
        },
        {
          id: 12665457,
          name: "environment",
          value: "dev",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "accuknox-agents",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576485,
      name: "autopol-egress-sniaqhchphqaguk",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694972,
          name: "app",
          value: "recommendationservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576512,
      name: "autopol-egress-tvjlszttloxbwad",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694986,
          name: "app",
          value: "paymentservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576506,
      name: "autopol-ingress-xvvtspjtkskvmqp",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694986,
          name: "app",
          value: "paymentservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576503,
      name: "autopol-egress-frelhecitkuvyjg",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694956,
          name: "app",
          value: "checkoutservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576515,
      name: "autopol-ingress-pavqpbyyzmhvugv",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694956,
          name: "app",
          value: "checkoutservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576491,
      name: "autopol-ingress-gwjsosvnamxvurz",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12678339,
          name: "control-plane",
          value: "elastic-operator",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "default",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576509,
      name: "autopol-egress-guruazaeclhxdzs",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694990,
          name: "app",
          value: "productcatalogservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576494,
      name: "autopol-egress-tkmchbsxuywfgzn",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12678339,
          name: "control-plane",
          value: "elastic-operator",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "default",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576497,
      name: "autopol-ingress-cqsykiddbuefytg",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694996,
          name: "app",
          value: "cartservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576500,
      name: "autopol-ingress-absaewpqphuzwtq",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694942,
          name: "app",
          value: "emailservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:46 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576473,
      name: "autopol-ingress-jfuzmsnwdwrzbyc",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12768255,
          name: "app",
          value: "wordpress",
          color: "#38571A"
        },
        {
          id: 13900401,
          name: "tier",
          value: "frontend",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:45 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "wordpress-mysql",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576479,
      name: "autopol-egress-tfnqapqpfdpgwgp",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12768255,
          name: "app",
          value: "wordpress",
          color: "#38571A"
        },
        {
          id: 13900407,
          name: "tier",
          value: "mysql",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:45 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "wordpress-mysql",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576476,
      name: "autopol-egress-wysugnzkkcpxnis",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12768255,
          name: "app",
          value: "wordpress",
          color: "#38571A"
        },
        {
          id: 13900401,
          name: "tier",
          value: "frontend",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:45 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "wordpress-mysql",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576437,
      name: "autopol-system-1569772727",
      type: "System",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12665463,
          name: "container",
          value: "ubuntu-4",
          color: "#38571A"
        },
        {
          id: 12665466,
          name: "group",
          value: "group-2",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "multiubuntu",
      policy_kind: "KubeArmorPolicy"
    },
    {
      policy_id: 1576458,
      name: "autopol-egress-ulmjcobdaepgczw",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12695013,
          name: "app",
          value: "redis-cart",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576470,
      name: "autopol-ingress-qndajtyabolxnzk",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12768255,
          name: "app",
          value: "wordpress",
          color: "#38571A"
        },
        {
          id: 13900407,
          name: "tier",
          value: "mysql",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "wordpress-mysql",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576455,
      name: "autopol-egress-fnmlewtjmvoejdd",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694999,
          name: "app",
          value: "currencyservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576449,
      name: "autopol-ingress-epxbnbxajyieyuv",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694953,
          name: "app",
          value: "frontend",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576452,
      name: "autopol-ingress-tztieyaqtvagwzt",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694972,
          name: "app",
          value: "recommendationservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576440,
      name: "autopol-egress-rzajbvjqiuukmdr",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 13901534,
          name: "app",
          value: "policy-enforcement-agent",
          color: "#38571A"
        },
        {
          id: 12665457,
          name: "environment",
          value: "dev",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "accuknox-agents",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576443,
      name: "autopol-egress-qgzbljdqhghyyol",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12665478,
          name: "node",
          value: "feeder-service",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "accuknox-agents-feeder",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576446,
      name: "autopol-ingress-glmtgxywjfrrsok",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694999,
          name: "app",
          value: "currencyservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576464,
      name: "autopol-egress-jocxqisofziauqf",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694953,
          name: "app",
          value: "frontend",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576467,
      name: "autopol-egress-qidmbyzxwekchbb",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12695013,
          name: "app",
          value: "redis-cart",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576461,
      name: "autopol-egress-lqqkmhfnarpjsdv",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694996,
          name: "app",
          value: "cartservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:44 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576425,
      name: "autopol-ingress-jbuthjfpuxgcors",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12768255,
          name: "app",
          value: "wordpress",
          color: "#38571A"
        },
        {
          id: 13900401,
          name: "tier",
          value: "frontend",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:43 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "wordpress-mysql",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576431,
      name: "autopol-egress-wqgefyfcnevfpak",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694996,
          name: "app",
          value: "cartservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:43 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576434,
      name: "autopol-ingress-qavfjdqbzkdlhhy",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12695019,
          name: "app",
          value: "adservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:43 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576428,
      name: "autopol-egress-gbhymxlddicxiqb",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12768255,
          name: "app",
          value: "wordpress",
          color: "#38571A"
        },
        {
          id: 13900407,
          name: "tier",
          value: "mysql",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:43 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "wordpress-mysql",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576422,
      name: "autopol-egress-zeephsdtddliani",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12768255,
          name: "app",
          value: "wordpress",
          color: "#38571A"
        },
        {
          id: 13900401,
          name: "tier",
          value: "frontend",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:43 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "wordpress-mysql",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576398,
      name: "autopol-egress-mbnshphbovfbylx",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694956,
          name: "app",
          value: "checkoutservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:42 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    },
    {
      policy_id: 1576401,
      name: "autopol-ingress-gapasdijfjxwbss",
      type: "Network",
      category: "Un-used",
      label_type: "Default",
      labels: [
        {
          id: 12694986,
          name: "app",
          value: "paymentservice",
          color: "#38571A"
        }
      ],
      updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
      updated_at: "11-29-2022 10:35:42 PST",
      cluster_name: "accuknox-e2e-01",
      namespace_name: "ad-test",
      policy_kind: "NetworkPolicy"
    }
  ]
};

export const GET_ALL_POLICIES = {
  total_record: 2,
  list_of_policies: [
    {
      policy_id: 1577547,
      name: "vd-k8s-policy-1",
      description: "validations",
      version: 1,
      type: "System",
      label_type: "Default",
      labels: [
        {
          id: 14020848,
          name: "beta.kubernetes.io/arch",
          value: "amd64",
          color: ""
        }
      ],
      tags: [
        {
          id: 14020854,
          name: "HIPAA"
        }
      ],
      updated_by: "Rooban K",
      updated_at: "11-29-2022 13:29:02 PST",
      status: "Pending",
      cluster_id: 1067,
      cluster_name: "accuknox-e2e-01",
      namespace_id: 9600,
      namespace_name: "nginx-ingress",
      policy_kind: "KubeArmorHostPolicy"
    },
    {
      policy_id: 1577544,
      name: "policy-test-nov-001",
      description: "validations",
      version: 1,
      type: "System",
      label_type: "Default",
      labels: [
        {
          id: 12661887,
          name: "beta.kubernetes.io/arch",
          value: "amd64",
          color: "#8A008A"
        }
      ],
      updated_by: "Rooban K",
      updated_at: "11-29-2022 13:23:59 PST",
      status: "Pending",
      cluster_id: 1067,
      cluster_name: "accuknox-e2e-01",
      namespace_id: 9597,
      namespace_name: "kube-public",
      policy_kind: "KubeArmorPolicy"
    }
  ],
  active_record: 0,
  inactive_record: 0,
  pending_record: 2
};

export const GET_ALL_GROUPS = {
  message: "Success",
  total_records: 15,
  result: [
    {
      ID: 68,
      Group_Name: "redhatdefault",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2021-09-24T04:32:57Z"
    },
    {
      ID: 222,
      Group_Name: "default",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2021-10-21T05:39:19Z"
    },
    {
      ID: 301,
      Group_Name: "ubuntu",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2021-09-24T04:32:57Z"
    },
    {
      ID: 302,
      Group_Name: "redis",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2021-10-21T05:39:19Z"
    },
    {
      ID: 303,
      Group_Name: "mint",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2021-10-21T05:39:19Z"
    },
    {
      ID: 304,
      Group_Name: "test-vm-kloud",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2021-10-21T05:39:19Z"
    },
    {
      ID: 305,
      Group_Name: "test-vm-kl",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2021-10-21T05:39:19Z"
    },
    {
      ID: 306,
      Group_Name: "test-vm-plm",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2021-10-21T05:39:19Z"
    },
    {
      ID: 324,
      Group_Name: "testgroup1",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2021-11-23T07:41:18Z"
    },
    {
      ID: 420,
      Group_Name: "datapipeline-test-vm",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2022-01-21T11:11:19Z"
    },
    {
      ID: 453,
      Group_Name: "vm-process",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2022-01-28T06:53:36Z"
    },
    {
      ID: 606,
      Group_Name: "qwerty",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2022-02-15T04:59:32Z"
    },
    {
      ID: 663,
      Group_Name: "demovmonboarding",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2022-02-21T11:40:12Z"
    },
    {
      ID: 699,
      Group_Name: "new-test5",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2022-02-22T09:35:38Z"
    },
    {
      ID: 702,
      Group_Name: "test-vm",
      Workspace_Id: 147,
      Location: "",
      Cloud_Provider: "",
      Project_Id: "",
      Status: true,
      Updated_At: "2022-02-22T09:53:45Z"
    }
  ]
};

export const GET_INSTANCE_GROUP_ENTITY_INFO = {
  result: {
    GroupID: 303,
    Vmcount: 4,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12745563,
        name: "vmlabel12",
        value: "kk",
        color: "#004D6"
      },
      {
        label_id: 12846543,
        name: "nucleci",
        value: "",
        color: ""
      },
      {
        label_id: 12846546,
        name: "cve-2021-39327",
        value: "",
        color: ""
      },
      {
        label_id: 12661890,
        name: "beta.kubernetes.io/instance-type",
        value: "e2-standard-4",
        color: "#8A008A"
      },
      {
        label_id: 12768345,
        name: "akhil",
        value: "testing-label",
        color: "#004D65"
      },
      {
        label_id: 12922419,
        name: "entity",
        value: "test",
        color: "#004D65"
      },
      {
        label_id: 12846528,
        name: "wordpress",
        value: "",
        color: ""
      },
      {
        label_id: 12735382,
        name: "it",
        value: "me",
        color: "#8A008A"
      },
      {
        label_id: 13908804,
        name: "Test-Policy",
        value: "Policy-Editor",
        color: "#004D65"
      }
    ]
  }
};

export const CLUSTER_GRAPH_DETAILS = {
  cluster_id: 1413,
  NodeCount: 2,
  PodCount: 64,
  NamespaceCount: 11,
  LabelCount: 0,
  PolicyCount: 0,
  AlertsCount: 0
};

export const TAB = [
  { id: 1, label: "Network", disabled: false },
  { id: 2, label: "System", disabled: false },
  { id: 2, label: "Auto Discovered", disabled: false }
];

export const COLUMNS = [
  { field: "id", headerName: "ID" },
  { field: "title", headerName: "Title", width: 300 },
  { field: "body", headerName: "Body", width: 600 }
];

export const AUTO_DISCOVERED_POLICY = [
  {
    id: 0,
    policy_id: 1642974,
    name: "autopol-egress-bgfsaxaarqcmyvz",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024375,
        name: "app",
        value: "cartservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:35 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy"
  },
  {
    policy_id: 1642968,
    name: "autopol-egress-rkazxnwgkjeulcf",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024330,
        name: "app",
        value: "currencyservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:34 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 1
  },
  {
    policy_id: 1642971,
    name: "autopol-egress-cbpxvrhsrqcuxft",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024357,
        name: "app",
        value: "emailservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:34 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 2
  },
  {
    policy_id: 1642962,
    name: "autopol-egress-mnhcglijyngzheo",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024375,
        name: "app",
        value: "cartservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:33 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 3
  },
  {
    policy_id: 1642959,
    name: "autopol-egress-hceldjbflbssqzj",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024312,
        name: "app",
        value: "checkoutservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:33 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 4
  },
  {
    policy_id: 1642956,
    name: "autopol-egress-pewjabkhgmqpgfz",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022609,
        name: "app",
        value: "dvwa-web",
        color: "#38571A"
      },
      {
        id: 14022615,
        name: "tier",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:33 PST",
    cluster_name: "rk-test",
    namespace_name: "dvwa",
    policy_kind: "NetworkPolicy",
    id: 5
  },
  {
    policy_id: 1642953,
    name: "autopol-egress-yfytkhmsectpvhm",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022609,
        name: "app",
        value: "dvwa-web",
        color: "#38571A"
      },
      {
        id: 14022615,
        name: "tier",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:32 PST",
    cluster_name: "rk-test",
    namespace_name: "dvwa",
    policy_kind: "NetworkPolicy",
    id: 6
  },
  {
    policy_id: 1642950,
    name: "autopol-egress-akhdtgnwryoxroj",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024321,
        name: "app",
        value: "redis-cart",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:32 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 7
  },
  {
    policy_id: 1642947,
    name: "autopol-egress-ltcshkpfopgjzsy",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024369,
        name: "app",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:31 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 8
  },
  {
    policy_id: 1642944,
    name: "autopol-egress-hnkvqqapvrwrhai",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024321,
        name: "app",
        value: "redis-cart",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:31 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 9
  },
  {
    policy_id: 1642935,
    name: "autopol-egress-kcmjiwnoecsujtc",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022687,
        name: "node",
        value: "feeder-service",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:30 PST",
    cluster_name: "rk-test",
    namespace_name: "accuknox-agents",
    policy_kind: "NetworkPolicy",
    id: 10
  },
  {
    policy_id: 1642932,
    name: "autopol-system-1037270987",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024321,
        name: "app",
        value: "redis-cart",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:30 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 11
  },
  {
    policy_id: 1642929,
    name: "autopol-system-1943863861",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14023217,
        name: "container",
        value: "ubuntu-5",
        color: "#38571A"
      },
      {
        id: 14023220,
        name: "group",
        value: "group-2",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:29 PST",
    cluster_name: "rk-test",
    namespace_name: "multiubuntu",
    policy_kind: "KubeArmorPolicy",
    id: 12
  },
  {
    policy_id: 1642926,
    name: "autopol-system-741400970",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14023130,
        name: "app",
        value: "ubuntu",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-09-2022 07:53:29 PST",
    cluster_name: "rk-test",
    namespace_name: "default",
    policy_kind: "KubeArmorPolicy",
    id: 13
  },
  {
    policy_id: 1639902,
    name: "autopol-system-1377914592",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14023142,
        name: "app",
        value: "azure-vote-front",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 12:26:13 PST",
    cluster_name: "rk-test",
    namespace_name: "default",
    policy_kind: "KubeArmorPolicy",
    id: 14
  },
  {
    policy_id: 1638666,
    name: "autopol-egress-luvdujaycmcsfhe",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14023142,
        name: "app",
        value: "azure-vote-front",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:59:08 PST",
    cluster_name: "rk-test",
    namespace_name: "default",
    policy_kind: "NetworkPolicy",
    id: 15
  },
  {
    policy_id: 1638663,
    name: "autopol-egress-edwexdekdukjkdf",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14023142,
        name: "app",
        value: "azure-vote-front",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:59:08 PST",
    cluster_name: "rk-test",
    namespace_name: "default",
    policy_kind: "NetworkPolicy",
    id: 16
  },
  {
    policy_id: 1638282,
    name: "autopol-system-1804736057",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022609,
        name: "app",
        value: "dvwa-web",
        color: "#38571A"
      },
      {
        id: 14022615,
        name: "tier",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:51:17 PST",
    cluster_name: "rk-test",
    namespace_name: "dvwa",
    policy_kind: "KubeArmorPolicy",
    id: 17
  },
  {
    policy_id: 1636644,
    name: "autopol-system-2810200270",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14023250,
        name: "container",
        value: "ubuntu-3",
        color: "#38571A"
      },
      {
        id: 14023238,
        name: "group",
        value: "group-1",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:18:31 PST",
    cluster_name: "rk-test",
    namespace_name: "multiubuntu",
    policy_kind: "KubeArmorPolicy",
    id: 18
  },
  {
    policy_id: 1636587,
    name: "autopol-egress-zzlmauuhwgrzlra",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022687,
        name: "node",
        value: "feeder-service",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:30 PST",
    cluster_name: "rk-test",
    namespace_name: "accuknox-agents",
    policy_kind: "NetworkPolicy",
    id: 19
  },
  {
    policy_id: 1636584,
    name: "autopol-egress-ojvtlwuaxgxfhss",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022750,
        name: "app",
        value: "shared-informer-agent",
        color: "#38571A"
      },
      {
        id: 14022639,
        name: "environment",
        value: "dev",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:29 PST",
    cluster_name: "rk-test",
    namespace_name: "accuknox-agents",
    policy_kind: "NetworkPolicy",
    id: 20
  },
  {
    policy_id: 1636569,
    name: "autopol-egress-nhugxhgoluxecmk",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024330,
        name: "app",
        value: "currencyservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:28 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 21
  },
  {
    policy_id: 1636575,
    name: "autopol-egress-dlqadvqxmtaewnq",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024357,
        name: "app",
        value: "emailservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:28 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 22
  },
  {
    policy_id: 1636572,
    name: "autopol-egress-oeltivsmbbvghph",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024342,
        name: "app",
        value: "shippingservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:28 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 23
  },
  {
    policy_id: 1636560,
    name: "autopol-egress-yjyqrpfsvkkszqp",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024375,
        name: "app",
        value: "cartservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:27 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 24
  },
  {
    policy_id: 1636563,
    name: "autopol-egress-ctkomvpqqytwlsb",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024375,
        name: "app",
        value: "cartservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:27 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 25
  },
  {
    policy_id: 1636566,
    name: "autopol-egress-qfctsbxbjnpzpqs",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024387,
        name: "app",
        value: "productcatalogservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:27 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 26
  },
  {
    policy_id: 1636557,
    name: "autopol-egress-duoqkjqogspbsug",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024312,
        name: "app",
        value: "checkoutservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:26 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 27
  },
  {
    policy_id: 1636548,
    name: "autopol-egress-kuboknkgblisepd",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024342,
        name: "app",
        value: "shippingservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:19 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 28
  },
  {
    policy_id: 1636551,
    name: "autopol-egress-exhvgeyjhlvyvpn",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024357,
        name: "app",
        value: "emailservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:19 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 29
  },
  {
    policy_id: 1636539,
    name: "autopol-egress-hjqtvefkpkwgtwz",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024321,
        name: "app",
        value: "redis-cart",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:18 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 30
  },
  {
    policy_id: 1636542,
    name: "autopol-egress-vlpnwlgnqmuqkla",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024387,
        name: "app",
        value: "productcatalogservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:18 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 31
  },
  {
    policy_id: 1636545,
    name: "autopol-egress-obpagomfwbybzho",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024330,
        name: "app",
        value: "currencyservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:18 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 32
  },
  {
    policy_id: 1636530,
    name: "autopol-egress-umianrcffirbkia",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022609,
        name: "app",
        value: "dvwa-web",
        color: "#38571A"
      },
      {
        id: 14022615,
        name: "tier",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:17 PST",
    cluster_name: "rk-test",
    namespace_name: "dvwa",
    policy_kind: "NetworkPolicy",
    id: 33
  },
  {
    policy_id: 1636536,
    name: "autopol-egress-oxflbxrhnwpfdbc",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024321,
        name: "app",
        value: "redis-cart",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:17 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 34
  },
  {
    policy_id: 1636533,
    name: "autopol-egress-iwgtujzeqssmxqw",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024369,
        name: "app",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:17 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "NetworkPolicy",
    id: 35
  },
  {
    policy_id: 1636527,
    name: "autopol-egress-ueriabpqgmsnipu",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022609,
        name: "app",
        value: "dvwa-web",
        color: "#38571A"
      },
      {
        id: 14022615,
        name: "tier",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:16 PST",
    cluster_name: "rk-test",
    namespace_name: "dvwa",
    policy_kind: "NetworkPolicy",
    id: 36
  },
  {
    policy_id: 1636521,
    name: "autopol-system-527556505",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024306,
        name: "app",
        value: "adservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:15 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 37
  },
  {
    policy_id: 1636518,
    name: "autopol-system-1132830462",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024387,
        name: "app",
        value: "productcatalogservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:12 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 38
  },
  {
    policy_id: 1636512,
    name: "autopol-system-2721996775",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024369,
        name: "app",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:11 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 39
  },
  {
    policy_id: 1636515,
    name: "autopol-system-1009997439",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024363,
        name: "app",
        value: "recommendationservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:11 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 40
  },
  {
    policy_id: 1636509,
    name: "autopol-system-3537432870",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024357,
        name: "app",
        value: "emailservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:11 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 41
  },
  {
    policy_id: 1636506,
    name: "autopol-system-3070520076",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024351,
        name: "app",
        value: "paymentservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:09 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 42
  },
  {
    policy_id: 1636500,
    name: "autopol-system-1681231530",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024375,
        name: "app",
        value: "cartservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:08 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 43
  },
  {
    policy_id: 1636503,
    name: "autopol-system-3562225227",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024330,
        name: "app",
        value: "currencyservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:08 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 44
  },
  {
    policy_id: 1636497,
    name: "autopol-system-3944212596",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024312,
        name: "app",
        value: "checkoutservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:08 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 45
  },
  {
    policy_id: 1636494,
    name: "autopol-system-569793842",
    type: "System",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14024342,
        name: "app",
        value: "shippingservice",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 11:17:06 PST",
    cluster_name: "rk-test",
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy",
    id: 46
  },
  {
    policy_id: 1621524,
    name: "autopol-egress-sadhkksgatxvlyr",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022609,
        name: "app",
        value: "dvwa-web",
        color: "#38571A"
      },
      {
        id: 14022615,
        name: "tier",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 04:59:54 PST",
    cluster_name: "rk-test",
    namespace_name: "dvwa",
    policy_kind: "NetworkPolicy",
    id: 47
  },
  {
    policy_id: 1621521,
    name: "autopol-ingress-bgnsurtmqotfkci",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022609,
        name: "app",
        value: "dvwa-web",
        color: "#38571A"
      },
      {
        id: 14022615,
        name: "tier",
        value: "frontend",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 04:59:54 PST",
    cluster_name: "rk-test",
    namespace_name: "dvwa",
    policy_kind: "NetworkPolicy",
    id: 48
  },
  {
    policy_id: 1621518,
    name: "autopol-egress-dwcjybtsqfvnwue",
    type: "Network",
    category: "Un-used",
    label_type: "Default",
    labels: [
      {
        id: 14022750,
        name: "app",
        value: "shared-informer-agent",
        color: "#38571A"
      },
      {
        id: 14022639,
        name: "environment",
        value: "dev",
        color: "#38571A"
      }
    ],
    updated_by: "42a6b134-9c96-4a84-81da-cb7f19fb9ae8",
    updated_at: "12-08-2022 04:59:53 PST",
    cluster_name: "rk-test",
    namespace_name: "accuknox-agents",
    policy_kind: "NetworkPolicy",
    id: 49
  }
];

export const OPTIONS = [
  {
    value: 1444,
    label: "All Clusters"
  },
  {
    value: 1472,
    label: "validation-testing"
  },
  {
    value: 1413,
    label: "rk-test"
  }
];

export const MOCK_ALL_POLICIES = [
  {
    id: 1,
    policy_id: 1639902,
    name: "autopol-system-1377914592",
    description: "Auto Discovered Policy",
    version: 1,
    type: "System",
    label_type: "Default",
    labels: [
      {
        id: 14023142,
        name: "app",
        value: "azure-vote-front",
        color: "#38571A"
      }
    ],
    updated_by: "30d4caa0-0c48-4d2c-874d-0edb1a950a43",
    updated_at: "12-12-2022 06:53:08 PST",
    status: "Pending",
    cluster_id: 1413,
    cluster_name: "rk-test",
    namespace_id: 15894,
    namespace_name: "default",
    policy_kind: "KubeArmorPolicy"
  },
  {
    id: 2,
    policy_id: 1744652,
    name: "autopol-system-1037270987",
    description: "Auto Discovered Policy",
    version: 1,
    type: "System",
    label_type: "Default",
    labels: [
      {
        id: 14024321,
        name: "app",
        value: "redis-cart",
        color: "#38571A"
      }
    ],
    updated_by: "30d4caa0-0c48-4d2c-874d-0edb1a950a43",
    updated_at: "12-12-2022 05:57:12 PST",
    status: "Pending",
    cluster_id: 1413,
    cluster_name: "rk-test",
    namespace_id: 18800,
    namespace_name: "gms",
    policy_kind: "KubeArmorPolicy"
  },
  {
    id: 3,
    policy_id: 1744651,
    name: "autopol-system-1943863861",
    description: "Auto Discovered Policy",
    version: 1,
    type: "System",
    label_type: "Default",
    labels: [
      {
        id: 14023217,
        name: "container",
        value: "ubuntu-5",
        color: "#38571A"
      },
      {
        id: 14023220,
        name: "group",
        value: "group-2",
        color: "#38571A"
      }
    ],
    updated_by: "336872b9-c949-48f5-b858-c97f81aec690",
    updated_at: "12-12-2022 05:37:22 PST",
    status: "Pending",
    cluster_id: 1413,
    cluster_name: "rk-test",
    namespace_id: 16001,
    namespace_name: "multiubuntu",
    policy_kind: "KubeArmorPolicy"
  },
  {
    id: 4,
    policy_id: 1647752,
    name: "autopol-system-23250342",
    description: "Auto Discovered Policy",
    version: 1,
    type: "System",
    label_type: "Default",
    labels: [
      {
        id: 14022687,
        name: "node",
        value: "feeder-service",
        color: "#38571A"
      }
    ],
    updated_by: "30d4caa0-0c48-4d2c-874d-0edb1a950a43",
    updated_at: "12-12-2022 05:12:53 PST",
    status: "Active",
    cluster_id: 1413,
    cluster_name: "rk-test",
    namespace_id: 15897,
    namespace_name: "accuknox-agents",
    policy_kind: "KubeArmorPolicy"
  }
];

export const CLUSTERS_DATA = [
  {
    AlertsCount: 0,
    ClusterName: "rk-test",
    ID: 1413,
    LabelCount: 0,
    Location: "us-west2",
    NamespaceCount: 11,
    NodeCount: 2,
    PodCount: 64,
    PolicyCount: 5,
    WorkspaceID: 846,
    cluster_id: 1413,
    cluster_name: "rk-test",
    create_time: "12-02-2022 03:55:52 PST",
    entity_type: "cluster",
    id: 1413,
    labels: 0,
    last_updated_time: "12-02-2022 03:55:52 PST",
    name: "rk-test",
    namespaces: 11,
    no_of_alerts: 0,
    number_of_nodes: 2,
    number_of_pods: 64,
    policies: 5,
    zone: "us-west2",
    traffic: "View Traffic"
  }
];
export const CONTAINER_DATA = [
  {
    ClusterID: 1413,
    ID: 526004,
    NodeID: 2703,
    PodID: 565577,
    WorkspaceID: 846,
    container_id: "containerd://bd9065eb46da01ae3cb2757726b33afafa551350a4510c55f8865790ae23eeac",
    container_name: "server",
    name: "server",
    image: "docker.io/knoxuser/productcatalogservice:latest",
    last_updated_time: "12-06-2022 20:55:21 PST",
    name_of_services: "default",
    namespace: "gms",
    node_name: "aks-agentpool-12366169-vmss000000",
    pod_name: "productcatalogservice-9fb7986cc-xxbvd",
    protocol_port: "3550",
    status: "running",
    id: 526004
  }
];
export const PODS_DATA = [
  {
    ID: 565577,
    podname: "productcatalogservice-9fb7986cc-xxbvd",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:09 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565577,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 00:59:09 PST",
    name: "productcatalogservice-9fb7986cc-xxbvd",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565574,
    podname: "loadgenerator-7969674f5b-hns6h",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:58:47 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565574,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 00:58:47 PST",
    name: "loadgenerator-7969674f5b-hns6h",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565568,
    podname: "cartservice-8d8f496b9-cwpjb",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:57:12 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565568,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 00:57:12 PST",
    name: "cartservice-8d8f496b9-cwpjb",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565565,
    podname: "frontend-674dc7dd97-rtgnb",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:57:28 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2700,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565565,
    zone: "us-west2",
    nodeId: 2700,
    create_time: "12-07-2022 00:57:28 PST",
    name: "frontend-674dc7dd97-rtgnb",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565562,
    podname: "recommendationservice-7df8dd9ddf-9llpq",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:57:22 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2700,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565562,
    zone: "us-west2",
    nodeId: 2700,
    create_time: "12-07-2022 00:57:22 PST",
    name: "recommendationservice-7df8dd9ddf-9llpq",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565559,
    podname: "emailservice-dfbf54759-ckbds",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:57:08 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565559,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 00:57:08 PST",
    name: "emailservice-dfbf54759-ckbds",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565556,
    podname: "paymentservice-5b6dd84d77-2ml2s",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:57:06 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2700,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565556,
    zone: "us-west2",
    nodeId: 2700,
    create_time: "12-07-2022 00:57:06 PST",
    name: "paymentservice-5b6dd84d77-2ml2s",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565550,
    podname: "shippingservice-587488b4c9-vmrc8",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 01:00:07 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565550,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 01:00:07 PST",
    name: "shippingservice-587488b4c9-vmrc8",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565535,
    podname: "currencyservice-569b65587f-f7hlj",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:54 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565535,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 00:59:54 PST",
    name: "currencyservice-569b65587f-f7hlj",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565529,
    podname: "redis-cart-79bf75fd45-np2fp",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:49 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 17,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "redis",
    clusterId: 1413,
    id: 565529,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 00:59:49 PST",
    name: "redis-cart-79bf75fd45-np2fp",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565511,
    podname: "checkoutservice-66fc8bff7d-nhkwq",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:27 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565511,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 00:59:27 PST",
    name: "checkoutservice-66fc8bff7d-nhkwq",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 565508,
    podname: "adservice-6d59878665-xd2m8",
    namespace: "gms",
    namespace_id: 18800,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:17 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 565508,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 00:59:17 PST",
    name: "adservice-6d59878665-xd2m8",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 562967,
    podname: "ubuntu-4-deployment-868dd7ddb-82ds5",
    namespace: "multiubuntu",
    namespace_id: 16001,
    Location: "us-west2",
    last_updated_time: "12-07-2022 01:00:09 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 562967,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 01:00:09 PST",
    name: "ubuntu-4-deployment-868dd7ddb-82ds5",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 3,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 3,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 562964,
    podname: "ubuntu-3-deployment-59445d6fb7-8n9lc",
    namespace: "multiubuntu",
    namespace_id: 16001,
    Location: "us-west2",
    last_updated_time: "12-07-2022 01:00:08 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2700,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 562964,
    zone: "us-west2",
    nodeId: 2700,
    create_time: "12-07-2022 01:00:08 PST",
    name: "ubuntu-3-deployment-59445d6fb7-8n9lc",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 3,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 3,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 562961,
    podname: "ubuntu-1-deployment-5bd4dff469-fqk9b",
    namespace: "multiubuntu",
    namespace_id: 16001,
    Location: "us-west2",
    last_updated_time: "12-07-2022 01:00:01 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 562961,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 01:00:01 PST",
    name: "ubuntu-1-deployment-5bd4dff469-fqk9b",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 3,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 3,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 562958,
    podname: "ubuntu-2-deployment-55c894ddb5-swm97",
    namespace: "multiubuntu",
    namespace_id: 16001,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:39 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2700,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 562958,
    zone: "us-west2",
    nodeId: 2700,
    create_time: "12-07-2022 00:59:39 PST",
    name: "ubuntu-2-deployment-55c894ddb5-swm97",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 3,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 3,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 562955,
    podname: "deathstar-6c94dcc57b-ghgmz",
    namespace: "default",
    namespace_id: 15894,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:07 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2700,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 562955,
    zone: "us-west2",
    nodeId: 2700,
    create_time: "12-07-2022 00:59:07 PST",
    name: "deathstar-6c94dcc57b-ghgmz",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 4,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 4,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 562952,
    podname: "cilium-node-init-cfb85",
    namespace: "kube-system",
    namespace_id: 15927,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:05 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2703,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 562952,
    zone: "us-west2",
    nodeId: 2703,
    create_time: "12-07-2022 00:59:05 PST",
    name: "cilium-node-init-cfb85",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 3,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 3,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 562949,
    podname: "kubearmor-relay-5656cc5bf7-mf8v4",
    namespace: "kube-system",
    namespace_id: 15927,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:04 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2700,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 562949,
    zone: "us-west2",
    nodeId: 2700,
    create_time: "12-07-2022 00:59:04 PST",
    name: "kubearmor-relay-5656cc5bf7-mf8v4",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 2,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 2,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  },
  {
    ID: 562946,
    podname: "cilium-node-init-v8ddd",
    namespace: "kube-system",
    namespace_id: 15927,
    Location: "us-west2",
    last_updated_time: "12-07-2022 00:59:02 PST",
    WorkspaceID: 846,
    ClusterID: 1413,
    NodeID: 2700,
    workload_id: 10,
    kubearmor_file_posture: "",
    kubearmor_network_posture: "",
    default_posture: "Audit",
    workload_name: "generic",
    clusterId: 1413,
    id: 562946,
    zone: "us-west2",
    nodeId: 2700,
    create_time: "12-07-2022 00:59:02 PST",
    name: "cilium-node-init-v8ddd",
    entity_type: "Pod",
    ContainerCount: 1,
    LabelCount: 3,
    PolicyCount: 0,
    AlertsCount: 0,
    labels: 3,
    no_of_containers: 1,
    policies: 0,
    no_of_alerts: 0
  }
];

export const VMGROUP_DATA = [
  {
    ID: 68,
    Group_Name: "redhatdefault",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2021-09-24T04:32:57Z",
    GroupID: 68,
    Vmcount: 4,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12636402,
        name: "MITRE",
        value: "",
        color: ""
      },
      {
        label_id: 12690589,
        name: "testing",
        value: "VM",
        color: "#9A244F"
      },
      {
        label_id: 12661890,
        name: "beta.kubernetes.io/instance-type",
        value: "e2-standard-4",
        color: "#8A008A"
      },
      {
        label_id: 12661893,
        name: "cloud.google.com/gke-boot-disk",
        value: "pd-standard",
        color: "#8A008A"
      },
      {
        label_id: 12700758,
        name: "dsadsad",
        value: "asdsadas",
        color: "#004D65"
      }
    ]
  },
  {
    ID: 222,
    Group_Name: "default",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2021-10-21T05:39:19Z",
    GroupID: 222,
    Vmcount: 0,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12692101,
        name: "policy2",
        value: "test",
        color: "#002E7A"
      }
    ]
  },
  {
    ID: 301,
    Group_Name: "ubuntu",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2021-09-24T04:32:57Z",
    GroupID: 301,
    Vmcount: 4,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12700758,
        name: "dsadsad",
        value: "asdsadas",
        color: "#004D65"
      }
    ]
  },
  {
    ID: 302,
    Group_Name: "redis",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2021-10-21T05:39:19Z",
    GroupID: 302,
    Vmcount: 4,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12700758,
        name: "dsadsad",
        value: "asdsadas",
        color: "#004D65"
      },
      {
        label_id: 12661893,
        name: "cloud.google.com/gke-boot-disk",
        value: "pd-standard",
        color: "#8A008A"
      }
    ]
  },
  {
    ID: 303,
    Group_Name: "mint",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2021-10-21T05:39:19Z",
    GroupID: 303,
    Vmcount: 4,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12745563,
        name: "vmlabel12",
        value: "kk",
        color: "#004D6"
      },
      {
        label_id: 12846543,
        name: "nucleci",
        value: "",
        color: ""
      }
    ]
  },
  {
    ID: 304,
    Group_Name: "test-vm-kloud",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2021-10-21T05:39:19Z",
    GroupID: 304,
    Vmcount: 4,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12922227,
        name: "vm-",
        value: "testing",
        color: "#004D65"
      },
      {
        label_id: 12664557,
        name: "k8s-app",
        value: "cilium",
        color: "#38571A"
      },
      {
        label_id: 12661893,
        name: "cloud.google.com/gke-boot-disk",
        value: "pd-standard",
        color: "#8A008A"
      },
      {
        label_id: 12661998,
        name: "tier",
        value: "node",
        color: "#38571A"
      }
    ]
  },
  {
    ID: 305,
    Group_Name: "test-vm-kl",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2021-10-21T05:39:19Z",
    GroupID: 305,
    Vmcount: 0,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12661890,
        name: "beta.kubernetes.io/instance-type",
        value: "e2-standard-4",
        color: "#8A008A"
      }
    ]
  },
  {
    ID: 306,
    Group_Name: "test-vm-plm",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2021-10-21T05:39:19Z",
    GroupID: 306,
    Vmcount: 0,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12745636,
        name: "jopl",
        value: "mopk",
        color: "#004D6"
      }
    ]
  },
  {
    ID: 324,
    Group_Name: "testgroup1",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2021-11-23T07:41:18Z",
    GroupID: 324,
    Vmcount: 0,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12661887,
        name: "beta.kubernetes.io/arch",
        value: "amd64",
        color: "#8A008A"
      },
      {
        label_id: 12920358,
        name: "testdp",
        value: "testval",
        color: "#004D6"
      },
      {
        label_id: 12922227,
        name: "vm-",
        value: "testing",
        color: "#004D65"
      },
      {
        label_id: 12922419,
        name: "entity",
        value: "test",
        color: "#004D65"
      },
      {
        label_id: 13934049,
        name: "beta.kubernetes.io/arch",
        value: "amd64",
        color: ""
      },
      {
        label_id: 13934052,
        name: "adsasd",
        value: "",
        color: ""
      }
    ]
  },
  {
    ID: 420,
    Group_Name: "datapipeline-test-vm",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2022-01-21T11:11:19Z",
    GroupID: 420,
    Vmcount: 2,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12824343,
        name: "host",
        value: "",
        color: ""
      }
    ]
  },
  {
    ID: 453,
    Group_Name: "vm-process",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2022-01-28T06:53:36Z",
    GroupID: 453,
    Vmcount: 10,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12846495,
        name: "config-files",
        value: "",
        color: ""
      },
      {
        label_id: 12846498,
        name: "mysqldump",
        value: "",
        color: ""
      }
    ]
  },
  {
    ID: 606,
    Group_Name: "qwerty",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2022-02-15T04:59:32Z",
    GroupID: 606,
    Vmcount: 2,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12661905,
        name: "beta.kubernetes.io/os",
        value: "linux",
        color: "#8A008A"
      },
      {
        label_id: 12661896,
        name: "cloud.google.com/gke-container-runtime",
        value: "containerd",
        color: "#8A008A"
      }
    ]
  },
  {
    ID: 663,
    Group_Name: "demovmonboarding",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2022-02-21T11:40:12Z",
    GroupID: 663,
    Vmcount: 0,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: null
  },
  {
    ID: 699,
    Group_Name: "new-test5",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2022-02-22T09:35:38Z",
    GroupID: 699,
    Vmcount: 0,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12846528,
        name: "wordpress",
        value: "",
        color: ""
      },
      {
        label_id: 12846543,
        name: "nucleci",
        value: "",
        color: ""
      },
      {
        label_id: 12846546,
        name: "cve-2021-39327",
        value: "",
        color: ""
      },
      {
        label_id: 12777837,
        name: "testnew",
        value: "akhil",
        color: "#004D65"
      }
    ]
  },
  {
    ID: 702,
    Group_Name: "test-vm",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    Updated_At: "2022-02-22T09:53:45Z",
    GroupID: 702,
    Vmcount: 0,
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12846549,
        name: "django",
        value: "",
        color: ""
      },
      {
        label_id: 12846552,
        name: "cve-2021-31542",
        value: "",
        color: ""
      },
      {
        label_id: 12846555,
        name: "dir traversal",
        value: "",
        color: ""
      },
      {
        label_id: 12846558,
        name: "django 3.2",
        value: "",
        color: ""
      },
      {
        label_id: 13899234,
        name: "controller-uid",
        value: "4013c1ad-d27e-46d2-9ee0-b60d19759ddc",
        color: "#38571A"
      },
      {
        label_id: 13899786,
        name: "job-name1234",
        value: "sensitive-data-cron-27595683",
        color: "#38571A"
      }
    ]
  }
];

export const VMINSTANCE_DATA = [
  {
    ID: 57,
    Instance_Name: "test-linux3",
    Host: "",
    Group_Id: 68,
    VPC: "default_vpc",
    Internal_Ip: "172.18.0.1",
    External_Ip: "34.126.157.242",
    Operating_System: "",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    ControlPlaneDomain: "",
    Updated_At: "2021-10-26T09:11:47Z",
    Created_At: "2021-10-26T09:11:47Z",
    Group_Name: "redhatdefault",
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12666483,
        name: "test13",
        value: "vtest13",
        color: "#004D65"
      },
      {
        label_id: 12666489,
        name: "testttt",
        value: "testinggg",
        color: "#004D65"
      },
      {
        label_id: 12657905,
        name: "team",
        value: "policy",
        color: "#61177C"
      },
      {
        label_id: 12668475,
        name: "zdx",
        value: "fdsf",
        color: "#004D65"
      },
      {
        label_id: 12679176,
        name: "dsda",
        value: "dsd",
        color: "#004D65"
      },
      {
        label_id: 12700758,
        name: "dsadsad",
        value: "asdsadas",
        color: "#004D65"
      },
      {
        label_id: 12922227,
        name: "vm-",
        value: "testing",
        color: "#004D65"
      },
      {
        label_id: 12922419,
        name: "entity",
        value: "test",
        color: "#004D65"
      },
      {
        label_id: 12661890,
        name: "beta.kubernetes.io/instance-type",
        value: "e2-standard-4",
        color: "#8A008A"
      },
      {
        label_id: 12661902,
        name: "topology.gke.io/zone",
        value: "us-central1-c",
        color: "#8A008A"
      },
      {
        label_id: 12661899,
        name: "cloud.google.com/gke-nodepool",
        value: "accuknox-qa-e2e-pool",
        color: "#8A008A"
      },
      {
        label_id: 12661893,
        name: "cloud.google.com/gke-boot-disk",
        value: "pd-standard",
        color: "#8A008A"
      },
      {
        label_id: 12661908,
        name: "failure-domain.beta.kubernetes.io/zone",
        value: "us-central1-c",
        color: "#8A008A"
      },
      {
        label_id: 12661914,
        name: "kubernetes.io/hostname",
        value: "gke-accuknox-qa-e2e-accuknox-qa-e2e-p-8889bb4d-qmcn",
        color: "#8A008A"
      }
    ]
  },
  {
    ID: 55,
    Instance_Name: "test-linux1",
    Host: "",
    Group_Id: 68,
    VPC: "default_vpc",
    Internal_Ip: "172.18.0.1",
    External_Ip: "34.126.157.242",
    Operating_System: "",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    ControlPlaneDomain: "",
    Updated_At: "2021-10-26T09:11:47Z",
    Created_At: "2021-10-26T09:11:47Z",
    Group_Name: "redhatdefault",
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12661893,
        name: "cloud.google.com/gke-boot-disk",
        value: "pd-standard",
        color: "#8A008A"
      },
      {
        label_id: 12700758,
        name: "dsadsad",
        value: "asdsadas",
        color: "#004D65"
      },
      {
        label_id: 12922227,
        name: "vm-",
        value: "testing",
        color: "#004D65"
      },
      {
        label_id: 12984474,
        name: "two",
        value: "april",
        color: "#004D65"
      },
      {
        label_id: 13899504,
        name: "job-name",
        value: "redis-exposure-cron-27595681",
        color: "#38571A"
      }
    ]
  },
  {
    ID: 56,
    Instance_Name: "test-linux2",
    Host: "",
    Group_Id: 68,
    VPC: "default_vpc",
    Internal_Ip: "172.18.0.1",
    External_Ip: "34.126.157.242",
    Operating_System: "",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    ControlPlaneDomain: "",
    Updated_At: "2021-10-26T09:11:47Z",
    Created_At: "2021-10-26T09:11:47Z",
    Group_Name: "redhatdefault",
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12636402,
        name: "MITRE",
        value: "",
        color: ""
      },
      {
        label_id: 12640827,
        name: "Discovery",
        value: "",
        color: ""
      },
      {
        label_id: 12700758,
        name: "dsadsad",
        value: "asdsadas",
        color: "#004D65"
      },
      {
        label_id: 12746235,
        name: "google",
        value: "add-label-google",
        color: "#004D65"
      },
      {
        label_id: 12661887,
        name: "beta.kubernetes.io/arch",
        value: "amd64",
        color: "#8A008A"
      },
      {
        label_id: 12661902,
        name: "topology.gke.io/zone",
        value: "us-central1-c",
        color: "#8A008A"
      }
    ]
  },
  {
    ID: 58,
    Instance_Name: "test-linux4",
    Host: "",
    Group_Id: 68,
    VPC: "default_vpc",
    Internal_Ip: "172.18.0.1",
    External_Ip: "34.126.157.242",
    Operating_System: "",
    Workspace_Id: 147,
    Location: "",
    Cloud_Provider: "",
    Project_Id: "",
    Status: true,
    ControlPlaneDomain: "",
    Updated_At: "2021-10-26T09:11:47Z",
    Created_At: "2021-10-26T09:11:47Z",
    Group_Name: "redhatdefault",
    Policycount: 0,
    TotalPolicycount: 0,
    Labels: [
      {
        label_id: 12661998,
        name: "tier",
        value: "node",
        color: "#38571A"
      }
    ]
  }
];

export const CLUSTERFILTER = [
  {
    ID: 1413,
    ClusterName: "rk-test",
    WorkspaceID: 846,
    id: 1413,
    clusterId: 1413,
    clusterName: "rk-test",
    name: "rk-test",
    entity_type: "cluster",
    cluster_name: "rk-test",
    children: [
      {
        ID: 565577,
        PodName: "productcatalogservice-9fb7986cc-xxbvd",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 565577,
        podName: "productcatalogservice-9fb7986cc-xxbvd",
        entity_type: "pod"
      },
      {
        ID: 565574,
        PodName: "loadgenerator-7969674f5b-hns6h",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 565574,
        podName: "loadgenerator-7969674f5b-hns6h",
        entity_type: "pod"
      },
      {
        ID: 565568,
        PodName: "cartservice-8d8f496b9-cwpjb",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 565568,
        podName: "cartservice-8d8f496b9-cwpjb",
        entity_type: "pod"
      },
      {
        ID: 565565,
        PodName: "frontend-674dc7dd97-rtgnb",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2700,
        ContainerCount: 1,
        podId: 565565,
        podName: "frontend-674dc7dd97-rtgnb",
        entity_type: "pod"
      },
      {
        ID: 565562,
        PodName: "recommendationservice-7df8dd9ddf-9llpq",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2700,
        ContainerCount: 1,
        podId: 565562,
        podName: "recommendationservice-7df8dd9ddf-9llpq",
        entity_type: "pod"
      },
      {
        ID: 565559,
        PodName: "emailservice-dfbf54759-ckbds",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 565559,
        podName: "emailservice-dfbf54759-ckbds",
        entity_type: "pod"
      },
      {
        ID: 565556,
        PodName: "paymentservice-5b6dd84d77-2ml2s",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2700,
        ContainerCount: 1,
        podId: 565556,
        podName: "paymentservice-5b6dd84d77-2ml2s",
        entity_type: "pod"
      },
      {
        ID: 565550,
        PodName: "shippingservice-587488b4c9-vmrc8",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 565550,
        podName: "shippingservice-587488b4c9-vmrc8",
        entity_type: "pod"
      },
      {
        ID: 565535,
        PodName: "currencyservice-569b65587f-f7hlj",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 565535,
        podName: "currencyservice-569b65587f-f7hlj",
        entity_type: "pod"
      },
      {
        ID: 565529,
        PodName: "redis-cart-79bf75fd45-np2fp",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 565529,
        podName: "redis-cart-79bf75fd45-np2fp",
        entity_type: "pod"
      },
      {
        ID: 565511,
        PodName: "checkoutservice-66fc8bff7d-nhkwq",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 565511,
        podName: "checkoutservice-66fc8bff7d-nhkwq",
        entity_type: "pod"
      },
      {
        ID: 565508,
        PodName: "adservice-6d59878665-xd2m8",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 565508,
        podName: "adservice-6d59878665-xd2m8",
        entity_type: "pod"
      },
      {
        ID: 562967,
        PodName: "ubuntu-4-deployment-868dd7ddb-82ds5",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 562967,
        podName: "ubuntu-4-deployment-868dd7ddb-82ds5",
        entity_type: "pod"
      },
      {
        ID: 562964,
        PodName: "ubuntu-3-deployment-59445d6fb7-8n9lc",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2700,
        ContainerCount: 1,
        podId: 562964,
        podName: "ubuntu-3-deployment-59445d6fb7-8n9lc",
        entity_type: "pod"
      },
      {
        ID: 562961,
        PodName: "ubuntu-1-deployment-5bd4dff469-fqk9b",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 562961,
        podName: "ubuntu-1-deployment-5bd4dff469-fqk9b",
        entity_type: "pod"
      },
      {
        ID: 562958,
        PodName: "ubuntu-2-deployment-55c894ddb5-swm97",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2700,
        ContainerCount: 1,
        podId: 562958,
        podName: "ubuntu-2-deployment-55c894ddb5-swm97",
        entity_type: "pod"
      },
      {
        ID: 562955,
        PodName: "deathstar-6c94dcc57b-ghgmz",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2700,
        ContainerCount: 1,
        podId: 562955,
        podName: "deathstar-6c94dcc57b-ghgmz",
        entity_type: "pod"
      },
      {
        ID: 562952,
        PodName: "cilium-node-init-cfb85",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2703,
        ContainerCount: 1,
        podId: 562952,
        podName: "cilium-node-init-cfb85",
        entity_type: "pod"
      },
      {
        ID: 562949,
        PodName: "kubearmor-relay-5656cc5bf7-mf8v4",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2700,
        ContainerCount: 1,
        podId: 562949,
        podName: "kubearmor-relay-5656cc5bf7-mf8v4",
        entity_type: "pod"
      },
      {
        ID: 562946,
        PodName: "cilium-node-init-v8ddd",
        WorkspaceID: 846,
        ClusterID: 1413,
        NodeID: 2700,
        ContainerCount: 1,
        podId: 562946,
        podName: "cilium-node-init-v8ddd",
        entity_type: "pod"
      }
    ]
  }
];
export const VM_FILTER = [
  {
    id: 68,
    group_name: "redhatdefault",
    instances: [
      {
        id: 55,
        instance_name: "test-linux1",
        entity_type: "vm"
      },
      {
        id: 56,
        instance_name: "test-linux2",
        entity_type: "vm"
      },
      {
        id: 57,
        instance_name: "test-linux3",
        entity_type: "vm"
      },
      {
        id: 58,
        instance_name: "test-linux4",
        entity_type: "vm"
      }
    ],
    entity_type: "vmgroup"
  },
  {
    id: 222,
    group_name: "default",
    instances: null,
    entity_type: "vmgroup"
  },
  {
    id: 301,
    group_name: "ubuntu",
    instances: [
      {
        id: 61,
        instance_name: "test-linux1",
        entity_type: "vm"
      },
      {
        id: 62,
        instance_name: "test-linux2",
        entity_type: "vm"
      },
      {
        id: 63,
        instance_name: "test-linux3",
        entity_type: "vm"
      },
      {
        id: 64,
        instance_name: "test-linux4",
        entity_type: "vm"
      }
    ],
    entity_type: "vmgroup"
  },
  {
    id: 302,
    group_name: "redis",
    instances: [
      {
        id: 65,
        instance_name: "test-linux1",
        entity_type: "vm"
      },
      {
        id: 66,
        instance_name: "test-linux2",
        entity_type: "vm"
      },
      {
        id: 67,
        instance_name: "test-linux3",
        entity_type: "vm"
      },
      {
        id: 68,
        instance_name: "test-linux4",
        entity_type: "vm"
      }
    ],
    entity_type: "vmgroup"
  },
  {
    id: 303,
    group_name: "mint",
    instances: [
      {
        id: 69,
        instance_name: "test-linux1",
        entity_type: "vm"
      },
      {
        id: 70,
        instance_name: "test-linux2",
        entity_type: "vm"
      },
      {
        id: 71,
        instance_name: "test-linux3",
        entity_type: "vm"
      },
      {
        id: 72,
        instance_name: "test-linux4",
        entity_type: "vm"
      }
    ],
    entity_type: "vmgroup"
  },
  {
    id: 304,
    group_name: "test-vm-kloud",
    instances: [
      {
        id: 73,
        instance_name: "test-linux1",
        entity_type: "vm"
      },
      {
        id: 74,
        instance_name: "test-linux2",
        entity_type: "vm"
      },
      {
        id: 75,
        instance_name: "test-linux3",
        entity_type: "vm"
      },
      {
        id: 76,
        instance_name: "test-linux4",
        entity_type: "vm"
      }
    ],
    entity_type: "vmgroup"
  },
  {
    id: 305,
    group_name: "test-vm-kl",
    instances: null,
    entity_type: "vmgroup"
  },
  {
    id: 306,
    group_name: "test-vm-plm",
    instances: null,
    entity_type: "vmgroup"
  },
  {
    id: 324,
    group_name: "testgroup1",
    instances: null,
    entity_type: "vmgroup"
  },
  {
    id: 420,
    group_name: "datapipeline-test-vm",
    instances: [
      {
        id: 138,
        instance_name: "test-datapipeline-vm",
        entity_type: "vm"
      },
      {
        id: 279,
        instance_name: "l3l4",
        entity_type: "vm"
      }
    ],
    entity_type: "vmgroup"
  },
  {
    id: 453,
    group_name: "vm-process",
    instances: [
      {
        id: 156,
        instance_name: "vm-feeds",
        entity_type: "vm"
      },
      {
        id: 159,
        instance_name: "vm-feed",
        entity_type: "vm"
      },
      {
        id: 165,
        instance_name: "flowbetweenvm",
        entity_type: "vm"
      },
      {
        id: 168,
        instance_name: "flowvm",
        entity_type: "vm"
      },
      {
        id: 228,
        instance_name: "datapipeline-script-test",
        entity_type: "vm"
      },
      {
        id: 231,
        instance_name: "datapipeline-script-test-vm",
        entity_type: "vm"
      },
      {
        id: 234,
        instance_name: "datapipeline-script-vm",
        entity_type: "vm"
      },
      {
        id: 237,
        instance_name: "datapipe-script-vm",
        entity_type: "vm"
      },
      {
        id: 264,
        instance_name: "datapipe-script-vm",
        entity_type: "vm"
      },
      {
        id: 273,
        instance_name: "datapipeline-test-vm-159",
        entity_type: "vm"
      }
    ],
    entity_type: "vmgroup"
  },
  {
    id: 606,
    group_name: "qwerty",
    instances: [
      {
        id: 276,
        instance_name: "l3l4",
        entity_type: "vm"
      },
      {
        id: 294,
        instance_name: "test2",
        entity_type: "vm"
      }
    ],
    entity_type: "vmgroup"
  },
  {
    id: 663,
    group_name: "demovmonboarding",
    instances: null,
    entity_type: "vmgroup"
  },
  {
    id: 699,
    group_name: "new-test5",
    instances: null,
    entity_type: "vmgroup"
  },
  {
    id: 702,
    group_name: "test-vm",
    instances: null,
    entity_type: "vmgroup"
  }
];
export const API_VM_FILTER = {
  status: "success",
  isLoading: false,
  isSuccess: true,
  isError: false,
  data: {
    total_record: 15,
    list_of_instance_group: VM_FILTER
  },
  isFetched: true,
  isFetching: false
};
