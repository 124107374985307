import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Title, Input, Button, ContentContainer, Select } from "components/simple";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Typography } from "@mui/material";
import ErrorText from "../ErrorText";

function PagerDutyIntegration() {
  const history = useHistory();
  const [help, setHelp] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control,
    setValue,
    getValues
  } = useForm({ mode: "onChange" });
  const onSubmit = data => {};
  return (
    <ContentContainer className={styles.container}>
      <div className="flex justify-between py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex gap-1">
          {/* <img src={SlackIcon} alt="icon" /> */}
          <Title>Pager Duty</Title>
        </div>
        <div className="cursor-pointer flex " onClick={() => setHelp(!help)}>
          <Typography>Help</Typography> <HelpOutlineIcon className={styles.help_icon} />
        </div>
      </div>
      {help ? (
        <div className={`bg-slate-300 rounded-sm ${styles.help}`}>
          <Typography className="text-base font-medium">
            Integrate webhook by entering required parameters below. Once your Integration is
            complete you will recieve alert notifications via PagerDuty
          </Typography>
          <Typography className="mt-3 text-base font-normal">
            Check PagerDuty API docs for any PagerDuty specific parameter.
          </Typography>
        </div>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%", width: "100%" }}>
        <div className="flex justify-between mt-8">
          <div style={{ width: "35%" }}>
            <Controller
              name="integrationName"
              {...register("integrationName", {
                required: "Integration name is Required"
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, ref }) => (
                <Input
                  name="integrationName"
                  placeholder="Enter a name for integration"
                  size="small"
                  label="Integration Name"
                  inputRef={ref}
                  value={value}
                  autoFocus
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <div className="h-2 mt-2">
              <ErrorText errorMsg={errors.integrationName && errors.integrationName.message} />
            </div>
          </div>
          <div style={{ width: "60%" }}>
            <Controller
              name="ServiceAPIKey"
              {...register("ServiceAPIKey", {
                required: "Service API Key is Required"
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, ref }) => (
                <Input
                  name="ServiceAPIKey"
                  placeholder="Enter the Service API Key"
                  label="Service API Key"
                  inputRef={ref}
                  value={value}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <div className="h-2 mt-2">
              <ErrorText errorMsg={errors.ServiceAPIKey && errors.ServiceAPIKey.message} />
            </div>
          </div>
        </div>
        <hr className="mt-12" />
        <div className="flex justify-between mt-6">
          <Button variant="primary" disabled={!isDirty || !isValid} onClick={() => {}}>
            Test
          </Button>
          <div className="flex">
            <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button
              disabled={!isDirty || !isValid}
              onClick={handleSubmit(onSubmit)}
              variant="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </ContentContainer>
  );
}
export default PagerDutyIntegration;
