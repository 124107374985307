import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { routes } from "router";

import { Divider } from "@mui/material";
import { Modal, Title, Tabs, Loader } from "components/simple";
import { DetailModalInfo, DetailsModalForm, DetailsModalNav } from ".";
import { navigate } from "helper/history";
import { stringToHTML } from "helper/formatHtml";

import styles from "./styles.module.scss";
import MetadataModal from "../MetadataModal";

import { getBaselineTagById, postTags } from "store/entities/baseline/actions";
import { createLoadingSelector } from "store/entities/loading/selector";

const DetailsModal = memo(
  ({
    data,
    isOpen,
    resOptions,
    onCloseModal,
    baselineDetailConfig,
    handleAction,
    selectTicketConfig,
    onSelectTicketConfig,
    ticketConfigOptions,
    group_ids,
    setSingleMode,
    ...rest
  }) => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(0);
    const [status, setStatus] = useState({ value: "", label: "" });
    const [annotations, setAnnotations] = useState(false);
    const [applyToGroup, setApplyToGroup] = useState(false);

    const noData = "No Data";

    const control = data?.control || noData;
    const description = data?.description || noData;
    const information = data?.info || noData;
    const solution = data?.solution || noData;
    const check_value = data?.check_value || noData;
    const actual_value = data?.actual_value || noData;

    const currentResult = resOptions?.find(item =>
      baselineDetailConfig?.find(el => item?.value === el?.expected_result)
    );

    const isLoading = useSelector(state => createLoadingSelector([getBaselineTagById.type])(state));

    const cleanEffect = () => {
      setApplyToGroup(false);
      setActiveTab(0);
    };

    useEffect(() => {
      if (isOpen) setStatus(currentResult);
      return () => cleanEffect();
    }, [isOpen, data]);

    const firstBaseline = 0;
    let currentBaseline = group_ids?.findIndex(baselineId => data?.id === baselineId);
    const lastBaseline = group_ids?.length - 1;

    const handleNext = useCallback(() => {
      currentBaseline += 1;
      const nextBaseline = group_ids[currentBaseline];

      dispatch(
        getBaselineTagById({
          id: nextBaseline,
          controlId: nextBaseline,
          baselineId: rest?.baselineId
        })
      );
    }, [currentBaseline]);

    const handlePrevious = useCallback(() => {
      currentBaseline -= 1;
      const prevBaseline = group_ids[currentBaseline];

      dispatch(
        getBaselineTagById({
          id: prevBaseline,
          controlId: prevBaseline,
          baselineId: rest?.baselineId
        })
      );
    }, [currentBaseline]);

    const insertHtmlMarkup = content => (
      <div
        className={styles["modal-tab-content"]}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={stringToHTML(content)}
      />
    );
    const handleChange = useCallback((event, newValue) => setActiveTab(newValue), [activeTab]);
    const handleSwitch = useCallback(() => setApplyToGroup(!applyToGroup), [applyToGroup]);
    const handleSelect = useCallback(v => setStatus(v), [status]);
    const handleMore = useCallback(() => setAnnotations(!annotations), [annotations]);

    const handleSuccessCallback = () =>
      dispatch(getBaselineTagById({ id: data?.id, baselineId: rest?.baselineId }));

    const handleSubmit = () => {
      dispatch(
        postTags({
          data: {
            expected_result: status.value,
            apply_actual: applyToGroup,
            baseline_id: rest?.baselineId,
            control_ids: rest?.controlIds
          },
          successCallback: () => handleSuccessCallback,
          id: rest?.baselineId
        })
      );
    };
    const toTicketsPage = () => navigate(`${routes.tickets}`, { ticketId: data?.id });

    const tabs = [
      {
        id: 0,
        label: `Information`,
        content: insertHtmlMarkup(information)
      },
      {
        id: 1,
        label: `Description`,
        content: insertHtmlMarkup(description)
      },
      {
        id: 2,
        label: `Solution`,
        content: insertHtmlMarkup(solution)
      },
      {
        id: 3,
        label: `Check Value`,
        content: insertHtmlMarkup(check_value)
      },
      {
        id: 3,
        label: `Actual Value`,
        content: insertHtmlMarkup(actual_value)
      }
    ];

    return (
      <Modal
        isOpen={isOpen}
        toggle={onCloseModal}
        width={850}
        sx={{ paddingRight: "20px", minHeight: 700 }}
      >
        {isLoading ? (
          <Loader className={styles.loader} />
        ) : (
          <article className={styles.container}>
            <div className={styles["modal-header"]}>
              <Title className={styles["modal-header__title"]}>{control}</Title>
            </div>
            <Divider className={styles["modal-divider-md"]} />
            {!!group_ids?.length && (
              <section className={styles["modal-nav"]}>
                <DetailsModalNav
                  group_ids={group_ids}
                  firstBaseline={firstBaseline}
                  currentBaseline={currentBaseline}
                  lastBaseline={lastBaseline}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
              </section>
            )}
            <section className={styles["modal-details"]}>
              <DetailModalInfo data={data} />
              <DetailsModalForm
                data={data}
                options={resOptions}
                selectTicketConfig={selectTicketConfig}
                ticketConfigOptions={ticketConfigOptions}
                groupIds={group_ids}
                status={status}
                value={applyToGroup}
                handleTicketConfig={onSelectTicketConfig}
                handleAction={handleAction}
                handleSelect={handleSelect}
                handleSwitch={handleSwitch}
                handleSubmit={handleSubmit}
                handleMore={handleMore}
                toTickets={toTicketsPage}
              />
            </section>
            <MetadataModal
              id={data?.id}
              baselineId={rest?.baselineId}
              controlIds={rest?.controlIds}
              setSingleMode={setSingleMode}
              resOptions={resOptions}
              groupIds={group_ids}
              isOpen={Boolean(annotations)}
              onCloseModal={() => setAnnotations(!annotations)}
              fromDetails
              isClear={false}
            />
            <section className={styles["modal-tabs"]}>
              <Tabs tabs={tabs} value={activeTab} handleChange={handleChange} spaceBetween />
            </section>
          </article>
        )}
      </Modal>
    );
  }
);

export default DetailsModal;
