/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, Loader } from "components/simple";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getBaseline,
  duplicateBaseline,
  editBaselineFields,
  getBaselineConfigTable
} from "store/entities/baseline/actions";
import { getBaselineDataSelector } from "store/entities/baseline/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import { Data, AssignedGroups, AssignedHosts, History } from "./Tabs";
import Panel from "./Panel";
import PanelFields from "./PanelFields";

import styles from "./styles.module.scss";
import { routes } from "router";

const BaselineConfiguration = ({ history }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const selectTenant = useSelector(state => state.users.selectTenant);
  const baselineData = useSelector(getBaselineDataSelector);

  const id = params?.id;
  const [activeTab, setActiveTab] = useState(0);
  const [collapse, setCollapse] = useState(false);

  const isLoading = useSelector(state => {
    if (state?.loading?.GET_BASELINE === undefined || state?.loading?.GET_BASELINE) {
      return true;
    }
    return createLoadingSelector([getBaseline.type])(state);
  });

  const isLoadingTable = useSelector(state =>
    createLoadingSelector([getBaselineConfigTable.type])(state)
  );

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setCollapse(false);
  };

  useEffect(() => {
    dispatch(getBaseline({ id }));
  }, [selectTenant, id]);

  const tabs = [
    {
      id: 0,
      label: `Data`,
      content: <Data id={id} />
    },
    {
      id: 1,
      label: `Assigned Hosts`,
      content: <AssignedHosts id={id} />
    },
    {
      id: 2,
      label: `Assigned Groups`,
      content: <AssignedGroups id={id} />
    },
    {
      id: 3,
      label: `History`,
      content: <History id={id} />
    }
  ];

  const onEdit = () => {
    history.push(`${routes.editBaseline}/${id}`);
  };

  const onEditFields = () => {
    dispatch(editBaselineFields());
  };

  const onDuplicate = () => {
    dispatch(duplicateBaseline({ id }));
  };

  const onCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <div className={styles.container}>
      <div className={styles.panels}>
        {isLoadingTable && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        <Panel
          onEdit={onEdit}
          onCollapse={onCollapse}
          title={baselineData?.name}
          onDuplicate={onDuplicate}
        />
        <PanelFields
          display_fields_options={baselineData?.display_fields_options}
          filter_fields_options={baselineData?.filter_fields_options}
          className={!collapse && styles.hide}
          onEditFields={onEditFields}
          id={id}
        />
      </div>
      {isLoading ? <Loader /> : <Tabs tabs={tabs} value={activeTab} handleChange={handleChange} />}
    </div>
  );
};

BaselineConfiguration.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default BaselineConfiguration;
