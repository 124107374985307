import React, { useState } from "react";

import { Controller } from "react-hook-form";
import { Input, ErrorText } from "components/simple";

export default function Password({ isEdit, control, errors }) {
  const [isFocusedBefore, setIsFocusedBefore] = useState(false);

  return (
    <div className="w-full mb-3.5 mt-3">
      <Controller
        name="password"
        rules={{
          required: { value: !isEdit, message: "Password is required!" },
          maxLength: { value: 100, message: "Maximum 100 characters allowed!" }
        }}
        defaultValue=""
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Input
            type="password"
            name="password"
            label="Password"
            placeholder="Enter Password"
            required
            value={value}
            onFocus={evt => {
              onChange(isFocusedBefore ? evt.target.value : "");
              setIsFocusedBefore(true);
            }}
            onChange={evt => {
              onChange(evt.target.value);
            }}
          />
        )}
      />
      <div className="m-0.5">
        <ErrorText errorMsg={errors.password && errors.password.message} />
      </div>
    </div>
  );
}
