import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import styles from "./styles.module.scss";

const ActionButton = ({ onClick, className, type, text, ...rest }) => {
  const renderIcon = () => {
    switch (type) {
      case "plus":
        return <Icon.PlusInCircle />;
      case "minus":
        return <Icon.MinusInCircle />;
      case "add":
        return <Icon.Add />;
      case "save":
        return <Icon.Save />;
      case "addTag":
        return <Icon.AddTag />;
      case "addToGroup":
        return <Icon.AddToGroup />;
      case "addToTarget":
        return <Icon.AddToTarget />;
      case "addBaseline":
        return <Icon.Add />;
      case "annotation":
        return <Icon.Annotation />;
      case "createTicket":
        return <Icon.CreateTicket />;
      case "delete":
        return <Icon.Delete />;
      case "edit":
        return <Icon.Edit />;
      case "export":
        return <Icon.Export />;
      case "ignoreFinding":
        return <Icon.IgnoreFinding />;
      case "justification":
        return <Icon.Justification />;
      case "run":
        return <Icon.Run />;
      case "search":
        return <Icon.Search />;
      case "monitor":
        return <Icon.Monitor color="#2B3858" />;
      default:
        return null;
    }
  };

  const renderTooltip = () => {
    switch (type) {
      case "plus":
        return null;
      case "minus":
        return null;
      case "addTag":
        return "Add Tag";
      case "addToGroup":
        return "Add to Group";
      case "addToTarget":
        return "Add to target";
      case "addBaseline":
        return "Create New Baseline";
      case "annotation":
        return "Annotation";
      case "createTicket":
        return "Create a ticket";
      case "delete":
        return "Delete";
      case "edit":
        return "Edit";
      case "export":
        return "Export";
      case "ignoreFinding":
        return "Ignore finding";
      case "justification":
        return "Justification";
      case "run":
        return "Run";
      case "search":
        return "Search";
      case "monitor":
        return "Monitor";
      default:
        return null;
    }
  };

  return (
    <button
      type="button"
      className={`${styles.container} ${className}`}
      onClick={() => onClick(type)}
      {...rest}
    >
      {renderTooltip() ? <div className={styles.tooltip}>{renderTooltip()}</div> : null}
      <div className={`${styles.icon} ${text ? styles.widthAuto : ""}`}>
        {text ? <span>{text}</span> : null}
        {renderIcon()}
      </div>
    </button>
  );
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string
};

ActionButton.defaultProps = {
  onClick: () => null,
  className: "",
  type: "addTag",
  text: ""
};

export default ActionButton;
