// Set Monitors params
export const SET_MONITORS_PARAMS = "SET_MONITORS_PARAMS";

// Get MONITORS
export const GET_MONITORS = "GET_GET_MONITORS_REQUEST";
export const GET_MONITORS_SUCCESS = "GET_GET_MONITORS_SUCCESS";
export const GET_MONITORS_FAILED = "GET_GET_MONITORS_FAILED";

// Get MONITOR
export const GET_MONITOR = "GET_MONITOR_REQUEST";
export const GET_MONITOR_SUCCESS = "GET_MONITOR_SUCCESS";
export const GET_MONITOR_FAILED = "GET_MONITOR_FAILED";

// Create MONITOR
export const CREATE_MONITOR = "CREATE_MONITOR_REQUEST";
export const CREATE_MONITOR_SUCCESS = "CREATE_MONITOR_SUCCESS";
export const CREATE_MONITOR_FAILED = "CREATE_MONITOR_FAILED";

// Delete MONITOR
export const DELETE_MONITOR = "DELETE_MONITOR_REQUEST";
export const DELETE_MONITOR_SUCCESS = "DELETE_MONITOR_SUCCESS";
export const DELETE_MONITOR_FAILED = "DELETE_MONITOR_FAILED";

// Create monitor row
export const CREATE_MONITOR_ROW = "CREATE_MONITOR_ROW_REQUEST";
export const CREATE_MONITOR_ROW_SUCCESS = "CREATE_MONITOR_ROW_SUCCESS";
export const CREATE_MONITOR_ROW_FAILED = "CREATE_MONITOR_ROW_FAILED";

// Delete monitor row
export const DELETE_MONITOR_ROW = "DELETE_MONITOR_ROW_REQUEST";
export const DELETE_MONITOR_ROW_SUCCESS = "DELETE_MONITOR_ROW_SUCCESS";
export const DELETE_MONITOR_ROW_FAILED = "DELETE_MONITOR_ROW_FAILED";

// Get MONITORS
export const GET_MONITORS_MINI = "GET_MONITORS_MINI_REQUEST";
export const GET_MONITORS_MINI_SUCCESS = "GET_MONITORS_MINI_SUCCESS";
export const GET_MONITORS_MINI_FAILED = "GET_MONITORS_MINI_FAILED";

export const SET_MONITOR_CONFIG_PARAMS = "SET_MONITOR_CONFIG_PARAMS";
export const UPD_MONITOR_CONFIG_PARAMS = "UPD_MONITOR_CONFIG_PARAMS";
export const CLEAR_MONITOR_CONFIG_PARAMS = "CLEAR_MONITOR_CONFIG_PARAMS";

export const GET_MONITOR_CONFIG_FILTERS = "GET_MONITOR_CONFIG_FILTERS_REQUEST";
export const GET_MONITOR_CONFIG_FILTERS_SUCCESS = "GET_MONITOR_CONFIG_FILTERS_SUCCESS";
export const GET_MONITOR_CONFIG_FILTERS_FAILED = "GET_MONITOR_CONFIG_FILTERS_FAILED";

// Edti MONITOR
export const EDIT_MONITOR = "EDIT_MONITOR_REQUEST";
export const EDIT_MONITOR_SUCCESS = "EDIT_MONITOR_SUCCESS";
export const EDIT_MONITOR_FAILED = "EDIT_MONITOR_FAILED";

// Duplicate Monitor
export const DUPLICATE_MONITOR = "DUPLICATE_MONITOR_REQUEST";
export const DUPLICATE_MONITOR_SUCCESS = "GET_COMPARE_MONITORS_SUCCESS";
export const DUPLICATE_MONITOR_FAILED = "DUPLICATE_MONITOR_FAILED";

export const GET_MONITOR_CONFIG_TABLE = "GET_MONITOR_CONFIG_TABLE_REQUEST";
export const GET_MONITOR_CONFIG_TABLE_SUCCESS = "GET_MONITOR_CONFIG_TABLE_SUCCESS";
export const GET_MONITOR_CONFIG_TABLE_FAILED = "GET_MONITOR_CONFIG_TABLE_FAILED";

// add-control
export const ADD_CONTROL_MONITOR_CONFIG_TABLE = "ADD_CONTROL_MONITOR_CONFIG_TABLE_REQUEST";
export const ADD_CONTROL_MONITOR_CONFIG_TABLE_SUCCESS = "ADD_CONTROL_MONITOR_CONFIG_TABLE_SUCCESS";
export const ADD_CONTROL_MONITOR_CONFIG_TABLE_FAILED = "ADD_CONTROL_MONITOR_CONFIG_TABLE_FAILED";

// delete-control
export const DELETE_CONTROL_MONITOR_CONFIG_TABLE = "DELETE_CONTROL_MONITOR_CONFIG_TABLE_REQUEST";
export const DELETE_CONTROL_MONITOR_CONFIG_TABLE_SUCCESS =
  "DELETE_CONTROL_MONITOR_CONFIG_TABLE_SUCCESS";
export const DELETE_CONTROL_MONITOR_CONFIG_TABLE_FAILED =
  "DELETE_CONTROL_MONITOR_CONFIG_TABLE_FAILED";

// Set Assign-hosts params
export const SET_ASSIGN_HOSTS_PARAMS = "SET_MONITOR_ASSIGN_HOSTS_PARAMS";

// Set Assign-groups params
export const SET_ASSIGN_GROUPS_PARAMS = "SET_MONITOR_ASSIGN_GROUPS_PARAMS";

export const SET_MONITOR_HISTORY_PARAMS = "SET_MONITOR_HISTORY_PARAMS";

export const GET_MONITOR_HISTORY = "GET_MONITOR_HISTORY_REQUEST";
export const GET_MONITOR_HISTORY_SUCCESS = "GET_MONITOR_HISTORY_SUCCESS";
export const GET_MONITOR_HISTORY_FAILED = "GET_MONITOR_HISTORY_FAILED";

// Get Assets-mini
export const GET_ASSETS_MINI = "GET_ASSETS_MINI_MONITOR_REQUEST";
export const GET_ASSETS_MINI_SUCCESS = "GET_ASSETS_MINI_MONITOR_SUCCESS";
export const GET_ASSETS_MINI_FAILED = "GET_ASSETS_MINI_MONITOR_FAILED";

// Get Groups
export const GET_MONITOR_GROUPS = "GET_MONITOR_GROUPS_REQUEST";
export const GET_MONITOR_GROUPS_SUCCESS = "GET_MONITOR_GROUPS_SUCCESS";
export const GET_MONITOR_GROUPS_FAILED = "GET_MONITOR_GROUPS_FAILED";

// Add Monitor asset
export const ADD_MONITOR_ASSET = "ADD_MONITOR_ASSET_REQUEST";
export const ADD_MONITOR_ASSET_SUCCESS = "ADD_MONITOR_ASSET_SUCCESS";
export const ADD_MONITOR_ASSET_FAILED = "ADD_MONITOR_ASSET_FAILED";

// Delete Monitor asset
export const DELETE_MONITOR_ASSET = "DELETE_MONITOR_ASSET_REQUEST";
export const DELETE_MONITOR_ASSET_SUCCESS = "DELETE_MONITOR_ASSET_SUCCESS";
export const DELETE_MONITOR_ASSET_FAILED = "DELETE_MONITOR_ASSET_FAILED";

// Add Monitor group
export const ADD_MONITOR_GROUP = "ADD_MONITOR_GROUP_REQUEST";
export const ADD_MONITOR_GROUP_SUCCESS = "ADD_MONITOR_GROUP_SUCCESS";
export const ADD_MONITOR_GROUP_FAILED = "ADD_MONITOR_GROUP_FAILED";

// Delete Monitor group
export const DELETE_MONITOR_GROUP = "DELETE_MONITOR_GROUP_REQUEST";
export const DELETE_MONITOR_GROUP_SUCCESS = "DELETE_MONITOR_GROUP_SUCCESS";
export const DELETE_MONITOR_GROUP_FAILED = "DELETE_MONITOR_GROUP_FAILED";
