import React from "react";

import { IconButton } from "@mui/material";
import { Icon } from "components/simple";

import styles from "../styles.module.scss";

export const DetailsModalNav = ({
  group_ids,
  handlePrevious,
  handleNext,
  firstBaseline,
  currentBaseline,
  lastBaseline
}) => {
  return (
    <>
      <IconButton
        disableRipple
        disableTouchRipple
        disableFocusRipple
        onClick={handlePrevious}
        disabled={currentBaseline === firstBaseline}
      >
        <Icon.NavArrow className={styles["modal-nav__left"]} />
      </IconButton>
      <p className="modal-nav_pagination">
        <span>{currentBaseline + 1}</span> of <span>{group_ids?.length}</span>
      </p>
      <IconButton
        type="button"
        onClick={handleNext}
        disableRipple
        disableTouchRipple
        disableFocusRipple
        disabled={currentBaseline === lastBaseline}
      >
        <Icon.NavArrow className={styles["modal-nav__right"]} />
      </IconButton>
    </>
  );
};
