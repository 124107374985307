import React from "react";
import { useTheme } from "@mui/styles";
import { CardContainer } from "screens/PolicyEditor/KubeArmorPolicy/components/PolicyMetadataCard";
import { Typography } from "@mui/material";
import InfoIcon from "components/simple/Icon/Info";

const UnsupportedRuleCard = ({ rule }) => {
  const theme = useTheme();

  return (
    <CardContainer className="flex flex-col gap-3">
      <Typography sx={{ color: "primary.main", fontSize: "16px", fontWeight: 600 }}>
        {rule}
      </Typography>
      <div className="flex items-center gap-2 self-center">
        <InfoIcon color={theme.palette.grey[500]} />
        <Typography fontSize={14} sx={{ color: "grey.500", fontWeight: 500 }}>
          Unable to view this rule
        </Typography>
      </div>
    </CardContainer>
  );
};

export default UnsupportedRuleCard;
