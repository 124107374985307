import React, { useState } from "react";
import CustomAccordion from "../Accordion";

const DefaultExpandAccordion = ({
  Accordionkey,
  Summary,
  Details,
  highlitedColor,
  fontDetails,
  backgroundDetails,
  paddingDetails,
  iconSize,
  summaryBorder,
  summaryRadius,
  accRadius,
  accBottom,
  accBorder,
  summaryFont
}) => {
  const [expand, setExpand] = useState(true);
  const handleAccordionChange = panel => (e, newExpanded) => {
    setExpand(!expand);
  };
  return (
    <CustomAccordion
      Accordionkey={Accordionkey}
      Summary={Summary}
      Details={Details}
      expand={expand}
      onChange={handleAccordionChange(Accordionkey)}
      highlitedColor={highlitedColor}
      fontDetails={fontDetails}
      backgroundDetails={backgroundDetails}
      paddingDetails={paddingDetails}
      iconSize={iconSize}
      summaryBorder={summaryBorder}
      summaryRadius={summaryRadius}
      accRadius={accRadius}
      accBottom={accBottom}
      accBorder={accBorder}
      summaryFont={summaryFont}
      isDefaultopen
    />
  );
};

export default DefaultExpandAccordion;
