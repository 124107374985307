import React from "react";
import { ContentContainer, LightTooltip, Loader, Title } from "components/simple";
import { kFormatter } from "helper/helpers";
import { Typography } from "@mui/material";
import { SEVERITY_COLORS } from "store/entities/issues/registryScan/constants";
import { StackedBar } from "../common";
import InfoIcon from "components/simple/Icon/Info";
import { useTheme } from "@mui/styles";
import { useImageSeverityIssueQuery } from "store/entities/issues/registryScan/queries";

export const ImageSecurityCard = () => {
  const theme = useTheme();
  const imageSeverityIssueQuery = useImageSeverityIssueQuery();

  const totalImages = imageSeverityIssueQuery.data?.totalImages
    ? String(kFormatter(imageSeverityIssueQuery.data?.totalImages))
    : 0;

  return (
    <ContentContainer className="p-4 flex flex-col gap-4">
      <div className="flex items-center justify-between w-full">
        <Title>Image Security Issues</Title>
        <LightTooltip title="Shows image security issues" placement="top" arrow>
          <span>
            <InfoIcon color={theme.palette.primary.light} />
          </span>
        </LightTooltip>
      </div>

      <div className="flex items-center justify-between p-2 bg-blue-100 rounded-lg">
        <Typography paragraph sx={{ color: "primary.main", fontWeight: "500", m: 0 }}>
          Total Images
        </Typography>
        <Typography variant="h6" sx={{ color: "primary.main", fontWeight: "600", m: 0 }}>
          {totalImages}
        </Typography>
      </div>

      <>
        {imageSeverityIssueQuery.isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-3">
            <StackedBar
              data={imageSeverityIssueQuery.data?.severities || {}}
              dataLength={6}
              height={76}
              severitycolor={SEVERITY_COLORS}
            />

            <div>
              <div className="flex gap-1">
                <Typography>Total Malware:</Typography>
                <Typography fontWeight={600} sx={{ color: SEVERITY_COLORS.Critical }}>
                  {imageSeverityIssueQuery.data?.totalMalware}
                </Typography>
              </div>
              <div className="flex gap-1">
                <Typography className="">Total Sensitive Data:</Typography>
                <Typography fontWeight={600} sx={{ color: SEVERITY_COLORS.High }}>
                  {imageSeverityIssueQuery.data?.totalSensitiveData}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </>
    </ContentContainer>
  );
};
