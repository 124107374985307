import React from "react";

import { Controller } from "react-hook-form";
import { Select, ErrorText } from "components/simple";
import { useGetRegistryTypesQuery } from "store/entities/registry/queries";
import { Typography } from "@mui/material";

import styles from "../../../styles.module.scss";

export const RegistryType = ({ setSelectedRegistryType, control, errors }) => {
  const { data: registryTypes } = useGetRegistryTypesQuery();

  return (
    <div className="w-full mb-3.5 mt-3">
      <Typography className={styles.typo}>Registry Type*</Typography>
      <Controller
        name="registryType"
        rules={{
          required: "Please select a Registry Type!"
        }}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value, ref } }) => (
          <div style={{ paddingTop: "10px" }}>
            <Select
              name="registryType"
              placeholder="Select Registry Type"
              options={registryTypes}
              onChange={data => {
                onChange(data);
                setSelectedRegistryType(data);
              }}
              value={value}
            />
          </div>
        )}
      />
      <div className="m-0.5">
        <ErrorText errorMsg={errors.registryType && errors.registryType.message} />
      </div>
    </div>
  );
};
