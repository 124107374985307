import { Divider } from "@mui/material";
import { Button, Input, Modal, Title } from "components/simple";
import React, { useState } from "react";

import styles from "./styles.module.scss";

const StatusModal = ({ isOpen, status, onStatusChange, close }) => {
  const [inputValue, setInputValue] = useState("");

  const onSubmit = () => {
    const passWord = !status ? "enable" : "disable";
    if (inputValue === passWord) {
      onStatusChange();
      close();
      setInputValue("");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <Title className={styles.title}>Confirm {!status ? "Enable" : "Disable"}</Title>
      <Divider />
      <div className={styles.pointsContainer}>
        {status && (
          <>
            <div className={styles.point}>Background scanning will be disabled</div>
            <div className={styles.point}>All existing saved scan reports will be deleted</div>
            <div className={styles.point}>Real-time events will no longer be collected</div>
            <div className={styles.point}>
              All suppressions and scan configurations will be lost
            </div>
          </>
        )}
      </div>
      <div className={styles.label}>
        Type <span className={styles.bold}>&quot;{!status ? "enable" : "disable"}&quot;</span> to
        confirm
      </div>
      <Input
        type="text"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        className={styles.inputContainer}
      />
      <div className={styles.btnContainer}>
        <Button variant="outline" onClick={close}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Submit</Button>
      </div>
    </Modal>
  );
};

export default StatusModal;
