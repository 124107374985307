// Set Templates Params
export const SET_TEMPLATES_PARAMS = "SET_TEMPLATES_PARAMS";

// Get Templates
export const GET_TEMPLATES = "GET_TEMPLATES_REQUEST";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILED = "GET_TEMPLATES_FAILED";

// Get Template
export const GET_TEMPLATE = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILED = "GET_TEMPLATE_FAILED";

// Add Template
export const ADD_TEMPLATE = "ADD_TEMPLATE_REQUEST";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILED = "ADD_TEMPLATE_FAILED";

// Edit Template
export const EDIT_TEMPLATE = "EDIT_TEMPLATE_REQUEST";
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_FAILED = "EDIT_TEMPLATE_FAILED";

// Delete Template
export const DELETE_TEMPLATE = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILED = "DELETE_TEMPLATE_FAILED";

// Duplicate Template
export const DUPLICATE_TEMPLATE = "DUPLICATE_TEMPLATE_REQUEST";
export const DUPLICATE_TEMPLATE_SUCCESS = "DUPLICATE_TEMPLATE_SUCCESS";
export const DUPLICATE_TEMPLATE_FAILED = "DUPLICATE_TEMPLATE_FAILED";

// Get Templates Data-types
export const GET_TEMPLATES_DATA_TYPES = "GET_TEMPLATES_DATA_TYPES_REQUEST";
export const GET_TEMPLATES_DATA_TYPES_SUCCESS = "GET_TEMPLATES_DATA_TYPES_SUCCESS";
export const GET_TEMPLATES_DATA_TYPES_FAILED = "GET_TEMPLATES_DATA_TYPES_FAILED";

// Set Roles Params
export const SET_ROLES_PARAMS = "SET_ROLES_PARAMS";

// Get Roles
export const GET_ROLES = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILED = "GET_ROLES_FAILED";

// Get Role
export const GET_ROLE = "GET_ROLE_REQUEST";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILED = "GET_ROLE_FAILED";

// Add Role
export const ADD_ROLE = "ADD_ROLE_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILED = "ADD_ROLE_FAILED";

// Edit Role
export const EDIT_ROLE = "EDIT_ROLE_REQUEST";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE_FAILED = "EDIT_ROLE_FAILED";

// Delete Role
export const DELETE_ROLE = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILED = "DELETE_ROLE_FAILED";

// Get Users
export const GET_USERS = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

// Get Permissions
export const GET_PERMISSIONS = "GET_PERMISSIONS_REQUEST";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILED = "GET_PERMISSIONS_FAILED";

// Get S3Profile
export const GET_S3PROFILE = "GET_S3PROFILE_REQUEST";
export const GET_S3PROFILE_SUCCESS = "GET_S3PROFILE_SUCCESS";
export const GET_S3PROFILE_FAILED = "GET_S3PROFILE_FAILED";

// Add S3Profile
export const ADD_S3PROFILE = "ADD_S3PROFILE_REQUEST";
export const ADD_S3PROFILE_SUCCESS = "ADD_S3PROFILE_SUCCESS";
export const ADD_S3PROFILE_FAILED = "ADD_S3PROFILE_FAILED";
export const ADD_MODAL_S3PROFILE = "ADD_MODAL_S3PROFILE";

// Delete S3Profile
export const DELETE_S3PROFILE = "DELETE_S3PROFILE_REQUEST";
export const DELETE_S3PROFILE_SUCCESS = "DELETE_S3PROFILE_SUCCESS";
export const DELETE_S3PROFILE_FAILED = "DELETE_S3PROFILE_FAILED";

// Edit S3Profile
export const EDIT_S3PROFILE = "EDIT_S3PROFILE_REQUEST";
export const EDIT_S3PROFILE_SUCCESS = "EDIT_S3PROFILE_SUCCESS";
export const EDIT_S3PROFILE_FAILED = "EDIT_S3PROFILE_FAILED";

// Add Connector
export const ADD_MODAL_CONNECTOR = "ADD_MODAL_CONNECTOR";

// Jira Server
export const GET_JIRASERVER = "GET_JIRASERVER_REQUEST";
export const GET_JIRASERVER_SUCCESS = "GET_JIRASERVER_SUCCESS";
export const GET_JIRASERVER_FAILED = "GET_JIRASERVER_FAILED";

export const GET_ONE_JIRASERVER = "GET_ONE_JIRASERVER_REQUEST";
export const GET_ONE_JIRASERVER_SUCCESS = "GET_ONE_JIRASERVER_SUCCESS";
export const GET_ONE_JIRASERVER_FAILED = "GET_ONE_JIRASERVER_FAILED";

export const ADD_JIRASERVER = "ADD_JIRASERVER_REQUEST";
export const ADD_JIRASERVER_SUCCESS = "ADD_JIRASERVER_SUCCESS";
export const ADD_JIRASERVER_FAILED = "ADD_JIRASERVER_FAILED";

export const EDIT_JIRASERVER = "EDIT_JIRASERVER_REQUEST";
export const EDIT_JIRASERVER_SUCCESS = "EDIT_JIRASERVER_SUCCESS";
export const EDIT_JIRASERVER_FAILED = "EDIT_JIRASERVER_FAILED";

export const DELETE_JIRASERVER = "DELETE_JIRASERVER_REQUEST";
export const DELETE_JIRASERVER_SUCCESS = "DELETE_JIRASERVER_SUCCESS";
export const DELETE_JIRASERVER_FAILED = "DELETE_JIRASERVER_FAILED";

// // Jira Cloud
export const GET_JIRACLOUD = "GET_JIRACLOUD_REQUEST";
export const GET_JIRACLOUD_SUCCESS = "GET_JIRACLOUD_SUCCESS";
export const GET_JIRACLOUD_FAILED = "GET_JIRACLOUD_FAILED";

export const GET_ONE_JIRACLOUD = "GET_ONE_JIRACLOUD_REQUEST";
export const GET_ONE_JIRACLOUD_SUCCESS = "GET_ONE_JIRACLOUD_SUCCESS";
export const GET_ONE_JIRACLOUD_FAILED = "GET_ONE_JIRACLOUD_FAILED";

export const ADD_JIRACLOUD = "ADD_JIRACLOUD_REQUEST";
export const ADD_JIRACLOUD_SUCCESS = "ADD_JIRACLOUD_SUCCESS";
export const ADD_JIRACLOUD_FAILED = "ADD_JIRACLOUD_FAILED";

export const EDIT_JIRACLOUD = "EDIT_JIRACLOUD_REQUEST";
export const EDIT_JIRACLOUD_SUCCESS = "EDIT_JIRACLOUD_SUCCESS";
export const EDIT_JIRACLOUD_FAILED = "EDIT_JIRACLOUD_FAILED";

export const DELETE_JIRACLOUD = "DELETE_JIRACLOUD_REQUEST";
export const DELETE_JIRACLOUD_SUCCESS = "DELETE_JIRACLOUD_SUCCESS";
export const DELETE_JIRACLOUD_FAILED = "DELETE_JIRACLOUD_FAILED";

// // Connect Wise
export const GET_CONNECTWISE = "GET_CONNECTWISE_REQUEST";
export const GET_CONNECTWISE_SUCCESS = "GET_CONNECTWISE_SUCCESS";
export const GET_CONNECTWISE_FAILED = "GET_CONNECTWISE_FAILED";

export const GET_ONE_CONNECTWISE = "GET_ONE_CONNECTWISE_REQUEST";
export const GET_ONE_CONNECTWISE_SUCCESS = "GET_ONE_CONNECTWISE_SUCCESS";
export const GET_ONE_CONNECTWISE_FAILED = "GET_ONE_CONNECTWISE_FAILED";

export const ADD_CONNECTWISE = "ADD_CONNECTWISE_REQUEST";
export const ADD_CONNECTWISE_SUCCESS = "ADD_CONNECTWISE_SUCCESS";
export const ADD_CONNECTWISE_FAILED = "ADD_CONNECTWISE_FAILED";

export const EDIT_CONNECTWISE = "EDIT_CONNECTWISE_REQUEST";
export const EDIT_CONNECTWISE_SUCCESS = "EDIT_CONNECTWISE_SUCCESS";
export const EDIT_CONNECTWISE_FAILED = "EDIT_CONNECTWISE_FAILED";

export const DELETE_CONNECTWISE = "DELETE_CONNECTWISE_REQUEST";
export const DELETE_CONNECTWISE_SUCCESS = "DELETE_CONNECTWISE_SUCCESS";
export const DELETE_CONNECTWISE_FAILED = "DELETE_CONNECTWISE_FAILED";

// // Freshservice
export const GET_FRESHSERVICE = "GET_FRESHSERVICE_REQUEST";
export const GET_FRESHSERVICE_SUCCESS = "GET_FRESHSERVICE_SUCCESS";
export const GET_FRESHSERVICE_FAILED = "GET_FRESHSERVICE_FAILED";

export const GET_ONE_FRESHSERVICE = "GET_ONE_FRESHSERVICE_REQUEST";
export const GET_ONE_FRESHSERVICE_SUCCESS = "GET_ONE_FRESHSERVICE_SUCCESS";
export const GET_ONE_FRESHSERVICE_FAILED = "GET_ONE_FRESHSERVICE_FAILED";

export const ADD_FRESHSERVICE = "ADD_FRESHSERVICE_REQUEST";
export const ADD_FRESHSERVICE_SUCCESS = "ADD_FRESHSERVICE_SUCCESS";
export const ADD_FRESHSERVICE_FAILED = "ADD_FRESHSERVICE_FAILED";

export const EDIT_FRESHSERVICE = "EDIT_FRESHSERVICE_REQUEST";
export const EDIT_FRESHSERVICE_SUCCESS = "EDIT_FRESHSERVICE_SUCCESS";
export const EDIT_FRESHSERVICE_FAILED = "EDIT_FRESHSERVICE_FAILED";

export const DELETE_FRESHSERVICE = "DELETE_FRESHSERVICE_REQUEST";
export const DELETE_FRESHSERVICE_SUCCESS = "DELETE_FRESHSERVICE_SUCCESS";
export const DELETE_FRESHSERVICE_FAILED = "DELETE_FRESHSERVICE_FAILED";

// // Email Backends
// Get Email-profile
export const GET_EMAIL_PROFILE = "GET_EMAIL_PROFILE_REQUEST";
export const GET_EMAIL_PROFILE_SUCCESS = "GET_EMAIL_PROFILE_SUCCESS";
export const GET_EMAIL_PROFILE_FAILED = "GET_EMAIL_PROFILE_FAILED";

// Add Email-profile
export const ADD_EMAIL_PROFILE = "ADD_EMAIL_PROFILE_REQUEST";
export const ADD_EMAIL_PROFILE_SUCCESS = "ADD_EMAIL_PROFILE_SUCCESS";
export const ADD_EMAIL_PROFILE_FAILED = "ADD_EMAIL_PROFILE_FAILED";

// Edit Email-profile
export const EDIT_EMAIL_PROFILE = "EDIT_EMAIL_PROFILE_REQUEST";
export const EDIT_EMAIL_PROFILE_SUCCESS = "EDIT_EMAIL_PROFILE_SUCCESS";
export const EDIT_EMAIL_PROFILE_FAILED = "EDIT_EMAIL_PROFILE_FAILED";

// Delete Email-profile
export const DELETE_EMAIL_PROFILE = "DELETE_EMAIL_PROFILE_REQUEST";
export const DELETE_EMAIL_PROFILE_SUCCESS = "DELETE_EMAIL_PROFILE_SUCCESS";
export const DELETE_EMAIL_PROFILE_FAILED = "DELETE_EMAIL_PROFILE_FAILED";

// User Profiles
export const GET_USERPROFILES = "GET_USERPROFILES_REQUEST";
export const GET_USERPROFILES_SUCCESS = "GET_USERPROFILES_SUCCESS";
export const GET_USERPROFILES_FAILED = "GET_USERPROFILES_FAILED";

export const SET_USERPROFILES_PARAMS = "SET_USERPROFILES_PARAMS";

export const GET_ONE_USERPROFILE = "GET_ONE_USERPROFILE_REQUEST";
export const GET_ONE_USERPROFILE_SUCCESS = "GET_ONE_USERPROFILE_SUCCESS";
export const GET_ONE_USERPROFILE_FAILED = "GET_ONE_USERPROFILE_FAILED";

export const ADD_USERPROFILES = "ADD_USERPROFILES_REQUEST";
export const ADD_USERPROFILES_SUCCESS = "ADD_USERPROFILES_SUCCESS";
export const ADD_USERPROFILES_FAILED = "ADD_USERPROFILES_FAILED";

export const EDIT_USERPROFILES = "EDIT_USERPROFILES_REQUEST";
export const EDIT_USERPROFILES_SUCCESS = "EDIT_USERPROFILES_SUCCESS";
export const EDIT_USERPROFILES_FAILED = "EDIT_USERPROFILES_FAILED";

export const DELETE_USERPROFILES = "DELETE_USERPROFILES_REQUEST";
export const DELETE_USERPROFILES_SUCCESS = "DELETE_USERPROFILES_SUCCESS";
export const DELETE_USERPROFILES_FAILED = "DELETE_USERPROFILES_FAILED";

export const GET_USERPROFILES_GROUP = "GET_USERPROFILES_GROUP_REQUEST";
export const GET_USERPROFILES_GROUP_SUCCESS = "GET_USERPROFILES_GROUP_SUCCESS";
export const GET_USERPROFILES_GROUP_FAILED = "GET_USERPROFILES_GROUP_FAILED";

// Labels
export const SET_LABELS_PARAMS = "SET_LABELS_PARAMS";

export const GET_SETTINGS_LABELS = "GET_SETTINGS_LABELS_REQUEST";
export const GET_SETTINGS_LABELS_SUCCESS = "GET_SETTINGS_LABELS_SUCCESS";
export const GET_SETTINGS_LABELS_FAILED = "GET_SETTINGS_LABELS_FAILED";

export const GET_LABEL = "GET_LABEL_REQUEST";
export const GET_LABEL_SUCCESS = "GET_LABEL_SUCCESS";
export const GET_LABEL_FAILED = "GET_LABEL_FAILED";

export const ADD_LABEL = "ADD_LABEL_REQUEST";
export const ADD_LABEL_SUCCESS = "ADD_LABEL_SUCCESS";
export const ADD_LABEL_FAILED = "ADD_LABEL_FAILED";

export const EDIT_LABEL = "EDIT_LABEL_REQUEST";
export const EDIT_LABEL_SUCCESS = "EDIT_LABEL_SUCCESS";
export const EDIT_LABEL_FAILED = "EDIT_LABEL_FAILED";

export const DELETE_SETTINGS_LABEL = "DELETE_SETTINGS_LABEL_REQUEST";
export const DELETE_SETTINGS_LABEL_SUCCESS = "DELETE_SETTINGS_LABEL_SUCCESS";
export const DELETE_SETTINGS_LABEL_FAILED = "DELETE_SETTINGS_LABEL_FAILED";

// tickets-config
export const GET_TICKETS_CONFIG = "GET_TICKETS_CONFIGURATION_REQUEST";
export const GET_TICKETS_CONFIG_SUCCESS = "GET_TICKETS_CONFIGURATION_SUCCESS";
export const GET_TICKETS_CONFIG_FAILED = "GET_TICKETS_CONFIGURATION_FAILED";

export const SHOW_TICKETS_CONFIG_MODAL = "SHOW_TICKETS_CONFIG_MODAL";

export const GET_TICKET_CONFIG = "GET_TICKET_CONFIGURATION_REQUEST";
export const GET_TICKET_CONFIG_SUCCESS = "GET_TICKET_CONFIGURATION_SUCCESS";
export const GET_TICKET_CONFIG_FAILED = "GET_TICKET_CONFIGURATION_FAILED";

export const ADD_TICKET_CONFIG = "ADD_TICKET_CONFIGURATION_REQUEST";
export const ADD_TICKET_CONFIG_SUCCESS = "ADD_TICKET_CONFIGURATION_SUCCESS";
export const ADD_TICKET_CONFIG_FAILED = "ADD_TICKET_CONFIGURATION_FAILED";

export const EDIT_TICKET_CONFIG = "EDIT_TICKET_CONFIGURATION_REQUEST";
export const EDIT_TICKET_CONFIG_SUCCESS = "EDIT_TICKET_CONFIGURATION_SUCCESS";
export const EDIT_TICKET_CONFIG_FAILED = "EDIT_TICKET_CONFIGURATION_FAILED";

export const DELETE_TICKET_CONFIG = "DELETE_TICKET_CONFIGURATION_REQUEST";
export const DELETE_TICKET_CONFIG_SUCCESS = "DELETE_TICKET_CONFIGURATION_SUCCESS";
export const DELETE_TICKET_CONFIG_FAILED = "DELETE_TICKET_CONFIGURATION_FAILED";

export const GET_JIRA_ALL = "GET_JIRA_ALL_REQUEST";
export const GET_JIRA_ALL_SUCCESS = "GET_JIRA_ALL_SUCCESS";
export const GET_JIRA_ALL_FAILED = "GET_JIRA_ALL_FAILED";

export const GET_FRESHSERVICE_CONFIG = "GET_FRESHSERVICE_CONFIG_REQUEST";
export const GET_FRESHSERVICE_CONFIG_SUCCESS = "GET_FRESHSERVICE_CONFIG_SUCCESS";
export const GET_FRESHSERVICE_CONFIG_FAILED = "GET_FRESHSERVICE_CONFIG_FAILED";

export const GET_CONNECTWISE_CONFIG = "GET_CONNECTWISE_CONFIG_REQUEST";
export const GET_CONNECTWISE_CONFIG_SUCCESS = "GET_CONNECTWISE_CONFIG_SUCCESS";
export const GET_CONNECTWISE_CONFIG_FAILED = "GET_CONNECTWISE_CONFIG_FAILED";

export const GET_TEMPLATE_LIST_MINI = "GET_TEMPLATE_LIST_MINI_REQUEST";
export const GET_TEMPLATE_LIST_MINI_SUCCESS = "GET_TEMPLATE_LIST_MINI_SUCCESS";
export const GET_TEMPLATE_LIST_MINI_FAILED = "GET_TEMPLATE_LIST_MINI_FAILED";

export const GET_STATUS_FOR_TICKET_CONFIG = "GET_STATUS_FOR_TICKET_CONFIG_REQUEST";
export const GET_STATUS_FOR_TICKET_CONFIG_SUCCESS = "GET_STATUS_FOR_TICKET_CONFIG_SUCCESS";
export const GET_STATUS_FOR_TICKET_CONFIG_FAILED = "GET_STATUS_FOR_TICKET_CONFIG_FAILED";

export const PERMISSIONS_MODAL = "PERMISSIONS_MODAL";
