import React from "react";
import PropTypes from "prop-types";
import { styled, Button } from "@mui/material";
import Loader from "components/simple/Loader";

import Icon from "../Icon";

const Btn = styled(Button)(({ theme, variant }) => ({
  padding: "8px 12px",
  background: variant === "outline" ? "#fff" : theme.palette.primary.main,
  border: variant === "outline" ? `1px solid ${theme.palette.primary.main}` : "",
  borderRadius: "10px",
  color: variant === "outline" ? theme.palette.primary.main : "#FFFFFF",
  fontSize: "14px",
  fontWeight: "500",
  textTransform: "none",
  minWidth: "127px",
  "&:hover": {
    background:
      variant === "outline" ? `rgba(${theme.palette.primary.dark}, .5)` : theme.palette.primary.dark
  },
  "&:active": {
    background:
      variant === "outline" ? `rgba(${theme.palette.primary.dark}, .5)` : theme.palette.primary.dark
  },
  "&:disabled": {
    color: "#FFFFFF !important",
    background: "#A1A9BE !important",
    border: "none !important"
  },
  "&.loading": {
    background:
      variant === "outline" ? `rgba(${theme.palette.primary.dark}, .5)` : theme.palette.primary.dark
  }
}));

const IconButton = styled("div")(({ theme, variant }) => ({
  alignItems: "center",
  display: "flex",
  marginLeft: "15px",
  "& svg path": {
    fill: variant === "outline" ? theme.palette.primary.main : "#fff"
  }
}));

export default function ButtonComponent(props) {
  const {
    className = "",
    children,
    disabled,
    icon,
    variant,
    isLoading,
    iconProps,
    ...buttonProps
  } = props;
  return (
    <Btn
      className={`${className} ${isLoading ? "loading" : ""}`}
      {...buttonProps}
      disabled={disabled}
      variant={variant}
    >
      {!isLoading ? children : <Loader size={20} />}

      {icon ? <IconButton variant={variant}>{iconProps}</IconButton> : null}
    </Btn>
  );
}

ButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  variant: PropTypes.string,
  iconProps: PropTypes.element
};

ButtonComponent.defaultProps = {
  className: "",
  isLoading: false,
  disabled: false,
  icon: "",
  variant: "",
  iconProps: <Icon.Plus />
};
