import React from "react";

import { getAuditFileLabels } from "store/entities/auditfiles/service";

import { SelectAsync } from "components/simple";

const LabelSelect = ({ onInputChange = () => null, ...rest }) => {
  const request = async () => {
    try {
      const res = await getAuditFileLabels(rest?.audit).then(r => r.json());
      const listOptions = res?.map(item => ({
        value: item?.id,
        label: item?.name
      }));
      return { options: listOptions };
    } catch (e) {
      // return console.log("err", e);
    }
  };
  const loadOptions = async () => {
    const { options } = await request();
    return { options };
  };
  return <SelectAsync {...rest} loadOptions={loadOptions} onInputChange={onInputChange} />;
};

export default LabelSelect;
