import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { COLORS } from "./colors";
import NoChartData from "./NoData";
import { toolTipWrapper, legendWrapper } from "./style";
function StackedBarchart({ data, dataKey, toolTipStyle, LegendStyle, onClick }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      {data?.length > 0 ? (
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis stroke="black" style={{ fontSize: "12", fontWeight: "600" }} dataKey="name" />
          <YAxis />
          <Tooltip cursor={{ fill: "#ffffff" }} wrapperStyle={toolTipStyle} />
          <Legend wrapperStyle={LegendStyle} />

          {dataKey?.map((e, i) => {
            return (
              <Bar
                className="cursor-pointer"
                dataKey={e.name}
                stackId="a"
                fill={e.color}
                onClick={xAxisInfo => {
                  onClick(xAxisInfo);
                }}
              />
            );
          })}
        </BarChart>
      ) : (
        <NoChartData />
      )}
    </ResponsiveContainer>
  );
}
StackedBarchart.defaultProps = {
  data: [],
  dataKey: [],
  toolTipStyle: toolTipWrapper,
  LegendStyle: legendWrapper
};
export default StackedBarchart;
