/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { routes } from "router";
import { onChangeUrlParams, useQuery } from "helper/history";

import {
  deleteReport,
  getReportPeriods,
  getReports,
  setReportsParams
} from "store/entities/reports/actions";
import { addNotification } from "store/entities/notifications/actions";
import { getReportView } from "store/entities/reports/service";

import {
  convertedDaysOfMonthOptionsSelector,
  daysOfWeekOptionsSelector,
  reportPeriodsOptionsSelector,
  reportsSelector
} from "store/entities/reports/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import {
  ContentContainer,
  Title,
  Search,
  Button,
  ActionButton,
  Loader,
  CustomPagination
} from "components/simple";
import { Table } from "components/complex";
import RunModal from "./RunModal";

import styles from "./styles.module.scss";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

const Reports = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getReports.type])(state));
  const [isViewLoading, setViewLoading] = useState(false);

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const { page, pageSize } = useSelector(state => state.reports.reportsParams);

  const { columns, data, total } = useSelector(state => reportsSelector(state));

  const reportPeriodsOptions = useSelector(reportPeriodsOptionsSelector);
  const daysOfWeek = useSelector(daysOfWeekOptionsSelector);
  const daysOfMonth = useSelector(convertedDaysOfMonthOptionsSelector);

  const [runModal, showRunModal] = useState(false);

  // get default request
  const getRequest = () => {
    dispatch(
      setReportsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: ""
      })
    );
  };
  // get periods
  const getPeriods = () => dispatch(getReportPeriods());

  const formattedData = data?.map(item => {
    return {
      ...item,
      period: reportPeriodsOptions.find(({ value }) => +value === item.period)?.label,
      day_of_week: item?.day_of_week
        ?.map(day => daysOfWeek.find(({ value }) => +value === day)?.label)
        .join(", "),
      day_of_the_month: item?.day_of_the_month
        ?.map(day => daysOfMonth.find(({ value }) => +value === day)?.label)
        .join(", ")
    };
  });

  useEffect(() => {
    getRequest();
    getPeriods();
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel?.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setReportsParams({
        page: 0,
        search: v
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setReportsParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setReportsParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setReportsParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = (event, newPage) => {
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setReportsParams({
          page: newPage
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setReportsParams({
        pageSize: v
      })
    );
  };

  const uploadReportView = async (id, { name, email, period, pages }) => {
    setViewLoading(true);
    const pdf = await getReportView({
      id,
      data: {
        name,
        email,
        period,
        pages: pages.split(",")
      }
    })
      .then(res => {
        setViewLoading(false);
        return res.blob();
      })
      .catch(() => {
        setViewLoading(false);
        dispatch(addNotification({ msg: "Download failed", type: "error" }));
      });
    const file = new Blob([pdf], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
    const { id, field, row } = p;

    if (isViewLoading) return;
    if (field === "view") {
      uploadReportView(id, row);
      return;
    }

    history.push(`${routes.reportsDetails}/${id}`);
  };

  // Actions
  const handleAction = type => {
    if (!selectionModel?.length) {
      dispatch(addNotification({ msg: "You must select at least one row", type: "warning" }));
      return;
    }

    if (type === "delete") {
      dispatch(deleteReport({ ids: selectionModel }));
      return;
    }

    if (type === "run") {
      const reports = data.filter(r => selectionModel.includes(r.id));
      showRunModal(reports);
    }
  };

  const createReportConfigHandler = () => {
    history.push(routes.reportsDetails);
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  return (
    <ContentContainer className={styles.container}>
      {isViewLoading && <Loader className={styles.loader} />}
      <Title>Report</Title>
      <div className={styles.searchRow}>
        <Search onSearch={handleSearch} />

        <Button icon="plus" onClick={createReportConfigHandler} className={styles.btn}>
          Add report configuration
        </Button>
      </div>

      <div className={styles.actionsRow}>
        <div className={styles.actions}>
          <ActionButton type="run" onClick={handleAction} className={styles.actionbtn} />
          <ActionButton type="delete" onClick={handleAction} className={styles.actionbtn} />
        </div>
      </div>
      <div className={styles.table}>
        <Table
          data={isLoading ? [] : formattedData}
          columns={columns}
          loading={isLoading}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          page={page || 0}
          selectionModel={selectionModel}
          onSortModelChange={model => handleSort(model)}
          pageSize={pageSize}
          // rowsPerPageOptions={[5, 10, 20, 50]}
          // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          // onPageChange={onPageChange}
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
          components={{
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(total, pageSize),
              onChange: onPageChange,
              page: page + 1,
              totalCount: total || 0,
              selected: pageSize,
              perPageChange: (event, value) => {
                setPageSize(event?.target?.value);
              }
            }
          }}
        />
      </div>
      <RunModal open={Boolean(runModal)} onClose={() => showRunModal(false)} data={runModal} />
    </ContentContainer>
  );
};

Reports.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Reports;
