import React from "react";
import { formatDate } from "helper/DateLib";

import { ContentContainer, InputSearch, Title } from "components/simple";

import styles from "../styles.module.scss";

const History = ({ list, name, searchText, onSearch }) => {
  return (
    <ContentContainer>
      <div className={`${styles.headerSection} ${styles.searchHeader}`}>
        <Title>History</Title>
        <InputSearch
          value={searchText}
          onChange={val => onSearch(name, val)}
          placeholder="Search"
        />
      </div>

      <div className={`${styles.scrollableList} ${styles.historyList}`}>
        <div className={`${styles.listElem} ${styles.headElem}`}>
          <span>Date</span>
          <span>Status</span>
        </div>
        <ul>
          {list.length ? (
            list.map((elem, index) => (
              <li key={index} className={styles.listElem}>
                <span>{formatDate(elem.date, "DD/MM/YYYY")}</span>
                <div
                  className={`${styles.status} ${
                    elem.status !== "Active" ? styles.disconnected : ""
                  }`}
                >
                  {elem.status}
                </div>
              </li>
            ))
          ) : (
            <div className={styles.noData}>No data</div>
          )}
        </ul>
      </div>
    </ContentContainer>
  );
};

export default History;
