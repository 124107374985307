import React from "react";
import IconButton from "@mui/material/IconButton";
import {
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector
} from "@mui/x-data-grid-pro";

import { Icon } from "components/simple";
import styles from "./styles.module.scss";

function CustomDetailPanelToggle(props) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);
  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? "Close" : "Open"}
    >
      <Icon.ExpendArrow className={isExpanded ? styles.rotate180 : styles.rotateDef} />
    </IconButton>
  );
}

export default CustomDetailPanelToggle;
