import yaml from 'js-yaml';

export const parseYAML = (y) => {
    try {
      yaml.load(y);
      return [];
    } catch (e) {
      return [e];
    }
  };
