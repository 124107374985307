import React from "react";
import PropTypes from "prop-types";

export default function Warning(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.001 11C11.001 10.4477 11.4487 10 12.001 10V10C12.5533 10 13.001 10.4477 13.001 11V14C13.001 14.5523 12.5533 15 12.001 15V15C11.4487 15 11.001 14.5523 11.001 14V11ZM11 17C11 16.4477 11.4477 16 12 16V16C12.5523 16 13 16.4477 13 17V17C13 17.5523 12.5523 18 12 18V18C11.4477 18 11 17.5523 11 17V17Z"
        fill={props.color}
      />
      <path
        d="M13.7689 4.20067C13.4209 3.54567 12.7429 3.13867 12.0009 3.13867C11.2589 3.13867 10.5809 3.54567 10.2329 4.20167L2.89493 18.0647C2.73209 18.3691 2.65143 18.7107 2.6609 19.0558C2.67037 19.4009 2.76964 19.7376 2.94893 20.0327C3.12562 20.3291 3.37657 20.5742 3.67697 20.744C3.97738 20.9137 4.31687 21.0022 4.66192 21.0007H19.3399C20.0479 21.0007 20.6889 20.6387 21.0539 20.0327C21.2329 19.7375 21.332 19.4009 21.3415 19.0558C21.351 18.7108 21.2705 18.3692 21.1079 18.0647L13.7689 4.20067ZM4.66192 19.0007L12.0009 5.13767L19.3449 19.0007H4.66192Z"
        fill={props.color}
      />
    </svg>
  );
}

Warning.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Warning.defaultProps = {
  className: "",
  style: {},
  color: "#D83E3C",
  width: 24,
  height: 24
};
