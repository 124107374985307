import React, { useState } from "react";

import { Controller } from "react-hook-form";
import { ErrorText, Textarea } from "components/simple";

export function isValidJSON(v) {
  try {
    const ReplaceBlockLine = v.replaceAll("\n", "");
    JSON.parse(ReplaceBlockLine);
  } catch (e) {
    return false;
  }
  return true;
}

export default function JSONKey({ isEdit, control, errors }) {
  const [isFocusedBefore, setIsFocusedBefore] = useState(false);

  return (
    <div className="w-full mb-3.5 mt-3">
      <Controller
        name="jsonKey"
        rules={{
          required: { value: !isEdit, message: "Service Account JSON Key is required!" },
          maxLength: { value: 3072, message: "Maximum 3072 characters allowed!" },
          validate: v => {
            if (isEdit) {
              if (v === undefined || v === "" || v === "{*****}") {
                return true;
              }
              return isValidJSON(v) || "Please Enter in JSON format";
            }
            return isValidJSON(v) || "Please Enter in JSON format";
          }
        }}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Textarea
            name="jsonKey"
            label="Service Account JSON Key"
            placeholder="Enter JSON key"
            required
            value={value}
            onFocus={evt => {
              onChange(isFocusedBefore ? evt.target.value : "");
              setIsFocusedBefore(true);
            }}
            onChange={val => {
              onChange(val);
            }}
          />
        )}
      />
      <div className="m-0.5">
        <ErrorText errorMsg={errors.jsonKey && errors.jsonKey.message} />
      </div>
    </div>
  );
}
