import React from "react";
import styles from "./styles.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { goBack } from "helper/history";

const AuthFooter = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerContent} onClick={() => goBack()}>
          <ArrowBackIcon sx={{ color: "#fff", fontSize: "20px" }} /> Back
        </div>
      </div>
    </div>
  );
};

export default AuthFooter;
