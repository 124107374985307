import React from "react";
import JSONKey from "./Fields/JSONKey";
import RegistryRegion from "./Fields/RegistryRegion";
import { useGetGCRRegionQuery } from "store/entities/registry/queries";

export const GCRForm = ({ isEdit, registryId, control, errors }) => {
  const { data: GCRRegions } = useGetGCRRegionQuery(registryId);

  return (
    <>
      <RegistryRegion title="GCR Region*" placeholder="Select GCR Region" options={GCRRegions?.data} registryId={registryId} control={control} errors={errors} />
      <JSONKey isEdit={isEdit} control={control} errors={errors} />
    </>
  );
};
