/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { routes } from "router";
import { assignAssetsLeftTableColumns, assignAssetsRightTableColumns } from "../../../tableColumns";

import {
  getAssingAssetsLeftTableSelector,
  getAssingAssetsRightTableSelector
} from "store/entities/baseline/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import {
  addBaselineAsset,
  deleteBaselineAsset,
  setAssignHostsParams,
  getBaselineAssetsMini,
  getBaseline
} from "store/entities/baseline/actions";

import AssignTables from "../AssignedTables";

const AssignedHosts = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const [searchVal, setSearchVal] = useState({
    leftTable: "",
    rightTable: ""
  });

  const selectTenant = useSelector(state => state.users.selectTenant);

  const isLoadingGetBaseline = useSelector(state =>
    createLoadingSelector([getBaseline.type])(state)
  );
  const isLoadingGetAssets = useSelector(state =>
    createLoadingSelector([getBaselineAssetsMini.type])(state)
  );
  const isLoadingAddAsset = useSelector(state =>
    createLoadingSelector([addBaselineAsset.type])(state)
  );

  const [baselineAssets, setBaselineAssets] = useState([]);

  const dataLeftTable = useSelector(getAssingAssetsLeftTableSelector);
  const dataRightTable = useSelector(getAssingAssetsRightTableSelector);

  const baselineId = params?.id;

  // get default request
  const getRequest = () => {
    dispatch(
      setAssignHostsParams({
        label: "",
        search: "",
        baseline_id: baselineId
      })
    );
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  useEffect(() => {
    setBaselineAssets(dataRightTable);
  }, [dataRightTable]);

  // Search
  const submitLeftTableSearch = text => {
    dispatch(
      setAssignHostsParams({
        search: text
      })
    );
  };

  const submitRightTableSearch = text => {
    if (text === "") setBaselineAssets(dataRightTable);
    const newList = dataRightTable.filter(elem =>
      elem.title.toLowerCase().includes(text.toLowerCase())
    );
    setBaselineAssets(newList);
  };

  const handleSearch = (val, name) => {
    setSearchVal({
      ...searchVal,
      [name]: val
    });
  };
  //

  const onCellClick = p => {
    const {
      field,
      id: uid,
      row: { title }
    } = p;

    if (field === "failed_findings") {
      history.push({
        pathname: `${routes.failedBaseline}/${baselineId}`,
        search: `?title=${title}`
      });
    }
    if (field === "delete") {
      dispatch(deleteBaselineAsset({ id: baselineId, data: { id: uid } }));
    }
  };

  const addAssetHandler = list => {
    dispatch(
      addBaselineAsset({
        id: baselineId,
        data: {
          ids: [...list]
        }
      })
    );
  };

  return (
    <AssignTables
      columnsLeftTable={assignAssetsLeftTableColumns}
      columnsRightTable={assignAssetsRightTableColumns}
      dataLeftTable={dataLeftTable}
      dataRightTable={baselineAssets}
      isLoadingLeftTable={isLoadingGetAssets}
      isLoadingOnAdd={isLoadingAddAsset}
      isLoadingRightTable={isLoadingGetBaseline}
      titleLeftTable="Assigned hosts"
      onCellClick={onCellClick}
      onSearch={handleSearch}
      onSubmitLeftTableSearch={submitLeftTableSearch}
      onSubmitRightTableSearch={submitRightTableSearch}
      onAdd={addAssetHandler}
      searchVal={searchVal}
    />
  );
};

AssignedHosts.propTypes = {};

export default AssignedHosts;
