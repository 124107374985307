import { Box } from "@mui/material";
import { Table } from "components/complex";
import { Button, ContentContainer, Search, Select, Title } from "components/simple";
import React, { useCallback } from "react";
import { getMinionsList } from "store/entities/minions/query";
import { minionsHeaders } from "./columns";
import MinionsTable from "./minionsTable";
import { minionMockData2 } from "./mockData";

function Minions() {
  const getDetailPanelContent = useCallback(({ row }) => <></>, []);
  const fetchMinionsList = getMinionsList();
  const handleAddNewMinion = () => {};
  return (
    <ContentContainer>
      <Title className="m-4">Minions</Title>
      <Box className="flex justify-between mx-2 my-2">
        <Box>
          <Search />
        </Box>
        <Box className="flex gap-1">
          <Button icon="plus" onClick={handleAddNewMinion}>
            Add Minion
          </Button>
          {/* <Button on>Delete</Button> */}
        </Box>
      </Box>
      <Box className="m-2 w-52">
        <Select
          // containerClass={styles.select}
          placeholder="Filter by: Status"
          options={[]}
          isClearable
          // value={type}
          // onChange={handleTargetTypeChange}
        />
      </Box>
      <MinionsTable
        rows={fetchMinionsList?.data?.results || []}
        count={fetchMinionsList?.data?.results?.length || 0}
        loading={fetchMinionsList.isLoading}
      />
    </ContentContainer>
  );
}

export default Minions;
