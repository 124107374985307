/*eslint-disable */

import React, { useState, useEffect } from "react";
import {
  ContentContainer,
  Title,
  Select,
  RefreshInterval,
  Button,
  CustomPagination,
  Tags
} from "components/simple";
import Loader from "components/simple/Loader";
import { Box, Drawer } from "@mui/material";
import styled from "styled-components";
import DateRangePicker from "components/simple/DateRangePicker/DateRangePicker-2";
import styles from "../styles.module.scss";
import "../inspector.css";
import { useClustersQuery, useNamespacesQuery } from "store/entities/filters/queries";
import {
  getSelectedInfraType,
  setSelectedInfraType,
  getSelectedComponentType,
  setSelectedComponentType,
  getSelectedClusters,
  setSelectedClusters,
  getSelectedNamespaces,
  setSelectedNamespace
} from "store/entities/filters/slice";
import { useDispatch, useSelector } from "react-redux";
import { logsChart, RefreshTime, SYSTEM, timeRange } from "../Constants";
import Barchart from "../components/charts/Barchart";
import LogFields from "../components/LogFields";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Summary from "../components/Summary";
import CustomTableSeverityComponent from "components/simple/SeverityBasedTable/CustomTableSeverityComponent";
import {
  useGetAllFilters,
  useGetDynamicData,
  useGetDynamicDataKeys,
  useGetLogsCount,
  useGetLogsData
} from "store/entities/datapipeline/queries";
import SaveFilterModal from "../components/SaveFilter/SaveFilterModal";
import CreateTriggerModal from "../components/CreateTrigger/CreateTriggerModal";
import useInterval from "../hooks/useInterval";
import TablePagination from "@mui/material/TablePagination";
import { usePrimaryLayoutTitle } from "../Context/Context";
import { buildQString, convertLogsData, downloadFile, getDifferenceTime } from "../helper";
import DynamicFilterLogs from "components/complex/DynamicFilter/CustomFilter";
import { Table } from "components/complex";
import moment from "moment-timezone";
import { useHistory, useLocation } from "react-router-dom";
import Inspector from "react-json-inspector";
import _ from "lodash";
import { addNotification } from "store/entities/notifications/actions";
import EditModal from "../components/EditModal";
import EditTrigger from "../components/EditTrigger/EditTrigger";
import CustomSelect from "components/simple/CustomSelect";
import { useQuery } from "helper/history";
import { useMemo } from "react";
import { useDatapipelineState } from "store/entities/datapipeline/slice";

const Logs = () => {
  const { logdDslTag } = usePrimaryLayoutTitle();
  const dispatch = useDispatch();
  const { data: clusters = {} } = useClustersQuery();
  const selectedClusters = useSelector(getSelectedClusters);
  const { data: namespaces = {} } = useNamespacesQuery(selectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedInfraType = useSelector(getSelectedInfraType);
  const selectedComponentType = useSelector(getSelectedComponentType);
  const [refreshTimeVal, setRefreshTimeVal] = useState(RefreshTime[4].label);
  const [refreshTimeinsec, setRefreshTimeinsec] = useState(RefreshTime[4].value);
  const [logFields, setLogFields] = useState(true);
  const [histogram, setHistogram] = useState(false);
  const [selectedExport, setSelectedExport] = useState("");
  const [drawer, setDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isTriggerOpen, setIsTriggerOpen] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [localDSL, setLocalDSL] = useState(logdDslTag);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [pouUp, setShowPopup] = useState(false);
  const [showEditTrigger, setShowEditTrigger] = useState(false);
  const [dropValues, setDropValues] = useState([]);
  const selectedFilter = useDatapipelineState("selectedFilter");
  const [edit, setEdit] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const arrTrigger = [];
  arrTrigger?.push(location?.state?.dslFilter);
  const lengthTrigger = arrTrigger?.filter(element => !(element === null || element === undefined));
  const editTriggerQuery = location?.state?.dslFilter;
  const filterArr = [];
  const splitArr = editTriggerQuery?.FILTER_QUERY?.split(",");
  const query = useQuery();
  const queryFilters = query.get("filters");
  const filterDict = queryFilters && JSON.parse(queryFilters.replace("=", ""));

  useEffect(() => {
    if (location?.state?.type === "tags") {
      const key = Object.keys(location.state.data);
      updateLocalDSL([
        {
          text: `ATags:${key[0]}`,
          key: "ATags",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "Cluster") {
      updateLocalDSL([
        {
          text: `ClusterName:${location.state.data?.name}`,
          key: "ClusterName",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "NamespaceName") {
      updateLocalDSL([
        {
          text: `NamespaceName:${location.state?.data?.name}`,
          key: "NamespaceName",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "namespace") {
      const severity = Object.keys(location.state.data);
      const filterSeverity = severity.filter(e => e.includes("Severity"));
      const arr = filterSeverity.map(e => {
        let split = e.split(" ");
        return {
          text: `Severity:${split[1]}`,
          key: "Severity",
          type: "log"
        };
      });
      updateLocalDSL([
        {
          text: `NamespaceName:${location.state.data.name}`,
          key: "NamespaceName",
          type: "log"
        },
        ...arr
      ]);
    }
    if (location?.state?.type === "policy") {
      const key = Object.keys(location.state.data)
        .filter(e => e !== "name")
        ?.map(data => ({
          text: `PolicyName:${data}`,
          key: "PolicyName",
          type: "log"
        }));
      let to = moment(location.state.data.name, "DD MMM YYYY")
        .utc()
        .format();
      let from = moment(to)
        .subtract(1, "day")
        .utc()
        .format();
      let time = {
        from: from,
        to: to,
        type: "Custom range"
      };
      // key.push({
      //   text: `UpdatedTime:${location.state.data.name}`,
      //   key: "UpdatedTime",
      //   type: "log"
      // });
      onChangeRange(time);
      setTimeRange(time);
      updateLocalDSL(key);
    }
    if (location?.state?.type === "operation") {
      const arr = Object.keys(location.state.data);
      const arr2 = arr.filter(e => e !== "name");
      const arr3 = arr2.map(e => {
        return {
          text: `Operation:${e}`,
          key: "Operation",
          type: "log"
        };
      });
      updateLocalDSL([...arr3]);
    }
    if (location?.state?.type === "pod") {
      //Type:MatchedPolicy
      updateLocalDSL([
        {
          text: `PodName:${location.state.data.name}`,
          key: "PodName",
          type: "log"
        },
        {
          text: "Type:MatchedPolicy",
          key: "Type",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "severity") {
      const severity = Object.keys(location.state.data);
      const filterSeverity = severity.filter(e => e.includes("Severity"));
      const arr = filterSeverity.map(e => {
        let split = e.split(" ");
        return {
          text: `Severity:${split[1]}`,
          key: "Severity",
          type: "log"
        };
      });
      //Type:MatchedPolicy
      updateLocalDSL([...arr]);
    }
    if (location?.state?.type === "cnapp_tags") {
      const tagsList = location?.state?.data;
      updateLocalDSL([
        {
          text: `ATags:${tagsList?.name}`,
          key: "ATags",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "totalAlerts") {
      updateLocalDSL([
        {
          text: "Type:MatchedPolicy",
          key: "Type",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "totalBlockedAlerts") {
      updateLocalDSL([
        {
          text: "Action:Block",
          key: "Action",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "totalAuditedAlerts") {
      updateLocalDSL([
        {
          text: "Action:Audit",
          key: "Action",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "MITRE") {
      updateLocalDSL([
        {
          text: "ATags:MITRE",
          key: "Action",
          type: "log"
        },
        // {
        //   text: "OR"
        // },
        {
          text: "ATags:mitre",
          key: "Action",
          type: "log"
        },
        // {
        //   text: "OR"
        // },
        {
          text: "ATags:T1008",
          key: "Action",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "NIST") {
      updateLocalDSL([
        {
          text: "ATags:NIST",
          key: "Action",
          type: "log"
        },
        // {
        //   text: "OR"
        // },
        {
          text: "ATags:nist",
          key: "Action",
          type: "log"
        },
        // {
        //   text: "OR"
        // },
        {
          text: "ATags:nist-800",
          key: "Action",
          type: "log"
        },
        // {
        //   text: "OR"
        // },
        {
          text: "ATags:NIST-800",
          key: "Action",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "CIS") {
      updateLocalDSL([
        {
          text: "ATags:CIS",
          key: "Action",
          type: "log"
        },
        // {
        //   text: "OR"
        // },
        {
          text: "ATags:cis",
          key: "Action",
          type: "log"
        }
      ]);
    }
    if (location?.state?.type === "PCI") {
      updateLocalDSL([
        {
          text: "ATags:PCI",
          key: "Action",
          type: "log"
        },
        // {
        //   text: "OR"
        // },
        {
          text: "ATags:pci",
          key: "Action",
          type: "log"
        },
        // {
        //   text: "OR"
        // },
        {
          text: "ATags:pci-dss",
          key: "Action",
          type: "log"
        },
        // {
        //   text: "OR"
        // },
        {
          text: "ATags:PCI-DSS",
          key: "Action",
          type: "log"
        }
      ]);
    }
  }, [location]);
  splitArr?.map(val => {
    if (val != "") {
      filterArr.push({ text: val });
    }
  });
  useEffect(() => {
    if (filterArr?.length > 0) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, []);

  useEffect(() => {
    let filterDict = null;
    if (queryFilters) {
      filterDict = JSON.parse(queryFilters.replace("=", ""));
    }
    // if (namespaces?.options?.length > 0 && filterDict) {
    //   const selectedName = namespaces?.options.filter(
    //     value => value.label.toLowerCase() === filterDict.name.toLowerCase()
    //   );
    //   if (selectedName.length > 0) {
    //     dispatch(setSelectedNamespace([selectedName[0]]));
    //   }
    // }
  }, [namespaces]);

  const handleEditModal = () => {
    setShowPopup(false);
  };
  const closeHandle = () => {
    setShowEditTrigger(false);
  };
  const handleFilter = (value, filterName) => {
    if (filterName === "infra_type") {
      dispatch(setSelectedInfraType(value));
    } else if (filterName === "cluster") {
      dispatch(setSelectedClusters(value));
    } else if (filterName === "namespace") {
      dispatch(setSelectedNamespace(value));
    } else if (filterName === "component") {
      dispatch(setSelectedComponentType(value));
    }
  };
  useEffect(() => {
    if (refreshTimeVal === "5 sec") {
      setRefreshTimeinsec(RefreshTime[1].value);
    }
    if (refreshTimeVal === "10 sec") {
      setRefreshTimeinsec(RefreshTime[2].value);
    }
    if (refreshTimeVal === "30 sec") {
      setRefreshTimeinsec(RefreshTime[3].value);
    }
    if (refreshTimeVal === "1 min") {
      setRefreshTimeinsec(RefreshTime[4].value);
    }
    if (refreshTimeVal === "5 mins") {
      setRefreshTimeinsec(RefreshTime[5].value);
    }
    if (refreshTimeVal === "10 mins") {
      setRefreshTimeinsec(RefreshTime[6].value);
    }
    if (refreshTimeVal === "30 mins") {
      setRefreshTimeinsec(RefreshTime[7].value);
    }
    if (refreshTimeVal === "off") {
      setRefreshTimeinsec(RefreshTime[0].value);
    }
  }, [refreshTimeVal]);

  const [timerange, setTimeRange] = useState(timeRange[9].value);
  const onChangeRange = range => setTimeRange(range);
  const onSelectedFilter = filters => setSelectedFilters(filters);
  const updateLocalDSL = function(val) {
    setLocalDSL(val);
  };
  // API Call for Property value
  const buildFString = key => {
    const resultArray = [];
    if (key) {
      const text = key;
      if (text === "OR") {
        resultArray.push("OR:");
      } else {
        const arr = text.split(":");
        const key = arr[0];
        resultArray.push(`${key}`);
      }
      return resultArray.flat();
    }
  };
  var filterString = buildFString(selectedFilter);
  const { fieldValues, isLoading } = useGetDynamicData(
    data => {
      const filterData = data?.[selectedFilter]?.filter(item => Boolean(item));
      const dropdownValues = filterData?.map(dtx => ({
        value: dtx,
        label: dtx
      }));
      setDropValues(dropdownValues);
      return data;
    },
    selectedClusters?.map(item => `${item?.value}`),
    selectedNamespaces?.label,
    selectedComponentType?.label,
    filterString,
    timerange,
    true
  );
  const { data: savedData } = useGetAllFilters(
    data => {
      return data;
    },
    "SAVED",
    selectedComponentType?.label
  );
  const handleDrawer = e => {
    // e?.stopPropagation();
    setDrawer(e);
  };

  // savefilter modal
  const closeHandler = () => {
    setIsOpen(false);
  };

  const submitHandler = () => {};

  // Edit trigger modal
  const closeTriggerHandler = () => {
    setIsTriggerOpen(false);
  };
  const { data: getLogsData = [], isFetching: logsLoading, refetch: refetchLogs } = useGetLogsData(
    data => {
      return data;
    },
    selectedNamespaces?.map(item => item?.label),
    timerange,
    pageId,
    pageSize,
    buildQString(logdDslTag),
    selectedClusters?.map(item => `${item?.value}`),
    selectedInfraType?.label,
    selectedComponentType,
    isEditing
  );
  const {
    data: getLogsCount,
    refetch: refetchLogsCount,
    isFetching: isLoadingCount
  } = useGetLogsCount(
    data => {
      return data;
    },
    selectedClusters?.map(item => `${item?.value}`),
    buildQString(logdDslTag),
    selectedNamespaces?.map(item => item?.label),
    timerange,
    isEditing
  );

  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(timerange, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).unix();
  const [from, setFrom] = useState(getFromTime);

  const timeconvert = () => {
    if (typeof timerange === "string") {
      const curtime = new Date();
      const difftime = getDifferenceTime(timerange, curtime).getTime();
      const test = moment(difftime).unix();
      setFrom(test);
    } else {
      let fromTime = moment(timerange?.from).format("YYYY-MM-DDTHH:mm:ss.SSS");
      setFrom(fromTime + "Z");
    }
  };

  useEffect(() => {
    timeconvert();
    setPageId(1);
  }, [timerange, localDSL]);

  const nestedObjects = [
    "",
    "flow",
    "flow.IP",
    "flow.destination",
    "flow.ethernet",
    "flow.event_type",
    "flow.l4",
    "flow.source",
    "flow.l4.Udp"
  ];
  const getKeyValue = json => {
    // here json parameter is set of key value from the Json Inspector npm
    if (!nestedObjects?.includes(json?.path)) {
      //based on the path I'm not pushing anyhting inside DSL filter
      let tmp = logdDslTag;
      if (tmp === undefined) {
        tmp = [];
      }
      tmp.push({
        text: `${json?.path} ${"match"} ${json?.value}`,
        key: `${json?.key}`,
        op: "match"
      }); //here I'm doing transforming the object like i need
      tmp = _.uniq(tmp);
      setPageId(1);
      handleDrawer(false);
      updateLocalDSL(tmp);
    } else {
    }
  };
  useEffect(() => {
    if (selectedExport?.label === "CSV") {
      // Headers for each column
      let headers = [Object.keys(getLogsData[0]).join()];
      // Convert users data to a csv
      let usersCsv = getLogsData?.reduce((acc, user) => {
        acc.push(Object.values(user));
        return acc;
      }, []);
      downloadFile({
        data: [...headers, ...usersCsv].join("\n"),
        fileName: `${selectedComponentType?.label?.toLowerCase()}_${from}.csv`,
        fileType: "text/csv"
      });
    }
    if (selectedExport?.label === "JSON") {
      downloadFile({
        data: JSON.stringify(getLogsData),
        fileName: `${selectedComponentType?.label?.toLowerCase()}_${from}.json`,
        fileType: "text/json"
      });
    }
  }, [selectedExport]);

  useInterval(
    () => {
      refetchLogs();
      refetchLogsCount();
    },
    typeof refreshTimeinsec === "number" ? refreshTimeinsec : null
  );
  const handlePageChange = (event, value) => {
    setPageId(value);
  };
  function handleChange(event) {
    setPageId(1);
    setPageSize(event.target.value);
  }

  const { data: keys } = useGetDynamicDataKeys(
    data => {
      return data;
    },
    selectedClusters?.map(item => `${item?.value}`),
    selectedNamespaces?.map(item => item?.label),
    selectedComponentType?.label,
    timerange
  );

  const logsTimeFormat = "MM-DD-YY HH:mm z";

  const columnsKube = [
    {
      field: "boxes",
      headerName: "",
      width: 1,
      sortable: false,
      renderCell: params => <CustomTableSeverityComponent row={params.row.Severity} />
    },
    {
      field: "Timestamp",
      headerName: "Timestamps",
      width: 100,
      flex: 2,
      valueGetter: tableData =>
        moment
          .unix(tableData?.row?.Timestamp)
          .tz(moment.tz.guess())
          .format(logsTimeFormat)
    },
    {
      field: "Message",
      headerName: "Message",
      width: 120,
      flex: 2,
      valueGetter: tableData => (tableData?.row?.Message ? tableData?.row?.Message : "--")
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: true,
      width: 80
      // flex: 1
    },
    {
      field: "Severity",
      headerName: "Severity",
      sortable: true,
      width: 80,
      // flex: 1,
      valueGetter: tableData => (tableData?.row?.Severity ? tableData?.row?.Severity : "--")
    },
    {
      field: "Operation",
      headerName: "Operation",
      sortable: true,
      width: 160,
      flex: 1
    },
    {
      field: "PodName",
      headerName: "Pod Name",
      sortable: true,
      width: 160,
      flex: 1
    },
    {
      field: "Result",
      headerName: "Result",
      sortable: true,
      width: 160,
      flex: 1
    }
  ];
  const columnsCilium = [
    {
      field: "boxes",
      headerName: "",
      width: 2,
      sortable: false,
      renderCell: params => <CustomTableSeverityComponent row={"Critical"} />
    },
    {
      field: "time",
      headerName: "Timestamps",
      width: 150,
      valueGetter: tableData =>
        moment
          .unix(tableData?.row?.time)
          .tz(moment.tz.guess())
          .format(logsTimeFormat)
    },
    {
      field: "verdict",
      headerName: "Flow Verdict",
      width: 150,
      valueGetter: tableData => tableData?.row?.flow?.verdict
    },
    {
      field: "Summary",
      headerName: "Flow Summary",
      sortable: true,
      width: 160,
      valueGetter: tableData => tableData?.row?.flow?.Summary
    },
    {
      field: "source",
      headerName: "Flow Source",
      sortable: true,
      width: 160,
      valueGetter: tableData => tableData?.row?.flow?.IP?.source
    },
    {
      field: "destination",
      headerName: "Flow Destination",
      sortable: true,
      width: 160,
      valueGetter: tableData => tableData?.row?.flow?.IP?.destination
    },
    {
      field: "severity",
      headerName: "Severity",
      sortable: true,
      width: 160,
      valueGetter: tableData => "Critical"
    }
  ];

  const cluster_fields_list = useMemo(() => {
    if (selectedClusters.length) {
      return clusters?.options.filter(
        item => !selectedClusters.some(({ label }) => item.label === label)
      );
    }
    return clusters?.options || [];
  }, [clusters?.options, selectedClusters]);

  const namespace_fields_list = useMemo(() => {
    if (selectedNamespaces.length) {
      return namespaces?.options?.filter(
        item => !selectedNamespaces.some(({ label }) => item.label === label)
      );
    }
    return namespaces?.options || [];
  }, [namespaces?.options, selectedNamespaces]);
  useEffect(() => {
    setLogFields(savedData?.apiResponse?.length > 0 ? true : false);
  }, [savedData]);
  return (
    <ContentContainer className={styles.container}>
      <EditModal
        isOpenModal={pouUp}
        isCloseModal={() => handleEditModal()}
        updateLocalDSL={updateLocalDSL}
        filterArr={filterArr}
      />
      <SaveFilterModal
        isModalOpen={isOpen}
        onCloseModal={() => closeHandler()}
        filters={selectedFilters}
        fcomp={selectedComponentType?.label}
        updateLocalDSL={updateLocalDSL}
        savedFilter={savedData}
      />
      <CreateTriggerModal
        isModalOpen={isTriggerOpen}
        selectedFilters={selectedFilters}
        setIsTriggerOpen={setIsTriggerOpen}
        onCloseModal={() => closeTriggerHandler()}
        selectedComponentsFilter={selectedComponentType?.label}
        updateLocalDSL={updateLocalDSL}
      />
      <EditTrigger
        showEditTrigger={showEditTrigger}
        setShowEditTrigger={closeHandle}
        data={editTriggerQuery}
        selectedFilters={selectedFilters}
        updateLocalDSL={updateLocalDSL}
      />
      <Title>Logs</Title>
      <div className={`${styles["action-group"]} mt-4`}>
        <div className={`${styles["action-group__filter"]}`}>
          <div className="flex gap-5 w-5/7">
            <Select
              value={selectedInfraType}
              onChange={v => handleFilter(v, "infra_type")}
              placeholder="Select Workload"
              defaultValue={{ label: "K8s", value: 1 }}
              options={[{ label: "K8s", value: 1 }]}
              containerClass={`${styles["action-group__filter-option"]} ${styles.selectBtn}`}
              hideSelectedOptions={false}
            />
            <Select
              value={selectedComponentType}
              onChange={v => handleFilter(v, "component")}
              placeholder="Component"
              defaultValue={{ label: "KubeArmor", value: 1 }}
              options={[
                { label: "KubeArmor", value: 1 },
                { label: "Cilium", value: 2 }
              ]}
              containerClass={`${styles["action-group__filter-option"]} ${styles.selectBtn}`}
              hideSelectedOptions={false}
            />
            <CustomSelect
              value={selectedClusters}
              onChange={v => handleFilter(v, "cluster")}
              placeholder="Cluster"
              options={clusters?.options}
              containerClass={`${styles["action-group__filter-option"]} ${styles.selectBtn}`}
              isMulti
              hideSelectedOptions={false}
              // label={"Cluster"}
            />
            <CustomSelect
              value={selectedNamespaces}
              onChange={v => handleFilter(v, "namespace")}
              placeholder="Namespace"
              options={namespaces?.options}
              containerClass={`${styles["action-group__filter-option"]} ${styles.selectBtn}`}
              isMulti
              hideSelectedOptions={false}
              // label={"Namespace"}
            />
          </div>
          <div className="flex gap-5 w-2/7">
            <RefreshInterval
              items={RefreshTime}
              setSelected={setRefreshTimeVal}
              defaultValue={{ label: refreshTimeVal, value: refreshTimeVal }}
              refreshIconClick={e => {
                refetchLogs();
                refetchLogsCount();
              }}
            />
            <DateRangePicker onChange={onChangeRange} items={timeRange} />
          </div>
        </div>
        {/* <div className="flex flex-col gap-2 mt-5 h-fit">
          <Tags
            className={styles["action-group__filter-multiOption"]}
            label="Select Clusters to filter"
            values={selectedClusters}
            list={cluster_fields_list}
            onChange={v => handleFilter(v, "cluster")}
            showAddIcon={!!cluster_fields_list?.length}
          />
          <Tags
            className={styles["action-group__filter-multiOption"]}
            label="Select Namespace to filter"
            values={selectedNamespaces}
            list={namespace_fields_list}
            onChange={v => handleFilter(v, "namespace")}
            showAddIcon={!!namespace_fields_list?.length}
          />
        </div> */}
      </div>
      <Box className="flex w-full gap-2 mt-3">
        <div className="w-full">
          <DynamicFilterLogs
            type="Logs"
            updateLocalDSL={updateLocalDSL}
            dsl={localDSL}
            onSelected={onSelectedFilter}
            keysList={keys}
            range={timerange}
            setPageId={setPageId}
            dropValues={dropValues}
            optionsOperators={[{ value: "match", label: "match" }]}
          />
        </div>

        <div
          className={`${styles.saveBtn} ${
            lengthTrigger?.length >= 1 && selectedFilters?.length >= 1 ? "hidden" : null
          }`}
        >
          <Button
            onClick={() => setIsOpen(true)}
            disabled={selectedFilters?.length > 0 ? false : true}
          >
            Save
          </Button>
        </div>
      </Box>
      <div>
        {/* SideBar Filter */}
        <div className={styles.columnLog3}>
          {logFields && (
            <div className={`${styles.border} h-auto`}>
              <LogFields
                // preDefinedFilterData={predefindData}
                savedFilterdata={savedData}
                updateLocalDSL={updateLocalDSL}
              />
            </div>
          )}
        </div>
        <div className={`${logFields ? styles.columnLog9 : styles.columnLog12}`}>
          <div className={`${styles.border} flex justify-between`}>
            <div className="flex gap-4 mt-2 mb-2 ml-2">
              <Button
                onClick={() => {
                  setLogFields(!logFields);
                }}
                className={`${styles.button}`}
                variant="outline"
              >
                Filter
                {logFields ? (
                  <VisibilityIcon fill="#021040" size={10} className="ml-1" />
                ) : (
                  <VisibilityOffIcon fill="#021040" size={10} className="ml-1" />
                )}
              </Button>
              <Button
                onClick={() => {
                  setHistogram(!histogram);
                }}
                className={`${styles.button}`}
                variant="outline"
              >
                Histogram
                {histogram ? (
                  <VisibilityIcon fill="#021040" size={10} className="ml-1" />
                ) : (
                  <VisibilityOffIcon fill="#021040" size={10} className="ml-1" />
                )}
              </Button>
            </div>
            <div className="flex gap-4 mt-2 mb-2 mr-2">
              <Button
                className={
                  lengthTrigger?.length >= 1 && selectedFilters?.length >= 1 ? "hidden" : "block"
                }
                onClick={() => setIsTriggerOpen(true)}
                disabled={selectedFilters?.length > 0 ? false : true}
              >
                Create Trigger
              </Button>
              <Button
                className={
                  lengthTrigger?.length >= 1 && selectedFilters?.length >= 1 ? "block" : "hidden"
                }
                onClick={() => setShowEditTrigger(true)}
                disabled={selectedFilters?.length > 0 ? false : true}
              >
                Save Trigger
              </Button>
              <Button
                className={`${
                  lengthTrigger?.length >= 1 && selectedFilters?.length >= 1 ? "hidden" : "block"
                }
                  ${styles.button}`}
                onClick={() => history?.push("/monitors-logging/triggers")}
              >
                Manage Triggers
              </Button>
              <Select
                value={selectedExport}
                onChange={v => setSelectedExport(v)}
                isDisabled={getLogsData?.length === 0 ? true : false}
                placeholder="Export"
                options={[
                  { label: "CSV", value: 1 },
                  { label: "JSON", value: 2 }
                ]}
                containerClass={`${styles["action-group__filter-option"]} ${styles.selectBtn}`}
                hideSelectedOptions={false}
              />
            </div>
          </div>
          {/* Barchart Start */}
          <div>
            {logsLoading && histogram ? (
              <div className="flex items-center justify-center" style={{ height: "20vh" }}>
                <Loader />
              </div>
            ) : (
              <div>
                {histogram &&
                  (getLogsData?.length > 0 ? (
                    <div
                      className={`${styles.border} h-36 my-2 pt-2`}
                      style={{ width: logFields ? "100%" : "none" }}
                    >
                      <div className="mb-2 ml-5 font-semibold">No of Logs</div>
                      <Barchart data={convertLogsData(getLogsData, selectedComponentType?.label)} />
                    </div>
                  ) : (
                    <div
                      className={`${styles.border} h-36 my-2 pt-2 flex justify-center items-center`}
                      style={{ width: logFields ? "100%" : "none" }}
                    >
                      No data to show
                    </div>
                  ))}
              </div>
            )}
          </div>

          <Box sx={{ height: "100%", width: "100%" }}>
            <Table
              columns={selectedComponentType?.label === SYSTEM ? columnsKube : columnsCilium}
              data={logsLoading ? [] : getLogsData}
              checkboxVisible={false}
              sortingMod
              onRowClick={e => {
                setDrawerData(
                  Object?.keys(e?.row)
                    .filter(key => key != "id")
                    .reduce((acc, key) => {
                      acc[key] = e?.row[key];
                      return acc;
                    }, {})
                );
                handleDrawer(true);
              }}
              hideFooter={true}
              loading={logsLoading}
              paginationVisible={false}
              page={pageId || 0}
              pageSize={pageSize}
              sx={{
                "& .MuiDataGrid-row": { cursor: "pointer" }
              }}
            />
          </Box>
          {isLoadingCount ? (
            <div className="flex justify-end"></div>
          ) : (
            <CustomPagination
              count={
                getLogsCount?.count % pageSize <= 0
                  ? Math.floor(getLogsCount?.count / pageSize) || 0
                  : Math.floor(getLogsCount?.count / pageSize) + 1 || 0
              }
              onChange={handlePageChange}
              page={pageId}
              selected={pageSize}
              perPageChange={handleChange}
              totalCount={getLogsCount?.count || 0}
            />
          )}
          <Drawer
            anchor={"right"}
            open={drawer}
            onClose={e => handleDrawer(false)}
            PaperProps={{
              sx: { width: "60%" }
            }}
          >
            <div className="p-5">
              <Summary
                type={selectedComponentType?.label}
                data={drawerData}
                onClose={handleDrawer}
                updateLocalDSL={updateLocalDSL}
              />
            </div>
            <div className="px-5 mt-3">
              <div className="flex justify-between px-2 py-2">
                <Title className="raw-logs">Raw Logs</Title>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(drawerData));
                    dispatch(addNotification({ msg: "Log copied to clipboard", type: "info" }));
                  }}
                  variant="outline"
                >
                  Copy
                </Button>
              </div>
              <Inspector
                data={drawerData}
                onClick={e => getKeyValue(e)}
                isExpanded={(keypath, query) => true}
              />
            </div>
          </Drawer>
        </div>
      </div>
    </ContentContainer>
  );
};

export default Logs;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 10px;
  padding: 10px;
`;
export const ChartTitle = styled.div`
  margin: 0px 10px 10px 10px;
  font-size: 18px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
`;
