import React, { useState, useEffect, memo } from "react";
import ReactDOM from "react-dom";

import { useSelector } from "react-redux";
import { getAuditChoicesMiniSelect } from "store/entities/baseline/selectors";

import { Button, Title, Modal, Tags, Loader } from "components/simple";

import styles from "./styles.module.scss";
import TagsSelector from "components/simple/TagsSelector/TagsSelector";

const AddAuditFilesModal = memo(({ open, close, data, onAddFiles, isLoading, params }) => {
  const selectTenant = useSelector(state => state.users.selectTenant);
  const auditChoicesList = useSelector(getAuditChoicesMiniSelect);

  const formattedList = auditChoicesList?.map(item => ({ value: item, label: item }));
  const availableList = params?.label !== "" || params?.source !== "" ? formattedList : [];

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (open) {
      setItems(data);
    }
    return () => setItems([]);
  }, [selectTenant, open]);

  const onSave = () => onAddFiles(items);
  const onBack = () => close();

  return ReactDOM.createPortal(
    <Modal isOpen={open} toggle={close}>
      <Title>Add</Title>
      <div className={styles.content}>
        <div className={styles.form}>
          {isLoading ? (
            <Loader />
          ) : (
            <TagsSelector
              label="Audit file"
              values={items}
              list={availableList}
              onChange={v => setItems(v)}
            />
          )}
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={onBack} variant="outline" className={styles.modalButton}>
          Back
        </Button>
        <Button onClick={onSave} className={styles.modalButton}>
          Save
        </Button>
      </div>
    </Modal>,
    document.getElementById("modal-root")
  );
});

export default AddAuditFilesModal;
