import React from "react";

import { Button, Modal, Title } from "components/simple";
import Loader from "components/simple/Loader";
import TicketsList from "./TicketsList";
import CommentsList from "./CommentsList";
import AddComment from "./AddComment";

import styles from "./styles.module.scss";

const CommentsModal = ({
  step,
  setStep,
  tickets,
  ticket,
  isOpen,
  onCloseModal,
  commentValue,
  onChange,
  onSubmit,
  onSelectTicket,
  isTicketsLoading,
  isAddCommentLoading
}) => {
  const navigateBackHandler = () => {
    if (step > 1) {
      setStep("back", step);
    } else {
      onCloseModal();
    }
  };

  const renderContent = contentStep => {
    switch (contentStep) {
      case 1:
        return (
          <TicketsList
            tickets={tickets}
            step={step}
            setStep={setStep}
            onSelectTicket={onSelectTicket}
          />
        );

      case 2:
        return <CommentsList ticket={ticket} step={step} setStep={setStep} />;

      case 3:
        return <AddComment value={commentValue} onChange={onChange} />;

      default:
        break;
    }
  };

  const containerStyles = {
    height: "500px",
    overflow: "auto",
    paddingRight: "5px",
    marginBottom: "30px"
  };

  const commentsListSteps = step <= 2;
  const backBtn = step <= 1;
  return (
    <Modal isOpen={isOpen} toggle={onCloseModal} className={styles.modal}>
      <Title className={styles.title}>{commentsListSteps ? "Comments" : "Add comment"}</Title>
      {isTicketsLoading ? (
        <Loader />
      ) : (
        <>
          <div style={commentsListSteps ? containerStyles : null}>{renderContent(step)}</div>
          <div className={styles.btnsRow}>
            <Button variant="outline" onClick={navigateBackHandler} className={styles.btn}>
              {backBtn ? "Close" : "Back"}
            </Button>
            {!commentsListSteps && (
              <Button onClick={onSubmit} className={styles.btn} isLoading={isAddCommentLoading}>
                Save
              </Button>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default CommentsModal;
