import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import Trend from "react-trend";
import NoTableRowData from "components/complex/Table/NoTableRowData";
import NoTableSVG from "../../../assets/images/noDataAvailable.svg";
import AWSLogo from "assets/images/AmazonWebServicesLogo.svg";
import GCPLogo from "assets/images/GcpLogo.svg";
import AzureLogo from "assets/images/AzureLogo.svg";
function Row(props) {
  const { row, isItemSelected, handleClick } = props;
  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={event => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row?.id}
        selected={isItemSelected}
      >
        <TableCell align="center">
          <div className="flex items-center justify-center gap-1">
            {row?.cloudname.includes("AWS") && <img src={AWSLogo} />}
            {row?.cloudname.includes("Azure") && <img src={AzureLogo} />}
            {row?.cloudname.includes("GCP") && <img src={GCPLogo} />}
            {row?.cloudname}
          </div>
        </TableCell>
        <TableCell align="center">{row?.lastscanned}</TableCell>
        <TableCell>
          {row?.compliance?.map(e => {
            return (
              <TableCell align="center" sx={{ border: "none", width: "7%" }}>
                {!e?.count ? "NA" : e?.count}
              </TableCell>
            );
          })}
        </TableCell>

        <TableCell align="center">
          <Trend
            width={100}
            height={50}
            gradient={["#00ff73", "#ff0022"]}
            data={row?.trendLine}
            autoDraw
            autoDrawDuration={1000}
            autoDrawEasing="ease-in"
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ComplianceTable({ rows }) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleClick = (event, name) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "16px", fontWeight: 700 }} align="center">
                Cloud Accounts
              </TableCell>
              <TableCell sx={{ fontSize: "16px", fontWeight: 700 }} align="center">
                Last Scanned
              </TableCell>
              <TableCell sx={{ fontSize: "16px", fontWeight: 700 }} align="center">
                Compliance in Percentage
                <TableHead align="center">
                  {rows[0]?.compliance?.map(e => {
                    return (
                      <TableCell sx={{ border: "none", width: "7%" }} align="center">
                        {e?.date}
                      </TableCell>
                    );
                  })}
                </TableHead>
              </TableCell>
              <TableCell sx={{ fontSize: "16px", fontWeight: 700 }} align="center">
                Trend
              </TableCell>
            </TableRow>
          </TableHead>
          {rows?.length ? (
            <TableBody>
              {rows?.map(row => {
                const isItemSelected = isSelected(row.id);
                return (
                  <>
                    <Row
                      isItemSelected={isItemSelected}
                      setSelected={setSelected}
                      key={row?.id}
                      row={row}
                      handleClick={handleClick}
                    />
                  </>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableRow
              style={{
                width: "365%"
              }}
              className="flex justify-center m-4"
            >
              <NoTableRowData showSVGImage={false}>
                <img style={{ marginBottom: "-16px" }} src={NoTableSVG} alt="no-row-available" />
              </NoTableRowData>
            </TableRow>
          )}
        </Table>
      </TableContainer>
      {Boolean(rows?.length) && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
