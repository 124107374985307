import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useObservabilityContext } from "../Context";
import Select from "react-select";
import { formatDate } from "../Utils/utils";
import { ClickAwayListener } from "@mui/material";

const LeftClickMenu = ({ data, xPos, yPos, setShowLeftClickMenu, image = "pod", type }) => {
  const [img, setImg] = useState();
  const {
    selectedPod,
    portDropdown,
    selectedPort,
    setSelectedPort,
    countOfCommands
  } = useObservabilityContext();
  useEffect(() => {
    // eslint-disable-next-line no-shadow
    import(`../Images/${image}.svg`).then(img => {
      setImg(img.default);
    });
  }, [image]);
  return (
    <ClickAwayListener onClickAway={() => setShowLeftClickMenu(false)}>
      <Container
        top={yPos + 25}
        left={xPos - 300}
        borderColor={`${
          // eslint-disable-next-line no-nested-ternary
          data?.data?.connectionType === "Allow"
            ? "#14FF00"
            : data?.data?.connectionType === "Deny"
            ? "red"
            : "#0500FF"
        }`}
      >
        {type !== "connection" && data?.type !== "SecondarySubNode" ? (
          <>
            <div className="flex items-center px-2 py-1 select-none">
              <img src={img} className="imgContainer w-1/5" alt="podIcon" />
              <p className="header pl-2 flex items-center text-xs font-semibold w-4/5">
                {data?.id === "parent"
                  ? `Source : ${selectedPod?.label}`
                  : `Destination Pod/Svc/Ip : ${data?.data?.ip}`}
              </p>
            </div>
            <div
              style={{ maxWidth: "500px" }}
              className="px-2 py-1 text-xs select-none desc break-all"
            >
              <strong> Cluster :</strong> {data?.data?.cluster_name} <br />
              <strong> Namespace : </strong>
              {data?.data?.namespace} <br />
              {data?.type === "PrimaryNode" && (
                <>
                  <strong> Count of Commands : </strong> {countOfCommands} <br />
                </>
              )}
              {data?.id === "parent" && (
                <>
                  {" "}
                  <strong> Allowed Connection : </strong> {data?.data?.allowed_connections} <br />{" "}
                </>
              )}
              {data?.type === "PrimaryNode" && (
                <>
                  {" "}
                  <strong> Blocked Connection :</strong> {data?.data?.blocked_connections} <br />{" "}
                </>
              )}
              {data?.type !== "PrimaryNode" && (
                <>
                  <strong> Type :</strong> {data?.data?.nw_type} <br />
                  {data?.type !== "PrimarySubNode" && (
                    <>
                      <strong> Occurrence :</strong> {data?.data?.count} <br />
                    </>
                  )}
                </>
              )}
              <strong> Labels :</strong> {data?.data?.labels} <br />
              <strong> Last Updated Time : </strong> {formatDate(data?.data?.updated_time)} <br />
            </div>
          </>
        ) : (
          <>
            <div className="flex px-2 py-1 select-none">
              {type === "connection" ? (
                <>
                  <img src={img} className="imgContainer w-1/5" alt="flowIcom" />
                  <p className="header pl-2 flex items-center text-xs font-semibold w-4/5">
                    {`${data?.label} to ${
                      data?.label.includes("egress") ? data?.data?.ip : selectedPod?.label
                    },`}
                  </p>
                  <div className="flex items-center">
                    <p className="header pl-2 flex items-center text-xs font-semibold w-1/2">
                      Port :
                    </p>
                    <Select
                      options={portDropdown}
                      value={selectedPort}
                      defaultValue={selectedPort}
                      onChange={value => setSelectedPort(value)}
                      isSearchable={false}
                      // eslint-disable-next-line no-use-before-define
                      styles={dropdownstyle}
                    />
                  </div>
                </>
              ) : (
                <>
                  <img src={img} className="imgContainer w-1/5" alt="podIcon" />
                  <p className="header pl-2 flex items-center text-xs font-semibold w-4/5">
                    {data?.data?.ip}
                  </p>
                </>
              )}
            </div>
            <div className="px-2 py-1 text-xs select-none desc">
              <strong> To : </strong>{" "}
              {data?.label?.includes("egress") ? data?.data?.ip : selectedPod?.label} <br />
              {data?.type === "SecondarySubNode" && (
                <>
                  <strong>Container: </strong>
                  {data?.data?.container_name}
                  <br />
                  <strong> Cluster : </strong> {data?.data?.cluster_name} <br />
                  <strong> Namespace : </strong> {data?.data?.namespace} <br />
                </>
              )}
              <strong> Protocol : </strong> {data?.data?.protocol} <br />
              <strong> Direction :</strong>
              {// eslint-disable-next-line no-nested-ternary
              type === "connection"
                ? data?.label?.includes("ingress")
                  ? "ingress"
                  : "egress"
                : // eslint-disable-next-line prettier/prettier
                  data?.data?.nw_type}
              <br />
              <strong> Port :</strong> {data?.data?.port} <br />
              {type === "connection" && (
                <>
                  <strong> Source :</strong> {data?.data?.source} <br />
                  <strong> Occurrence :</strong> {data?.data?.count} <br />
                  <strong> Status :</strong> {data?.data?.action} <br />
                  <strong> Last Updated Time : </strong> {formatDate(data?.data?.updated_time)}{" "}
                  <br />
                </>
              )}
            </div>
          </>
        )}
      </Container>
    </ClickAwayListener>
  );
};

export default LeftClickMenu;

export const dropdownstyle = {
  container: styles => ({ ...styles, height: 30, width: 85 }),
  control: styles => ({
    ...styles,
    fontSize: 12,
    minHeight: "20px",
    height: 30,
    border: "none !important"
  }),
  valueContainer: style => ({ ...style, padding: "0px 2px 0px 0px", width: "75%" }),
  indicatorSeparator: () => ({ display: "none" }),
  indicatorsContainer: style => ({ ...style, height: 28, width: "45%" }),
  menuList: styles => ({ ...styles, height: 115 }),
  singleValue: styles => ({ ...styles, top: "50%", color: "#326CE5" }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    fontSize: 12,
    ":hover": {
      backgroundColor: "#f3f3f3"
    },
    backgroundColor: isSelected || isFocused ? "#f3f3f3" : "#ffffff",
    color: "#000000"
  })
};

export const Container = styled.div`
  min-width: 400px;
  min-height: 110px;
  background: #ffffff;
  position: absolute;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 10;
  border-left: 4px solid ${props => props.borderColor};
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  .imgContainer {
    width: 40px;
    height: 40px;
    border: 2px solid #0003;
    border-radius: 50%;
    padding: 3px;
  }
  .header {
    color: #326ce5;
  }
  .desc {
    border-top: 3px solid #cddbfe;
  }
`;
