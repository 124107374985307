import React from "react";
import styles from "./styles.module.scss";
import { Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Select } from "..";
import { colourStyles } from "screens/MonitorLogs/Constants";

export default function RefreshInterval({
  items,
  setSelected,
  defaultValue,
  disable,
  refreshIconClick
}) {
  return (
    <div className={`${styles.refreshBox} ${disable && "opacity-25 cursor-not-allowed"}`}>
      <div
        className={`${styles.refreshInput} flex justify-center items-center border-blue-700 w-[80px]`}
      >
        <Tooltip
          title={
            window.location.pathname === "/inventory/cloud-workloads"
              ? "Refresh Alert Counts"
              : "Refresh"
          }
          placement="top"
          disable={disable}
          className="max-w-xs"
        >
          <RefreshIcon
            className={`cursor-pointer ${disable && "cursor-not-allowed"}`}
            fill="#05147D"
            size={20}
            onClick={refreshIconClick}
          />
        </Tooltip>
      </div>
      <Select
        placeholder="Select the Time"
        options={items}
        onChange={data => {
          setSelected(data?.label);
        }}
        value={defaultValue}
        isDisabled={disable}
        refreshCustom={true}
        colourStyles={colourStyles}
      />
    </div>
  );
}
