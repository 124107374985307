import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import PodIcon from "../Images/pod.svg";
import styles from "./styles.module.scss";

function SecondarySubNode({ data }) {
  const [image, setImage] = useState(null);
  useEffect(() => {
    import(`../Images/${data?.imageName}.svg`).then(img => {
      setImage(img.default);
    });
  }, [data]);
  return (
    <div className={styles.secondarySubNode}>
      <div className={styles.img} data-for={data.label}>
        <img src={image} alt="pod" />
      </div>
      {data.toolTip && (
        <div
          className={`${
            data.toolPos === "right" ? styles.customToolTipRight : styles.customToolTipLeft
          }`}
        >
          {data.ip}
        </div>
      )}
      <Handle isConnectable={false} type="source" position="top" id="a" />
      <Handle isConnectable={false} type="source" position="right" id="b" />
      <Handle isConnectable={false} type="source" position="bottom" id="c" />
      <Handle isConnectable={false} type="source" position="left" id="d" />
    </div>
  );
}

export default SecondarySubNode;
