import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  history: {},
  params: {
    page: 0,
    pageSize: 20,
    search: "",
    ordering: "",
    filters: ""
  },
  dataTypesList: null
};

const reducer = createReducer(
  initialState,
  {
    [actions.getImportHistorySuccess.type]: (state, action) => ({
      ...state,
      history: action.payload
    }),
    [actions.setImportHistoryParams.type]: (state, action) => ({
      ...state,
      params: {
        ...state.params,
        ...action.payload
      }
    }),
    [actions.getDataTypesSuccess.type]: (state, action) => ({
      ...state,
      dataTypesList: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
