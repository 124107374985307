import { useMutation } from "react-query";
import { addRole, deleteRole, editRole } from "./service";
import { useDispatch } from "react-redux";
import { addNotification } from "../notifications/actions";
import { navigate } from "helper/history";
import { routes } from "router";

export const useAddRoleMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => addRole(payload), {
    onSuccess: () => {
      dispatch(addNotification({ msg: "Role added successfully", type: "success" }));
      navigate(routes.settingsRbac);
    },
    onError: () => {
      dispatch(addNotification({ msg: "There was an error while adding the role", type: "error" }));
    }
  });
};

export const useEditRoleMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => editRole(payload), {
    onSuccess: () => {
      dispatch(addNotification({ msg: "Role edited successfully", type: "success" }));
      navigate(routes.settingsRbac);
    },
    onError: () => {
      dispatch(
        addNotification({ msg: "There was an error while editing the role", type: "error" })
      );
    }
  });
};

export const useDeleteRoleMutation = () => {
  const dispatch = useDispatch();

  return useMutation(roleId => deleteRole(roleId), {
    onSuccess: () => {
      dispatch(addNotification({ msg: "Role deleted successfully", type: "success" }));
    },
    onError: () => {
      dispatch(
        addNotification({ msg: "There was an error while deleting the role", type: "error" })
      );
    }
  });
};
