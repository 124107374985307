import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";

import { Divider, IconButton, Link } from "@mui/material";

import { CodePreview, Icon, Modal, Tabs, Title, Tooltip } from "components/simple";

import { DetailModalForm, DetailModalInfo, DetailModalNav, SubModal } from ".";

import {
  getVulnerability,
  setVulnerabilitiesParams,
  updateCurrentVulnerability
} from "store/entities/vulnerabilities/actions";

import { routes } from "router";

import { navigate } from "helper/history";
import { stringToHTML } from "helper/formatHtml";
import { getExtension } from "helper/getExtension";

import styles from "./styles.module.scss";

const DetailModal = memo(
  ({
    data,
    type,
    isOpen,
    onClose,
    group_ids,
    statusesOptions,
    severitiesOptions,
    ticketConfigOptions,
    selectTicketConfig,
    handleTicketConfig,
    handleAction
  }) => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(0);
    const [status, setStatus] = useState({ value: "Active", label: "Active" });
    const [severity, setSeverity] = useState({ value: "Unknown", label: "Unknown" });
    const [ignoredValue, setIgnoredValue] = useState(data?.ignored || false);
    const [showDetails, setShowDetails] = useState(false);

    const firstVulnerability = 0;
    let currentVulnerability = group_ids?.findIndex(vulnerId => data?.id === vulnerId);
    const lastVulnerability = group_ids?.length - 1;

    const currentStatus = statusesOptions.find(({ value }) => value === data?.status);
    const currentSeverity = severitiesOptions.find(
      ({ value }) => value === data?.vulnerability?.risk_factor
    );

    const ext = getExtension(data?.misc?.filename);
    const noData = "No Data";

    const insertHtmlLink = links => (
      <div className={styles["tls-modal-tab-content"]}>
        {links?.length > 0 ? (
          <ul>
            {links?.map((path, index) => (
              <li key={index}>
                <Link href={path} target="_blank">
                  {path}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          noData
        )}
      </div>
    );

    const insertHtmlMarkup = content => (
      <>
        {content ? (
          <div
            className={styles["tls-modal-tab-content"]}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={stringToHTML(content)}
          />
        ) : (
          <div className={styles["tls-modal-tab-content"]}>{noData}</div>
        )}
      </>
    );

    const cleanEffect = () => {
      setStatus({ value: "Active", label: "Active" });
      setSeverity({ value: "Unknown", label: "Unknown" });
      setIgnoredValue(false);
      setShowDetails(false);
      setActiveTab(0);
    };

    useEffect(() => {
      if (isOpen) {
        setStatus(currentStatus);
        setSeverity(currentSeverity);
        setIgnoredValue(data?.ignored);
      }
      return () => cleanEffect();
    }, [isOpen, data]);

    const successSaveCallback = () => dispatch(setVulnerabilitiesParams());

    const handleChange = useCallback((event, newValue) => setActiveTab(newValue), []);
    const handleSwitch = useCallback(() => setIgnoredValue(!ignoredValue), [ignoredValue]);
    const handleSelect = useCallback(v => setStatus(v), []);
    const handleSelectSeverity = useCallback(v => setSeverity(v), []);
    const handleMore = () => setShowDetails(true);

    const handleNext = useCallback(() => {
      currentVulnerability += 1;
      const nextVulnerability = group_ids[currentVulnerability];

      dispatch(getVulnerability(nextVulnerability));
    }, [currentVulnerability]);

    const handlePrevious = useCallback(() => {
      currentVulnerability -= 1;
      const prevVulnerability = group_ids[currentVulnerability];

      dispatch(getVulnerability(prevVulnerability));
    }, [currentVulnerability]);

    const handleSubmit = () => {
      const submitData = {
        vulnerability_id: data?.vulnerability?.id,
        finding_ids: [data?.id],
        id: data?.id,
        risk_factor: severity.value,
        status: status.value,
        ignored: ignoredValue,
        description: data?.vulnerability?.description || noData,
        solution: data?.vulnerability?.solution || noData
      };

      dispatch(updateCurrentVulnerability({ data: submitData, callback: successSaveCallback }));
    };

    const sonarQubeTabs = [
      {
        id: 0,
        label: `Source code`,
        content: <CodePreview snippet={data?.misc?.snippet} lang={ext} /> || noData
      },
      {
        id: 1,
        label: `Description`,
        content: insertHtmlMarkup(data?.vulnerability?.description)
      },
      {
        id: 2,
        label: `Solution`,
        content: insertHtmlMarkup(data?.vulnerability?.solution)
      },
      {
        id: 3,
        label: `References`,
        content: insertHtmlLink(data?.vulnerability?.references)
      }
    ];

    const restTabs = [
      {
        id: 0,
        label: `Description`,
        content: insertHtmlMarkup(data?.vulnerability?.description)
      },
      {
        id: 1,
        label: `Tool Output`,
        content: insertHtmlMarkup(data?.tool_output)
      },
      {
        id: 2,
        label: `Solution`,
        content: insertHtmlMarkup(data?.vulnerability?.solution)
      },
      {
        id: 3,
        label: `References`,
        content: insertHtmlLink(data?.vulnerability?.references)
      }
    ];

    const tabs = useMemo(() => {
      switch (type) {
        case "sonarqube":
        case "snyk":
          return sonarQubeTabs;
        default:
          return restTabs || [];
      }
    }, [type, sonarQubeTabs, restTabs]);

    const toTicketsPage = () => navigate(`${routes.tickets}`, { ticketId: data?.id });
    const toDetailPage = () => navigate(`${routes.vulnerabilitiesDetails}/${data?.id}`);

    return ReactDOM.createPortal(
      <>
        <Modal isOpen={isOpen} toggle={onClose} sx={{ margin: "7rem 0" }}>
          <article className={styles["tls-modal-container"]}>
            <div className={styles["tls-modal-header"]}>
              <Title className={styles["tls-modal-header__title"]}>
                {data?.vulnerability?.name}
              </Title>
              <div className={styles["tls-modal-header__more"]}>
                <IconButton
                  onClick={toDetailPage}
                  className={styles["tls-modal-header__more-btn"]}
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                >
                  <Icon.RedirectArrow />
                  <Tooltip title="Go to detail page" />
                </IconButton>
              </div>
            </div>
            <Divider className={styles["tls-modal-divider-md"]} />
            {!!group_ids?.length && (
              <section className={styles["tls-modal-nav"]}>
                <DetailModalNav
                  group_ids={group_ids}
                  firstVulnerability={firstVulnerability}
                  currentVulnerability={currentVulnerability}
                  lastVulnerability={lastVulnerability}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                />
              </section>
            )}
            <section className={styles["tls-modal-details"]}>
              <DetailModalInfo data={data} type={type} />
              <DetailModalForm
                data={data}
                type={type}
                severity={severity}
                status={status}
                ignoredValue={ignoredValue}
                statusesOptions={statusesOptions}
                severitiesOptions={severitiesOptions}
                handleSelect={handleSelect}
                handleSwitch={handleSwitch}
                handleSelectSeverity={handleSelectSeverity}
                handleMore={handleMore}
                handleSubmit={handleSubmit}
                toTickets={toTicketsPage}
                ticketConfigOptions={ticketConfigOptions}
                selectTicketConfig={selectTicketConfig}
                handleTicketConfig={handleTicketConfig}
                handleAction={handleAction}
              />
            </section>
            <section className={styles["tls-modal-tabs"]}>
              <Tabs tabs={tabs} value={activeTab} handleChange={handleChange} spaceBetween />
            </section>
          </article>
        </Modal>
        <SubModal
          title={data?.vulnerability?.identification_numbers[0]}
          data={data?.vulnerability?.misc?.tags}
          isOpen={Boolean(showDetails)}
          onClose={() => setShowDetails(!showDetails)}
        />
      </>,
      document.getElementById("modal-root")
    );
  }
);

export default DetailModal;
