/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  Button,
  ContentContainer,
  Search,
  Select,
  Title,
  Input,
  Switch,
  Modal,
  Textarea
} from "components/simple";
import styles from "./styles.module.scss";
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import YamlEditor from "components/simple/YamlEditor";
import { Controller, useForm } from "react-hook-form";
import ErrorText from "components/simple/ErrorText";
import {
  useClustersQuery,
  useGetSelectorLabel,
  useNamespacesQuery
} from "store/entities/filters/queries";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedClusters } from "store/entities/filters/slice";
import { yamltoJSONV2 } from "helper/hooks/useYamlToJsonV2";
import yaml from "js-yaml";
import ValidationLoader from "../ValidationLoader";
import { flattenYaml } from "helper/flattenYaml";
import { parseYAML } from "helper/parseYAML";
import { useCreatePolicyMutation } from "store/entities/policies/mutations";
import { getAccuknoxWorkspace } from "api/api";
import { addNotification } from "store/entities/notifications/actions";
import { navigate } from "helper/history";
import { routes } from "router";
import { LABEL_TYPES } from "../constants";
import { useLocation } from "react-router-dom";
// import { useQueryClient } from "react-query";

export const INFRA_TYPES = ["K8s", "VM"];

const CreatePolicy = () => {
  const location = useLocation();
  const [infra, setInfra] = useState(INFRA_TYPES?.[0]);
  const [namespaceOptions, setNamespaceOptions] = useState([]);
  const [code, setCode] = useState("");
  const [clusterDetail, setClusterDetail] = useState({});
  const [clusterNamespace, setClusterNamespace] = useState({});
  const [mandatoryYamlFieldError, setMandatoryYamlFieldError] = useState(false);
  const [kindValidation, setKindValidation] = useState(false);
  const [kindError, setKindError] = useState("");
  const [actionValidation, setActionValidation] = useState(false);
  const [actionError, setActionError] = useState("");
  const [severityValidation, setSeverityValidation] = useState(false);
  const [severityError, setSeverityError] = useState("");
  const [apiVersionValidation, setApiVersionValidation] = useState(false);
  const [apiVersionError, setApiVersionError] = useState("");
  const [ownerOnlyValidation, setOwnerOnlyValidation] = useState(false);
  const [ownerOnlyError, setOwnerOnlyError] = useState("");
  const [readOnlyValidation, setReadOnlyValidation] = useState(false);
  const [readOnlyError, setReadOnlyError] = useState("");
  const [recursiveValidation, setRecursiveValidation] = useState(false);
  const [recursiveError, setRecursiveError] = useState("");
  const [protocolValidation, setProtocolValidation] = useState(false);
  const [protocolError, setProtocolError] = useState("");
  const [pathValidation, setPathValidation] = useState(false);
  const [pathError, setPathError] = useState("");
  const [dirValidation, setDirValidation] = useState(false);
  const [dirError, setDirError] = useState("");
  const [namespaceValidation, setNamespaceValidation] = useState(false);
  const [namespaceError, setNamespaceError] = useState("");
  const [nameValidation, setNameValidation] = useState(false);
  const [nameError, setNameError] = useState("");
  const [yamlParseErrors, setYamlParseErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const workspaceID = getAccuknoxWorkspace();

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm();

  const dispatch = useDispatch();

  // const queryClient = useQueryClient();

  const POLICY_TYPES = [
    { label: "System", value: "System" },
    { label: "Network", value: "Network" }
  ];

  const policyType = [
    { label: "Kubearmor", value: "Kubearmor" },
    { label: "Kubernetes Network", value: "Kubernetes Network" },
    { label: "Cilium Network", value: "Cilium Network" }
  ];

  const [
    watchPolicyName,
    watchPolicyType,
    watchPolicyDescription,
    watchClusterName,
    watchNamespace,
    watchLabelType,
    watchLabels,
    watchCommitMessage
  ] = watch([
    "policyName",
    "policyType",
    "policyDescription",
    "cluster",
    "namespace",
    "labelType",
    "labels",
    "commitMessage"
  ]);

  const clusterValue = watchClusterName?.value;
  const namespaceValue = watchNamespace?.value;
  const policyValue = watchPolicyType?.value;
  const labelsValue = watchLabels?.value;

  const nodePolicyType = [
    {
      label: "KubeArmor Host",
      value: "KubeArmor Host"
    }
  ];

  const { data: clusterList } = useClustersQuery();
  const { data: namespaceList } = useNamespacesQuery([clusterDetail]);
  const { data: selectorLabel } = useGetSelectorLabel(
    [watchClusterName]?.map(val => val?.value),
    [watchNamespace]?.map(val => val?.value),
    watchLabelType
  );

  const labelTypeConvertor = value => {
    const filteredValue = value === undefined ? LABEL_TYPES.DEFAULT : LABEL_TYPES.NODE;

    return filteredValue;
  };
  const createPolicy = useCreatePolicyMutation(() => {
    dispatch(addNotification({ msg: "Policy Created successfully", type: "success" }));
    navigate(
      location?.state?.from === "ClusterManager" ? routes.clusterManagement : routes.allPolicies
    );
    // queryClient.invalidateQueries(["allPolicies"]);
  });

  const onSubmit = () => {
    setIsModalOpen(true);
  };

  const createPolicyHandler = () => {
    const payload = {
      name: watchPolicyName,
      type: !policyValue ? "KuberArmor Host" : policyValue,
      description: watchPolicyDescription,
      workspace_id: workspaceID,
      cluster_id: clusterValue,
      label_type: watchLabelType === undefined ? LABEL_TYPES.DEFAULT : LABEL_TYPES.NODE,
      namespace_id: !namespaceValue ? 0 : namespaceValue,
      created_by: "30d4caa0-0c48-4d2c-874d-0edb1a950a43",
      policy_yaml: code,
      message: watchCommitMessage || "",
      label_id: [labelsValue]
    };

    createPolicy.mutate(payload);
  };

  const handleInfraTypeToggle = v => {
    if (v) {
      setInfra(INFRA_TYPES?.[1]);
    } else {
      setInfra(INFRA_TYPES?.[0]);
    }
    // when switching between the k8s and VM resetting the form values
    reset();
  };
  const yamlData = yamltoJSONV2(code);

  const combinedNamespace = namespaceOptions.map(a => a.namespace);

  const yamlValue = flattenYaml(yamlData);

  const combinedKeyValues = yamlKey => {
    const combinedKeys = Object.fromEntries(
      Object.entries(yamlValue).filter(([key]) => key.includes(yamlKey))
    );
    return Object.values(combinedKeys);
  };

  const combinedData = {
    action: combinedKeyValues("action"),
    severity: combinedKeyValues("severity"),
    protocol: combinedKeyValues("protocol"),
    path: combinedKeyValues("path"),
    dir: combinedKeyValues("dir"),
    ownerOnly: combinedKeyValues("ownerOnly"),
    readOnly: combinedKeyValues("readOnly"),
    recursive: combinedKeyValues("recursive")
  };

  useEffect(() => {
    const parseErrors = parseYAML(code);
    if (parseErrors?.length > 0) {
      setYamlParseErrors(parseErrors);
      setErrorMessage({
        message: `${parseErrors?.[0]?.reason} at ${parseErrors?.[0]?.mark?.line}:${parseErrors?.[0]?.mark?.column}`,
        snippet: parseErrors?.[0]?.mark?.snippet || "YAML Error "
      });
    } else {
      setYamlParseErrors([]);
      setErrorMessage({});
    }
  }, [code]);

  const checkForValidYamlPolicydata = () => {
    if (yamlData && yamlData.kind === "NetworkPolicy") {
      const mandatoryKeys = ["apiVersion", "kind", "metadata"];
      const mandatoryMetadataKeys = ["name", "namespace"];

      const mandatoryKeysValues = mandatoryKeys.map(each => ({
        // eslint-disable-next-line no-shadow
        if(yamlData) {
          return yamlData[each];
        }
      }));
      const metadataChildKeysValues = mandatoryMetadataKeys.map(each => {
        if (yamlData && yamlData.metadata) {
          return yamlData.metadata[each];
        }
      });
      const combinedKeysValues = [...metadataChildKeysValues, ...mandatoryKeysValues];
      setMandatoryYamlFieldError(combinedKeysValues.some(each => [null, undefined].includes(each)));
    } else {
      const mandatoryKeys = ["apiVersion", "kind", "metadata", "spec"];
      const mandatoryMetadataKeys = ["name", "namespace"];
      if (yamlData && yamlData.kind === "KubeArmorHostPolicy") {
        mandatoryMetadataKeys.pop();
      }
      const allSpecKeys = yamlData && yamlData.spec && Object?.keys(yamlData.spec);
      const filterMandatorySpecKeys = allSpecKeys?.filter(
        each =>
          each === "selector" ||
          each === "nodeSelector" ||
          each === "podSelector" ||
          each === "endpointSelector"
      );
      let mandatorySpecKeys = ["matchLabels"];
      if (filterMandatorySpecKeys) {
        mandatorySpecKeys = [...mandatorySpecKeys, ...filterMandatorySpecKeys];
      }
      const mandatoryKeysValues = mandatoryKeys.map(each => ({
        // eslint-disable-next-line no-shadow
        if(yamlData) {
          return yamlData[each];
        }
      }));
      const metadataChildKeysValues = mandatoryMetadataKeys.map(each => {
        if (yamlData && yamlData.metadata) {
          return yamlData.metadata[each];
        }
      });
      const specKeysValues = mandatorySpecKeys?.map(each => {
        if (yamlData && yamlData.spec && each !== "matchLabels") {
          return yamlData.spec[each];
        }
        if (yamlData && yamlData.spec && yamlData.spec[filterMandatorySpecKeys[0]]) {
          return yamlData.spec[filterMandatorySpecKeys[0]][each];
        }
      });
      const combinedKeysValues = [
        ...metadataChildKeysValues,
        ...mandatoryKeysValues,
        ...specKeysValues
      ];
      setMandatoryYamlFieldError(combinedKeysValues.some(each => [null, undefined].includes(each)));
    }

    if (yamlData && yamlData.kind) {
      const validateKind = [
        "KubeArmorPolicy",
        "KubeArmorHostPolicy",
        "NetworkPolicy",
        "CiliumNetworkPolicy"
      ].includes(yamlData.kind);
      if (validateKind) {
        setKindValidation(false);
        setKindError("");
      } else {
        setKindValidation(true);
        setKindError("Invalid kind");
      }
    }
    if (yamlData && yamlData.spec) {
      const validateAction = combinedData?.action?.some(
        each => !["Allow", "Audit", "Block"].includes(each)
      );
      if (!validateAction) {
        setActionValidation(false);
        setActionError("");
      } else {
        setActionValidation(true);
        setActionError("Action should be Allow , Audit or Block");
      }
    }
    if (yamlData && yamlData.spec) {
      const validateSeverity = combinedData?.severity?.some(
        each => typeof each === "string" || each === 0 || each > 10
      );
      if (!validateSeverity) {
        setSeverityValidation(false);
        setSeverityError("");
      } else {
        setSeverityValidation(true);
        setSeverityError("Severity must be between 1-10");
      }
    }
    if (yamlData && yamlData.kind) {
      if (yamlData.kind === "KubeArmorPolicy" || yamlData.kind === "KubeArmorHostPolicy") {
        if (yamlData.apiVersion === "security.kubearmor.com/v1") {
          setApiVersionValidation(false);
          setApiVersionError("");
        } else {
          setApiVersionValidation(true);
          setApiVersionError("apiVersion is invalid");
        }
      } else if (yamlData.kind === "CiliumNetworkPolicy") {
        if (yamlData.apiVersion === "cilium.io/v2") {
          setApiVersionValidation(false);
          setApiVersionError("");
        } else {
          setApiVersionValidation(true);
          setApiVersionError("apiVersion is invalid");
        }
      } else if (yamlData.kind === "NetworkPolicy") {
        if (yamlData.apiVersion === "networking.k8s.io/v1") {
          setApiVersionValidation(false);
          setApiVersionError("");
        } else {
          setApiVersionValidation(true);
          setApiVersionError("apiVersion is invalid");
        }
      }
    }

    if (yamlData && combinedData?.ownerOnly?.length > 0 && yamlData.spec) {
      const validateOwnerOnly = combinedData?.ownerOnly?.some(
        each => ![true, false].includes(each)
      );
      if (!validateOwnerOnly) {
        setOwnerOnlyValidation(false);
        setOwnerOnlyError("");
      } else {
        setOwnerOnlyValidation(true);
        setOwnerOnlyError("OwnerOnly should be true or false");
      }
    }
    if (yamlData && combinedData?.readOnly?.length > 0 && yamlData.spec) {
      const validateReadOnly = combinedData?.readOnly?.some(each => ![true, false].includes(each));
      if (!validateReadOnly) {
        setReadOnlyValidation(false);
        setReadOnlyError("");
      } else {
        setReadOnlyValidation(true);
        setReadOnlyError("ReadOnly should be true or false");
      }
    }

    if (yamlData && combinedData?.recursive?.length > 0 && yamlData.spec) {
      const validateRecursive = combinedData?.recursive?.some(
        each => ![true, false].includes(each)
      );
      if (!validateRecursive) {
        setRecursiveValidation(false);
        setRecursiveError("");
      } else {
        setRecursiveValidation(true);
        setRecursiveError("Recursive should be true or false");
      }
    }
    if (yamlData && yamlData.spec) {
      const validateProtocol = combinedData?.protocol?.some(
        each => !["tcp", "udp", "icmp", "TCP", "UDP", "ICMP"].includes(each)
      );
      if (!validateProtocol) {
        setProtocolValidation(false);
        setProtocolError("");
      } else {
        setPathValidation(true);
        setProtocolError("Protocol should be TCP, UDP or ICMP");
      }
    }
    if (yamlData && combinedData?.path?.length > 0 && yamlData.spec) {
      // eslint-disable-next-line no-useless-escape
      const format = /^\/+.*[^\/]$/;
      const validatePath = combinedData?.path?.map(each => format.test(each));
      if (validatePath.includes(false)) {
        setPathValidation(true);
        setPathError("Path is invalid");
      } else {
        setPathValidation(false);
        setPathError("");
      }
    }
    if (yamlData && combinedData?.dir?.length > 0 && yamlData.spec) {
      const format = /^\/$|^\/.*\/$/;
      const validateDir = combinedData?.dir?.map(
        each => format.test(each) && each.endsWith("/") && each.charAt(each.length - 2) !== "/"
      );
      if (validateDir.includes(false)) {
        setDirValidation(true);
        setDirError("Dir is invalid");
      } else {
        setDirValidation(false);
        setDirError("");
      }
    }
    if (
      combinedNamespace.length > 0 &&
      yamlData &&
      yamlData.metadata &&
      yamlData.metadata?.namespace
    ) {
      // let format = /^[a-z0-9]+(-[a-z0-9]+)*$/;
      const format = /^[a-z0-9-]*$/;
      if (
        combinedNamespace.length > 0 &&
        combinedNamespace.includes(yamlData.metadata?.namespace) &&
        format.test(yamlData.metadata?.namespace)
      ) {
        setNamespaceValidation(false);
        setNamespaceError("");
      } else {
        setNamespaceValidation(true);
        setNamespaceError("Please enter existing namespace ");
      }
    }

    if (yamlData && yamlData.metadata && yamlData.metadata?.name) {
      // let format = /^[a-z0-9]+(-[a-z0-9]+)*$/;
      const format = /^[a-z0-9-]*$/;
      if (format.test(yamlData.metadata?.name)) {
        setNameValidation(false);
        setNameError("");
      } else {
        setNameValidation(true);
        setNameError("Please enter valid Policy name");
      }
    }
  };

  useEffect(() => {
    checkForValidYamlPolicydata();
  }, [code]);

  const loaderValidation =
    !mandatoryYamlFieldError &&
    !actionValidation &&
    !nameValidation &&
    !namespaceValidation &&
    !severityValidation &&
    !kindValidation &&
    !pathValidation &&
    !dirValidation &&
    !ownerOnlyValidation &&
    !recursiveValidation &&
    !readOnlyValidation &&
    !protocolValidation &&
    !apiVersionValidation &&
    !errorMessage?.message?.length > 0;

  useEffect(() => {
    const parseErrors = parseYAML(code);
    if (parseErrors?.length > 0) {
      setYamlParseErrors(parseErrors);
      setErrorMessage({
        message: `${parseErrors?.[0]?.reason} at ${parseErrors?.[0]?.mark?.line}:${parseErrors?.[0]?.mark?.column}`,
        snippet: parseErrors?.[0]?.mark?.snippet || "YAML Error "
      });
    } else {
      setYamlParseErrors([]);
      setErrorMessage({});
    }
  }, [code]);

  useEffect(() => {
    location?.state?.selectedCluster && setValue("cluster", location?.state?.selectedCluster);
    location?.state?.selectedNamespace && setValue("namespace", location?.state?.selectedNamespace);
  }, []);

  const ruleForNamespace =
    watchLabelType === "Node" ? { required: false } : { required: "Namespace is required" };
  return (
    <ContentContainer className={styles.container}>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
          <div style={{ borderBottom: "1px solid rgba(0,0,0,0.4)" }}>
            <Typography variant="body2" fontWeight={700} sx={{ marginBottom: "10px" }}>
              Commit Message
            </Typography>
          </div>
          <div className="mt-8">
            <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
              Write commit message (optional)
            </Typography>
            <Controller
              name="commitMessage"
              {...register("commitMessage", {
                required: false
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  value={value}
                  onChange={val => onChange(val, "commit")}
                  placeholder="Leave a commit message here..."
                />
              )}
            />
          </div>
          <div className="flex justify-end items-center w-full mt-4 gap-4">
            <Button
              variant="outline"
              className={styles.cancelBtn}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={() => createPolicyHandler()} isLoading={createPolicy.isLoading}>
              Submit
            </Button>
          </div>
        </Modal>
      )}
      <Title>Create Policy</Title>
      <Typography variant="caption" display="block" gutterBottom>
        Create Policy, provide the required information and review the policy.
      </Typography>
      {/* <Box
        sx={{
          padding: "1rem"
        }}
      >
        <Switch
          value={infra === INFRA_TYPES[1]}
          label={`Creating policy for ${infra}`}
          onChange={handleInfraTypeToggle}
        />
      </Box> */}
      <div className={styles["form-wrapper"]}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box sx={{ display: "flex", gap: "1rem", width: "100%" }}>
            <Box sx={{ width: "50%" }}>
              <div className={styles.row}>
                <Box sx={{ width: "100%" }}>
                  <Controller
                    name="policyName"
                    rules={{
                      required: "Policy name is required",
                      pattern: {
                        value: /^[a-z0-9-]+$/,
                        message:
                          "Can have only lowercase alphabets, numerals, no special character except hyphen and no spaces. Can not start and end with hyphen."
                      }
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        type="text"
                        placeholder="Policy Name"
                        label="Name"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        containerClassName={styles.searchContainer}
                        className={styles.input}
                        required
                      />
                    )}
                  />
                  <div className={styles["error-text-container"]}>
                    {errors.policyName && <ErrorText errorMsg={errors.policyName.message} />}
                  </div>
                </Box>

                <Box sx={{ width: "100%" }}>
                  <Controller
                    name="policyType"
                    {...register("policyType", {
                      required: "Policy type is required"
                    })}
                    defaultValue=""
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        containerClass={styles.select}
                        onChange={val => onChange(val)}
                        label="Policy Type"
                        value={value}
                        placeholder="Policy Type"
                        options={watchLabelType === LABEL_TYPES.NODE ? [] : policyType}
                        required
                      />
                    )}
                  />
                  <div className={styles["error-text-container"]}>
                    {errors.policyType && <ErrorText errorMsg={errors.policyType.message} />}
                  </div>
                </Box>
              </div>
              <div className={styles.row}>
                <Box sx={{ width: "100%" }}>
                  <Controller
                    name="policyDescription"
                    {...register("policyDescription", {
                      required: "Policy description cannot be empty",
                      maxLength: 100
                    })}
                    defaultValue=""
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        type="text"
                        placeholder="Policy Description"
                        label="Description"
                        value={value}
                        onChange={val => onChange(val.target.value)}
                        containerClassName={styles["full-width"]}
                        className={styles.input}
                        required
                      />
                    )}
                  />
                  <div className={styles["error-text-container"]}>
                    {errors.policyDescription && (
                      <ErrorText
                        errorMsg={
                          errors?.policyDescription?.type === "maxLength"
                            ? "Max 100 characters allowed"
                            : errors.policyDescription.message
                        }
                      />
                    )}
                  </div>
                </Box>
              </div>
              {infra === INFRA_TYPES[0] ? (
                <>
                  <div className={styles.row}>
                    <Box sx={{ width: "100%" }}>
                      <Controller
                        name="cluster"
                        {...register("cluster", {
                          required: "Cluster is required"
                        })}
                        defaultValue={location?.state?.selectedCluster}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            containerClass={styles.select}
                            onChange={val => {
                              onChange(val);
                              setClusterDetail(val);
                            }}
                            label="Cluster"
                            value={value}
                            placeholder="Cluster"
                            options={clusterList?.options}
                            required
                          />
                        )}
                      />
                      <div className={styles["error-text-container"]}>
                        {errors.cluster && <ErrorText errorMsg={errors.cluster.message} />}
                      </div>
                    </Box>

                    <Box sx={{ width: "100%" }}>
                      <Controller
                        name="namespace"
                        {...register("namespace", {
                          required:
                            watchLabelType === LABEL_TYPES.NODE ? false : "Namespace is required"
                        })}
                        defaultValue={location?.state?.selectedNamespace}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            containerClass={styles.select}
                            onChange={val => {
                              onChange(val);
                              setClusterNamespace(val);
                            }}
                            value={watchLabelType === LABEL_TYPES.NODE ? "" : value}
                            label="Namespace"
                            placeholder="Namespace"
                            options={namespaceList?.options}
                            required
                            isDisabled={
                              watchLabelType === LABEL_TYPES.NODE ? true : !watch("cluster")
                            }
                          />
                        )}
                      />
                      <div className={styles["error-text-container"]}>
                        {errors.namespace && <ErrorText errorMsg={errors.namespace.message} />}
                      </div>
                    </Box>
                  </div>

                  <Box sx={{ width: "100%" }}>
                    <Controller
                      name="labelType"
                      {...register("labelType")}
                      defaultValue={LABEL_TYPES.DEFAULT}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={val => {
                            onChange(val);
                            setValue("namespace", null);
                            setValue("labels", null);
                            setValue(
                              "policyType",
                              watchLabelType === LABEL_TYPES.NODE ? null : nodePolicyType
                            );
                          }}
                          defaultValue={LABEL_TYPES.DEFAULT}
                          name="labelType"
                          value={value}
                          row
                        >
                          <FormControlLabel
                            value={LABEL_TYPES.DEFAULT}
                            control={<Radio />}
                            label="Default"
                          />
                          <FormControlLabel
                            value={LABEL_TYPES.NODE}
                            control={<Radio />}
                            label="Node"
                          />
                        </RadioGroup>
                      )}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <div className={styles.row}>
                    <Box sx={{ width: "100%" }}>
                      <Controller
                        name="instanceGroup"
                        {...register("instanceGroup", {
                          required: "Instance group is required"
                        })}
                        defaultValue=""
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            containerClass={styles.select}
                            onChange={val => onChange(val)}
                            label="Instance Group"
                            value={value}
                            placeholder="Instance Group"
                            options={POLICY_TYPES}
                            required
                          />
                        )}
                      />
                      <div className={styles["error-text-container"]}>
                        {errors.instanceGroup && (
                          <ErrorText errorMsg={errors.instanceGroup.message} />
                        )}
                      </div>
                    </Box>

                    <Box sx={{ width: "100%" }}>
                      <Controller
                        name="instance"
                        {...register("instance")}
                        defaultValue=""
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            containerClass={styles.select}
                            onChange={val => onChange(val)}
                            value={value}
                            label="Instance"
                            placeholder="Instance"
                            options={POLICY_TYPES}
                            required
                          />
                        )}
                      />
                      <div className={styles["error-text-container"]}>
                        {errors.instance && <ErrorText errorMsg={errors.instance.message} />}
                      </div>
                    </Box>
                  </div>
                </>
              )}

              <div className={styles.row}>
                <Box sx={{ width: "100%" }}>
                  <Controller
                    name="labels"
                    {...register("labels", {
                      required: "Selector labels are required"
                    })}
                    defaultValue=""
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        containerClass={styles.select}
                        value={value}
                        onChange={val => onChange(val)}
                        label="Selector Labels"
                        placeholder="Selector Labels"
                        options={selectorLabel?.options}
                        required
                        isDisabled={
                          watchLabelType === LABEL_TYPES.NODE ? false : !watch("namespace")
                        }
                      />
                    )}
                  />
                  <div className={styles["error-text-container"]}>
                    {errors.labels && <ErrorText errorMsg={errors.labels.message} />}
                  </div>
                </Box>
              </div>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Box sx={{ minHeight: "40px" }}>
                {code && (
                  <div className="flex items-center gap-2 mb-1">
                    <ValidationLoader
                      validationValue={yamltoJSONV2(code)}
                      isValid={loaderValidation}
                      type="createPolicy"
                    />
                    {/* <p className='text-xs font-medium text-gray-800'>
                  {!mandatoryYamlFieldError && !errorMessage?.message?.length > 0 ? VALID_YAML1 : INVALID_YAML1}
                </p> */}
                  </div>
                )}
              </Box>
              <Controller
                name="policyYaml"
                {...register("policyYaml", {
                  required: "Policy YAML is required"
                })}
                defaultValue=""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <YamlEditor
                    code={value}
                    setCode={val => {
                      onChange(val);
                      setCode(val);
                    }}
                  />
                )}
              />
              <div className={styles["error-text-container"]}>
                {errors?.policyYaml && <ErrorText errorMsg={errors?.policyYaml?.message} />}
                {nameError && code && <ErrorText errorMsg={nameError} />}
                {namespaceError && code && <ErrorText errorMsg={namespaceError} />}
                {actionError && code && <ErrorText errorMsg={actionError} />}
                {severityError && code && <ErrorText errorMsg={severityError} />}
                {kindError && code && <ErrorText errorMsg={kindError} />}
                {protocolError && code && <ErrorText errorMsg={protocolError} />}
                {ownerOnlyError && code && <ErrorText errorMsg={ownerOnlyError} />}
                {dirError && code && <ErrorText errorMsg={dirError} />}
                {recursiveError && code && <ErrorText errorMsg={recursiveError} />}
                {readOnlyError && code && <ErrorText errorMsg={readOnlyError} />}
                {pathError && code && <ErrorText errorMsg={pathError} />}
                {apiVersionError && code && <ErrorText errorMsg={apiVersionError} />}
              </div>
            </Box>
          </Box>

          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}
            className={styles.row}
          >
            <Button onClick={handleSubmit(onSubmit)} type="submit">
              Create
            </Button>
          </Box>
        </form>
      </div>
    </ContentContainer>
  );
};

export default CreatePolicy;
