export const toolTipWrapper = {
  display: "flex",
  maxHeight: "150px",
  maxWidth: "20rem",
  opacity: "0.9",
  //   width: "auto",
  overflowY: "auto",
  overflowX: "auto",
  pointerEvents: "auto"
};
