import React from "react";

export default function Inventory(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 17"
      fill="none"
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 0.25H1.75C0.925 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V1.75C13.75 0.925 13.075 0.25 12.25 0.25ZM8.5 10.75H3.25V9.25H8.5V10.75ZM10.75 7.75H3.25V6.25H10.75V7.75ZM10.75 4.75H3.25V3.25H10.75V4.75Z"
        fill={props.color}
      />
    </svg>
  );
}
