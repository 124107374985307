import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";
import { styled, useTheme } from "@mui/material";
import { Icon } from "../../../simple";
import { useBasePath } from "helper/history";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ItemLink = styled("li")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.sideMenu.textColor,
    fontWeight: "500",
    padding: "10px 12px",
    cursor: "pointer",
    // borderRadius: "10px",
    fontSize: "16px",
    marginBottom: "8px",
    "&:hover": {
      background: theme.sideMenu.highlight
    },
    "& span svg path": {
      stroke: theme.sideMenu.logoColor
    }
  };
});

const SubItemLink = styled("div")(({ theme, active }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: active !== "true" ? theme.sideMenu.textHighlight : theme.sideMenu.textColor,
    padding: "10px 12px",
    fontSize: "16px",
    cursor: "pointer",
    borderRight: active == "true" && "3px solid #7F56D9",
    // borderRadius: "4px 0px 0px 4px",
    borderRadius: "4px",
    background: active === "true" ? theme.sideMenu.highlight : "transparent",
    marginBottom: "8px",
    marginLeft: "9px",
    "&:hover": {
      background: theme.sideMenu.highlight,
      color: theme.sideMenu.textColor
    }
  };
});

export default function Item({ text, icon, onClick, list }) {
  const [open, setOpen] = useState(false);
  const current = useBasePath();
  const theme = useTheme();

  const isActive = r => {
    const currentRoute = current.replaceAll("/", " ");
    const route = r.replaceAll("/", " ");
    return currentRoute.includes(route);
  };

  const checkActvieTab = () => {
    return list.some(r => isActive(r.route));
  };

  useEffect(() => {
    if (checkActvieTab()) {
      setOpen(true);
      return;
    }
    setOpen(false);
  }, [current]);

  const IconImg = Icon[icon];
  const logoThemeColor = theme.sideMenu.logoColor;

  return (
    <>
      <ItemLink onClick={() => setOpen(!open)}>
        <span className={styles.icon}>
          <IconImg color={logoThemeColor} style={{ color: logoThemeColor}} />
        </span>
        <span className={styles.title}>{text}</span>
        <span className="ml-auto pt-2">
          {open ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </span>
      </ItemLink>
      <div className={`${styles.list} ${open ? styles.open : ""}`}>
        {list.map((item, index) => {
          return (
            <SubItemLink
              key={item.route + index}
              onClick={() => onClick(item.route)}
              className={styles.itemTree}
              active={isActive(item.route).toString()}
            >
              {item.name}
            </SubItemLink>
          );
        })}
      </div>
    </>
  );
}

Item.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

Item.defaultProps = {
  onClick: () => {}
};
