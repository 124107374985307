import React from "react";
import { ContentContainer } from "components/simple";
import { FileInfo, MetadataInfo, NetworkInfo, ProcessInfo, SystemPolicyInfo } from "./contents";
import {
  FILE,
  METADATA,
  NETWORK,
  PROCESS
} from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import { useKubeArmorPolicyEditorState } from "store/entities/policyEditor/kubeArmorPolicyEditor/slice";

const PolicyInfoGuide = () => {
  const activeCard = useKubeArmorPolicyEditorState("activeCard");

  const renderInfoSections = () => {
    if (activeCard === METADATA) {
      return <MetadataInfo />;
    }
    if (activeCard === PROCESS) {
      return <ProcessInfo />;
    }
    if (activeCard === FILE) {
      return <FileInfo />;
    }
    if (activeCard === NETWORK) {
      return <NetworkInfo />;
    }
    return <SystemPolicyInfo />;
  };

  return <ContentContainer className="h-fit p-4">{renderInfoSections()}</ContentContainer>;
};

export default PolicyInfoGuide;
