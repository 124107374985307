import React, { createContext, useContext, useMemo } from "react";
import styled from "styled-components";
import EditorCanvas from "./components/EditorCanvas";
import YamlPreview from "./components/YamlPreview";
import PolicyLibrary from "./components/PolicyLibrary";
import PolicyInfoGuide from "./components/PolicyInfoGuide";
import {
  useFileRuleForm,
  useNetworkRuleForm,
  usePolicyMetadataForm,
  useProcessRuleForm
} from "store/entities/policyEditor/kubeArmorPolicyEditor/hooks";

const KubeArmorPolicyContext = createContext();
export const useKubeArmorPolicyContext = () => {
  return useContext(KubeArmorPolicyContext);
};

const KubeArmorPolicyEditor = () => {
  const policyMetadataForm = usePolicyMetadataForm();
  const processRuleForm = useProcessRuleForm();
  const fileRuleForm = useFileRuleForm();
  const networkRuleForm = useNetworkRuleForm();
  const contextValue = useMemo(
    () => ({ policyMetadataForm, processRuleForm, fileRuleForm, networkRuleForm }),
    [policyMetadataForm, processRuleForm, fileRuleForm, networkRuleForm]
  );

  return (
    <KubeArmorPolicyContext.Provider value={contextValue}>
      <ContentSection>
        <EditorCanvas />
        <YamlPreview />
        <PolicyLibrary />
        <PolicyInfoGuide />
      </ContentSection>
    </KubeArmorPolicyContext.Provider>
  );
};

const ContentSection = styled.main`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 68% 32%;
  grid-template-rows: 450px 1fr;
  gap: 0.75rem;
`;

export default KubeArmorPolicyEditor;
