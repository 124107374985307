let id = 0;

const defaultOptions = {
  type: "success"
};

export default function createNotific(options) {
  id += 1;
  return {
    ...defaultOptions,
    ...options,
    id
  };
}
