import React, { memo } from "react";
import { ZoomableGroup, ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";

function RegionWidget({ setTooltipContent, data }) {
  const geoUrl =
    "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

  return (
    <div data-tip="" className="w-full h-full">
      <ComposableMap>
        <ZoomableGroup>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  // onMouseEnter={() => {
                  //   setTooltipContent(`${geo.properties.name}`);
                  // }}
                  // onMouseLeave={() => {
                  //   setTooltipContent("");
                  // }}
                  style={{
                    default: {
                      fill: "#D6D6DA",
                      outline: "none"
                    },
                    hover: {
                      fill: "#D6D6DA",
                      outline: "none"
                    }
                    // pressed: {
                    //   fill: "#E42",
                    //   outline: "none"
                    // }
                  }}
                />
              ))
            }
          </Geographies>
          {data.map(e => (
            <Marker
              onMouseEnter={() => {
                setTooltipContent(e.content);
              }}
              onMouseLeave={() => {
                setTooltipContent("");
              }}
              coordinates={e.location}
            >
              {e.severity === "pass" && <circle r={4} fill="#1489FF" />}
              {e.severity === "fail" && <circle r={4} fill="#D13D3D" />}
              {e.severity === "warning" && <circle r={4} fill="#F5A647" />}
            </Marker>
          ))}
          {/* <Marker
            onMouseEnter={() => {
              setTooltipContent("Map tooltip");
            }}
            onMouseLeave={() => {
              setTooltipContent("");
            }}
            coordinates={[-74.006, 40.7128]}
          >
            <circle r={4} fill="#D13D3D" />
            <circle r={4} fill="#F5A647" />
            <circle r={4} fill="#1489FF" />
          </Marker> */}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
}

export default memo(RegionWidget);
