/* eslint-disable no-return-await */

import jwtDecode from "jwt-decode";
import {
  accuknoxAuth,
  ACCUKNOX_API,
  API,
  getTenantId,
  authorization,
  authorizationTenant
} from "./api";
import { checkAccuknoxAPIStatus } from "./checkStatus";
import { logout } from "../store/entities/auth/utils";

export const REQUEST_METHODS = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE"
};

export const request = async (
  url = "",
  method = REQUEST_METHODS.GET,
  body = null,
  headers = { "Content-Type": "application/json" }
) => {
  // Logout immediately if the expiry time is past current time
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken?.exp < Date.now() / 1000) {
      logout(true);
      return;
    }
  }

  return await fetch(url, {
    method,
    headers,
    body
  });
};

export const get = (url, headers) => {
  return request(url, REQUEST_METHODS.GET, null, headers);
};

export const post = (url, data, headers) => {
  return request(url, REQUEST_METHODS.POST, data, headers).then(
    async response => await checkAccuknoxAPIStatus(response)
  );
};

export const put = (url, data, headers) => {
  return request(url, REQUEST_METHODS.PUT, data, headers);
};

export const patch = (url, data, headers) => {
  return request(url, REQUEST_METHODS.PATCH, data, headers);
};

export const remove = (url, data, headers) => {
  return request(url, REQUEST_METHODS.DELETE, data, headers);
};

export const customGet = async (
  endpoint = "",
  headers = { "Content-Type": "application/json" }
) => {
  const response = await request(`${ACCUKNOX_API}${endpoint}`, REQUEST_METHODS.GET, null, {
    ...headers,
    ...authorization(),
    ...getTenantId()
  });
  return checkAccuknoxAPIStatus(response);
};
export const customPut = (
  endpoint = "",
  data,
  headers = { "Content-Type": "application/json" }
) => {
  return request(`${ACCUKNOX_API}${endpoint}`, REQUEST_METHODS.PUT, JSON.stringify(data), {
    ...headers,
    ...authorization(),
    ...getTenantId()
  });
};

export const customPost = async (
  endpoint = "",
  data,
  headers = { "Content-Type": "application/json" }
) => {
  const response = await request(
    `${ACCUKNOX_API}${endpoint}`,
    REQUEST_METHODS.POST,
    JSON.stringify(data),
    {
      ...headers,
      ...authorization(),
      ...getTenantId()
    }
  );
  return checkAccuknoxAPIStatus(response);
};

export const customDelete = (
  endpoint = "",
  data,
  headers = { "Content-Type": "application/json" }
) => {
  return request(`${ACCUKNOX_API}${endpoint}`, REQUEST_METHODS.DELETE, JSON.stringify(data), {
    ...headers,
    ...authorization(),
    ...getTenantId()
  }).then(async response => await checkAccuknoxAPIStatus(response));
};

// formData
export const postFormData = (endpoint = "", data) => {
  return post(`${ACCUKNOX_API}${endpoint}`, data, { ...authorization(), ...getTenantId() });
};
export const postFormRequest = async (
  endpoint = "",
  data,
  headers = { "Content-Type": "application/json" }
) => {
  const response = await request(`${API}${endpoint}`, REQUEST_METHODS.POST, JSON.stringify(data), {
    ...headers,
    ...authorization(),
    ...(authorizationTenant()?.["Tenant-Id"] !== "undefined" ? { ...authorizationTenant() } : {})
  });
  return checkAccuknoxAPIStatus(response);
};
export const getFormRequest = (endpoint = "", headers = { "Content-Type": "application/json" }) => {
  return request(`${API}${endpoint}`, REQUEST_METHODS.GET, null, {
    ...headers,
    ...authorization(),
    ...(authorizationTenant()?.["Tenant-Id"] !== "undefined" ? { ...authorizationTenant() } : {})
  }).then(async response => await checkAccuknoxAPIStatus(response));
};

export const deleteFormRequest = (
  endpoint = "",
  headers = { "Content-Type": "application/json" }
) => {
  return request(`${API}${endpoint}`, REQUEST_METHODS.DELETE, null, {
    ...headers,
    ...authorization(),
    ...(authorizationTenant()?.["Tenant-Id"] !== "undefined" ? { ...authorizationTenant() } : {})
  }).then(async response => await checkAccuknoxAPIStatus(response));
};

export const patchFormRequest = (
  endpoint = "",
  headers = { "Content-Type": "application/json" }
) => {
  return request(`${API}${endpoint}`, REQUEST_METHODS.PATCH, null, {
    ...headers,
    ...authorization(),
    ...(authorizationTenant()?.["Tenant-Id"] !== "undefined" ? { ...authorizationTenant() } : {})
  }).then(async response => await checkAccuknoxAPIStatus(response));
};
