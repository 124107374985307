import React from "react";
import * as moment from "dayjs";
import { ContentContainer, ActionButton } from "components/simple";

import styles from "../styles.module.scss";

const Statistics = ({ data, onShowEditModal }) => {
  const backgrounds = {
    orange: "#FEBD46",
    blue: "#576ACA",
    green: "#9EEC60",
    red: "#EB5958"
  };
  return (
    <ContentContainer className={styles.statisticsContent}>
      <div className={styles.block}>
        <div className={styles.line} style={{ background: backgrounds.orange }} />
        <div className={styles.name}>
          <div>Severity:</div>
          <div>{data.vulnerability.risk_factor}</div>
        </div>
        <ActionButton
          type="edit"
          onClick={() => onShowEditModal("severity")}
          className={styles.control}
        />
      </div>
      <div className={styles.block}>
        <div className={styles.line} style={{ background: backgrounds.blue }} />
        <div className={styles.name}>
          <div>Status:</div>
          <div>{data.status}</div>
        </div>
        <ActionButton
          type="edit"
          onClick={() => onShowEditModal("status")}
          className={styles.control}
        />
      </div>
      <div className={styles.block}>
        <div className={styles.line} style={{ background: backgrounds.green }} />
        <div className={styles.name}>
          <div>Exploitability:</div>
          <div>{data.vulnerability.exploit_available.toString()}</div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.line} style={{ background: backgrounds.red }} />
        <div className={styles.name}>
          <div>Discovered:</div>
          <div>{moment(data.date_discovered).fromNow()}</div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default Statistics;
