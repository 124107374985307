import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Select, Title, ContentContainer } from "components/simple";
import styled from "@emotion/styled";
import Radio from "@mui/material/Radio";
import { navigate } from "helper/history";
import { routes } from "router";
import styles from "./styles.module.scss";
import { useForm, Controller } from "react-hook-form";
import { optionArr } from "./fixture";
import {
  useClusterOnboardQuery,
  useGetOnboardedClusters,
  workspaceID
} from "store/entities/onboarding/queries";
import { useStoreClusterMutation } from "store/entities/onboarding/mutations";

const ClusterOnboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [regionData, setRegionData] = useState();
  const [options, setOptions] = React.useState("Regional");

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange",
    defaultValues: {}
  });

  const { data: clusterData = [] } = useClusterOnboardQuery();
  const storeCluster = useStoreClusterMutation();

  const handleChange = event => {
    setOptions(event.target.value);
  };
  const { refetch: refetchOnboardedClusters } = useGetOnboardedClusters();

  const storeClusterHandler = formValues => {
    const payload = {
      store_clusters_request: [
        {
          cloud_provider: "",
          cluster_name: formValues.clusterName,
          location: "",
          workspace_id: workspaceID
        }
      ]
    };
    storeCluster.mutate(payload, {
      onSuccess: data => {
        refetchOnboardedClusters();
        const clusterDetails = {
          clusterId: data.result?.hash?.[0]?.cluster_id,
          clusterName: payload?.store_clusters_request?.[0]?.cluster_name
        };
        navigate(routes.settingsManageClusterOnboardScrollSpy, clusterDetails);
      }
    });
  };

  const confirmHandler = () => {
    navigate(routes.settingsManageCluster);
  };

  const zoneRegionHandler = () => {
    if (options === "Regional") {
      return "Region";
    }
    return "Zone";
  };

  const zoneRegionNameHandler = () => {
    if (options === "Regional") {
      const filteredRegionData = clusterData.map(data => ({
        value: data?.Region,
        label: data?.Region
      }));
      setRegionData(filteredRegionData);
    }
  };

  useEffect(() => {
    zoneRegionNameHandler();
  }, [options, JSON.stringify(clusterData)]);

  return (
    <ContentContainer className={styles.container}>
      <ClusterOnboardingForm>
        <Title className="title">Cluster Onboarding</Title>
        <form onSubmit={handleSubmit(storeClusterHandler)} className="w-full">
          <ClusterContainer>
            <p>Enter Cluster Name</p>
            <Controller
              name="clusterName"
              control={control}
              rules={{
                required: true,
                validate: value => {
                  const regex = /^[a-z0-9]([-a-z0-9]*[a-z0-9])$/g;
                  return (
                    regex.test(value) || "Please enter a valid name with only lowercase letters"
                  );
                }
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    onChange={data => {
                      onChange(data);
                      // clusterNameHandler(data);
                    }}
                    type="text"
                    placeholder="Enter Cluster Name"
                    containerClassName="cluster_input"
                    invalid={errors.clusterName?.message}
                  />
                );
              }}
            />
          </ClusterContainer>

          <ClusterContainer>
            <ClusterSaveButton>
              <Modal isOpen={isModalOpen} width="100%" toggle={() => setIsModalOpen(false)}>
                <div>
                  <h2>Confirmation</h2>
                  <hr />
                </div>
                <div>
                  <p>All your changes will be lost. Are you sure you want to go back?</p>
                </div>
                <BtnContainer>
                  <Button className="clusterButtonBack" onClick={() => setIsModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button onClick={confirmHandler}>Confirm</Button>
                </BtnContainer>
              </Modal>
              <Button onClick={() => setIsModalOpen(!isModalOpen)} className="clusterButtonBack">
                Back
              </Button>
              <Button onClick={handleSubmit(storeClusterHandler)} disabled={!isValid}>
                Save & Next
              </Button>
            </ClusterSaveButton>
          </ClusterContainer>
        </form>
      </ClusterOnboardingForm>
    </ContentContainer>
  );
};

export default ClusterOnboard;

const ClusterOnboardingForm = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  margin-top: 35px;

  .title {
    margin-bottom: 50px;
  }
`;
const ClusterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  width: 50%;
  margin-bottom: 15px;

  .cluster_input {
    width: 100%;
  }
`;

const ClusterSaveButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  gap: 10px;

  .clusterButtonBack {
    height: 38px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: #fff;
    padding: 10px 20px 12px;
    width: auto;
    color: black;
  }

  .clusterButtonBack:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;

  .clusterButtonBack {
    height: 38px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: #fff;
    padding: 10px 20px 12px;
    width: auto;
    color: black;
  }

  .clusterButtonBack:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
