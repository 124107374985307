import { API, authorization, contentTypeJson } from "api/api";
import { get, patch, post, put } from "api/base";
import { getAccessTokenHeader } from "./utils";

export const login = async payload => {
  const response = await post(`${API}/login/`, JSON.stringify(payload), {
    ...contentTypeJson()
  });
  if (!response.ok) {
    throw new Error("Error while logging in");
  }
  const data = await response.json();
  return data;
};

export const signUp = async payload => {
  const response = await post(`${API}/sign-up`, JSON.stringify(payload), {
    ...contentTypeJson()
  });
  const data = await response.json();
  if (!response.ok) {
    throw data;
  }
  return data;
};

export const getQRMFA = async (userId, accessToken) => {
  const response = await get(`${API}/mfa/add`, {
    ...contentTypeJson(),
    ...getAccessTokenHeader()
  });
  if (!response.ok) {
    throw new Error("There was an error while getting QR Code");
  }
  const data = await response.json();
  return data;
};

export const addMFA = async payload => {
  const response = await post(`${API}/mfa/submit`, JSON.stringify(payload), {
    ...contentTypeJson(),
    ...getAccessTokenHeader()
  });
  if (!response.ok) {
    throw new Error("Error while adding MFA");
  }
  const data = await response.text();
  return data;
};

export const verifyMFA = async payload => {
  const response = await post(`${API}/mfa/verify`, JSON.stringify(payload), {
    ...contentTypeJson()
  });
  if (!response.ok) {
    throw new Error("Error while verifying MFA");
  }
  const data = await response.json();
  return data;
};

export const verifyDomain = async payload => {
  const response = await post(`${API}/verify-domain/`, JSON.stringify(payload), {
    ...contentTypeJson()
  });
  const data = await response.json();
  return data;
};

export const forgotPassword = async payload => {
  const response = await post(`${API}/reset-password/reset`, JSON.stringify(payload), {
    ...contentTypeJson()
  });
  if (!response.ok) {
    throw response;
  }
  const data = await response.json();
  return data;
};

export const resetPassword = async payload => {
  const response = await post(`${API}/reset-password/new-password`, JSON.stringify(payload), {
    ...contentTypeJson()
  });
  if (!response.ok) {
    throw response;
  }
  return response;
};

export const verifyEmail = async ({ uid, userToken }) => {
  const response = await get(`${API}/verify-email/${uid}/${userToken}/`, {
    ...contentTypeJson()
  });
  if (!response.ok) {
    throw response;
  }
  const data = await response.json();
  return data;
};

export const validateToken = async ({ uid, userToken }) => {
  const response = await get(`${API}/reset-password/validate-token?uid=${uid}&token=${userToken}`, {
    ...contentTypeJson()
  });
  if (!response.ok) {
    throw response;
  }
  return response;
};

export const changePassword = async payload => {
  const response = await patch(`${API}/users/change-password`, JSON.stringify(payload), {
    ...contentTypeJson(),
    ...authorization()
  });
  const data = await response.json();
  if (!response.ok) {
    throw data;
  }
  return data;
};

export const refreshToken = async () => {
  const token = localStorage.getItem("token");
  const payload = { token };

  const response = await post(`${API}/auth/refresh/`, JSON.stringify(payload), {
    ...authorization(),
    ...contentTypeJson()
  });
  const data = await response.json();
  return data;
};
