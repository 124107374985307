import React from "react";
import PropTypes from "prop-types";
import TooltipComp from "@mui/material/Tooltip";

import styles from "./styles.module.scss";

export const Tooltip = ({ title, children }) => {
  return (
    <TooltipComp title={title}>
      <div className={styles.container}>{children}</div>
    </TooltipComp>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string
};

Tooltip.defaultProps = {
  title: "Tooltip"
};
