import React from "react";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { handleAddRule } from "store/entities/policyEditor/NetworkPolicyEditor/slice";
import { Typography } from "@mui/material";
import {
  K8S_NETWORK_RULES,
  TRAFFIC_OPTIONS
} from "store/entities/policyEditor/NetworkPolicyEditor/constants";
import clsx from "clsx";

const RulesPalette = () => {
  const dispatch = useDispatch();
  const [selectedTrafficType, setSelectedTrafficType] = React.useState(TRAFFIC_OPTIONS[0].label);

  return (
    <RulesPaletteContainer className="flex flex-col gap-1 text-sm bg-white rounded-lg">
      <div className="m-2 mb-0">
        <Typography>Rules</Typography>
        <Typography sx={{ fontSize: 12, color: "grey.600" }}>
          Drag and Drop the properties below
        </Typography>
      </div>

      <div className="grid grid-cols-2 border border-solid border-gray-400 m-2 mb-0">
        {TRAFFIC_OPTIONS?.map(option => (
          <span
            key={option.id}
            className={clsx(
              "flex-1 text-center py-[6px] cursor-pointer",
              option.id === 1 ? "border-0 border-r border-solid border-gray-400" : "",
              selectedTrafficType === option.label ? "bg-gray-200" : "bg-white"
            )}
            onClick={() => setSelectedTrafficType(option.label)}
          >
            <Typography>{option.label}</Typography>
          </span>
        ))}
      </div>

      <div className="px-2 py-0 rounded-b-lg bg-gray-50">
        {K8S_NETWORK_RULES?.map((ruleType, index) => (
          <li key={index} className="flex py-2 items-end justify-between">
            <Typography>{ruleType}</Typography>
            <span
              className="cursor-pointer"
              onClick={() => {
                dispatch(handleAddRule({ trafficType: selectedTrafficType, ruleType }));
              }}
            >
              <AddIcon color="primary" />
            </span>
          </li>
        ))}
      </div>
    </RulesPaletteContainer>
  );
};

export default RulesPalette;

const RulesPaletteContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 240px;
  height: fit-content;
`;
