import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

export const setImportHistoryParams = createAction(constant.SET_IMPORT_HISTORY_PARAMS);

// Get ImpotHistory
export const getImportHistory = createAction(constant.GET_IMPORT_HISTORY);
export const getImportHistorySuccess = createAction(constant.GET_IMPORT_HISTORY_SUCCESS);
export const getImportHistoryFailed = createAction(constant.GET_IMPORT_HISTORY_FAILED);

// Delete ImpotHistory
export const deleteImportHistory = createAction(constant.DELETE_IMPORT_HISTORY);
export const deleteImportHistorySuccess = createAction(constant.DELETE_IMPORT_HISTORY_SUCCESS);
export const deleteImportHistoryFailed = createAction(constant.DELETE_IMPORT_HISTORY_FAILED);

// Add Tag
export const addTag = createAction(constant.ADD_TAG);
export const addTagSuccess = createAction(constant.ADD_TAG_SUCCESS);
export const addTagFailed = createAction(constant.ADD_TAG_FAILED);

// Get Data Types
export const getDataTypes = createAction(constant.GET_DATA_TYPES);
export const getDataTypesSuccess = createAction(constant.GET_DATA_TYPES_SUCCESS);
export const getDataTypesFailed = createAction(constant.GET_DATA_TYPES_FAILED);

// Run report
export const runReport = createAction(constant.RUN_REPORT);
export const runReportSuccess = createAction(constant.RUN_REPORT_SUCCESS);
export const runReportFailed = createAction(constant.RUN_REPORT_FAILED);
