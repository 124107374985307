import { customGet, customPost } from "../../../../api/base";
import { USER_ID } from "./constants";

export const getClusters = async workspaceId => {
  const response = await customGet(`/policystorageservice/api/v1/policy/clusters/${workspaceId}`);
  const data = await response.json();
  return data;
};

export const getNamespaces = async requestBody => {
  const response = await customPost("/policystorageservice/api/v1/policy/namespaces", requestBody);
  const data = await response.json();
  return data;
};

export const getInstanceGroups = async requestBody => {
  const response = await customPost(
    "/policystorageservice/api/v1/policy/vminstancegroup",
    requestBody
  );
  const data = response.json();
  return data;
};

export const getInstances = async requestBody => {
  const response = await customPost("/policystorageservice/api/v1/policy/vminstance", requestBody);
  const data = response.json();
  return data;
};

export const getLabels = async requestBody => {
  const response = await customPost("/policystorageservice/api/v1/policy/labels", requestBody);
  const data = response.json();
  return data;
};

export const getTags = async workspaceId => {
  const response = await customGet(`/policystorageservice/api/v1/policy/tags/${workspaceId}`);
  const data = response.json();
  return data;
};

export const storeTags = async payload => {
  const response = await customPost("/policystorageservice/api/v1/policy/tag", {
    ...payload,
    created_by: USER_ID // TODO: get this userId dynamically from localStorage
  });
  const data = response.json();
  return data;
};

export const getAllPolicies = async requestBody => {
  const response = await customPost("/policystorageservice/api/v1/policy/policies", requestBody);
  const data = response.json();
  return data;
};

export const storePolicy = async requestBody => {
  const response = await customPost("/policystorageservice/api/v1/policy/store", requestBody);
  const data = response.json();
  return data;
};

export const updatePolicy = async requestBody => {
  const response = await customPost("/policystorageservice/api/v1/policy/update", requestBody);
  const data = response.json();
  return data;
};

export const deletePolicy = async requestBody => {
  const response = await customPost("/policystorageservice/api/v1/policy/delete", requestBody);
  const data = response.json();
  return data;
};

export const applyPolicy = async requestBody => {
  const response = await customPost(
    "/policystorageservice/api/v1/policy/apply-policy",
    requestBody
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data?.message);
  }
  return data;
};

export const downloadPolicies = async requestBody => {
  const response = await customPost("/policystorageservice/api/v1/policy/download", requestBody, {
    responseType: "blob"
  });
  const data = response.blob();
  return data;
};
