import React, { useState } from "react";
// import { usePrimaryLayoutTitle } from "../../contexts/primaryLayoutTitle";
import "./HybridViewSidebar.scss";
import { CLUSTER, POD, CONTAINER, VMGROUP } from "../constant";

const CustomFilterComponent = ({
  sidebarFilterOptions,
  setShowViewPolicy,
  selectedFilter,
  setSelectedFilter,
  setSearch,
  setEntityType,
  setSelectedView,
  setVmGroupData,
  instanceGroupData,
  switchToNormalView
}) => {
  // const { setMainEntity } = usePrimaryLayoutTitle();

  const [setActive, setActiveState] = useState("");

  const toggleHandler = () => {
    setActiveState(setActive === "" ? "active" : "");
  };
  return (
    <>
      <div className="filter-section">
        {sidebarFilterOptions?.map(val => (
          <div
            key={val}
            className={selectedFilter === val ? "active" : ""}
            onClick={() => {
              setSelectedFilter(val);
              setSearch("");
              toggleHandler();
              setShowViewPolicy(false);
              // setMainEntity(val === "K8s Cluster" ? "Clusters" : "VM");
              setEntityType(val === "K8s Cluster" ? "Clusters" : "vmgroup");
              setSelectedView(val === "K8s Cluster" ? CLUSTER : VMGROUP);
              setVmGroupData(
                // eslint-disable-next-line no-nested-ternary
                instanceGroupData?.isError
                  ? []
                  : instanceGroupData.isFetched
                  ? instanceGroupData?.data?.list_of_instance_group
                  : []
              );
              switchToNormalView();
            }}
          >
            {val}
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomFilterComponent;
