import React from "react";
import { Box, Typography } from "@mui/material";
import { Button, ContentContainer, Tabs, Title } from "components/simple";
import styles from "./style.module.scss";
import styled from "styled-components";
import { comingSoon, notificationData, securityEventData } from "./constants";
import { useHistory, Route } from "react-router-dom";

const ChannelIntegration = () => {
  const history = useHistory();
  return (
    <ContentContainer className="p-5">
      <Typography variant="h6" margin="0px 30px">
        Security Events
      </Typography>
      <TableContainer>
        <Box className={styles.channel_content}>
          {securityEventData?.map((item, index) => (
            <Box className={`${styles.channel_box}`} key={index}>
              <Box className={styles.channel_sub_content}>
                <div>
                  <Box className={styles.logo_content}>
                    <img src={item?.logo} alt="logo" />
                    <div>{item.title}</div>
                  </Box>
                  <Box className={styles.res_screen}>{item?.desc}</Box>
                </div>
                <Box className={styles.margin_class}>
                  <Button onClick={() => history.push(item?.link)}>{item?.integrate}</Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </TableContainer>
      <Typography variant="h6" margin="0px 30px">
        Notification
      </Typography>
      <TableContainer>
        <Box className={styles.channel_content}>
          {notificationData?.map((item, index) => (
            <Box className={`${styles.channel_box}`} key={index}>
              <Box className={styles.channel_sub_content}>
                <div>
                  <Box className={styles.logo_content}>
                    <img src={item?.logo} alt="logo" />
                    <div>{item.title}</div>
                  </Box>
                  <Box className={styles.res_screen}>{item?.desc}</Box>
                </div>
                <Box className={styles.margin_class}>
                  <Button onClick={() => history.push(item?.link)}>{item?.integrate}</Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </TableContainer>
      <Typography variant="h6" margin="0px 30px">
        Coming Soon
      </Typography>
      <TableContainer>
        <Box className={styles.channel_content}>
          {comingSoon?.map((item, index) => (
            <Box className={`${styles.channel_box}`} key={index}>
              <Box className={styles.channel_sub_content}>
                <div>
                  <Box className={styles.logo_content}>
                    <img src={item?.logo} alt="logo" />
                    <div style={{ opacity: "0.5" }}>{item.title}</div>
                  </Box>
                  <Box className={styles.res_screen} style={{ opacity: "0.5" }}>
                    {item?.desc}
                  </Box>
                </div>
                <Box className={styles.margin_class}>
                  <Button disabled>{item?.integrate}</Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </TableContainer>
    </ContentContainer>
  );
};

export default ChannelIntegration;

const TableContainer = styled.div`
  overflow: hidden;
  width: 100%;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;
