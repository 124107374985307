import React, { useState, useEffect } from "react";

import { uniqueId } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import { Table } from "components/complex";
import { customStyle, layersDataHeaders } from "./utils";
import { ContentContainer, Search } from "components/simple";
import { useGetImageDetails } from "store/entities/imageDetails/queries";

export const Layers = ({ assetId }) => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchedLayers, setSearchedLayers] = useState([]);

  const {
    data: layersData = [],
    isLoading: isLayersDataLoading,
    refetch: refetchLayersData
  } = useGetImageDetails("INSPECT_IMAGES", assetId);

  useEffect(() => {
    const searchResult = layersData?.filter(row =>
      row?.created_by?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSearchedLayers(searchResult);
  }, [searchValue]);

  const apiRef = useGridApiRef();

  return (
    <ContentContainer className="p-4 mt-2">
      <div className="flex flex-col gap-3">
        <div>Search for Layers</div>
        <div style={{ display: "flex" }}>
          <Search value={searchValue} onSearch={e => setSearchValue(e)} />
          <CloseIcon
            style={{ cursor: "pointer", alignSelf: "center", marginLeft: "-30px", zIndex: 10 }}
            onClick={() => setSearchValue("")}
          />
        </div>
      </div>
      <div className="mt-3">
        <Table
          page={page}
          apiRef={apiRef}
          pageSize={pageSize}
          checkboxVisible={false}
          customStyles={customStyle}
          columns={layersDataHeaders}
          data={searchValue === "" ? layersData : searchedLayers}
          getRowId={row => uniqueId()}
          onPageChange={p => setPage(p)}
          rowCount={layersData?.length}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        />
      </div>
    </ContentContainer>
  );
};
