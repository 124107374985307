import { createDraftSafeSelector } from "@reduxjs/toolkit";

const dashboard = state => state.dashboard?.dashboard;

export const dashboardSelector = createDraftSafeSelector(dashboard, state => {
  if (!Object.keys(state)?.length) {
    return { most_vulnerable_assets: [], scans_data: [], topRiskData: [] };
  }
  const most_vulnerable_assets = state?.most_vulnerable_assets.map(item => {
    return {
      asset: item?.title,
      vulnerabilities: {
        Low: item?.low,
        Critical: item?.critical,
        High: item?.high,
        Medium: item?.medium
      },
      id: item?.id,
      last_scan: item?.last_scan
    };
  });
  const scans_data = state?.latest_scans.map(item => {
    return {
      scan_file: item?.scan_file,
      vulnerabilities: {
        Low: item?.low,
        Critical: item?.critical,
        High: item?.high,
        Medium: item?.medium
      },
      total_assets: item?.total_assets.toString(),
      id: item?.id,
      last_scan: item?.scan_date
    };
  });
  const topRiskData = state?.top_risks.map((item, index) => {
    return {
      name: item?.vulnerability?.name,
      data_type: item?.vulnerability?.data_type,
      asset: item?.asset?.title,
      risk_score: item?.vulnerability?.risk_factor,
      id: index,
      asset_id: item?.asset?.id
    };
  });

  return { most_vulnerable_assets, scans_data, topRiskData };
});

const colors = [
  "#26dfb5",
  "#9fec60",
  "#7edacf",
  "#bfcc78",
  "#2075f7",
  "#ee00fb",
  "#eb5958",
  "#d602c9",
  "#a0a862",
  "#6678df",
  "#4cacd4",
  "#576aca",
  "#b686b0",
  "#da07d8",
  "#394563"
];

const setColor = (k, index) => {
  switch (k) {
    case "Very Low":
      return "#26DFB5";
    case "Low":
      return "#9EEC60";
    case "Critical":
      return "#EB5958";
    case "High":
      return "#576ACA";
    case "Medium":
      return "#FEBD46";
    case "Informational":
      return "#F5FF27";

    case "awsaccount":
      return "#576ACA";
    case "host":
      return "#EB5958";

    case "fail_baselines":
      return "#EB5958";
    case "pass_baselines":
      return "#9EEC60";

    case "closed":
      return "#EB5958";
    case "ongoing":
      return "#FEBD46";
    case "opened":
      return "#9EEC60";

    case "container":
      return "#26DFB5";
    case "webapp":
      return "#9EEC60";
    case "awsasset":
      return "#84E9FF";
    case "device":
      return "#EE00FB";
    case "software":
      return "#F5FF27";

    default: {
      const color = colors?.[index] ?? colors?.[index % 15];
      return color;
    }
  }
};

function sort(data, sortOrderBy) {
  const itemsById = new Map(data.map(item => [item.title, item]));
  return sortOrderBy.map(field => itemsById.get(field)).filter(item => item);
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getPieChartsDataSelector = createDraftSafeSelector(dashboard, state => {
  if (!state?.pie_charts_data) {
    return { vulnerabilities: [], compliance: [], tickets: [], assets: [] };
  }
  const vulnerabilitiesObj = state?.pie_charts_data?.vulnerabilities;
  const complianceObj = state?.pie_charts_data?.compliance;
  const ticketsObj = state?.pie_charts_data?.tickets;
  const assetsObj = state?.pie_charts_data?.assets;

  const vulnerabilities = Object.keys(vulnerabilitiesObj).map((key, index) => {
    return { title: key, key, value: vulnerabilitiesObj[key], color: setColor(key, index) };
  });
  const sortedDataVulnerabilities = sort(vulnerabilities, [
    "Critical",
    "High",
    "Medium",
    "Low",
    "Very Low",
    "Informational"
  ]);

  const compliance = Object.keys(complianceObj).map((key, index) => {
    let title = "";
    switch (key) {
      case "fail_baselines":
        title = "Fail";
        break;
      case "pass_baselines":
        title = "Pass";
        break;
      default:
        break;
    }
    return { title, key, value: complianceObj[key], color: setColor(key, index) };
  });
  const tickets = Object.keys(ticketsObj).map((key, index) => {
    return {
      title: capitalizeFirstLetter(key),
      key,
      value: ticketsObj[key],
      color: setColor(key, index)
    };
  });
  const assets = Object.keys(assetsObj).map((key, index) => {
    return {
      title: capitalizeFirstLetter(key),
      key,
      value: assetsObj[key],
      color: setColor(key, index)
    };
  });

  return {
    vulnerabilities: sortedDataVulnerabilities,
    compliance,
    tickets,
    assets
  };
});

export const getOverviewDataSelector = createDraftSafeSelector(dashboard, state => {
  if (state?.vulnerability_overview) {
    const res = Object.entries(state?.vulnerability_overview).map(([key, val]) => {
      return {
        name: key?.replaceAll("_", " ").replaceAll("cve", "CVE"),
        value: val?.value
      };
    });
    return res;
  }
  return [];
});

export const vulnerabilitiesChartSelector = createDraftSafeSelector(dashboard, state => {
  if (state?.vulnerabilities_by_source) {
    const res = Object.entries(state?.vulnerabilities_by_source).map(([key, val]) => {
      return {
        name: key,
        Critical: val?.Critical,
        High: val?.High,
        Low: val?.Low,
        Medium: val?.Medium,
        Negligible: val?.Negligible
      };
    });
    return res;
  }
  return [];
});
