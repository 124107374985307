import { getDifferenceTime, getTime } from "screens/Inventory/ClusterManagement/service/query";

export const getTimeRange = range => {
  let from;
  let to;
  const now = new Date();
  if (typeof range === "string") {
    from = getDifferenceTime(range, now).getTime();
    to = now.getTime();
  } else if (typeof range === "object") {
    from = getTime(range.from);
    to = getTime(range.to);
  }

  return { from, to };
};
