import { Box, Typography } from "@mui/material";
import { Table } from "components/complex";
import React, { useState } from "react";
import NoTableDataSVG from "../../../assets/images/noDataAvailable.svg";

function TopTicketsTable({ data, column, onCellClick, loading }) {
  const customStyles = {
    height: "150%",
    width: "100%",
    paddingTop: 0,
    "& .MuiDataGrid-root": {
      fontSize: "12px",
      color: "#000000"
    },
    "& .MuiDataGrid-row": {
      cursor: "pointer",
      maxHeight: "30px !important",
      minHeight: "30px !important"
    },
    "& .MuiDataGrid-cell": {
      maxHeight: "30px !important",
      minHeight: "30px !important"
    },
    "& .MuiDataGrid-virtualScroller": {
      overflow: "hidden"
    }
  };
  return (
    <Table
      paginationVisible={false}
      hideFooter={true}
      disableSelectionOnClick={true}
      checkboxVisible={false}
      rows={loading ? [] : data}
      loading={loading}
      columns={column}
      onCellClick={(p, e) => onCellClick(p, e)}
      customStyles={customStyles}
      components={{
        NoRowsOverlay: () => (
          <Box className="flex flex-col items-center justify-center w-full h-full">
            <img src={NoTableDataSVG} alt="" />
            <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>No data available!</Typography>
          </Box>
        ),
        NoResultsOverlay: () => (
          <Box className="flex flex-col items-center justify-center w-full h-full">
            <img src={NoTableDataSVG} alt="" />
            <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>No data available!</Typography>
          </Box>
        )
      }}
    />
  );
}

export default TopTicketsTable;
