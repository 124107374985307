import React, { useEffect, useState } from "react";

import { Button, Title, Modal } from "components/simple";

import Radio from "@mui/material/Radio";
import { styled } from "@mui/material";
import styles from "./styles.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { actionGroupModal } from "store/entities/vulnerabilities/actions";

import NewGroup from "./NewGroup";
import ExistingGroup from "./ExistingGroup";

const Block = styled("div")(({ theme, active }) => {
  return {
    display: "flex",
    padding: "14px",
    borderRadius: "5px",
    marginTop: "16px",
    alignItems: "flex-start",
    cursor: "pointer",
    background: active ? theme.modalSelectionBackground : "transparent",

    "&:active": {
      background:theme.modalSelectionBackground
    }
  };
});

const CreateGroupModal = () => {
  const [selectType, setSelectType] = useState("new");
  const [isNext, setNext] = useState(false);
  const dispatch = useDispatch();

  const { show, data } = useSelector(state => state.vulnerabilities?.groupModal);

  const close = () => {
    dispatch(actionGroupModal({ show: false, data: [] }));
    setNext(false);
  };

  const onNext = () => {
    setNext(true);
  };

  const handleChange = v => {
    setSelectType(v);
  };

  useEffect(() => {
    if (!show) {
      setNext(false);
    }
  }, [show]);

  if (isNext && show) {
    if (selectType === "new") {
      return <NewGroup open onBack={() => setNext(false)} close={close} data={data} />;
    }
    return <ExistingGroup open onBack={() => setNext(false)} close={close} data={data} />;
  }

  return (
    <>
      <Modal isOpen={show} toggle={close}>
        <Title>Add to group</Title>
        <div className={styles.content}>
          <div className={styles.title}>Choose the type of group:</div>

          <Block active={selectType === "new"} onClick={() => handleChange("new")}>
            <Radio
              checked={selectType === "new"}
              sx={{
                padding: 0
              }}
              onChange={() => handleChange("new")}
              value="new"
              name="radio-buttons"
            />
            <div className={styles.cont}>
              <div className={styles.title}>New group</div>
              <div className={styles.description}>It allows you to create a new group</div>
            </div>
          </Block>

          <Block active={selectType === "existing"} onClick={() => handleChange("existing")}>
            <Radio
              checked={selectType === "existing"}
              sx={{
                padding: 0
              }}
              onChange={() => handleChange("existing")}
              value="existing"
              name="radio-buttons"
            />
            <div className={styles.cont}>
              <div className={styles.title}>Existing group</div>
              <div className={styles.description}>It allows you to edit an existing group</div>
            </div>
          </Block>
        </div>
        <div className={styles.modalFooter}>
          <Button onClick={close} variant="outline" className={styles.modalButton}>
            Close
          </Button>
          <Button onClick={onNext} className={styles.modalButton}>
            Next
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CreateGroupModal;
