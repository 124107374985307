import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { routes } from "router";

import { ContentContainer, InputSearch, Title, Loader } from "components/simple";

import styles from "./styles.module.scss";

const Statistics = ({ title, name, request, render, headers }) => {
  const [data, setData] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);

  const onRequest = async (p, s) => {
    setLoading(true);
    const res = await request({ page: p, search: s })
      .then(r => {
        setLoading(false);
        return r.json();
      })
      .catch(() => {
        setLoading(false);
      });
    setData(res);
  };

  const reloadRequest = () => {
    setData(null);
    setPage(1);
    onRequest(1, searchVal);
  };

  useEffect(() => {
    onRequest(page, searchVal);
  }, []);

  const lazyLoadRequest = async () => {
    const res = await request({ page: page + 1, search: searchVal }).then(r => {
      return r.json();
    });
    setPage(page + 1);
    setData({
      ...data,
      ...res,
      results: [...data?.results, ...res?.results]
    });
  };

  // Search
  const timerRef = React.useRef();
  const triggerSearchRequest = text => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setData(null);
      onRequest(1, text);
      setPage(1);
    }, 500);
  };

  const handleSearch = v => {
    triggerSearchRequest(v);
    setSearchVal(v);
  };

  const list = data?.results || [];
  const renderList = () => {
    if (render) {
      return render(list, name, reloadRequest);
    }
    if (name === "tickets") {
      return list.map(elem => (
        <li key={elem.id} className={styles.li}>
          <Link to={`${routes.ticketsDetails}/${elem.id}`}>{elem.name}</Link>
        </li>
      ));
    }

    if (name === "monitors") {
      return list.map(elem => (
        <li key={elem.id} className={styles.li}>
          <Link to={`${routes.monitorsConfiguration}/${elem.id}`}>{elem.name}</Link>
        </li>
      ));
    }

    return list?.map(elem => (
      <li className={styles.li} key={elem.id}>
        {elem.name}
      </li>
    ));
  };

  return (
    <ContentContainer>
      <div className={`${styles.headerSection} ${styles.searchHeader}`}>
        <Title>{title}</Title>
        <InputSearch value={searchVal} onChange={val => handleSearch(val)} placeholder="Search" />
      </div>

      {headers && (
        <div className={`${styles.tableRow} ${styles.tableHead}`}>
          {headers.map((el, index) => (
            <span key={index}>{el}</span>
          ))}
        </div>
      )}

      <ul className={styles.scrollableList}>
        {isLoading ? (
          <Loader />
        ) : (
          <InfiniteScroll
            dataLength={list?.length || 0} // This is important field to render the next data
            next={lazyLoadRequest}
            hasMore={!!data?.next}
            height={165}
            loader={<Loader />}
          >
            {list?.length ? renderList(list) : <div className={styles.noData}>No data</div>}
          </InfiniteScroll>
        )}
      </ul>
    </ContentContainer>
  );
};

export default Statistics;
