/* eslint-disable camelcase */

import { createReducer } from "@reduxjs/toolkit";

import * as actions from "./actions";

const initialState = {
  theme: "Theme9"
};

const reducer = createReducer(
  initialState,
  {
    [actions.changeTheme.type]: (state, action) => {
      localStorage.setItem("theme", action.payload);
      return { ...state, theme: action.payload };
    }
  },
  [], // required
  state => state // required
);

export default reducer;
