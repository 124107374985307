import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { runReport } from "store/entities/reports/actions";
import { Button, Modal, DateRangePicker } from "components/simple";

import styles from "./styles.module.scss";

const RunModal = ({ open, onClose, data }) => {
  const dispatch = useDispatch();
  const [datePicker, setDatePicker] = useState([null, null]);

  const hangleChangeDate = v => {
    setDatePicker(v);
  };
  const close = () => {
    onClose();
  };

  const onRun = () => {
    dispatch(runReport({ list: data, date: datePicker, callback: close }));
  };
  useEffect(() => {
    return () => setDatePicker([null, null]);
  }, [open]);

  return (
    <Modal isOpen={open} toggle={close} width={425}>
      {/* <Title>You do not have permissions to the Settings</Title> */}
      <div className={styles.datePicker}>
        <DateRangePicker
          label="Select Date Range"
          placeholder="Select Date Range"
          onChange={v => hangleChangeDate(v)}
          value={datePicker || ""}
          clearable={false}
        />
      </div>
      <div className={styles.footer}>
        <Button className={styles.modalButton} onClick={close} variant="outline">
          Close
        </Button>
        <Button className={styles.modalButton} onClick={onRun}>
          Run
        </Button>
      </div>
    </Modal>
  );
};

export default RunModal;
