import { Box, Typography, Link, Badge, IconButton, Popover } from "@mui/material";
import React, { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import ButtonLink from "../ButtonLink";
import styles from "./styles.module.scss";
import SelectorLabel from "../SelectorLabel";
import CommentMessagePopover from "../CommentMessagePopover";
import { CommentMessage } from "../CommentMessagePopover/CommentMessagePopover";
import { useHistory } from "react-router-dom";
import { routes } from "router/router";
import { navigate } from "helper/history";
import { formatDate } from "helper/helpers";

export default function PolicyDetailView({ policy, setShowModel, setSelectedPolicy, userList }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handlePopOverOpen = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handlePopOverClose = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handlePolicyDetail = policyDetail => {
    navigate(routes.pendingpolicyDetailView, { selectedPolicy: policyDetail });
  };
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", marginLeft: "10px" }}>
      <div className={styles.PolicyDetails}>
        <Box sx={{ alignItems: "center", display: "flex" }}>
          <Typography sx={{ fontWeight: "600", paddingRight: "2px" }} variant="b2">
            Policy Name:
          </Typography>
          <ButtonLink
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handlePolicyDetail(policy);
            }}
            isUnderline={false}
            fontsize="16px"
            fontweight="600"
            buttonLable={policy?.policy_name}
          />
        </Box>
        <div className={styles.policy_data}>
          <div className={styles.UpdatedTimeLabel}>
            <Typography sx={{ fontWeight: "500", fontSize: "13px", paddingRight: "2px" }}>
              Latest Update by <strong>{userList?.[policy?.updated_by]}</strong> at &nbsp;
              {policy?.updated_at}
            </Typography>
          </div>
          <div className={styles.VersionLabel}>
            <Typography sx={{ fontWeight: "500", fontSize: "13px", paddingRight: "2px" }}>
              Version:
            </Typography>
            <ButtonLink
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handlePolicyDetail(policy);
              }}
              isUnderline={false}
              fontsize="14px"
              fontweight="500"
              buttonLable={policy?.version || 0}
            />
          </div>
          <div className={styles.selectorLabel}>
            <Typography sx={{ fontWeight: "500", fontSize: "13px", paddingRight: "2px" }}>
              Selector Labels:
            </Typography>
            <div className="relative bottom-1">
              <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                {policy?.labels ? (
                  <SelectorLabel labels={policy?.selector_labels} />
                ) : (
                  <span className="inline-block mt-1">None</span>
                )}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.PolicyReview}>
        <ButtonLink
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowModel(true);
            setSelectedPolicy(policy);
          }}
          isUnderline
          fontsize="14px"
          fontweight="600"
          buttonLable=" Review Changes "
        />
        <IconButton
          onMouseEnter={e => handlePopOverOpen(e)}
          onMouseLeave={e => handlePopOverClose(e)}
        >
          <Badge
            overlap="circular"
            badgeContent=" "
            variant="dot"
            sx={{
              "& .MuiBadge-badge": {
                background: "#B50404"
              }
            }}
          >
            <EmailIcon sx={{ color: "#D3D3D3" }} />
          </Badge>
        </IconButton>
        <Popover
          sx={{
            pointerEvents: "none",
            minWidth: 500,
            padding: "2px",
            opacity: "0.9"
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handlePopOverClose}
        >
          <CommentMessage
            ownerName={
              userList?.[policy?.updated_by] === window.__env__.REACT_APP_ACCUKNOX_DISCOVERY_ENGINE_ID
                ? "AccuKnox Discovery Engine"
                : userList?.[policy?.updated_by] || "Unknown"
            }
            data={policy?.updated_at}
            message={policy?.review_msg || "no message from reviewer"}
          />
        </Popover>
      </div>
    </Box>
  );
}
