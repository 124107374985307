import React, { useState } from "react";
import { PieChart, Pie, Sector, Cell, Tooltip, Label } from "recharts";
import { COLORS } from "screens/RuntimeSecurity/CWPP/constants";
// import { CustomTooltip } from "./Recharts/RechartPiechart";
import { kFormatter } from "helper/helpers";
import { ConstructionOutlined } from "@mui/icons-material";
import styles from "./widgets.module.scss";
import {
  ActiveTabColor,
  CustomPolicyCount,
  CustomTooltip,
  DiscoverPolicyCount,
  HardeningPolicyCount,
  statusColour,
  tabsList
} from "../../../screens/CnappDashboard/Component/helper";

import BasicColorTabs from "../../../screens/CnappDashboard/Component/Tab/tab";
import { useEffect } from "react";
export const DoubleRadiusPieChart = ({ graphData, onClickChart }) => {
  const [hovered1, setHovered1] = useState(undefined);
  const [activeTab, setActiveTab] = useState(0);
  const [activeData, setActiveData] = useState([]);
  const screenWidth = window?.screen?.width;
  const EmptyData = [
    {
      name: "EmptyData",
      value: 1,
      color: "#F2F4F7"
    }
  ];
  const filterCategoryData = (data, Category) => {
    let filterData = data?.map(key => {
      return {
        name: `${key === "InActive" ? "Inactive" : key}`,
        value: graphData[key][`${Category}`],
        color: statusColour(key)
      };
    });
    return filterData;
  };

  useEffect(() => {
    const data1 = Object.keys(graphData).filter(e => e !== "TotalPoliciesCount");
    if (activeTab === 0) {
      setActiveData(filterCategoryData(data1, "Discover"));
    } else if (activeTab === 1) {
      setActiveData(filterCategoryData(data1, "Custom"));
    } else if (activeTab === 2) {
      setActiveData(filterCategoryData(data1, "Hardening"));
    }
  }, [activeTab]);
  const onClick = e => {
    // console.log(e);
  };
  const handleTabChange = (event, newValue) => {
    event.stopPropagation();
    setActiveTab(newValue);
  };
  const isEmptyData = values => {
    let totalCount = values?.reduce((total, count) => {
      return total + count?.value;
    }, 0);
    return totalCount;
  };
  return (
    <div className={`$w-full grid ${screenWidth > 1366 ? `grid-cols-10` : `grid-cols-5`} gap-1 `}>
      <div className={`${screenWidth > 1366 ? `col-span-4` : `col-span-2`}`}>
        {isEmptyData(activeData) ? (
          <PieChart
            className={`${screenWidth < 1367 ? "mt-4" : null}`}
            width={screenWidth > 1366 ? 150 : 105}
            height={screenWidth > 1366 ? 150 : 105}
          >
            <Pie
              dataKey="value"
              cx={screenWidth > 1366 ? 70 : 50}
              cy={screenWidth > 1366 ? 70 : 50}
              data={activeData}
              innerRadius={screenWidth > 1366 ? 40 : 20}
              outerRadius={screenWidth > 1366 ? 70 : 50}
              minAngle={5}
              fill="#82ca9d"
              isAnimationActive={false}
              onMouseOver={(event, index) => {
                setHovered1(index);
              }}
              onMouseOut={() => {
                setHovered1(undefined);
              }}
              onClick={e => onClickChart(e)}
            >
              {activeData?.map((entry, index) => (
                <Cell
                  className="cursor-pointer focus:scale-105"
                  onClick={() => {}}
                  key={`cell-${index}`}
                  fill={entry?.color}
                  // style={{
                  //   filter: `${index === hovered1 && `drop-shadow(0px 0px 2px ${entry?.color}`}`,
                  //   transitionDelay: "2s",
                  //   transitionTimingFunction: "ease-in-out"
                  // }}
                  stroke={entry?.color}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        ) : (
          <PieChart
            className={`${screenWidth < 1367 ? "mt-4" : null}`}
            width={screenWidth > 1366 ? 150 : 105}
            height={screenWidth > 1366 ? 150 : 105}
          >
            <Pie
              dataKey="value"
              cx={screenWidth > 1366 ? 70 : 50}
              cy={screenWidth > 1366 ? 70 : 50}
              data={EmptyData}
              innerRadius={screenWidth > 1366 ? 45 : 25}
              outerRadius={screenWidth > 1366 ? 70 : 50}
              fill="#82ca9d"
              isAnimationActive={false}
            >
              {EmptyData?.map((entry, index) => (
                <Cell
                  className="cursor-pointer focus:scale-105"
                  key={`cell-${index}`}
                  fill={entry?.color}
                  style={{
                    filter: `${index === hovered1 && `drop-shadow(0px 0px 2px ${entry?.color}`}`
                  }}
                  stroke={entry?.color}
                />
              ))}
              <Label width={80} className={styles.lablelstyle} position="center">
                {`No Data`}
              </Label>
            </Pie>
          </PieChart>
        )}
      </div>
      <div className={` ${screenWidth > 1366 ? `col-span-6` : `col-span-3`} gap-[6px] `}>
        <BasicColorTabs
          tabs={tabsList(
            DiscoverPolicyCount(graphData),
            CustomPolicyCount(graphData),
            HardeningPolicyCount(graphData),
            screenWidth
          )}
          value={activeTab}
          handleChange={handleTabChange}
          screenWidth={screenWidth}
          activeTab={ActiveTabColor(activeTab)}
        />
      </div>
    </div>
  );
};
