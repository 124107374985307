import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clusters: [],
  namespaces: [],
  pod: [],
  infra_type: { label: "K8s", value: "GKE" },
  policy_type: [],
  policy_status: [],
  component: { label: "KubeArmor", value: "KubeArmor" }
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSelectedPolicyType(state, action) {
      state.policy_type = action.payload;
    },
    setSelectedInfraType(state, action) {
      state.infra_type = action.payload;
    },
    setSelectedClusters(state, action) {
      state.clusters = action.payload;
    },
    setSelectedNamespace(state, action) {
      state.namespaces = action.payload;
    },
    setSelectedPolicyStatus(state, action) {
      state.policy_status = action.payload;
    },
    setSelectedCategory(state, action) {
      state.category = action.payload;
    },
    setSelectedPod(state, action) {
      state.pod = action.payload;
    },
    setSelectedComponentType(state, action) {
      state.component = action.payload;
    }
  }
});

export const getSelectedClusters = state => state.filters.clusters || [];
export const getSelectedNamespaces = state => state.filters.namespaces || [];
export const getSelectedInfraType = state =>
  state.filters.infra_type || { label: "K8s", value: "GKE" };
export const getSelectedComponentType = state =>
  state.filters.component || { label: "KubeArmor", value: "KubeArmor" };
export const getSelectedPolicyType = state => state.filters.policy_type || {};
export const getSelectedPolicyStatus = state => state.filters.policy_status || [];
export const getSelectedCategory = state => state.filters.category || [];
export const getSelectedPod = state => state.filters.pod || [];
export const {
  setSelectedClusters,
  setSelectedNamespace,
  setSelectedInfraType,
  setSelectedPolicyType,
  setSelectedPolicyStatus,
  setSelectedCategory,
  setSelectedPod,
  setSelectedComponentType
} = filtersSlice.actions;
export default filtersSlice.reducer;
