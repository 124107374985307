import React from "react";
import {
  Button,
  ContentContainer,
  Icon,
  ActionButton,
  Textarea,
  Title
  // Tooltip
} from "components/simple";

import styles from "../styles.module.scss";

const Comments = ({
  isEditing,
  vulnerability,
  ticketsQuantity,
  commentsQuantity,
  onChange,
  onSubmit,
  onEditModeSwitch,
  onCancelEditMode,
  onShowComments
}) => {
  const submitHandler = e => {
    e.preventDefault();
    // eslint-disable-next-line prefer-destructuring
    const id = vulnerability.id;
    onSubmit(id);
    onEditModeSwitch();
  };
  if (isEditing) {
    return (
      <ContentContainer className={`${styles.commentsContent} ${styles.editMode}`}>
        <form onSubmit={submitHandler}>
          <div className={`${styles.headerSection} ${styles.commentsHeader}`}>
            <Title className={styles.title}>{`${vulnerability.name} Protocol Detection`}</Title>
            <ActionButton type="edit" onClick={() => {}} />
          </div>
          <Textarea
            value={vulnerability.description}
            onChange={val => onChange(val, "description")}
            label="Description"
            className={styles.textField}
            required
          />
          <Textarea
            value={vulnerability.solution}
            onChange={val => onChange(val, "solution")}
            label="Solution"
            className={styles.textField}
            required
          />
          <div className={styles.buttonsContainer}>
            <Button variant="outline" className={styles.button} onClick={onCancelEditMode}>
              Cancel
            </Button>
            <Button className={styles.button} type="submit">
              Save
            </Button>
          </div>
        </form>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer className={styles.commentsContent}>
      <div className={`${styles.headerSection} ${styles.commentsHeader}`}>
        <Title className={styles.title}>{vulnerability.name}</Title>
        <ActionButton type="edit" onClick={onEditModeSwitch} />
      </div>

      <div>
        <h3 className={styles.titleSection}>Description</h3>
        <p className={`${styles.text} ${styles.scroll}`}>{vulnerability.description}</p>
      </div>

      <div>
        <h3 className={styles.titleSection}>Solution</h3>
        <p className={`${styles.text} ${styles.scroll}`}>{vulnerability.solution}</p>
      </div>

      <div>
        <h3 className={styles.titleSection}>Ticket Comments</h3>
        <div className={styles.commentsSection}>
          <Icon.Message />
          <p className={styles.quantity}>{commentsQuantity}</p>
          <p className={styles.text}>comments available</p>
        </div>
        <Button
          onClick={onShowComments}
          disabled={!ticketsQuantity}
          className={`${styles.button} ${styles.showCommentsBtn}`}
        >
          Show comments
        </Button>
      </div>
    </ContentContainer>
  );
};

export default Comments;
