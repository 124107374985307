import moment from "moment";
export const PIE_CHART_COLORS = ["#43CE9C", "#FF6F6F", "#ECB435"];

export const SEVERITY_COLORS = {
  Critical: "#BB0504",
  High: "#FF435F",
  Medium: "#FF8E50",
  Low: "#F6A192",
  Negligible: "#FEC051",
  "No Issue": "#DADADA"
};

export const IMAGE_STATUS_LIST = [
  { label: "Pending", value: "Not-Started" },
  { label: "In Progress", value: "In-Progress" },
  { label: "Finished", value: "Success" },
  { label: "Failed", value: "Failed" }
];
export const DATE_FROM = moment()
  .subtract(40, "years")
  .format("YYYY-MM-DDTHH:mm:ss.SSS");
export const DATE_TO = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
