/* eslint-disable no-shadow */
import { routes } from "router";
import { useMutation } from "react-query";
import {
  addMFA,
  forgotPassword,
  signUp,
  login,
  resetPassword,
  verifyMFA,
  verifyEmail,
  validateToken,
  changePassword,
  refreshToken
} from "./service";
import { navigate } from "helper/history";
import { useDispatch } from "react-redux";
import { addNotification } from "../notifications/actions";
import { useLocation } from "react-router-dom";

export const useLoginMutation = () => {
  const dispatch = useDispatch();

  return useMutation(login, {
    onSuccess: response => {
      const token = response?.token;
      const mfaUid = response?.mfa_uuid;

      if (token) {
        // MFA is not added, so navigate to the setup MFA screen
        localStorage.setItem("accessToken", token);
        navigate(routes.addMFA);
      } else if (mfaUid) {
        // MFA is already added, so navigate to verify MFA screen
        navigate(`${routes.verifyMFA}/${mfaUid}`);
      }
    },
    onError: async response => {
      const data = await response.json();
      try {
        if (response.status === 500) {
          dispatch(addNotification({ msg: "There was an unexpected error.", type: "error" }));
        } else {
          dispatch(addNotification({ msg: data, type: "error" }));
        }
      } catch {
        dispatch(addNotification({ msg: "There was an unexpected error.", type: "error" }));
      }
    }
  });
};

export const useSignUpMutation = () => {
  const dispatch = useDispatch();

  return useMutation(signUp, {
    onSuccess: () => {
      navigate(routes.signUpSuccess, { success: true });
    },
    onError: async errorResponse => {
      const errorData = await errorResponse.json();
      const errors = Object.keys(errorData)?.map(err => errorData[err]);
      if (errors?.length) {
        dispatch(addNotification({ msg: errors[0], type: "error" }));
      }
    }
  });
};

export const useAddMFAMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => addMFA(payload), {
    onSuccess: data => {
      dispatch(
        addNotification({ msg: "You have successfully assigned virtual MFA", type: "success" })
      );
      localStorage.removeItem("accessToken");
      navigate(routes.home);
    },
    onError: data => {
      dispatch(addNotification({ msg: "There was an error while adding MFA", type: "error" }));
    }
  });
};

export const useVerifyMFAMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => verifyMFA(payload), {
    onSuccess: response => {
      const token = response?.token;
      if (token) {
        localStorage.setItem("token", token);
        navigate(routes.home);
      }
    },
    onError: async response => {
      const error = await response.json();
      dispatch(
        addNotification({ msg: error ?? "There was an error while verifying MFA", type: "error" })
      );
    }
  });
};

export const useForgotPasswordMutation = () => {
  const dispatch = useDispatch();

  return useMutation(forgotPassword, {
    onSuccess: response => {
      dispatch(
        addNotification({
          msg: response,
          type: "success"
        })
      );
    },
    onError: async response => {
      const error = await response.json();
      dispatch(
        addNotification({
          msg: error,
          type: "error"
        })
      );
    }
  });
};

export const useResetPasswordMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => resetPassword(payload), {
    onSuccess: () => {
      navigate(routes.resetPasswordSuccess, { success: true });
    },
    onError: async data => {
      const response = await data.json();

      if (response) {
        dispatch(
          addNotification({
            msg: response,
            type: "error"
          })
        );
      } else {
        dispatch(
          addNotification({
            msg: "There was an error while resetting the password. Please try again later.",
            type: "error"
          })
        );
      }
    }
  });
};

export const useChangePasswordMutation = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  return useMutation(payload => resetPassword(payload), {
    onSuccess: () => {
      navigate(location.pathname, {});
      navigate(routes.addMFA);
    },
    onError: data => {
      dispatch(
        addNotification({
          msg: "There was an error while changing the password. Please try again later.",
          type: "error"
        })
      );
    }
  });
};

export const useChangeUserPasswordMutation = () => {
  const dispatch = useDispatch();

  return useMutation(changePassword, {
    onSuccess: response => {
      dispatch(
        addNotification({
          msg: response,
          type: "success"
        })
      );
    },
    onError: error => {
      dispatch(
        addNotification({
          msg: error,
          type: "error"
        })
      );
    }
  });
};

export const useVerifyEmail = () => {
  const dispatch = useDispatch();

  return useMutation(verifyEmail, {
    onSuccess: data => {
      dispatch(addNotification({ msg: "Verified", type: "success" }));
      navigate(routes.loginScreen);
    },
    onError: data => {
      dispatch(addNotification({ msg: "Unable to verify", type: "error" }));
      navigate(routes.loginScreen);
    }
  });
};

export const useValidateToken = () => {
  return useMutation(validateToken, {
    onSuccess: data => console.log({ data }),
    onError: data => console.log({ data })
  });
};

export const useRefreshToken = () => {
  return useMutation(refreshToken, {
    onSuccess: data => {
      if (data?.token) {
        localStorage.setItem("token", data.token);
      }
    }
  });
};
