import { cloneDeep, isEmpty } from "lodash";
import { transformPolicyJSON } from "./helpers";
import {
  DEFAULT_POLICY_TEMPLATE,
  DEFAULT_POLICY_YAML,
  EGRESS,
  INGRESS,
  IP_BLOCK,
  KUBERNETES_NETWORK_POLICY,
  NAMESPACE_SELECTOR,
  POD_SELECTOR
} from "./constants";

export const updatePolicyJSONReducer = (state, action) => {
  const policyJSON = action.payload;
  state.policyJSON = policyJSON;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policyJSON);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

export const resetStateReducer = state => {
  // Hide all the cards
  state.showRulesPaletteCard = false;
  state.ipBlockRule.showRuleCard = { ingress: false, egress: false };
  state.podSelectorRule.showRuleCard = { ingress: false, egress: false };
  state.namespaceSelectorRule.showRuleCard = { ingress: false, egress: false };

  // Empty all rule's view mode data
  state.policyJSON = DEFAULT_POLICY_TEMPLATE;
  state.policyYAML = DEFAULT_POLICY_YAML;
  state.ipBlockRule.ruleData = { ingress: [], egress: [] };
  state.podSelectorRule.ruleData = { ingress: [], egress: [] };
  state.namespaceSelectorRule.ruleData = { ingress: [], egress: [] };

  // Set all the card's state to have adding/editing mode
  state.isEditingPolicyMetadata = true;
  state.ipBlockRule.isEditingRule = { ingress: true, egress: true };
  state.podSelectorRule.isEditingRule = { ingress: true, egress: true };
  state.namespaceSelectorRule.isEditingRule = { ingress: true, egress: true };

  // Reset except/ports add buttons state
  state.ipBlockRule.showAddExcept = { ingress: false, egress: false };
  state.ipBlockRule.showAddPort = { ingress: false, egress: false };
  state.podSelectorRule.showAddPort = { ingress: false, egress: false };
  state.namespaceSelectorRule.showAddPort = { ingress: false, egress: false };

  // Clear up all misc. state variables
  state.activeCard = null;
  state.currentPolicy = {};
  state.unknownPolicyValues = {};
  state.selectedCluster = null;
  state.selectedNamespace = null;
  state.isValidYAML = null;
  state.isCreatingPolicy = false;
  state.isUpdatingPolicy = false;
  state.unsupportedRules = [];
};

export const handlePolicyMetadataFormSubmitReducer = (state, action) => {
  const formValues = action.payload;
  const policy = cloneDeep(state?.policyJSON);

  policy.kind = policy?.kind || "";
  policy.metadata = policy?.metadata || {};

  policy.kind = KUBERNETES_NETWORK_POLICY;
  policy.metadata.name = formValues?.name;
  policy.metadata.namespace = formValues?.namespace?.value;

  // Add podSelector labels
  if (formValues?.selectorLabel?.length) {
    policy.spec = policy?.spec || {};
    policy.spec.podSelector = policy?.spec?.podSelector || {};

    const matchLabels = {};
    formValues.selectorLabel.forEach(label => {
      matchLabels[label.name] = label.value;
    });

    policy.spec.podSelector.matchLabels = matchLabels;
  } else {
    if (policy?.spec?.podSelector?.matchLabels) {
      delete policy.spec.podSelector.matchLabels;
      delete policy.spec.podSelector;
    }
  }

  if (isEmpty(policy.spec)) {
    delete policy.spec;
  }

  state.policyJSON = policy;
  state.isEditingPolicyMetadata = false;
  state.showRulesPaletteCard = true;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

export const handleIPBlockRuleFormSubmitReducer = (state, action) => {
  const { formValues, trafficType } = action.payload;
  const policy = cloneDeep(state?.policyJSON);

  const rule = {};
  const ipBlock = {};
  const ports = [];

  if (!policy?.spec) {
    policy.spec = {};
  }

  // If the policy.spec.policyTypes key doesn't exist, create it as an empty array.
  if (!policy?.spec?.policyTypes) {
    policy.spec.policyTypes = [];
  }

  if (!policy?.spec?.[trafficType]?.length) {
    policy.spec[trafficType] = [];
  }

  if (formValues?.cidr) {
    ipBlock.cidr = formValues?.cidr;
  }
  if (formValues?.except?.length) {
    ipBlock.except = formValues?.except?.map?.(cidr => cidr?.value);
  }
  if (!isEmpty(ipBlock)) {
    rule[trafficType === INGRESS ? "from" : "to"] = [{ ipBlock }];
  }

  if (formValues?.ports?.length && formValues?.protocol?.value) {
    formValues?.ports?.forEach(port => {
      ports.push({ protocol: formValues?.protocol?.value, port: Number(port?.value) });
    });
    rule.ports = ports;
  }

  if (!isEmpty(rule)) {
    policy.spec[trafficType].push(rule);

    // Check if the trafficType is already present in the policy.spec.policyTypes array.
    const trafficTypeExists = policy?.spec?.policyTypes?.some(
      policyType => policyType.toLowerCase() === trafficType
    );

    // If the trafficType is not present, add it to the policy.spec.policyTypes array.
    if (!trafficTypeExists) {
      policy.spec.policyTypes.push(trafficType.charAt(0).toUpperCase() + trafficType.slice(1));
    }

    state.ipBlockRule.ruleData[trafficType].push({ ipBlock, ports });
    state.ipBlockRule.isEditingRule[trafficType] = false;
    state.ipBlockRule.showAddExcept[trafficType] = false;
    state.ipBlockRule.showAddPort[trafficType] = false;
  }

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyJSON = policy;
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

export const handlePodSelectorRuleFormSubmitReducer = (state, action) => {
  const { formValues, trafficType } = action.payload;
  const policy = cloneDeep(state?.policyJSON);

  const rule = {};
  const podSelector = {};
  const ports = [];

  if (!policy?.spec) {
    policy.spec = {};
  }

  // If the policy.spec.policyTypes key doesn't exist, create it as an empty array.
  if (!policy?.spec?.policyTypes) {
    policy.spec.policyTypes = [];
  }

  if (!policy?.spec?.[trafficType]?.length) {
    policy.spec[trafficType] = [];
  }

  if (formValues?.selectorLabel?.length && !formValues?.allowAll) {
    const matchLabels = {};
    formValues.selectorLabel.forEach(label => {
      matchLabels[label.name] = label.value;
    });

    podSelector.matchLabels = matchLabels;
  }

  rule[trafficType === INGRESS ? "from" : "to"] = [{ podSelector }];

  if (formValues?.ports?.length && formValues?.protocol?.value) {
    formValues?.ports?.forEach(port => {
      ports.push({ protocol: formValues?.protocol?.value, port: Number(port?.value) });
    });
    rule.ports = ports;
  }

  if (!isEmpty(rule)) {
    policy.spec[trafficType].push(rule);

    // Check if the trafficType is already present in the policy.spec.policyTypes array.
    const trafficTypeExists = policy?.spec?.policyTypes?.some(
      policyType => policyType.toLowerCase() === trafficType
    );

    // If the trafficType is not present, add it to the policy.spec.policyTypes array.
    if (!trafficTypeExists) {
      policy.spec.policyTypes.push(trafficType.charAt(0).toUpperCase() + trafficType.slice(1));
    }

    state.podSelectorRule.ruleData[trafficType].push({ podSelector, ports });
    state.podSelectorRule.isEditingRule[trafficType] = false;
    state.podSelectorRule.showAddPort[trafficType] = false;
  }

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyJSON = policy;
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

export const handleNamespaceSelectorRuleFormSubmitReducer = (state, action) => {
  const { formValues, trafficType } = action.payload;
  const policy = cloneDeep(state?.policyJSON);

  const rule = {};
  const namespaceSelector = {};
  const ports = [];

  if (!policy?.spec) {
    policy.spec = {};
  }

  // If the policy.spec.policyTypes key doesn't exist, create it as an empty array.
  if (!policy?.spec?.policyTypes) {
    policy.spec.policyTypes = [];
  }

  if (!policy?.spec?.[trafficType]?.length) {
    policy.spec[trafficType] = [];
  }

  if (formValues?.selectorLabel?.length && !formValues?.allowAll) {
    const matchLabels = {};
    formValues.selectorLabel.forEach(label => {
      matchLabels[label.name] = label.value;
    });

    namespaceSelector.matchLabels = matchLabels;
  }

  rule[trafficType === INGRESS ? "from" : "to"] = [{ namespaceSelector }];

  if (formValues?.ports?.length && formValues?.protocol?.value) {
    formValues?.ports?.forEach(port => {
      ports.push({ protocol: formValues?.protocol?.value, port: Number(port?.value) });
    });
    rule.ports = ports;
  }

  if (!isEmpty(rule)) {
    policy.spec[trafficType].push(rule);

    // Check if the trafficType is already present in the policy.spec.policyTypes array.
    const trafficTypeExists = policy?.spec?.policyTypes?.some(
      policyType => policyType.toLowerCase() === trafficType
    );

    // If the trafficType is not present, add it to the policy.spec.policyTypes array.
    if (!trafficTypeExists) {
      policy.spec.policyTypes.push(trafficType.charAt(0).toUpperCase() + trafficType.slice(1));
    }

    state.namespaceSelectorRule.ruleData[trafficType].push({ namespaceSelector, ports });
    state.namespaceSelectorRule.isEditingRule[trafficType] = false;
    state.namespaceSelectorRule.showAddPort[trafficType] = false;
  }

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyJSON = policy;
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

export const removeRuleFromPolicyJSONReducer = (state, action) => {
  const { trafficType, ruleType } = action.payload;
  const policy = cloneDeep(state.policyJSON);

  policy.spec[trafficType] = policy.spec[trafficType]
    .map(rule => {
      rule.from = rule.from.filter(fromRule => !fromRule.hasOwnProperty(ruleType));
      return rule;
    })
    .filter(rule => rule.from.length > 0);

  if (!policy?.spec?.[trafficType]?.length) {
    delete policy.spec[trafficType];
    policy.spec.policyTypes = policy?.spec?.policyTypes?.filter(
      policyType => policyType.toLowerCase() !== trafficType
    );

    // If there are no rules for the other traffic type, remove the "policyTypes" key entirely.
    const otherTrafficType = trafficType === INGRESS ? EGRESS : INGRESS;
    if (!policy?.spec?.[otherTrafficType]?.length) {
      delete policy.spec.policyTypes;
    }
  }

  if (isEmpty(policy?.spec)) {
    delete policy.spec;
  }

  state.policyJSON = policy;

  if (ruleType === IP_BLOCK) {
    state.ipBlockRule.ruleData[trafficType] = [];
    state.ipBlockRule.showAddExcept[trafficType] = false;
    state.ipBlockRule.showAddPort[trafficType] = false;
    state.ipBlockRule.showRuleCard[trafficType] = false;
  } else if (ruleType === POD_SELECTOR) {
    state.podSelectorRule.ruleData[trafficType] = [];
    state.podSelectorRule.showAddPort[trafficType] = false;
    state.podSelectorRule.showRuleCard[trafficType] = false;
  } else if (ruleType === NAMESPACE_SELECTOR) {
    state.namespaceSelectorRule.ruleData[trafficType] = [];
    state.namespaceSelectorRule.showAddPort[trafficType] = false;
    state.namespaceSelectorRule.showRuleCard[trafficType] = false;
  }

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

export const deleteIndividualRuleReducer = (state, action) => {
  const { trafficType, ruleType, occurrenceIndex } = action.payload;
  const policy = cloneDeep(state.policyJSON);

  // Counter for the number of occurrences of the specified rule type.
  let occurrenceCount = 0;
  // The key used in the rule, which differs for ingress ("from") and egress ("to") traffic types.
  const ruleKey = trafficType === INGRESS ? "from" : "to";

  // Iterate over the rules in the specified traffic type.
  for (let ruleIndex = 0; ruleIndex < policy.spec[trafficType].length; ruleIndex++) {
    const rule = policy.spec[trafficType][ruleIndex];

    // Check if the rule has the specified rule key ("from" or "to").
    if (rule[ruleKey]) {
      // Find the rule object with the specified rule type.
      const matchedRule = rule[ruleKey].find(ruleObj => ruleObj[ruleType]);

      // If the rule object is found.
      if (matchedRule) {
        // If the occurrence count matches the specified occurrence index, perform the deletion.
        if (occurrenceCount === occurrenceIndex) {
          // Remove the rule object with the specified rule type from the rule key.
          rule[ruleKey] = rule[ruleKey].filter(ruleObj => !ruleObj[ruleType]);

          // If the rule key becomes empty after deletion, remove the entire rule from the traffic type.
          if (!rule?.[ruleKey]?.length) {
            policy.spec[trafficType].splice(ruleIndex, 1);
          }

          // Remove the rule from the state object.
          const stateKey = `${ruleType}Rule`;
          state[stateKey].ruleData[trafficType].splice(occurrenceIndex, 1);
          if (!state[stateKey].ruleData[trafficType].length) {
            state[stateKey].showRuleCard[trafficType] = false;
          }
        }

        // Increment the occurrence count.
        occurrenceCount++;
      }
    }
  }

  if (!policy?.spec?.[trafficType]?.length) {
    delete policy.spec[trafficType];
    policy.spec.policyTypes = policy?.spec?.policyTypes?.filter(
      policyType => policyType.toLowerCase() !== trafficType
    );

    // If there are no rules for the other traffic type, remove the "policyTypes" key entirely.
    const otherTrafficType = trafficType === INGRESS ? EGRESS : INGRESS;
    if (!policy?.spec?.[otherTrafficType]?.length) {
      delete policy.spec.policyTypes;
    }
  }

  if (isEmpty(policy?.spec)) {
    delete policy.spec;
  }

  state.policyJSON = policy;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

export const updateRulesComponentStateReducer = (state, action) => {
  const { ipBlockRuleData, podSelectorRuleData, namespaceSelectorRuleData } = action.payload;

  state.ipBlockRule.ruleData = ipBlockRuleData;
  state.ipBlockRule.showRuleCard = {
    [INGRESS]: Boolean(ipBlockRuleData?.[INGRESS]?.length),
    [EGRESS]: Boolean(ipBlockRuleData?.[EGRESS]?.length)
  };
  state.ipBlockRule.isEditingRule = {
    [INGRESS]: false,
    [EGRESS]: false
  };

  state.podSelectorRule.ruleData = podSelectorRuleData;
  state.podSelectorRule.showRuleCard = {
    [INGRESS]: Boolean(podSelectorRuleData?.[INGRESS]?.length),
    [EGRESS]: Boolean(podSelectorRuleData?.[EGRESS]?.length)
  };
  state.podSelectorRule.isEditingRule = {
    [INGRESS]: false,
    [EGRESS]: false
  };

  state.namespaceSelectorRule.ruleData = namespaceSelectorRuleData;
  state.namespaceSelectorRule.showRuleCard = {
    [INGRESS]: Boolean(namespaceSelectorRuleData?.[INGRESS]?.length),
    [EGRESS]: Boolean(namespaceSelectorRuleData?.[EGRESS]?.length)
  };
  state.namespaceSelectorRule.isEditingRule = {
    [INGRESS]: false,
    [EGRESS]: false
  };
};
