import React from "react";
import styles from "./styles.module.scss";

const PrefixLabelInput = ({ placeholder, leftAddsOn, className }) => {
  return (
    <div className="relative text-gray-700 text-sm">
      <input className={`w-full h-full pl-20 py-2 pr-3 ${styles.input} ${className}`} placeholder={placeholder} type="text" />
      <span className="absolute inset-y-0 border border-gray-400 px-4 h-full rounded-l flex items-center text-gray-400 bg-gray-100" >{leftAddsOn}</span>
    </div>
  );
};

export default PrefixLabelInput;
