import React from "react";
import PropTypes from "prop-types";

import Select, { components } from "react-select";
import { FormLabel } from "@mui/material";

import { Icon, Checkbox } from "components/simple";
import QuestionTooltip from "../QuestionTooltip";

import styles from "./styles.module.scss";
import { stylesSelect } from "./styles";
import { stylesTableSelect } from "./stylesTableSelect";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon.DropDownArrow />
    </components.DropdownIndicator>
  );
};

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <Checkbox value={props.isSelected} onChange={() => null} label={props.label} />
      </components.Option>
    </div>
  );
};

const MultiValue = props => {
  if (props.data.value === "*") return "";
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

export const SelectInput = ({
  containerClass,
  error,
  style,
  onChange,
  name,
  value,
  label,
  isDisabled,
  required,
  placeholder,
  options,
  isMulti,
  tooltip,
  allowSelectAll,
  allOption,
  tableSelect,
  colourStyles,
  refreshCustom,
  ...rest
}) => {
  const changeHandler = (selectedOptions, event) => {
    if (!allowSelectAll) {
      onChange(selectedOptions, name);
      return;
    }

    if (event.action === "select-option" && event.option.value === "*") {
      onChange(options, name);
    } else if (event.action === "select-option") {
      onChange(selectedOptions, name);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      onChange([], name);
    } else if (event.action === "deselect-option" || event.action === "remove-value") {
      onChange(
        selectedOptions.filter(o => o.value !== "*"),
        name
      );
    } else if (selectedOptions.length === options.length - 1) {
      onChange(options, name);
    } else {
      onChange(selectedOptions, name);
    }
  };

  const optionsValue =
    allowSelectAll && value?.length === options?.length ? [allOption, ...value] : value;
  const customStyles = tableSelect ? stylesTableSelect : refreshCustom ? colourStyles : stylesSelect;

  return (
    <div
      className={`${containerClass} ${styles.container} ${required ? styles.required : ""}`}
      onClick={e => e.stopPropagation()}
    >
      {label && (
        <div className={styles.labelCont}>
          <FormLabel className={styles.label}>{label}</FormLabel>
          {tooltip && <QuestionTooltip text={tooltip} />}
        </div>
      )}
      <Select
        isDisabled={isDisabled}
        components={{ DropdownIndicator, ...(isMulti ? { Option, MultiValue } : {}) }}
        name={name}
        defaultValue={value ?? ""}
        value={optionsValue ?? ""}
        options={allowSelectAll ? [allOption, ...options] : options}
        onChange={changeHandler}
        styles={{ ...customStyles, ...style }}
        isMulti={isMulti}
        placeholder={!placeholder ? "" : placeholder}
        required={required}
        {...rest}
      />
      <input
        tabIndex={-1}
        autoComplete="off"
        style={{ opacity: 0, height: 0, position: "absolute" }}
        value={value ?? ""}
        onChange={_ => onChange(_, name)}
        required={required}
      />

      {error && <div className={styles.error}>{!error ? "" : error}</div>}
    </div>
  );
};

SelectInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.array
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  containerClass: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),
  tooltip: PropTypes.string,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

SelectInput.defaultProps = {
  value: "",
  name: "",
  placeholder: "",
  label: "",
  error: "",
  containerClass: "",
  required: false,
  isDisabled: false,
  onChange: () => {},
  options: [],
  tooltip: "",
  allowSelectAll: false,
  allOption: { label: "Select all", value: "*" }
};
