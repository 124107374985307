import React from "react";

import { Modal, Title, Input, Button } from "components/simple";

import styles from "./styles.module.scss";

function AddTagModal({ onChange, value, onCloseModal, isCreateTagLoading, onSubmit, isModalOpen }) {
  return (
    <Modal isOpen={isModalOpen} toggle={onCloseModal}>
      <Title className={styles.title}>Create tag</Title>
      <Input
        value={value}
        placeholder="Name"
        onChange={e => onChange(e.target.value)}
        label="Name"
        className={styles.textarea}
      />
      <div className={styles.btnsRow}>
        <Button variant="outline" onClick={onCloseModal} className={styles.btn}>
          Cancel
        </Button>
        <Button onClick={onSubmit} className={styles.btn} isLoading={isCreateTagLoading}>
          Save
        </Button>
      </div>
    </Modal>
  );
}

export default AddTagModal;
