import { useCallback, useState } from "react";

export const useToggle = (isListViewByDefault = false) => {
  const [isListView, setListView] = useState(isListViewByDefault);
  const setAsListView = useCallback(() => setListView(true));
  const setAsGraphView = useCallback(() => setListView(false));

  return {
    isListView,

    actions: {
      setAsListView,
      setAsGraphView
    }
  };
};
