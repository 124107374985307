import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#282828",
    boxShadow: theme.shadows[1],
    borderRadius: "4px",
    fontSize: 12,
    maxWidth: "200px",
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #999"
      }
    }
  }
}));

export default LightTooltip;
