import React from "react";
import PropTypes from "prop-types";

export default function AddTag(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9446 6.30566C17.9446 5.06302 16.9372 4.05566 15.6946 4.05566C14.4519 4.05566 13.4446 5.06302 13.4446 6.30566C13.4446 7.5483 14.4519 8.55566 15.6946 8.55566C16.9372 8.55566 17.9446 7.5483 17.9446 6.30566ZM15.6946 5.55566C16.1088 5.55566 16.4446 5.89145 16.4446 6.30566C16.4446 6.71988 16.1088 7.05566 15.6946 7.05566C15.2804 7.05566 14.9446 6.71988 14.9446 6.30566C14.9446 5.89145 15.2804 5.55566 15.6946 5.55566Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8775 0.409623C19.4721 0.429687 21.5706 2.52812 21.5906 5.12275L21.6222 9.20294C21.632 10.4754 21.1309 11.6986 20.2311 12.5984L12.2206 20.6089C10.3656 22.4639 7.35803 22.4639 5.50304 20.6089L1.3913 16.4972C-0.463692 14.6422 -0.463692 11.6347 1.3913 9.77969L9.40181 1.76917C10.3016 0.869372 11.5248 0.368232 12.7973 0.378072L16.8775 0.409623ZM20.0907 5.13435C20.0769 3.35907 18.6412 1.92331 16.8659 1.90958L12.7857 1.87803C11.9151 1.87129 11.0781 2.21418 10.4625 2.82983L2.45196 10.8403C1.18275 12.1096 1.18275 14.1673 2.45196 15.4365L6.5637 19.5483C7.8329 20.8175 9.89069 20.8175 11.1599 19.5483L19.1704 11.5378C19.7861 10.9221 20.1289 10.0852 20.1222 9.21454L20.0907 5.13435Z"
        fill={props.color}
      />
    </svg>
  );
}

AddTag.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

AddTag.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 22,
  height: 22
};
