import React, { useState, useEffect } from "react";

import { Modal, Input, Title, Button } from "components/simple";
import Loader from "components/simple/Loader";
import { routes } from "router";
import { useDispatch } from "react-redux";
import { getBaselineControl, getBaselineConfigFilters } from "store/entities/baseline/actions";
import { getOneDataList } from "store/entities/datalist/service";

import DetailsModal from "screens/Compliance/Baselines/BaselineConfiguration/Tabs/Data/DetailsModal";

import styles from "./styles.module.scss";

const DetailsModalComponent = ({ isOpen, onCloseModal, data, history }) => {
  const [state, setData] = useState({});
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);

  const id = data?.id;
  const type = data?.type;

  const getRequest = () => {
    setLoading(true);
    if (type === "control") {
      dispatch(getBaselineControl({ baselineId: id, controlId: data?.data?.id }));
      dispatch(getBaselineConfigFilters());
    }
    if (type === "data-list") {
      getOneDataList(id)
        .then(res => {
          setData(res);
          setLoading(false);
        })
        .catch(() => {
          setLoading(true);
        });
    }
  };

  useEffect(() => {
    if (isOpen && id) {
      getRequest();
    }
    return () => setData(null);
  }, [isOpen, id]);

  const goToPage = () => {
    if (type === "control") {
      history.push(`${routes.baselineConfiguration}/${id}?search=${data?.data?.id}`);
    }
    if (type === "data-list") {
      history.push(`${routes.dataStructureDatalist}?search=${id}`);
    }
  };

  if (type === "control") {
    const controlIds = [data?.data?.id];
    return (
      <DetailsModal
        isOpen={Boolean(isOpen)}
        onCloseModal={onCloseModal}
        isSingleMode
        setSingleMode={() => null}
        baselineId={data?.id}
        controlIds={controlIds}
        data={{ row: data?.data }}
        goToPage={goToPage}
      />
    );
  }

  const createInputs = obj => {
    if (obj) {
      const { display_fields, ...rest } = obj;
      return Object.entries(rest)
        .filter(([key]) => display_fields?.[key])
        .map(([key, val]) => {
          return (
            <Input
              className={styles.mb_20}
              key={key}
              label={display_fields[key]}
              value={val ?? ""}
              readOnly
            />
          );
        });
    }
    return [];
  };

  return (
    <Modal isOpen={isOpen} toggle={onCloseModal} width={850} sx={{ paddingRight: "20px" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <Title className={styles.title}>Details</Title>
            <Button onClick={goToPage}>Go to page</Button>
          </div>

          {createInputs(state)}
        </div>
      )}
    </Modal>
  );
};

export default DetailsModalComponent;
