import { getAccuknoxWorkspace } from "api/api";

import { customPost, postFormData } from "api/base";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedClusters,
  getSelectedInfraType,
  getSelectedNamespaces,
  getSelectedComponentType
} from "../filters/slice";
import { addNotification } from "../notifications/actions";
import { NETWORK, SYSTEM } from "screens/MonitorLogs/Constants";
import { clean, getDifferenceTime, getTime } from "screens/MonitorLogs/helper";
import moment from "moment";

export const useGetLogsData = (
  onSuccess = () => {},
  namespace_name,
  period,
  pageId,
  perPage,
  queryString,
  clusterID,
  isEditing
) => {
  const dispatch = useDispatch();
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedInfraType = useSelector(getSelectedInfraType);
  const selectedComponentType = useSelector(getSelectedComponentType);
  // const { logDslTag } = usePrimaryLayoutTitle();
  const workspaceVal = getAccuknoxWorkspace();
  return useQuery(
    [
      "getLogsData",
      selectedNamespaces,
      period,
      pageId,
      perPage,
      queryString,
      selectedClusters,
      workspaceVal,
      selectedComponentType,
      selectedInfraType,
      isEditing
    ],
    async () => {
      if (selectedComponentType?.label === NETWORK) {
        const now = new Date();
        let from;
        let to;
        if (typeof period === "string") {
          from = getDifferenceTime(period, now).getTime();
          to = now.getTime();
        }
        if (typeof period === "object") {
          let modifiedPeriod = { ...period };
          if (moment(period.from).diff(period.to, "seconds") < 30) {
            modifiedPeriod.from = moment(period.from).startOf("day");
            modifiedPeriod.to = moment(period.to).endOf("day");
          }
          from = getTime(modifiedPeriod.from);
          to = getTime(modifiedPeriod.to);
        }
        const payload = {
          Namespace: namespace_name,
          FromTime: Math.floor(from / 1000),
          ToTime: Math.floor(to / 1000),
          PageId: pageId,
          PageSize: perPage,
          Filters: queryString,
          ClusterID: clusterID,
          WorkspaceID: workspaceVal
        };
        const response = await customPost("/datapipeline/v3/alerts/cilium", payload);
        const res = await response.json();
        return res;
      }
      if (selectedComponentType?.label === SYSTEM) {
        const now = new Date();
        let from;
        let to;
        if (typeof period === "string") {
          from = getDifferenceTime(period, now).getTime();
          to = now.getTime();
        }
        if (typeof period === "object") {
          let modifiedPeriod = { ...period };
          if (moment(period.from).diff(period.to, "seconds") < 30) {
            modifiedPeriod.from = moment(period.from).startOf("day");
            modifiedPeriod.to = moment(period.to).endOf("day");
          }
          from = getTime(modifiedPeriod.from);
          to = getTime(modifiedPeriod.to);
        }
        const payload = {
          Namespace: namespace_name,
          FromTime: Math.floor(from / 1000),
          ToTime: Math.floor(to / 1000),
          PageId: pageId,
          PageSize: perPage,
          Filters: queryString,
          ClusterID: clusterID,
          WorkspaceID: workspaceVal
        };
        const response = await customPost("/datapipeline/v3/alerts/kubearmor", payload);
        const res = await response.json();
        return res;
      }
    },
    {
      select: data => {
        const arrData = data?.response?.map((item, index) => ({ ...item, id: index }));
        return arrData;
      },
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Something went wrong with request", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};

export const fetchClustersCounts = (
  onSuccess = () => {},
  range,
  ClusterID = [],
  Namespace = [],
  PodName = []
) => {
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).unix();
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const convertCurrentTime = moment(currentTimeMoment).unix();
  let payload;
  const selectedCluster = ClusterID?.map(cluster => cluster?.value.toString());
  const selectedNamespace = Namespace?.map(namespace => namespace?.label.toString());
  // const selecPod = PodName?.map(pod => pod?.label.toString());
  const workspaceId = getAccuknoxWorkspace();

  payload = {
    WorkspaceID: workspaceId.toString(),
    ClusterID: selectedCluster?.length ? selectedCluster : [],
    Namespace: selectedNamespace?.length ? selectedNamespace : [],
    // PodName: selecPod?.length ? selecPod : [],
    FromTime: getFromTime ? getFromTime : 0,
    ToTime: convertCurrentTime ? convertCurrentTime : 0
  };
  // }
  return useQuery(
    ["fetchClustersCounts", workspaceId, ClusterID, Namespace, PodName, range],
    async () => {
      const response = await customPost("/dashboards/v1/telemetry/system/clusters/count", payload);
      return await response.json();
    },
    {
      select: data => {
        let res = data?.response?.Alerts?.Policies;
        let transformedData = Object.entries(res).map(([Timestamp, obj]) => {
          const total = Object.entries(obj).reduce((acc, [, value]) => acc + value, 0);
          return { Timestamp, total };
        });
        return transformedData;
      },
      onSuccess,
      onError: () => () => {
        dispatch(addNotification({ msg: "Something went wrong with request", type: "error" }));
      },
      refetchOnWindowFocus: false
    }
  );
};

export const useGetAllFilters = (onSuccess = () => {}, filterType, filters) => {
  const dispatch = useDispatch();
  const selectedComponentType = useSelector(getSelectedComponentType);
  const workspaceVal = getAccuknoxWorkspace();
  return useQuery(
    ["getAllFilters", workspaceVal, filterType, selectedComponentType],
    async () => {
      if (filterType === "PREDEFINED") {
        const payload = {
          PageID: 1,
          PageSize: 10,
          Filters: "KubeArmor"
        };

        const response = await customPost("/v3/integrations/getfilters", payload);
        const res = await response.json();
        return res;
      }
      if (filterType === "SAVED") {
        const payload = {
          PageID: 1,
          PageSize: 10,
          Filters: "KubeArmor"
        };

        const response = await customPost("/v3/integrations/getfilters", payload);
        const res = await response.json();
        return res;
      }
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Something went wrong with request", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};

export const useGetLogsCount = (
  onSuccess = () => {},
  clusterID,
  queryString,
  namespace_name,
  period,
  isEditing
) => {
  const dispatch = useDispatch();
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedComponentType = useSelector(getSelectedComponentType);
  const workspaceVal = getAccuknoxWorkspace();
  return useQuery(
    [
      "getLogsCount",
      selectedClusters,
      workspaceVal,
      queryString,
      selectedNamespaces,
      period,
      selectedComponentType,
      isEditing
    ],
    async () => {
      const now = new Date();
      let from;
      let to;
      if (typeof period === "string") {
        from = getDifferenceTime(period, now).getTime();
        to = now.getTime();
      }
      if (typeof period === "object") {
        let modifiedPeriod = { ...period };
        if (moment(period.from).diff(period.to, "seconds") < 30) {
          modifiedPeriod.from = moment(period.from).startOf("day");
          modifiedPeriod.to = moment(period.to).endOf("day");
        }
        from = getTime(modifiedPeriod.from);
        to = getTime(modifiedPeriod.to);
      }
      const payload = {
        ClusterID: clusterID,
        WorkspaceID: workspaceVal,
        Namespace: namespace_name,
        Filters: queryString,
        FromTime: Math.floor(from / 1000),
        ToTime: Math.floor(to / 1000)
      };
      if (selectedComponentType?.label === NETWORK) {
        const response = await customPost("/datapipeline/v3/alerts/cilium/count", payload);
        const res = await response.json();
        return res;
      }
      if (selectedComponentType?.label === SYSTEM) {
        const response = await customPost("/datapipeline/v3/alerts/kubearmor/count", payload);
        const res = await response.json();
        return res;
      }
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Something went wrong with request", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};

export const useGetDynamicData = (
  onSuccess = () => {},
  cluster_id,
  namespace,
  component,
  filter_String,
  period,
  enabled
) => {
  const dispatch = useDispatch();
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedComponentType = useSelector(getSelectedComponentType);
  const workspaceVal = getAccuknoxWorkspace();
  return useQuery(
    [
      "useGetDynamicData",
      selectedClusters,
      workspaceVal,
      selectedNamespaces,
      selectedComponentType,
      filter_String,
      period
    ],
    async () => {
      const now = new Date();
      let from;
      let to;
      if (typeof period === "string") {
        from = getDifferenceTime(period, now).getTime();
        to = now.getTime();
      }
      if (typeof period === "object") {
        let modifiedPeriod = { ...period };
        if (moment(period.from).diff(period.to, "seconds") < 30) {
          modifiedPeriod.from = moment(period.from).startOf("day");
          modifiedPeriod.to = moment(period.to).endOf("day");
        }
        from = getTime(modifiedPeriod.from);
        to = getTime(modifiedPeriod.to);
      }
      const payload = {
        ClusterID: cluster_id,
        WorkspaceID: workspaceVal,
        Namespace: namespace,
        Component: `${component}`,
        Query: filter_String,
        Type: "value",
        FromTime: Math.floor(from / 1000),
        ToTime: Math.floor(to / 1000)
      };
      if (filter_String?.length > 0) {
        const response = await customPost("/datapipeline/v3/alerts/fields", payload);
        const res = await response.json();
        return res;
      }
    },
    {
      select: data => {
        return data?.fields?.values;
      },
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Something went wrong with request", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false,
      enabled: enabled
    }
  );
};

export const useGetDynamicDataKeys = (
  onSuccess = () => {},
  cluster_id,
  namespace,
  component,
  period
) => {
  const dispatch = useDispatch();
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedComponentType = useSelector(getSelectedComponentType);
  const workspaceVal = getAccuknoxWorkspace();

  return useQuery(
    [
      "useGetDynamicData",
      selectedClusters,
      workspaceVal,
      selectedNamespaces,
      selectedComponentType,
      period
    ],
    async () => {
      const now = new Date();
      let from;
      let to;
      if (typeof period === "string") {
        from = getDifferenceTime(period, now).getTime();
        to = now.getTime();
      }
      if (typeof period === "object") {
        let modifiedPeriod = { ...period };
        if (moment(period.from).diff(period.to, "seconds") < 30) {
          modifiedPeriod.from = moment(period.from).startOf("day");
          modifiedPeriod.to = moment(period.to).endOf("day");
        }
        from = getTime(modifiedPeriod.from);
        to = getTime(modifiedPeriod.to);
      }
      const payload = {
        ClusterID: cluster_id,
        WorkspaceID: workspaceVal,
        Namespace: namespace,
        Component: `${component}`,
        Type: "key",
        FromTime: Math.floor(from / 1000),
        ToTime: Math.floor(to / 1000)
      };
      const response = await customPost("/datapipeline/v3/alerts/fields", payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        const keysData = data?.fields?.keys?.map(dtx => ({
          value: dtx,
          label: dtx
        }));
        if (window.location.pathname === "/monitors-logging/logs-v2") {
          return keysData;
        } else {
          return data?.fields?.keys;
        }
      },
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Something went wrong with request", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};

export const useCreateTriggers = (onSuccess = () => {}) => {
  const workspaceVal = getAccuknoxWorkspace();
  const dispatch = useDispatch();
  return useMutation(
    async ({
      triggerName,
      channel_id,
      toggle,
      freq_data,
      filter_query,
      filter_type,
      selectedComponentsFilter,
      statusTrigger
    }) => {
      const payload = {
        TriggerName: triggerName,
        ChannelID: channel_id,
        Frequency: freq_data,
        FilterQuery: filter_query.join(","),
        Filters: selectedComponentsFilter,
        Priority: statusTrigger,
        Option: "save"
      };
      const result = await customPost("/v3/integrations/triggers", payload);
      return result.json();
    },
    {
      onSuccess,
      onError: async data => {
        const res = await data?.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: "Failed to Create trigger",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useCallSaveFilter = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  const workspaceVal = getAccuknoxWorkspace();
  return useMutation(
    async ({ filterQuery, name, filterType, filters }) => {
      const payload = {
        FilterName: name,
        FilterQuery: filterQuery.join(","),
        Filters: filters,
        Option: "save"
      };
      const response = await customPost("/v3/integrations/filters", payload);
      return response.json;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Save", type: "error" }));
      }
    }
  );
};

export const useEditSaveFilter = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ filter_id, filter_name, filterQuery, filters }) => {
      const payload = {
        FilterID: filter_id,
        FilterName: filter_name,
        FilterQuery: filterQuery,
        Filters: filters,
        Option: "update"
      };
      const response = await customPost("/v3/integrations/filters", payload);
      return response.json;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Edit", type: "error" }));
      }
    }
  );
};
export const useDeleteSaveFilter = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ filter_id }) => {
      const payload = {
        FilterID: filter_id.toString()
      };
      const response = await customPost("/v3/integrations/deletefilters", payload);
      return response.json;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Delete!", type: "error" }));
      }
    }
  );
};

export const useFetchTriggers = (onSuccess = () => {}, workspaceVal, pageId, pageSize) => {
  const dispatch = useDispatch();
  return useQuery(
    ["fetchTrigersData", workspaceVal, pageId, pageSize],
    async () => {
      const payload = {
        PageID: pageId,
        PageSize: pageSize
      };
      const response = await customPost("/v3/integrations/gettriggers", payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Something went wrong with request", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};
export const useEditTriggers = (onSuccess = () => {}) => {
  const workspaceVal = getAccuknoxWorkspace();
  const dispatch = useDispatch();
  return useMutation(
    async ({
      trigger_name,
      channelID,
      filter_query,
      selectedComponentsFilter,
      filterid,
      trigger_id,
      frequency_data,
      priority_data
    }) => {
      const payload = {
        TriggerID: Number(trigger_id),
        FilterID: Number(filterid),
        TriggerName: trigger_name,
        ChannelID: Number(channelID),
        Frequency: frequency_data,
        FilterQuery: filter_query,
        Filters: selectedComponentsFilter,
        Priority: priority_data,
        Option: "update"
      };

      const result = await customPost("/v3/integrations/triggers", payload);
      return result.json();
    },
    {
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Edit trigger", type: "error" }));
      }
    }
  );
};
export const useHandleSwitch = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ id, toggleChange }) => {
      const payload = {
        TriggerID: id,
        Toggle: toggleChange
      };
      const response = await customPost("/v3/integrations/toggletrigger", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Something went wrong with request", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};

export const useDeleteTrigger = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ trigger_id, filter_id }) => {
      const payload = {
        FilterID: filter_id,
        TriggerID: trigger_id
      };
      const response = await customPost("/v3/integrations/deletetriggers", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Something went wrong with request", type: "error" }));
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};
