/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getCompareBaselinesSelector } from "store/entities/baseline/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import { getCompareBaselines } from "store/entities/baseline/actions";

import { Table } from "components/complex";
import { ContentContainer, Title, ButtonGoBack } from "components/simple";

import styles from "../styles.module.scss";

const CompareBaselines = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [pageSize, setPageSize] = useState(20);
  const ids = params?.ids;
  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getCompareBaselines.type])(state));
  const { data, columns } = useSelector(getCompareBaselinesSelector);

  // get default request
  const getRequest = () => {
    if (ids) {
      dispatch(getCompareBaselines({ ids: ids.split(",") }));
    }
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  const customStyles = {
    height: 710,
    width: 1,
    padding: "30px",
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      borderRadius: "10px",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important"
      }
    }
  };

  return (
    <>
      <ButtonGoBack className={styles.btnGoBack} />
      <ContentContainer className={styles.container}>
        <div className={styles.controls}>
          <Title>Compare</Title>
        </div>
        <Table
          data={isLoading ? [] : data}
          columns={columns}
          loading={isLoading}
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 50]}
          checkboxVisible={false}
          sortingMode={false}
          customStyles={customStyles}
        />
      </ContentContainer>
    </>
  );
};

CompareBaselines.propTypes = {};

export default CompareBaselines;
