import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Button, Modal, Select, SelectAsync } from "components/simple";
import { Controller, useForm } from "react-hook-form";
import { useAddEntities } from "store/entities/clusters/mutations";
import { convertEntityType, useGetLabel } from "store/entities/clusters/queries";
import styled from "styled-components";
import useDebounce from "helper/hooks/useDebounce";

const AddLabelModal = ({ modalOpen, setModalOpen, rightEntity }) => {
  const [labelID, setLabelID] = useState([]);
  const [searchText, setSearchText] = useState("");
  const useAddEntitiesMutation = useAddEntities();

  const searchInputText = useDebounce(searchText, 1000);
  const { data: labelData = [] } = useGetLabel(rightEntity, searchInputText);

  const addEntity = (entity, label) => {
    label?.map(data => {
      const payload = {
        workspace_id: entity?.WorkspaceID,
        label_id: data?.labelId,
        entities: [
          {
            entity_type: convertEntityType(entity?.entity_type),
            id: entity.ID
          }
        ],
        created_by: "30d4caa0-0c48-4d2c-874d-0edb1a950a43"
      };
      useAddEntitiesMutation.mutate(payload);
    });
  };

  const {
    control,
    formState: { isValid }
  } = useForm();
  return (
    <>
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <TitleContainer>
          <Typography variant="h5">Add Label</Typography>
        </TitleContainer>
        <SelectorContainer>
          <Typography variant="subtitle1">Search Label</Typography>
          <Controller
            name="workload"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  options={labelData?.map(data => {
                    return {
                      value: data?.name,
                      label: data?.name,
                      labelId: data?.label_id
                    };
                  })}
                  placeholder="Select..."
                  value={value}
                  onChange={data => {
                    onChange(data);
                    setLabelID(data);
                  }}
                  onInputChange={e => setSearchText(e)}
                  isMulti={true}
                />
              );
            }}
          />
        </SelectorContainer>
        <ButtonContainer>
          <Button onClick={() => setModalOpen(!modalOpen)} className="CancelBtn">
            Cancel
          </Button>
          <Button onClick={() => addEntity(rightEntity, labelID)} disabled={!isValid}>
            Add
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};

export default AddLabelModal;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 13px;
`;

const SelectorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  .CancelBtn {
    height: 38px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: #fff;
    padding: 10px 20px 12px;
    width: auto;
    color: black;
  }
  .CancelBtn:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
