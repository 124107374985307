import React, { useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Select, Tabs } from "components/simple";
import styles from "./drawer.module.scss";
import { FormLabel } from "@mui/material";
import Table from "../Table";
import CloseIcon from "@mui/icons-material/Close";
import {
  AUTO_DISCOVERED_POLICY_COLUMN,
  POLICIES_COLUMN
} from "screens/Inventory/ClusterManagement/columns";
import { CLUSTER, NODE, POD } from "screens/Inventory/ClusterManagement/constant";
import {
  useADPolicyApi,
  useAllPolicyApi
} from "screens/Inventory/ClusterManagement/service/mutation";
import { AddId } from "screens/Inventory/ClusterManagement/helpers/dataTransformation";
import { uniq } from "lodash";
import { useGetUsersQuery } from "store/entities/policies/queries";

export default function RightSlideDrawer({
  text = "Policies for",
  anchor,
  tabs,
  value,
  options,
  handleChange,
  state,
  setState,
  activeTab,
  onChange
}) {
  const [tableRow, setTableRow] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const Network = ["Kubernetes Network", "Cilium Network"];
  const System = ["KubeArmor", "KubeArmor Host"];
  const Infra = "GKE";
  const getUsers = useGetUsersQuery();
  const getNetworkPoilicy = useAllPolicyApi();
  const getSystemPoilicy = useAllPolicyApi();
  const autoDiscoverPolicies = useADPolicyApi();

  useEffect(() => {
    const Cluster =
      value?.entity_type === CLUSTER ? [value?.ID] : value?.ClusterID ? [value?.ClusterID] : [];
    const Node = value?.entity === NODE ? [value?.ID] : value?.NodeID ? [value?.NodeID] : [];
    const Namespace = value?.namespace_id ? [value?.namespace_id] : [];
    const Pod = value?.entity_type === POD ? [value?.ID] : [];

    if (value?.ID) {
      let type = Network;
      getNetworkPoilicy.mutate({ Cluster, Node, Namespace, Pod, Infra, type });
      type = System;
      getSystemPoilicy.mutate({ Cluster, Node, Namespace, Pod, Infra, type });
      autoDiscoverPolicies.mutate({ Cluster, Node, Namespace, Pod, Infra, type });
    }
  }, [JSON.stringify(value)]);
  useEffect(() => {
    if (
      getNetworkPoilicy?.data?.list_of_policies?.length &&
      getSystemPoilicy?.data?.list_of_policies?.length
    ) {
      const userIdsforNetwork = uniq(
        getNetworkPoilicy?.data?.list_of_policies?.map(p => p.updated_by)
      );
      const userIdsforSystem = uniq(
        getSystemPoilicy?.data?.list_of_policies?.map(p => p.updated_by)
      );
      const userIds = uniq(userIdsforNetwork?.concat(userIdsforSystem));
    }
  }, [JSON.stringify(getNetworkPoilicy), JSON.stringify(getSystemPoilicy)]);
  useEffect(() => {
    if (activeTab === 0) {
      const result = AddId(getNetworkPoilicy?.data?.list_of_policies);
      setTableRow(result);
      setTableColumn(POLICIES_COLUMN(getUsers));
    }
    if (activeTab === 1) {
      const result = AddId(getSystemPoilicy?.data?.list_of_policies);
      setTableRow(result);
      setTableColumn(POLICIES_COLUMN(getUsers));
    }
    if (activeTab === 2) {
      const result = AddId(autoDiscoverPolicies?.data?.list_of_policies);
      setTableRow(result);
      setTableColumn(AUTO_DISCOVERED_POLICY_COLUMN);
    }
  }, [
    activeTab,
    JSON.stringify(getUsers),
    JSON.stringify(getNetworkPoilicy),
    JSON.stringify(getSystemPoilicy),
    JSON.stringify(autoDiscoverPolicies)
  ]);

  return (
    <>
      <SwipeableDrawer
        anchor={anchor}
        open={state}
        onClose={() => setState(false)}
        onOpen={() => setState(true)}
        PaperProps={{
          sx: {
            width: "65%"
          }
        }}
      >
        <Box role="presentation" className={styles.slide}>
          <section className="flex items-center justify-between w-full">
            <Box className="flex">
              <FormLabel className="flex items-center justify-center w-32 mr-1">{text}</FormLabel>
              <Select
                className=" w-44"
                onChange={val => onChange(val)}
                // label="clusters"
                value={value}
                placeholder="clusters"
                options={options}
              />
            </Box>
            <CloseIcon onClick={() => setState(false)} className="cursor-pointer" />
          </section>
          <section className={styles.tab}>
            <Tabs tabs={tabs} value={activeTab} handleChange={handleChange} />
          </section>
          <section className={styles.tab}>
            {/* {activeTab === 2 ? (
              <Box className={`${styles.nodata} pt-8`}>Coming Soon</Box>
            ) : (
              <Table data={tableRow} columns={tableColumn} checkboxVisible={false} pageSize={10} />
            )} */}
            <Table data={tableRow} columns={tableColumn} checkboxVisible={false} pageSize={10} />
          </section>
        </Box>
      </SwipeableDrawer>
    </>
  );
}
