import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "../notifications/actions";

const getTagsParams = state => {
  return {
    page: state.tags.tagsParams.page,
    pageSize: state.tags.tagsParams.pageSize,
    search: state.tags.tagsParams.search
  };
};

// Workers //

// Set Tickets Params
function* setTagsParamsWorker() {
  const { page, pageSize, search } = yield select(getTagsParams);
  yield put(
    actions.getTags({
      page,
      pageSize,
      search
    })
  );
}

// Get Tags
function* getTagsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTags), payload);
    yield put(actions.getTagsSuccess(response));
  } catch (error) {
    yield put(actions.getTagsFailed(error));
  }
}

// Get Tag
function* getTagWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTag), payload);
    yield put(actions.getTagSuccess(response));
  } catch (error) {
    yield put(actions.getTagFailed(error));
  }
}

// Create Tag
function* createTagWorker({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield call(makeRequest(service.createTag), data);
    yield put(actions.createTagSuccess(response));
    yield put(actions.setTagsParams());
    yield put(addNotification({ msg: `Tag created successfully` }));
    callback();
  } catch (error) {
    yield put(actions.createTagFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete Tag
function* deleteTagWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteTag), id)));
    yield put(actions.deleteTagSuccess(response));
    yield put(actions.setTagsParams());
    yield put(addNotification({ msg: `${ids?.length > 1 ? "Tags" : "Tag"} deleted successfully` }));
  } catch (error) {
    yield put(actions.deleteTagFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Tags Mini
function* getTagsMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTagsMini), payload);
    yield put(actions.getTagsMiniSuccess(response));
  } catch (error) {
    yield put(actions.getTagsMiniFailed(error));
  }
}

// Workers End //

// Watchers //

// Get Tags
function* watchSetTagsParamsWorker() {
  yield takeEvery(actions.setTagsParams, setTagsParamsWorker);
}

// Get Tags
function* watchGetTagsWorker() {
  yield takeEvery(actions.getTags, getTagsWorker);
}

// Get Tag
function* watchGetTagWorker() {
  yield takeEvery(actions.getTag, getTagWorker);
}

// Create Tag
function* watchCreateTagWorker() {
  yield takeEvery(actions.createTag, createTagWorker);
}

// Delete Tag
function* watchDeleteTagWorker() {
  yield takeEvery(actions.deleteTag, deleteTagWorker);
}

// Get Tags Mini
function* watchGetTagsMiniWorker() {
  yield takeEvery(actions.getTagsMini, getTagsMiniWorker);
}

// Watchers End//

export default function* rootSaga() {
  yield all([
    fork(watchSetTagsParamsWorker),
    fork(watchGetTagsWorker),
    fork(watchGetTagWorker),
    fork(watchGetTagsMiniWorker),
    fork(watchCreateTagWorker),
    fork(watchDeleteTagWorker)
  ]);
}
