import React from "react";
import PropTypes from "prop-types";

export default function User(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17V16C1 14.1435 1.7375 12.363 3.05025 11.0503C4.36301 9.7375 6.14348 9 8 9V9C9.85652 9 11.637 9.7375 12.9497 11.0503C14.2625 12.363 15 14.1435 15 16V17"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9C9.06087 9 10.0783 8.57857 10.8284 7.82843C11.5786 7.07828 12 6.06087 12 5C12 3.93913 11.5786 2.92172 10.8284 2.17157C10.0783 1.42143 9.06087 1 8 1C6.93913 1 5.92172 1.42143 5.17157 2.17157C4.42143 2.92172 4 3.93913 4 5C4 6.06087 4.42143 7.07828 5.17157 7.82843C5.92172 8.57857 6.93913 9 8 9V9Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

User.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

User.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 16,
  height: 18
};
