import { customGet, customPost } from "api/base";
import { getAccuknoxWorkspace } from "api/api";
// eslint-disable-next-line import/no-unresolved
import { useQuery, useMutation } from "react-query";
import { data } from "autoprefixer";

export const workspaceID = getAccuknoxWorkspace();

export const useGetOnboardedClusters = search => {
  return useQuery(
    ["onboard", "getAllOnboardedClusters"],
    async () => {
      const workSpaceId = getAccuknoxWorkspace();
      const response = await customGet(
        `/cluster-onboarding/api/v1/get-onboarded-clusters?wsid=${workSpaceId}`
      );
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false,
      // refetchInterval: refreshTime,
      retry: false,
      select: data => {
        if (!data) {
          return null;
        } else {
          if (search) {
            const searchResult = data?.filter(cluster =>
              cluster?.ClusterName?.toLowerCase().includes(search.toLowerCase())
            );
            return searchResult;
          } else {
            return data;
          }
        }
      }
    }
  );
};

export const useGetVMQuery = () => {
  const payload = {
    workspace_id: workspaceID,
    query: "",
    from_time: [],
    to_time: []
  };

  return useQuery(
    ["vmOnboardingData"],
    async () => {
      const response = await customPost(`/cm/api/v1/vm-management/vm-navbar`, payload);
      const finalVMData = await response.json();

      return finalVMData.list_of_instance_group;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export const useClusterOnboardQuery = () => {
  return useQuery(
    ["clusterData"],
    async () => {
      const response = await customGet(
        `/cluster-onboarding/api/v1/list-cloud-provider-cluster-locations/zones`
      );
      const finalClusterData = await response.json();
      return finalClusterData.result;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};
