import React from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  updateSelectedVulnerabilityType,
  useRiskBasedPrioritizationState
} from "store/entities/issues/riskBasedPriortization/slice";
import CloseIcon from "@mui/icons-material/Close";

export const DynamicFilter = () => {
  const dispatch = useDispatch();
  const selectedVulnerabilityType = useRiskBasedPrioritizationState("selectedVulnerabilityType");

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center border shadow rounded-md p-1 pr-2 min-w-[400px]">
        <div className="p-2 h-auto flex items-center justify-center gap-1 border-r">
          <FilterAltIcon sx={{ color: theme => theme.palette.grey[600] }} />
          <Typography paragraph sx={{ color: theme => theme.palette.grey[600], m: 0 }}>
            Filter
          </Typography>
        </div>
        {Boolean(selectedVulnerabilityType) && (
          <Box
            sx={{ backgroundColor: "primary.light" }}
            className="text-white font-medium flex items-center justify-between gap-2
          rounded-3xl px-3 py-2 m-0"
          >
            <p className="text-xs m-0">{selectedVulnerabilityType.description}</p>
            <CloseIcon
              sx={{ fontSize: "16px", cursor: "pointer" }}
              onClick={() => dispatch(updateSelectedVulnerabilityType(null))}
            />
          </Box>
        )}
      </div>
    </div>
  );
};
