import _ from "lodash";

export const createLoadingSelector = actions => state => {
  // returns true only when all actions is not loading
  return _(actions).some(action => {
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action);
    const [, requestName] = matches;
    return _.get(state, `loading.${requestName}`);
  });
};
// HOW USE:  const loading = useSelector(state => createLoadingSelector([getUser.type])(state));
