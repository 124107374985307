import React from "react";

import { ContentContainer, Title } from "components/simple";

import styles from "./styles.module.scss";

const Overview = ({ data, days }) => {
  return (
    <ContentContainer className={styles.container}>
      <Title>Vulnerability Overview</Title>
      <div className={styles.blocksContainer}>
        {data.map((elem, index) => (
          <div key={index} className={styles.block}>
            <div>{elem.name}</div>
            <div>{elem.value}</div>
            <div>Reappeared</div>
            <div>
              Within the last {days} {days > 1 ? "days" : "day"}
            </div>
          </div>
        ))}
      </div>
    </ContentContainer>
  );
};

export default Overview;
