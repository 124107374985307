import React from "react";

export default function Runtime(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 17"
      fill="none"
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25002 13.75H2.50002L3.25002 8.5H0.625024C0.190024 8.5 0.197524 8.26 0.340024 8.005C0.482524 7.75 0.377524 7.945 0.392524 7.915C1.36002 6.205 2.81502 3.655 4.75002 0.25H5.50002L4.75002 5.5H7.37502C7.74252 5.5 7.79502 5.7475 7.72752 5.8825L7.67502 5.995C4.72002 11.1625 3.25002 13.75 3.25002 13.75Z"
        fill={props.color}
      />
    </svg>
  );
}
