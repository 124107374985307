/* eslint-disable camelcase */
import React from "react";
import { Select, Button, Tags, Input, Switcher } from "components/simple";
import { useSelector, useDispatch } from "react-redux";
import { removeQuery } from "helper/history";

import { setMonitorConfigParams, updMonitorConfigParams } from "store/entities/monitors/actions";
import {
  getMonitorConfigFiltersSelect,
  getMonitorDataSelector
} from "store/entities/monitors/selectors";

import styles from "./styles.module.scss";

const Panel = ({ onSave, onDublicate, id }) => {
  const dispatch = useDispatch();
  const {
    name,
    action,
    condition,
    display_fields,
    assign_to_data_type,
    data_type,
    display_fields_width
  } = useSelector(getMonitorDataSelector);

  const monitorConfigFiltersSelect = useSelector(getMonitorConfigFiltersSelect);

  const { actions, conditions, display_fields_list } = monitorConfigFiltersSelect;

  const onChangeSelect = (val, key) => {
    removeQuery("display_fields");
    dispatch(
      setMonitorConfigParams({
        [key]: val?.map(item => item?.value).filter(s => s)
      })
    );
  };

  const handleChange = (val, key) => {
    dispatch(
      updMonitorConfigParams({
        [key]: val
      })
    );
  };

  const handleChangeFilter = (val, key) => {
    dispatch(
      updMonitorConfigParams({
        [key]: val?.value
      })
    );
  };

  const onSubmit = e => {
    e.preventDefault();
    onSave({
      name,
      action: action?.value,
      condition: condition?.value,
      display_fields: display_fields?.map(item => item?.value).filter(s => s),
      assign_to_data_type,
      data_type,
      table_properties: { display_fields_width }
    });
  };

  const onDublicateHandle = () => {
    onDublicate({
      id,
      name,
      action: action?.value,
      condition: condition?.value,
      display_fields: display_fields?.map(item => item?.value).filter(s => s),
      assign_to_data_type,
      data_type,
      table_properties: { display_fields_width }
    });
  };

  return (
    <form className={styles.panel} onSubmit={onSubmit}>
      <div className={styles.panelHead}>
        <Input
          label="Name"
          value={name}
          onChange={val => handleChange(val.target.value, "name")}
          containerClassName={styles.input}
          required
        />
        {id ? (
          <div className={styles.buttonGroup}>
            <button type="button" className={styles.button} onClick={onDublicateHandle}>
              Duplicate
            </button>
          </div>
        ) : null}
      </div>
      <div className={styles.row}>
        <Select
          containerClass={styles.select}
          onChange={v => handleChangeFilter(v, "condition")}
          value={condition || ""}
          placeholder="Condition "
          label="Condition "
          options={conditions}
          required
        />
        <Select
          containerClass={styles.select}
          onChange={v => handleChangeFilter(v, "action")}
          value={action || ""}
          placeholder="Action"
          label="Action"
          options={actions}
          required
        />
        <Tags
          label="Select fields to display"
          values={display_fields}
          list={display_fields_list}
          onChange={v => onChangeSelect(v, "display_fields")}
        />
        <Switcher
          label="Assign to data type"
          options={{
            active: "True",
            inactive: "False"
          }}
          value={Boolean(assign_to_data_type)}
          onChange={val => handleChange(val, "assign_to_data_type")}
        />
      </div>

      <div className={styles.buttonGroup}>
        <Button className={styles.editButton} type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

Panel.propTypes = {};

export default Panel;
