export const SET_REPORTS_PARAMS = "SET_REPORTS_PARAMS";

// Get reports
export const GET_REPORTS = "GET_REPORTS_REQUEST";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAILED = "GET_REPORTS_FAILED";

// Get report
export const GET_REPORT = "GET_REPORT_REQUEST";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILED = "GET_REPORT_FAILED";

// Create report
export const CREATE_REPORT = "CREATE_REPORT_REQUEST";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILED = "CREATE_REPORT_FAILED";

// Edit report
export const EDIT_REPORT = "EDIT_REPORT_REQUEST";
export const EDIT_REPORT_SUCCESS = "EDIT_REPORT_SUCCESS";
export const EDIT_REPORT_FAILED = "EDIT_REPORT_FAILED";

// Delete report
export const DELETE_REPORT = "DELETE_REPORT_REQUEST";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAILED = "DELETE_REPORT_FAILED";

// Run report
export const RUN_REPORT = "RUN_REPORT_REQUEST";
export const RUN_REPORT_SUCCESS = "RUN_REPORT_SUCCESS";
export const RUN_REPORT_FAILED = "RUN_REPORT_FAILED";

// Get report pages
export const GET_REPORT_PAGES = "GET_REPORT_PAGES_REQUEST";
export const GET_REPORT_PAGES_SUCCESS = "GET_REPORT_PAGES_SUCCESS";
export const GET_REPORT_PAGES_FAILED = "GET_REPORT_PAGES_FAILED";

// Get report periods
export const GET_REPORT_PERIODS = "GET_REPORT_PERIODS_REQUEST";
export const GET_REPORT_PERIODS_SUCCESS = "GET_REPORT_PERIODS_SUCCESS";
export const GET_REPORT_PERIODS_FAILED = "GET_REPORT_PERIODS_FAILED";
