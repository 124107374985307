import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  templates: [],
  template: {},
  templatesDataTypes: [],
  templatesParams: {
    page: 0,
    pageSize: 20,
    ordering: ""
  },
  roles: [],
  role: {},
  users: [],
  permissions: [],
  rolesParams: {
    page: 0,
    pageSize: 20,
    ordering: ""
  },
  s3Profile: null,
  addModalS3Profile: false,
  emailProfile: {},
  addModalConnector: false,
  jiraCloud: null,
  jiraServer: null,
  connectWise: null,

  freshservice: null,
  currentJiraCloud: null,
  currentJiraServer: null,
  currentFreshservice: null,
  currentConnectWise: null,
  userProfiles: {
    page: 0,
    pageSize: 20,
    ordering: "",
    search: "",
    data: null
  },
  oneUserProfile: null,
  groupsForUserProfile: null,
  labels: [],
  label: {},
  labelsParams: {
    page: 0,
    pageSize: 20,
    ordering: "",
    search: ""
  },
  ticketsConfigList: null,
  ticketsConfig: null,
  showTicketsConfigModal: {
    show: false,
    id: null,
    type: null
  },
  jiraAll: [],
  freshserviceConfig: [],
  connectWiseConfig: [],
  templateListMini: null,
  status: [],
  permissionsModal: false
};

const reducer = createReducer(
  initialState,
  {
    [actions.setTemplatesParams.type]: (state, action) => ({
      ...state,
      templatesParams: {
        ...state.templatesParams,
        ...action.payload
      }
    }),
    [actions.getTemplatesSuccess.type]: (state, action) => ({
      ...state,
      template: {},
      templates: action.payload
    }),
    [actions.getTemplateSuccess.type]: (state, action) => ({
      ...state,
      template: action.payload
    }),
    [actions.getTemplatesDataTypesSuccess.type]: (state, action) => ({
      ...state,
      templatesDataTypes: action.payload
    }),
    [actions.setRolesParams.type]: (state, action) => ({
      ...state,
      rolesParams: {
        ...state.rolesParams,
        ...action.payload
      }
    }),
    [actions.getRolesSuccess.type]: (state, action) => ({
      ...state,
      role: {},
      roles: action.payload
    }),
    [actions.getRoleSuccess.type]: (state, action) => ({
      ...state,
      role: action.payload
    }),
    [actions.getUsersSuccess.type]: (state, action) => ({
      ...state,
      users: action.payload
    }),
    [actions.getPermissionsSuccess.type]: (state, action) => ({
      ...state,
      permissions: action.payload
    }),
    [actions.getS3Profile.type]: state => ({
      ...state,
      s3Profile: null
    }),
    [actions.getS3ProfileSuccess.type]: (state, action) => ({
      ...state,
      s3Profile: action.payload
    }),
    [actions.addModalS3Profile.type]: (state, action) => ({
      ...state,
      addModalS3Profile: action.payload
    }),
    [actions.getEmailProfileSuccess.type]: (state, action) => ({
      ...state,
      emailProfile: action.payload
    }),
    [actions.addModalConnector.type]: (state, action) => ({
      ...state,
      addModalConnector: action.payload
    }),
    [actions.getJiraCloudSuccess.type]: (state, action) => ({
      ...state,
      jiraCloud: action.payload
    }),
    [actions.getOneJiraCloud.type]: state => ({
      ...state,
      currentJiraCloud: null,
      currentJiraServer: null,
      currentFreshservice: null,
      ticketsConfigList: null,
      currentConnectWise: null
    }),
    [actions.getOneJiraCloudSuccess.type]: (state, action) => ({
      ...state,
      currentJiraCloud: action.payload
    }),

    [actions.getConnectWiseSuccess.type]: (state, action) => ({
      ...state,
      connectWise: action.payload
    }),
    [actions.getConnectWise.type]: state => ({
      ...state,
      currentJiraCloud: null,
      currentJiraServer: null,
      currentFreshservice: null,
      ticketsConfigList: null,
      currentConnectWise: null
    }),
    [actions.getOneConnectWiseSuccess.type]: (state, action) => ({
      ...state,
      currentConnectWise: action.payload
    }),

    [actions.getJiraServerSuccess.type]: (state, action) => ({
      ...state,
      jiraServer: action.payload
    }),
    [actions.getOneJiraServer.type]: state => ({
      ...state,
      currentJiraCloud: null,
      currentJiraServer: null,
      currentFreshservice: null,
      ticketsConfigList: null,
      currentConnectWise: null
    }),
    [actions.getOneJiraServerSuccess.type]: (state, action) => ({
      ...state,
      currentJiraServer: action.payload
    }),
    [actions.getFreshserviceSuccess.type]: (state, action) => ({
      ...state,
      freshservice: action.payload
    }),
    [actions.getOneFreshservice.type]: state => ({
      ...state,
      currentJiraCloud: null,
      currentJiraServer: null,
      currentFreshservice: null,
      ticketsConfigList: null,
      currentConnectWise: null
    }),
    [actions.getOneFreshserviceSuccess.type]: (state, action) => ({
      ...state,
      currentFreshservice: action.payload
    }),
    [actions.getUserProfilesSuccess.type]: (state, action) => ({
      ...state,
      oneUserProfile: {},
      userProfiles: {
        ...state.userProfiles,
        data: action.payload
      }
    }),
    [actions.setUserProfilesParams.type]: (state, action) => ({
      ...state,
      userProfiles: {
        ...state.userProfiles,
        ...action.payload
      }
    }),
    [actions.getOneUserProfile.type]: state => ({
      ...state,
      oneUserProfile: null
    }),
    [actions.getOneUserProfileSuccess.type]: (state, action) => ({
      ...state,
      oneUserProfile: action.payload
    }),
    [actions.getUserProfilesGroupSuccess.type]: (state, action) => ({
      ...state,
      groupsForUserProfile: action.payload
    }),
    [actions.setLabelsParams.type]: (state, action) => ({
      ...state,
      labelsParams: {
        ...state.labelsParams,
        ...action.payload
      }
    }),
    [actions.getSettingsLabelsSuccess.type]: (state, action) => ({
      ...state,
      label: {},
      labels: action.payload
    }),
    [actions.getLabelSuccess.type]: (state, action) => ({
      ...state,
      label: action.payload
    }),
    [actions.editLabelSuccess.type]: (state, action) => ({
      ...state,
      label: action.payload
    }),
    [actions.getTicketsConfig.type]: state => ({
      ...state,
      ticketsConfigList: null
    }),
    [actions.getTicketsConfigSuccess.type]: (state, action) => ({
      ...state,
      ticketsConfigList: action.payload
    }),

    [actions.getTicketConfig.type]: state => ({
      ...state,
      ticketsConfig: null
    }),
    [actions.getTicketConfigSuccess.type]: (state, action) => ({
      ...state,
      ticketsConfig: action.payload
    }),
    [actions.showTicketsConfigModal.type]: (state, action) => ({
      ...state,
      showTicketsConfigModal: action.payload
    }),
    [actions.getJiraAll.type]: state => ({
      ...state,
      jiraAll: [],
      freshserviceConfig: [],
      connectWiseConfig: []
    }),
    [actions.getJiraAllSuccess.type]: (state, action) => ({
      ...state,
      jiraAll: action.payload,
      freshserviceConfig: [],
      connectWiseConfig: []
    }),
    [actions.getFreshserviceConfig.type]: state => ({
      ...state,
      freshserviceConfig: [],
      jiraAll: [],
      connectWiseConfig: []
    }),
    [actions.getFreshserviceConfigSuccess.type]: (state, action) => ({
      ...state,
      freshserviceConfig: action.payload,
      jiraAll: [],
      connectWiseConfig: []
    }),

    [actions.getConnectWiseConfig.type]: state => ({
      ...state,
      freshserviceConfig: [],
      jiraAll: [],
      connectWiseConfig: []
    }),
    [actions.getConnectWiseConfigSuccess.type]: (state, action) => ({
      ...state,
      freshserviceConfig: [],
      jiraAll: [],
      connectWiseConfig: action.payload
    }),
    [actions.getTemplateListMiniSuccess.type]: (state, action) => ({
      ...state,
      templateListMini: action.payload
    }),
    [actions.setLabelsParams.type]: (state, action) => ({
      ...state,
      labelsParams: {
        ...state.labelsParams,
        ...action.payload
      }
    }),
    [actions.getSettingsLabelsSuccess.type]: (state, action) => ({
      ...state,
      label: {},
      labels: action.payload
    }),
    [actions.getLabelSuccess.type]: (state, action) => ({
      ...state,
      label: action.payload
    }),
    [actions.editLabelSuccess.type]: (state, action) => ({
      ...state,
      label: action.payload
    }),
    [actions.getStatusForTicketConfigSuccess.type]: (state, action) => ({
      ...state,
      status: action.payload
    }),
    [actions.permissionsModal.type]: (state, action) => ({
      ...state,
      permissionsModal: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
