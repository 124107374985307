import React from "react";

import { Controller } from "react-hook-form";
import { ErrorText, Textarea } from "components/simple";

export const RegistryDescription = ({ control, errors }) => {
  return (
    <div className="w-full mb-3.5 mt-3">
      <Controller
        name="registryDescription"
        rules={{
          required: "Registry Description is required!",
          maxLength: { value: 256, message: "Maximum 256 characters allowed!" }
        }}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Textarea
            name="registryDescription"
            label="Description"
            placeholder="Registry Description"
            required
            value={value}
            onChange={val => {
              onChange(val);
            }}
          />
        )}
      />
      <div className="m-0.5">
        <ErrorText errorMsg={errors.registryDescription && errors.registryDescription.message} />
      </div>
    </div>
  );
};
