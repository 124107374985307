import React, { useState } from "react";
import { ContentContainer, RefreshInterval, Select } from "components/simple";
import DateRangePicker from "components/simple/DateRangePicker/DateRangePicker-2";
import { Skeleton, Tooltip, Typography } from "@mui/material";
import styled from "styled-components";
import styles from "../AutoDiscoveredPolicy/styles.module.scss";
import Barchart from "components/simple/Charts/Barchart";
import StackedBarchart from "components/simple/Charts/StackedBarchart";
import Piechart from "components/simple/Charts/Piechart";
import Linechart from "components/simple/Charts/Linechart";
import {
  fetchKubeActionCounts,
  fetchClustersCounts,
  fetchNamespaceCounts,
  fetchPodsCounts
} from "./service";
import { useEffect } from "react";
import { COLORS, Severity, tagKeys, RefreshTime } from "./constants";
import moment from "moment";
import Loader from "components/simple/Loader";
import CustomSelect from "components/simple/CustomSelect";
import {
  getSelectedClusters,
  getSelectedNamespaces,
  getSelectedPod,
  setSelectedClusters,
  setSelectedNamespace,
  setSelectedPod
} from "store/entities/filters/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  useClustersQuery,
  useListofPodData,
  useNamespacesQuery
} from "store/entities/filters/queries";
import { CountDataConversion } from "helper/dataTransformation";
import { useInterval } from "helper/helpers";
import { onChangeUrlParams, useQuery } from "helper/history";
import { navigate } from "helper/history";
import { routes } from "router";
import { useHistory } from "react-router-dom";
import { toolTipWrapper } from "./style";
import {
  getSingleClusters,
  getSingleNamespaces,
  setSingleClusters,
  setSingleNamespace,
  setSinglePod
} from "store/entities/filters/singleFilters/slice";

function CwppDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const queryFilters = query.get("filters");

  const [complianceAlerts, setComplianceAlert] = useState({ data: [], tags: [] });
  const [namespaceSeverity, setNamespaceSeverity] = useState();
  const [alertPolicy, setAlertPolicy] = useState({ data: [], keys: [] });
  const [alertPolicykeys, setalertPolicykeys] = useState([]);
  const [nameSpaceAlerts, setNameSpaceAlerts] = useState();
  const [podAlerts, setPodAlerts] = useState();
  const [alertsOperation, setAlertsOperation] = useState({ data: [], keys: [] });
  const [alertsOperationKeys, setAlertsOperationKeys] = useState([]);
  const [alertsSeverity, setAlertsSeverity] = useState([]);

  const [mitreCount, setMitreCount] = useState(0);
  const [nist, setNist] = useState();
  const [cis, setCis] = useState();
  const [pci, setPci] = useState();

  const [refreshTimeVal, setRefreshTimeVal] = useState(RefreshTime[4].label);
  const [refreshTimeinsec, setRefreshTimeinsec] = useState(RefreshTime[4].value);

  const [ClusterAudit, setClusterAudit] = useState(0);
  const [ClusterBlocked, setClusterBlocked] = useState(0);
  const [podsAudit, setPodsAudit] = useState(0);
  const [podsBlocked, setPodsBlocked] = useState(0);
  const [namespaceAudit, setNamespaceAudit] = useState(0);
  const [namespaceBlocked, setNamespaceBlocked] = useState(0);
  // const [totalAuditCount, setTotalAuditCount] = useState(0);

  useEffect(() => {
    if (refreshTimeVal === "5 sec") {
      setRefreshTimeinsec(RefreshTime[1].value);
    }
    if (refreshTimeVal === "10 sec") {
      setRefreshTimeinsec(RefreshTime[2].value);
    }
    if (refreshTimeVal === "30 sec") {
      setRefreshTimeinsec(RefreshTime[3].value);
    }
    if (refreshTimeVal === "1 min") {
      setRefreshTimeinsec(RefreshTime[4].value);
    }
    if (refreshTimeVal === "5 mins") {
      setRefreshTimeinsec(RefreshTime[5].value);
    }
    if (refreshTimeVal === "10 mins") {
      setRefreshTimeinsec(RefreshTime[6].value);
    }
    if (refreshTimeVal === "30 mins") {
      setRefreshTimeinsec(RefreshTime[7].value);
    }
    if (refreshTimeVal === "off") {
      setRefreshTimeinsec(RefreshTime[0].value);
    }
  }, [refreshTimeVal]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="w-auto h-auto px-3 rounded-xl flex flex-col justify-center items-center bg-white opacity-90">
          <p className="w-full">
            <span className="font-semibold">{payload[0].payload.label}</span> :{" "}
            <span className="font-normal">{payload[0].name}</span>
          </p>

          <p className="w-full">
            <span className="font-semibold">Count of Alerts</span> :{" "}
            <span className="font-light"> {payload[0].value} </span>
          </p>
        </div>
      );
    }

    return null;
  };

  const timeRange = [
    { value: "Last 2 days", id: 1 },
    { value: "Last 7 days", id: 2 },
    { value: "Last 30 days", id: 3 },
    { value: "Last 60 days", id: 4 }
  ];
  const [Timerange, setTimeRange] = useState(timeRange[1].value);
  const { data: clusters = {}, isSuccess: isClusterIdSuccess } = useClustersQuery();
  const singleSelectedCluster = useSelector(getSingleClusters);
  // const selectedClusters = useSelector(getSelectedClusters);
  const { data: namespaces = {}, isSuccess: isNamespaceIdSuccess } = useNamespacesQuery(
    singleSelectedCluster
  );
  const singleSelectedNamespaces = useSelector(getSingleNamespaces);
  // const selectedNamespaces = useSelector(getSelectedNamespaces);
  const { data: pod = [] } = useListofPodData(singleSelectedCluster, singleSelectedNamespaces);
  const selectedPod = useSelector(getSelectedPod);

  const handleFilter = (value, filterName) => {
    if (filterName === "cluster") {
      dispatch(setSingleClusters([value]));
      // dispatch(setSelectedClusters([value]));
    } else if (filterName === "namespace") {
      dispatch(setSingleNamespace(value));
      // dispatch(setSelectedNamespace(value));
    } else if (filterName === "pod") {
      // dispatch(setSelectedPod(value));
      dispatch(setSinglePod([value]));
    }
  };
  const onChangeRange = range => setTimeRange(range);
  const clusterData = fetchClustersCounts(
    Timerange,
    singleSelectedCluster,
    singleSelectedNamespaces,
    selectedPod
  );
  const PodsData = fetchPodsCounts(
    Timerange,
    singleSelectedCluster,
    singleSelectedNamespaces,
    selectedPod
  );
  const NamespaceData = fetchNamespaceCounts(
    Timerange,
    singleSelectedCluster,
    singleSelectedNamespaces,
    selectedPod
  );
  const ActionsCount = fetchKubeActionCounts(
    Timerange,
    singleSelectedCluster,
    singleSelectedNamespaces,
    selectedPod
  );
  useEffect(() => {
    if (
      singleSelectedCluster.length === 0 &&
      singleSelectedNamespaces.length === 0 &&
      queryFilters
    ) {
      const params = JSON.parse(queryFilters);
      if (params.cluster) {
        dispatch(setSelectedClusters(params.cluster));
      }
      if (params.namespace) {
        dispatch(setSelectedNamespace(params.namespace));
      }
    }
    return () => {
      onChangeUrlParams("filter", "");
    };
  }, [queryFilters, singleSelectedCluster]);

  useEffect(() => {
    const dictFilter = {};
    if (singleSelectedCluster.length > 0) {
      dictFilter.cluster = singleSelectedCluster;
    }
    if (singleSelectedNamespaces.length > 0) {
      dictFilter.namespace = singleSelectedNamespaces;
    }
    if (dictFilter.cluster || dictFilter.namespace) {
      onChangeUrlParams("filters", JSON.stringify(dictFilter));
    }
  }, [singleSelectedCluster, singleSelectedNamespaces]);

  useEffect(() => {
    let nist_Tags = [];
    let mitre_Tags = [];
    let pci_Tags = [];
    let cis_Tags = [];
    let TagsData = [];

    // setClusterAudit(ActionsCount?.data?.response?.Alerts?.Audit);
    // setClusterBlocked(ActionsCount?.data?.response?.Alerts?.Blocked);
    if (clusterData?.isSuccess) {
      Object?.keys(clusterData?.data?.response?.Alerts?.Tags).forEach(key => {
        // TagsData.push(key.split(","));
        if (key.includes("nist") || key.includes("NIST") || key.includes("Nist")) {
          nist_Tags?.push({
            name: "NIST",
            nist: key,
            NIST: clusterData?.data?.response?.Alerts?.Tags[key],
            count: clusterData?.data?.response?.Alerts?.Tags[key]
          });
          TagsData.push({ name: "NIST", count: clusterData?.data?.response?.Alerts?.Tags[key] });
        }
        if (key.includes("mitre") || key.includes("MITRE") || key.includes("Mitre")) {
          mitre_Tags.push({
            name: "MITRE",
            mitre: key,
            MITRE: clusterData?.data?.response?.Alerts?.Tags[key],
            count: clusterData?.data?.response?.Alerts?.Tags[key]
          });
          TagsData.push({ name: "MITRE", count: clusterData?.data?.response?.Alerts?.Tags[key] });
        }
        if (key.includes("pci") || key.includes("PCI") || key.includes("Pci")) {
          pci_Tags.push({
            name: "PCI",
            pci: key,
            PCI: clusterData?.data?.response?.Alerts?.Tags[key],
            count: clusterData?.data?.response?.Alerts?.Tags[key]
          });
          TagsData.push({ name: "PCI-DSS", count: clusterData?.data?.response?.Alerts?.Tags[key] });
        }
        if (key.includes("cis") || key.includes("CIS") || key.includes("Cis")) {
          cis_Tags.push({
            name: "CIS",
            cis: key,
            CIS: clusterData?.data?.response?.Alerts?.Tags[key],
            count: clusterData?.data?.response?.Alerts?.Tags[key]
          });
          TagsData.push({ name: "CIS", count: clusterData?.data?.response?.Alerts?.Tags[key] });
        }
        if (key.includes("T1003")) {
          TagsData.push({ name: "T1003", count: clusterData?.data?.response?.Alerts?.Tags[key] });
        }
        if (key.includes("sysrv-hello")) {
          TagsData.push({
            name: "sysrv-hello",
            count: clusterData?.data?.response?.Alerts?.Tags[key]
          });
        }
        if (key.includes("malware")) {
          TagsData.push({ name: "malware", count: clusterData?.data?.response?.Alerts?.Tags[key] });
        }
        if (key.includes("crypto-mining")) {
          TagsData.push({
            name: "crypto-mining",
            count: clusterData?.data?.response?.Alerts?.Tags[key]
          });
        }
        if (key.includes("Botnet")) {
          TagsData.push({ name: "Botnet", count: clusterData?.data?.response?.Alerts?.Tags[key] });
        }
        if (key.includes("TeamTNT")) {
          TagsData.push({ name: "TeamTNT", count: clusterData?.data?.response?.Alerts?.Tags[key] });
        }
      });
      // const tagsFlat = _.flatten(TagsList);
      var holder = {};

      TagsData.forEach(function(d) {
        if (holder.hasOwnProperty(d.name)) {
          holder[d.name] = holder[d.name] + d.count;
        } else {
          holder[d.name] = d.count;
        }
      });

      var obj2 = [];

      for (var prop in holder) {
        obj2.push({ [prop]: holder[prop] });
      }

      setComplianceAlert({
        data: obj2
      });
      // const tagsFinal = _.uniq(tagsFlat);
      setNist(
        _.sumBy(nist_Tags, function(o) {
          return o.count;
        })
      );
      setMitreCount(
        _.sumBy(mitre_Tags, function(o) {
          return o.count;
        })
      );
      setCis(
        _.sumBy(cis_Tags, function(o) {
          return o.count;
        })
      );
      setPci(
        _.sumBy(pci_Tags, function(o) {
          return o.count;
        })
      );

      if (!nist_Tags.length > 0) {
        nist_Tags.push({
          name: "NIST",
          mitre: 0,
          count: 0
        });
      }
      if (!mitre_Tags.length > 0) {
        mitre_Tags.push({
          name: "MITRE",
          mitre: 0,
          count: 0
        });
      }
      if (!pci_Tags.length > 0) {
        pci_Tags.push({
          name: "PCI",
          mitre: 0,
          count: 0
        });
      }
      if (!cis_Tags.length > 0) {
        cis_Tags.push({
          name: "CIS",
          mitre: 0,
          count: 0
        });
      }

      // setComplianceAlert({
      //   data: [...nist_Tags, ...mitre_Tags, ...cis_Tags, ...pci_Tags]
      // });
      const policyData = CountDataConversion(clusterData?.data?.response?.Alerts?.Policies);
      const policyDataKeys = policyData.keys.map((e, i) => {
        return { name: e, color: COLORS[i] };
      });
      const OperationData = CountDataConversion(clusterData?.data?.response?.Alerts?.Operations);
      const OperationDataKeys = OperationData.keys.map((e, i) => {
        return { name: e, color: COLORS[i] };
      });

      setAlertPolicy(policyData);
      setalertPolicykeys(policyDataKeys);
      setAlertsOperation(OperationData);
      setAlertsOperationKeys(OperationDataKeys);
      /*Severity */
      let severity_alerts = [];
      let severity_list = [
        "Severity 1",
        "Severity 2",
        "Severity 3",
        "Severity 4",
        "Severity 5",
        "Severity 6",
        "Severity 7",
        "Severity 8",
        "Severity 9",
        "Severity 10"
      ];
      _.forEach(clusterData?.data?.response.Alerts?.Severity, function(value, key) {
        const severity_name = [];
        _.forEach(value, function(value, key) {
          severity_name.push("Severity " + key);
        });
        let arr2 = [];
        _.forEach(value, function(value, key) {
          arr2.push({ ["Severity " + key]: value });
        });
        const diff = _.difference(severity_list, severity_name);
        const arr = diff.map(e => {
          return { [e]: 0 };
        });
        // const final = diff?.map(e => {
        //   return { [e]: 0 };
        // });
        let obj = {};
        // Object.assign(obj, ...final);
        Object.assign(obj, ...arr2);
        const time = moment.unix(key).format("Do MMM YYYY");

        severity_alerts.push({
          name: time,
          ...value,
          ...obj
        });
      });
      setAlertsSeverity(severity_alerts);
    }
  }, [JSON.stringify(clusterData), selectedPod, singleSelectedCluster, singleSelectedNamespaces]);

  useEffect(() => {
    setNamespaceAudit(_.sumBy(NamespaceData?.data?.response?.Namespaces, "Alerts.Actions.Audit"));
    if (NamespaceData.isSuccess) {
      setNamespaceBlocked(
        _.sumBy(NamespaceData?.data?.response?.Namespaces, "Alerts.Actions.Blocked")
      );

      const namespace = NamespaceData?.data?.response?.Namespaces?.map(e => {
        const obj = {};
        if (e?.Alerts?.Severity !== null) {
          Object.keys(e?.Alerts?.Severity)?.forEach(key => {
            obj["Severity " + key] = e?.Alerts?.Severity[key];
          });
          return { name: e.NamespaceName, ...obj };
        }
        return null;
      });

      const namespacealerts = NamespaceData?.data?.response?.Namespaces?.map((e, i) => {
        return {
          name: e.NamespaceName,
          value: e.Alerts.Actions.Blocked + e.Alerts.Actions.Audit,
          color: COLORS[i],
          label: "Namespace"
        };
      });

      setNameSpaceAlerts(namespacealerts);

      setNamespaceSeverity(_.compact(namespace));
    }
  }, [JSON.stringify(NamespaceData), selectedPod, singleSelectedCluster, singleSelectedNamespaces]);

  useEffect(() => {
    if (PodsData.isSuccess) {
      setPodsAudit(_.sumBy(PodsData?.data?.response?.Pods, "Alerts.Audit"));
      setPodsBlocked(_.sumBy(PodsData?.data?.response?.Pods, "Alerts.Blocked"));

      const podalerts = PodsData?.data?.response?.Pods?.map((e, i) => {
        return {
          name: e.PodName,
          value: e.Alerts.Blocked + e.Alerts.Audit,
          color: COLORS[i],
          label: "Pod"
        };
      });
      setPodAlerts(podalerts);
    }
  }, [JSON.stringify(PodsData), selectedPod, singleSelectedCluster, singleSelectedNamespaces]);
  useInterval(
    () => {
      clusterData.refetch();
      PodsData.refetch();
      NamespaceData.refetch();
      ActionsCount.refetch();
    },
    typeof refreshTimeinsec === "number" ? refreshTimeinsec : null
  );
  function viewLogs(type, params, key) {
    const payload = params.payload;
    history.push({
      pathname: routes.alertsSummaryLogs,
      // search: `?filters=${new URLSearchParams(JSON.stringify({ ...payload }))}`,
      state: {
        data: payload,
        key: key,
        type: type,
        cluster: singleSelectedCluster,
        namespace: singleSelectedNamespaces
      }
    });
  }

  return (
    <>
      <div className="m-4 text-2xl font-semibold">CWPP Dashboard</div>
      <ContentContainer style={{ height: "90vh" }}>
        <div className="flex justify-between w-full p-4">
          <div className="flex w-1/2 gap-1">
            <Select
              value={singleSelectedCluster}
              onChange={v => handleFilter(v, "cluster")}
              placeholder="Cluster"
              // defaultValue={{ label: "K8s", value: "GKE" }}
              options={clusters?.options}
              containerClass={styles["action-group__filter-option"]}
              hideSelectedOptions={false}
              // isMulti
            />
            {singleSelectedCluster?.length > 0 && (
              <Select
                value={singleSelectedNamespaces}
                onChange={v => handleFilter(v, "namespace")}
                placeholder="Namespace"
                options={namespaces?.options}
                containerClass={styles["action-group__filter-option"]}
                hideSelectedOptions={false}
                isMulti
              />
            )}
            {/* {singleSelectedNamespaces?.length > 0 && (
            <Select
              value={selectedPod}
              onChange={v => handleFilter(v, "pod")}
              placeholder="select pod"
              options={pod?.options}
              containerClass={styles["action-group__filter-option"]}
              hideSelectedOptions={false}
              isMulti
            />
          )} */}
          </div>
          <div className="flex justify-end gap-1 mx-2 ">
            <div className="w-40">
              <RefreshInterval
                items={RefreshTime}
                setSelected={setRefreshTimeVal}
                defaultValue={{ label: refreshTimeVal, value: refreshTimeVal }}
                refreshIconClick={e => {
                  clusterData.refetch();
                  PodsData.refetch();
                  NamespaceData.refetch();
                  ActionsCount.refetch();
                }}
              />
            </div>
            <DateRangePicker
              // activateDatePicker={false}
              onChange={onChangeRange}
              items={timeRange}
            />
          </div>
        </div>
        <div className="flex w-full p-4">
          <DashboardContainer>
            <Heading>Alerts summary</Heading>
            <Container className="flex mx-2 shadow-lg">
              <Block1>
                <Tooltip title="Total Alerts Generated" placement="top" arrow>
                  <Title
                    className="cursor-pointer"
                    onClick={() => viewLogs("totalAlerts", {}, "")}
                    data-tip="Total Alerts Generated"
                  >
                    Total Alerts Generated
                  </Title>
                </Tooltip>
                <Count className="cursor-pointer" onClick={() => viewLogs("totalAlerts", {}, "")}>
                  {clusterData.isLoading ||
                  NamespaceData.isLoading ||
                  PodsData.isLoading ||
                  ActionsCount.isLoading ? (
                    <div className="m-2">
                      <Skeleton variant="rounded" width={40} height={25} />
                    </div>
                  ) : (
                    <>
                      {ActionsCount?.data?.response?.Alerts?.Audit +
                        ActionsCount?.data?.response?.Alerts?.Blocked +
                        ActionsCount?.data?.response?.Alerts?.["Audit (Block)"] || 0}
                    </>
                  )}
                </Count>
                <div className="text-[#0066FF] mx-2">Alerts</div>
              </Block1>
              <Block1>
                <Tooltip title="Total Alerts Generated" placement="top" arrow>
                  <Title
                    className="cursor-pointer"
                    onClick={() => viewLogs("totalBlockedAlerts", {}, "")}
                    data-tip="Total Alerts Generated"
                  >
                    Total Blocked Alerts
                  </Title>
                </Tooltip>
                {clusterData.isLoading || NamespaceData.isLoading || PodsData.isLoading ? (
                  <div className="m-2">
                    <Skeleton variant="rounded" width={40} height={25} />
                  </div>
                ) : (
                  <Count
                    className="cursor-pointer"
                    onClick={() => viewLogs("totalBlockedAlerts", {}, "")}
                  >
                    {ActionsCount?.data?.response?.Alerts?.Blocked || 0}
                  </Count>
                )}
                <Tooltip title="Blocked Alerts" placement="top" arrow>
                  <div data-tip="Blocked Alerts" className="text-[#F90000] mx-2 truncate">
                    Blocked Alerts
                  </div>
                </Tooltip>
              </Block1>
              <Block11>
                <Tooltip title="Total Audited Alerts" placement="top" arrow>
                  <Title
                    className="cursor-pointer"
                    onClick={() => viewLogs("totalAuditedAlerts", {}, "")}
                    data-tip="Total Audited Alerts"
                  >
                    Total Audited Alerts
                  </Title>
                </Tooltip>
                {clusterData.isLoading || NamespaceData.isLoading || PodsData.isLoading ? (
                  <div className="m-2">
                    <Skeleton variant="rounded" width={40} height={25} />
                  </div>
                ) : (
                  <Count
                    className="cursor-pointer"
                    onClick={() => viewLogs("totalAuditedAlerts", {}, "")}
                  >
                    {ActionsCount?.data?.response?.Alerts?.Audit +
                      ActionsCount?.data?.response?.Alerts?.["Audit (Block)"] || 0}
                  </Count>
                )}
                <div className="text-[#E0B829] mx-2 xl:w-[100%]">Audited Alerts</div>
              </Block11>
            </Container>
          </DashboardContainer>
          <DashboardContainer>
            <Heading>Compliance summary</Heading>
            <Container className="flex mx-2 shadow-lg">
              <Block2>
                <Title className="cursor-pointer" onClick={() => viewLogs("MITRE", {}, "")}>
                  MITRE
                </Title>
                {clusterData.isLoading ? (
                  <div className="m-2">
                    <Skeleton variant="rounded" width={40} height={25} />
                  </div>
                ) : (
                  <Count className="cursor-pointer" onClick={() => viewLogs("MITRE", {}, "")}>
                    {mitreCount || 0}
                  </Count>
                )}
                <div className="text-[#60B058] mx-2 ">MITRE</div>
              </Block2>
              <Block2>
                <Title className="cursor-pointer" onClick={() => viewLogs("NIST", {}, "")}>
                  NIST
                </Title>
                {clusterData.isLoading ? (
                  <div className="m-2">
                    <Skeleton variant="rounded" width={40} height={25} />
                  </div>
                ) : (
                  <Count className="cursor-pointer" onClick={() => viewLogs("NIST", {}, "")}>
                    {nist || 0}
                  </Count>
                )}
                <div className="text-[#6923FF] mx-2">NIST</div>
              </Block2>
              <Block2>
                <Title className="cursor-pointer" onClick={() => viewLogs("CIS", {}, "")}>
                  CIS
                </Title>
                {clusterData.isLoading ? (
                  <div className="m-2">
                    <Skeleton variant="rounded" width={40} height={25} />
                  </div>
                ) : (
                  <Count className="cursor-pointer" onClick={() => viewLogs("CIS", {}, "")}>
                    {cis || 0}
                  </Count>
                )}
                <div className="text-[#1EBA5C] mx-2">CIS</div>
              </Block2>
              <Block21>
                <Title className="cursor-pointer" onClick={() => viewLogs("PCI", {}, "")}>
                  PCI-DSS
                </Title>
                {clusterData.isLoading ? (
                  <div className="m-2">
                    <Skeleton variant="rounded" width={40} height={25} />
                  </div>
                ) : (
                  <Count className="cursor-pointer" onClick={() => viewLogs("PCI", {}, "")}>
                    {pci || 0}
                  </Count>
                )}
                <div className="text-[#8E8E8E] mx-2">PCI-DSS</div>
              </Block21>
            </Container>
          </DashboardContainer>
        </div>
        <div className="flex flex-col p-4 ">
          <ChartContainer>
            <ChartTitle>Compliance Alerts</ChartTitle>
            <div className="w-full mt-2 h-60">
              {false ? (
                <div className="flex items-center justify-center w-full h-full">
                  <Loader />
                </div>
              ) : (
                <Barchart
                  height={"14rem"}
                  data={complianceAlerts.data}
                  dataKey={tagKeys}
                  onClick={data => {
                    viewLogs("tags", data, "Tags");
                  }}
                />
              )}
            </div>
          </ChartContainer>
          <div className="flex w-full gap-2">
            <ChartContainer2>
              <ChartTitle>Namespace Severity Summary</ChartTitle>
              <div className="mt-2 h-80">
                {NamespaceData?.isLoading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <Loader />
                  </div>
                ) : (
                  <StackedBarchart
                    data={namespaceSeverity}
                    dataKey={Severity}
                    onClick={data => {
                      viewLogs("namespace", data, "NamespaceName");
                    }}
                  />
                )}
              </div>
            </ChartContainer2>
            <ChartContainer2>
              <ChartTitle>Top 10 Policies by Alert Count </ChartTitle>
              <div className="mt-2 h-80">
                {clusterData?.isLoading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <Loader />
                  </div>
                ) : (
                  <StackedBarchart
                    data={alertPolicy.data.data}
                    dataKey={alertPolicykeys}
                    onClick={data => {
                      viewLogs("policy", data, "PolicyName");
                    }}
                    toolTipStyle={toolTipWrapper}
                  />
                )}
              </div>
            </ChartContainer2>
          </div>
          <div className="flex w-full gap-2">
            <ChartContainer2>
              <ChartTitle>Namespace Alerts</ChartTitle>
              <div className="overflow-x-hidden overflow-y-scroll h-80">
                {NamespaceData.isLoading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <Loader />
                  </div>
                ) : (
                  <Piechart
                    content={<CustomTooltip label={"Namespace"} />}
                    data={nameSpaceAlerts}
                    onClick={data => {
                      viewLogs("namespace", data, "NamespaceName");
                    }}
                  />
                )}
              </div>
            </ChartContainer2>
            <ChartContainer2>
              <ChartTitle>Pod Alerts</ChartTitle>
              <div className="overflow-x-hidden overflow-y-scroll h-80">
                {PodsData?.isLoading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <Loader />
                  </div>
                ) : (
                  <Piechart
                    content={<CustomTooltip />}
                    data={podAlerts}
                    onClick={data => {
                      viewLogs("pod", data, "PodName");
                    }}
                  />
                )}
              </div>
            </ChartContainer2>
          </div>
          <div className="flex w-full gap-2">
            <ChartContainer2>
              <ChartTitle>Alerts based Operations</ChartTitle>
              <div className="h-60">
                {clusterData?.isLoading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <Loader />
                  </div>
                ) : (
                  <Barchart
                    data={alertsOperation.data.data}
                    dataKey={alertsOperationKeys}
                    onClick={data => {
                      viewLogs("operation", data, "Operation");
                    }}
                  />
                )}
              </div>
            </ChartContainer2>
            <ChartContainer2>
              <ChartTitle>Alerts based on Severity</ChartTitle>
              <div className="h-60 mb-[10px]">
                {clusterData?.isLoading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <Loader />
                  </div>
                ) : (
                  <StackedBarchart
                    data={alertsSeverity}
                    dataKey={Severity}
                    onClick={data => {
                      viewLogs("severity", data, "Severity");
                    }}
                  />
                  // <Linechart data={alertsSeverity} dataKey={Severity} />
                )}
              </div>
            </ChartContainer2>
          </div>
        </div>
      </ContentContainer>
    </>
  );
}

export const Block1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  width: 30%;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #babdb1;
`;
export const Block11 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  width: 30%;
`;
export const Block2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  width: 30%;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #babdb1;
`;
export const Block21 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  width: 30%;
`;
export const Title = styled.div`
  margin: 0px 10px 0px 10px;
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const ChartTitle = styled.div`
  margin: 0px 10px 10px 10px;
  font-size: 18px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Heading = styled.div`
  margin: 0px 10px 10px 20px;
  font-size: 25px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Count = styled.div`
  margin: 0px 10px 0px 10px;
  font-size: 50px;
  font-weight: 500;
  @media screen and (max-width: 1300px) {
    font-size: 40px;
  }
`;

export const Alerts = styled.div`
  margin: 0px 10px 0px 10px;
  color: #3491e2;
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const Container = styled.div`
  display: flex;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 0px 10px 0px 10px;
`;

// export const Title = styled.div`
//   margin: 0px 10px 10px 10px;
//   font-weight: 800;
//   font-size: 20px;
// `;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 10px;
  padding: 10px;
`;
export const ChartContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 10px;
  padding: 10px;
`;

export default CwppDashboard;
