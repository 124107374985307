import React from "react";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import { Box } from "@mui/material";
import styled from "styled-components";

const generateKey = index => `${index}_${new Date().getTime()}`;

const SelectedTags = ({
  isEditing,
  setIsEditing = () => {},
  edit,
  setEdit = () => {},
  list,
  closeTag,
  closable = true,
  getSelected,
  idSelected
}) => (
  <>
    {list?.map((prop, index) => {
      const GetEditIndex = (prop, index) => {
        prop.edit = true;
        setIsEditing(true);
        setEdit(prop);
      };

      const key = generateKey(index);
      const handleEnter = e => {
        if (e.key === "Enter") {
          list[index].edit = false;
          setIsEditing(false);
          setEdit(...list);
        }
      };

      const handleChange = (e, index, prop) => {
        let temp = list[index];
        temp.text = e.target.value;
      };
      return (
        <Box
          key={index}
          onClick={e => {
            e.stopPropagation();
            GetEditIndex(prop, index);
            getSelected(prop, index, e);
          }}
          aria-describedby={idSelected}
          data-testid="handleTag"
          className=" text-white float-left inline-block rounded-full mx-1 px-2 py-1 relative"
          style={{ background: "#0288d1", marginTop: "2px" }}
          // onDoubleClick={GetEditIndex(prop, index)}
        >
          <InputBox
            className="float-left inline-block px-1"
            style={{
              width: "100%",
              paddingRight: "26px",
              fontSize: "13px",
              overflowWrap: "anywhere"
            }}
          >
            {isEditing && prop.edit === true ? (
              <>
                <input
                  autoFocus
                  onChange={e => handleChange(e, index, prop)}
                  onKeyDown={e => handleEnter(e, index)}
                  style={{ background: "#0288d1", color: "#fff" }}
                  defaultValue={prop?.text}
                />
              </>
            ) : (
              <>{prop?.text}</>
            )}
          </InputBox>
          {closable && (
            <CancelSharpIcon
              fontSize="small"
              className="float-right inline-block ml-2 cursor-pointer"
              style={{ position: "absolute", top: "2px", right: "8px", color: "#ffffffb3" }}
              onClick={e => {
                e.stopPropagation();
                closeTag(e, index);
              }}
            />
          )}
        </Box>
      );
    })}
  </>
);

export default SelectedTags;

export const InputBox = styled.div`
  input:focus {
    outline: none !important;
    border: 0px !important;
  }
`;
