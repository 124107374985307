import React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "components/simple/Tabs/Tabs";

const BasicColorTabs = ({ tabs, handleChange, value, activeTab }) => {
  const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    fontWeight: 400,
    fontSize: 12,
    padding: 0,
    minWidth: 60,
    minHeight: 10,
    "&.Mui-selected": {
      color: `${activeTab}`
    },
    "&.Mui-focusVisible": {
      backgroundColor: "white"
    }
  }));
  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: `${activeTab}`,
            color: "#D97D54"
          }
        }}
        textColor="secondary"
        indicatorColor="secondary"
        sx={{ display: "flex", minHeight: 20, justifyContent: "space-between" }}
      >
        {tabs?.map(item => (
          <StyledTab value={item?.id} label={item?.label} />
        ))}
      </Tabs>
      {tabs?.map((tab, index) => (
        <TabPanel key={tab?.id} value={value} index={index}>
          <Box className="pl-3 mt-3">{tab.content}</Box>
        </TabPanel>
      ))}
    </Box>
  );
};

export default BasicColorTabs;
