import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  updatePageIdReducer,
  updateRowsPerpageReducer,
  updateSelectedFilterReducer,
  updateSelectedOperatorReducer,
  updatedSelectedKeyTypeReducer
} from "./reducers";
export const DATAPIPELINE = "datapipeline";
export const DATAPIPELINE_INITIAL_STATE = {
  pageId: 1,
  pageSize: 10,
  selectedFilter: "",
  selectedOperator: "",
  selectedKeyType: ""
};

const datapipelineSlice = createSlice({
  name: DATAPIPELINE,
  initialState: DATAPIPELINE_INITIAL_STATE,
  reducers: {
    updatePageId: updatePageIdReducer,
    updatePageSize: updateRowsPerpageReducer,
    updateSelectedFilter: updateSelectedFilterReducer,
    updateSelectedOperator: updateSelectedOperatorReducer,
    updatedSelectedKeyType: updatedSelectedKeyTypeReducer
  }
});

export const useDatapipelineState = key => {
  const value = useSelector(state => state[DATAPIPELINE][key]);
  return value;
};

export const {
  updatePageId,
  updatePageSize,
  updateSelectedFilter,
  updateSelectedOperator,
  updatedSelectedKeyType
} = datapipelineSlice.actions;

export default datapipelineSlice.reducer;
