/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, remove } from "api/base";

export const getLabels = fields => {
  const { page, search, pageSize, ordering } = fields;
  let url = "";

  if (search) {
    url += `&search=${search}`;
  }
  if (ordering) {
    url += `&ordering=${ordering}`;
  }

  return get(`${API}/labels?page=${page + 1 || 1}&page_size=${pageSize}${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getLabelsList = () => {
  return get(`${API}/labels-mini`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getLabelsMini = () => {
  return get(`${API}/labels-mini`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const createLabel = fields => {
  return post(`${API}/labels`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteLabel = id => {
  return remove(
    `${API}/labels/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};
