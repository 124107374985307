import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Title, Modal, Input } from "components/simple";
import { addGroup } from "store/entities/datalist/actions";

import styles from "./styles.module.scss";

const NewGroup = ({ open, onBack = () => null, close, data }) => {
  const [name, setName] = useState("");
  const dispatch = useDispatch();

  const onSave = () => {
    dispatch(
      addGroup({
        name,
        ids: data
      })
    );
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Create a new group</Title>
      <div className={styles.content}>
        <div className={styles.form}>
          <Input
            type="text"
            required
            placeholder="Name"
            label="Name"
            value={name}
            onChange={val => setName(val.target.value)}
            className={styles.input}
            containerClassName={styles.inputContainer}
          />
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={onBack} variant="outline" className={styles.modalButton}>
          Back
        </Button>
        <Button onClick={onSave} className={styles.modalButton} disabled={!name?.length}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default NewGroup;
