import React, { useMemo } from "react";

import { Divider } from "@mui/material";

import styles from "../styles.module.scss";

export const DetailModalInfo = ({ data, type }) => {
  const sonarqubeType = type === "sonarqube";
  const fortifyType = type === "fortify";

  const lineOrPortJSX = useMemo(
    () =>
      !sonarqubeType ? (
        <p className={styles["tls-modal-details__info-asset"]}>
          Port:
          <span>{data?.location}</span>
        </p>
      ) : (
        <p className={styles["tls-modal-details__info-asset"]}>
          Line:
          <span>{data?.misc?.line}</span>
        </p>
      ),
    [sonarqubeType, data]
  );

  const fortifyInfoJSX = useMemo(
    () =>
      fortifyType && (
        <>
          <p className={styles["tls-modal-details__info-asset"]}>
            Filename:
            <span>{data?.misc?.file_name}</span>
          </p>
          <p className={styles["tls-modal-details__info-asset"]}>
            Line:
            <span>{data?.misc?.line_start}</span>
          </p>
          <p className={styles["tls-modal-details__info-asset"]}>
            Location:
            <span>{data?.location}</span>
          </p>
        </>
      ),
    [fortifyType, data]
  );

  return (
    <div className={styles["tls-modal-details__info"]}>
      <div className={styles["tls-modal-details__info-wrapper"]}>
        <p className={styles["tls-modal-details__info-asset"]}>
          Asset:
          <span>{data?.asset?.title}</span>
        </p>
        <p className={styles["tls-modal-details__info-asset"]}>
          Asset Type:
          <span>{data?.asset?.asset_type}</span>
        </p>
      </div>
      <Divider className={styles["tls-modal-divider-md"]} />
      <div className={styles["tls-modal-details__info-wrapper"]}>
        {sonarqubeType && (
          <p className={styles["tls-modal-details__info-asset"]}>
            Filename:
            <span>{data?.misc?.filename}</span>
          </p>
        )}
        {fortifyType ? fortifyInfoJSX : lineOrPortJSX}
        {sonarqubeType && (
          <p className={styles["tls-modal-details__info-asset"]}>
            Hotspot:
            <span>{data?.misc?.from_hotspot?.toString()}</span>
          </p>
        )}
        {data?.vulnerability?.misc?.tags?.length > 0 && (
          <p className={styles["tls-modal-details__info-asset-category"]}>
            Categories:{" "}
            {data?.vulnerability?.misc?.tags?.map(tag => (
              <span key={tag} className={styles["tls-modal-details__info-asset-category__tag"]}>
                {tag}
              </span>
            ))}
          </p>
        )}
      </div>
    </div>
  );
};
