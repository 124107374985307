import React from "react";
import { Icon } from "components/simple";

import renderCellExpand from "helper/renderCellExpand";

export const historyTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Scan",
    field: "scan",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.row?.scan?.scan_file}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Asset",
    field: "asset",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.row?.asset?.title}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Groups",
    field: "groups",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.row?.groups?.map(item => item?.name).join(", ")}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const detailsHistoryColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Version",
    field: "version",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Strength",
    field: "strength",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Encryption",
    field: "encryption",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const assignAssetsLeftTableColumns = [
  {
    headerName: "ID",
    field: "id",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Host name",
    field: "title",
    resizable: true,
    minWidth: 340,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const assignAssetsRightTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Host Page",
    field: "title",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "",
    field: "delete",
    resizable: true,
    minWidth: 40,
    flex: 0.5,
    renderCell: () => <Icon.Delete color="#2b3858" style={{ cursor: "pointer" }} />,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const assignGroupsLeftTableColumns = [
  {
    headerName: "ID",
    field: "id",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Host name",
    field: "name",
    resizable: true,
    minWidth: 340,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const assignGroupsRightTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Host Page",
    field: "name",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "",
    field: "delete",
    resizable: true,
    minWidth: 40,
    flex: 0.5,
    renderCell: () => <Icon.Delete color="#2b3858" style={{ cursor: "pointer" }} />,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];
