/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { postTicketComment, getTicketComments } from "store/entities/tickets/actions";

import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Button } from "components/simple";
import AddCommentModal from "./AddCommentModal";

import styles from "./styles.module.scss";

const Comments = ({ id, comments }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [state, setState] = useState("");
  const selectTenant = useSelector(s => s.users.selectTenant);
  const isLoading = useSelector(s => createLoadingSelector([postTicketComment.type])(s));
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getTicketComments(id));
    }
  }, [selectTenant, id]);

  const successSaveCallback = () => {
    setModalOpen(false);
  };

  const submitHandler = () => {
    if (state) {
      dispatch(
        postTicketComment({
          data: { ticket_id: id, comment: state },
          callback: successSaveCallback
        })
      );
    }
  };

  const closeModalHandler = () => {
    setModalOpen(false);
    setState("");
  };

  return (
    <ContentContainer className={styles.container}>
      <div className={styles.addBtnContainer}>
        <Button icon="plus" className={styles.btn} onClick={() => setModalOpen(true)}>
          Add comment
        </Button>
      </div>
      {comments?.length > 0 ? (
        comments.map(({ data, comment, ...rest }) => (
          <div key={rest.id} className={styles.tile}>
            <div>{data}</div>
            <div>{comment}</div>
          </div>
        ))
      ) : (
        <div className={styles.noData}>No data</div>
      )}
      {isModalOpen && (
        <AddCommentModal
          onChange={setState}
          value={state}
          onCloseModal={closeModalHandler}
          isAddCommentLoading={isLoading}
          onSubmit={submitHandler}
          isModalOpen={isModalOpen}
        />
      )}
    </ContentContainer>
  );
};

Comments.propTypes = {};

export default Comments;
