import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import {
  DEFAULT,
  NODE,
  LABEL_TYPE_OPTIONS,
  METADATA,
  K8S,
  WORKSPACE_ID,
  VM
} from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import DataObjectIcon from "@mui/icons-material/DataObject";
import EditIcon from "@mui/icons-material/Edit";
import { transformPolicyName } from "store/entities/policyEditor/kubeArmorPolicyEditor/helpers";
import { Button, CreatableSelect, Input, Select, SelectAsync } from "components/simple";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  useKubeArmorPolicyEditorState,
  handlePolicyMetadataFormSubmit,
  updateActiveCard,
  updateIsEditingPolicyMetadata,
  updateSelectedCluster,
  updateSelectedInstance,
  updateSelectedInstanceGroup,
  updateSelectedNamespace
} from "store/entities/policyEditor/kubeArmorPolicyEditor/slice";
import { useKubeArmorPolicyContext } from "../..";
import {
  useClustersQuery,
  useInstanceGroupLoadOptions,
  useInstanceLoadOptions,
  useLabelsLoadOptions,
  useNamespaceLoadOptions,
  useTagsQuery
} from "store/entities/policyEditor/kubeArmorPolicyEditor/queries";
import { useStoreTagsMutation } from "store/entities/policyEditor/kubeArmorPolicyEditor/mutations";
import { truncate } from "lodash";
import useEffectAfterMount from "helper/hooks/useEffectAfterMount";
import { useLocation } from "react-router-dom";

const PolicyMetadataCard = () => {
  const dispatch = useDispatch();
  const policyJSON = useKubeArmorPolicyEditorState("policyJSON");
  const selectedEntity = useKubeArmorPolicyEditorState("selectedEntity");
  const selectedCluster = useKubeArmorPolicyEditorState("selectedCluster");
  const selectedNamespace = useKubeArmorPolicyEditorState("selectedNamespace");
  const selectedInstanceGroup = useKubeArmorPolicyEditorState("selectedInstanceGroup");
  const selectedInstance = useKubeArmorPolicyEditorState("selectedInstance");
  const activeCard = useKubeArmorPolicyEditorState("activeCard");
  const isEditingPolicyMetadata = useKubeArmorPolicyEditorState("isEditingPolicyMetadata");

  const clustersDataQuery = useClustersQuery();
  const namespaceLoadOptions = useNamespaceLoadOptions();
  const instanceGroupLoadOptions = useInstanceGroupLoadOptions();
  const instanceLoadOptions = useInstanceLoadOptions();
  const labelsLoadOptions = useLabelsLoadOptions();
  const tagsDataQuery = useTagsQuery();
  const storeTagsMutation = useStoreTagsMutation();
  const { policyMetadataForm } = useKubeArmorPolicyContext();

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    watch,
    trigger: triggerValidation,
    reset: resetPolicyMetadataForm
  } = policyMetadataForm;

  const labelType = watch("labelType");

  useEffectAfterMount(() => {
    triggerValidation();

    if (selectedEntity === K8S) {
      dispatch(updateSelectedCluster(null));
      dispatch(updateSelectedNamespace(null));
      setValue("cluster", "");
      setValue("policyNamespace", "");
      setValue("labelType", NODE);
      setFocus("instanceGroup");
    } else if (selectedEntity === VM) {
      dispatch(updateSelectedInstanceGroup(null));
      setValue("instanceGroup", "");
      setValue("instance", "");
      setFocus("cluster");
    }
  }, [selectedEntity]);

  const onChangePolicyName = (e, onChange) => {
    const value = transformPolicyName(e.target.value);
    onChange(value);
  };

  const onFormSubmit = formValues => {
    dispatch(handlePolicyMetadataFormSubmit(formValues));
  };

  const handleEditPolicy = () => {
    dispatch(updateIsEditingPolicyMetadata(true));
    triggerValidation();
  };

  const handleCreateNewTagOption = async value => {
    const payload = {
      name: [value],
      workspace_ID: WORKSPACE_ID
    };
    storeTagsMutation.mutate(payload, {
      onSuccess: data => {
        const newOption =
          data?.tag?.map(tag => ({
            label: tag.name,
            value: tag.name,
            id: tag.id
          })) || [];
        const prevOptions = getValues("policyTags");
        setValue("policyTags", [...prevOptions, ...newOption]);
        tagsDataQuery.refetch();
      }
    });
  };

  const formatCreateNamespaceLabel = value => <p>Create new namespace {value && `'${value}'`}</p>;
  const formatCreateTagLabel = value => <p>Create new tag {value && `'${value}'`}</p>;

  const handleCreateNewNamespaceOption = (value, onChange) => {
    const option = {
      label: value,
      value
    };
    dispatch(updateSelectedNamespace(option));
    onChange(option);
    return option;
  };

  const handleLabelTypeChange = (e, onChange) => {
    const value = e.target.value;
    dispatch(updateSelectedNamespace(null));
    setValue("policyNamespace", "");
    onChange(value);
  };

  return (
    <CardContainer
      $isActive={activeCard === METADATA}
      onFocus={() => dispatch(updateActiveCard(METADATA))}
    >
      {isEditingPolicyMetadata ? (
        <form onSubmit={handleSubmit(onFormSubmit)} className="grid gap-2">
          <div>
            <Controller
              name="policyName"
              rules={{
                required: "Policy Name should be filled",
                pattern: {
                  value: /^[a-z0-9-]+$/,
                  message:
                    "Can have only lowercase alphabets, numerals, no special character except hyphen and no spaces. Can not start and end with hyphen."
                }
              }}
              control={control}
              ref={null}
              render={({ field: { onChange, value, ref } }) => (
                <Input
                  label="Policy Name"
                  value={value}
                  onChange={e => onChangePolicyName(e, onChange)}
                  placeholder="Enter Policy Name"
                  autoFocus
                  ref={ref}
                  invalid={errors?.policyName?.message}
                  className="panzoom-exclude"
                  required
                />
              )}
            />
          </div>
          {selectedEntity === K8S ? (
            <>
              <div>
                <Controller
                  name="cluster"
                  rules={{
                    required: "Cluster should be selected"
                  }}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      key={`policy-editor-tool-${selectedCluster?.id}`}
                      label="Cluster"
                      options={clustersDataQuery?.data}
                      value={value}
                      onChange={selectedOption => {
                        onChange(selectedOption);
                        dispatch(updateSelectedCluster(selectedOption));
                        dispatch(updateSelectedNamespace(null));
                        setValue("policyNamespace", "");
                      }}
                      placeholder="Select Cluster"
                      noOptionsMessage={() => "No clusters found"}
                      isSearchable={false}
                      className="panzoom-exclude"
                      classNamePrefix="panzoom-react-select"
                      error={errors?.cluster?.message}
                      required
                    />
                  )}
                />
              </div>

              {labelType === "Default" && (
                <div>
                  <Controller
                    name="policyNamespace"
                    rules={{
                      required: labelType !== NODE && "Namespace should be selected"
                    }}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CreatableSelect
                        key={`policy-editor-tool-${selectedCluster?.id}`}
                        asyncPaginate
                        label="Namespace"
                        value={value}
                        onChange={selectedOption => {
                          onChange(selectedOption);
                          dispatch(updateSelectedNamespace(selectedOption));
                        }}
                        debounceTimeout={500}
                        loadOptions={namespaceLoadOptions}
                        additional={{
                          workspaceId: WORKSPACE_ID,
                          clusterId: selectedCluster?.id
                        }}
                        onCreateOption={v => handleCreateNewNamespaceOption(v, onChange)}
                        formatCreateLabel={formatCreateNamespaceLabel}
                        placeholder="Select Namespace"
                        noOptionsMessage={() => "No namespaces found"}
                        isDisabled={!selectedCluster?.id || labelType === NODE}
                        onBlurResetsInput={false}
                        className="panzoom-exclude"
                        classNamePrefix="panzoom-react-select"
                        error={errors?.policyNamespace?.message}
                        required={labelType !== NODE}
                      />
                    )}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div>
                <Controller
                  name="instanceGroup"
                  rules={{
                    required: "Instance Group should be selected"
                  }}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <SelectAsync
                      key={`policy-editor-tool-${selectedCluster}`}
                      label="Instance Group"
                      value={value}
                      onChange={selectedOption => {
                        onChange(selectedOption);
                        dispatch(updateSelectedInstanceGroup(selectedOption));
                      }}
                      debounceTimeout={500}
                      loadOptions={instanceGroupLoadOptions}
                      additional={{
                        workspaceId: WORKSPACE_ID,
                        page: 0
                      }}
                      placeholder="Select Instance Group"
                      noOptionsMessage={() => "No instance group found"}
                      isSearchable={false}
                      className="panzoom-exclude"
                      classNamePrefix="panzoom-react-select"
                      error={errors?.instanceGroup?.message}
                      required
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="instance"
                  rules={{
                    required: "Instance should be selected"
                  }}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <SelectAsync
                      label="Instance"
                      key={`policy-editor-tool-${selectedInstanceGroup?.id}`}
                      value={value}
                      onChange={selectedOption => {
                        onChange(selectedOption);
                        dispatch(updateSelectedInstance(selectedOption));
                      }}
                      debounceTimeout={500}
                      loadOptions={instanceLoadOptions}
                      additional={{
                        workspaceId: WORKSPACE_ID,
                        instanceGroupId: selectedInstanceGroup?.id,
                        page: 0
                      }}
                      placeholder="Select Instance"
                      noOptionsMessage={() => "No instances found"}
                      isSearchable={false}
                      isDisabled={!selectedInstanceGroup?.id}
                      className="panzoom-exclude"
                      classNamePrefix="panzoom-react-select"
                      error={errors?.instance?.message}
                      required
                    />
                  )}
                />
              </div>
            </>
          )}

          <div>
            <Controller
              name="labelType"
              control={control}
              render={({ field: { value, onChange, name } }) => (
                <RadioGroup
                  aria-labelledby={name}
                  name={name}
                  defaultValue={LABEL_TYPE_OPTIONS[0].value}
                  value={value}
                  onChange={e => handleLabelTypeChange(e, onChange)}
                  row
                >
                  {LABEL_TYPE_OPTIONS.map((label, index) => (
                    <FormControlLabel
                      key={index}
                      control={<Radio />}
                      label={label.label}
                      value={label.value}
                      disabled={selectedEntity === VM && label.value === DEFAULT}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </div>

          <div>
            <Controller
              name="selectorLabel"
              rules={{
                required: "Selector labels should be selected"
              }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectAsync
                  label="Selector Labels"
                  key={`policy-editor-tool-${selectedNamespace?.id}-${labelType}`}
                  value={value}
                  onChange={onChange}
                  debounceTimeout={500}
                  loadOptions={labelsLoadOptions}
                  additional={{
                    workspaceId: WORKSPACE_ID,
                    clusterId: selectedCluster?.id,
                    namespaceId: selectedNamespace?.id,
                    labelType,
                    page: 0
                  }}
                  placeholder="Select Labels"
                  noOptionsMessage={() => "No selector labels found"}
                  isMulti
                  isSearchable
                  className="panzoom-exclude"
                  classNamePrefix="panzoom-react-select"
                  error={errors?.selectorLabel?.message}
                  required
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="policyTags"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CreatableSelect
                  label="Tags"
                  options={tagsDataQuery?.data}
                  value={value}
                  onChange={onChange}
                  isMulti
                  isSearchable
                  placeholder="Select Tags"
                  onCreateOption={v => handleCreateNewTagOption(v)}
                  noOptionsMessage={() => "No tags found"}
                  className="panzoom-exclude"
                  classNamePrefix="panzoom-react-select"
                  formatCreateLabel={formatCreateTagLabel}
                  errors={errors?.policyTags?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="policyMessage"
              control={control}
              ref={null}
              render={({ field: { onChange, value } }) => (
                <Input
                  label="Message"
                  value={value}
                  onChange={onChange}
                  placeholder="Enter Policy message"
                  className="panzoom-exclude"
                  invalid={errors?.policyMessage?.message}
                />
              )}
            />
          </div>
          {/* Save Button */}
          <div className="flex flex-row-reverse justify-between mt-1">
            <Button
              type="submit"
              onClick={handleSubmit(onFormSubmit)}
              disabled={!isValid}
              className="panzoom-exclude"
            >
              Save
            </Button>
          </div>
        </form>
      ) : (
        <div className="grid gap-3 text-gray-500">
          <div className="flex justify-between items-center">
            <div className="flex justify-center items-center gap-2">
              <DataObjectIcon color="primary" sx={{ fontSize: "22px" }} />
              <Typography fontWeight={600}>
                {truncate(policyJSON?.metadata?.name, { length: 28 })}
              </Typography>
            </div>
            <EditIcon
              color="primary"
              sx={{ cursor: "pointer", fontSize: "18px" }}
              onClick={handleEditPolicy}
            />
          </div>

          {policyJSON?.metadata?.namespace ? (
            <Typography fontSize={14} fontWeight={600}>
              {truncate(policyJSON?.metadata?.namespace, { length: 36 })}
            </Typography>
          ) : null}

          {selectedCluster?.label || selectedCluster?.name ? (
            <div className="grid grid-cols-3 items-center">
              <Typography fontSize={14} fontWeight={600}>
                Cluster:
              </Typography>
              <span className="col-span-2">
                <Typography fontSize={14}>
                  {truncate(selectedCluster?.label || selectedCluster?.name, { length: 26 })}
                </Typography>
              </span>
            </div>
          ) : null}

          {policyJSON?.spec?.selector?.matchLabels ||
          policyJSON?.spec?.nodeSelector?.matchLabels ? (
            <div className="grid grid-cols-3 items-center">
              <Typography fontSize={14} fontWeight={600}>
                Labels:
              </Typography>
              <span className="flex items-center flex-wrap gap-2 col-span-2 h-auto">
                {Object.entries(
                  policyJSON?.spec?.selector?.matchLabels ||
                    policyJSON?.spec?.nodeSelector?.matchLabels
                )?.map(([key, value], index) => (
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    key={index}
                    className="rounded-full px-2 py-1 text-blue-700 bg-blue-100"
                  >
                    {truncate(`${key}=${value}`, {
                      // eslint-disable-next-line no-restricted-globals
                      length: screen.width < 1920 ? 26 : 28
                    })}
                  </Typography>
                ))}
              </span>
            </div>
          ) : null}

          {policyJSON?.spec?.tags ? (
            <div className="grid grid-cols-3 items-center">
              <Typography fontSize={14} fontWeight={600}>
                Tags:
              </Typography>
              <span className="flex items-center flex-wrap gap-2 col-span-2 h-auto">
                {policyJSON?.spec?.tags?.map((tag, index) => (
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    key={index}
                    className="rounded-full px-2 py-1 text-gray-700 bg-gray-100"
                  >
                    {truncate(tag, { length: 26 })}
                  </Typography>
                ))}
              </span>
            </div>
          ) : null}

          {policyJSON?.spec?.message ? (
            <div className="grid grid-cols-3 items-center">
              <Typography fontSize={14} fontWeight={600}>
                Message:
              </Typography>
              <span className="col-span-2">
                <Typography fontSize={14}>
                  {truncate(policyJSON?.spec?.message, { length: 26 })}
                </Typography>
              </span>
            </div>
          ) : null}
        </div>
      )}
    </CardContainer>
  );
};

export const CardContainer = styled.div`
  width: 320px;
  height: fit-content;
  padding: 8px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: ${({ $isActive }) =>
    $isActive ? "0px 0px 4px 0px #00000040" : "0px 4px 4px rgba(0, 0, 0, 0.25)"};
  border: ${({ $isActive }) => ($isActive ? "2px solid #253664" : "2px solid #EEF3F4")};
`;

export default PolicyMetadataCard;
