import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clusters: [],
  namespaces: [],
  pod: []
};

const filtersSlice = createSlice({
  name: "singlefilter",
  initialState,
  reducers: {
    setSingleClusters(state, action) {
      state.clusters = action.payload;
    },
    setSingleNamespace(state, action) {
      state.namespaces = action.payload;
    },
    setSinglePod(state, action) {
      state.pod = action.payload;
    }
  }
});

export const getSingleClusters = state => state.singlefilter.clusters || [];
export const getSingleNamespaces = state => state.singlefilter.namespaces || [];
export const getSinglePod = state => state.singlefilter.pod || [];
export const { setSingleClusters, setSingleNamespace, setSinglePod } = filtersSlice.actions;
export default filtersSlice.reducer;
