import React from "react";
import PropTypes from "prop-types";

export default function QuestionMark(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="6.5" fill="#F2F5FA" stroke={props.color} />
      <path
        d="M6.40897 8.64093C6.40897 8.32152 6.47793 8.02389 6.61586 7.74804C6.75379 7.47219 6.92075 7.23626 7.11675 7.04026C7.31275 6.84426 7.50875 6.65915 7.70475 6.48493C7.90075 6.30345 8.06771 6.10745 8.20564 5.89693C8.34356 5.67915 8.41253 5.45049 8.41253 5.21093C8.41253 4.84797 8.28549 4.57212 8.03141 4.38338C7.7846 4.18738 7.46156 4.08938 7.0623 4.08938C6.26379 4.08938 5.75201 4.44145 5.52697 5.1456L4.66675 4.66649C4.84823 4.15834 5.15675 3.7736 5.5923 3.51226C6.02786 3.24367 6.52149 3.10938 7.07319 3.10938C7.71927 3.10938 8.27097 3.29086 8.7283 3.65382C9.1929 4.01678 9.42519 4.5213 9.42519 5.16738C9.42519 5.47226 9.35623 5.759 9.2183 6.0276C9.08038 6.29619 8.91341 6.52849 8.71741 6.72449C8.52141 6.92049 8.32541 7.11286 8.12941 7.3016C7.93341 7.48308 7.76645 7.68997 7.62853 7.92226C7.4906 8.1473 7.42164 8.38686 7.42164 8.64093H6.40897ZM6.90986 10.9929C6.71386 10.9929 6.5469 10.924 6.40897 10.786C6.27104 10.6481 6.20208 10.4812 6.20208 10.2852C6.20208 10.0892 6.27104 9.92219 6.40897 9.78426C6.5469 9.64634 6.71386 9.57737 6.90986 9.57737C7.11312 9.57737 7.28008 9.64634 7.41075 9.78426C7.54867 9.92219 7.61764 10.0892 7.61764 10.2852C7.61764 10.4812 7.54867 10.6481 7.41075 10.786C7.28008 10.924 7.11312 10.9929 6.90986 10.9929Z"
        fill={props.color}
      />
    </svg>
  );
}

QuestionMark.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

QuestionMark.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 14,
  height: 14
};
