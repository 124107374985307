import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

import { temporaryDataDefault } from "./selectors";

const initialState = {
  page: 0,
  pageSize: 20,
  ordering: "",
  search: "",
  selectConfig: "",
  temporaryData: temporaryDataDefault,
  filters: null,
  groupBy: "",
  data_type: "",
  asset: "",
  orderModel: [],
  selectTicketConfig: "",

  vulnerabilities: {},
  vulnerability: {},
  vulnerabilityConfigs: {},
  vulnerabilityConfigsFilterData: {},
  history: [],
  assets: [],
  severityOptions: [],
  statusesOptions: [],

  targetModal: { show: false, data: [] },
  groupModal: { show: false, data: [] },
  tagModal: { show: false, data: [] },

  tags: null,
  groups: null,
  targets: null,
  targetsType: null
};

const reducer = createReducer(
  initialState,

  {
    [actions.setVulnerabilitiesParams.type]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [actions.updVulnerabilitiesParams.type]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [actions.getVulnerabilitiesSuccess.type]: (state, action) => ({
      ...state,
      // vulnerability: {},
      vulnerabilities: action.payload
    }),
    [actions.getVulnerabilitySuccess.type]: (state, action) => ({
      ...state,
      vulnerability: action.payload
    }),
    [actions.getVulnerabilityConfigsSuccess.type]: (state, action) => ({
      ...state,
      vulnerabilityConfigs: action.payload
    }),
    [actions.getVulnerabilityConfigsFiltersDataSuccess.type]: (state, action) => ({
      ...state,
      vulnerabilityConfigsFilterData: action.payload
    }),
    [actions.getVulnerabilityHistorySuccess.type]: (state, action) => ({
      ...state,
      history: action.payload
    }),
    [actions.getVulnerabilityAssetsSuccess.type]: (state, action) => ({
      ...state,
      assets: action.payload
    }),
    [actions.getVulnerabilityRiskFactorsSuccess.type]: (state, action) => ({
      ...state,
      severityOptions: action.payload
    }),
    [actions.getFindingStatusesSuccess.type]: (state, action) => ({
      ...state,
      statusesOptions: action.payload
    }),
    [actions.actionTargetModal.type]: (state, action) => ({
      ...state,
      targetModal: action.payload
    }),
    [actions.actionGroupModal.type]: (state, action) => ({
      ...state,
      groupModal: action.payload
    }),
    [actions.actionTagModal.type]: (state, action) => ({
      ...state,
      tagModal: action.payload
    }),
    [actions.getTagsSuccess.type]: (state, action) => ({
      ...state,
      tags: action.payload
    }),
    [actions.getGroupsSuccess.type]: (state, action) => ({
      ...state,
      groups: action.payload
    }),
    [actions.getTargetsSuccess.type]: (state, action) => ({
      ...state,
      targets: action.payload
    }),
    [actions.getTargetsTypesSuccess.type]: (state, action) => ({
      ...state,
      targetsType: action.payload
    }),
    [actions.editVulnerabilityConfigsSuccess.type]: (state, action) => ({
      ...state,
      selectConfig: {
        ...state?.selectConfig,
        label: action.payload
      }
    })
  },
  [], // required
  state => state // required
);

export default reducer;
