/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { addQuery, removeQuery } from "helper/history";

import { getGroupAssetsSelector, groupAssetsTableColumns } from "store/entities/groups/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";

import {
  setGroupAssetsParams,
  getGroupAssets,
  getGroup,
  updateGroup
} from "store/entities/groups/actions";

import { ContentContainer, Title, Search, Button } from "components/simple";
import { Table } from "components/complex";
import AddAssetModal from "./AddAssetModal";

import styles from "./styles.module.scss";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const GroupsDetails = () => {
  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const [assignAssetState, setAssignAssetState] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getGroupAssets.type])(state));
  const isAssignDeleteLoading = useSelector(state =>
    createLoadingSelector([updateGroup.type])(state)
  );

  const { page, pageSize } = useSelector(state => state.groups.groupsParams);
  const { data, total } = useSelector(getGroupAssetsSelector);
  const group = useSelector(state => state.groups.group);

  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");
  const params = useParams();
  const { id } = params;

  // get default request
  const getRequest = () => {
    dispatch(getGroup(id));
    dispatch(
      setGroupAssetsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: "",
        groupId: id
      })
    );
  };

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(group).length !== 0) {
      const assetsOption = group.assets.map(el => ({ value: el.id, label: el.title }));
      setAssignAssetState(assetsOption);
    }
  }, [group]);

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  const onChangeUrlParams = (key, name) => {
    if (name) {
      addQuery(key, name);
    } else {
      removeQuery(key);
    }
  };

  // Search
  const handleSearch = text => {
    onChangeUrlParams("page", 0);
    dispatch(
      setGroupAssetsParams({
        page: 0,
        search: text
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      const ordering = field === "date" || field === "time" ? "last_scan" : field;
      if (sort === "asc") {
        dispatch(
          setGroupAssetsParams({
            ordering
          })
        );
      } else {
        dispatch(
          setGroupAssetsParams({
            ordering: `-${ordering}`
          })
        );
      }
    } else {
      dispatch(
        setGroupAssetsParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setGroupAssetsParams({
          page: newPage
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setGroupAssetsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
  };

  const closeModalHandler = () => {
    setModalOpen(false);
    setAssignAssetState([]);
  };

  const assignHandler = e => {
    e.preventDefault();
    const assets = assignAssetState.map(i => i.value);
    dispatch(updateGroup({ data: { assets }, id, callback: closeModalHandler }));
  };

  const deleteHandler = () => {
    const options = group.assets.filter(el => {
      return !selectionModel.includes(el.id);
    });
    const assets = options.map(el => el.id);
    dispatch(updateGroup({ data: { assets }, id }));
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>{group?.name}</Title>
      <div className={styles.searchRow}>
        <Search onSearch={handleSearch} />
        <div className={styles.btnsContainer}>
          <Button icon="plus" className={styles.btn} onClick={() => setModalOpen(true)}>
            Assign Asset
          </Button>
          <Button
            className={styles.btn}
            onClick={deleteHandler}
            isLoading={isAssignDeleteLoading}
            disabled={!selectionModel.length}
          >
            Delete
          </Button>
        </div>
      </div>
      <div className={styles.table}>
        <Table
          data={isLoading ? [] : data}
          columns={groupAssetsTableColumns}
          loading={isLoading}
          page={page || 0}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          onSortModelChange={model => handleSort(model)}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          onPageChange={onPageChange}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
        />
      </div>
      <AddAssetModal
        onChange={setAssignAssetState}
        state={assignAssetState}
        onCloseModal={closeModalHandler}
        isLoading={isAssignDeleteLoading}
        onSubmit={assignHandler}
        isModalOpen={isModalOpen}
      />
    </ContentContainer>
  );
};

GroupsDetails.propTypes = {};

export default GroupsDetails;
