import React from "react";
import PropTypes from "prop-types";
import { styled, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

import Icon from "../Icon";

const Btn = styled(Button)(() => ({
  padding: "8px 12px",
  width: "150px",
  background: "#fff",
  border: "none",
  borderRadius: "10px",
  color: "#2B3858",
  fontSize: "14px",
  fontWeight: "500",
  textTransform: "none",
  "&:disabled": {
    color: "#FFFFFF !important",
    background: "#A1A9BE !important",
    border: "none !important"
  }
}));

const IconButton = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  marginRight: "15px",
  "& svg path": {
    fill: theme.palette.primary.main
  }
}));

export default function ButtonComponent(props) {
  const { className = "", children, disabled, isLoading, ...buttonProps } = props;
  const history = useHistory();
  const goBack = () => {
    history?.goBack();
  };

  return (
    <Btn className={`${className}`} {...buttonProps} disabled={disabled} onClick={() => goBack()}>
      <IconButton>
        <Icon.ArrowBack />
      </IconButton>
      {!isLoading ? children : null}
    </Btn>
  );
}

ButtonComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

ButtonComponent.defaultProps = {
  children: "Back to all",
  className: "",
  isLoading: false,
  disabled: false
};
