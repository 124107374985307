// Set TICKETS PARAMS
export const SET_TICKETS_PARAMS = "SET_TICKETS_PARAMS";

// Get TICKETS
export const GET_TICKETS = "GET_TICKETS_REQUEST";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_FAILED = "GET_TICKETS_FAILED";

// Get TICKET
export const GET_TICKET = "GET_TICKET_REQUEST";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const GET_TICKET_FAILED = "GET_TICKET_FAILED";

// Create Ticket
export const CREATE_TICKET = "CREATE_TICKET_REQUEST";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILED = "CREATE_TICKET_FAILED";

// Edit TICKET
export const EDIT_TICKET = "EDIT_TICKET_REQUEST";
export const EDIT_TICKET_SUCCESS = "EDIT_TICKET_SUCCESS";
export const EDIT_TICKET_FAILED = "EDIT_TICKET_FAILED";

// Delete TICKET
export const DELETE_TICKET = "DELETE_TICKET_REQUEST";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAILED = "DELETE_TICKET_FAILED";

// Close TICKET
export const CLOSE_TICKET = "CLOSE_TICKET_REQUEST";
export const CLOSE_TICKET_SUCCESS = "CLOSE_TICKET_SUCCESS";
export const CLOSE_TICKET_FAILED = "CLOSE_TICKET_FAILED";

// Get TICKETS_CONFIG
export const GET_TICKETS_CONFIG = "GET_TICKETS_CONFIG_REQUEST";
export const GET_TICKETS_CONFIG_SUCCESS = "GET_TICKETS_CONFIG_SUCCESS";
export const GET_TICKETS_CONFIG_FAILED = "GET_TICKETS_CONFIG_FAILED";

// GET Vulnerability Tickets
export const GET_VULNERABILITY_TICKETS = "GET_VULNERABILITY_TICKETS_REQUEST";
export const GET_VULNERABILITY_TICKETS_SUCCESS = "GET_VULNERABILITY_TICKETS_SUCCESS";
export const GET_VULNERABILITY_TICKETS_FAILED = "GET_VULNERABILITY_TICKETS_FAILED";

// GET Ticket comment
export const GET_TICKET_COMMENTS = "GET_TICKET_COMMENTS_REQUEST";
export const GET_TICKET_COMMENTS_SUCCESS = "GET_TICKET_COMMENTS_SUCCESS";
export const GET_TICKET_COMMENTS_FAILED = "GET_TICKET_COMMENTS_FAILED";

// POST Ticket comment
export const POST_TICKET_COMMENT = "POST_TICKET_COMMENT_REQUEST";
export const POST_TICKET_COMMENT_SUCCESS = "POST_TICKET_COMMENT_SUCCESS";
export const POST_TICKET_COMMENT_FAILED = "POST_TICKET_COMMENT_FAILED";

// POST Vulnerability Ticket comment
export const POST_VULNERABILITY_TICKET_COMMENT = "POST_VULNERABILITY_TICKET_COMMENT_REQUEST";
export const POST_VULNERABILITY_TICKET_COMMENT_SUCCESS =
  "POST_VULNERABILITY_TICKET_COMMENT_SUCCESS";
export const POST_VULNERABILITY_TICKET_COMMENT_FAILED = "POST_VULNERABILITY_TICKET_COMMENT_FAILED";

// Get Tickets Preview
export const GET_TICKETS_PREVIEW = "GET_TICKETS_PREVIEW_REQUEST";
export const GET_TICKETS_PREVIEW_SUCCESS = "GET_TICKETS_PREVIEW_SUCCESS";
export const GET_TICKETS_PREVIEW_FAILED = "GET_TICKETS_PREVIEW_FAILED";
export const CLEAR_TICKETS_PREVIEW = "CLEAR_TICKETS_PREVIEW";

// GET Baseline Tickets
export const GET_BASELINE_TICKETS = "GET_BASELINE_TICKETS_REQUEST";
export const GET_BASELINE_TICKETS_SUCCESS = "GET_BASELINE_TICKETS_SUCCESS";
export const GET_BASELINE_TICKETS_FAILED = "GET_BASELINE_TICKETS_FAILED";

// POST Baseline Ticket comment
export const POST_BASELINE_TICKET_COMMENT = "POST_BASELINE_TICKET_COMMENT_REQUEST";
export const POST_BASELINE_TICKET_COMMENT_SUCCESS = "POST_BASELINE_TICKET_COMMENT_SUCCESS";
export const POST_BASELINE_TICKET_COMMENT_FAILED = "POST_BASELINE_TICKET_COMMENT_FAILED";

// Get tickets related-types
export const GET_TICKETS_RELATED_TYPES = "GET_TICKETS_RELATED_TYPES_REQUEST";
export const GET_TICKETS_RELATED_TYPES_SUCCESS = "GET_TICKETS_RELATED_TYPES_SUCCESS";
export const GET_TICKETS_RELATED_TYPES_FAILED = "GET_TICKETS_RELATED_TYPES_FAILED";

export const GET_TICKETS_STATUS_LIST = "GET_TICKETS_STATUS_LIST_REQUEST";
export const GET_TICKETS_STATUS_LIST_SUCCESS = "GET_TICKETS_STATUS_LIST_SUCCESS";
export const GET_TICKETS_STATUS_LIST_FAILED = "GET_TICKETS_STATUS_LIST_FAILED";
