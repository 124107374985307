import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

import { routes } from "router";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { navigate } from "helper/history";
import { getAccuknoxWorkspace } from "api/api";
import { addNotification } from "store/entities/notifications/actions";
import { useAddRegistryMutate, useTestRegistryCredMutate } from "store/entities/registry/mutations";
import { getUserId } from "store/entities/auth/utils";

import { GCRForm } from "./Forms/GCRForm";
import { ECRForm } from "./Forms/ECRForm";
import { NexusForm } from "./Forms/NexusForm";
import { DockerHubForm } from "./Forms/DockerHubForm";
import { RegistryName } from "./Forms/Fields/common/RegistryName";
import { RegistryType } from "./Forms/Fields/common/RegistryType";
import { Button, ContentContainer, Title } from "components/simple";
import { RegistryDescription } from "./Forms/Fields/common/RegistryDescription";

function AddRegistry() {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedRegistryType, setSelectedRegistryType] = useState();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty, isValid }
  } = useForm();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setIsSaveDisabled(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const testRegistryCred = useTestRegistryCredMutate(
    () => {
      dispatch(addNotification({ msg: "Registry Tested Successfully", type: "success" }));
      setIsSaveDisabled(false);
    },
    () => {
      dispatch(
        addNotification({
          msg: {
            2: "Invalid registryURL or username or password",
            4: "Invalid JSON key",
            5: "Invalid accessKey or secretKey",
            6: "Invalid username or password"
          }[selectedRegistryType?.value],
          type: "error"
        })
      );
    }
  );

  const addRegistry = useAddRegistryMutate(
    () => {
      queryClient.invalidateQueries(["getRegistryList"]);
      dispatch(addNotification({ msg: "Registry Added Successfully", type: "success" }));
    },
    () => {
      dispatch(addNotification({ msg: "Registry Addition Failed", type: "error" }));
    }
  );

  const handleTest = async e => {
    const payload = {
      apikey: "",
      registry_name: e?.registryType?.label || "",
      registry_type: e?.registryType?.value || 0,

      url: {
        2: e?.registryURL || "",
        4: `https://${e?.registryRegion?.label}` || "",
        5: e?.registryRegion?.label || "",
        6: "https://hub.docker.com/v2/repositories/"
      }[e?.registryType?.value],

      username: {
        2: e?.username || "",
        4: "",
        5: e?.accessKey || "",
        6: e?.username || ""
      }[e?.registryType?.value],
      password: {
        2: e?.password || "",
        4: e?.jsonKey || "",
        5: e?.secretKey || "",
        6: e?.password || ""
      }[e?.registryType?.value]
    };

    testRegistryCred.mutate(payload);
  };

  const onSubmit = async e => {
    const payload = {
      api_key: {
        2: "",
        4: "{}",
        5: "{}",
        6: ""
      }[e?.registryType?.value],
      created_by: getUserId(),
      description: e?.registryDescription || "",
      domain_id: 1,
      name: e?.registryName || "",
      password: {
        2: e?.password || "",
        4: e?.jsonKey || "",
        5: e?.secretKey || "",
        6: e?.password || ""
      }[e?.registryType?.value],
      registry_id: e?.registryType?.value,
      url: {
        2: e?.registryURL || "",
        4: `https://${e?.registryRegion?.label}` || "",
        5: e?.registryRegion?.label || "",
        6: "https://hub.docker.com/v2/repositories/"
      }[e?.registryType?.value],
      user_name: {
        2: e?.username || "",
        4: "",
        5: e?.accessKey || "",
        6: e?.username || ""
      }[e?.registryType?.value],
      workspace_id: getAccuknoxWorkspace()
    };
    addRegistry.mutate(payload);
    navigate(routes.settingsIntegrationsRegistry);
  };

  return (
    <ContentContainer className={styles.container}>
      <div
        style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "flex-start" }}
      >
        <Title>Add Registry</Title>

        <RegistryName control={control} errors={errors} />
        <RegistryDescription errors={errors} control={control} />
        <RegistryType
          errors={errors}
          control={control}
          registryId={selectedRegistryType?.value}
          setSelectedRegistryType={setSelectedRegistryType}
        />

        {
          {
            2: <NexusForm control={control} errors={errors} />,
            4: (
              <GCRForm errors={errors} control={control} registryId={selectedRegistryType?.value} />
            ),
            5: (
              <ECRForm errors={errors} control={control} registryId={selectedRegistryType?.value} />
            ),
            6: <DockerHubForm control={control} errors={errors} />
          }[selectedRegistryType?.value]
        }
      </div>

      <div className="w-full flex flex-row justify-between pt-6 pb-4">
        <Button onClick={handleSubmit(handleTest)}>Test Connection</Button>
        <div className="flex">
          <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid || isSaveDisabled}
            type="submit"
          >
            Save
          </Button>
        </div>
      </div>
    </ContentContainer>
  );
}

export default AddRegistry;
