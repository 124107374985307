import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Tabs } from "components/simple";
import {
  FindingsBySeverityCard,
  ImageScanningCard,
  ImageSecurityCard,
  ScanQueue,
  RiskAcceptance
} from "./components";

import { Findings } from "./components/Findings/index";

export const RegistryScan = () => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const tabsList = [
    { id: 0, label: "Findings", content: <Findings /> },
    { id: 1, label: "Scan Queue", content: <ScanQueue /> }
    // { id: 2, label: "Risk Acceptance", content: <RiskAcceptance /> }
  ];

  const handleTabChange = (event, newValue) => {
    event.stopPropagation();
    setActiveTab(newValue);
  };
  useEffect(() => {
    if (location?.state?.Tag) {
      setActiveTab(location?.state?.Tag);
    }
  }, [location?.state?.Tag]);

  return (
    <div className="h-full">
      <div className="grid grid-cols-3 gap-4">
        <ImageScanningCard />
        <FindingsBySeverityCard />
        <ImageSecurityCard />
      </div>

      <div className="mt-4">
        <Tabs tabs={tabsList} value={activeTab} handleChange={handleTabChange} />
      </div>
    </div>
  );
};
