/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getMonitorConfigTable } from "store/entities/monitors/service";
import { tableBuilder } from "store/entities/monitors/selectors";
import Loader from "components/simple/Loader";
import { useTheme } from "@mui/material";

import { Table } from "components/complex";

import styles from "./styles.module.scss";

const SubTable = ({
  onCellClick,
  row,
  selectionModelSubTable,
  setExpandHeight,
  expandHeight,
  onChangeSelectionModelSubTable
}) => {
  const [initData, setData] = useState({
    columns: [],
    data: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const { columns, data } = initData;

  const params = useSelector(state => state.monitors.monitorConfigParams);

  const setHeight = length => {
    const rowHeight = 38;
    if (length > 15) {
      setExpandHeight(510);
    } else {
      const height = rowHeight * length + 10;
      setExpandHeight(height);
    }
  };

  useEffect(() => {
    const groupByKey = params?.groupBy.value;
    const groupByValue = row?.[groupByKey];

    const group_ids = row?.group_ids.length;
    setHeight(group_ids);
    setData({
      columns: [],
      data: []
    });
    setIsLoading(true);

    const getData = () => {
      getMonitorConfigTable({ ...params, groupByValue })
        .then(res => res.json())
        .then(result => {
          const res = tableBuilder({ results: result }, params, true);
          setData(res);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    getData();
    return () => {
      setData({
        columns: [],
        data: []
      });
      setIsLoading(true);
    };
  }, [row]);

  const tableConfig = {
    paginationVisible: false,
    hideFooter: true,
    sortingMode: false
  };

  const customStyles = {
    height: `${expandHeight}px `,
    width: 1,
    "& .MuiDataGrid-virtualScroller": {
      marginTop: "0px !important",
      height: `${expandHeight}px !important`
    },
    "& .MuiDataGrid-columnHeaders": {
      display: "none",
      height: 0,
      padding: 0
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #E7E6F8"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#E7E6F8",
      marginBottom: "0px"
    },
    "& .MuiDataGrid-cell.active": {
      backgroundColor: theme.sideMenu.highlight,
      color: theme.sideMenu.textColor
    }
  };

  const onChangeSelectionModel = v => {
    onChangeSelectionModelSubTable(v);
  };

  const selectionModel = useMemo(() => {
    return selectionModelSubTable.find(item => item?.id === row?.id)?.selectionModel || [];
  }, [selectionModelSubTable, row]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={`${styles.table} ${styles.subTable}`} style={{ height: `${expandHeight}px` }}>
      <Table
        data={isLoading ? [] : data}
        columns={columns}
        loading={isLoading}
        rowHeight={38}
        autoHeight
        headerHeight={0}
        selectionModel={selectionModel}
        onSelectionModelChange={newSelectionModel => {
          onChangeSelectionModel(newSelectionModel);
        }}
        // getRowClassName={param => {
        //   const status = !param?.row?.active ? "--active" : "";
        //   return `super-app-theme--row${status}`;
        // }}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
        onCellClick={(p, e) => onCellClick(p, e)}
        {...tableConfig}
        customStyles={customStyles}
      />
    </div>
  );
};

SubTable.propTypes = {};

export default SubTable;
