import React, { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import styles from "./styles.module.scss";


function PrimarySubNode({ data }) {
  const [image, setImage] = useState(null);
  useEffect(() => {
    import(`../Images/${data?.imageName}.svg`).then(img => {
      setImage(img.default);
    });
  }, [image, data]);

  return (
    <div className={styles.primarySubNode}>
      <Handle id="11" type="parent" position={Position.Left} />
      <div
        className={styles.subImg}
        // eslint-disable-next-line no-return-assign, no-param-reassign
        onClick={() => (data.image = true)}
      >
        <img src={image} alt="pod" />
      </div>
      <div className={styles.childName}>{data.ip}</div>
    </div>
  );
}

export default PrimarySubNode;
