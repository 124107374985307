import React from "react";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import renderCellExpand from "helper/renderCellExpand";
import { Icon } from "components/simple";

const monitors = state => state.monitors.monitors;
const monitorConfigParams = state => state.monitors.monitorConfigParams;
const monitor = state => state.monitors.monitor;
const monitorHistory = state => state.monitors.monitorHistory;

const monitorsMini = state => state.monitors?.monitorsMini;
const assets = state => state.assets?.assetsMini;

const monitorConfigFilters = state => state.monitors.monitorConfigFilters;
const monitorConfigTable = state => state.monitors.monitorConfigTable;

export const monitorsMiniSelector = createDraftSafeSelector(monitorsMini, state => {
  if (!state?.results?.length) {
    return [];
  }
  const { results } = state;
  const data = results?.map(item => {
    return {
      value: item?.id,
      label: item?.value
    };
  });
  return data;
});

const monitorsTableColumns = () => [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Name",
    field: "name",
    resizable: true,
    minWidth: 300,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Assets",
    field: "assets",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value?.length || 0}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Group",
    field: "groups",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value?.length || 0}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Data type",
    field: "data_type",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const getMonitorsSelector = createDraftSafeSelector(
  monitors,
  monitorsTableColumns,
  (state, columns) => {
    if (!state?.results?.length) {
      return { columns, data: [], total: 0 };
    }

    const { results, count } = state;
    return { columns, data: results, total: count };
  }
);

export const createList = obj => {
  if (obj) {
    return Object.entries(obj).map(([key, val]) => {
      return { value: key, label: val };
    });
  }
  return [];
};

export const getMonitorConfigFiltersSelect = createDraftSafeSelector(
  monitorConfigFilters,
  state => {
    if (!state) {
      return {
        actions: [],
        conditions: [],
        display_fields_list: []
      };
    }

    const res = {
      actions: state.actions.map(([a, b]) => ({ value: a, label: b })),
      conditions: state.conditions.map(([a, b]) => ({ value: a, label: b })),
      display_fields_list: createList(state.display_field)
    };
    return res;
  }
);

export const getMonitorDataSelector = createDraftSafeSelector(
  monitorConfigParams,
  getMonitorConfigFiltersSelect,
  (state, config) => {
    const res = {
      ...state,
      action: config?.actions?.find(item => item?.value === state?.action),
      assets: state?.assets?.length,
      name: state?.name,
      assign_to_data_type: Boolean(state?.assign_to_data_type),
      condition: config?.conditions?.find(item => item?.value === state?.condition),
      data_type: state?.data_type,
      data_type_display: state?.data_type_display,
      display_fields:
        state?.display_fields
          ?.map(item => {
            return config?.display_fields_list?.find(t => t.value === item);
          })
          .filter(s => s) ?? [],
      groups: state?.groups?.length,
      id: state?.id
    };
    return res;
  }
);

function sort(data, sortOrderBy) {
  const itemsById = new Map(data.map(item => [item.field, item]));
  return sortOrderBy.map(field => itemsById.get(field)).filter(item => item);
}

const createColumns = (obj, dict, display_fields_width) => {
  const res = Object.entries(obj)
    .filter(([key]) => dict?.[key] || key === "id")
    .map(([key]) => {
      const fieldWidth = display_fields_width
        ? display_fields_width?.find(item => item?.field === key)
        : undefined;
      // eslint-disable-next-line no-nested-ternary
      const width = fieldWidth ? fieldWidth?.width : 160;

      return {
        headerName: dict?.[key] || "Null",
        field: key,
        resizable: key !== "group_ids",
        width,
        renderCell: renderCellExpand,
        headerClassName: "super-app-theme--header",
        hide: Boolean(!dict?.[key]),
        sortable: true,
        cellClassName: params => {
          const include = params?.row?.highlight_fields?.includes(key);
          return include ? "active" : "";
        },

        ...{
          ...(key === "id"
            ? {
                hide: !dict?.id
              }
            : {})
        },
        ...{
          ...(key === "group_ids"
            ? {
                width: 120,
                valueGetter: ({ row }) => {
                  return row.group_ids?.length || 0;
                }
              }
            : {})
        },
        ...{
          ...(key === "custom"
            ? {
                sortable: false,
                headerName: "",
                width: 50,
                resizable: false,
                renderCell: params => {
                  if (params?.value) {
                    return <Icon.Delete style={{ cursor: "pointer" }} />;
                  }
                  return "";
                }
              }
            : {})
        }
      };
    });
  const sortedData = sort(res, ["id", ...Object.keys(dict)]);
  return sortedData;
};

export const tableBuilder = (state, configParams, groupBy) => {
  if (!state?.results?.length) {
    return { data: [], total: 0, columns: [] };
  }

  const display_fields_width = configParams?.display_fields_width;
  const isGroupBy = Boolean(groupBy);

  const formatData = d => {
    const res = d.map(item => {
      const newItem = {};
      Object.entries(item).forEach(([key, val]) => {
        if (key === "group_ids") {
          newItem[key] = val || [];
          return;
        }
        if (key === "highlight_fields") {
          newItem[key] = val || [];
          return;
        }
        if (Array.isArray(val)) {
          newItem[key] = val.join(",");
          return;
        }
        if (key === "custom") {
          newItem[key] = val;
          return;
        }
        if (key === "active") {
          newItem[key] = val;
          return;
        }
        if (typeof val === "boolean") {
          newItem[key] = val ? "True" : "False";
          return;
        }
        if (typeof val !== "string" && typeof val !== "number") {
          newItem[key] = "";
          return;
        }
        newItem[key] = val;
      });
      return newItem;
    });

    return res;
  };

  const dict = configParams?.dict;
  const getVisibleFields = () => {
    if (isGroupBy) {
      return { group_ids: "Group ids", ...dict, custom: "_" };
    }
    return { ...dict, custom: "_" };
  };
  const dictSort = getVisibleFields();
  const result = formatData(state.results);
  return {
    total: state.count,
    data: result,
    columns: createColumns(result[0], dictSort, display_fields_width)
  };
};

export const getMonitorConfigTableSelector = createDraftSafeSelector(
  monitorConfigTable,
  monitorConfigParams,
  (_, groupBy) => groupBy,
  (state, configParams, groupBy) => {
    return tableBuilder(state, configParams, groupBy);
  }
);

export const getGroupByListSelect = createDraftSafeSelector(
  monitorConfigParams,
  getMonitorConfigFiltersSelect,
  (_, displayFields) => displayFields,
  (state, config, displayFields) => {
    const display_fields =
      state?.display_fields
        ?.map(item => {
          return config?.display_fields_list?.find(t => t.value === item);
        })
        .filter(s => s) ?? [];

    if (config?.display_fields_list?.length && displayFields) {
      const rr = displayFields?.split(",");
      const filtered_display_fields = config?.display_fields_list?.filter(item =>
        rr.includes(item.value)
      );
      if (filtered_display_fields?.length) {
        return filtered_display_fields;
      }
    }
    const res = display_fields?.length ? display_fields : config?.display_fields_list;
    return res;
  }
);

const assetsMini = state => state.monitors.assetsMini;
const groups = state => state.monitors?.groups;

export const getAssingAssetsLeftTableSelector = createDraftSafeSelector(assetsMini, state => {
  if (!state?.results?.length) {
    return [];
  }
  return state.results;
});

export const getAssingAssetsRightTableSelector = createDraftSafeSelector(
  monitorConfigParams,
  state => {
    if (!state?.assets?.length) {
      return [];
    }

    const res = state.assets.map(asset => {
      return { ...asset };
    });
    return res;
  }
);

export const getAssingGroupsLeftTableSelector = createDraftSafeSelector(groups, state => {
  if (!state?.results?.length) {
    return [];
  }
  return state.results;
});

export const getAssingGroupsRightTableSelector = createDraftSafeSelector(
  monitorConfigParams,
  state => {
    if (!state?.groups?.length) {
      return [];
    }

    const res = state.groups.map(group => {
      return { ...group };
    });
    return res;
  }
);

export const getHistoryTableSelector = createDraftSafeSelector(monitorHistory, state => {
  if (!state?.results?.length) {
    return { total: 0, data: [] };
  }

  return {
    total: state.count,
    data: state?.results
  };
});
