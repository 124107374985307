import React from "react";

import { Controller } from "react-hook-form";
import { Input, ErrorText } from "components/simple";

export default function AccessKey({ register, control, errors }) {
  return (
    <div className="w-full mb-3.5 mt-3">
      <Controller
        name="accessKey"
        rules={{
          required: "Access Key is required!",
          pattern: {
            value: /(?:^|[^A-Z0-9])([A-Z0-9]{20})(?:$|[^A-Z0-9])/,
            message: "Enter a valid Access Key!"
          }
        }}
        defaultValue=""
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Input
            name="accessKey"
            label="Access key"
            placeholder="Enter Access Key"
            required
            value={value}
            onChange={evt => {
              onChange(evt.target.value);
            }}
          />
        )}
      />
      <div className="m-0.5">
        <ErrorText errorMsg={errors.accessKey && errors.accessKey.message} />
      </div>
    </div>
  );
}
