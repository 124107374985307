import React, { useState } from "react";
import styles from "./styles.module.scss";

import { Button, Input } from "components/simple";
import AccuknoxLogoIcon from "../assets/AccuknoxLogo.png";
import { useVerifyMFAMutation } from "store/entities/auth/mutations";
import AuthFooter from "../AuthFooter";
import { useParams } from "react-router-dom";

const VerifyMFA = () => {
  const [errorMessageCode, setErrorMessageCode] = useState(null);
  const [state, setState] = useState({
    code: ""
  });
  const params = useParams();
  const { mfaUid } = params;

  const { mutate: verifyMFA, isLoading: isVerifyingMFA } = useVerifyMFAMutation();

  const handleSubmit = e => {
    e.preventDefault();

    if (!state.code.trim()) {
      setErrorMessageCode("this field is required");
      return;
    }
    const code = state?.code?.replaceAll(" ", "");
    verifyMFA({ mfa_uuid: mfaUid, token: code });
  };

  const handleChange = (val, type) => {
    if (val.trim().length >= 8) {
      return;
    }
    setErrorMessageCode(null);
    const res = val.replace(/ /gm, "");

    let num = `${res.substring(0, 3)} ${res.substring(3, 6)}`;

    num = num.trim();
    setState({
      ...state,
      [type]: num
    });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="mt-8">
          <img src={AccuknoxLogoIcon} width="311px" height="64px" alt="" />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.title}>Please Enter MFA Code</div>
          <div className={styles.description}>
            Two-factor authentication is enabled for your account. Please enter a code to log in.
          </div>

          <div className={styles.content}>
            <div>
              <Input
                placeholder="Enter code"
                value={state.code}
                onChange={val => handleChange(val.target.value, "code")}
                invalid={errorMessageCode}
                containerClassName={styles.inputcode}
                className={`${styles.input}`}
                autoFocus
              />
            </div>
            <Button
              className={styles.button}
              type="submit"
              disabled={state?.code.trim().length < 7 || isVerifyingMFA}
            >
              Login
            </Button>
          </div>
        </div>
      </form>
      <AuthFooter />
    </div>
  );
};

export default VerifyMFA;
