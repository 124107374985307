import { Typography } from "@mui/material";

import { routes } from "router";
import { navigate } from "helper/history";

import { BugChip, LockChip, StackedBar } from "../common";
import { SEVERITY_COLORS } from "store/entities/issues/registryScan/constants";

const headerCellConfig = {
  hide: false,
  minWidth: 100,
  sortable: false,
  resizable: true,
  headerClassName: "super-app-theme--header"
};

export const findingsTable = {
  styles: {
    height: 710,
    width: 1,
    "& .super-app-theme--header": {
      color: "#000"
    },

    "& .super-app-theme--rowData": {
      borderRadius: "5px",
      border: "1px solid #D9D9D9",
      borderLeft: "5px solid #9CBEFF"
    }
  },
  headers: [
    {
      headerName: "Repositories",
      field: "repositoryName",
      renderCell: params => (
        <Typography
          paragraph
          fontWeight={600}
          sx={{ color: "primary.light", m: 0, overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {params?.value}
        </Typography>
      ),
      flex: 2,
      ...headerCellConfig
    },
    {
      headerName: "Security Issues",
      field: "severity",
      renderCell: params => (
        <div className="w-4/5 flex justify-between items-center gap-4">
          {/* <div> */}

          {/* </div> */}
          <StackedBar
            height={10}
            dataLength={5}
            showInnerLabel={false}
            severitycolor={SEVERITY_COLORS}
            data={params?.value?.severities}
          />
          <div className="flex items-center gap-1">
            <BugChip size="small" count={params?.value?.totalMalware} fill={false} />
            <LockChip size="small" count={params?.value?.totalSensitive} fill={false} />
          </div>
        </div>
      ),
      flex: 2,
      ...headerCellConfig
    },
    {
      headerName: "Registry Name",
      field: "registryName",
      flex: 1,
      ...headerCellConfig
    }
  ]
};

export const imageDetailsTable = {
  styles: {
    width: 1,
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff"
    }
  },
  headers: [
    {
      headerName: "Image Name",
      field: "imageName",
      renderCell: params => (
        <Typography
          paragraph
          fontWeight={600}
          sx={{
            m: 0,
            cursor: "pointer",
            overflow: "hidden",
            color: "primary.light",
            textOverflow: "ellipsis"
          }}
          onClick={() => {
            navigate(routes.registryScanImageDetails, {
              imageName: params?.value,
              assetId: params?.row?.asset
            });
          }}
        >
          {params?.value}
        </Typography>
      ),
      flex: 1,
      ...headerCellConfig
    },
    {
      headerName: "Security Issues",
      field: "securityIssues",
      renderCell: params => (
        <div className="w-4/5 flex justify-between items-center gap-4">
          <StackedBar
            height={28}
            dataLength={5}
            severitycolor={SEVERITY_COLORS}
            data={params?.value?.severities}
          />
          <div className="flex items-center gap-1">
            <BugChip count={params?.value?.malware} />
            <LockChip count={params?.value?.sensitive} />
          </div>
        </div>
      ),
      flex: 1,
      ...headerCellConfig
    }
  ]
};
