import React from "react";
import Drawer from "components/simple/Drawer";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@mui/styles";
import { useImageMetadataQuery } from "store/entities/issues/riskBasedPriortization/queries";
import { useRiskBasedPrioritizationState } from "store/entities/issues/riskBasedPriortization/slice";

export const VulnerabilityDetailsDrawer = ({ showDrawer, setShowDrawer }) => {
  const theme = useTheme();
  const drawerVulnerability = useRiskBasedPrioritizationState("drawerVulnerability");
  const { data: metadata } = useImageMetadataQuery(drawerVulnerability);

  return (
    <Drawer showDrawer={showDrawer} setShowDrawer={setShowDrawer}>
      <div className="p-2">
        <Header>
          <SubHeader>
            <Typography variant="subtitle2" sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
              {metadata?.name}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
              {metadata?.asset}
            </Typography>
          </SubHeader>
          <CloseIcon>
            <ClearIcon sx={{ fontSize: "1.5rem", fontWeight: "bold", cursor: "pointer" }} />
          </CloseIcon>
        </Header>

        <div className="flex flex-col gap-6 mb-4">
          <div className="grid grid-cols-3 gap-20">
            {Boolean(metadata?.severity?.text) && (
              <div
                className="text-white text-xs font-semibold tracking-wider uppercase px-4 py-0 w-24 rounded-full flex items-center justify-center"
                style={{ backgroundColor: metadata?.severity?.color }}
              >
                {metadata?.severity?.text}
              </div>
            )}
            {Boolean(metadata?.basedOn) && (
              <div>
                <Typography sx={{ color: theme.palette.grey[800] }}>Based on</Typography>
                <Typography sx={{ color: theme.palette.primary.light, fontWeight: 600 }}>
                  {metadata?.basedOn}
                </Typography>
              </div>
            )}
          </div>

          {Boolean(metadata?.description) && (
            <Typography sx={{ color: theme.palette.grey[800] }}>{metadata?.description}</Typography>
          )}

          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-3 gap-20">
              <Typography sx={{ color: theme.palette.grey[800] }}>Installed Resource</Typography>
              <Typography sx={{ color: theme.palette.grey[800] }}>
                {metadata?.installedResource || "--"}
              </Typography>
              <Typography sx={{ color: theme.palette.grey[800] }}>
                Based on parent vulnerability
              </Typography>
            </div>
            <div className="grid grid-cols-3 gap-20">
              <Typography sx={{ color: theme.palette.grey[800] }}>Fixed Version</Typography>
              <Typography sx={{ color: theme.palette.grey[800] }}>
                {metadata?.fixedVersion || "--"}
              </Typography>
            </div>
            <div className="grid grid-cols-3 gap-20">
              <Typography sx={{ color: theme.palette.grey[800] }}>CVSS Score</Typography>
              <span className="flex flex-col">
                <span className="grid grid-cols-4">
                  <Typography
                    sx={{ color: theme.palette.primary.light, fontWeight: 600 }}
                    className="col-span-3"
                  >
                    NVD CVSSv3
                  </Typography>
                  <Typography sx={{ color: theme.palette.primary.light, fontWeight: 600 }}>
                    {metadata?.cvssScore?.v3 || "--"}
                  </Typography>
                </span>
                <span className="grid grid-cols-4">
                  <Typography
                    sx={{ color: theme.palette.primary.light, fontWeight: 600 }}
                    className="col-span-3"
                  >
                    NVD CVSSv2
                  </Typography>
                  <Typography sx={{ color: theme.palette.primary.light, fontWeight: 600 }}>
                    {metadata?.cvssScore?.v2 || "--"}
                  </Typography>
                </span>
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <Typography sx={{ color: theme.palette.grey[900], fontWeight: 600 }}>
              Recommendations
            </Typography>
            <div className="flex flex-col gap-2 text-gray-600">
              <div className="grid grid-cols-3 gap-20">
                <Typography sx={{ color: theme.palette.grey[800] }}>Remediation</Typography>
                <Typography sx={{ color: theme.palette.grey[800] }} className="col-span-2">
                  {metadata?.recommendations?.remediation}
                </Typography>
              </div>
              <div className="grid grid-cols-3 gap-20">
                <Typography sx={{ color: theme.palette.grey[800] }}>Status</Typography>
                <Typography sx={{ color: theme.palette.grey[800] }} className="col-span-2">
                  {metadata?.recommendations?.status}
                </Typography>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <Typography sx={{ color: theme.palette.grey[900], fontWeight: 600 }}>
              Scan Details
            </Typography>
            <div className="flex flex-col gap-2 text-gray-600">
              <div className="grid grid-cols-3 gap-20">
                <Typography sx={{ color: theme.palette.grey[800] }}>Image</Typography>
                <Typography
                  sx={{ color: theme.palette.primary.light, fontWeight: 600 }}
                  className="col-span-2"
                >
                  {metadata?.scanDetails?.image}
                </Typography>
              </div>
              <div className="grid grid-cols-3 gap-20">
                <Typography sx={{ color: theme.palette.grey[800] }}>Operating System</Typography>
                <Typography sx={{ color: theme.palette.grey[800] }}>
                  {metadata?.scanDetails?.os}
                </Typography>
              </div>
              {/* <div className="grid grid-cols-3 gap-20">
              <Typography sx={{ color: theme.palette.grey[800] }}>First Found</Typography>
              <Typography sx={{ color: theme.palette.grey[800] }}>2022-07-03</Typography>
            </div>
            <div className="grid grid-cols-3 gap-20">
              <Typography sx={{ color: theme.palette.grey[800] }}>Last Scan</Typography>
              <Typography sx={{ color: theme.palette.grey[800] }}>2022-07-03</Typography>
            </div>
            <div className="grid grid-cols-3 gap-20">
              <Typography sx={{ color: theme.palette.grey[800] }}>Workloads</Typography>
              <Typography sx={{ color: theme.palette.grey[800] }}>No Running Workloads</Typography>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;
const CloseIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  font-weight: bolder;
`;
const SubHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
