import React from "react";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import Router from "./router/router";
import store from "./store";
import "./styles/index.scss";
import Notifications from "components/simple/Notifications";
import { PrimaryLayoutTitleProvider } from "screens/MonitorLogs/Context/Context";
import { addNotification } from "store/entities/notifications/actions";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        onError: () => {
          store.dispatch(addNotification({ msg: "Unexpected error occurred", type: "error" }));
        }
      }
    }
  });
  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <PrimaryLayoutTitleProvider>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <div className="App">
                <Router />
                <Notifications />
              </div>
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
          </Provider>
        </PrimaryLayoutTitleProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
