/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { routes } from "router/router";
import PropTypes from "prop-types";

import { onChangeUrlParams, useQuery } from "helper/history";

import { getGroupsSelector, groupsTableColumns } from "store/entities/groups/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";

import {
  setGroupsParams,
  getGroups,
  deleteGroup,
  createGroup
} from "store/entities/groups/actions";

import { ContentContainer, Title, Search, Button, CustomPagination, Icon } from "components/simple";
import { Table } from "components/complex";
import AddGroupModal from "./AddGroupModal";

import styles from "./styles.module.scss";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

const Groups = ({ history }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const initialGroupState = {
    name: "",
    assets: []
  };
  const [createGroupState, setCreateGroupState] = useState({ ...initialGroupState });
  const [isModalOpen, setModalOpen] = useState(false);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getGroups.type])(state));
  const isCreateLoading = useSelector(state => createLoadingSelector([createGroup.type])(state));
  const isDeleteLoading = useSelector(state => createLoadingSelector([deleteGroup.type])(state));

  const { page, pageSize } = useSelector(state => state.groups.groupsParams);
  const { data, total } = useSelector(getGroupsSelector);

  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");

  // get default request
  const getRequest = () => {
    dispatch(
      setGroupsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: ""
      })
    );
  };

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  const inputChangeHandler = (value, name) => {
    setCreateGroupState({
      ...createGroupState,
      [name]: value
    });
  };

  // Search
  const handleSearch = text => {
    onChangeUrlParams("page", 0);
    dispatch(
      setGroupsParams({
        page: 0,
        search: text
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      const ordering = field === "date" || field === "time" ? "last_scan" : field;
      if (sort === "asc") {
        dispatch(
          setGroupsParams({
            ordering
          })
        );
      } else {
        dispatch(
          setGroupsParams({
            ordering: `-${ordering}`
          })
        );
      }
    } else {
      dispatch(
        setGroupsParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = (event, newPage) => {
    onChangeUrlParams("page", newPage);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setGroupsParams({
          page: newPage - 1
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setGroupsParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (p, e) => {
    e.stopPropagation();
    history.push(`${routes.settingsGroupsDetails}/${p?.id}`);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
    setCreateGroupState({ ...initialGroupState });
  };

  const createHandler = e => {
    e.preventDefault();
    const { assets, name } = createGroupState;
    const assetsIds = assets.map(i => i.value);
    dispatch(createGroup({ data: { assets: assetsIds, name }, callback: closeModalHandler }));
  };

  const deleteHandler = () => {
    dispatch(deleteGroup({ ids: selectionModel }));
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Groups</Title>
      <div className={styles.searchRow}>
        <Search onSearch={handleSearch} />
        <div className={styles.btnsContainer}>
          <Button icon="plus" className={styles.btn} onClick={() => setModalOpen(true)}>
            Create group
          </Button>
          <Button
            className={styles.btn}
            onClick={deleteHandler}
            isLoading={isDeleteLoading}
            disabled={!selectionModel.length}
          >
            Delete
            <Icon.Delete color="#ffff" />
          </Button>
        </div>
      </div>
      <div className={styles.table}>
        <Table
          data={isLoading ? [] : data}
          columns={groupsTableColumns}
          loading={isLoading}
          page={page || 0}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          onSortModelChange={model => handleSort(model)}
          pageSize={pageSize}
          // rowsPerPageOptions={[5, 10, 20, 50]}
          // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          // onPageChange={onPageChange}
          onCellClick={(p, e) => onCellClick(p, e)}
          customStyles={customStyles}
          components={{
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(total, pageSize),
              onChange: onPageChange,
              page: page + 1,
              totalCount: total || 0,
              selected: pageSize,
              perPageChange: (event, value) => {
                setPageSize(event?.target?.value);
              }
            }
          }}
        />
      </div>
      <AddGroupModal
        onChange={inputChangeHandler}
        state={createGroupState}
        onCloseModal={closeModalHandler}
        isCreateLoading={isCreateLoading}
        onSubmit={createHandler}
        isModalOpen={isModalOpen}
      />
    </ContentContainer>
  );
};

Groups.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Groups;
