import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { routes } from "router";
import { navigate } from "helper/history";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "../notifications/actions";

const getMonitorsParams = state => {
  return {
    page: state.monitors.page,
    pageSize: state.monitors.pageSize,
    search: state.monitors.search,
    filterBy: state.monitors.filterBy,
    ordering: state.monitors.ordering
  };
};

const getFilterConfigParams = state => {
  return {
    id: state.monitors.monitorConfigParams.id,
    page: state.monitors.monitorConfigParams.page,
    pageSize: state.monitors.monitorConfigParams.pageSize,
    ordering: state.monitors.monitorConfigParams.ordering,
    search: state.monitors.monitorConfigParams.search,
    groupBy: state.monitors.monitorConfigParams.groupBy,
    action: state.monitors.monitorConfigParams.action?.value,
    condition: state.monitors.monitorConfigParams.condition?.value,
    display_fields: state.monitors.monitorConfigParams.display_fields,
    data_type: state.monitors.monitorConfigParams.data_type
  };
};

const getFilterHistoryParams = state => {
  return {
    id: state.monitors.monitorHistoryParams.id,
    page: state.monitors.monitorHistoryParams.page,
    pageSize: state.monitors.monitorHistoryParams.pageSize,
    ordering: state.monitors.monitorHistoryParams.ordering,
    search: state.monitors.monitorHistoryParams.search
  };
};

const getAssignHostsParams = state => {
  return {
    label: state.monitors.assignHostsParams.label,
    search: state.monitors.assignHostsParams.search,
    monitor_id: state.monitors.assignHostsParams.monitor_id
  };
};

const getAssignGroupsParams = state => {
  return {
    search: state.monitors.assignGroupsParams.search
  };
};

// Workers //

// Set  Params
function* setMonitorsParamsWorker() {
  const { page, pageSize, search, filterBy, ordering } = yield select(getMonitorsParams);
  yield put(
    actions.getMonitors({
      page,
      pageSize,
      search,
      filterBy,
      ordering
    })
  );
}

// Get Monitors
function* getMonitorsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getMonitors), payload);
    yield put(actions.getMonitorsSuccess(response));
  } catch (error) {
    yield put(actions.getMonitorsFailed(error));
  }
}

// Get Monitor
function* getMonitorWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getMonitor), payload);
    yield put(actions.getMonitorSuccess(response));
    yield put(actions.getMonitorConfigFilters(response?.data_type));
  } catch (error) {
    yield put(actions.getMonitorFailed(error));
  }
}

// Create Monitor
function* createMonitorWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.createMonitor), payload);
    yield put(actions.createMonitorSuccess(response));
    navigate(`${routes.monitorsConfiguration}/${response?.id}`);
    yield put(addNotification({ msg: `Monitor created successfully` }));
  } catch (error) {
    yield put(actions.createMonitorFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete Monitor
function* deleteMonitorWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteMonitor), id)));
    yield put(actions.deleteMonitorSuccess(response));
    yield put(actions.setMonitorsParams());
    yield put(
      addNotification({ msg: `${ids?.length > 1 ? "Monitors" : "Monitor"} deleted successfully` })
    );
  } catch (error) {
    yield put(actions.deleteMonitorFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Create Monitor row
function* createMonitorRowWorker({ payload }) {
  const { callback, ...rest } = payload;

  try {
    const response = yield call(makeRequest(service.createMonitorRow), rest);
    yield put(actions.createMonitorRowSuccess(response));
    yield put(actions.setMonitorConfigParams());

    yield put(addNotification({ msg: `Monitor created successfully` }));
    callback();
  } catch (error) {
    yield put(actions.createMonitorRowFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete Monitor row
function* deleteMonitorRowWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteMonitorRow), payload);
    yield put(actions.deleteMonitorRowSuccess(response));
    yield put(actions.setMonitorConfigParams());
    yield put(addNotification({ msg: `Monitor deleted successfully` }));
  } catch (error) {
    yield put(actions.deleteMonitorRowFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Monitors Mini
function* getMonitorsMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getMonitorsMini), payload);
    yield put(actions.getMonitorsMiniSuccess(response));
  } catch (error) {
    yield put(actions.getMonitorsMiniFailed(error));
  }
}

function* getMonitorConfigTableWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getMonitorConfigTable), payload);
    yield put(actions.getMonitorConfigTableSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getMonitorConfigTableFailed(error));
  }
}

// Set Monitor Config Params
function* setMonitorConfigParamsWorker() {
  const {
    id,
    page,
    pageSize,
    ordering,
    search,
    groupBy,
    action,
    condition,
    display_fields,
    data_type
  } = yield select(getFilterConfigParams);
  yield put(
    actions.getMonitorConfigTable({
      id,
      page,
      pageSize,
      ordering,
      search,
      groupBy,
      action,
      condition,
      display_fields,
      data_type
    })
  );
}

function* getMonitorConfigFiltersWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getMonitorConfigFilters), payload);
    yield put(actions.getMonitorConfigFiltersSuccess(response));
  } catch (error) {
    yield put(actions.getMonitorConfigFiltersFailed(error));
  }
}

// Edit Monitor
function* editMonitorWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editMonitor), payload);
    yield put(actions.editMonitorSuccess(response));
    yield put(addNotification({ msg: "Monitor updated successfully" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.editMonitorFailed(error));
  }
}

// Dublicate Monitor
function* duplicateMonitorWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.duplicateMonitor), payload);
    yield put(actions.duplicateMonitorSuccess(response));
    yield put(addNotification({ msg: "Monitor duplicated successfully" }));
    navigate(`${routes.monitorsConfiguration}/${response?.id}`);
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.duplicateMonitorFailed(error));
  }
}

// add-control
function* addControlMonitorConfigurationTableWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addControlMonitorConfigurationTable), payload);
    yield put(addNotification({ msg: "You have successfully added rule" }));
    yield put(actions.setMonitorConfigParams());
    yield put(actions.addControlMonitorConfigurationTableSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addControlMonitorConfigurationTableFailed(error));
  }
}

// delete-control
function* deleteControlMonitorConfigurationTableWorker({ payload }) {
  try {
    const response = yield call(
      makeRequest(service.deleteControlMonitorConfigurationTable),
      payload
    );
    yield put(addNotification({ msg: "You have successfully deleted rule" }));
    yield put(actions.setMonitorConfigParams());
    yield put(actions.deleteControlMonitorConfigurationTableSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.deleteControlMonitorConfigurationTableFailed(error));
  }
}

// Add Monitor Asset
function* addMonitorAssetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addMonitorAsset), payload);
    yield put(actions.addMonitorAssetSuccess(response));
    const res = yield call(makeRequest(service.getMonitor), payload);
    yield put(actions.getMonitorSuccess(res));
    yield put(
      addNotification({
        msg: `${payload?.data?.ids?.length > 1 ? "Assets" : "Asset"} added successfully`
      })
    );
  } catch (error) {
    yield put(actions.addMonitorAssetFailed(error));
    yield put(addNotification({ msg: error, type: "error" }));
  }
}

// Delete Monitor Asset
function* deleteMonitorAssetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteMonitorAsset), payload);
    yield put(actions.deleteMonitorAssetSuccess(response));
    const res = yield call(makeRequest(service.getMonitor), payload);
    yield put(actions.getMonitorSuccess(res));
    yield put(addNotification({ msg: "Asset deleted successfully" }));
  } catch (error) {
    yield put(actions.deleteMonitorAssetFailed(error));
    yield put(addNotification({ msg: error, type: "error" }));
  }
}

// Add Monitor Group
function* addMonitorGroupWorker({ payload }) {
  const { id, ids } = payload;
  try {
    const response = yield all(
      ids.map(group => call(makeRequest(service.addMonitorGroup), { id, data: { group } }))
    );
    yield put(actions.addMonitorGroupSuccess(response));
    const res = yield call(makeRequest(service.getMonitor), payload);
    yield put(actions.getMonitorSuccess(res));
    yield put(
      addNotification({ msg: `${ids?.length > 1 ? "Groups" : "Group"} added successfully` })
    );
  } catch (error) {
    yield put(actions.addMonitorGroupFailed(error));
    yield put(addNotification({ msg: error, type: "error" }));
  }
}

// Delete Monitor Group
function* deleteMonitorGroupWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteMonitorGroup), payload);
    yield put(actions.deleteMonitorGroupSuccess(response));
    const res = yield call(makeRequest(service.getMonitor), payload);
    yield put(actions.getMonitorSuccess(res));
    yield put(addNotification({ msg: "Group deleted successfully" }));
  } catch (error) {
    yield put(actions.deleteMonitorGroupFailed(error));
    yield put(addNotification({ msg: error, type: "error" }));
  }
}

// Set Assign-hosts Params
function* setAssignHostsParamsWorker() {
  const { label, search, monitor_id } = yield select(getAssignHostsParams);
  yield put(
    actions.getMonitorAssetsMini({
      label,
      search,
      monitor_id
    })
  );
}

// Set Assign-groups Params
function* setAssignGroupsParamsWorker() {
  const { search } = yield select(getAssignGroupsParams);
  yield put(
    actions.getMonitorGroups({
      search
    })
  );
}

// Get Assets-mini
function* getMonitorAssetsMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getMonitorAssetsMini), payload);
    yield put(actions.getMonitorAssetsMiniSuccess(response));
  } catch (error) {
    yield put(actions.getMonitorAssetsMiniFailed(error));
  }
}

// Get groups
function* getMonitorGroupsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getMonitorGroups), payload);
    yield put(actions.getMonitorGroupsSuccess(response));
  } catch (error) {
    yield put(actions.getMonitorGroupsFailed(error));
  }
}

// Get Monitor History
function* getMonitorHistoryWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getMonitorHistory), payload);
    yield put(actions.getMonitorHistorySuccess(response));
  } catch (error) {
    yield put(actions.getMonitorHistoryFailed(error));
  }
}

// Set Monitor History Params
function* setMonitorHistoryParamsWorker() {
  const { id, page, pageSize, ordering, search } = yield select(getFilterHistoryParams);
  yield put(
    actions.getMonitorHistory({
      id,
      page,
      pageSize,
      ordering,
      search
    })
  );
}
// Workers End //

// Watchers //

// Get Monitors
function* watchSetMonitorsParamsWorker() {
  yield takeEvery(actions.setMonitorsParams, setMonitorsParamsWorker);
}

// Get Monitors
function* watchGetMonitorsWorker() {
  yield takeEvery(actions.getMonitors, getMonitorsWorker);
}

// Get Monitor
function* watchGetMonitorWorker() {
  yield takeEvery(actions.getMonitor, getMonitorWorker);
}

// Create Monitor
function* watchCreateMonitorWorker() {
  yield takeEvery(actions.createMonitor, createMonitorWorker);
}

// Delete Monitor
function* watchDeleteMonitorWorker() {
  yield takeEvery(actions.deleteMonitor, deleteMonitorWorker);
}

// Create Monitor row
function* watchCreateMonitorRowWorker() {
  yield takeEvery(actions.createMonitorRow, createMonitorRowWorker);
}

// Delete Monitor row
function* watchDeleteMonitorRowWorker() {
  yield takeEvery(actions.deleteMonitorRow, deleteMonitorRowWorker);
}

// Get Monitors Mini
function* watchGetMonitorsMiniWorker() {
  yield takeEvery(actions.getMonitorsMini, getMonitorsMiniWorker);
}

function* watchGetMonitorHistoryWorker() {
  yield takeEvery(actions.getMonitorHistory, getMonitorHistoryWorker);
}

function* watchSetMonitorConfigParamsWorker() {
  yield takeEvery(actions.setMonitorConfigParams, setMonitorConfigParamsWorker);
}

function* watchGetMonitorConfigFiltersWorker() {
  yield takeEvery(actions.getMonitorConfigFilters, getMonitorConfigFiltersWorker);
}

function* watchGetMonitorConfigTableWorker() {
  yield takeEvery(actions.getMonitorConfigTable, getMonitorConfigTableWorker);
}

function* watchEditMonitorWorker() {
  yield takeEvery(actions.editMonitor, editMonitorWorker);
}

function* watchDuplicateMonitorWorker() {
  yield takeEvery(actions.duplicateMonitor, duplicateMonitorWorker);
}

function* watchAddControlMonitorConfigurationTableWorker() {
  yield takeEvery(
    actions.addControlMonitorConfigurationTable,
    addControlMonitorConfigurationTableWorker
  );
}

function* watchDeleteControlMonitorConfigurationTableWorker() {
  yield takeEvery(
    actions.deleteControlMonitorConfigurationTable,
    deleteControlMonitorConfigurationTableWorker
  );
}

function* watchAddMonitorAssetWorker() {
  yield takeEvery(actions.addMonitorAsset, addMonitorAssetWorker);
}

function* watchDeleteMonitorAsseWorker() {
  yield takeEvery(actions.deleteMonitorAsset, deleteMonitorAssetWorker);
}

function* watchAddMonitorGroupWorker() {
  yield takeEvery(actions.addMonitorGroup, addMonitorGroupWorker);
}

function* watchDeleteMonitorGroupWorker() {
  yield takeEvery(actions.deleteMonitorGroup, deleteMonitorGroupWorker);
}

function* watchSetAssignHostsParamsWorker() {
  yield takeEvery(actions.setAssignHostsParams, setAssignHostsParamsWorker);
}

function* watchSetAssignGroupsParamsWorker() {
  yield takeEvery(actions.setAssignGroupsParams, setAssignGroupsParamsWorker);
}

function* watchGetMonitorAssetsMiniWorker() {
  yield takeEvery(actions.getMonitorAssetsMini, getMonitorAssetsMiniWorker);
}

function* watchGetMonitorGroupWorker() {
  yield takeEvery(actions.getMonitorGroups, getMonitorGroupsWorker);
}

function* watchSetMonitorHistoryParamsWorker() {
  yield takeEvery(actions.setMonitorHistoryParams, setMonitorHistoryParamsWorker);
}

// Watchers End//

export default function* rootSaga() {
  yield all([
    fork(watchSetMonitorsParamsWorker),
    fork(watchGetMonitorsWorker),
    fork(watchGetMonitorWorker),
    fork(watchGetMonitorsMiniWorker),
    fork(watchGetMonitorHistoryWorker),
    fork(watchCreateMonitorWorker),
    fork(watchDeleteMonitorWorker),
    fork(watchCreateMonitorRowWorker),
    fork(watchDeleteMonitorRowWorker),
    fork(watchSetMonitorConfigParamsWorker),
    fork(watchGetMonitorConfigFiltersWorker),
    fork(watchGetMonitorConfigTableWorker),
    fork(watchEditMonitorWorker),
    fork(watchDuplicateMonitorWorker),
    fork(watchAddControlMonitorConfigurationTableWorker),
    fork(watchDeleteControlMonitorConfigurationTableWorker),

    fork(watchAddMonitorAssetWorker),
    fork(watchDeleteMonitorAsseWorker),
    fork(watchAddMonitorGroupWorker),
    fork(watchDeleteMonitorGroupWorker),
    fork(watchSetAssignHostsParamsWorker),
    fork(watchSetAssignGroupsParamsWorker),
    fork(watchGetMonitorAssetsMiniWorker),
    fork(watchGetMonitorGroupWorker),
    fork(watchSetMonitorHistoryParamsWorker)
  ]);
}
