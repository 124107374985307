import React from "react";

import { Controller } from "react-hook-form";
import { Input, ErrorText } from "components/simple";

export default function Username({ control, errors}) {
  return (
    <div className="w-full mb-3.5 mt-3">
      <Controller
        name="username"
        rules={{
          required: "Username is required!",
          maxLength: { value: 100, message: "Maximum 100 characters allowed!" }
        }}
        defaultValue=""
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Input
            name="username"
            label="Username"
            placeholder="Enter Username"
            required
            value={value}
            onChange={evt => {
              onChange(evt.target.value);
            }}
          />
        )}
      />
      <div className="m-0.5">
        <ErrorText errorMsg={errors.username && errors.username.message} />
      </div>
    </div>
  );
}
