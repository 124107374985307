import React, { useState } from "react";
import { Divider } from "@mui/material";
import { Button, Input, Modal, Title } from "components/simple";

import { useDispatch } from "react-redux";
import { actionDeleteModal, deleteCloudAccount } from "store/entities/cloudAccounts/actions";

import styles from "./styles.module.scss";

const DeleteModal = ({ isOpen, accountId }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");

  const onClose = () => {
    dispatch(actionDeleteModal({ show: false, id: null }));
  };

  const onDelete = () => {
    dispatch(deleteCloudAccount(accountId));
  };

  const onSubmit = () => {
    if (inputValue === "delete") {
      onDelete();
      onClose();
      setInputValue("");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <Title className={styles.title}>Delete Account</Title>
      <Divider />
      <div className={styles.pointsContainer}>
        <div className={styles.question}>Are you sure want to delete this account?</div>
        <div className={styles.point}>All existing saved scan reports will be deleted</div>
        <div className={styles.point}>Real-time events will no longer be collected</div>
        <div className={styles.point}>All suppressions and scan configurations will be lost</div>
      </div>
      <div className={styles.label}>
        Type <span className={styles.bold}>&quot;delete&quot;</span> to confirm
      </div>
      <Input
        type="text"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        className={styles.inputContainer}
      />
      <div className={styles.btnContainer}>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Submit</Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
