import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const Switcher = ({ label, options, value, onChange }) => {
  return (
    <div className={`${styles.container}`}>
      {label && <div className={styles.label}>{label}</div>}

      <div className={styles.block}>
        <div className={`${styles.item} ${value && styles.active}`} onClick={() => onChange(true)}>
          {options?.active}
        </div>
        <div
          className={`${styles.item} ${!value && styles.active}`}
          onClick={() => onChange(false)}
        >
          {options?.inactive}
        </div>
      </div>
    </div>
  );
};

Switcher.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func
};

Switcher.defaultProps = {
  label: "",
  onChange: () => {}
};

export default Switcher;
