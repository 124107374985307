import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { customPost } from "api/base";
import { getAccuknoxWorkspace } from "api/api";
import { getSelectedClusters, getSelectedNamespaces } from "./slice";
import { LABEL_TYPES } from "screens/RuntimeSecurity/constants";
import { getDifferenceTime, getTime } from "screens/Inventory/ClusterManagement/service/query";
import { getTimeRange } from "helper/getTimeRange";

export const useClustersQuery = range => {
  const workspaceId = getAccuknoxWorkspace();
  const dispatch = useDispatch();
  let payload;
  // if (range === "All") {
  //   payload = {
  //     workspace_id: workspaceId,
  //     from_time: [],
  //     to_time: []
  //   };
  // } else {
  const filteredRange = getTimeRange(range);
  let from = filteredRange.from;
  let to = filteredRange.to;
  payload = {
    workspace_id: workspaceId,
    from_time: from ? [from.toString()] : [],
    to_time: to ? [to.toString()] : []
  };
  // }
  return useQuery(
    ["clusters", range],
    async () => {
      const response = await customPost(
        "/cm/api/v1/cluster-management/clusters-in-workspace-filter",
        payload
      );
      const res = response.json();

      return res;
    },
    {
      select: data => {
        return {
          ...data,
          options: data.result?.map(c => ({ label: c.ClusterName, value: c.ID })) || []
        };
      },
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export const useNamespacesQuery = (selectedClusters, range) => {
  const workspaceId = getAccuknoxWorkspace();
  let payload;
  const filteredRange = getTimeRange(range);
  let from = filteredRange.from;
  let to = filteredRange.to;
  const clusterId = selectedClusters?.map(item => item?.value);
  payload = {
    workspace_id: workspaceId,
    cluster_id: selectedClusters?.length ? clusterId : [],
    from_time: from ? [from.toString()] : [],
    to_time: to ? [to.toString()] : []
  };
  return useQuery(
    ["namespaces", selectedClusters, range],
    async () => {
      try {
        const response = await customPost("/cm/api/v1/cluster-management/all-namespaces", payload);
        const res = await response.json();
        return res;
      } catch (e) {
        // console.error(e);
      }
    },
    {
      select: data => {
        return {
          ...data,
          options:
            data.result?.map(i => ({
              label: i?.namespace,
              value: i?.ID
            })) || []
        };
      },
      refetchOnWindowFocus: false
    }
  );
};

export const useGetTagsQuery = (pagePrev, pageNext, query = "", policyId, enabled) => {
  const workspaceId = getAccuknoxWorkspace();
  const payload = {
    workspace_id: workspaceId,
    page_previous: pagePrev,
    page_next: pageNext,
    policy_id: policyId,
    query
  };
  return useQuery(
    ["getTags", workspaceId, pagePrev, pageNext, query],
    async () => {
      try {
        const response = await customPost("/labelmanagement/api/v2/tag/get-tags", payload);
        const res = await response.json();
        return res;
      } catch (e) {
        // console.error(e);
      }
    },
    {
      enabled,
      select: data => {
        return {
          ...data,
          options:
            data?.get_tags?.map(t => ({
              label: t.name,
              value: t.label_id
            })) || []
        };
      },
      refetchOnWindowFocus: false
    }
  );
};

export const useGetSelectorLabel = (
  clusterId = [],
  namespaceId = [],
  label = LABEL_TYPES.DEFAULT
) => {
  const workspaceId = getAccuknoxWorkspace();
  const defaultFilter = {
    with: [
      {
        entity_type: "Pod"
      },
      {
        entity_type: "Cluster",
        entity_id: [...(clusterId || [])]
      },
      {
        entity_type: "Namespace",
        entity_id: [...(namespaceId || [])]
      }
    ]
  };
  const nodeFilter = {
    with: [
      {
        entity_type: "Node"
      },
      {
        entity_type: "Cluster",
        entity_id: [...(clusterId || [])]
      }
    ]
  };
  const payload = {
    workspace_id: workspaceId,
    page_previous: 0,
    page_next: 50,
    query: "",
    filter:
      // eslint-disable-next-line no-nested-ternary
      label === LABEL_TYPES.DEFAULT ? defaultFilter : label === LABEL_TYPES.NODE ? nodeFilter : {}
  };

  return useQuery(
    ["getAllLabel", workspaceId, clusterId, namespaceId, label],
    async () => {
      try {
        const response = await customPost("/labelmanagement/api/v1/label/get-label", payload);
        const result = await response?.json();

        return result;
      } catch (e) {
        // console.log(e)
      }
    },
    {
      select: data => {
        return {
          ...data,
          options:
            data?.get_labels?.map(v => ({
              label: `${v?.name}=${v?.value}`,
              value: v?.label_id
            })) || []
        };
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: !!clusterId
    }
  );
};
export const useListofPodData = (selectedClusters, selectedNamespace, range) => {
  const workspaceId = getAccuknoxWorkspace();
  const URL = "/cm/api/v1/cluster-management/pods-in-node-filter";
  let payload;
  const selectCluster = selectedClusters?.length
    ? selectedClusters?.map(cluster => cluster?.value)
    : [];
  const selectNameSpace = selectedNamespace?.length
    ? selectedNamespace?.map(namespace => namespace?.value)
    : [];
  // if (range === "All") {
  //   payload = {
  //     workspace_id: workspaceId,
  //     from_time: [],
  //     to_time: [],
  //     Filter: {},
  //     cluster_id: selectCluster,
  //     namespace_id: selectNameSpace,
  //     node_id: []
  //   };
  // } else {
  const filteredRange = getTimeRange(range);
  let from = filteredRange.from;
  let to = filteredRange.to;

  payload = {
    workspace_id: workspaceId,
    from_time: from ? [from.toString()] : [],
    to_time: to ? [to.toString()] : [],
    Filter: {},
    cluster_id: selectCluster,
    namespace_id: selectNameSpace,
    node_id: []
  };
  // }
  return useQuery(
    ["podSideBar", range, selectedClusters, selectedNamespace],
    async () => {
      const response = await customPost(URL, payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return {
          ...data,
          options:
            data?.result?.map(i => ({
              label: i?.PodName,
              value: i?.ID
            })) || []
        };
      },
      refetchOnWindowFocus: false
    }
  );
};
