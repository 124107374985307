import React from "react";
import { SEVERITY_COLORS } from "store/entities/issues/registryScan/constants";
import styles from "screens/Issues/RegistryScan/styles.module.scss";

export const HorizontalBarChart = ({ data = [], total }) => {
  const calculateFillWidth = (num, total) => `${(num / total) * 100}%`;

  return (
    <div className="grid grid-rows-6 gap-1">
      {Object.keys(data)?.map(severity => (
        <div className="grid grid-cols-10 gap-2 items-center text-sm" key={severity}>
          <p className={`col-span-2 m-0 ${styles.severityFonts}`}>{severity}</p>
          <div className="w-full h-5 col-span-7 bg-gray-200">
            <div
              style={{
                backgroundColor: SEVERITY_COLORS[severity],
                width: calculateFillWidth(data[severity], total),
                height: "100%"
              }}
            ></div>
          </div>
          <p className="col-span-1 m-0">{data[severity]}</p>
        </div>
      ))}
    </div>
  );
};
