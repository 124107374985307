import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
const USER_PROFILE = "userProfile";
const initialState = {
  userDetail: "",
  getRoleData: []
};

export const userInviteDetails = createSlice({
  name: USER_PROFILE,
  initialState,
  reducers: {
    userDetailData: (state, action) => {
      state.userDetail = action.payload;
    },
    updateUserRole: (state, action) => {
      state.getRoleData = action.payload;
    }
  }
});

export const useUserProfileState = key => {
  const value = useSelector(state => state[USER_PROFILE][key]);
  return value;
};

export const { updateUserRole, userDetailData } = userInviteDetails.actions;

export default userInviteDetails.reducer;
