// Set Tags params
export const SET_TAGS_PARAMS = "SET_TAGS_PARAMS";

// Get TAGS
export const GET_TAGS = "GET_GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_GET_TAGS_SUCCESS";
export const GET_TAGS_FAILED = "GET_GET_TAGS_FAILED";

// Get TAG
export const GET_TAG = "GET_TAG_REQUEST";
export const GET_TAG_SUCCESS = "GET_TAG_SUCCESS";
export const GET_TAG_FAILED = "GET_TAG_FAILED";

// Create TAG
export const CREATE_TAG = "CREATE_TAG_REQUEST";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILED = "CREATE_TAG_FAILED";

// Delete TAG
export const DELETE_TAG = "DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILED = "DELETE_TAG_FAILED";

// Get TAGS
export const GET_TAGS_MINI = "GET_TAGS_MINI_REQUEST";
export const GET_TAGS_MINI_SUCCESS = "GET_TAGS_MINI_SUCCESS";
export const GET_TAGS_MINI_FAILED = "GET_TAGS_MINI_FAILED";
