import React from "react";
import PropTypes from "prop-types";

import { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

import { FormLabel } from "@mui/material";

import { Icon, Checkbox } from "components/simple";
import QuestionTooltip from "../QuestionTooltip";

import styles from "./styles.module.scss";
import { customStyles } from "./styles";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon.DropDownArrow />
    </components.DropdownIndicator>
  );
};
const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <Checkbox value={props.isSelected} onChange={() => null} label={props.label} />
      </components.Option>
    </div>
  );
};

const MultiValue = props => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};
export const SelectAsync = ({
  containerClass,
  error,
  onChange,
  name,
  value,
  isDisabled,
  label,
  placeholder,
  options,
  isMulti,
  required,
  tooltip,
  ...rest
}) => {
  return (
    <div className={`${containerClass} ${styles.container} ${required ? styles.required : ""}`}>
      {label && (
        <div className={styles.labelCont}>
          <FormLabel className={styles.label}>{label}</FormLabel>
          {tooltip && <QuestionTooltip text={tooltip} />}
        </div>
      )}
      <AsyncPaginate
        isDisabled={isDisabled}
        components={{ DropdownIndicator, ...(isMulti ? { Option, MultiValue } : {}) }}
        name={name}
        defaultValue={value}
        value={value}
        options={options}
        onChange={option => onChange(option, name)}
        styles={customStyles}
        isMulti={isMulti}
        required={required}
        placeholder={!placeholder ? "" : placeholder}
        {...rest}
      />
      <input
        tabIndex={-1}
        autoComplete="off"
        style={{ opacity: 0, height: 0, position: "absolute" }}
        value={value}
        onChange={onChange}
        required={required}
      />
      {error && <div className={styles.error}>{!error ? "" : error}</div>}
    </div>
  );
};

SelectAsync.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.array
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  containerClass: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),
  required: PropTypes.bool,
  tooltip: PropTypes.string
};

SelectAsync.defaultProps = {
  value: "",
  name: "",
  placeholder: "",
  label: "",
  error: "",
  containerClass: "",
  isDisabled: false,
  required: false,
  onChange: () => {},
  options: [],
  tooltip: ""
};
