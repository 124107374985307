/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, patch, post, remove } from "api/base";
import { formatDate } from "helper/DateLib";

export const getReports = fields => {
  const { page, pageSize, search, ordering, baseline, label, group } = fields;

  let url = `${API}/report-confs?page=${page + 1}&page_size=${pageSize}`;
  if (search) {
    url += `&search=${encodeURIComponent(search)}`;
  }
  if (ordering) {
    url += `&ordering=${encodeURIComponent(ordering)}`;
  }
  if (baseline) {
    url += `&baseline_id=${encodeURIComponent(baseline)}`;
  }
  if (label) {
    url += `&label_id=${encodeURIComponent(label)}`;
  }
  if (group) {
    url += `&group_id=${encodeURIComponent(group)}`;
  }

  return get(url, {
    ...authorization(url),
    ...authorizationTenant()
  });
};

export const getReport = id => {
  return get(`${API}/report-confs/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const createReport = fields => {
  return post(`${API}/report-confs`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editReport = fields => {
  const { id, data } = fields;
  return patch(`${API}/report-confs/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteReport = id => {
  return remove(
    `${API}/report-confs/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const runReport = fields => {
  const { id, data, date } = fields;
  const date_from = date?.[0] ? formatDate(date[0]) : "";
  const date_to = date?.[1] ? formatDate(date[1]) : "";
  const dataObj =
    date_from && date_to
      ? {
          date_from,
          date_to
        }
      : {};

  return post(
    `${API}/report-confs/${id}/run`,
    JSON.stringify({
      ...data,
      ...dataObj
    }),
    {
      ...authorization(),
      ...contentTypeJson(),
      ...authorizationTenant()
    }
  );
};

export const getReportView = fields => {
  const { id, data } = fields;
  return post(`${API}/report-confs/${id}/view`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getReportPages = () => {
  return get(`${API}/report-confs/pages-list`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getReportPeriods = () => {
  return get(`${API}/report-confs/periods`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
