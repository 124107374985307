import { useMemo } from "react";
import { useQuery } from "react-query";
import { useRiskBasedPrioritizationState } from "./slice";
import { VULNERABILITY_TYPES } from "./constants";
import { getImageMetadata, getVulnerabilities } from "./service";
import { useQueryClient } from "react-query";
import { getSeverity, getSeverityColor } from "./helpers";

export const useVulnerabilitiesQuery = vulnerabilityType => {
  const selectedVulnerabilityType =
    vulnerabilityType || useRiskBasedPrioritizationState("selectedVulnerabilityType");
  const page = useRiskBasedPrioritizationState("vulnerabilityTablePage");
  const limit = 100; // TODO: hard-coding for now as pagination doesn't work

  const filterParams = useMemo(() => {
    let params = "";
    if (selectedVulnerabilityType?.value === VULNERABILITY_TYPES?.[0]?.value) {
      params += `${VULNERABILITY_TYPES?.[0]?.value}=True`;
    } else if (selectedVulnerabilityType?.value === VULNERABILITY_TYPES?.[1]?.value) {
      params += `${VULNERABILITY_TYPES?.[0]?.value}=True&${VULNERABILITY_TYPES?.[1]?.value}=True`;
    }
    // if (!params) {
    //   params += `page=${page + 1}`;
    // } else {
    //   params += `&page=${page + 1}`;
    // }
    if (!params) {
      params += `limit=${limit}`;
    } else {
      params += `&limit=${limit}`;
    }
    return params;
  }, [selectedVulnerabilityType, page]);

  return useQuery(
    ["riskBasedPriortization", "getVulnerabilities", filterParams],
    () => getVulnerabilities(filterParams),
    {
      keepPreviousData: true
    }
  );
};

export const usePrefetchImageMetadataQuery = () => {
  const queryClient = useQueryClient();

  return async assetId => {
    await queryClient.prefetchQuery(
      ["riskBasedPriortization", "imageMetadata", assetId],
      () => getImageMetadata(assetId),
      { staleTime: Infinity }
    );
  };
};

export const useImageMetadataQuery = vulnerability => {
  const assetId = vulnerability?.asset?.id;

  return useQuery(
    ["riskBasedPriortization", "imageMetadata", assetId],
    () => getImageMetadata(assetId),
    {
      enabled: Boolean(assetId),
      staleTime: Infinity,
      select: data => {
        const metadata = data?.result?.metadata_image;

        const vulnerabilityMetadata = {
          name: vulnerability?.vulnerability?.name,
          asset: `${vulnerability?.asset?.title} (${metadata?.image_registry_data?.registry_name})`,
          description: vulnerability?.vulnerability?.description,
          severity: {
            text: getSeverity(vulnerability?.vulnerability?.misc?.cvss_v3_score),
            color: getSeverityColor(vulnerability?.vulnerability?.misc?.cvss_v3_score)
          },
          basedOn: `NVD CVSSv3 ${vulnerability?.vulnerability?.misc?.cvss_v3_score || "--"}`,
          installedResource: `${vulnerability?.vulnerability?.misc?.pkg_name} ${vulnerability?.vulnerability?.misc?.ins_version}`,
          fixedVersion: vulnerability?.vulnerability?.misc?.fix_version,
          publishDate: vulnerability?.vulnerability?.misc?.publish_date?.substring(0, 10),
          cvssScore: {
            v2: vulnerability?.vulnerability?.misc?.cvss_v2_score,
            v3: vulnerability?.vulnerability?.misc?.cvss_v3_score
          },
          recommendations: {
            remediation: vulnerability?.vulnerability?.misc?.fix_version
              ? `Upgrade package ${vulnerability?.vulnerability?.misc?.pkg_name} to ${vulnerability?.vulnerability?.misc?.fix_version} or above`
              : "--",
            status: vulnerability?.ack_status
          },
          scanDetails: {
            image: `${vulnerability?.asset?.title} (${metadata?.image_registry_data?.registry_name})`,
            os: `${metadata?.image_metadata?.os} (${metadata?.image_metadata?.os_version})`
          }
        };

        return vulnerabilityMetadata;
      }
    }
  );
};
