const PIE_CHART_COLORS = ["#EDBA45", "#57DCAC", "#E79A3D"];
export const NAMESPACE_PIE_CHART_COLORS = [
  "#16BFD6",
  "#F765A3",
  "#9EE3B2",
  "#7CA1EA",
  "#A155B9",
  "#DBB553"
];
const POLICYCOUNT_PIE_CHART_COLORS = ["#0066FF", "#DD70DF", "#70DF8F", "#F9E536", "#F92323"];
export const REMEDIATIONS = ["#9EED60", "#FEBD47", "#576AC9", "#EB5959", "#FF7F82"];

export const TOPFIVE_PODS = {
  forntend: "#BB0504",
  backend: "#C56261",
  userdb: "#97CE52",
  carts: "#FF435F",
  sockshop: "#F4F5F9"
};
export const Remediationsdata = {
  isLoading: false,
  metrics: [
    {
      title: "34 Scanned Images",
      value: 34,
      color: PIE_CHART_COLORS[0]
    },
    {
      title: "96 Failed to Scan",
      value: 96,
      color: PIE_CHART_COLORS[1]
    },
    {
      title: "70 Images in Queue",
      value: 70,
      color: PIE_CHART_COLORS[2]
    }
  ],
  total: 200
};
export const NameSpaceAlertsdata = {
  isLoading: false,
  metrics: [
    {
      title: "my-sql",
      value: 45,
      color: NAMESPACE_PIE_CHART_COLORS[0]
    },
    {
      title: "elastic",
      value: 12,
      color: NAMESPACE_PIE_CHART_COLORS[1]
    },
    {
      title: "redis",
      value: 13,
      color: NAMESPACE_PIE_CHART_COLORS[2]
    },
    {
      title: "default",
      value: 16,
      color: NAMESPACE_PIE_CHART_COLORS[3]
    },
    {
      title: "java",
      value: 6,
      color: NAMESPACE_PIE_CHART_COLORS[4]
    },
    {
      title: "sql",
      value: 11,
      color: NAMESPACE_PIE_CHART_COLORS[5]
    }
  ],
  total: 101
};
export const PolicyCountdata = [
  {
    name: "Discovered",
    value: 45,
    color: POLICYCOUNT_PIE_CHART_COLORS[0]
  },
  {
    name: "Changed",
    value: 60,
    color: POLICYCOUNT_PIE_CHART_COLORS[1]
  },
  {
    name: "Active",
    value: 60,
    color: POLICYCOUNT_PIE_CHART_COLORS[2]
  },
  {
    name: "Pending",
    value: 20,
    color: POLICYCOUNT_PIE_CHART_COLORS[3]
  },
  {
    name: "Inactive",
    value: 20,
    color: POLICYCOUNT_PIE_CHART_COLORS[4]
  }
];
export const Remediations = {
  isLoading: false,
  metrics: [
    // {
    //   name: "Tickets Open",
    //   value: 32,
    //   color: REMEDIATIONS[0]
    // },
    {
      name: "Ongoing",
      value: 6,
      color: REMEDIATIONS[1]
    },
    {
      name: "Cancelled",
      value: 4,
      color: REMEDIATIONS[2]
    },
    {
      name: "Closed",
      value: 5,
      color: REMEDIATIONS[3]
    }
    // {
    //   name: "Tickets Re-opened",
    //   value: 9,
    //   color: REMEDIATIONS[4]
    // }
  ],
  total: 251
};
export const ImageSeverities = {
  severities: {
    critical: 1200,
    high: 100,
    medium: 200,
    low: 500,
    negligible: 100,
    noissues: 700
  },
  total: 6154
};
export const TopfivepodCount = {
  severities: {
    frontend: 1200,
    backend: 1000,
    userdb: 1500,
    carts: 500,
    sockshop: 100
  },
  total: 6154
};
export const imageSecurities = {
  image_severity: {
    critical: 60,
    high: 30,
    medium: 80,
    low: 95,
    unknown: 0
  },
  totalVulnerabilities: 45493,
  total_malware: 894,
  total_sensitive_data: 79
};
export const Mockdata1 = [
  {
    name: "Mon Nov 14 2022",
    File: 10,
    Process: 15,
    Network: 8
  },
  {
    name: "Wed Nov 16 2022",
    File: 25,
    Process: 15,
    Network: 20
  },
  {
    name: "Fri Nov 19 2022",
    File: 15,
    Process: 5,
    Network: 20
  },
  {
    name: "Mon Nov 23 2022",
    File: 15,
    Process: 5,
    Network: 20
  }
];
export const Mockdata2 = [
  {
    name: "Execution",
    count: 10
  },
  {
    name: "MITRE",
    count: 7.5
  },
  {
    name: "PCI-DSS",
    count: 9
  },
  {
    name: "D3dend",
    count: 6
  },
  {
    name: "NIST-800",
    count: 9
  },
  {
    name: "Unix Shell",
    count: 6
  }
];
