import {
  DEFAULT,
  DEFAULT_POLICY_TEMPLATE,
  DEFAULT_POLICY_YAML,
  FILE,
  K8S,
  KIND_KUBEARMOR_HOST_POLICY,
  KIND_KUBEARMOR_POLICY,
  NETWORK,
  NODE,
  PROCESS,
  VM
} from "./constants";
import { transformPolicyJSON } from "./helpers";

export const updatePolicyJSONReducer = (state, action) => {
  const policyJSON = action.payload;
  state.policyJSON = policyJSON;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policyJSON);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};
export const updateSelectedEntityReducer = (state, action) => {
  state.selectedEntity = action.payload;
};
export const updateSelectedClusterReducer = (state, action) => {
  state.selectedCluster = action.payload;
};
export const updateSelectedNamespaceReducer = (state, action) => {
  state.selectedNamespace = action.payload;
};
export const updateSelectedInstanceGroupReducer = (state, action) => {
  state.selectedInstanceGroup = action.payload;
};
export const updateSelectedInstanceReducer = (state, action) => {
  state.selectedInstance = action.payload;
};
export const updateActiveCardReducer = (state, action) => {
  state.activeCard = action.payload;
};
export const updateCurrentPolicyReducer = (state, action) => {
  const currentPolicy = action.payload;

  state.currentPolicy = currentPolicy;
  if (currentPolicy?.id) {
    state.isUpdatingPolicy = true;
  }
};
export const updateUnknownPolicyValuesReducer = (state, action) => {
  state.unknownPolicyValues = action.payload;
};
export const updateUnsupportedRulesReducer = (state, action) => {
  state.unsupportedRules = action.payload;
};
export const updateShowRulesPaletteReducer = (state, action) => {
  state.showRulesPaletteCard = action.payload;
};

// Policy Metadata Reducers
export const updateIsEditingPolicyMetadataReducer = (state, action) => {
  state.isEditingPolicyMetadata = action.payload;
};
export const handlePolicyMetadataFormSubmitReducer = (state, action) => {
  const formValues = action.payload;

  const { policyJSON, selectedEntity } = state;
  const policy = { ...policyJSON };

  if (!policy?.kind) {
    policy.kind = "";
  }
  if (!policy?.metadata) {
    policy.metadata = {};
  }
  if (!policy?.spec) {
    policy.spec = {};
  }
  policy.metadata.name = formValues?.policyName;

  if (selectedEntity === K8S) {
    policy.metadata.namespace = formValues?.policyNamespace?.value;
  } else if (selectedEntity === VM) {
    if (policy?.metadata?.namespace) {
      delete policy.metadata.namespace;
    }
  }

  if (formValues?.policyMessage) {
    policy.spec.message = formValues?.policyMessage;
  }
  if (formValues?.policyTags?.length) {
    policy.spec.tags = formValues?.policyTags?.map(tag => tag.value);
  } else if (policy?.spec?.tags) {
    delete policy.spec.tags;
  }

  // generate match labels object
  const matchLabels = {};
  formValues?.selectorLabel?.forEach(label => {
    matchLabels[label.name] = label.value;
  });

  if (formValues?.labelType === DEFAULT) {
    policy.spec.selector = {};
    policy.spec.selector.matchLabels = matchLabels;
    policy.kind = KIND_KUBEARMOR_POLICY;

    if (policy?.spec?.nodeSelector) {
      delete policy.spec.nodeSelector;
    }
  } else if (formValues?.labelType === NODE) {
    policy.spec.nodeSelector = {};
    policy.spec.nodeSelector.matchLabels = matchLabels;
    policy.kind = KIND_KUBEARMOR_HOST_POLICY;

    if (policy?.spec?.selector) {
      delete policy.spec.selector;
    }
  }

  state.policyJSON = policy;
  state.isEditingPolicyMetadata = false;
  state.showRulesPaletteCard = true;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

// Process Rule Reducers
export const updateIsEditingProcessRuleReducer = (state, action) => {
  state.processRule.isEditingProcessRule = action.payload;
};
export const updateProcessRuleDataReducer = (state, action) => {
  state.processRule.processRuleData = action.payload;
};
export const updateShowProcessRuleCardReducer = (state, action) => {
  state.processRule.showProcessRuleCard = action.payload;
};
export const updateShowProcessMandateParentPathReducer = (state, action) => {
  state.processRule.showProcessMandateParentPath = action.payload;
};
export const handleProcessRuleFormSubmitReducer = (state, action) => {
  const formValues = action.payload;

  // 1. Initialize the process rule
  const processRule = {};

  // 2. Construct the JSON for each rule
  if (formValues?.directory) {
    processRule.dir = formValues?.path;
  } else {
    processRule.path = formValues?.path;
  }
  if (formValues?.recursive) {
    processRule.recursive = formValues?.recursive;
  }
  if (formValues?.ownerOnly) {
    processRule.ownerOnly = formValues?.ownerOnly;
  }
  if (formValues?.fromSource?.length) {
    processRule.fromSource = formValues?.fromSource
      ?.filter(item => item?.path)
      ?.map(item => ({ path: item?.path }));
  }
  if (formValues?.severity) {
    processRule.severity = formValues?.severity;
  }
  if (formValues?.action) {
    processRule.action = formValues?.action;
  }

  // 3. Save the newly-created rule to context
  const policy = { ...state.policyJSON };

  if (!policy?.spec) {
    policy.spec = {};
  }
  if (!policy?.spec?.process) {
    policy.spec.process = {};
  }
  if (formValues?.directory) {
    if (!policy?.spec?.process?.matchDirectories) {
      policy.spec.process.matchDirectories = [processRule];
    } else {
      policy.spec.process.matchDirectories?.push(processRule);
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (!policy?.spec?.process?.matchPaths) {
      policy.spec.process.matchPaths = [processRule];
    } else {
      policy.spec.process.matchPaths?.push(processRule);
    }
  }

  state.policyJSON = policy;
  state.processRule.processRuleData.push(processRule);
  state.processRule.showProcessMandateParentPath = false;
  state.processRule.isEditingProcessRule = false;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

// File Rule Reducers
export const updateIsEditingFileRuleReducer = (state, action) => {
  state.fileRule.isEditingFileRule = action.payload;
};
export const updateFileRuleDataReducer = (state, action) => {
  state.fileRule.fileRuleData = action.payload;
};
export const updateShowFileRuleCardReducer = (state, action) => {
  state.fileRule.showFileRuleCard = action.payload;
};
export const updateShowFileMandateParentPathReducer = (state, action) => {
  state.fileRule.showFileMandateParentPath = action.payload;
};
export const handleFileRuleFormSubmitReducer = (state, action) => {
  const formValues = action.payload;

  // 1. Initialize the file rule
  const fileRule = {};

  // 2. Construct the JSON for each rule
  if (formValues?.directory) {
    fileRule.dir = formValues?.path;
  } else {
    fileRule.path = formValues?.path;
  }
  if (formValues?.recursive) {
    fileRule.recursive = formValues?.recursive;
  }
  if (formValues?.ownerOnly) {
    fileRule.ownerOnly = formValues?.ownerOnly;
  }
  if (formValues?.readOnly) {
    fileRule.readOnly = formValues?.readOnly;
  }
  if (formValues?.fromSource?.length) {
    fileRule.fromSource = formValues?.fromSource
      ?.filter(item => item?.path)
      ?.map(item => ({ path: item?.path }));
  }
  if (formValues?.severity) {
    fileRule.severity = formValues?.severity;
  }
  if (formValues?.action) {
    fileRule.action = formValues?.action;
  }

  // 3. Save the newly-created rule to context
  const policy = { ...state.policyJSON };

  if (!policy?.spec) {
    policy.spec = {};
  }
  if (!policy?.spec?.file) {
    policy.spec.file = {};
  }
  if (formValues?.directory) {
    if (!policy?.spec?.file?.matchDirectories) {
      policy.spec.file.matchDirectories = [fileRule];
    } else {
      policy.spec.file.matchDirectories?.push(fileRule);
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (!policy?.spec?.file?.matchPaths) {
      policy.spec.file.matchPaths = [fileRule];
    } else {
      policy.spec.file.matchPaths?.push(fileRule);
    }
  }

  state.policyJSON = policy;
  state.fileRule.fileRuleData.push(fileRule);
  state.fileRule.showFileMandateParentPath = false;
  state.fileRule.isEditingFileRule = false;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

// Network Rule Reducers
export const updateIsEditingNetworkRuleReducer = (state, action) => {
  state.networkRule.isEditingNetworkRule = action.payload;
};
export const updateNetworkRuleDataReducer = (state, action) => {
  state.networkRule.networkRuleData = action.payload;
};
export const updateShowNetworkRuleCardReducer = (state, action) => {
  state.networkRule.showNetworkRuleCard = action.payload;
};
export const updateShowNetworkMandateParentPathReducer = (state, action) => {
  state.networkRule.showNetworkMandateParentPath = action.payload;
};
export const handleNetworkRuleFormSubmitReducer = (state, action) => {
  const formValues = action.payload;

  // 1. Initialize the network rule
  const networkRule = {};

  // 2. Construct the JSON for each rule
  if (formValues?.protocol) {
    networkRule.protocol = formValues?.protocol;
  }
  if (formValues?.fromSource?.length) {
    networkRule.fromSource = formValues?.fromSource
      ?.filter(item => item?.path)
      ?.map(item => ({ path: item?.path }));
  }
  if (formValues?.severity) {
    networkRule.severity = formValues?.severity;
  }
  if (formValues?.action) {
    networkRule.action = formValues?.action;
  }

  // 3. Save the newly-created rule to context
  const policy = { ...state.policyJSON };

  if (!policy?.spec) {
    policy.spec = {};
  }
  if (!policy?.spec?.network) {
    policy.spec.network = {};
  }
  if (!policy?.spec?.network?.matchProtocols) {
    policy.spec.network.matchProtocols = [networkRule];
  } else {
    policy.spec.network.matchProtocols?.push(networkRule);
  }

  state.policyJSON = policy;
  state.networkRule.networkRuleData.push(networkRule);
  state.networkRule.showNetworkMandateParentPath = false;
  state.networkRule.isEditingNetworkRule = false;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(policy);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};

export const removeRuleFromPolicyJSONReducer = (state, action) => {
  const ruleType = action.payload;

  const newPolicyJSON = { ...state.policyJSON };
  delete newPolicyJSON?.spec?.[ruleType.toLowerCase()];
  state.policyJSON = newPolicyJSON;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(newPolicyJSON);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;

  if (ruleType === PROCESS) {
    state.processRule.processRuleData = [];
    state.processRule.showProcessRuleCard = false;
  }
  if (ruleType === FILE) {
    state.fileRule.fileRuleData = [];
    state.fileRule.showFileRuleCard = false;
  }
  if (ruleType === NETWORK) {
    state.networkRule.networkRuleData = [];
    state.networkRule.showNetworkRuleCard = false;
  }
};
export const handleAddRuleReducer = (state, action) => {
  const ruleType = action.payload;

  if (ruleType === PROCESS) {
    state.processRule.showProcessRuleCard = true;
    state.processRule.isEditingProcessRule = true;
  } else if (ruleType === FILE) {
    state.fileRule.showFileRuleCard = true;
    state.fileRule.isEditingFileRule = true;
  } else if (ruleType === NETWORK) {
    state.networkRule.showNetworkRuleCard = true;
    state.networkRule.isEditingNetworkRule = true;
  }
};
export const deleteIndividualRuleReducer = (state, action) => {
  const { ruleType, rule, index } = action.payload;
  const newPolicyJSON = { ...state.policyJSON };
  const newNetworkRuleData = [...state.networkRule.networkRuleData];

  const isProcessRule = ruleType === PROCESS.toLowerCase();
  const isFileRule = ruleType === FILE.toLowerCase();
  const isNetworkRule = ruleType === NETWORK.toLowerCase();

  const deleteRule = (RType, primaryKey, secondaryKey, type) => {
    if (
      newPolicyJSON?.spec?.[RType]?.[primaryKey]?.length === 1 &&
      !newPolicyJSON?.spec?.[RType]?.[secondaryKey]
    ) {
      // this block will execute if there is one 'path'/'dir' and there is no 'dir'/'path'
      delete newPolicyJSON?.spec?.[RType];
      if (isProcessRule) {
        state.processRule.processRuleData = [];
        state.processRule.showProcessRuleCard = false;
      } else if (isFileRule) {
        state.fileRule.fileRuleData = [];
        state.fileRule.showFileRuleCard = false;
      }
    } else if (newPolicyJSON?.spec?.[RType]?.[primaryKey]?.length === 1) {
      // this block will execute if both dir and path present
      delete newPolicyJSON?.spec?.[RType]?.[primaryKey];
      if (isProcessRule) {
        state.processRule.processRuleData = [...newPolicyJSON?.spec[RType][secondaryKey]];
      } else if (isFileRule) {
        state.fileRule.fileRuleData = [...newPolicyJSON?.spec[RType][secondaryKey]];
      }
    } else {
      // this will execute if multiple 'path'/'dir' present
      let tmp = isProcessRule ? state.processRule.processRuleData : state.fileRule.fileRuleData;
      tmp.splice(index, 1);
      if (isProcessRule) {
        state.processRule.processRuleData = tmp;
      } else if (isFileRule) {
        state.fileRule.fileRuleData = tmp;
      }

      tmp = tmp.filter(each => each[type]);
      newPolicyJSON.spec[RType][primaryKey] = tmp;
    }
  };

  if (isProcessRule || isFileRule) {
    if (rule?.path) {
      deleteRule(ruleType, "matchPaths", "matchDirectories", "path");
    } else if (rule?.dir) {
      deleteRule(ruleType, "matchDirectories", "matchPaths", "dir");
    }
  }

  if (isNetworkRule) {
    if (newPolicyJSON?.spec?.[ruleType]?.matchProtocols?.length === 1) {
      delete newPolicyJSON?.spec?.[ruleType];
      state.networkRule.networkRuleData = [];
      state.networkRule.showNetworkRuleCard = false;
    } else {
      newNetworkRuleData?.splice(index, 1);
      state.networkRule.networkRuleData = newNetworkRuleData;
      newPolicyJSON?.spec?.[ruleType]?.matchProtocols?.splice(index, 1);
    }
  }

  state.policyJSON = newPolicyJSON;

  // Trigger side effects when policyJSON changes
  const { policyYAML, isValidYAML, isCreatingPolicy } = transformPolicyJSON(newPolicyJSON);
  state.policyYAML = policyYAML;
  state.isValidYAML = isValidYAML;
  state.isCreatingPolicy = isCreatingPolicy;
};
export const resetStateReducer = state => {
  // Hide all the cards
  state.showRulesPaletteCard = false;
  state.processRule.showProcessRuleCard = false;
  state.fileRule.showFileRuleCard = false;
  state.networkRule.showNetworkRuleCard = false;

  // Empty all rule's view mode data
  state.policyJSON = DEFAULT_POLICY_TEMPLATE;
  state.policyYAML = DEFAULT_POLICY_YAML;
  state.processRule.processRuleData = [];
  state.fileRule.fileRuleData = [];
  state.networkRule.networkRuleData = [];

  // Set all the card's state to have adding/editing mode
  state.isEditingPolicyMetadata = true;
  state.processRule.isEditingProcessRule = true;
  state.fileRule.isEditingFileRule = true;
  state.networkRule.isEditingNetworkRule = true;

  // Reset parent paths states
  state.processRule.showProcessMandateParentPath = false;
  state.fileRule.showFileMandateParentPath = false;
  state.networkRule.showNetworkMandateParentPath = false;

  // Clear up all misc. state variables
  state.activeCard = null;
  state.currentPolicy = {};
  state.unknownPolicyValues = {};
  state.selectedEntity = K8S;
  state.selectedCluster = null;
  state.selectedNamespace = null;
  state.selectedInstanceGroup = null;
  state.selectedInstance = null;
  state.isValidYAML = null;
  state.isCreatingPolicy = false;
  state.isUpdatingPolicy = false;
  state.unsupportedRules = [];
};
