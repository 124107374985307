import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { addNotification } from "../notifications/actions";
import { errorHandler } from "helper/errorHandler";
// import { errorHandler } from "helper/errorHandler";

const getFilter = state => ({
  page: state.targets.page,
  pageSize: state.targets.pageSize,
  search: state.targets.search,
  filterBy: state.targets.filterBy,
  type: state.targets.type ? state.targets.type.value : "",
  tag: state.targets.tag ? state.targets.tag.value : "",
  ordering: state?.targets?.ordering
});

// Watchers //

// Get Targets
function* getTargetsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTargets), payload);
    yield put(actions.getTargetsSuccess(response));
  } catch (error) {
    yield put(actions.getTargetsFailed(error));
  }
}

function* getTargetsMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTargetsMini), payload);
    yield put(actions.getTargetsMiniSuccess(response));
  } catch (error) {
    yield put(actions.getTargetsMiniFailed(error));
  }
}

// Get Target
function* getTargetWorker({ payload }) {
  try {
    let response = yield call(makeRequest(service.getTarget), payload);

    const assetData = [];
    const assetCount = response.assets.length;
    // eslint-disable-next-line no-plusplus
    for (let idx = 0; idx < assetCount; idx++) {
      const currData = yield call(makeRequest(service.getAsset), response.assets[idx]);
      assetData.push(currData);
    }

    response = {
      ...response,
      assetData
    };

    yield put(actions.getTargetSuccess(response));
  } catch (error) {
    yield put(actions.getTargetFailed(error));
  }
}

// Get Target Types
function* getTargetTypesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTargetTypes), payload);
    yield put(actions.getTargetTypesSuccess(response));
  } catch (error) {
    yield put(actions.getTargetTypesFailed(error));
  }
}

// Get Target Tags
function* getTargetTagsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTargetTags), payload);
    yield put(actions.getTargetTagsSuccess(response));
  } catch (error) {
    yield put(actions.getTargetTagsFailed(error));
  }
}

// Get Schedule Data
function* getScheduleDataWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getScheduleData), payload);
    yield put(actions.getScheduleDataSuccess(response));
  } catch (error) {
    yield put(actions.getScheduleDataFailed(error));
  }
}

// Get Groups
function* getTargetGroupsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTargetGroups), payload);
    yield put(actions.getTargetGroupsSuccess(response));
  } catch (error) {
    yield put(actions.getTargetGroupsFailed(error));
  }
}

// Get Assets
function* getAssetsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAssets), payload);
    yield put(actions.getAssetsSuccess(response));
  } catch (error) {
    yield put(actions.getAssetsFailed(error));
  }
}

// Get playbooks
function* getPlaybooksWorker() {
  try {
    const response = yield call(makeRequest(service.getPlaybooks));
    yield put(actions.getPlaybooksSuccess(response));
  } catch (error) {
    yield put(actions.getPlaybooksFailed(error));
  }
}

// Get minions
function* getMinionsWorker() {
  try {
    const response = yield call(makeRequest(service.getMinions));
    yield put(actions.getMinionsSuccess(response));
  } catch (error) {
    yield put(actions.getMinionsFailed(error));
  }
}

// Get expected variables
function* getExpectedVariablesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getExpectedVaribles), payload);
    yield put(actions.getExpectedVariablesSuccess(response));
  } catch (error) {
    yield put(actions.getExpectedVariablesFailed(error));
  }
}

// Get variables-mini
function* getVariablesMiniWorker() {
  try {
    const response = yield call(makeRequest(service.getVariablesMini));
    yield put(actions.getVariablesMiniSuccess(response));
  } catch (error) {
    yield put(actions.getVariablesMiniFailed(error));
  }
}

// Set targets params
function* setTargetsParamsWorker() {
  const { page, pageSize, search, filterBy, type, tag, ordering } = yield select(getFilter);
  yield put(
    actions.getTargets({
      page,
      pageSize,
      search,
      filterBy,
      type,
      tag,
      ordering
    })
  );
}

// ACTIONS ====================================

// Create target
function* createTargetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.postTarget), JSON.stringify(payload));
    yield put(actions.createTargetSuccess(response));

    yield put(actions.actionCreateTargetModal({ show: false }));

    const { page, pageSize, search, filterBy, type, tag, ordering } = yield select(getFilter);
    yield put(
      actions.getTargets({
        page,
        pageSize,
        search,
        filterBy,
        type,
        tag,
        ordering
      })
    );
  } catch (error) {
    yield put(actions.createTargetFailed(error));
  }
}

// Edit target
function* editTargetWorker({ payload }) {
  try {
    const { id, data, onSuccess } = payload;

    const response = yield call(makeRequest(service.putTarget), { id, data: JSON.stringify(data) });
    yield put(actions.editTargetSuccess(response));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(actions.editTargetFailed(error));
  }
}

// Add to group
function* addToGroupWorker({ payload }) {
  try {
    const { selectList, group } = payload;

    yield call(makeRequest(service.addToGroup), {
      id: group.id,
      ids: selectList.map(item => item.id)
    });

    yield put(actions.addToGroupSuccess());
    yield put(actions.getTargetGroups());
    yield put(actions.actionAddToGroupModal({ show: false, selectList: [], newGroup: false }));
  } catch (error) {
    yield put(actions.addToGroupFailed(error));
  }
}

// Add to new group
function* addToNewGroupWorker({ payload }) {
  try {
    const { selectList, groupName } = payload;

    yield call(makeRequest(service.addToGroup), {
      name: groupName,
      ids: selectList.map(item => item.id)
    });

    yield put(actions.addToNewGroupSuccess());
    yield put(actions.getTargetGroups());
    yield put(actions.actionAddToGroupModal({ show: false, selectList: [], newGroup: false }));
  } catch (error) {
    yield put(actions.addToNewGroupFailed(error));
  }
}

// Delete targets
function* deleteTargetsWorker({ payload }) {
  try {
    const { items, onSuccess } = payload;
    const itemCount = items.length;

    // eslint-disable-next-line no-plusplus
    for (let itemIdx = 0; itemIdx < itemCount; itemIdx++) {
      yield call(makeRequest(service.deleteTarget), items[itemIdx]);
    }

    yield put(actions.deleteTargetsSuccess());
    yield put(actions.actionDeleteTargetModal({ show: false, deleteList: [] }));

    yield put(actions.setTargetsParams({ page: 0 }));

    const { pageSize, search, filterBy, type, tag, ordering } = yield select(getFilter);
    yield put(
      actions.getTargets({
        page: 0,
        pageSize,
        search,
        filterBy,
        type,
        tag,
        ordering
      })
    );

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(actions.deleteTargetsFailed(error));
  }
}

// Save playbook config
function* savePlaybookConfigWorker({ payload }) {
  const { tagParams } = payload;
  const params = Object.keys(tagParams.parameters);
  const variables = params.map(param => ({
    function_variable: param,
    variable: tagParams.parameters[param].value
  }));

  try {
    const playbookConfigPayload = {
      target: payload.target,
      tag: payload.tagId,
      playbook: tagParams.playbook.value,
      minion: tagParams.minion.value,
      label: tagParams.label.value,
      variables,
      schedule: tagParams.schedule.isEnabled ? tagParams.schedule : {}
    };

    const response = yield call(
      makeRequest(service.postPlaybookConfiguration),
      playbookConfigPayload
    );
    yield put(actions.savePlaybookConfigSuccess(response));
    yield put(addNotification({ msg: "Config Created Successfully", type: "success" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.savePlaybookConfigFailed(error));
  }
}

// Get playbook configs
function* getPlaybookConfigsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getPlaybookConfigurations), payload);
    yield put(actions.getPlaybookConfigsSuccess(response));
  } catch (error) {
    yield put(actions.getPlaybookConfigsFailed(error));
  }
}

// Update playbook configs
function* updatePlaybookConfigWorker({ payload }) {
  const params = Object.keys(payload.parameters);
  const variables = params.map(param => ({
    function_variable: param,
    variable: payload.parameters[param].value
  }));
  try {
    const playbookConfigPayload = {
      configId: payload.configId,
      target: payload.target.id,
      tag: payload.tag.id,
      playbook: payload.playbook.value,
      minion: payload.minion.value,
      label: payload.label.value,
      variables,
      schedule: payload.schedule.isEnabled ? payload.schedule : {}
    };

    const response = yield call(makeRequest(service.updatePlaybookConfig), playbookConfigPayload);
    yield put(actions.updatePlaybookConfigSuccess(response));
    yield put(addNotification({ msg: "Config Updated Successfully", type: "success" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.updatePlaybookConfigFailed(error));
  }
}

// Delete playbook configs
function* deletePlaybookConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deletePlaybookConfig), payload);
    yield put(actions.deletePlaybookConfigSuccess(response));
    yield put(addNotification({ msg: "Config Deleted Successfully", type: "success" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.deletePlaybookConfigFailed(error));
  }
}

// Run playbook configs
function* runPlaybookConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.runPlaybookConfiguration), payload);
    yield put(actions.runPlaybookConfigSuccess(response));
    yield put(addNotification({ msg: response.message, type: "success" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.runPlaybookConfigFailed(error));
  }
}

// Get list of Api token

function* getApiTokenListsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getApiTokenLists), payload);
    yield put(actions.getApiTokenListsSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getApiTokenListsFailed(error));
  }
}

// Generate Api token
function* generateApiTokenWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.generateApiToken), payload);
    yield put(actions.generateApiTokenSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.generateApiTokenFailed(error));
  }
}

// Delete Api token
function* deleteApiTokenWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteApiToken), payload);
    yield put(actions.deleteApiTokenSuccess(response));
    yield put(addNotification({ msg: "Token Deleted Successfully", type: "success" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.deleteApiTokenFailed(error));
  }
}

// Get Api token
function* getApiTokenWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getApiToken), payload);
    yield put(actions.getApiTokenSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getApiTokenFailed(error));
  }
}

// ACTIONS END ====================================

// Watchers End//

// Get Targets
function* watchGetTargetsWorker() {
  yield takeEvery(actions.getTargets, getTargetsWorker);
}

function* watchGetTargetsMiniWorker() {
  yield takeEvery(actions.getTargetsMini, getTargetsMiniWorker);
}

// Get Target
function* watchGetTargetWorker() {
  yield takeEvery(actions.getTarget, getTargetWorker);
}

// Get Target Types
function* watchGetTargetTypesWorker() {
  yield takeEvery(actions.getTargetTypes, getTargetTypesWorker);
}

// Get Target Tags
function* watchGetTargetTagsWorker() {
  yield takeEvery(actions.getTargetTags, getTargetTagsWorker);
}

// Get Schedule Data
function* watchGetScheduleDataWorker() {
  yield takeEvery(actions.getScheduleData, getScheduleDataWorker);
}

// Get Groups
function* watchGetTargetGroupsWorker() {
  yield takeEvery(actions.getTargetGroups, getTargetGroupsWorker);
}

// Get Assets
function* watchGetAssetsWorker() {
  yield takeEvery(actions.getAssets, getAssetsWorker);
}

// Get Playbooks
function* watchGetPlaybooksWorker() {
  yield takeEvery(actions.getPlaybooks, getPlaybooksWorker);
}

// Get Minions
function* watchGetMinionsWorker() {
  yield takeEvery(actions.getMinions, getMinionsWorker);
}

// Get Expected Variables
function* watchGetExpectedVariablesWorker() {
  yield takeEvery(actions.getExpectedVariables, getExpectedVariablesWorker);
}

// Get variables mini
function* watchGetVariablesMiniWorker() {
  yield takeEvery(actions.getVariablesMini, getVariablesMiniWorker);
}

// Set targets params
function* watchSetTargetsParamsWorker() {
  yield takeEvery(actions.setTargetsParams, setTargetsParamsWorker);
}

// Create target
function* watchCreateTargetWorker() {
  yield takeEvery(actions.createTarget, createTargetWorker);
}

// Edit target
function* watchEditTargetWorker() {
  yield takeEvery(actions.editTarget, editTargetWorker);
}

// Add to group
function* watchAddToGroupWorker() {
  yield takeEvery(actions.addToGroup, addToGroupWorker);
}

// Add to new group
function* watchAddToNewGroupWorker() {
  yield takeEvery(actions.addToNewGroup, addToNewGroupWorker);
}

// Delete targets
function* watchDeleteTargetsWorker() {
  yield takeEvery(actions.deleteTargets, deleteTargetsWorker);
}

// Save playbook configuration
function* watchSavePlaybookConfigurationWorker() {
  yield takeEvery(actions.savePlaybookConfig, savePlaybookConfigWorker);
}

// Get playbook configs
function* watchGetPlaybookConfigsWorker() {
  yield takeEvery(actions.getPlaybookConfigs, getPlaybookConfigsWorker);
}

function* watchSavePlaybookConfigurationSuccess() {
  yield takeEvery(actions.savePlaybookConfigSuccess, function*(action) {
    yield put(actions.getPlaybookConfigs({ target_id: action.payload.target }));
  });
}

// Update playbook config
function* watchUpdatePlaybookConfigWorker() {
  yield takeEvery(actions.updatePlaybookConfig, updatePlaybookConfigWorker);
}

// Delete playbook config
function* watchDeletePlaybookConfigWorker() {
  yield takeEvery(actions.deletePlaybookConfig, deletePlaybookConfigWorker);
}

// Run playbook config
function* watchRunPlaybookConfigWorker() {
  yield takeEvery(actions.runPlaybookConfig, runPlaybookConfigWorker);
}

// Get Api token
function* watchGetApiTokenListsWorker() {
  yield takeEvery(actions.getApiTokenLists, getApiTokenListsWorker);
}

// Generate Api token
function* watchGenerateApiTokenWorker() {
  yield takeEvery(actions.generateApiToken, generateApiTokenWorker);
}

// Delete Api token
function* watchDeleteApiTokenWorker() {
  yield takeEvery(actions.deleteApiToken, deleteApiTokenWorker);
}

function* watchGenerateApiTokenSuccessWorker() {
  yield takeEvery(actions.generateApiTokenSuccess, function*(action) {
    yield put(
      actions.getApiTokenLists({ target: action.payload.target, tenant: action.payload.tenant })
    );
  });
}

// Get Api token
function* watchGetApiTokenWorker() {
  yield takeEvery(actions.getApiToken, getApiTokenWorker);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetTargetsWorker),
    fork(watchGetTargetsMiniWorker),
    fork(watchGetTargetWorker),
    fork(watchSetTargetsParamsWorker),
    fork(watchGetTargetTypesWorker),
    fork(watchGetTargetTagsWorker),
    fork(watchGetScheduleDataWorker),
    fork(watchGetTargetGroupsWorker),
    fork(watchGetAssetsWorker),
    fork(watchGetPlaybooksWorker),
    fork(watchGetMinionsWorker),
    fork(watchGetExpectedVariablesWorker),
    fork(watchGetVariablesMiniWorker),
    fork(watchCreateTargetWorker),
    fork(watchEditTargetWorker),
    fork(watchAddToGroupWorker),
    fork(watchAddToNewGroupWorker),
    fork(watchDeleteTargetsWorker),
    fork(watchSavePlaybookConfigurationWorker),
    fork(watchSavePlaybookConfigurationSuccess),
    fork(watchGetPlaybookConfigsWorker),
    fork(watchUpdatePlaybookConfigWorker),
    fork(watchDeletePlaybookConfigWorker),
    fork(watchRunPlaybookConfigWorker),
    fork(watchGenerateApiTokenWorker),
    fork(watchDeleteApiTokenWorker),
    fork(watchGetApiTokenWorker),
    fork(watchGetApiTokenListsWorker),
    fork(watchGenerateApiTokenSuccessWorker)
  ]);
}
