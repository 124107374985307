import React from "react";

import moment from "moment";
import { PieChart, Pie, Cell, Label } from "recharts";

import { getVulnerabilities } from "./utils";
import { SEVERITY_COLORS } from "../../constants";
import { StackedBar } from "../../components/common";
import { ContentContainer, Loader } from "components/simple";
import { useGetImageDetails } from "store/entities/imageDetails/queries";

export const Overview = ({ assetId }) => {
  const { data: imageDetails, isLoading: isImageDetailsLoading } = useGetImageDetails(
    "IMAGE_DETAILS",
    assetId
  );
  const { data: imageMetaData, isLoading: isImageMetaDataLoading } = useGetImageDetails(
    "METADATA_IMAGE",
    assetId
  );

  const pieCharData = {
    total: imageDetails?.total_vulnerabilities,
    metrics: [
      {
        name: "Critical",
        value: imageDetails?.severities?.critical,
        color: SEVERITY_COLORS.Critical
      },
      {
        name: "High",
        value: imageDetails?.severities?.high,
        color: SEVERITY_COLORS.High
      },
      {
        name: "Medium",
        value: imageDetails?.severities?.medium,
        color: SEVERITY_COLORS.Medium
      },
      {
        name: "Low",
        value: imageDetails?.severities?.low,
        color: SEVERITY_COLORS.Low
      },
      {
        name: "Negligible",
        value: imageDetails?.severities?.negligible,
        color: SEVERITY_COLORS.Negligible
      }
    ]
  };

  const data = [
    { label: "Architecture:", value: imageMetaData?.image_metadata?.architecture },
    { label: "Content Digest:", value: imageMetaData?.image_metadata?.docker_id },
    { label: "Created:", value: moment(imageDetails?.scan_date).format("MM/DD/YYYY hh:mm A") },
    { label: "Docker Digest:", value: imageMetaData?.image_metadata?.repo_digests[0] },
    { label: "Docker ID:", value: imageMetaData?.image_metadata?.docker_id },
    { label: "Docker Labels:", value: imageMetaData?.image_metadata?.docker_labels },
    { label: "Docker Version:", value: imageMetaData?.image_metadata?.docker_version },
    { label: "Environment:", value: imageMetaData?.image_metadata?.env },
    {
      label: "Operating System:",
      value: `${imageMetaData?.image_metadata?.os} (${imageMetaData?.image_metadata?.os_version})`
    }
  ];

  const stackedBarData = getVulnerabilities(imageDetails);

  return (
    <ContentContainer className="p-4 flex justify-center align-middle items-center mt-2">
      {isImageDetailsLoading && isImageMetaDataLoading ? (
        <Loader />
      ) : (
        <>
          <div className="w-2/3 mr-4 inline-block">
            {data &&
              data?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex justify-start mb-2 pb-1"
                    style={{
                      borderBottom: "1px solid #E3E3E3"
                    }}
                  >
                    <div className="w-1/5 text-sm" style={{ color: "#797979" }}>
                      {item?.label}
                    </div>
                    <div
                      className="w-4/5 text-sm break-all"
                      style={{
                        color: "#282828"
                      }}
                    >
                      {item?.label === "Environment:"
                        ? item?.value?.map((i, ind) => {
                            return <div key={data.length + ind}>{i}</div>;
                          })
                        : item?.value}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="w-1/3 inline-block">
            <div
              className="flex flex-col gap-5 p-5"
              style={{
                borderRadius: "10px",
                border: "1px solid grey"
              }}
            >
              <div className="text-lg" style={{ fontWeight: 500 }}>
                Vulnerability Scan Details
              </div>
              <div className="text-sm" style={{ color: "#575757" }}>
                <div style={{ color: "#000" }}>{imageDetails?.name}</div>
                {`created ${moment().diff(imageDetails?.scan_date, "days")} day(s) ago`}
              </div>
              <div className="mt-5">
                <PieChart width={350} height={200}>
                  <Pie
                    data={pieCharData?.metrics}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={70}
                    outerRadius={100}
                  >
                    <Label
                      content={props => {
                        const {
                          viewBox: { cx, cy }
                        } = props;

                        return (
                          <text
                            x={cx}
                            y={cy}
                            dominantBaseline="central"
                            textAnchor="middle"
                            style={{
                              fill: "#000",
                              fontSize: "24px",
                              pointerEvents: "none"
                            }}
                          >
                            <tspan x={cx} y={cy - 15} fontSize="16px">
                              Total
                            </tspan>
                            <tspan x={cx} y={cy + 15} fontWeight="800">
                              {pieCharData.total}
                            </tspan>
                          </text>
                        );
                      }}
                    />
                    {pieCharData?.metrics?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry?.color} stroke="none" />
                    ))}
                  </Pie>
                </PieChart>
              </div>
              <div className="mt-5">
                <StackedBar
                  data={stackedBarData}
                  dataLength={Object.keys(stackedBarData).length}
                  height={30}
                  severitycolor={SEVERITY_COLORS}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ContentContainer>
  );
};
