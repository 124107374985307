import React from "react";
import renderCellExpand from "helper/renderCellExpand";

import { formatDate } from "helper/DateLib";

export const columnsMostVulnerableAssets = [
  {
    headerName: "ID",
    field: "id",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Asset",
    field: "asset",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Total Vulnerabilities",
    field: "vulnerabilities",
    resizable: true,
    minWidth: 220,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.vulnerabilities;
      const render = () => {
        const res = [];
        if (param?.Medium) {
          res.push(
            <div style={{ color: "#FEBD46", fontWeight: "bold" }}>{param.Medium || ""}</div>
          );
        }
        if (param?.High) {
          res.push(<div style={{ color: "#576ACA", fontWeight: "bold" }}>{param.High || ""}</div>);
        }
        if (param?.Low) {
          res.push(<div style={{ color: "#9EEC60", fontWeight: "bold" }}>{param.Low || ""}</div>);
        }
        if (param?.Critical) {
          res.push(
            <div style={{ color: "#d83e3c", fontWeight: "bold" }}>{param.Critical || ""}</div>
          );
        }
        return res;
      };

      return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "15px" }}>
          {render()}
        </div>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Last Scan Date",
    field: "last_scan",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: params => <div>{formatDate(params?.row?.last_scan)}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];

export const columnsScansData = [
  {
    headerName: "ID",
    field: "id",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Scan File",
    field: "scan_file",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Total Vulnerabilities",
    field: "vulnerabilities",
    resizable: true,
    minWidth: 220,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.vulnerabilities;
      const render = () => {
        const res = [];
        if (param?.Medium) {
          res.push(
            <div key={param?.Medium} style={{ color: "#FEBD46", fontWeight: "bold" }}>
              {param.Medium || ""}
            </div>
          );
        }
        if (param?.High) {
          res.push(
            <div key={param?.High} style={{ color: "#576ACA", fontWeight: "bold" }}>
              {param.High || ""}
            </div>
          );
        }
        if (param?.Low) {
          res.push(
            <div key={param?.Low} style={{ color: "#9EEC60", fontWeight: "bold" }}>
              {param.Low || ""}
            </div>
          );
        }
        if (param?.Critical) {
          res.push(
            <div key={param?.Critical} style={{ color: "#d83e3c", fontWeight: "bold" }}>
              {param.Critical || ""}
            </div>
          );
        }
        return res;
      };

      return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "15px" }}>
          {render()}
        </div>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Total Assets",
    field: "total_assets",
    resizable: true,
    minWidth: 80,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Last Scan Date",
    field: "last_scan",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: params => <div>{formatDate(params?.row?.last_scan)}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];

export const topRiskColumn = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true,
    sortable: false
  },
  {
    headerName: "Name",
    field: "name",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Data type",
    field: "data_type",
    resizable: true,
    minWidth: 140,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Asset",
    field: "asset",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Risk score",
    field: "risk_score",
    resizable: true,
    minWidth: 140,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];
