/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, patch, remove } from "api/base";
import moment from "moment";
export const getTickets = fields => {
  const {
    page,
    pageSize,
    ordering,
    search,
    filterBy,
    type,
    status,
    configuration,
    priority,
    range
  } = fields;

  let url = "";
  if (ordering) {
    url += `&ordering=${ordering}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if (filterBy) {
    url += `&label_id=${filterBy}`;
  }
  if (type) {
    url += `&by_ticket_related_objects_type=${type}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  if (configuration) {
    url += `&ticket_configuration_id=${configuration}`;
  }
  if (priority) {
    url += `&priority=${priority}`;
  }
  if (range) {
    let v1 = moment(range[0]).format("YYYY-MM-DD");
    let v2 =
      moment().format("YYYY-MM-DD") !== moment(range[1] ? range[1] : range[0]).format("YYYY-MM-DD")
        ? moment(range[1] ? range[1] : range[0]).format("YYYY-MM-DD")
        : moment(range[1] ? range[1] : range[0])
            .add(1, "days")
            .format("YYYY-MM-DD");

    url += `&created_before=${v2}&created_after=${v1}`;
  }
  return get(`${API}/tickets?page=${page + 1 || 1}&page_size=${pageSize}${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTicket = id => {
  return get(`${API}/tickets/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const createTicket = fields => {
  return post(`${API}/tickets`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editTicket = fields => {
  const { id, data } = fields;
  return patch(`${API}/tickets/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteTicket = id => {
  return remove(
    `${API}/tickets/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const closeTicket = id => {
  return remove(
    `${API}/tickets/${id}/close`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getTicketsConfig = data_type => {
  const url = data_type ? `?data_type=${data_type}` : "";
  return get(`${API}/tickets-config-mini${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getVulnerabilityTickets = id => {
  return get(`${API}/findings/${id}/tickets`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTicketComments = id => {
  return get(`${API}/ticket-comments?ticket_id=${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const postTicketComment = fields => {
  return post(`${API}/ticket-comments`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const postVulnerabilityTicketComment = fields => {
  return post(`${API}/ticket-comments`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getBaselineTickets = ({ baselineId, controlId = "" }) => {
  return get(`${API}/baselines/${baselineId}/tickets?control_id=${controlId}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const postBaselineTicketComment = fields => {
  return post(`${API}/ticket-comments`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getTicketsPreview = fields => {
  return post(`${API}/tickets/preview`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getPriorities = ({ id, model }) => {
  return get(`${API}/jira/priorities?object_id=${id}&model=${model}`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};

export const getPrioritiesFreshService = ({ id }) => {
  return get(`${API}/fresh-service/${id}/config-data`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};

export const getConnectWiseConfig = ({ id }) => {
  return get(`${API}/connectwise/${id}/config-data`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};

export const getStatuses = ({ id, type }) => {
  return get(`${API}/tickets/ticket-statuses?label_id=${id}&ticket_profile_type=${type}`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};

export const getTicketsRelatedTypes = () => {
  return get(`${API}/tickets/ticket-related-types-list`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTicketsStatusList = () => {
  return get(`${API}/tickets/ticket-statuses`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
