/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { CircularProgress, Typography } from "@mui/material";
import Loader from "components/simple/Loader";
import { Button, Input } from "components/simple";
import AccuknoxLogoIcon from "../assets/AccuknoxLogo.png";
import styles from "./styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import { useQRMFAQuery } from "store/entities/auth/queries";
import { useAddMFAMutation } from "store/entities/auth/mutations";

const AddMFA = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: mfaData, isLoading: mfaIsLoading } = useQRMFAQuery();
  const { mutate: addMFA, isLoading: isAddingMFA } = useAddMFAMutation();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = formValues => {
    const { mfaCode } = formValues;

    if (mfaCode) {
      addMFA({ token: String(mfaCode) });
    }
  };

  return (
    <div className={styles.container}>
      <div className="mt-8">
        <img src={AccuknoxLogoIcon} width="311px" height="64px" alt="Accunox Logo" />
      </div>
      <div className="flex justify-center items-center">
        <div className={styles.authCard}>
          <Typography variant="h4" color="white" sx={{ width: "80%" }}>
            Setup virtual MFA device
          </Typography>
          <div className="flex justify-center items-start flex-col gap-3">
            <Typography variant="body1" color="white" fontWeight={500}>
              1. Install a Authenticator app on your mobile or computer (example: Google
              Authenticator App)
            </Typography>
            <Typography variant="body1" color="white" fontWeight={500}>
              2. Use your virtual MFA app and your device camera to scan the QR code
            </Typography>
            <Typography variant="body1" color="white" fontWeight={500}>
              3. Enter the MFA code
            </Typography>
          </div>

          <div className="h-full w-full">
            {mfaIsLoading ? (
              <div className="flex justify-center items-center h-1/2 w-full">
                <Loader size={40} style={{ borderColor: "white" }} />
              </div>
            ) : (
              <div className="flex flex-col justify-center gap-4">
                <div className="outline outline-8 outline-white w-fit bg-white">
                  <QRCode value={mfaData?.url} size={160} />
                </div>
                <div className="w-full">
                  <Typography variant="body1" color="white" fontWeight={500}>
                    Alternatively you can type the secret key.
                    <span
                      className="text-red-600 cursor-pointer ml-[2px]"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      Show secret key
                    </span>
                  </Typography>
                  {isOpen && (
                    <Typography variant="body1" color="white" fontWeight={500}>
                      {mfaData?.secret_key}
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.formSignIn}>
          <div>
            <Typography variant="body2" fontWeight={600} sx={{ fontSize: "30px" }}>
              Enter MFA Code
            </Typography>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{ fontSize: "16px", width: "80%" }}
              color="#696F79"
            >
              You need to have an Authentication App to authorise and Proceed Safely
            </Typography>
          </div>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="w-full mb-5">
              <Controller
                name="mfaCode"
                control={control}
                rules={{ required: "MFA code is requried" }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="text"
                    label="Enter Code"
                    value={value}
                    onChange={val => onChange(val.target.value)}
                    containerClassName={styles.inputContainer}
                    className={styles.input}
                    placeholder="Enter MFA Code"
                    invalid={errors?.mfaCode?.message}
                  />
                )}
              />
            </div>
            <div className="w-full text-left mb-7">
              <Button
                className={styles.button}
                onClick={handleSubmit(onSubmit)}
                type="submit"
                disabled={isAddingMFA}
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMFA;
