import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  page: 0,
  pageSize: 20,
  search: "",
  filterBy: "",
  ordering: "",
  monitors: {},
  monitorsMini: {},
  monitorConfigParams: {
    page: 0,
    ordering: "",
    search: "",
    pageSize: 20,
    orderModel: [],
    groupBy: "",
    name: "",
    action: null,
    condition: null,
    display_fields: [],
    display_fields_width: [],
    data_type: "",
    dict: {}
  },
  monitorConfigFilters: null,
  monitorConfigTable: null,
  monitorHistoryParams: {
    page: 0,
    ordering: "",
    search: "",
    pageSize: 20
  },
  monitorHistory: {},
  assetsMini: [],
  assignHostsParams: {
    search: "",
    label: "",
    monitor_id: ""
  },
  groups: [],
  assignGroupsParams: {
    search: ""
  },
  isLoading: true
};

const reducer = createReducer(
  initialState,
  {
    [actions.setMonitorsParams.type]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [actions.getMonitorsSuccess.type]: (state, action) => ({
      ...state,
      monitors: action.payload
    }),
    [actions.getMonitorsMiniSuccess.type]: (state, action) => ({
      ...state,
      monitorsMini: action.payload
    }),
    [actions.setMonitorConfigParams.type]: (state, action) => ({
      ...state,
      monitorConfigParams: {
        ...state?.monitorConfigParams,
        ...action.payload
      }
    }),
    [actions.updMonitorConfigParams.type]: (state, action) => ({
      ...state,
      monitorConfigParams: {
        ...state?.monitorConfigParams,
        ...action.payload
      }
    }),
    [actions.createMonitor.type]: state => ({
      ...state,
      isLoading: true
    }),

    [actions.clearMonitorConfigParams.type]: state => ({
      ...state,
      isLoading: true,
      monitorConfigParams: {
        page: 0,
        ordering: "",
        search: "",
        pageSize: 20,
        orderModel: [],
        groupBy: "",
        name: "",
        action: null,
        condition: null,
        display_fields: [],
        display_fields_width: [],
        data_type: "",
        dict: {},
        id: null
      }
    }),

    [actions.getMonitor.type]: state => {
      return {
        ...state,
        monitorConfigParams: {
          page: 0,
          ordering: "",
          search: "",
          pageSize: 20,
          orderModel: [],
          groupBy: "",
          name: "",
          action: null,
          condition: null,
          display_fields: [],
          display_fields_width: [],
          data_type: "",
          dict: {},
          id: null
        },
        monitorConfigTable: null
      };
    },
    [actions.getMonitorSuccess.type]: (state, action) => {
      return {
        ...state,
        monitorConfigParams: {
          ...state?.monitorConfigParams,
          ...action.payload,
          display_fields_width: action.payload?.table_properties?.display_fields_width || []
        }
      };
    },
    [actions.getMonitorConfigFilters.type]: state => ({
      ...state,
      isLoading: true
    }),
    [actions.getMonitorConfigFiltersFailed.type]: state => ({
      ...state,
      isLoading: false
    }),
    [actions.getMonitorConfigFiltersSuccess.type]: (state, action) => ({
      ...state,
      monitorConfigFilters: action.payload,
      isLoading: false,
      monitorConfigParams: {
        ...state?.monitorConfigParams,
        dict: action.payload?.display_field
      }
    }),
    [actions.getMonitorConfigTableSuccess.type]: (state, action) => ({
      ...state,
      monitorConfigTable: action.payload
    }),
    [actions.setMonitorHistoryParams.type]: (state, action) => ({
      ...state,
      monitorHistoryParams: {
        ...state?.monitorHistoryParams,
        ...action.payload
      }
    }),
    [actions.getMonitorHistorySuccess.type]: (state, action) => ({
      ...state,
      monitorHistory: action.payload
    }),

    [actions.setAssignHostsParams.type]: (state, action) => ({
      ...state,
      assignHostsParams: {
        ...state?.assignHostsParams,
        ...action.payload
      }
    }),
    [actions.setAssignGroupsParams.type]: (state, action) => ({
      ...state,
      assignGroupsParams: {
        ...state?.assignGroupsParams,
        ...action.payload
      }
    }),
    [actions.getMonitorAssetsMiniSuccess.type]: (state, action) => ({
      ...state,
      assetsMini: action.payload
    }),
    [actions.getMonitorGroupsSuccess.type]: (state, action) => ({
      ...state,
      groups: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
