import React from "react";
import PropTypes from "prop-types";

export default function NotCheck(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.18748 1.00932C2.03031 0.857519 1.81981 0.773523 1.60131 0.775422C1.38281 0.777321 1.1738 0.864962 1.0193 1.01947C0.864791 1.17398 0.77715 1.38299 0.775251 1.60148C0.773352 1.81998 0.857348 2.03048 1.00915 2.18765L5.82165 7.00015L1.00831 11.8127C0.928721 11.8895 0.865236 11.9815 0.821562 12.0831C0.777887 12.1848 0.754899 12.2942 0.753938 12.4048C0.752976 12.5155 0.774061 12.6252 0.815962 12.7276C0.857862 12.83 0.91974 12.9231 0.997984 13.0013C1.07623 13.0796 1.16927 13.1414 1.27168 13.1833C1.3741 13.2252 1.48383 13.2463 1.59448 13.2454C1.70513 13.2444 1.81448 13.2214 1.91615 13.1777C2.01782 13.1341 2.10977 13.0706 2.18665 12.991L6.99998 8.17848L11.8125 12.991C11.9696 13.1428 12.1801 13.2268 12.3986 13.2249C12.6171 13.223 12.8262 13.1353 12.9807 12.9808C13.1352 12.8263 13.2228 12.6173 13.2247 12.3988C13.2266 12.1803 13.1426 11.9698 12.9908 11.8127L8.17831 7.00015L12.9908 2.18765C13.1426 2.03048 13.2266 1.81998 13.2247 1.60148C13.2228 1.38299 13.1352 1.17398 12.9807 1.01947C12.8262 0.864962 12.6171 0.777321 12.3986 0.775422C12.1801 0.773523 11.9696 0.857519 11.8125 1.00932L6.99998 5.82182L2.18748 1.00848V1.00932Z"
        fill={props.color}
      />
    </svg>
  );
}

NotCheck.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

NotCheck.defaultProps = {
  className: "",
  style: {},
  color: "#D83E3C",
  width: 14,
  height: 14
};
