//DELETE Response Messages
export const DISCOVERED_HARDEN_DELETE_MSG =
  "Discovered / Hardening policies are inactivated. Discovered / Hardening policies cannot be deleted.";
export const CUSTOM_DELETE_MSG = "Custom policies deleted successfully.";
export const COMBINATION_DELETE_MSG =
  "Custom policies deleted successfully.Discovered / Hardening policies are inactivated. Discovered / Hardening policies cannot be deleted.";

//IGNORE Response Messages
export const CUSTOM_IGNORE_MSG = "Custom policies cannot be ignored.";
export const COMBINATION_IGNORE_MSG =
  "Discovered / Hardening policies are ignored. Custom policies cannot be ignored.";

export const DISCOVERED_HARDEN_IGNORE_MSG = "Discovered / Hardening policies are ignored.";

export const DISCOVERED_HARDEN_ONLY_IGNORE_MSG =
  "Discovered / Hardening policies ignored successfully";
