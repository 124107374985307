/* eslint-disable */

// auth
export const loginScreen = "/login";
export const signUpScreen = "/sign-up";
export const signUpSuccess = "/sign-up/success";
export const verifyEmail = "/verify-email";
export const addMFA = "/mfa/add";
export const verifyMFA = "/mfa/verify";
export const forgotPassword = "/forgot-password";
export const resetPasswordScreen = "/reset-password";
export const resetPasswordSuccess = "/reset-password/success";
export const changePassword = "/change-password";

//compliance

export const CSPM = "/compliance/CSPM-executive-dashboard";

// sidebar
export const overview = "/remediation/overview";

export const clusterManagement = "/inventory/cloud-workloads";
export const createPolicyCluster = "/inventory/cloud-workloads/policy-editor";

export const dataStructure = "/data-structure";
export const dataStructureHistory = "/inventory/import-history";
export const dataStructureDatalist = "/data-structure/datalist";
export const dataStructureDatalistCreateTicket = "/data-structure/datalist/create-ticket";

export const dataStructureAssets = "/inventory/assets";
export const dataStructureAssetsDetails = "/inventory/assets/details";

export const dataStructureBaselines = "/inventory/baselines";

// Issues
export const vulnerabilities = "/issues/vulnerabilities";
export const vulnerabilitiesDetails = "/issues/vulnerabilities/details";
export const vulnerabilitiesCreateTicket = "/issues/vulnerabilities/create-ticket";
export const registryScan = "/issues/registry-scan";
export const registryScanImageDetails = "/issues/registry-scan/image-details";
export const riskBasedPrioritization = "/issues/risk-based-prioritization";

export const baselines = "/compliance/baselines";
export const failedBaseline = "/baselines/failed-assets";
export const createBaseline = "/compliance/baselines/create-baseline";
export const editBaseline = "/baselines/edit-baseline";
export const baselineConfiguration = "/baselines/baseline-configuration";
export const baselineConfigurationCreateTicket = "/baselines/baseline-configuration/create-ticket";
export const compareBaselines = "/baselines/compare";

// export const collectors = "/collectors";
// export const collectorsDetails = "/collectors/details";

export const tickets = "/tickets";
export const ticketsDetails = "/tickets/details";

export const reports = "/reports";
export const reportsDetails = "/reports/details";

export const notifications = "/notifications";
export const notificationsDetails = "/notifications/details";

// SETTINGS
export const settingsCloudAccounts = "/settings/cloud-accounts";
export const settingsAddCloudAccount = "/settings/cloud-accounts/add-account";
export const settingsEditCloudAccount = "/settings/cloud-accounts/edit-account";

export const settingsManageCluster = "/settings/manage-cluster";
export const settingsManageClusterOnboard = "/settings/manage-cluster/onboard";
export const settingsManageClusterOnboardScrollSpy = "/settings/manage-cluster/onboard/agents";
export const settingsManageClusterScrollSpy = "/settings/manage-cluster/agents";

export const settingsUserProfiles = "/settings/user-profiles";
export const settingsUserProfilesDetails = "/settings/user-profiles/details";

export const settingsRbac = "/settings/roles";
export const settingsRbacRoleAdd = "/settings/roles/add";
export const settingsRbacRoleEdit = "/settings/roles/edit";

export const settingsIntegrations = "/settings/integrations";
export const settingsIntegrationsCspm = "/settings/integrations/CSPM";
export const settingsIntegrationsJiraServer = "/settings/integrations/cspm/jira-server";
export const settingsIntegrationsJiraCloud = "/settings/integrations/cspm/jira-cloud";
export const settingsIntegrationsFreshservice = "/settings/integrations/cspm/freshservice";
export const settingsIntegrationsEmailsDetails = "/settings/integrations/cspm/emails-details";
export const settingsIntegrationsConnectWise = "/settings/integrations/cspm/connectwise";
export const settingsIntegrationsRegistry = "/settings/integrations/registry";
export const settingsIntegrationsRegistryAdd = "/settings/integrations/registry/add";
export const settingsIntegrationsRegistryEdit = "/settings/integrations/registry/edit";
export const settingsIntegrationsS3DataSource = "/settings/integrations/s3-data-source";

export const settingsLabels = "/settings/labels";

export const settingsTags = "/settings/tags";
export const settingsTagsDetails = "/settings/tags/details";

export const settingsGroups = "/settings/groups";
export const settingsGroupsDetails = "/settings/groups/details";

export const settingsDataSourceEdit = "/settings/data-source/edit";
export const settingsTicketTemplates = "/settings/ticket-templates";
export const settingsTicketTemplatesDetails = "/settings/ticket-templates/details";

// export const settingsMinions = "/settings/minions";
// SETTINGS END

export const profile = "/profile";

// Monitor

export const monitors = "/monitors-logging/monitors";
export const monitorsConfiguration = "/monitors-logging/monitors/monitors-configuration";

//Runtime Security
export const CwppDashboard = "/runtime-security/CWPP-dashboard";
export const allPolicies = "/runtime-security/policies";
export const autoDiscoveredPolicies = "/runtime-security/autodiscovered-policies";
export const policiesPendingApproval = "/runtime-security/policies-pending-approval";
export const podObservability = "/runtime-security/app-behavior";

export const createPolicy = "/runtime-security/all-policies/create-policy";
export const policyDetails = "/runtime-security/all-policies/policy-details";
export const policyHistory = "/runtime-security/policies/policy-history";
export const pendingpolicyDetailView = "/runtime-security/policies-pending-approval/policy-details";
export const policyEditor = "/runtime-security/policies/policy-editor";

// Workspace manager
export const channelIntegration = "/integrations";
export const integrationList = "/settings/integrations/list";
export const manageOnboardedClusters = "/manage-onboarded-clusters";
export const integrationJira = "/settings/integrations/jira";
export const integrationRsyslog = "/settings/integrations/rsyslog";
export const integrationAWS = "/settings/integrations/aws-cloudwatch";
export const integrationElastic = "/settings/integrations/elastic-search";
export const integrationSlack = "/settings/integrations/slack";
export const integrationSplunk = "/settings/integrations/splunk";
export const emailIntegration = "/settings/integrations/email";
export const pagerDutyIntegration = "/settings/integrations/pagerduty";
export const webhookIntegration = "/settings/integrations/webhook";

// editChannels
export const editSlack = "/settings/integrations/list/edit slackchannel";
export const editSplunk = "/settings/integrations/list/edit splunkchannel";
export const editRsyslog = "/settings/integrations/list/edit rsyslogchannel";
export const editAws = "/settings/integrations/list/edit cloudwatchchannel";
export const editElastic = "/settings/integrations/list/edit elasticsearchchannel";
export const editJira = "/settings/integrations/list/edit jirachannel";

// Logs & Summary
export const alertsSummaryLogs = "/monitors-logging/logs";
export const logsV2 = "/monitors-logging/logs-v2";
export const alertsSummaryTriggers = "/monitors-logging/triggers";
// CnappDashboard
export const home = "/";

// Inventory
export const explorer = "/inventory/explorer";

const routes = {
  loginScreen,
  signUpScreen,
  signUpSuccess,
  verifyEmail,
  addMFA,
  verifyMFA,
  forgotPassword,
  changePassword,
  resetPasswordScreen,
  resetPasswordSuccess,
  overview,
  home,
  clusterManagement,
  podObservability,
  dataStructure,
  dataStructureHistory,
  dataStructureDatalist,
  dataStructureDatalistCreateTicket,
  dataStructureAssets,
  dataStructureAssetsDetails,
  dataStructureBaselines,
  vulnerabilities,
  vulnerabilitiesDetails,
  vulnerabilitiesCreateTicket,
  registryScan,
  registryScanImageDetails,
  riskBasedPrioritization,
  baselines,
  // collectors,
  // collectorsDetails,
  tickets,
  ticketsDetails,
  reports,
  reportsDetails,
  notifications,
  notificationsDetails,
  settingsIntegrations,
  settingsDataSourceEdit,
  settingsIntegrationsCspm,
  settingsIntegrationsJiraServer,
  settingsIntegrationsJiraCloud,
  settingsIntegrationsFreshservice,
  settingsIntegrationsEmailsDetails,
  settingsIntegrationsConnectWise,
  settingsIntegrationsRegistry,
  settingsIntegrationsRegistryAdd,
  settingsIntegrationsRegistryEdit,
  settingsIntegrationsS3DataSource,
  settingsTicketTemplates,
  settingsTicketTemplatesDetails,
  settingsRbac,
  settingsRbacRoleAdd,
  settingsRbacRoleEdit,
  settingsLabels,
  // settingsLabelsDetails,
  settingsTags,
  settingsTagsDetails,
  settingsGroups,
  settingsGroupsDetails,
  settingsUserProfiles,
  settingsUserProfilesDetails,
  profile,
  createBaseline,
  editBaseline,
  baselineConfiguration,
  failedBaseline,
  baselineConfigurationCreateTicket,
  compareBaselines,
  monitors,
  monitorsConfiguration,
  allPolicies,
  createPolicy,
  createPolicyCluster,
  policyDetails,
  autoDiscoveredPolicies,
  policiesPendingApproval,
  policyEditor,
  channelIntegration,
  integrationList,
  manageOnboardedClusters,
  pendingpolicyDetailView,
  policyHistory,
  integrationJira,
  integrationRsyslog,
  integrationAWS,
  integrationElastic,
  integrationSlack,
  integrationSplunk,
  settingsCloudAccounts,
  settingsAddCloudAccount,
  settingsManageCluster,
  settingsManageClusterOnboard,
  settingsManageClusterOnboardScrollSpy,
  settingsManageClusterScrollSpy,
  CwppDashboard,
  alertsSummaryLogs,
  alertsSummaryTriggers,
  explorer,
  CSPM,
  logsV2,
  settingsEditCloudAccount
  // settingsMinions
};
export default routes;
