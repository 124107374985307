import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Title, Modal, Input } from "components/simple";
import { addS3Profile, addModalS3Profile } from "store/entities/settings/actions";

import styles from "./styles.module.scss";

const AddS3ProfileModal = () => {
  const [state, setState] = useState({
    aws_s3_access_key: "",
    aws_s3_bucket_name: "",
    secret: ""
  });
  const open = useSelector(st => st.settings.addModalS3Profile);
  const dispatch = useDispatch();

  useEffect(() => {
    return () =>
      setState({
        aws_s3_access_key: "",
        aws_s3_bucket_name: "",
        secret: ""
      });
  }, [open]);

  const onSave = e => {
    e.preventDefault();
    dispatch(addS3Profile(state));
  };

  const close = () => {
    dispatch(addModalS3Profile(false));
  };

  const inputChangeHandler = (val, key) => {
    setState({ ...state, [key]: val });
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Add s3 profile </Title>
      <div className={styles.content}>
        <form className={styles.form} onSubmit={onSave}>
          <Input
            value={state.aws_s3_bucket_name}
            containerClassName={styles.inputContainer}
            label="s3 bucket name:"
            required
            onChange={val => inputChangeHandler(val.target.value, "aws_s3_bucket_name")}
          />
          <Input
            value={state.aws_s3_access_key}
            containerClassName={styles.inputContainer}
            label="s3 access key:"
            type="text"
            required
            autoComplete="off"
            onChange={val => inputChangeHandler(val.target.value, "aws_s3_access_key")}
          />
          <Input
            value={state.secret}
            containerClassName={styles.inputContainer}
            label="Secret"
            type="password"
            autocomplete="new-password"
            required
            onChange={val => inputChangeHandler(val.target.value, "secret")}
          />
          <div className={styles.modalFooter}>
            <Button onClick={close} variant="outline" className={styles.modalButton}>
              Back
            </Button>
            <Button type="submit" className={styles.modalButton}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddS3ProfileModal;
