import { API, authorization, contentTypeJson, authorizationTenant } from "api/api";
import { get, post } from "api/base";

export const getVulnerabilities = async filterParams => {
  const DATA_TYPE = "RISK_BASED_PRIORITIZATION";
  const response = await get(`${API}/screen?data_type=${DATA_TYPE}&${filterParams}`, {
    ...contentTypeJson(),
    ...authorization(),
    ...(authorizationTenant()?.["Tenant-Id"] !== "undefined" ? { ...authorizationTenant() } : {})
  });
  const data = await response.json();
  return data;
};

export const getImageMetadata = async assetId => {
  const DATA_TYPE = "METADATA_IMAGE";
  const response = await get(`${API}/screen?data_type=${DATA_TYPE}&asset=${assetId}`, {
    ...contentTypeJson(),
    ...authorization(),
    ...(authorizationTenant()?.["Tenant-Id"] !== "undefined" ? { ...authorizationTenant() } : {})
  });
  const data = await response.json();
  return data;
};

export const updateVulnerabilityStatus = async payload => {
  const response = await post(`${API}/findings/update-status`, JSON.stringify(payload), {
    ...contentTypeJson(),
    ...authorization(),
    ...(authorizationTenant()?.["Tenant-Id"] !== "undefined" ? { ...authorizationTenant() } : {})
  });
  const data = await response.json();
  return data;
};
