export const SET_IMPORT_HISTORY_PARAMS = "SET_IMPORT_HISTORY_PARAMS";

// Get IMPORT_HISTORY
export const GET_IMPORT_HISTORY = "GET_IMPORT_HISTORY_REQUEST";
export const GET_IMPORT_HISTORY_SUCCESS = "GET_IMPORT_HISTORY_SUCCESS";
export const GET_IMPORT_HISTORY_FAILED = "GET_IMPORT_HISTORY_FAILED";

// Delete IMPORT_HISTORY
export const DELETE_IMPORT_HISTORY = "DELETE_IMPORT_HISTORY_REQUEST";
export const DELETE_IMPORT_HISTORY_SUCCESS = "DELETE_IMPORT_HISTORY_SUCCESS";
export const DELETE_IMPORT_HISTORY_FAILED = "DELETE_IMPORT_HISTORY_FAILED";

// Delete IMPORT_HISTORY
export const ADD_TAG = "ADD_TAG_IMPORT_HISTORY_REQUEST";
export const ADD_TAG_SUCCESS = "ADD_TAG_IMPORT_HISTORY_SUCCESS";
export const ADD_TAG_FAILED = "ADD_TAG_IMPORT_HISTORY_FAILED";

// Get Data Types
export const GET_DATA_TYPES = "GET_DATA_TYPES_IMPORT_HISTORY_REQUEST";
export const GET_DATA_TYPES_SUCCESS = "GET_DATA_TYPES_IMPORT_HISTORY_SUCCESS";
export const GET_DATA_TYPES_FAILED = "GET_DATA_TYPES_IMPORT_HISTORY_FAILED";

// Run report
export const RUN_REPORT = "RUN_REPORT_IMPORT_HISTORY_REQUEST";
export const RUN_REPORT_SUCCESS = "RUN_REPORT_IMPORT_HISTORY_SUCCESS";
export const RUN_REPORT_FAILED = "RUN_REPORT_IMPORT_HISTORY_FAILED";
