import React, { Fragment } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import Popover from "@mui/material/Popover";

const PolicyTags = ({ tagData = [], deletable = false, onDelete, policyId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const chipStyle = {
    backgroundColor: "#F2F4F7",
    color: "#344054",
    height: "22px",
    borderRadius: "16px",
    fontSize: "12px",
    fontWeight: "500"
  };

  return (
    <Section>
      <Chip
        label={tagData?.[0]?.name}
        size="small"
        sx={chipStyle}
        // {...(deletable ? { onDelete: () => onDelete(tagData?.[0]?.id, policyId) } : {})}
      />
      {tagData?.length > 1 && (
        <Chip
          label={`+${Number(tagData?.length) - 1} `}
          sx={chipStyle}
          // onDelete={handleClick}
          onClick={handleClick}
          className="cursor-pointer"
        />
      )}
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{ marginTop: "15px" }}
      >
        <div
          style={{
            gap: "5px",
            display: "flex",
            padding: "10px",
            flexWrap: "wrap",
            maxWidth: "680px",
            // overflowY: "scroll",
            width: "fit-content",
            alignItems: "flex-start",
            justifyContent: "flex-start"
          }}
        >
          {tagData?.slice(1).map((data, index) => {
            return (
              <Chip
                key={data.id}
                label={data?.name}
                size="small"
                sx={chipStyle}
                // {...(deletable ? { onDelete: () => onDelete(data?.id, policyId) } : {})}
              />
            );
          })}
        </div>
      </Popover>
    </Section>
  );
};

PolicyTags.propTypes = {
  tagData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

const Section = styled.section`
  /* border: 2px solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding-top: 5px;
`;
export default PolicyTags;
