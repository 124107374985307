import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { routes } from "router";
import { navigate } from "helper/history";
import { errorHandler } from "helper/errorHandler";
import { addNotification, getModalNotifications } from "../notifications/actions";
import { changeTheme } from "../theme/actions";
import { logout } from "../auth/utils";

// Watchers //

// Get User Profile
function* getUserProfileWorker() {
  try {
    const response = yield call(makeRequest(service.getUserProfile));
    localStorage.setItem("userId", response?.id);
    yield put(actions.getUserProfileSuccess(response));
    if (window.__env__.NODE_ENV !== "development") {
      if (!response?.mfa?.id) {
        yield put(actions.mfaModal(true));
      }
    }
    yield put(getModalNotifications());
    yield put(changeTheme(response?.user_profile?.color_schema || "Theme9"));
  } catch (error) {
    if (error?.expired) {
      yield put(addNotification({ msg: error?.expired, type: "error" }));
      yield put(actions.openChangePasswordModal());
    }
    yield put(actions.getUserProfileFailed(error));
  }
}

// Get Tenant
function* getTenantWorker() {
  try {
    const response = yield call(makeRequest(service.getTenants));
    yield put(actions.getTenantsSuccess(response));
    const tenant = sessionStorage.getItem("tenant");
    if (!tenant && response?.length) {
      const opt = { value: response[0].id, label: response[0].name };
      sessionStorage.setItem("tenant", JSON.stringify(opt));
      localStorage.setItem("tenant", JSON.stringify(opt));
    }
    yield put(actions.getUserProfile());
  } catch (error) {
    yield put(actions.getTenantsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Signup
function* signUpWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.signUp), payload);
    yield put(actions.signupSuccess(response));
  } catch (error) {
    yield put(actions.signupFailed(error));
  }
}

// Forgot Password
function* forgotPasswordWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.forgotPassword), payload);
    yield put(actions.forgotPasswordSuccess(response));
    navigate(routes.loginScreen);
  } catch (error) {
    yield put(actions.forgotPasswordFailed(error));
  }
}

// Activate
function* activateWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.activate), payload);
    yield put(actions.activateSuccess(response));
    navigate(routes.loginScreen);
  } catch (error) {
    yield put(actions.activateFailed(error));
  }
}

// Login
function* loginWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.login), payload);
    yield put(actions.loginSuccess(response));
    // FIXME: Remove once the Accuknox and Divy API backend are integrated
    const accuknoxLoginRes = yield call(makeRequest(service.accuknoxLogin), payload);
    const accuknoxRoleMappingRes = yield call(makeRequest(service.accuknoxRoleMapping), {
      refresh_token: accuknoxLoginRes.refresh_token,
      access_token: accuknoxLoginRes.access_token
    });
    if (accuknoxRoleMappingRes?.access_token)
      localStorage.setItem("accuknoxToken", accuknoxRoleMappingRes.access_token);
    if (response?.token) {
      localStorage.setItem("token", response?.token);
      navigate(routes.home);
    }
    if (response?.id) {
      navigate(`${routes.verification}/${response?.id}`);
    }
  } catch (error) {
    yield put(actions.loginFailed(error));
  }
}

// Update Profile
function* updateProfileWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.updateProfile), payload);
    yield put(actions.updateProfileSuccess(response));
    yield put(actions.getUserProfile());
    yield put(addNotification({ msg: "Profile has been successfully updated" }));
  } catch (error) {
    yield put(actions.updateProfileFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Change Password
function* changePasswordWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.changePassword), payload);
    yield put(actions.changePasswordSuccess(response));
    yield put(addNotification({ msg: "Password has been successfully changed" }));
    yield put(actions.closeChangePasswordModal());
  } catch (error) {
    yield put(actions.changePasswordFailed(error));
  }
}

// Close Change Password Modal
function* closeChangePasswordModalWorker() {
  yield put(actions.getUserProfile());
}

// Change Tenant
function* setTenantWorker({ payload: tenant }) {
  try {
    const response = yield call(makeRequest(service.switchTenant), tenant?.value);
    const token = response?.token;
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("tenant", JSON.stringify(tenant));
      sessionStorage.setItem("tenant", JSON.stringify(tenant));
      navigate(routes.home);
      yield put(actions.getUserProfile());
    }
  } catch (error) {}
}

// Logout
function* logoutWorker() {
  try {
    yield put(actions.logoutSuccess());
    logout();
  } catch (error) {
    yield put(actions.loginFailed(error));
    logout();
  }
}

// Refresh
function* refreshWorker({ payload }) {
  try {
    const token = localStorage.getItem("token");
    const response = yield call(makeRequest(service.refresh), { token });
    const newToken = response?.token;
    if (newToken) {
      localStorage.setItem("token", newToken);
      yield put(actions.refreshSuccess(newToken));
    }
    if (payload?.action) {
      yield put(payload.action);
    }
  } catch (error) {
    logout();
  }
}

// Verification
function* verificationWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.verification), payload);
    yield put(actions.verificationSuccess(response));
    localStorage.setItem("token", response?.token);
    yield call(getTenantWorker, actions.getTenants);
    navigate(routes.home);
  } catch (error) {
    yield put(actions.verificationFailed(error));
  }
}

function* emailNotificationSubscriptionsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.emailNotificationSubscriptions), payload);
    yield put(actions.emailNotificationSubscriptionsSuccess(response));
  } catch (error) {
    yield put(actions.emailNotificationSubscriptionsFailed(error));
  }
}

// MFA
function* addMFAWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addMFA), payload);
    yield put(actions.addMFASuccess(response));
    yield put(actions.getUserProfile());
    yield put(actions.MFASuccessModal(true));
    yield put(actions.mfaModal(false));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addMFAFailed(error));
  }
}

function* removeMFAWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.removeMFA), payload);
    yield put(actions.removeMFASuccess(response));
    yield put(actions.getUserProfile());
    yield put(addNotification({ msg: "Success" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.removeMFAFailed(error));
  }
}

// Watchers End//

// Get User Profile
function* watchGetUserProfileWorker() {
  yield takeEvery(actions.getUserProfile, getUserProfileWorker);
}

function* watchGetTenantWorker() {
  yield takeEvery(actions.getTenants, getTenantWorker);
}

// Signup
function* watchSignUpWorker() {
  yield takeEvery(actions.signup, signUpWorker);
}

// Forgot Password
function* watchForgotPasswordWorker() {
  yield takeEvery(actions.forgotPassword, forgotPasswordWorker);
}

// Activate
function* watchActivateWorker() {
  yield takeEvery(actions.activate, activateWorker);
}

// Login
function* watchLoginWorker() {
  yield takeEvery(actions.login, loginWorker);
}

// Logout
function* watchLogout() {
  yield takeEvery(actions.logout, logoutWorker);
}

// Update Profile
function* watchUpdateProfile() {
  yield takeEvery(actions.updateProfile, updateProfileWorker);
}

// Change Password
function* watchChangePasswordProfile() {
  yield takeEvery(actions.changePassword, changePasswordWorker);
}

// Close Change Password Modal
function* watchCloseChangePasswordModalProfile() {
  yield takeEvery(actions.closeChangePasswordModal, closeChangePasswordModalWorker);
}

// Set tenant
function* watchSetTenant() {
  yield takeEvery(actions.setTenant, setTenantWorker);
}

// Refresh
function* watchRefresh() {
  yield takeEvery(actions.refresh, refreshWorker);
}

// Verification
function* watchVerification() {
  yield takeEvery(actions.verification, verificationWorker);
}

function* watchEmailNotificationSubscriptions() {
  yield takeEvery(actions.emailNotificationSubscriptions, emailNotificationSubscriptionsWorker);
}

// MFA
function* watchAddMFA() {
  yield takeEvery(actions.addMFA, addMFAWorker);
}

function* watchRemoveMFA() {
  yield takeEvery(actions.removeMFA, removeMFAWorker);
}

export default function* rootSaga() {
  yield all([
    fork(watchLogout),
    fork(watchGetUserProfileWorker),
    fork(watchGetTenantWorker),
    fork(watchSignUpWorker),
    fork(watchForgotPasswordWorker),
    fork(watchActivateWorker),
    fork(watchLoginWorker),
    fork(watchUpdateProfile),
    fork(watchRefresh),
    fork(watchVerification),
    fork(watchChangePasswordProfile),
    fork(watchSetTenant),
    fork(watchCloseChangePasswordModalProfile),
    fork(watchEmailNotificationSubscriptions),
    fork(watchAddMFA),
    fork(watchRemoveMFA)
  ]);
}
