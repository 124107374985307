import { Button as Btn, Menu, MenuItem, styled } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";

const StatusButton = styled(Btn)(({ bgcolor, width }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 12px",
    height: "30px",
    width,
    border: "none",
    background: bgcolor,
    borderRadius: "4px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
    "&:hover": {
      background: bgcolor
    },
    "& svg": {
      width: "16px"
    }
  };
});

const StyledMenu = styled(props => <Menu {...props} />)(({ width }) => ({
  "& .MuiPaper-root": {
    width
  },
  "& .MuiList-root": {
    padding: 0,
    fontSize: "14px"
  },
  "& .MuiMenuItem-root": {
    fontSize: "14px"
  }
}));

const MenuToggle = ({
  statusList,
  status,
  statusColor,
  onClick = () => {},
  onChange = () => {},
  width = "100px",
  showDropdownIcon = true
}) => {
  const [buttonRef, setButtonRef] = useState(null);
  const open = Boolean(buttonRef);

  const handleStatusButtonClick = e => {
    e.stopPropagation();
    setButtonRef(e.currentTarget);
    onChange();
  };

  const handleMenuItemClick = (e, option) => {
    e.stopPropagation();
    setButtonRef(null);
    onClick(option);
  };

  const handleMenuClose = () => {
    setButtonRef(null);
  };

  return (
    <div>
      <StatusButton onClick={handleStatusButtonClick} bgcolor={statusColor} width={width}>
        {status} {showDropdownIcon && <KeyboardArrowDownIcon />}
      </StatusButton>
      <StyledMenu open={open} onClose={handleMenuClose} anchorEl={buttonRef} width={width}>
        {statusList
          ?.filter(option => option !== status)
          ?.map((option, optionIndex) => (
            <MenuItem onClick={e => handleMenuItemClick(e, option)} key={optionIndex}>
              {option}
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};

export default MenuToggle;
