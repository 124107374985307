import React from "react";

import { Textarea } from "components/simple";

import styles from "./styles.module.scss";

const AddComment = ({ onChange, value }) => {
  return (
    <Textarea
      value={value}
      placeholder="Your comment"
      onChange={val => onChange(val, "comment")}
      label="Comment"
      className={styles.input}
    />
  );
};

export default AddComment;
