import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

const BarChartCpmponent = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Low" stackId="a" fill="#9EEC60" />
        <Bar dataKey="Medium" stackId="a" fill="#FEBD46" />
        <Bar dataKey="High" stackId="a" fill="#576ACA" />
        <Bar dataKey="Critical" stackId="a" fill="#EB5958" />
        <Bar dataKey="Negligible" stackId="a" fill="#bc6eb9" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartCpmponent;
