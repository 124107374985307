import React from "react";
import ComplianceTable from "./ComplianceTable";

function ExecutiveComplianceSummary({ data }) {
  return (
    <div className="w-full">
      <ComplianceTable rows={data} />
    </div>
  );
}

export default ExecutiveComplianceSummary;
