/* eslint-disable camelcase */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { routes } from "router";
import { onChangeUrlParams, useQuery } from "helper/history";

import {
  deleteTicket,
  closeTicket,
  getTickets,
  getTicketsConfig,
  setTicketsParams,
  getTicketsRelatedTypes,
  getTicketsStatusList
} from "store/entities/tickets/actions";
import { getLabelsMini } from "store/entities/labels/actions";

import {
  getTicketsSelector,
  ticketsRelatedTypesSelector,
  ticketsTableColumns,
  statusListSelector,
  ticketsConfigSelector
} from "store/entities/tickets/selectors";
import { labelsMiniSelector } from "store/entities/labels/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import Loader from "components/simple/Loader";
import {
  ContentContainer,
  Title,
  Search,
  Button,
  Select,
  DateRangePicker,
  CustomPagination
} from "components/simple";
import { Table } from "components/complex";

import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";

import FindingsBySeverity from "./TicketsDetails/components/FindingsBySeverity/FindingsBySeverity";
import { TicketByStatus } from "./TicketsDetails/components/TicketByStatus/TicketByStatus";
import { Widget } from "components/simple/Charts/widgets";
import { useRemidationCount } from "screens/CnappDashboard/service/query";
import { getAccuknoxWorkspace } from "api/api";
import RechartPieChart from "components/simple/Charts/Recharts/RechartPiechart";
import NoChart from "components/simple/Icon/Nochart";
import _ from "lodash";
import { REMEDIATIONS } from "screens/CnappDashboard/mockdata";
import { SingleProgressBar } from "components/simple/Charts/singleProgressbar";
import { SEVERITY_COLORS } from "screens/CnappDashboard/CnappDashboard";
import {
  ConvertCapitalizeText,
  EmptyPriorityData,
  MockData,
  PRIORITYCOLOR,
  PriorityOption,
  priorityValue,
  TopTicketsColumns
} from "./helper";
import TopTicketsTable from "./TopTicketTable";
import { useOpenTicketCount } from "../service";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

const Tickets = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");
  const queryStatus = query.get("status");
  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getTickets.type])(state));

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);
  const [selectedDate, setSelectedDate] = useState("");

  const {
    page,
    pageSize,
    filterBy,
    type,
    status,
    search,
    configuration,
    priority,
    range
  } = useSelector(state => state.tickets.ticketsParams);
  const { data, total } = useSelector(state => getTicketsSelector(state));
  const labelsOptions = useSelector(labelsMiniSelector);
  const typesOptions = useSelector(ticketsRelatedTypesSelector);
  const statusOptions = useSelector(statusListSelector);
  const configurationOptions = useSelector(ticketsConfigSelector);
  const workspaceId = getAccuknoxWorkspace();
  const { state: availableTicket } = useLocation();

  const clearUrl = () => {
    onChangeUrlParams("page", 0);
    onChangeUrlParams("status", 0);
  };
  const [remediation, setRemediation] = useState([]);
  // get default request
  const {
    data: remidationData = {},
    isLoading: RemidationLoading,
    isFetching: isRemidationisFetching,
    refetch: refetchRemediationCount
  } = useRemidationCount(workspaceId);
  const {
    data: openTicketDetail = {},
    isLoading: OpenTicketDetailLoading,
    isFetching: isOpenTicketDetailFetching
  } = useOpenTicketCount(workspaceId);
  useEffect(() => {
    if (!RemidationLoading) {
      const remidation = _.isEmpty(remidationData)
        ? []
        : Object.keys(remidationData)?.map((e, i) => {
            return {
              name: e,
              value: remidationData[e],
              color: REMEDIATIONS[i]
            };
          });
      setRemediation(remidation);
    }
  }, [JSON.stringify(remidationData), RemidationLoading]);
  const getRequest = useCallback(() => {
    onChangeUrlParams("page", 0);
    const statusVal = queryStatus ? ConvertCapitalizeText(queryStatus) : "";
    const statusSelect = statusVal
      ? { value: statusVal, label: statusVal }
      : availableTicket?.type
      ? availableTicket?.type
      : "";
    dispatch(
      setTicketsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: availableTicket?.ticketId ?? "",
        filterBy: "",
        type: "",
        status: statusSelect,
        priority: "",
        range: availableTicket?.time
      })
    );

    dispatch(getTicketsConfig());
    dispatch(getTicketsRelatedTypes());
    dispatch(getLabelsMini());
    dispatch(getTicketsStatusList());
  }, [queryPage, availableTicket?.ticketId, availableTicket?.type]);

  useEffect(() => {
    if (queryPage > 1) {
      onChangeUrlParams("page", 0);
    }
  }, [queryPage]);

  useEffect(() => {
    getRequest();
    return () => clearUrl();
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setTicketsParams({
        page: 0,
        search: v
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setTicketsParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setTicketsParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setTicketsParams({
          ordering: ""
        })
      );
    }
  };

  // Next page
  const onPageChange = (event, newPage) => {
    onChangeUrlParams("page", newPage);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setTicketsParams({
          page: newPage - 1
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setTicketsParams({
        pageSize: v
      })
    );
  };
  const onCellClick = (p, e) => {
    e.stopPropagation();
    const { id } = p;

    history.push(`${routes.ticketsDetails}/${id}`);
  };

  const deleteTicketsHandler = () => {
    dispatch(deleteTicket({ ids: selectionModel }));
  };

  const closeTicketsHandler = () => {
    dispatch(closeTicket({ ids: selectionModel }));
  };

  const filterChangeHandler = (option, variant) => {
    onChangeUrlParams("status", 0);
    onChangeUrlParams("page", 0);
    const value = !option ? "" : option;
    if (variant === "filter") {
      dispatch(
        setTicketsParams({
          filterBy: value,
          page: 0
        })
      );
      return;
    }
    if (variant === "data_type") {
      dispatch(
        setTicketsParams({
          type: value,
          page: 0
        })
      );
      return;
    }
    if (variant === "status") {
      dispatch(
        setTicketsParams({
          status: value,
          page: 0
        })
      );
      return;
    }
    if (variant === "configuration") {
      dispatch(
        setTicketsParams({
          configuration: value,
          page: 0
        })
      );
    }
    if (variant === "priority") {
      dispatch(
        setTicketsParams({
          priority: value,
          page: 0
        })
      );
    }
    if (variant === "range") {
      dispatch(
        setTicketsParams({
          range: value,
          page: 0
        })
      );
    }
  };

  const customStyles = {
    height: 710,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };
  const openDetails = {
    highest: 30,
    high: 117,
    medium: 282,
    low: 563,
    lowest: 3
  };
  const onPieChartClickHandle = (e, type) => {
    const statusVal = e ? ConvertCapitalizeText(e?.name) : "";
    const statusSelect = statusVal && { value: statusVal, label: statusVal };
    dispatch(
      setTicketsParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: "",
        filterBy: "",
        type: "",
        status: statusSelect,
        priority: "",
        range: ""
      })
    );
  };
  const onClickOfProgressBar = (e, count) => {
    const priorityVal = e ? ConvertCapitalizeText(e) : "";
    const prioritySelect = priorityVal && {
      value: priorityValue(priorityVal, PriorityOption),
      label: priorityVal
    };
    if (count) {
      dispatch(
        setTicketsParams({
          page: queryPage ? queryPage - 1 : 0,
          ordering: "",
          search: "",
          filterBy: "",
          type: "",
          status: { label: "Opened", value: "Opened" },
          priority: prioritySelect,
          range: ""
        })
      );
    }
  };
  return (
    <div className="h-full">
      <div className={`grid grid-cols-7 gap-4 mb-8`}>
        <Widget
          boxClass="col-span-2"
          isHeader={_.sumBy(remediation, "value")}
          title="Tickets by status"
          header="Total Tickets "
          totalCount={_.sumBy(remediation, "value") || 0}
          isHeaderClickable={false}
        >
          {RemidationLoading || isRemidationisFetching ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : _.sumBy(remediation, "value") ? (
            <RechartPieChart
              data={remediation}
              color={REMEDIATIONS}
              isLabelClickable
              onClickofChart={data => onPieChartClickHandle(data, "remediation")}
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <NoChart width={`50`} height={`50`} />
              <span className="mt-2 text-sm">No Graph data available!</span>
            </div>
          )}
        </Widget>
        <Widget
          boxClass="col-span-2"
          isHeader={openTicketDetail?.ticketsByPriority?.Total}
          title="Open Tickets by Priority"
          header="Open Tickets by Priority"
          onClickHeader={() => RedirectFindingVul()}
          totalCount={openTicketDetail?.ticketsByPriority?.Total || 0}
          isHeaderClickable={false}
        >
          {OpenTicketDetailLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : openTicketDetail?.ticketsByPriority?.Total ? (
            <SingleProgressBar
              onClickOfProgress={onClickOfProgressBar}
              data={openTicketDetail?.ticketsByPriority?.priorityList}
              percentage
              totalCount={openTicketDetail?.ticketsByPriority?.Total || 0}
              severityColors={PRIORITYCOLOR}
              EmptyData={EmptyPriorityData}
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <NoChart width={`50`} height={`50`} />
              <span className="mt-2 text-sm">No Graph data available!</span>
            </div>
          )}
        </Widget>
        <Widget boxClass="col-span-3" title="Top 5 Tickets by Age">
          <TopTicketsTable
            loading={OpenTicketDetailLoading}
            onCellClick={onCellClick}
            data={openTicketDetail?.ticketByAge}
            column={TopTicketsColumns}
          />
        </Widget>
      </div>
      <ContentContainer className={styles.container}>
        <Title>Tickets</Title>
        <div className={styles.searchRow}>
          <Search value={search} onSearch={handleSearch} />
          <div className={styles.buttonGroup}>
            <Button
              onClick={deleteTicketsHandler}
              className={styles.btn}
              disabled={!selectionModel.length}
            >
              Delete
            </Button>
            <Button
              onClick={closeTicketsHandler}
              className={styles.btn}
              disabled={!selectionModel.length}
            >
              Close
            </Button>
          </div>
        </div>
        <div className={styles.filterRow}>
          <Select
            containerClass={styles.select}
            onChange={v => filterChangeHandler(v, "filter")}
            value={filterBy}
            placeholder="Type: Label"
            options={labelsOptions}
            isClearable
          />
          <Select
            containerClass={styles.select}
            onChange={v => filterChangeHandler(v, "data_type")}
            value={type}
            placeholder="Type: Data type"
            options={typesOptions}
            isClearable
          />
          <Select
            containerClass={styles.select}
            onChange={v => filterChangeHandler(v, "status")}
            value={status}
            placeholder="Type: Status"
            options={statusOptions}
            isClearable
          />
          <Select
            containerClass={styles.select}
            onChange={v => filterChangeHandler(v, "configuration")}
            value={configuration}
            placeholder="Type: Configuration"
            options={configurationOptions}
            isClearable
          />
          <Select
            containerClass={styles.select}
            onChange={v => filterChangeHandler(v, "priority")}
            value={priority}
            placeholder="Type: Priority"
            options={PriorityOption}
            isClearable
          />
          <DateRangePicker
            placeholder="Type: Date"
            clearable
            onChange={v => filterChangeHandler(v, "range")}
            value={range}
            className={styles.datePicker}
          />
        </div>

        <div className={styles.table}>
          <Table
            data={isLoading ? [] : data}
            columns={ticketsTableColumns}
            loading={isLoading}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel);
            }}
            page={page || 0}
            selectionModel={selectionModel}
            onSortModelChange={model => handleSort(model)}
            pageSize={pageSize}
            // rowsPerPageOptions={[5, 10, 20, 50]}
            // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowCount={total}
            paginationMode="server"
            // onPageChange={onPageChange}
            initialState={{ pinnedColumns: { left: ["__check__"] } }}
            onCellClick={(p, e) => onCellClick(p, e)}
            customStyles={customStyles}
            components={{
              Pagination: CustomPagination
            }}
            componentsProps={{
              pagination: {
                count: FindTotalPage(total, pageSize),
                onChange: onPageChange,
                page: page + 1,
                totalCount: total || 0,
                selected: pageSize,
                perPageChange: (event, value) => {
                  setPageSize(event?.target?.value);
                }
              }
            }}
          />
        </div>
      </ContentContainer>
    </div>
  );
};

Tickets.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Tickets;
