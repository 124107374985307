import React, { useState, useEffect } from "react";

import moment from "moment";
import { Box, Typography } from "@mui/material";

import styles from "../styles.module.scss";
import { Table } from "components/complex";
import BasicModal from "components/simple/Modal/Modal";
import { Button, CustomPagination, Checkbox } from "components/simple";
import { useCategoriesQuery, usePermissionsQuery } from "store/entities/rbac/queries";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

export const customStyles = {
  height: 710,
  width: 1,
  padding: "30px",
  paddingTop: 0,
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "none !important",
    borderTop: "none !important"
  },
  "& .MuiDataGrid-pinnedColumnHeaders": {
    boxShadow: "none !important"
  },
  "& .MuiDataGrid-pinnedColumns": {
    boxShadow: "none !important",
    "& .super-app-theme--row": {
      borderTopLeftRadius: "10px !important",
      borderBottomLeftRadius: "10px !important"
    }
  },
  "& .super-app-theme--header": {
    backgroundColor: "#fff"
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .super-app-theme--row": {
      borderBottom: "none !important",
      borderBottomRightRadius: "10px",
      borderTopRightRadius: "10px"
    }
  }
};

export const getTableColumns = (activeTab, roleMap, MenuComponent) => {
  return [
    {
      flex: 2,
      field: "first_name",
      headerName: "FIRST NAME",
      headerClassName: "super-app-theme--header"
    },
    {
      flex: 2,
      field: "last_name",
      headerName: "LAST NAME",
      headerClassName: "super-app-theme--header"
    },
    {
      flex: 3,
      field: "email",
      headerName: "EMAIL",
      headerClassName: "super-app-theme--header"
    },
    {
      flex: 2,
      field: "role",
      headerName: "Role",
      headerClassName: "super-app-theme--header",
      renderCell: params => (params?.row?.is_tenant_admin ? "Admin" : params?.value || "-")
    },
    {
      flex: 3,
      field: "recent_login",
      headerName: "RECENT LOGIN",
      headerClassName: "super-app-theme--header",
      renderCell: params =>
        params?.value ? moment(params?.value).format("MM-DD-YYYY hh:mm:ss A") : "-"
    },
    {
      flex: 1,
      headerName: "",
      align: "center",
      field: "user_option",
      headerClassName: "super-app-theme--header",
      renderCell: params => <MenuComponent user={params?.row} />
    }
  ];
};

export const UsersTable = ({ activeTab, users, roleMap, MenuComponent = <></> }) => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const tableConfig = {
    hideSelectedRowCount: true,
    checkboxVisible: false
  };

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  return (
    <Table
      data={users?.data?.results || []}
      columns={getTableColumns(activeTab, roleMap, MenuComponent)}
      loading={users?.isLoading}
      pageSize={pageSize}
      page={page - 1}
      rowCount={users?.data?.count || 0}
      onSortModelChange={() => {}}
      customStyles={customStyles}
      {...tableConfig}
      components={{
        Pagination: CustomPagination
      }}
      componentsProps={{
        pagination: {
          count: FindTotalPage(users?.data?.count, pageSize),
          onChange: (event, value) => {
            setPage(value);
          },
          page: page,
          totalCount: users?.data?.count || 0,
          selected: pageSize,
          perPageChange: (event, value) => {
            setPageSize(event?.target?.value);
          }
        }
      }}
    />
  );
};

export const ConfirmationModal = ({
  isActive,
  heading,
  handleCancel,
  handleAction,
  actionText
}) => {
  return (
    <BasicModal isOpen={isActive} toggle={handleCancel} width="160vw" heading="Confirmation">
      <div className="body flex flex-col px-2 pt-6">
        <Typography>{heading}</Typography>
        <div className="flex justify-between pt-8">
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleAction} variant="contained">
            {actionText}
          </Button>
        </div>
      </div>
    </BasicModal>
  );
};

export const ViewPermissionsModal = ({
  isActive,
  handleCancel,
  selectedUserRoleDetails,
  is_tenant_admin
}) => {
  const categoriesQuery = useCategoriesQuery();
  const permissionsQuery = usePermissionsQuery({ id: "*", name: "Full Access" });

  const categoricalPermissions = categoriesQuery?.data?.slice(1).map(c => {
    return {
      categoryName: c.name,
      categoryPermissions: is_tenant_admin
        ? permissionsQuery?.data
            ?.slice(1)
            .filter(p => c?.name === p?.category)
            .map(p => {
              return { label: p.label, isTrue: true };
            })
        : permissionsQuery?.data
            ?.slice(1)
            .filter(p => c?.name === p?.category)
            .map(p =>
              selectedUserRoleDetails?.visible_permissions.includes(p.id)
                ? { label: p.label, isTrue: true }
                : { label: p.label, isTrue: false }
            )
    };
  });

  return (
    <BasicModal isOpen={isActive} toggle={handleCancel} heading="Permissions">
      <Box className={styles.permissionmodel}>
        <div className="width-full flex- h-[60vh] overflow-auto p-1">
          {categoricalPermissions?.map((c, ind) => (
            <div key={ind}>
              <Typography variant="body1">{c?.categoryName}</Typography>
              <div
                style={{
                  display: "flex",
                  marginLeft: "20px",
                  flexDirection: "column"
                }}
              >
                {c.categoryPermissions?.map((p, index) => (
                  <Checkbox key={index} disabled={true} label={p.label} value={p.isTrue} />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-2">
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>{" "}
        </div>
      </Box>
    </BasicModal>
  );
};
