import { useQuery, useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { customGet, customPost } from "api/base";
import { getAccuknoxWorkspace } from "api/api";

export const useGetListOfRegistryQuery = (startIndex, pageSize) => {
  const body = {
    workspace_id: getAccuknoxWorkspace(),
    page_previous: startIndex,
    page_next: pageSize
  };

  return useQuery(
    ["getRegistryList", startIndex, pageSize],
    async () => {
      const response = await customPost(
        "/vulnerabilityservice/api/v1/registry/get-list-of-registry",
        body
      );
      const res = await response.json();

      return res;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      select: data => {
        if (!data?.list_of_registry) {
          return { ...data, list_of_registry: [] };
        }
        return data;
      }
    }
  );
};

export const useGetRegistryTypesQuery = () => {
  const body = {
    page_previous: 0,
    page_next: 10
  };

  return useQuery(
    ["getResistryTypesQuery"],
    async () => {
      const response = await customPost(
        "/vulnerabilityservice/api/v1/registry/get-registry-type",
        body
      );
      const res = await response.json();

      return res;
    },
    {
      refetchOnWindowFocus: false,
      select: data => {
        return data?.list_of_registry_type?.map(item => ({
          value: item?.id,
          label: item?.name
        }));
      }
    }
  );
};

export const useGetGCRRegionQuery = registryTypeId => {
  const body = {
    registry_id: registryTypeId,
    page_previous: 0,
    page_next: 10
  };

  return useQuery(
    ["getRegion"],
    async () => {
      const response = await customPost(
        "/vulnerabilityservice/api/v1/registry/get-list-of-regions",
        body
      );
      const res = await response.json();

      return res;
    },
    {
      refetchOnWindowFocus: false,
      select: data => {
        return {
          data: data?.list_of_region?.map(item => ({
            value: item?.id,
            label: item?.name
          })),
          total: data?.total_record
        };
      }
    }
  );
};

export const useGetRegistryQuery = registryId => {
  const body = {
    id: registryId,
    workspace_id: getAccuknoxWorkspace()
  };

  return useQuery(
    ["getRegistry"],
    async () => {
      const response = await customPost("/vulnerabilityservice/api/v1/registry/get-registry", body);
      const res = await response.json();

      return res;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};
