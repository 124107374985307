/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { routes } from "router";

import { getDashboard } from "store/entities/dashboard/actions";
import { createLoadingSelector } from "store/entities/loading/selector";

import {
  dashboardSelector,
  getOverviewDataSelector,
  getPieChartsDataSelector,
  vulnerabilitiesChartSelector
} from "store/entities/dashboard/selectors";

import { ContentContainer, Title, Icon, PieChart, DateRangePicker } from "components/simple";
import { columnsMostVulnerableAssets, columnsScansData, topRiskColumn } from "./tableColumns";

import { Table } from "components/complex";

import Overview from "./Overview";
import BarChart from "./BarChart";

import styles from "./styles.module.scss";
import { dayBetween } from "helper/DateLib";

const Home = ({ history }) => {
  const dispatch = useDispatch();
  const selectTenant = useSelector(state => state.users.selectTenant);
  const getTenantSuccess = useSelector(state => state.users.getTenantSuccess);
  const isLoading = useSelector(state => createLoadingSelector([getDashboard.type])(state));

  const { most_vulnerable_assets, scans_data, topRiskData } = useSelector(dashboardSelector);
  const { vulnerabilities, compliance, tickets, assets } = useSelector(getPieChartsDataSelector);
  const overview = useSelector(getOverviewDataSelector);
  const vulnerabilitiesChart = useSelector(vulnerabilitiesChartSelector);

  const [datePicker, setDatePicker] = useState([new Date(Date.now() - 12096e5), new Date()]);

  const hangleChangeDate = v => {
    setDatePicker(v);
    dispatch(getDashboard({ date: v }));
  };

  useEffect(() => {
    if (getTenantSuccess) {
      dispatch(getDashboard({ date: datePicker }));
    }
  }, [selectTenant, getTenantSuccess]);

  const customStyles = {
    height: 710,
    width: 1,
    // padding: "30px",
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "1px solid #E7E6F8",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important"
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #E7E6F8",
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important",
        "&:focus, &:active": {
          outline: "none !important"
        }
      }
    }
  };

  const tableConfig = {
    checkboxVisible: false,
    paginationVisible: false,
    hideFooter: true,
    sortingMode: false
  };

  const onClickTopRiskData = (p, e) => {
    e.stopPropagation();
    const id = p?.row?.asset_id;
    history.push(`${routes.dataStructureAssetsDetails}/${id}`);
  };
  const onClickMostVulnerableAssets = (p, e) => {
    e.stopPropagation();
    const { id } = p;
    history.push(`${routes.dataStructureAssetsDetails}/${id}`);
  };
  const onAssetClick = e => {
    e.stopPropagation();
    const assetKey = e.target.closest("div").dataset.key;
    if (!assetKey) return;
    history.push(`${routes.dataStructureAssets}?filter=${assetKey}`);
  };

  const onClickScansData = () => {
    // e.stopPropagation();
    // const { id } = p;
    // history.push(`${routes.dataStructureHistory}`);
  };

  return (
    <div>
      <ContentContainer className={styles.filter}>
        <Title>Dashboard</Title>
        <br />
        <div className={styles.datePicker}>
          <DateRangePicker
            label="Filter by date"
            placeholder="Filter by date"
            onChange={v => hangleChangeDate(v)}
            value={datePicker || ""}
            clearable={false}
          />
        </div>
      </ContentContainer>

      <div className={styles.chartContainer}>
        <ContentContainer className={styles.wrapper}>
          <div className={styles.head}>
            <Title>Vulnerabilities</Title>
            <div className={styles.btn} onClick={() => history.push(routes.vulnerabilities)}>
              <div className={styles.iconBtn}>
                <Icon.ArrowBack color="#2B3858" />
              </div>
            </div>
          </div>
          <br />
          <PieChart
            data={vulnerabilities}
            className={styles.chart}
            onClick={v => {
              const val = vulnerabilities[v]?.key;
              const res = { risk_factor: { value: val, label: val } };
              history.push(`${routes.vulnerabilities}?filters=${JSON.stringify(res)}`);
            }}
          />
        </ContentContainer>
        {/* Compliance */}
        <ContentContainer className={styles.wrapper}>
          <div className={styles.head}>
            <Title>Compliance</Title>
            <div className={styles.btn} onClick={() => history.push(routes.baselines)}>
              <div className={styles.iconBtn}>
                <Icon.ArrowBack color="#2B3858" />
              </div>
            </div>
          </div>
          <br />
          <PieChart
            data={compliance}
            className={styles.chart}
            onClick={v => history.push(`${routes.baselines}?filter=${compliance[v]?.key}`)}
          />
        </ContentContainer>
        {/* Tickets */}
        <ContentContainer className={styles.wrapper}>
          <div className={styles.head}>
            <Title>Tickets</Title>
            <div className={styles.btn} onClick={() => history.push(routes.tickets)}>
              <div className={styles.iconBtn}>
                <Icon.ArrowBack color="#2B3858" />
              </div>
            </div>
          </div>
          <br />
          <PieChart
            data={tickets}
            className={styles.chart}
            onClick={v => {
              const val = tickets[v]?.key;
              history.push(`${routes.tickets}?status=${val}`);
            }}
          />
        </ContentContainer>
        {/* Assets */}
        <ContentContainer className={styles.wrapper}>
          <div className={styles.head}>
            <Title>Assets</Title>
            <div className={styles.btn} onClick={() => history.push(routes.dataStructureAssets)}>
              <div className={styles.iconBtn}>
                <Icon.ArrowBack color="#2B3858" />
              </div>
            </div>
          </div>
          <br />
          <div onClick={onAssetClick}>
            {assets.map(asset => {
              return (
                <div className={styles.asset} key={asset.key} data-key={asset.key}>
                  <p>{asset.title}</p>
                  <p>{asset.value}</p>
                </div>
              );
            })}
          </div>
        </ContentContainer>
      </div>
      {overview && <Overview data={overview} days={dayBetween(datePicker[1], datePicker[0])} />}
      <div className={styles.row}>
        <ContentContainer className={styles.wrapper}>
          <div className={styles.head}>
            <Title>Most Vulnerable Assets</Title>
          </div>
          <Table
            {...tableConfig}
            data={isLoading ? [] : most_vulnerable_assets}
            columns={columnsMostVulnerableAssets}
            loading={isLoading}
            onCellClick={(p, e) => onClickMostVulnerableAssets(p, e)}
            customStyles={customStyles}
          />
        </ContentContainer>
        <ContentContainer className={styles.wrapper}>
          <div className={styles.head}>
            <Title>Scans to Data</Title>
          </div>
          <Table
            {...tableConfig}
            data={isLoading ? [] : scans_data}
            columns={columnsScansData}
            loading={isLoading}
            onCellClick={(p, e) => onClickScansData(p, e)}
            customStyles={customStyles}
          />
        </ContentContainer>
      </div>

      <div className={styles.row2}>
        <ContentContainer className={styles.wrapper}>
          <div className={styles.head}>
            <Title>Top risks</Title>
          </div>
          <Table
            {...tableConfig}
            data={isLoading ? [] : topRiskData}
            columns={topRiskColumn}
            loading={isLoading}
            onCellClick={(p, e) => onClickTopRiskData(p, e)}
            customStyles={customStyles}
          />
        </ContentContainer>
        <ContentContainer className={styles.wrapper}>
          <div className={styles.head}>
            <Title>Vulnerabilities By Source</Title>
          </div>
          <br />
          <BarChart data={vulnerabilitiesChart} className="" />
        </ContentContainer>
      </div>
    </div>
  );
};

Home.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Home;
