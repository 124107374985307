import React, { useState, useCallback } from "react";
import { PieChart, Pie, Cell, Sector } from "recharts";

import styles from "./styles.module.scss";

// example
// const data = [
//   { title: "Low", value: 10, color: "#9EEC60" },
//   { title: "Critical", value: 15, color: "#EB5958" }
// ];

const PieChartComponent = ({
  className,
  data = [],
  viewBoxSize = [120, 120],
  center = [60, 60],
  onClick = () => null
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const onPieLeave = useCallback(
    (_, index) => {
      setActiveIndex(-1);
    },
    [setActiveIndex]
  );
  const renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, midAngle, value } = props;
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          fill={fill}
          innerRadius={innerRadius + 10}
          outerRadius={outerRadius + 10}
          startAngle={startAngle}
          endAngle={endAngle}
        />
        <text
          x={-x}
          y={y}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          style={{
            fill: "#000",
            fontSize: "14px",
            fontWeight: "600",
            transform: "scale(-1, 1)"
          }}
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        {data.slice(0, 4).map((item, index) => (
          <div className={styles.row} key={index}>
            <div className={styles.name}>{item?.title}</div>
            <div className={styles.color} style={{ background: item?.color }} />
          </div>
        ))}
      </div>
      <div className={`${styles.chart} ${className}`}>
        <PieChart width={200} height={200}>
          <Pie
            cx="50%"
            cy="50%"
            data={data}
            nameKey="name"
            dataKey="value"
            startAngle={90}
            endAngle={360}
            innerRadius={30}
            outerRadius={80}
            labelLine={false}
            onClick={(event, index) => {
              onClick(index);
            }}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry?.color} stroke="none" />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className={styles.footer}>
        <div className={`${styles.list} ${styles.list2}`}>
          {data.slice(4, 8).map((item, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.name}>{item?.title}</div>
              <div className={styles.color} style={{ background: item?.color }} />
            </div>
          ))}
        </div>
        <div className={`${styles.list} ${styles.list3}`}>
          {data.slice(8, 12).map((item, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.name}>{item?.title}</div>
              <div className={styles.color} style={{ background: item?.color }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PieChartComponent;
