import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Title, Modal } from "components/simple";
import { permissionsModal } from "store/entities/settings/actions";
import { routes } from "router";

import styles from "./styles.module.scss";

const PermissionsModal = () => {
  const open = useSelector(st => st.settings.permissionsModal);
  const history = useHistory();
  const dispatch = useDispatch();

  const close = () => {
    dispatch(permissionsModal(false));
    history.push(routes.dashboard);
  };

  return (
    <Modal isOpen={open} toggle={close} width={425}>
      <Title>You do not have permissions to the Settings</Title>
      <div className={styles.footer}>
        <Button className={styles.modalButton} onClick={close}>
          Got it
        </Button>
      </div>
    </Modal>
  );
};

export default PermissionsModal;
