/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { Title, Select, Button } from "components/simple";
import { useSelector, useDispatch } from "react-redux";
import { setBaselineConfigParams, getBaselineConfigFilters } from "store/entities/baseline/actions";
import { getBaselineConfigFiltersSelect } from "store/entities/baseline/selectors";

import styles from "./styles.module.scss";

const Panel = ({ onEdit, onCollapse, onDuplicate, title }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector(state => state.baseline.baselineConfigParams);

  const baselineConfigFiltersSelect = useSelector(getBaselineConfigFiltersSelect);
  const selectTenant = useSelector(st => st.users.selectTenant);

  useEffect(() => {
    dispatch(getBaselineConfigFilters());
  }, [selectTenant]);

  const resultList = baselineConfigFiltersSelect?.result;
  const expectedList = baselineConfigFiltersSelect?.expected_result;
  const ticketList = baselineConfigFiltersSelect?.has_tickets;
  const annotationList = baselineConfigFiltersSelect.annotation;

  const hangleChange = (val, name) => {
    dispatch(
      setBaselineConfigParams({
        page: 0,
        filters: {
          ...filters,
          [name]: val
        }
      })
    );
  };

  return (
    <div className={styles.panel}>
      <div className={styles.panelHead}>
        <Title>{title}</Title>
        <div className={styles.buttonGroup}>
          <button type="button" className={styles.button} onClick={onDuplicate}>
            Duplicate
          </button>
        </div>
      </div>
      <div className={styles.row}>
        <Select
          containerClass={styles.select}
          onChange={v => hangleChange(v, "result")}
          value={filters?.result || ""}
          placeholder="Result"
          label="Result"
          options={resultList}
          isClearable
        />
        <Select
          containerClass={styles.select}
          onChange={v => hangleChange(v, "expected")}
          value={filters?.expected || ""}
          placeholder="Expected Value"
          label="Expected Value"
          options={expectedList}
          isClearable
        />
        <Select
          containerClass={styles.select}
          onChange={v => hangleChange(v, "ticket")}
          value={filters?.ticket || ""}
          placeholder="Ticket(s) Created"
          label="Ticket(s) Created"
          options={ticketList}
          isClearable
        />
        <Select
          containerClass={styles.select}
          onChange={v => hangleChange(v, "annotation")}
          value={filters?.annotation || ""}
          placeholder="Annotation "
          label="Annotation "
          options={annotationList}
          isClearable
        />
      </div>
      <div className={styles.buttonGroup}>
        <Button className={styles.editButton} onClick={onCollapse}>
          Edit Fields
        </Button>
        <Button className={styles.editButton} onClick={onEdit}>
          Edit Sources
        </Button>
      </div>
    </div>
  );
};

Panel.propTypes = {};

export default Panel;
