import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "react-router-redux";

import reducer from "./reducers";
import sagas from "./sagas";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];

const configureRootStore = () => {
  const store = configureStore({
    reducer,
    middleware: [...middlewares]
  });

  sagaMiddleware.run(sagas);

  return store;
};

export default configureRootStore();
