import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Title, Input, Button, ContentContainer, Select } from "components/simple";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useForm, Controller } from "react-hook-form";
import {
  AWS_ACCESS_KEY,
  AWS_SECRET_KEY,
  EDIT_AWS_CLOUD_WATCH_LINK,
  helpAWS,
  regions
} from "screens/ChannelIntegration/constants";
import AWSIcon from "../../assets/img/aws.svg";
import { Typography, Tooltip } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import ErrorText from "components/simple/ErrorText";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import DeleteModal from "./DeleteModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getAccuknoxWorkspace } from "api/api";
import {
  useAwsCloudIntegration,
  useAWSCloudTest,
  useDeleteChannel,
  useEditAwsCloudIntegration
} from "store/entities/channels/mutation";

function AWSIntegration() {
  const [integrationName, setIntegrationName] = useState("");
  const [awsAccessKey, setAwsAccessKey] = useState("");
  const [awsSecretKey, setAwsSecretKey] = useState("");
  const [region, setRegion] = useState("us-east-2");
  const [logGroupName, setLogGroupName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const workspaceVal = getAccuknoxWorkspace();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: "onChange" });
  const history = useHistory();
  const location = useLocation();

  // edit stuffs
  const decryptAccess = location?.state?.AccessKey?.split(",") ?? "";
  const decryptSecret = location?.state?.SecretKey?.split(",") ?? "";
  const awsAccess = CryptoJS.AES.decrypt(decryptAccess[0] ?? "", decryptAccess[1] ?? "");
  const awsSecret = CryptoJS.AES.decrypt(decryptSecret[0] ?? "", decryptSecret[1] ?? "");

  const getIntegrationName = location.state?.IntegrationName;
  const getAccesskey = awsAccess.toString(CryptoJS.enc.Utf8);
  const getSecretKey = awsSecret.toString(CryptoJS.enc.Utf8);
  const getRegion = location.state?.Region;
  const getLogGroupName = location.state?.LogGroupName;

  useEffect(() => {
    if (
      (location?.pathname === EDIT_AWS_CLOUD_WATCH_LINK && integrationName) ||
      awsAccessKey ||
      awsSecretKey ||
      region ||
      logGroupName
    ) {
      setValue("integrationName", integrationName);
      setValue("AWSKey", awsAccessKey);
      setValue("AWSKeySecretKey", awsSecretKey);
      setValue("regionAWS", region);
      setValue("logGroup", logGroupName);
    }
  }, [integrationName, awsAccessKey, awsSecretKey, region, logGroupName]);
  useEffect(() => {
    if (location?.pathname === EDIT_AWS_CLOUD_WATCH_LINK) {
      setIntegrationName(getIntegrationName);
      setAwsAccessKey(getAccesskey);
      setAwsSecretKey(getSecretKey);
      setRegion(getRegion);
      setLogGroupName(getLogGroupName);
    }
  }, []);
  const awsCloudWatch = useAwsCloudIntegration(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "AWSCloudwatch test success!", type: "success" }));
    } else {
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });
  const handleTest = async e => {
    try {
      const secretkey1 = (Math.random() + 1).toString(36).substring(7);
      const secretkey2 = (Math.random() + 1).toString(36).substring(7);
      const encryptedAccesskey = CryptoJS.AES.encrypt(awsAccessKey, secretkey1).toString();
      const encryptedsecretkey = CryptoJS.AES.encrypt(awsSecretKey, secretkey2).toString();
      const accessKey = encryptedAccesskey.concat(",", secretkey1);
      const secretKey = encryptedsecretkey.concat(",", secretkey2);
      const option = "test";
      awsCloudWatch.mutate({
        integrationName,
        accessKey,
        secretKey,
        region,
        logGroupName,
        option
      });
    } catch (err) {
      // console.log(err);
    }
  };
  const awsCloudWatchSave = useAwsCloudIntegration(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "CloudWatch Integration Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });
  const awsCloudWatchUpdate = useEditAwsCloudIntegration(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "CloudWatch Updated Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });

  const onSubmit = async e => {
    if (location?.pathname === EDIT_AWS_CLOUD_WATCH_LINK) {
      try {
        const secretkey1 = (Math.random() + 1).toString(36).substring(7);
        const secretkey2 = (Math.random() + 1).toString(36).substring(7);
        const encryptedAccesskey = CryptoJS.AES.encrypt(awsAccessKey, secretkey1).toString();
        const encryptedsecretkey = CryptoJS.AES.encrypt(awsSecretKey, secretkey2).toString();
        const accessKey = encryptedAccesskey.concat(",", secretkey1);
        const secretKey = encryptedsecretkey.concat(",", secretkey2);
        const ID = location?.state?.id;

        awsCloudWatchUpdate.mutate({
          ID,
          integrationName,
          accessKey,
          secretKey,
          region,
          logGroupName
        });
      } catch (err) {
        // console.log(err)
      }
    } else {
      try {
        const secretkey1 = (Math.random() + 1).toString(36).substring(7);
        const secretkey2 = (Math.random() + 1).toString(36).substring(7);
        const encryptedAccesskey = CryptoJS.AES.encrypt(awsAccessKey, secretkey1).toString();
        const encryptedsecretkey = CryptoJS.AES.encrypt(awsSecretKey, secretkey2).toString();
        const accessKey = encryptedAccesskey.concat(",", secretkey1);
        const secretKey = encryptedsecretkey.concat(",", secretkey2);
        const option = "save";
        awsCloudWatchSave.mutate({
          integrationName,
          accessKey,
          secretKey,
          region,
          logGroupName,
          option
        });
      } catch (err) {
        // console.log(err)
      }
    }
  };
  const closeHandler = () => {
    setIsOpen(false);
  };

  const deleteAwsCloudChannel = useDeleteChannel(data => {
    history.push("/settings/integrations/list");
    dispatch(addNotification({ msg: "Status: Deleted CloudWatch Integration", type: "success" }));
  });
  const handleDelete = async () => {
    const ID = location?.state?.id;
    const channeId = 3;
    deleteAwsCloudChannel.mutate({
      channeId,
      ID
    });
  };
  return (
    <ContentContainer className={styles.container}>
      <DeleteModal
        isModalOpen={isOpen}
        onCloseModal={() => closeHandler()}
        onSubmit={() => handleDelete()}
      />
      <div className="flex justify-between py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex gap-1">
          <img src={AWSIcon} alt="icon" />
          <Title>AWS CloudWatch</Title>
        </div>
        <div className="flex justify-center items-center gap-5">
          {location?.pathname === EDIT_AWS_CLOUD_WATCH_LINK && (
            <div
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => setIsOpen(true)}
            >
              <DeleteOutlineIcon />
              Delete Channel
            </div>
          )}
          <div
            onClick={() => window.open(helpAWS, "_blank", "noopener,noreferrer")}
            className="flex justify-center items-center text-blue-700 font-medium cursor-pointer"
          >
            Help <HelpOutlineIcon className={styles.help_icon} />
          </div>
        </div>
      </div>
      <div className="py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex flex-row gap-5">
          <div className="w-7/12">
            <Controller
              name="integrationName"
              {...register("integrationName", {
                required: "This Field Cannot be empty",
                maxLength: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="integrationName"
                  label="Integration Name"
                  placeholder="Enter a name for integration"
                  required
                  value={integrationName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setIntegrationName(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.integrationName && errors.integrationName.message} />
            </div>
          </div>
          <div className="w-full">
            <Controller
              name="AWSKey"
              {...register("AWSKey", {
                required: "AWS Key is Required",
                maxLength: {
                  value: 128,
                  message: "Maximum length for this field is 128 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="AWSKey"
                  label={
                    <span>
                      AWS Access Key
                      <Tooltip title={AWS_ACCESS_KEY} placement="top">
                        <InfoOutlinedIcon className={styles.infoIcon} />
                      </Tooltip>
                    </span>
                  }
                  placeholder="Enter the Access key"
                  required
                  value={awsAccessKey}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setAwsAccessKey(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.AWSKey && errors.AWSKey.message} />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5 mt-5">
          <div style={{ width: "36%" }}>
            <Controller
              name="AWSKeySecretKey"
              {...register("AWSKeySecretKey", {
                required: "AWS Secret Key is Required",
                maxLength: {
                  value: 128,
                  message: "Maximum length for this field is 128 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="AWSKeySecretKey"
                  label={
                    <span>
                      AWS Secret Key
                      <Tooltip title={AWS_SECRET_KEY} placement="top">
                        <InfoOutlinedIcon className={styles.infoIcon} />
                      </Tooltip>
                    </span>
                  }
                  placeholder="Enter the Secret key"
                  required
                  value={awsSecretKey}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setAwsSecretKey(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.AWSKeySecretKey && errors.AWSKeySecretKey.message} />
            </div>
          </div>
          <div className="w-1/3">
            <Typography className={styles.typo}>Region</Typography>
            <Controller
              name="regionAWS"
              {...register("regionAWS", {
                required: true
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <div style={{ paddingTop: "10px" }}>
                  <Select
                    name="regionAWS"
                    placeholder="Select the region"
                    options={regions}
                    onChange={data => {
                      onChange(data);
                      setRegion(data?.label);
                    }}
                    value={{ label: region, value: region }}
                  />
                </div>
              )}
            />
          </div>
          <div style={{ width: "36%" }}>
            <Controller
              name="logGroup"
              {...register("logGroup", {
                required: "LogGroup Name is Required"
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="logGroup"
                  label={"LogGroup Name"}
                  placeholder="Enter the LogGroup Name"
                  required
                  value={logGroupName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setLogGroupName(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.logGroup && errors.logGroup.message} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between pt-6 pb-4">
        <Button onClick={handleSubmit(handleTest)} disabled={!isDirty || !isValid}>
          Test
        </Button>
        <div className="flex">
          <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} disabled={!isValid} type="submit">
            Save
          </Button>
        </div>
      </div>
    </ContentContainer>
  );
}

export default AWSIntegration;
