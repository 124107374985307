import { get, post, put, remove } from "api/base";
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";

export const getRoles = async (page, pageSize) => {
  const response = await get(
    page && pageSize ? `${API}/roles?page=${page}&page_size=${pageSize}` : `${API}/roles`,
    {
      ...contentTypeJson(),
      ...authorization(),
      ...authorizationTenant()
    }
  );
  const data = await response.json();
  return data;
};

export const getCategories = async () => {
  const response = await get(`${API}/category-permissions`, {
    ...contentTypeJson(),
    ...authorization(),
    ...authorizationTenant()
  });
  const data = await response.json();
  return data;
};

export const getPermissions = async () => {
  const response = await get(`${API}/visible-permissions`, {
    ...contentTypeJson(),
    ...authorization(),
    ...authorizationTenant()
  });
  const data = await response.json();
  return data;
};

export const addRole = async data => {
  const response = await post(`${API}/roles`, JSON.stringify(data), {
    ...contentTypeJson(),
    ...authorization(),
    ...authorizationTenant()
  });
  if (!response.ok) {
    throw response;
  }
  return response;
};

export const editRole = async ({ roleId, data }) => {
  const response = await put(`${API}/roles/${roleId}`, JSON.stringify(data), {
    ...contentTypeJson(),
    ...authorization(),
    ...authorizationTenant()
  });
  if (!response.ok) {
    throw response;
  }
  return response;
};

export const deleteRole = async roleId => {
  const response = await remove(`${API}/roles/${roleId}`, "", {
    ...contentTypeJson(),
    ...authorization(),
    ...authorizationTenant()
  });
  if (!response.ok) {
    throw response;
  }
  return response;
};
