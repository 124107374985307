import { Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";

export default function ButtonLink({
  onClick = () => {},
  isUnderline = true,
  fontsize = "14px",
  fontweight = "normal",
  buttonLable,
  className
}) {
  return (
    <div onClick={onClick} className={className}>
      <Typography
        sx={{ fontSize: fontsize, fontWeight: fontweight, lineHeight: 0 }}
        className={isUnderline ? styles.defaultline : styles.button_link}
      >
        {buttonLable}
      </Typography>
    </div>
  );
}
