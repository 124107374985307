import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Table } from "components/complex";
import { ActionButton, DateRangePicker, Search, Select, Title } from "components/simple";
import { formatDate } from "helper/DateLib";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "router";
import { CreateGroupModal } from "screens/Issues/Vulnerabilities/CreateGroupModal";
import CreateTagModal from "screens/Issues/Vulnerabilities/CreateTagModal";
import { CreateTargetModal } from "screens/Issues/Vulnerabilities/CreateTargetModal";
import EditStatusModal from "screens/Issues/Vulnerabilities/EditStatusModal";
import { getFindingsByAsset, getTicketsConfigs } from "store/entities/assets/actions";
import { getFindingsByAssetSelector } from "store/entities/assets/selectors";
import { datalistConfigsFiltersDataSelector } from "store/entities/datalist/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import { addNotification } from "store/entities/notifications/actions";
import {
  actionGroupModal,
  actionTagModal,
  actionTargetModal,
  exportVulnerability,
  getFindingStatuses,
  ignoreFinding
} from "store/entities/vulnerabilities/actions";

import styles from "./styles.module.scss";

const actionButtons = [
  "edit",
  "addToGroup",
  "addTag",
  "addToTarget",
  "createTicket",
  "export",
  "ignoreFinding"
];

const customStyles = {
  height: 300,
  width: 1,
  "& .super-app-theme--header": {
    backgroundColor: "#fff",
    borderBottom: "1px solid #E7E6F8"
  },
  "& .super-app-theme--row": {
    backgroundColor: "#fff",
    marginBottom: "0px"
  }
};

const columns = [
  {
    headerName: "Last seen",
    field: "last_seen",
    resizable: true,
    minWidth: 120,
    renderCell: params => <div>{formatDate(params.value)}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Risk Factor",
    field: "vulnerability__origin_risk_factor",
    resizable: true,
    minWidth: 120,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Finding",
    field: "vulnerability__name",
    resizable: true,
    minWidth: 280,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Status",
    field: "status",
    resizable: true,
    minWidth: 100,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Ignored",
    field: "ignored",
    resizable: true,
    minWidth: 100,
    renderCell: params => <div>{params.value ? "True" : "False"}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Exploit Available",
    field: "vulnerability__exploit_available",
    resizable: true,
    minWidth: 120,
    renderCell: params => <div>{params.value ? "True" : "False"}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Tickets",
    field: "tickets",
    resizable: true,
    minWidth: 100,
    renderCell: params => <div>{params.value.length || 0}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Data Type",
    field: "vulnerability__data_type",
    resizable: true,
    minWidth: 120,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  }
];

const Findings = () => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const { id: assetId } = useParams();
  const history = useHistory();

  const [tableState, setTableState] = useState({
    page: 0,
    pageSize: 20,
    ordering: "",
    search: ""
  });

  const [selectionModel, setSelectionModel] = useState([]);
  const [lastSeen, setLastSeen] = useState(null);
  const [filterByDataType, setFilterByDataType] = useState(null);
  const [showEditStatusModal, onShowEditStatusModal] = useState(null);
  const [ticketConfig, setTicketConfig] = useState(null);

  const { data_types_list } = useSelector(datalistConfigsFiltersDataSelector);
  const { count, data, ticketsConfigs: ticketConfigList } = useSelector(state =>
    getFindingsByAssetSelector(state)
  );
  const isLoading = useSelector(state => createLoadingSelector([getFindingsByAsset.type])(state));

  const handleSearch = v => {
    setTableState(prevState => ({
      ...prevState,
      search: v
    }));
  };

  const setPageSize = v => {
    setTableState(prevState => ({
      ...prevState,
      pageSize: v
    }));
  };

  const onPageChange = newPage => {
    setTableState(prevState => ({
      ...prevState,
      page: newPage
    }));
  };

  const handleAction = type => {
    if (type === "createTicket" && !ticketConfig?.value) {
      dispatch(addNotification({ msg: "You need to select ticket config", type: "warning" }));
      return;
    }
    if (!selectionModel?.length) {
      dispatch(addNotification({ msg: "You must select at least one row", type: "warning" }));
      return;
    }

    switch (type) {
      case "edit":
        onShowEditStatusModal(selectionModel);
        break;
      case "export":
        dispatch(exportVulnerability({ ids: selectionModel }));
        break;
      case "addToGroup":
        dispatch(actionGroupModal({ show: true, data: selectionModel }));
        break;
      case "addTag":
        dispatch(actionTagModal({ show: true, data: selectionModel }));
        break;
      case "addToTarget":
        dispatch(actionTargetModal({ show: true, data: selectionModel }));
        break;
      case "createTicket": {
        history.push(
          `${routes.vulnerabilitiesCreateTicket}/${ticketConfig?.value}?ids=${selectionModel.join(
            ","
          )}`
        );
        break;
      }
      case "ignoreFinding":
        dispatch(ignoreFinding(selectionModel));
        break;
      default:
        break;
    }
  };

  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];

      setTableState(prevState => ({
        ...prevState,
        ordering: sort === "asc" ? field : `-${field}`
      }));
    } else {
      setTableState(prevState => ({
        ...prevState,
        ordering: ""
      }));
    }
  };

  useEffect(() => {
    dispatch(getTicketsConfigs("finding"));
    dispatch(getFindingStatuses());
  }, []);

  useEffect(() => {
    dispatch(
      getFindingsByAsset({
        assetId,
        page: tableState.page,
        pageSize: tableState.pageSize,
        ordering: tableState.ordering,
        search: tableState.search,
        data_type: filterByDataType?.value,
        lastSeen
      })
    );
  }, [
    assetId,
    tableState.page,
    tableState.pageSize,
    tableState.ordering,
    tableState.search,
    filterByDataType?.value,
    lastSeen
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.filters}>
          <Title className={styles.title}>Findings</Title>
          <Search onSearch={handleSearch} value={null} />
          <div className={styles.filterRow}>
            <div className={styles.selectsGroup}>
              <Select
                containerClass={styles.select}
                onChange={setTicketConfig}
                value={ticketConfig}
                placeholder="Ticket Configuration"
                options={ticketConfigList}
                isClearable
              />
              <Select
                containerClass={styles.select}
                onChange={setFilterByDataType}
                value={filterByDataType}
                placeholder="Filter by"
                options={data_types_list}
                isClearable
              />
              <DateRangePicker placeholder="Last seen" onChange={setLastSeen} value={lastSeen} />
            </div>
            <div className={styles.buttonGroup}>
              {actionButtons.map(btn => (
                <ActionButton
                  key={btn}
                  type={btn}
                  onClick={handleAction}
                  className={styles.actionButton}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <Table
            apiRef={apiRef}
            data={isLoading ? [] : data}
            columns={columns}
            loading={isLoading}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            onSortModelChange={model => handleSort(model)}
            page={tableState.page || 0}
            pageSize={tableState.pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowCount={count || 0}
            paginationMode="server"
            onPageChange={onPageChange}
            customStyles={customStyles}
          />
        </div>
      </div>
      <CreateTargetModal />
      <CreateGroupModal />
      <CreateTagModal />
      <EditStatusModal
        isOpen={Boolean(showEditStatusModal)}
        data={showEditStatusModal}
        onClose={() => onShowEditStatusModal(null)}
      />
    </div>
  );
};

export default memo(Findings);
