import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const Status = ({ className, connected }) => {
  return (
    <div className={`${styles.status} ${className} ${connected ? styles.active : ""}`}>
      {connected ? "Connected" : "Not Connected"}
    </div>
  );
};

Status.propTypes = {
  className: PropTypes.string,
  connected: PropTypes.bool
};

Status.defaultProps = {
  className: "",
  connected: false
};

export default Status;
