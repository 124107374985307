import React from "react";
import { Box } from "@mui/material";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { CommentMessage } from "../CommentMessagePopover/CommentMessagePopover";
import { useGetPoliciesComparison } from "store/entities/policies/queries";
import Loader from "../Loader";

export default function DifferJsonView({ policyId, policyVersionId, isNeedUserInfo, userDetail }) {
  const {
    data: policyComparison = {},
    isLoading: isPolicyComparisonisLoading
  } = useGetPoliciesComparison(policyId, policyVersionId);

  const styles = {
    contentText: {
      fontFamily: "monospace !important",
      maxWidth: "600px",
      wordWrap: "break-word"
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: 1,
        backgroundColor: "#FFFFFF",
        fontSize: "14px"
      }}
    >
      {isPolicyComparisonisLoading ? (
        <div className="flex justify-center items-center" style={{ height: "40vh" }}>
          <Loader />
        </div>
      ) : (
        <>
          <ReactDiffViewer
            oldValue={policyComparison?.approved_yaml || ""}
            newValue={policyComparison?.version_yaml || ""}
            splitView={true}
            compareMethod={DiffMethod.LINES}
            styles={styles}
          />
        </>
      )}
      {isNeedUserInfo && <Box>{userDetail}</Box>}
    </Box>
  );
}
