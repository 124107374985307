import React from "react";
import RegistryURL from "./Fields/RegistryURL";
import Username from "./Fields/Username";
import Password from "./Fields/Password";

export const NexusForm = ({ isEdit, control, errors }) => {
  return (
    <>
      <RegistryURL control={control} errors={errors} />
      <Username control={control} errors={errors} />
      <Password isEdit={isEdit} control={control} errors={errors} />
    </>
  );
};
