import React from "react";

import { Button, Title, Modal } from "components/simple";

import styles from "./styles.module.scss";

const UpdateStatusModal = ({ isOpen, onSubmit, onClose, isLoading, from, to }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <Title className={styles.title}>Confirmation</Title>
      <form onSubmit={onSubmit}>
        <div>
          Are you sure you want to change the status from {from} to {to}?
        </div>
        <div className={styles.footer}>
          <Button variant="outline" onClick={onClose} className={styles.btn}>
            Cancel
          </Button>
          <Button type="submit" isLoading={isLoading} className={styles.btn}>
            Yes
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateStatusModal;
