/* eslint-disable camelcase */
import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getVulnerabilityConfigs,
  deleteVulnerabilityConfigs,
  getVulnerabilityConfigsFiltersData,
  updVulnerabilitiesParams,
  getVulnerabilities
} from "store/entities/vulnerabilities/actions";

import {
  vulnerabilityConfigsSelector,
  vulnerabilityConfigsFiltersDataSelector,
  temporaryDataDefault
} from "store/entities/vulnerabilities/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";

import { ActionButton, Button, Select, Title, Loader } from "components/simple";

import styles from "./styles.module.scss";
import PanelCreateConfig from "./PanelCreateConfig";
import DynamicSelect from "./DynamicSelect";

import { AssetSelect } from "helper/AsyncSelects";

const Panel = ({
  selectConfig,
  temporaryData,
  setSelectConfig,
  selectedFilterFields,
  onChangeSelectedFilterFields,
  onLoadData,
  onClearTemporaryData,
  data_type,
  asset,
  onChangeSelectTopBar
}) => {
  const [collapse, setCollapse] = useState(false);
  const [editData, setEditData] = useState(null);

  const filter_fields = temporaryData?.filter_fields || [];
  const temporaryEditData = temporaryData || null;

  const dispatch = useDispatch();
  const selectTenant = useSelector(state => state.users.selectTenant);

  const vulnerabilityConfigs = useSelector(vulnerabilityConfigsSelector);
  const { dictList, data_types_list, display_fields_list, filter_fields_list } = useSelector(
    vulnerabilityConfigsFiltersDataSelector
  );
  const isLoading = useSelector(state => createLoadingSelector([getVulnerabilities.type])(state));

  const [state, setState] = useState({
    name: "",
    target: "",
    filter_fields: [],
    display_fields: [],
    display_fields_width: []
  });

  useEffect(() => {
    dispatch(getVulnerabilityConfigsFiltersData());
    dispatch(getVulnerabilityConfigs());
  }, [selectTenant]);

  useEffect(() => {
    if (temporaryEditData) {
      const display_fields =
        temporaryEditData?.display_fields?.map(item => {
          return display_fields_list?.find(t => t.value === item);
        }) ?? [];

      const filterFields = temporaryEditData?.filter_fields?.map(item => {
        return filter_fields_list?.find(t => t.value === item);
      });

      setState({
        ...state,
        display_fields,
        filter_fields: filterFields,
        display_fields_width: temporaryEditData?.display_fields_width ?? []
      });
    }
  }, [temporaryEditData, display_fields_list]);

  useEffect(() => {
    if (temporaryEditData?.data_type) {
      dispatch(getVulnerabilityConfigsFiltersData({ data_type: temporaryEditData?.data_type }));
    }
  }, [temporaryEditData]);

  // clear data temporary data
  const clearPanelCreateConfig = () => {
    onClearTemporaryData();
    setEditData(null);
    setState({
      name: "",
      target: "",
      filter_fields: [],
      display_fields: []
    });
  };

  // Button Close and clear all data
  const onClose = () => {
    setCollapse(false);
    clearPanelCreateConfig();
  };

  //  create new config
  const onAdd = () => {
    setCollapse(true);
    setEditData(temporaryEditData);
  };

  // collapse panel
  const onCollapse = v => {
    setCollapse(v);
    setEditData(null);
  };

  // load  Temporary data on front side
  const onLoadTemporaryData = r => {
    setCollapse(false);
    onLoadData(r);
    setEditData(null);
  };

  // Select Config
  const onChangeSelectConfig = v => {
    setSelectConfig(v);
  };

  // Edit
  const onEdit = () => {
    setCollapse(true);
    setEditData(temporaryEditData);
  };

  // Delete Config
  const onDelete = () => {
    const id = selectConfig?.value;
    dispatch(deleteVulnerabilityConfigs({ id }));
    onClose();
  };

  const onChangeSelect = (val, name) => {
    if (name === "data_type") {
      dispatch(getVulnerabilityConfigsFiltersData({ data_type: val?.value }));
      dispatch(
        updVulnerabilitiesParams({
          temporaryData: {
            ...temporaryData,
            ...temporaryDataDefault
          }
        })
      );
    }
    onChangeSelectTopBar(val, name);
  };
  const data_typeSelect = data_types_list.find(item => item.value === data_type);

  return (
    <div className={styles.panel}>
      <div className={styles.panelHead}>
        <Title>Vulnerabilities</Title>
        <div className={styles.panelRow}>
          <div className={styles.leftPanelRow}>
            <Select
              containerClass={styles.select}
              onChange={v => onChangeSelect(v, "data_type")}
              value={data_typeSelect}
              placeholder="Data type"
              options={data_types_list}
              isClearable
            />
            <AssetSelect
              key={data_type}
              data_type={data_type}
              containerClass={styles.select}
              onChange={v => onChangeSelect(v, "asset")}
              value={asset}
              placeholder="Asset"
              isClearable
            />
          </div>
          {!collapse ? (
            <div className={styles.selectConfigWrapper}>
              {selectConfig ? (
                <div className={styles.selectConfigText}>
                  Config: <span>{selectConfig.label}</span>
                </div>
              ) : null}
              <Select
                containerClass={styles.select}
                onChange={onChangeSelectConfig}
                value={selectConfig}
                placeholder="Select Config"
                options={vulnerabilityConfigs}
                isClearable
              />
              <ActionButton type="add" onClick={() => onAdd()} className={styles.actionButton} />
            </div>
          ) : null}
        </div>
      </div>

      <PanelCreateConfig
        editData={editData}
        id={selectConfig?.value}
        onLoadData={onLoadTemporaryData}
        onCollapse={onCollapse}
        collapse={collapse}
        onClose={onClose}
        state={state}
        setState={setState}
        data_type={data_type}
        asset={asset}
      />

      {temporaryEditData && !editData && !collapse ? (
        <div className={styles.panelConfig}>
          <div className={styles.panelGrid}>
            {filter_fields?.map((item, index) => {
              return (
                <DynamicSelect
                  key={index}
                  item={item}
                  dictList={dictList}
                  params={temporaryData}
                  data_type={data_type}
                  asset={asset}
                  selectedFilterField={selectedFilterFields?.[item] || ""}
                  onChange={onChangeSelectedFilterFields}
                />
              );
            })}
          </div>
          <div className={styles.buttonGroup}>
            <Button className={styles.button} onClick={() => onEdit()}>
              Edit
            </Button>
            <Button
              className={`${styles.button} ${styles.right} ${styles.close}`}
              variant="outline"
              onClick={() => onClose()}
            >
              Reset
            </Button>
            {selectConfig ? (
              <Button className={`${styles.button}`} onClick={() => onDelete()}>
                Delete
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

Panel.propTypes = {};

export default memo(Panel);
