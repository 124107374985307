import { getAccuknoxWorkspace } from "api/api";

export const DEFAULT_KUBEARMOR_POLICY_QUERY_KEYS = ["PolicyEditorTool", "KubeArmorPolicy"];

// TODO: Please remove once the details can be retrieved from Local Storage
export const WORKSPACE_ID = getAccuknoxWorkspace();
export const USER_ID = "30d4caa0-0c48-4d2c-874d-0edb1a950a43";
export const USER_FULL_NAME = "Accuknox User";

// All the Policy Types
export const KUBEARMOR_POLICY = "KubeArmor Policy";
export const NETWORK_POLICY = "Network Policy";
export const POLICY_TYPES = [KUBEARMOR_POLICY, NETWORK_POLICY];

// All Entity Types
export const K8S = "K8s";
export const VM = "VM";

// eslint-disable-next-line prefer-regex-literals
export const PATH_VALIDATION_REGEX = new RegExp(/^\/$|^\/.*\/?$/);

// All the Rule Types
export const METADATA = "Metadata";
export const PROCESS = "Process";
export const FILE = "File";
export const NETWORK = "Network";
export const CAPABILITIES = "Capabilities";
export const SYSTEM_RULE_TYPES = [PROCESS, FILE, NETWORK];
export const POLICY_SPEC_PROPERTIES = [
  "process",
  "file",
  "network",
  "selector",
  "nodeSelector",
  "tags",
  "message"
];

export const DEFAULT = "Default";
export const NODE = "Node";

export const KIND_KUBEARMOR_HOST_POLICY = "KubeArmorHostPolicy";
export const KIND_KUBEARMOR_POLICY = "KubeArmorPolicy";
export const KIND_KUBEARMOR_HOST = "KubeArmor Host";
export const KUBEARMOR = "KubeArmor";
export const KUBEARMOR_POLICY_KINDS = [KIND_KUBEARMOR_HOST_POLICY, KIND_KUBEARMOR_POLICY];

export const LABEL_TYPE_OPTIONS = [
  { label: DEFAULT, value: DEFAULT },
  { label: NODE, value: NODE }
];

export const PROTOCOL_OPTIONS = [
  { label: "TCP", value: "tcp" },
  { label: "UDP", value: "udp" },
  { label: "ICMP", value: "icmp" }
];

export const SEVERITY_OPTIONS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 }
];

export const ALLOW = "Allow";
export const BLOCK = "Block";
export const AUDIT = "Audit";

export const ACTION_OPTIONS = [
  { value: BLOCK, label: BLOCK },
  { value: ALLOW, label: ALLOW },
  { value: AUDIT, label: AUDIT }
];

export const LOGS_TIME_FORMAT = "MM-DD-YY HH:mm z";

export const POLICY_INFO_CODE = `
apiVersion: security.kubearmor.com/v1
kind:KubeArmorPolicy
metadata:
name: [policy name]
namespace: [namespace name]
spec:                       
selector:                              
matchLabels:
[key1]: [value1]
[keyN]: [valueN]

process:
severity: [1-10]                       
matchPaths:
- path: [absolute executable path]
ownerOnly: [true|false]              
fromSource:                          
- path: [absolute exectuable path]
matchDirectories:
- dir: [absolute directory path]
recursive: [true|false]              
ownerOnly: [true|false]              
fromSource:                          
- path: [absolute exectuable path]
action: [Allow|Audit|Block]
`;

export const DEFAULT_POLICY_TEMPLATE = {
  apiVersion: "security.kubearmor.com/v1"
};
export const DEFAULT_POLICY_YAML = "apiVersion: security.kubearmor.com/v1";

// PolicyEditorToolPopup Messages
export const DELETE_POLICY_FAIL_TOAST = "Failed to Delete!";
export const EDIT_POLICY_MSG =
  "There is a policy already being created, do you want to discard it?";
export const DELETE_POLICY_MSG =
  "Are you sure you want to delete this Policy? This action cannot be undone.";
export const DELETE_POLICY_SUCCESS_TOAST = "Policy Deleted!";
export const VALID_YAML_MESSAGE = "The created YAML is valid";
export const INVALID_YAML_MESSAGE = "The created YAML is invalid";

export const VALID_YAML1 = "The YAML is valid";
export const INVALID_YAML1 = "The YAML is invalid";

// Default Form Values
export const DEFAULT_POLICY_METADATA_FORM_VALUES = {
  policyName: "",
  cluster: "",
  policyNamespace: "",
  instanceGroup: "",
  instance: "",
  labelType: LABEL_TYPE_OPTIONS[0].value,
  selectorLabel: "",
  policyTags: "",
  policyMessage: ""
};
export const DEFAULT_PROCESS_FORM_VALUES = {
  path: "",
  directory: false,
  recursive: false,
  ownerOnly: false,
  severity: SEVERITY_OPTIONS[0].value,
  action: ACTION_OPTIONS[0].value,
  fromSource: []
};
export const DEFAULT_FILE_FORM_VALUES = {
  path: "",
  directory: false,
  recursive: false,
  ownerOnly: false,
  readOnly: false,
  severity: SEVERITY_OPTIONS[0].value,
  action: ACTION_OPTIONS[0].value,
  fromSource: []
};
export const DEFAULT_NETWORK_FORM_VALUES = {
  protocol: PROTOCOL_OPTIONS[0].value,
  severity: SEVERITY_OPTIONS[0].value,
  action: ACTION_OPTIONS[0].value,
  fromSource: []
};
