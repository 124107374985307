import React, { useState } from "react";
import styles from "./styles.module.scss";
import {
  Title,
  Input,
  Button,
  ContentContainer,
  Select
} from "components/simple";
import { useForm, Controller } from "react-hook-form";
import ErrorText from "../ErrorText";
import PrefixLabelInput from "../PrefixLabelInput";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Switch } from "@mui/material";

function SplunkIntegration() {
  const [httpMethod,setHttpmethod]=useState("GET");
  const [isAccessibility,setIsAccessinility]=useState("");
  const [showLabelKeyValueInput, setShowLabelKeyValueInput] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [authMethod,setAuthMethod] =useState("");
  const [isCritical,setIsCritical]=useState(true);
  const [isTrouble,setIsTrouble]=useState(true);
  const { register, clearErrors, handleSubmit, watch, control, formState: { errors, isDirty, isValid } } = useForm();
  const onSubmit = (e => {
  })
  const addLabelHandler = (e) => {
    e.preventDefault();
    setShowLabelKeyValueInput(true);
    setInputList([...inputList, { name: "", value: "" }]);
  };
  const removeLabelHandler = (e, index) => {
    e.preventDefault();
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  return (
    <ContentContainer className={styles.container}>
      <div className="flex justify-between py-5" style={{borderBottom: "0.5px solid #dfdfdf"}}>
        <div className="flex gap-1">
          {/* <img src={JiraIcon} alt="icon" /> */}
          <Title>Splunk</Title>
        </div>
        {/* <div
          onClick={() => window.open("_blank",  "noopener,noreferrer")}
          className="flex text-blue-700 font-medium cursor-pointer"
        >
          Help <HelpOutlineIcon className={styles.help_icon} />
        </div> */}
      </div>
      <form className="w-full h-full flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-8 pb-6 border-b border-gray-300 align-center flex gap-6">
          <div className="w-9/12">
            <Controller
              {...register("integrationName", {
                required: "Integration name cannot be empty",
                maxLength: {
                  value: 20,
                  message: "characters must not exceed 20 characters "
                }
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label="Integration Name*"
                  name="integrationName"
                  type="text"
                  autoFocus
                  tabindex="0"
                  size="medium"
                  placeholder="Enter a name for integration"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <ErrorText errorMsg={errors.integrationName && errors.integrationName.message}/>
          </div>
          <div className="w-full">
            <div className={`mb-2 ${styles.label}`}>Hook URL</div>
            <Controller
              {...register("hookURL", {
                required: "Hook URL name cannot be empty"
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <PrefixLabelInput
                  leftAddsOn="http://"
                  placeholder="Enter the complete URL from Accuknox Data"
                  name="hookURL"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <ErrorText errorMsg={errors.hookURL && errors.hookURL.message} />
          </div>
        </div>
        <div className="pt-5 pb-5 border-b border-gray-300 ">
          <div className="mb-6">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label" className={`mb-2 ${styles.label}`}>HTTP Method</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="GET"
                name="radio-buttons-group"
                value={httpMethod}
                onChange={(e) => setHttpmethod(e.target.value)}
              >
                <FormControlLabel value="GET" control={<Radio />} label="GET" />
                <FormControlLabel value="POST" control={<Radio />} label="POST" />
                <FormControlLabel value="PUT" control={<Radio />} label="PUT" />
                <FormControlLabel value="DELETE" control={<Radio />} label="DELETE" />
                <FormControlLabel value="PATCH" control={<Radio />} label="PATCH" />
             </RadioGroup>
            </FormControl>
          </div>
          <div>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={false}
                      // onChange={() => setIsChecked(...isChecked, !isChecked.postasjson)} 
                      name="POST as JSON"
                    />
                  }
                  label="POST as JSON"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={false}
                      // onChange={() => setIsChecked(...isChecked, !isChecked.incidentparameters)}
                      name="Send Incident Parameters"
                    />
                  }
                  label="Send Incident Parameters"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={false}
                      // onChange={() => setIsChecked(...isChecked, !isChecked.customparameters)}
                      name="Send Custom Parameters"
                    />
                  }
                  label="Send Custom Parameters"
                />
              </FormGroup>
            </FormControl>
          </div>
        </div>
        <div className="pt-4 pb-5 border-b border-gray-300">
          <FormControl>
            <FormLabel className={`mb-2 ${styles.label}`}>Accessibility</FormLabel>
            <RadioGroup
              name="radio-buttons-group"
              value={isAccessibility}
              onChange={e => 
                setIsAccessinility(e?.target?.value === isAccessibility ? " " : "Global")}
            >
              <FormControlLabel value="Global" control={<Radio />} label="Global" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="pt-4 pb-5 border-b border-gray-300">
          {showLabelKeyValueInput &&
            inputList?.map((inputVal, index) => (
              <div className="flex gap-6 pb-4" key={index}>
                <div className="flex flex-col w-5/12">
                  <div className="mb-2">HTTP Request Header Name</div>
                  <Input placeholder="Enter the header name" />
                </div>
                <div className="flex flex-col w-5/12">
                  <div className="mb-2">HTTP Request Header Value</div>
                  <Input placeholder="Enter the header value" />
                </div>
                <div className="mb-auto mt-6">
                  <Button variant="outline" onClick={e => removeLabelHandler(e)}>-</Button>
                </div>
              </div>
            ))}
          <div className="flex gap-6">
            <div className="flex flex-col w-5/12">
              <Controller
                {...register("headerName", {
                  required: "Header name cannot be empty",
                })}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Input
                    label="HTTP Request Header Name"
                    placeholder="Enter the header name"
                    name="headerName"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
              <ErrorText errorMsg={errors.headerName && errors.headerName.message} />
            </div>
            <div className="flex flex-col w-5/12">
              <Controller
                {...register("headerValue", {
                  required: "Header value cannot be empty",
                })}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Input
                    label="HTTP Request Header Value"
                    placeholder="Enter the header value"
                    name="headerValue"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
              <ErrorText errorMsg={errors.headerValue && errors.headerValue.message} />
            </div>
            <div className="mb-auto mt-6">
              <Button variant="outline" onClick={e => addLabelHandler(e)}>+</Button>
            </div>
          </div>
          <div className="mt-4 flex flex-col w-5/12">
            <Controller
              {...register("userAgent", {
                required: "User agent cannot be empty",
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label="UserAgent"
                  placeholder="Customized User Agent (User Agent)"
                  name="userAgent"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <ErrorText errorMsg={errors.userAgent && errors.userAgent.message} />
          </div>
          <div className="flex mt-8 mb-2 flex-col">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label" className={`mb-2 ${styles.label}`}>Authentication method</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Basic/NTLM"
                name="radio-buttons-group"
                value={authMethod}
                onChange={e => setAuthMethod(e.target.value)}
              >
                <FormControlLabel value="Basic/NTLM" control={<Radio />} label="Basic / NTLM" />
                <FormControlLabel value="OAuth" control={<Radio />} label="OAuth" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="flex gap-6 pb-4">
            <div className="mt-4 w-2/5">
              <Controller
                {...register("userName", {
                  required: "User name cannot be empty",
                })}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Input
                    label="User name"
                    placeholder="Enter the user name"
                    name="userName"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
              <ErrorText errorMsg={errors.userName && errors.userName.message} />
            </div>
            <div className="mt-4 w-2/5">
              <Controller
                {...register("userPassword", {
                  required: "Password cannot be empty",
                })}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Input
                    label="Password"
                    placeholder="Enter the password"
                    name="userPassword"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
              <ErrorText errorMsg={errors.userPassword && errors.userPassword.message} />
            </div>
            <div className="mt-4 w-2/5">
              <div className="w-1/2">
                <Controller
                  {...register("timeout", {
                    required: "Add some Time-out",
                  })}
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Time-out"
                      placeholder="0"
                      type="number"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                />
                <ErrorText errorMsg={errors.timeout && errors.timeout.message} />
              </div>
            </div>
          </div>
          <div className="flex mb-5 flex-col" style={{ width: "32%" }}>
            <Controller
              {...register("addTags", {
                required: "Add tags cannot be empty",
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label="Tags to be sent with alerts"
                  // inputclassname="w-80"
                  //   icon={search}
                  placeholder="Add tags"
                  name="addTags"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <ErrorText errorMsg={errors.addTags && errors.addTags.message} />
          </div>
          <div className="mb-5 self-center w-2/4 flex justify-between">
            <div className="mr-4">Trigger incidents when my status is Critical</div>
            <div className="mt-0 flex">
              <span className="text-gray-400 font-medium text-sm">No</span>
              <Switch
                checked={isCritical}
                onChange={() => setIsCritical(!isCritical)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span className="text-gray-400 font-medium text-sm">Yes</span>
            </div>
          </div>
          <div className="mb-5 self-center w-2/4 flex justify-between">
            <div className="mr-4">Trigger incidents when my status is in Trouble</div>
            <div className="mt-0 flex">
              <span className="text-gray-400 font-medium text-sm">No</span>
              <Switch
                checked={isTrouble}
                onChange={() => setIsTrouble(!isTrouble)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span className="text-gray-400 font-medium text-sm">Yes</span>
            </div>
          </div>
        </div>
        <div className="w-full pt-6 pb-4 border-b border-gray-300 flex flex-row justify-between">
          <Button
            title="Test"
            onClick={() => {}}
            variant="primary"
            // disabled={isButtonDisAble}
          >
            Test
          </Button>
          <div className="flex">
            <Button
              className="mr-4"
              variant="outline"
              onClick={() => {
                clearErrors();
              }}
            >
              Cancel
            </Button>
            <Button
              // disabled={!isDirty || !isValid}
              onClick={handleSubmit(onSubmit)}
              variant="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </ContentContainer>
  );
}
export default SplunkIntegration;
