import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import SpinnerLoader from "./SpinnerLoader";
import SuccessLoader from "./SuccessLoader";
import ErrorLoader from "./ErrorLoader";
import {
  INVALID_YAML_MESSAGE,
  VALID_YAML_MESSAGE
} from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";

const ValidationLoader = ({ validationValue, isValid, type }) => {
  const [showSpinner, setShowSpinner] = useState();

  useEffect(() => {
    setShowSpinner(true);
    const timeoutId = setTimeout(() => {
      setShowSpinner(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [validationValue]);

  if (showSpinner) {
    return (
      <div className="flex gap-[6px] items-center h-5 mt-1">
        <SpinnerLoader />
        <p className="text-sm">Validating</p>
      </div>
    );
  }
  return isValid ? (
    <div className="flex gap-[6px] items-center h-5 mt-1">
      <SuccessLoader />
      <p className="text-sm">{VALID_YAML_MESSAGE}</p>
    </div>
  ) : (
    <div className="flex gap-[6px] items-center h-5 mt-1">
      <ErrorLoader />
      <p className="text-sm">{INVALID_YAML_MESSAGE}</p>
    </div>
  );
};

export default ValidationLoader;
