/* eslint-disable camelcase */
import React, { useState, useCallback, useEffect } from "react";

import { Tab, Box, Tabs } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { routes } from "router/router";
import { navigate } from "helper/history";
import { useRolesQuery } from "store/entities/rbac/queries";
import { getLabelList } from "store/entities/labels/actions";
import { getLabelListSelector } from "store/entities/labels/selectors";
import { getUserProfilesGroup } from "store/entities/settings/actions";
import { getGroupsListForUserProfileSelect } from "store/entities/settings/selectors";

import styles from "./styles.module.scss";
import { PendingInvites } from "./tabs/PendingInvites";
import { UsersInWorkspace } from "./tabs/UsersInWorkspace";
import { DeactivatedUsers } from "./tabs/DeactivatedUsers";
import { ContentContainer, Title, Button } from "components/simple";

const UserProfiles = ({ history }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const selectTenant = useSelector(st => st.users.selectTenant);

  const listLabel = useSelector(getLabelListSelector);
  const groupList = useSelector(getGroupsListForUserProfileSelect);
  const { data: allRoles, isLoading, refetch: refetchRoles } = useRolesQuery();

  useEffect(() => {
    // dispatch(getPermissions());
    dispatch(getLabelList());
    dispatch(getUserProfilesGroup());
  }, [selectTenant]);

  const roleDetailsMap = allRoles?.results?.reduce(
    (acc, newVal) => ({
      ...acc,
      [newVal?.name]: newVal
    }),
    {}
  );
  const roleOptionsArray = allRoles?.results?.map(item => ({ value: item?.id, label: item?.name }));

  const handleChange = useCallback((event, newValue) => setActiveTab(newValue), [activeTab]);

  // Sorting handle
  // const handleSort = s => {
  //   if (s.length) {
  //     const { field, sort } = s[0];
  //     if (sort === "asc") {
  //       dispatch(
  //         setUserProfilesParams({
  //           ordering: field
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         setUserProfilesParams({
  //           ordering: `-${field}`
  //         })
  //       );
  //     }
  //   } else {
  //     dispatch(
  //       setUserProfilesParams({
  //         ordering: ""
  //       })
  //     );
  //   }
  // };

  // const handleSearch = text => {
  //   dispatch(
  //     setUserProfilesParams({
  //       page: 0,
  //       search: text
  //     })
  //   );
  // };

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const tabStyle = {
    width: "70%",
    fontSize: "14px",
    fontFamily: "PoppinsMedium",
    textTransform: "capitalize"
    // marginLeft: "2rem",
  };

  return (
    <ContentContainer>
      <div className={styles.controls}>
        <Title>User Profiles</Title>
        <div className={styles.btnsContainer}>
          {/* <Search onSearch={handleSearch} /> */}
          <Button
            className={styles.btn}
            onClick={() =>
              navigate(routes.settingsUserProfilesDetails, {
                roleOptions: roleOptionsArray,
                labelOptions: listLabel,
                groupOptions: groupList
              })
            }
            icon="plus"
          >
            Add user
          </Button>
        </div>
      </div>
      <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
        <Tab sx={tabStyle} label="Users in workspace" {...tabProps(0)} />
        <Tab sx={tabStyle} label="Deactivated Users" {...tabProps(1)} />
        <Tab sx={tabStyle} label="Pending Invites" {...tabProps(2)} />
      </Tabs>

      <Box sx={{ p: 3 }}>
        {
          {
            0: (
              <UsersInWorkspace
                roleDetailsMap={roleDetailsMap}
                roleOptions={roleOptionsArray}
                labelOptions={listLabel}
                groupOptions={groupList}
              />
            ),
            1: <DeactivatedUsers />,
            2: <PendingInvites />
          }[activeTab]
        }
      </Box>
    </ContentContainer>
  );
};

UserProfiles.propTypes = {};

export default UserProfiles;
