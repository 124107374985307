import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import {
  DEFAULT,
  METADATA,
  WORKSPACE_ID
} from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import DataObjectIcon from "@mui/icons-material/DataObject";
import EditIcon from "@mui/icons-material/Edit";
import { transformPolicyName } from "store/entities/policyEditor/kubeArmorPolicyEditor/helpers";
import { Button, CreatableSelect, Input, Select, SelectAsync } from "components/simple";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  useNetworkPolicyEditorState,
  handlePolicyMetadataFormSubmit,
  updateActiveCard,
  updateIsEditingPolicyMetadata,
  updateSelectedCluster,
  updateSelectedNamespace
} from "store/entities/policyEditor/NetworkPolicyEditor/slice";
import { useNetworkPolicyContext } from "../..";
import {
  useClustersQuery,
  useLabelsLoadOptions,
  useNamespaceLoadOptions
} from "store/entities/policyEditor/kubeArmorPolicyEditor/queries";
import { truncate } from "lodash";

const PolicyMetadataCard = () => {
  const dispatch = useDispatch();

  const policyJSON = useNetworkPolicyEditorState("policyJSON");
  const selectedCluster = useNetworkPolicyEditorState("selectedCluster");
  const selectedNamespace = useNetworkPolicyEditorState("selectedNamespace");
  const activeCard = useNetworkPolicyEditorState("activeCard");
  const isEditingPolicyMetadata = useNetworkPolicyEditorState("isEditingPolicyMetadata");

  const clustersDataQuery = useClustersQuery();
  const namespaceLoadOptions = useNamespaceLoadOptions();
  const labelsLoadOptions = useLabelsLoadOptions();

  const { policyMetadataForm } = useNetworkPolicyContext();
  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
    setValue,
    trigger: triggerValidation
  } = policyMetadataForm;

  const onChangePolicyName = (e, onChange) => {
    const value = transformPolicyName(e.target.value);
    onChange(value);
  };

  const onFormSubmit = formValues => {
    dispatch(handlePolicyMetadataFormSubmit(formValues));
  };

  const handleEditPolicy = () => {
    dispatch(updateIsEditingPolicyMetadata(true));
    triggerValidation();
  };

  const formatCreateNamespaceLabel = value => <p>Create new namespace {value && `'${value}'`}</p>;

  const handleCreateNewNamespaceOption = (value, onChange) => {
    const option = {
      label: value,
      value
    };
    dispatch(updateSelectedNamespace(option));
    onChange(option);
    return option;
  };

  return (
    <CardContainer
      $isActive={activeCard === METADATA}
      onFocus={() => dispatch(updateActiveCard(METADATA))}
    >
      {isEditingPolicyMetadata ? (
        <form onSubmit={handleSubmit(onFormSubmit)} className="grid gap-2">
          <div>
            <Controller
              name="name"
              rules={{
                required: "Policy Name should be filled",
                pattern: {
                  value: /^[a-z0-9-]+$/,
                  message:
                    "Can have only lowercase alphabets, numerals, no special character except hyphen and no spaces. Can not start and end with hyphen."
                }
              }}
              control={control}
              ref={null}
              render={({ field: { onChange, value, ref } }) => (
                <Input
                  label="Policy Name"
                  value={value}
                  onChange={e => onChangePolicyName(e, onChange)}
                  placeholder="Enter Policy Name"
                  autoFocus
                  ref={ref}
                  invalid={errors?.name?.message}
                  className="panzoom-exclude"
                  required
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="cluster"
              rules={{
                required: "Cluster should be selected"
              }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  key={`policy-editor-tool-${selectedCluster?.id}`}
                  label="Cluster"
                  options={clustersDataQuery?.data}
                  value={value}
                  onChange={selectedOption => {
                    onChange(selectedOption);
                    dispatch(updateSelectedCluster(selectedOption));
                    dispatch(updateSelectedNamespace(null));
                    setValue("namespace", "");
                  }}
                  placeholder="Select Cluster"
                  noOptionsMessage={() => "No clusters found"}
                  isSearchable={false}
                  className="panzoom-exclude"
                  classNamePrefix="panzoom-react-select"
                  error={errors?.cluster?.message}
                  required
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="namespace"
              rules={{
                required: "Namespace should be selected"
              }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <CreatableSelect
                  key={`policy-editor-tool-${selectedCluster?.id}`}
                  asyncPaginate
                  label="Namespace"
                  value={value}
                  onChange={selectedOption => {
                    onChange(selectedOption);
                    dispatch(updateSelectedNamespace(selectedOption));
                  }}
                  debounceTimeout={500}
                  loadOptions={namespaceLoadOptions}
                  additional={{
                    workspaceId: WORKSPACE_ID,
                    clusterId: selectedCluster?.id
                  }}
                  onCreateOption={v => handleCreateNewNamespaceOption(v, onChange)}
                  formatCreateLabel={formatCreateNamespaceLabel}
                  placeholder="Select Namespace"
                  noOptionsMessage={() => "No namespaces found"}
                  isDisabled={!selectedCluster?.id}
                  onBlurResetsInput={false}
                  className="panzoom-exclude"
                  classNamePrefix="panzoom-react-select"
                  error={errors?.namespace?.message}
                  required
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="selectorLabel"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectAsync
                  label="Selector Labels"
                  key={`policy-editor-tool-${selectedNamespace?.id}`}
                  value={value}
                  onChange={onChange}
                  debounceTimeout={500}
                  loadOptions={labelsLoadOptions}
                  additional={{
                    workspaceId: WORKSPACE_ID,
                    clusterId: selectedCluster?.id,
                    namespaceId: selectedNamespace?.id,
                    labelType: DEFAULT,
                    page: 0
                  }}
                  placeholder="Selector Labels"
                  noOptionsMessage={() => "No selector labels found"}
                  isMulti
                  isSearchable
                  className="panzoom-exclude"
                  classNamePrefix="panzoom-react-select"
                  error={errors?.selectorLabel?.message}
                />
              )}
            />
          </div>

          {/* Save Button */}
          <div className="flex flex-row-reverse justify-between mt-1">
            <Button
              type="submit"
              onClick={handleSubmit(onFormSubmit)}
              disabled={!isValid}
              className="panzoom-exclude"
            >
              Save
            </Button>
          </div>
        </form>
      ) : (
        <div className="grid gap-3 text-gray-500">
          <div className="flex justify-between items-center">
            <div className="flex justify-center items-center gap-2">
              <DataObjectIcon color="primary" sx={{ fontSize: "22px" }} />
              <Typography fontWeight={600}>
                {truncate(policyJSON?.metadata?.name, { length: 30 })}
              </Typography>
            </div>
            <EditIcon
              color="primary"
              sx={{ cursor: "pointer", fontSize: "18px" }}
              onClick={handleEditPolicy}
            />
          </div>

          {policyJSON?.kind ? (
            <Typography fontSize={14} fontWeight={600}>
              {policyJSON?.kind}
            </Typography>
          ) : null}

          {selectedCluster?.label || selectedCluster?.name ? (
            <div className="grid grid-cols-3 items-center">
              <Typography fontSize={14} fontWeight={600}>
                Cluster:
              </Typography>
              <span className="col-span-2">
                <Typography fontSize={14}>
                  {truncate(selectedCluster?.label || selectedCluster?.name, { length: 26 })}
                </Typography>
              </span>
            </div>
          ) : null}

          {policyJSON?.metadata?.namespace ? (
            <div className="grid grid-cols-3 items-center">
              <Typography fontSize={14} fontWeight={600}>
                Namespace:
              </Typography>
              <span className="col-span-2">
                <Typography fontSize={14}>
                  {truncate(policyJSON?.metadata?.namespace, { length: 26 })}
                </Typography>
              </span>
            </div>
          ) : null}

          {policyJSON?.spec?.podSelector?.matchLabels ? (
            <div className="grid grid-cols-3 items-center">
              <Typography fontSize={14} fontWeight={600}>
                Labels:
              </Typography>
              <span className="flex items-center flex-wrap gap-2 col-span-2 h-auto">
                {Object.entries(policyJSON?.spec?.podSelector?.matchLabels)?.map(
                  ([key, value], index) => (
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      key={index}
                      className="rounded-full px-2 py-1 text-blue-700 bg-blue-100"
                    >
                      {truncate(`${key}=${value}`, {
                        // eslint-disable-next-line no-restricted-globals
                        length: screen.width < 1920 ? 26 : 28
                      })}
                    </Typography>
                  )
                )}
              </span>
            </div>
          ) : null}
        </div>
      )}
    </CardContainer>
  );
};

export const CardContainer = styled.div`
  width: 320px;
  height: fit-content;
  padding: 8px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: ${({ $isActive }) =>
    $isActive ? "0px 0px 4px 0px #00000040" : "0px 4px 4px rgba(0, 0, 0, 0.25)"};
  border: ${({ $isActive }) => ($isActive ? "2px solid #253664" : "2px solid #EEF3F4")};
`;

export default PolicyMetadataCard;
