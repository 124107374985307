import React from "react";
import PropTypes from "prop-types";

export default function Bell(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      onClick={props.onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8485 20.5299C10.5185 20.5299 8.18848 20.1599 5.97848 19.4199C5.13848 19.1299 4.49848 18.5399 4.21848 17.7699C3.92848 16.9999 4.02848 16.1499 4.48848 15.3899L5.63848 13.4799C5.87848 13.0799 6.09848 12.2799 6.09848 11.8099V8.91992C6.09848 5.19992 9.12848 2.16992 12.8485 2.16992C16.5685 2.16992 19.5985 5.19992 19.5985 8.91992V11.8099C19.5985 12.2699 19.8185 13.0799 20.0585 13.4899L21.1985 15.3899C21.6285 16.1099 21.7085 16.9799 21.4185 17.7699C21.1285 18.5599 20.4985 19.1599 19.7085 19.4199C17.5085 20.1599 15.1785 20.5299 12.8485 20.5299ZM12.8485 3.66992C9.95848 3.66992 7.59848 6.01992 7.59848 8.91992V11.8099C7.59848 12.5399 7.29848 13.6199 6.92848 14.2499L5.77848 16.1599C5.55848 16.5299 5.49848 16.9199 5.62848 17.2499C5.74848 17.5899 6.04848 17.8499 6.45848 17.9899C10.6385 19.3899 15.0685 19.3899 19.2485 17.9899C19.6085 17.8699 19.8885 17.5999 20.0185 17.2399C20.1485 16.8799 20.1185 16.4899 19.9185 16.1599L18.7685 14.2499C18.3885 13.5999 18.0985 12.5299 18.0985 11.7999V8.91992C18.0985 6.01992 15.7485 3.66992 12.8485 3.66992Z"
        fill={props.color}
      />
      <path
        d="M14.7085 3.93969C14.6385 3.93969 14.5685 3.92969 14.4985 3.90969C14.2085 3.82969 13.9285 3.76969 13.6585 3.72969C12.8085 3.61969 11.9885 3.67969 11.2185 3.90969C10.9385 3.99969 10.6385 3.90969 10.4485 3.69969C10.2585 3.48969 10.1985 3.18969 10.3085 2.91969C10.7185 1.86969 11.7185 1.17969 12.8585 1.17969C13.9985 1.17969 14.9985 1.85969 15.4085 2.91969C15.5085 3.18969 15.4585 3.48969 15.2685 3.69969C15.1185 3.85969 14.9085 3.93969 14.7085 3.93969Z"
        fill={props.color}
      />
      <path
        d="M12.8484 22.8105C11.8584 22.8105 10.8984 22.4105 10.1984 21.7105C9.49839 21.0105 9.09839 20.0505 9.09839 19.0605H10.5984C10.5984 19.6505 10.8384 20.2305 11.2584 20.6505C11.6784 21.0705 12.2584 21.3105 12.8484 21.3105C14.0884 21.3105 15.0984 20.3005 15.0984 19.0605H16.5984C16.5984 21.1305 14.9184 22.8105 12.8484 22.8105Z"
        fill={props.color}
      />
    </svg>
  );
}

Bell.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Bell.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 25,
  height: 24
};
