export const VULNERABILITY_TYPES = [
  {
    value: "high_to_critical",
    label: "High to Critical",
    description: "Vulnerabilities with High and Critical severity"
  },
  {
    value: "network_attack",
    label: "Network Attack",
    description: "Vulnerabilities with CVSS network attack vector"
  }
];
