import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const EmptyClusterData = ({ type }) => {
  return (
    <Container>
      <SvgContainer>
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="100" cy="100" r="100" fill="#F2F2F2" />
          <g filter="url(#filter0_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M68 64C65.7908 64 64 65.7909 64 68V132C64 134.209 65.7908 136 68 136H91.4688C91.6411 136.67 91.9894 137.304 92.514 137.828L98.1708 143.485C99.7329 145.047 102.266 145.047 103.828 143.485L109.485 137.828C110.009 137.304 110.357 136.67 110.53 136H132C134.209 136 136 134.209 136 132V68C136 65.7909 134.209 64 132 64H68Z"
              fill="white"
            />
          </g>
          <path
            d="M116 98.2224H101.778V84H98.2224V98.2224H84V101.778H98.2224V116H101.778V101.778H116V98.2224Z"
            fill="#D9D9D9"
          />
          <defs>
            <filter
              id="filter0_d"
              x="48"
              y="52"
              width="104"
              height="112.657"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="8" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.88656 0 0 0 0 0.884 0 0 0 0 0.916 0 0 0 0.8 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
          </defs>
        </svg>
      </SvgContainer>
      {/* {type === "Clusters" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800" >
            Please wait while your Cluster is being Onboarded!
            </Typography>
        </>
      )} */}

      {type === "cluster" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800">
            No Clusters available in the current Workspace!
          </Typography>

          {/* <h3>
            Please{' '}
            <Link className="link" to="/cluster-onboarding">
              Onboard
            </Link>{' '}
            a Cluster or Change the Workspace
          </h3> */}
        </>
      )}
      {type === "node" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800">
            No Nodes available in the selected Clusters!
          </Typography>
        </>
      )}

      {type === "pod" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800">
            No Pods available in the selected Nodes!
          </Typography>
        </>
      )}
      {type === "container" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800">
            {" "}
            No Containers available in the selected Pods!`
          </Typography>
        </>
      )}
      {type === "vmgroup" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800">
            No Instance Groups available in the current Workspace!
          </Typography>
        </>
      )}
      {type === "vm" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800">
            No Instances available in the selected Instance Group!
          </Typography>
        </>
      )}
      {type === "process" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800">
            {" "}
            No Processes available in the selected Instance!
          </Typography>
        </>
      )}
      {/* {type === "vm" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800">
            No VM available in the selected node!
          </Typography>
        </>
      )} */}
      {type === "nodata" && (
        <>
          <Typography variant="h2" className="mt-8 mb-2 text-2xl text-slate-800">
            No data to show!
          </Typography>
        </>
      )}
    </Container>
  );
};

export default EmptyClusterData;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height:100vh;
`;

const SvgContainer = styled.div`
  width: 200px;
  height: 200px;
  background-color: rgb(242, 242, 242);
  border-radius: 50%;
`;
