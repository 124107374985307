import React from "react";

import { SelectAsync, DateRangePicker } from "components/simple";

import styles from "./styles.module.scss";
import { getDynamicSelect } from "store/entities/baseline/service";
import { createList } from "store/entities/vulnerabilities/selectors";

const DynamicSelect = ({ item, onChange, selectedFilterField, id, dictList }) => {
  const selected = selectedFilterField;
  const name = dictList?.[item] || item;

  const handleChange = value => {
    onChange({ [item]: value });
  };

  if (item === "last_seen" || item === "date_discovered") {
    return (
      <DateRangePicker
        placeholder="Select date"
        label={name}
        onChange={handleChange}
        value={selected}
      />
    );
  }

  const requst = async (search, page) => {
    if (item !== "last_seen" && item !== "date_discovered") {
      try {
        const res = await getDynamicSelect({ field: item, id, search, page });
        const listOptions = createList(res?.results);
        return { options: listOptions, next: res?.next };
      } catch (e) {
        // return console.log("err", e);
      }
    }
  };

  const loadOptions = async (search, prevOptions, { page }) => {
    const { options, next } = await requst(search, page);
    const hasMore = !search ? Boolean(next) : false;

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    };
  };

  return (
    <SelectAsync
      containerClass={styles.selectPanel}
      onChange={handleChange}
      value={selected}
      placeholder={name}
      label={name}
      isClearable
      cacheOptions
      // defaultOptions
      loadOptions={loadOptions}
      onInputChange={() => null}
      additional={{
        page: 1
      }}
    />
  );
};

export default DynamicSelect;
