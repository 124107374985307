/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeQuery, useQuery } from "helper/history";

import { failedAssetsColumns } from "../tableColumns";

import { getBaselineAssetsSelector } from "store/entities/baseline/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import { getBaselineAssets, setBaselineAssetsParams } from "store/entities/baseline/actions";

import { Table } from "components/complex";
import { ContentContainer, Input, Icon } from "components/simple";
import { Button as MuiBtn } from "@mui/material";
import { CSVLink } from "react-csv";
import DetailsModal from "./DetailsModal";

import styles from "../styles.module.scss";

const FailedBaseline = () => {
  const [assets, setAssets] = useState([]);
  const [searchText, setSearchText] = useState([]);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const selectTenant = useSelector(state => state.users.selectTenant);

  const isLoading = useSelector(state => createLoadingSelector([getBaselineAssets.type])(state));

  const data = useSelector(getBaselineAssetsSelector);

  const query = useQuery();
  const searchQuery = query.get("title");

  const params = useParams();
  const id = params?.id;

  // TEST finding id
  // const id = "49959868-12ca-43f1-bde6-74610edf9b7e";

  // get default request
  const getRequest = () => {
    dispatch(
      setBaselineAssetsParams({
        id
      })
    );
  };

  useEffect(() => {
    setAssets(data);
  }, [data]);

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  // Search
  const searchByText = text => {
    return data.filter(elem => {
      const { asset, control, control_id, groups, expected_result, result } = elem;
      const subStr = `${asset} ${control} ${control_id} ${groups} ${expected_result} ${result}`;
      return subStr.toLowerCase().includes(text);
    });
  };

  const handleSearch = v => {
    // local search
    setSearchText(v);
    const searchedArr = searchByText(v.toLowerCase());
    setAssets(searchedArr);
  };

  useEffect(() => {
    if (searchQuery) {
      setSearchText(searchQuery);
      setTimeout(() => {
        handleSearch(searchQuery);
      }, 500);
      removeQuery("title");
    }
  }, [searchQuery]);

  const onCellClick = (p, e) => {
    e.stopPropagation();
    if (p?.row?.check_id) {
      setDetailsModalOpen(p?.row?.check_id);
    }
  };

  const headersCSV = [
    { label: "Host", key: "assets" },
    { label: "Control ID", key: "control" },
    { label: "Groups", key: "groups" },
    { label: "Finding", key: "actual_value" },
    { label: "Expected value", key: "expected_result" },
    { label: "Result", key: "result" }
  ];

  const configCSV = {
    data,
    headers: headersCSV,
    filename: "Assets_Failed_Report.csv"
  };

  const customStyles = {
    height: 710,
    width: 1,
    padding: "30px",
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      borderRadius: "10px",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important"
      }
    }
  };

  const tableConfig = {
    checkboxVisible: false,
    paginationVisible: true,
    hideFooter: false,
    sortingMode: false
  };

  return (
    <ContentContainer className={styles.container}>
      <div className={styles.controls}>
        <Input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={val => handleSearch(val.target.value)}
          containerClassName={styles.searchContainer}
          className={styles.search}
        />
        <CSVLink {...configCSV}>
          <MuiBtn className={`${styles.btn} ${styles.create}`}>
            Export
            <Icon.Export color="#ffff" />
          </MuiBtn>
        </CSVLink>
      </div>
      <Table
        data={isLoading ? [] : assets}
        columns={failedAssetsColumns}
        loading={isLoading}
        onCellClick={(p, e) => onCellClick(p, e)}
        customStyles={customStyles}
        {...tableConfig}
      />
      <DetailsModal
        isOpen={Boolean(isDetailsModalOpen)}
        onCloseModal={() => setDetailsModalOpen(false)}
        id={isDetailsModalOpen}
      />
    </ContentContainer>
  );
};

FailedBaseline.propTypes = {};

export default FailedBaseline;
