import React from "react";
import { PieChart, Pie, Cell, Legend, Tooltip, Label, ResponsiveContainer } from "recharts";
import NoChartData from "./NoData";
import styles from "screens/RuntimeSecurity/CWPP/charts/styles.module.scss";

function Piechart({
  data,
  legendStyle,
  height,
  width,
  cx,
  cy,
  label,
  dataKey,
  outerRadius,
  innerRadius,
  content,
  wrapperStyle,
  onClick
}) {
  return (
    <ResponsiveContainer height={"100%"} width={"100%"}>
      {data?.length > 0 ? (
        <div className="w-full grid grid-cols-2 h-full justify-between overflow-hidden gap-2">
          <div className="col-span-1">
            <PieChart width={300} height={300}>
              <Pie
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={innerRadius || 0}
                outerRadius={outerRadius}
                fill="#8884d8"
                dataKey={dataKey}
                label={label}
                onClick={xAxisInfo => {
                  onClick(xAxisInfo);
                }}
              >
                {data?.map((entry, index) => (
                  <Cell className="cursor-pointer" key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip content={content} />
              {/* <Legend layout="vertical" iconType="circle" wrapperStyle={legendStyle} /> */}
            </PieChart>
          </div>
          <div
            className={`col-span-1 flex flex-col ${
              data?.length < 6 ? "justify-center" : null
            }  gap-[6px] py-3 overflow-auto ${styles.legend}`}
          >
            {data?.map((item, index) => (
              <div
                className={`grid grid-cols-6 ${
                  data?.length < 6 ? "items-center" : "items-top"
                } center justify-center text-sm`}
                key={index}
              >
                <div
                  className="w-3 h-3 rounded-full col-span-1 m-auto"
                  style={{ background: item?.color }}
                />
                <p className="col-span-5 m-0">{item?.name || item?.title}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoChartData />
      )}
    </ResponsiveContainer>
  );
}

Piechart.defaultProps = {
  data: [],
  legendStyle: {
    left: "70%",
    top: "5%",
    lineHeight: "2rem"
  },
  cx: 170,
  cy: 150,
  label: false,
  dataKey: "value",
  outerRadius: 100,
  wrapperStyle: { backgroundColor: "white", padding: "10px", border: "1px solid lightgray" }
};

export default Piechart;
