import React from "react";

import { Button, Title, Modal } from "components/simple";

import styles from "./styles.module.scss";

const DeleteModal = ({ show, onSubmit, onClose, count }) => {
  const open = show;

  const close = () => {
    onClose();
  };

  const onDelete = () => {
    onClose();
    onSubmit();
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Delete</Title>
      <div className={styles.content}>
        <div className={styles.block}>
          <div className={styles.cont}>
            <div className={styles.title}>
              Are you sure you want to delete{" "}
              {count > 1 ? `${count} positions` : `${count} position`} ?
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button variant="outline" onClick={close}>
          Close
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
