import React, { useState } from "react";

import { ContentContainer } from "components/simple";
import { useGetImageVulnerabilities } from "store/entities/imageDetails/queries";
import { VulnerabilityTable } from "screens/Issues/RiskBasedPrioritization/components/VulnerabilityTable";
import { VulnerabilityDetailsDrawer } from "screens/Issues/RiskBasedPrioritization/components/VulnerabilityDetailsDrawer";

export const Vulnerabilities = ({ assetId }) => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const vulnerabilitiesQuery = useGetImageVulnerabilities(assetId);

  return (
    <ContentContainer className="p-4 mt-2">
      <div className="mt-3">
        <VulnerabilityTable
          setShowDrawer={setShowDrawer}
          vulnerabilitiesQuery={vulnerabilitiesQuery}
        />
        <VulnerabilityDetailsDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
      </div>
    </ContentContainer>
  );
};
