import React, { useState } from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography
} from "@mui/material";
import styled from "@emotion/styled";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import styles from "./styles.module.scss";

export default function CustomAccordion({
  Accordionkey,
  Summary,
  Details,
  expand,
  onChange,
  highlitedColor,
  fontDetails,
  backgroundDetails,
  paddingDetails,
  iconSize,
  summaryBorder,
  summaryRadius,
  accRadius,
  accBottom,
  accBorder,
  summaryFont,
  isDefaultopen = true,
  defaultExpanded
}) {
  // when ever use this component make sure implement expand state and handleAccordionChange function
  // to restrict one accordion will open
  // const [expand, setExpand] = useState(false);
  // const handleAccordionChange = panel => (e, newExpanded) => {
  //   setExpand(newExpanded ? panel : false);
  // };
  const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={2} square {...props} />
  ))(({ theme }) => ({
    border: accBorder,
    borderRadius: accRadius,
    marginBottom: accBottom
  }));
  const AccordionSummary = styled(props => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowForwardIosSharpIcon
          sx={{ fontSize: iconSize, transition: "transform 1s ease-in-out" }}
        />
      }
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: "row-reverse",
    border: summaryBorder,
    borderLeft: ` 7px solid ${highlitedColor}`,
    borderRadius: summaryRadius,
    "& .MuiAccordionSummary-root": {
      background: "#FFF"
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
      background: "#FFF",
      fontSize: summaryFont
    },
  }));
  const AccordionDetails = styled(props => <MuiAccordionDetails {...props} />)(({ theme }) => ({
    border: "0px",
    fontSize: fontDetails,
    background: backgroundDetails,
    padding: paddingDetails
  }));

  return (
    <div>
      <Accordion
        expanded={isDefaultopen ? expand : expand === Accordionkey}
        className={styles.MuiAccordionroot}
        onChange={onChange}
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary aria-controls={Accordionkey} id={Accordionkey}>{Summary}</AccordionSummary>
        <AccordionDetails>{Details}</AccordionDetails>
      </Accordion>
    </div>
  );
}
