import React, { useState } from "react";
import { Avatar, Box, IconButton, Popover, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import moment from "moment";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name?.split(" ")?.[0]?.[0]}${name.split(" ")?.[1]?.[0] || ""}`
  };
}

export const CommentMessage = ({ ownerName = "User", date, message, policyDetails }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", padding: "4px 8px" }}>
      <Avatar {...stringAvatar(ownerName.toUpperCase())} />
      <Box className={styles.policyMessage} sx={{ padding: "8px", fontSize: "10px" }}>
        {policyDetails?.approved_at && (
          <>
            <Typography>
              <strong>{ownerName}</strong> has approved changes at{" "}
              <strong>{moment(date).fromNow()}</strong>
              <strong>{" (" + moment(date).format("hh:mm a") + ")"}</strong>
            </Typography>
            <Typography>{message}</Typography>
          </>
        )}
        {policyDetails?.rejected_at && (
          <>
            <Typography>
              <strong>{ownerName}</strong> has rejected changes at{" "}
              <strong>{moment(date).fromNow()}</strong>
              <strong>{" (" + date + ")"}</strong>
            </Typography>
            <Typography>{message}</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const CommentMessagePopover = ({ message, date, owner_name = "User" }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopOverOpen = e => {
    setAnchorEl(e.currentTarget);
  };
  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onMouseEnter={handlePopOverOpen} onMouseLeave={handlePopOverClose}>
        <EmailIcon />
      </IconButton>
      <Popover
        sx={{
          pointerEvents: "none",
          opacity: "0.9"
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopOverClose}
      >
        <CommentMessage ownerName={owner_name} date={date} message={message} />
      </Popover>
    </>
  );
};

CommentMessagePopover.propTypes = {
  message: PropTypes.string,
  date: PropTypes.string.isRequired,
  owner_name: PropTypes.string
};

CommentMessagePopover.defaultProps = {
  message: "No message from reviewer"
};

export default CommentMessagePopover;
