import React from "react";
import styled from "styled-components";
import { truncate } from "lodash";
import { CardContainer } from "../PolicyMetadataCard";
import {
  useKubeArmorPolicyEditorState,
  updateActiveCard,
  updateIsEditingProcessRule,
  removeRuleFromPolicyJSON,
  updateShowProcessMandateParentPath,
  handleProcessRuleFormSubmit,
  deleteIndividualRule
} from "store/entities/policyEditor/kubeArmorPolicyEditor/slice";
import {
  ACTION_OPTIONS,
  DEFAULT_PROCESS_FORM_VALUES,
  PROCESS,
  SEVERITY_OPTIONS
} from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getActionPillColor,
  validateExecutionPath,
  validateParentPath
} from "store/entities/policyEditor/kubeArmorPolicyEditor/helpers";
import clsx from "clsx";
import { useKubeArmorPolicyContext } from "../..";
import { Controller } from "react-hook-form";
import { Button, Checkbox, CreatableInput, Input, Select } from "components/simple";
import { useEditIndividualRule } from "store/entities/policyEditor/kubeArmorPolicyEditor/hooks";

const ProcessRulesCard = () => {
  const dispatch = useDispatch();
  const { processRuleForm } = useKubeArmorPolicyContext();
  const activeCard = useKubeArmorPolicyEditorState("activeCard");
  const processRule = useKubeArmorPolicyEditorState("processRule");
  const { isEditingProcessRule, processRuleData, showProcessMandateParentPath } = processRule;
  const editIndividualRule = useEditIndividualRule();

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
    watch,
    setValue,
    reset: resetProcessRuleForm,
    setError
  } = processRuleForm;

  const [directory, path] = watch(["directory", "path"]);

  const onFormSubmit = formValues => {
    dispatch(handleProcessRuleFormSubmit(formValues));
    resetProcessRuleForm(DEFAULT_PROCESS_FORM_VALUES);
  };

  const onChangeExecutionPath = (e, onChange) => {
    const { value } = e.target;

    if (value.endsWith("/")) {
      setValue("directory", true);
    } else {
      setValue("directory", false);
    }
    onChange(value);
  };

  const onChangeDirectory = (value, onChange) => {
    if (value) {
      setValue("path", `${path}/`);
    } else {
      setValue("path", path.slice(0, -1));
    }
    onChange(value);
  };

  return (
    <CardContainer
      className="flex flex-col gap-3"
      $isActive={activeCard === PROCESS}
      onFocus={() => dispatch(updateActiveCard(PROCESS))}
    >
      <div className="flex justify-between">
        <Typography sx={{ color: "primary.main", fontSize: "16px", fontWeight: 600 }}>
          Process {processRuleData?.length ? <span>({processRuleData?.length})</span> : null}
        </Typography>
        <span className="flex gap-2">
          <AddIcon
            className="cursor-pointer"
            onClick={() => dispatch(updateIsEditingProcessRule(true))}
          />
          <CloseIcon
            className="cursor-pointer"
            onClick={() => dispatch(removeRuleFromPolicyJSON(PROCESS))}
          />
        </span>
      </div>

      {processRuleData?.map((rule, index) => (
        <div
          key={index}
          className="flex flex-col gap-2 border-2 border-solid border-gray-200 relative p-2 pt-4 mt-1 rounded"
        >
          {rule?.action ? (
            <span
              className={clsx(
                "absolute top-0 left-2 border border-solid rounded-full px-2 py-0 transform -translate-y-1/2",
                getActionPillColor(rule?.action)
              )}
            >
              <Typography sx={{ fontSize: "14px" }}>{rule?.action}</Typography>
            </span>
          ) : null}

          {!isEditingProcessRule ? (
            <span className="flex gap-2 absolute top-0 right-2 transform -translate-y-1/2">
              <span
                className="cursor-pointer rounded-full px-1 py-0 flex items-center justify-center gap-[2px] bg-blue-100 border border-solid"
                onClick={() => editIndividualRule(PROCESS.toLowerCase(), rule, index)}
              >
                <EditIcon sx={{ fontSize: "16px", color: "primary.main" }} />
                <Typography sx={{ fontSize: "14px", color: "primary.main" }}>Edit</Typography>
              </span>
              <span
                className="cursor-pointer rounded-full px-1 py-0 flex items-center justify-center gap-[2px] bg-blue-100 border border-solid"
                onClick={() => {
                  dispatch(deleteIndividualRule({ ruleType: PROCESS.toLowerCase(), rule, index }));
                }}
              >
                <DeleteIcon sx={{ fontSize: "16px", color: "primary.main" }} />
                <Typography sx={{ fontSize: "14px", color: "primary.main" }}>Delete</Typography>
              </span>
            </span>
          ) : null}

          <div className="flex items-center px-2 py-1 border-2 border-solid border-gray-200 text-gray-600 rounded">
            <Typography>{truncate(rule?.path || rule?.dir, { length: 42 })}</Typography>
          </div>

          {rule?.fromSource ? (
            <div className="flex flex-col gap-1">
              <Typography>Mandate Parent Path</Typography>
              <div className="flex items-center flex-wrap gap-2 px-2 py-1 border-2 border-solid border-gray-200 rounded">
                {rule?.fromSource?.map((parentPath, parentPathIdx) => (
                  <span
                    key={parentPathIdx}
                    className="p-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-md"
                  >
                    <Typography>{parentPath?.path}</Typography>
                  </span>
                ))}
              </div>
            </div>
          ) : null}

          {rule?.ownerOnly || rule?.directory || rule?.recursive ? (
            <div className="flex flex-wrap gap-2">
              {rule.ownerOnly && (
                <span className="rounded-full px-2 py-1 bg-blue-100 text-xs font-medium">
                  <Typography sx={{ color: "primary.light", fontSize: "14px" }}>
                    OwnerOnly
                  </Typography>
                </span>
              )}
              {rule?.dir && (
                <span className="rounded-full px-2 py-1 bg-blue-100 text-xs font-medium">
                  <Typography sx={{ color: "primary.light", fontSize: "14px" }}>
                    IsDirectory
                  </Typography>
                </span>
              )}
              {rule.recursive && (
                <span className="rounded-full px-2 py-1 bg-blue-100 text-xs font-medium">
                  <Typography sx={{ color: "primary.light", fontSize: "14px" }}>
                    IsRecursive
                  </Typography>
                </span>
              )}
            </div>
          ) : null}

          {rule?.severity ? (
            <div className="flex flex-col gap-2">
              <Typography>Severity ({rule?.severity})</Typography>
              <div className="flex gap-1">
                {[...Array(Number(rule?.severity)).keys()].map(i => (
                  <SeverityBox key={i} $fill />
                ))}
                {[...Array(10 - Number(rule?.severity)).keys()].map(i => (
                  <SeverityBox key={i} $fill={false} />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      ))}

      {isEditingProcessRule && (
        <form className="grid gap-2" onSubmit={handleSubmit(onFormSubmit)}>
          <div>
            <Controller
              name="path"
              rules={{
                required: "Execution path should be filled",
                validate: validateExecutionPath
              }}
              control={control}
              ref={null}
              render={({ field: { value, onChange } }) => (
                <Input
                  label="Execution Path"
                  value={value}
                  onChange={e => onChangeExecutionPath(e, onChange)}
                  placeholder="Enter execution path"
                  autoFocus
                  invalid={errors?.path?.message}
                  className="panzoom-exclude"
                  required
                />
              )}
            />
          </div>

          <div className="ml-1 flex flex-wrap gap-1">
            <div>
              <Controller
                name="ownerOnly"
                control={control}
                ref={null}
                render={({ field: { value, onChange } }) => (
                  <Checkbox label="OwnerOnly" value={value} onChange={v => onChange(v)} />
                )}
              />
            </div>

            <div>
              <Controller
                name="directory"
                control={control}
                ref={null}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    label="IsDirectory"
                    value={value}
                    onChange={v => onChangeDirectory(v, onChange)}
                  />
                )}
              />
            </div>

            {directory && (
              <div>
                <Controller
                  name="recursive"
                  control={control}
                  ref={null}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox label="IsRecursive" value={value} onChange={v => onChange(v)} />
                  )}
                />
              </div>
            )}
          </div>

          <div>
            <Controller
              name="severity"
              rules={{
                required: "Severity should be selected"
              }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  label="Severity"
                  options={SEVERITY_OPTIONS}
                  value={SEVERITY_OPTIONS.find(item => item.value === value)}
                  onChange={selectedOption => {
                    onChange(selectedOption.value);
                  }}
                  isSearchable={false}
                  placeholder="Select severity"
                  error={errors?.severity?.message}
                  className="panzoom-exclude"
                  required
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="action"
              rules={{
                required: "Action should be selected"
              }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  label="Action"
                  options={ACTION_OPTIONS}
                  value={ACTION_OPTIONS.find(item => item.value === value)}
                  onChange={selectedOption => {
                    onChange(selectedOption.value);
                  }}
                  isSearchable={false}
                  placeholder="Select action"
                  error={errors?.action?.message}
                  className="panzoom-exclude"
                  required
                />
              )}
            />
          </div>

          {showProcessMandateParentPath && (
            <div className="flex flex-col gap-1">
              <Controller
                name="fromSource"
                control={control}
                ref={null}
                render={({ field: { value, onChange } }) => (
                  <CreatableInput
                    label="Parent Path"
                    value={value}
                    onChange={onChange}
                    createOption={v => ({
                      label: v,
                      value: v,
                      path: v
                    })}
                    validate={v => validateParentPath(v, setError)}
                    placeholder="Enter parent path"
                    error={errors?.fromSource?.message}
                    className="panzoom-exclude"
                  />
                )}
              />

              <div className="flex items-center gap-1">
                <InfoOutlinedIcon sx={{ color: "primary.light", fontSize: "18px", m: 0 }} />
                <Typography>Press &apos;Enter&apos; to add parent path</Typography>
              </div>
            </div>
          )}

          <div className="flex justify-between items-center">
            {!showProcessMandateParentPath ? (
              <Typography
                sx={{ color: "primary.light", cursor: "pointer" }}
                onClick={() => dispatch(updateShowProcessMandateParentPath(true))}
              >
                + Add Mandate Parent Path
              </Typography>
            ) : (
              <span />
            )}

            <Button
              type="submit"
              onClick={handleSubmit(onFormSubmit)}
              disabled={!isValid}
              className="panzoom-exclude"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </CardContainer>
  );
};

export const SeverityBox = styled.span`
  background-color: ${({ $fill }) => ($fill ? "#889ed7" : "#E7ECFFF7")};
  width: 24px;
  height: 6px;
  border-radius: 4px;
`;

export default ProcessRulesCard;
