import { useMutation } from "react-query";
import { customPost } from "api/base";
import { getAccuknoxWorkspace } from "api/api";
import { getUserId } from "store/entities/auth/utils";

export const useChangeRegistryStatusMutate = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    async ({ id, status }) => {
      const payload = {
        id,
        status,
        updated_by: getUserId(),
        workspace_id: getAccuknoxWorkspace()
      };
      const response = await customPost(
        "/vulnerabilityservice/api/v1/registry/change-status-registry",
        payload
      );
      const res = await response.json();
      return res;
    },
    {
      onSuccess,
      onError
    }
  );
};

export const useAddRegistryMutate = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    ["addRegistry"],
    async payload => {
      const response = await customPost(
        "/vulnerabilityservice/api/v1/registry/add-registry",
        payload
      );
      const res = await response.json();
      return res;
    },
    {
      onSuccess,
      onError
    }
  );
};

export const useDeleteRegistryMutate = (registryId, onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    async () => {
      const payload = {
        id: registryId,
        updated_by: getUserId(),
        workspace_id: getAccuknoxWorkspace()
      };
      const response = await customPost(
        "/vulnerabilityservice/api/v1/registry/delete-registry",
        payload
      );
      const res = await response.json();
      return res;
    },
    {
      onSuccess,
      onError
    }
  );
};

export const useEditRegistryMutate = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    ["editRegistry"],
    async payload => {
      const response = await customPost(
        "/vulnerabilityservice/api/v1/registry/edit-registry",
        payload
      );
      const res = await response.json();
      return res;
    },
    {
      onSuccess,
      onError
    }
  );
};

export const useTestRegistryCredMutate = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    ["testRegistry"],
    async payload => {
      const response = await customPost(
        "/vulnerabilityservice/api/v1/registry/validate-registry-creds",
        payload
      );
      const res = await response.json();

      return res;
    },
    {
      onSuccess,
      onError
    }
  );
};
