import React, { useState } from "react";
import PropTypes from "prop-types";

import Creatable from "react-select/creatable";
import { components } from "react-select";
import { FormLabel } from "@mui/material";

import QuestionTooltip from "../QuestionTooltip";

import styles from "../Select/styles.module.scss";
import { stylesSelect } from "../Select/styles";

const MultiValue = props => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

export const CreatableInput = ({
  containerClass,
  error,
  style,
  onChange,
  name,
  value,
  label,
  isDisabled,
  required,
  placeholder,
  options,
  isMulti,
  tooltip,
  createOption,
  validate,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = v => {
    setInputValue(v);
  };

  const handleKeyDown = event => {
    if (!inputValue) return;
    if (event.key === "Enter" || event.key === "Tab") {
      if (validate(inputValue)) {
        setInputValue("");
        const newOption = createOption(inputValue);
        if (newOption) {
          onChange([...(value || []), newOption]);
        } else {
          onChange([...(value || [])]);
        }
      }
      event.preventDefault();
    }
  };

  const customStyles = {
    ...stylesSelect,
    ...{ placeholder: s => ({ ...s }) },
    ...{ input: s => ({ ...s, maxWidth: "280px", ...(error && { color: "#d83e3c" }) }) },
    ...style
  };

  return (
    <div
      className={`${containerClass} ${styles.container} ${required ? styles.required : ""}`}
      onClick={e => e.stopPropagation()}
    >
      {label && (
        <div className={styles.labelCont}>
          <FormLabel className={styles.label}>{label}</FormLabel>
          {tooltip && <QuestionTooltip text={tooltip} />}
        </div>
      )}
      <Creatable
        isDisabled={isDisabled}
        components={{ DropdownIndicator: null, ...(isMulti ? { MultiValue } : {}) }}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={!placeholder ? "" : placeholder}
        styles={customStyles}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        isClearable
        isMulti
        menuIsOpen={false}
        required={required}
        {...rest}
      />

      {error && <div className={styles.error}>{!error ? "" : error}</div>}
    </div>
  );
};

CreatableInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.array
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  containerClass: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isDisabled: PropTypes.bool,
  tooltip: PropTypes.string
};

CreatableInput.defaultProps = {
  value: "",
  name: "",
  placeholder: "",
  label: "",
  error: "",
  containerClass: "",
  required: false,
  isDisabled: false,
  onChange: () => {},
  tooltip: ""
};
