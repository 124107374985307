import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Title, Input, Button, ContentContainer } from "components/simple";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useForm, Controller } from "react-hook-form";
import { EDIT_ELASTIC_SEARCH_LINK, helpElastic } from "screens/ChannelIntegration/constants";
import Elastic from "../../assets/img/es.svg";
import { useHistory, useLocation } from "react-router-dom";
import ErrorText from "components/simple/ErrorText";
import useChannelList from "../hooks/listChannel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  useEditElasticIntegration,
  useElasticIntegration,
  useElastictest
} from "screens/ChannelIntegration/services";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";
import DeleteModal from "./DeleteModal";
import { getAccuknoxWorkspace } from "api/api";
import { useChannelIntegrationState } from "store/entities/channels/slice";
import { useDeleteElastic } from "store/entities/channels/mutation";

function ElasticSearch() {
  const [integrationName, setIntegrationName] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [mountPath, setMountPath] = useState("");
  const [indexName, setIndexname] = useState("");
  const [indexType, setIndexType] = useState("");
  const [uniquename, setUniquename] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm();
  const workspaceVal = getAccuknoxWorkspace();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isFetching } = useChannelList();
  const channelData = useChannelIntegrationState("channelData");
  const uniqueIntegrationName = channelData?.dataList?.map(e => e?.IntegrationName);
  function integrationNameCheck(name) {
    let result = false;
    uniqueIntegrationName?.filter(item => {
      if (item === name) {
        result = true;
      }
      return result;
    });
    setUniquename(result);
    return result;
  }
  // edit stuffs
  const getIntegrationName = location?.state?.ElasticSearchdetails[0]?.INTEGRATION_NAME;
  const getApiUrl = location?.state?.ElasticSearchdetails[0]?.API_URL;
  const getMountPath = location?.state?.ElasticSearchdetails[0]?.MOUNT_PATH;
  const getIndexName = location?.state?.ElasticSearchdetails[0]?.INDEX_NAME;
  const getIndexType = location?.state?.ElasticSearchdetails[0]?.INDEX_TYPE;
  const getUsername = location?.state?.ElasticSearchdetails[0]?.USERNAME;
  const getPassword = location?.state?.ElasticSearchdetails[0]?.PASSWORD;

  useEffect(() => {
    if (location?.pathname === EDIT_ELASTIC_SEARCH_LINK) {
      setIntegrationName(getIntegrationName);
      setSiteUrl(getApiUrl);
      setMountPath(getMountPath);
      setIndexname(getIndexName);
      setIndexType(getIndexType);
      setPassword(getPassword);
      setUserName(getUsername);
    }
  }, []);

  useEffect(() => {
    if (
      (location?.pathname === EDIT_ELASTIC_SEARCH_LINK && integrationName) ||
      siteUrl ||
      mountPath ||
      indexName ||
      indexType ||
      password ||
      userName
    ) {
      setValue("integrationName", integrationName);
      setValue("ApiUrl", siteUrl);
      setValue("MountPath", mountPath);
      setValue("IndexName", indexName);
      setValue("IndexType", indexType);
      setValue("pass", password);
      setValue("Username", userName);
    }
  }, [integrationName, siteUrl, mountPath, indexName, indexType, password, userName]);

  const handleTest = async e => {
    try {
      await useElastictest(workspaceVal, integrationName, siteUrl, password, userName)
        .then(res => {
          res.json().then(data => {
            if (data?.apiResponse?.includes("Test Successful")) {
              dispatch(addNotification({ msg: data?.apiResponse, type: "success" }));
              setDisableSave(false);
            } else {
              dispatch(addNotification({ msg: data?.apiResponse, type: "error" }));
              setDisableSave(true);
            }
          });
        })
        .catch(err => {
          Promise.reject(err);
          setDisableSave(true);
        });
    } catch (err) {
      // console.log(err)
    }
  };
  const onSubmit = async e => {
    if (location?.pathname === EDIT_ELASTIC_SEARCH_LINK) {
      try {
        await useEditElasticIntegration(
          workspaceVal,
          integrationName,
          siteUrl,
          mountPath,
          indexName,
          indexType,
          getIntegrationName,
          password,
          userName
        )
          .then(res => {
            if (res.status === 200) {
              dispatch(addNotification({ msg: "Elastic Updated Successful", type: "success" }));
              history.push("/settings/integrations/list");
            } else {
              dispatch(addNotification({ msg: "Something went wrong", type: "error" }));
            }
          })
          .catch(err => {
            Promise.reject(err);
          });
      } catch (err) {
        // console.log(err)
      }
    }
    if (!integrationNameCheck(e?.integrationName)) {
      try {
        await useElasticIntegration(
          workspaceVal,
          integrationName,
          siteUrl,
          mountPath,
          indexName,
          indexType,
          password,
          userName
        )
          .then(res => {
            if (res.status === 200) {
              dispatch(addNotification({ msg: "Elastic Integration Successful", type: "success" }));
              history.push("/settings/integrations/list");
            } else {
              dispatch(addNotification({ msg: "Something went wrong", type: "error" }));
            }
          })
          .catch(err => {
            Promise.reject(err);
          });
      } catch (err) {
        // console.log(err)
      }
    }
  };
  const closeHandler = () => {
    setIsOpen(false);
  };
  const deleteElasticChannel = useDeleteElastic(data => {
    history.push("/settings/integrations/list");
    dispatch(addNotification({ msg: "Status: Deleted Elastic Integration", type: "success" }));
  });
  const handleDelete = async () => {
    const workspaceVal = getAccuknoxWorkspace();
    deleteElasticChannel.mutate({
      workspaceVal,
      getIntegrationName
    });
  };

  return (
    <ContentContainer className={styles.container}>
      <DeleteModal
        isModalOpen={isOpen}
        onCloseModal={() => closeHandler()}
        onSubmit={() => handleDelete()}
      />
      <div className="flex justify-between py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex gap-1">
          <img src={Elastic} alt="icon" />
          <Title>Elastic Search</Title>
        </div>
        <div className="flex justify-center items-center gap-5">
          {location?.pathname === EDIT_ELASTIC_SEARCH_LINK && (
            <div
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => setIsOpen(true)}
            >
              <DeleteOutlineIcon />
              Delete Channel
            </div>
          )}
          <div
            onClick={() => window.open(helpElastic, "_blank", "noopener,noreferrer")}
            className="flex justify-center items-center text-blue-700 font-medium cursor-pointer"
          >
            Help <HelpOutlineIcon className={styles.help_icon} />
          </div>
        </div>
      </div>
      <div className="py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex flex-row gap-7">
          <div className="w-3/6">
            <Controller
              name="integrationName"
              {...register("integrationName", {
                required: "This Field Cannot be empty",
                maxLength: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="integrationName"
                  label="Integration Name"
                  placeholder="Enter a name for integration"
                  required
                  value={integrationName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setIntegrationName(evt.target.value);
                    integrationNameCheck(evt);
                  }}
                  onKeyPress={e => integrationNameCheck(e)}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.integrationName && errors.integrationName.message} />
              <ErrorText errorMsg={uniquename ? "Integration name already exist" : null} />
            </div>
          </div>
          <div className="w-full">
            <Controller
              name="ApiUrl"
              {...register("ApiUrl", {
                required: "Elastic Host URL required",
                pattern: {
                  value: /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/,
                  message: "Invalid Elastic Host URL, Please enter a valid URL."
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="ApiUrl"
                  label="Elastic Host"
                  placeholder="https://myEhost:9200"
                  required
                  value={siteUrl}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setSiteUrl(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.ApiUrl && errors.ApiUrl.message} />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-3 pb-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex flex-row gap-8 mt-5 mb-3">
          <div style={{ width: "31.5%" }}>
            <Controller
              name="Username"
              {...register("Username", {
                required: "Username Required",
                maxLength: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="Username"
                  label="User Name"
                  placeholder="Enter the username"
                  required
                  value={userName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setUserName(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.Username && errors.Username.message} />
            </div>
          </div>
          <div style={{ width: "31.5%" }}>
            <Controller
              name="pass"
              {...register("pass", {
                required: "Password Required",
                minLength: {
                  value: 6,
                  message: "Minimum length for this field is 6 characters"
                },
                maxLength: {
                  value: 30,
                  message: "Maximum length for this field is 30 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="pass"
                  label="Password"
                  placeholder="......"
                  required
                  value={password}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setPassword(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.pass && errors.pass.message} />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-7 pt-3">
          <div className="w-7/12">
            <Controller
              name="MountPath"
              {...register("MountPath", {
                required: false,
                maxLength: {
                  value: 50,
                  message: "Maximum length for this field is 50 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="MountPath"
                  label="Mount Path"
                  placeholder="/path"
                  value={mountPath}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setMountPath(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.MountPath && errors.MountPath.message} />
            </div>
          </div>
          <div className="w-7/12">
            <Controller
              name="IndexName"
              {...register("IndexName", {
                required: false,
                maxLength: {
                  value: 30,
                  message: "Maximum length for this field is 30 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="IndexName"
                  label="Index Name"
                  placeholder="Enter the index name"
                  value={indexName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setIndexname(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.IndexName && errors.IndexName.message} />
            </div>
          </div>
          <div className="w-7/12">
            <Controller
              name="IndexType"
              {...register("IndexType", {
                required: false,
                maxLength: {
                  value: 40,
                  message: "Maximum length for this field is 40 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="IndexType"
                  label="Index Type"
                  placeholder="Enter the index type"
                  value={indexType}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setIndexType(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.IndexType && errors.IndexType.message} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between pt-6 pb-4">
        <Button onClick={handleSubmit(handleTest)} disabled={!isDirty || !isValid}>
          Test
        </Button>
        <div className="flex">
          <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} disabled={!isValid} type="submit">
            Save
          </Button>
        </div>
      </div>
    </ContentContainer>
  );
}

export default ElasticSearch;
