import { getAccuknoxWorkspace } from "api/api";
import { getUserId } from "store/entities/auth/utils";
import { customPost, customGet, customPut, getFormRequest } from "api/base";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedClusters,
  getSelectedInfraType,
  getSelectedNamespaces,
  getSelectedPolicyStatus,
  getSelectedPolicyType,
  getSelectedCategory
} from "../filters/slice";
import { addNotification } from "../notifications/actions";

export const useGetAllPoliciesQuery = (
  onSuccess = () => {},
  pagePrev,
  pageNext,
  pageSize,
  workspaceId,
  selectedPolicyStatus,
  searchTerm
) => {
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedInfraType = useSelector(getSelectedInfraType);
  // const selectedPolicyStatus = useSelector(getSelectedPolicyStatus);
  const selectedPolicyType = useSelector(getSelectedPolicyType);

  return useQuery(
    [
      "allPolicies",
      workspaceId,
      pagePrev,
      pageNext,
      pageSize,
      selectedClusters,
      selectedNamespaces,
      selectedInfraType,
      selectedPolicyStatus,
      selectedPolicyType,
      searchTerm
    ],
    async () => {
      const payload = {
        workspace_id: workspaceId,
        workload: "k8s",
        page_previous: pagePrev,
        page_next: pageNext,
        filter: {
          cluster_id: [...selectedClusters?.map(c => c?.value)],
          namespace_id: [...selectedNamespaces?.map(n => n.value)],
          kind: [...selectedPolicyType.map(t => t.value)],
          // infra: selectedInfraType?.value || "GKE",
          node_id: [],
          pod_id: [],
          status: selectedPolicyStatus ? [selectedPolicyStatus] : [],
          name: {
            regex: searchTerm.length ? [searchTerm] : []
          }
        }
      };

      const response = await customPost("/policymanagement/v2/list-policy", payload);

      const res = await response.json();

      return res;
    },
    {
      select: data => {
        return {
          ...data,
          table_data:
            data?.list_of_policies?.map(item => ({
              id: item?.policy_id,
              policy_name: item?.name,
              latest_comment: {
                message: "Change policy type",
                updated_by: item?.updated_by,
                owner_name: item?.owner_name,
                review_msg: item?.review_msg || "No message from reviewer",
                date: item?.updated_at
              },
              policy_type: item?.type,
              policy_kind: item?.policy_kind,
              cluster: { id: item?.cluster_id, name: item?.cluster_name },
              namespace: { id: item?.namespace_id, name: item?.namespace_name },
              version: item?.version,
              pending_available: item?.pending_available ? true : false,
              changes_available: item?.changes_available ? true : false,
              applied_at: item?.applied_at && item?.applied_at,
              selector_labels:
                item?.labels?.map(l => ({
                  color: l.color,
                  label: `${l.name}=${l.value}`
                })) || [],
              policy_status: {
                policy_details: { id: item.policy_id, name: item.name, status: item?.status }
              },
              policy_options: {
                policy_details: {
                  id: item.policy_id,
                  name: item.name,
                  status: item?.status,
                  policy_tags: item?.tags
                }
              },
              status: item?.status,
              policy_tags: { tags: item?.tags, id: item.policy_id } || [],
              applied_at: item?.applied_at
            })) || []
        };
      },
      onSuccess,
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};
export const useGetAutoDiscoveredPoliciesQuery = (pagePrev, pageNext, pageSize) => {
  const workspaceId = getAccuknoxWorkspace();
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedInfraType = useSelector(getSelectedInfraType);
  const selectedPolicyStatus = useSelector(getSelectedPolicyStatus);
  const selectedPolicyType = useSelector(getSelectedPolicyType);
  const setSelectedCategory = useSelector(getSelectedCategory);

  return useQuery(
    [
      "autodiscoverdpolicy",
      workspaceId,
      pagePrev,
      pageNext,
      pageSize,
      selectedClusters,
      selectedNamespaces,
      selectedInfraType,
      selectedPolicyStatus,
      selectedPolicyType,
      setSelectedCategory
    ],
    async () => {
      const payload = {
        workspace_id: workspaceId,
        page_previous: pagePrev,
        page_next: pageNext,
        filter: {
          cluster_id: [...selectedClusters?.map(c => c?.value)],
          namespace_id: [...selectedNamespaces?.map(n => n.value)],
          type: [...selectedPolicyType.map(t => t.value)],
          infra: selectedInfraType?.value || "GKE",
          node_id: [],
          pod_id: [],
          status: [...selectedPolicyStatus?.map(s => s.value)],
          discover: {
            used: setSelectedCategory.includes("Used"),
            ignore: setSelectedCategory.includes("Ignore")
          }
        }
      };

      const response = await customPost(
        "/policymanagement/api/v1/auto-discover/get-policies",
        payload
      );
      const res = await response.json();

      return res;
    },
    {
      select: data => {
        return {
          ...data,
          table_data:
            data?.list_of_policies?.map(item => ({
              id: item?.policy_id,
              policy_name: item?.name,
              policy_type: item?.type,
              cluster: { id: item?.cluster_id, name: item?.cluster_name },
              cluster_id: item?.cluster_id,
              namespace: { id: item?.namespace_id, name: item?.namespace_name },
              category: item?.category,
              selector_labels:
                item?.labels?.map(l => ({ color: l.color, label: `${l.name}=${l.value}` })) || [],
              policy_status: item?.status,
              policy_tags: (item?.tags || [])?.join(", "),
              updated_by: item?.updated_by
            })) || []
        };
      },
      refetchOnWindowFocus: false
    }
  );
};

export const UpdatePolicy = (onSuccess = () => {}, policy_id) => {
  return useMutation(
    async ({ policy_id }) => {
      const payload = {
        id: policy_id,
        updated_by: getUserId()
      };
      const response = await customPost("/policymanagement/v2/policy/discovered/update", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess
    }
  );
};

export const useGetPolicyDetailsQuery = (policyId, onSuccess = () => {}) => {
  const dispatch = useDispatch();
  const workspaceId = getAccuknoxWorkspace();
  return useQuery(
    ["getPolicyDetails", workspaceId, policyId],
    async () => {
      const payload = { policy_id: policyId, workspace_id: workspaceId };
      const response = await customPost("/policymanagement/api/v1/rule/get-policy-info", payload);
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        onSuccess(data);
      },
      onError: () => {
        dispatch(addNotification({ msg: "Fetch Policy Info Failed", type: "error" }));
      }
    }
  );
};

export const useReviewChangePolicyApi = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ policyId, finalreview, prBody }) => {
      const payload = {
        policy_id: policyId,
        updated_by: getUserId(),
        pr_event: finalreview,
        pr_body: prBody
      };
      const result = await customPost(
        "/policymanagement/api/v1/policy/review-policy-change",
        payload
      );
      return result.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["policyComparison"]);
        queryClient.invalidateQueries(["policyChanges"]);
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Review", type: "error" }));
      }
    }
  );
};
export const callReviewChangePolicyApi = async (policyId, finalreview, PrBody) => {
  const payload = {
    policy_id: policyId,
    // workspace_id: workspaceId,
    updated_by: "30d4caa0-0c48-4d2c-874d-0edb1a950a43",
    pr_event: finalreview,
    pr_body: PrBody
  };
  const result = await customPost("/policymanagement/api/v1/policy/review-policy-change", payload);
  return result.json();
};

export const useGetYamlMutation = () => {
  return useMutation(async pID => {
    const workspaceId = getAccuknoxWorkspace();
    const payload = {
      workspace_id: workspaceId,
      policy_id: pID
    };
    const response = await customPost(
      "/policymanagement/api/v2/rule/get-policy-json-yaml",
      payload
    );
    const res = await response.json();
    return res;
  });
};

export const useApplyPolicyMutation = () => {
  return useMutation(async obj => {
    const workspaceId = getAccuknoxWorkspace();
    const payload = {
      workspace_id: workspaceId,
      ...obj
    };
    const response = customPost("/policymanagement/api/v1/auto-discover/add-policy", payload);
    const res = await response.json();
    return res;
  });
};

export const useIgnorePolicyMutation = () => {
  return useMutation(async obj => {
    const payload = {
      ...obj
    };
    const response = customPost(
      "/policymanagement/api/v1/auto-discover/enable-policies-ignore-flag",
      payload
    );
    const res = await response.json();
    return res;
  });
};

export const useGetAllPendingPolicies = (onSuccess = () => {}, pagePrev, pageNext, pageSize) => {
  const workspaceId = getAccuknoxWorkspace();
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedInfraType = useSelector(getSelectedInfraType);
  const selectedPolicyType = useSelector(getSelectedPolicyType);
  return useQuery(
    [
      "allPendingPolicy",
      workspaceId,
      pagePrev,
      pageNext,
      pageSize,
      selectedClusters,
      selectedNamespaces,
      selectedInfraType,
      selectedPolicyType
    ],
    async () => {
      const payload = {
        workspace_id: workspaceId,
        page_previous: pagePrev,
        page_next: pageNext,
        filter: {
          cluster_id: [...selectedClusters?.map(c => c?.value)],
          namespace_id: [...selectedNamespaces?.map(n => n.value)],
          type: [...selectedPolicyType.map(t => t.value)],
          infra: selectedInfraType?.value || "GKE",
          status: ["pending"]
        }
      };
      const response = await customPost(
        "/policymanagement/api/v1/audit-log/get-all-policies-version",
        payload
      );
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return {
          total_record: data?.total_record,
          list_of_policies: data?.list_of_policies?.map(item => ({
            ...item,
            id: item?.policy_id,
            policy_name: item?.name,
            selector_labels:
              item?.labels?.map(l => ({ color: l.color, label: `${l.name}=${l.value}` })) || []
          }))
        };
      },
      onSuccess,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};
export const useGetPoliciesComparison = (policyId, PolicyVersionId) => {
  const workspaceId = getAccuknoxWorkspace();
  return useQuery(
    ["policyComparison", PolicyVersionId],
    async () => {
      const payload = {
        workspace_id: workspaceId,
        policy_id: policyId,
        policy_version_id: PolicyVersionId
      };
      const response = await customPost(
        "/policymanagement/api/v2/audit-log/get-compare-two-policies",
        payload
      );
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false
    }
  );
};

export const PolicyYaml = policy_id => {
  return useQuery(
    ["PolicyYaml", policy_id],
    async () => {
      const response = await customGet(`/policymanagement/v2/policy/${policy_id}`);
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};
export const PolicyDiffYaml = policy_id => {
  return useQuery(
    ["PolicyDiffYaml"],
    async () => {
      const response = await customGet(`/policymanagement/v2/policy/${policy_id}/diff`);
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export const useGetListofPoliciesChanges = (onSuccess = () => {}, policyId) => {
  const workspaceId = getAccuknoxWorkspace();
  return useQuery(
    ["policyChanges", policyId],
    async () => {
      const payload = {
        workspace_id: workspaceId,
        policy_id: policyId
      };
      const response = await customPost(
        "/policymanagement/api/v1/audit-log/get-list-of-policy-changes",
        payload
      );
      const res = await response.json();
      return res;
    },
    {
      onSuccess,
      refetchOnWindowFocus: false
    }
  );
};
export const useApprovePolicyApi = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ policyId, finalreview, prBody }) => {
      const workspaceId = getAccuknoxWorkspace();
      const payload = {
        policy_id: [policyId],
        // workspace_id: workspaceId,
        updated_by: getUserId(),
        pr_event: finalreview,
        pr_body: prBody
      };
      const result = await customPost("/policymanagement/v2/policy/approve", payload);
      return result.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["policyComparison"]);
        queryClient.invalidateQueries(["policyChanges"]);
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Approve", type: "error" }));
      }
    }
  );
};
export const callUpdatePolicyMutation = onSuccess => {
  return useMutation(
    async ({ policy_Id, editYaml, label_Detail, editComment, label_id }) => {
      const payload = {
        // workspace_id: workspaceId,
        // policy_id: pID
        comment: editComment,
        description: editComment,
        policy_id: policy_Id,
        // workspace_id: workspaceId,
        created_by: getUserId(),
        label_type: label_Detail,
        policy_yaml: editYaml,
        label_id: label_id
      };
      const response = await customPut("/policymanagement/v2/policy ", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Update Yaml Failed", type: "error" }));
      }
    }
  );
};
export const useGetAllPoliciesCountQuery = (onSuccess = () => {}, searchTerm) => {
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedNamespaces = useSelector(getSelectedNamespaces);
  const selectedPolicyType = useSelector(getSelectedPolicyType);

  return useQuery(
    ["allPoliciesCount", selectedClusters, selectedNamespaces, selectedPolicyType, searchTerm],
    async () => {
      const payload = {
        workload: "k8s",
        cluster_id: [...selectedClusters?.map(c => c?.value)],
        namespace_id: [...selectedNamespaces?.map(n => n.value)],
        kind: [...selectedPolicyType.map(t => t.value)],
        name: {
          regex: searchTerm?.length ? [searchTerm] : []
        }
      };

      const response = await customPost("/policymanagement/v2/policy-count", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};

export const useGetUsersQuery = (isActive, isDeactive, isInvite) => {
  return useQuery(
    ["getUsers", isActive, isDeactive, isInvite],

    async () => {
      const response = await getFormRequest(`/users`);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return {
          shortId: data.results.reduce(
            (acc, newVal) => ({
              ...acc,
              [newVal?.id]: `${newVal?.first_name} ${newVal?.last_name}`
            }),
            {}
          ),
          longId: data.results.reduce(
            (acc, newVal) => ({
              ...acc,
              [newVal?.user_profile?.id]: `${newVal?.first_name} ${newVal?.last_name}`
            }),
            {}
          )
        };
      },
      refetchOnWindowFocus: false
    }
  );
};

export const usePolicyReviewChanges = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ policyId, finalreview, prBody, action }) => {
      const workspaceId = getAccuknoxWorkspace();
      const payload = {
        policy_id: [policyId],
        updated_by: getUserId(),
        action: finalreview,
        review_msg: prBody
      };
      const result = await customPost("/policymanagement/v2/policy/review", payload);
      return result.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["policyComparison"]);
        queryClient.invalidateQueries(["policyChanges"]);
        queryClient.invalidateQueries(["allPolicies"]);
        queryClient.invalidateQueries(["allPoliciesCount"]);
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Review", type: "error" }));
      }
    }
  );
};
