import { OverflowTip } from "components/simple/OverflowTip/overFlowTip";
import { kFormatter } from "helper/helpers";
import React, { useState } from "react";
import styles from "./widgets.module.scss";
export const TransformText = per => {
  const str =
    per.charAt(0).toUpperCase() +
    per
      .slice(1)
      .replaceAll("_", " ")
      .replaceAll("-", " ");
  return str;
};
export const SingleProgressBar = ({
  data = [],
  severityColors,
  totalCount,
  isDataLoading,
  onClickOfProgress,
  percentage,
  EmptyData
}) => {
  const [priorityHover, setPriorityHover] = useState("");
  const calculateFillWidth = (num, total) => {
    return `${total ? (num / total) * 100 : 0}%`;
  };
  return (
    <div className="grid grid-rows-6 gap-1">
      {percentage && (
        <div className="grid grid-cols-10 gap-2 items-center text-sm">
          <p className="col-span-2 m-0">Priority</p>
          <p className="col-span-1 m-0">Count</p>
          <p className="col-span-6 m-0"></p>
          <p className="col-span-1 m-0"></p>
        </div>
      )}
      {Object.keys(data)?.map(severity => (
        <div
          className={`${percentage &&
            data[severity] &&
            "cursor-pointer"} grid grid-cols-10 gap-2 items-center text-sm`}
          key={severity}
          onMouseOver={(event, index) => {
            setPriorityHover(severity);
          }}
          onMouseOut={() => {
            setPriorityHover("");
          }}
          onClick={() => onClickOfProgress(severity, data[severity])}
        >
          <p
            className={`${percentage ? "col-span-2" : "col-span-3"} ${percentage &&
              priorityHover === severity &&
              styles.prioritydetail} m-0`}
          >
            <OverflowTip placement="right-start">
              {TransformText(severity === "informational" ? "no_issue" : severity)}
            </OverflowTip>
          </p>
          {percentage && (
            <p className={`${priorityHover === severity && styles.prioritydetail} col-span-1 m-0`}>
              {data[severity] > 1000 ? kFormatter(data[severity]) : data[severity]}
            </p>
          )}
          <div className="w-full h-3 col-span-6 bg-gray-200 rounded-full">
            <div
              // onClick={e => onClickOfProgress({ severity })}
              style={{
                backgroundColor: severityColors[severity],
                width: calculateFillWidth(data[severity], totalCount)
              }}
              className="rounded-full h-3 w-[20px] cursor-pointer"
            ></div>
          </div>
          {percentage ? (
            <p className={`${priorityHover === severity && styles.prioritydetail} col-span-1 m-0`}>
              {Math.round((data[severity] / totalCount) * 100)}%
            </p>
          ) : (
            <p className="col-span-1 m-0">
              {data[severity] > 1000 ? kFormatter(data[severity]) : data[severity]}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};
