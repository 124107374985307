import React from "react";
import PropTypes from "prop-types";

export default function Export(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7218 14.2368C20.7218 18.7068 18.9318 20.4968 14.4618 20.4968H7.95177C3.48177 20.4968 1.69177 18.7068 1.69177 14.2368M10.9247 14.0015V2.62145M14.1121 4.85391L10.7621 1.50391L7.41208 4.85391"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Export.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Export.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 22,
  height: 22
};
