import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { routes } from "router/router";
import WindowFocusHandler from "helper/WindowFocusHandler";

import { Select, Breadcrumbs, Icon } from "../../simple";
import Popover from "@mui/material/Popover";

import { getUserSelector } from "store/entities/users/selectors";
import {
  isUnreadNotificationsSelector,
  modalNotificationsSelector
} from "store/entities/notifications/selectors";

import { getModalNotifications } from "store/entities/notifications/actions";
import { setTenant, openChangePasswordModal } from "store/entities/users/actions";
import styles from "./styles.module.scss";
import "./styles.scss";

export default function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUserSelector);
  const listTenant = useSelector(state => state.users.tenants);
  const selectTenant = useSelector(state => state.users.selectTenant);

  const isUnreadNotific = useSelector(isUnreadNotificationsSelector);
  const notifications = useSelector(modalNotificationsSelector);

  const tenant = JSON.parse(sessionStorage.getItem("tenant")) || selectTenant;

  const notificationsIntervalId = useRef(null);
  const startNotificationsTimer = () => {
    if (tenant) {
      clearInterval(notificationsIntervalId.current);
      notificationsIntervalId.current = setInterval(() => {
        dispatch(getModalNotifications());
      }, 60000);
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem("tenant") && localStorage.getItem("tenant")) {
      sessionStorage.setItem("tenant", localStorage.getItem("tenant"));
    }
    return () => {
      clearInterval(notificationsIntervalId.current);
    };
  }, []);

  useEffect(() => {
    // dispatch(getModalNotifications());
    // startNotificationsTimer();
  }, [selectTenant]);

  const onChangeTenant = opt => {
    sessionStorage.setItem("tenant", JSON.stringify(opt));
    localStorage.setItem("tenant", JSON.stringify(opt));
    dispatch(setTenant(opt));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onGoToProfile = () => {
    handleClose();
    history.push(routes.profile);
  };

  const changePassword = () => {
    handleClose();
    dispatch(openChangePasswordModal());
  };

  // Notifications modal
  const [notificAnchorEl, setNotificAnchorEl] = useState(null);
  const notificModalOpen = Boolean(notificAnchorEl);
  const notificModalId = notificModalOpen ? "simple-popover" : undefined;
  const handleNotificClick = event => {
    setNotificAnchorEl(event.currentTarget);
  };
  const handleNotificClose = () => {
    setNotificAnchorEl(null);
  };
  const handleNotificationClick = id => {
    handleNotificClose();
    if (id) {
      history.push(`${routes.notificationsDetails}/${id}`);
      return;
    }
    history.push(routes.notifications);
  };
  const notificModalStyles = {
    ".MuiPopover-paper": {
      maxWidth: "360px",
      borderRadius: "15px",
      marginTop: "1rem"
    }
  };
  WindowFocusHandler();

  return (
    <div className={`${styles.header}`}>
      <Breadcrumbs className={styles.breadcrumbs} />
      <div className={styles.leftSide}>
        {listTenant.length ? (
          <Select
            containerClass={styles.select}
            onChange={onChangeTenant}
            value={tenant}
            options={listTenant.map(item => ({ value: item.id, label: item.name }))}
          />
        ) : (
          ""
        )}
        <div className={`${styles.notifications} ${isUnreadNotific ? styles.unread : ""}`}>
          <Icon.Bell onClick={handleNotificClick} />
          <Popover
            id={notificModalId}
            open={notificModalOpen}
            anchorEl={notificAnchorEl}
            onClose={handleNotificClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            sx={notificModalStyles}
          >
            {notifications.length ? (
              <div className={styles.notificationCont}>
                {notifications.map(e => (
                  <div
                    key={e.id}
                    className={`${styles.notification} ${!e.read ? styles.unread : ""}`}
                    onClick={() => handleNotificationClick(e.id)}
                  >
                    <div>{e.subject}</div>
                    <div>{e.date}</div>
                  </div>
                ))}
                <div className={styles.notificationBtn} onClick={() => handleNotificationClick()}>
                  View all &gt;
                </div>
              </div>
            ) : (
              <div className={styles.notificationNoData}>No data</div>
            )}
          </Popover>
        </div>
        <div>
          <div className={styles.name} onClick={handleClick}>
            {user.first_name}
            <div className={styles.icon}>
              <Icon.DropDownArrow />
            </div>
          </div>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
            sx={{ marginTop: "1.2rem" }}
          >
            <MenuItem onClick={onGoToProfile}>
              <Icon.User /> <span>Profile</span>
            </MenuItem>
            <MenuItem onClick={changePassword}>
              <Icon.Lock /> <span>Change password</span>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {};

Header.defaultProps = {};
