import React from "react";

export const ProcessInfo = () => {
  return (
    <>
      <h3 className="font-semibold my-2">Process Rule</h3>
      <p className="opacity-75 my-2">
        In the process section, there are 2 types of matches: matchPaths and matchDirectories. You
        can define specific executables using matchPaths or all executables in specific directories
        using matchDirectories. In each match, there are three options:
      </p>
      <ul className="opacity-75 my-2 pl-0">
        <li>
          <b>ownerOnly:</b> (static action: allow owner only; otherwise block all) If this is
          enabled, the owners of the executable(s) defined with matchPaths and matchDirectories will
          be only allowed to execute.
        </li>
        <li>
          <b>recursive:</b> If this is enabled, the coverage will extend to the subdirectories of
          the directory defined with matchDirectories.
        </li>
        <li>
          <b>fromSource:</b> If a path is specified in fromSource, the executable at the path will
          be allowed/blocked to execute the executables defined with matchPaths or matchDirectories.
        </li>
      </ul>
      <p className="opacity-75 my-2">
        <b>Severity:</b> The severity part is somewhat important. You can specify the severity of a
        given policy from 1 to 10. This severity will appear in alerts when policy violations
        happen.
      </p>
      <p className="opacity-75 my-2">
        <b>Action:</b> The action could be Allow, Audit, or Block. Security policies would be
        handled in a blacklist manner or a whitelist manner according to the action. Thus, you need
        to define the action carefully. In the case of the Audit action, we can use this action for
        policy verification before applying a security policy with the Block action.
      </p>
    </>
  );
};
