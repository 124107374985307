import React from "react";
import PropTypes from "prop-types";

export default function File(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.01133 2.17027e-07C7.8499 -0.000148864 7.69718 0.0765144 7.5963 0.208335L6.9063 1.11002H11.1635C12.3329 1.11002 13.2875 2.10894 13.2875 3.33224V4.99896H15.4031C16.112 4.99896 16.112 6.11116 15.4031 6.11116H13.2875V7.77673H15.4063C15.4259 7.7749 15.4456 7.77423 15.4654 7.77461C16.1922 7.7968 16.1309 8.94604 15.4063 8.88355H13.2875V10.5513H15.4063C16.1142 10.5522 16.1142 11.6626 15.4063 11.6635H13.2875V16.6667H15.9883C16.5395 16.6667 17 16.1859 17 15.6098C17 15.6098 17 5.16787 17 1.05801C17 0.481922 16.5396 7.42955e-05 15.9883 6.95081e-05L8.01133 2.17027e-07ZM2.77764 2.43727L0.17432 5.15861C0.0193326 5.32069 7.71667e-05 5.43444 7.71667e-05 5.55455H3.19171L3.1906 2.21582C3.01328 2.21582 2.88199 2.32813 2.77764 2.43727ZM4.25309 2.22113V6.11217C4.25197 6.41737 4.01568 6.66447 3.72392 6.66556H0.0124496L8.67873e-07 18.8889C-0.000748066 19.4947 0.483324 20 1.0625 20L11.1634 20C11.7426 20 12.2249 19.4947 12.2249 18.8889V3.33224C12.2249 2.72638 11.7423 2.24003 11.1634 2.22113H4.25309ZM2.66661 8.88567L9.57183 8.88567C10.2645 8.88567 10.2645 9.99677 9.57183 9.99677L2.66661 9.99678C1.97397 9.99678 1.97396 8.88567 2.66661 8.88567ZM2.66661 11.6667L9.57183 11.6667C10.2645 11.6667 10.2645 12.7778 9.57183 12.7778L2.66661 12.7778C1.97397 12.7778 1.97396 11.6667 2.66661 11.6667ZM2.66661 14.4445L9.57183 14.4445C10.2959 14.4445 10.2959 15.5556 9.57183 15.5556L2.66661 15.5556C1.94255 15.5556 1.94255 14.4445 2.66661 14.4445Z"
        stroke={props.color}
      />
    </svg>
  );
}

File.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

File.defaultProps = {
  className: "",
  style: {},
  color: "#8490A5",
  width: 17,
  height: 20
};
