import React from "react";
import styles from "../styles.module.scss";

export const DetailModalInfo = ({ data }) => {
  return (
    <div className={styles["modal-details__info"]}>
      <div className={styles["modal-details__info-wrapper"]}>
        <p className={styles["modal-details__info-asset"]}>
          Filename:
          <span>{data?.audit_file ? data?.audit_file : "No Data"}</span>
        </p>
        <p className={styles["modal-details__info-asset"]}>
          Result:
          <span>{data?.result ? data?.result : "No Data"}</span>
        </p>
      </div>
    </div>
  );
};
