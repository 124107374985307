import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "./actions";

const getNotificationsParams = state => {
  return {
    page: state.notifications.notificationsParams.page,
    pageSize: state.notifications.notificationsParams.pageSize,
    ordering: state.notifications.notificationsParams.ordering,
    search: state.notifications.notificationsParams.search,
    filters: state.notifications.notificationsParams.filters
  };
};

// Workers //

// Set Notifications params
function* setNotificationsParamsWorker() {
  const { page, pageSize, ordering, search, filters } = yield select(getNotificationsParams);
  yield put(
    actions.getNotifications({
      page,
      pageSize,
      ordering,
      search,
      filters
    })
  );
}

// Get Notifications
function* getNotificationsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getNotifications), payload);
    yield put(actions.getNotificationsSuccess(response));
  } catch (error) {
    yield put(actions.getNotificationsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Modal Notifications
function* getModalNotificationsWorker() {
  const params = {
    page: 0,
    pageSize: 20
  };
  try {
    const response = yield call(makeRequest(service.getNotifications), params);
    yield put(actions.getModalNotificationsSuccess(response));
  } catch (error) {
    yield put(actions.getModalNotificationsFailed(error));
    // yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Notification
function* getNotificationWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getNotification), payload);
    yield put(actions.getNotificationSuccess(response));
  } catch (error) {
    yield put(actions.getNotificationFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Update Notification
function* updateNotificationWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.updateNotification), payload);
    yield put(actions.updateNotificationSuccess(response));
  } catch (error) {
    yield put(actions.updateNotificationFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete Notifications
function* deleteNotificationsWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteNotification), id)));
    yield put(actions.deleteNotificationSuccess(response));
    yield put(actions.setNotificationsParams());
    yield put(
      addNotification({
        msg: `${ids?.length > 1 ? "Notifications" : "Notification"} successfully deleted`
      })
    );
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.deleteNotificationFailed(error));
  }
}

// Workers End//

// Watchers //

// Set Notifications params
function* watchSetNotificationsParamsWorker() {
  yield takeEvery(actions.setNotificationsParams, setNotificationsParamsWorker);
}

// Get Notifications
function* watchGetNotificationsWorker() {
  yield takeEvery(actions.getNotifications, getNotificationsWorker);
}

// Get Modal Notifications
function* watchGetModalNotificationsWorker() {
  yield takeEvery(actions.getModalNotifications, getModalNotificationsWorker);
}

// Get Notification
function* watchGetNotificationWorker() {
  yield takeEvery(actions.getNotification, getNotificationWorker);
}

// Update Notification
function* watchUpdateNotificationWorker() {
  yield takeEvery(actions.updateNotification, updateNotificationWorker);
}

// Delete Notification
function* watchDeleteNotificationWorker() {
  yield takeEvery(actions.deleteNotification, deleteNotificationsWorker);
}

// Watchers End//

export default function* rootSaga() {
  yield all([
    fork(watchSetNotificationsParamsWorker),
    fork(watchGetNotificationsWorker),
    fork(watchGetModalNotificationsWorker),
    fork(watchGetNotificationWorker),
    fork(watchUpdateNotificationWorker),
    fork(watchDeleteNotificationWorker)
  ]);
}
