import React, { createContext, useContext, useMemo } from "react";
import {
  useIPBlockRuleForm,
  usePolicyMetadataForm,
  usePodSelectorRuleForm,
  useNamespaceSelectorRuleForm
} from "store/entities/policyEditor/NetworkPolicyEditor/hooks";
import styled from "styled-components";
import EditorCanvas from "./components/EditorCanvas";
import PolicyInfoGuide from "./components/PolicyInfoGuide";
import PolicyLibrary from "./components/PolicyLibrary";
import YamlPreview from "./components/YamlPreview";

const NetworkPolicyContext = createContext();
export const useNetworkPolicyContext = () => {
  return useContext(NetworkPolicyContext);
};

const NetworkPolicyEditor = () => {
  const policyMetadataForm = usePolicyMetadataForm();
  const ipBlockRuleIngressForm = useIPBlockRuleForm();
  const ipBlockRuleEgressForm = useIPBlockRuleForm();
  const podSelectorRuleIngressForm = usePodSelectorRuleForm();
  const podSelectorRuleEgressForm = usePodSelectorRuleForm();
  const namespaceSelectorRuleIngressForm = useNamespaceSelectorRuleForm();
  const namespaceSelectorRuleEgressForm = useNamespaceSelectorRuleForm();

  const contextValue = useMemo(
    () => ({
      policyMetadataForm,
      ipBlockRuleIngressForm,
      ipBlockRuleEgressForm,
      podSelectorRuleIngressForm,
      podSelectorRuleEgressForm,
      namespaceSelectorRuleIngressForm,
      namespaceSelectorRuleEgressForm
    }),
    [
      policyMetadataForm,
      ipBlockRuleIngressForm,
      ipBlockRuleEgressForm,
      podSelectorRuleIngressForm,
      podSelectorRuleEgressForm,
      namespaceSelectorRuleIngressForm,
      namespaceSelectorRuleEgressForm
    ]
  );

  return (
    <NetworkPolicyContext.Provider value={contextValue}>
      <ContentSection>
        <EditorCanvas />
        <YamlPreview />
        <PolicyLibrary />
        <PolicyInfoGuide />
      </ContentSection>
    </NetworkPolicyContext.Provider>
  );
};
const ContentSection = styled.main`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 68% 32%;
  grid-template-rows: 450px 1fr;
  gap: 0.75rem;
`;

export default NetworkPolicyEditor;
