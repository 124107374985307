import React from "react";
import { POLICY_INFO_CODE } from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import CodeBlock from "../../../../../../components/simple/CodeBlock";

export const SystemPolicyInfo = () => {
  return (
    <>
      <h3 className="font-semibold my-2">What is System Policies</h3>
      <p className="opacity-75 my-2">
        Accuknox enforces application policies and hardening using KubeArmor - our own open-source
        product that brings AppArmor and SELinux to K8s / Cloud workloads. Additionally, Accuknox
        builds on top of Cilium to provide full support for identity-based network segmentation for
        K8s and VM workloads.
      </p>

      <p className="opacity-75 my-2">
        <b>System Policy:</b> System policies restrict the behavior (such as process execution, file
        access, and networking operation) of containers and nodes at the system level.
      </p>

      <h3 className="font-semibold my-2">Structure of System policy</h3>
      <CodeBlock text={POLICY_INFO_CODE} />
    </>
  );
};
