import { customPost, postFormData } from "api/base";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { addNotification } from "store/entities/notifications/actions";
import {
  getChannelDetails,
  getChannelDetailsV3,
  getChannelIntegrationList,
  getCloudwatchIntegrationURL,
  getCloudWatchtest,
  getDeleteChannel,
  getEditCloudwatchChannel,
  getEditSlackChannel,
  getEditSplunkChannel,
  getElasticSearchIntegrationURL,
  getElasticTest,
  getJiraAccountConfiguration,
  getJiraIntegrationURL,
  getJiraIntegrationURLV3,
  getJiratest,
  getRsyslogIntegrationURL,
  getSlackIntegrationURL,
  getslacktest,
  getsplunktest,
  useSplunkIntegrationURL
} from "./channelUrl";

export const integrationList = (tenant_id, channel_id) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    TENANT_ID: tenant_id,
    CHANNEL_ID: channel_id
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getChannelIntegrationList(), form);
};
function clean(obj) {
  Object.keys(obj)?.forEach(k => obj[k]?.length === 0 && delete obj[k]);
  return obj;
}
// test API services

export const useSplunktest = (splunkWeb_Url, token, source, sourceType, splunkIndex) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    Splunk_Webhook_url: splunkWeb_Url,
    Splunk_token: token,
    Splunk_source: source,
    Splunk_source_type: sourceType,
    Splunk_index: splunkIndex,
    Splunkapp_name: "Splunk enterprise"
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getsplunktest(), form);
};
export const useSlacktest = (
  integrationName,
  channelName,
  slackSenderName,
  slackWebhookUrlName
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    IntegrationName: integrationName,
    ChannelName: channelName,
    SenderName: slackSenderName,
    HookUrl: slackWebhookUrlName
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getslacktest(), form);
};
export const useCloudWatchtest = (awsKey, awsSecretKey, region) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    Aws_access_key_id: awsKey,
    Aws_secret_access_key: awsSecretKey,
    Region: region
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getCloudWatchtest(), form);
};
export const useElastictest = (workspaceVal, integrationName, ApiUrl, Password, Username) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    TENANT_ID: workspaceVal,
    CHANNEL_ID: 4,
    INTEGRATION_NAME: integrationName,
    HOST_URL: ApiUrl,
    PASSWORD: Password,
    USERNAME: Username
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getElasticTest(), form);
};
export const useJiratest = (addressSite, userID, userEmail, token) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    Address: addressSite,
    UserID: userID,
    UserEmail: userEmail,
    Token: token
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getJiraAccountConfiguration(), form);
};

// integrations

export const useSalckIntegration = (
  integration_name,
  channelName,
  slackSenderName,
  webHookUrl,
  workspaceVal
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    Integration_name: integration_name,
    Slack_channel_name: channelName,
    Slack_sender_name: slackSenderName,
    Slack_Webhook_url: webHookUrl,
    Tenant_id: workspaceVal,
    Option: "Save",
    Channel_id: "1"
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getSlackIntegrationURL(), form);
};

export const useSplunkIntegration = (
  // splunkApp,
  integrationName,
  splunkWebhookUrlName,
  stoken,
  sourceType,
  sourceName,
  splunkIndex,
  workspaceVal
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    // Splunkapp_name: splunkApp,
    Integration_name: integrationName,
    Splunk_Webhook_url: splunkWebhookUrlName,
    Splunk_token: stoken,
    Splunk_source_type: sourceType,
    Splunk_source: sourceName,
    Splunk_index: splunkIndex,
    Tenant_id: workspaceVal,
    Option: "Save",
    Channel_id: "2"
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(useSplunkIntegrationURL(), form);
};
export const useRsysLogIntegration = (
  integrationName,
  serverAddress,
  port,
  transport,
  workspaceVal
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    INTEGRATION_NAME: integrationName,
    CHANNEL_ID: 5,
    SERVER_ADDRESS: serverAddress,
    PORT: port,
    TRANSPORT: transport,
    TENANT_ID: workspaceVal,
    OPTION: "Save"
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getRsyslogIntegrationURL(), form);
};
export const useCloudWatchIntegration = (
  workspaceVal,
  integrationName,
  finalAccesskey,
  finalSecretkey,
  region
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    Channel_id: "3",
    Tenant_id: workspaceVal,
    Integration_name: integrationName,
    Access_key: finalAccesskey,
    Secret_key: finalSecretkey,
    Region: region,
    Option: "Save"
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getCloudwatchIntegrationURL(), form);
};
export const useElasticIntegration = (
  workspaceVal,
  integrationName,
  apiUrl,
  mountPath,
  indexName,
  indexType,
  password,
  username
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    TENANT_ID: workspaceVal,
    CHANNEL_ID: 4,
    INTEGRATION_NAME: integrationName,
    API_URL: apiUrl,
    MOUNT_PATH: mountPath,
    INDEX_NAME: indexName,
    INDEX_TYPE: indexType,
    PASSWORD: password,
    USERNAME: username,
    Option: "Save"
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getElasticSearchIntegrationURL(), form);
};
export const useJiraIntegration = (
  integrationName,
  issueSummary,
  siteUrl,
  projectId,
  issueType,
  userEmail,
  token,
  userID
) => {
  const form = {
    IntegrationName: integrationName,
    IssueSummary: issueSummary,
    Site: siteUrl,
    Project: projectId,
    IssueType: issueType,
    UserEmail: userEmail,
    Token: token,
    UserID: userID,
    ChannelID: 6,
    Option: "save"
  };

  return customPost(getJiraIntegrationURLV3(), form);
};

export const useChannelDetails = async (action_id, Channel_id) => {
  const payload = {
    ID: action_id,
    ChannelID: Channel_id
  };
  const response = await customPost(getChannelDetailsV3(), payload);
  return response?.json();
};

// edit integrations

export const useEditSlackChannel = (
  workspaceVal,
  getIntegrationName,
  integrationName,
  webHookUrl,
  senderName,
  channelName
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    TENANT_ID: workspaceVal,
    INTEGRATION_NAME: getIntegrationName,
    Integration_name: integrationName,
    Slack_Webhook_url: webHookUrl,
    Slack_sender_name: senderName,
    Slack_channel_name: channelName
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getEditSlackChannel(), form);
};
export const useEditSplunkChannel = (
  getIntegrationName,
  integrationName,
  workspaceVal,
  splunkWebhookUrlName,
  Splunktoken,
  sourceName,
  sourceType,
  splunkIndex
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    INTEGRATION_NAME: getIntegrationName,
    Integration_name: integrationName,
    Channel_id: 2,
    TENANT_ID: workspaceVal,
    Splunk_Webhook_url: splunkWebhookUrlName,
    Splunk_token: Splunktoken,
    Splunk_source: sourceName,
    Splunk_source_type: sourceType,
    Splunk_index: splunkIndex,
    Option: "Update"
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getEditSplunkChannel(), form);
};
export const useEditRsyslogChannel = (
  integrationName,
  serverAddress,
  port,
  transport,
  workspaceVal,
  getIntegrationName
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    INTEGRATION_NAME: integrationName,
    CHANNEL_ID: 5,
    SERVER_ADDRESS: serverAddress,
    PORT: port,
    TRANSPORT: transport,
    TENANT_ID: workspaceVal,
    OPTION: "Update",
    Integration_name: getIntegrationName
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getRsyslogIntegrationURL(), form);
};

export const useEditCloudWatchIntegration = (
  workspaceVal,
  integrationName,
  finalAccesskey,
  finalSecretkey,
  region,
  getIntegrationName
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    Tenant_id: workspaceVal,
    Integration_name: integrationName,
    Access_key: finalAccesskey,
    Secret_key: finalSecretkey,
    Region: region,
    INTEGRATION_NAME: getIntegrationName
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getEditCloudwatchChannel(), form);
};
export const useEditElasticIntegration = (
  workspaceVal,
  integrationName,
  apiUrl,
  mountPath,
  indexName,
  indexType,
  getIntegrationName,
  password,
  username
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    TENANT_ID: workspaceVal,
    CHANNEL_ID: 4,
    INTEGRATION_NAME: integrationName,
    API_URL: apiUrl,
    MOUNT_PATH: mountPath,
    INDEX_NAME: indexName,
    INDEX_TYPE: indexType,
    Integration_name: getIntegrationName,
    PASSWORD: password,
    USERNAME: username,
    Option: "Update"
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getElasticSearchIntegrationURL(), form);
};

export const useEditJiraChannel = (
  integrationName,
  getIntegrationName,
  siteUrl,
  userEmail,
  token,
  userId,
  summary,
  projectId,
  issueType,
  workspaceVal
) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    Integration_Name: integrationName,
    INTEGRATION_NAME: getIntegrationName,
    Site: siteUrl,
    UserEmail: userEmail,
    Token: token,
    UserID: userId,
    Issue_Summary: summary,
    Project: projectId,
    Issue_Type: issueType,
    TENANT_ID: workspaceVal,
    CHANNEL_ID: 6,
    Option: "Update"
  };
  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getJiraIntegrationURL(), form);
};

// Delete Channels

export const useDeleteChannel = (workspaceVal, getIntegrationName, channeId) => {
  let formData = {};
  const form = new FormData();
  formData = {
    ...formData,
    TENANT_ID: workspaceVal,
    INTEGRATION_NAME: getIntegrationName,
    CHANNEL_ID: channeId
  };

  Object.keys(formData).forEach(key => form.append(key, formData[key]));
  return postFormData(getDeleteChannel(), form);
};
export const useDeleteJira = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ workspaceVal, getIntegrationName }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        TENANT_ID: workspaceVal,
        CHANNEL_ID: 6,
        INTEGRATION_NAME: getIntegrationName,
        Option: "Delete"
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const result = await postFormData(getJiraIntegrationURL(), form);
      return result;
    },
    {
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Delete Jira", type: "error" }));
      }
    }
  );
};
export const useDeleteRsyslog = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ workspaceVal, getIntegrationName }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        TENANT_ID: workspaceVal,
        CHANNEL_ID: 5,
        INTEGRATION_NAME: getIntegrationName,
        OPTION: "Delete"
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const result = await postFormData(getRsyslogIntegrationURL(), form);
      return result;
    },
    {
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Delete Rsyslog", type: "error" }));
      }
    }
  );
};
export const useDeleteElastic = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ workspaceVal, getIntegrationName }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        TENANT_ID: workspaceVal,
        CHANNEL_ID: 4,
        INTEGRATION_NAME: getIntegrationName,
        Option: "Delete"
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const result = await postFormData(getElasticSearchIntegrationURL(), form);
      return result;
    },
    {
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Delete Elastic", type: "error" }));
      }
    }
  );
};
