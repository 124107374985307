import React from 'react'
import { useTheme } from "@mui/styles";
import { ContentContainer, Title } from 'components/simple';
import { Box, makeStyles } from '@mui/styles';
import styles from "./styles.module.scss";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const rows = [
  {
    id: 1,
    findings: "Finding 1",
    severity: "Critical",
    impactedAssets: 50,
    age: 120 + " days"
  },
  {
    id: 2,
    findings: "Finding 1",
    severity: "Critical",
    impactedAssets: 50,
    age: 120 + " days"
  },
  {
    id: 3,
    findings: "Finding 1",
    severity: "Critical",
    impactedAssets: 50,
    age: 120 + " days"
  },
  {
    id: 4,
    findings: "Finding 1",
    severity: "Critical",
    impactedAssets: 50,
    age: 120 + " days"
  },
]

const columns = [
  {
    field: "findings",
    headerName: "Findings",
    width: 90
  },
  {
    field: "severity",
    headerName: "Severity",
    width: 90
  },
  {
    field: "impactedAssets",
    headerName: "Impacted Assets",
    width: 90
  },
  {
    field: "age",
    headerName: "Age",
    width: 90
  },
]

const useStyles = makeStyles({
container: {
    scrollbarWidth: "none",
    maxHeight: "291px",
    "&::-webkit-scrollbar": {
      display: "none"
    } /* Chrome */
  }
});

export default function FindingsBySeverity() {
  const classes = useStyles();
  return (
    <ContentContainer className={`p-4 flex flex-col gap-4 ${classes.container}`}>
      <div className='flex items-center justify-between w-full'>
        <Title className={styles.title}>Unticketed Findings by Severity</Title>
      </div>
        <TableContainer component={Paper} className={styles.container}>
          <Table sx={{ maxWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align='left'>Findings</TableCell>
              <TableCell align="left">Severity</TableCell>
              <TableCell align="center">Impact Assests</TableCell>
              <TableCell align="left">Age</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0, borderBottom: "none" } }}
            >
              <TableCell component="th" scope="row"
              sx={{ borderBottom: "none" }}
              align="left"
              >
                {row.findings}
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }} align="left">{row.severity}</TableCell>
              <TableCell sx={{ borderBottom: "none" }} align="center">{row.impactedAssets}</TableCell>
              <TableCell sx={{ borderBottom: "none" }} align="left">{row.age}</TableCell>
            </TableRow>
          ))}
        </TableBody>
          </Table>
        </TableContainer>
    </ContentContainer>
  )
}
