import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  params: {
    page: 0,
    pageSize: 20,
    search: "",
    ordering: ""
  },
  cloudAccounts: [],
  editAccountInfo: {},
  awsRegions: [],
  statusModal: {
    show: false,
    status: false,
    id: null
  },
  deleteModal: {
    show: false,
    id: null
  },
  setScanScheduleModal: {
    show: false,
    id: null
  }
};

const reducer = createReducer(
  initialState,
  {
    [actions.setCloudParams.type]: (state, action) => ({
      ...state,
      params: {
        ...state.params,
        ...action.payload
      }
    }),
    [actions.getCloudAccountsSuccess.type]: (state, action) => ({
      ...state,
      cloudAccounts: action.payload
    }),
    [actions.getAwsRegionsSuccess.type]: (state, action) => ({
      ...state,
      awsRegions: action.payload
    }),
    [actions.actionStatusModal.type]: (state, action) => ({
      ...state,
      statusModal: action.payload
    }),
    [actions.actionSetScanScheduleModal.type]: (state, action) => ({
      ...state,
      setScanScheduleModal: action.payload
    }),
    [actions.actionDeleteModal.type]: (state, action) => ({
      ...state,
      deleteModal: action.payload
    }),
    [actions.getAzureAccountSuccess.type]: (state, action) => ({
      ...state,
      editAccountInfo: action.payload
    }),
    [actions.getGCPAccountSuccess.type]: (state, action) => ({
      ...state,
      editAccountInfo: action.payload
    }),
    [actions.getAWSManualAccountSuccess.type]: (state, action) => ({
      ...state,
      editAccountInfo: action.payload
    }),
    [actions.getAWSAccessAccountSuccess.type]: (state, action) => ({
      ...state,
      editAccountInfo: action.payload
    })
  },
  [],
  state => state
);

export default reducer;
