import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ClickAwayListener } from "@mui/base";
import { Button } from "components/simple";
import "./date.css";
import { Container } from "./styles";
import { Box } from "@mui/material";
function DateRangePicker({ items, onChange, disable, width, activateDatePicker }) {
  const [showItems, setShowItems] = useState(false);
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(() => {
    if (items && location.pathname.includes("/cloud-workloads")) {
      return items[10];
    } else if (items && location.pathname.includes("/app-behavior")) {
      return items[0];
    } else if (items && location.pathname.includes("/monitors-logging/logs")) {
      return items[9];
    } else if (items && location.pathname.includes("/runtime-security/CWPP")) {
      return items[0];
    } else if (items && location.pathname.includes("/")) {
      return items[2];
    } else {
      return items[3];
    }
  });
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const dropDown = () => {
    setShowItems(Prev => {
      if (!Prev) {
        return true;
      } else {
        return false;
      }
    });
  };

  const selectItem = item => {
    if ("value" in item) {
      setSelectedItem(item);
      setShowItems(false);
      onChange(item.value);
      return;
    }
    if ("from" in item[0]) {
      setSelectedItem(item[0].type);
      setShowItems(false);
      onChange(item[0]);
      return;
    }
  };

  const handleFromBlur = value => {
    const date = moment(value, "MM/DD/YYYY");
    if (date.isValid() === false) {
      setFrom(to);
    }
  };

  const handleToBlur = value => {
    const date = moment(value, "MM/DD/YYYY");
    if (date.isValid() === false) {
      setTo(new Date());
    }
  };

  const handleClickAway = e => {
    setShowItems(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Container
          onClick={() => dropDown()}
          data-testid="container"
          disable={disable}
          onChange={onChange}
          width={width}
        >
          <div
            className="flex items-center justify-center w-8 "
            style={{ borderRight: "solid 0.5px #05147D" }}
          >
            <AccessTimeFilledIcon size={20} />
          </div>
          <div className="flex select-none truncate text-sm items-center px-1 pl-2.5 range-text">
            {selectedItem?.value || selectedItem}
          </div>
          <div className="flex items-center justify-center w-8">
            <ExpandMoreIcon />
          </div>
        </Container>
        <AnimatePresence>
          {showItems && (
            <motion.div
              layout
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 5 }}
              exit={{ opacity: 0, y: 0 }}
              transition={{ duration: 0.2 }}
              className={`bg-white h-60 mt-1 absolute z-20 right-0 mr-4 shadow-xl p-0 text-left cursor-pointer rounded-lg text-sm flex scrollbar`}
            >
              {activateDatePicker && (
                <div className="flex flex-col p-3">
                  <div className="mb-2 font-semibold text-gray-400">Absolute time ranges</div>
                  <label className="block mb-1 text-sm text-gray-700" htmlFor="from">
                    From
                  </label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    className="w-full h-10 px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                    onBlur={e => handleFromBlur(e.target.value)}
                    selected={from}
                    onChange={date => setFrom(date)}
                    maxDate={to}
                    required
                  />
                  <div className="mt-3">
                    <label className="block mb-1 text-sm text-gray-700" htmlFor="to">
                      To
                    </label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      className="w-full h-10 px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                      selected={to}
                      onChange={date => setTo(date)}
                      minDate={from}
                      maxDate={new Date()}
                      onBlur={e => handleToBlur(e.target.value)}
                      required
                    />
                  </div>
                  <div className="w-auto mt-2">
                    <Button
                      // title="Apply"
                      onClick={() => {
                        selectItem([{ from: from, to: to, type: "Custom range" }]);
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
              <div
                className="w-48 h-auto px-3 overflow-y-scroll scrollbar"
                style={{
                  boxSizing: "border-box",
                  boxShadow: "0px 2px 12px rgba(45, 45, 47, 0.16)"
                }}
              >
                <div className="absolute h-10 py-3 font-semibold text-gray-400 bg-white">
                  Relative time ranges
                </div>
                <div className="pt-6">
                  {items?.map(item => (
                    <div
                      key={item?.id}
                      onClick={() => selectItem(item)}
                      className={selectedItem === item ? "selected" : ""}
                    >
                      <div className="my-3 hover:text-blue-600">{item?.value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </ClickAwayListener>
  );
}

DateRangePicker.defaultProps = { width: "auto", activateDatePicker: true };

export default DateRangePicker;
