import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  dashboard: {}
};

const reducer = createReducer(
  initialState,
  {
    [actions.getDashboardSuccess.type]: (state, action) => ({
      ...state,
      dashboard: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
