/*eslint-disable */

import React, { useState } from "react";
import { Button, Title, Modal, Select, Input, Tabs, ErrorText } from "components/simple";
import styles from "./styles.module.scss";
import { useForm, Controller } from "react-hook-form";
import { Chip, Typography } from "@mui/material";
import useChannelList from "screens/ChannelIntegration/pages/hooks/listChannel";
import {
  useCreateTriggers,
  useFetchTriggers,
  useGetAllFilters
} from "store/entities/datapipeline/queries";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { addNotification } from "store/entities/notifications/actions";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { getAccuknoxWorkspace } from "api/api";
import { useChannelIntegrationState } from "store/entities/channels/slice";
import { getChannelIdToName } from "screens/MonitorLogs/helper";
import { useDatapipelineState } from "store/entities/datapipeline/slice";

function CreateTriggerModal({
  onCloseModal,
  isCreateTagLoading,
  isModalOpen,
  selectedComponentsFilter,
  selectedFilters,
  setIsTriggerOpen,
  updateLocalDSL
}) {
  const dispatch = useDispatch();
  const [triggerName, setTriggerName] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [channelName, setChannelName] = useState("");
  const [filterName, setFilterName] = useState("");
  const [componentName, setComponentName] = useState([]);
  const [frequency, setFrequencyName] = useState("");
  const [priority, setPriority] = useState("");
  const [uniqueQuery, setUniqueQuery] = useState("");
  const [fetchQuery, setFetchQuery] = useState([]);
  const { isFetching } = useChannelList();
  const queryClient = useQueryClient();
  const history = useHistory();
  const channelData = useChannelIntegrationState("channelData");
  const updatedChannellist = _.uniqBy(channelData?.dataList, obj => obj["ChannelID"]);
  const reset = () => {
    setComponentName([]);
    setTriggerName("");
    setChannelName([]);
    setFrequencyName([]);
    setFilterName([]);
    setPriority([]);
  };
  const { data: preDefinedData } = useGetAllFilters(
    data => {
      return data;
    },
    "PREDEFINED",
    selectedComponentsFilter
  );
  const getCreateTrigger = useCreateTriggers(data => {
    updateLocalDSL([]);
    queryClient.invalidateQueries("fetchTrigersData");
    setIsTriggerOpen(false);
    history?.push("/monitors-logging/triggers");
    dispatch(addNotification({ msg: "Trigger created successfully", type: "success" }));
    reset();
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const [frequencyOptions] = useState([
    "Runtime as it happens",
    "Once a Day",
    "Once a Week",
    "Once a Month"
  ]);
  const [filterNameOptions] = useState(["Postgres filter", "Other filters"]);

  const [priorityOptions] = useState(["High", "Medium", "Low"]);
  const workspaceVal = getAccuknoxWorkspace();
  const pageId = useDatapipelineState("pageId");
  const pageSize = useDatapipelineState("pageSize");
  const { data: fetchTriggers } = useFetchTriggers(
    data => {
      setFetchQuery(data?.response?.Triggers?.map(item => item?.FilterQuery));
    },
    workspaceVal,
    pageId,
    pageSize
  );
  // function savefilterquerycheck(fetchQuery) {
  //   const fstring = selectedFilters?.map(item => item.text);
  //   let result1 = false;
  //   for (let j = 0; j < fetchQuery?.length; j++) {
  //     if (JSON.stringify([fetchQuery[j]]) === JSON.stringify(fstring)) {
  //       result1 = true;
  //     }
  //   }
  //   setUniqueQuery(result1);
  //   queryClient.invalidateQueries("fetchTrigersData");
  //   return result1;
  // }

  const handleTabChange = (event, newValue) => {
    event.stopPropagation();
    setActiveTab(newValue);
  };
  const onSubmit = data => {
    // if (!savefilterquerycheck(fetchQuery)) {
    let temp = [].concat(selectedFilters, componentName);
    const filter_type = "SAVED";
    const channel_id = channelName?.value;
    const toggle = "ON";
    const freq_data = frequency?.value;
    const statusTrigger = priority?.label;
    const filter_query = temp.map(item => item?.text);
    getCreateTrigger.mutate({
      triggerName,
      channel_id,
      toggle,
      freq_data,
      filter_query,
      filter_type,
      selectedComponentsFilter,
      statusTrigger
    });
    // }
  };
  const handlepredefinedfilter = e => {
    const filterArr = [];
    const splitArr = e.text.split(",");
    splitArr?.map(val => {
      if (val != "") {
        filterArr.push({ text: val });
      }
    });
    setComponentName(filterArr);
  };
  const tabsList = [
    {
      id: "1",
      label: "Search filter data",
      content: (
        <>
          <div className="flex flex-col hidden">
            <div className="flex">
              <Typography variant="body2" color="#384044">
                Selected search filter
              </Typography>
              <Typography variant="body2" color="#384044">
                Optional
              </Typography>
            </div>
            <Controller
              {...register("filterName", {})}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Filters"
                  options={filterNameOptions.map(item => ({
                    value: item,
                    label: item
                  }))}
                  value={filterName}
                  onChange={value => {
                    onChange(value);
                    setFilterName(value);
                  }}
                />
              )}
            />
            <ErrorText errorMsg={errors.filterName && errors.filterName.message} />
          </div>
          <div className="flex flex-col mt-3">
            <Typography variant="body2" color="#384044">
              Selected Filter
            </Typography>
            <div className={styles.filterBox}>
              {selectedFilters?.length
                ? selectedFilters?.map(name => (
                    <Chip
                      label={name["text"]}
                      color="info"
                      style={{ margin: "0px 2px", height: "27px" }}
                    />
                  ))
                : null}
            </div>
            <ErrorText errorMsg={uniqueQuery === true ? "filter query already exist" : null} />
          </div>
        </>
      )
    },
    {
      id: "2",
      label: "Predefined queries",
      content: (
        <div>
          <div className="w-1/3">
            <Controller
              {...register("componentName", {})}
              control={control}
              defaultValue="Kubearmor"
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Select Query"
                  options={preDefinedData?.apiResponse?.map(item => ({
                    value: item?.Filter_id,
                    key: item.Name,
                    label: item.Name,
                    text: item.Filter_query,
                    type: "log"
                  }))}
                  value={value}
                  onChange={value => {
                    onChange(value);
                    handlepredefinedfilter(value);
                  }}
                />
              )}
            />
          </div>
          <div className="flex flex-col mt-3">
            <Typography variant="body2" color="#384044">
              Selected Filters
            </Typography>
            <div className={styles.filterBox}>
              {componentName?.length
                ? componentName?.map(name => (
                    <Chip
                      label={name["text"]}
                      color="info"
                      style={{ margin: "0px 2px", height: "27px" }}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
      )
    }
  ];
  return (
    <Modal isOpen={isModalOpen} toggle={onCloseModal}>
      <Title className={styles.title}>Create an alert trigger</Title>
      <div className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="triggerName"
            {...register("triggerName", {
              required: "Trigger name cannot be empty"
            })}
            defaultValue=""
            control={control}
            render={({ field: { onChange }, value, ref }) => (
              <Input
                name="triggerName"
                label="Name"
                placeholder="Enter a Name"
                required
                value={triggerName}
                onChange={evt => {
                  onChange(evt.target.value);
                  setTriggerName(evt.target.value);
                }}
              />
            )}
          />
          <ErrorText errorMsg={errors.triggerName && errors.triggerName.message} />
          <div className="flex w-8/12 mt-3">
            <div className="w-1/2 mr-5">
              <Typography variant="body2" color="#384044">
                When to initiate this trigger
              </Typography>
              <Controller
                {...register("frequencyName", {
                  required: "Frequency name cannot be empty"
                })}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Search..."
                    options={frequencyOptions.map(item => ({
                      value: item,
                      label: item
                    }))}
                    value={frequency}
                    onChange={value => {
                      onChange(value);
                      setFrequencyName(value);
                    }}
                  />
                )}
              />
              <ErrorText errorMsg={errors.frequencyName && errors.frequencyName.message} />
            </div>

            <div className="w-1/2 priority-trigger">
              <Typography variant="body2" color="#384044">
                Define Threat Level
              </Typography>
              <Controller
                {...register("priority", {
                  required: "Priority cannot be empty"
                })}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Search..."
                    options={priorityOptions.map(item => ({
                      value: item,
                      label: item
                    }))}
                    value={priority}
                    onChange={value => {
                      onChange(value);
                      setPriority(value);
                    }}
                  />
                )}
              />
              <ErrorText errorMsg={errors.priority && errors.priority.message} />
            </div>
          </div>
          <Tabs tabs={tabsList} value={activeTab} handleChange={handleTabChange} />
          <div className="flex flex-col w-1/3 mt-3">
            <Typography variant="body2" color="#384044">
              Notification channel
            </Typography>
            <Controller
              {...register("channelName", {
                required: "Channel name cannot be empty"
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select
                  className="cursor-pointer"
                  placeholder="Search..."
                  options={updatedChannellist.map(item => ({
                    value: item?.ChannelID,
                    label: getChannelIdToName(item?.ChannelID)
                  }))}
                  value={channelName}
                  onChange={value => {
                    onChange(value);
                    setChannelName(value);
                  }}
                />
              )}
            />
            <ErrorText errorMsg={errors.channelName && errors.channelName.message} />
          </div>
          <div className="flex justify-end w-full mt-3 footer">
            <Button type="submit" onClick={handleSubmit(onSubmit)} className={styles.btn}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default CreateTriggerModal;
