import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: #ffffff;
  border: 0.5px solid #05147d;
  border-radius: 4px;
  height: 41px;
  width: ${props => (props.width ? props.width : "auto")};
  padding: 3px 0;
  // margin-left: 15px;
  color: #05147d;
  opacity: ${props => (props.disable ? "0.25" : "none")};
  cursor: ${props => (props.disable ? "not-allowed" : "pointer")};
  @media (min-width: 1700px) {
    height: 42px;
  }
`;

export const ItemsContainer = styled.div`
  /* w-5/12 h-48 bg-white absolute z-10 mt-12 shadow-sm */
  width: 400px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(45, 45, 47, 0.16);
  border-radius: 4px;
  position: absolute;
  z-index: 20;
  margin-top: 7.2px;
  display: ${props => (props.showItems ? "block" : "hidden")};
`;
