/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export function flattenYaml(ob) {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty.call(i))
      if (typeof ob[i] === "object" && ob[i] !== null) {
        const flatObject = flattenYaml(ob[i]);

        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty.call(x));

          toReturn[`${i}.${x}`] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
  }
  return toReturn;
}
