import React from "react";
import PropTypes from "prop-types";

import { Icon } from "components/simple";

import styles from "./styles.module.scss";

export const QuestionTooltip = ({ text }) => {
  return (
    <div className={styles.container}>
      <div className={styles.tooltip}>{text}</div>
      <Icon.QuestionMark />
    </div>
  );
};

QuestionTooltip.propTypes = {
  text: PropTypes.string
};

QuestionTooltip.defaultProps = {
  text: "Tooltip"
};
