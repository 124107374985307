import React from "react";
import PropTypes from "prop-types";

export default function NoChart(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width={props.width} height={props.height} fill="url(#pattern0)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_5358_133719" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_5358_133719"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFPElEQVR4nO2cy4scVRTGS3wiIsYIcREVxEdEBN24VNC48Q9QBF2Ij/gfuI3gQjJxETfqSlAQjZK4cSM6g7hRGHVhT/f9vpqybJPB1+ioGaMukpaT1EBbU4+uTFXf033uB7VK97lf31/de889906iKCgoKCgoKEid4ji+AcD7JP/MnuODweB2377MwiD5K8nR+APgN/k33/7MCedHxv9gjEE56tufOfH8FFUG5A/f/swJwEYFkN99+zMnAIsVQN717c+UnHMPADhTAmSd5F7fHs2o3+/vJnmyaN2QkRFgTFlZp4+DOAvg4Wn7CIrOwXimYIo6HDrHg+I4viWf6gL4Jk3TK3z4Ma3l5eVLSX6Rg/H3YDC4y7c3kyK5ULCIH/Dty6RI3l+Q4n44Go0u8u3NnIbD4S4A3+dGxo9Jkuzx7c2kABzNwTgDYL9vXyYF4EBBirvg25flFPdUbnR81ev1LossK86dyAF4L0mSG7tsUzpdOj8H4y/n3L7IsuKSEzkp3nUJpSTFfTayLlScyJF8p6M29xekuMe7aGuuTuRIrqdpek2b7TnnriO5lmvnpFR3I+saDoe7SP5bAUSmkQ0AB+WzbbQJ4IN8iuucezCyLufcPpKDKhi5Rxb7l3q93rUX2ibJ5wriHoqsyzn3KMnNBjDGn1MXAgbAHZJF5UbHl6ZT3NFodLFkN3LYU9DRCYCfsozrmJw/5PcI+ZM7ki9OAobk5SS/zsXYNH3Zrd/v7wbwUUkHv170pmbfOSi3O6pGDMkjVXUnki/nv+ece7rks6OdPNEsCMDdJL8teMv/KeuYEjAbdWDSNL1+/LskHypIcY+VtTX3QOI4fgLA6QIYJwDc2ySWTE8yTWXTVRWYw9m0V7ThrExx5xbI0tLSJbL4lhj/LP8mNxHJq0k+XzNiitad2hR3LoEkSbKH5Kclpo/IUWmL5xgv1Kwx40AW62I27WD1QGQaAnCioDNOy/TVRZsZmLo15tyNdVNASD4lCzW3d8R3JO/pun0ps1Ttbya5izsXQCRlJflaSUd8LPWjaXmRIuFO7uLOJBAAN0slVjZy2WYuKfjxZ2UTKJvBaXqTkn1ZOX+S658zB2RlZeUmAD/XLKCbUh6JPEmgyDl5dg+30V3cmQMC4K2ahXN1li+WzQyQXq93VXbntbS+JKX0tsrkvqQeiJQ9ALxaszueOIvRLpVA1tbWriT5JMnP6yA0zWK0SxWQOI7vJPlK05JEkyxGu7wDkWv2JB+X+tIEnf6LFO4A3CeLu1y3nLe/KOI0gIzfe8o68U3p1OycYL1mGpK/HFoE8Jgc9ERzLnYNpOLeU916IHuNhX6/f1tkSOwaSM29p22jgeQn2Zl3J6OBzdemqaaVnQOpufe0BULKH4dWV1dvbeuHlSkAqf6vITbiOH5kmjcvGEZIefWT5BvTArEl80DkqosczJRMU3vN5fmK0t6t6ucPJN+Wqm3kQQxAdIkBiC4xANElBiC6xABkvoGw5X2N9hemdTEA0SUGILrEAMQWkEhZPPWi8g5UD0R7FkPl8VpXAFKtACSnMELClKVrhOz08zQWr3Vp/8FUHq91af/BVB6vdWn/wVQeL6SpNQpAZuyNDiOkRgGI8Q5k12uINoM0Fk+9QRqLp94gjcVTb5DG4qk3SGPx1BuksXjqDdJYPPUGaSyeeoM0Fk+9QRqLp94gjcVTb5DG4qk3SGPx1BuksXjqDdJYPPUGaSyeeoM0Fk+9QRqLp94gjcVTb5DG4m37QNMnxGOr/ReAUNcLGIAwAAlTILePkP8A/CCX1u9R2gcAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
}

NoChart.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

NoChart.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 24,
  height: 24
};
