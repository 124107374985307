import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ticketCommentsSelector } from "store/entities/tickets/selectors";

import { Tabs } from "components/simple";
import { Comments, Details } from "./Tabs";

const TicketsDetails = () => {
  const params = useParams();
  const { id } = params;
  const [activeTab, setActiveTab] = useState(id ? 0 : 1);
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const comments = useSelector(ticketCommentsSelector);

  const tabs = [
    {
      id: 0,
      label: `Ticket Details`,
      content: <Details id={id} />,
      disabled: !id
    },
    {
      id: 1,
      label: `Comments (${comments.length})`,
      content: <Comments id={id} comments={comments} />
    }
  ];

  return <Tabs tabs={tabs} value={activeTab} handleChange={handleChange} />;
};

export default TicketsDetails;
