import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import styled from "@emotion/styled";
import styles from "./styles.module.scss";

export default function OutlinedCard({ children, isSelected, icon, sx, onClick }) {
  return (
    <Box sx={{ minWidth: 300, maxHeight: 300, marginBottom: "10px", ...sx }}>
      <Card
        variant="outlined"
        sx={{
          width: "300px",
          height: "100px",
          cursor: "pointer",
          ...sx
        }}
        className={isSelected ? styles.active : "notactive"}
      >
        {icon}
        <ContentContainer>{children}</ContentContainer>
      </Card>
    </Box>
  );
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  h3 {
    color: black;
    margin: 0;
    font-weight: 500;
  }
  p {
    color: black;
    margin: 0;
  }
`;
