import { useQuery } from "react-query";
import { customGet, customPost, getFormRequest, postFormRequest } from "api/base";
import { getAccuknoxWorkspace, getUserId } from "api/api";

export const useGetImageDetails = (dataType, assetId) => {
  return useQuery(
    ["getImageDetails", dataType, assetId],
    async () => {
      const response = await getFormRequest(`/screen?data_type=${dataType}&asset=${assetId}`);
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      select: data => {
        return dataType === "IMAGE_DETAILS" || dataType == "METADATA_IMAGE"
          ? data?.result?.[dataType.toLowerCase()]?.[0]
          : data?.result?.[dataType.toLowerCase()];
      }
    }
  );
};

export const useGetImageVulnerabilities = assetId => {
  return useQuery(
    ["getImageVulnerabilities"],
    async () => {
      const response = await getFormRequest(
        `/screen?data_type=RISK_BASED_PRIORITIZATION&limit=100&asset=${assetId}`
      );
      // Todo: limit is hardcoded to 100 for now since pagination is not supported
      const res = await response.json();

      return res;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
};
