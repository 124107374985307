export const frequency = [
  {
    value: "once",
    label: "Once"
  },
  {
    value: "daily",
    label: "Daily"
  },
  {
    value: "weekly",
    label: "Weekly"
  },
  {
    value: "monthly",
    label: "Monthly"
  }
];

export const days = [
  {
    id: 1,
    day: "Sunday",
    shortForm: "S"
  },
  {
    id: 2,
    day: "Monday",
    shortForm: "M"
  },
  {
    id: 3,
    day: "Tuesday",
    shortForm: "T"
  },
  {
    id: 4,
    day: "Wednesday",
    shortForm: "W"
  },
  {
    id: 5,
    day: "Thursday",
    shortForm: "T"
  },
  {
    id: 6,
    day: "Friday",
    shortForm: "F"
  },
  {
    id: 7,
    day: "Saturday",
    shortForm: "S"
  }
];

export const repeatDaily = [
  {
    value: 1,
    label: "Day"
  },
  {
    value: 2,
    label: "2 Days"
  },
  {
    value: 3,
    label: "3 Days"
  },
  {
    value: 4,
    label: "4 Days"
  },
  {
    value: 5,
    label: "5 Days"
  },
  {
    value: 6,
    label: "6 Days"
  },
  {
    value: 7,
    label: "7 Days"
  }
];

export const repeatWeekly = [
  {
    value: 1,
    label: "Week"
  },
  {
    value: 2,
    label: "2 Weeks"
  },
  {
    value: 3,
    label: "3 Weeks"
  },
  {
    value: 4,
    label: "4 Weeks"
  },
  {
    value: 5,
    label: "5 Weeks"
  },
  {
    value: 6,
    label: "6 Weeks"
  },
  {
    value: 7,
    label: "7 Weeks"
  }
];

export const repeatMonthly = [
  {
    value: 1,
    label: "Month"
  },
  {
    value: 2,
    label: "2 Months"
  },
  {
    value: 3,
    label: "3 Months"
  },
  {
    value: 4,
    label: "4 Months"
  },
  {
    value: 5,
    label: "5 Months"
  },
  {
    value: 6,
    label: "6 Months"
  },
  {
    value: 7,
    label: "7 Months"
  },
  {
    value: 8,
    label: "8 Months"
  },
  {
    value: 9,
    label: "9 Months"
  },
  {
    value: 10,
    label: "10 Months"
  },
  {
    value: 11,
    label: "11 Months"
  },
  {
    value: 12,
    label: "12 Months"
  }
];

export const timeZone = [
  {
    value: "America/Adak",
    label: "America/Adak"
  },
  {
    value: "Asia/Yakutsk",
    label: "Asia/Yakutsk"
  },
  {
    value: "Europe/Budapest",
    label: "Europe/Budapest"
  },
  {
    value: "Pacific/Auckland",
    label: "Pacific/Auckland"
  }
];
