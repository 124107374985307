import React, { useState } from "react";

import ActionMenu from "components/simple/ActionMenu";
import { UsersTable, ConfirmationModal } from "./utils";
import {
  useGetUsersQuery,
  useDeleteUser,
  useActivateUser
} from "store/entities/userProfile/queries";

export const DeactivatedUsers = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const deactivatedUsers = useGetUsersQuery(2);
  const { mutate: deleteUserMutation } = useDeleteUser();

  const { mutate: activateUser } = useActivateUser(() => {
    deactivatedUsers.refetch();
  });

  const MenuComponent = ({ user }) => {
    return (
      <ActionMenu
        onClick={() => {
          setSelectedUser(user);
        }}
        menuItems={[
          {
            name: "Activate",
            onClick: () => {
              setActivateModal(true);
            }
          },
          {
            name: "Delete",
            onClick: () => {
              setDeleteModal(true);
            }
          }
        ]}
      />
    );
  };

  return (
    <>
      {/* activate modal */}
      <ConfirmationModal
        isActive={activateModal}
        heading="Are you sure you want to activate the user from workspace?"
        handleCancel={() => setActivateModal(false)}
        handleAction={() => {
          activateUser(selectedUser.id);
          setActivateModal(false);
        }}
        actionText="Activate"
      />

      {/* delete modal */}
      <ConfirmationModal
        isActive={deleteModal}
        heading="Are you sure you want to delete the user from workspace?"
        handleCancel={() => setDeleteModal(false)}
        handleAction={() => {
          deleteUserMutation(selectedUser.id);
          setDeleteModal(false);
          deactivatedUsers?.refetch();
        }}
        actionText="Delete"
      />

      <UsersTable activeTab={1} users={deactivatedUsers} MenuComponent={MenuComponent} />
    </>
  );
};
