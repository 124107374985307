import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addGroup } from "store/entities/assets/actions";

import { Button, Title, Modal } from "components/simple";
import { GroupSelect } from "helper/AsyncSelects";

import styles from "./styles.module.scss";

const ExistingGroup = ({ open, onBack = () => null, close, data }) => {
  const dispatch = useDispatch();
  const [group, setGroup] = useState("");

  const onSave = () => {
    const res = {
      id: group?.value,
      ids: data
    };
    dispatch(addGroup(res));
  };

  return (
    <Modal isOpen={open} toggle={() => close()}>
      <Title>Add to Group</Title>
      <div className={styles.content}>
        <div className={styles.form}>
          <GroupSelect
            containerClass={styles.select}
            onChange={val => setGroup(val)}
            value={group}
            placeholder="Select"
            label="Group"
          />
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={onBack} variant="outline" className={styles.modalButton}>
          Back
        </Button>
        <Button onClick={onSave} className={styles.modalButton} disabled={!group}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default ExistingGroup;
