import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Popover from "@mui/material/Popover";
import { OverflowTip } from "../OverflowTip/overFlowTip";
import { Tooltip } from "@mui/material";

export default function SelectorLabel({ labels = [], limit = 7 }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const truncatedString = value => {
    const finalString = value?.length > 6 && value?.slice(0, `${limit}`);
    return `${finalString}...`;
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip placement="top" title={labels?.[0]?.name + "=" + labels?.[0]?.value}>
        <Chip
          key={0}
          label={truncatedString(labels?.[0]?.name + "=" + labels?.[0]?.value)}
          sx={{ color: labels[0]?.color, backgroundColor: "#cadae057" }}
        />
      </Tooltip>
      {labels?.length - 1 > 0 && (
        <Chip
          key={1}
          label={`+${labels?.length - 1}`}
          onDelete={handleClick}
          deleteIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
          sx={{ backgroundColor: "#cadae057" }}
        />
      )}
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        sx={{ marginTop: "15px" }}
      >
        <div
          style={{
            padding: "10px",
            width: "250px",
            maxHeight: "270px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "14px",
            flexWrap: "wrap",
            overflowY: "scroll"
          }}
        >
          {labels?.slice(1).map((data, index) => {
            return (
              <Tooltip placement="top" title={data?.name + "=" + data?.value}>
                <Chip
                  key={data.id}
                  size="small"
                  label={data?.name + "=" + data?.value}
                  sx={{ color: data?.color, backgroundColor: "#cadae057" }}
                />
              </Tooltip>
            );
          })}
        </div>
      </Popover>
    </Stack>
  );
}
