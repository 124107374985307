import React, { useCallback } from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const Textarea = ({
  onFocus,
  onChange,
  label,
  className,
  value,
  required,
  placeholder,
  disabled
}) => {
  const handleChange = useCallback(
    event => {
      // eslint-disable-next-line
      onChange && onChange(event.target.value);
    },
    [onChange]
  );
  return (
    <div className={`${className} ${styles.container} ${required ? styles.required : ""}`}>
      {label && (
        <label htmlFor={label} className={styles.label}>
          {label}
        </label>
      )}
      <textarea
        type="textarea"
        className={styles.textarea}
        onFocus={onFocus}
        onChange={handleChange}
        value={value}
        required={required}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

Textarea.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string
};

Textarea.defaultProps = {
  onChange: () => {},
  className: "",
  label: "",
  placeholder: ""
};

export default Textarea;
