import React, { useState, useEffect, useRef } from "react";
import Chip from "@mui/material/Chip";
import styled from "styled-components";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import DropItem from "../../simple/DropItem/dropItem";

export default function DynamicFilter({
  type,
  dsl,
  updateLocalDSL,
  filterValues,
  filterOptions,
  disabled
}) {
  const [myInp, setMyInp] = useState();
  const [key, setKey] = useState("");
  const isInitial = useRef(true);
  let heightLevel = 1;

  const [searchInput, setSearchInput] = useState("");
  const [showdata, setShowdata] = useState([]);
  const [selected, setSelected] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [droplist, setDropList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [leftOffset, setLeftOffset] = useState(0);

  const [popupVisible, setPopupVisible] = useState(false);
  const updateTagList = () => {};

  const closeAll = e => {
    e?.stopPropagation();
    setSelected([]);
    if (updateLocalDSL) {
      updateLocalDSL([]);
    }
    setShowDropdown(false);
  };

  const getOptions = () => {
    let options = filterOptions || [{ label: "Cluster", value: "cluster" }];
    if (type === "Policyinventory") {
      options = [
        { label: "Policy Group", value: "policyGroup" },
        { label: "Policy Label", value: "PolicyLabel" },
        { label: "Created By", value: "CreatedBy" },
        { label: "Status", value: "Status" }
      ];
    }

    if (type === "Standards") {
      options = [
        { label: "Name", value: "Name" },
        { label: "Cloud", value: "Cloud" },
        { label: "Created By", value: "Created By" }
      ];
    }

    if (type === "Audit") {
      options = [{ label: "Policy", value: "policy" }];
    }

    if (type === "k8s policy") {
      options = [
        { label: "Policy", value: "policy" },
        { label: "Node or Pod", value: "Node" },
        { label: "Rules", value: "rules" },
        { label: "Selector Labels", value: "labels" },
        { label: "Tags", value: "tags" }
      ];
    }

    if (type === "VM policy") {
      options = [
        { label: "Policy", value: "policy" },
        { label: "Rules", value: "rules" },
        { label: "Selector Labels", value: "labels" },
        { label: "Tags", value: "tags" }
      ];
    }

    if (type === "Trigger") {
      options = [
        { label: "Alert Trigger", value: "alertTrigger" },
        { label: "Alert Sent", value: "alertSent" },
        { label: "Channel", value: "channel" },
        { label: "Enabled", value: "enabled" },
        { label: "Disabled", value: "disabled" }
      ];
    }

    if (type === "Train Containers") {
      options = [
        { label: "Container Name", value: "containerName" },
        { label: "Training Status", value: "trainingStatus" }
      ];
    }
    if (type === "Sensitive Source") {
      options = [{ label: "Name", value: "Name" }];
    }

    return options;
  };

  const getOptionNameForKey = _key => {
    const options = getOptions();
    const matchedOption = options.find(o => o.value === _key) || {
      label: _key,
      value: _key
    };
    return matchedOption;
  };

  const addSelectedTag = (val, keyval) => {
    let local_selected = selected;
    if (local_selected === undefined) {
      local_selected = [];
    }

    if (val.value === "OR") {
      tagList.push(`OR`);
      if (updateLocalDSL) {
        updateLocalDSL([...dsl, { condition: `OR` }]);
      }
      local_selected.push({ text: `OR` });
      setTimeout(() => {
        setSelected(local_selected);
        setShowdata([]);

        setKey("");
        setSearchInput("");
        setShowDropdown(false);
      }, 100);
    } else if (keyval !== undefined && keyval !== "" && keyval !== "*") {
      const matchedOption = getOptionNameForKey(keyval);
      const isFilterItemAlreadyExists = dsl?.some(
        item => item.key === matchedOption.value && item.val === val.value
      );
      if (!isFilterItemAlreadyExists) {
        tagList.push(`${matchedOption.label}:${val.label}`);
        if (updateLocalDSL) {
          updateLocalDSL([
            ...dsl,
            {
              key: keyval,
              val: val.value,
              text: `${matchedOption.label}:${val.label}`
            }
          ]);
        }
        local_selected.push({
          text: `${matchedOption.label}:${val.label}`,
          key: keyval
        });
      }
      setTimeout(() => {
        setSelected(local_selected);
        setShowdata([]);
        setKey();

        setShowDropdown(false);
      }, 100);
    } else if (keyval === "*") {
      tagList.push(`${keyval}:${val}`);
      if (updateLocalDSL) {
        updateLocalDSL([...dsl, { key: keyval, val, text: `${keyval}:${val}` }]);
      }
      local_selected.push({ text: `${keyval}:${val}` });
      setTimeout(() => {
        setShowdata([]);

        setSearchInput("");
        setShowDropdown(false);
      }, 100);
    } else {
      setTimeout(() => {
        setShowdata([]);

        setKey(val.value);
        setSearchInput("");
        setShowDropdown(false);
      }, 100);
    }

    setTagList(tagList);
    // set focus to input
    myInp.focus();
    const height = document.getElementById("selectedTags").clientHeight;
    heightLevel = Math.round(height / 28);
  };

  useEffect(() => {
    if (!isInitial.current) {
      setTimeout(() => closeAll(), 100);
    } else {
      isInitial.current = false;
    }
  }, []);

  useEffect(() => {
    if (dsl && dsl.length !== 0) {
      setSelected(dsl);
      updateTagList(dsl, "dsl_filter");
      if (updateLocalDSL) updateLocalDSL(dsl);
    }
  }, [dsl, updateTagList, updateLocalDSL]);

  const renderValueDropDown = () => {
    const json = filterValues || {
      Name: [
        {
          label: "Test",
          value: "test"
        },
        {
          label: "Data",
          value: "data"
        },
        {
          label: "For",
          value: "for"
        },
        {
          label: "Dropdown",
          value: "dropdown"
        }
      ]
    };

    let options = [];
    if (json[key] != null) {
      options = json[key];
    } else if (type === "k8s policy" && key === "Node") {
      options = [
        { label: "Host", value: "Host" },
        { label: "Pod", value: "Pod" }
      ];
    } else {
      options = [{ label: "Testing", value: "testing" }];
    }
    setShowdata([]);
    const _droplist = [];
    options.forEach(d => {
      _droplist.push(d);
    });

    setDropList(_droplist);
    setShowdata(showdata);
    setKey(key);

    setShowDropdown(true);
  };

  const renderPropDropDown = () => {
    const options = getOptions();
    setShowdata([]);

    const _droplist = [];

    // find if any key in the selected tag => options need to be filtered.
    const keysList = [];
    if (selected) {
      selected.forEach(item => {
        const _key = item.key;
        if (keysList.indexOf(_key) === -1) {
          keysList.push(_key);
        }
      });
    }

    if (type !== "alerts") {
      options.forEach(d => {
        _droplist.push(d);
      });
    }

    setDropList(_droplist);
    setShowdata(showdata);
    setPopupVisible(true);
    setKey(key);
    setShowDropdown(true);
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      if (key) {
        addSelectedTag({ label: e.target.value }, key);
        setSearchInput("");
      } else {
        addSelectedTag(e.target.value, "*");
        setSearchInput("");
      }
    }

    if (e.key === "Backspace") {
      if (key && searchInput === "") {
        setKey("");
      }
    }
    setShowDropdown(false);
  };

  const placeDropdown = () => {
    const pos = document.getElementById("dslInput");
    setLeftOffset(pos.offsetLeft);
  };

  const onTap = e => {
    e.stopPropagation();
    // key is present show suggestions dropdown
    setShowdata([]);
    if (key) {
      renderValueDropDown();
    } else {
      // key is not present show keys dropdown
      renderPropDropDown(e.target.value);
    }

    setSearchInput(e.target.value);
    setPopupVisible(true);

    placeDropdown();
  };

  const closeTag = (e, index) => {
    e?.stopPropagation();
    setShowDropdown(false);
    const tmp = selected.filter((val, i) => val && i !== index);

    if (tmp === []) {
      updateLocalDSL([]);
      setSelected([]);
    } else {
      updateLocalDSL(tmp);
      setSelected(tmp);
    }
  };

  let placeholderText = "Search by cluster, node, pod or service name";
  if (type === "alerts") {
    placeholderText = "Search for Alerts";
  }
  placeholderText = "Filter";

  return (
    <Main disabled={disabled} className="z-10 flex">
      <Filter
        style={{ height: `${heightLevel * 40}px` }}
        onClick={() => {
          myInp.click();
        }}
      >
        <FilterIcon style={{ width: "4%", marginTop: "0.25rem" }}>
          <FilterAltIcon style={{ width: "1.5em", height: "26px", color: "#A6A8AA" }} />
        </FilterIcon>
        <SelectedTags style={{ width: "92%" }} id="selectedTags">
          {selected && (
            <div style={{ marginBottom: "2px" }} className="text-white float-left inline-block rounded-full mx-1 px-2 py-1 relative">
              {selected?.map((item, index) => (
                <Chip
                  key={index}
                  label={item?.text}
                  onDelete={e => {
                    closeTag(e, index);
                  }}
                  color="info"
                  style={{ margin: "0px 2px", height: "27px" }}
                />
              ))}
            </div>
          )}

          {key && <GetKey>{`${getOptionNameForKey(key).label} :`}</GetKey>}

          <input
            id="dslInput"
            type="text"
            autoComplete="off"
            className="mt-1 input-field bg-white rounded text-gray-900 w-2/5 cursor-pointer inline-block float-left"
            placeholder={placeholderText}
            style={{ height: "26px", width: "200px" }}
            ref={ip => setMyInp(ip)}
            value={searchInput}
            onChange={e => {
              onTap(e);
            }}
            onClick={e => {
              onTap(e);
            }}
            onKeyDown={e => {
              handleKeyDown(e);
            }}
          />
        </SelectedTags>

        <SelectedTags style={{ width: "0%", margin: "5px" }}>
          {selected && selected.length > 0 && (
            <CloseIcon style={{ width: "1.5em", height: "21px" }} onClick={closeAll} />
          )}
        </SelectedTags>
      </Filter>

      {showDropdown && (
        <ShowDrop
          style={{
            zIndex: 2000,
            height: "30vh",
            borderRadius: "4px",
            left: leftOffset,
            border: "1px solid rgb(228 228 228)"
          }}
        >
          <div
            style={{
              width: "100%",
              borderBottom: "1px solid rgb(228 228 228)",
              padding: "10px"
            }}
            className="flex justify-between px-2"
          >
            <div
              style={{
                display: "inline-block",
                color: "#888c92"
              }}
            >
              PROPERTIES
            </div>
            <div style={{display: "inline-block" }}>
              <CloseIcon
                style={{ float: "right", height: "21px" }}
                onClick={() => {
                  setShowDropdown(false);
                }}
              />
            </div>
          </div>

          {droplist.map((item, index) => (
            <DropItem
              key={index}
              len={index}
              onTap={() => addSelectedTag(item, key)}
              popupVisible={popupVisible}
              value={item.label}
            />
          ))}
        </ShowDrop>
      )}
    </Main>
  );
}

export const Main = styled.div`
  font-size: 13px;
  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none;
      opacity: 0.7;
  `}
  .input-field {
    border: 0;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .css-1a1l6zv-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    font-size: 19px;
  }
`;
export const Filter = styled.div`
  border: 1px solid #dbdff0;
  width: 100%;
  height: auto !important;
  border-radius: 0.25rem;
  cursor: pointer;
  background: #fff;
  border-width: 1px;
`;
export const FilterIcon = styled.div`
  display: inline-block;
  float: left;
  padding-right: 8px;
`;
export const SelectedTags = styled.div`
  display: inline-block;
  float: left;
`;
export const GetKey = styled.div`
  display: inline-block;
  float: left;
  padding: 4px 4px;
`;
export const ShowDrop = styled.div`
  background: #fff;
  border-width: 1px;
  width: 16.666667%;
  margin-top: 3rem;
  transition-delay: 150ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  overflow: auto;
`;
