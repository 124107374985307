import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export const POLICY_EDITOR_SLICE = "policyEditor";
export const POLICY_EDITOR_INITIAL_STATE = {
  activeEditorTab: 0
};

const policyEditorSlice = createSlice({
  name: POLICY_EDITOR_SLICE,
  initialState: POLICY_EDITOR_INITIAL_STATE,
  reducers: {
    updateActiveEditorTab: (state, action) => {
      state.activeEditorTab = action.payload;
    }
  }
});

export const usePolicyEditorState = (...keys) => {
  const value = useSelector(state => {
    let value = state[POLICY_EDITOR_SLICE];
    keys.forEach(key => {
      value = value[key];
    });
    return value;
  });
  return value;
};

export const { updateActiveEditorTab } = policyEditorSlice.actions;
export default policyEditorSlice.reducer;
