import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

// Set Tags params
export const setTagsParams = createAction(constant.SET_TAGS_PARAMS);

// Get Tags
export const getTags = createAction(constant.GET_TAGS);
export const getTagsSuccess = createAction(constant.GET_TAGS_SUCCESS);
export const getTagsFailed = createAction(constant.GET_TAGS_FAILED);

// Get Tag
export const getTag = createAction(constant.GET_TAG);
export const getTagSuccess = createAction(constant.GET_TAG_SUCCESS);
export const getTagFailed = createAction(constant.GET_TAG_FAILED);

// Create TAG
export const createTag = createAction(constant.CREATE_TAG);
export const createTagSuccess = createAction(constant.CREATE_TAG_SUCCESS);
export const createTagFailed = createAction(constant.CREATE_TAG_FAILED);

// Delete TAG
export const deleteTag = createAction(constant.DELETE_TAG);
export const deleteTagSuccess = createAction(constant.DELETE_TAG_SUCCESS);
export const deleteTagFailed = createAction(constant.DELETE_TAG_FAILED);

// Get Tag Mini
export const getTagsMini = createAction(constant.GET_TAGS_MINI);
export const getTagsMiniSuccess = createAction(constant.GET_TAGS_MINI_SUCCESS);
export const getTagsMiniFailed = createAction(constant.GET_TAGS_MINI_FAILED);
