export const SET_PARAMS_BASELINE = "SET_PARAMS_BASELINE";

// Get BASELINE
export const GET_BASELINE = "GET_BASELINE_REQUEST";
export const GET_BASELINE_SUCCESS = "GET_BASELINE_SUCCESS";
export const GET_BASELINE_FAILED = "GET_BASELINE_FAILED";

// Get BASELINES
export const GET_BASELINES = "GET_BASELINES_REQUEST";
export const GET_BASELINES_SUCCESS = "GET_BASELINES_SUCCESS";
export const GET_BASELINES_FAILED = "GET_BASELINES_FAILED";

// Get BASELINE assets
export const GET_BASELINE_ASSETS = "GET_BASELINE_ASSETS_REQUEST";
export const GET_BASELINE_ASSETS_SUCCESS = "GET_BASELINE_ASSETS_SUCCESS";
export const GET_BASELINE_ASSETS_FAILED = "GET_BASELINE_ASSETS_FAILED";

// Get baseline analysis mini
export const GET_BASELINE_ANALYSIS_MINI = "GET_BASELINE_ANALYSIS_MINI_REQUEST";
export const GET_BASELINE_ANALYSIS_MINI_SUCCESS = "GET_BASELINE_ANALYSIS_MINI_SUCCESS";
export const GET_BASELINE_ANALYSIS_MINI_FAILED = "GET_BASELINE_ANALYSIS_MINI_FAILED";

// Set BASELINE assets
export const SET_BASELINE_ASSETS_PARAMS = "SET_BASELINE_ASSETS_PARAMS_REQUEST";

// Get BASELINE control
export const GET_BASELINE_CONTROL = "GET_BASELINE_CONTROL_REQUEST";
export const GET_BASELINE_CONTROL_SUCCESS = "GET_BASELINE_CONTROL_SUCCESS";
export const GET_BASELINE_CONTROL_FAILED = "GET_BASELINE_CONTROL_FAILED";

// Edit BASELINE
export const EDIT_BASELINE = "EDIT_BASELINE_REQUEST";
export const EDIT_BASELINE_SUCCESS = "EDIT_BASELINE_SUCCESS";
export const EDIT_BASELINE_FAILED = "EDIT_BASELINE_FAILED";

// Edit BASELINE FIELDS
export const EDIT_BASELINE_FIELDS = "EDIT_BASELINE_FIELDS_REQUEST";
export const EDIT_BASELINE_FIELDS_SUCCESS = "EDIT_BASELINE_FIELDS_SUCCESS";
export const EDIT_BASELINE_FIELDS_FAILED = "EDIT_BASELINE_FIELDS_FAILED";

// create BASELINE
export const CREATE_BASELINE = "CREATE_BASELINE_REQUEST";
export const CREATE_BASELINE_SUCCESS = "CREATE_BASELINE_SUCCESS";
export const CREATE_BASELINE_FAILED = "CREATE_BASELINE_FAILED";

// Delete BASELINE
export const DELETE_BASELINE = "DELETE_BASELINE_REQUEST";
export const DELETE_BASELINE_SUCCESS = "DELETE_BASELINE_SUCCESS";
export const DELETE_BASELINE_FAILED = "DELETE_BASELINE_FAILED";

// Get BASELINE TAG
export const GET_BASELINE_TAG_BY_ID = "GET_BASELINE_TAG_BY_ID_REQUEST";
export const GET_BASELINE_TAG_BY_ID_SUCCESS = "GET_BASELINE_TAG_BY_ID_SUCCESS";
export const GET_BASELINE_TAG_BY_ID_FAILED = "GET_BASELINE_TAG_BY_ID_FAILED";

// Post tag/tags
export const POST_TAGS = "POST_TAGS_REQUEST";
export const POST_TAGS_SUCCESS = "POST_TAGS_SUCCESS";
export const POST_TAGS_FAILED = "POST_TAGS_FAILED";

// Get audit choices
export const GET_AUDIT_CHOICES = "GET_AUDIT_CHOICES_REQUEST";
export const GET_AUDIT_CHOICES_SUCCESS = "GET_AUDIT_CHOICES_SUCCESS";
export const GET_AUDIT_CHOICES_FAILED = "GET_AUDIT_CHOICES_FAILED";

// Get audit choices mini
export const GET_AUDIT_CHOICES_MINI = "GET_AUDIT_CHOICES_REQUEST_MINI";
export const GET_AUDIT_CHOICES_SUCCESS_MINI = "GET_AUDIT_CHOICES_SUCCESS_MINI";
export const GET_AUDIT_CHOICES_FAILED_MINI = "GET_AUDIT_CHOICES_FAILED_MINI";

// Set audit choices params
export const SET_AUDIT_CHOICES_PARAMS = "SET_AUDIT_CHOICES_PARAMS_REQUEST";

// Get audit choices
export const GET_BASELINE_SOURCES = "GET_BASELINE_SOURCES_REQUEST";
export const GET_BASELINE_SOURCES_SUCCESS = "GET_BASELINE_SOURCES_SUCCESS";
export const GET_BASELINE_SOURCES_FAILED = "GET_BASELINE_SOURCES_FAILED";

export const SET_BASELINE_CONFIG_PARAMS = "SET_BASELINE_CONFIG_PARAMS";
export const UPD_BASELINE_CONFIG_PARAMS = "UPD_BASELINE_CONFIG_PARAMS";

export const GET_BASELINE_CONFIG_FILTERS = "GET_BASELINE_CONFIG_FILTERS_REQUEST";
export const GET_BASELINE_CONFIG_FILTERS_SUCCESS = "GET_BASELINE_CONFIG_FILTERS_SUCCESS";
export const GET_BASELINE_CONFIG_FILTERS_FAILED = "GET_BASELINE_CONFIG_FILTERS_FAILED";

export const GET_BASELINE_CONFIG_TABLE = "GET_BASELINE_CONFIG_TABLE_REQUEST";
export const GET_BASELINE_CONFIG_TABLE_SUCCESS = "GET_BASELINE_CONFIG_TABLE_SUCCESS";
export const GET_BASELINE_CONFIG_TABLE_FAILED = "GET_BASELINE_CONFIG_TABLE_FAILED";

// Set BASELINE History table params
export const SET_BASELINE_HISTORY_PARAMS = "SET_BASELINE_HISTORY_PARAMS";

// EXPORT BASELINE_CONFIG_TABLE
export const EXPORT_BASELINE_CONFIG_TABLE = "EXPORT_BASELINE_CONFIG_TABLE_REQUEST";
export const EXPORT_BASELINE_CONFIG_TABLE_SUCCESS = "EXPORT_BASELINE_CONFIG_TABLE_SUCCESS";
export const EXPORT_BASELINE_CONFIG_TABLE_FAILED = "EXPORT_BASELINE_CONFIG_TABLE_FAILED";

// add-control
export const ADD_CONTROL_BASELINE_CONFIG_TABLE = "ADD_CONTROL_BASELINE_CONFIG_TABLE_REQUEST";
export const ADD_CONTROL_BASELINE_CONFIG_TABLE_SUCCESS =
  "ADD_CONTROL_BASELINE_CONFIG_TABLE_SUCCESS";
export const ADD_CONTROL_BASELINE_CONFIG_TABLE_FAILED = "ADD_CONTROL_BASELINE_CONFIG_TABLE_FAILED";

// delete-control
export const DELETE_CONTROL_BASELINE_CONFIG_TABLE = "DELETE_CONTROL_BASELINE_CONFIG_TABLE_REQUEST";
export const DELETE_CONTROL_BASELINE_CONFIG_TABLE_SUCCESS =
  "DELETE_CONTROL_BASELINE_CONFIG_TABLE_SUCCESS";
export const DELETE_CONTROL_BASELINE_CONFIG_TABLE_FAILED =
  "DELETE_CONTROL_BASELINE_CONFIG_TABLE_FAILED";

// Get Compare Baselines
export const GET_COMPARE_BASELINES = "GET_COMPARE_BASELINES_REQUEST";
export const GET_COMPARE_BASELINES_SUCCESS = "GET_COMPARE_BASELINES_SUCCESS";
export const GET_COMPARE_BASELINES_FAILED = "GET_COMPARE_BASELINES_FAILED";

// Duplicate Baseline
export const DUPLICATE_BASELINE = "DUPLICATE_BASELINE_REQUEST";
export const DUPLICATE_BASELINE_SUCCESS = "GET_COMPARE_BASELINES_SUCCESS";
export const DUPLICATE_BASELINE_FAILED = "DUPLICATE_BASELINE_FAILED";

// Set Assign-hosts params
export const SET_ASSIGN_HOSTS_PARAMS = "SET_ASSIGN_HOSTS_PARAMS";

// Set Assign-groups params
export const SET_ASSIGN_GROUPS_PARAMS = "SET_ASSIGN_GROUPS_PARAMS";

// Get Assets-mini
export const GET_ASSETS_MINI = "GET_ASSETS_MINI_BASELINE_REQUEST";
export const GET_ASSETS_MINI_SUCCESS = "GET_ASSETS_MINI_BASELINE_SUCCESS";
export const GET_ASSETS_MINI_FAILED = "GET_ASSETS_MINI_BASELINE_FAILED";

// Get LABELS List
export const GET_LABEL_LIST = "GET_LABEL_LIST_BASELINE_REQUEST";
export const GET_LABEL_LIST_SUCCESS = "GET_LABEL_LIST_BASELINE_SUCCESS";
export const GET_LABEL_LIST_FAILED = "GET_LABEL_LIST_BASELINE_FAILED";

// Get Groups
export const GET_BASELINE_GROUPS = "GET_BASELINE_GROUPS_REQUEST";
export const GET_BASELINE_GROUPS_SUCCESS = "GET_BASELINE_GROUPS_SUCCESS";
export const GET_BASELINE_GROUPS_FAILED = "GET_BASELINE_GROUPS_FAILED";

// Get Groups mini
export const GET_BASELINE_GROUPS_MINI = "GET_BASELINE_GROUPS_MINI_REQUEST";
export const GET_BASELINE_GROUPS_MINI_SUCCESS = "GET_BASELINE_GROUPS_MINI_SUCCESS";
export const GET_BASELINE_GROUPS_MINI_FAILED = "GET_BASELINE_GROUPS_MINI_FAILED";

// Add Baseline asset
export const ADD_BASELINE_ASSET = "ADD_BASELINE_ASSET_REQUEST";
export const ADD_BASELINE_ASSET_SUCCESS = "ADD_BASELINE_ASSET_SUCCESS";
export const ADD_BASELINE_ASSET_FAILED = "ADD_BASELINE_ASSET_FAILED";

// Delete Baseline asset
export const DELETE_BASELINE_ASSET = "DELETE_BASELINE_ASSET_REQUEST";
export const DELETE_BASELINE_ASSET_SUCCESS = "DELETE_BASELINE_ASSET_SUCCESS";
export const DELETE_BASELINE_ASSET_FAILED = "DELETE_BASELINE_ASSET_FAILED";

// Add Baseline group
export const ADD_BASELINE_GROUP = "ADD_BASELINE_GROUP_REQUEST";
export const ADD_BASELINE_GROUP_SUCCESS = "ADD_BASELINE_GROUP_SUCCESS";
export const ADD_BASELINE_GROUP_FAILED = "ADD_BASELINE_GROUP_FAILED";

// Delete Baseline group
export const DELETE_BASELINE_GROUP = "DELETE_BASELINE_GROUP_REQUEST";
export const DELETE_BASELINE_GROUP_SUCCESS = "DELETE_BASELINE_GROUP_SUCCESS";
export const DELETE_BASELINE_GROUP_FAILED = "DELETE_BASELINE_GROUP_FAILED";
