import { clearErrors } from "./actions";

const loadingReducer = (state = {}, action) => {
  const { type, payload } = action;
  const clearErrType = /(.*)_(REQUEST|FAILED)/.exec(payload);

  if (type === clearErrors.type) {
    return {
      ...state,
      [clearErrType[1]]: ""
    };
  }

  const matches = /(.*)_(REQUEST|FAILED)/.exec(type);
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  const res = typeof payload === "object" ? Object.values(payload)?.join() : payload;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === "FAILED" ? res : ""
  };
};
export default loadingReducer;

//       dispatch(clearErrors(lostPassword.type));
