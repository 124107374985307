import React from "react";

export default function Remediation(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 17"
      fill="none"
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 5.125H11.5V3.625H7.75V5.125ZM7.75 10.375H11.5V8.875H7.75V10.375ZM12.25 13.75H1.75C0.925 13.75 0.25 13.075 0.25 12.25V1.75C0.25 0.925 0.925 0.25 1.75 0.25H12.25C13.075 0.25 13.75 0.925 13.75 1.75V12.25C13.75 13.075 13.075 13.75 12.25 13.75ZM2.5 6.25H6.25V2.5H2.5V6.25ZM3.25 3.25H5.5V5.5H3.25V3.25ZM2.5 11.5H6.25V7.75H2.5V11.5ZM3.25 8.5H5.5V10.75H3.25V8.5Z"
        fill={props.color}
      />
    </svg>
  );
}
