import { useEffect, useRef } from "react";
import moment from "moment-timezone";

export const kFormatter = num => {
  return Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
    : Math.sign(num) * Math.abs(num);
};

export const formatTimestampToLocalDate = timestamp => {
  return moment(timestamp * 1000)
    .tz(moment.tz.guess())
    .format("MM/DD/YYYY HH:mm A");
};

export const formatIsoDateTimeToLocalDate = datetime => {
  return moment(datetime)
    .tz(moment.tz.guess())
    .format("MM/DD/YYYY HH:mm A");
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const downloadFile = (data, fileName, fileType) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  const blobObjectURL = window.URL.createObjectURL(blob);
  a.href = blobObjectURL;
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
  // prevents memory leak, each time we use createObjectURL we create an object in the memory
  window.URL.revokeObjectURL(blobObjectURL);
};

export const isValidDate = (d, sourceFormat) => {
  if (d) {
    let date = `${d}`;
    const dateString = date?.split(" ");
    if (dateString[1]) {
      const timeString = dateString[1]?.split(".");
      if (timeString[1] && timeString[1]?.length > 0) {
        date = date.replace(`.${timeString[1]}`, "");
        return moment(new Date(date), sourceFormat).isValid();
      }
      return moment(date, sourceFormat).isValid();
    }
  }
  return false;
};

export const formatDate = (
  dateString,
  sourceFormat = "DD/MM/YYYY HH:mm:ss Z",
  format = "MM-DD-YY HH:mm:ss",
  timezone = moment.tz.guess()
) => {
  const time = new Date();
  const timeZoneOffset = time.getTimezoneOffset();
  const timeformat = moment.tz.zone(timezone).abbr(timeZoneOffset);

  if (isValidDate(dateString, sourceFormat)) {
    const date = moment.utc(dateString, sourceFormat).tz(timezone);
    // return `${date.format(format)} IST`;
    return `${date.format(format)} ${timeformat}`;
  }
  if (sourceFormat === "YYYY-MM-DDTHH:mm:ssZ") {
    const date = moment.utc(dateString, "YYYY-MM-DD HH:mm:ss Z").tz(timezone);
    // return `${date.format(format)} IST`;
    return `${date.format(format)} ${timeformat}`;
  }
  return dateString;
};
