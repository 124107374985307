import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  page: 0,
  pageSize: 20,
  ordering: "",
  search: "",
  selectConfig: "",
  temporaryData: null,
  filters: null,
  groupBy: "",
  orderModel: [],
  selectTicketConfig: "",

  datalists: {},
  datalistConfigs: {},
  datalistConfigsFilterData: {},
  datalistsDataType: {},

  targetModal: { show: false, data: [] },
  groupModal: { show: false, data: [] },

  groups: null,
  targets: null,
  targetsType: null,
  panelState: {
    name: "",
    target: "",
    asset: "",
    data_type: "",
    filter_fields: [],
    display_fields: [],
    display_fields_width: []
  }
};

const reducer = createReducer(
  initialState,

  {
    [actions.setDataListsParams.type]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [actions.changeGroupByDataType.type]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [actions.setPanelState.type]: (state, action) => ({
      ...state,
      panelState: {
        ...state.panelState,
        ...action.payload
      }
    }),
    [actions.updDataListsParams.type]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [actions.getDataListsSuccess.type]: (state, action) => ({
      ...state,
      datalists: action.payload
    }),
    [actions.getDataListsDataTypeSuccess.type]: (state, action) => ({
      ...state,
      datalistsDataType: action.payload
    }),

    [actions.getDataListConfigsSuccess.type]: (state, action) => ({
      ...state,
      datalistConfigs: action.payload
    }),
    [actions.getDataListConfigsFiltersDataSuccess.type]: (state, action) => ({
      ...state,
      datalistConfigsFilterData: action.payload
    }),

    [actions.actionTargetModal.type]: (state, action) => ({
      ...state,
      targetModal: action.payload
    }),
    [actions.actionGroupModal.type]: (state, action) => ({
      ...state,
      groupModal: action.payload
    }),

    [actions.getGroupsSuccess.type]: (state, action) => ({
      ...state,
      groups: action.payload
    }),
    [actions.getTargetsSuccess.type]: (state, action) => ({
      ...state,
      targets: action.payload
    }),
    [actions.getTargetsTypesSuccess.type]: (state, action) => ({
      ...state,
      targetsType: action.payload
    }),
    [actions.editDataListConfigsSuccess.type]: (state, action) => ({
      ...state,
      selectConfig: {
        ...state?.selectConfig,
        label: action.payload
      }
    })
  },
  [], // required
  state => state // required
);

export default reducer;
