/* eslint-disable camelcase */
import {
  API,
  ACCUKNOX_API,
  authorization,
  contentTypeJson,
  authorizationTenant,
  getAccuknoxWorkspace
} from "api/api";
import { get, post, patch, remove } from "api/base";

export const signUp = fields => {
  return post(`${API}/auth/sign-up/`, JSON.stringify(fields));
};

export const login = fields => {
  return post(`${API}/login/`, JSON.stringify(fields));
};

export const accuknoxLogin = () => {
  const payload = {
    username: window.__env__.REACT_APP_ACCUKNOX_USERNAME,
    password: window.__env__.REACT_APP_ACCUKNOX_PASSWORD
  };

  return post(`${ACCUKNOX_API}/usermanagement/api/v1/auth/login`, JSON.stringify(payload), {
    "Content-Type": "application/json"
  });
};

export const accuknoxRoleMapping = fields => {
  const wsid = getAccuknoxWorkspace();
  const formData = new FormData();
  formData.append("refresh_token", fields.refresh_token);
  return post(
    `${ACCUKNOX_API}/usermanagement/api/v1/workspaces/role-mapping?wsid=${wsid}`,
    formData,
    {
      authorization: `Bearer ${fields.access_token}`
    }
  );
};

export const activate = fields => {
  return post(`${API}/reset-password/new-password`, JSON.stringify(fields));
};

export const validateToken = ({ uid, token }) => {
  return get(`${API}/reset-password/validate-token?uid=${uid}&token=${token}`);
};

export const forgotPassword = fields => {
  return post(`${API}/reset-password/reset`, JSON.stringify(fields));
};

export const getUserProfile = () => {
  return get(`${API}/users/current-user-data`, {
    ...authorization(),
    ...(authorizationTenant()?.["Tenant-Id"] !== "undefined" ? { ...authorizationTenant() } : {})
  });
};

export const getTenants = () => {
  return get(`${API}/clients`, {
    ...authorization()
  });
};

export const logout = token => {
  return post(`${API}/account/auth/logout`, JSON.stringify({ token }), {
    ...authorization(),
    ...contentTypeJson()
  });
};

export const lostPassword = fields => {
  return post(`${API}/account/manage-password/send-code`, JSON.stringify(fields));
};

export const updateProfile = fields => {
  const { id, ...rest } = fields;
  return patch(`${API}/users/${id}`, JSON.stringify(rest), {
    ...authorization(),
    ...authorizationTenant(),
    ...contentTypeJson()
  });
};

export const changePassword = fields => {
  return patch(`${API}/users/change-password`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson()
  });
};

export const refresh = payload => {
  return post(`${API}/auth/refresh/`, JSON.stringify(payload), {
    ...authorization(),
    ...contentTypeJson()
  });
};

export const switchTenant = tenantId => {
  return post(`${API}/switch-tenant/?tenant_id=${tenantId}`, "", {
    ...authorization(),
    ...contentTypeJson()
  });
};

export const verification = fields => {
  return post(`${API}/mfa/verify`, JSON.stringify(fields));
};

export const emailNotificationSubscriptions = () => {
  return get(`${API}/notifications/notification-list`, {
    ...authorization(),
    ...(authorizationTenant()?.["Tenant-Id"] !== "undefined" ? { ...authorizationTenant() } : {})
  });
};

export const getQRMFA = ({ user_id }) => {
  return get(`${API}/mfa/add?user_id=${user_id}`, {
    ...authorization()
  });
};

export const addMFA = fields => {
  return post(`${API}/mfa/verify`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson()
  });
};

export const removeMFA = id => {
  return remove(
    `${API}/mfa/${id}`,
    {},
    {
      ...authorization()
    }
  );
};
