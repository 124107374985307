import React, { useCallback, useState, useLayoutEffect } from "react";

const useElementSize = () => {
  const [ref, setRef] = useState();
  const [size, setSize] = useState({
    width: 0,
    height: 0
  });

  // Avoid too many renders using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0
    });
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useLayoutEffect(() => {
    // Call handler right away so state gets updated with initial element size
    handleSize();
    window.addEventListener("resize", handleSize);

    return () => window.removeEventListener("resize", handleSize);
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
};

export default useElementSize;
