import React from "react";

export default function Compliance(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 17"
      fill="none"
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 12H7.5V0H4.5V12ZM0 12H3V6H0V12ZM9 3.75V12H12V3.75H9Z" fill={props.color} />
    </svg>
  );
}
