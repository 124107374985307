import React, { memo, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { useDispatch } from "react-redux";
import { createBaseline } from "store/entities/baseline/actions";
import { addNotification } from "store/entities/notifications/actions";

import { Button, Input, Modal, Switcher, Title } from "components/simple";

import styles from "./styles.module.scss";
import { LabelSelect } from "helper/AsyncSelects";

export const CreateBaselineModal = memo(({ data, isOpen, onClose }) => {
  const dispatch = useDispatch();

  const [valid, setValid] = useState(true);
  const [state, setState] = useState({
    name: "",
    label: "",
    result_value: false
  });

  const switchOptions = {
    active: "Pass",
    inactive: "Fail"
  };

  const validation = useCallback(() => {
    if (state.name !== "") setValid(false);
    else setValid(true);
  }, [state.name, valid]);

  useEffect(() => {
    if (isOpen) validation();
  }, [state.name]);

  useEffect(() => {
    return () => setState({ name: "", label: "", result_value: false });
  }, [isOpen]);

  const handleSubmit = () => {
    const res = {
      name: state.name.trim(),
      label: state?.label?.value,
      source: data?.source,
      result_value: state?.result_value,
      audit_files: [data?.audit_file]
    };
    if (state?.name !== "") dispatch(createBaseline(res));
    else dispatch(addNotification({ msg: "Baseline name required", type: "error" }));
  };

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  return ReactDOM.createPortal(
    <Modal isOpen={isOpen} toggle={onClose}>
      <div className={styles["modal-create"]}>
        <Title>Create Baseline</Title>
        <form className={styles["modal-create-form"]}>
          <Input
            type="text"
            placeholder="Name"
            label="Name"
            value={state.name}
            onChange={({ target }) => handleChange(target.value, "name")}
            required
          />

          <p className={styles["modal-create-form__file"]}>
            <span>Audit File: </span>
            {data?.audit_file}
          </p>

          {data?.labels_count > 0 && (
            <LabelSelect
              value={state.label}
              audit={data?.audit_file}
              onChange={val => handleChange(val, "label")}
              placeholder="Label"
              closeMenuOnSelect
              isClearable
              cacheOptions
            />
          )}

          <Switcher
            options={switchOptions}
            value={state?.result_value}
            onChange={val => handleChange(val, "result_value")}
          />
          <Button
            disabled={valid}
            className={styles["modal-create-form__submit"]}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </form>
      </div>
    </Modal>,
    document.getElementById("modal-root")
  );
});
