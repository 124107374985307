import React from "react";
import { Box } from "@mui/material";
import ReactCodeMirror from "@uiw/react-codemirror";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { tags as t } from "@lezer/highlight";
import { createTheme } from "@uiw/codemirror-themes";
import { StreamLanguage } from "@codemirror/language";
import { indentWithTab } from "@codemirror/commands";
import { yaml } from "@codemirror/legacy-modes/mode/yaml";
import { keymap } from "@codemirror/view";
import styles from "./styles.module.scss";

const YamlEditor = ({ code, setCode, editable, height = "400px", containerClass = "" }) => {
  const extensionList = [
    keymap.of([indentWithTab]), // override default behaviour of tab key to have a tab(spaces) given on tab key press
    StreamLanguage.define(yaml)
  ];

  const myEditorTheme = createTheme({
    theme: "light",
    settings: {
      background: "#FAFAFA",
      foreground: "#384044",
      caret: "#AEAFAD",
      selection: "#D6D6D6",
      selectionMatch: "#D6D6D6",
      lineHighlight: "#EFEFEF",
      gutterBackground: "#f2f2f2",
      gutterForeground: "#5f6467",
      keyword: "#385723"
    },
    styles: [{ tag: t.keyword, color: "#385723" }] // name: policy-name change the color the "name" tag (key in the key:value pair)
  });
  return (
    <Box className={`${styles["code-editor-wrapper"]} ${containerClass}`}>
      <ReactCodeMirror
        value={code}
        extensions={extensionList}
        onChange={setCode}
        autoFocus
        height={height}
        theme={myEditorTheme}
        placeholder="Enter a new YAML here"
        editable={editable}
        className={styles.CodeMirror}
      />
      {!code ? (
        <div className={styles.placeholderText}>
          <ContentCopyIcon />
          Paste YAML here
        </div>
      ) : null}
    </Box>
  );
};

export default YamlEditor;
