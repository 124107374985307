export const animatedVariant = {
  initial: {
    opacity: 0,
    x: "0px",
    y: "0px"
  },

  animate: {
    opacity: 1,
    x: "0",
    y: "0",
    transition: {
      x: { delay: 0.2 },
      y: { delay: 0.2 },
      opacity: { delay: 0.2 },
      duration: 0.1,
      type: "Tween",
      stiffness: 50
    }
  }
};

export const pathLenghthAnimate = {
  start: {
    pathLength: 0,
    opacity: 0
  },

  end: {
    pathLength: 1,
    opacity: 1,
    transition: {
      delay: 0.2,
      pathLength: { duration: 0.4 },
      opacity: { duration: 0.1 },
      ease: "easeInOut"
    }
  }
};
