import React, { useState, useEffect } from "react";

import { Modal, Button, Input, Title } from "components/simple";
import { useDispatch } from "react-redux";

import { createMonitorRow } from "store/entities/monitors/actions";
import styles from "./styles.module.scss";

const DetailsModal = ({ isOpen, onCloseModal, data_type, dict }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({});

  useEffect(() => {
    if (isOpen) {
      const res = {};
      Object.entries(dict).forEach(([key]) => {
        res[key] = "";
      });
      setState(res);
    }
    return () => setState({});
  }, [isOpen, dict]);

  const onSubmit = e => {
    e.preventDefault();
    dispatch(createMonitorRow({ ...state, data_type, callback: onCloseModal }));
  };

  const handleChange = (v, key) => {
    setState({
      ...state,
      [key]: v
    });
  };

  const createInputs = obj => {
    if (obj) {
      return Object.entries(obj).map(([key, val]) => {
        return (
          <Input
            key={key}
            label={val}
            value={state?.[key] || ""}
            onChange={v => handleChange(v.target.value, key)}
            required
          />
        );
      });
    }
    return [];
  };
  return (
    <Modal isOpen={isOpen} toggle={onCloseModal} width={850} sx={{ paddingRight: "20px" }}>
      <div className={styles.container}>
        <form className={styles.form} onSubmit={onSubmit}>
          <Title>Add Row</Title>
          <div className={styles.wrapper}>{createInputs(dict)}</div>

          <Button className={styles.button} type="submit">
            Save
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default DetailsModal;
