import React from "react";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Customloader from "./Customloader";

export default function Loader({ size, className }) {
  const styles = {
    display: "flex",
    justifyContent: "center"
  };

  return (
    <Box sx={{ ...styles }} className={className}>
      {/* <CircularProgress size={size} /> */}
      <Customloader size={size} />
    </Box>
  );
}

Loader.propTypes = {
  size: PropTypes.number
};

Loader.defaultProps = {
  size: 40
};
