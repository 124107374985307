/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { routes } from "router";
import { assignGroupsLeftTableColumns, assignGroupsRightTableColumns } from "../../../tableColumns";

import {
  getAssingGroupsLeftTableSelector,
  getAssingGroupsRightTableSelector
} from "store/entities/monitors/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import {
  addMonitorGroup,
  deleteMonitorGroup,
  setAssignGroupsParams,
  getMonitorGroups,
  getMonitor
} from "store/entities/monitors/actions";

import AssignTables from "../AssignedTables";

const AssignedGroups = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const [searchVal, setSearchVal] = useState({
    leftTable: "",
    rightTable: ""
  });

  const selectTenant = useSelector(state => state.users.selectTenant);

  const isLoadingGetMonitor = useSelector(state => createLoadingSelector([getMonitor.type])(state));
  const isLoadingAddGroup = useSelector(state =>
    createLoadingSelector([addMonitorGroup.type])(state)
  );
  const isLoadingGroups = useSelector(state =>
    createLoadingSelector([getMonitorGroups.type])(state)
  );

  const [monitorGroups, setMonitorGroups] = useState([]);

  const dataLeftTable = useSelector(getAssingGroupsLeftTableSelector);
  const dataRightTable = useSelector(getAssingGroupsRightTableSelector);

  const monitorId = params?.id;

  // get default request
  const getRequest = () => {
    dispatch(
      setAssignGroupsParams({
        search: ""
      })
    );
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  useEffect(() => {
    setMonitorGroups(dataRightTable);
  }, [dataRightTable]);

  // Search
  const handleSearch = (val, name) => {
    setSearchVal({
      ...searchVal,
      [name]: val
    });
  };

  const submitLeftTableSearch = text => {
    dispatch(
      setAssignGroupsParams({
        search: text
      })
    );
  };

  const submitRightTableSearch = text => {
    if (text === "") setMonitorGroups(dataRightTable);
    const newList = dataRightTable.filter(elem =>
      elem.name.toLowerCase().includes(text.toLowerCase())
    );
    setMonitorGroups(newList);
  };
  //

  const onCellClick = (p, e) => {
    const {
      field,
      id: uid,
      row: { name }
    } = p;
    e.stopPropagation();

    if (field === "failed_findings") {
      history.push({
        pathname: `${routes.failedMonitor}/${monitorId}`,
        search: `?title=${name}`
      });
    }
    if (field === "delete") {
      dispatch(deleteMonitorGroup({ id: monitorId, data: { id: uid } }));
    }
  };

  const addAssetHandler = ids => {
    if (ids?.length) {
      dispatch(addMonitorGroup({ id: monitorId, ids }));
    }
  };

  return (
    <AssignTables
      columnsLeftTable={assignGroupsLeftTableColumns}
      columnsRightTable={assignGroupsRightTableColumns}
      dataLeftTable={dataLeftTable}
      dataRightTable={monitorGroups}
      isLoadingLeftTable={isLoadingGroups}
      isLoadingOnAdd={isLoadingAddGroup}
      isLoadingRightTable={isLoadingGetMonitor}
      titleLeftTable="Assigned groups"
      onCellClick={onCellClick}
      onSearch={handleSearch}
      onSubmitLeftTableSearch={submitLeftTableSearch}
      onSubmitRightTableSearch={submitRightTableSearch}
      onAdd={addAssetHandler}
      searchVal={searchVal}
    />
  );
};

AssignedGroups.propTypes = {};

export default AssignedGroups;
