/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { createLoadingSelector } from "store/entities/loading/selector";
import {
  getOneFreshservice,
  addFreshservice,
  editFreshservice,
  getTicketsConfig,
  showTicketsConfigModal,
  deleteTicketConfig,
  getFreshserviceConfig
} from "store/entities/settings/actions";
import {
  getCurrentFreshserviceSelector,
  getTicketsConfigListSelector
} from "store/entities/settings/selectors";

import { ContentContainer, Title, Icon, Input, Button } from "components/simple";
import TicketConfigModal from "../TicketConfigModal";

import styles from "../service_styles.module.scss";

const Freshservice = () => {
  const params = useParams();
  const id = params?.id;
  const selectTenant = useSelector(state => state.users.selectTenant);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    company_domain: "",
    email: "",
    secret: "",
    name: ""
  });

  const isSaveLoading = useSelector(
    s =>
      createLoadingSelector([editFreshservice.type])(s) ||
      createLoadingSelector([addFreshservice.type])(s)
  );

  const editData = useSelector(getCurrentFreshserviceSelector);

  const type = "freshserviceprofile";
  const ticketsConfigs = useSelector(st => getTicketsConfigListSelector(st, type));

  useEffect(() => {
    if (id) {
      dispatch(getFreshserviceConfig({ id }));
      dispatch(getOneFreshservice({ id }));
      dispatch(getTicketsConfig({ id }));
    }
  }, [id, selectTenant]);

  const handleSubmit = e => {
    e.preventDefault();
    if (id) {
      dispatch(editFreshservice({ id, data: state }));
      return;
    }
    dispatch(addFreshservice(state));
  };

  useEffect(() => {
    if (editData && id) {
      setState(editData);
    }
  }, [editData, id]);

  const inputChangeHandler = (val, name) => {
    if (name === "company_domain") {
      // eslint-disable-next-line prefer-regex-literals
      const Reg = new RegExp("^.*[^a-zA-Z].*$");
      if (!Reg.test(val)) {
        setState({
          ...state,
          [name]: val
        });
      }
      return;
    }
    setState({
      ...state,
      [name]: val
    });
  };

  const onAddConfiguration = () => {
    dispatch(showTicketsConfigModal({ show: true, id: null, type }));
  };

  const onEditConfiguration = val => {
    dispatch(showTicketsConfigModal({ show: true, id: val, type }));
  };

  const onDeleteConfiguration = val => {
    dispatch(deleteTicketConfig({ id: val, ticket_profile_id: id }));
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Freshservice</Title>
      <div className={styles.content}></div>
      <form onSubmit={handleSubmit}>
        <div className={styles.sub_title}>Main</div>
        <div className={styles.inputsCont}>
          <Input
            value={state.name}
            onChange={e => inputChangeHandler(e.target.value, "name")}
            label="Name"
            required
          />
          <Input
            value={state.company_domain}
            onChange={e => inputChangeHandler(e.target.value, "company_domain")}
            label="Company domain"
            required
            placeholder="https://___.freshservice.com/ "
            subTitle={`https://${state.company_domain}.freshservice.com/`}
          />
          <Input
            value={state.email}
            onChange={e => inputChangeHandler(e.target.value, "email")}
            label="Email"
            type="email"
            required
          />
          <Input
            value={state.secret}
            onChange={e => inputChangeHandler(e.target.value, "secret")}
            label="Secret"
            type="password"
            autoComplete="new-password"
            required
          />
        </div>

        {/* ================ */}
        {id ? (
          <>
            <div className={styles.row}>
              <div className={styles.sub_title}>Ticket Configuration</div>
              <Button className={styles.btn} onClick={onAddConfiguration}>
                Add configuration
              </Button>
            </div>
            <div className={styles.inputsCont}>
              {ticketsConfigs?.map(item => {
                return (
                  <div className={styles.box} key={item?.id}>
                    <div className={styles.boxContent}>
                      <div className={styles.name}>{item?.name}</div>
                      <div className={styles.row}>
                        <div className={styles.title}>Issue type:</div>
                        <div className={styles.description}>{item?.issue_type}</div>
                      </div>
                    </div>
                    <div className={styles.buttonGroup}>
                      <div className={styles.button} onClick={() => onEditConfiguration(item?.id)}>
                        <Icon.Edit />
                      </div>
                      <div
                        className={styles.button}
                        onClick={() => onDeleteConfiguration(item?.id)}
                      >
                        <Icon.Delete />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
        <div className={styles.buttonFooter}>
          <Button type="submit" className={styles.btn} isLoading={isSaveLoading}>
            Save
          </Button>
        </div>
      </form>
      <TicketConfigModal />
    </ContentContainer>
  );
};

Freshservice.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Freshservice;
