import React from "react";
import PropTypes from "prop-types";

export default function Search(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58342 18.1251C4.87508 18.1251 1.04175 14.2917 1.04175 9.58342C1.04175 4.87508 4.87508 1.04175 9.58342 1.04175C14.2917 1.04175 18.1251 4.87508 18.1251 9.58342C18.1251 14.2917 14.2917 18.1251 9.58342 18.1251ZM9.58342 2.29175C5.55841 2.29175 2.29175 5.56675 2.29175 9.58342C2.29175 13.6001 5.55841 16.8751 9.58342 16.8751C13.6084 16.8751 16.8751 13.6001 16.8751 9.58342C16.8751 5.56675 13.6084 2.29175 9.58342 2.29175Z"
        fill={props.color}
      />
      <path
        d="M18.3333 18.9583C18.175 18.9583 18.0166 18.9 17.8916 18.775L16.225 17.1083C15.9833 16.8666 15.9833 16.4666 16.225 16.225C16.4666 15.9833 16.8666 15.9833 17.1083 16.225L18.775 17.8916C19.0166 18.1333 19.0166 18.5333 18.775 18.775C18.65 18.9 18.4916 18.9583 18.3333 18.9583Z"
        fill={props.color}
      />
    </svg>
  );
}

Search.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Search.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 20,
  height: 20
};
