import React from 'react'
import target from "./target.png"

function Target() {
  return (
    <div>
        <img src={target} width={18} height={18} alt="" />
    </div>
  )
}

export default Target