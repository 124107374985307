import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from "@mui/x-data-grid-pro";
import CustomDetailPanelToggle from "screens/RuntimeSecurity/AppBehavior/components/ExpandMoreIcon";
export const minionsHeaders = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    width: 25,
    // cellClassName: styles.eachCell,
    renderCell: params => (
      <div className="text-blue-500 cursor-pointer">
        <CustomDetailPanelToggle {...params} />
      </div>
    )
  },
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 25
  },
  {
    headerName: "Name",
    field: "name",
    width: 150
  },
  {
    headerName: "Minion ID",
    field: "minionid",
    width: 150
  },
  {
    headerName: "Status",
    field: "status",
    width: 150
  },
  {
    headerName: "Last Ping",
    field: "lastPingData",
    width: 150
  },
  {
    headerName: "Last Ping Date",
    field: "lastPingData",
    width: 150
  },
  {
    headerName: "Sync Module",
    field: "SyncModule",
    width: 150
  }
];
