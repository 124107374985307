import React from "react";
import { ContentContainer, LightTooltip, Loader, Title } from "components/simple";
import InfoIcon from "components/simple/Icon/Info";
import { useTheme } from "@mui/styles";
import { kFormatter } from "helper/helpers";
import { Typography } from "@mui/material";
import { HorizontalBarChart } from "../common";
import { useImageRisksQuery } from "store/entities/issues/registryScan/queries";

export const FindingsBySeverityCard = () => {
  const theme = useTheme();
  const imageRisksQuery = useImageRisksQuery();

  const totalVulnerabilities = imageRisksQuery.data?.totalVulnerabilities
    ? String(kFormatter(imageRisksQuery.data?.totalVulnerabilities))
    : 0;

  return (
    <ContentContainer className="p-4 flex flex-col gap-4">
      <div className="flex items-center justify-between w-full">
        <Title>Image Risk Assessment</Title>
        <LightTooltip title="Shows image risk assessment" placement="top" arrow>
          <span>
            <InfoIcon color={theme.palette.primary.light} />
          </span>
        </LightTooltip>
      </div>

      <div className="flex items-center justify-between p-2 bg-blue-100 rounded-lg">
        <Typography paragraph sx={{ color: "primary.main", fontWeight: "500", m: 0 }}>
          Total Vulnerabilities
        </Typography>
        <Typography variant="h6" sx={{ color: "primary.main", fontWeight: "600", m: 0 }}>
          {totalVulnerabilities}
        </Typography>
      </div>

      <div>
        {imageRisksQuery.isLoading ? (
          <Loader />
        ) : (
          <HorizontalBarChart
            data={imageRisksQuery.data?.severities}
            total={imageRisksQuery.data?.totalVulnerabilities}
          />
        )}
      </div>
    </ContentContainer>
  );
};
