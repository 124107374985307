import React from "react";

const RedirectArrow = () => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9999 0.875C10.9999 0.653986 10.9122 0.442025 10.7559 0.285744C10.5996 0.129464 10.3876 0.0416668 10.1666 0.0416668L3.49995 0C3.27894 0 3.06697 0.0877975 2.91069 0.244078C2.75441 0.400358 2.66662 0.61232 2.66662 0.833333C2.66662 1.05435 2.75441 1.26631 2.91069 1.42259C3.06697 1.57887 3.27894 1.66667 3.49995 1.66667H8.13328L1.24162 8.575C1.16351 8.65247 1.10151 8.74464 1.05921 8.84618C1.0169 8.94773 0.995117 9.05666 0.995117 9.16667C0.995117 9.27668 1.0169 9.3856 1.05921 9.48715C1.10151 9.5887 1.16351 9.68086 1.24162 9.75833C1.31908 9.83644 1.41125 9.89844 1.5128 9.94074C1.61435 9.98305 1.72327 10.0048 1.83328 10.0048C1.94329 10.0048 2.05221 9.98305 2.15376 9.94074C2.25531 9.89844 2.34748 9.83644 2.42495 9.75833L9.33328 2.85V7.5C9.33328 7.72101 9.42108 7.93297 9.57736 8.08925C9.73364 8.24553 9.9456 8.33333 10.1666 8.33333C10.3876 8.33333 10.5996 8.24553 10.7559 8.08925C10.9122 7.93297 10.9999 7.72101 10.9999 7.5V0.875Z"
        fill="#2B3858"
      />
    </svg>
  );
};

export default RedirectArrow;
