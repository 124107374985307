import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

import { loginScreen } from "router/routes";
import { useRefreshToken } from "../store/entities/auth/mutations";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { mutate: refreshToken } = useRefreshToken();

  useEffect(() => {
    // Refresh every 15 mins
    const refreshDuration = 15 * 60 * 1000;
    refreshToken();

    const intervalId = setInterval(() => {
      refreshToken();
    }, refreshDuration);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        return localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: loginScreen,
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.any]).isRequired,
  location: PropTypes.shape({})
};

PrivateRoute.defaultProps = {
  location: {}
};

export default PrivateRoute;
