import React, { memo } from "react";
import { useSelector } from "react-redux";
import { assetTypesCountSelector } from "store/entities/assets/selectors";
import AssetTypeBlock from "./AssetTypeBlock";

import styles from "./styles.module.scss";

const AssetTypeBlockList = () => {
  const assetTypesCount = useSelector(assetTypesCountSelector);
  return (
    <div className={styles.container}>
      {assetTypesCount.map(({ title, value }) => {
        return <AssetTypeBlock key={title} title={title} amount={value} />;
      })}
    </div>
  );
};

export default memo(AssetTypeBlockList);
