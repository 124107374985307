/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from "react";
import { ContentContainer, Button, Input, Search, Checkbox } from "components/simple";
import { Controller, useForm } from "react-hook-form";
import { List, ListItem, Typography, styled } from "@mui/material";
import { useCategoriesQuery, usePermissionsQuery } from "store/entities/rbac/queries";
import { useAddRoleMutation, useEditRoleMutation } from "store/entities/rbac/mutations";
import { goBack } from "helper/history";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import clsx from "clsx";

const RbacRoleDetails = props => {
  const fullAccess = { id: "*", name: "Full Access" };
  const defaultValues = { name: "", permissions: [] };

  const [searchValue, setSearchValue] = useState();
  const [selectedCategory, setSelectedCategory] = useState(fullAccess);

  const theme = useTheme();
  const location = useLocation();
  const role = location?.state?.role;
  const roleId = role?.id;

  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
    reset
  } = useForm({ defaultValues });
  const selectedPermissions = watch("permissions");
  const categoriesQuery = useCategoriesQuery();
  const permissionsQuery = usePermissionsQuery(selectedCategory, searchValue);
  const { data: allPermissions } = usePermissionsQuery(fullAccess);
  const { mutate: addRole } = useAddRoleMutation();
  const { mutate: editRole } = useEditRoleMutation();

  useEffect(() => {
    if (roleId) {
      reset({
        name: role?.name,
        permissions: role?.visible_permissions
      });
    }
  }, [roleId]);

  const handleBack = () => {
    goBack();
  };

  const onFormSubmit = formValues => {
    const data = {
      name: formValues?.name,
      visible_permissions: formValues?.permissions,
      user_profile: []
    };
    if (roleId) {
      editRole(
        {
          roleId,
          data
        },
        {
          onSuccess: () => {
            reset(defaultValues);
            goBack();
          }
        }
      );
    } else {
      addRole(data, {
        onSuccess: () => {
          reset(defaultValues);
          goBack();
        }
      });
    }
  };

  const categoriesCount = useMemo(() => {
    if (selectedPermissions?.length) {
      return selectedPermissions?.reduce((accumulator, permissionId) => {
        const permission = allPermissions?.find(p => p?.id === permissionId);
        const category = categoriesQuery.data?.find(c => c?.name === permission?.category);
        const currentCount = accumulator?.[category?.name] || 0;

        return { ...accumulator, [category?.name]: currentCount + 1 };
      }, {});
    }
  }, [categoriesQuery.data, selectedPermissions]);

  return (
    <ContentContainer className="px-10 py-8">
      <form className="flex flex-col gap-3" onSubmit={handleSubmit(onFormSubmit)}>
        <Controller
          name="name"
          rules={{ required: true }}
          control={control}
          ref={null}
          render={({ field: { value, onChange } }) => (
            <Input
              label="Role Name"
              placeholder="Enter your role name"
              value={value}
              onChange={onChange}
              containerClassName="max-w-xs"
              required
              autoFocus
            />
          )}
        />

        <div className="flex gap-12 max-h-[34rem]">
          <div className="flex flex-col gap-4">
            <div>
              <p className="font-medium">Categories</p>
              <p className="font-medium pt-[6px]">Select the Navigation</p>
            </div>

            <div className="flex flex-col gap-2 w-[20rem] h-full overflow-y-scroll">
              <List component="nav" aria-label="categories">
                {categoriesQuery?.data?.map(category => (
                  <ListItem
                    divider
                    className="p-3 pt-[13px] hover:bg-gray-100 cursor-pointer"
                    key={category?.id}
                    onClick={() => setSelectedCategory(category)}
                  >
                    <span
                      className={clsx(
                        "text-sm",
                        category?.id === selectedCategory?.id && "font-semibold"
                      )}
                    >
                      {category?.name}
                    </span>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div>
              <p className="font-medium">Choose the permissions</p>
              <Search value={searchValue} onSearch={setSearchValue} className="max-w-[16rem]" />
            </div>

            {Boolean(categoriesCount) && (
              <span className="flex items-center flex-wrap gap-2 col-span-3 h-auto w-[30rem]">
                {Object.keys(categoriesCount)?.map(category => (
                  <Typography
                    fontSize={12}
                    fontWeight={500}
                    key={category}
                    className="rounded-full px-2 py-1 bg-blue-100"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {category}: {categoriesCount[category]}
                  </Typography>
                ))}
              </span>
            )}

            <div className="flex flex-col gap-2 w-[30rem] h-full overflow-y-scroll">
              <List component="nav" aria-label="permissions">
                {permissionsQuery?.data?.map((permission, idx) => (
                  <Controller
                    key={idx}
                    name="permissions"
                    rules={{ required: true }}
                    control={control}
                    ref={null}
                    render={({ field: { value, onChange } }) => (
                      <ListItem
                        divider
                        key={permission?.id}
                        className="hover:bg-gray-100 cursor-pointer"
                      >
                        <Checkbox
                          label={permission.label}
                          value={
                            permission?.id === "*"
                              ? Boolean(
                                  permissionsQuery?.data
                                    ?.filter(p => p?.id !== "*")
                                    ?.every(p => Boolean(value?.find(v => v === p?.id)))
                                )
                              : Boolean(value?.find(v => v === permission.id))
                          }
                          onChange={v => {
                            let newValues = [];
                            const permissionIds = permissionsQuery.data
                              ?.map(p => p?.id)
                              ?.filter(p => p !== "*");

                            if (v) {
                              if (permission?.id === "*") {
                                newValues = [...value, ...permissionIds];
                              } else {
                                newValues = [...value, permission?.id];
                              }
                            } else {
                              if (permission?.id === "*") {
                                const toRemove = new Set(permissionIds);
                                newValues = value?.filter(v => !toRemove.has(v));
                              } else {
                                newValues = value?.filter?.(v => v !== permission?.id);
                              }
                            }

                            onChange([...new Set(newValues)]);
                          }}
                        />
                      </ListItem>
                    )}
                  />
                ))}
              </List>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <GreyButton className="h-10 py-0 px-9 mt-9" onClick={handleBack}>
            Back
          </GreyButton>
          <Button
            onClick={handleSubmit(onFormSubmit)}
            className="h-10 py-0 px-9 mt-9"
            disabled={!isValid}
          >
            Save
          </Button>
        </div>
      </form>
    </ContentContainer>
  );
};

const GreyButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.grey[200],
  "&:hover": {
    backgroundColor: theme.palette.grey[200]
  }
}));

RbacRoleDetails.propTypes = {};

export default RbacRoleDetails;
