import React from "react";

import { Divider, IconButton } from "@mui/material";
import { ActionButton, Button, Icon, Input, Select, Switch } from "components/simple";

import styles from "../styles.module.scss";

export const DetailModalForm = ({
  data,
  status,
  severity,
  ignoredValue,
  handleSelect,
  handleSwitch,
  handleSelectSeverity,
  handleSubmit,
  handleMore,
  statusesOptions,
  severitiesOptions,
  toTickets,
  ticketConfigOptions,
  selectTicketConfig,
  handleTicketConfig,
  handleAction
}) => {
  const CVE = data?.vulnerability?.identification_numbers;
  return (
    <form className={styles["tls-modal-details__form"]}>
      <div className={styles["tls-modal-details__form-action"]}>
        <span>Status: </span>
        <Select
          value={status}
          onChange={handleSelect}
          options={statusesOptions}
          containerClass={styles["tls-modal-details__form-action-field"]}
        />
      </div>
      <div className={styles["tls-modal-details__form-action"]}>
        <span>Ignored: </span>
        <div className={styles["tls-modal-details__form-action-switch"]}>
          <Input readOnly value={ignoredValue ? "True" : "False"} />
          <Switch
            value={ignoredValue}
            onChange={handleSwitch}
            className={styles["tls-modal-details__form-switch-item"]}
          />
        </div>
      </div>
      <div className={styles["tls-modal-details__form-action"]}>
        <span>Tickets: </span>
        <Input
          readOnly
          value={data?.tickets?.length}
          containerClassName={styles["tls-modal-details__form-action-field"]}
        />
        <IconButton
          onClick={toTickets}
          className={styles["tls-modal-details__form-action-redirect"]}
          disableRipple
          disableTouchRipple
          disableFocusRipple
        >
          <Icon.RedirectArrow />
        </IconButton>
      </div>
      <Divider className={styles["tls-modal-divider-sm"]} />
      <div className={styles["tls-modal-details__form-action"]}>
        <span>Severity: </span>
        <Select
          readOnly
          value={severity}
          onChange={handleSelectSeverity}
          options={severitiesOptions}
          containerClass={styles["tls-modal-details__form-action-field"]}
        />
      </div>
      <Divider className={styles["tls-modal-divider-sm"]} />
      {ticketConfigOptions?.length > 0 && (
        <>
          <div className={styles["tls-modal-details__form-action-container"]}>
            <Select
              containerClass={styles.select}
              onChange={handleTicketConfig}
              value={selectTicketConfig}
              placeholder="Ticket Configuration"
              options={ticketConfigOptions}
              isClearable
            />
            <ActionButton
              type="createTicket"
              onClick={handleAction}
              className={styles.actionButton}
            />
          </div>
          <Divider className={styles["modal-divider-sm"]} />
        </>
      )}
      <div className={styles["tls-modal-details__form-action-btn"]}>
        <Button
          onClick={handleSubmit}
          className={styles["tls-modal-details__form-action-btn-save"]}
        >
          Save
        </Button>
        {CVE?.length > 0 && (
          <>
            <Divider className={styles["tls-modal-divider-sm"]} />
            <Button
              onClick={handleMore}
              className={styles["tls-modal-details__form-action-btn-more"]}
            >
              Show more
            </Button>
          </>
        )}
      </div>
    </form>
  );
};
