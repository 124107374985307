import * as constant from "./constants";

import { createAction } from "@reduxjs/toolkit";

export const addNotification = createAction(constant.ADD_NOTIFICATION);
export const removeNotification = createAction(constant.REMOVE_NOTIFICATION);

// Set Notifications params
export const setNotificationsParams = createAction(constant.SET_NOTIFICATIONS_PARAMS);

// Get Notifications
export const getNotifications = createAction(constant.GET_NOTIFICATIONS);
export const getNotificationsSuccess = createAction(constant.GET_NOTIFICATIONS_SUCCESS);
export const getNotificationsFailed = createAction(constant.GET_NOTIFICATIONS_FAILED);

// Get Modal Notifications
export const getModalNotifications = createAction(constant.GET_MODAL_NOTIFICATIONS);
export const getModalNotificationsSuccess = createAction(constant.GET_MODAL_NOTIFICATIONS_SUCCESS);
export const getModalNotificationsFailed = createAction(constant.GET_MODAL_NOTIFICATIONS_FAILED);

// Get Notification
export const getNotification = createAction(constant.GET_NOTIFICATION);
export const getNotificationSuccess = createAction(constant.GET_NOTIFICATION_SUCCESS);
export const getNotificationFailed = createAction(constant.GET_NOTIFICATION_FAILED);

// Delete Notification
export const deleteNotification = createAction(constant.DELETE_NOTIFICATION);
export const deleteNotificationSuccess = createAction(constant.DELETE_NOTIFICATION_SUCCESS);
export const deleteNotificationFailed = createAction(constant.DELETE_NOTIFICATION_FAILED);

// Update Notification
export const updateNotification = createAction(constant.UPDATE_NOTIFICATION);
export const updateNotificationSuccess = createAction(constant.UPDATE_NOTIFICATION_SUCCESS);
export const updateNotificationFailed = createAction(constant.UPDATE_NOTIFICATION_FAILED);
