import React from "react";
import { Modal, Title, Button } from "components/simple";
import { Divider } from "@mui/material";

export default function EditModal({ isOpenModal, isCloseModal, updateLocalDSL, filterArr }) {
  const handleModal = () => {
    updateLocalDSL(filterArr);
    isCloseModal();
  };
  return (
    <Modal isOpen={isOpenModal} toggle={isCloseModal}>
      <Title className="mb-3">Edit Trigger</Title>
      <Divider />
      <p>Click OK button and Add a new filter select one from the logs and Click Save Trigger.</p>
      <div className="flex justify-end">
        <Button onClick={() => handleModal()}>Ok</Button>
      </div>
    </Modal>
  );
}
