import React from "react";
import PropTypes from "prop-types";

export default function ArrowBack(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31848 13.0709C8.31848 12.8809 8.24848 12.6909 8.09848 12.5409L2.55848 7.00086L8.09848 1.46086C8.38848 1.17086 8.38848 0.690859 8.09848 0.400859C7.80848 0.110859 7.32848 0.110859 7.03848 0.400859L0.968477 6.47086C0.678477 6.76086 0.678477 7.24086 0.968477 7.53086L7.03848 13.6009C7.32848 13.8909 7.80848 13.8909 8.09848 13.6009C8.24848 13.4609 8.31848 13.2609 8.31848 13.0709Z"
        fill={props.color}
      />
      <path
        d="M19.2485 7.00086C19.2485 6.59086 18.9085 6.25086 18.4985 6.25086L1.66848 6.25086C1.25848 6.25086 0.918477 6.59086 0.918477 7.00086C0.918477 7.41086 1.25848 7.75086 1.66848 7.75086L18.4985 7.75086C18.9085 7.75086 19.2485 7.41086 19.2485 7.00086Z"
        fill={props.color}
      />
    </svg>
  );
}

ArrowBack.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

ArrowBack.defaultProps = {
  className: "",
  style: {},
  color: "#D83E3C",
  width: 20,
  height: 14
};
