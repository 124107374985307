import React from "react";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import renderCellExpand from "helper/renderCellExpand";
import { formatDate, getTimeIntervalFromNow } from "helper/DateLib";

const toasts = state => state.notifications.toasts;
const notifications = state => state.notifications.notifications;
const modalNotifications = state => state.notifications.modalNotifications;

export const toastsSelector = createDraftSafeSelector(toasts, state => state);

export const notificationsTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Subject",
    field: "subject",
    resizable: true,
    minWidth: 400,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Date",
    field: "date",
    resizable: true,
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Label",
    field: "label",
    resizable: true,
    minWidth: 260,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Type",
    field: "notification_type",
    resizable: true,
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const getNotificationsSelector = createDraftSafeSelector(notifications, state => {
  if (!state?.results?.length) {
    return { data: [], total: 0 };
  }

  const { results, count } = state;
  const data = results.map(item => {
    return {
      id: item.id,
      subject: item.subject,
      label: item.label?.name,
      read: item.read,
      notification_type: item.notification_type,
      date: formatDate(item.date)
    };
  });
  return { data, total: count };
});

export const isUnreadNotificationsSelector = createDraftSafeSelector(modalNotifications, state => {
  if (!state?.results?.length) {
    return false;
  }

  return state.results.find(item => !item.read);
});

export const modalNotificationsSelector = createDraftSafeSelector(modalNotifications, state => {
  const data = state?.results;
  if (!data?.length) {
    return [];
  }

  let list = data;
  if (data.length > 3) {
    list = data.slice(0, 3);
  }

  return list.map(item => {
    return {
      id: item.id,
      subject: item.subject,
      read: item.read,
      date: getTimeIntervalFromNow(item.date)
    };
  });
});
