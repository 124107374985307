import { customPost } from "api/base";
import { useMutation } from "react-query";

export const useAddEntities = () => {
  return useMutation(["addEntities"], async payload => {
    const response = await customPost("/labelmanagement/api/v1/label/add-entities", payload);
    const result = await response.json();

    return result;
  });
};
