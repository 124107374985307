// import { ContentContainer, DateRangePicker, Title } from "components/simple";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useQuery } from "react-query";
import VisGraph from "./GraphContainer/VisGraph";
import {
  CLUSTERS_DATA,
  PODS_DATA,
  CONTAINER_DATA,
  VMGROUP_DATA,
  VMINSTANCE_DATA,
  CLUSTERFILTER,
  AUTO_DISCOVERED_POLICY,
  CLUSTER_GRAPH_DETAILS,
  MOCK_ALL_POLICIES,
  OPTIONS,
  TAB
} from "./mockdata";
import _ from "lodash";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "./styles.module.scss";
import { CLUSTER, POD, CONTAINER, VMGROUP, VMINSTANCE, PROCESS, NODE } from "./constant";
import { Box, ButtonGroup, CssBaseline, Skeleton, Typography } from "@mui/material";
import HybridViewSidebar from "./HybridViewSidebar";
import { InputSearch, Button, RefreshInterval, CustomPagination } from "components/simple";
import { ToggleView } from "./Components/ToggleView";
import { useToggle } from "./hooks/useToggle";
import { Table } from "components/complex";
import DynamicFilter from "components/complex/DynamicFilter/logsDSL";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import EmptyClusterData from "./Components/EmptyClusterData";
import RightClickMenu from "./Components/Menu";
import DateRangePicker from "components/simple/DateRangePicker/DateRangePicker-2";
import Loader from "components/simple/Loader";
import RightSlideDrawer from "components/complex/Drawer";
import {
  FindTotalPage,
  heightDifferenceForContextMenu
} from "./helpers/heightDifferenceForContextMenu";
import {
  useClustersideBarData,
  useClusterDetail,
  uselistofClusterData,
  usePodSideBarData,
  uselistofPodData,
  fetchAllPolicies,
  fetchAutoDiscover,
  uselistofContainerData,
  uselistofNodeData,
  useListOfPolicies,
  useListOfAutodiscoveredPolicies,
  useClusterDetails
} from "./service/query";
import { CLUSTER_COLUMNS, POD_COLUMNS, CONTAINER_COLUMNS, NODE_COLUMNS } from "./columns";
import AddLabelModal from "./Components/AddLabelModal";
import {
  getClusterDetail,
  getPodDetail,
  getWorkloadListApi,
  uselistofAlertsClusterData,
  uselistofAlertsNamespaceData
} from "./service/api";
import {
  AddId,
  AlertsDetails,
  filterUnSelected,
  splitName,
  TransformCluster,
  TransformPod
} from "./helpers/dataTransformation";
import SecurityModal from "./Components/SecurityPostureModal";
import { useStorePostureMutation } from "./service/mutation";
import { RefreshTime } from "./constants";
import useInterval from "screens/MonitorLogs/hooks/useInterval";
import { navigate } from "helper/history";
import { routes } from "router";
import { useMemo } from "react";
import { usePrimaryLayoutTitle } from "screens/MonitorLogs/Context/Context";
// import entityService from "./service-2";

function ClusterManagement() {
  const [selectedView, setSelectedView] = useState(CLUSTER);
  const [graphData, setGraphData] = useState([]);
  const [highLightEntity, setHighlightEntity] = useState("");
  const [normalView, setNormalView] = useState(true);
  const [entityType, setEntityType] = useState("Clusters");
  const [selectedNodeId, setSelectedNodeId] = useState();
  const [selectedPodId, setSelectedPodId] = useState();
  const [selectInstanceGroupId, setSelectInstanceGroupId] = useState("");
  const [selectVmInstanceId, setSelectVmInstanceId] = useState("");
  const [vmGroupData, setVmGroupData] = useState([]);
  const [selectedInstanceGroup, setSelectedInstanceGroup] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState([]);
  const [applicationView, setApplicationView] = useState([]);
  const [parentSelected, setParentSelected] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedNamespace, setSelectedNamespace] = useState([]);
  const [showViewPolicy, setShowViewPolicy] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("K8s Cluster");
  const [showVMGraph, setShowVMGraph] = useState([]);
  const [activeClusterId, setActiveClusterId] = useState([]);
  const [activeNodeId, setActiveNodeId] = useState([]);
  const [throughCluster, setThroughCluster] = useState([]);
  const [isSelected, setIsSelected] = useState([]);
  const [range, setRange] = useState("Last 7 days");
  const [vmData, setVmData] = useState([]);
  const [vmProcessData, setVmProcessData] = useState([]);
  const [mainEntity, setMainEntity] = useState(["clusters"]);
  const [selectedNamespaceId, setSelectedNamespaceId] = useState();
  const [pageSize, setPageSize] = [0];
  const [allPolicies, setAllPolicies] = useState([]);
  const [autoDiscoverPolicies, setAutoDiscoverPolicies] = useState([]);
  const [tabNumber, setTabNumber] = useState(0);
  const [showClusterAddPolicy, setShowClusterAddPolicy] = useState(false);
  const [RightSlideMenu, setRightSlideMenu] = useState(false);
  const [RightSlideTabState, setRightSlideTabState] = useState(0);
  const [selectedCluster, setSelectedCluster] = useState({});
  const [selectedNode, setSelectedNode] = useState({});
  const [selectedPod, setSelectedPod] = useState({});
  const [rightClickEntity, setRightClickEntity] = useState({});
  const [showClusterRightMenu, setShowClusterRightMenu] = useState(false);
  const [xPos, setXPos] = useState("");
  const [yPos, setYPos] = useState("");
  const [clusterAlerts, setClusterAlerts] = useState([]);
  const [showContextMenuLeft, setShowContextMenuLeft] = useState({
    showLeft: false
  });
  const [showContextMenuAbove, setShowContextMenuAbove] = useState({
    showAbove: false
  });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { logdDslTag } = usePrimaryLayoutTitle();
  const [localDSL, setLocalDSL] = useState(logdDslTag);
  const [entitySelectedDetail, setEntitySelectedDetail] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEntityDetailLoading, setIsEntityDetailLoading] = useState(false);
  const [isTableEntityDetailLoading, setIsTableEntityDetailLoading] = useState(false);
  const [showSecurityPosture, setShowSecurityPosture] = useState(false);
  const [combineCluster, setCombineCluster] = useState([]);
  const [podData, setPodData] = useState([]);
  const [combinePod, setCombinePod] = useState([]);
  const [value, setValue] = useState();
  const [page, setPage] = useState(0);
  const [viewPoliciesOptions, setViewPoliciesOptions] = useState([]);
  const [workloadList, setworkloadList] = useState([]);
  const { isListView, actions: toggleActions } = useToggle();
  const [namespaceData, setNamespaceData] = useState({});
  const [clickedNamespace, setClickedNamespace] = useState([]);
  const [refreshTimeVal, setRefreshTimeVal] = useState(RefreshTime[0].label);
  const [refreshTimeinsec, setRefreshTimeinsec] = useState(RefreshTime[0].value);
  const apiRef = useGridApiRef();
  // check wether its list view or now
  //hierchy
  const handleChange = useCallback((event, newValue) => setRightSlideTabState(newValue), [
    RightSlideTabState
  ]);

  //entity API
  const {
    data: listofCluster = [],
    isLoading: ListofClusterisLLoading,
    refetch: useGetAllClusterRefetch
  } = uselistofClusterData(range);
  useEffect(() => {
    if (refreshTimeVal === "5 sec") {
      setRefreshTimeinsec(RefreshTime[1].value);
    }
    if (refreshTimeVal === "10 sec") {
      setRefreshTimeinsec(RefreshTime[2].value);
    }
    if (refreshTimeVal === "30 sec") {
      setRefreshTimeinsec(RefreshTime[3].value);
    }
    if (refreshTimeVal === "1 min") {
      setRefreshTimeinsec(RefreshTime[4].value);
    }
    if (refreshTimeVal === "5 mins") {
      setRefreshTimeinsec(RefreshTime[5].value);
    }
    if (refreshTimeVal === "10 mins") {
      setRefreshTimeinsec(RefreshTime[6].value);
    }
    if (refreshTimeVal === "30 mins") {
      setRefreshTimeinsec(RefreshTime[7].value);
    }
    if (refreshTimeVal === "off") {
      setRefreshTimeinsec(RefreshTime[0].value);
    }
  }, [refreshTimeVal]);
  const onSelectedFilter = filters => setSelectedFilters(filters);
  const useStorePosture = useStorePostureMutation();
  const {
    data: listofPod = [],
    isLoading: ListofPodisLLoading,
    refetch: useListofPodDataRefetch
  } = uselistofPodData(selectedView, selectedCluster, selectedNode, range, useStorePosture);
  const {
    data: listofNode = [],
    isLoading: ListofNodeisLLoading,
    refetch: useListofNodeDataRefetch
  } = uselistofNodeData(selectedView, selectedCluster, range);
  const {
    data: listofContainer = [],
    isLoading: ListofContainerisLoading,
    refetch: useListofContainerDataRefetch
  } = uselistofContainerData(selectedView, selectedCluster, selectedNode, selectedPod, range);

  const {
    data: sideBarClusterData,
    isFetched: useSideBarClusterFetched,
    refetch: useClusterSideBarDataRefetch,
    isLoading: useClusterSideBarDataIsLoading
  } = useClustersideBarData(range);
  const {
    data: sideBarPodData,
    isFetched: useSideBarPodFetched,
    refetch: usePodSideBarDataRefetch,
    isLoading: usePodSideBarDataIsLoading
  } = usePodSideBarData(range);
  useInterval(
    () => {
      if (selectedView === "cluster") {
        AlertsDetails(listofCluster, CLUSTER, setGraphData, range, setClusterAlerts);
      } else if (selectedView === "pod") {
        AlertsDetails(
          listofPod,
          selectedView,
          setGraphData,
          range,
          setClusterAlerts,
          selectedCluster
        );
      }
    },
    typeof refreshTimeinsec === "number" ? refreshTimeinsec : null
  );
  const timeRange = [
    { value: "All", id: 0 },
    { value: "Last 5 min", id: 1 },
    { value: "Last 15 min", id: 2 },
    { value: "Last 30 min", id: 3 },
    { value: "Last 1 hour", id: 4 },
    { value: "Last 3 hours", id: 5 },
    { value: "Last 6 hours", id: 6 },
    { value: "Last 12 hours", id: 7 },
    { value: "Last 24 hours", id: 8 },
    { value: "Last 2 days", id: 9 },
    { value: "Last 7 days", id: 10 }
  ];

  const handleRefreshApi = () => {
    if (selectedView === "cluster") {
      AlertsDetails(listofCluster, CLUSTER, setGraphData, range, setClusterAlerts);
    } else if (selectedView === "pod") {
      AlertsDetails(
        listofPod,
        selectedView,
        setGraphData,
        range,
        setClusterAlerts,
        selectedCluster
      );
    }
  };

  const onChangeRange = range => setRange(range);
  useEffect(async () => {
    if (!ListofClusterisLLoading) {
      setIsTableEntityDetailLoading(true);
      TransformCluster(
        listofCluster,
        getClusterDetail,
        "ID",
        "cluster_id",
        setCombineCluster,
        setIsTableEntityDetailLoading
      );
    }
  }, [JSON.stringify(listofCluster)]);
  useEffect(() => {
    setSelectedView(CLUSTER);
    AlertsDetails(listofCluster, CLUSTER, setGraphData, range, setClusterAlerts);
  }, [range]);
  const detailRef = useRef(false);
  const switchToNormalView = () => {
    setApplicationView(false);
    setNormalView(true);
  };
  const onClickGraphClusterNode = () => {
    setThroughCluster(true);
    if (rightClickEntity?.entity_type === CLUSTER) {
      setSelectedCluster({
        value: rightClickEntity?.id,
        label: rightClickEntity?.name
      });
      setSelectedNode({});
      setSelectedPod({});
    }
    setSelectedView(NODE);
    setEntityType("cluster");
  };
  const onClickGraphClusterPod = () => {
    setThroughCluster(false);
    if (rightClickEntity?.entity_type === CLUSTER) {
      setSelectedCluster({
        value: rightClickEntity?.id,
        label: rightClickEntity?.name
      });
      setSelectedPod({});
    }
    setSelectedView(POD);
    setEntityType("node");
  };
  const onCellClick = (p, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedView === CLUSTER) {
      setSelectedCluster({
        label: p?.row?.name,
        value: p?.row?.ID
      });
    }
    if (selectedView === POD) {
      setSelectedNamespace({
        label: p?.row?.namespace,
        value: p?.row?.namespace_id
      });
      setSelectedPod({
        label: p?.row?.podname,
        value: p?.row?.ID
      });
    }
    const [posX, posY, differenceInHeightInPct] = heightDifferenceForContextMenu(
      e.pageX,
      e.pageY,
      e.view?.innerHeight
    );
    setXPos(posX + "px");
    setYPos(posY + "px");
    const maxHeight = e.view?.innerHeight;
    setRightClickEntity(p?.row);
    setShowContextMenuAbove({
      showAbove: differenceInHeightInPct < 20,
      newYPosFromTable: maxHeight - posY
    });
    if (selectedView !== CONTAINER) {
      setShowClusterRightMenu(true);
    }
  };

  const onClickOfEntity = entity => {
    switch (selectedView) {
      case CLUSTER:
        setThroughCluster(false);
        setSelectedCluster({
          value: entity?.id,
          label: entity?.name
        });

        setSelectedNode({});
        setSelectedPod({});
        setSelectedNamespace({});
        setSelectedView(POD);
        switchToNormalView();
        break;
      case NODE:
        setThroughCluster(true);
        setSelectedView(POD);
        setSelectedNode({
          value: entity?.id,
          label: entity?.name
        });
        switchToNormalView();
        setSelectedPod({});
        setSelectedNamespace({});
        break;
      case POD:
        setSelectedPod({ value: entity?.id, label: entity?.name });
        setSelectedNamespace({
          value: entity?.namespace_id,
          label: entity?.namespace
        });
        setSelectedView(CONTAINER);
        switchToNormalView();
        break;
      case VMGROUP:
        setThroughCluster(false);
        setSelectedView(VMINSTANCE);
        switchToNormalView();
        break;
      case VMINSTANCE:
        setThroughCluster(false);
        setSelectedView(PROCESS);
        switchToNormalView();
        break;
      default:
        setSelectedView(CLUSTER);
        break;
    }
  };

  const updateLocalDSL = function(val) {
    setLocalDSL(val);
  };
  useEffect(() => {
    let pages = page * 10;
    switch (selectedView) {
      case POD:
        TransformPod(
          podData,
          page,
          getPodDetail,
          "ID",
          setCombinePod,
          setIsTableEntityDetailLoading
        );
        break;
      default:
        // setGraphData([]);
        break;
    }
  }, [page, JSON.stringify(podData)]);
  useEffect(() => {
    switch (selectedView) {
      case CLUSTER:
        setGraphData(listofCluster);
        AlertsDetails(listofCluster, selectedView, setGraphData, range, setClusterAlerts);
        break;
      case NODE:
        setGraphData(listofNode);
        break;
      case POD:
        setGraphData(listofPod);
        AlertsDetails(
          listofPod,
          selectedView,
          setGraphData,
          range,
          setClusterAlerts,
          selectedCluster
        );
        setPodData(listofPod);
        break;
      case CONTAINER:
        setGraphData(listofContainer);
        break;
      case VMGROUP:
        setGraphData(VMGROUP_DATA);
        break;
      case VMINSTANCE:
        setGraphData(VMINSTANCE_DATA);
        break;
      case PROCESS:
        setGraphData([]);
        break;
      default:
        setGraphData([]);
        break;
    }
  }, [
    selectedView,
    JSON.stringify(listofCluster),
    JSON.stringify(listofPod),
    JSON.stringify(listofContainer),
    JSON.stringify(listofNode)
  ]);
  useEffect(() => {
    updateLocalDSL([]);
  }, [isListView]);
  useEffect(() => {
    if (selectedView === POD) {
      if (logdDslTag?.length) {
        let TransformFilter = logdDslTag?.map(e => ({
          name: splitName(e?.text),
          type: e?.key
        }));
        let arr = TransformFilter?.map(e => {
          if (e?.type === "Namespace") {
            return _.filter(listofPod, { namespace: e?.name });
          }
          if (e?.type === "PodName") {
            return _.filter(listofPod, { name: e?.name });
          }
          return tmp;
        });
        let filterArray = _.uniqBy(arr?.flat(), "name");
        setGraphData(filterArray);
        AlertsDetails(
          filterArray,
          selectedView,
          setGraphData,
          range,
          setClusterAlerts,
          selectedCluster
        );
        setPodData(filterArray);
      } else {
        setGraphData(listofPod);
        AlertsDetails(
          listofPod,
          selectedView,
          setGraphData,
          range,
          setClusterAlerts,
          selectedCluster
        );
        setPodData(listofPod);
      }
      setPage(0);
    }
  }, [logdDslTag]);
  const CombainAlerts = (sidebarCluster, clusterAlerts) => {
    const a3 = sidebarCluster?.map(t1 => ({
      ...t1,
      ...clusterAlerts.find(t2 => t2?.ClusterID === t1?.ID)
    }));
    return a3 || [];
  };
  const detailsInGraphPodView = async entity => {
    if (entity?.entity_type === CLUSTER) {
      const data = await getClusterDetail(entity?.ID);
      setEntitySelectedDetail(data);
    }
    if (entity?.entity_type === POD) {
      const data = await getPodDetail(entity?.ID);
      setEntitySelectedDetail(data);
    }
  };

  useEffect(async () => {
    detailsInGraphPodView(rightClickEntity);
  }, [rightClickEntity]);

  useEffect(() => {
    setRightClickEntity({});
  }, [selectedView]);
  const handlePageChange = newPageSize => {
    setPageSize(newPageSize);
  };
  const DataTrasform = useSideBarClusterFetched
    ? sideBarClusterData?.map(clusters => {
        let filterPod = useSideBarPodFetched
          ? sideBarPodData?.filter(e => e?.ClusterID === clusters?.ID)
          : [];
        clusters.children = [...filterPod];
        return clusters;
      })
    : [];
  const filterValuesForPod = useMemo(
    () => ({
      podName: _.uniqBy(listofPod, "name").map(c => c?.name?.toString())
    }),
    [listofPod]
  );
  const filterValuesForNamespaceName = useMemo(
    () => ({
      NamespaceName: _.uniqBy(listofPod, "namespace").map(c => c?.namespace?.toString())
    }),
    [listofPod]
  );
  useEffect(() => {
    let options = [];
    switch (selectedView) {
      case CLUSTER:
        options.push({
          value: null,
          label: "All Clusters"
        });
        listofCluster?.map(e =>
          options.push({
            ...e,
            value: e?.ID,
            label: e?.name
          })
        );
        setValue({
          ...rightClickEntity,
          label: rightClickEntity.name,
          value: rightClickEntity.ID
        });
        break;
      case NODE:
        options.push({
          value: null,
          label: "All Nodes"
        });
        listofNode?.map(e =>
          options.push({
            ...e,
            value: e?.ID,
            label: e?.NodeName
          })
        );
        setValue({
          ...rightClickEntity,
          label: rightClickEntity.name,
          value: rightClickEntity.ID
        });
        break;
      case POD:
        options.push({
          value: null,
          label: "All Pods"
        });
        listofPod?.map(e =>
          options.push({
            ...e,
            value: e?.ID,
            label: e.name
          })
        );
        setValue({
          ...rightClickEntity,
          label: rightClickEntity.name,
          value: rightClickEntity.ID
        });
        break;
      default:
        options = [];
    }
    setViewPoliciesOptions(options);
  }, [RightSlideMenu]);
  const handleMultiselect = res => {
    setValue(res);
  };
  useEffect(() => {
    if (isListView) {
      setClickedNamespace(namespaceData);
    } else {
      setClickedNamespace(podData.filter(a => a.namespace == namespaceData.groupName));
    }
  }, [namespaceData]);

  const hangleChangeFilter = (val, key) => {
    const res = {
      ...filters,
      [key]: val
    };
    dispatch(
      setImportHistoryParams({
        filters: res,
        page: 0,
        search: "",
        ordering: ""
      })
    );
  };
  const { namespace_id } = namespaceData;
  const handleStorePosture = (process, network) => {
    useStorePosture.mutate({ namespace_id, process, network });
  };
  return (
    <div className={styles.dashboard}>
      {isModalOpen && (
        <>
          <AddLabelModal
            modalOpen={isModalOpen}
            setModalOpen={() => setIsModalOpen(!isModalOpen)}
            rightEntity={rightClickEntity}
          />
        </>
      )}
      <RightSlideDrawer
        options={viewPoliciesOptions}
        state={RightSlideMenu}
        activeTab={RightSlideTabState}
        handleChange={handleChange}
        onChange={handleMultiselect}
        anchor="right"
        tabs={TAB}
        setState={setRightSlideMenu}
        value={value}
      />
      {showSecurityPosture && (
        <SecurityModal
          xPos={xPos}
          yPos={yPos}
          setShowSecurityPosture={setShowSecurityPosture}
          showContextMenuAbove={showContextMenuAbove}
          setShowContextMenuAbove={setShowContextMenuAbove}
          showContextMenuLeft={showContextMenuLeft}
          setShowContextMenuLeft={setShowContextMenuLeft}
          namespaceData={namespaceData}
          isListView={isListView}
          setClickedNamespace={setClickedNamespace}
          setNamespaceData={setNamespaceData}
          clickedNamespace={clickedNamespace}
          process_posture={
            isListView
              ? clickedNamespace?.kubearmor_file_posture
              : clickedNamespace[0]?.kubearmor_file_posture
          }
          network_posture={
            isListView
              ? clickedNamespace?.kubearmor_network_posture
              : clickedNamespace[0]?.kubearmor_network_posture
          }
          default_posture={
            isListView ? clickedNamespace?.default_posture : clickedNamespace[0]?.default_posture
          }
          handleStorePosture={handleStorePosture}
        />
      )}
      {showClusterRightMenu && (
        <RightClickMenu
          style={{ marginTop: "10px" }}
          setShowRightMenu={setShowClusterRightMenu}
          // setShowWorkLoadModal={setShowWorkLoadModal}
          setShowViewPolicy={setShowViewPolicy}
          setShowAddLabel={() => setIsModalOpen(!isModalOpen)}
          xPos={xPos}
          yPos={yPos}
          setTabNumber={setTabNumber}
          setShowAddPolicy={setShowClusterAddPolicy}
          selectedview={selectedView}
          isContextMenuVisible={showClusterRightMenu}
          podId={[]}
          nodeId={[]}
          nodeOnClick={onClickGraphClusterNode}
          podOnClick={onClickGraphClusterPod}
          isListView={isListView}
          hybridViewClusterId={selectedCluster.value}
          hybridViewClusterName={selectedCluster.label}
          hybridViewNamespaceName={selectedNamespace.label}
          hybridViewNamespaceId={selectedNamespace.value}
          selectedPodNamespaceOptions={[]}
          labelTypeForAddPolicy={[]}
          entityType={entityType}
          selectedNodeId={selectedNodeId}
          throughCluster={throughCluster}
          selectedCluster={selectedCluster}
          addPolicyPermission
          selectedPodName=""
          showContextMenuAbove={showContextMenuAbove}
          setShowContextMenuAbove={setShowContextMenuAbove}
          showContextMenuLeft={showContextMenuLeft}
          setShowContextMenuLeft={setShowContextMenuLeft}
          setRightSlideMenu={setRightSlideMenu}
          selectedPod={selectedPod}
          selectedNamespace={selectedNamespace}
        />
      )}
      <Box
        sx={{
          display: "flex",
          marginRight: "10px",
          backgroundColor: "#fff",
          width: "21%",
          height: "fit-content"
        }}
      >
        <HybridViewSidebar
          setEntityType={setEntityType}
          // setSelectedClusterId={setSelectedClusterId}
          // setSelectedNodeId={setSelectedNodeId}
          setSelectedPodId={setSelectedPodId}
          setSelectedPod={setSelectedPod}
          selectedPod={selectedPod}
          setSelectInstanceGroupId={setSelectInstanceGroupId}
          setSelectVmInstanceId={setSelectVmInstanceId}
          clusterData={CombainAlerts(DataTrasform, clusterAlerts)}
          isClusterLoading={useClusterSideBarDataIsLoading}
          isNodeLoading={false}
          isPodLoading={usePodSideBarDataIsLoading}
          setVmGroupData={setVmGroupData}
          setVmData={setVmData}
          setVmProcessData={setVmProcessData}
          switchToNormalView={switchToNormalView}
          setSelectedView={setSelectedView}
          selectedview={selectedView}
          setSelectedInstanceGroup={setSelectedInstanceGroup}
          setSelectedInstance={setSelectedInstance}
          setSelectedCluster={setSelectedCluster}
          setApplicationView={setApplicationView}
          setParentSelected={setParentSelected}
          parentSelected={parentSelected}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          detailRef={detailRef}
          // setSelectedNamespaceId={setSelectedNamespaceId}
          setSelectedNamespace={setSelectedNamespace}
          setShowViewPolicy={setShowViewPolicy}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          range={range}
          setShowVMGraph={setShowVMGraph}
          setActiveClusterId={setActiveClusterId}
          setActiveNodeId={setActiveNodeId}
          setThroughCluster={setThroughCluster}
          MainEntity={mainEntity}
          setMainEntity={setMainEntity}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          backgroundColor: "#fff",
          width: "79%",
          marginRight: "10px",
          flexDirection: "column",
          height: "90vh"
        }}
      >
        <Box className="flex flex-col gap-3 m-3" sx={{ height: "20%" }}>
          <Box className="flex justify-between">
            <div>
              {selectedView === CLUSTER ||
              selectedView === NODE ||
              selectedView === POD ||
              selectedView === CONTAINER ? (
                <div className="flex items-center breadcrumb">
                  <p
                    onClick={() => {
                      setSelectedCluster({});
                      setSelectedNode({});
                      setSelectedPod({});
                      setSelectedOption("");
                      setParentSelected("");
                      setIsSelected("");
                      setThroughCluster(true);
                      setSelectedView(CLUSTER);
                      // clear the selected cluster when traversing back
                    }}
                    style={selectedView === CLUSTER ? { color: "" } : { color: "#001eba" }}
                    className={`${
                      selectedView === "Clusters"
                        ? "no-underline cursor-default text-base"
                        : "cursor-pointer hover:underline text-base"
                    }`}
                  >
                    Clusters
                  </p>
                  {throughCluster &&
                    (selectedView === NODE ||
                      selectedView === POD ||
                      selectedView === CONTAINER) && (
                      <>
                        <ArrowForwardIosIcon fill="#A6A8AA" fontSize="18" className="mx-2" />
                        <p
                          // onClick={selectedView === "cluster" ? () => {} : onClickNodesBC}
                          onClick={() => {
                            setSelectedNode({});
                            setSelectedPod({});
                            setSelectedOption("");
                            setIsSelected("");
                            setSelectedView(NODE);
                            // clear the selected cluster when traversing back
                          }}
                          className={`${
                            selectedView === "cluster"
                              ? "no-underline cursor-default text-base"
                              : "cursor-pointer hover:underline text-base"
                          }`}
                          style={selectedView === NODE ? { color: "" } : { color: "#001eba" }}
                        >
                          Nodes
                        </p>
                      </>
                    )}
                  {(selectedView === POD || selectedView === CONTAINER) && (
                    <>
                      <ArrowForwardIosIcon fill="#A6A8AA" fontSize="18" className="mx-2" />
                      <p
                        title="Pods"
                        variant="b2"
                        onClick={
                          () => {
                            setSelectedNamespace({});
                            setSelectedPod({});
                            setSelectedOption("");
                            setIsSelected("");
                            // setThroughCluster(true);
                            setSelectedView(POD);
                          }

                          // selectedView === POD
                          //   ? () => {}
                          //   : () => {
                          //       setSelectedNamespace({});
                          //       setSelectedPod({})
                          //     }
                        }
                        className={`${
                          selectedView === POD
                            ? "no-underline cursor-default text-base"
                            : "cursor-pointer hover:underline text-base"
                        }`}
                        style={selectedView === "node" ? { color: "" } : { color: "#001eba" }}
                      >
                        Pods
                      </p>
                    </>
                  )}
                  {selectedView === CONTAINER && (
                    <>
                      <ArrowForwardIosIcon fill="#A6A8AA" fontSize="18" className="mx-2" />
                      <p className="no-underline cursor-default text-base">Containers</p>
                      {/* <Typography title="Containers" variant="text-base" /> */}
                    </>
                  )}
                </div>
              ) : null}
            </div>
            <div className="w-1/5 flex justify-end items-center gap-[10px]">
              <DateRangePicker
                // activateDatePicker={false}
                onChange={onChangeRange}
                items={timeRange}
              />
              <RefreshInterval
                items={RefreshTime}
                setSelected={setRefreshTimeVal}
                defaultValue={{ label: refreshTimeVal, value: refreshTimeVal }}
                refreshIconClick={handleRefreshApi}
              />
              <Button
                className="w-full text-[12px] h-[45px]"
                onClick={() => navigate(routes.settingsManageClusterOnboard)}
              >
                Onboard Cluster
              </Button>
            </div>
          </Box>
          <Box className="flex w-full gap-2">
            <div className="w-full">
              {selectedView === POD && (
                <DynamicFilter
                  updateLocalDSL={updateLocalDSL}
                  dsl={localDSL}
                  onSelected={onSelectedFilter}
                  keys={["Namespace", "PodName"]}
                  type="PodView"
                  setPageId={() => {}}
                  podOption={filterUnSelected(filterValuesForPod?.podName, logdDslTag)}
                  namespaceOption={filterUnSelected(
                    filterValuesForNamespaceName?.NamespaceName,
                    logdDslTag
                  )}
                />
              )}
            </div>
            <div className={`w-1/4 ${styles.toggleBtn}`}>
              <ToggleView isListView={isListView} actions={toggleActions} />
            </div>
          </Box>
        </Box>
        {isListView ? (
          <>
            {selectedView === CLUSTER && (
              <Table
                getRowId={isTableEntityDetailLoading ? listofCluster?.ID : combineCluster?.ID}
                apiRef={apiRef}
                loading={ListofClusterisLLoading}
                checkboxVisible={false}
                columns={CLUSTER_COLUMNS(
                  setSelectedView,
                  setSelectedCluster,
                  setSelectedPod,
                  setParentSelected,
                  toggleActions,
                  isTableEntityDetailLoading,
                  setHighlightEntity,
                  setThroughCluster
                )}
                data={isTableEntityDetailLoading ? listofCluster : combineCluster}
                page={page || 0}
                pageSize={10}
                // rowCount={CLUSTERS_DATA?.length}
                onCellClick={(p, e) => onCellClick(p, e)}
                components={{
                  Pagination: CustomPagination
                }}
                componentsProps={{
                  pagination: {
                    count: FindTotalPage(listofCluster?.length, 10),
                    onChange: (event, value) => {
                      setPage(value - 1);
                    },
                    page: page + 1,
                    totalCount: listofCluster?.length || 0
                  }
                }}
              />
            )}
            {selectedView === NODE && (
              <Table
                apiRef={apiRef}
                loading={ListofNodeisLLoading}
                checkboxVisible={false}
                columns={NODE_COLUMNS(
                  setSelectedView,
                  setSelectedNode,
                  setSelectedPod,
                  toggleActions,
                  false,
                  setHighlightEntity
                )}
                data={listofNode}
                pageSize={10}
                onCellClick={(p, e) => onCellClick(p, e)}
                components={{
                  Pagination: CustomPagination
                }}
                componentsProps={{
                  pagination: {
                    count: FindTotalPage(listofNode?.length, 10),
                    onChange: (event, value) => {
                      setPage(value - 1);
                    },
                    page: page + 1,
                    totalCount: listofNode?.length || 0
                  }
                }}
              />
            )}
            {selectedView === POD && (
              <Table
                // rowCount={podData?.length}
                apiRef={apiRef}
                loading={ListofPodisLLoading}
                checkboxVisible={false}
                columns={POD_COLUMNS(
                  setSelectedView,
                  setSelectedPod,
                  toggleActions,
                  isTableEntityDetailLoading,
                  setHighlightEntity,
                  setShowSecurityPosture,
                  setXPos,
                  setYPos,
                  setShowContextMenuAbove,
                  setNamespaceData
                )}
                data={combinePod}
                pageSize={10}
                page={page}
                // onPageChange={newPage => setPage(newPage)}
                // handlePageChange={handlePageChange}
                onCellClick={(p, e) => onCellClick(p, e)}
                components={{
                  Pagination: CustomPagination
                }}
                componentsProps={{
                  pagination: {
                    count: FindTotalPage(podData?.length, 10),
                    onChange: (event, value) => {
                      setPage(value - 1);
                    },
                    page: page + 1,
                    totalCount: podData?.length || 0
                  }
                }}
              />
            )}
            {selectedView === CONTAINER && (
              <Table
                apiRef={apiRef}
                checkboxVisible={false}
                loading={ListofContainerisLoading}
                columns={CONTAINER_COLUMNS(toggleActions, setHighlightEntity)}
                data={listofContainer}
                page={page || 0}
                pageSize={10}
                // rowCount={CONTAINER_DATA?.length}
                onCellClick={(p, e) => onCellClick(p, e)}
                components={{
                  Pagination: CustomPagination
                }}
                componentsProps={{
                  pagination: {
                    count: FindTotalPage(listofContainer?.length, 10),
                    onChange: (event, value) => {
                      setPage(value - 1);
                    },
                    page: page + 1,
                    totalCount: listofContainer?.length || 0
                  }
                }}
              />
            )}
          </>
        ) : (
          <>
            {(selectedView === CLUSTER && ListofClusterisLLoading) ||
            (selectedView === POD && ListofPodisLLoading) ||
            (selectedView === NODE && ListofNodeisLLoading) ||
            (selectedView === CONTAINER && ListofContainerisLoading) ? (
              <div className="flex items-center justify-center w-full h-full">
                <Loader />
              </div>
            ) : !graphData.length ? (
              <EmptyClusterData type={selectedView} />
            ) : (
              <Box sx={{ height: "73%", marginTop: "1%" }}>
                <VisGraph
                  selectedView={selectedView}
                  setSelectedView={setSelectedView}
                  data={graphData}
                  entityType={entityType}
                  setSelectedInstanceGroup={setSelectedInstanceGroup}
                  setSelectedInstance={setSelectedInstance}
                  // setSelectedClusterId={setSelectedClusterId}
                  setSelectedNamespaceId={setSelectedNamespaceId}
                  setSelectedNamespace={setSelectedNamespace}
                  setSelectedCluster={setSelectedCluster}
                  // setSelectedNodeId={setSelectedNodeId}
                  onClickNode={onClickOfEntity}
                  highLightEntity={highLightEntity}
                  setXPos={setXPos}
                  setYPos={setYPos}
                  showContextMenu={showClusterRightMenu}
                  setShowContextMenu={setShowClusterRightMenu}
                  setShowContextMenuAbove={setShowContextMenuAbove}
                  setShowContextMenuLeft={setShowContextMenuLeft}
                  setRightClickEntity={setRightClickEntity}
                  setIsEntityDetailLoading={setIsEntityDetailLoading}
                  isEntityDetailLoading={isEntityDetailLoading}
                  entitySelectedDetail={entitySelectedDetail}
                  rightClickEntity={rightClickEntity}
                  setSelectedNode={setSelectedNode}
                  setSelectedPod={setSelectedPod}
                  setHighlightEntity={setHighlightEntity}
                  setShowSecurityPosture={setShowSecurityPosture}
                  setNamespaceData={setNamespaceData}
                  selectedCluster={selectedCluster}
                  range={range}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </div>
  );
}

export default ClusterManagement;
