import React from "react";
import PropTypes from "prop-types";

export default function AddToGroup(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 15H4.43C2.14 15 1 13.86 1 11.57L1 4.43C1 2.14 2.14 1 4.43 1H9C11.29 1 12.43 2.14 12.43 4.43M13.87 14H17.13M15.5 15.6301V12.3701M17.5699 21H12.9999C10.7099 21 9.56995 19.86 9.56995 17.57V10.43C9.56995 8.14 10.7099 7 12.9999 7H17.5699C19.8599 7 20.9999 8.14 20.9999 10.43V17.57C20.9999 19.86 19.8599 21 17.5699 21Z"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

AddToGroup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

AddToGroup.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 22,
  height: 22
};
