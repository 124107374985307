import React, { useState, useEffect } from "react";
// import { styled } from "@mui/material/styles";
import styled from "@emotion/styled";
import { Divider, List, ListItem, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import Title from "../Title";
import CodeBlock from "../CodeBlock";
import styles from "./styles.module.scss";
import Button from "../Button";
import ContentContainer from "../ContentContainer";
import { navigate } from "helper/history";
import { routes } from "router";
import Modal from "../Modal";
import { useLocation } from "react-router-dom";
import { getAccuknoxWorkspace } from "api/api";

const Container = styled("section")(({ theme }) => ({
  display: "flex",
  width: "100%"
}));
const Navbar = styled("div")(({ theme }) => ({
  width: "25%",
  height: "100%",
  position: "sticky",
  top: 0,
  left: 0
}));
const NavbarHeading = styled("h3")(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: "25px"
}));
const NavbarItemsWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  width: "100%"
}));
const NavbarItem = styled("div")(({ theme, state }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.h4,
  padding: "1rem",
  marginRight: "0.5rem",
  cursor: "pointer",
  borderRadius: "0.375rem",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "transparent",
  ...(state.active && {
    backgroundColor: "#E2EAF5",
    borderColor: "#82B4FF"
  })
}));
const ContentSection = styled("div")(({ theme }) => ({
  display: "grid",
  marginTop: "1rem",
  width: "100%",
  maxHeight: "calc(100vh - 200px)",
  overflowX: "scroll",
  borderLeft: "1px solid rgba(0,0,0,0.4)"
}));

const Scrollspy = ({ navbarHeading }) => {
  const [activeItem, setActiveItem] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const workspaceId = getAccuknoxWorkspace();
  const location = useLocation();

  const { state } = location;

  const item1 = useInView({
    // rootMargin: "0px 0px -40% 0px",
    threshold: 0.5,
    onChange: inView => inView && setActiveItem(1)
  });

  const item2 = useInView({
    // rootMargin: "0px 0px -40% 0px",
    threshold: 0.5,
    onChange: inView => inView && setActiveItem(2)
  });

  const handleNavbarItemClick = (e, item) => {
    e.stopPropagation();
    setActiveItem(item.id);
    item.observer?.entry?.target?.scrollIntoView({ behavior: "smooth" });
  };

  const confirmHandler = () => {
    navigate(routes.settingsManageClusterOnboard);
  };

  const NavbarItemsHeader = [
    {
      id: 1,
      name: "KubeArmor",
      observer: item1
    },
    {
      id: 2,
      name: "Accuknox Agents",
      observer: item2
    }
  ];

  const installCode = () => {
    let defaultInstructions = `
    helm repo add ${window.__env__.REACT_APP_HELM_REPO_NAME} ${
      window.__env__.REACT_APP_HELM_REPO_URL
    }
    helm repo update
    helm upgrade --install agents-operator ${
      window.__env__.REACT_APP_HELM_REPO_NAME
    }/agents-operator \\
      --set props.tenant_id="${workspaceId}" \\
      --set props.workspace_id="${workspaceId}" \\
      --set props.cluster_name="${state?.clusterName || state?.ClusterName}" \\
      --set props.CLUSTER_NAME="${state?.clusterName || state?.ClusterName}" \\
      --set props.cluster_id="${state?.clusterId || state?.ID}" \\
      --set props.helm_repo="${window.__env__.REACT_APP_HELM_REPO_NAME}" \\
      --set props.helm_repo_url="${window.__env__.REACT_APP_HELM_REPO_URL}" \\
      --set props.docker_repo_host="${window.__env__.REACT_APP_DOCKER_REPO_HOST}" \\
      --set props.docker_repo_username="${window.__env__.REACT_APP_DOCKER_REPO_USERNAME}" \\
      --set props.docker_repo_password="${window.__env__.REACT_APP_DOCKER_REPO_PASSWORD}" \\`;

    if (window.__env__.REACT_APP_ON_PREM) {
      defaultInstructions += `
      --set props.knoxGateway="${window.__env__.REACT_APP_KNOX_GATEWAY}" \\
      --set props.cwpp="${window.__env__.REACT_APP_CWPP_URL.replace("https://", "")}" \\`;
    }

    defaultInstructions += `
      --create-namespace -n ${window.__env__.REACT_APP_DOCKER_NAME_SPACE}`;
    return defaultInstructions;
  };

  const NavbarItems = [
    {
      id: 1,
      name: "KubeArmor",
      observer: item1,
      installation: "Installation Guide",
      description: "Description",
      descriptionText: "This agent is used to apply system level policies",
      download: "Download and install karmor CLI",
      downloadCode: `curl -sfL http://get.kubearmor.io/ | sudo sh -s -- -b /usr/local/bin`,
      install: "Install KubeArmor",
      installCode: "karmor install"
    },
    {
      id: 2,
      name: "Accuknox Agents",
      observer: item2,
      installation: "Installation Guide",
      description: "Description",
      descriptionText: `The AccuKnox agent is a K8s operator that installs the following agents:`,
      descriptionText1: "- Feeder-service: It collects KubeArmor feeds.",
      descriptionText2:
        "- Shared-informer-agent: This agent authenticates with your cluster and collects information regarding entities like nodes, pods, and namespaces.",
      descriptionText3:
        "- Policy-enforcement-agent: This agent authenticates with your cluster and enforces labels and policies.",
      descriptionText4:
        "- Discovery Engine: Discovery Engine discovers the security posture for your workloads and auto-discovers the policy set required to put the workload in least-permissive mode. The engine leverages the rich visibility provided by KubeArmor to auto discover systems and network security postures.",
      descriptionText5:
        "The agent-operator also manages the agents' resource limits. The operator is in charge of spawning the agents based on the size of the cluster. If the cluster size changes, i.e., new nodes are added or existing nodes are deleted, then the operator scales up or down the resources accordingly.",

      installCode: installCode()
    }
  ];

  return (
    <ContentContainer className={styles.container}>
      <Container>
        <Navbar>
          <NavbarHeading>
            <Title>List of Agents</Title>
          </NavbarHeading>
          <Divider sx={{ marginBottom: "10px" }} />
          <NavbarItemsWrapper>
            {NavbarItemsHeader?.map((item, index) => (
              <NavbarItem
                key={index}
                state={{ active: activeItem === item.id }}
                onClick={e => handleNavbarItemClick(e, item)}
              >
                {item.name}
              </NavbarItem>
            ))}
          </NavbarItemsWrapper>
        </Navbar>
        <ContentSection>
          <ContentWrapper>
            {/* <Title>Pre-requisites</Title>
            <SubContentContainer>
              <Typography variant="body1" component="p">
                Create Namespace
              </Typography>
              <CodeBlock text="kubectl create namespace accuknox-agents" />
            </SubContentContainer>
            <SubContentContainer>
              <Title>Adding AccuKnox Helm repository</Title>
              <Typography variant="body1" component="p">
                Required incase of installing by Helm
              </Typography>
            </SubContentContainer>
            <Divider sx={{ margin: "12px 0 12px 0", width: "100%" }} />

            <SubContentContainer>
              <Typography variant="body1" component="p">
                Add AccuKnox repository to install agents helm package
              </Typography>
              <CodeBlock text="helm repo add accuknox-agents-dev  https://accuknox-agents-dev:h47Sh4taEs@agents.accuknox.com/repository/accuknox-agents-dev " />
            </SubContentContainer>
            <SubContentContainer>
              <Typography variant="body1" component="p">
                Once repository added successfully, update the helm repository
              </Typography>
              <CodeBlock text="helm repo update" />
            </SubContentContainer> */}
            {NavbarItems?.map((item, index) => (
              <div key={index} style={{ width: "100%" }} ref={item.observer.ref}>
                <ScrollSpyDetails>
                  <Title>
                    {item.id}. {item.name}
                  </Title>
                  <Typography variant="body1" component="p" sx={{ fontWeight: "600" }}>
                    {item.installation}
                  </Typography>
                  <SubContentContainer>
                    <Typography variant="subtitle1" component="p" sx={{ fontWeight: "600" }}>
                      {item.description}
                    </Typography>
                    <Typography variant="body1" component="p" sx={{ width: "100%" }}>
                      {item.descriptionText}
                    </Typography>
                    {item.descriptionText1 && (
                      <>
                        <List
                          sx={{
                            listStyleType: "disc",
                            // pl: 2,
                            marginBottom: "-7px",
                            marginTop: "-11px"
                          }}
                        >
                          <ListItem
                            sx={{
                              display: "list-item"
                            }}
                          >
                            {item?.descriptionText1}
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            {item?.descriptionText2}
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            {item?.descriptionText3}
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                            {item?.descriptionText4}
                          </ListItem>
                        </List>

                        <Typography variant="body1" component="li" sx={{ width: "100%" }}>
                          {item.descriptionText5}
                        </Typography>
                      </>
                    )}
                  </SubContentContainer>
                  {item?.download && (
                    <SubContentContainer>
                      <Typography variant="subtitle1" component="p" sx={{ fontWeight: "600" }}>
                        {item?.download}
                      </Typography>
                      <CodeBlock text={item?.downloadCode} />
                    </SubContentContainer>
                  )}
                  <SubContentContainer>
                    <Typography variant="subtitle1" component="p" sx={{ fontWeight: "600" }}>
                      {item?.install}
                    </Typography>
                    <CodeBlock text={item.installCode} />
                  </SubContentContainer>
                </ScrollSpyDetails>
              </div>
            ))}
          </ContentWrapper>
        </ContentSection>

        {/* <Divider orientation="vertical" /> */}
      </Container>
      <ScrollSpyBtn>
        <Modal isOpen={isModalOpen} width="100%" toggle={() => setIsModalOpen(false)}>
          <div>
            <h2>Confirmation</h2>
            <hr />
          </div>
          <div>
            <p>All your changes will be lost. Are you sure you want to go back?</p>
          </div>
          <BtnContainer>
            <Button className="clusterButtonBack" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button onClick={confirmHandler}>Confirm</Button>
          </BtnContainer>
        </Modal>
        <Button onClick={() => setIsModalOpen(!isModalOpen)} className="clusterButtonBack">
          Back
        </Button>
        <Button
          onClick={() => {
            navigate(routes.clusterManagement);
          }}
        >
          Next
        </Button>
      </ScrollSpyBtn>
    </ContentContainer>
  );
};

export default Scrollspy;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  width: 100%;
`;

const SubContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  margin-top: 18px;
  width: 80%;
`;

const ScrollSpyDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-left: 20px;
`;
const ScrollSpyBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 30px;
  gap: 10px;
  margin-right: 3rem;

  .clusterButtonBack {
    height: 38px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: #fff;
    padding: 10px 20px 12px;
    width: auto;
    color: black;
    margin-left: 4rem;
  }

  .clusterButtonBack:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;

  .clusterButtonBack {
    height: 38px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: #fff;
    padding: 10px 20px 12px;
    width: auto;
    color: black;
  }

  .clusterButtonBack:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
