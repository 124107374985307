/* eslint-disable camelcase */
import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { addNotification } from "store/entities/notifications/actions";

import {
  postVulnerabilityConfigs,
  editVulnerabilityConfigs
} from "store/entities/vulnerabilities/actions";

import { vulnerabilityConfigsFiltersDataSelector } from "store/entities/vulnerabilities/selectors";

import { ActionButton, Button, Tags, Input } from "components/simple";
import { TargetsSelect } from "helper/AsyncSelects";

import styles from "./styles.module.scss";

const PanelCreateConfig = ({
  onLoadData,
  onCollapse,
  collapse,
  editData,
  state,
  setState,
  onClose,
  id,
  asset,
  data_type
}) => {
  const dispatch = useDispatch();

  const { filter_fields_list, display_fields_list } = useSelector(
    vulnerabilityConfigsFiltersDataSelector
  );

  useEffect(() => {
    if (editData) {
      const target = editData?.target?.id
        ? {
            value: editData?.target?.id,
            label: editData?.target?.name
          }
        : editData?.target;

      const filter_fields = editData?.filter_fields?.map(item => {
        return filter_fields_list?.find(t => t.value === item);
      });
      const display_fields =
        editData?.display_fields?.map(item => {
          return display_fields_list?.find(t => t.value === item);
        }) ?? [];
      const name = editData?.name;

      const res = {
        name,
        target,
        filter_fields,
        display_fields,
        display_fields_width: editData?.display_fields_width ?? []
      };
      setState(res);
    }
  }, [editData]);

  const onChangeSelect = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  const saveConfig = () => {
    if (!state?.name) {
      dispatch(addNotification({ msg: "You must fill in the required fields", type: "warning" }));
      return;
    }

    const res = {
      name: state.name,
      filter_fields: state?.filter_fields?.map(item => item.value),
      display_fields: state?.display_fields?.map(item => item.value),
      target: state?.target || "",
      asset: asset || "",
      data_type: data_type || "",
      table_properties: {
        display_fields_width: state?.display_fields_width ?? []
      }
    };

    if (id) {
      dispatch(
        editVulnerabilityConfigs({
          id,
          ...res
        })
      );
      const { table_properties, ...updRes } = res;
      onLoadData({ ...updRes, display_fields_width: state?.display_fields_width ?? [] });
    } else {
      dispatch(postVulnerabilityConfigs(res));
      onCollapse(false);
    }
  };

  const loadData = () => {
    const res = {
      name: state?.name,
      filter_fields: state?.filter_fields?.map(item => item.value),
      display_fields: state?.display_fields?.map(item => item.value),
      target: state?.target || "",
      display_fields_width: state?.display_fields_width ?? []
    };
    onLoadData(res);
  };

  return (
    <>
      {collapse ? (
        <div className={styles.panelContent}>
          <ActionButton
            type="save"
            text="Save config"
            onClick={() => saveConfig()}
            className={styles.saveConfigButton}
          />
          <div className={styles.panelGrid}>
            <Input
              type="text"
              placeholder="Name"
              label="Name"
              required="true"
              value={state.name}
              onChange={val => onChangeSelect(val.target.value, "name")}
              className={styles.input}
            />
            <TargetsSelect
              containerClass={styles.selectPanel}
              onChange={v => onChangeSelect(v, "target")}
              value={state.target}
              placeholder="Target"
              label="Target"
              isClearable
              cacheOptions={false}
            />
          </div>

          <div className={styles.panelGrid}>
            <Tags
              label="Select fields to filter"
              values={state.filter_fields}
              list={filter_fields_list}
              onChange={v => onChangeSelect(v, "filter_fields")}
            />
            <Tags
              label="Select fields to display"
              values={state.display_fields || []}
              list={display_fields_list}
              onChange={v => onChangeSelect(v, "display_fields")}
            />
            <div />
          </div>

          <div className={styles.buttonGroup}>
            <Button className={styles.button} icon="plus" onClick={() => onCollapse(false)}>
              Collapse
            </Button>
            <Button
              className={styles.button}
              icon="plus"
              variant="outline"
              onClick={() => loadData()}
            >
              Load data
            </Button>

            <Button className={`${styles.button} ${styles.right}`} onClick={() => onClose()}>
              Reset
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

PanelCreateConfig.propTypes = {};

export default memo(PanelCreateConfig);
