import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  page: 0,
  pageSize: 20,
  search: "",
  filterBy: "",
  type: null,
  tag: null,
  ordering: "",

  targetsMini: null,
  targetTypes: [],
  targetTags: {},
  scheduleData: {},
  groups: {},
  assets: {},
  targets: {},
  target: {},
  playbooks: {},
  playbookConfigs: {},
  minions: {},
  variables: {},
  variableOptions: {},
  apiToken: null,
  tokenList: {},

  createTargetModal: { show: false },
  addToGroupModal: { show: false, selectList: [], newGroup: false },
  deleteTargetModal: { show: false, deleteList: [], navigateBack: false },
  createTokenModal: { show: false, showCreateBtn: true, data: {} },
  deleteTokenModal: { show: false, tokenID: null }
};

const reducer = createReducer(
  initialState,
  {
    [actions.setTargetsParams.type]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [actions.getTargets.type]: state => ({
      ...state,
      target: {}
    }),
    [actions.getTargetsMiniSuccess.type]: (state, action) => ({
      ...state,
      targetsMini: action.payload
    }),

    [actions.getTargetsSuccess.type]: (state, action) => ({
      ...state,
      targets: action.payload
    }),

    [actions.getTargetSuccess.type]: (state, action) => ({
      ...state,
      target: action.payload
    }),

    [actions.getTargetTypesSuccess.type]: (state, action) => ({
      ...state,
      targetTypes: action.payload
    }),

    [actions.getTargetTagsSuccess.type]: (state, action) => ({
      ...state,
      targetTags: action.payload
    }),

    [actions.getScheduleDataSuccess.type]: (state, action) => ({
      ...state,
      scheduleData: action.payload
    }),

    [actions.getTargetGroupsSuccess.type]: (state, action) => ({
      ...state,
      groups: action.payload
    }),

    [actions.getAssetsSuccess.type]: (state, action) => ({
      ...state,
      assets: action.payload
    }),

    [actions.getPlaybooksSuccess.type]: (state, action) => ({
      ...state,
      playbooks: action.payload
    }),

    [actions.getPlaybookConfigsSuccess.type]: (state, action) => ({
      ...state,
      playbookConfigs: action.payload
    }),

    [actions.resetPlaybookConfigs.type]: (state, action) => ({
      ...state,
      playbookConfigs: action.payload
    }),

    [actions.getMinionsSuccess.type]: (state, action) => ({
      ...state,
      minions: action.payload
    }),

    [actions.getExpectedVariablesSuccess.type]: (state, action) => ({
      ...state,
      variables: action.payload
    }),

    [actions.resetExpectedVariables.type]: (state, action) => ({
      ...state,
      variables: action.payload
    }),

    [actions.getVariablesMiniSuccess.type]: (state, action) => ({
      ...state,
      variableOptions: action.payload
    }),

    [actions.getApiTokenListsSuccess.type]: (state, action) => ({
      ...state,
      tokenList: action.payload
    }),

    [actions.generateApiTokenSuccess.type]: (state, action) => ({
      ...state,
      apiToken: action.payload
    }),

    [actions.deleteApiTokenSuccess.type]: (state, action) => ({
      ...state,
      apiToken: null
    }),

    [actions.resetApiToken.type]: (state, action) => ({
      ...state,
      apiToken: action.payload
    }),

    [actions.actionCreateTargetModal.type]: (state, action) => ({
      ...state,
      createTargetModal: action.payload
    }),

    [actions.actionAddToGroupModal.type]: (state, action) => ({
      ...state,
      addToGroupModal: action.payload
    }),

    [actions.actionDeleteTargetModal.type]: (state, action) => ({
      ...state,
      deleteTargetModal: action.payload
    }),

    [actions.actionCreateTokenModal.type]: (state, action) => ({
      ...state,
      createTokenModal: action.payload
    }),

    [actions.actionDeleteTokenModal.type]: (state, action) => ({
      ...state,
      deleteTokenModal: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
