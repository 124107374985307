import jsyaml from "js-yaml";
import {
  DEFAULT_POLICY_TEMPLATE,
  CIDR_REGEX,
  PORT_REGEX,
  KIND_OPTIONS,
  DEFAULT,
  K8S,
  INGRESS,
  EGRESS,
  IP_BLOCK,
  POD_SELECTOR,
  NAMESPACE_SELECTOR
} from "./constants";

export const JsonToYaml = jsonObject => {
  return jsyaml.dump(jsonObject, {
    noArrayIndent: true
  });
};
export const parseYamlErrors = yamlDoc => {
  try {
    jsyaml.load(yamlDoc);
    return [];
  } catch (e) {
    return [e];
  }
};

export const transformPolicyJSON = policyJSON => {
  let isValidYAML;
  let isCreatingPolicy;

  const policyYAML = JsonToYaml(policyJSON);

  const yamlErrors = parseYamlErrors(policyYAML);
  if (yamlErrors?.length) {
    isValidYAML = false;
  } else {
    isValidYAML = true;
  }

  if (JSON.stringify(policyJSON) === JSON.stringify(DEFAULT_POLICY_TEMPLATE)) {
    isCreatingPolicy = false;
  } else {
    isCreatingPolicy = true;
  }

  return { policyYAML, isValidYAML, isCreatingPolicy };
};

export const YamlToJson = yamlDoc => {
  try {
    return jsyaml.load(yamlDoc);
  } catch (error) {
    // console.log(error)
  }
};

export const getRandomId = () => {
  return Math.floor(Math.random() * 100000000);
};

export const transformPolicyName = value => {
  return value?.replaceAll(" ", "-")?.toLowerCase();
};

export const parseMetadataValues = (policyJSON, currentPolicy) => {
  const formValues = {};

  // Setting policy name
  formValues.name = policyJSON.metadata?.name;

  // Setting labels
  if (currentPolicy?.label?.length) {
    const labels = currentPolicy?.label?.map(label => ({
      id: label?.id,
      name: label?.name,
      value: label?.value,
      label: `${label?.name}=${label?.value}`
    }));
    formValues.selectorLabel = labels;
  }

  // setting cluster
  if (currentPolicy?.cluster?.id) {
    formValues.cluster = {
      id: currentPolicy?.cluster?.id,
      label: currentPolicy?.cluster?.name,
      value: currentPolicy?.cluster?.name
    };
  }

  // setting namespace
  if (currentPolicy?.namespace?.id) {
    formValues.namespace = {
      id: currentPolicy?.namespace?.id,
      label: currentPolicy?.namespace?.name,
      value: currentPolicy?.namespace?.name
    };
  }

  return formValues;
};

// TODO:
export const generateStorePolicyRequestBody = ({
  formValues,
  unknownPolicyValues,
  policyJSON,
  policyYAML,
  selectedCluster,
  isUpdatingPolicy,
  userId,
  workspaceId
}) => {
  let reqBody = {};
  let namespace = {};
  let labels = [{ ids: [] }];
  let tags = [{ ids: [] }];

  if (formValues?.namespace) {
    if (formValues?.namespace?.id) {
      namespace = { id: formValues?.namespace?.id };
    } else {
      namespace = { new: { name: formValues?.namespace?.value } };
    }
  }

  if (formValues?.selectorLabel?.length) {
    if (isUpdatingPolicy) {
      labels = [{ ids: formValues?.selectorLabel?.map(label => label?.id) }];
    } else {
      // eslint-disable-next-line no-lonely-if
      if (unknownPolicyValues?.labels?.length) {
        const newlyAddedLabels = formValues?.selectorLabel?.filter(label =>
          unknownPolicyValues?.labels?.every(unknwn => unknwn?.id !== label?.id)
        );
        const knownLabelsIds = newlyAddedLabels?.map(label => label?.id);
        const unknownLabels = unknownPolicyValues?.labels?.map(label => ({
          name: label?.name,
          value: label?.value
        }));
        labels = [{ ids: knownLabelsIds }, { new: unknownLabels }];
      } else {
        labels = [{ ids: formValues?.selectorLabel?.map(label => label?.id) }];
      }
    }
  }

  reqBody = {
    name: policyJSON?.metadata?.name,
    workspace_id: workspaceId,
    domain_id: 1,
    user_id: userId,
    policy_content: policyYAML,
    label: labels,
    label_type: DEFAULT,
    kind: policyJSON?.kind
  };

  reqBody = {
    ...reqBody,
    type: K8S,
    cluster_id: selectedCluster?.id,
    namespace
  };

  return reqBody;
};

export const preProcessUploadedYAML = policyJSON => {
  const updatedPolicyJSON = { ...policyJSON };
  const metadataFormValues = {};
  const unknownPolicyValues = {};

  const { ipBlockRuleData, podSelectorRuleData, namespaceSelectorRuleData } = getRulesData(
    updatedPolicyJSON
  );

  if (policyJSON?.metadata?.name) {
    const policyName = transformPolicyName(policyJSON?.metadata?.name);
    metadataFormValues.name = policyName;
  }

  if (policyJSON?.metadata?.namespace) {
    const namespace = {
      label: policyJSON?.metadata?.namespace,
      value: policyJSON?.metadata?.namespace
    };
    metadataFormValues.namespace = namespace;
    unknownPolicyValues.namespace = namespace;
  }

  const matchLabels = policyJSON?.spec?.podSelector?.matchLabels;

  if (Object.keys(matchLabels)?.length) {
    const labels = Object.entries(matchLabels)?.map(([name, value]) => ({
      id: getRandomId(),
      label: `${name}=${value}`,
      name,
      value
    }));

    metadataFormValues.selectorLabel = labels;
    unknownPolicyValues.labels = labels;
  }

  return {
    updatedPolicyJSON,
    metadataFormValues,
    unknownPolicyValues,
    ipBlockRuleData,
    podSelectorRuleData,
    namespaceSelectorRuleData
  };
};

export const validateExcept = (value, setError) => {
  if (value) {
    if (CIDR_REGEX.test(value)) {
      return true;
    }
    setError("except", {
      type: "validate",
      message: "Please enter a valid except path"
    });
    return false;
  }

  return true;
};

export const validatePort = (value, setError) => {
  if (value) {
    if (PORT_REGEX.test(value)) {
      return true;
    }
    setError("ports", {
      type: "validate",
      message: "Please enter a valid port"
    });
    return false;
  }
  return true;
};

export const createSelectOption = (value, existingValues) => {
  const alreadyExists = existingValues?.find(val => val?.value === value);
  if (!alreadyExists) {
    return {
      label: value,
      value
    };
  }
};

export const getRulesData = policyJSON => {
  const transformRules = (rules, direction, type) => {
    const result = [];
    for (const rule of rules[direction]) {
      const fromOrTo = rule[direction === INGRESS ? "from" : "to"];
      if (!fromOrTo) continue;

      for (const item of fromOrTo) {
        let object;
        if (type === IP_BLOCK) {
          if (item[type]?.cidr) {
            object = { cidr: item?.[type]?.cidr };
            if (item[type]?.except?.length) {
              object = { ...object, except: item[type]?.except };
            }
          }
        } else if (item[type]) {
          if (item[type]?.matchLabels) {
            object = { matchLabels: item[type]?.matchLabels };
          } else {
            object = {};
          }
        }

        if (object) {
          const ports =
            item?.ports?.map(p => ({
              protocol: p?.protocol || "TCP", // Default protocol to 'TCP' if missing
              port: p?.port
            })) || [];

          if (ports?.length) {
            result.push({
              [type]: object,
              ports
            });
          } else {
            result.push({
              [type]: object
            });
          }
        }
      }
    }
    return result;
  };

  const ipBlockRuleData = {
    ingress: transformRules(policyJSON?.spec, INGRESS, IP_BLOCK),
    egress: transformRules(policyJSON?.spec, EGRESS, IP_BLOCK)
  };
  const podSelectorRuleData = {
    ingress: transformRules(policyJSON?.spec, INGRESS, POD_SELECTOR),
    egress: transformRules(policyJSON?.spec, EGRESS, POD_SELECTOR)
  };
  const namespaceSelectorRuleData = {
    ingress: transformRules(policyJSON?.spec, INGRESS, NAMESPACE_SELECTOR),
    egress: transformRules(policyJSON?.spec, EGRESS, NAMESPACE_SELECTOR)
  };

  return {
    ipBlockRuleData,
    podSelectorRuleData,
    namespaceSelectorRuleData
  };
};
