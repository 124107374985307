import React, { memo, useEffect, useState } from "react";

import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Table } from "components/complex";
import { ActionButton, Search, Select, Title } from "components/simple";
import { formatDate } from "helper/DateLib";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getDataListByAsset } from "store/entities/assets/actions";
import { getDataListByAssetSelector } from "store/entities/assets/selectors";
import {
  actionGroupModal,
  actionTargetModal,
  exportDataList,
  getDataListConfigsFiltersData
} from "store/entities/datalist/actions";
import { createLoadingSelector } from "store/entities/loading/selector";
import { addNotification } from "store/entities/notifications/actions";
import { routes } from "router";
import { getTicketsConfig } from "store/entities/tickets/actions";
import {
  datalistConfigsFiltersDataSelector,
  getTicketsConfigSelect
} from "store/entities/datalist/selectors";

import styles from "./styles.module.scss";
import { CreateTargetModal } from "screens/Inventory/Datalist/CreateTargetModal";
import { CreateGroupModal } from "screens/Inventory/Datalist/CreateGroupModal";

const actionButtons = ["addToGroup", "createTicket", "addToTarget", "export", "monitor"];

const customStyles = {
  height: 300,
  width: 1,
  "& .super-app-theme--header": {
    backgroundColor: "#fff",
    borderBottom: "1px solid #E7E6F8"
  },
  "& .super-app-theme--row": {
    backgroundColor: "#fff",
    marginBottom: "0px"
  }
};

const columns = [
  {
    headerName: "Last seen",
    field: "last_seen",
    resizable: true,
    minWidth: 180,
    renderCell: params => <div>{formatDate(params.value)}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Data Type",
    field: "data_type",
    resizable: true,
    minWidth: 180,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Tickets",
    field: "tickets_count",
    resizable: true,
    minWidth: 180,
    renderCell: params => <div>{params.value || 0}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "OS",
    field: "data__os",
    resizable: true,
    minWidth: 360,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Monitors",
    field: "monitors",
    resizable: true,
    minWidth: 150,
    renderCell: params => <div>{params.value || 0}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  }
];

const Explorer = () => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const { id: assetId } = useParams();
  const history = useHistory();

  const [tableState, setTableState] = useState({
    page: 0,
    pageSize: 20,
    ordering: "",
    search: ""
  });
  const [selectionModel, setSelectionModel] = useState([]);
  const [filterByDataType, setFilterByDataType] = useState(null);
  const [ticketConfig, setTicketConfig] = useState(null);

  const dataList = useSelector(state => getDataListByAssetSelector(state));
  const ticketsConfigList = useSelector(getTicketsConfigSelect);
  const { data_types_list } = useSelector(datalistConfigsFiltersDataSelector);
  const isLoading = useSelector(state => createLoadingSelector([getDataListByAsset.type])(state));

  const handleSearch = v => {
    setTableState(prevState => ({
      ...prevState,
      search: v
    }));
  };

  const setPageSize = v => {
    setTableState(prevState => ({
      ...prevState,
      pageSize: v
    }));
  };

  const onPageChange = newPage => {
    setTableState(prevState => ({
      ...prevState,
      page: newPage
    }));
  };

  const handleAction = type => {
    if (type === "createTicket" && !ticketConfig?.value) {
      dispatch(addNotification({ msg: "You need to select ticket config", type: "warning" }));
      return;
    }
    if (!selectionModel?.length) {
      dispatch(addNotification({ msg: "You must select at least one row", type: "warning" }));
      return;
    }

    const valid = () => {
      if (selectionModel.length !== 1) {
        dispatch(addNotification({ msg: "You need to select just one row", type: "warning" }));
        return false;
      }
      return true;
    };

    switch (type) {
      case "export":
        dispatch(exportDataList({ ids: selectionModel }));
        break;
      case "monitor":
        if (valid()) {
          const selectedData = dataList.data.find(item => item.id === selectionModel[0]);
          const dataType = selectedData?.data_type;
          history.push(`${routes.monitorsConfiguration}?data_type=${dataType}`);
        }
        break;
      case "addToGroup":
        dispatch(actionGroupModal({ show: true, data: selectionModel }));
        break;
      case "addToTarget":
        dispatch(actionTargetModal({ show: true, data: selectionModel }));
        break;
      case "createTicket": {
        history.push(
          `${routes.dataStructureDatalistCreateTicket}/${
            ticketConfig.value
          }?ids=${selectionModel.join(",")}`
        );
        break;
      }
      default:
        break;
    }
  };

  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];

      setTableState(prevState => ({
        ...prevState,
        ordering: sort === "asc" ? field : `-${field}`
      }));
    } else {
      setTableState(prevState => ({
        ...prevState,
        ordering: ""
      }));
    }
  };

  useEffect(() => {
    dispatch(getTicketsConfig("data-list"));
    dispatch(getDataListConfigsFiltersData());
  }, []);

  useEffect(() => {
    dispatch(
      getDataListByAsset({
        assetId,
        page: tableState.page,
        pageSize: tableState.pageSize,
        ordering: tableState.ordering,
        search: tableState.search,
        data_type: filterByDataType?.value
      })
    );
  }, [
    assetId,
    tableState.page,
    tableState.pageSize,
    tableState.ordering,
    tableState.search,
    filterByDataType?.value
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.filters}>
          <Title className={styles.title}>Explorer</Title>
          <Search onSearch={handleSearch} value={tableState.search} />
          <div className={styles.filterRow}>
            <div className={styles.selectsGroup}>
              <Select
                containerClass={styles.select}
                onChange={setTicketConfig}
                value={ticketConfig}
                placeholder="Ticket Configuration"
                options={ticketsConfigList}
                isClearable
              />
              <Select
                containerClass={styles.select}
                onChange={setFilterByDataType}
                value={filterByDataType}
                placeholder="Filter by"
                options={data_types_list}
                isClearable
              />
            </div>
            <div className={styles.buttonGroup}>
              {actionButtons.map(btn => (
                <ActionButton
                  key={btn}
                  type={btn}
                  onClick={handleAction}
                  className={styles.actionButton}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <Table
            apiRef={apiRef}
            data={isLoading ? [] : dataList.data}
            columns={columns}
            loading={isLoading}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            onSortModelChange={model => handleSort(model)}
            page={tableState.page || 0}
            pageSize={tableState.pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowCount={dataList.count || 0}
            paginationMode="server"
            onPageChange={onPageChange}
            customStyles={customStyles}
          />
        </div>
      </div>
      <CreateTargetModal />
      <CreateGroupModal />
    </div>
  );
};

export default Explorer;
