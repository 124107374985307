import React from "react";

import { Controller } from "react-hook-form";
import { Input, ErrorText } from "components/simple";

export const RegistryName = ({ control, errors }) => {
  return (
    <div className="w-full mb-3.5 mt-3">
      <Controller
        name="registryName"
        rules={{
          required: "Registry Name is required!",
          maxLength: { value: 100, message: "Maximum 100 characters allowed!" }
        }}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Input
            name="registryName"
            label="Registry Name"
            placeholder="Enter Registry Name"
            required
            value={value}
            onChange={evt => {
              onChange(evt.target.value);
              // setIntegrationName(evt.target.value);
            }}
          />
        )}
      />
      <div className="m-0.5">
        <ErrorText errorMsg={errors.registryName && errors.registryName.message} />
      </div>
    </div>
  );
};
