import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.scss";

const Title = ({ children, className }) => {
  return <div className={`${styles.title} ${className}`}>{children}</div>;
};

Title.propTypes = {
  className: PropTypes.string
};

Title.defaultProps = {
  className: ""
};

export default Title;
