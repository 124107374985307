import React from "react";
import PropTypes from "prop-types";

export default function Annotation(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 22H16C20 22 22 20 22 16V3C22 2.45 21.55 2 21 2H8C4 2 2 4 2 8V16C2 20 4 22 8 22Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 14.5H7"
        strokeMiterlimit="10"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9.5H10"
        strokeMiterlimit="10"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Annotation.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Annotation.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 24,
  height: 24
};
