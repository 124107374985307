import { customPost } from "api/base";
import moment from "moment";

function getDifferenceTime(period, now) {
  const diff = parseInt(period.split(" ")[1], 10);
  if (period.includes("min"))
    return moment(now)
      .subtract(diff, "minutes")
      .toDate();
  if (period.includes("hour"))
    return moment(now)
      .subtract(diff, "hours")
      .toDate();
  if (period.includes("day"))
    return moment(now)
      .subtract(diff, "days")
      .toDate();
}

function getTime(date) {
  return new Date(date).getTime();
}

export const callGetTotalCounts = (workspace, cluster, podName, period) => {
  let from;
  let to;
  const now = new Date();
  if (typeof period === "string") {
    if (period === "All") {
      from = 0;
      to = now.getTime();
    } else {
      from = getDifferenceTime(period, now).getTime();
      to = now.getTime();
    }
  }
  if (typeof period === "object") {
    from = getTime(period[0]);
    to = getTime(period[1]);
  }
  const payload = {
    workspace_id: workspace,
    cluster_id: cluster?.value,
    pod_name: podName?.label,
    from_time: Math.floor(from / 1000),
    to_time: Math.floor(to / 1000)
  };
  return customPost("/observabilityapi/v2/get-count", payload);
};

export const callProcessObservability = (
  workspace,
  cluster,
  podName,
  filters,
  period,
  pagePrev = 0,
  pageNext = 10
) => {
  let from;
  let to;
  const now = new Date();
  if (typeof period === "string") {
    if (period === "All") {
      from = 0;
      to = now.getTime();
    } else {
      from = getDifferenceTime(period, now).getTime();
      to = now.getTime();
    }
  }
  if (typeof period === "object") {
    from = getTime(period[0]);
    to = getTime(period[1]);
  }
  const payload = {
    workspace_id: workspace,
    cluster_id: cluster?.value,
    pod_name: podName?.label,
    filters: filters,
    from_time: Math.floor(from / 1000),
    to_time: Math.floor(to / 1000),
    page_previous: pagePrev,
    page_next: pageNext
  };
  return customPost("/observabilityapi/v2/get-process-observability", payload);
};

export const callFileObservability = (
  workspace,
  cluster,
  podName,
  filters,
  aggregated,
  period,
  pagePrev = 0,
  pageNext = 10
) => {
  const now = new Date();
  let from;
  let to;
  if (typeof period === "string") {
    if (period === "All") {
      from = 0;
      to = now.getTime();
    } else {
      from = getDifferenceTime(period, now).getTime();
      to = now.getTime();
    }
  }
  if (typeof period === "object") {
    from = getTime(period[0]);
    to = getTime(period[1]);
  }
  const payload = {
    workspace_id: workspace,
    cluster_id: cluster?.value,
    pod_name: podName?.label,
    filters: filters,
    aggregated,
    from_time: Math.floor(from / 1000),
    to_time: Math.floor(to / 1000),
    page_previous: pagePrev,
    page_next: pageNext
  };
  return customPost("/observabilityapi/v2/get-file-observability ", payload);
};

export const callGetNetworkObservability = (
  workspace,
  cluster,
  podName,
  filters,
  period,
  pagePrev = 0,
  pageNext = 10
) => {
  let from;
  let to;
  const now = new Date();
  if (typeof period === "string") {
    if (period === "All") {
      from = 0;
      to = now.getTime();
    } else {
      from = getDifferenceTime(period, now).getTime();
      to = now.getTime();
    }
  }
  if (typeof period === "object") {
    from = getTime(period[0]);
    to = getTime(period[1]);
  }
  const payload = {
    workspace_id: workspace,
    cluster_id: cluster?.value,
    pod_name: podName?.label,
    filters: filters,
    from_time: Math.floor(from / 1000),
    to_time: Math.floor(to / 1000),
    page_previous: pagePrev,
    page_next: pageNext
  };
  return customPost("/observabilityapi/v2/get-network-observability", payload);
};

export const callNetworkObservabilityFlow = (workspace, cluster, podName, filters, period) => {
  let from;
  let to;
  const now = new Date();
  if (typeof period === "string") {
    if (period === "All") {
      from = 0;
      to = now.getTime();
    } else {
      from = getDifferenceTime(period, now).getTime();
      to = now.getTime();
    }
  }
  if (typeof period === "object") {
    from = getTime(period[0]);
    to = getTime(period[1]);
  }
  const payload = {
    workspace_id: workspace,
    cluster_id: cluster?.value,
    pod_name: podName?.label,
    filters: filters,
    from_time: Math.floor(from / 1000),
    to_time: Math.floor(to / 1000)
    // page_previous: page_previous,
    // page_next: page_next
  };

  return customPost("/observabilityapi/v2/get-network-observability-graph", payload);
};

export const callNetworkObservabilityProcessFlow = (
  workspace,
  cluster,
  podName,
  filters,
  period
) => {
  let from;
  let to;
  const now = new Date();
  if (typeof period === "string") {
    if (period === "All") {
      from = 0;
      to = now.getTime();
    } else {
      from = getDifferenceTime(period, now).getTime();
      to = now.getTime();
    }
  }
  if (typeof period === "object") {
    from = getTime(period[0]);
    to = getTime(period[1]);
  }
  const payload = {
    workspace_id: workspace,
    cluster_id: cluster?.value,
    pod_name: podName?.label,
    filters: filters,
    from_time: Math.floor(from / 1000),
    to_time: Math.floor(to / 1000)
    // page_previous: page_previous,
    // page_next: page_next
  };
  return customPost("/observabilityapi/v2/get-network-observability-process-graph", payload);
};

// export const callClustersFilter = (workspace) => {
//   const payload = {
//     workspace_id: workspace,
//     // from_time: [],
//     // to_time: [],
//   };
//   return api2.post(
//     '/cm/api/v1/cluster-management/clusters-in-workspace-filter',
//     payload,
//   );
// };

export const callNamespaceFilter = (workspace, cluster) => {
  const payload = {
    workspace_id: workspace,
    cluster_id: [cluster?.value]
  };
  return customPost("/cm/api/v1/cluster-management/all-namespaces", payload);
};

export const callPodsFilter = (workspace, cluster, namespace) => {
  const payload = {
    workspace_id: workspace,
    cluster_id: [cluster?.value],
    // node_id: [],
    namespace_id: [namespace?.value]
    // from_time: [],
    // to_time: [],
    // Filter: {},
  };
  return customPost("/cm/api/v1/cluster-management/pods-in-node-filter", payload);
};
