/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { createLoadingSelector } from "store/entities/loading/selector";

import { Table, CommentsModal } from "components/complex";
import SubTable from "./SubTable";

import {
  useGridApiRef,
  visibleGridColumnsSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from "@mui/x-data-grid-pro";

import { ActionButton, Search, Select, CustomDetailPanelToggle } from "components/simple";
import MetadataModal from "./MetadataModal";
import DetailsModal from "./DetailsModal";

import { useSelector, useDispatch } from "react-redux";
// import { addQuery, removeQuery } from "helper/history";
import { addNotification } from "store/entities/notifications/actions";

import {
  setBaselineConfigParams,
  updBaselineConfigParams,
  getBaselineConfigTable,
  exportBaselineConfigurationTable,
  addControlBaselineConfigurationTable,
  deleteControlBaselineConfigurationTable,
  getBaselineTagById
} from "store/entities/baseline/actions";
import {
  getTicketsConfigSelect,
  getBaselineConfigTableSelector,
  getGroupByListSelect,
  getBaselineConfigFiltersSelect
} from "store/entities/baseline/selectors";
import {
  getBaselineTicketsSelector,
  getBaselineTicketSelector
} from "store/entities/tickets/selectors";
import {
  getTicketsConfig,
  getBaselineTickets,
  postBaselineTicketComment
} from "store/entities/tickets/actions";
import { onChangeUrlParams, useQuery } from "helper/history";

import styles from "./styles.module.scss";
import { routes } from "router";

const Data = ({ id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const apiRef = useGridApiRef();

  const query = useQuery();
  const querySearch = query.get("search");

  const {
    page,
    pageSize,
    groupBy,
    orderModel,
    selectTicketConfig,
    baselineConfigTemporaryData,
    search
  } = useSelector(state => state.baseline.baselineConfigParams);

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = React.useRef(selectionModel);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState([]);
  const [selectionModelSubTable, setSelectionModelSubTable] = useState([]); // selections for  Expend Table

  const baselineDetailConfig = useSelector(state => state.baseline.baselineConfigControl.results);
  const baselineTagById = useSelector(state => state.baseline.baselineTagData);
  const ticketsConfigList = useSelector(getTicketsConfigSelect);
  const selectTenant = useSelector(state => state.users.selectTenant);

  const isLoading = useSelector(state =>
    createLoadingSelector([getBaselineConfigTable.type])(state)
  );

  const display_fields = baselineConfigTemporaryData?.display_fields || [];
  const display_fields_width = baselineConfigTemporaryData?.display_fields_width || [];
  const groupByList = useSelector(state => getGroupByListSelect(state, display_fields));

  const { columns, data, total } = useSelector(state =>
    getBaselineConfigTableSelector(state, display_fields, null, display_fields_width)
  );
  const expectedResultOptions = useSelector(getBaselineConfigFiltersSelect);

  const [comment, setComment] = useState("");
  const [isCommentsModalOpen, setCommentsModalOpen] = useState(false);
  const [commentsModalStep, setCommentsModalStep] = useState(0);
  const isAddCommentLoading = useSelector(state =>
    createLoadingSelector([postBaselineTicketComment.type])(state)
  );
  const [ticketId, setTicketId] = useState("");

  const tickets = useSelector(getBaselineTicketsSelector);
  const ticket = useSelector(store => getBaselineTicketSelector(store, ticketId));
  const isTicketsLoading = useSelector(state =>
    createLoadingSelector([getBaselineTickets.type])(state)
  );

  const [controlId, setControlId] = useState("");
  const [controlIds, setControlIds] = useState([]);

  const [isMetadataModalOpen, setMetadataModalOpen] = useState(false);
  const [detailsModal, setDetailsModal] = useState({
    open: false,
    group_ids: []
  });

  const [isSingleMode, setSingleMode] = useState(false);

  // Next page
  const onPageChange = newPage => {
    prevSelectionModel.current = selectionModel;
    dispatch(
      setBaselineConfigParams({
        page: newPage
      })
    );
  };

  const clear = () => {
    const current = history?.location?.pathname;
    const isActive = () => {
      const currentRoute = current.replaceAll("/", " ");
      const route = routes.baselines.replaceAll("/", " ");
      return currentRoute.includes(route);
    };

    if (!isActive()) {
      dispatch(
        updBaselineConfigParams({
          page: 0,
          ordering: "",
          search: "",
          filters: {},
          pageSize: 20,
          orderModel: [],
          groupBy: "",
          selectTicketConfig: "",
          baselineConfigTemporaryData: null
        })
      );
    }
  };

  // get default request
  const getRequest = () => {
    dispatch(
      setBaselineConfigParams({
        id,
        search: querySearch ?? ""
      })
    );
  };

  useEffect(() => {
    getRequest();
    dispatch(getTicketsConfig("control"));
    return () => clear();
  }, [selectTenant, id]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
    setDetailPanelExpandedRowIds([]); // clear expend row
    setSelectionModelSubTable([]);
  }, [page, data]);

  // Actions
  const handleAction = type => {
    const selectionModelSubTableRow = selectionModelSubTable
      ?.map(item => item?.selectionModel)
      ?.flat();

    if (type === "export") {
      const groupByValue = groupBy?.value;
      const myMap = apiRef?.current?.getSelectedRows();
      const values = Array.from(myMap.values());
      const ids = groupByValue
        ? [
            ...new Set([
              ...values?.map(item => item?.group_ids).flat(),
              ...selectionModelSubTableRow
            ])
          ]
        : selectionModel;
      dispatch(exportBaselineConfigurationTable({ id, ids }));
      return;
    }
    if (type === "createTicket" && !selectTicketConfig?.value) {
      dispatch(addNotification({ msg: "You need to select ticket config", type: "warning" }));
      return;
    }

    switch (type) {
      case "plus": {
        dispatch(addControlBaselineConfigurationTable({ id, ids: selectionModel }));
        break;
      }
      case "minus": {
        dispatch(deleteControlBaselineConfigurationTable({ id, ids: selectionModel }));
        break;
      }
      case "createTicket": {
        const ticketConfig_id = selectTicketConfig?.value;

        const groupByValue = groupBy?.value;

        const url = groupByValue ? `&groupBy=${groupByValue}` : "";
        const myMap = apiRef?.current?.getSelectedRows();

        const values = Array.from(myMap.values());
        const ids = groupByValue
          ? [
              ...new Set([
                ...values?.map(item => item?.group_ids).flat(),
                ...selectionModelSubTableRow
              ])
            ]
          : selectionModel;

        history.push(
          `${routes.baselineConfigurationCreateTicket}/${ticketConfig_id}/${id}?ids=${ids.join(
            ","
          )}${url}`
        );
        break;
      }
      case "addTag": {
        setMetadataModalOpen(true);
        if (groupBy.value) {
          const myMap = apiRef?.current?.getSelectedRows();
          const values = Array.from(myMap.values());

          const ids = [
            ...new Set([
              ...values?.map(item => item?.group_ids).flat(),
              ...selectionModelSubTableRow
            ])
          ];

          setControlIds(ids);
        } else {
          setControlIds(selectionModel);
        }
        break;
      }
      default:
        break;
    }
  };

  const handleSearch = v => {
    onChangeUrlParams("search", "");
    dispatch(
      setBaselineConfigParams({
        page: 0,
        search: v
      })
    );
  };

  const onSelectTicketConfig = v => {
    dispatch(updBaselineConfigParams({ selectTicketConfig: v }));
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      let fieldSort = field;

      if (field === "__detail_panel_toggle__") {
        fieldSort = "group_ids";
      }
      if (field === "tags") {
        fieldSort = "has_tags";
      }
      if (field === "tickets__last_comment") {
        fieldSort = "last_comment";
      }
      if (field === "tickets__ids") {
        fieldSort = "tickets_number";
      }
      if (sort === "asc") {
        dispatch(
          setBaselineConfigParams({
            ordering: fieldSort,
            orderModel: s
          })
        );
      } else {
        dispatch(
          setBaselineConfigParams({
            ordering: `-${fieldSort}`,
            orderModel: s
          })
        );
      }
    } else {
      dispatch(
        setBaselineConfigParams({
          ordering: "",
          orderModel: s
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setBaselineConfigParams({
        pageSize: v
      })
    );
  };

  const noBaselineTicketsCallback = () => {
    setCommentsModalOpen(false);
    setCommentsModalStep(0);
  };

  const onCellClick = (params, event) => {
    const { field, id: uid } = params;
    event.stopPropagation();
    setControlId(uid);

    if (field === "tickets__last_comment") {
      dispatch(
        getBaselineTickets({
          baselineId: id,
          controlId: uid,
          noTicketsCallback: noBaselineTicketsCallback
        })
      );
      setCommentsModalOpen(true);
      setCommentsModalStep(1);
    } else if (field !== "__detail_panel_toggle__") {
      dispatch(getBaselineTagById({ id: uid, controlId: uid, baselineId: id }));
      setSingleMode(true);
      setDetailsModal({ open: true, group_ids: params?.row?.group_ids || [] });
      if (groupBy.value) {
        const ids = params?.row?.group_ids?.length ? params?.row?.group_ids : [params?.row?.id];
        setControlIds(ids);
      } else {
        setControlIds([uid]);
      }
    }
  };

  // Comments modal
  const switchCommentsModalStepHandler = (direction, currentStep) => {
    if (direction === "back") {
      if (currentStep === 3 && !ticket?.comments?.length) {
        setCommentsModalStep(currentStep - 2);
      } else {
        setCommentsModalStep(currentStep - 1);
      }
      setComment("");
    } else {
      setCommentsModalStep(currentStep + 1);
    }
  };

  const closeCommentsModalHnadler = () => {
    setCommentsModalStep(0);
    setCommentsModalOpen(!isCommentsModalOpen);
    setComment("");
  };

  const successSubmitCommentCallback = () => {
    switchCommentsModalStepHandler("back", commentsModalStep);
    setComment("");
  };

  const onCloseDetailsModalHandler = () => {
    dispatch(setBaselineConfigParams({ id }));
    setDetailsModal(false);
  };

  const submitCommentHandler = () => {
    if (comment) {
      dispatch(
        postBaselineTicketComment({
          data: {
            ticket_id: ticket.id,
            comment
          },
          baselineId: id,
          controlId,
          successCallback: successSubmitCommentCallback
        })
      );
    }
  };

  // Select Group By
  const onGroupBy = v => {
    dispatch(
      setBaselineConfigParams({
        ordering: "",
        groupBy: v,
        page: 0
      })
    );
  };

  // Expend
  const onChangeSelectionModelSubTable = (v, rowId) => {
    const res = {
      id: rowId,
      selectionModel: v
    };
    let newSelection = [];

    if (selectionModelSubTable.find(item => item?.id === rowId)) {
      newSelection = selectionModelSubTable?.map(item => {
        if (item?.id === rowId) {
          return res;
        }
        return item;
      });
    } else {
      newSelection = [...selectionModelSubTable, res];
    }

    if (selectionModel.find(item => item === rowId)) {
      const resetSelectForMainTable = selectionModel?.filter(item => item !== rowId);
      setSelectionModel(resetSelectForMainTable);
    }

    setSelectionModelSubTable(newSelection);
  };

  const [expandHeight, setExpandHeight] = useState(150);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => {
      return (
        <SubTable
          row={row}
          onCellClick={onCellClick}
          selectionModelSubTable={selectionModelSubTable}
          setExpandHeight={setExpandHeight}
          expandHeight={expandHeight}
          onChangeSelectionModelSubTable={v => onChangeSelectionModelSubTable(v, row?.id)}
        />
      );
    },
    [selectionModelSubTable, expandHeight]
  );

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    newIds => {
      setDetailPanelExpandedRowIds(newIds.slice(-1));
    },
    [apiRef]
  );

  // Expend End

  useEffect(() => {
    return apiRef.current.subscribeEvent("columnHeaderDragEnd", () => {
      const newOrder = visibleGridColumnsSelector(apiRef.current.state)
        .map(col => col.field)
        ?.filter(item => item !== "__check__" && item !== "__detail_panel_toggle__");
      dispatch(
        updBaselineConfigParams({
          baselineConfigTemporaryData: {
            ...baselineConfigTemporaryData,
            display_fields: newOrder
          }
        })
      );
    });
  }, [apiRef, baselineConfigTemporaryData]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  const timerRef = React.useRef();
  const triggerChangeWidth = widthArrat => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      dispatch(
        updBaselineConfigParams({
          baselineConfigTemporaryData: {
            ...baselineConfigTemporaryData,
            display_fields_width: widthArrat
          }
        })
      );
    }, 500);
  };

  const onColumnResize = v => {
    const fields = display_fields_width;
    const prevColWidth = fields ?? [];
    const newCol = [...prevColWidth, { field: v?.colDef?.field, width: v?.width }];
    triggerChangeWidth(getUniqueListBy(newCol, "field"));
  };

  const customStyles = {
    height: 710,
    width: 1,
    "& .super-app-theme--header": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #E7E6F8"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      marginBottom: "0px"
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.filters}>
        <Search onSearch={handleSearch} value={search} />
        <div className={styles.filterRow}>
          <div className={styles.selectsGroup}>
            <Select
              containerClass={styles.select}
              onChange={onSelectTicketConfig}
              value={selectTicketConfig}
              placeholder="Ticket Configuration"
              options={ticketsConfigList}
              isClearable
            />
            <Select
              containerClass={styles.select}
              onChange={onGroupBy}
              value={groupBy}
              placeholder="Group by"
              options={groupByList}
              isClearable
            />
          </div>
          <div className={styles.buttonGroup}>
            <ActionButton type="plus" onClick={handleAction} className={styles.actionButton} />
            <ActionButton type="minus" onClick={handleAction} className={styles.actionButton} />
            <ActionButton
              type="createTicket"
              onClick={handleAction}
              className={styles.actionButton}
            />
            <ActionButton type="addTag" onClick={handleAction} className={styles.actionButton} />
            <ActionButton type="export" onClick={handleAction} className={styles.actionButton} />
          </div>
        </div>
      </div>

      <div className={styles.table}>
        <Table
          apiRef={apiRef}
          data={isLoading ? [] : data}
          onColumnResize={onColumnResize}
          columns={[
            {
              ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
              headerName: "Group ids",
              field: "__detail_panel_toggle__",
              width: 120,
              sortable: true,
              renderCell: params => {
                return (
                  <span>
                    {params?.row?.group_ids?.length || 0}
                    {params?.row?.group_ids?.length > 1 ? (
                      <CustomDetailPanelToggle {...params} />
                    ) : null}
                  </span>
                );
              }
            },
            ...columns
          ]}
          loading={isLoading}
          onSelectionModelChange={newSelectionModel => {
            if (groupBy) {
              const current = newSelectionModel.slice(-1)[0];
              const res = data?.find(item => item?.id === current);
              if (current) {
                const groupIds = res?.group_ids || [];
                if (selectionModelSubTable?.find(item => item.id === current)) {
                  const rr = selectionModelSubTable?.map(item => {
                    if (item.id === current) {
                      if (newSelectionModel?.find(i => i === item?.id)) {
                        return { ...item, selectionModel: groupIds };
                      }
                      return;
                    }
                    if (newSelectionModel?.find(i => i !== item?.id)) {
                      return { ...item, selectionModel: [] };
                    }
                    return item;
                  });
                  setSelectionModelSubTable(rr);
                } else {
                  setSelectionModelSubTable([
                    ...selectionModelSubTable,
                    { id: current, selectionModel: groupIds }
                  ]);
                }
              } else {
                setSelectionModelSubTable([]);
              }
            }

            setSelectionModel(newSelectionModel);
          }}
          getRowClassName={param => {
            const status = !param?.row?.active ? "--active" : "";
            return `super-app-theme--row${status}`;
          }}
          page={page || 0}
          selectionModel={selectionModel}
          onSortModelChange={model => {
            handleSort(model);
          }}
          sortModel={orderModel}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowCount={total}
          paginationMode="server"
          onPageChange={onPageChange}
          initialState={{ pinnedColumns: { left: ["__check__"] } }}
          onCellClick={(p, e) => onCellClick(p, e)}
          // rowThreshold={0}
          //
          {...(groupBy ? { getDetailPanelHeight: () => expandHeight } : {})}
          {...(groupBy ? { getDetailPanelContent } : {})}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          customStyles={customStyles}
        />
      </div>

      <CommentsModal
        step={commentsModalStep}
        setStep={switchCommentsModalStepHandler}
        tickets={tickets}
        ticket={ticket}
        commentValue={comment}
        onSelectTicket={setTicketId}
        onChange={val => setComment(val)}
        onSubmit={submitCommentHandler}
        isOpen={isCommentsModalOpen}
        onCloseModal={closeCommentsModalHnadler}
        isTicketsLoading={isTicketsLoading}
        isAddCommentLoading={isAddCommentLoading}
      />

      <MetadataModal
        isOpen={isMetadataModalOpen}
        onCloseModal={() => setMetadataModalOpen(false)}
        isSingleMode={isSingleMode}
        setSingleMode={setSingleMode}
        baselineId={id}
        controlIds={controlIds}
        fromTags
      />

      <DetailsModal
        data={baselineTagById}
        baselineDetailConfig={baselineDetailConfig}
        isOpen={Boolean(detailsModal.open)}
        onCloseModal={onCloseDetailsModalHandler}
        group_ids={detailsModal?.group_ids}
        resOptions={expectedResultOptions?.result}
        handleAction={handleAction}
        isSingleMode={isSingleMode}
        setSingleMode={setSingleMode}
        baselineId={id}
        controlIds={controlIds}
        selectTicketConfig={selectTicketConfig}
        ticketConfigOptions={ticketsConfigList}
        onSelectTicketConfig={onSelectTicketConfig}
      />
    </div>
  );
};

Data.propTypes = {};

export default Data;
