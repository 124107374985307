import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import moment from "moment";
import { useQuery } from "react-query";
import { getFormRequest, get, postFormRequest } from "api/base";
import { convertOpenTicketDetails, convertPriorityLabel } from "./Tickets/helper";
export const useOpenTicketCount = workspaceID => {
  let to = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  let originData = moment()
    .subtract(40, "years")
    .format("YYYY-MM-DDTHH:mm:ss.SSS");
  const URL = `${API}/dashboard?data_type=TICKET_CHART&date_from=${originData}&date_to=${to}`;
  return useQuery(
    ["openTicket ", workspaceID],

    async () => {
      const response = await get(URL, {
        ...contentTypeJson(),
        ...authorization(),
        ...authorizationTenant()
      });
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false,
      select: data => {
        let ticketPriority = convertPriorityLabel(data?.result?.ticket_chart?.ticket_by_priority);
        let ticketDetails = convertOpenTicketDetails(data?.result?.ticket_chart?.ticket_by_age);
        return { ticketsByPriority: ticketPriority, ticketByAge: ticketDetails };
      }
    }
  );
};
