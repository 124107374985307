/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { getTag } from "store/entities/tags/actions";
import {
  getTagAssets,
  getTagBaselines,
  getTagControls,
  getTagFindings,
  getTagGroups,
  getTagVulnerabilities
} from "store/entities/tags/service";

import { ContentContainer, DataListServer, Title } from "components/simple";

import styles from "./styles.module.scss";

const TagsDetails = () => {
  const selectTenant = useSelector(state => state.users.selectTenant);
  const tag = useSelector(state => state.tags.tag);

  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    dispatch(getTag(id));
  }, [selectTenant]);

  const renderList = (list, name) => {
    let key;
    switch (name) {
      case "findings":
        key = "vulnerability__name";
        break;
      case "controls":
        key = "control";
        break;
      case "assets":
        key = "title";
        break;

      default:
        break;
    }
    return list?.map(elem => (
      <li className={styles.li} key={elem.id}>
        {elem[key]}
      </li>
    ));
  };

  return (
    <div>
      <ContentContainer className={styles.title}>
        <Title>{tag?.value}</Title>
      </ContentContainer>
      <div className={styles.grid}>
        <DataListServer
          title="Findings"
          name="findings"
          request={pr => getTagFindings({ ...pr, id })}
          render={renderList}
        />
        <DataListServer
          title="Controls"
          name="controls"
          request={pr => getTagControls({ ...pr, id })}
          render={renderList}
        />
        <DataListServer
          title="Assets"
          name="assets"
          request={pr => getTagAssets({ ...pr, id })}
          render={renderList}
        />
        <DataListServer
          title="Vulnerabilities"
          name="vulnerabilities"
          request={pr => getTagVulnerabilities({ ...pr, id })}
        />
        <DataListServer
          title="Baselines"
          name="baselines"
          request={pr => getTagBaselines({ ...pr, id })}
        />
        <DataListServer title="Groups" name="groups" request={pr => getTagGroups({ ...pr, id })} />
      </div>
    </div>
  );
};

TagsDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default TagsDetails;
