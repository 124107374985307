import React, { useState, useEffect } from "react";

import { Modal, Textarea, Input, Loader, Title } from "components/simple";
import { getBaselineAssetsDetails } from "store/entities/baseline/service";

import styles from "./styles.module.scss";

const DetailsModal = ({ isOpen, onCloseModal, id }) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const getRequest = () => {
    setLoading(true);
    getBaselineAssetsDetails({ id })
      .then(res => {
        setData(res?.control);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen && id) {
      getRequest();
    }
    return () => setData(null);
  }, [isOpen, id]);

  const control = data?.control || "";
  const description = data?.description || "";
  const information = data?.info || "";
  const solution = data?.solution || "";
  const check_value = data?.check_value || "";
  const actual_value = data?.actual_value || "";

  return (
    <Modal isOpen={isOpen} toggle={onCloseModal} width={850} sx={{ paddingRight: "20px" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className="">{control}</div>
          <Title className={styles.title}>Details</Title>
          <Textarea
            value={description}
            placeholder=""
            label="Description"
            readOnly
            className={styles.mb_20}
          />
          <Textarea
            value={information}
            placeholder=""
            label="Information"
            readOnly
            className={styles.mb_20}
          />
          <Textarea
            value={solution}
            placeholder=""
            label="Solution"
            readOnly
            className={styles.mb_20}
          />
          <Input
            type="text"
            placeholder=""
            label="Check Value"
            value={check_value}
            readOnly
            containerClassName={`${styles.mb_20}`}
          />
          <Input
            type="text"
            placeholder=""
            label="Actual Value"
            value={actual_value}
            readOnly
            containerClassName={`${styles.mb_20}`}
          />
        </div>
      )}
    </Modal>
  );
};

export default DetailsModal;
