import React from "react";
import PropTypes from "prop-types";

export default function Edit(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.23602 4.38943C5.79881 4.38943 5.39881 4.54757 5.10114 4.83595C4.74765 5.18943 4.58949 5.69176 4.67321 6.23129L4.93367 8.07315C4.98949 8.47315 5.24066 8.98478 5.52903 9.27315L13.6458 17.3899C15.3016 19.0457 16.7435 18.3016 17.6551 17.3899C18.3714 16.6736 18.697 15.9202 18.6226 15.1667C18.5667 14.5527 18.2412 13.976 17.6551 13.3806L9.53834 5.26385C9.24996 4.97548 8.74763 4.72432 8.33833 4.6592L6.49648 4.39874C6.41276 4.39874 6.31974 4.38943 6.23602 4.38943ZM15.6458 16.9806C15.3016 16.9806 14.9947 16.7574 14.6412 16.4132L6.52439 8.29641C6.44997 8.22199 6.33834 7.98943 6.31974 7.8871L6.05927 6.04525C6.04997 5.95222 6.05925 5.8685 6.09646 5.83129C6.13367 5.79408 6.2174 5.78478 6.31043 5.79408L8.1523 6.05455C8.26393 6.07315 8.48718 6.18478 8.5616 6.2592L16.6784 14.376C17.0319 14.7295 17.2179 15.0364 17.2458 15.3155C17.2737 15.6318 17.0877 16.0039 16.6784 16.4132C16.2691 16.8039 15.9435 16.9806 15.6458 16.9806Z"
        fill={props.color}
      />
      <path
        d="M15.8159 11.8293C15.7507 11.8293 15.6856 11.8386 15.6298 11.8572C13.9461 12.3317 12.5972 13.6712 12.1228 15.3642C12.0205 15.7363 12.2344 16.1177 12.6065 16.2293C12.9786 16.3317 13.36 16.1177 13.4624 15.7456C13.8066 14.527 14.7833 13.5503 16.0019 13.2061C16.374 13.1038 16.5879 12.7131 16.4856 12.341C16.4019 12.034 16.1228 11.8293 15.8159 11.8293Z"
        fill={props.color}
      />
      <path
        d="M12.7907 0H7.2093C2.15814 0 0 2.15814 0 7.2093L0 12.7907C0 17.8419 2.15814 20 7.2093 20H9.06977C9.45116 20 9.76744 19.6837 9.76744 19.3023C9.76744 18.9209 9.45116 18.6047 9.06977 18.6047H7.2093C2.92093 18.6047 1.39535 17.0791 1.39535 12.7907L1.39535 7.2093C1.39535 2.92093 2.92093 1.39535 7.2093 1.39535H12.7907C17.0791 1.39535 18.6047 2.92093 18.6047 7.2093V9.06977C18.6047 9.45116 18.9209 9.76744 19.3023 9.76744C19.6837 9.76744 20 9.45116 20 9.06977V7.2093C20 2.15814 17.8512 0 12.7907 0Z"
        fill={props.color}
      />
    </svg>
  );
}

Edit.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Edit.defaultProps = {
  className: "",
  style: { transform: "rotate(-90deg)" },
  color: "#2B3858",
  width: 20,
  height: 20
};
