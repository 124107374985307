import React from "react";

import { Modal, Title, Textarea, Button } from "components/simple";

import styles from "./styles.module.scss";

function AddCommentModal({
  onChange,
  value,
  onCloseModal,
  isAddCommentLoading,
  onSubmit,
  isModalOpen
}) {
  return (
    <Modal isOpen={isModalOpen} toggle={onCloseModal}>
      <Title className={styles.title}>Add comment</Title>
      <Textarea
        value={value}
        placeholder="Your comment"
        onChange={val => onChange(val)}
        label="Comment"
        className={styles.textarea}
      />
      <div className={styles.btnsRow}>
        <Button variant="outline" onClick={onCloseModal} className={styles.btn}>
          Back
        </Button>
        <Button onClick={onSubmit} className={styles.btn} isLoading={isAddCommentLoading}>
          Save
        </Button>
      </div>
    </Modal>
  );
}

export default AddCommentModal;
