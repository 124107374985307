import { Typography } from "@mui/material";
import React from "react";
import { formatTimestampToLocalDate } from "helper/helpers";
import { BugChip, LockChip, StackedBar } from "./components/common";
import { navigate } from "helper/history";
import * as routes from "router/routes";
import { SEVERITY_COLORS } from "./constants";

export const imageStatusList = [
  { label: "Pending", value: "Not-Started" },
  { label: "In Progress", value: "In-Progress" },
  { label: "Finished", value: "Success" },
  { label: "Failed", value: "Failed" }
];
export const imageStatusCount = { "Not-Started": 1, "In-Progress": 127, Success: 864, Failed: 0 };

export const imageSeverities = {
  severities: {
    Critical: 60,
    High: 30,
    Medium: 80,
    Low: 10,
    Unknown: 100
  },
  total: 6154
};

export const imageSecurities = {
  severities: {
    Critical: 60,
    High: 30,
    Medium: 80,
    Low: 95,
    Unknown: 0
  },
  totalVulnerabilities: 45493,
  totalMalware: 894,
  totalSensitiveData: 79
};

const headerCellConfig = {
  resizable: true,
  minWidth: 100,
  flex: 1,
  renderCell: params => <div>{params?.value}</div>,
  headerClassName: "super-app-theme--header",
  hide: false
};
export const tableHeaders = [
  {
    headerName: "Name",
    field: "name",
    ...headerCellConfig,
    minWidth: 500,
    renderCell: params => (
      <Typography
        paragraph
        fontWeight={600}
        sx={{ color: "primary.light", m: 0, cursor: "pointer" }}
        onClick={() => navigate(routes.registryScanImageDetails)}
      >
        {params?.value}
      </Typography>
    )
  },
  {
    headerName: "Registry Name",
    field: "registry_name",
    ...headerCellConfig
  },
  {
    headerName: "Status",
    field: "status",
    ...headerCellConfig
  },
  {
    headerName: "Queued On",
    field: "created_at",
    ...headerCellConfig,
    renderCell: params => <div>{formatTimestampToLocalDate(params?.value)}</div>
  },
  {
    headerName: "Last Progress",
    field: "updated_at",
    ...headerCellConfig,
    renderCell: params => <div>{formatTimestampToLocalDate(params?.value)}</div>
  }
];
export const tableRows = {
  total_record: 564,
  list_of_image: [
    {
      id: 109587,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/shared-informer-agent",
      registry_name: "nexus",
      tags: "1.0.123",
      created_at: 1669977278,
      updated_at: 1670101579
    },
    {
      id: 109455,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/shared-informer-agent",
      registry_name: "nexus",
      tags: "1.0.121",
      created_at: 1669621253,
      updated_at: 1670101706
    },
    {
      id: 109452,
      status: "Success",
      name: "agents.accuknox.com/repository/stable/discovery-engine",
      registry_name: "nexus",
      tags: "1.0",
      created_at: 1669621108,
      updated_at: 1670101578
    },
    {
      id: 109449,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/policy-enforcement-agent",
      registry_name: "nexus",
      tags: "1.0.62",
      created_at: 1669396116,
      updated_at: 1670101576
    },
    {
      id: 108843,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/feeder-service",
      registry_name: "nexus",
      tags: "1.0.59",
      created_at: 1669118956,
      updated_at: 1670101575
    },
    {
      id: 108795,
      status: "Success",
      name: "projectaccuknox/redis",
      registry_name: "dockerhub",
      tags: "5.0-20.04_beta",
      created_at: 1669109872,
      updated_at: 1669109919
    },
    {
      id: 108414,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/shared-informer-agent",
      registry_name: "nexus",
      tags: "1.0.120",
      created_at: 1669061551,
      updated_at: 1670101705
    },
    {
      id: 108402,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/feeder-service",
      registry_name: "nexus",
      tags: "1.0.58",
      created_at: 1669018247,
      updated_at: 1670101704
    },
    {
      id: 108399,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/shared-informer-agent",
      registry_name: "nexus",
      tags: "1.0.117",
      created_at: 1669018247,
      updated_at: 1670101703
    },
    {
      id: 108396,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/policy-enforcement-agent",
      registry_name: "nexus",
      tags: "1.0.61",
      created_at: 1669009191,
      updated_at: 1670101574
    },
    {
      id: 108393,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/shared-informer-agent",
      registry_name: "nexus",
      tags: "1.0.116",
      created_at: 1669009191,
      updated_at: 1670101573
    },
    {
      id: 108390,
      status: "Success",
      name: "agents.accuknox.com/repository/dev/feeder-service",
      registry_name: "nexus",
      tags: "1.0.57",
      created_at: 1669009191,
      updated_at: 1670101572
    }
  ]
};

const findingsHeaderCellConfig = {
  resizable: true,
  renderCell: params => <div>{params?.value}</div>,
  headerClassName: "super-app-theme--header",
  hide: false
};
export const findingsTableHeaders = [
  {
    headerName: "Repositories",
    field: "repositories",
    ...headerCellConfig,
    renderCell: params => (
      <Typography paragraph fontWeight={600} sx={{ color: "primary.light", m: 0 }}>
        {params?.value}
      </Typography>
    ),
    width: 400
  },
  {
    headerName: "Security Issues",
    field: "security_issues",
    ...headerCellConfig,
    renderCell: ({ value }) =>
      value === "Queued" ? (
        <div className="font-medium">{value}</div>
      ) : (
        <div className="w-4/5 flex justify-between items-center gap-4">
          <StackedBar
            height={24}
            data={imageSecurities.severities}
            dataLength={Object.keys(imageSecurities.severities).length}
            severitycolor={SEVERITY_COLORS}
          />
          <div className="flex items-center gap-1">
            <BugChip size="small" count={8} fill={false} />
            <LockChip size="small" count={2} fill={false} />
          </div>
        </div>
      ),
    width: 600
  },
  {
    headerName: "Registry Name",
    field: "registry_name",
    ...headerCellConfig,
    width: 200
  }
];
export const findingsTableRows = {
  total_record: 4,
  list_of_image: [
    {
      id: 109587,
      security_issues: "Queued",
      repositories: "Ubuntu/test-repo",
      registry_name: "docker pro"
    },
    {
      id: 109452,
      security_issues: "Scanned",
      repositories: "PostgreSQL/test-repo",
      registry_name: "docker pro"
    },
    {
      id: 109449,
      security_issues: "Queued",
      repositories: "Mongo/test-repo",
      registry_name: "docker pro"
    },
    {
      id: 109455,
      security_issues: "Scanned",
      repositories: "CentOS/test-repo",
      registry_name: "docker pro"
    },
    {
      id: 569854,
      security_issues: "Queued",
      repositories: "Nginx/test-repo",
      registry_name: "docker pro"
    },
    {
      id: 879721,
      security_issues: "Scanned",
      repositories: "Sentry/test-repo",
      registry_name: "docker pro"
    },
    {
      id: 3565894,
      security_issues: "Queued",
      repositories: "Jenkins/test-repo",
      registry_name: "docker pro"
    },
    {
      id: 124165,
      security_issues: "Scanned",
      repositories: "ElasticSearch/test-repo",
      registry_name: "docker pro"
    }
  ]
};

export const findingsSubTableHeaders = [
  {
    headerName: "Image Name",
    field: "image_name",
    resizable: true,
    renderCell: params => (
      <Typography
        paragraph
        fontWeight={600}
        sx={{ cursor: "pointer", color: "primary.light", m: 0 }}
        onClick={() => navigate(routes.registryScanImageDetails)}
      >
        {params?.value}
      </Typography>
    ),
    headerClassName: "super-app-theme--header",
    hide: false,
    width: 400
  },
  {
    headerName: "Security Issues",
    field: "security_issues",
    resizable: true,
    renderCell: ({ value }) =>
      value === "Queued" ? (
        <div className="flex items-center gap-2">
          <span className="inline-block h-4 w-4 bg-green-600" />
          {value}
        </div>
      ) : (
        <div className="w-4/5 flex justify-between items-center gap-4">
          <StackedBar
            height={28}
            data={imageSecurities.severities}
            dataLength={Object.keys(imageSecurities.severities).length}
            severitycolor={SEVERITY_COLORS}
          />
          <div className="flex items-center gap-1">
            <BugChip count={8} />
            <LockChip count={1} />
          </div>
        </div>
      ),
    headerClassName: "super-app-theme--header",
    hide: false,
    width: 600
  }
];
export const findingsSubTableQueuedRows = {
  total_record: 4,
  list_of_image: [
    {
      id: 109587,
      image_name: "alpine/k8s:1.24.8",
      security_issues: "Queued"
    },
    {
      id: 109455,
      image_name: "alpine/k8s:1.21.5",
      security_issues: "Queued"
    }
  ]
};

export const findingsSubTableScannedRows = {
  total_record: 4,
  list_of_image: [
    {
      id: 109587,
      image_name: "alpine/k8s:1.24.8",
      security_issues: "Scanned"
    },
    {
      id: 109455,
      image_name: "alpine/k8s:1.21.5",
      security_issues: "Scanned"
    }
  ]
};

export const RISK_ACCEPTANCE_HEADER_CONFIG = {
  resizable: true,
  flex: 1,
  renderCell: params => <div>{params?.value}</div>,
  headerClassName: "super-app-theme--header",
  hide: false
};
export const RISK_ACCEPTANCE_HEADER_COlUMNS = [
  {
    headerName: "Creation Time",
    field: "creation_time",
    minWidth: 300,
    ...headerCellConfig
  },
  {
    headerName: "Risk Name",
    field: "risk_name",
    minWidth: 150,
    ...headerCellConfig
  },
  {
    headerName: "Risk Type",
    field: "risk_type",
    minWidth: 100,
    ...headerCellConfig
  },
  {
    headerName: "Resource",
    field: "resource",
    minWidth: 400,
    ...headerCellConfig
  },
  {
    headerName: "Image",
    field: "image",
    minWidth: 200,
    ...headerCellConfig
  },
  {
    headerName: "Repository",
    field: "repository",
    minWidth: 200,
    ...headerCellConfig
  },
  {
    headerName: "Expiration",
    field: "expiration",
    minWidth: 200,
    ...headerCellConfig
  }
];
export const RISK_ACCEPTANCE_ROWS = {
  total_record: 20,
  list_of_risk: [
    {
      id: 1,
      creation_time: "2022-07-11 | 07:50:59PM",
      risk_name: "CVE-2022-12403",
      risk_type: "Vulnerability",
      resource: "libnss3 (2:3:26.2-11+deb9)",
      image: "ravikishu/log4j-poc:1.0",
      repository: "-",
      expiration: "-"
    },
    {
      id: 2,
      creation_time: "2022-07-11 | 12:36:45AM",
      risk_name: "CVE-2022-12404",
      risk_type: "Vulnerability",
      resource: "libx11-data (2:1.6.4-3+deb9)",
      image: "-",
      repository: "ravikishu/log4j-poc",
      expiration: "2022-07-23 (in 12 days)"
    },
    {
      id: 3,
      creation_time: "2022-07-12 | 02:11:41PM",
      risk_name: "CVE-2022-12404",
      risk_type: "Vulnerability",
      resource: "org.springframework#spring",
      image: "-",
      repository: "-",
      expiration: "2022-08-17 (in 6days)"
    },
    {
      id: 4,
      creation_time: "2022-07-13 | 11:14:32PM",
      risk_name: "CVE-2022-12405",
      risk_type: "Vulnerability",
      resource: "libnss3 (2:3:26.2-11+de9)",
      image: "ravikishu/log4j-poc:1.0",
      repository: "-",
      expiration: "2022-09-14 (in 9days)"
    }
  ]
};
