import React from "react";

import { Modal } from "components/simple";
import Metadata from "../Metadata";

const MetadataModal = ({ isOpen, onCloseModal, ...rest }) => {
  return (
    <Modal isOpen={isOpen} toggle={onCloseModal} width={850}>
      <Metadata onCloseModal={onCloseModal} {...rest} />
    </Modal>
  );
};

export default MetadataModal;
