// Set Data-structure labels params
export const SET_DATA_STRUCTURE_LABELS_PARAMS = "SET_DATA_STRUCTURE_LABELS_PARAMS";

// Get LABELS
export const GET_LABELS = "GET_GET_LABELS_REQUEST";
export const GET_LABELS_SUCCESS = "GET_GET_LABELS_SUCCESS";
export const GET_LABELS_FAILED = "GET_GET_LABELS_FAILED";

// Get LABELS List
export const GET_LABEL_LIST = "GET_LABEL_LIST_REQUEST";
export const GET_LABEL_LIST_SUCCESS = "GET_LABEL_LIST_SUCCESS";
export const GET_LABEL_LIST_FAILED = "GET_LABEL_LIST_FAILED";

// Get LABELS-mini
export const GET_LABELS_MINI = "GET_GET_LABELS_MINI_REQUEST";
export const GET_LABELS_MINI_SUCCESS = "GET_GET_LABELS_MINI_SUCCESS";
export const GET_LABELS_MINI_FAILED = "GET_GET_LABELS_MINI_FAILED";

// Create Label
export const CREATE_LABEL = "CREATE_LABEL_REQUEST";
export const CREATE_LABEL_SUCCESS = "CREATE_LABEL_SUCCESS";
export const CREATE_LABEL_FAILED = "CREATE_LABEL_FAILED";

// Delete Label
export const DELETE_LABEL = "DELETE_LABEL_REQUEST";
export const DELETE_LABEL_SUCCESS = "DELETE_LABEL_SUCCESS";
export const DELETE_LABEL_FAILED = "DELETE_LABEL_FAILED";
