import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { addNotification } from "../notifications/actions";
import { errorHandler } from "helper/errorHandler";

const getDataStructureLabelsParams = state => {
  return {
    page: state.labels.dataStructureLabelsParams.page,
    pageSize: state.labels.dataStructureLabelsParams.pageSize,
    ordering: state.labels.dataStructureLabelsParams.ordering,
    search: state.labels.dataStructureLabelsParams.search
  };
};

// Workers //

// Set Data-structure labels params
function* setDataStructureLabelsParamsWorker() {
  const { page, pageSize, ordering, search } = yield select(getDataStructureLabelsParams);
  yield put(
    actions.getLabels({
      page,
      pageSize,
      ordering,
      search
    })
  );
}

// Get Labels
function* getLabelsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getLabels), payload);
    yield put(actions.getLabelsSuccess(response));
  } catch (error) {
    yield put(actions.getLabelsFailed(error));
  }
}

// Create Label
function* createLabelWorker({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield call(makeRequest(service.createLabel), data);
    yield put(actions.createLabelSuccess(response));
    yield put(actions.setDataStructureLabelsParams());
    yield put(
      addNotification({
        msg: `Label created successfully`
      })
    );
    callback();
  } catch (error) {
    yield put(actions.createLabelFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete  Label
function* deleteLabelWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteLabel), id)));
    yield put(actions.deleteLabelSuccess(response));
    yield put(actions.setDataStructureLabelsParams());
    yield put(
      addNotification({
        msg: `${ids?.length > 1 ? "Labels" : "Label"} deleted successfully`
      })
    );
  } catch (error) {
    yield put(actions.deleteLabelFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Labels List
function* getLabelsListWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getLabelsList), payload);
    yield put(actions.getLabelgetLabelListSuccess(response));
  } catch (error) {
    yield put(actions.getLabelgetLabelListFailed(error));
  }
}

// Get Labels-mini
function* getLabelsMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getLabelsMini), payload);
    yield put(actions.getLabelsMiniSuccess(response));
  } catch (error) {
    yield put(actions.getLabelsMiniFailed(error));
  }
}

// Get Labels
function* watchGetLabelsWorker() {
  yield takeEvery(actions.getLabels, getLabelsWorker);
}

// Create Label
function* watchCreateLabelWorker() {
  yield takeEvery(actions.createLabel, createLabelWorker);
}

// Delete Label
function* watchDeleteLabelWorker() {
  yield takeEvery(actions.deleteLabel, deleteLabelWorker);
}

// Get Labels List
function* watchGetLabelsListWorkerWorker() {
  yield takeEvery(actions.getLabelList, getLabelsListWorker);
}

// Get Labels-mini
function* watchGetLabelsMiniWorker() {
  yield takeEvery(actions.getLabelsMini, getLabelsMiniWorker);
}

// Set Data-structure labels params
function* watchSetDataStructureLabelsParamsWorker() {
  yield takeEvery(actions.setDataStructureLabelsParams, setDataStructureLabelsParamsWorker);
}

// Watchers End//

export default function* rootSaga() {
  yield all([
    fork(watchSetDataStructureLabelsParamsWorker),
    fork(watchGetLabelsWorker),
    fork(watchCreateLabelWorker),
    fork(watchDeleteLabelWorker),
    fork(watchGetLabelsListWorkerWorker),
    fork(watchGetLabelsMiniWorker)
  ]);
}
