import React, { useEffect, useRef } from "react";
import AssetTypeBlockList from "./AssetTypeBlockList";

import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getChartsData } from "store/entities/assets/actions";
import DashboardChart from "./DashboardChart";
import {
  assetsChartDataSelector,
  findingsChartDataSelector,
  scansChartDataSelector
} from "store/entities/assets/selectors";
import useWindowSize from "helper/hooks/useContainerDimensions";
import moment from "moment";

const VisualDashboard = ({ date }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { width } = useWindowSize(ref);
  const assetsChartData = useSelector(assetsChartDataSelector);
  const scansChartData = useSelector(scansChartDataSelector);
  const findingsChartData = useSelector(findingsChartDataSelector);

  const requestChartsData = ([dateFrom, dateTo]) => {
    const formattedDate = {};
    if (dateFrom && dateTo) {
      formattedDate.dateFrom = moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss.SSS");
      formattedDate.dateTo = moment(dateTo).format("YYYY-MM-DDTHH:mm:ss.SSS");
    }
    dispatch(getChartsData(formattedDate));
  };

  useEffect(() => {
    requestChartsData(date);
  }, [date]);

  return (
    <div>
      <AssetTypeBlockList />
      <div className={styles.chartContainer} ref={ref}>
        <DashboardChart containerWidth={width} data={scansChartData} name="Asset Count by Scan" />
        <DashboardChart containerWidth={width} data={assetsChartData} name="Asset Types by Date" />
        <DashboardChart containerWidth={width} data={findingsChartData} name="Findings by Date" />
      </div>
    </div>
  );
};

export default VisualDashboard;
