import React from "react";
import { AcknowledgeRiskModal } from "./components/AcknowledgeRiskModal";
import { FunnelFilter } from "./components/FunnelFilter";
import { VulnerabilityDetailsDrawer } from "./components/VulnerabilityDetailsDrawer";
import { VulnerabilityTable } from "./components/VulnerabilityTable";
import { useVulnerabilitiesQuery } from "store/entities/issues/riskBasedPriortization/queries";

const RiskBasedPrioritization = () => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const vulnerabilitiesQuery = useVulnerabilitiesQuery();

  return (
    <div className="flex flex-col gap-4">
      <FunnelFilter />
      <VulnerabilityTable
        setShowDrawer={setShowDrawer}
        vulnerabilitiesQuery={
          vulnerabilitiesQuery?.data?.result?.risk_based_prioritization?.[0]?.finding_id === ""
            ? {}
            : vulnerabilitiesQuery
        }
      />
      <VulnerabilityDetailsDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
    </div>
  );
};

export default RiskBasedPrioritization;
