import React from "react";

import { formatDate } from "helper/DateLib";

import { Button, Icon } from "components/simple";

import styles from "./styles.module.scss";

const TicketsList = ({ step, setStep, tickets, onSelectTicket }) => {
  return (
    <>
      {tickets.map(t => {
        const lastComment = t.comments[t.comments.length - 1];
        return (
          <div key={t.id} className={styles.card}>
            <div className={styles.part}>
              <span className={styles.name}>{t.title}</span>
              <div className={styles.controls}>
                <div
                  className={`${!t.comments.length ? styles.noComments : ""}`}
                  onClick={() => {
                    onSelectTicket(t.id);
                    setStep("forward", step);
                  }}
                >
                  <Icon.Message />
                  <span>{t.comments.length}</span>
                </div>
                <Button
                  onClick={() => {
                    onSelectTicket(t.id);
                    setStep("forward", 2);
                  }}
                  className={styles.addBtn}
                >
                  <Icon.Add color="#ffffff" />
                </Button>
              </div>
            </div>

            {lastComment ? (
              <div className={styles.part}>
                <span>{`${formatDate(lastComment.date, "DD/MM/YYYY")} - ${lastComment.user}`}</span>
                <span>{lastComment.comment}</span>
              </div>
            ) : (
              <div className={styles.part}>
                <span>Add comment to the ticket</span>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default TicketsList;
