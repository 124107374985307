import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Tabs } from "components/simple";
import { History, Configuration } from "./Tabs";

import styles from "./styles.module.scss";

const ReportsDetails = () => {
  const params = useParams();
  const { id } = params;
  const [activeTab, setActiveTab] = useState(id ? 0 : 1);
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabs = [
    {
      id: 0,
      label: `Configuration`,
      content: <Configuration id={id} />
    },
    {
      id: 1,
      label: `History`,
      content: <History id={id} />,
      disabled: !id
    }
  ];

  return (
    <div className={styles.container}>
      <Tabs tabs={tabs} value={activeTab} handleChange={handleChange} />
    </div>
  );
};

export default ReportsDetails;
