/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { routes } from "router";
import PropTypes from "prop-types";

import { templatesTableColumns } from "../../tableColumns";
import { templatesTableDataSelector } from "store/entities/settings/selectors";
import {
  setTemplatesParams,
  getTemplates,
  duplicateTemplate,
  deleteTemplate
} from "store/entities/settings/actions";
import { createLoadingSelector } from "store/entities/loading/selector";

import { Table } from "components/complex";
import { ContentContainer, Title, Button, Icon, CustomPagination } from "components/simple";

import styles from "./styles.module.scss";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

const TicketTemplates = ({ history }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const selectTenant = useSelector(state => state.users.selectTenant);

  const { page, pageSize } = useSelector(state => state.settings.templatesParams);
  const { data, total } = useSelector(templatesTableDataSelector);

  const isLoading = useSelector(state => createLoadingSelector([getTemplates.type])(state));
  const dispatch = useDispatch();

  // get default request
  const getRequest = () => {
    dispatch(
      setTemplatesParams({
        page: 0,
        ordering: ""
      })
    );
  };

  // Next page
  const onPageChange = (event, newPage) => {
    prevSelectionModel.current = selectionModel;
    dispatch(
      setTemplatesParams({
        page: newPage - 1
      })
    );
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setTemplatesParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setTemplatesParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setTemplatesParams({
          ordering: ""
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setTemplatesParams({
        pageSize: v
      })
    );
  };

  const onCellClick = v => {
    const { id } = v;
    history.push(`${routes.settingsTicketTemplatesDetails}/${id}`);
  };

  const addTemplateHandler = () => {
    history.push(routes.settingsTicketTemplatesDetails);
  };

  const duplicateTemplateHandler = () => {
    if (selectionModel.length) {
      dispatch(duplicateTemplate({ ids: selectionModel }));
    }
  };

  const deleteTemplateHandler = () => {
    if (selectionModel.length) {
      dispatch(deleteTemplate({ ids: selectionModel }));
    }
  };

  const customStyles = {
    height: 710,
    width: 1,
    padding: "30px",
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  const tableConfig = {
    hideSelectedRowCount: true
  };

  return (
    <ContentContainer>
      <div className={styles.controls}>
        <Title>Ticket Templates</Title>
        <div className={styles.btnsContainer}>
          <Button
            className={`${styles.btn} ${styles.dynamic}`}
            onClick={duplicateTemplateHandler}
            disabled={!selectionModel?.length}
          >
            Duplicate template
            <Icon.AddToGroup color="#ffff" />
          </Button>
          <Button className={styles.btn} onClick={addTemplateHandler} icon="plus">
            Add template
          </Button>
          <Button
            className={`${styles.btn} ${styles.dynamic}`}
            onClick={deleteTemplateHandler}
            disabled={!selectionModel?.length}
          >
            Delete
            <Icon.Delete color="#ffff" />
          </Button>
        </div>
      </div>
      <Table
        data={isLoading ? [] : data}
        columns={templatesTableColumns}
        loading={isLoading}
        onSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel);
        }}
        page={page || 0}
        rowCount={total}
        selectionModel={selectionModel}
        onSortModelChange={model => handleSort(model)}
        pageSize={pageSize}
        // rowsPerPageOptions={[5, 10, 20, 50]}
        // onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        paginationMode="server"
        // onPageChange={onPageChange}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
        onCellClick={e => onCellClick(e)}
        customStyles={customStyles}
        components={{
          Pagination: CustomPagination
        }}
        componentsProps={{
          pagination: {
            count: FindTotalPage(data?.count, pageSize),
            onChange: onPageChange,
            page: page + 1,
            totalCount: data?.count || 0,
            selected: pageSize,
            perPageChange: (event, value) => {
              setPageSize(event?.target?.value);
            }
          }
        }}
        {...tableConfig}
      />
    </ContentContainer>
  );
};

TicketTemplates.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default TicketTemplates;
