import { Lock } from "@mui/icons-material";
import React from "react";
import { LightTooltip } from "components/simple";

export const LockChip = ({ fill = true, size, count }) => {
  const isSmall = size === "small";

  return (
    <LightTooltip arrow placement="top" title="Sensitive Data">
      <div
        className="font-medium flex justify-center items-center p-[2px] rounded border shadow"
        style={{
          backgroundColor: fill ? "#000" : "#fff",
          height: size === "small" ? "24px" : "28px",
          width: size === "small" ? "32px" : "36px",
          padding: isSmall ? "1px 1px 0 0" : "1px 1px 0 0"
        }}
      >
        <Lock sx={{ color: fill ? "#fff" : "#000", fontSize: isSmall ? "14px" : "20px" }} />
        <span
          style={{
            color: fill ? "#fff" : "#000",
            fontSize: isSmall ? "7px" : "14px",
            fontWeight: isSmall ? 500 : 600
          }}
        >
          {count}
        </span>
      </div>
    </LightTooltip>
  );
};
