import { Table } from "components/complex";
import React, { useState } from "react";
import { useObservabilityContext } from "../Context";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { processListColumn, fileListColumn, networkListColumn } from "../constant/constant";
import styles from "./styles.module.scss";
import { formatDate } from "../Utils/utils";
import { useCallback } from "react";
import { CustomPagination, Switch } from "components/simple";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

function ListView() {
  const apiRef = useGridApiRef();

  const {
    processListData,
    fileListData,
    networkListData,
    setPageNext,
    setPagePrevious,
    setPageFilePrevious,
    setPageFileNext,
    setPageProcessNext,
    setPageProcessPrevious,
    activeTab,
    setActiveTab,
    aggregatedView,
    setAggregatedView,
    pagePrevious,
    pageFilePrevious,
    pageProcessPrevious
  } = useObservabilityContext();
  const tabData = [
    { id: 1, name: "File Observability" },
    { id: 2, name: "Process Observability" },
    { id: 3, name: "Network Observability" }
  ];

  const allow = <div style={{ color: "green" }}>Allow</div>;
  const deny = <div style={{ color: "red" }}>Deny</div>;

  const processData = processListData?.data?.response?.map((val, i) => ({
    status: val?.action === "Allow" ? allow : val?.action === "Deny" ? deny : val?.action,
    id: i,
    source: val?.source,
    destination: val?.destination,
    container_name: val?.container_name,
    occurance_name: val?.count,
    action: formatDate(val?.updated_time),
    additionalInfo: val
  }));

  const fileData = fileListData?.data?.response?.map((val, i) => ({
    status: val?.action === "Allow" ? allow : val?.action === "Deny" ? deny : val?.action,
    id: i,
    source: val?.source,
    destination: val?.destination,
    container_name: val?.container_name,
    occurance_name: val?.count,
    action: formatDate(val?.updated_time),
    additionalInfo: val
  }));

  const networkData = networkListData?.data?.response?.map((val, i) => ({
    id: i,
    action: formatDate(val?.updated_time),
    source: val?.source,
    network_flow: val?.nw_type,
    pod_svc: val?.ip,
    port: val?.port,
    container: val?.container_name,
    occurance: val?.count,
    status: val?.action === "Allow" ? allow : val?.action === "Deny" ? deny : val?.action,
    additionalInfo: val
  }));

  const handlerPageNetworkChange = (event, value) => {
    let newPageSize = value - 1;
    setPagePrevious(newPageSize * 10);
    setPageNext(newPageSize * 10 + 10);
  };

  const handlePageFileChange = (event, value) => {
    let newPageSize = value - 1;
    setPageFilePrevious(newPageSize * 10);
    setPageFileNext(newPageSize * 10 + 10);
  };

  const handlePageProcessChange = (event, value) => {
    let newPageSize = value - 1;
    setPageProcessPrevious(newPageSize * 10);
    setPageProcessNext(newPageSize * 10 + 10);
  };

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <pre className={styles.additionalInfo}>
        <code>{JSON.stringify(row.additionalInfo, null, 2)}</code>
      </pre>
    ),
    []
  );

  return (
    <div className="contents inline text-sm">
      <div className="flex justify-between">
        <div
          className="flex mt-4 border-2 rounded-lg mb-2"
          style={{ width: "fit-content", fontSize: "14px", border: "1px solid #D0D5DD" }}
        >
          {tabData.map(item => {
            return (
              <div
                key={item.id}
                onClick={() => {
                  setActiveTab(item.id);
                  if (item.id === 1) {
                    // setTotalRecords(processListData?.data?.total_records);
                    // setPagePrevious(pagePrevProcess);
                  }
                  if (item.id === 2) {
                    // setTotalRecords(fileListData?.data?.total_records);
                    // setPagePrevious(pagePrevFile);
                  }
                  if (item.id === 3) {
                    // setTotalRecords(networkListData?.data?.total_records);
                    // setPagePrevious(pagePrevNetwork);
                  }
                }}
                className={`px-2 flex justify-center cursor-pointer select-none py-2 ${item.id !==
                  3 && styles.items} ${activeTab === item.id && "font-bold"}`}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        {activeTab === 1 && (
          <Switch
            label="Show Aggregated View"
            value={aggregatedView}
            onChange={value => setAggregatedView(value)}
          />
        )}
      </div>
      {activeTab === 2 && (
        <Table
          apiRef={apiRef}
          checkboxVisible={false}
          columns={processListColumn}
          data={processData}
          rowCount={processListData?.data?.total_records || 0}
          paginationMode="server"
          loading={processListData?.isLoading}
          // rowsPerPageOptions={[5, 10, 20, 50]}
          page={pageProcessPrevious / 10}
          pageSize={10}
          // onPageChange={handlePageProcessChange}
          // onPageSizeChange={newPageSize => {
          //   setPageProcessPrevious(0 * newPageSize);
          //   setPageProcessNext((0 + 1) * newPageSize);
          // }}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => "auto"}
          getRowClassName={() => "rowMargin"}
          components={{
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(processListData?.data?.total_records, 10),
              onChange: handlePageProcessChange,
              page: pageProcessPrevious / 10 + 1,
              totalCount: processListData?.data?.total_records || 0
            }
          }}
        />
      )}
      {activeTab === 1 && (
        <Table
          checkboxVisible={false}
          data={fileData}
          columns={fileListColumn}
          rowCount={fileListData?.data?.total_records || 0}
          loading={fileListData?.isLoading}
          paginationMode="server"
          // rowsPerPageOptions={[5, 10, 20, 50]}
          page={pageFilePrevious / 10}
          pageSize={10}
          // onPageChange={handlePageFileChange}
          // onPageSizeChange={newPageSize => {
          //   // when page size is changed we need to set pageprev and pagenext to initial
          //   setPageFilePrevious(0 * newPageSize);
          //   setPageFileNext((0 + 1) * newPageSize);
          // }}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => "auto"}
          getRowClassName={() => "rowMargin"}
          components={{
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(fileListData?.data?.total_records, 10),
              onChange: handlePageFileChange,
              page: pageFilePrevious / 10 + 1,
              totalCount: fileListData?.data?.total_records || 0
            }
          }}
        />
      )}
      {activeTab === 3 && (
        <Table
          apiRef={apiRef}
          checkboxVisible={false}
          data={networkData}
          columns={networkListColumn}
          rowCount={networkListData?.data?.total_records || 0}
          loading={networkListData?.isLoading}
          paginationMode="server"
          // onPageSizeChange={newPageSize => {
          //   setPagePrevious(0 * newPageSize);
          //   setPageNext((0 + 1) * newPageSize);
          // }}
          // rowsPerPageOptions={[5, 10, 20, 50]}
          page={pagePrevious / 10}
          // onPageChange={handlerPageNetworkChange}
          pageSize={10}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => "auto"}
          getRowClassName={() => "rowMargin"}
          components={{
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(networkListData?.data?.total_records, 10),
              onChange: handlerPageNetworkChange,
              page: pagePrevious / 10 + 1,
              totalCount: networkListData?.data?.total_records || 0
            }
          }}
        />
      )}
    </div>
  );
}

export default ListView;
