import React from "react";
import PropTypes from "prop-types";

export default function CreateTicket(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 13.5825L10.75 13.5825M8 10.8325L8 16.3325M21 17.5825L21 15.1625C21 13.5825 20 12.5825 18.42 12.5825L15 12.5825L15 19.5725C15 20.6825 15.91 21.5825 17.02 21.5825C18.11 21.5725 19.11 21.1325 19.83 20.4125C20.55 19.6825 21 18.6825 21 17.5825ZM1 2.5825C1 1.7525 1.94001 1.2825 2.60001 1.7825L4.31 3.0625C4.71 3.3625 5.27001 3.3225 5.63001 2.9625L7.28999 1.2925C7.67999 0.902499 8.32001 0.902499 8.71001 1.2925L10.39 2.9725C10.74 3.3225 11.3 3.3625 11.69 3.0625L13.4 1.7825C14.06 1.2925 15 1.7625 15 2.5825L15 19.5825C15 20.6825 15.9 21.5825 17 21.5825L5 21.5825C2 21.5825 1 19.7925 1 17.5825L1 2.5825Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CreateTicket.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

CreateTicket.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 22,
  height: 23
};
