import React, { useState, useContext, createContext } from "react";

const LayoutTitle = createContext("");

function PrimaryLayoutTitleProvider({ children }) {
  const [logdDslTag, setLogdDslTag] = useState([]);
  const [selected, setSelected] = useState([]);
  const [dslTag, setDSLTag] = useState([]);
  function updateTagList(val) {
    setDSLTag(val);
  }
  function updateLogList(val2) {
    setLogdDslTag(val2);
  }
  function updateSelected(data) {
    setSelected(data);
  }

  function clearTagList() {
    setDSLTag([]);
  }
  return (
    <LayoutTitle.Provider
      value={{
        logdDslTag,
        updateLogList,
        selected,
        updateSelected,
        dslTag,
        updateTagList,
        clearTagList
      }}
    >
      {children}
    </LayoutTitle.Provider>
  );
}
function usePrimaryLayoutTitle() {
  const context = useContext(LayoutTitle);
  return context;
}

export { usePrimaryLayoutTitle, PrimaryLayoutTitleProvider };
