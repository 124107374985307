import React, { useCallback } from "react";
import { Button, ContentContainer, YamlEditor } from "components/simple";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useNetworkPolicyEditorState } from "store/entities/policyEditor/NetworkPolicyEditor/slice";
import { Box, Typography } from "@mui/material";
import useElementSize from "helper/hooks/useElementSize";
import { downloadFile } from "helper/helpers";
import {
  useUpdateComponentState,
  useUploadPolicyDropzone
} from "store/entities/policyEditor/NetworkPolicyEditor/hooks";
import { generateStorePolicyRequestBody } from "store/entities/policyEditor/NetworkPolicyEditor/helpers";
import {
  WORKSPACE_ID,
  USER_ID,
  USER_FULL_NAME,
  DEFAULT_KUBEARMOR_POLICY_QUERY_KEYS
} from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import ValidationLoader from "screens/PolicyEditor/common/components/ValidationLoader";
import { addNotification } from "store/entities/notifications/actions";
import useCopyToClipboard from "helper/hooks/useCopyToClipboard";
import { useNetworkPolicyContext } from "../..";
import {
  useStorePolicyMutation,
  useUpdatePolicyMutation
} from "store/entities/policyEditor/kubeArmorPolicyEditor/mutations";
import { useQueryClient } from "react-query";
import InfoIcon from "components/simple/Icon/Info";
import { useTheme } from "@mui/styles";

const YamlPreview = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { policyMetadataForm } = useNetworkPolicyContext();
  const policyJSON = useNetworkPolicyEditorState("policyJSON");
  const policyYAML = useNetworkPolicyEditorState("policyYAML");
  const unknownPolicyValues = useNetworkPolicyEditorState("unknownPolicyValues");
  const selectedCluster = useNetworkPolicyEditorState("selectedCluster");
  const isValidYAML = useNetworkPolicyEditorState("isValidYAML");
  const currentPolicy = useNetworkPolicyEditorState("currentPolicy");
  const isUpdatingPolicy = useNetworkPolicyEditorState("isUpdatingPolicy");
  const isEditingPolicyMetadata = useNetworkPolicyEditorState("isEditingPolicyMetadata");
  const unsupportedRules = useNetworkPolicyEditorState("unsupportedRules");

  const [copy] = useCopyToClipboard();
  const { getRootProps, getInputProps } = useUploadPolicyDropzone();
  const [containerRef, { height: containerHeight }] = useElementSize();
  const { getValues: getMetadataValues } = policyMetadataForm;
  const storePolicyMutation = useStorePolicyMutation();
  const updatePolicyMutation = useUpdatePolicyMutation();
  const updateComponentState = useUpdateComponentState();

  const handleCopyPolicy = useCallback(async () => {
    const isCopied = await copy(policyYAML);
    if (isCopied) {
      dispatch(addNotification({ msg: "Policy copied to clipboard", type: "info" }));
    } else {
      dispatch(addNotification({ msg: "Failed to copy policy", type: "error" }));
    }
  }, [policyYAML]);

  const handleDownloadPolicy = e => {
    e.preventDefault();
    downloadFile(
      policyYAML,
      `${policyJSON?.metadata?.name || "untitled"}.yaml`,
      "application/x-yaml"
    );
  };

  const handleStorePolicy = e => {
    e.preventDefault();

    if (isEditingPolicyMetadata) {
      dispatch(
        addNotification({ type: "error", msg: "Fill the metadata form to save the policy" })
      );
      return;
    }

    const formValues = getMetadataValues();
    const policy = generateStorePolicyRequestBody({
      formValues,
      unknownPolicyValues,
      policyJSON,
      policyYAML,
      selectedCluster,
      isUpdatingPolicy,
      userId: USER_ID,
      workspaceId: WORKSPACE_ID
    });

    const handleMutationSuccess = response => {
      const updatedPolicy = response?.policy;
      if (updatedPolicy) {
        updateComponentState(updatedPolicy);
      }
      queryClient.invalidateQueries([...DEFAULT_KUBEARMOR_POLICY_QUERY_KEYS, "getAllPoliciesData"]); // TODO: try with refetch
    };

    if (isUpdatingPolicy) {
      // If there's already a policy being updated then call the update policy
      updatePolicyMutation.mutate(
        {
          ...policy,
          id: currentPolicy?.id,
          updated_by: USER_FULL_NAME
        },
        {
          onSuccess: handleMutationSuccess
        }
      );
    } else {
      storePolicyMutation.mutate(
        { ...policy, created_by: USER_FULL_NAME },
        {
          onSuccess: handleMutationSuccess
        }
      );
    }
  };

  return (
    <ContentContainer className="flex flex-col gap-2 p-2 relative" ref={containerRef}>
      <div className="flex gap-3 justify-end">
        <div {...getRootProps()}>
          <Button variant="outline" className="flex gap-1">
            <input {...getInputProps()} />
            <Typography fontSize={13} fontWeight={500}>
              Upload YAML
            </Typography>
            <UploadIcon sx={{ fontSize: "14px", color: "primary.light" }} />
          </Button>
        </div>

        <Button className="flex gap-1" onClick={handleStorePolicy}>
          <Typography fontSize={13} fontWeight={500}>
            {isUpdatingPolicy ? "Update Policy" : "Save to Workspace"}
          </Typography>
          <FileCopyIcon sx={{ fontSize: "14px", color: "#fff", marginLeft: "2px" }} />
        </Button>
      </div>

      <div className="flex items-center gap-2 mb-1">
        <ValidationLoader validationValue={policyJSON} isValid={isValidYAML} />
      </div>

      <PolicyEditorWrapper className="relative">
        <ContentCopyOutlinedIcon
          sx={{ fontSize: "28px", color: "primary.main" }}
          className="absolute top-1 right-12 z-10 shadow-md bg-white p-1 rounded cursor-pointer"
          onClick={handleCopyPolicy}
        />
        <DownloadIcon
          sx={{ fontSize: "28px", color: "primary.main" }}
          className="absolute top-1 right-3 z-10 shadow-md bg-white p-1 rounded cursor-pointer"
          onClick={handleDownloadPolicy}
        />

        <YamlEditor code={policyYAML} editable={false} height={`${containerHeight - 95}px`} />
      </PolicyEditorWrapper>
    </ContentContainer>
  );
};

const PolicyEditorWrapper = styled.div`
  .cm-editor {
    font-size: 16px;
  }
`;

export default YamlPreview;
