/* eslint-disable camelcase */
import createNotific from "./createNotification";

import { createReducer } from "@reduxjs/toolkit";

import * as actions from "./actions";

const initialState = {
  toasts: [],
  notificationsParams: {
    page: 0,
    pageSize: 20,
    search: "",
    ordering: "",
    filters: ""
  },
  notifications: {},
  modalNotifications: {},
  notification: {}
};

const reducer = createReducer(
  initialState,
  {
    [actions.setNotificationsParams.type]: (state, action) => ({
      ...state,
      notificationsParams: {
        ...state.notificationsParams,
        ...action.payload
      }
    }),
    [actions.getNotificationsSuccess.type]: (state, action) => ({
      ...state,
      notifications: action.payload
    }),
    [actions.getModalNotificationsSuccess.type]: (state, action) => ({
      ...state,
      modalNotifications: action.payload
    }),
    [actions.getNotificationSuccess.type]: (state, action) => ({
      ...state,
      notification: action.payload
    }),
    [actions.addNotification.type]: (state, action) => {
      const new_toast = createNotific(action.payload);
      return { ...state, toasts: [...state.toasts, new_toast] };
    },
    [actions.removeNotification.type]: (state, action) => {
      const toasts = state.toasts.filter(toast => toast.id !== action.payload);
      return { ...state, toasts };
    }
  },
  [], // required
  state => state // required
);

export default reducer;
