export const RefreshTime = [
  { label: "off", value: false },
  { label: "5 sec", value: 5000 },
  { label: "10 sec", value: 10000 },
  { label: "30 sec", value: 30000 },
  { label: "1 min", value: 60000 },
  { label: "5 mins", value: 300000 },
  { label: "10 mins", value: 600000 },
  { label: "30 mins", value: 1800000 }
];
export const timeRange = [
  { value: "Last 5 min", id: 1 },
  { value: "Last 15 min", id: 2 },
  { value: "Last 30 min", id: 3 },
  { value: "Last 1 hour", id: 4 },
  { value: "Last 3 hours", id: 5 },
  { value: "Last 6 hours", id: 6 },
  { value: "Last 12 hours", id: 7 },
  { value: "Last 24 hours", id: 8 },
  { value: "Last 2 days", id: 9 },
  { value: "Last 7 days", id: 10 },
  { value: "Last 30 days", id: 11 },
  { value: "Last 60 days", id: 12 }
];
export const colourStyles = {
  control: (provided, state) => {
    const { menuIsOpen } = state.selectProps;

    return {
      ...provided,
      width: "100%",
      minHeight: "40px",
      border: menuIsOpen ? "0px solid #d83e3c" : "0px solid #d9dfea",
      boxShadow: menuIsOpen ? "0px 0px 33px rgba(0, 0, 0, 0.06)" : "none",
      backgroundColor: "#ffffff",
      paddingLeft: "9px",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      color: "#2b3858",

      ":hover": {
        borderColor: menuIsOpen ? "#ca2149" : "#d9dfea"
      }
    };
  },

  menu: provided => {
    return {
      ...provided,
      backgroundColor: "#ffffff",
      boxShadow: "none",
      zIndex: 999
    };
  },

  menuList: provided => ({
    ...provided,
    backgroundColor: "#ffffff",
    padding: "8px",
    border: "1px solid #d9dfea",
    zIndex: 10000
  }),

  option: (provided, { isDisabled, isFocused, isSelected }) => {
    return {
      ...provided,
      cursor: isDisabled ? "not-allowed" : "pointer",
      backgroundColor: isFocused || isSelected ? "#f4f5fa" : "#ffffff",
      color: "#2b3858",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: isSelected ? "600" : "normal",
      borderRadius: "7px",
      paddingLeft: "10px",
      marginBottom: "1px",

      ":active": {
        backgroundColor: "#f4f5fa"
      }
    };
  },

  input: styles => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "600",
    color: "#2b3858"
  }),

  singleValue: styles => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    position: "absolute",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "600",
    marginLeft: "0px !important",
    color: "#2b3858"
  }),

  placeholder: styles => ({
    ...styles,
    fontFamily: "PoppinsMedium",
    fontSize: "14px",
    lineHeight: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#2B3858",
    margin: "0"
  }),

  valueContainer: styles => ({ ...styles, padding: "0", overflowY: "auto", maxHeight: "100px" }),

  indicatorSeparator: () => ({
    backgroundColor: "transparent"
  }),

  noOptionsMessage: styles => ({
    ...styles,
    fontFamily: "PoppinsMedium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "inherit"
  }),

  dropdownIndicator: (styles, state) => {
    const { menuIsOpen, required } = state.selectProps;
    let color = "#2b3858";
    if (menuIsOpen || required) {
      color = "#d83e3c";
    }

    return {
      ...styles,
      transition: "0.5s",
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
      marginRight: "14px",

      path: {
        stroke: color
      }
    };
  }
};
export const NETWORK = "Cilium";
export const SYSTEM = "KubeArmor";

export const pageSizes = [10, 20, 25, 50];

export const keysList = [
  { value: "cluster_id", label: "cluster_id" },
  { value: "tenant_id", label: "tenant_id" },
  { value: "component_name", label: "component_name" },
  { value: "instanceGroup", label: "instanceGroup" },
  { value: "instanceID", label: "instanceID" },
  { value: "workload", label: "workload" },
  { value: "HostName", label: "HostName" },
  { value: "NamespaceName", label: "NamespaceName" }
];
export const valuesList = [
  { value: "1498", label: "1498" },
  { value: "07", label: "07" },
  { value: "accuknox-e2e", label: "accuknox-e2e" },
  { value: "0", label: "0" },
  { value: "32", label: "32" },
  { value: "workload", label: "workload" },
  { value: "default", label: "default" },
  { value: "multiubuntu", label: "multiubuntu" }
];
export const operatorsList = [
  { value: "=", label: "=" },
  { value: "!=", label: "!=" },
  { value: ">", label: ">" },
  { value: "<", label: "<" },
  { value: ">=", label: ">=" },
  { value: "<=", label: "<=" },
  { value: "contains", label: "contains" },
  { value: "begins with", label: "begins with" },
  { value: "ends with", label: "ends with" },
  { value: "does not contain", label: "does not contain" }
];

export const customSelect = {
  control: (provided, state) => {
    const { menuIsOpen } = state.selectProps;

    return {
      ...provided,
      width: "100%",
      minHeight: "33px !important",
      border: "1px solid #d9dfea",
      boxShadow: menuIsOpen ? "0px 0px 33px rgba(0, 0, 0, 0.06)" : "none",
      backgroundColor: "#ffffff",
      paddingLeft: "9px",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      color: "#2b3858",

      ":hover": {
        borderColor: menuIsOpen ? "#ca2149" : "#d9dfea"
      }
    };
  },

  menu: provided => {
    return {
      ...provided,
      backgroundColor: "#ffffff",
      boxShadow: "none",
      zIndex: 999
    };
  },

  menuList: provided => ({
    ...provided,
    backgroundColor: "#ffffff",
    padding: "8px",
    border: "1px solid #d9dfea",
    zIndex: 10000,
    position: "fixed !important",
    backgroundColor: "white",
    // border: "1px solid lightgray",
    width: "20rem"
  }),

  option: (provided, { isDisabled, isFocused, isSelected }) => {
    return {
      ...provided,
      cursor: isDisabled ? "not-allowed" : "pointer",
      backgroundColor: isFocused || isSelected ? "#f4f5fa" : "#ffffff",
      color: "#2b3858",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: isSelected ? "600" : "normal",
      borderRadius: "7px",
      paddingLeft: "10px",
      marginBottom: "1px",

      ":active": {
        backgroundColor: "#f4f5fa"
      }
    };
  },

  input: styles => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px !important",
    lineHeight: "17px",
    fontWeight: "600",
    color: "#2b3858"
  }),

  singleValue: styles => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    position: "absolute",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "600",
    marginLeft: "0px !important",
    color: "#2b3858"
  }),

  placeholder: styles => ({
    ...styles,
    fontFamily: "PoppinsMedium",
    fontSize: "14px",
    lineHeight: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#2B3858",
    margin: "0"
  }),

  valueContainer: styles => ({ ...styles, padding: "0", overflowY: "auto", maxHeight: "100px" }),

  indicatorSeparator: () => ({
    backgroundColor: "transparent"
  }),

  noOptionsMessage: styles => ({
    ...styles,
    fontFamily: "PoppinsMedium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "inherit"
  }),

  dropdownIndicator: (styles, state) => {
    const { menuIsOpen } = state.selectProps;
    let color = "#2b3858";

    return {
      ...styles,
      transition: "0.5s",
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
      marginRight: "14px",

      path: {
        stroke: color
      }
    };
  }
};
