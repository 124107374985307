import React, { memo, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";

import { routes } from "router/router";

import { useDispatch, useSelector } from "react-redux";
import { getAuditFileAssets, getAuditFileBaselines } from "store/entities/auditfiles/actions";
import { getAuditFileDataSelector } from "store/entities/auditfiles/selectors";

import { navigate } from "helper/history";

import { Loader, Modal, Search, Title } from "components/simple";

import styles from "./styles.module.scss";

export const DetailModal = memo(({ type, file, isOpen, onClose, loading }) => {
  const dispatch = useDispatch();
  const { labels, assets, baselines, history } = useSelector(getAuditFileDataSelector);

  const types = {
    label: "labels_count",
    asset: "assets_count",
    baseline: "baselines_count",
    scan: "history_count"
  };

  const noData = "No Data";

  const handleSearch = text => {
    if (type === types.asset) dispatch(getAuditFileAssets({ fileName: file, search: text }));
    if (type === types.baseline) dispatch(getAuditFileBaselines({ fileName: file, search: text }));
  };

  const renderSearchJSX = useMemo(
    () =>
      type === types.asset || type === types.baseline ? <Search onSearch={handleSearch} /> : null,
    [type, types]
  );

  const toBaselinePageByLabel = ({ id, title }) =>
    navigate(`${routes.baselines}`, { value: id, label: title });
  const toAssetDetailPage = ({ id }) => navigate(`${routes.dataStructureAssetsDetails}/${id}`);
  const toEditBaselinePage = ({ id }) => navigate(`${routes.editBaseline}/${id}`);
  const toHistoryPage = ({ title }) => navigate(`${routes.dataStructureHistory}`, { title });

  const renderListJSX = useCallback(
    elem => {
      switch (type) {
        case types.label:
          return (
            <button type="button" onClick={() => toBaselinePageByLabel(elem)}>
              {elem?.title}
            </button>
          );
        case types.asset:
          return (
            <button type="button" onClick={() => toAssetDetailPage(elem)}>
              {elem?.title}
            </button>
          );
        case types.baseline:
          return (
            <button type="button" onClick={() => toEditBaselinePage(elem)}>
              {elem?.title}
            </button>
          );
        case types.scan:
          return (
            <button type="button" onClick={() => toHistoryPage(elem)}>
              {elem?.title}
            </button>
          );
        default:
          break;
      }
    },
    [type, types]
  );

  const renderContentJSX = (content, label1, label2, label3) => (
    <>
      {label3 && <Title>{file}</Title>}
      {renderSearchJSX}
      <div className={styles["modal-cols"]}>
        <div className={styles["modal-cols__left-col"]}>
          <Title className={styles["modal-cols__left-col-title"]}>{label1}</Title>
          <ul className={styles["modal-cols__left-col-list"]}>
            {!content?.length && !loading && <p>{noData}</p>}
            {loading ? (
              <Loader />
            ) : (
              content?.map(elem => <li key={elem?.id}>{renderListJSX(elem)}</li>)
            )}
          </ul>
        </div>
        <div className={styles["modal-cols__right-col"]}>
          <Title className={styles["modal-cols__right-col-title"]}>{label2}</Title>
          <ul className={styles["modal-cols__right-col-list"]}>
            {!content?.length && !loading && <p>{noData}</p>}
            {loading ? <Loader /> : content?.map(elem => <li key={elem?.id}>{elem?.counter}</li>)}
          </ul>
        </div>
        {label3 && (
          <div className={styles["modal-cols__rest-col"]}>
            <Title className={styles["modal-cols__rest-col-title"]}>{label3}</Title>
            <ul className={styles["modal-cols__rest-col-list"]}>
              {!content?.length && !loading && <p>{noData}</p>}
              {loading ? <Loader /> : content?.map(elem => <li key={elem?.id}>{elem?.date}</li>)}
            </ul>
          </div>
        )}
      </div>
    </>
  );

  const infoJSX = useMemo(() => {
    switch (type) {
      case types.label:
        return renderContentJSX(labels, "label", "baseline");
      case types.asset:
        return renderContentJSX(assets, "assets", "baseline");
      case types.baseline:
        return renderContentJSX(baselines, "baseline", "assets");
      case types.scan:
        return renderContentJSX(history, "scan", "assets", "date");
      default:
        break;
    }
  }, [type, types, labels, assets, baselines, history]);

  return ReactDOM.createPortal(
    <Modal isOpen={isOpen} toggle={onClose}>
      {infoJSX}
    </Modal>,
    document.getElementById("modal-root")
  );
});
