import React from "react";
import { ContentContainer, Title } from "components/simple";

const PolicyEditorHeader = () => {
  return (
    <ContentContainer className="p-4">
      <Title>Policy Editor Tool</Title>
    </ContentContainer>
  );
};

export default PolicyEditorHeader;
