export const RefreshTime = [
  { label: "off", value: false },
  { label: "5 sec", value: 5000 },
  { label: "10 sec", value: 10000 },
  { label: "30 sec", value: 30000 },
  { label: "1 min", value: 60000 },
  { label: "5 mins", value: 300000 },
  { label: "10 mins", value: 600000 },
  { label: "30 mins", value: 1800000 }
];
