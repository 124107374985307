import React from "react";
import { SEVERITY_COLORS } from "../../../constants";

export const SeverityFilter = ({ selectedFilters, setSelectedFilters }) => {
  const severities = ["Critical", "High", "Medium", "Low", "Negligible"];

  const updateSevirityFilter = filter_clicked => {
    selectedFilters.includes(filter_clicked)
      ? setSelectedFilters(selectedFilters.filter(item => item !== filter_clicked))
      : setSelectedFilters(oldValue => [...oldValue, filter_clicked]);
  };

  return (
    <div>
      Filter by Severity
      <div className="flex gap-2 mt-2">
        {severities.map((item, index) => {
          return (
            <div
              key={index}
              className="text-sm px-3 py-1"
              style={{
                color: "#fff",
                cursor: "pointer",
                borderRadius: "5px",
                backgroundColor: SEVERITY_COLORS[item],
                outline: selectedFilters.includes(item)
                  ? `2px dashed ${SEVERITY_COLORS[item]}`
                  : "none"
              }}
              onClick={() => updateSevirityFilter(item)}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};
