import ContentContainer from "../ContentContainer";
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Link,
  Badge,
  Divider,
  RadioGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio
} from "@mui/material";
import styles from "./styles.module.scss";
import Textarea from "../Textarea";
import { Button, Modal } from "components/simple";
import { Title } from "@mui/icons-material";

const PolicyStatus = ({
  updateModelView,
  approveChangesCallBack,
  reviewChangesCallBack,
  selectedPolicy,
  reviewPolicyChangesCallBack,
  setShowRejectPolicyModal,
  showSubmit,
  setShowSubmit,
  commitText,
  setCommitText
}) => {
  // const [showSubmit, setShowSubmit] = useState("");
  // const [commitText, setCommitText] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const APPROVE = "APPROVE";
  const REQU_CHANGES = "REQUEST_CHANGES";
  const REJ_CHANGES = "REJECT";

  useEffect(() => {
    if (showSubmit === APPROVE) {
      setIsDisable(false);
    } else if (showSubmit === REQU_CHANGES && commitText?.length > 0) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [showSubmit, commitText]);
  const handleCancelClick = () => {
    updateModelView();
    setShowSubmit("");
    setCommitText("");
  };
  const handleSubmitClick = () => {
    updateModelView();
    if (showSubmit === APPROVE) {
      approveChangesCallBack(showSubmit, commitText, selectedPolicy?.id);
      setShowSubmit("");
      setCommitText("");
    } else if (showSubmit === REQU_CHANGES) {
      reviewChangesCallBack(showSubmit, commitText, selectedPolicy?.id);
      setShowSubmit("");
      setCommitText("");
    } else if (showSubmit === REJ_CHANGES) {
      setShowRejectPolicyModal(true);
    }
  };
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div className="w-9/12">
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              paddingRight: "2px",
              position: "relative",
              bottom: "12px"
            }}
          >
            Finish Your Review
          </Typography>
        </div>
        <Divider orientation="horizontal" flexItem />
        <div>
          <Textarea
            value={commitText}
            onChange={setCommitText}
            label="Write commit message (optional)"
            className={styles.label}
            disabled={selectedPolicy?.version === 0 && showSubmit === REJ_CHANGES}
          />
          <RadioGroup
            sx={{ "& > div": { p: 1, flexDirection: "row" } }}
            onChange={e => setShowSubmit(e.target.value)}
          >
            <FormControl size="sm" className={styles.radio}>
              <Radio overlay value={APPROVE} />
              <div className={styles.radiotext}>
                <FormLabel className={styles.radiolabel}>Approve</FormLabel>
                <FormHelperText className={styles.radioDescription}>
                  Submit feeback that must be addressed before changes
                </FormHelperText>
              </div>
            </FormControl>
            <FormControl size="sm" className={styles.radio}>
              <Radio overlay value={REQU_CHANGES} />
              <div className={styles.radiotext}>
                <FormLabel className={styles.radiolabel}>Request Changes</FormLabel>
                <FormHelperText className={styles.radioDescription}>
                  Submit feeback that must be addressed before changes
                </FormHelperText>
              </div>
            </FormControl>
            <FormControl size="sm" className={styles.radio}>
              <Radio overlay value={REJ_CHANGES} />
              <div className={styles.radiotext}>
                <FormLabel className={styles.radiolabel}>Reject Changes</FormLabel>
                <FormHelperText className={styles.radioDescription}>
                  Submit feeback and reject these changes
                </FormHelperText>
              </div>
            </FormControl>
          </RadioGroup>
        </div>
        <div className={styles.button}>
          <Button variant="outline" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmitClick} sx={{ marginLeft: "5px" }}>
            Submit
          </Button>
        </div>
      </Box>
    </>
  );
};

export default PolicyStatus;
