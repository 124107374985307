import React, { useCallback, useEffect, useState } from "react";
import { Button, ContentContainer, Select } from "components/simple";
import Stepper from "components/simple/Stepper";

import styles from "./styles.module.scss";
import { Divider } from "@mui/material";
import { TabPanel } from "components/simple/Tabs/Tabs";
import { useHistory } from "react-router-dom";
import { routes } from "router";
import AzureForm from "./AzureForm";
import GoogleCloudForm from "./GoogleCloudForm";
import { AccessKeys, ManualSetup } from "./AWSForm";
import { useDispatch, useSelector } from "react-redux";
import { createTag, getTagsMini } from "store/entities/tags/actions";
import { createLabel, getLabelsMini } from "store/entities/labels/actions";
import { dataStructureLabelsSelector, labelsMiniSelector } from "store/entities/labels/selectors";
import { getTagsSelector, tagsMiniSelector } from "store/entities/tags/selectors";
import AddLabelModal from "screens/Settings/Labels/AddLabelModal";
import AddTagModal from "screens/Settings/Tags/AddTagModal";
import { createLoadingSelector } from "store/entities/loading/selector";

const steps = ["Cloud Account Details", "Label & Tag", "Set Up Connectivity"];
const cloudTypeOptions = [
  {
    value: "Microsoft Azure",
    label: "Microsoft Azure"
  },
  {
    value: "Google Cloud Platform (GCP)",
    label: "Google Cloud Platform (GCP)"
  },
  {
    value: "Amazon Web Service (AWS)",
    label: "Amazon Web Service (AWS)"
  }
];
const connectionMethodOptions = [
  {
    value: "Manual Setup",
    label: "Manual Setup"
  },
  {
    value: "Access Keys",
    label: "Access Keys"
  }
];

const AddAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const initialModalState = {
    name: "",
    aws_prefix: ""
  };

  const [activeStep, setActiveStep] = useState(0);
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [createTagState, setCreateTagState] = useState("");

  const [showAddLabelModal, setShowAddLabelModal] = useState(false);
  const [labelModalState, setLabelModalState] = useState({ ...initialModalState });
  const isSaveLoading = useSelector(state => createLoadingSelector([createLabel.type])(state));
  const isCreateTagLoading = useSelector(state => createLoadingSelector([createTag.type])(state));

  const inputChangeHandler = (val, name) => {
    setLabelModalState({
      ...labelModalState,
      [name]: val
    });
  };
  const createTagHandler = () => {
    if (createTagState) {
      dispatch(createTag({ data: { value: createTagState }, callback: tagCloseModalHandler }));
    }
  };
  const tagCloseModalHandler = () => {
    setShowAddTagModal(false);
    setCreateTagState("");
    setState({ ...state, tagSelected: null });
  };
  const addLabelHandler = e => {
    e.preventDefault();
    dispatch(createLabel({ data: labelModalState, callback: closeModalHandler }));
  };
  const closeModalHandler = () => {
    setShowAddLabelModal(false);
    setLabelModalState({ ...initialModalState });
    setState({ ...state, labelSelected: null });
  };
  const [state, setState] = useState({
    cloudAccountType: null,
    connectionMethod: null,
    labelSelected: null,
    tagSelected: null
  });

  const labels = useSelector(labelsMiniSelector);
  const tags = useSelector(tagsMiniSelector);

  const fieldsHandler = (val, field) => {
    setState({ ...state, [field]: val });
  };

  const onCancelHandler = useCallback(() => {
    history.push(routes.settingsCloudAccounts);
  }, []);

  const onNextHandler = e => {
    e.preventDefault();
    setActiveStep(st => st + 1);
  };

  const onBackHandler = useCallback(() => {
    setActiveStep(st => st - 1);
  }, [setActiveStep]);

  useEffect(() => {
    dispatch(getLabelsMini());
    dispatch(getTagsMini());
  }, []);

  const renderForm = () => {
    switch (state.cloudAccountType?.value) {
      case "Microsoft Azure":
        return (
          <AzureForm
            onBackHandler={onBackHandler}
            onCancelHandler={onCancelHandler}
            label={state.labelSelected}
            tag={state.tagSelected}
          />
        );
      case "Google Cloud Platform (GCP)":
        return (
          <GoogleCloudForm
            onBackHandler={onBackHandler}
            onCancelHandler={onCancelHandler}
            label={state.labelSelected}
            tag={state.tagSelected}
          />
        );
      case "Amazon Web Service (AWS)":
        if (state.connectionMethod?.value === "Manual Setup") {
          return (
            <ManualSetup
              onBackHandler={onBackHandler}
              onCancelHandler={onCancelHandler}
              label={state.labelSelected}
              tag={state.tagSelected}
            />
          );
        }
        if (state.connectionMethod?.value === "Access Keys") {
          return (
            <AccessKeys
              onBackHandler={onBackHandler}
              onCancelHandler={onCancelHandler}
              label={state.labelSelected}
              tag={state.tagSelected}
            />
          );
        }
        return null;
      default:
        return null;
    }
  };
  useEffect(() => {
    if (state?.labelSelected?.value === "Create a new Label") {
      setShowAddLabelModal(true);
    }
  }, [state.labelSelected]);
  useEffect(() => {
    if (state?.tagSelected?.value === "Create a new Tag") {
      setShowAddTagModal(true);
    }
  }, [state.tagSelected]);
  const { data: labelsData } = useSelector(dataStructureLabelsSelector);
  const { data: TagData } = useSelector(getTagsSelector);
  const [labelData, setLabelData] = useState([]);
  const [tagData, setTagData] = useState([]);
  useEffect(() => {
    if (labelsData?.length > 0) {
      setLabelData(
        labelsData?.map(e => {
          return { value: e.id, label: e.name };
        })
      );
    } else {
      setLabelData(labels);
    }
  }, [labels, labelsData]);
  useEffect(() => {
    if (TagData?.length > 0) {
      setTagData(
        TagData?.map(e => {
          return { value: e.id, label: e.value };
        })
      );
    } else {
      setTagData(tags);
    }
  }, [tags, TagData]);

  return (
    <ContentContainer className={styles.container}>
      <div className={styles.stepperWrapper}>
        <Stepper steps={steps} activeStep={activeStep} className={styles.stepper} />
      </div>
      <Divider />
      <TabPanel value={activeStep} index={0}>
        <div className={styles.tabPanel}>
          <form onSubmit={onNextHandler} className={styles.selectTypePage}>
            <Select
              label="Cloud Account Type"
              placeholder="Select the Cloud Account Type"
              options={cloudTypeOptions}
              value={state.cloudAccountType}
              onChange={val => fieldsHandler(val, "cloudAccountType")}
              required
            />
            {state.cloudAccountType?.value === "Amazon Web Service (AWS)" && (
              <Select
                label="Connection Method"
                placeholder="Select the Connection Method"
                options={connectionMethodOptions}
                value={state.connectionMethod}
                onChange={val => fieldsHandler(val, "connectionMethod")}
                required
              />
            )}
            <div className={styles.btnContainer}>
              <Button variant="outline" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </div>
          </form>
        </div>
      </TabPanel>
      <TabPanel value={activeStep} index={1} style={{ width: "100%" }}>
        <div className={styles.tabPanel}>
          <form onSubmit={onNextHandler} className={styles.selectTypePage}>
            <Select
              label="Label"
              placeholder="Select the label"
              options={[
                { value: "Create a new Label", label: "+ Create a new Label" },
                ...labelData
              ]}
              value={state.labelSelected}
              onChange={val => fieldsHandler(val, "labelSelected")}
              required
              tooltip="Labels are a way to categorize the Asset, Baseline, Findings and Tickets."
            />
            <Select
              label="Tag"
              placeholder="Select the tag"
              options={[{ value: "Create a new Tag", label: "+ Create a new Tag" }, ...tagData]}
              value={state.tagSelected}
              onChange={val => fieldsHandler(val, "tagSelected")}
              // required
              tooltip="Tags are a way to filter different Vulnerabilities, Asset, Controls, Baselines and Findings."
            />
            <div className={styles.btnContainer}>
              <Button variant="outline" onClick={onBackHandler}>
                Back
              </Button>
              <Button variant="outline" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </div>
          </form>
        </div>
      </TabPanel>
      <TabPanel value={activeStep} index={2} style={{ width: "100%" }}>
        <div className={styles.tabPanel}>{renderForm()}</div>
      </TabPanel>
      <AddTagModal
        onChange={setCreateTagState}
        value={createTagState}
        onCloseModal={tagCloseModalHandler}
        isCreateTagLoading={isCreateTagLoading}
        onSubmit={createTagHandler}
        isModalOpen={showAddTagModal}
      />
      <AddLabelModal
        isOpen={showAddLabelModal}
        state={labelModalState}
        onChange={inputChangeHandler}
        onSubmit={addLabelHandler}
        onClose={closeModalHandler}
        isLoading={isSaveLoading}
      />
    </ContentContainer>
  );
};

export default AddAccount;
