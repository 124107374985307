import React, { useState } from "react";
import { Switch, Tabs } from "components/simple";
import PolicyEditorHeader from "./common/components/PolicyEditorHeader";
import KubeArmorPolicy from "./KubeArmorPolicy";
import {
  K8S,
  KUBEARMOR_POLICY,
  NETWORK_POLICY,
  VM
} from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import { updateActiveEditorTab, usePolicyEditorState } from "store/entities/policyEditor";
import {
  updateIsEditingPolicyMetadata,
  updateSelectedEntity,
  useKubeArmorPolicyEditorState
} from "store/entities/policyEditor/kubeArmorPolicyEditor/slice";
import { useDispatch } from "react-redux";
import NetworkPolicy from "./NetworkPolicy";

const PolicyEditor = () => {
  const dispatch = useDispatch();
  const activeEditorTab = usePolicyEditorState("activeEditorTab");
  const selectedEntity = useKubeArmorPolicyEditorState("selectedEntity");

  const editorTabsList = [
    {
      id: 0,
      label: KUBEARMOR_POLICY,
      content: <KubeArmorPolicy />
    }
    // {
    //   id: 1,
    //   label: NETWORK_POLICY,
    //   content: <NetworkPolicy />
    // }
  ];

  const handleEditorTabChange = (event, newValue) => {
    event.stopPropagation();
    dispatch(updateActiveEditorTab(newValue));
  };

  const handleSwitchEntity = value => {
    dispatch(updateIsEditingPolicyMetadata(true));
    if (value) {
      dispatch(updateSelectedEntity(VM));
    } else {
      dispatch(updateSelectedEntity(K8S));
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <PolicyEditorHeader />
      <div className="relative">
        <Tabs tabs={editorTabsList} value={activeEditorTab} handleChange={handleEditorTabChange} />
        {activeEditorTab === 0 ? (
          <div className="absolute left-[60%] top-0 flex items-center justify-center gap-1">
            <p>K8S</p>
            <Switch
              value={Boolean(selectedEntity === VM)}
              onChange={handleSwitchEntity}
              className="ml-0 -mr-3"
            />
            <p>VM</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PolicyEditor;
