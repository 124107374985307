import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import _ from "lodash";
import NodeIcon from "../assets/images/node-icon.png";
import ContainerIcon from "../assets/images/container-icon.png";
import VMIcon from "../assets/images/vm-icon.png";
import ClusterIcon from "../assets/images/cluster-icon.png";
import PodIcon from "../assets/images/pod-icon.png";
import VMGroupIcon from "../assets/images/new-vmgroup.png";
import ProcessIcon from "../assets/images/new-process-icon.png";
import InfoCircle from "../assets/images/info3.png";
import { CLUSTER, CONTAINER, NODE, POD, PROCESS, VMGROUP, VMINSTANCE } from "../constant";
import styles from "./styles.module.scss";
import { PODS_DATA } from "../mockdata";
import { routes } from "router/router";
import { useHistory } from "react-router";
import { uselistofAlertsNamespaceData } from "../service/api";
import { kFormatter } from "helper/helpers";
import {
  EntitySummary,
  graphSummaryData,
  heightDifferenceForContextMenu
} from "../helpers/heightDifferenceForContextMenu";
import GraphInfo from "components/simple/GraphInfo/GraphInfo";

const VisGraph = ({
  selectedView,
  data,
  entityType,
  setSelectedInstanceGroup,
  setSelectedInstance,
  setSelectedClusterId,
  setSelectedNamespaceId,
  setSelectedNamespace,
  setSelectedCluster,
  setSelectedNodeId,
  setPathName,
  onClickNode,
  type,
  EntityDetails,
  setXPos,
  setYPos,
  setShowContextMenu,
  setShowContextMenuAbove,
  setShowContextMenuLeft,
  showContextMenu,
  setRightClickEntity,
  setIsEntityDetailLoading,
  isEntityDetailLoading,
  entitySelectedDetail,
  rightClickEntity,
  setSelectedNode,
  setSelectedPod,
  highLightEntity,
  setHighlightEntity,
  setShowSecurityPosture,
  setNamespaceData,
  selectedCluster,
  range
}) => {
  const [showSummary, setShowSummary] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [showSummaryLeft, setShowSummaryLeft] = useState(false);
  const [view, setView] = useState();
  const container = React.useRef(null);
  const [containers, setContainer] = useState();
  const history = useHistory();
  let timeOut = null;
  const imageWidth = 50;
  const imageHeight = 50;
  const zoom = d3
    .zoom()
    .on("zoom", e => {
      d3.select("#hybridSVG #g-section").attr("transform", e.transform);
    })
    .scaleExtent([0, 20]);
  function initZoom() {
    if (showContextMenu === false) {
      const elem = d3.select("#hybridSVG");
      elem.call(zoom);
    } else {
      const elem = d3.select("#hybridSVG");
      elem.on(".zoom", null);
    }
  }

  const getNearestSquare = n => {
    const sq = Math.ceil(Math.sqrt(n)) + 1;
    return sq;
  };
  const viewSecurityInfo = (event, node) => {
    event.stopPropagation();
    event.preventDefault();
    const maxWidth = event.view.innerWidth;
    const maxHeight = event.view.innerHeight;
    if (maxWidth - event.x < 400) {
      setShowSummaryLeft(true);
    } else {
      setShowSummaryLeft(false);
    }
    const [
      posX,
      posY,
      differenceInHeightInPct,
      differenceInWidthInPct
    ] = heightDifferenceForContextMenu(event.pageX, event.pageY, maxHeight, maxWidth);
    setXPos(posX + "px");
    setYPos(posY + "px");
    setShowContextMenuLeft({
      showLeft: differenceInWidthInPct < 40,
      newXPosFromTable: maxWidth - posX
    });
    setShowContextMenuAbove({
      showAbove: differenceInHeightInPct < 20,
      newYPosFromTable: maxHeight - posY
    });
    setNamespaceData(node);
    setShowSecurityPosture(true);
  };
  const RedirectToLogs = (params, type) => {
    history.push({
      pathname: routes.alertsSummaryLogs,
      state: { data: params, type: type }
    });
  };
  // zoom in and zoom out funciton
  const zoomInHandler = () => {
    d3.select("#hybridSVG")
      .transition()
      .duration(600)
      .ease(d3.easeQuadIn)
      .call(zoom.scaleBy, 1.2);
  };

  const zoomOutHandler = () => {
    d3.select("#hybridSVG")
      .transition()
      .duration(600)
      .ease(d3.easeQuadInOut)
      .call(zoom.scaleBy, 0.8);
  };
  const filterNamespaceId = (id, name) => {
    const keyss = Object.fromEntries(Object.entries(id).filter(([key]) => key.includes(name)));
    return keyss[name][0]?.namespace_id;
  };
  const filterNamespaceAlerts = (id, name) => {
    const keyss = Object.fromEntries(Object.entries(id).filter(([key]) => key.includes(name)));
    return keyss[name][0]?.TotalAlerts;
  };
  useEffect(() => {
    EntitySummary(
      rightClickEntity,
      entitySelectedDetail,
      setSummaryData,
      isEntityDetailLoading,
      summaryData,
      containers,
      clearRightClick
    );
    setIsEntityDetailLoading(false);
  }, [entitySelectedDetail]);
  const unSelectedPath = () => {
    d3.selectAll(`.edge`).classed("selected_path", false);
  };
  const viewRightClickMenu = (event, node) => {
    event.stopPropagation();
    event.preventDefault();
    const maxWidth = event.view.innerWidth;
    const maxHeight = event.view.innerHeight;
    if (maxWidth - event.x < 400) {
      // setShowSummaryLeft(true);
    } else {
      // setShowSummaryLeft(false);
    }
    const [
      posX,
      posY,
      differenceInHeightInPct,
      differenceInWidthInPct
    ] = heightDifferenceForContextMenu(event.pageX, event.pageY, maxHeight, maxWidth);
    // viewInfo(node);
    // selectAll('.label').classed('focussed', false);
    // select(`#n-t-${node.data.id}`).classed('focussed',true);
    setXPos(`${posX}px`);
    setYPos(`${posY}px`);
    setShowContextMenuLeft({
      showLeft: differenceInWidthInPct < 40,
      newXPosFromTable: maxWidth - posX
    });
    setShowContextMenuAbove({
      showAbove: differenceInHeightInPct < 20,
      newYPosFromTable: maxHeight - posY
    });
  };
  const clearRightClick = () => {
    setShowContextMenu(false);
    setHighlightEntity("");
    setShowSummary(false);
    d3.selectAll(".entity").classed(styles.glass, false);
    d3.selectAll(".edge").classed(styles.edge_highlight, false);
  };
  const selectNodal = (val, isNode) => {
    const elem = d3.select(`#i-n-${val}`);
    if (elem) {
      if (elem.classed("node_selected")) {
        d3.selectAll(".entity").classed(styles.node_selected, false);
        d3.selectAll(".entity").classed(styles.glass, false);
      } else {
        //make the node/edge selected
        elem.classed("node_selected", true);

        d3.selectAll(".entity").classed(styles.glass, true);
        elem.classed(styles.glass, false);
        //find all the nodes, connector and label belonging to this image
        d3.select(`#n-${val}`).classed(styles.glass, false);
        d3.select(`#n-t-${val}`).classed(styles.glass, false);

        d3.selectAll(`.edge_val_${val}`).classed(styles.glass, false);
        d3.selectAll(`.edge_val_${val}`).classed(styles.edge_highlight, true);
        // iterate over the connected array and find if my id is matching either a or b.
        // if (isNode) {
        //   connectedArray?.map((c) => {
        //     if (c?.a === `${val}`) {
        //       d3.select(`#i-n-${c?.b}`).classed("glass", false);
        //       d3.select(`#n-t-${c?.b}`).classed("glass", false);
        //     }

        //     if (c?.b === `${val}`) {
        //       d3.select(`#i-n-${c?.a}`).classed("glass", false);
        //       d3.select(`#n-t-${c?.a}`).classed("glass", false);
        //     }
        //   });
        // }
      }
    }
  };
  const onClickNodal = (e, node) => {
    setShowSummary(false);
    unSelectedPath();
    const name = e.target.getAttribute("nameVal");
    let id;
    if (entityType === "process") {
      setPathName(e.target?.__data__?.resource);
      id = node.pid;
    } else {
      id = node.ID;
    }
    const containername = name;
    onClickNode(node);
    // setname(containername);
    // onClickOfNode(e, node);
  };
  const getOuterDimen = (sq, N, w, h) => {
    return {
      w1: w,
      h1: h,
      w2: 150 * sq,
      h2: h + 145 * Math.round(N / sq) + 120
    };
  };

  const getXPosition = (i, sq, origin) => {
    return (i % sq) * 125 + 80 + origin;
  };

  const getYPosition = (i, col, origin) => {
    return Math.floor(i / col) * 145 + origin + 25 + (i % 2) * 65;
  };
  useEffect(() => {
    if (highLightEntity) {
      setTimeout(() => {
        selectNodal(highLightEntity, true);

        const elem = d3.select(`#i-n-${highLightEntity}`);
        let x = elem.attr("x") - 300;
        let y = elem.attr("y");

        d3.select("#hybridSVG")
          .transition()
          .duration(600)
          .ease(d3.easeQuadIn)
          .call(zoom.translateBy, -x, y > 400 ? -(y - 300) : 0);
      }, 200);

      d3.select("#hybridSVG")
        .node()
        .addEventListener("click", () => {
          d3.selectAll(".entity").classed("glass", false);
          d3.selectAll(`.edge_val_${highLightEntity}`).classed("edge_highlight", false);
        });
    }
  }, [highLightEntity]);
  function displayTextWidth(text, font) {
    displayTextWidth.canvas = document.createElement("canvas");
    const myCanvas = displayTextWidth.canvas;
    const context = myCanvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics?.width;
  }

  const renderEntityGroups = (containerRef, groupArr) => {
    containerRef
      .select("#g-section")
      .selectAll(".entity-group")
      .data(groupArr)
      .enter()
      .append("rect")
      .attr("class", `${styles["entity-group"]}`)
      .attr("id", (d, i) => `group- + ${i}`)
      .attr("x", d => d.w1)
      .attr("y", d => d.h1)
      .attr("width", d => d.w2)
      .attr("height", d => d.h2);

    for (let k = 0; k < groupArr.length; k += 1) {
      containerRef
        .select("#g-section")
        .append("text")
        .attr("id", `n-t-g-" + ${k}`)
        .attr("class", `${styles.node_text2} ${styles.entity}`)
        .attr("text-anchor", "start")
        .attr("fill", "#111")
        // .attr("filter", "url(#solid)")
        .attr("x", groupArr[k].w1)
        .attr("y", groupArr[k].h1 - 10)
        .text(groupArr[k].groupName !== "undefined" ? groupArr[k].groupName : "");

      const infoIcon = containerRef
        .select("#g-section")
        .append("g")
        .attr("class", `${styles.infolink}`)
        .on("click", e => {
          clearTimeout(timeOut);
          timeOut = setTimeout(() => {
            viewSecurityInfo(e, groupArr[k]);
          });
        });

      infoIcon
        .append("image")
        .attr("xlink:href", InfoCircle)
        .attr("width", 14)
        .attr("x", () => {
          const text_width = displayTextWidth(groupArr[k].groupName, "normal 14px Inter");
          return groupArr[k].w1 + text_width + 5;
        })
        .attr("y", groupArr[k].h1 - 22)
        .attr("height", 14)
        .attr("ref", `d-i-${groupArr[k].groupName}`);
      infoIcon
        .append("text")
        .text("Info")
        .attr("x", () => {
          const text_width = displayTextWidth(groupArr[k]?.groupName, "normal 14px Inter ");
          return groupArr[k].w1 + text_width + 20;
        })
        .attr("y", groupArr[k].h1 - 10)
        .attr("ref", `d-l-${groupArr[k].groupName}`)
        .attr("class", `${styles.infolink}`);

      const alertCircle = containerRef
        .select("#g-section")
        .append("g")
        .on("click", e => {
          clearTimeout(timeOut);
          timeOut = setTimeout(() => {
            if (groupArr[k]?.alerts) {
              let params = { name: groupArr[k]?.groupName, value: groupArr[k]?.alerts };
              RedirectToLogs(params, "NamespaceName");
            }
          });
        });
      alertCircle
        .append("circle")
        .attr("r", 10)
        .attr("stroke", "#E0E9FB")
        .attr("cx", () => {
          const text_width = displayTextWidth(groupArr[k]?.groupName, "normal 14px Inter ");
          return groupArr[k].w1 + text_width + 60;
        })
        .attr("cy", groupArr[k].h1 - 14)
        .attr("fill", "#E0E9FB")
        .style("cursor", `${groupArr[k]?.alerts ? "pointer" : "none"}`);

      alertCircle
        .append("text")
        .attr("opacity", 1)
        .attr("class", "count entity")
        .attr("y", groupArr[k].h1 - 11)
        .attr("x", () => {
          const text_width = displayTextWidth(groupArr[k]?.groupName, "normal 14px Inter ");
          return groupArr[k].w1 + text_width + 60;
        })
        .attr("text-anchor", "middle")
        .attr("font-size", () => {
          return 10 / ((10 * 10) / 100);
        })
        .style("cursor", `${groupArr[k]?.alerts ? "pointer" : "none"}`)
        .style("fill", "black")
        .text(kFormatter(groupArr[k]?.alerts));
    }
  };

  // const renderPoints = (containerRef, groupArr, newElements) => {
  //   if (selectedView === POD) {
  //     renderEntityGroups(containerRef, groupArr);
  //   }
  // };

  const connectedArray = () => {
    const tmpArr = [];
    // if (false) {
    //   let flows = otherProps?.data?.flows;
    //   flows.map((f) => {
    //     if (dupMap[`${f.from}_${f.to}`]) {
    //       //duplicate so ignoring the flow
    //     } else {
    //       dupMap[`${f.from}_${f.to}`] = true;
    //       tmpArr.push({
    //         a: `${f.from}`,
    //         b: `${f.to}`,
    //         value: `${f.info.connectionType}`,
    //         color: f.info.IsAllowed ? "#249D6F" : "red",
    //         info: f.info,
    //       });
    //     }

    //     tmpArr.push({
    //       a: `${f.from}`,
    //       b: `${f.to}`,
    //       value: `${f.info.connectionType}`,
    //       color: f.info.IsAllowed ? "#249D6F" : "red",
    //       info: f.info,
    //     });
    //   });

    //   //  setConnectArr(tmpArr)
    // }

    // if(otherProps?.type === "vm"){
    //   tmpArr.push({
    //     a: `${otherProps?.data?.nodes[0]?.ID}`,
    //     b: `${otherProps?.data?.nodes[1]?.ID}`,
    //     value: `TCP`,
    //     color: "green",
    //     info: {count:121,type:"TCP"},
    //   });
    // }
    return tmpArr;
  };

  // const nodePosition = (node, containerRef, newElements) => {
  //   const nodeObj = newElements?.filter(d => d?.ID === Number(node));
  //   if (nodeObj[0]) {
  //     return {
  //       x: Number(nodeObj[0].x),
  //       y: Number(nodeObj[0].y)
  //     };
  //   }
  //   return {
  //     x: 0,
  //     y: 0
  //   };
  // };

  const renderOuterCircle = (containerRef, newElements) => {
    const elem = containerRef
      .select("#g-section")
      .selectAll(`${styles["outer-circle"]}`)
      .data(newElements)
      .enter()
      .append("circle");

    elem
      .attr("id", d => `c-${d?.id || d?.ID}`)
      .attr("cx", d => {
        if (selectedView === VMGROUP) {
          return d.x + 20;
        }
        // if (d?.type === "HostLog") {
        //   return d.x + 35;
        // }
        return d.x + 25;
      })
      .attr("cy", d => {
        if (selectedView === VMGROUP) {
          return d.y + 22;
        }
        if (selectedView === VMINSTANCE) {
          return d.y + 25;
        }
        if (selectedView === CONTAINER) {
          return d.y + 23;
        }
        return d.y + 27;
      })
      .attr("class", "outer-circle")
      .style("fill", "#326CE526");
  };

  const lableXpoint = ele => {
    // if (entitiesIcon === "process") {
    //   return ele ? 34 + ele.x : 0;
    // } else if (entitiesIcon === "vmgroup") {
    //   return ele ? 20 + ele.x : 0;
    // } else if (entitiesIcon === "vm") {
    //   return ele ? 32 + ele.x : 0;
    // } else {
    return ele ? 24 + ele.x : 0;
    // }
  };
  const labelYpoint = ele => {
    // if (entitiesIcon === "vmgroup") {
    //   return ele ? 65 + ele.y : 0;
    // } else {
    return ele ? 65 + ele.y : 0;
    // }
  };
  const renderPoints = (containerRef, groupArr, newElements) => {
    // setGroupList(getGroups(elements))
    if (selectedView === POD) {
      renderEntityGroups(containerRef, groupArr);
    }
    // renderEntityGhost(containerRef,newElements);
  };

  const renderEntityLabel = (containerRef, newElements) => {
    const textValue = [];

    newElements?.map(ele => {
      if (selectedView === CLUSTER || selectedView === POD || selectedView === CONTAINER) {
        return textValue.push(` ${ele?.name}`);
      }
      if (selectedView === VMGROUP) {
        return textValue.push(` ${ele?.Group_Name}`);
      }
      if (selectedView === VMINSTANCE) {
        return textValue.push(` ${ele?.Instance_Name}`);
      }
      if (selectedView === PROCESS) {
        return textValue.push(` ${ele?.service_name}`);
      }
      return textValue.push(` ${ele?.name}`);
    });

    // containerRef
    //   .select("#g-section")
    //   .selectAll("text")
    //   .data(elements)
    //   .enter()
    //   .append("rect")
    //   .attr("fill", "#fff")
    //   .attr("width", "100")
    //   .attr("height", "50")
    //   .attr("x", (ele, i) => (ele ? ALIGN_X + 44 + getAlignment(i).x : 0))
    //   .attr("y", (ele, i) => (ele ? (ALIGN_Y + 100 + getAlignment(i).y) : 0))

    const labelText = containerRef
      .select("#g-section")
      .selectAll(".node_text")
      .data(newElements)
      .enter()
      .append("text")
      .attr("id", ele => `n-t-${ele?.id || ele?.process_id || ele?.pid || ele?.ID}`)
      .attr("class", "node_text entity")
      .attr("text-anchor", "middle")
      .attr("fill", "#555");
    // .attr("filter", "url(#solid)")
    // .text((ele, i) => (ele ? textValue[i] : ""));

    // Add a <tspan> for every data element in <text>
    labelText
      .append("tspan")
      .text((e, i) =>
        e && textValue[i].length > 25 ? textValue[i].substring(0, 25) : textValue[i]
      )
      .attr("x", (e, i) => lableXpoint(e, i))
      .attr("y", (e, i) => labelYpoint(e, i))
      .attr("class", "lable1");

    labelText
      .append("tspan")
      .text((e, i) => (e && textValue[i].length > 25 ? textValue[i].substring(25, 50) : ""))
      .attr("x", (e, i) => lableXpoint(e, i))
      .attr("y", (e, i) => labelYpoint(e, i) + 20)
      .attr("class", "lable2");

    labelText
      .append("tspan")
      .text((e, i) => (e && textValue[i].length > 50 ? textValue[i].substring(50) : ""))
      .attr("x", (e, i) => lableXpoint(e, i))
      .attr("y", (e, i) => labelYpoint(e, i) + 30)
      .attr("class", "lable3");
  };

  const getGroups = arr => {
    return _.groupBy(arr, "namespace");
  };
  const renderEntityImages = (containerRef, newElements) => {
    // containerRef.style("background-color", "#FBFCFF");
    const elem = containerRef
      .select("#g-section")
      .selectAll(".image")
      .data(newElements)
      .enter()
      .append("image");
    elem
      .attr("id", d => `i-n-${d?.id || d?.ID}`)
      .attr("class", `${styles.node_image} entity`)
      .attr("x", d => (d ? d.x : 0))
      .attr("y", d => (d ? d.y : 0))
      .attr("width", () => {
        // if (entitiesIcon === "process") {
        //   return imageWidth + 20;
        // } else if (entitiesIcon === "vmgroup") {
        //   return imageWidth - 10;
        // } else {
        return imageWidth;
        // }
      })
      .attr("height", () => {
        // if (entitiesIcon === "process") {
        //   return imageHeight + 5;
        // } else if (entitiesIcon === "vmgroup") {
        //   return imageHeight - 5;
        // } else {
        return imageHeight;
        // }
      })
      .attr("idVal", d => d?.ID)
      .attr("nameVal", d => d?.name)
      .attr("a", d => d?.id)
      .attr("data", d => d)
      .attr("xlink:href", d => {
        if (selectedView === "datapipeline-vm") {
          return VMIcon;
        }
        if (selectedView === NODE) {
          return NodeIcon;
        }
        if (selectedView === CLUSTER) {
          return ClusterIcon;
        }
        if (selectedView === POD) {
          return PodIcon;
        }
        if (selectedView === CONTAINER) {
          return ContainerIcon;
        }
        if (selectedView === VMGROUP) {
          return VMGroupIcon;
        }
        if (selectedView === VMINSTANCE) {
          return VMIcon;
        }
        if (selectedView === PROCESS) {
          return ProcessIcon;
        }
      })
      .on("click", function(e, obj) {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          setSummaryData(graphSummaryData(obj, selectedView));
          setShowSummary(true);
          viewRightClickMenu(e, obj);
          if (selectedView === CLUSTER) {
            setSelectedCluster({
              id: obj?.ID,
              value: obj?.name,
              label: obj?.name
            });
          }
          // if(selectedView  === NODE){
          //   setSelectedNode({
          //     label: obj.name,
          //     value: obj.ID
          //   })
          // }
          if (selectedView === POD) {
            setSelectedNamespace({
              label: obj?.namespace,
              value: obj?.namespace_id
            });
            setSelectedPod({
              label: obj?.name,
              value: obj?.ID
            });
          }
          if (selectedView !== CONTAINER) {
            if (selectedView !== NODE) {
              setIsEntityDetailLoading(true);
            }
            setShowContextMenu(true);
            setRightClickEntity(obj);
          }
        }, 200);
      })
      .on("dblclick", (e, node) => {
        d3.select("#hybridSVG")
          .transition()
          .duration(300)
          .call(zoom.transform, d3.zoomIdentity);
        clearTimeout(timeOut);
        if (selectedView !== CONTAINER) {
          onClickNodal(e, node);
        }
      })
      .on("mouseover", (e, node) => {
        d3.select(`#c-${node?.ID || node?.id}`)
          .transition()
          .duration(200)
          .ease(d3.easeQuadOut)
          .attr("r", 30);
      })
      .on("mouseout", (e, node) => {
        d3.select(`#c-${node?.ID || node?.id}`)
          .transition()
          .duration(400)
          .ease(d3.easeQuadOut)
          .attr("r", 0);
      });
  };
  const renderEntityCount = (containerRef, newElements) => {
    if (selectedView === CLUSTER) {
      const elem1 = containerRef
        .select("#g-section")
        .selectAll(".countbox")
        .data(newElements)
        .enter();
      elem1
        .append("circle")
        .attr("id", d => `i-n-r-${d?.id || d?.ID}`)
        .attr("class", `entity`)
        .attr("r", 10)
        .attr("stroke", "#E0E9FB")
        .attr("cx", d => d.x + 40)
        .attr("cy", d => d.y - 3)
        .attr("fill", "#E0E9FB")
        .style("cursor", "pointer")
        .on("click", (e, data) => {
          if (data?.TotalAlerts) {
            let params = { name: data?.name, value: data?.TotalAlerts };
            RedirectToLogs(params, "Cluster");
          }
        });

      elem1
        .append("text")
        .attr("opacity", 1)
        .attr("class", "count entity")
        .attr("id", d => `i-n--r-c-${d?.data?.id}`)
        // .attr("y", d => {
        //   return d?.y + 3 - 10 / ((10 * 25) / 100);
        // })
        .attr("y", d => d?.y + 1)
        .attr("x", d => d?.x + 40)
        .attr("text-anchor", "middle")
        .attr("font-size", () => {
          return 10 / ((10 * 10) / 100);
        })
        .style("cursor", "pointer")
        .style("fill", "black")
        .text(d => kFormatter(d?.TotalAlerts) || 0)
        .on("click", (e, data) => {
          if (data?.TotalAlerts) {
            let params = { name: data?.name, value: data?.TotalAlerts };
            RedirectToLogs(params, "Cluster");
          }
        });
    }
  };
  useEffect(() => {
    d3.selectAll("rect").remove();
    d3.selectAll("image").remove();
    d3.selectAll("text").remove();
    d3.selectAll(".edge").remove();
    d3.selectAll("circle").remove();

    // Grouping the data using "group"
    const containerRef = d3.select(container.current);
    let ele;
    if (selectedView === POD) {
      ele = _.orderBy(data, ["namespace"], ["asc"]);
    } else {
      ele = data;
    }
    const groupObj = getGroups(ele);
    const keys = Object.keys(groupObj);
    const values = Object.values(groupObj);
    const groupList = [];
    // Create the Square which Contain number of Pods Before Image Rendering
    let nextOrigin = 50;
    const groupOriginMap = {};
    for (let i = 0; i < keys.length; i += 1) {
      const prevOrigin = nextOrigin;
      const tmpObj = getOuterDimen(
        getNearestSquare(values[i].length),
        values[i].length,
        prevOrigin,
        50
      );
      //  W1+W2 is a whole with of container of each group
      nextOrigin = tmpObj.w1 + tmpObj.w2 + 70;
      groupOriginMap[keys[i]] = [prevOrigin, 0];
      groupList.push({
        ...tmpObj,
        groupName: keys[i],
        namespace_id: filterNamespaceId(groupObj, keys[i]),
        alerts: filterNamespaceAlerts(groupObj, keys[i])
      });
    }

    const newElements = [];
    for (let i = 0; i < keys.length; i += 1) {
      for (let j = 0; j < values[i].length; j += 1) {
        const groupElements = values[i];
        const sq2 = getNearestSquare(groupElements.length);
        const obj = groupElements[j];
        //  Split the GroupName
        const groupName = obj.namespace;
        // elements[i] = {
        //     ...obj,
        //     x:getXPosition(j,sq2,groupOriginMap[groupName][0]),
        //     y:getYPosition(j,sq2,0),
        // }
        newElements.push({
          ...obj,
          x: getXPosition(j, sq2, groupOriginMap[groupName][0]),
          y: getYPosition(j, sq2, 50)
        });
      }
    }
    renderPoints(containerRef, groupList, newElements);
    // renderConnectors(containerRef);
    renderEntityLabel(containerRef, newElements);
    renderOuterCircle(containerRef, newElements);
    // // renderConnectors(containerRef, newElements);
    renderEntityImages(containerRef, newElements);
    initZoom();
    renderEntityCount(containerRef, newElements);
    containerRef.on("click", clearRightClick);
    setView(containerRef);
  }, [data]);
  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <svg
        id="hybridSVG"
        viewBox="100 20 600 600"
        ref={container}
        style={{
          height: "100%",
          width: "100%",
          position: "relative"
          // background: backgroundColor,
        }}
        onDrag={e => {}}
        onClick={() => {
          setShowSummary(false);
          unSelectedPath();
          setShowSecurityPosture(false);
        }}
      >
        <g id="g-section"></g>
      </svg>
      {/* <div id="outer-wrapper"></div> */}
      {showSummary && (
        <GraphInfo
          heading={summaryData?.name}
          listData={summaryData}
          onClose={() => setShowSummary(false)}
          isEntityDetailsLoading={isEntityDetailLoading}
        />
      )}
      <div className="absolute flex flex-col justify-between h-10 bottom-20 left-5">
        <button
          type="button"
          onClick={zoomInHandler}
          className={`${styles.btn} ${styles["zoom-in-btn"]}`}
        >
          +
        </button>
        <button
          type="button"
          onClick={zoomOutHandler}
          className={`${styles.btn} ${styles["zoom-out-btn"]}`}
        >
          -
        </button>
      </div>
    </div>
  );
};

export default VisGraph;
