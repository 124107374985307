import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "../notifications/actions";
import { navigate } from "helper/history";

const getCloudParams = state => ({
  page: state.cloudAccounts.params.page,
  pageSize: state.cloudAccounts.params.pageSize,
  search: state.cloudAccounts.params.search,
  ordering: state.cloudAccounts.params.ordering
});

// Workers

// Set search params
function* setCloudParamsWorker() {
  const { page, pageSize, search, ordering } = yield select(getCloudParams);
  yield put(
    actions.getCloudAccounts({
      page,
      pageSize,
      search,
      ordering
    })
  );
}

// Get cloud accounts
function* getCloudAccountsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getCloudAccounts), payload);
    yield put(actions.getCloudAccountsSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getCloudAccountsFailed(error));
  }
}

// Get aws regions
function* getAwsRegionsWorker() {
  try {
    const response = yield call(makeRequest(service.getAwsRegions));
    yield put(actions.getAwsRegionsSuccess(response));
  } catch (error) {
    yield put(actions.getAwsRegionsFailed(error));
  }
}

// Add Azure Account
function* addAzureAccountWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addAzureAccount), payload.data);
    yield put(actions.addAzureAccountSuccess(response));
    yield put(addNotification({ msg: "Account Connected Successfully", type: "success" }));
    yield call(navigate, "/settings/cloud-accounts");
  } catch (error) {
    const err = yield call(() => error.json());
    if (err.non_field_errors) {
      yield put(addNotification({ msg: err.non_field_errors, type: "error" }));
    } else {
      yield put(addNotification({ msg: errorHandler(error), type: "error" }));
      yield put(actions.addAzureAccountFailed(error));
    }
  }
}

// Get Azure Account
function* getAzureAccountWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAzureAccount), payload);
    yield put(actions.getAzureAccountSuccess(response));
  } catch (error) {
    yield put(actions.getAzureAccountFailed(error));
  }
}

// Add google cloud account
function* addGoogleCloudAccountWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addGoogleCloudAccount), payload);
    yield put(actions.addGoogleCloudAccountSuccess(response));
    yield put(addNotification({ msg: "Account Connected Successfully", type: "success" }));
    yield call(navigate, "/settings/cloud-accounts");
  } catch (error) {
    const err = yield call(() => error.json());
    if (err.non_field_errors) {
      yield put(addNotification({ msg: err.non_field_errors, type: "error" }));
    } else {
      yield put(addNotification({ msg: errorHandler(error), type: "error" }));
      yield put(actions.addGoogleCloudAccountFailed(error));
    }
  }
}

// Get GCP Account
function* getGCPAccountWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getGCPAccount), payload);
    yield put(actions.getGCPAccountSuccess(response));
  } catch (error) {
    yield put(actions.getGCPAccountFailed(error));
  }
}

// Add aws cloud manual setup
function* addAwsCloudManualWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addAwsCloudManual), payload);
    yield put(actions.addAwsCloudManualSetupSuccess(response));
    yield put(addNotification({ msg: "Account Connected Successfully", type: "success" }));
    yield call(navigate, "/settings/cloud-accounts");
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addAwsCloudManualSetupFailed(error));
  }
}

// Get aws manual Account
function* getAWSManualAccountWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAWSManualAccount), payload);
    yield put(actions.getAWSManualAccountSuccess(response));
  } catch (error) {
    yield put(actions.getAWSManualAccountFailed(error));
  }
}

// Add aws cloud manual setup
function* addAwsCloudAccessKeyWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addAwsCloudAccessKey), payload);
    yield put(actions.addAwsCloudAccessKeySuccess(response));
    yield put(
      addNotification({
        msg: "Account Connected Successfully. It will take few minutes to view the details.",
        type: "success"
      })
    );
    yield call(navigate, "/settings/cloud-accounts");
  } catch (error) {
    const res = yield call(() => error.json());
    if (res?.non_field_errors) {
      yield put(addNotification({ msg: res?.non_field_errors, type: "error" }));
    } else if (res?.access_key_id && res?.secret_access_key && res?.region) {
      yield put(
        addNotification({
          msg: [
            "Access Key must not be blank.",
            "Secret Key must not be blank.",
            "You must select a region."
          ],
          type: "error"
        })
      );
    } else if (res?.region) {
      yield put(addNotification({ msg: res?.region, type: "error" }));
    } else if (res?.secret_access_key) {
      yield put(addNotification({ msg: "Secret Key must not be blank", type: "error" }));
    } else if (res?.access_key_id) {
      yield put(addNotification({ msg: "Access Key must not be blank", type: "error" }));
    } else {
      yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    }
    yield put(actions.addAwsCloudAccessKeyFailed(error));
  }
}

// Get aws access Account
function* getAWSAccessAccountWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAWSAccessAccount), payload);
    yield put(actions.getAWSAccessAccountSuccess(response));
  } catch (error) {
    yield put(actions.getAWSAccessAccountFailed(error));
  }
}

// Change enabled status
function* changeEnabledStatusWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.changeEnabledStatus), payload);
    yield put(actions.changeEnabledStatusSuccess(response));
    yield put(actions.getCloudAccounts());
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.changeEnabledStatusFailed(error));
  }
}

// Run account scan
function* runAccountScanWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.runAccountScan), payload);
    yield put(actions.runAccountScanSuccess(response));
    yield put(addNotification({ msg: response, type: "success" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.runAccountScanFailed(error));
  }
}

// Delete Account
function* deleteCloudAccountWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteCloudAccount), payload);
    yield put(actions.deleteCloudAccountSuccess(response));
    yield put(actions.getCloudAccounts());
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.deleteCloudAccountFailed(error));
  }
}

// Update account
function* updateConnectionWorker({ payload }) {
  try {
    let response;
    switch (payload.accountType) {
      case "azure":
        response = yield call(makeRequest(service.updateAzureAccount), payload);
        break;
      case "gcp":
        response = yield call(makeRequest(service.updateGCPAccount), payload);
        break;
      case "aws":
        response = yield call(makeRequest(service.updateAWSAccess), payload);
        break;
      case "aws_assume":
        response = yield call(makeRequest(service.updateAWSManual), payload);
        break;
      default:
        return;
    }
    yield put(actions.updateConnectionSuccess(response));
    yield put(actions.getCloudAccounts());
    yield call(navigate, "/settings/cloud-accounts");
    yield put(addNotification({ msg: "Account Updated Successfully", type: "success" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.updateConnectionFailed(error));
  }
}

// Watchers

function* watcherSetCloudParams() {
  yield takeEvery(actions.setCloudParams, setCloudParamsWorker);
}

function* watcherGetCloudAccounts() {
  yield takeEvery(actions.getCloudAccounts, getCloudAccountsWorker);
}

function* watcherGetAwsRegions() {
  yield takeEvery(actions.getAwsRegions, getAwsRegionsWorker);
}

function* watcherAddAzureAccount() {
  yield takeEvery(actions.addAzureAccount, addAzureAccountWorker);
}

function* watcherGetAzureAccount() {
  yield takeEvery(actions.getAzureAccount, getAzureAccountWorker);
}

function* watcherAddGoogleCloudAccount() {
  yield takeEvery(actions.addGoogleCloudAccount, addGoogleCloudAccountWorker);
}

function* watcherGetGCPAccount() {
  yield takeEvery(actions.getGCPAccount, getGCPAccountWorker);
}

function* watcherAddAwsCloudManual() {
  yield takeEvery(actions.addAwsCloudManualSetup, addAwsCloudManualWorker);
}

function* watcherGetAWSManualAccount() {
  yield takeEvery(actions.getAWSManualAccount, getAWSManualAccountWorker);
}

function* watcherAddAwsCloudAccessKey() {
  yield takeEvery(actions.addAwsCloudAccessKey, addAwsCloudAccessKeyWorker);
}

function* watcherGetAWSAccessAccount() {
  yield takeEvery(actions.getAWSAccessAccount, getAWSAccessAccountWorker);
}

function* watcherChangeEnabledStatus() {
  yield takeEvery(actions.changeEnabledStatus, changeEnabledStatusWorker);
}

function* watcherRunAccountScan() {
  yield takeEvery(actions.runAccountScan, runAccountScanWorker);
}

function* watcherDeleteCloudAccount() {
  yield takeEvery(actions.deleteCloudAccount, deleteCloudAccountWorker);
}

function* watcherUpdateConnection() {
  yield takeEvery(actions.updateConnection, updateConnectionWorker);
}

// ROOT SAGA

export default function* rootSaga() {
  yield all([
    fork(watcherSetCloudParams),
    fork(watcherAddAzureAccount),
    fork(watcherAddGoogleCloudAccount),
    fork(watcherGetCloudAccounts),
    fork(watcherAddAwsCloudManual),
    fork(watcherAddAwsCloudAccessKey),
    fork(watcherGetAwsRegions),
    fork(watcherChangeEnabledStatus),
    fork(watcherDeleteCloudAccount),
    fork(watcherUpdateConnection),
    fork(watcherGetAzureAccount),
    fork(watcherGetGCPAccount),
    fork(watcherGetAWSManualAccount),
    fork(watcherGetAWSAccessAccount),
    fork(watcherRunAccountScan)
  ]);
}
