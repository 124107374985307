import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { alpha, Checkbox, Switch, TablePagination, Toolbar, Tooltip } from "@mui/material";
import { handleSwitch } from "../service";
import { customPost, postFormData } from "api/base";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import EditIcon from "@mui/icons-material/Edit";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { addNotification } from "store/entities/notifications/actions";
import styles from "../styles.module.scss";
import moment from "moment-timezone";
import EditTrigger from "./EditTrigger/EditTrigger";
import { useDeleteTrigger, useHandleSwitch } from "store/entities/datapipeline/queries";
import { CustomPagination } from "components/simple";
import {
  updatePageId,
  updatePageSize,
  useDatapipelineState
} from "store/entities/datapipeline/slice";
import NoTableRowData from "components/complex/Table/NoTableRowData";

function EnhancedTableToolbar(props) {
  const { numSelected, filterID } = props;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const deleteTrigger = useDeleteTrigger(data => {
    queryClient.invalidateQueries("fetchTrigersData");
    dispatch(addNotification({ msg: "Trigger Deleted!", type: "success" }));
  });
  const handleDelete = async () => {
    const trigger_id = numSelected?.join(",");
    const filter_id = filterID;
    deleteTrigger.mutate({
      filter_id,
      trigger_id
    });
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected.length > 0 && {
          bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}
    >
      {numSelected.length > 0 && (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {numSelected.length} selected
        </Typography>
      )}

      {numSelected.length > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon onClick={handleDelete} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

function Row(props) {
  const { row, isItemSelected, handleClick } = props;
  const [open, setOpen] = React.useState(false);
  const [showEditTrigger, setShowEditTrigger] = useState(false);
  const closeHandle = () => {
    setShowEditTrigger(false);
  };
  const switchStyle = {
    borderRadius: 2,
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white"
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "green"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "red"
    }
  };
  const [toggle, setToggle] = useState(row?.Toggle === true ? true : false);
  // useEffect(() => {
  //   setToggle(!toggle);
  // }, [toggle]);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleSwitch = useHandleSwitch(data => {
    queryClient.invalidateQueries("fetchTrigersData");
    dispatch(addNotification({ msg: "Trigger Updated!", type: "success" }));
  });
  const handleToggle = async (e, row) => {
    const id = row?.ID;
    const toggleChange = toggle ? false : true;
    setToggle(!toggle);
    handleSwitch.mutate({
      id,
      toggleChange
    });
  };
  const handleRowClick = () => {};
  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={event => handleRowClick(event, row?.ID)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row?.ID}
        selected={isItemSelected}
      >
        <TableCell className="selectCheckbox" padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            hover
            className="selectCheckbox"
            onClick={event => handleClick(event, row?.ID, row?.FilterID)}
            role="checkbox"
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <div className="flex gap-2">
            {row?.Priority === "Medium" ? (
              <span className={styles.normal} />
            ) : row?.Priority === "High" ? (
              <span className={styles.critical} />
            ) : (
              <span className={styles.low} />
            )}
            {row.TriggerName}
          </div>
        </TableCell>
        <TableCell align="inherit">{moment(row?.CreatedAt).format("MM-DD-YY HH:mm z")}</TableCell>
        <TableCell align="inherit">
          {row?.ChannelID === 1
            ? "Slack"
            : row?.ChannelID === 2
            ? "Splunk"
            : row?.ChannelID === 3
            ? "Cloud"
            : row?.ChannelID === 4
            ? "Elastic"
            : row?.ChannelID === 5
            ? "Rsyslog"
            : row?.ChannelID === 6
            ? "Jira"
            : ""}
        </TableCell>
        <TableCell align="right">
          <Switch
            sx={switchStyle}
            checked={toggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={e => handleToggle(e, row)}
          />
        </TableCell>
        <TableCell>
          <EditIcon
            className={`${styles.icon_pencil} cursor-pointer`}
            onClick={() => {
              setShowEditTrigger(true);
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow style={{ borderBottom: "1px solid #e0e0e0" }}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            border: "0px solid",
            borderBottom: "0px solid"
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: "15px", display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "16px" }}>Query : </Typography>
              <Typography
                sx={{
                  marginLeft: "10px",
                  display: "flex",
                  width: "90%",
                  padding: "15px",
                  borderRadius: "10px",
                  backgroundColor: "#ebebeb",
                  fontSize: "14px"
                }}
                variant="body1"
              >
                {row?.FilterQuery}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <EditTrigger showEditTrigger={showEditTrigger} setShowEditTrigger={closeHandle} data={row} />
    </React.Fragment>
  );
}

export default function CollapsibleTable({ rows, count }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [filterID, setFilterID] = useState([]);
  const dispatch = useDispatch();
  const pageId = useDatapipelineState("pageId");
  const pageSize = useDatapipelineState("pageSize");
  const handleClick = (event, name, filters_id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    const selectedFilter = filterID.indexOf(filters_id);
    let newSelected = [];
    let newSelectedFilter = [];

    if (selectedIndex === -1 && selectedFilter === -1) {
      newSelected = newSelected.concat(selected, name);
      newSelectedFilter = newSelectedFilter.concat(filterID, filters_id);
    } else if (selectedIndex === 0 && selectedFilter === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedFilter = newSelectedFilter.concat(filterID.slice(1));
    } else if (selectedIndex === selected.length - 1 && selectedFilter === filterID.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedFilter = newSelectedFilter.concat(filterID.slice(0, -1));
    } else if (selectedIndex > 0 && selectedFilter > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedFilter = newSelectedFilter.concat(
        filterID.slice(0, selectedFilter),
        filterID.slice(selectedFilter + 1)
      );
    }
    setFilterID(newSelectedFilter?.join(","));
    setSelected(newSelected);
  };

  const handleChangePage = (event, value) => {
    dispatch(updatePageId(value));
  };
  const handleChangeRowsPerPage = event => {
    dispatch(updatePageId(1));
    dispatch(updatePageSize(event.target.value));
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <Box>
      <TableContainer component={Paper}>
        {selected?.length > 0 && (
          <EnhancedTableToolbar numSelected={selected} filterID={filterID} />
        )}
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>AlertTrigger</TableCell>
              <TableCell align="inherit">Created At</TableCell>
              <TableCell align="inherit">Channel</TableCell>
              <TableCell align="right">Enable/Disable</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.map(row => {
              const isItemSelected = isSelected(row?.ID);
              return (
                <>
                  <Row
                    isItemSelected={isItemSelected}
                    setSelected={setSelected}
                    key={row?.ID}
                    row={row}
                    handleClick={handleClick}
                  />
                </>
              );
            })}

            {!rows?.length && (
              <TableRow>
                <TableCell colSpan={7} className="text-center" sx={{ height: 500 }}>
                  <NoTableRowData />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows?.length > 0 && (
        <CustomPagination
          count={
            count % pageSize <= 0
              ? Math.floor(count / pageSize) || 0
              : Math.floor(count / pageSize) + 1 || 0
          }
          onChange={handleChangePage}
          page={pageId}
          selected={pageSize}
          totalCount={count}
          perPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
