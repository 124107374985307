import React, { useState, useEffect } from "react";

import { Title, Modal } from "components/simple";
import { Table } from "components/complex";

import { getMonitorDatalist } from "store/entities/monitors/service";
import renderCellExpand from "helper/renderCellExpand";

import styles from "./styles.module.scss";

const renderCol = (obj, dict) => {
  const res = Object.entries(obj)
    .filter(([key]) => dict?.[key] || key === "id")
    .map(([key]) => {
      return {
        headerName: dict?.[key],
        field: key,
        resizable: true,
        minWidth: 10,
        flex: 1,
        renderCell: renderCellExpand,
        headerClassName: "super-app-theme--header",
        ...{
          ...(key === "id"
            ? {
                hide: !dict?.id
              }
            : {})
        }
      };
    });
  return res;
};

const DetailsHistoryModal = ({ open, data, close, dict }) => {
  const [state, setState] = useState({ data: [], columns: [] });
  const [isLoading, setIsLoading] = useState(true);

  const request = () => {
    setIsLoading(true);
    getMonitorDatalist({ id: data?.id })
      .then(r => r.json())
      .then(res => {
        const result = res.map((item, index) => {
          return {
            id: index,
            ...item
          };
        });
        const col = renderCol(result[0], dict);
        setState({ data: result, columns: col });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      request();
    }
    return () => {
      setState({ data: [], columns: [] });
    };
  }, [open]);

  const customStyles = {
    height: 500,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      borderTop: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      // borderRadius: "10px",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important"
      }
    }
  };

  const tableConfig = {
    checkboxVisible: false,
    paginationVisible: false,
    hideFooter: false,
    sortingMode: false
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>{data.title}</Title>
      <div className={styles.content}>
        <Table
          data={isLoading ? [] : state?.data}
          columns={state?.columns}
          loading={isLoading}
          customStyles={customStyles}
          {...tableConfig}
        />
      </div>
    </Modal>
  );
};

export default DetailsHistoryModal;
