import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Input, Select } from "components/simple";
import { useDispatch, useSelector } from "react-redux";
import { addAwsCloudManualSetup, getAwsRegions } from "store/entities/cloudAccounts/actions";
import { getAwsRegionsSelector } from "store/entities/cloudAccounts/selectors";
import StepsToGet from "../StepsToGet";
import { addNotification } from "store/entities/notifications/actions";

import styles from "./styles.module.scss";

const steps = {
  roleARN: {
    title: "Role ARN",
    steps: [
      "Log into your AWS account and navigate to the IAM console.",
      "Create a new IAM role.",
      'When prompted for a "trusted entity type" select: "AWS account".',
      'Choose "Another AWS account" and Enter "199488642388" for the Account ID.',
      'Check the box to "Require external ID" and enter the external ID displayed at the bottom.',
      'Ensure that "Require MFA" is not selected.',
      'Select the "SecurityAudit" managed policy.',
      "Enter a memorable role name and create the role.",
      "Then click on the role name and copy the role ARN to paste it here"
    ]
  },
  secretAccessKey: {
    title: "Access Key",
    steps: (
      <div>
        <div className={styles.stepTitle}>Via console:</div>
        <p className={styles.step}>
          1. Use your AWS account ID or account alias, your IAM user name, and your password to sign
          in to the IAM console.
        </p>
        <p className={styles.step}>
          2. In the navigation bar on the upper right, choose your user name, and then choose
          Security credentials.
        </p>
        <p className={styles.step}>
          3. Expand the Access keys (access key ID and secret access key) section.
        </p>
        <p className={styles.step}>
          4. Do any of the following: To create an access key, choose Create New Access Key.
        </p>
        <div className={styles.stepTitle}>Via AWS CLI:</div>
        <p className={styles.step}>To create an access key:</p>
        <p className={styles.step}>aws iam create-access-key </p>
        <div className={styles.stepTitle}>Permissions:</div>
        <p className={styles.step}>Grant the ReadOnlyAccess policy to your user or role.</p>
      </div>
    )
  }
};

const ManualSetup = ({ onBackHandler, onCancelHandler, label, tag }) => {
  const dispatch = useDispatch();
  const awsRegions = useSelector(getAwsRegionsSelector);
  const [getSteps, setGetSteps] = useState("");
  const [state, setState] = useState({
    externalId: "",
    roleARN: "",
    accessKeyId: "",
    secretAccessKey: "",
    region: []
  });

  const onSave = e => {
    e.preventDefault();
    const data = {
      secret_access_key: state.secretAccessKey,
      access_key_id: state.accessKeyId,
      region: state.region,
      external_id: state.externalId,
      role_arn: state.roleARN,
      label: label.value,
      tag: tag?.value || null
    };
    dispatch(addAwsCloudManualSetup(data));
    dispatch(
      addNotification({
        msg: "Account connected successfully. It will take few minutes to view the details.",
        type: "success"
      })
    );
  };

  const onSelectAll = () => {
    if (state.region.length === awsRegions.length) {
      setState(prev => ({ ...prev, region: [] }));
      return;
    }
    setState(prev => ({ ...prev, region: awsRegions.map(reg => reg.value) }));
  };

  const onRegionChange = region => value => {
    if (value) {
      setState(prevSt => ({ ...prevSt, region: [...prevSt.region, region] }));
      return;
    }
    setState(prevSt => ({ ...prevSt, region: [...prevSt.region.filter(reg => reg !== region)] }));
  };

  const closeSteps = useCallback(() => {
    setGetSteps("");
  }, [setGetSteps]);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  useEffect(() => {
    dispatch(getAwsRegions());
  }, []);

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSave}>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>External ID</span>
          </div>
          <Input
            type="text"
            placeholder="Enter the External ID*"
            value={state.externalId}
            onChange={val => handleChange(val.target.value, "externalId")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Role ARN*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("roleARN")}>
              Show steps
            </span>
          </div>
          <Input
            type="text"
            placeholder="Enter the Role ARN"
            value={state.roleARN}
            onChange={val => handleChange(val.target.value, "roleARN")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Access Key ID*</span>
          </div>
          <Input
            type="text"
            placeholder="Enter the Access Key ID*"
            value={state.accessKeyId}
            onChange={val => handleChange(val.target.value, "accessKeyId")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Secret Access Key*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("secretAccessKey")}>
              Show steps
            </span>
          </div>
          <Input
            type="text"
            placeholder="Enter the Secret Access Key"
            value={state.secretAccessKey}
            onChange={val => handleChange(val.target.value, "secretAccessKey")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Region*</span>
            <div className={styles.select} onClick={onSelectAll}>
              {state.region.length === awsRegions.length ? "Unselect All" : "Select All"}
            </div>
          </div>
          <div className={styles.regionsContainer}>
            {awsRegions.map(region => (
              <Checkbox
                key={region.value}
                label={region.label}
                onChange={onRegionChange(region.label)}
                value={state.region.includes(region.label)}
              />
            ))}
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button variant="outline" onClick={onBackHandler}>
            Back
          </Button>
          <Button variant="outline" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Connect</Button>
        </div>
      </form>
      {getSteps && (
        <div className={styles.stepsContainer}>
          <StepsToGet steps={steps[getSteps]} onClose={closeSteps} />
        </div>
      )}
    </div>
  );
};

export default ManualSetup;
