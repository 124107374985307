import React from "react";
import PropTypes from "prop-types";

export default function DatapipelineIcon(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.color}
        d="M5.33789 3H19.3379C20.4379 3 21.3379 3.9 21.3379 5V19C21.3379 20.1 20.4379 21 19.3379 21H5.33789C4.23789 21 3.33789 20.1 3.33789 19V5C3.33789 3.9 4.23789 3 5.33789 3ZM7.33789 16C7.33789 16.55 7.78789 17 8.33789 17C8.88789 17 9.33789 16.55 9.33789 16V11C9.33789 10.45 8.88789 10 8.33789 10C7.78789 10 7.33789 10.45 7.33789 11V16ZM12.3379 17C11.7879 17 11.3379 16.55 11.3379 16V8C11.3379 7.45 11.7879 7 12.3379 7C12.8879 7 13.3379 7.45 13.3379 8V16C13.3379 16.55 12.8879 17 12.3379 17ZM15.3379 16C15.3379 16.55 15.7879 17 16.3379 17C16.8879 17 17.3379 16.55 17.3379 16V14C17.3379 13.45 16.8879 13 16.3379 13C15.7879 13 15.3379 13.45 15.3379 14V16Z"
      />
    </svg>
  );
}

DatapipelineIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

DatapipelineIcon.defaultProps = {
  className: "",
  style: {},
  color: "#fff",
  width: 24,
  height: 24
};