import React from "react";
import { ContentContainer } from "components/simple";
import { DynamicFilter } from "./DynamicFilter";
import { Stepper } from "./Stepper";

export const FunnelFilter = () => {
  return (
    <ContentContainer className="p-4 flex flex-col gap-16">
      <Stepper />
      <DynamicFilter />
    </ContentContainer>
  );
};
