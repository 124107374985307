import React from "react";

import { Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { Select, ErrorText } from "components/simple";

import styles from "../../styles.module.scss";

export default function RegistryRegion({ title, placeholder, options, control, errors }) {
  return (
    <div className="w-full mb-3.5 mt-3">
      <Typography className={styles.typo}>{title}</Typography>
      <Controller
        name="registryRegion"
        rules={{
          required: "Please select a Registry Region!"
        }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div style={{ paddingTop: "10px" }}>
            <Select
              name="registryRegion"
              placeholder={placeholder}
              options={options}
              onChange={data => {
                onChange(data);
              }}
              value={value}
            />
          </div>
        )}
      />
      <div className="m-0.5">
        <ErrorText errorMsg={errors.registryRegion && errors.registryRegion.message} />
      </div>
    </div>
  );
}
