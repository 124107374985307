import React from "react";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import renderCellExpand from "helper/renderCellExpand";

const labels = state => state.labels.labels;
const labelList = state => state.labels?.labelList;
const labelsMini = state => state.labels.labelsMini;

export const getLabelListSelector = createDraftSafeSelector(labelList, state => {
  if (!state?.length) {
    return [];
  }

  const res = state.map(item => {
    return {
      value: item.id,
      label: item.name
    };
  });
  return res;
});

export const labelsMiniSelector = createDraftSafeSelector(labelsMini, state => {
  if (!state?.length) {
    return [];
  }

  const res = state.map(item => {
    return {
      value: item.id,
      label: item.name
    };
  });
  return res;
});

export const dataStructureLabelsTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Label",
    field: "name",
    resizable: true,
    minWidth: 220,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Asset",
    field: "assets",
    resizable: true,
    minWidth: 220,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Finding",
    field: "findings",
    resizable: true,
    minWidth: 180,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Ticket",
    field: "tickets",
    resizable: true,
    minWidth: 180,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Baseline",
    field: "baselines",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: params => {
      const param = params?.row?.baselines;
      return (
        <>
          <div style={{ color: "#9EEC60" }}>{param?.passed ?? ""}</div>/
          <div style={{ color: "#d83e3c" }}>{param?.failed ?? ""}</div>
        </>
      );
    },
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const dataStructureLabelsSelector = createDraftSafeSelector(labels, state => {
  if (!state?.results?.length) {
    return { data: [], total: 0 };
  }

  const { results, count } = state;
  const data = results.reduce((acc, elem) => {
    return [
      ...acc,
      {
        id: elem.id,
        name: elem.name,
        assets: elem.assets.toString(),
        findings: elem.findings.length.toString(),
        tickets: elem.tickets.toString(),
        baselines: elem.baselines
      }
    ];
  }, []);

  return { data, total: count };
});
