import React from "react";
import { Route, Switch } from "react-router-dom";
import AppBehavior from "./AppBehavior";
import { ObservabilityContextProvider } from "./Context";

const Observability = () => {
  return (
    <ObservabilityContextProvider>
      <Switch>
        <Route path="/" component={AppBehavior} />
      </Switch>
    </ObservabilityContextProvider>
  );
};
export default Observability;
