import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { SYSTEM_RULE_TYPES } from "store/entities/policyEditor/kubeArmorPolicyEditor/constants";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { handleAddRule } from "store/entities/policyEditor/kubeArmorPolicyEditor/slice";
import { Typography } from "@mui/material";

const RulesPalette = () => {
  const dispatch = useDispatch();

  return (
    <RulesPaletteContainer className="flex flex-col gap-1 text-sm bg-white rounded-lg">
      <div className="m-2 mb-0">
        <Typography>Rules</Typography>
        <Typography sx={{ fontSize: 12, color: "grey.600" }}>
          Drag and Drop the properties below
        </Typography>
      </div>

      <div className="px-2 py-0 rounded-b-lg bg-gray-50">
        {SYSTEM_RULE_TYPES?.map((ruleType, index) => (
          <li key={index} className="flex py-2 items-end justify-between">
            <Typography>{ruleType}</Typography>
            <span className="cursor-pointer" onClick={() => dispatch(handleAddRule(ruleType))}>
              <AddIcon color="primary" />
            </span>
          </li>
        ))}
      </div>
    </RulesPaletteContainer>
  );
};

export default RulesPalette;

const RulesPaletteContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 240px;
  height: fit-content;
`;
