// Set GROUPS params
export const SET_GROUPS_PARAMS = "SET_GROUPS_PARAMS";

// Set GROUP-ASSETS params
export const SET_GROUP_ASSETS_PARAMS = "SET_GROUP_ASSETS_PARAMS";

// Get GROUPS
export const GET_GROUPS = "GET_GROUPS_REQUEST";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED";

// Get GROUP ASSETS
export const GET_GROUP_ASSETS = "GET_GROUP_ASSETS_REQUEST";
export const GET_GROUP_ASSETS_SUCCESS = "GET_GROUP_ASSETS_SUCCESS";
export const GET_GROUP_ASSETS_FAILED = "GET_GROUP_ASSETS_FAILED";

// Get GROUP
export const GET_GROUP = "GET_GROUP_REQUEST";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAILED = "GET_GROUP_FAILED";

// Create GROUP
export const CREATE_GROUP = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILED = "CREATE_GROUP_FAILED";

// Update GROUP
export const UPDATE_GROUP = "UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAILED = "UPDATE_GROUP_FAILED";

// Delete GROUP
export const DELETE_GROUP = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILED = "DELETE_GROUP_FAILED";

// Get GROUPS
export const GET_GROUPS_MINI = "GET_GROUPS_MINI_REQUEST";
export const GET_GROUPS_MINI_SUCCESS = "GET_GROUPS_MINI_SUCCESS";
export const GET_GROUPS_MINI_FAILED = "GET_GROUPS_MINI_FAILED";
