import React from "react";
import PropTypes from "prop-types";

export default function Targets(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9.25" stroke={props.color} strokeWidth="1.5" />
      <circle cx="12" cy="12" r="5.25" stroke={props.color} strokeWidth="1.5" />
      <circle cx="12" cy="12" r="1" fill={props.color} />
    </svg>
  );
}

Targets.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Targets.defaultProps = {
  className: "",
  style: {},
  color: "#fff",
  width: 24,
  height: 24
};
