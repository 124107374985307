import React from "react";
import MaterialStepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const Stepper = ({ steps, activeStep, className }) => {
  return (
    <MaterialStepper activeStep={activeStep} alternativeLabel className={className}>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </MaterialStepper>
  );
};

export default Stepper;
