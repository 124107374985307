import React from "react";
import PropTypes from "prop-types";

export default function Lock(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58291 0.923642C11.5029 0.923642 13.8809 3.30164 13.8809 6.22464L13.8808 7.80124C15.656 8.41905 16.933 10.1091 16.933 12.0924V16.3814C16.933 18.8864 14.896 20.9234 12.391 20.9234H4.75001C2.24501 20.9234 0.208008 18.8864 0.208008 16.3814V12.0924C0.208008 10.1094 1.48453 8.41969 3.25921 7.80159L3.25991 6.22464C3.26591 4.78464 3.82291 3.45564 4.82791 2.45964C5.83391 1.46264 7.16191 0.885642 8.58291 0.923642ZM12.391 9.05044H4.75001C3.07201 9.05044 1.70801 10.4144 1.70801 12.0924V16.3814C1.70801 18.0594 3.07201 19.4234 4.75001 19.4234H12.391C14.068 19.4234 15.433 18.0594 15.433 16.3814V12.0924C15.433 10.4144 14.068 9.05044 12.391 9.05044ZM8.57031 12.3762C8.98431 12.3762 9.32031 12.7122 9.32031 13.1262V15.3472C9.32031 15.7612 8.98431 16.0972 8.57031 16.0972C8.15631 16.0972 7.82031 15.7612 7.82031 15.3472V13.1262C7.82031 12.7122 8.15631 12.3762 8.57031 12.3762ZM8.57991 2.42364H8.56391C7.55091 2.42364 6.60191 2.81364 5.88491 3.52464C5.16291 4.23864 4.76391 5.19164 4.75991 6.20764L4.75901 7.54988H12.38L12.3809 6.22464C12.3809 4.12864 10.6759 2.42364 8.57991 2.42364Z"
        fill={props.color}
      />
    </svg>
  );
}

Lock.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Lock.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 17,
  height: 21
};
