/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { createLoadingSelector } from "store/entities/loading/selector";
import {
  getOneJiraCloud,
  addJiraCloud,
  editJiraCloud,
  getTicketsConfig,
  showTicketsConfigModal,
  deleteTicketConfig,
  getJiraAll
} from "store/entities/settings/actions";
import {
  getCurrentJiraCloudSelector,
  getTicketsConfigListSelector,
  jiraAllSelect
} from "store/entities/settings/selectors";

import { ContentContainer, Title, Icon, Checkbox, Input, Button } from "components/simple";
import TicketConfigModal from "../TicketConfigModal";

import styles from "../service_styles.module.scss";

const JiraCloud = () => {
  const params = useParams();
  const id = params?.id;
  const selectTenant = useSelector(state => state.users.selectTenant);
  const dispatch = useDispatch();

  const isSaveLoading = useSelector(
    s =>
      createLoadingSelector([editJiraCloud.type])(s) ||
      createLoadingSelector([addJiraCloud.type])(s)
  );
  const type = "jiracloudprofile";
  const ticketsConfigs = useSelector(st => getTicketsConfigListSelector(st, type));
  const jiraAll = useSelector(jiraAllSelect);
  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    var arr = _.flatten(Object?.values(jiraAll?.issue_types || { null: "null" }));
    let filter = ticketsConfigs?.map(e => {
      let tmp = _.find(arr, { id: e.issue_type });
      return {
        ...e,
        issue_type: tmp?.name
      };
    });
    setTickets(filter);
  }, [ticketsConfigs]);
  const [state, setState] = useState({
    secret: "",
    name: "",
    url: "",
    email: "",
    is_jira_admin: false
  });

  const editData = useSelector(getCurrentJiraCloudSelector);

  useEffect(() => {
    if (id) {
      dispatch(getJiraAll({ id, type }));
      dispatch(getOneJiraCloud({ id }));
      dispatch(getTicketsConfig({ id }));
    }
  }, [id, selectTenant]);

  const handleSubmit = e => {
    e.preventDefault();
    if (id) {
      dispatch(editJiraCloud({ id, data: state }));
      return;
    }
    dispatch(addJiraCloud(state));
  };

  useEffect(() => {
    if (editData && id) {
      setState(editData);
    }
  }, [editData, id]);

  const inputChangeHandler = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  const onAddConfiguration = () => {
    dispatch(showTicketsConfigModal({ show: true, id: null, type }));
  };

  const onEditConfiguration = val => {
    dispatch(showTicketsConfigModal({ show: true, id: val, type }));
  };

  const onDeleteConfiguration = val => {
    dispatch(deleteTicketConfig({ id: val, ticket_profile_id: id }));
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Jira Cloud</Title>
      <div className={styles.content}></div>
      <form onSubmit={handleSubmit}>
        <div className={styles.sub_title}>Main</div>
        <div className={styles.inputsCont}>
          <Input
            value={state.name}
            onChange={e => inputChangeHandler(e.target.value, "name")}
            label="Name"
            required
          />
          <Input
            value={state.url}
            onChange={e => inputChangeHandler(e.target.value, "url")}
            label="Service Desk URL"
            required
          />
          <Input
            value={state.email}
            onChange={e => inputChangeHandler(e.target.value, "email")}
            label="Email"
            type="email"
            required
          />
          <Input
            value={state.secret}
            onChange={e => inputChangeHandler(e.target.value, "secret")}
            label="Secret"
            type="password"
            autoComplete="new-password"
            required
          />
        </div>

        <Checkbox
          value={Boolean(state.is_jira_admin)}
          onChange={val => inputChangeHandler(val, "is_jira_admin")}
          label="Is Jira admin"
          className={styles.checkbox}
        />
        {/* ================ */}

        {id ? (
          <>
            <div className={styles.row}>
              <div className={styles.sub_title}>Ticket Configuration</div>
              <Button className={styles.btn} onClick={onAddConfiguration}>
                Add configuration
              </Button>
            </div>
            <div className={styles.inputsCont}>
              {tickets?.map(item => {
                return (
                  <div className={styles.box} key={item?.id}>
                    <div className={styles.boxContent}>
                      <div className={styles.name}>{item?.name}</div>
                      <div className={styles.row}>
                        <div className={styles.title}>Project:</div>
                        <div className={styles.description}>{item?.project}</div>
                      </div>
                      <div className={styles.row}>
                        <div className={styles.title}>Issue type:</div>
                        <div className={styles.description}>{item?.issue_type}</div>
                      </div>
                    </div>
                    <div className={styles.buttonGroup}>
                      <div className={styles.button} onClick={() => onEditConfiguration(item?.id)}>
                        <Icon.Edit />
                      </div>
                      <div
                        className={styles.button}
                        onClick={() => onDeleteConfiguration(item?.id)}
                      >
                        <Icon.Delete />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
        <div className={styles.buttonFooter}>
          <Button type="submit" className={styles.btn} isLoading={isSaveLoading}>
            Save
          </Button>
        </div>
      </form>
      <TicketConfigModal />
    </ContentContainer>
  );
};

JiraCloud.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default JiraCloud;
