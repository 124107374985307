import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  dataStructureLabelsParams: {
    page: 0,
    pageSize: 20,
    ordering: "",
    search: ""
  },
  labels: {},
  labelList: [],
  labelsMini: []
};

const reducer = createReducer(
  initialState,
  {
    [actions.setDataStructureLabelsParams.type]: (state, action) => ({
      ...state,
      dataStructureLabelsParams: {
        ...state.dataStructureLabelsParams,
        ...action.payload
      }
    }),
    [actions.getLabelsSuccess.type]: (state, action) => ({
      ...state,
      labels: action.payload
    }),
    [actions.getLabelgetLabelListSuccess.type]: (state, action) => ({
      ...state,
      labelList: action.payload
    }),
    [actions.getLabelsMiniSuccess.type]: (state, action) => ({
      ...state,
      labelsMini: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
