import React, { useEffect, useState } from "react";
import { Divider, Switch, TextField } from "@mui/material";
import { Button, DateRangePicker, Modal, Select, Title } from "components/simple";
import { useDispatch } from "react-redux";
import { actionSetScanScheduleModal } from "store/entities/cloudAccounts/actions";
import styles from "./styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import { days, frequency, repeatDaily, repeatMonthly, repeatWeekly, timeZone } from "./fixture";
import OutlinedCard from "components/simple/Card";
import { style } from "d3";
import moment from "moment";
import { awsCloudAccessKeyDetails } from "store/entities/cloudAccounts/query";
import { TimeSelect } from "./TimeSelect";
import { useAwsCloudAccessScan } from "store/entities/cloudAccounts/mutations";

const SetScheduleScanModal = ({ isOpen, id }) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeZoneList, setTimezoneList] = useState([]);

  const dispatch = useDispatch();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm();

  const [datePicker, time, frequencyList, repeatEvery, timeZone] = watch([
    "datepicker",
    "startTime",
    "frequency",
    "repeatevery",
    "timezone"
  ]);
  const { data } = awsCloudAccessKeyDetails(id);
  const scheduleScan = useAwsCloudAccessScan(id);

  const onClose = () => {
    dispatch(actionSetScanScheduleModal({ show: false, id: null }));
  };

  const handleSelectedDays = data => {
    const filteredDays = days.filter(day => day?.id === data?.id);

    setSelectedDays(prev => [...prev, ...filteredDays]);
  };

  const handleDeleteDays = data => {
    const filterDelete = selectedDays.filter(day => day?.id !== data?.id);

    setSelectedDays(filterDelete);
  };

  const filteredDays = selectedDays.map(data => data.day).join(", ");

  const filterTime = data => {
    let hours = data?.slice(0, 2);
    let minute = data?.slice(3, 5);
    let amPm = hours >= 12 ? "pm" : "am";
    let hour = hours % 12 || 12;
    let finalTime = `${hour}:${minute} ${amPm}`;

    setSelectedTime(finalTime);

    return finalTime;
  };

  const filterDate = data => {
    const newDate = new Date(data);
    const filteredDate = moment(newDate).format("L");
    filteredDate === "Invalid date" ? setSelectedDate("") : setSelectedDate(filteredDate);
  };

  const handleRepeatEvery = () => {
    if (frequencyList?.label === "Daily") {
      return repeatDaily;
    } else if (frequencyList?.label === "Weekly") {
      return repeatWeekly;
    } else if (frequencyList?.label === "Monthly") {
      return repeatMonthly;
    }
  };

  const handleScheduleScan = () => {
    const payload = {
      schedule: {
        frequency: {
          value: frequencyList?.value
        },
        repeatEvery: {
          value: frequencyList?.value === "once" ? frequencyList?.value : repeatEvery?.value
        },
        repeatOn: {
          value: selectedDays.map(data => data?.day)
        },
        start: {
          value: selectedTime
        },
        timezone: {
          value: timeZone?.value
        },
        date: {
          value: selectedDate
        },
        isEnabled: isToggleOpen
      }
    };
    scheduleScan.mutate(payload, {
      onSuccess: () => {
        onClose();
      }
    });
  };

  useEffect(() => {
    const handleTimeZone = () => {
      const timeZones = moment.tz.names();
      timeZones.map(data => {
        const timeZoneValue = {
          value: data,
          label: data
        };
        setTimezoneList(prev => [...prev, timeZoneValue]);
      });
    };
    return handleTimeZone();
  }, []);

  const currentDate = new Date();

  return (
    <Modal isOpen={isOpen} toggle={onClose} sx={{ padding: "0", borderRadius: "5px" }}>
      <div className={styles.titleContainer}>
        <Title className={styles.title}>Schedule the scan </Title>
      </div>
      <Divider />
      <form onSubmit={handleSubmit(handleScheduleScan)}>
        <div className={styles.container}>
          <div className={styles.dateContainer}>
            <div className={styles.selectContainer}>
              <p className={styles.heading}>Frequency</p>
              <Controller
                name="frequency"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      placeholder="Choose Frequency"
                      options={frequency}
                      onChange={data => {
                        onChange(data);
                      }}
                      value={value}
                      className={styles.datePicker}
                    />
                  );
                }}
              />
            </div>
            <div className={styles.selectContainer}>
              <p className={styles.heading}>Date</p>
              <Controller
                name="datepicker"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DateRangePicker
                      placeholder="Filter By Date"
                      clearable
                      onChange={data => {
                        onChange(data);
                        filterDate(data);
                      }}
                      value={value}
                      className={styles.datePicker}
                      minDate={currentDate}
                    />
                  );
                }}
              />
            </div>
            <div className={styles.selectContainer}>
              <p className={styles.heading}>Timezone</p>
              <Controller
                name="timezone"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      placeholder="Choose Timezone"
                      options={timeZoneList}
                      onChange={data => {
                        onChange(data);
                      }}
                      value={value}
                      className={styles.datePicker}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className={styles.dateContainer}>
            <div className={styles.selectContainer}>
              {/* <p className={styles.heading}>Start</p> */}
              <Controller
                name="startTime"
                control={control}
                defaultValue={new Date()}
                render={({ field: { onChange, value } }) => (
                  <TimeSelect
                    value={value}
                    onChange={value => {
                      onChange(value);
                      filterTime(value);
                    }}
                    // className={styles.selectTime}
                    label="Start"
                  />
                )}
              />
            </div>
            {frequencyList?.label !== "Once" ? (
              <div className={styles.selectContainer}>
                <p className={styles.heading}>Repeat every</p>
                <Controller
                  name="repeatevery"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        placeholder="Repeat"
                        options={handleRepeatEvery()}
                        onChange={data => {
                          onChange(data);
                        }}
                        value={value}
                      />
                    );
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className={styles.daysContainer}>
            <p className={styles.heading}>Repeat on</p>
            <div className={styles.dayBoxContainer}>
              {days?.map(data => {
                return (
                  <div
                    key={data?.id}
                    onClick={() => {
                      const isSelected = selectedDays.some(day => day?.id === data?.id);
                      handleSelectedDays(data);
                      if (isSelected) {
                        handleDeleteDays(data);
                      } else null;
                    }}
                  >
                    <OutlinedCard
                      isSelected={selectedDays.some(day => day?.id === data?.id)}
                      width="100px"
                      height="40px"
                      gap="0px"
                      sx={{
                        width: "50px",
                        height: "50px",
                        gap: "7px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "40px",
                        borderRadius: "10px"
                      }}
                    >
                      <p>{data?.shortForm}</p>
                    </OutlinedCard>
                  </div>
                );
              })}
            </div>
            <div className={styles.dateContainer}>
              <Switch checked={isToggleOpen} onChange={() => setIsToggleOpen(!isToggleOpen)} />
              <p className={styles.heading}>Email Notification</p>
            </div>
            <div className={style.daysContainer}>
              <p className={styles.heading}>Summary</p>
              <p className={styles.summary}>
                Repeat every {frequencyList?.label === "Once" ? "Once" : repeatEvery?.label} on{" "}
                {filteredDays} at {selectedTime} starting on {selectedDate}
              </p>
            </div>
          </div>
          <div className={styles.btnContainer}>
            <Button className={styles.cancelBtn}>Pause Schedule Scan</Button>
            <div className={styles.Btn}>
              <Button className={styles.cancelBtn} onClick={() => onClose()}>
                Cancel
              </Button>
              <Button className={styles.saveBtn} onClick={handleSubmit(handleScheduleScan)}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default SetScheduleScanModal;
