import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

// Set Tickets params
export const setTicketsParams = createAction(constant.SET_TICKETS_PARAMS);

// Get Tickets
export const getTickets = createAction(constant.GET_TICKETS);
export const getTicketsSuccess = createAction(constant.GET_TICKETS_SUCCESS);
export const getTicketsFailed = createAction(constant.GET_TICKETS_FAILED);

// Get Ticket
export const getTicket = createAction(constant.GET_TICKET);
export const getTicketSuccess = createAction(constant.GET_TICKET_SUCCESS);
export const getTicketFailed = createAction(constant.GET_TICKET_FAILED);

// Create Ticket
export const createTicket = createAction(constant.CREATE_TICKET);
export const createTicketSuccess = createAction(constant.CREATE_TICKET_SUCCESS);
export const createTicketFailed = createAction(constant.CREATE_TICKET_FAILED);

// Edit Ticket
export const editTicket = createAction(constant.EDIT_TICKET);
export const editTicketSuccess = createAction(constant.EDIT_TICKET_SUCCESS);
export const editTicketFailed = createAction(constant.EDIT_TICKET_FAILED);

// Delete Ticket
export const deleteTicket = createAction(constant.DELETE_TICKET);
export const deleteTicketSuccess = createAction(constant.DELETE_TICKET_SUCCESS);
export const deleteTicketFailed = createAction(constant.DELETE_TICKET_FAILED);

// Close Ticket
export const closeTicket = createAction(constant.CLOSE_TICKET);
export const closeTicketSuccess = createAction(constant.CLOSE_TICKET_SUCCESS);
export const closeTicketFailed = createAction(constant.CLOSE_TICKET_FAILED);

// Get TicketsConfig
export const getTicketsConfig = createAction(constant.GET_TICKETS_CONFIG);
export const getTicketsConfigSuccess = createAction(constant.GET_TICKETS_CONFIG_SUCCESS);
export const getTicketsConfigFailed = createAction(constant.GET_TICKETS_CONFIG_FAILED);

// Get Vulnerability Tickets
export const getVulnerabilityTickets = createAction(constant.GET_VULNERABILITY_TICKETS);
export const getVulnerabilityTicketsSuccess = createAction(
  constant.GET_VULNERABILITY_TICKETS_SUCCESS
);
export const getVulnerabilityTicketsFailed = createAction(
  constant.GET_VULNERABILITY_TICKETS_FAILED
);

// Get Ticket comments
export const getTicketComments = createAction(constant.GET_TICKET_COMMENTS);
export const getTicketCommentsSuccess = createAction(constant.GET_TICKET_COMMENTS_SUCCESS);
export const getTicketCommentsFailed = createAction(constant.GET_TICKET_COMMENTS_FAILED);

// Post Ticket comment
export const postTicketComment = createAction(constant.POST_TICKET_COMMENT);
export const postTicketCommentSuccess = createAction(constant.POST_TICKET_COMMENT_SUCCESS);
export const postTicketCommentFailed = createAction(constant.POST_TICKET_COMMENT_FAILED);

// Post Vulnerability Ticket comment
export const postVulnerabilityTicketComment = createAction(
  constant.POST_VULNERABILITY_TICKET_COMMENT
);
export const postVulnerabilityTicketCommentSuccess = createAction(
  constant.POST_VULNERABILITY_TICKET_COMMENT_SUCCESS
);
export const postVulnerabilityTicketCommentFailed = createAction(
  constant.POST_VULNERABILITY_TICKET_COMMENT_FAILED
);

// Get Tickets Preview
export const getTicketsPreview = createAction(constant.GET_TICKETS_PREVIEW);
export const getTicketsPreviewSuccess = createAction(constant.GET_TICKETS_PREVIEW_SUCCESS);
export const getTicketsPreviewFailed = createAction(constant.GET_TICKETS_PREVIEW_FAILED);

export const clearTicketsPreview = createAction(constant.CLEAR_TICKETS_PREVIEW);

// Get Baseline Tickets
export const getBaselineTickets = createAction(constant.GET_BASELINE_TICKETS);
export const getBaselineTicketsSuccess = createAction(constant.GET_BASELINE_TICKETS_SUCCESS);
export const getBaselineTicketsFailed = createAction(constant.GET_BASELINE_TICKETS_FAILED);

// Post Baseline Ticket comment
export const postBaselineTicketComment = createAction(constant.POST_BASELINE_TICKET_COMMENT);
export const postBaselineTicketCommentSuccess = createAction(
  constant.POST_BASELINE_TICKET_COMMENT_SUCCESS
);
export const postBaselineTicketCommentFailed = createAction(
  constant.POST_BASELINE_TICKET_COMMENT_FAILED
);

// Get TICKETS related-types
export const getTicketsRelatedTypes = createAction(constant.GET_TICKETS_RELATED_TYPES);
export const getTicketsRelatedTypesSuccess = createAction(
  constant.GET_TICKETS_RELATED_TYPES_SUCCESS
);
export const getTicketsRelatedTypesFailed = createAction(constant.GET_TICKETS_RELATED_TYPES_FAILED);

// Get Tickets Status List
export const getTicketsStatusList = createAction(constant.GET_TICKETS_STATUS_LIST);
export const getTicketsStatusListSuccess = createAction(constant.GET_TICKETS_STATUS_LIST_SUCCESS);
export const getTicketsStatusListFailed = createAction(constant.GET_TICKETS_STATUS_LIST_FAILED);
