/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  getVulnerabilitiesSelector,
  getTicketsConfigSelect,
  getGroupByListSelect,
  temporaryDataDefault,
  defaultDisplayFieldsList,
  getStatusesOptionsSelector,
  getSeverityOptionsSelector,
  getVulnerabilitySelector
} from "store/entities/vulnerabilities/selectors";
import { addNotification } from "store/entities/notifications/actions";

import {
  getVulnerabilities,
  actionTargetModal,
  actionGroupModal,
  setVulnerabilitiesParams,
  updVulnerabilitiesParams,
  exportVulnerability,
  ignoreFinding,
  getFindingStatuses,
  getVulnerabilityRiskFactors,
  getVulnerability,
  actionTagModal
} from "store/entities/vulnerabilities/actions";

import { createLoadingSelector } from "store/entities/loading/selector";
import {
  ActionButton,
  Search,
  Select,
  CustomDetailPanelToggle,
  CustomPagination
} from "components/simple";
import { Table } from "components/complex";
import SubTable from "./SubTable";

import {
  useGridApiRef,
  visibleGridColumnsSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from "@mui/x-data-grid-pro";

import styles from "./styles.module.scss";
import Panel from "./Panel";
import { getTicketsConfig } from "store/entities/tickets/actions";
import { onChangeUrlParams, useQuery } from "helper/history";
import { CreateTargetModal } from "./CreateTargetModal";
import { CreateGroupModal } from "./CreateGroupModal";
import EditStatusModal from "./EditStatusModal";
import DetailModal from "./Details/DetailModal";

import { routes } from "router";
import CreateTagModal from "./CreateTagModal";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

const Vulnerabilities = ({ history }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryPage = query.get("page");
  const queryFilters = query.get("filters");
  const queryAsset = query.get("asset");

  const {
    page,
    pageSize,
    selectConfig,
    temporaryData,
    filters,
    groupBy,
    data_type,
    asset,
    orderModel,
    selectTicketConfig,
    search
  } = useSelector(state => state.vulnerabilities);

  const ticketsConfigList = useSelector(getTicketsConfigSelect);

  const display_fields = temporaryData?.display_fields || "";

  const display_fields_width = temporaryData?.display_fields_width || "";

  const { columns, data, total } = useSelector(state =>
    getVulnerabilitiesSelector(state, display_fields, null, display_fields_width)
  );

  const vulnerability = useSelector(getVulnerabilitySelector);
  const statusesOptions = useSelector(getStatusesOptionsSelector);
  const severitiesOptions = useSelector(getSeverityOptionsSelector);

  const groupByList = useSelector(state => getGroupByListSelect(state, display_fields));

  const isLoading = useSelector(state => createLoadingSelector([getVulnerabilities.type])(state));

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = React.useRef(selectionModel);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState([]);
  const [selectionModelSubTable, setSelectionModelSubTable] = useState([]); // selections for  Expend Table

  const selectTenant = useSelector(state => state.users.selectTenant);
  const apiRef = useGridApiRef();

  const [showEditStatusModal, onShowEditStatusModal] = useState(null);
  const [showDetailModal, onShowDetailModal] = useState({
    open: false,
    type: "",
    group_ids: []
  });

  const clearUrl = () => {
    onChangeUrlParams("page", 0);
    onChangeUrlParams("filters", "");
    onChangeUrlParams("asset", "");
    onChangeUrlParams("data_type", "");
  };

  const onClearTemporaryData = () => {
    clearUrl();
    if (temporaryData) {
      dispatch(
        setVulnerabilitiesParams({
          page: 0,
          ordering: "",
          search: "",
          filters: {},
          data_type: "",
          asset: "",
          groupBy: "",
          orderModel: [],
          selectConfig: null,
          temporaryData: temporaryDataDefault
        })
      );
    }
  };

  // get default request
  const getRequest = () => {
    const urlFilters = JSON.parse(queryFilters) || {};
    const urlAsset = JSON.parse(queryAsset) || "";
    dispatch(
      setVulnerabilitiesParams({
        page: queryPage ? queryPage - 1 : 0,
        filters: urlFilters || filters,
        asset: urlAsset || asset
      })
    );
  };

  const clear = () => {
    const current = history?.location?.pathname;
    const isActive = () => {
      const currentRoute = current.replaceAll("/", " ");
      const route = routes.vulnerabilities.replaceAll("/", " ");
      return currentRoute.includes(route);
    };

    if (!isActive()) {
      clearUrl();
      dispatch(
        updVulnerabilitiesParams({
          page: 0,
          filters: {},
          groupBy: "",
          selectConfig: null,
          data_type: "",
          asset: "",
          ordering: "",
          search: "",
          temporaryData: temporaryDataDefault,
          selectTicketConfig: "",
          orderModel: []
        })
      );
    }
  };

  useEffect(() => {
    getRequest();
    dispatch(getTicketsConfig("finding"));
    dispatch(getFindingStatuses());
    dispatch(getVulnerabilityRiskFactors());

    return () => clear();
  }, [selectTenant]);

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      let fieldSort = field;

      if (field === "__detail_panel_toggle__") {
        fieldSort = "group_ids";
      }
      if (sort === "asc") {
        dispatch(
          setVulnerabilitiesParams({
            ordering: fieldSort,
            orderModel: s
          })
        );
      } else {
        dispatch(
          setVulnerabilitiesParams({
            ordering: `-${fieldSort}`,
            orderModel: s
          })
        );
      }
    } else {
      dispatch(
        setVulnerabilitiesParams({
          ordering: "",
          orderModel: s
        })
      );
    }
  };

  // filters
  const onChangeSelectedFilterFields = v => {
    const isEmpty = !Object.values(v).some(x => x);
    const filtr = { ...filters, ...v };
    if (isEmpty) {
      Object.keys(v).some(x => {
        delete filtr[x];
        return true;
      });
    }
    onChangeUrlParams("filters", JSON.stringify(filtr));
    dispatch(
      setVulnerabilitiesParams({
        ordering: "",
        filters: filtr
      })
    );
  };

  // Load temporary data
  const onLoadData = r => {
    onChangeUrlParams("page", 0);
    dispatch(
      setVulnerabilitiesParams({
        page: 0,
        ordering: "",
        search: "",
        temporaryData: r
      })
    );
  };

  // Handle for select config
  const onSetSelectConfig = v => {
    clearUrl();
    dispatch(
      setVulnerabilitiesParams({
        page: 0,
        selectConfig: v,
        temporaryData: v ? v?.result : temporaryDataDefault,
        ordering: "",
        search: "",
        filters: {},
        groupBy: null,
        data_type: v?.result?.data_type || "",
        asset: v?.result?.asset
          ? {
              value: v?.result?.asset?.id,
              label: v?.result?.asset?.title
            }
          : ""
      })
    );
  };

  // Next page
  const onPageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    prevSelectionModel.current = selectionModel;
    if (page !== -1) {
      dispatch(
        setVulnerabilitiesParams({
          page: newPage
        })
      );
    }
  };

  React.useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
    setDetailPanelExpandedRowIds([]); // clear expend row
    setSelectionModelSubTable([]);
  }, [page, data]);

  // Search
  const handleSearch = v => {
    onChangeUrlParams("page", 0);
    dispatch(
      setVulnerabilitiesParams({
        page: 0,
        search: v
      })
    );
  };

  // Actions
  const handleAction = type => {
    const selectionModelSubTableRow = selectionModelSubTable
      ?.map(item => item?.selectionModel)
      ?.flat();

    const ticketConfig_id = selectTicketConfig?.value;
    const groupByValue = groupBy?.value;
    const myMap = apiRef?.current?.getSelectedRows();
    const values = Array.from(myMap.values());
    const ids = groupByValue
      ? [...new Set([...values?.map(item => item?.group_ids).flat(), ...selectionModelSubTableRow])]
      : selectionModel;

    if (type === "export") {
      const fields = display_fields || defaultDisplayFieldsList;
      dispatch(exportVulnerability({ displayFields: fields, ids }));
      return;
    }

    if (type === "createTicket" && !selectTicketConfig?.value) {
      dispatch(addNotification({ msg: "You need to select ticket config", type: "warning" }));
      return;
    }
    if (!selectionModel?.length && !selectionModelSubTableRow?.length) {
      dispatch(addNotification({ msg: "You must select at least one row", type: "warning" }));
      return;
    }

    if (type === "edit") {
      onShowEditStatusModal(ids);
      return;
    }

    switch (type) {
      case "addToGroup":
        dispatch(actionGroupModal({ show: true, data: ids }));
        break;
      case "addTag":
        dispatch(actionTagModal({ show: true, data: ids }));
        break;
      case "addToTarget":
        dispatch(actionTargetModal({ show: true, data: ids }));
        break;
      case "createTicket": {
        const url = groupByValue ? `&groupBy=${groupByValue}` : "";
        history.push(
          `${routes.vulnerabilitiesCreateTicket}/${ticketConfig_id}?ids=${ids.join(",")}${url}`
        );
        break;
      }
      case "ignoreFinding":
        dispatch(ignoreFinding(ids));
        break;
      default:
        break;
    }
  };

  // Select Group By
  const onGroupBy = v => {
    dispatch(
      setVulnerabilitiesParams({
        ordering: "",
        groupBy: v,
        page: 0
      })
    );
    setSelectionModel([]);
  };

  const onSelectTicketConfig = v => {
    dispatch(updVulnerabilitiesParams({ selectTicketConfig: v }));
  };

  const setPageSize = v => {
    dispatch(
      setVulnerabilitiesParams({
        pageSize: v
      })
    );
  };

  const onCellClick = (v, e) => {
    const { field, id, row } = v;
    e.stopPropagation();

    if (field !== "__detail_panel_toggle__") {
      dispatch(getVulnerability(id));
      onShowDetailModal({ open: true, type: data_type, group_ids: row?.group_ids || [] });
    }
  };

  const onChangeSelectTopBar = (val, name) => {
    onChangeUrlParams("filters", "");
    onChangeUrlParams("asset", "");
    onChangeUrlParams("data_type", "");
    if (name === "asset") {
      dispatch(
        setVulnerabilitiesParams({
          asset: val || "",
          page: 0,
          filters: {}
        })
      );
      return;
    }

    dispatch(
      setVulnerabilitiesParams({
        [name]: val?.value || "",
        ...(name === "data_type" ? { asset: "" } : {}),
        page: 0,
        filters: {}
      })
    );
  };

  // Expend
  const onChangeSelectionModelSubTable = (v, rowId) => {
    const res = {
      id: rowId,
      selectionModel: v
    };
    let newSelection = [];

    if (selectionModelSubTable.find(item => item?.id === rowId)) {
      newSelection = selectionModelSubTable?.map(item => {
        if (item?.id === rowId) {
          return res;
        }
        return item;
      });
    } else {
      newSelection = [...selectionModelSubTable, res];
    }

    if (selectionModel.find(item => item === rowId)) {
      const resetSelectForMainTable = selectionModel?.filter(item => item !== rowId);
      setSelectionModel(resetSelectForMainTable);
    }

    setSelectionModelSubTable(newSelection);
  };

  const [expandHeight, setExpandHeight] = useState(150);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => {
      return (
        <SubTable
          row={row}
          onCellClick={onCellClick}
          selectionModelSubTable={selectionModelSubTable}
          setExpandHeight={setExpandHeight}
          expandHeight={expandHeight}
          display_fields={display_fields}
          display_fields_width={display_fields_width}
          onChangeSelectionModelSubTable={v => onChangeSelectionModelSubTable(v, row?.id)}
        />
      );
    },
    [selectionModelSubTable, expandHeight]
  );

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    newIds => {
      setDetailPanelExpandedRowIds(newIds.slice(-1));
    },
    [apiRef]
  );

  // Expend End

  useEffect(() => {
    return apiRef.current.subscribeEvent("columnHeaderDragEnd", () => {
      const newOrder = visibleGridColumnsSelector(apiRef.current.state)
        .map(col => col.field)
        ?.filter(item => item !== "__check__");
      dispatch(
        updVulnerabilitiesParams({
          temporaryData: {
            ...temporaryData,
            display_fields: newOrder
          }
        })
      );
    });
  }, [apiRef, temporaryData]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  const timerRef = React.useRef();

  const triggerChangeWidth = widthArrat => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      dispatch(
        updVulnerabilitiesParams({
          temporaryData: {
            ...temporaryData,
            display_fields_width: widthArrat
          }
        })
      );
    }, 500);
  };

  const onColumnResize = v => {
    const fields = display_fields_width;
    const prevColWidth = fields ?? [];
    const newCol = [...prevColWidth, { field: v?.colDef?.field, width: v?.width }];
    triggerChangeWidth(getUniqueListBy(newCol, "field"));
  };

  const customStyles = {
    height: 710,
    width: 1,
    "& .super-app-theme--header": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #E7E6F8"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      marginBottom: "0px"
    }
  };

  return (
    <div className={styles.container}>
      <Panel
        selectConfig={selectConfig}
        temporaryData={temporaryData}
        setSelectConfig={onSetSelectConfig}
        onChangeSelectedFilterFields={onChangeSelectedFilterFields}
        selectedFilterFields={filters}
        onLoadData={onLoadData}
        onClearTemporaryData={onClearTemporaryData}
        data_type={data_type}
        asset={asset}
        onChangeSelectTopBar={onChangeSelectTopBar}
      />
      <div className={styles.content}>
        <div className={styles.filters}>
          <Search onSearch={handleSearch} value={search} />

          <div className={styles.filterRow}>
            <div className={styles.selectsGroup}>
              {ticketsConfigList?.length ? (
                <Select
                  containerClass={styles.select}
                  onChange={onSelectTicketConfig}
                  value={selectTicketConfig}
                  placeholder="Ticket Configuration"
                  options={ticketsConfigList}
                  isClearable
                />
              ) : null}

              <Select
                containerClass={styles.select}
                onChange={onGroupBy}
                value={groupBy}
                placeholder="Group by"
                options={groupByList}
                isClearable
              />
            </div>
            <div className={styles.buttonGroup}>
              <ActionButton type="edit" onClick={handleAction} className={styles.actionButton} />
              <ActionButton
                type="addToGroup"
                onClick={handleAction}
                className={styles.actionButton}
              />
              <ActionButton type="addTag" onClick={handleAction} className={styles.actionButton} />
              <ActionButton
                type="addToTarget"
                onClick={handleAction}
                className={styles.actionButton}
              />
              <ActionButton
                type="createTicket"
                onClick={handleAction}
                className={styles.actionButton}
              />
              <ActionButton type="export" onClick={handleAction} className={styles.actionButton} />
              <ActionButton
                type="ignoreFinding"
                onClick={handleAction}
                className={styles.actionButton}
              />
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <Table
            apiRef={apiRef}
            disableVirtualization
            onColumnResize={onColumnResize}
            data={isLoading ? [] : data}
            // columns={columns}
            columns={[
              {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                headerName: "Group ids",
                field: "__detail_panel_toggle__",
                width: 120,
                sortable: true,
                hide: !groupBy?.value,
                renderCell: params => {
                  return (
                    <span>
                      {params?.row?.group_ids?.length || 0}
                      {params?.row?.group_ids?.length > 1 ? (
                        <CustomDetailPanelToggle {...params} />
                      ) : null}
                    </span>
                  );
                }
              },
              ...columns
            ]}
            loading={isLoading}
            // onSelectionModelChange={newSelectionModel => {
            //   setSelectionModel(newSelectionModel);
            // }}
            onSelectionModelChange={newSelectionModel => {
              if (groupBy) {
                const current = newSelectionModel.slice(-1)[0];
                const res = data?.find(item => item?.id === current);
                if (current) {
                  const groupIds = res?.group_ids || [];
                  if (selectionModelSubTable?.find(item => item.id === current)) {
                    const rr = selectionModelSubTable?.map(item => {
                      if (item.id === current) {
                        if (newSelectionModel?.find(i => i === item?.id)) {
                          return { ...item, selectionModel: groupIds };
                        }
                        return;
                      }
                      if (newSelectionModel?.find(i => i !== item?.id)) {
                        return { ...item, selectionModel: [] };
                      }
                      return item;
                    });
                    setSelectionModelSubTable(rr);
                  } else {
                    setSelectionModelSubTable([
                      ...selectionModelSubTable,
                      { id: current, selectionModel: groupIds }
                    ]);
                  }
                } else {
                  setSelectionModelSubTable([]);
                }
              }

              setSelectionModel(newSelectionModel);
            }}
            // page={page || 0}
            selectionModel={selectionModel}
            sortModel={orderModel}
            onSortModelChange={model => handleSort(model)}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowCount={total}
            paginationMode="server"
            onPageChange={onPageChange}
            initialState={{ pinnedColumns: { left: ["__check__"] } }}
            onCellClick={(p, e) => onCellClick(p, e)}
            //
            // {...(groupBy ? { getDetailPanelHeight: () => expandHeight } : {})}
            // {...(groupBy ? { getDetailPanelContent } : {})}
            getDetailPanelHeight={() => expandHeight}
            getDetailPanelContent={getDetailPanelContent}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            customStyles={customStyles}
            components={{
              Pagination: CustomPagination
            }}
            componentsProps={{
              pagination: {
                count: FindTotalPage(total, pageSize),
                onChange: (event, value) => {
                  onPageChange(value - 1);
                },
                page: page + 1,
                totalCount: total || 0
              }
            }}
          />
        </div>
      </div>
      <CreateTargetModal />
      <CreateGroupModal />
      <CreateTagModal />
      <EditStatusModal
        isOpen={Boolean(showEditStatusModal)}
        data={showEditStatusModal}
        onClose={() => onShowEditStatusModal(null)}
      />
      <DetailModal
        data={vulnerability}
        type={showDetailModal?.type}
        group_ids={showDetailModal.group_ids}
        statusesOptions={statusesOptions}
        severitiesOptions={severitiesOptions}
        isOpen={Boolean(showDetailModal.open)}
        onClose={() => onShowDetailModal(!showDetailModal.open)}
        ticketConfigOptions={ticketsConfigList}
        selectTicketConfig={selectTicketConfig}
        handleTicketConfig={onSelectTicketConfig}
        handleAction={handleAction}
      />
    </div>
  );
};

Vulnerabilities.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Vulnerabilities;
