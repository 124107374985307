import React, { useCallback } from "react";
import { useStore, getStraightPath, getBezierPath } from "reactflow";
import styles from "./styles.module.scss";
import { getEdgeParams } from "../Utils/utils";

function FloatingEdge({ id, source, target, style, label, data, markerEnd }) {
  const sourceNode = useStore(useCallback(store => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback(store => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
    source === target,
    data?.bindId
  );
  if (source === target) {
    const [edgePath] = getBezierPath({
      sourceX: sx,
      sourceY: sy,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
      targetX: tx,
      targetY: ty,
      curvature: 1
    });
    return (
      <g>
        <path id={id} d={edgePath} markerEnd={markerEnd} style={style} fill="none" />
        <path id={id} d={edgePath} stroke="#0000" fill="none" strokeWidth="10px" />
      </g>
    );
  }

  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty
  });

  return (
    <g className={`${data?.transparent && styles.transparentPath}`}>
      <>
        <text dy={-5} className={`${sx < tx ? styles.labelClockwise : styles.labelAnticlockwise}`}>
          <textPath
            href={`#${id}`}
            style={{ fontSize: "12px", userSelect: "none" }}
            startOffset="40%"
            textAnchor="middle"
          >
            {label.slice(0, 3)}
          </textPath>
        </text>
        <marker
          id="arrow_green"
          viewBox="0 -5 10 10"
          refX={-100}
          refY="0"
          markerWidth="10"
          markerHeight="10"
          orient="auto"
        >
          <path d="M0,-5L10,0L0,5" stroke="#0500FF" fill="#0500FF"></path>
        </marker>
      </>
      <marker
        id="arrow_red"
        viewBox="0 -5 10 10"
        refX={50}
        refY="0"
        markerWidth="10"
        markerHeight="10"
        orient="auto"
      >
        <path d="M0,-5L10,0L0,5" stroke="#0500FF" fill="#0500FF"></path>
      </marker>

      <path id={id} d={edgePath} markerStart="url(#arrow_green)" style={style} />
      <path id={id} d={edgePath} stroke="#0000" fill="none" strokeWidth="10px" />
    </g>
  );
}

export default FloatingEdge;
