import React, { useState } from "react";
import { Button, Title, Modal, Input } from "components/simple";

import styles from "./styles.module.scss";

const NewTags = ({ open, onBack = () => null, close, data, onSubmit }) => {
  const [name, setName] = useState("");

  const onSave = () => {
    onSubmit({
      name,
      ids: data
    });
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Create a new tag</Title>
      <div className={styles.content}>
        <div className={styles.form}>
          <Input
            type="text"
            required
            placeholder="Name"
            label="Name"
            value={name}
            onChange={val => setName(val.target.value)}
            className={styles.input}
            containerClassName={styles.inputContainer}
          />
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={onBack} variant="outline" className={styles.modalButton}>
          Back
        </Button>
        <Button onClick={onSave} className={styles.modalButton} disabled={!name?.length}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default NewTags;
