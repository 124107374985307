import { getAccuknoxWorkspace } from "api/api";
import { useQuery } from "react-query";
import { DEFAULT, DEFAULT_KUBEARMOR_POLICY_QUERY_KEYS, WORKSPACE_ID } from "./constants";
import {
  getClusters,
  getInstanceGroups,
  getInstances,
  getLabels,
  getNamespaces,
  getTags,
  getAllPolicies
} from "./service";

export const useClustersQuery = () => {
  const workspaceId = getAccuknoxWorkspace();

  return useQuery(
    [...DEFAULT_KUBEARMOR_POLICY_QUERY_KEYS, "getClustersData", workspaceId],
    () => getClusters(workspaceId),
    {
      enabled: Boolean(workspaceId),
      refetchOnWindowFocus: false,
      select: data =>
        data?.clusters?.map(cluster => ({
          id: cluster.id,
          label: cluster.name,
          value: cluster.name
        }))
    }
  );
};

export const useNamespaceLoadOptions = () => {
  const namespacesLoadOptions = async (search, loadedOptions, { workspaceId, clusterId }) => {
    let options = [];
    const hasMore = false;

    const requestBody = {
      workspace_id: workspaceId,
      cluster_id: clusterId,
      search
    };
    const data = await getNamespaces(requestBody);
    if (data?.namespaces?.length) {
      options = data?.namespaces?.map(namespace => ({
        id: namespace?.id,
        label: namespace?.name,
        value: namespace?.name
      }));
    }

    return {
      options,
      hasMore,
      additional: {
        workspaceId,
        clusterId
      }
    };
  };

  return namespacesLoadOptions;
};

export const useInstanceGroupLoadOptions = () => {
  const instanceGroupLoadOptions = async (_, loadedOptions, { workspaceId, page }) => {
    const limit = 10;
    let options = [];
    let hasMore;

    const requestBody = {
      workspace_id: workspaceId,
      page,
      limit
    };
    const data = await getInstanceGroups(requestBody);

    if (data?.instance_group?.length) {
      options = data?.instance_group?.map(ig => ({
        id: ig?.id,
        label: ig?.name,
        value: ig?.name
      }));
    }
    if (data?.total) {
      hasMore = data?.total > loadedOptions?.length + limit;
    }

    return {
      options,
      hasMore,
      additional: {
        workspaceId,
        page: page + 1
      }
    };
  };

  return instanceGroupLoadOptions;
};

export const useInstanceLoadOptions = () => {
  const instanceLoadOptions = async (_, loadedOptions, { workspaceId, instanceGroupId, page }) => {
    const limit = 10;
    let options = [];
    let hasMore;

    const requestBody = {
      workspace_id: workspaceId,
      instance_group_id: instanceGroupId,
      page,
      limit
    };
    const data = await getInstances(requestBody);

    if (data?.instance?.length) {
      options = data?.instance?.map(i => ({
        id: i?.id,
        label: i?.name,
        value: i?.name
      }));
    }
    if (data?.total) {
      hasMore = data?.total > loadedOptions?.length + limit;
    }

    return {
      options,
      hasMore,
      additional: {
        workspaceId,
        instanceGroupId,
        page: page + 1
      }
    };
  };

  return instanceLoadOptions;
};

export const useLabelsLoadOptions = () => {
  const labelsLoadOptions = async (
    search,
    loadedOptions,
    { workspaceId, clusterId, namespaceId, labelType, page }
  ) => {
    const limit = 10;
    let options = [];
    let hasMore;
    const filters = [];

    let requestBody = {
      workspace_id: workspaceId,
      page,
      limit,
      search
    };
    if (clusterId) {
      filters.push({
        entity_type: "Cluster",
        entity_id: [clusterId]
      });
    }
    if (namespaceId) {
      filters.push({
        entity_type: "Namespace",
        entity_id: [namespaceId]
      });
    }
    if (filters.length) {
      requestBody = {
        ...requestBody,
        filter: { with: [{ entity_type: labelType === DEFAULT ? "Pod" : "Node" }, ...filters] }
      };
    }

    const data = await getLabels(requestBody);

    if (data?.labels) {
      options = data?.labels?.map(label => ({
        id: label?.id,
        name: label?.name,
        value: label?.value,
        label: `${label?.name}=${label?.value}`
      }));
    }
    if (data?.total) {
      hasMore = data?.total > loadedOptions?.length + limit;
    }

    return {
      options,
      hasMore,
      additional: {
        workspaceId,
        clusterId,
        namespaceId,
        labelType,
        page: page + 1
      }
    };
  };

  return labelsLoadOptions;
};

export const useTagsQuery = () => {
  const workspaceId = getAccuknoxWorkspace();

  return useQuery(
    [...DEFAULT_KUBEARMOR_POLICY_QUERY_KEYS, "getTagsData", workspaceId],
    () => getTags(workspaceId),
    {
      enabled: Boolean(workspaceId),
      refetchOnWindowFocus: false,
      select: data =>
        data?.tags?.map(tag => ({
          id: tag.id,
          label: tag.name,
          value: tag.name
        }))
    }
  );
};

export const useAllPoliciesQuery = ({ selectedEntity, kind, page, perPage }) => {
  const workspaceId = getAccuknoxWorkspace();
  const requestBody = {
    workspace_id: workspaceId,
    type: selectedEntity,
    limit: perPage,
    page,
    kind
  };

  return useQuery(
    [
      ...DEFAULT_KUBEARMOR_POLICY_QUERY_KEYS,
      "getAllPoliciesData",
      workspaceId,
      selectedEntity,
      perPage,
      page,
      kind
    ],
    () => getAllPolicies(requestBody),
    {
      select: data => {
        return {
          ...data,
          policies: data?.policies.map(policyItem => ({
            ...policyItem,
            selected: false
          }))
        };
      },
      enabled: Boolean(WORKSPACE_ID),
      refetchOnWindowFocus: false
    }
  );
};
