import React from "react";

import { formatDate } from "helper/DateLib";

import { Button, Icon } from "components/simple";

import styles from "./styles.module.scss";

const CommentsList = ({ step, setStep, ticket }) => {
  return (
    <>
      <div className={styles.vulnTitle}>
        <div>{`${ticket.title} (${ticket.comments.length})`}</div>
        <Button onClick={() => setStep("forward", step)} className={styles.addBtn}>
          <Icon.Add color="#ffff" />
        </Button>
      </div>
      {ticket.comments.map(comment => {
        return (
          <div key={comment.id} className={styles.card}>
            <div className={`${styles.part} ${styles.borderNone}`}>
              <span>{`${formatDate(comment.date, "DD/MM/YYYY")} - ${comment.user}`}</span>
              <span>{comment.comment}</span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CommentsList;
