import { Info } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import ButtonLink from "components/simple/ButtonLink";
import { CONTAINER, NODE, POD } from "./constant";
import WorkloadModal from "./Components/WorkloadModal";
import PolicyStatusToggle from "components/simple/PolicyStatusToggle";
import PolicyStatusTag from "components/simple/PolicyStatusToggle/policyStatusLable";
import SecurityModal from "./Components/SecurityPostureModal";
import { heightDifferenceForContextMenu } from "./helpers/heightDifferenceForContextMenu";

export const POLICIES_COLUMN = getUsers => {
  return [
    {
      headerName: "Name",
      field: "name",
      resizable: true,
      minWidth: 160,
      flex: 1,
      hide: false,
      renderCell: params => (
        <>
          <ButtonLink className="cursor-pointer" isUnderline={false} buttonLable={params?.value} />
        </>
      )
    },
    {
      headerName: "Type",
      field: "type",
      resizable: true,
      minWidth: 200,
      flex: 1,
      hide: false
    },
    {
      headerName: "Namespace",
      field: "namespace_name",
      resizable: true,
      minWidth: 200,
      flex: 1,
      hide: false
    },
    {
      headerName: "Version",
      field: "version",
      resizable: true,
      minWidth: 100,
      flex: 1,
      hide: false
    },
    {
      headerName: "Owner",
      field: "updated_by",
      resizable: true,
      minWidth: 100,
      flex: 1,
      hide: false,
      renderCell: params => (
        <>
          {getUsers?.isLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            <div>{getUsers?.data?.userIdMap?.[params?.value]}</div>
          )}
        </>
      )
    },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      minWidth: 160,
      flex: 1,
      hide: false,
      renderCell: params => <PolicyStatusTag status={params.value} />
    }
  ];
};

export const CONTAINER_COLUMNS = (toggleActions, setHighlightEntity) => [
  {
    headerName: "Container Name",
    field: "name",
    resizable: true,
    minWidth: 160,
    flex: 1,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Service Name",
    field: "name_of_services",
    resizable: true,
    minWidth: 160,
    flex: 1,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Image",
    field: "image",
    resizable: true,
    minWidth: 160,
    flex: 1,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Network Traffic",
    field: "id",
    resizable: true,
    minWidth: 160,
    flex: 1,
    renderCell: params => (
      <>
        <ButtonLink
          isUnderline={false}
          className="cursor-pointer"
          buttonLable="View Traffic"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            toggleActions.setAsGraphView(true);
            setHighlightEntity(params?.id);
          }}
        />
      </>
    ),
    headerClassName: "super-app-theme--header",
    hide: false
  },
  // {
  //   headerName: "Alerts",
  //   field: "protocol_port",
  //   resizable: true,
  //   minWidth: 160,
  //   flex: 1,
  //   renderCell: params => <div>--</div>,
  //   headerClassName: "super-app-theme--header",
  //   hide: false
  // },
  {
    headerName: "Status",
    field: "status",
    resizable: true,
    minWidth: 160,
    flex: 1,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const CLUSTER_COLUMNS = (
  setSelectedView,
  setSelectedCluster,
  setSelectedPod,
  setParentSelected,
  toggleActions,
  isTableEntityDetailLoading,
  setHighlightEntity,
  setThroughCluster
) => {
  return [
    {
      headerName: "Cluster Name",
      field: "name",
      resizable: true,
      minWidth: 200,
      flex: 1,
      renderCell: params => (
        <>
          <ButtonLink
            className="cursor-pointer"
            isUnderline={false}
            buttonLable={params?.value}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedView(POD);
              setSelectedCluster({
                value: params?.id,
                label: params?.row?.name
              });
              setSelectedPod({});
              setThroughCluster(false);
              setParentSelected(params?.id);
            }}
          />
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Nodes",
      field: "NodeCount",
      resizable: true,
      minWidth: 100,
      flex: 1,
      renderCell: params => (
        <>
          {isTableEntityDetailLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            <ButtonLink
              className="cursor-pointer"
              isUnderline={false}
              buttonLable={params?.value}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedView(NODE);
                setSelectedCluster({
                  value: params?.id,
                  label: params?.row?.name
                });
              }}
            />
          )}
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Pods",
      field: "PodCount",
      resizable: true,
      minWidth: 100,
      flex: 1,
      renderCell: params => (
        <>
          {isTableEntityDetailLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            <ButtonLink
              isUnderline={false}
              className="cursor-pointer"
              buttonLable={params?.value}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedView(POD);
                setThroughCluster(false);
                setSelectedCluster({
                  value: params?.id,
                  label: params?.row?.name
                });
                setSelectedPod({});
                setParentSelected(params?.id);
              }}
            />
          )}
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Active Policies",
      field: "PolicyCount",
      resizable: true,
      minWidth: 160,
      flex: 1,
      renderCell: params => (
        <div>
          {isTableEntityDetailLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            params?.value
          )}
        </div>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Alerts",
      field: "AlertsCount",
      resizable: true,
      minWidth: 160,
      flex: 1,
      renderCell: params => (
        <div>
          {isTableEntityDetailLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            params?.value
          )}
        </div>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Location",
      field: "Location",
      resizable: true,
      minWidth: 160,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Network Traffic",
      field: "last_updated_time",
      resizable: true,
      minWidth: 160,
      flex: 1,
      renderCell: params => (
        <>
          <ButtonLink
            isUnderline={false}
            className="cursor-pointer"
            buttonLable="View Traffic"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              toggleActions.setAsGraphView(true);
              setHighlightEntity(params?.id);
            }}
          />
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    }
  ];
};
export const NODE_COLUMNS = (
  setSelectedView,
  setSelectedNode,
  setSelectedPod,
  toggleActions,
  isTableEntityDetailLoading,
  setHighlightEntity
) => {
  return [
    {
      headerName: "Node Name",
      field: "name",
      resizable: true,
      minWidth: 370,
      flex: 1,
      renderCell: params => (
        <>
          <ButtonLink
            className="cursor-pointer"
            isUnderline={false}
            buttonLable={params?.value}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedNode({
                value: params?.id,
                label: params?.row?.name
              });
              setSelectedPod({});
              setSelectedView(POD);
            }}
          />
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Labels",
      field: "LabelCount",
      resizable: true,
      minWidth: 80,
      flex: 1,
      renderCell: params => (
        <>
          {isTableEntityDetailLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            <ButtonLink
              className="cursor-pointer"
              isUnderline={false}
              buttonLable={params?.value}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Pods",
      field: "PodCount",
      resizable: true,
      minWidth: 70,
      flex: 1,
      renderCell: params => (
        <>
          {isTableEntityDetailLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            <ButtonLink
              isUnderline={false}
              className="cursor-pointer"
              buttonLable={params?.value}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedNode({
                  value: params?.id,
                  label: params?.row?.name
                });
                setSelectedPod({});
                setSelectedView(POD);
              }}
            />
          )}
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Active Policies",
      field: "PolicyCount",
      resizable: true,
      minWidth: 130,
      flex: 1,
      renderCell: params => (
        <div>
          {isTableEntityDetailLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            params?.value
          )}
        </div>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Alerts",
      field: "AlertsCount",
      resizable: true,
      minWidth: 70,
      flex: 1,
      renderCell: params => (
        <div>
          {isTableEntityDetailLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            params?.value
          )}
        </div>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Location",
      field: "Location",
      resizable: true,
      minWidth: 100,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Network Traffic",
      field: "last_updated_time",
      resizable: true,
      minWidth: 130,
      flex: 1,
      renderCell: params => (
        <>
          <ButtonLink
            isUnderline={false}
            className="cursor-pointer"
            buttonLable="View Traffic"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              toggleActions.setAsGraphView(true);
              setHighlightEntity(params?.id);
            }}
          />
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    }
  ];
};
export const POD_COLUMNS = (
  setSelectedView,
  setSelectedPod,
  toggleActions,
  isTableEntityDetailLoading,
  setHighlightEntity,
  setShowSecurityPosture,
  setXPos,
  setYPos,
  setShowContextMenuAbove,
  setNamespaceData
) => {
  const handleIconClick = (e, namespace) => {
    e.preventDefault();
    e.stopPropagation();
    const maxHeight = e.view?.innerHeight;
    const [posX, posY, differenceInHeightInPct] = heightDifferenceForContextMenu(
      e.pageX,
      e.pageY,
      maxHeight
    );
    setShowContextMenuAbove({
      showAbove: differenceInHeightInPct < 20,
      newYPosFromTable: maxHeight - posY
    });
    setXPos(posX + "px");
    setYPos(posY + "px");
    setNamespaceData(namespace);
    setShowSecurityPosture(true);
  };
  return [
    {
      headerName: "Pod Name",
      field: "name",
      resizable: true,
      minWidth: 400,
      flex: 1,
      renderCell: params => (
        <>
          <ButtonLink
            className="cursor-pointer"
            isUnderline={false}
            buttonLable={params?.value}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setSelectedView(CONTAINER);
              setSelectedPod({
                value: params?.id,
                label: params?.row?.name
              });
            }}
          />
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Namespace",
      field: "namespace",
      resizable: true,
      minWidth: 200,
      flex: 1,
      renderCell: params => (
        <div className="w-full flex items-center justify-between gap-2 pr-3">
          {params?.value}
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleIconClick(e, params?.row);
            }}
          >
            <Info />
          </div>
        </div>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    // {
    //   headerName: "Workload",
    //   field: "workload_name",
    //   resizable: true,
    //   minWidth: 160,
    //   flex: 1,
    //   renderCell: params => (
    //     <div className="flex items-center justify-center gap-2">
    //       {params?.row?.workload_name}
    //       <WorkloadModal placeholder={params?.row?.workload_name} />
    //     </div>
    //   ),
    //   headerClassName: "super-app-theme--header",
    //   hide: false
    // },
    // {
    //   headerName: "Labels",
    //   field: "LabelCount",
    //   resizable: true,
    //   minWidth: 70,
    //   flex: 1,
    //   renderCell: params => (
    //     <>
    //       {isTableEntityDetailLoading ? (
    //         <Skeleton variant="rectangular" width={70} height={11} />
    //       ) : (
    //         <ButtonLink
    //           className="cursor-pointer"
    //           isUnderline={false}
    //           buttonLable={params?.value}
    //           onClick={() => {
    //             setSelectedPod({
    //               value: params?.id,
    //               label: params?.row?.name
    //             });
    //           }}
    //         />
    //       )}
    //     </>
    //   ),
    //   headerClassName: "super-app-theme--header",
    //   hide: false
    // },
    {
      headerName: "Containers",
      field: "ContainerCount",
      resizable: true,
      minWidth: 70,
      flex: 1,
      renderCell: params => (
        <>
          {isTableEntityDetailLoading ? (
            <Skeleton variant="rectangular" width={70} height={11} />
          ) : (
            <ButtonLink
              className="cursor-pointer"
              isUnderline={false}
              buttonLable={params?.value}
              onClick={() => {
                setSelectedPod({
                  value: params?.id,
                  label: params?.row?.name
                });
                setSelectedView(CONTAINER);
              }}
            />
          )}
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    },
    // {
    //   headerName: "Location",
    //   field: "Location",
    //   resizable: true,
    //   minWidth: 160,
    //   flex: 1,
    //   renderCell: params => <div>{params?.value}</div>,
    //   headerClassName: "super-app-theme--header",
    //   hide: false
    // },
    {
      headerName: "Network Traffic",
      field: "ID",
      resizable: true,
      minWidth: 160,
      flex: 1,
      renderCell: params => (
        <>
          <ButtonLink
            className="cursor-pointer"
            isUnderline={false}
            buttonLable="View Traffic"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              toggleActions.setAsGraphView(true);
              setHighlightEntity(params?.id);
            }}
          />
        </>
      ),
      headerClassName: "super-app-theme--header",
      hide: false
    }
  ];
};

export const AUTO_DISCOVERED_POLICY_COLUMN = [
  // { field: "id", headerName: "ID", width: 300 },
  { field: "name", headerName: "Name", minWidth: 250 },
  { field: "type", headerName: "Type", minWidth: 100 },
  { field: "cluster_name", headerName: "Cluster Name", minWidth: 150 },
  { field: "namespace_name", headerName: "Namespace", minWidth: 100 },
  { field: "category", headerName: "Category", minWidth: 100 },
  {
    field: "labels",
    headerName: "Selector Labels",
    renderCell: params => (
      <div className="flex items-center justify-center gap-2">
        {/* {params?.row?.workload_name} */}
      </div>
    ),
    minWidth: 250
  }
];
