import React from "react";

export default function Monitor(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 17"
      fill="none"
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 0.25H2.25C1.425 0.25 0.75 0.925 0.75 1.75V10.75C0.75 11.575 1.425 12.25 2.25 12.25H6V13.75H12V12.25H15.75C16.575 12.25 17.2425 11.575 17.2425 10.75L17.25 1.75C17.25 0.925 16.575 0.25 15.75 0.25ZM15.75 10.75H2.25V1.75H15.75V10.75ZM14.25 4H6V5.5H14.25V4ZM14.25 7H6V8.5H14.25V7ZM5.25 4H3.75V5.5H5.25V4ZM5.25 7H3.75V8.5H5.25V7Z"
        fill={props.color}
      />
    </svg>
  );
}
