import { getAccuknoxWorkspace } from "api/api";
import { onChangeUrlParams, useQuery as filterQuery } from "helper/history";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useClustersideBarData } from "screens/Inventory/ClusterManagement/query";
import { TIME_RANGE } from "./constant/constant";
import {
  callFileObservability,
  callGetNetworkObservability,
  callGetTotalCounts,
  callNamespaceFilter,
  callNetworkObservabilityFlow,
  callNetworkObservabilityProcessFlow,
  callPodsFilter,
  callProcessObservability
} from "./service";
import { createNode, secondaryNode } from "./Utils/utils";
import { usePrimaryLayoutTitle } from "screens/MonitorLogs/Context/Context";

const ObservabilityContext = createContext();

export const ObservabilityContextProvider = ({ children }) => {
  const { logdDslTag } = usePrimaryLayoutTitle();
  const workspaceVal = getAccuknoxWorkspace();
  const location = useLocation();
  const getClusterFilterData = useClustersideBarData();
  const [clusterFilterData, setClusterFilterData] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState(location?.state?.selectedCluster);
  const [selectedNamespace, setSelectedNamespace] = useState(location?.state?.selectedNamespace);
  const [selectedPod, setSelectedPod] = useState(location?.state?.selectedPod);
  const [portDropdown, setPortDropdown] = useState([]);
  const [selectedPort, setSelectedPort] = useState("");
  const [countOfCommands, setCountOfCommands] = useState(0);
  const [datePicker, setDatePicker] = useState(TIME_RANGE[0].value);
  const [pageNext, setPageNext] = useState(10);
  const [pagePrevious, setPagePrevious] = useState(0);
  const [pageFileNext, setPageFileNext] = useState(10);
  const [pageFilePrevious, setPageFilePrevious] = useState(0);
  const [pageProcessNext, setPageProcessNext] = useState(10);
  const [pageProcessPrevious, setPageProcessPrevious] = useState(0);
  const [showCommandDetails, setShowCommandDetails] = useState(false);
  const [showModal, setShowModal] = useState(!location?.state?.selectedCluster);
  const [activeTab, setActiveTab] = useState(1);
  const [aggregatedView, setAggregatedView] = useState(true);
  const filters = {};
  logdDslTag?.map(item =>
    Object.keys(item)
      .filter(key => key != "text")
      .reduce((acc, key) => {
        acc[key] = item[key];
        Object.assign(filters, acc);
        return acc;
      }, {})
  );
  useEffect(() => {
    if (!getClusterFilterData?.isLoading && getClusterFilterData?.data?.length === 0) {
      setClusterFilterData([]);
    } else if (getClusterFilterData?.data) {
      setClusterFilterData(
        getClusterFilterData?.data
          ?.sort((a, b) => {
            if (a.ClusterName < b.ClusterName) return -1;
            if (a.ClusterName > b.ClusterName) return 1;
            return 0;
          })
          .map(e => {
            return {
              value: e.ID,
              label: e.clusterName
            };
          })
      );
    }
  }, [JSON.stringify(getClusterFilterData)]);
  const getTotalCounts = useQuery(
    ["getTotalCounts", workspaceVal, selectedPod, datePicker],
    async () => {
      const response = await callGetTotalCounts(
        workspaceVal,
        selectedCluster,
        selectedPod,
        datePicker
      );
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      refetchOnWindowFocus: false,
      enabled: !!selectedPod?.value,
      cacheTime: 0
    }
  );

  const processListData = useQuery(
    [
      "getProcessListData",
      workspaceVal,
      selectedPod,
      filters,
      datePicker,
      pageProcessPrevious,
      pageProcessNext
    ],
    async () => {
      let response;
      try {
        response = await callProcessObservability(
          workspaceVal,
          selectedCluster,
          selectedPod,
          filters,
          datePicker,
          pageProcessPrevious,
          pageProcessNext
        );
        const res = await response.json();
        return res;
      } catch (e) {
        // console.error(e);
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedPod?.value,
      cacheTime: 0
    }
  );

  const fileListData = useQuery(
    [
      "getFileListData",
      workspaceVal,
      selectedPod,
      filters,
      datePicker,
      aggregatedView,
      pageFilePrevious,
      pageFileNext
    ],
    async () => {
      let response;
      try {
        response = await callFileObservability(
          workspaceVal,
          selectedCluster,
          selectedPod,
          filters,
          aggregatedView,
          datePicker,
          pageFilePrevious,
          pageFileNext
        );
        const res = await response.json();
        return res;
      } catch (e) {
        // console.error(e);
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedPod?.value,
      cacheTime: 0
    }
  );

  const networkListData = useQuery(
    ["getNetworkListData", workspaceVal, selectedPod, filters, datePicker, pagePrevious, pageNext],
    async () => {
      let response;
      try {
        response = await callGetNetworkObservability(
          workspaceVal,
          selectedCluster,
          selectedPod,
          filters,
          datePicker,
          pagePrevious,
          pageNext
        );
        const res = await response.json();
        return res;
      } catch (e) {
        // console.error(e);
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedPod?.value,
      cacheTime: 0
    }
  );

  const networkFlowData = useQuery(
    ["getNetworkFlowData", workspaceVal, selectedPod, filters, datePicker],
    async () => {
      let response;
      try {
        response = await callNetworkObservabilityFlow(
          workspaceVal,
          selectedCluster,
          selectedPod,
          filters,
          datePicker
        );
        const res = await response.json();
        return res;
      } catch (e) {
        // console.error(e);
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedPod?.value,
      cacheTime: 0,
      onSettled: data => {
        if (data?.nodes) {
          createNode(data);
        }
      }
    }
  );

  const networkProcessFlowData = useQuery(
    ["getNetworkProcessFlowData", workspaceVal, selectedPod, filters, datePicker],
    async () => {
      let response;
      try {
        response = await callNetworkObservabilityProcessFlow(
          workspaceVal,
          selectedCluster,
          selectedPod,
          filters,
          datePicker
        );
        const res = await response.json();
        return res;
      } catch (e) {
        // console.error(e);
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedPod?.value,
      cacheTime: 0,
      onSettled: data => {
        if (data?.nodes) {
          secondaryNode(data);
        }
      }
    }
  );
  const namespaceFilterData = useQuery(
    ["getNamespaceFilterData", workspaceVal, selectedCluster],
    async () => {
      let response;
      try {
        response = await callNamespaceFilter(workspaceVal, selectedCluster);
        const res = await response.json();
        return res?.result;
      } catch (e) {
        // console.error(e);
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedCluster?.value
    }
  );

  const podFilterData = useQuery(
    ["getPodFilterData", workspaceVal, selectedCluster, selectedNamespace],
    async () => {
      let response;
      try {
        response = await callPodsFilter(workspaceVal, selectedCluster, selectedNamespace);
        const res = await response.json();
        return res?.result;
      } catch (e) {
        // console.error(e);
      }
    },
    {
      enabled: !!selectedNamespace?.value,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (networkProcessFlowData?.data?.nodes) {
      const res = networkProcessFlowData?.data?.nodes?.filter(e => e.type === "SecondaryHeadNode");
      setCountOfCommands(res?.length);
    }
  }, [JSON.stringify(networkProcessFlowData)]);

  const query = filterQuery();
  const queryFilters = query.get("filters");
  useEffect(() => {
    if (!selectedCluster && !selectedNamespace && queryFilters) {
      const params = JSON.parse(queryFilters);
      if (params.cluster) {
        setSelectedCluster(params.cluster);
      }
      if (params.namespace) {
        setSelectedNamespace(params.namespace);
      }
      if (params.pod) {
        setSelectedPod(params.pod);
      }
    }
    return () => {
      onChangeUrlParams("filter", "");
    };
  }, [queryFilters]);

  useEffect(() => {
    const params = JSON.parse(queryFilters);
    if (!params && !location.state) {
      if (!selectedCluster && clusterFilterData?.length > 0) {
        setSelectedCluster(clusterFilterData[0]);
      }
      if (!selectedNamespace && namespaceFilterData?.data) {
        let filterNamespace = namespaceFilterData?.data.find(
          e => e.namespace === "accuknox-agents"
        );
        filterNamespace
          ? setSelectedNamespace({
              value: filterNamespace.ID,
              label: filterNamespace.namespace
            })
          : setSelectedNamespace(null);
      }
      if (!selectedPod && podFilterData?.data) {
        let filterPod = podFilterData?.data?.find(e => e.PodName.includes("feeder-service-"));
        filterPod
          ? setSelectedPod({
              value: filterPod.ID,
              label: filterPod.PodName
            })
          : setSelectedPod(null);
      }
    }
  }, [
    JSON.stringify(clusterFilterData),
    JSON.stringify(namespaceFilterData),
    JSON.stringify(podFilterData)
  ]);

  useEffect(() => {
    const dictFilter = {};
    if (selectedCluster) {
      dictFilter.cluster = selectedCluster;
    }
    if (selectedNamespace) {
      dictFilter.namespace = selectedNamespace;
    }
    if (selectedPod) {
      dictFilter.pod = selectedPod;
    }
    if (dictFilter.cluster && namespaceFilterData?.data === null) {
      return onChangeUrlParams("filters", JSON.stringify(dictFilter));
    }
    if (dictFilter.cluster && selectedNamespace === null) {
      return onChangeUrlParams("filters", JSON.stringify(dictFilter));
    }
    if (dictFilter.cluster && dictFilter.namespace && selectedPod === null) {
      return onChangeUrlParams("filters", JSON.stringify(dictFilter));
    }
    if (dictFilter.pod) {
      return onChangeUrlParams("filters", JSON.stringify(dictFilter));
    }
  }, [selectedCluster, selectedNamespace, selectedPod, JSON.stringify(namespaceFilterData)]);

  const contextValue = useMemo(
    () => ({
      clusterFilterData,
      podFilterData,
      namespaceFilterData,
      getTotalCounts,
      processListData,
      fileListData,
      networkListData,
      networkFlowData,
      networkProcessFlowData,
      selectedPod,
      setSelectedPod,
      selectedNamespace,
      setSelectedNamespace,
      selectedCluster,
      setSelectedCluster,
      portDropdown,
      setPortDropdown,
      selectedPort,
      setSelectedPort,
      countOfCommands,
      showCommandDetails,
      setShowCommandDetails,
      showModal,
      setShowModal,
      datePicker,
      setDatePicker,
      setPagePrevious,
      setPageNext,
      pagePrevious,
      pageNext,
      pageFileNext,
      pageFilePrevious,
      setPageFileNext,
      setPageFilePrevious,
      pageProcessNext,
      pageProcessPrevious,
      setPageProcessNext,
      setPageProcessPrevious,
      activeTab,
      setActiveTab,
      aggregatedView,
      setAggregatedView
    }),
    [networkProcessFlowData]
  );

  return (
    <ObservabilityContext.Provider value={contextValue}>{children}</ObservabilityContext.Provider>
  );
};

export const useObservabilityContext = () => {
  const context = useContext(ObservabilityContext);
  if (context === undefined)
    // eslint-disable-next-line no-console
    console.warn("useObservabilityContext must be used within ObservabilityContextProvider");
  return context;
};
