import React, { useState } from "react";
import { PieChart, Pie, Legend, Tooltip, Cell } from "recharts";
import styles from "./styles.module.scss";

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className=" w-auto h-auto px-3 rounded-xl flex justify-center items-center bg-white opacity-90">
        <p className="w-full">
          {`${payload[0]?.name} : `} <span>{payload[0].value}</span>
        </p>
      </div>
    );
  }

  return null;
};

export default function RechartPieChart({ data = [], color, onClickofChart, isLabelClickable }) {
  const [hovered1, setHovered1] = useState(undefined);
  return (
    <div className="w-full grid grid-cols-2 overflow-hidden gap-2">
      <div className="col-span-1">
        <PieChart width={200} height={150}>
          <Pie
            dataKey="value"
            data={data}
            cx={70}
            cy={60}
            innerRadius={25}
            minAngle={5}
            outerRadius={65}
            fill="#82ca9d"
            isAnimationActive={false}
            onMouseOver={(event, index) => {
              setHovered1(index);
            }}
            onMouseOut={() => {
              setHovered1(undefined);
            }}
            onClick={data => onClickofChart(data?.payload?.payload)}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                style={{
                  filter: `${index === hovered1 && `drop-shadow(0px 0px 2px ${entry?.color}`}`,
                  transitionDelay: "2s",
                  transitionTimingFunction: "ease-in-out"
                }}
                fill={color[index % color.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </div>
      <div
        className={`col-span-1 flex flex-col ${
          data?.length < 6 ? "justify-center" : null
        }  gap-[6px] py-3 overflow-auto ${styles.legend}`}
      >
        {data?.map((item, index) => (
          <div
            className={`grid grid-cols-6 ${
              data?.length < 6 ? "items-center" : "items-top"
            }center justify-center text-sm ${isLabelClickable && styles.selectedLabel}`}
            key={index}
            onClick={() => {
              isLabelClickable && onClickofChart(item);
            }}
          >
            <div
              className="w-3 h-3 rounded col-span-1 m-auto"
              style={{ background: item?.color }}
            />
            <p className="col-span-5 m-0">
              {item?.name || item?.title}: {item?.value}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
