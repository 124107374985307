import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { formatDate } from "helper/DateLib";

import {
  getVulnerabilitySelector,
  getVulnerabilityHistorySelector,
  getVulnerabilityAssetsSelector,
  getSeverityOptionsSelector,
  getStatusesOptionsSelector,
  getTicketsConfigSelect
} from "store/entities/vulnerabilities/selectors";
import {
  getVulnerabilityTicketsSelector,
  getVulnerabilityTicketSelector,
  getCommentsQuantitySelector
} from "store/entities/tickets/selectors";

import {
  getVulnerability,
  getVulnerabilityHistory,
  getVulnerabilityAssets,
  editVulnerability,
  getFindingStatuses,
  getVulnerabilityRiskFactors,
  editFinding,
  actionTargetModal,
  editFindingAsset
} from "store/entities/vulnerabilities/actions";

import {
  getVulnerabilityTickets,
  postVulnerabilityTicketComment,
  getTicketsConfig
} from "store/entities/tickets/actions";
import { createLoadingSelector } from "store/entities/loading/selector";
import { getLabelsMini } from "store/entities/labels/actions";
import { labelsMiniSelector } from "store/entities/labels/selectors";

import { routes } from "router";

import { Button, Select, DataList } from "components/simple";
import CommentsModal from "components/complex/CommentsModal";
import Comments from "./Comments";
import History from "./History";
import Statistics from "./Statistics";
import EditModal from "./EditModal";
import { CreateTargetModal } from "../CreateTargetModal";

import styles from "./styles.module.scss";

const Details = props => {
  const initialState = {
    comment: "",
    name: "",
    description: "",
    solution: "",
    severity: "", // risk_factor
    exportabiltiy: "", // exploit_available
    status: "",
    id: ""
  };
  const [state, setState] = useState({ ...initialState });

  const [isCommentsModalOpen, setCommentsModalOpen] = useState(false);
  const [commentsModalStep, setCommentsModalStep] = useState(0);
  const [ticketId, setTicketId] = useState("");
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState("");
  const [isVulnerabilityEditing, setVulnerabilityEditing] = useState(false);
  const [searchText, setSearchText] = useState({});
  const [assetsList, setAssetsList] = useState([]);
  const [ticketsList, setTicketsList] = useState([]);
  const [historyList, setHistoryList] = useState([]);

  const vulnerability = useSelector(getVulnerabilitySelector);
  const tickets = useSelector(getVulnerabilityTicketsSelector);
  const history = useSelector(getVulnerabilityHistorySelector);
  const assets = useSelector(getVulnerabilityAssetsSelector);
  const commentsQuantity = useSelector(getCommentsQuantitySelector);
  const severityOptions = useSelector(getSeverityOptionsSelector);
  const statusesOptions = useSelector(getStatusesOptionsSelector);
  const ticket = useSelector(store => getVulnerabilityTicketSelector(store, ticketId));
  const ticketsConfigList = useSelector(getTicketsConfigSelect);

  const labelsOptions = useSelector(labelsMiniSelector);
  const [label, setLabel] = useState("");

  const [selectTicketConfig, setSelectTicketConfig] = useState("");

  const selectTenant = useSelector(st => st.users.selectTenant);

  const isTicketsLoading = useSelector(s =>
    createLoadingSelector([getVulnerabilityTickets.type])(s)
  );
  const isAddCommentLoading = useSelector(s =>
    createLoadingSelector([postVulnerabilityTicketComment.type])(s)
  );

  const dispatch = useDispatch();
  const { id: findingId } = useParams();

  const navigation = useHistory();

  const setStateHelper = data => {
    const {
      status,
      vulnerability: { id, name, description, solution, risk_factor: severity }
    } = data;
    setState({
      ...state,
      id,
      name,
      description,
      solution,
      severity: { label: severity, value: severity },
      status: { label: status, value: status }
    });
  };

  useEffect(() => {
    dispatch(getVulnerability(findingId));
    dispatch(getVulnerabilityTickets(findingId));
    dispatch(getVulnerabilityHistory(findingId));
    dispatch(getVulnerabilityAssets({ id: findingId }));
    dispatch(getFindingStatuses());
    dispatch(getVulnerabilityRiskFactors());
    dispatch(getTicketsConfig("finding"));
    dispatch(getLabelsMini());
  }, [selectTenant]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(vulnerability).length !== 0) {
      setStateHelper(vulnerability);
    }
  }, [vulnerability]);

  useEffect(() => {
    if (tickets.length > 0) {
      setTicketsList(tickets);
    }
    if (assets) {
      setAssetsList(assets);
    }
    if (history.length > 0) {
      setHistoryList(history);
    }
  }, [tickets, history, assets]);

  const inputChangeHandler = (value, name) => {
    setState({
      ...state,
      [name]: value
    });
  };

  const filterListHandler = (filterBy, value, list, name) => {
    const newList = list.filter(elem => {
      if (filterBy === "date") {
        return formatDate(elem[filterBy], "DD/MM/YYYY").includes(value);
      }
      if (name === "assets") {
        return elem.asset[filterBy].toLowerCase().includes(value.toLowerCase());
      }
      return elem[filterBy].toLowerCase().includes(value.toLowerCase());
    });
    return newList;
  };

  const searchInputChangeHandler = (value, name) => {
    setSearchText({
      ...searchText,
      [name]: value
    });

    let newList;
    switch (name) {
      case "history":
        if (value === "") setHistoryList(history);
        newList = filterListHandler("date", value, history);
        setHistoryList(newList);
        break;

      case "assets":
        if (value === "") setAssetsList(assets);
        newList = filterListHandler("title", value, assets, "assets");
        setAssetsList(newList);
        break;

      case "tickets":
        if (value === "") setTicketsList(tickets);
        newList = filterListHandler("title", value, tickets);
        setTicketsList(newList);
        break;

      default:
        break;
    }
  };

  const submitVulnerabilityHandler = () => {
    const { id, name, description, solution, severity } = state;
    const data = {
      id,
      name,
      description,
      solution,
      risk_factor: severity.value
    };
    dispatch(editVulnerability({ data, id: findingId }));
  };

  const submitFindingHandler = () => {
    const data = {
      status: state.status.value,
      id: findingId
    };
    dispatch(editFinding({ data }));
  };

  const cancelEditVulnerabilityHandler = () => {
    setVulnerabilityEditing(!isVulnerabilityEditing);
    if (Object.getOwnPropertyNames(vulnerability).length !== 0) {
      setStateHelper(vulnerability);
    }
  };

  const toggleEditModalHandler = () => {
    if (isEditModalOpen) {
      setStateHelper(vulnerability);
    }
    setEditModalOpen(!isEditModalOpen);
  };

  const openEditModalHandler = name => {
    setEditModalOpen(true);
    setEditModalData(name);
  };

  const clearCommentFieldHandler = () => {
    setState({
      ...state,
      comment: ""
    });
  };

  const switchCommentsModalStepHandler = (direction, currentStep) => {
    if (direction === "back") {
      if (currentStep === 3 && !ticket?.comments?.length) {
        setCommentsModalStep(currentStep - 2);
      } else {
        setCommentsModalStep(currentStep - 1);
      }
      clearCommentFieldHandler();
    } else {
      setCommentsModalStep(currentStep + 1);
    }
  };

  const closeCommentsModalHnadler = () => {
    setCommentsModalStep(0);
    setCommentsModalOpen(!isCommentsModalOpen);
    clearCommentFieldHandler();
  };

  const successSubmitCommentCallback = () => {
    switchCommentsModalStepHandler("back", commentsModalStep);
    clearCommentFieldHandler();
  };

  const submitCommentHandler = () => {
    if (state.comment) {
      dispatch(
        postVulnerabilityTicketComment({
          data: {
            ticket_id: ticket.id,
            comment: state.comment
          },
          findingId,
          successCallback: successSubmitCommentCallback
        })
      );
    }
  };

  const createTarget = () => {
    dispatch(actionTargetModal({ show: true, data: [findingId] }));
  };

  const createTicket = () => {
    const ticketConfig_id = selectTicketConfig?.value;
    navigation.push(`${routes.vulnerabilitiesCreateTicket}/${ticketConfig_id}?ids=${findingId}`);
  };

  const onSelectTicketConfig = v => {
    setSelectTicketConfig(v);
  };

  const onSelectChange = (v, name, assetId) => {
    const data = {
      [name]: v,
      id: assetId
    };
    dispatch(editFindingAsset({ data, findingId, label: label?.value }));
  };

  const onSelectLabel = v => {
    dispatch(getVulnerabilityAssets({ id: findingId, label: v?.value }));
    setLabel(v);
    setSearchText({
      ...searchText,
      assets: ""
    });
  };

  return (
    <div>
      <div className={styles.buttonsContainer}>
        <Button variant="outline" icon="plus" className={styles.button} onClick={createTarget}>
          Create a target
        </Button>
        <div className={styles.buttonGroup}>
          {ticketsConfigList?.length ? (
            <Select
              containerClass={styles.select}
              onChange={onSelectTicketConfig}
              value={selectTicketConfig}
              placeholder="Ticket Configuration"
              options={ticketsConfigList}
              isClearable
            />
          ) : null}

          <Button
            icon="plus"
            className={styles.button}
            onClick={createTicket}
            disabled={!selectTicketConfig?.value}
          >
            Create a ticket
          </Button>
        </div>
      </div>

      {Object.getOwnPropertyNames(vulnerability).length !== 0 && (
        <>
          <div className={styles.contentGrid}>
            <div className={styles.gridColumn}>
              <Comments
                vulnerability={state}
                ticketsQuantity={tickets?.length}
                commentsQuantity={commentsQuantity}
                isEditing={isVulnerabilityEditing}
                onChange={inputChangeHandler}
                onSubmit={submitVulnerabilityHandler}
                onEditModeSwitch={() => setVulnerabilityEditing(!isVulnerabilityEditing)}
                onCancelEditMode={cancelEditVulnerabilityHandler}
                onShowComments={() => {
                  switchCommentsModalStepHandler("forward", commentsModalStep);
                  setCommentsModalOpen(true);
                }}
              />
              <History
                list={historyList}
                name="history"
                searchText={searchText.history}
                onSearch={searchInputChangeHandler}
              />
            </div>

            <div className={styles.gridColumn}>
              <Statistics data={vulnerability} onShowEditModal={openEditModalHandler} />
              <DataList
                list={assetsList}
                title="Impacted assets"
                name="assets"
                searchText={searchText.assets}
                onClick={e => props.history.push(`${routes.dataStructureAssetsDetails}/${e?.id}`)}
                onSearch={searchInputChangeHandler}
                onSelectChange={onSelectChange}
                header={
                  <div className={styles.labelFilter}>
                    <Select
                      containerClass={styles.select}
                      onChange={onSelectLabel}
                      value={label}
                      placeholder="Label"
                      options={labelsOptions}
                      isClearable
                    />
                  </div>
                }
              />
              <DataList
                list={ticketsList}
                title="Tickets"
                name="tickets"
                searchText={searchText.tickets}
                onSearch={searchInputChangeHandler}
              />
            </div>
          </div>

          <CommentsModal
            step={commentsModalStep}
            setStep={switchCommentsModalStepHandler}
            tickets={tickets}
            ticket={ticket}
            commentValue={state.comment}
            onSelectTicket={setTicketId}
            onChange={inputChangeHandler}
            onSubmit={submitCommentHandler}
            isOpen={isCommentsModalOpen}
            onCloseModal={closeCommentsModalHnadler}
            isTicketsLoading={isTicketsLoading}
            isAddCommentLoading={isAddCommentLoading}
          />

          <EditModal
            value={editModalData === "severity" ? state.severity : state.status}
            options={editModalData === "severity" ? severityOptions : statusesOptions}
            title={editModalData}
            onChange={inputChangeHandler}
            onSubmit={
              editModalData === "severity" ? submitVulnerabilityHandler : submitFindingHandler
            }
            isOpen={isEditModalOpen}
            toggle={toggleEditModalHandler}
          />
        </>
      )}
      <CreateTargetModal />
    </div>
  );
};

Details.propTypes = {};

export default Details;
