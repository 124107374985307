/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createErrorMessageSelector } from "store/entities/error/selector";
import styles from "./styles.module.scss";

import { Typography } from "@mui/material";
import { Button, Input } from "components/simple";
import AccuknoxLogoIcon from "../assets/AccuknoxLogo.png";

import { routes } from "router/router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLoginMutation } from "store/entities/auth/mutations";
import { allowSignup } from "store/entities/auth/constants";

const Login = ({ history }) => {
  const [errorMessageEmail, setErrorMessageEmail] = useState(null);
  const [errorMessagePassword, setErrorMessagePassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: ""
  });

  const { mutate: login, isLoading: isLoggingIn } = useLoginMutation();

  const handleSubmit = e => {
    e.preventDefault();
    if (!state.email?.length) {
      setErrorMessageEmail("this field is required");
      return;
    }
    if (!state.password?.length) {
      setErrorMessagePassword("this field is required");
      return;
    }
    const { email, password } = state;
    login({ username: email, password });
  };

  const handleChange = (val, type) => {
    setErrorMessageEmail(null);
    setErrorMessagePassword(null);
    setState({
      ...state,
      [type]: val
    });
  };

  return (
    <div className={styles.container}>
      <div className="mt-8">
        <img src={AccuknoxLogoIcon} width="311px" height="64px" alt="" />
      </div>
      <div className="flex justify-center items-center">
        <div className={styles.authCard}>
          <Typography variant="h4" color="white" sx={{ width: "97%" }} fontWeight={600}>
            Cloud Native Application Protection
          </Typography>
          <Typography variant="body1" color="white">
            Easily see & secure cloud assets, workloads, vulnerabilities and posture.
          </Typography>
          {Boolean(allowSignup) && (
            <div className="w-full text-left">
              <Button
                onClick={() => {
                  history.push(routes.signUpScreen);
                }}
                className={styles.signUpBtn}
              >
                Sign Up
              </Button>
            </div>
          )}
        </div>
        <div className={styles.formSignIn}>
          <div className={styles.title}>Sign In</div>
          {showReset && (
            <div className="bg-[#FFF6F6] mb-[15px] pt-[2px] pb-[2px] pl-[10px] w-[67%] flex justify-start items-start flex-col">
              <p className="text-[#EC7070]">
                User account has not been confirmed{" "}
                <span className="text-[#3200C1] cursor-pointer">click here </span>
                to resend confirmation email
              </p>
              <div></div>
            </div>
          )}
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className="w-full mb-5">
              <Input
                label="Email Address"
                value={state.email}
                onChange={val => handleChange(val.target.value, "email")}
                invalid={errorMessageEmail}
                containerClassName={styles.input}
                className={styles.input}
                placeholder="Enter Email address"
                required
              />
            </div>
            <div className="w-full z-0">
              <Input
                label="Password"
                value={state.password}
                onChange={val => handleChange(val.target.value, "password")}
                invalid={errorMessagePassword}
                type={`${showPassword ? "text" : "password"}`}
                containerClassName={styles.inputpassword}
                className={`${styles.inputPassword}`}
                placeholder="Enter Password"
                required
              />
              <div className="text-right">
                <VisibilityIcon
                  onClick={() => setShowPassword(!showPassword)}
                  sx={{
                    marginRight: "17px",
                    marginTop: "-20px",
                    marginBottom: "14px",
                    cursor: "pointer"
                  }}
                />
              </div>
            </div>
            {/* <div className={main["error-text"]}>{errorMessage}</div> */}
            <div
              className={`${styles.forgetPassword}`}
              onClick={() => {
                history.push(routes.forgotPassword);
              }}
            >
              Forgot password?
            </div>
            <div className="w-full text-left mb-7">
              <Button className={styles.button} type="submit" disabled={isLoggingIn}>
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
