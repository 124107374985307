import React, { useState } from "react";

import {
  useDeleteUser,
  useResendInvite,
  useGetUsersQuery
} from "store/entities/userProfile/queries";
import ActionMenu from "components/simple/ActionMenu";
import { UsersTable, ConfirmationModal } from "./utils";

export const PendingInvites = () => {
  const [resendModal, setResendModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const invitedUsers = useGetUsersQuery(0);
  const { mutate: resendInvite } = useResendInvite();
  const { mutate: deleteUserMutation } = useDeleteUser();

  const MenuComponent = ({ user }) => {
    return (
      <ActionMenu
        onClick={() => {
          setSelectedUser(user);
        }}
        menuItems={[
          {
            name: "Resend",
            onClick: () => {
              setResendModal(true);
            }
          },
          {
            name: "Delete",
            onClick: () => {
              setDeleteModal(true);
            }
          }
        ]}
      />
    );
  };

  return (
    <>
      {/* resend invitation modal */}
      <ConfirmationModal
        isActive={resendModal}
        heading="Are you sure you want to Resend the invite?"
        handleCancel={() => setResendModal(false)}
        handleAction={() => {
          resendInvite(selectedUser.id);
          setResendModal(false);
          invitedUsers?.refetch();
        }}
        actionText="Resend"
      />

      {/* delete modal */}
      <ConfirmationModal
        isActive={deleteModal}
        heading="Are you sure you want to delete the user from workspace?"
        handleCancel={() => setDeleteModal(false)}
        handleAction={() => {
          deleteUserMutation(selectedUser.id);
          setDeleteModal(false);
          invitedUsers?.refetch();
        }}
        actionText="Delete"
      />

      <UsersTable activeTab={2} users={invitedUsers} MenuComponent={MenuComponent} />
    </>
  );
};
