/* eslint-disable */
import React from "react";
import { Switch } from "react-router-dom";

// Routing
import * as routes from "router/routes";

// Screens
import Home from "screens/Home";
import {
  History,
  Datalist,
  DatalistConfig,
  Assets,
  AssetsDetails,
  Baselines as BaselineDashbord
} from "screens/DataStructure";

import { Vulnerabilities, VulnerabilityDetails } from "screens/Issues/Vulnerabilities";
import { RegistryScan } from "screens/Issues/RegistryScan";
import { RiskBasedPrioritization } from "screens/Issues/RiskBasedPrioritization";
import Baselines from "screens/Compliance/Baselines";
import FailedBaseline from "screens/Compliance/Baselines/FailedBaseline";
import CreateBaseline from "screens/Compliance/Baselines/CreateBaseline";
import BaselineConfiguration from "screens/Compliance/Baselines/BaselineConfiguration";
import CompareBaselines from "screens/Compliance/Baselines/CompareBaselines";
import { Targets, TargetsDetails } from "screens/Targets";
import { Tickets, TicketsDetails, CreateTicket } from "screens/Remediation/Tickets";

import { Reports, ReportsDetails } from "screens/Reports";
import { Notifications, NotificationsDetails } from "screens/Notifications";
// Settings
import IntegrationTabs, {
  Labels,
  Tags,
  TagsDetails,
  Groups,
  GroupsDetails,
  Rbac,
  RbacRoleDetails,
  UserProfiles,
  UserProfilesDetails,
  CloudAccounts,
  AddCloudAccount,
  ManageCluster,
  ManageClusterOnboard,
  Scrollspy
} from "screens/Settings";
import {
  DataSourceEdit,
  JiraCloud,
  JiraServer,
  Freshservice,
  EmailsDetails,
  TicketTemplateDetails,
  LabelDetails,
  ConnectWise,
  TicketTemplates
} from "screens/Settings/Tabs";
// Settings end

import Profile from "screens/Profile";

import { Monitors } from "screens/MonitorLogs/Monitors";
import MonitorsConfiguration from "screens/MonitorLogs/Monitors/MonitorsConfiguration";

import PrivateRoute from "components/PrivateRoute";
import AllPolicies from "screens/RuntimeSecurity/AllPolicies";

import ClusterManagement from "screens/Inventory/ClusterManagement/clusterManager";
import AppBehavior from "screens/RuntimeSecurity/AppBehavior";
import AutoDiscoveredPolicies from "screens/RuntimeSecurity/AutoDiscoveredPolicy";

import { PendingPolicyApproval } from "screens/RuntimeSecurity/PendingApproval";
import { PendingPolicyDetail } from "screens/RuntimeSecurity/PendingPolicyDetail";
import PolicyEditor from "screens/PolicyEditor";

import ChannelIntegration from "screens/ChannelIntegration";
import ChannelList from "screens/ChannelIntegration/pages/ChannelList";
import CreatePolicy from "screens/RuntimeSecurity/CreatePolicy";
import {
  JiraIntegration,
  RsyslogIntegration,
  AWSIntegration,
  ElasticSearch,
  SplunkIntegration,
  SlackIntegration,
  EditSplunkIntegration
} from "screens/ChannelIntegration/pages/channels";
import EmailIntegration from "components/simple/Emailintegration";
import PagerDutyIntegration from "components/simple/pagerDuty";
import WebHookIntegration from "components/simple/Webhook";
import PolicyDetails from "screens/RuntimeSecurity/PolicyDetails";
import CwppDashboard from "screens/RuntimeSecurity/CWPP";
import Logs from "screens/MonitorLogs/Logs/Logs";
import Logs_V2 from "screens/MonitorLogs/Logs/LogsV2";
import Triggers from "screens/MonitorLogs/Triggers/Triggers";
import { ImageDetails } from "screens/Issues/RegistryScan/ImageDetails/ImageDetails";
import { CnappDashboard } from "screens/CnappDashboard/CnappDashboard";
import Explorer from "screens/Inventory/Assets/AssetsDetails/Explorer";
import CSPM from "screens/Compliance/CSPM";
import EditAccount from "screens/Settings/CloudAccounts/EditAccount";
import Minions from "screens/Settings/minions/minions";

// Permissions
// import { AccessControlRoute, permissions } from "components/permissions";

const DashboardContent = () => {
  return (
    <Switch>
      <PrivateRoute exact path={routes.overview} component={Home} />

      <PrivateRoute exact path={routes.dataStructureHistory} component={History} />
      <PrivateRoute exact path={routes.explorer} component={Datalist} />
      <PrivateRoute exact path={routes.clusterManagement} component={ClusterManagement} />
      <PrivateRoute exact path={routes.createPolicyCluster} component={PolicyEditor} />
      <PrivateRoute exact path={routes.podObservability} component={AppBehavior} />
      <PrivateRoute exact path={routes.CwppDashboard} component={CwppDashboard} />
      <PrivateRoute exact path={routes.dataStructureDatalist} component={Datalist} />
      <PrivateRoute
        exact
        path={`${routes.dataStructureDatalistCreateTicket}/:ticketConfig_id`}
        component={CreateTicket}
      />
      <PrivateRoute exact path={routes.dataStructureAssets} component={Assets} />
      <PrivateRoute
        exact
        path={`${routes.dataStructureAssetsDetails}/:id`}
        component={AssetsDetails}
      />

      <PrivateRoute exact path={routes.dataStructureBaselines} component={BaselineDashbord} />
      <PrivateRoute exact path={routes.CSPM} component={CSPM} />

      <PrivateRoute exact path={routes.vulnerabilities} component={Vulnerabilities} />
      <PrivateRoute
        exact
        path={`${routes.vulnerabilitiesCreateTicket}/:ticketConfig_id`}
        component={CreateTicket}
      />
      <PrivateRoute
        exact
        path={`${routes.vulnerabilitiesDetails}/:id`}
        component={VulnerabilityDetails}
      />
      <PrivateRoute exact path={routes.registryScan} component={RegistryScan} />
      <PrivateRoute exact path={routes.registryScanImageDetails} component={ImageDetails} />
      <PrivateRoute
        exact
        path={routes.riskBasedPrioritization}
        component={RiskBasedPrioritization}
      />

      <PrivateRoute exact path={routes.baselines} component={Baselines} />

      <PrivateRoute exact path={`${routes.failedBaseline}/:id`} component={FailedBaseline} />
      <PrivateRoute exact path={routes.createBaseline} component={CreateBaseline} />
      <PrivateRoute exact path={`${routes.editBaseline}/:id`} component={CreateBaseline} />
      <PrivateRoute
        exact
        path={`${routes.baselineConfiguration}/:id`}
        component={BaselineConfiguration}
      />
      <PrivateRoute
        exact
        path={`${routes.baselineConfigurationCreateTicket}/:ticketConfig_id/:baseline_id`}
        component={CreateTicket}
      />
      <PrivateRoute exact path={`${routes.compareBaselines}/:ids`} component={CompareBaselines} />

      {/* <PrivateRoute exact path={routes.collectors} component={Targets} /> */}
      {/* <PrivateRoute exact path={`${routes.collectorsDetails}/:id`} component={TargetsDetails} /> */}

      <PrivateRoute exact path={routes.home} component={CnappDashboard} />
      <PrivateRoute exact path={routes.tickets} component={Tickets} />
      <PrivateRoute exact path={routes.ticketsDetails} component={TicketsDetails} />
      <PrivateRoute exact path={`${routes.ticketsDetails}/:id`} component={TicketsDetails} />

      <PrivateRoute exact path={routes.reports} component={Reports} />
      <PrivateRoute exact path={routes.reportsDetails} component={ReportsDetails} />
      <PrivateRoute exact path={`${routes.reportsDetails}/:id`} component={ReportsDetails} />

      <PrivateRoute exact path={routes.notifications} component={Notifications} />
      <PrivateRoute
        exact
        path={`${routes.notificationsDetails}/:id`}
        component={NotificationsDetails}
      />

      <PrivateRoute exact path={routes.settingsIntegrations} component={IntegrationTabs} />
      <PrivateRoute exact path={`${routes.settingsDataSourceEdit}`} component={DataSourceEdit} />
      {/* Setting Integrations */}
      <PrivateRoute exact path={routes.settingsCloudAccounts} component={CloudAccounts} />
      <PrivateRoute exact path={routes.settingsAddCloudAccount} component={AddCloudAccount} />
      <PrivateRoute exact path={`${routes.settingsEditCloudAccount}/:id`} component={EditAccount} />
      <PrivateRoute exact path={routes.settingsIntegrationsCspm} component={IntegrationTabs} />
      <PrivateRoute exact path={routes.settingsIntegrationsRegistry} component={IntegrationTabs} />
      <PrivateRoute
        exact
        path={routes.settingsIntegrationsRegistryAdd}
        component={IntegrationTabs}
      />
      <PrivateRoute
        exact
        path={routes.settingsIntegrationsRegistryEdit}
        component={IntegrationTabs}
      />
      <PrivateRoute
        exact
        path={routes.settingsIntegrationsS3DataSource}
        component={IntegrationTabs}
      />
      <PrivateRoute exact path={routes.settingsIntegrationsJiraServer} component={JiraServer} />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsJiraServer}/:id`}
        component={JiraServer}
      />
      <PrivateRoute exact path={routes.settingsIntegrationsJiraCloud} component={JiraCloud} />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsJiraCloud}/:id`}
        component={JiraCloud}
      />
      <PrivateRoute exact path={routes.settingsIntegrationsFreshservice} component={Freshservice} />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsFreshservice}/:id`}
        component={Freshservice}
      />
      <PrivateRoute
        exact
        path={routes.settingsIntegrationsEmailsDetails}
        component={EmailsDetails}
      />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsEmailsDetails}/:id`}
        component={EmailsDetails}
      />

      <PrivateRoute exact path={routes.settingsIntegrationsConnectWise} component={ConnectWise} />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsConnectWise}/:id`}
        component={ConnectWise}
      />

      {/* Setting Integrations End */}

      <PrivateRoute exact path={routes.settingsTicketTemplates} component={TicketTemplates} />
      <PrivateRoute
        exact
        path={routes.settingsTicketTemplatesDetails}
        component={TicketTemplateDetails}
      />
      <PrivateRoute
        exact
        path={`${routes.settingsTicketTemplatesDetails}/:id`}
        component={TicketTemplateDetails}
      />

      <PrivateRoute exact path={routes.settingsRbac} component={Rbac} />
      <PrivateRoute exact path={routes.settingsRbacRoleAdd} component={RbacRoleDetails} />
      <PrivateRoute exact path={`${routes.settingsRbacRoleEdit}/:id`} component={RbacRoleDetails} />

      <PrivateRoute exact path={routes.settingsLabels} component={Labels} />
      {/* <PrivateRoute exact path={routes.settingsLabels} component={Settings} /> */}
      {/* <PrivateRoute exact path={routes.settingsLabelsDetails} component={LabelDetails} /> */}
      {/* <PrivateRoute exact path={`${routes.settingsLabelsDetails}/:id`} component={LabelDetails} /> */}

      <PrivateRoute exact path={routes.settingsTags} component={Tags} />
      <PrivateRoute exact path={`${routes.settingsTagsDetails}/:id`} component={TagsDetails} />

      <PrivateRoute exact path={routes.settingsGroups} component={Groups} />
      <PrivateRoute exact path={`${routes.settingsGroupsDetails}/:id`} component={GroupsDetails} />

      <PrivateRoute exact path={routes.settingsUserProfiles} component={UserProfiles} />
      <PrivateRoute
        exact
        path={`${routes.settingsUserProfilesDetails}`}
        component={UserProfilesDetails}
      />
      <PrivateRoute
        exact
        path={`${routes.settingsUserProfilesDetails}/:id`}
        component={UserProfilesDetails}
      />

      <PrivateRoute exact path={routes.profile} component={Profile} />

      <PrivateRoute exact path={routes.monitors} component={Monitors} />
      <PrivateRoute
        exact
        path={`${routes.monitorsConfiguration}`}
        component={MonitorsConfiguration}
      />
      <PrivateRoute
        exact
        path={`${routes.monitorsConfiguration}/:id`}
        component={MonitorsConfiguration}
      />
      <PrivateRoute exact path={routes.settingsManageCluster} component={ManageCluster} />
      <PrivateRoute
        exact
        path={`${routes.settingsManageClusterOnboard}`}
        component={ManageClusterOnboard}
      />
      <PrivateRoute
        exact
        path={`${routes.settingsManageClusterOnboardScrollSpy}`}
        component={Scrollspy}
      />
      <PrivateRoute exact path={`${routes.settingsManageClusterScrollSpy}`} component={Scrollspy} />

      <PrivateRoute exact path={routes.allPolicies} component={AllPolicies} />
      <PrivateRoute exact path={routes.policyHistory} component={PendingPolicyDetail} />
      <PrivateRoute exact path={routes.policiesPendingApproval} component={PendingPolicyApproval} />
      <PrivateRoute exact path={routes.autoDiscoveredPolicies} component={AutoDiscoveredPolicies} />
      <PrivateRoute exact path={routes.pendingpolicyDetailView} component={PendingPolicyDetail} />
      <PrivateRoute exact path={routes.createPolicy} component={CreatePolicy} />
      <PrivateRoute exact path={routes.policyDetails} component={PolicyDetails} />
      <PrivateRoute exact path={routes.policyEditor} component={PolicyEditor} />

      {/* Workspace Manager */}
      <PrivateRoute exact path={routes.channelIntegration} component={ChannelIntegration} />
      <PrivateRoute exact path={routes.integrationList} component={ChannelList} />
      <PrivateRoute exact path={routes.integrationJira} component={JiraIntegration} />
      <PrivateRoute exact path={routes.integrationRsyslog} component={RsyslogIntegration} />
      <PrivateRoute exact path={routes.integrationAWS} component={AWSIntegration} />
      <PrivateRoute exact path={routes.integrationElastic} component={ElasticSearch} />
      <PrivateRoute exact path={routes.integrationSlack} component={SlackIntegration} />
      <PrivateRoute exact path={routes.integrationSplunk} component={SplunkIntegration} />
      <PrivateRoute exact path={routes.emailIntegration} component={EmailIntegration} />
      <PrivateRoute exact path={routes.pagerDutyIntegration} component={PagerDutyIntegration} />
      <PrivateRoute exact path={routes.webhookIntegration} component={WebHookIntegration} />

      {/* edit Channels */}
      <PrivateRoute exact path={routes.editSlack} component={SlackIntegration} />
      <PrivateRoute exact path={routes.editSplunk} component={EditSplunkIntegration} />
      <PrivateRoute exact path={routes.editRsyslog} component={RsyslogIntegration} />
      <PrivateRoute exact path={routes.editAws} component={AWSIntegration} />
      <PrivateRoute exact path={routes.editElastic} component={ElasticSearch} />
      <PrivateRoute exact path={routes.editJira} component={JiraIntegration} />
      {/* Logs & Summary */}
      <PrivateRoute exact path={routes.alertsSummaryLogs} component={Logs} />
      <PrivateRoute exact path={routes.logsV2} component={Logs_V2} />
      <PrivateRoute exact path={routes.alertsSummaryTriggers} component={Triggers} />
      {/* <PrivateRoute exact path={routes.settingsMinions} component={Minions} /> */}
    </Switch>
  );
};

export default DashboardContent;
