import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import * as actions from "./actions";
import * as service from "./service";

import { addNotification } from "../notifications/actions";

import { errorHandler } from "helper/errorHandler";
import { makeRequest } from "helper/request";

// Get Audit Files
function* getAuditFilesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAuditFiles), payload);
    yield put(actions.getAuditFilesSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getAuditFilesFailed(error));
  }
}

// Get Audit File Labels
function* getAuditFileLabelsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAuditFileLabels), payload);
    yield put(actions.getAuditFileLabelsSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getAuditFileLabelsFailed(error));
  }
}

// Get Audit File Assets
function* getAuditFileAssetsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAuditFileAssets), payload);
    yield put(actions.getAuditFileAssetsSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getAuditFileAssetsFailed(error));
  }
}

// Get Audit File Assets
function* getAuditFileBaselinesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAuditFileBaselines), payload);
    yield put(actions.getAuditFileBaselinesSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getAuditFileBaselinesFailed(error));
  }
}

// Get Audit File Assets
function* getAuditFileHistoryWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getAuditFileHistory), payload);
    yield put(actions.getAuditFileHistorySuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getAuditFileHistoryFailed(error));
  }
}

// Get Audit Files
function* watchGetAuditFilesWorker() {
  yield takeEvery(actions.getAuditFiles, getAuditFilesWorker);
}

// Get Audit File Labels
function* watchGetAuditFileLabelsWorker() {
  yield takeEvery(actions.getAuditFileLabels, getAuditFileLabelsWorker);
}

// Get Audit File Assets
function* watchGetAuditFileAssetsWorker() {
  yield takeEvery(actions.getAuditFileAssets, getAuditFileAssetsWorker);
}

// Get Audit File Baselines
function* watchGetAuditFileBaselinesWorker() {
  yield takeEvery(actions.getAuditFileBaselines, getAuditFileBaselinesWorker);
}

// Get Audit File Baselines
function* watchGetAuditFileHistoryWorker() {
  yield takeEvery(actions.getAuditFileHistory, getAuditFileHistoryWorker);
}

// Set Baseline Config Params
export default function* rootSaga() {
  yield all([
    fork(watchGetAuditFilesWorker),
    fork(watchGetAuditFileLabelsWorker),
    fork(watchGetAuditFileAssetsWorker),
    fork(watchGetAuditFileBaselinesWorker),
    fork(watchGetAuditFileHistoryWorker)
  ]);
}
