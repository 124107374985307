import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

// Set Templates Params
export const setTemplatesParams = createAction(constant.SET_TEMPLATES_PARAMS);

// Get Templates
export const getTemplates = createAction(constant.GET_TEMPLATES);
export const getTemplatesSuccess = createAction(constant.GET_TEMPLATES_SUCCESS);
export const getTemplatesFailed = createAction(constant.GET_TEMPLATES_FAILED);

// Get Template
export const getTemplate = createAction(constant.GET_TEMPLATE);
export const getTemplateSuccess = createAction(constant.GET_TEMPLATE_SUCCESS);
export const getTemplateFailed = createAction(constant.GET_TEMPLATE_FAILED);

// Add Template
export const addTemplate = createAction(constant.ADD_TEMPLATE);
export const addTemplateSuccess = createAction(constant.ADD_TEMPLATE_SUCCESS);
export const addTemplateFailed = createAction(constant.ADD_TEMPLATE_FAILED);

// Edit Template
export const editTemplate = createAction(constant.EDIT_TEMPLATE);
export const editTemplateSuccess = createAction(constant.EDIT_TEMPLATE_SUCCESS);
export const editTemplateFailed = createAction(constant.EDIT_TEMPLATE_FAILED);

// Delete Template
export const deleteTemplate = createAction(constant.DELETE_TEMPLATE);
export const deleteTemplateSuccess = createAction(constant.DELETE_TEMPLATE_SUCCESS);
export const deleteTemplateFailed = createAction(constant.DELETE_TEMPLATE_FAILED);

// Duplicate Template
export const duplicateTemplate = createAction(constant.DUPLICATE_TEMPLATE);
export const duplicateTemplateSuccess = createAction(constant.DUPLICATE_TEMPLATE_SUCCESS);
export const duplicateTemplateFailed = createAction(constant.DUPLICATE_TEMPLATE_FAILED);

// Get Templates Data-types
export const getTemplatesDataTypes = createAction(constant.GET_TEMPLATES_DATA_TYPES);
export const getTemplatesDataTypesSuccess = createAction(constant.GET_TEMPLATES_DATA_TYPES_SUCCESS);
export const getTemplatesDataTypesFailed = createAction(constant.GET_TEMPLATES_DATA_TYPES_FAILED);

// Set Roles Params
export const setRolesParams = createAction(constant.SET_ROLES_PARAMS);

// Get Roles
export const getRoles = createAction(constant.GET_ROLES);
export const getRolesSuccess = createAction(constant.GET_ROLES_SUCCESS);
export const getRolesFailed = createAction(constant.GET_ROLES_FAILED);

// Get Role
export const getRole = createAction(constant.GET_ROLE);
export const getRoleSuccess = createAction(constant.GET_ROLE_SUCCESS);
export const getRoleFailed = createAction(constant.GET_ROLE_FAILED);

// Add Role
export const addRole = createAction(constant.ADD_ROLE);
export const addRoleSuccess = createAction(constant.ADD_ROLE_SUCCESS);
export const addRoleFailed = createAction(constant.ADD_ROLE_FAILED);

// Edit Role
export const editRole = createAction(constant.EDIT_ROLE);
export const editRoleSuccess = createAction(constant.EDIT_ROLE_SUCCESS);
export const editRoleFailed = createAction(constant.EDIT_ROLE_FAILED);

// Delete Role
export const deleteRole = createAction(constant.DELETE_ROLE);
export const deleteRoleSuccess = createAction(constant.DELETE_ROLE_SUCCESS);
export const deleteRoleFailed = createAction(constant.DELETE_ROLE_FAILED);

// Get Users
export const getUsers = createAction(constant.GET_USERS);
export const getUsersSuccess = createAction(constant.GET_USERS_SUCCESS);
export const getUsersFailed = createAction(constant.GET_USERS_FAILED);

// Get Permissions
export const getPermissions = createAction(constant.GET_PERMISSIONS);
export const getPermissionsSuccess = createAction(constant.GET_PERMISSIONS_SUCCESS);
export const getPermissionsFailed = createAction(constant.GET_PERMISSIONS_FAILED);

// Get S3Profile
export const getS3Profile = createAction(constant.GET_S3PROFILE);
export const getS3ProfileSuccess = createAction(constant.GET_S3PROFILE_SUCCESS);
export const getS3ProfileFailed = createAction(constant.GET_S3PROFILE_FAILED);

// Add S3Profile
export const addS3Profile = createAction(constant.ADD_S3PROFILE);
export const addS3ProfileSuccess = createAction(constant.ADD_S3PROFILE_SUCCESS);
export const addS3ProfileFailed = createAction(constant.ADD_S3PROFILE_FAILED);

export const addModalS3Profile = createAction(constant.ADD_MODAL_S3PROFILE);

// Delete S3Profile
export const deleteS3Profile = createAction(constant.DELETE_S3PROFILE);
export const deleteS3ProfileSuccess = createAction(constant.DELETE_S3PROFILE_SUCCESS);
export const deleteS3ProfileFailed = createAction(constant.DELETE_S3PROFILE_FAILED);

// Edit S3Profile
export const editS3Profile = createAction(constant.EDIT_S3PROFILE);
export const editS3ProfileSuccess = createAction(constant.EDIT_S3PROFILE_SUCCESS);
export const editS3ProfileFailed = createAction(constant.EDIT_S3PROFILE_FAILED);

// Add Connector
export const addModalConnector = createAction(constant.ADD_MODAL_CONNECTOR);

// Jira Server
export const getJiraServer = createAction(constant.GET_JIRASERVER);
export const getJiraServerSuccess = createAction(constant.GET_JIRASERVER_SUCCESS);
export const getJiraServerFailed = createAction(constant.GET_JIRASERVER_FAILED);

export const getOneJiraServer = createAction(constant.GET_ONE_JIRASERVER);
export const getOneJiraServerSuccess = createAction(constant.GET_ONE_JIRASERVER_SUCCESS);
export const getOneJiraServerFailed = createAction(constant.GET_ONE_JIRASERVER_FAILED);

export const addJiraServer = createAction(constant.ADD_JIRASERVER);
export const addJiraServerSuccess = createAction(constant.ADD_JIRASERVER_SUCCESS);
export const addJiraServerFailed = createAction(constant.ADD_JIRASERVER_FAILED);

export const editJiraServer = createAction(constant.EDIT_JIRASERVER);
export const editJiraServerSuccess = createAction(constant.EDIT_JIRASERVER_SUCCESS);
export const editJiraServerFailed = createAction(constant.EDIT_JIRASERVER_FAILED);

export const deleteJiraServer = createAction(constant.DELETE_JIRASERVER);
export const deleteJiraServerSuccess = createAction(constant.DELETE_JIRASERVER_SUCCESS);
export const deleteJiraServerFailed = createAction(constant.DELETE_JIRASERVER_FAILED);

// // Jira Cloud
export const getJiraCloud = createAction(constant.GET_JIRACLOUD);
export const getJiraCloudSuccess = createAction(constant.GET_JIRACLOUD_SUCCESS);
export const getJiraCloudFailed = createAction(constant.GET_JIRACLOUD_FAILED);

export const getOneJiraCloud = createAction(constant.GET_ONE_JIRACLOUD);
export const getOneJiraCloudSuccess = createAction(constant.GET_ONE_JIRACLOUD_SUCCESS);
export const getOneJiraCloudFailed = createAction(constant.GET_ONE_JIRACLOUD_FAILED);

export const addJiraCloud = createAction(constant.ADD_JIRACLOUD);
export const addJiraCloudSuccess = createAction(constant.ADD_JIRACLOUD_SUCCESS);
export const addJiraCloudFailed = createAction(constant.ADD_JIRACLOUD_FAILED);

export const editJiraCloud = createAction(constant.EDIT_JIRACLOUD);
export const editJiraCloudSuccess = createAction(constant.EDIT_JIRACLOUD_SUCCESS);
export const editJiraCloudFailed = createAction(constant.EDIT_JIRACLOUD_FAILED);

export const deleteJiraCloud = createAction(constant.DELETE_JIRACLOUD);
export const deleteJiraCloudSuccess = createAction(constant.DELETE_JIRACLOUD_SUCCESS);
export const deleteJiraCloudFailed = createAction(constant.DELETE_JIRACLOUD_FAILED);

// // Freshservice
export const getFreshservice = createAction(constant.GET_FRESHSERVICE);
export const getFreshserviceSuccess = createAction(constant.GET_FRESHSERVICE_SUCCESS);
export const getFreshserviceFailed = createAction(constant.GET_FRESHSERVICE_FAILED);

export const getOneFreshservice = createAction(constant.GET_ONE_FRESHSERVICE);
export const getOneFreshserviceSuccess = createAction(constant.GET_ONE_FRESHSERVICE_SUCCESS);
export const getOneFreshserviceFailed = createAction(constant.GET_ONE_FRESHSERVICE_FAILED);

export const addFreshservice = createAction(constant.ADD_FRESHSERVICE);
export const addFreshserviceSuccess = createAction(constant.ADD_FRESHSERVICE_SUCCESS);
export const addFreshserviceFailed = createAction(constant.ADD_FRESHSERVICE_FAILED);

export const editFreshservice = createAction(constant.EDIT_FRESHSERVICE);
export const editFreshserviceSuccess = createAction(constant.EDIT_FRESHSERVICE_SUCCESS);
export const editFreshserviceFailed = createAction(constant.EDIT_FRESHSERVICE_FAILED);

export const deleteFreshservice = createAction(constant.DELETE_FRESHSERVICE);
export const deleteFreshserviceSuccess = createAction(constant.DELETE_FRESHSERVICE_SUCCESS);
export const deleteFreshserviceFailed = createAction(constant.DELETE_FRESHSERVICE_FAILED);

// // Connect Wise
export const getConnectWise = createAction(constant.GET_CONNECTWISE);
export const getConnectWiseSuccess = createAction(constant.GET_CONNECTWISE_SUCCESS);
export const getConnectWiseFailed = createAction(constant.GET_CONNECTWISE_FAILED);

export const getOneConnectWise = createAction(constant.GET_ONE_CONNECTWISE);
export const getOneConnectWiseSuccess = createAction(constant.GET_ONE_CONNECTWISE_SUCCESS);
export const getOneConnectWiseFailed = createAction(constant.GET_ONE_CONNECTWISE_FAILED);

export const addConnectWise = createAction(constant.ADD_CONNECTWISE);
export const addConnectWiseSuccess = createAction(constant.ADD_CONNECTWISE_SUCCESS);
export const addConnectWiseFailed = createAction(constant.ADD_CONNECTWISE_FAILED);

export const editConnectWise = createAction(constant.EDIT_CONNECTWISE);
export const editConnectWiseSuccess = createAction(constant.EDIT_CONNECTWISE_SUCCESS);
export const editConnectWiseFailed = createAction(constant.EDIT_CONNECTWISE_FAILED);

export const deleteConnectWise = createAction(constant.DELETE_CONNECTWISE);
export const deleteConnectWiseSuccess = createAction(constant.DELETE_CONNECTWISE_SUCCESS);
export const deleteConnectWiseFailed = createAction(constant.DELETE_CONNECTWISE_FAILED);

// // Email Backends
// Get Email-profile
export const getEmailProfile = createAction(constant.GET_EMAIL_PROFILE);
export const getEmailProfileSuccess = createAction(constant.GET_EMAIL_PROFILE_SUCCESS);
export const getEmailProfileFailed = createAction(constant.GET_EMAIL_PROFILE_FAILED);

// Add Email-profile
export const addEmailProfile = createAction(constant.ADD_EMAIL_PROFILE);
export const addEmailProfileSuccess = createAction(constant.ADD_EMAIL_PROFILE_SUCCESS);
export const addEmailProfileFailed = createAction(constant.ADD_EMAIL_PROFILE_FAILED);

// Edit Email-profile
export const editEmailProfile = createAction(constant.EDIT_EMAIL_PROFILE);
export const editEmailProfileSuccess = createAction(constant.EDIT_EMAIL_PROFILE_SUCCESS);
export const editEmailProfileFailed = createAction(constant.EDIT_EMAIL_PROFILE_FAILED);

// Delete Email-profile
export const deleteEmailProfile = createAction(constant.DELETE_EMAIL_PROFILE);
export const deleteEmailProfileSuccess = createAction(constant.DELETE_EMAIL_PROFILE_SUCCESS);
export const deleteEmailProfileFailed = createAction(constant.DELETE_EMAIL_PROFILE_FAILED);

// User Profiles
export const getUserProfiles = createAction(constant.GET_USERPROFILES);
export const getUserProfilesSuccess = createAction(constant.GET_USERPROFILES_SUCCESS);
export const getUserProfilesFailed = createAction(constant.GET_USERPROFILES_FAILED);

export const setUserProfilesParams = createAction(constant.SET_USERPROFILES_PARAMS);

export const getOneUserProfile = createAction(constant.GET_ONE_USERPROFILE);
export const getOneUserProfileSuccess = createAction(constant.GET_ONE_USERPROFILE_SUCCESS);
export const getOneUserProfileFailed = createAction(constant.GET_ONE_USERPROFILE_FAILED);

export const addUserProfiles = createAction(constant.ADD_USERPROFILES);
export const addUserProfilesSuccess = createAction(constant.ADD_USERPROFILES_SUCCESS);
export const addUserProfilesFailed = createAction(constant.ADD_USERPROFILES_FAILED);

export const editUserProfiles = createAction(constant.EDIT_USERPROFILES);
export const editUserProfilesSuccess = createAction(constant.EDIT_USERPROFILES_SUCCESS);
export const editUserProfilesFailed = createAction(constant.EDIT_USERPROFILES_FAILED);

export const deleteUserProfiles = createAction(constant.DELETE_USERPROFILES);
export const deleteUserProfilesSuccess = createAction(constant.DELETE_USERPROFILES_SUCCESS);
export const deleteUserProfilesFailed = createAction(constant.DELETE_USERPROFILES_FAILED);

export const getUserProfilesGroup = createAction(constant.GET_USERPROFILES_GROUP);
export const getUserProfilesGroupSuccess = createAction(constant.GET_USERPROFILES_GROUP_SUCCESS);
export const getUserProfilesGroupFailed = createAction(constant.GET_USERPROFILES_GROUP_FAILED);

// Labels
export const setLabelsParams = createAction(constant.SET_LABELS_PARAMS);

export const getSettingsLabels = createAction(constant.GET_SETTINGS_LABELS);
export const getSettingsLabelsSuccess = createAction(constant.GET_SETTINGS_LABELS_SUCCESS);
export const getSettingsLabelsFailed = createAction(constant.GET_SETTINGS_LABELS_FAILED);

export const getLabel = createAction(constant.GET_LABEL);
export const getLabelSuccess = createAction(constant.GET_LABEL_SUCCESS);
export const getLabelFailed = createAction(constant.GET_LABEL_FAILED);

export const addLabel = createAction(constant.ADD_LABEL);
export const addLabelSuccess = createAction(constant.ADD_LABEL_SUCCESS);
export const addLabelFailed = createAction(constant.ADD_LABEL_FAILED);

export const editLabel = createAction(constant.EDIT_LABEL);
export const editLabelSuccess = createAction(constant.EDIT_LABEL_SUCCESS);
export const editLabelFailed = createAction(constant.EDIT_LABEL_FAILED);

export const deleteSettingsLabel = createAction(constant.DELETE_SETTINGS_LABEL);
export const deleteSettingsLabelSuccess = createAction(constant.DELETE_SETTINGS_LABEL_SUCCESS);
export const deleteSettingsLabelFailed = createAction(constant.DELETE_SETTINGS_LABEL_FAILED);

// tickets-config
export const getTicketsConfig = createAction(constant.GET_TICKETS_CONFIG);
export const getTicketsConfigSuccess = createAction(constant.GET_TICKETS_CONFIG_SUCCESS);
export const getTicketsConfigFailed = createAction(constant.GET_TICKETS_CONFIG_FAILED);
export const showTicketsConfigModal = createAction(constant.SHOW_TICKETS_CONFIG_MODAL);

export const getTicketConfig = createAction(constant.GET_TICKET_CONFIG);
export const getTicketConfigSuccess = createAction(constant.GET_TICKET_CONFIG_SUCCESS);
export const getTicketConfigFailed = createAction(constant.GET_TICKET_CONFIG_FAILED);

export const addTicketConfig = createAction(constant.ADD_TICKET_CONFIG);
export const addTicketConfigSuccess = createAction(constant.ADD_TICKET_CONFIG_SUCCESS);
export const addTicketConfigFailed = createAction(constant.ADD_TICKET_CONFIG_FAILED);

export const editTicketConfig = createAction(constant.EDIT_TICKET_CONFIG);
export const editTicketConfigSuccess = createAction(constant.EDIT_TICKET_CONFIG_SUCCESS);
export const editTicketConfigFailed = createAction(constant.EDIT_TICKET_CONFIG_FAILED);

export const deleteTicketConfig = createAction(constant.DELETE_TICKET_CONFIG);
export const deleteTicketConfigSuccess = createAction(constant.DELETE_TICKET_CONFIG_SUCCESS);
export const deleteTicketConfigFailed = createAction(constant.DELETE_TICKET_CONFIG_FAILED);

export const getJiraAll = createAction(constant.GET_JIRA_ALL);
export const getJiraAllSuccess = createAction(constant.GET_JIRA_ALL_SUCCESS);
export const getJiraAllFailed = createAction(constant.GET_JIRA_ALL_FAILED);

export const getFreshserviceConfig = createAction(constant.GET_FRESHSERVICE_CONFIG);
export const getFreshserviceConfigSuccess = createAction(constant.GET_FRESHSERVICE_CONFIG_SUCCESS);
export const getFreshserviceConfigFailed = createAction(constant.GET_FRESHSERVICE_CONFIG_FAILED);

export const getConnectWiseConfig = createAction(constant.GET_CONNECTWISE_CONFIG);
export const getConnectWiseConfigSuccess = createAction(constant.GET_CONNECTWISE_CONFIG_SUCCESS);
export const getConnectWiseConfigFailed = createAction(constant.GET_CONNECTWISE_CONFIG_FAILED);

export const getTemplateListMini = createAction(constant.GET_TEMPLATE_LIST_MINI);
export const getTemplateListMiniSuccess = createAction(constant.GET_TEMPLATE_LIST_MINI_SUCCESS);
export const getTemplateListMiniFailed = createAction(constant.GET_TEMPLATE_LIST_MINI_FAILED);

export const getStatusForTicketConfig = createAction(constant.GET_STATUS_FOR_TICKET_CONFIG);
export const getStatusForTicketConfigSuccess = createAction(
  constant.GET_STATUS_FOR_TICKET_CONFIG_SUCCESS
);
export const getStatusForTicketConfigFailed = createAction(
  constant.GET_STATUS_FOR_TICKET_CONFIG_FAILED
);

export const permissionsModal = createAction(constant.PERMISSIONS_MODAL);
