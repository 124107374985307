import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { addNotification } from "../notifications/actions";
import { getTagsMini } from "../tags/actions";
import { errorHandler } from "helper/errorHandler";

const getHistoryParams = state => ({
  page: state.importHistory.params.page,
  pageSize: state.importHistory.params.pageSize,
  search: state.importHistory.params.search,
  ordering: state.importHistory.params.ordering,
  filters: state.importHistory.params.filters
});

// Watchers //

// Get ImportHistory
function* getImportHistoryWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getImportHistory), payload);
    yield put(actions.getImportHistorySuccess(response));
  } catch (error) {
    yield put(actions.getImportHistoryFailed(error));
  }
}
// Get ImportHistory
function* watchGetImportHistoryWorker() {
  yield takeEvery(actions.getImportHistory, getImportHistoryWorker);
}

function* setImportHistoryParamsWorker() {
  const { page, pageSize, search, ordering, filters } = yield select(getHistoryParams);
  yield put(
    actions.getImportHistory({
      page,
      pageSize,
      search,
      ordering,
      filters
    })
  );
}
function* watchSetImportHistoryParamsWorker() {
  yield takeEvery(actions.setImportHistoryParams, setImportHistoryParamsWorker);
}

// Delete History
function* deleteImportHistoryWorker({ payload }) {
  try {
    const { ids } = payload;
    const response = yield all(ids.map(id => call(makeRequest(service.deleteImportHistory), id)));
    yield put(actions.deleteImportHistorySuccess(response));
    yield put(actions.setImportHistoryParams());
    yield put(addNotification({ msg: `History deleted successfully` }));
  } catch (error) {
    yield put(actions.deleteImportHistoryFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete History
function* watchDeleteImportHistoryWorker() {
  yield takeEvery(actions.deleteImportHistory, deleteImportHistoryWorker);
}

// Add Tag
function* addTagWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addTag), payload);
    yield put(actions.addTagSuccess(response));
    yield put(actions.setImportHistoryParams());
    yield put(addNotification({ msg: `Tag added successfully` }));
    yield put(getTagsMini());
  } catch (error) {
    yield put(actions.addTagFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Add Tag
function* watchAddTagWorker() {
  yield takeEvery(actions.addTag, addTagWorker);
}

// Get Data Types
function* getDataTypesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getDataTypes), payload);
    yield put(actions.getDataTypesSuccess(response));
  } catch (error) {
    yield put(actions.getDataTypesFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Data Types
function* watchGetDataTypesgWorker() {
  yield takeEvery(actions.getDataTypes, getDataTypesWorker);
}

// Run Report
function* runReportWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.runReport), payload);
    yield put(actions.runReportSuccess(response));
    yield put(
      addNotification({
        msg: response
      })
    );
  } catch (error) {
    yield put(actions.runReportFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// Run Report
function* watchRunReportWorker() {
  yield takeEvery(actions.runReport, runReportWorker);
}

// Watchers End//

export default function* rootSaga() {
  yield all([
    fork(watchGetImportHistoryWorker),
    fork(watchSetImportHistoryParamsWorker),
    fork(watchDeleteImportHistoryWorker),
    fork(watchAddTagWorker),
    fork(watchGetDataTypesgWorker),
    fork(watchRunReportWorker)
  ]);
}
