import React from "react";
import PropTypes from "prop-types";

export default function IgnoreFinding(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.25V11"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.08 15.42V8.58C21.08 7.46 20.4799 6.41996 19.5099 5.84996L13.5699 2.41997C12.5999 1.85997 11.3999 1.85997 10.4199 2.41997L4.47992 5.84996C3.50992 6.40996 2.90991 7.45 2.90991 8.58V15.42C2.90991 16.54 3.50992 17.58 4.47992 18.15L10.4199 21.58C11.3899 22.14 12.5899 22.14 13.5699 21.58L19.5099 18.15C20.4799 17.58 21.08 16.55 21.08 15.42Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.7998V7.69981"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

IgnoreFinding.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

IgnoreFinding.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 24,
  height: 24
};
