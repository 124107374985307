/*eslint-disable */

import React, { useState, useEffect } from "react";
import CustomAccordion from "components/simple/Accordion";
import Loader from "components/simple/Loader";
import { Typography } from "@mui/material";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import EditQueryModal from "../EditQueryModal";

const LogFields = ({ preDefinedFilterData, savedFilterdata, updateLocalDSL, filters, fcomp }) => {
  const [expand, setExpand] = useState();
  const [filterName, setFilterName] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [fillId, setFillID] = useState();
  const [fillname, setFillname] = useState();
  const [fillquery, setfillquery] = useState();
  const handleChange = panel => e => {
    e?.stopPropagation();
    setExpand(!expand);
  };
  const handlepredefinedfilter = e => {
    const sname = e?.Name;
    const foundVal = preDefinedFilterData?.apiResponse?.filter(name => name?.Name === sname)[0];
    const string = foundVal?.Filter_string;
    const query = foundVal?.Filter_query;
    let filterArr = [];
    if (filterArr === undefined) {
      filterArr = [];
    }
    filterArr.push({ text: query, string: string, type: "PREDEFIND" });
    filterArr = _.uniq(filterArr);
    updateLocalDSL(filterArr);
  };
  useEffect(() => {
    const save = savedFilterdata?.response?.Filters?.map(item => ({
      name: item?.Name,
      id: item?.ID,
      query: item?.FilterQuery
    }));
    setFilterName(save);
  }, [savedFilterdata]);
  const handlesavefilter = async e => {
    const sname = e?.name;
    const query = filterName?.filter(name => name.name === sname)[0].query;
    let filterArr = [];
    if (filterArr === undefined) {
      filterArr = [];
    }
    const splitArr = query?.split(",");
    splitArr?.map(val => {
      const arr = val?.split(":");
      const key = arr[0];
      filterArr.push({ key: key, text: val, type: "SAVED" });
    });
    filterArr = _.uniq(filterArr);
    updateLocalDSL(filterArr);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <LogContent className="border">
      <EditQueryModal
        isModalOpen={isOpen}
        onCloseModal={() => handleClose()}
        fname={fillname}
        filter_id={fillId}
        fquery={fillquery}
        filters={filters}
        fcomp={fcomp}
      />
      {/* <CustomAccordion
        Accordionkey="predefined"
        Summary="Predefined"
        Details={
          <>
            {preDefinedFilterData?.apiResponse?.length < 0 ? (
              <Loader className="mt-6 mb-8" />
            ) : preDefinedFilterData?.apiResponse?.length === 0 ? (
              <div className="tbody">
                <Typography>No data to show</Typography>
              </div>
            ) : (
              <div className="predefined-filter">
                {preDefinedFilterData?.apiResponse?.map((item, index) => (
                  <div className="grid-class" key={index}>
                    <p
                      onClick={() => {
                        handlepredefinedfilter(item);
                      }}
                    >
                      {item.Name}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </>
        }
        defaultExpanded={preDefinedFilterData?.apiResponse?.length > 0 ? true : false}
        expand={expand}
        onChange={e => handleChange(e)}
        summaryBorder="1px solid #DBDFF0"
        iconSize="1rem"
        summaryFont="14px"
        fontDetails="14px"
      /> */}
      <CustomAccordion
        Accordionkey="saved"
        Summary="Saved"
        Details={
          <>
            {savedFilterdata?.response?.Filters?.length < 0 ? (
              <Loader className="mt-6 mb-8" />
            ) : filterName?.length < 1 ? (
              <div className="tbody">
                <Typography>No data to show</Typography>
              </div>
            ) : (
              <div className="saved-filter">
                {filterName?.map((item, index) => (
                  <div className="grid-class" key={index}>
                    <p
                      onClick={() => {
                        handlesavefilter(item);
                      }}
                    >
                      {item?.name}
                    </p>
                    <EditIcon
                      fontSize="17"
                      color="#001EBA"
                      className="mt-4 mr-2 cursor-pointer"
                      onClick={() => {
                        setIsOpen(true);
                        setfillquery(item?.query);
                        setFillname(item?.name);
                        setFillID(item?.id);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        }
        defaultExpanded={savedFilterdata?.response?.Filters?.length > 0 ? true : false}
        expand={expand}
        onChange={e => handleChange(e)}
        summaryBorder="1px solid #DBDFF0"
        iconSize="1rem"
        summaryFont="14px"
        fontDetails="14px"
      />
    </LogContent>
  );
};
export default LogFields;

export const LogContent = styled.div`
  .grid-class {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    justify-content: space-between;
  }
  .grid-class p {
    font-size: 13px;
    color: #001eba;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
  .tbody {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .saved-filter {
    overflow-y: auto;
    height: 250px;
  }
  .saved-filter::-webkit-scrollbar {
    width: 6px;
    background: #fff;
  }
  .saved-filter::-webkit-scrollbar-track {
    background: #ffffff !important;
    border-radius: 5px;
  }
  .saved-filter::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #ffffff80;
  }
  .predefined-filter {
    overflow-y: auto;
    height: 210px;
  }
  .predefined-filter::-webkit-scrollbar {
    width: 6px;
    background: #fff;
  }
  .predefined-filter::-webkit-scrollbar-track {
    background: #ffffff !important;
    border-radius: 5px;
  }
  .predefined-filter::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #ffffff80;
  }
  .css-390ccc-MuiAccordionDetails-root {
    padding: 8px 10px 16px !important;
    border: 0px;
    font-size: 14px;
  }
`;
