/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  editReport,
  createReport,
  getReport,
  deleteReport,
  getReportPages,
  getReportPeriods
} from "store/entities/reports/actions";
import { getVulnerabilityRiskFactors } from "store/entities/vulnerabilities/actions";
import { getLabelList } from "store/entities/labels/actions";
import { addNotification } from "store/entities/notifications/actions";

import { getReportView } from "store/entities/reports/service";
import { getBaselines } from "store/entities/baseline/service";

import { getGroupsMini } from "store/entities/groups/actions";
import { groupsMiniListSelector } from "store/entities/groups/selectors";

import {
  monthsOptionsSelector,
  daysOfWeekOptionsSelector,
  daysOfMonthOptionsSelector,
  reportPagesOptionsSelector,
  reportPeriodsOptionsSelector,
  reportSelector
} from "store/entities/reports/selectors";
import { getSeverityOptionsSelector } from "store/entities/vulnerabilities/selectors";
import { getLabelListSelector } from "store/entities/labels/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Button, Input, Select, Tags, SelectAsync } from "components/simple";

import styles from "./styles.module.scss";

const Configuration = ({ id }) => {
  const initialState = {
    name: "",
    email: "",
    month: "",
    labels: [],
    period: "",
    risks: [],
    baselines: [],
    day_of_the_month: [],
    day_of_week: [],
    pages: [],
    groups: []
  };
  const [state, setState] = useState({ ...initialState });
  const selectTenant = useSelector(s => s.users.selectTenant);

  const severityOptions = useSelector(getSeverityOptionsSelector); // risks
  const labelsOptions = useSelector(getLabelListSelector);
  const monthsOptions = useSelector(monthsOptionsSelector);
  const daysOfWeekOptions = useSelector(daysOfWeekOptionsSelector);
  const daysOfMonthOptions = useSelector(daysOfMonthOptionsSelector);
  const reportPagesOptions = useSelector(reportPagesOptionsSelector);
  const reportPeriodsOptions = useSelector(reportPeriodsOptionsSelector);

  const groupList = useSelector(groupsMiniListSelector);

  const report = useSelector(reportSelector);

  const isSaveLoading = useSelector(
    s =>
      createLoadingSelector([editReport.type])(s) || createLoadingSelector([createReport.type])(s)
  );
  const isDeleteLoading = useSelector(s => createLoadingSelector([deleteReport.type])(s));
  const [isViewLoading, setViewLoading] = useState(false);

  const dispatch = useDispatch();

  const inputChangeHandler = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(getReport(id));
      return;
    }
    setState({
      ...initialState
    });
  }, [id, selectTenant]);

  useEffect(() => {
    dispatch(getLabelList());
    dispatch(getVulnerabilityRiskFactors());
    dispatch(getReportPages());
    dispatch(getReportPeriods());
    dispatch(getGroupsMini({ pageSize: 2000 }));
  }, [selectTenant]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(report).length !== 0 && id) {
      setState({
        ...report
      });
    }
  }, [report]);

  const uploadReportViewHandler = async data => {
    setViewLoading(true);
    const pdf = await getReportView({
      id,
      data
    })
      .then(res => {
        setViewLoading(false);
        return res.blob();
      })
      .catch(() => {
        setViewLoading(false);
        dispatch(addNotification({ msg: "Download failed", type: "error" }));
      });
    const file = new Blob([pdf], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const clearCallback = () => {
    setState({
      ...initialState
    });
  };

  const getArrayIds = optionsList => optionsList.map(elem => `${elem.value}`);

  const saveHandler = e => {
    e.preventDefault();
    const action = e.nativeEvent.submitter.name;
    const {
      name,
      email,
      month,
      labels,
      period,
      risks,
      baselines,
      day_of_the_month,
      day_of_week,
      pages,
      groups
    } = state;
    const baselinesIds = getArrayIds(baselines);
    const risksIds = getArrayIds(risks);
    const daysOfTheMonthIds = getArrayIds(day_of_the_month);
    const daysOfTheWeekIds = getArrayIds(day_of_week);
    const pagesIds = getArrayIds(pages);
    const data = {
      name,
      email,
      period,
      month: month?.value,
      labels: labels?.map(item => item?.value),
      baselines: baselinesIds,
      risks: risksIds,
      day_of_the_month: daysOfTheMonthIds,
      day_of_week: daysOfTheWeekIds,
      pages: pagesIds,
      groups: groups?.map(item => item?.value)
    };
    if (action === "preview") {
      uploadReportViewHandler(data);
      return;
    }

    if (id) {
      dispatch(
        editReport({
          id,
          data,
          action,
          callback: clearCallback
        })
      );
      return;
    }
    dispatch(
      createReport({
        data,
        action,
        callback: clearCallback
      })
    );
  };

  const deleteHandler = () => {
    dispatch(deleteReport({ ids: [id], redirect: true }));
  };

  const requst = async (search, page) => {
    try {
      const res = await getBaselines({ search, page, pageSize: 20 }).then(r => r.json());
      const listOptions = res?.results?.map(item => ({ value: item?.id, label: item?.name }));
      return { options: listOptions, next: res?.next };
    } catch (e) {
      // return console.log("err", e);
    }
  };

  const loadOptions = async (search, prevOptions, { page }) => {
    const { options, next } = await requst(search, page);
    const hasMore = !search ? Boolean(next) : false;

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    };
  };

  return (
    <ContentContainer className={styles.container}>
      <form onSubmit={saveHandler} className={styles.form}>
        <div className={styles.row}>
          <Input
            label="Name"
            value={state.name}
            onChange={e => inputChangeHandler(e.target.value, "name")}
            containerClassName={styles.input}
            required
          />
          <Input
            label="Email"
            type="email"
            value={state.email}
            onChange={e => inputChangeHandler(e.target.value, "email")}
            containerClassName={styles.input}
            required
          />
          <Select
            value={state.groups}
            onChange={e => inputChangeHandler(e, "groups")}
            label="Groups"
            options={groupList}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable
            allowSelectAll
          />
        </div>

        <div className={styles.row}>
          <Select
            label="Month"
            onChange={v => inputChangeHandler(v, "month")}
            value={state.month}
            options={monthsOptions}
            containerClass={styles.select}
          />
          <Select
            label="Label"
            onChange={v => inputChangeHandler(v, "labels")}
            value={state.labels}
            options={labelsOptions}
            containerClass={styles.select}
            isMulti
          />
          <Select
            label="Period"
            onChange={v => inputChangeHandler(v?.value, "period")}
            value={reportPeriodsOptions?.find(o => o.value === state.period)}
            options={reportPeriodsOptions}
            tooltip="Number of days"
            required
            containerClass={styles.select}
          />
        </div>

        <div className={styles.row}>
          <Tags
            label="Risks"
            values={state.risks}
            list={severityOptions}
            onChange={val => inputChangeHandler(val, "risks")}
          />
          <SelectAsync
            containerClass={styles.selectPanel}
            onChange={val => inputChangeHandler(val, "baselines")}
            value={state.baselines}
            label="Baselines"
            isClearable
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            onInputChange={() => null}
            additional={{
              page: 0
            }}
          />
          <Tags
            label="Pages"
            values={state.pages}
            list={reportPagesOptions}
            onChange={val => inputChangeHandler(val, "pages")}
          />
        </div>

        <div className={styles.row}>
          <Tags
            label="Day of the month"
            values={state.day_of_the_month}
            list={daysOfMonthOptions}
            onChange={val => inputChangeHandler(val, "day_of_the_month")}
          />
          <Tags
            label="Day of the week"
            values={state.day_of_week}
            list={daysOfWeekOptions}
            onChange={val => inputChangeHandler(val, "day_of_week")}
          />
        </div>

        <div className={styles.btnsContainer}>
          <Button type="submit" className={styles.btn} isLoading={isSaveLoading}>
            Save
          </Button>
          <Button
            type="submit"
            name="clear"
            variant="outline"
            className={styles.btn}
            disabled={isSaveLoading}
          >
            Save and add another
          </Button>
          <Button
            type="submit"
            name="continue"
            variant="outline"
            className={styles.btn}
            disabled={isSaveLoading}
          >
            Save and continue editing
          </Button>
          {id && (
            <Button
              type="submit"
              name="preview"
              variant="outline"
              className={styles.btn}
              disabled={isSaveLoading || isViewLoading}
              isLoading={isViewLoading}
            >
              Preview
            </Button>
          )}
          <Button
            variant="outline"
            onClick={deleteHandler}
            className={styles.btn}
            isLoading={isDeleteLoading}
            disabled={!id}
          >
            Delete
          </Button>
        </div>
      </form>
    </ContentContainer>
  );
};

Configuration.propTypes = {};

export default Configuration;
