import React, { useState } from "react";
import { Title, Input, Button, ContentContainer, Select } from "components/simple";
import styles from "./styles.module.scss";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Controller, useForm } from "react-hook-form";
import ErrorText from "../ErrorText";
import { Switch } from "@mui/material";
import { useHistory } from "react-router-dom";

function EmailIntegration() {
  const history = useHistory();
  const [integrationName, setIntegrationName] = useState("");
  const [toggler, setToggler] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control,
    setValue,
    getValues
  } = useForm({ mode: "onChange" });
  const onSubmit = data => {};
  return (
    <ContentContainer className={styles.container}>
      <div className="flex justify-between py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex gap-1">
          {/* <img src={SlackIcon} alt="icon" /> */}
          <Title>Email</Title>
        </div>
        {/* <div>
          Help <HelpOutlineIcon className={styles.help_icon} />
        </div> */}
      </div>
      <div className="py-5">
        <div className="flex flex-row  gap-5 w-full">
          <div className="flex flex-col w-2/5">
            <div className="mb-1 font-medium"></div>
            <Controller
              name="integrationName"
              {...register("integrationName", {
                required: "Fields Cannot be empty",
                maxLength: {
                  value: 20,
                  message: "characters must not exceed 20 characters "
                }
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, ref }) => (
                <Input
                  label="Integration Name"
                  name="integrationName"
                  placeholder="Enter a name for integration"
                  inputRef={ref}
                  value={value}
                  autoFocus
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              {errors.integrationName && <ErrorText errorMsg={errors.integrationName.message} />}
            </div>
          </div>
          <div className=" w-1/3">
            <div className="mb-1 font-medium">Credential</div>
            <Select
              name="credential"
              placeholder="Select the Credential"
              isSearchable={false}
              //   options={[{ label: "Splunk_enterprise", value: "1" }]}
              //   onChange={(e)=>}
            />
          </div>
        </div>
        <div className="flex flex-row gap-5 w-full">
          <div className="flex flex-col py-2 w-1/3">
            <div className="mb-1 font-medium">SMTP Address</div>
            <Controller
              name="address"
              {...register("address", {
                required: "SMTPAddress error"
              })}
              control={control}
              defaultValue=" "
              render={({ field: { onChange, value }, ref }) => (
                <Input
                  name="address"
                  placeholder="Enter the address"
                  value={value}
                  inputRef={ref}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              {errors.address && <ErrorText errorMsg={errors.address.message} />}
            </div>
          </div>
          <div className="flex flex-col py-2 w-1/3">
            <div className="mb-1 font-medium">Port</div>
            <Controller
              name="port"
              {...register("port", {
                required: "port error"
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, ref }) => (
                <Input
                  name="port"
                  placeholder="Enter the port"
                  value={value}
                  inputRef={ref}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              {errors.port && <ErrorText errorMsg={errors.port.message} />}
            </div>
          </div>
          <div className=" w-1/3 py-2">
            <div className="mb-1 font-medium">Credential</div>
            <Select
              name="credential"
              placeholder="Select the Credential"
              isSearchable={false}
              //   options={[{ label: "Splunk_enterprise", value: "1" }]}
              //   onChange={(e)=>}
            />
          </div>
        </div>
        <div className="flex flex-row gap-5 w-full">
          <div className="flex flex-col w-2/5">
            <div className="mb-1 font-medium">From</div>
            <Controller
              name="emailaddress"
              {...register("emailaddress", {
                required: "Invalid Email Address, Please add a valid Email.",
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z0-9]{2,4}|museum)$/,
                  message: "Entered value does not match email format"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value }, ref }) => (
                <Input
                  name="emailaddress"
                  placeholder={`Email address used as “From"`}
                  inputRef={ref}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                />
              )}
            />
            <div className="m-0.5">
              {errors.emailaddress && <ErrorText errorMsg={errors.emailaddress.message} />}
            </div>
          </div>
          <div className="w-3/5">
            <div className="mb-1 font-medium">Recipients</div>
            <Controller
              name="recipients"
              {...register("reciepients", {
                required: "Reciepients error",
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z0-9]{2,4}|museum)$/,
                  message: "Entered value does not match email format"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange, value }, ref }) => (
                <Input
                  name="reciepients"
                  placeholder="Recipients Email address"
                  inputRef={ref}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                />
              )}
            />
            <div className="m-0.5">
              {errors.reciepients && <ErrorText errorMsg={errors.reciepients.message} />}
            </div>
          </div>
        </div>
        <div className="pb-4 pt-4">
          <div className="flex w-1/5 gap-7 items-center">
            <div className="font-medium">SSL</div>
            <div className="flex gap-2 items-center">
              <span className="text-gray-400 font-medium text-sm">No</span>
              <Switch
                checked={toggler}
                onChange={setToggler(!toggler)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span className="text-gray-400 font-medium text-sm">Yes</span>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row justify-between pt-6 pb-4">
          <Button
          // onClick={handletest}
          // disabled={isButtonDisAble}
          >
            Test
          </Button>
          <div className="flex">
            <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!isValid}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </ContentContainer>
  );
}
export default EmailIntegration;
