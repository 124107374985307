// GET AUDIT FILES
export const GET_AUDIT_FILES = "GET_AUDIT_FILES_REQUEST";
export const GET_AUDIT_FILES_SUCCESS = "GET_AUDIT_FILES_SUCCESS";
export const GET_AUDIT_FILES_FAILED = "GET_AUDIT_FILES_FAILED";

// GET AUDIT FILE LABELS
export const GET_AUDIT_FILE_LABELS = "GET_AUDIT_FILE_LABELS_REQUEST";
export const GET_AUDIT_FILE_LABELS_SUCCESS = "GET_AUDIT_FILE_LABELS_SUCCESS";
export const GET_AUDIT_FILE_LABELS_FAILED = "GET_AUDIT_FILE_LABELS_FAILED";

// GET AUDIT FILE ASSETS
export const GET_AUDIT_FILE_ASSETS = "GET_AUDIT_FILE_ASSETS_REQUEST";
export const GET_AUDIT_FILE_ASSETS_SUCCESS = "GET_AUDIT_FILE_ASSETS_SUCCESS";
export const GET_AUDIT_FILE_ASSETS_FAILED = "GET_AUDIT_FILE_ASSETS_FAILED";

// GET AUDIT FILE BASELINES
export const GET_AUDIT_FILE_BASELINES = "GET_AUDIT_FILE_BASELINES_REQUEST";
export const GET_AUDIT_FILE_BASELINES_SUCCESS = "GET_AUDIT_FILE_BASELINES_SUCCESS";
export const GET_AUDIT_FILE_BASELINES_FAILED = "GET_AUDIT_FILE_BASELINES_FAILED";

// GET AUDIT FILE BASELINES
export const GET_AUDIT_FILE_HISTORY = "GET_AUDIT_FILE_HISTORY_REQUEST";
export const GET_AUDIT_FILE_HISTORY_SUCCESS = "GET_AUDIT_FILE_HISTORY_SUCCESS";
export const GET_AUDIT_FILE_HISTORY_FAILED = "GET_AUDIT_FILE_HISTORY_FAILED";

// SET AUDIT FILE
export const SET_AUDIT_FILE = "SET_AUDIT_FILE_REQUEST";
export const SET_AUDIT_FILE_SUCCESS = "SET_AUDIT_FILE_SUCCESS";
export const SET_AUDIT_FILE_FAILED = "SET_AUDIT_FILE_FAILED";
