import React from "react";

import { Modal, Title, Button } from "components/simple";

import styles from "./styles.module.scss";

function DeleteModal({ onCloseModal, isCreateTagLoading, onSubmit, isModalOpen }) {
  return (
    <Modal isOpen={isModalOpen} toggle={onCloseModal}>
      <Title className={styles.title}>Delete Channel</Title>
      <p className="p-4 font-medium text-base text-center">Are you sure, You want to delete this channel?</p>
      <div className={styles.btnsRow}>
        <Button variant="outline" onClick={onCloseModal} className={styles.btn}>
          Cancel
        </Button>
        <Button onClick={onSubmit} className={styles.btn} isLoading={isCreateTagLoading}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
}

export default DeleteModal;
