import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

import { routes } from "router";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { navigate } from "helper/history";
import { getAccuknoxWorkspace } from "api/api";
import { getUserId } from "store/entities/auth/utils";
import { addNotification } from "store/entities/notifications/actions";
import {
  useEditRegistryMutate,
  useTestRegistryCredMutate
} from "store/entities/registry/mutations";
import { useGetRegistryQuery } from "store/entities/registry/queries";

import { GCRForm } from "./Forms/GCRForm";
import { ECRForm } from "./Forms/ECRForm";
import { NexusForm } from "./Forms/NexusForm";
import { transformPassword } from "./utils/utils";
import { DockerHubForm } from "./Forms/DockerHubForm";
import { RegistryName } from "./Forms/Fields/common/RegistryName";
import { RegistryType } from "./Forms/Fields/common/RegistryType";
import { Button, ContentContainer, Title } from "components/simple";
import { RegistryDescription } from "./Forms/Fields/common/RegistryDescription";

function EditRegistry() {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedRegistryType, setSelectedRegistryType] = useState();

  const {
    reset,
    control,
    watch,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setIsSaveDisabled(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const testRegistryCred = useTestRegistryCredMutate(
    () => {
      dispatch(addNotification({ msg: "Registry Tested Successfully", type: "success" }));
      setIsSaveDisabled(false);
    },
    () => {
      dispatch(
        addNotification({
          msg: {
            2: "Invalid registryURL or username or password",
            4: "Invalid JSON key",
            5: "Invalid accessKey or secretKey",
            6: "Invalid username or password"
          }[selectedRegistryType?.value],
          type: "error"
        })
      );
    }
  );

  const { state: registry } = useLocation();
  const { data: registryInfo, refetch: refetchRegistryInfo } = useGetRegistryQuery(registry?.id);

  const editRegistry = useEditRegistryMutate(
    () => {
      queryClient.invalidateQueries(["getRegistryList"]);
      dispatch(addNotification({ msg: "Registry Updated Successfully", type: "success" }));
    },
    () => {
      dispatch(addNotification({ msg: "Registry Updation Failed", type: "error" }));
    }
  );

  useEffect(() => {
    setSelectedRegistryType(
      {
        2: { label: "Nexus", value: 2 },
        4: { label: "GCR", value: 4 },
        5: { label: "ECR", value: 5 },
        6: { label: "DockerHub", value: 6 }
      }[registryInfo?.registry_id]
    );

    reset({
      registryName: registryInfo?.name || "",
      registryDescription: registryInfo?.description || "",
      registryType:
        {
          2: { label: "Nexus", value: 2 },
          4: { label: "GCR", value: 4 },
          5: { label: "ECR", value: 5 },
          6: { label: "DockerHub", value: 6 }
        }[registryInfo?.registry_id] || "",

      registryURL: registryInfo?.url || "",
      username: registryInfo?.user_name || "",
      password: "*****",

      registryRegion: {
        value: registryInfo?.url.split("//")[1],
        label: registryInfo?.url.split("//")[1]
      }, // GCR region and ECR region is mapped to url at backend?
      jsonKey: "{*****}",

      accessKey: registryInfo?.user_name,
      secretKey: "*****"
    });
  }, [registryInfo || {}, registry || {}]);

  const handleTest = async e => {
    const payload = {
      apikey: "",
      id: registry?.id,
      registry_name: e?.registryType?.label || "",
      registry_type: e?.registryType?.value || 0,

      url: {
        2: e?.registryURL || "",
        4: `https://${e?.registryRegion?.label}` || "",
        5: e?.registryRegion?.label || "",
        6: "https://hub.docker.com/v2/repositories/"
      }[e?.registryType?.value],

      username: {
        2: e?.username || "",
        4: "",
        5: e?.accessKey || "",
        6: e?.username || ""
      }[e?.registryType?.value],
      password: {
        2: transformPassword(e?.password),
        4: transformPassword(e?.jsonKey),
        5: transformPassword(e?.secretKey),
        6: transformPassword(e?.password)
      }[e?.registryType?.value]
    };

    testRegistryCred.mutate(payload);
  };

  const onSubmit = async e => {
    const payload = {
      description: e?.registryDescription || "",
      id: registry?.id,
      name: e?.registryName || "",
      registry_id: e?.registryType?.value,
      updated_by: getUserId(),
      url: {
        2: e?.registryURL || "",
        4: `https://${e?.registryRegion?.label}` || "",
        5: e?.registryRegion?.label || "",
        6: "https://hub.docker.com/v2/repositories/"
      }[e?.registryType?.value],
      user_name: {
        2: e?.username || "",
        4: "",
        5: e?.accessKey || "",
        6: e?.username || ""
      }[e?.registryType?.value],
      workspace_id: getAccuknoxWorkspace()
    };
    editRegistry.mutate(payload);
    navigate(routes.settingsIntegrationsRegistry);
  };

  return (
    <ContentContainer className={styles.container}>
      <div
        style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "flex-start" }}
      >
        <Title>Edit Registry</Title>

        <div className="mt-2">Registry Name: {registryInfo?.name}</div>
        <RegistryDescription errors={errors} control={control} />
        <RegistryType
          errors={errors}
          control={control}
          registryId={selectedRegistryType?.value}
          setSelectedRegistryType={setSelectedRegistryType}
        />

        {
          {
            2: <NexusForm isEdit control={control} errors={errors} />,
            4: (
              <GCRForm
                isEdit
                errors={errors}
                control={control}
                registryId={selectedRegistryType?.value}
              />
            ),
            5: (
              <ECRForm
                isEdit
                errors={errors}
                control={control}
                registryId={selectedRegistryType?.value}
              />
            ),
            6: <DockerHubForm isEdit control={control} errors={errors} />
          }[selectedRegistryType?.value]
        }
      </div>

      <div className="w-full flex flex-row justify-between pt-6 pb-4">
        <Button onClick={handleSubmit(handleTest)}>Test Connection</Button>
        <div className="flex">
          <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} disabled={isSaveDisabled} type="submit">
            Save
          </Button>
        </div>
      </div>
    </ContentContainer>
  );
}

export default EditRegistry;
