import { Tooltip } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";

export const OverflowTip = ({ children, placement }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();
  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);
  return (
    <Tooltip
      title={children}
      TransitionProps={{ timeout: 300 }}
      disableHoverListener={!isOverflowed}
      placement={placement}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
