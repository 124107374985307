import React from "react";
import { Typography } from "@mui/material";
import { LightTooltip } from "components/simple";
import { clsx } from "clsx";
import { VULNERABILITY_TYPES } from "store/entities/issues/riskBasedPriortization/constants";
import {
  updateSelectedVulnerabilityType,
  useRiskBasedPrioritizationState
} from "store/entities/issues/riskBasedPriortization/slice";
import { useDispatch } from "react-redux";
import { useVulnerabilitiesQuery } from "store/entities/issues/riskBasedPriortization/queries";

export const Stepper = () => {
  const dispatch = useDispatch();
  const selectedVulnerabilityType = useRiskBasedPrioritizationState("selectedVulnerabilityType");
  const { data: rbpData } = useVulnerabilitiesQuery(
    selectedVulnerabilityType?.value === "high_to_critical"
      ? VULNERABILITY_TYPES[0]
      : VULNERABILITY_TYPES[1]
  );

  const getVulnerabilityCount = v => {
    return rbpData?.results?.[0]?.finding_id === "" ? 0 : rbpData?.count;
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <Typography paragraph fontWeight={500}>
        Filter vulnerabilities by the context of their environment and risk factors
      </Typography>

      <div className="flex items-center gap-4 border shadow rounded-3xl px-4 py-2 w-full">
        <p className="text-xs m-0 font-medium">IMPORTANT</p>

        <div className="flex justify-evenly items-center rounded-3xl bg-gradient-to-l from-[#FF5353] to-[#FF981F] p-[6px] w-full h-auto">
          {VULNERABILITY_TYPES.map(vulnerability => (
            <div
              className="flex justify-center items-center relative"
              key={vulnerability.label}
              onClick={() => dispatch(updateSelectedVulnerabilityType(vulnerability))}
            >
              <LightTooltip title={vulnerability.description} placement="top" arrow>
                <div
                  className={clsx(
                    "cursor-pointer bg-transparent rounded-full p-[3px] h-auto w-auto flex items-center justify-center",
                    selectedVulnerabilityType?.value === vulnerability?.value &&
                      "outline outline-offset-0 outline-[3px] outline-white"
                  )}
                >
                  <div className="bg-white rounded-full h-[10px] w-[10px]"></div>
                </div>
              </LightTooltip>

              <div className="absolute top-10 text-center flex flex-col justify-center items-center gap-1 whitespace-nowrap">
                <p
                  className={clsx(
                    "text-sm m-0",
                    selectedVulnerabilityType?.value === vulnerability.value && "font-semibold"
                  )}
                >
                  {vulnerability.label}
                </p>
                <p className="text-sm m-0 font-bold text-red-500">
                  {getVulnerabilityCount(vulnerability)}
                </p>
              </div>
            </div>
          ))}
        </div>

        <p className="text-xs m-0 font-medium">IMPORTANT & URGENT</p>
      </div>
    </div>
  );
};
