import React, { useEffect, useRef } from "react";

/**
 This custom hook skips the first render and performs effects on the subsequent renders only
 */
const useEffectAfterMount = (callback, dependencies) => {
  const mounted = useRef(true);

  useEffect(() => {
    if (!mounted.current) {
      return callback();
    }
    mounted.current = false;
  }, dependencies);
};

export default useEffectAfterMount;
