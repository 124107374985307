/* eslint-disable no-shadow */
import { routes } from "router";
import { useMutation } from "react-query";
import { navigate } from "helper/history";
import { useDispatch } from "react-redux";
import { addNotification } from "../notifications/actions";
import { post, patch } from "api/base";
import { scanCloudAccount } from "./service";
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { stringify } from "json5";

// export const useScanCloudMutation = () => {
//   const dispatch = useDispatch();
//   return useMutation(id => scanCloudAccount(id), {
//     onSuccess: data => {
//       dispatch(addNotification({ msg: "Scan task was added to queue", type: "success" }));
//     },
//     onError: data => {
//       dispatch(addNotification({ msg: "Scan task was not added to queue", type: "error" }));
//     }
//   });
// };

export const useAwsCloudAccessScan = id => {
  const dispatch = useDispatch();
  return useMutation(
    ["getAwsCloudAccessScan"],
    async payload => {
      const response = await patch(`${API}/aws-cloud-access-key/${id}`, JSON.stringify(payload), {
        ...contentTypeJson(),
        ...authorization(),
        ...authorizationTenant()
      });
      const result = await response.json();
    },
    {
      onSuccess: () => {
        dispatch(
          addNotification({ msg: "Scheduled scan reports will sent to Email", type: "success" })
        );
      },
      onError: () => {
        dispatch(
          addNotification({ msg: "There was an error while Scheduling Scan", type: "error" })
        );
      }
    }
  );
};
