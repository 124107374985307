import { useQuery } from "react-query";
import { get } from "api/base";
import { async } from "q";
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { getAccessTokenHeader, getCurrentTenant } from "../auth/utils";

export const awsCloudAccessKeyDetails = id => {
  return useQuery(["awsCloudAccessDetails", id], async () => {
    const response = await get(`${API}/aws-cloud-access-key/${id}`, {
      ...contentTypeJson(),
      ...authorization(),
      ...authorizationTenant()
    });
    const result = await response.json();
    return result;
  });
};
