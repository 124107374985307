import { createTheme } from "@mui/material/styles";

const Theme1 = createTheme({
  palette: {
    primary: {
      light: "#F9E8E8",
      main: "#d83e3c",
      dark: "#722120"
    },
    secondary: {
      main: "#2B3858"
    }
  },
  sideMenu: {
    background: "#d83e3c",
    highlight: "#ea5e5d",
    logoColor: "#FFFFFF",
    textColor: "#FFE5E4",
    textHighlight: "#FF8682"
  },
  modalSelectionBackground: "#F5F8FF"
});

const Theme2 = createTheme({
  palette: {
    primary: {
      light: "#6ab7ff",
      main: "#379EFF",
      dark: "#1a4267"
    },
    secondary: {
      main: "#2B3858"
    }
  },
  sideMenu: {
    background: "#379EFF",
    highlight: "#6ab7ff",
    logoColor: "#FFFFFF",
    textColor: "#FFFFFF",
    textHighlight: "#FFFFFF"
  },
  modalSelectionBackground: "#F5F8FF"
});

const Theme3 = createTheme({
  palette: {
    primary: {
      light: "#2D3F63",
      main: "#1F3155",
      dark: "#14223f"
    },
    secondary: {
      main: "#2B3858"
    }
  },
  sideMenu: {
    background: "#1F3155",
    highlight: "#2D3F63",
    logoColor: "#FFFFFF",
    textColor: "#FFFFFF",
    textHighlight: "#FFFFFF"
  },
  modalSelectionBackground: "#F5F8FF"
});

const Theme4 = createTheme({
  palette: {
    primary: {
      light: "#2E313B",
      main: "#1D1F29",
      dark: "#13151c"
    },
    secondary: {
      main: "#2B3858"
    }
  },
  sideMenu: {
    background: "#1D1F29",
    highlight: "#2E313B",
    logoColor: "#FFFFFF",
    textColor: "#FFFFFF",
    textHighlight: "#FFFFFF"
  },
  modalSelectionBackground: "#F5F8FF"
});

const Theme5 = createTheme({
  palette: {
    primary: {
      light: "#253664",
      main: "#172A5B",
      dark: "#101e44"
    },
    secondary: {
      main: "#2B3858"
    }
  },
  sideMenu: {
    background: "#172A5B",
    highlight: "#253664",
    logoColor: "#FFFFFF",
    textColor: "#FFFFFF",
    textHighlight: "#FFFFFF"
  },
  modalSelectionBackground: "#F5F8FF"
});

const Theme6 = createTheme({
  palette: {
    primary: {
      light: "#66B0B0",
      main: "#44A7A7",
      dark: "#297575"
    },
    secondary: {
      main: "#2B3858"
    }
  },
  sideMenu: {
    background: "#44A7A7",
    highlight: "#66B0B0",
    logoColor: "#FFFFFF",
    textColor: "#FFFFFF",
    textHighlight: "#FFFFFF"
  },
  modalSelectionBackground: "#F5F8FF"
});

const Theme7 = createTheme({
  palette: {
    primary: {
      light: "#4BC0A5",
      main: "#3AB195",
      dark: "#268f76"
    },
    secondary: {
      main: "#2B3858"
    }
  },
  sideMenu: {
    background: "#3AB195",
    highlight: "#4BC0A5",
    logoColor: "#FFFFFF",
    textColor: "#FFFFFF",
    textHighlight: "#FFFFFF"
  },
  modalSelectionBackground: "#F5F8FF"
});

const Theme8 = createTheme({
  palette: {
    primary: {
      light: "#72A1D8",
      main: "#6190C7",
      dark: "#375e8d"
    },
    secondary: {
      main: "#2B3858"
    }
  },
  sideMenu: {
    background: "#6190C7",
    highlight: "#72A1D8",
    logoColor: "#FFFFFF",
    textColor: "#FFFFFF",
    textHighlight: "#FFFFFF"
  },
  modalSelectionBackground: "#F5F8FF"
});

const Theme9 = createTheme({
  palette: {
    primary: {
      light: "#253664",
      main: "#021040",
      dark: "#101e44"
    },
    secondary: {
      main: "#2B3858"
    }
  },
  sideMenu: {
    // background: "#021040",
    // highlight: "#171F4F",
    background: "#101828",
    highlight: "#344054",
    logoColor: "#F9E8E8",
    textColor: "#F9E8E8",
    textHighlight: "#F9E8E8"
  },
  modalSelectionBackground: "#F5F8FF"

});

export default { Theme1, Theme2, Theme3, Theme4, Theme5, Theme6, Theme7, Theme8, Theme9 };
