import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Title, Modal, Input, Select, Icon } from "components/simple";
import { styled } from "@mui/material";

import { addTarget } from "store/entities/vulnerabilities/actions";
import { getTargetsTypeVulnerabilitiesListSelect } from "store/entities/vulnerabilities/selectors";

import styles from "./styles.module.scss";

const ButtonAddRow = styled("div")(({ theme }) => {
  return {
    width: "24px",
    height: "24px",
    borderRadius: "5px",
    cursor: "pointer",
    flexShrink: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    marginLeft: "10px",
    fontSize: "20px",
    marginBottom: "8px"
  };
});

const NewTarget = ({ open, onBack = () => null, close, displayFieldsList = [], data }) => {
  const [state, setState] = useState({
    name: "",
    targetType: "",
    rows: [{ displayField: [], mapFields: "" }]
  });

  const dispatch = useDispatch();

  const targetsTypeList = useSelector(getTargetsTypeVulnerabilitiesListSelect);

  const validation = () => {
    let valid = true;
    if (!state?.name) {
      valid = false;
    }

    if (!state?.targetType) {
      valid = false;
    }

    const mapping = state?.rows?.every(item => {
      return item.mapFields?.value && item.displayField?.value;
    });

    if (!mapping) {
      valid = false;
    }

    return valid;
  };

  const onSave = () => {
    if (validation()) {
      const mapping = state?.rows?.map(item => {
        return [item.mapFields?.value, item.displayField?.value];
      });
      const res = {
        name: state?.name,
        target_type: state.targetType?.value,
        mapping,
        ids: data
      };

      dispatch(addTarget(res));
    }
  };

  const handleChange = (value, key) => {
    setState({ ...state, [key]: value });
  };

  const handleChangeSelect = (val, key, i) => {
    const res = state.rows?.map((item, index) => {
      if (i === index) {
        return { ...item, [key]: val };
      }
      return item;
    });
    setState({ ...state, rows: res });
  };

  const onAddRow = () => {
    setState({ ...state, rows: [...state.rows, { displayField: [], mapFields: "" }] });
  };

  const onDeleteRow = i => {
    const res = state.rows?.filter((_, index) => index !== i);
    setState({ ...state, rows: res });
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Create a new target</Title>
      <div className={styles.content}>
        <div className={styles.form}>
          <Input
            type="text"
            required
            placeholder="Name"
            label="Name"
            value={state.name}
            onChange={val => handleChange(val.target.value, "name")}
            className={styles.input}
            containerClassName={styles.inputContainer}
          />
          <Select
            containerClass={styles.select}
            onChange={val => handleChange(val, "targetType")}
            value={state.targetType}
            placeholder="Dev security"
            label="Target type"
            options={targetsTypeList}
          />
          {state?.targetType?.fields ? (
            <>
              {state.rows?.map((item, index) => {
                return (
                  <div className={styles.row} key={index}>
                    <Select
                      containerClass={styles.select}
                      onChange={val => handleChangeSelect(val, "displayField", index)}
                      value={item.displayField}
                      placeholder="Select"
                      label="Display field"
                      options={displayFieldsList}
                    />
                    <Select
                      containerClass={styles.select}
                      onChange={val => handleChangeSelect(val, "mapFields", index)}
                      value={item.mapFields}
                      placeholder="Select"
                      label="Map fields to target"
                      options={state?.targetType?.fields || []}
                    />
                    <ButtonAddRow type="button" onClick={() => onAddRow()}>
                      +
                    </ButtonAddRow>
                    {state.rows.length > 1 && (
                      <div className={styles.buttonDelete} onClick={() => onDeleteRow(index)}>
                        <Icon.Close className={styles.close} />
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={onBack} variant="outline" className={styles.modalButton}>
          Back
        </Button>
        <Button onClick={onSave} className={styles.modalButton}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default NewTarget;
