import React, { useEffect, useState } from "react";

import { ContentContainer, Select, Input, TextEditor, Title } from "components/simple";
import {
  getPriorities,
  getPrioritiesFreshService,
  getConnectWiseConfig
} from "store/entities/tickets/service";

import styles from "./styles.module.scss";

const TicketForm = ({ data, onChange }) => {
  const [options, setOptions] = useState([]);
  const [focus, setFocus] = useState(false);

  const handleChange = (v, name) => {
    if (name === "priority") {
      onChange({ ...data, [name]: v?.value });
      return;
    }
    onChange({ ...data, [name]: v });
  };

  useEffect(() => {
    if (data) {
      if (data?.ticket_profile_type === "freshserviceprofile") {
        getPrioritiesFreshService({ id: data?.ticket_profile_id }).then(res => {
          const list = Object.entries(res?.priorities).map(([key, val]) => {
            return { value: key, label: val };
          });
          setOptions(list);
        });
      } else if (data?.ticket_profile_type === "connectwiseprofile") {
        getConnectWiseConfig({ id: data?.ticket_profile_id }).then(res => {
          const list = Object.entries(res?.priorities).map(([key, val]) => {
            return { value: key, label: val };
          });
          setOptions(list);
        });
      } else {
        getPriorities({ id: data?.ticket_profile_id, model: data?.ticket_profile_type }).then(
          res => {
            const list = res?.map(item => ({ value: item.id, label: item.name }));
            setOptions(list);
          }
        );
      }
    }
  }, []);

  const getPrioritieValue = v => {
    return options?.find(item => item?.value === v);
  };

  const onBlur = () => {
    setFocus(false);
  };
  const onFocus = () => {
    setFocus(true);
  };

  return (
    <ContentContainer className={styles.container}>
      <div>
        <Title>Create ticket</Title>
        <div className={styles.content}>
          <Select
            value={getPrioritieValue(data?.priority)}
            onChange={val => handleChange(val, "priority")}
            label="Priority"
            options={options}
            required={data?.ticket_profile_type === "freshserviceprofile"}
            containerClass={styles.field}
          />
          <Input
            value={data?.title}
            label="Ticket Title"
            onChange={val => handleChange(val.target.value, "title")}
            required="true"
            className={styles.field}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <TextEditor
            label="Ticket Description"
            value={data?.description}
            handleChange={v => handleChange(v, "description")}
            optionsList={{ autofocus: !focus }}
            onFocus={onFocus}
            isPreviewOn
          />
        </div>
      </div>
    </ContentContainer>
  );
};

export default TicketForm;
