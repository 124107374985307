import { getAccuknoxWorkspace } from "api/api";
import { customPost } from "api/base";
import { useTimeDifference } from "helper/hooks/useTimeDifference";
import { useQuery } from "react-query";
import moment from "moment";
//https://cwpp.stage.accuknox.com/dashboards/v1/telemetry/system/clusters/actions/count
const workspaceId = getAccuknoxWorkspace();
const ACTION_URL = "/dashboards/v1/telemetry/system/clusters/actions/count";
const CLUSTER_URL = "/dashboards/v1/telemetry/system/clusters/count";
const NAMESPACE_URL = "/dashboards/v1/telemetry/system/namespaces/count";
const POD_URL = "/dashboards/v1/telemetry/system/pods/count";
export function getDifferenceTime(period, now) {
  if (typeof period === "string") {
    if (period.includes("min")) {
      const diff = parseInt(period.split(" ")[1]);
      return moment(now)
        .subtract(diff, "minutes")
        .toDate();
    }
    if (period.includes("hour")) {
      const diff = parseInt(period.split(" ")[1]);
      return moment(now)
        .subtract(diff, "hours")
        .toDate();
    }
    if (period.includes("day")) {
      const diff = parseInt(period.split(" ")[1]);
      return moment(now)
        .subtract(diff, "days")
        .toDate();
    }
  } else {
    return moment(period?.from);
  }
}

const mockPayload = {
  WorkspaceID: "846",
  ClusterID: ["1483"],
  FromTime: 1672662364,
  ToTime: 1672669123
};

export const fetchClustersCounts = (range, ClusterID = [], Namespace = [], PodName = []) => {
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).unix();
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const convertCurrentTime = moment(currentTimeMoment).unix();
  let payload;
  const selecCluster = ClusterID?.map(cluster => cluster?.value.toString());
  const selecNamespace = Namespace?.map(namespace => namespace?.label.toString());
  const selecPod = PodName?.map(pod => pod?.label.toString());
  // if (range === "All") {
  //   payload = {
  //     WorkspaceID: workspaceId.toString(),
  //     // Namespace: [],

  //     // PodName: [],
  //     FromTime: 1671628356,
  //     ToTime: 1672835873
  //   };
  // } else {
  // if (typeof range === "string") {
  //   from = useTimeDifference(range, now).getTime();
  //   to = now.getTime();
  // } else if (typeof range === "object") {
  //   from = getTime(range.from);
  //   to = getTime(range.to);
  // }
  payload = {
    WorkspaceID: workspaceId.toString(),
    ClusterID: selecCluster?.length ? selecCluster : [],
    Namespace: selecNamespace?.length ? selecNamespace : [],
    PodName: selecPod?.length ? selecPod : [],
    FromTime: getFromTime ? getFromTime : 0,
    ToTime: convertCurrentTime ? convertCurrentTime : 0
  };
  // }
  return useQuery(
    ["fetchClustersCounts", workspaceId, ClusterID, Namespace, PodName, range],
    async () => {
      const response = await customPost(CLUSTER_URL, payload);
      return await response.json();
    },
    {
      onSuccess: res => {
        return res;
      },
      onError: () => {
        return [];
      },
      refetchOnWindowFocus: false
    }
  );
};
export const fetchPodsCounts = (range, ClusterID = [], Namespace = [], PodName = []) => {
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).unix();
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const convertCurrentTime = moment(currentTimeMoment).unix();
  let payload;
  const selecCluster = ClusterID?.map(cluster => cluster?.value.toString());
  const selecNamespace = Namespace?.map(namespace => namespace?.label.toString());
  const selecPod = PodName?.map(pod => pod?.label.toString());
  // if (range === "All") {
  //   payload = {
  //     WorkspaceID: workspaceId.toString(),
  //     Namespace: [],
  //     ClusterID: [],
  //     PodName: [],
  //     FromTime: 1671686008,
  //     ToTime: 1672142865
  //   };
  // } else {
  // if (typeof range === "string") {
  //   from = useTimeDifference(range, now).getTime();
  //   to = now.getTime();
  // } else if (typeof range === "object") {
  //   from = getTime(range.from);
  //   to = getTime(range.to);
  // }
  payload = {
    WorkspaceID: workspaceId.toString(),
    ClusterID: selecCluster?.length ? selecCluster : [],
    Namespace: selecNamespace?.length ? selecNamespace : [],
    PodName: selecPod?.length ? selecPod : [],
    FromTime: getFromTime ? getFromTime : 0,
    ToTime: convertCurrentTime ? convertCurrentTime : 0
  };
  // }
  return useQuery(
    ["fetchPodsCounts", workspaceId, ClusterID, Namespace, PodName, range],
    async () => {
      const response = await customPost(POD_URL, payload);
      return await response.json();
    },
    {
      onSuccess: res => {
        return res;
      },
      onError: () => {
        return [];
      },
      refetchOnWindowFocus: false
    }
  );
};
export const fetchNamespaceCounts = (range, ClusterID = [], Namespace = [], PodName = []) => {
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).unix();
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const convertCurrentTime = moment(currentTimeMoment).unix();
  let payload;
  const selecCluster = ClusterID?.map(cluster => cluster?.value.toString());
  const selecNamespace = Namespace?.map(namespace => namespace?.label.toString());
  const selecPod = PodName?.map(pod => pod?.label.toString());
  // if (range === "All") {
  //   payload = {
  //     WorkspaceID: workspaceId.toString(),
  //     Namespace: [],
  //     ClusterID: [],
  //     PodName: [],
  //     FromTime: 1671686008,
  //     ToTime: 1672142865
  //   };
  // } else {
  // if (typeof range === "string") {
  //   from = useTimeDifference(range, now).getTime();
  //   to = now.getTime();
  // } else if (typeof range === "object") {
  //   from = getTime(range.from);
  //   to = getTime(range.to);
  // }
  payload = {
    WorkspaceID: workspaceId.toString(),
    ClusterID: selecCluster?.length ? selecCluster : [],
    Namespace: selecNamespace?.length ? selecNamespace : [],
    PodName: selecPod?.length ? selecPod : [],
    FromTime: getFromTime ? getFromTime : 0,
    ToTime: convertCurrentTime ? convertCurrentTime : 0
  };
  // }
  return useQuery(
    ["fetchNamespaceCounts", workspaceId, ClusterID, Namespace, PodName, range],
    async () => {
      const response = await customPost(NAMESPACE_URL, payload);
      return await response.json();
    },
    {
      onSuccess: res => {
        return res;
      },
      onError: () => {
        return [];
      },
      refetchOnWindowFocus: false
    }
  );
};

export const fetchKubeActionCounts = (range, ClusterID = [], Namespace = [], PodName = []) => {
  const currentTime = new Date();
  const getTimeDifference = getDifferenceTime(range, currentTime);
  const getDate = new Date(getTimeDifference).getTime();
  const getFromTime = moment(getDate).unix();
  const currentTimeMoment = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
  const convertCurrentTime = moment(currentTimeMoment).unix();
  let payload;
  const selecCluster = ClusterID?.map(cluster => cluster?.value.toString());
  const selecNamespace = Namespace?.map(namespace => namespace?.label.toString());
  const selecPod = PodName?.map(pod => pod?.label.toString());

  payload = {
    WorkspaceID: workspaceId.toString(),
    ClusterID: selecCluster?.length ? selecCluster : [],
    Namespace: selecNamespace?.length ? selecNamespace : [],
    PodName: selecPod?.length ? selecPod : [],
    FromTime: getFromTime ? getFromTime : 0,
    ToTime: convertCurrentTime ? convertCurrentTime : 0
  };

  return useQuery(
    ["fetchKubeActionCounts", workspaceId, ClusterID, Namespace, PodName, range],
    async () => {
      const response = await customPost(ACTION_URL, payload);
      return await response.json();
    },
    {
      onSuccess: res => {
        return res;
      },
      onError: () => {
        return [];
      },
      refetchOnWindowFocus: false
    }
  );
};
