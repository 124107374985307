import React from "react";
import PropTypes from "prop-types";

export default function Arrows(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4977 13.3496L8.11768 13.3496C7.70768 13.3496 7.36768 13.6896 7.36768 14.0996C7.36768 14.5096 7.70768 14.8496 8.11768 14.8496L19.4977 14.8496C19.9077 14.8496 20.2477 14.5096 20.2477 14.0996C20.2477 13.6896 19.9077 13.3496 19.4977 13.3496Z"
        fill={props.color}
      />
      <path
        d="M0.750127 4.84765L12.1301 4.84766C12.5401 4.84766 12.8801 4.50766 12.8801 4.09766C12.8801 3.68766 12.5401 3.34766 12.1301 3.34766L0.750127 3.34765C0.340127 3.34765 0.000126779 3.68765 0.000126708 4.09765C0.000126636 4.50765 0.340127 4.84765 0.750127 4.84765Z"
        fill={props.color}
      />
      <path
        d="M10.3475 9.99977C10.1575 9.99977 9.9675 10.0698 9.8175 10.2198L6.4675 13.5698C6.1775 13.8598 6.1775 14.3398 6.4675 14.6298L9.8175 17.9798C10.1075 18.2698 10.5875 18.2698 10.8775 17.9798C11.1675 17.6898 11.1675 17.2098 10.8775 16.9198L8.0575 14.0998L10.8775 11.2798C11.1675 10.9898 11.1675 10.5098 10.8775 10.2198C10.7375 10.0698 10.5375 9.99977 10.3475 9.99977Z"
        fill={props.color}
      />
      <path
        d="M9.90006 8.1975C10.0901 8.1975 10.2801 8.1275 10.4301 7.9775L13.7801 4.6275C14.0701 4.3375 14.0701 3.8575 13.7801 3.5675L10.4301 0.217499C10.1401 -0.0725007 9.66006 -0.0725007 9.37006 0.217499C9.08006 0.507499 9.08006 0.9875 9.37006 1.2775L12.1901 4.0975L9.37006 6.9175C9.08006 7.2075 9.08006 7.6875 9.37006 7.9775C9.51006 8.1275 9.71006 8.1975 9.90006 8.1975Z"
        fill={props.color}
      />
    </svg>
  );
}

Arrows.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Arrows.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 21,
  height: 19
};
