import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  Controls,
  ConnectionMode,
  useReactFlow,
  ReactFlowProvider
} from "reactflow";
import "reactflow/dist/style.css";
import "./reactflow.css";
import PrimaryNode from "./Nodes/PrimaryNode";
import PrimarySubNode from "./Nodes/PrimarySubNode";
import { SecondaryNode, SecondaryHeadNode } from "./Nodes/SecondaryNode";
import SecondarySubNode from "./Nodes/SecondarySubNode";
import LeftClickMenu from "./components/LeftClickMenu";
import FloatingEdge from "./Connection/FloatingEdge";
import SecondaryEdgeConnection from "./Connection/SecondaryEdgeConnection";
import { useObservabilityContext } from "./Context";
import { Loader } from "components/simple";
import { Typography } from "@mui/material";
import NoTableRowData from "components/complex/Table/NoTableRowData";

const NestedFlow = () => {
  const {
    networkFlowData,
    networkProcessFlowData,
    setPortDropdown,
    selectedPort,
    setSelectedPort,
    setShowCommandDetails
  } = useObservabilityContext();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [image, setImage] = useState("pod");
  const [loading, setLoading] = useState(false);
  const [onClickType, setOnClickType] = useState("");
  const { setViewport, zoomIn, zoomOut, setCenter, getViewport } = useReactFlow();
  const edgeTypes = useMemo(
    () => ({
      floating: FloatingEdge,
      secondaryConnection: SecondaryEdgeConnection
    }),
    []
  );

  const scrollToBottom = useRef(null);
  const reactFlowInstance = useReactFlow();

  useEffect(() => {
    setLoading(networkFlowData?.isLoading);
    setNodes([]);
    setEdges([]);
    setShowLeftClickMenu(false);
    if (networkFlowData?.data?.nodes) {
      setLoading(networkFlowData?.isLoading);
      setNodes(
        networkFlowData?.data?.nodes.filter(
          e => e.type === "PrimaryNode" || e.data.nw_type !== "bind"
        )
      );
      setEdges(networkFlowData?.data?.edges?.filter(e => e.source !== e.target));
      scrollToBottom.current.scrollIntoView({ behavior: "smooth" });
    }
    if (networkFlowData.isSuccess && !networkFlowData?.data?.nodes) {
      setLoading(false);
      setNodes([]);
      setEdges([]);
    }
  }, [networkFlowData?.isLoading]);
  const nodeTypes = useMemo(
    () => ({
      PrimaryNode,
      PrimarySubNode,
      SecondaryHeadNode,
      SecondaryNode,
      SecondarySubNode
    }),
    []
  );

  const [showLeftClickMenu, setShowLeftClickMenu] = useState(false);
  const [selectedNode, setSelectedNode] = useState("");
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);

  const onConnect = useCallback(connection => {
    setEdges(eds => addEdge(connection, eds));
  }, []);

  // useEffect(() => {
  //   edges.forEach(e => {
  //     if (selectedPort.value === e.data.ip && selectedPort.label === e.data.port) {
  //       setSelectedNode(e);
  //     }
  //   });
  // }, [selectedPort]);

  const showSelectedDetailsModal = (e, n) => {
    if (n.type !== "SecondaryHeadNode" && n.type !== "SecondaryNode") {
      // if (!n.data.transparent) {
      if (selectedNode?.id === n?.id && selectedNode?.type === n?.type) {
        setShowLeftClickMenu(false);
        setSelectedNode("");
      } else {
        setOnClickType("");
        setImage(`${n.data.imageName ? n.data.imageName : "pod"}`);
        setXPos(
          // eslint-disable-next-line no-nested-ternary
          window.innerWidth - e.pageX > 250
            ? e.pageX < 340
              ? e.pageX + 40
              : e.pageX
            : e.pageX - (300 - (window.innerWidth - e.pageX))
        );
        setYPos(
          window.innerHeight - e.pageY > 170
            ? e.pageY
            : e.pageY - (125 + (window.innerHeight - e.pageY) / 2)
        );
        setSelectedNode(n);
        setShowLeftClickMenu(true);
      }
      // }
    }
    if (n.type === "PrimaryNode" && n.data.transparent) {
      // redirect to secondary pods view
      setViewport({ x: 500, y: 0, zoom: 1 }, { duration: 800 });
      setLoading(networkProcessFlowData.isLoading);
      // eslint-disable-next-line no-param-reassign
      n.data.transparent = false;
      setShowLeftClickMenu(false);
      setNodes(
        networkProcessFlowData?.data?.nodes.filter(
          each =>
            each.type === "SecondaryNode" ||
            each.type === "SecondaryHeadNode" ||
            each.data.nw_type !== "bind"
        )
      );
      setEdges(networkProcessFlowData?.data?.edges?.filter(e => e.source !== e.target));
    }
  };
  const handleNodeClick = (e, n) => {
    if (n.data.previous) {
      // eslint-disable-next-line no-param-reassign
      n.data.previous = false;
      setShowLeftClickMenu(false);
      setNodes(
        networkFlowData?.data?.nodes.filter(
          each => each.type === "PrimaryNode" || each.data.nw_type !== "bind"
        )
      );
      setEdges(networkFlowData?.data?.edges?.filter(e => e.source !== e.target));
      setViewport({ x: -200, y: -100, zoom: 1 }, { duration: 800 });
    }
    showSelectedDetailsModal(e, n);
    // eslint-disable-next-line no-param-reassign
    n.data.freeze = !n.data.freeze;
    if (n.type === "SecondaryHeadNode") {
      if (n.data.freeze) setShowCommandDetails(true);
      else setShowCommandDetails(false);
      const getNodes = nodes.map(node => {
        if (node.type === "SecondaryHeadNode" && node.id === n.id) {
          // eslint-disable-next-line no-param-reassign
          node.data.freeze = n.data.freeze;
        } else if (node.type === "SecondaryHeadNode") {
          // eslint-disable-next-line no-param-reassign
          node.data.freeze = false;
        } else if (node.type !== n.type && node.data.parentNode === n.id && n.data.freeze) {
          // eslint-disable-next-line no-param-reassign
          node.data.label = "Destination Pod/SVC/IP";
          // eslint-disable-next-line no-param-reassign
          node.data.toolTip = true;
          // eslint-disable-next-line no-param-reassign
          node.data.transparent = false;
        } else if (node.type !== n.type && node.data.parentNode === n.id) {
          // eslint-disable-next-line no-param-reassign
          node.data.toolTip = false;
          // eslint-disable-next-line no-param-reassign
          node.data.transparent = false;
        } else if (node.type !== n.type && node.data.parentNode !== n.id) {
          // eslint-disable-next-line no-param-reassign
          node.data.toolTip = false;
          // eslint-disable-next-line no-param-reassign
          node.data.transparent = true;
        }
        return node;
      });
      const getEdges = edges.map(edge => {
        if (edge.data.parentNode === n.id) {
          // eslint-disable-next-line no-param-reassign
          edge.source = n.id;
          // eslint-disable-next-line no-param-reassign
          edge.data.transparent = false;
        } else if (n.type !== "SecondarySubNode" && !e.label.includes("bind")) {
          // eslint-disable-next-line no-param-reassign
          edge.source = n.parentNode;
          // eslint-disable-next-line no-param-reassign
          edge.data.transparent = true;
        }
        return edge;
      });
      setNodes(getNodes);
      setEdges(getEdges);
    }
  };

  const handleEdgeClick = (e, n) => {
    if (selectedNode?.id === n?.id && selectedNode?.type === n?.type) {
      setShowLeftClickMenu(false);
      setSelectedNode("");
    } else {
      const res = [];
      edges.forEach(edge => {
        if (edge.data.ip === n.data.ip) {
          res.push({
            label: edge.data.port,
            value: edge.data.ip
          });
        }
      });
      setSelectedPort({
        label: n.data.port,
        value: n.data.ip
      });
      setPortDropdown(res);
      setOnClickType("connection");
      setImage("flow");
      setXPos(
        // eslint-disable-next-line no-nested-ternary
        window.innerWidth - e.pageX > 250
          ? e.pageX < 340
            ? e.pageX + 40
            : e.pageX
          : e.pageX - (300 - (window.innerWidth - e.pageX))
      );
      setYPos(
        window.innerHeight - e.pageY > 170
          ? e.pageY
          : e.pageY - (125 + (window.innerHeight - e.pageY) / 2)
      );
      setSelectedNode(n);
      setShowLeftClickMenu(true);
    }
  };

  const handleOnHover = (ele, n) => {
    const getFreezed = nodes.filter(e => e.data.freeze);
    if (n.type === "SecondaryHeadNode") {
      const getNodes = nodes.map(e => {
        if (e.id === n.id) {
          e.data.toolTip = true;
        } else if (e.type === "SecondarySubNode" && !n.data.freeze && e.data.parentNode === n.id) {
          e.data.transparent = false;
        }
        if (
          e.type === "SecondarySubNode" &&
          getFreezed?.length > 0 &&
          e.data.parentNode === getFreezed[0]?.id &&
          n.id !== getFreezed[0]?.id
        ) {
          e.data.transparent = true;
        }
        return e;
      });

      const getEdges = edges.map(e => {
        if (e.data.parentNode === n.id) {
          e.source = n.id;
          e.data.transparent = false;
        }
        if (
          getFreezed.length > 0 &&
          e.data.parentNode === getFreezed[0].id &&
          e.data.parentNode !== n.id
        ) {
          e.data.transparent = true;
        }
        return e;
      });
      setNodes(getNodes);
      setEdges(getEdges);
    }
  };
  const handleOnLeave = (e, n) => {
    const getFreezed = nodes?.filter(node => node.data.freeze);
    if (n.type === "SecondaryHeadNode") {
      const getNodes = nodes.map(node => {
        if (node.type === "SecondaryHeadNode" && node.id === n.id) {
          // eslint-disable-next-line no-param-reassign
          node.data.toolTip = false;
        } else if (
          node.type === "SecondarySubNode" &&
          !n.data.freeze &&
          node.data.parentNode === n.id
        ) {
          // eslint-disable-next-line no-param-reassign
          node.data.transparent = true;
        }
        if (
          node.type === "SecondarySubNode" &&
          getFreezed?.length > 0 &&
          node.data.parentNode === getFreezed[0]?.id &&
          n.id !== getFreezed[0]?.id
        ) {
          // eslint-disable-next-line no-param-reassign
          node.data.transparent = false;
        }
        return node;
      });

      const getEdges = edges.map(edge => {
        if (edge.data.parentNode === n.id && !n.data.freeze) {
          // eslint-disable-next-line no-param-reassign
          edge.source = n.parentNode;
          // eslint-disable-next-line no-param-reassign
          edge.data.transparent = true;
        }
        if (getFreezed.length > 0 && edge.data.parentNode === getFreezed[0].id) {
          // eslint-disable-next-line no-param-reassign
          edge.data.transparent = false;
        }
        return edge;
      });

      setNodes(getNodes);
      setEdges(getEdges);
    }
  };
  return (
    <div className="flex flex-col react-flow-parent overflow-x-hidden w-full" ref={scrollToBottom}>
      {loading && (
        <div className="loader">
          <Loader />
        </div>
      )}

      {showLeftClickMenu && (
        <LeftClickMenu
          data={selectedNode}
          xPos={xPos}
          yPos={yPos}
          setShowLeftClickMenu={setShowLeftClickMenu}
          image={image}
          type={onClickType}
        />
      )}
      {!loading && nodes?.length === 0 && (
        <div className="loader">
          <NoTableRowData />
        </div>
      )}
      <ReactFlow
        onLoad={() => reactFlowInstance.fitView({ duration: 1000 })}
        onNodeClick={handleNodeClick}
        onNodeMouseEnter={handleOnHover}
        onNodeMouseLeave={handleOnLeave}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectable={false}
        zoomOnScroll={!showLeftClickMenu}
        panOnDrag={!showLeftClickMenu}
        onEdgeClick={handleEdgeClick}
        connectionMode={ConnectionMode.Loose}
        fitView
        fitViewOptions={{ duration: 1000 }}
      >
        <Controls showInteractive={false} />
        <Background
          style={{
            backgroundColor: "#FBFCFF",
            borderRadius: "10px",
            border: "#96b1fd dashed"
          }}
          variant="none"
        />
      </ReactFlow>
    </div>
  );
};

export default () => (
  <ReactFlowProvider>
    <NestedFlow />
  </ReactFlowProvider>
);
