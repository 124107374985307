import { Box, InputLabel, MenuItem, Pagination, Select, Stack } from "@mui/material";
import React from "react";
import { pageSizes } from "screens/MonitorLogs/Constants";
import styled from "styled-components";

function CustomPagination({ totalCount, page, count, onChange, perPageChange, selected }) {
  return (
    <Box className="w-full flex justify-between">
      <Box className="my-auto ml-2">
        <InputLabel id="per-page" sx={{ fontSize: "14px" }}>
          {" "}
          {`Total Count: ${totalCount}`}
        </InputLabel>
      </Box>
      {totalCount ? (
        <Box className="flex justify-end gap-2 py-4">
          {selected && (
            <SelectBox>
              <InputLabel className="mt-1" id="per-page" sx={{ fontSize: "14px" }}>
                Rows per page:
              </InputLabel>
              <Select
                variant="standard"
                value={selected}
                id="per-page"
                onChange={perPageChange}
                sx={{ fontSize: "14px" }}
              >
                {pageSizes?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </SelectBox>
          )}

          <Stack>
            <Pagination
              count={count}
              page={page}
              onChange={onChange}
              shape="rounded"
              variant="outlined"
            />
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}

export default CustomPagination;

export const SelectBox = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 2px;
  font-size: 14px !important;
  .MuiInput-root::before {
    border-bottom: 0px !important;
  }
  .MuiInput-root::after {
    border-bottom: 0px !important;
  }
`;
