export const AllPolicyTabs = count => {
  return [
    {
      id: 0,
      label: `All (${count?.total_count || 0})`,
      content: null
    },
    {
      id: 1,
      label: `Discovered (${count?.discovered_count || 0})`,
      content: null
    },
    {
      id: 2,
      label: `Changed (${count?.changed_count || 0})`,
      content: null
    },
    {
      id: 3,
      label: `Active (${count?.active_count || 0})`,
      content: null
    },
    {
      id: 4,
      label: `Inactive (${count?.inactive_count || 0})`,
      content: null
    },
    {
      id: 5,
      label: `Pending (${count?.pending_count || 0})`,
      content: null
    },
    {
      id: 6,
      label: `Ignored (${count?.ignored_count || 0})`,
      content: null
    },
    {
      id: 7,
      label: `Hardening (${count?.hardening_count || 0})`,
      content: null
    },
    {
      id: 8,
      label: `Custom (${count?.custom_count || 0})`,
      content: null
    }
  ];
};
export const totalTableCount = (activeTab, allPoliciesCount) => {
  return activeTab === 1
    ? allPoliciesCount?.discovered_count
    : activeTab == 2
    ? allPoliciesCount?.changed_count
    : activeTab == 3
    ? allPoliciesCount?.active_count
    : activeTab == 4
    ? allPoliciesCount?.inactive_count
    : activeTab == 5
    ? allPoliciesCount?.pending_count
    : activeTab == 6
    ? allPoliciesCount?.ignored_count
    : activeTab == 7
    ? allPoliciesCount?.hardening_count
    : activeTab == 8
    ? allPoliciesCount?.custom_count
    : allPoliciesCount?.total_count;
};
