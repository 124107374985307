/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, remove } from "api/base";

export const getTags = fields => {
  const { page, pageSize, search } = fields;
  let url = "";

  if (search) {
    url += `&search=${search}`;
  }

  return get(`${API}/tags?page=${page + 1 || 1}&page_size=${pageSize}${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTag = id => {
  return get(`${API}/tags/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTagsMini = () => {
  return get(`${API}/tags?page_size=2000`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const createTag = fields => {
  return post(`${API}/tags`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteTag = id => {
  return remove(
    `${API}/tags/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getTagFindings = ({ page, search, id }) => {
  return get(`${API}/findings?tag_id=${id}&page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTagControls = ({ page, search, id }) => {
  return get(`${API}/controls?tag_id=${id}&page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTagAssets = ({ page, search, id }) => {
  return get(`${API}/assets?tag_id=${id}&page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTagVulnerabilities = ({ page, search, id }) => {
  return get(`${API}/vulnerabilities?tag_id=${id}&page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTagBaselines = ({ page, search, id }) => {
  return get(`${API}/baselines?tag_id=${id}&page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTagGroups = ({ page, search, id }) => {
  return get(`${API}/groups?tag_id=${id}&page=${page}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
