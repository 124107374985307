import React from "react";
import PropTypes from "prop-types";

import * as routes from "router/routes";

import { Route } from "react-router-dom";
import Login from "../Login";
import SignUp from "../SignUp";
import VerifyEmail from "../VerifyEmail";
import AddMFA from "../AddMFA";
import VerifyMFA from "../VerifyMFA";
import ResetPassword from "../ResetPassword";
import ForgotPassword from "../ForgotPassword";
import ChangePassword from "../ChangePassword";
import { SignupSuccess, ResetPasswordSuccess } from "../Verification";

import styles from "./styles.module.scss";

const AuthContainer = () => {
  return (
    <div className={styles.container}>
      <Route exact path={routes.loginScreen} component={Login} />
      <Route exact path={routes.signUpScreen} component={SignUp} />
      <Route exact path={routes.signUpSuccess} component={SignupSuccess} />
      <Route exact path={`${routes.verifyEmail}/:uid/:userToken`} component={VerifyEmail} />
      <Route exact path={routes.addMFA} component={AddMFA} />
      <Route exact path={`${routes.verifyMFA}/:mfaUid`} component={VerifyMFA} />
      <Route
        exact
        path={`${routes.resetPasswordScreen}/:uid/:userToken`}
        component={ResetPassword}
      />
      <Route exact path={routes.resetPasswordSuccess} component={ResetPasswordSuccess} />
      <Route exact path={routes.forgotPassword} component={ForgotPassword} />
      <Route exact path={routes.changePassword} component={ChangePassword} />
    </div>
  );
};

AuthContainer.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({})
};

AuthContainer.defaultProps = {
  location: {}
};

export default AuthContainer;
