import React from "react";

export const MetadataInfo = () => {
  return (
    <>
      <h3 className="font-semibold my-2">Policy Metadata</h3>
      <p className="opacity-75 my-2">
        <b>Common:</b> A security policy starts with the base information such as apiVersion, kind,
        and metadata. The apiVersion and kind would be the same in any security policies. In the
        case of metadata, you need to specify the names of a policy and a namespace where you want
        to apply the policy.
      </p>
      <p className="opacity-75 my-2">
        <b>Selector:</b> The selector part is relatively straightforward. Similar to other
        Kubernetes configurations, you can specify (a group of) pods based on labels.
      </p>
    </>
  );
};
