import React, { useState } from "react";
import {
  ContentContainer,
  Button,
  Title,
  Loader,
  Select,
  CustomPagination
} from "components/simple";
import { Table } from "components/complex";
import styles from "./style.module.scss";
import {
  customStyles,
  EDIT_AWS_CLOUD_WATCH_LINK,
  EDIT_ELASTIC_SEARCH_LINK,
  EDIT_JIRA_LINK,
  EDIT_RSYSLOG_LINK,
  EDIT_SLACK_LINK,
  EDIT_SPLUNK_LINK,
  INTEGRATIONS,
  optionsChannels
} from "../../constants";
import { useHistory } from "react-router-dom";
import useChannelList from "../hooks/listChannel";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useChannelDetails } from "screens/ChannelIntegration/services";
import { getAccuknoxWorkspace } from "api/api";
import {
  useAwsCloudIntegration,
  useElasticTest,
  useJirIntegration,
  useRsyslogIntegration,
  useSlackIntegration,
  useSplunkIntegration
} from "store/entities/channels/mutation";
import { useDispatch } from "react-redux";
import { addNotification } from "store/entities/notifications/actions";
import { updateChannelFilter, useChannelIntegrationState } from "store/entities/channels/slice";
import { getChannelIdToName } from "screens/MonitorLogs/helper";
import {
  updatePageId,
  updatePageSize,
  useDatapipelineState
} from "store/entities/datapipeline/slice";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

const ChannelList = () => {
  const { isFetching } = useChannelList();
  const history = useHistory();
  const page = useDatapipelineState("pageId");
  const pageSize = useDatapipelineState("pageSize");
  const [isLoading, setIsLoading] = useState(false);
  const workspaceVal = getAccuknoxWorkspace();
  const dispatch = useDispatch();

  const handleAPIdetails = async item => {
    try {
      const res = await useChannelDetails(item?.row?.action_id, item?.row?.Channel_id);
      switch (item?.row?.Channel_name) {
        case "Slack": {
          history.push({
            pathname: EDIT_SLACK_LINK,
            state: res?.response
          });
          break;
        }
        case "Splunk": {
          history.push({
            pathname: EDIT_SPLUNK_LINK,
            state: res?.response
          });
          break;
        }
        case "Cloudwatch": {
          history.push({
            pathname: EDIT_AWS_CLOUD_WATCH_LINK,
            state: res?.response
          });
          break;
        }
        case "ElasticSearch": {
          history.push({
            pathname: EDIT_ELASTIC_SEARCH_LINK,
            state: res?.response
          });
          break;
        }
        case "Rsyslog": {
          history.push({
            pathname: EDIT_RSYSLOG_LINK,
            state: res?.response
          });
          break;
        }
        case "Jira": {
          history.push({
            pathname: EDIT_JIRA_LINK,
            state: res?.response
          });
          break;
        }
        default:
          break;
      }
    } catch (error) {
      // console.log(error)
    }
  };
  const channelData = useChannelIntegrationState("channelData");
  const channelFilter = useChannelIntegrationState("channelFilter");
  const slackTest = useSlackIntegration(data => {
    setIsLoading(false);
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Slack test success!", type: "success" }));
    } else {
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });
  const handlePageSizeChange = event => {
    dispatch(updatePageId(1));
    dispatch(updatePageSize(event.target.value));
  };
  const handlePageChange = (event, newPage) => {
    dispatch(updatePageId(newPage));
  };
  const splunkTest = useSplunkIntegration(data => {
    setIsLoading(false);
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Splunk test success!", type: "success" }));
    } else {
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });
  const awsCloudWatch = useAwsCloudIntegration(data => {
    setIsLoading(false);
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "AWSCloudwatch test success!", type: "success" }));
    } else {
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });
  const usejiraTest = useJirIntegration(data => {
    setIsLoading(false);
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Jira test success!", type: "success" }));
    } else {
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });
  const useElastic = useElasticTest(data => {
    setIsLoading(false);
    if (data?.apiResponse === "Test Successful") {
      dispatch(addNotification({ msg: "Elastic test success!", type: "success" }));
    } else {
      dispatch(addNotification({ msg: data?.apiResponse, type: "error" }));
    }
  });
  const useRsyslog = useRsyslogIntegration(data => {
    setIsLoading(false);
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Rsyslog test success!", type: "success" }));
    } else {
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });
  const handleTest = async params => {
    setIsLoading({ ...isLoading, [params?.id]: !isLoading[params?.id] });
    const res = await useChannelDetails(params?.row?.action_id, params?.row?.Channel_id);
    setIsLoading(false);
    switch (params?.row?.Channel_name) {
      case "Slack":
        {
          const webhookUrl = res?.response?.WebhookUrl;
          const senderName = res?.response?.SenderName;
          const channelName = res?.response?.ChannelName;
          const Option = "test";
          const integrationName = res?.response?.IntegrationName;
          const title = res?.response?.Title;
          slackTest?.mutate({
            integrationName,
            senderName,
            webhookUrl,
            channelName,
            Option,
            title
          });
        }
        break;
      case "Splunk":
        {
          const splunk_url = res?.response?.Url;
          const splunk_token = res?.response?.Token;
          const splunk_source = res?.response?.Source;
          const splunk_sourceType = res?.response?.SourceType;
          const source_index = res?.response?.Index;
          const channelId = 2;
          const option = "test";
          const integrationName = res?.response?.IntegrationName;
          splunkTest?.mutate({
            channelId,
            integrationName,
            splunk_url,
            splunk_token,
            splunk_source,
            splunk_sourceType,
            source_index,
            option
          });
        }
        break;
      case "Cloudwatch":
        {
          const integrationName = res?.response?.IntegrationName;
          const accessKey = res?.response?.AccessKey;
          const secretKey = res?.response?.SecretKey;
          const region = res?.response?.Region;
          const logGroupName = res?.response?.LogGroupName;
          const option = "test";
          awsCloudWatch.mutate({
            integrationName,
            accessKey,
            secretKey,
            region,
            logGroupName,
            option
          });
        }
        break;
      case "Jira":
        {
          const EncryptedJiraUrl = res?.response?.Site;
          const tokenEncrypted = res?.response?.Token;
          const option = "test";
          const userId = res?.response?.UserID;
          const email = res?.response?.UserEmail;
          const integrationName = res?.response?.IntegrationName;
          const summary = res?.response?.IssueSummary;
          const issueType = res?.response?.IssueType;
          const projectId = res?.response?.Project;
          usejiraTest.mutate({
            integrationName,
            summary,
            EncryptedJiraUrl,
            projectId,
            issueType,
            email,
            tokenEncrypted,
            userId,
            option
          });
        }
        break;
      case "ElasticSearch":
        const elasticdetails = response?.ElasticSearch.filter(
          e => e.INTEGRATION_NAME === params?.row?.IntegrationName
        );
        const API_URL = elasticdetails[0]?.API_URL;
        const USER_NAME = elasticdetails[0]?.USERNAME;
        const PASSWORD_KEY = elasticdetails[0]?.PASSWORD;
        useElastic.mutate({
          API_URL,
          USER_NAME,
          PASSWORD_KEY
        });
        break;
      case "Rsyslog":
        {
          const integrationName = res?.response?.IntegrationName;
          const server = res?.response?.ServerAddress;
          const port = res?.response?.Port;
          const transport = res?.response?.Transport;
          const option = "test";
          useRsyslog.mutate({
            integrationName,
            server,
            port,
            transport,
            option
          });
        }
        break;
    }
  };

  const columns = [
    {
      field: "Channel_name",
      headerName: "channel",
      minWidth: 300,
      resizable: true,
      renderCell: params => <span>{params?.formattedValue}</span>
    },
    {
      field: "IntegrationName",
      headerName: "Integration Name",
      minWidth: 350,
      resizable: true
    },
    {
      field: "Status",
      headerName: "Status",
      minWidth: 300,
      resizable: true
    },
    {
      field: "test",
      headerName: "Test",
      flex: 1,
      maxWidth: 130,
      headerClassName: "super-app-theme--header",
      hide: false,
      resizable: true
    },
    {
      field: "testButton",
      headerName: "",
      flex: 1,
      maxWidth: 30,
      headerClassName: "super-app-theme--header",
      hide: false,
      align: "center",
      renderCell: params => (
        <div>
          {isLoading[params?.id] ? (
            <Loader size="20px" />
          ) : (
            <PlayCircleFilledWhiteIcon
              style={{ color: "#021040" }}
              className="cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                handleTest(params);
              }}
            />
          )}
        </div>
      )
    }
  ];
  const dataTransforRows = channelData?.dataList?.map((item, index) => ({
    id: index,
    action_id: item?.ID,
    Channel_id: item?.ChannelID,
    Channel_name: getChannelIdToName(item?.ChannelID),
    IntegrationName: item?.IntegrationName,
    Status: item?.Status === "" ? "ACTIVE" : item?.Status,
    test: "Start Testing"
  }));

  const handleChannel = data => {
    dispatch(updateChannelFilter(data));
  };
  return (
    <ContentContainer className={`${styles.container} mt-5`}>
      <Title>Integrations List</Title>
      <div className={styles.searchRow}>
        <div className={styles.selectsGroup}>
          <Select
            containerClass={styles.select}
            placeholder="Channels"
            options={optionsChannels}
            isClearable
            value={channelFilter}
            onChange={handleChannel}
          />
        </div>
        <Button onClick={() => history.push(INTEGRATIONS)}>Integrate New Channel</Button>
      </div>
      <div className={styles.table}>
        <Table
          columns={columns}
          rows={dataTransforRows || []}
          checkboxVisible={false}
          customStyles={customStyles}
          page={page - 1 || 0}
          pageSize={pageSize}
          onRowClick={params => {
            handleAPIdetails(params);
          }}
          sx={{
            "& .MuiDataGrid-row": { cursor: "pointer" }
          }}
          loading={isFetching}
          components={{
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(channelData?.total, pageSize),
              onChange: handlePageChange,
              page: page,
              totalCount: channelData?.total || 0,
              selected: pageSize,
              perPageChange: handlePageSizeChange
            }
          }}
        />
      </div>
    </ContentContainer>
  );
};

export default ChannelList;
