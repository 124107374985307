import { Skeleton } from "@mui/material";
import React from "react";
import styled from "styled-components";

export default function ObservabilityDash({ title, content }) {
  return (
    <Box>
      <Items>
        <p className="title-text" data-tip={title[0].title}>
          {title[0].title}
        </p>
        <p className="count-text">
          {content?.isLoading ? (
            <Skeleton variant="rectangular" width={100} height={11} />
          ) : (
            content?.data?.allowed_events || 0
          )}
        </p>
      </Items>
      <Items>
        <p className="title-text" data-tip={title[1].title}>
          {title[1].title}
        </p>
        <p className="count-text">
          {content?.isLoading ? (
            <Skeleton variant="rectangular" width={100} height={11} />
          ) : (
            content?.data?.blocked_events || 0
          )}
        </p>
      </Items>
      <Items>
        <p className="title-text" data-tip={title[2].title}>
          {title[2].title}
        </p>
        <p className="count-text">
          {content?.isLoading ? (
            <Skeleton variant="rectangular" width={100} height={11} />
          ) : (
            content?.data?.file_events || 0
          )}
        </p>
      </Items>
      <Items>
        <p className="title-text" data-tip={title[3].title}>
          {title[3].title}
        </p>
        <p className="count-text">
          {content?.isLoading ? (
            <Skeleton variant="rectangular" width={100} height={11} />
          ) : (
            content?.data?.network_ingress_events || 0
          )}
        </p>
      </Items>
      <Items>
        <p className="title-text" data-tip={title[4].title}>
          {title[4].title}
        </p>
        <p className="count-text">
          {content?.isLoading ? (
            <Skeleton variant="rectangular" width={100} height={11} />
          ) : (
            content?.data?.network_egress_events || 0
          )}
        </p>
      </Items>
      <Items>
        <p className="title-text" data-tip={title[5].title}>
          {title[5].title}
        </p>
        <p className="count-text">
          {content?.isLoading ? (
            <Skeleton variant="rectangular" width={100} height={11} />
          ) : (
            content?.data?.process_events || 0
          )}
        </p>
      </Items>
    </Box>
  );
}

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1%;
  width: 100%;

  .title-text {
    font-size: 14px;
    text-align: start;
    font-weight: 500;
    white-space: break-space;
    margin: 0;
    // width: 150px;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  .count-text {
    font-weight: 500;
    font-size: 21px;
    color: #000;
    margin: 0;
    // padding-top: 10px;
    text-align: start;
  }
  .percentage-text {
    position: relative;
    top: -3px;
  }

  @media screen and (max-width: 1400px) {
    .title-text {
      font-size: 12px;
      width: 100%;
    }

    .count-text {
      font-size: 19px;
    }
  }

  @media screen and (max-width: 1300px) {
    .title-text {
      font-size: 11px;
      width: 100%;
    }

    .count-text {
      font-size: 18px;
    }
  }
`;
export const Items = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  height: 100px;
  width: 20%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1400px) {
    // width: 160px;
    height: 110px;
    padding: 8px 8px;
    overflow: hidden;
  }

  @media screen and (max-width: 1300px) {
    padding: 10px 10px;
    overflow: hidden;
  }
`;
