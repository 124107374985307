export const SET_PARAMS_VULNERABILITIES = "SET_PARAMS_VULNERABILITIES";
export const UPD_PARAMS_VULNERABILITIES = "UPD_PARAMS_VULNERABILITIES";

// Get Vulnerabilities
export const GET_VULNERABILITIES = "GET_VULNERABILITIES_REQUEST";
export const GET_VULNERABILITIES_SUCCESS = "GET_VULNERABILITIES_SUCCESS";
export const GET_VULNERABILITIES_FAILED = "GET_VULNERABILITIES_FAILED";

// Get one vulnerability
export const GET_VULNERABILITY = "GET_VULNERABILITY_REQUEST";
export const GET_VULNERABILITY_SUCCESS = "GET_VULNERABILITY_SUCCESS";
export const GET_VULNERABILITY_FAILED = "GET_VULNERABILITY_FAILED";

// Edit vulnerability
export const EDIT_VULNERABILITY = "EDIT_VULNERABILITY_REQUEST";
export const EDIT_VULNERABILITY_SUCCESS = "EDIT_VULNERABILITY_SUCCESS";
export const EDIT_VULNERABILITY_FAILED = "EDIT_VULNERABILITY_FAILED";

// Get vulnerability risk factors (options)
export const GET_VULNERABILITY_RISK_FACTORS = "GET_VULNERABILITY_RISK_FACTORS_REQUEST";
export const GET_VULNERABILITY_RISK_FACTORS_SUCCESS = "GET_VULNERABILITY_RISK_FACTORS_SUCCESS";
export const GET_VULNERABILITY_RISK_FACTORS_FAILED = "GET_VULNERABILITY_RISK_FACTORS_FAILED";

// Get finding statuses (options)
export const GET_FINDING_STATUSES = "GET_FINDING_STATUSES_REQUEST";
export const GET_FINDING_STATUSES_SUCCESS = "GET_FINDING_STATUSES_SUCCESS";
export const GET_FINDING_STATUSES_FAILED = "GET_FINDING_STATUSES_FAILED";

// Get vulnerability history
export const GET_VULNERABILITY_HISTORY = "GET_VULNERABILITY_HISTORY_REQUEST";
export const GET_VULNERABILITY_HISTORY_SUCCESS = "GET_VULNERABILITY_HISTORY_SUCCESS";
export const GET_VULNERABILITY_HISTORY_FAILED = "GET_VULNERABILITY_HISTORY_FAILED";

// Get vulnerability assets
export const GET_VULNERABILITY_ASSETS = "GET_VULNERABILITY_ASSETS_REQUEST";
export const GET_VULNERABILITY_ASSETS_SUCCESS = "GET_VULNERABILITY_ASSETS_SUCCESS";
export const GET_VULNERABILITY_ASSETS_FAILED = "GET_VULNERABILITY_ASSETS_FAILED";

// GET vulnerability configs
export const GET_VULNERABILITY_CONFIGS = "GET_VULNERABILITY_CONFIGS_REQUEST";
export const GET_VULNERABILITY_CONFIGS_SUCCESS = "GET_VULNERABILITY_CONFIGS_SUCCESS";
export const GET_VULNERABILITY_CONFIGS_FAILED = "GET_VULNERABILITY_CONFIGS_FAILED";

export const GET_VULNERABILITY_CONFIGS_FILTERS_DATA =
  "GET_VULNERABILITY_CONFIGS_FILTERS_DATA_REQUEST";
export const GET_VULNERABILITY_CONFIGS_FILTERS_DATA_SUCCESS =
  "GET_VULNERABILITY_CONFIGS_FILTERS_DATA_SUCCESS";
export const GET_VULNERABILITY_CONFIGS_FILTERS_DATA_FAILED =
  "GET_VULNERABILITY_CONFIGS_FILTERS_DATA_FAILED";

// EDIT vulnerability configs
export const EDIT_VULNERABILITY_CONFIGS = "EDIT_VULNERABILITY_CONFIGS_REQUEST";
export const EDIT_VULNERABILITY_CONFIGS_SUCCESS = "EDIT_VULNERABILITY_CONFIGS_SUCCESS";
export const EDIT_VULNERABILITY_CONFIGS_FAILED = "EDIT_VULNERABILITY_CONFIGS_FAILED";

// POST vulnerability configs
export const POST_VULNERABILITY_CONFIGS = "POST_VULNERABILITY_CONFIGS_REQUEST";
export const POST_VULNERABILITY_CONFIGS_SUCCESS = "POST_VULNERABILITY_CONFIGS_SUCCESS";
export const POST_VULNERABILITY_CONFIGS_FAILED = "POST_VULNERABILITY_CONFIGS_FAILED";

// DELETE vulnerability configs
export const DELETE_VULNERABILITY_CONFIGS = "DELETE_VULNERABILITY_CONFIGS_REQUEST";
export const DELETE_VULNERABILITY_CONFIGS_SUCCESS = "DELETE_VULNERABILITY_CONFIGS_SUCCESS";
export const DELETE_VULNERABILITY_CONFIGS_FAILED = "DELETE_VULNERABILITY_CONFIGS_FAILED";

// Edit finding
export const EDIT_FINDING = "EDIT_FINDING_REQUEST";
export const EDIT_FINDING_SUCCESS = "EDIT_FINDING_SUCCESS";
export const EDIT_FINDING_FAILED = "EDIT_FINDING_FAILED";

// Edit finding asset
export const EDIT_FINDING_ASSET = "EDIT_FINDING_ASSET_REQUEST";
export const EDIT_FINDING_ASSET_SUCCESS = "EDIT_FINDING_ASSET_SUCCESS";
export const EDIT_FINDING_ASSET_FAILED = "EDIT_FINDING_ASSET_FAILED";

// MODAL
// Create Target
export const TARGET_MODAL = "TARGET_MODAL_VULNERABILITY";
// Create Group
export const GROUP_MODAL = "GROUP_MODAL_VULNERABILITY";
// Create Tag
export const TAG_MODAL = "TAG_MODAL_VULNERABILITY";

// ACTIONS

// GET TAGS
export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAILED = "GET_TAGS_FAILED";

// ADD TAG
export const ADD_TAG = "ADD_TAG";
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS";
export const ADD_TAG_FAILED = "ADD_TAG_FAILED";

// GET GROUP
export const GET_TO_GROUP = "GET_TO_GROUP_VULNERABILITY_REQUEST";
export const GET_TO_GROUP_SUCCESS = "GET_TO_GROUP_VULNERABILITY_SUCCESS";
export const GET_TO_GROUP_FAILED = "GET_TO_GROUP_VULNERABILITY_FAILED";

// ADD GROUP
export const ADD_TO_GROUP = "ADD_TO_GROUP_VULNERABILITY_REQUEST";
export const ADD_TO_GROUP_SUCCESS = "ADD_TO_GROUP_VULNERABILITY_SUCCESS";
export const ADD_TO_GROUP_FAILED = "ADD_TO_GROUP_VULNERABILITY_FAILED";

// GET TARGET
export const GET_TO_TARGET = "GET_TO_TARGET_VULNERABILITY_REQUEST";
export const GET_TO_TARGET_SUCCESS = "GET_TO_TARGET_VULNERABILITY_SUCCESS";
export const GET_TO_TARGET_FAILED = "GET_TO_TARGET_VULNERABILITY_FAILED";

// GET TARGET TYPES
export const GET_TO_TARGET_TYPES = "GET_TO_TARGET_TYPES_VULNERABILITY_REQUEST";
export const GET_TO_TARGET__TYPES_SUCCESS = "GET_TO_TARGET_TYPES_VULNERABILITY_SUCCESS";
export const GET_TO_TARGET__TYPES_FAILED = "GET_TO_TARGET_TYPES_VULNERABILITY_FAILED";

// ADD TARGET
export const ADD_TO_TARGET = "ADD_TO_TARGET_VULNERABILITY_REQUEST";
export const ADD_TO_TARGET_SUCCESS = "ADD_TO_TARGET_VULNERABILITY_SUCCESS";
export const ADD_TO_TARGET_FAILED = "ADD_TO_TARGET_VULNERABILITY_FAILED";

// IGNORE FINDING
export const IGNORE_FINDING = "IGNORE_FINDING_VULNERABILITY_REQUEST";
export const IGNORE_FINDING_SUCCESS = "IGNORE_FINDING_VULNERABILITY_SUCCESS";
export const IGNORE_FINDING_FAILED = "IGNORE_FINDING_VULNERABILITY_FAILED";

// EXPORT VULNERABILITY
export const EXPORT_VULNERABILITY = "EXPORT_VULNERABILITY_REQUEST";
export const EXPORT_VULNERABILITY_SUCCESS = "EXPORT_VULNERABILITY_SUCCESS";
export const EXPORT_VULNERABILITY_FAILED = "EXPORT_VULNERABILITY_FAILED";

// UPD STATU VULNERABILITY
export const UPD_STATUS_VULNERABILITY = "UPD_STATUS_VULNERABILITY_REQUEST";
export const UPD_STATUS_VULNERABILITY_SUCCESS = "UPD_STATUS_VULNERABILITY_SUCCESS";
export const UPD_STATUS_VULNERABILITY_FAILED = "UPD_STATUS_VULNERABILITY_FAILED";

// UPD CURRENT VULNERABILITY
export const UPD_CURRENT_VULNERABILITY = "UPD_CURRENT_VULNERABILITY_REQUEST";
export const UPD_CURRENT_VULNERABILITY_SUCCESS = "UPD_CURRENT_VULNERABILITY_SUCCESS";
export const UPD_CURRENT_VULNERABILITY_FAILED = "UPD_STATUS_VULNERABILITY_FAILED";
