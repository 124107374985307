import React, { useState } from "react";

import { routes } from "router";
import { navigate } from "helper/history";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import { useGetListOfRegistryQuery } from "store/entities/registry/queries";

import { Table } from "components/complex";
import { registryTableHeaders } from "./utils/utils";
import { Button, ContentContainer, CustomPagination } from "components/simple";
import { Typography } from "@mui/material";
import InfoIcon from "components/simple/Icon/Info";
import { useTheme } from "@mui/styles";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

function Registry() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [startIndex, setStartIndex] = useState(0);

  const theme = useTheme();
  const apiRef = useGridApiRef();

  const {
    data: allRegistries = {},
    isLoading: isRegistriesLoading,
    refetch: refetchListOfRegistries
  } = useGetListOfRegistryQuery(startIndex, pageSize);

  return (
    <ContentContainer className="p-4 flex flex-col gap-2 bg-slate-500">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 p-2 bg-blue-100 rounded">
          <InfoIcon color={theme.palette.primary.light} />
          <Typography fontSize={14} sx={{ color: "primary.main", fontWeight: 500 }}>
            By default, Images are scanned every 2 hours
          </Typography>
        </div>

        <div className="flex items-center justify-center gap-2">
          {Boolean(allRegistries?.list_of_registry?.length) && (
            <Button onClick={() => navigate(routes.registryScan)}>View Registry Scan</Button>
          )}
          <Button icon="plus" onClick={() => navigate(routes.settingsIntegrationsRegistryAdd)}>
            Add Registry
          </Button>
        </div>
      </div>

      <div>
        <Table
          apiRef={apiRef}
          data={allRegistries?.list_of_registry}
          columns={registryTableHeaders}
          rowCount={allRegistries?.total_record || 0}
          page={page}
          pageSize={pageSize}
          // rowsPerPageOptions={[5, 10, 20, 100]}
          // onPageChange={p => {
          //   setPage(p);
          //   setStartIndex(pageSize * p);
          // }}
          // onPageSizeChange={newPageSize => {
          //   setPageSize(newPageSize);
          // }}
          paginationMode="server"
          loading={isRegistriesLoading}
          components={{
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(allRegistries?.total_record, pageSize),
              onChange: (event, value) => {
                setPage(value - 1);
                setStartIndex(pageSize * value);
              },
              page: page + 1,
              totalCount: allRegistries?.total_record || 0,
              selected: pageSize,
              perPageChange: (event, value) => {
                setPageSize(event?.target?.value);
              }
            }
          }}
        />
      </div>
    </ContentContainer>
  );
}

export default Registry;
