import React from "react";
import moment from "moment";
import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { BugChip, LockChip, StackedBar } from "../../components/common";
import { SEVERITY_COLORS } from "screens/Issues/RegistryScan/constants";

const headerClassName = "super-app-theme--header";

export const customStyle = {
  height: 710,
  width: 1,
  "& .super-app-theme--header": {
    backgroundColor: "#D9E0E7"
  },

  // these classes are specifically for sensitive-data tables
  "& .super-app-theme--sensitiveData--CRITICAL": {
    borderLeft: 5,
    borderRadius: "5px",
    borderLeftColor: SEVERITY_COLORS.Critical
  },
  "& .super-app-theme--sensitiveData--HIGH": {
    borderLeft: 5,
    borderRadius: "5px",
    borderLeftColor: SEVERITY_COLORS.High
  },
  "& .super-app-theme--sensitiveData--MEDIUM": {
    borderLeft: 5,
    borderRadius: "5px",
    borderLeftColor: SEVERITY_COLORS.Medium
  },
  "& .super-app-theme--sensitiveData--LOW": {
    borderLeft: 5,
    borderRadius: "5px",
    borderLeftColor: SEVERITY_COLORS.Low
  },
  "& .super-app-theme--sensitiveData--NEGLIGIBLE": {
    borderLeft: 5,
    borderRadius: "5px",
    borderLeftColor: SEVERITY_COLORS.Negligible
  }
};

export const layersDataHeaders = [
  {
    headerName: "Created",
    field: "created_at",
    flex: 1,
    hide: false,
    renderCell: params => moment(params.value).format("MM/DD/YYYY hh:mm A"),
    headerClassName: headerClassName
  },
  {
    headerName: "Command",
    field: "created_by",
    flex: 2,
    hide: false,
    headerClassName: headerClassName
  }
];

export const getVulnerabilities = image => {
  return {
    Critical: image?.severities?.critical,
    High: image?.severities?.high,
    Medium: image?.severities?.medium,
    Low: image?.severities?.low,
    Negligible: image?.severities?.negligible
  };
};

export const resourcesDataHeaders = [
  {
    headerName: "Resource",
    field: "name",
    minWidth: 250,
    flex: 1,
    hide: false,
    headerClassName: headerClassName
  },
  {
    headerName: "Type",
    field: "type",
    minWidth: 150,
    flex: 1,
    hide: false,
    headerClassName: headerClassName
  },
  {
    headerName: "Version",
    field: "ins_version",
    minWidth: 150,
    flex: 1,
    hide: false,
    headerClassName: headerClassName
  },
  {
    headerName: "Fix Version",
    field: "fix_version",
    minWidth: 200,
    flex: 1,
    renderCell: params => {
      return params?.value === "" ? "None" : params?.value;
    },
    hide: false,
    headerClassName: headerClassName
  },
  {
    headerName: "Vulnerabilities",
    field: "vulns_found",
    minWidth: 150,
    flex: 1,
    renderCell: params => {
      const vulnerabilities = getVulnerabilities(params?.row);
      return params?.value === 0 ? (
        <Typography sx={{ fontWeight: 600, color: "#72C575" }}>No Issues</Typography>
      ) : (
        <StackedBar data={vulnerabilities} dataLength={5} severitycolor={SEVERITY_COLORS} />
      );
    },
    hide: false,
    headerClassName: headerClassName
  }
];

export const sensitiveDataHeaders = [
  {
    headerName: "File Name",
    field: "filename",
    flex: 1,
    headerClassName: headerClassName
  },
  {
    headerName: "Full Path",
    field: "path",
    flex: 1,
    headerClassName: headerClassName
  }
];

export const scanHistoryDataHeaders = [
  {
    headerName: "Scan Date",
    field: "date",
    flex: 1,
    renderCell: params => moment(params.value).format("MM/DD/YYYY hh:mm A"),
    hide: false,
    headerClassName: headerClassName
  },
  {
    headerName: "Image ID",
    field: "digest",
    flex: 2,
    hide: false,
    headerClassName: headerClassName
  },
  {
    headerName: "Security Status",
    field: "security_status",
    flex: 1,
    renderCell: params => (
      <div className="flex justify-center items-center gap-1">
        <CheckCircleIcon style={{ color: "#72C575" }} />
        <p className="text-[#03B72A]">Passed</p>
      </div>
    ),
    hide: false,
    headerClassName: headerClassName
  },
  {
    headerName: "Image Creation Date",
    field: "image_creation_date",
    flex: 1,
    renderCell: params => moment(params.value).format("MM/DD/YYYY hh:mm A"),
    hide: false,
    headerClassName: headerClassName
  },
  {
    headerName: "Scan Results",
    field: "crit_vulns",
    flex: 2,
    renderCell: params => {
      const vulnerabilities = getVulnerabilities(params?.row);
      return (
        <div className="w-4/5 flex justify-between items-center gap-4">
          <StackedBar
            height={24}
            data={vulnerabilities}
            dataLength={5}
            severitycolor={SEVERITY_COLORS}
          />
          <div className="flex items-center gap-1">
            <BugChip size="small" count={params?.row?.malware_data} fill={false} />
            <LockChip size="small" count={params?.row?.sensitive_data} fill={false} />
          </div>
        </div>
      );
    },
    hide: false,
    headerClassName: headerClassName
  }
];
