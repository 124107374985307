import { useMutation, useQuery } from "react-query";
import { getAccuknoxWorkspace } from "api/api";
import { customGet, customPost } from "api/base";
import { CLUSTER, POD, CONTAINER, VMGROUP, VMINSTANCE, NODE } from "../constant";
import { getClusterDetail } from "./api";
import moment from "moment";

const workspaceid = getAccuknoxWorkspace();

export function getDifferenceTime(period, now) {
  const diff = parseInt(period?.split(" ")[1]);
  if (period.includes("min"))
    return moment(now)
      .subtract(diff, "minutes")
      .toDate();
  if (period.includes("hour"))
    return moment(now)
      .subtract(diff, "hours")
      .toDate();
  if (period.includes("day"))
    return moment(now)
      .subtract(diff, "days")
      .toDate();
}
export function getTime(date) {
  return new Date(date).getTime();
}

export const useClusterDetails = () => {
  return useQuery(["policyList"], getClusterDetail, {
    onSuccess: res => {},
    refetchOnWindowFocus: false
  });
};

export const uselistofClusterData = range => {
  const URL = "/cm/api/v1/cluster-management/get-all-clusters-in-workspace";
  const now = new Date();
  let from;
  let to;
  let payload;
  if (range === "All") {
    payload = {
      workspace_id: workspaceid,
      from_time: [],
      to_time: []
    };
  } else {
    if (typeof range === "string") {
      from = getDifferenceTime(range, now).getTime();
      to = now.getTime();
    } else if (typeof range === "object") {
      from = getTime(range.from);
      to = getTime(range.to);
    }
    payload = {
      workspace_id: workspaceid,
      from_time: from ? [from.toString()] : [],
      to_time: to ? [to.toString()] : []
    };
  }
  return useQuery(
    ["clusterslist", workspaceid],
    async () => {
      const response = await customPost(URL, {
        workspace_id: workspaceid,
        from_time: [],
        to_time: []
      });
      const res = await response.json();
      return res?.result;
    },
    {
      select: data => {
        return data?.map(item => ({
          ...item,
          name: item?.ClusterName,
          entity_type: CLUSTER,
          id: item?.ID
        }));
      },
      refetchOnWindowFocus: false
    }
  );
};
// export const entitylist = async res => {
//   const service = useClusterDetail();
//   const list = await res?.map(async data => {
//     // await getClusterDetail(data.ID).then(res => {
//     //   return res;
//     // });
//     const data1 = service.mutate(data.ID);
//   });

//   return list;
// };

export const useClustersideBarData = range => {
  const URL = "/cm/api/v1/cluster-management/clusters-in-workspace-filter";
  const now = new Date();
  let from;
  let to;
  let payload;
  if (range === "All") {
    payload = {
      workspace_id: workspaceid,
      from_time: [],
      to_time: []
    };
  } else {
    if (typeof range === "string") {
      from = getDifferenceTime(range, now).getTime();
      to = now.getTime();
    } else if (typeof range === "object") {
      from = getTime(range.from);
      to = getTime(range.to);
    }
    payload = {
      workspace_id: workspaceid,
      from_time: from ? [from.toString()] : [],
      to_time: to ? [to.toString()] : []
    };
  }
  return useQuery(
    ["clusterSideBar"],
    async () => {
      const response = await customPost(URL, {
        workspace_id: workspaceid,
        from_time: [],
        to_time: []
      });
      const res = await response.json();
      return res?.result;
    },
    {
      select: data => {
        return data?.map(item => ({
          ...item,
          clusterId: item?.ID,
          clusterName: item?.ClusterName
        }));
      },
      refetchOnWindowFocus: false
    }
  );
};
export const usePodSideBarData = range => {
  const URL = "/cm/api/v1/cluster-management/pods-in-node-filter";
  const now = new Date();
  let from;
  let to;
  let payload;
  if (range === "All") {
    payload = {
      workspace_id: workspaceid,
      from_time: [],
      to_time: [],
      Filter: {},
      cluster_id: [],
      namespace_id: [],
      node_id: []
    };
  } else {
    if (typeof range === "string") {
      from = getDifferenceTime(range, now).getTime();
      to = now.getTime();
    } else if (typeof range === "object") {
      from = getTime(range.from);
      to = getTime(range.to);
    }
    payload = {
      workspace_id: workspaceid,
      from_time: from ? [from.toString()] : [],
      to_time: to ? [to.toString()] : [],
      Filter: {},
      cluster_id: [],
      namespace_id: [],
      node_id: []
    };
  }
  return useQuery(
    ["podSideBar"],
    async () => {
      const response = await customPost(URL, {
        workspace_id: workspaceid,
        from_time: [],
        to_time: [],
        Filter: {},
        cluster_id: [],
        namespace_id: [],
        node_id: []
      });
      const res = await response.json();
      return res?.result;
    },
    {
      select: data => {
        return data?.map(item => ({
          ...item,
          podId: item?.ID,
          podName: item?.PodName
        }));
      },

      refetchOnWindowFocus: false
    }
  );
};
export const vmNavBar = () => {
  const URL = "/cm/api/v1/vm-management/vm-navbar";
  const payload = {
    from_time: [],
    to_time: [],
    workspace_id: workspaceid,
    query: ""
  };
  return useQuery(
    ["vmlist"],
    async () => {
      const response = await customPost(URL, {
        from_time: [],
        to_time: [],
        workspace_id: workspaceid,
        query: ""
      });
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data?.list_of_instance_group?.map(item => ({
          ...item,
          entity_type: VMGROUP,
          instances: item?.instances
            ? item?.instances?.map(i => ({
                ...i,
                entity_type: VMINSTANCE
              }))
            : []
        }));
      },
      refetchOnWindowFocus: false
    }
  );
};
export const useClusterDetail = () => {
  const mutation = useMutation(payload => {
    return getClusterDetail(payload);
  });
  return mutation;
};
export const uselistofPodData = (
  selectedView,
  clusterId,
  nodeId,
  range,
  useStorePosture,
  nameSpace = []
) => {
  const clusterIds = clusterId?.value ? [clusterId?.value] : [];
  const nodeIds = nodeId?.value ? [nodeId?.value] : [];
  const URL = "/cm/api/v1/cluster-management/get-pods";
  const now = new Date();
  let from;
  let to;
  let payload;
  if (range === "All") {
    payload = {
      workspace_id: workspaceid || getAccuknoxWorkspace(),
      cluster_id: clusterIds,
      node_id: nodeIds,
      namespace_id: [],
      from_time: [],
      to_time: []
    };
  } else {
    if (typeof range === "string") {
      from = getDifferenceTime(range, now).getTime();
      to = now.getTime();
    } else if (typeof range === "object") {
      from = getTime(range.from);
      to = getTime(range.to);
    }
    payload = {
      workspace_id: workspaceid || getAccuknoxWorkspace(),
      cluster_id: clusterIds,
      node_id: nodeIds,
      namespace_id: [],
      from_time: from ? [from.toString()] : [],
      to_time: to ? [to.toString()] : []
    };
  }
  return useQuery(
    [("podlist", clusterId, nodeId)],
    async () => {
      const response = await customPost(URL, {
        workspace_id: workspaceid || getAccuknoxWorkspace(),
        cluster_id: clusterIds,
        node_id: nodeIds,
        namespace_id: [],
        from_time: [],
        to_time: []
      });
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data?.map(item => ({
          ...item,
          name: item?.podname,
          entity_type: POD,
          id: item?.ID
        }));
      },
      refetchOnWindowFocus: false,
      enabled: [POD].includes(selectedView)
    }
  );
};
export const uselistofContainerData = (selectedView, clusterId, nodeId, podid, range) => {
  const clusterIds = clusterId?.value ? [clusterId?.value] : [];
  const nodeIds = nodeId?.value ? [nodeId?.value] : [];
  const podIds = podid?.value ? [podid?.value] : [];
  const URL = "/cm/api/v1/cluster-management/containers-in-pod";
  const now = new Date();
  let from;
  let to;
  let payload;
  if (range === "All") {
    payload = {
      workspace_id: workspaceid || getAccuknoxWorkspace(),
      cluster_id: clusterIds,
      node_id: nodeIds,
      pod_id: podIds,
      from_time: [],
      to_time: []
    };
  } else {
    if (typeof range === "string") {
      from = getDifferenceTime(range, now).getTime();
      to = now.getTime();
    } else if (typeof range === "object") {
      from = getTime(range.from);
      to = getTime(range.to);
    }
    payload = {
      workspace_id: workspaceid || getAccuknoxWorkspace(),
      cluster_id: clusterIds,
      node_id: nodeIds,
      pod_id: podIds,
      from_time: from ? [from.toString()] : [],
      to_time: to ? [to.toString()] : []
    };
  }
  return useQuery(
    ["containerList", workspaceid, clusterId, podid],
    async () => {
      const response = await customPost(URL, {
        workspace_id: workspaceid || getAccuknoxWorkspace(),
        cluster_id: clusterIds,
        node_id: nodeIds,
        pod_id: podIds,
        from_time: [],
        to_time: []
      });
      const res = await response.json();
      return res?.result;
    },
    {
      select: data => {
        return data?.map(item => ({
          ...item,
          name: item?.container_name,
          entity_type: CONTAINER,
          id: item?.ID
        }));
      },
      refetchOnWindowFocus: false,
      enabled: [CONTAINER].includes(selectedView)
    }
  );
};
export const uselistofNodeData = (selectedView, clusterId, range) => {
  const clusterIds = clusterId?.value ? [clusterId?.value] : [];
  const URL = "/cm/api/v1/cluster-management/nodes-in-cluster";
  const now = new Date();
  let from;
  let to;
  let payload;
  if (range === "All") {
    payload = {
      workspace_id: workspaceid || getAccuknoxWorkspace(),
      cluster_id: clusterIds,
      from_time: [],
      to_time: []
    };
  } else {
    if (typeof range === "string") {
      from = getDifferenceTime(range, now).getTime();
      to = now.getTime();
    } else if (typeof range === "object") {
      from = getTime(range.from);
      to = getTime(range.to);
    }
    payload = {
      workspace_id: workspaceid || getAccuknoxWorkspace(),
      cluster_id: clusterIds,
      from_time: from ? [from.toString()] : [],
      to_time: to ? [to.toString()] : []
    };
  }
  return useQuery(
    ["nodeList", clusterId],
    async () => {
      const response = await customPost(URL, {
        workspace_id: workspaceid || getAccuknoxWorkspace(),
        cluster_id: clusterIds,
        from_time: [],
        to_time: []
      });
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data?.result?.map(item => ({
          ...item,
          name: item?.NodeName,
          entity_type: NODE,
          id: item?.ID
        }));
      },
      refetchOnWindowFocus: false,
      enabled: [NODE].includes(selectedView)
    }
  );
};
export const useSearchData = searchQuery => {
  const URL = "/cm/api/v1/vm-management/search-navbar";
  const payload = { search_query: searchQuery, workspace_id: workspaceid };
  return useQuery(
    ["sidebarsearch", searchQuery && searchQuery?.length > 3],
    async () => {
      const response = await customPost(URL, payload);
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false
    }
  );
};
export const useNameSpaceDetail = nameSpaceId => {
  const URL = `/cm/api/v1/cluster-management/namespace/${nameSpaceId}`;
  const payload = { namespace_id: nameSpaceId };
  return useQuery(
    ["namespaceDetail", nameSpaceId],
    async () => {
      const response = await customGet(URL);
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false
    }
  );
};
