import { Box, Collapse, IconButton, Skeleton, styled, Tooltip, Typography } from "@mui/material";
import {
  Button,
  ContentContainer,
  Icon,
  Input,
  Modal,
  Select,
  Textarea,
  Title
} from "components/simple";
import Loader from "components/simple/Loader";
import ButtonLink from "components/simple/ButtonLink";
import SelectorLabel from "components/simple/SelectorLabel";
import YamlEditor from "components/simple/YamlEditor";
import React, { useState, useEffect } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import styles from "./styles.module.scss";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import { useLocation } from "react-router-dom";
import { useGetPolicyDetailsQuery } from "store/entities/policies/queries";
import { navigate } from "helper/history";
import { routes } from "router";
import { useDispatch } from "react-redux";
import { addNotification } from "store/entities/notifications/actions";
import {
  useEditPolicyMutation,
  useGetIsPolicyPushedMutation
} from "store/entities/policies/mutations";
import { useQueryClient } from "react-query";
import { useGetSelectorLabel } from "store/entities/filters/queries";
import { downloadFile, formatDate, formatTimestampToLocalDate, useInterval } from "helper/helpers";

const Banner = ({
  variant = "warning",
  children,
  containerClass,
  closeable = false,
  toggle = () => {}
}) => {
  const StyledBox = styled(props => <Box {...props} />)(() => {
    const getBorderColor = () => {
      if (variant === "warning") {
        return "#d0b155";
      }
    };

    const getBgColor = () => {
      if (variant === "warning") {
        return "#fff6e2";
      }
    };
    return {
      borderLeft: `8px solid ${getBorderColor()}`,
      background: getBgColor(),
      padding: "1rem",
      margin: "1rem 0",
      borderRadius: "8px",
      fontSize: "14px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .bannerToggle": {
        cursor: "pointer"
      }
    };
  });
  return (
    <StyledBox>
      <div className={` ${containerClass}`}>{children}</div>
      {closeable && (
        <div className="bannerToggle" onClick={toggle}>
          <Icon.Close className={styles.close} />
        </div>
      )}
    </StyledBox>
  );
};

const PolicyStatusBadge = ({ status }) => {
  const getBgColorForBadge = () => {
    if (status === "Active") {
      return "#1cac6e";
    }
    if (status === "Inactive") {
      return "#f55353";
    }
    return "#fdc77c";
  };
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "16px",
        alignItems: "center",
        marginLeft: "10px",
        background: getBgColorForBadge(),
        padding: "4px 10px",
        fontSize: "14px",
        "& .statusIcon > svg": {
          fill: `${status === "Pending" ? "#E4921E" : "#fff"}`,
          margin: "4px 4px 0 0",
          width: "20px"
        },
        "& .statusText": {
          color: `${status === "Pending" ? "#000" : "#fff"}`
        }
      }}
    >
      <span className="statusIcon">
        {status === "Active" && <CheckCircleIcon />}
        {status === "Pending" && <ErrorIcon />}
        {status === "Inactive" && <CancelIcon />}
      </span>
      <span className="statusText">{status}</span>
    </Box>
  );
};
/**
 *
 * 1. Pending Polict Banner
 * 2. Policy details
 * 3. Policy details edit
 * 4. Policy YAML info with edit
 */
const PolicyDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const policy_id = location.state?.policy_id;
  const cluster = location.state?.cluster;
  const namespace = location.state?.namespace;
  const row = location.state?.row;

  const [isEditing, setIsEditing] = useState(false);
  const [toggleYAMLEdit, setToggleYAMLEdit] = useState(false);
  const [showCommitMessageModal, setShowCommitMessageModal] = useState(false);

  const { data: policyDetails, isLoading } = useGetPolicyDetailsQuery(policy_id);

  const { data: selectorLabel } = useGetSelectorLabel(
    [cluster?.id],
    [namespace?.id],
    policyDetails?.label_type
  );

  const editPolicy = useEditPolicyMutation(
    policy_id,
    policyDetails?.label_type,
    () => {
      queryClient.invalidateQueries(["getPolicyDetails"]);
      setIsEditing(false);
      dispatch(addNotification({ msg: "Policy updated", type: "success" }));
      setShowCommitMessageModal(false);
      setToggleYAMLEdit(false);
    },
    () => {
      dispatch(addNotification({ msg: "Update policy failed", type: "error" }));
    }
  );

  useEffect(() => {
    if (!policy_id) {
      navigate(routes.allPolicies);
      dispatch(addNotification({ msg: "Policy doesn't exist", type: "error" }));
    }
  }, []);

  const handleDownloadPolicy = e => {
    e.preventDefault();
    const policyName = policyDetails?.policy_name;
    downloadFile(
      policyDetails?.policy_yaml,
      policyName ? `${policyName}.yaml` : "policy.yaml",
      "application/x-yaml"
    );
  };

  const toPolicyHistory = () => {
    navigate(routes.policyHistory, { selectedPolicy: row });
  };
  const [showBanner, setShowBanner] = useState(false);

  const [editedValues, setEditedValues] = useState({});

  const handleChange = (value, name) => {
    setEditedValues({ ...editedValues, [name]: value });
  };

  const toggleEditMode = () => {
    setIsEditing(prev => !prev);
  };

  const POLICY_STATUS_FETCH_FREQ = 5000; // ms
  const [approvePolicyPermission] = useState(true);

  // const { mutate: fetchPolicyStatus, data: isPolicyPushed } = useGetIsPolicyPushedMutation(
  //   policy_id
  // );

  const resetPolicyDetails = () => {
    setEditedValues({
      description: policyDetails?.description,
      labels: policyDetails?.labels?.map(i => ({
        label: `${i.name}=${i.value}`,
        value: i.id
      })),
      policy_yaml: policyDetails?.policy_yaml
    });
  };

  useEffect(() => {
    resetPolicyDetails();
  }, [policyDetails]);

  // useInterval(() => {
  //   if (approvePolicyPermission && !isPolicyPushed?.result) {
  //     fetchPolicyStatus();
  //   }
  // }, [POLICY_STATUS_FETCH_FREQ]);

  const onSubmit = () => {
    editPolicy.mutate({
      description: editedValues?.description,
      label_id: editedValues?.labels?.map(l => l.value),
      policy_yaml: editedValues?.policy_yaml,
      comment: editedValues?.commit
    });
  };

  const onCancel = () => {
    setIsEditing(false);
    setShowCommitMessageModal(false);
    resetPolicyDetails();
  };

  const [hasPolicyMetaDataChanged, setHasPolicyMetaDataChanged] = useState(false);

  useEffect(() => {
    setHasPolicyMetaDataChanged(
      JSON.stringify(editedValues?.labels) === JSON.stringify(policyDetails?.labels) ||
        editedValues?.description?.trim() === policyDetails?.description?.trim()
    );
  }, [editedValues]);

  return (
    <>
      {/* {isPolicyPushed?.result && ( */}
      {(
        <Banner
          containerClass={styles.banner}
          // closeable
          toggle={() => setShowBanner(prev => !prev)}
          variant="warning"
        >
          This policy has been requested for approval and is pending review
          <div className="ml-2">
            <ButtonLink
              buttonLable="View Pending Policies"
              onClick={() => {
                toPolicyHistory();
              }}
            />
          </div>
        </Banner>
      )}

      <Modal isOpen={showCommitMessageModal} toggle={() => setShowCommitMessageModal(false)}>
        <div style={{ borderBottom: "1px solid rgba(0,0,0,0.4)" }}>
          <Typography variant="body2" fontWeight={700} sx={{ marginBottom: "10px" }}>
            Commit Message
          </Typography>
        </div>
        <div className="mt-8">
          <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
            Write commit message (optional)
          </Typography>

          <Textarea
            value={editedValues?.commit || ""}
            onChange={val => handleChange(val, "commit")}
            placeholder="Leave a commit message here..."
          />
        </div>
        <div className="flex justify-end items-center w-full mt-4 gap-4">
          <Button
            variant="outline"
            className={styles.cancelBtn}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button onClick={() => onSubmit()} isLoading={editPolicy?.isLoading}>
            Submit
          </Button>
        </div>
      </Modal>
      <ContentContainer className={styles.container}>
        <Title>Policy Details</Title>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <section className={styles["policy-metadata"]}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">{policyDetails?.policy_name}&nbsp;</Typography>
                <ButtonLink
                  fontsize="14px"
                  onClick={toPolicyHistory}
                  buttonLable={`(Version ${policyDetails?.policy_version})`}
                />
                <PolicyStatusBadge status={policyDetails?.status} />
                <Tooltip
                  placement="top"
                  // title={isPolicyPushed?.result ? "Edit" : "Policy not yet pushed"}
                  title={"Edit"}
                >
                  <span>
                    <IconButton
                      // disabled={!isPolicyPushed?.result}
                      disableRipple
                      onClick={toggleEditMode}
                    >
                      <BorderColorIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0.5rem 0",
                  width: "100%",
                  "& strong": {
                    fontWeight: "500"
                  }
                }}
              >
                <div className="w-4/12 flex items-center">
                  <div className="mr-4">
                    <strong>Policy Type:</strong>
                  </div>
                  <Typography variant="body2">{policyDetails?.policy_type}</Typography>
                </div>

                <div className="w-4/12 flex items-center">
                  <div className="mr-4">
                    <strong>Cluster:</strong>
                  </div>
                  <Typography variant="body2">{policyDetails?.cluster}</Typography>
                </div>

                <div className="w-4/12 flex items-center">
                  <div className="mr-4">
                    <strong>Namespace:</strong>
                  </div>
                  <Typography variant="body2">{policyDetails?.namespace}</Typography>
                </div>

                <div className="w-4/12 flex items-center">
                  <div className="mr-4">
                    <strong>Timestamp:</strong>
                  </div>
                  <Typography variant="body2">
                    {formatDate(
                      policyDetails?.timestamp,
                      "YYYY-MM-DDTHH:mm:ssZ",
                      "MM-DD-YYYY hh:mm:ss"
                    )}
                  </Typography>
                </div>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  padding: "0.5rem 0",
                  "& strong": {
                    fontWeight: "500"
                  },
                  width: "100%"
                }}
              >
                <div className="w-9/12 flex items-ccenter">
                  <div className="mr-4">
                    <Typography variant="body2">
                      <strong>Description:</strong>
                    </Typography>
                  </div>
                  {isEditing ? (
                    <>
                      <Input
                        type="text"
                        className={styles["input-field"]}
                        placeholder="Policy Description"
                        value={editedValues?.description || ""}
                        onChange={e => handleChange(e.target.value, "description")}
                      />{" "}
                    </>
                  ) : (
                    <Typography variant="body2">{policyDetails?.description}</Typography>
                  )}
                </div>

                <div className="w-3/12 flex items-ccenter">
                  <div className="mr-4">
                    <Typography variant="body2">
                      <strong>Label Type:</strong>
                    </Typography>
                  </div>
                  <Typography variant="body2">{policyDetails?.label_type}</Typography>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "0.25rem",
                  padding: "0.5rem 0",
                  fontSize: "14px",
                  "& strong": {
                    fontWeight: "500"
                  }
                }}
              >
                <div className="w-1/12">
                  <strong>Labels:</strong>
                </div>

                {policyDetails?.labels?.length > 0 &&
                  (isEditing ? (
                    <>
                      <Select
                        containerClass={styles.select}
                        value={editedValues.labels}
                        isMulti
                        onChange={val => handleChange(val, "labels")}
                        options={selectorLabel?.options} // TODO: Get Labels here
                      />
                    </>
                  ) : (
                    <SelectorLabel
                      labels={
                        policyDetails?.labels?.map(l => ({
                          color: l.color,
                          label: `${l.name}=${l.value}`
                        })) || []
                      }
                      limit={20}
                    />
                  ))}

                {(policyDetails?.labels?.length === 0 || !policyDetails?.labels) && "None"}
              </Box>
              {isEditing && (
                <Box sx={{ display: "flex" }}>
                  <Button className="ml-auto mr-4" variant="outline" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    disabled={hasPolicyMetaDataChanged}
                    isLoading={editPolicy?.isLoading}
                    onClick={() => setShowCommitMessageModal(true)}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </section>
            <section className={styles["policy-yaml"]}>
              <div className={styles.editor_action_group}>
                {toggleYAMLEdit && (
                  <>
                    <IconButton
                      className="ml-auto"
                      onClick={() => {
                        resetPolicyDetails();
                        setToggleYAMLEdit(false);
                      }}
                      disableRipple
                    >
                      <CancelIcon fontSize="small" /> Cancel
                    </IconButton>
                    <Tooltip
                      placement="top"
                      title={
                        editedValues?.policy_yaml.trim() === policyDetails?.policy_yaml.trim()
                          ? "No changes to the policy YAML"
                          : "Save"
                      }
                    >
                      <span>
                        <IconButton
                          disabled={
                            editedValues?.policy_yaml.trim() === policyDetails?.policy_yaml.trim()
                          }
                          onClick={() => setShowCommitMessageModal(true)}
                          disableRipple
                        >
                          <SaveIcon fontSize="small" /> Save
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}

                {!toggleYAMLEdit && (
                  <Tooltip
                    placement="top"
                    // title={isPolicyPushed?.result ? "Edit YAML" : "Policy not yet pushed"}
                    title={"Edit YAML"}
                  >
                    <span className="ml-auto">
                      <IconButton
                        // disabled={!isPolicyPushed?.result}
                        onClick={() => setToggleYAMLEdit(true)}
                        disableRipple
                      >
                        <EditIcon fontSize="small" /> Edit
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

                <IconButton onClick={handleDownloadPolicy} disabled={toggleYAMLEdit} disableRipple>
                  <DownloadIcon fontSize="small" /> Download
                </IconButton>
              </div>
              <YamlEditor
                setCode={e => handleChange(e, "policy_yaml")}
                code={editedValues?.policy_yaml}
                editable={toggleYAMLEdit}
              />
            </section>
          </>
        )}
      </ContentContainer>
    </>
  );
};

export default PolicyDetails;
