import { Popover } from "@mui/material";
import React from "react";

export default function PopOver({ popoverContent, id, open, anchorEl, onClose }) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
    >
      {popoverContent}
    </Popover>
  );
}
