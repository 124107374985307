import React from "react";

export default function Dashboard2(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 17"
      fill="none"
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 7.75H6.25V0.25H0.25V7.75ZM0.25 13.75H6.25V9.25H0.25V13.75ZM7.75 13.75H13.75V6.25H7.75V13.75ZM7.75 0.25V4.75H13.75V0.25H7.75Z"
        fill={props.color}
      />
    </svg>
  );
}
