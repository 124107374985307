/* eslint-disable camelcase */
import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getDataListConfigs,
  deleteDataListConfigs,
  getDataLists,
  setPanelState
} from "store/entities/datalist/actions";

import {
  datalistConfigsSelector,
  datalistConfigsFiltersDataSelector
} from "store/entities/datalist/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";

import { ActionButton, Button, Select, Title } from "components/simple";
import Loader from "components/simple/Loader";

import styles from "./styles.module.scss";
import PanelCreateConfig from "./PanelCreateConfig";
import DynamicSelect from "./DynamicSelect";

const Panel = ({
  selectConfig,
  temporaryData,
  setSelectConfig,
  selectedFilterFields,
  onChangeSelectedFilterFields,
  onLoadData,
  onClearTemporaryData
}) => {
  const [collapse, setCollapse] = useState(false);
  const [editData, setEditData] = useState(null);

  const filter_fields = temporaryData?.filter_fields || [];
  const temporaryEditData = temporaryData || null;
  const dispatch = useDispatch();
  const selectTenant = useSelector(state => state.users.selectTenant);

  const datalistConfigs = useSelector(datalistConfigsSelector);
  const { dictList, display_fields_list } = useSelector(datalistConfigsFiltersDataSelector);

  const isLoading = useSelector(state => createLoadingSelector([getDataLists.type])(state));

  useEffect(() => {
    dispatch(getDataListConfigs());
  }, [selectTenant]);

  useEffect(() => {
    if (temporaryEditData) {
      const display_fields =
        temporaryEditData?.display_fields
          ?.map(item => {
            return display_fields_list?.find(t => t.value === item);
          })
          .filter(v => v) ?? [];
      dispatch(
        setPanelState({
          display_fields,
          display_fields_width: temporaryEditData?.display_fields_width ?? []
        })
      );
    }
  }, [temporaryEditData, display_fields_list]);

  // clear data temporary data
  const clearPanelCreateConfig = () => {
    onClearTemporaryData();
    setEditData(null);
    dispatch(
      setPanelState({
        name: "",
        target: "",
        asset: "",
        data_type: "",
        filter_fields: [],
        display_fields: []
      })
    );
  };

  // Button Close and clear all data
  const onClose = () => {
    setCollapse(false);
    clearPanelCreateConfig();
  };

  //  create new config
  const onAdd = () => {
    setCollapse(true);
    clearPanelCreateConfig();
  };

  // collapse panel
  const onCollapse = v => {
    setCollapse(v);
    setEditData(null);
  };

  // load  Temporary data on front side
  const onLoadTemporaryData = r => {
    setCollapse(false);
    onLoadData(r);
    setEditData(null);
  };

  // Select Config
  const onChangeSelectConfig = v => {
    setSelectConfig(v);
  };

  // Edit
  const onEdit = () => {
    setCollapse(true);
    setEditData(temporaryEditData);
  };

  // Delete Config
  const onDelete = () => {
    const id = selectConfig?.value;
    dispatch(deleteDataListConfigs({ id }));
    onClose();
  };

  return (
    <div className={styles.panel}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <div className={styles.panelHead}>
        <Title>Explorer</Title>
        <div className={styles.panelRow}>
          {!collapse ? (
            <>
              {selectConfig ? (
                <div className={styles.selectConfigText}>
                  Config: <span>{selectConfig.label}</span>
                </div>
              ) : null}
              <Select
                containerClass={styles.select}
                onChange={onChangeSelectConfig}
                value={selectConfig}
                placeholder="Select Config"
                options={datalistConfigs}
                isClearable
              />
              <ActionButton type="add" onClick={() => onAdd()} className={styles.actionButton} />
            </>
          ) : null}
        </div>
      </div>

      <PanelCreateConfig
        editData={editData}
        id={selectConfig?.value}
        onLoadData={onLoadTemporaryData}
        onCollapse={onCollapse}
        collapse={collapse}
        onClose={onClose}
      />

      {temporaryEditData && !editData && !collapse ? (
        <div className={styles.panelConfig}>
          <div className={styles.panelGrid}>
            {filter_fields?.map((item, index) => {
              return (
                <DynamicSelect
                  key={index}
                  item={item}
                  dictList={dictList}
                  params={temporaryData}
                  selectedFilterField={selectedFilterFields?.[item] || ""}
                  onChange={onChangeSelectedFilterFields}
                />
              );
            })}
          </div>
          <div className={styles.buttonGroup}>
            <Button className={styles.button} onClick={() => onEdit()}>
              Edit
            </Button>
            <Button
              className={`${styles.button} ${styles.right} ${styles.close}`}
              variant="outline"
              onClick={() => onClose()}
            >
              Close
            </Button>
            {selectConfig ? (
              <Button className={`${styles.button} `} onClick={() => onDelete()}>
                Delete
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

Panel.propTypes = {};

export default memo(Panel);
