import React from "react";
import Loader from "components/simple/Loader";
import { getAccuknoxWorkspace } from "api/api";
import { ContentContainer } from "components/simple";
import { useFetchTriggers } from "store/entities/datapipeline/queries";
import CollapsibleTable from "../components/TriggerTable";
import { useDatapipelineState } from "store/entities/datapipeline/slice";

const Triggers = () => {
  const workspaceVal = getAccuknoxWorkspace();
  const pageId = useDatapipelineState("pageId");
  const pageSize = useDatapipelineState("pageSize");
  const { data: triggersData, isFetching } = useFetchTriggers(
    data => {
      return data;
    },
    workspaceVal,
    pageId,
    pageSize
  );
  return (
    <ContentContainer>
      <div className="flex p-5">
        <div className={isFetching ? "w-full h-screen" : "w-full"}>
          {isFetching ? (
            <div className="flex justify-center items-center h-screen">
              <Loader />
            </div>
          ) : (
            <CollapsibleTable
              rows={triggersData?.response?.Triggers}
              count={triggersData?.response?.TotalCount}
            />
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default Triggers;
