import React from "react";
import PropTypes from "prop-types";

export default function ClusterIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 17 17"
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8645 0C11.1007 0 10.3795 0.17085 9.72778 0.469626C10.9363 1.65878 11.6875 3.31054 11.6875 5.13549C11.6875 6.96023 10.9363 8.61199 9.72778 9.80114C10.3793 10.0999 11.1007 10.2708 11.8645 10.2708C14.7007 10.2708 17 7.97151 17 5.13549C17 2.29925 14.7007 0 11.8645 0ZM7.27154 7.19886C6.06369 8.38801 5.3125 10.0398 5.3125 11.8645C5.3125 13.6895 6.06369 15.3412 7.27154 16.5304C6.62044 16.8292 5.89921 17 5.13549 17C2.29925 17 0 14.7008 0 11.8645C0 9.02849 2.29925 6.72924 5.13549 6.72924C5.89921 6.72924 6.62065 6.90009 7.27154 7.19886ZM7.19885 9.72783C8.388 10.9363 10.0398 11.6875 11.8645 11.6875C13.6895 11.6875 15.3412 10.9363 16.5304 9.72783C16.8291 10.3796 17 11.1008 17 11.8645C17 14.7008 14.7007 17 11.8645 17C9.02848 17 6.72923 14.7008 6.72923 11.8645C6.72923 11.1008 6.90008 10.3794 7.19885 9.72783ZM9.80115 7.27154C8.612 6.06369 6.96023 5.3125 5.1355 5.3125C3.31055 5.3125 1.65878 6.06369 0.469633 7.27154C0.170858 6.62044 7.62939e-06 5.89921 7.62939e-06 5.13549C7.62939e-06 2.29925 2.29926 0 5.1355 0C7.97152 0 10.2708 2.29925 10.2708 5.13549C10.2708 5.89921 10.0999 6.62065 9.80115 7.27154Z"
        fill={props.color}
      />
    </svg>
  );
}
