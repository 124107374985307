import { IconButton, Menu, MenuItem, styled } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";

const StyledMenuItem = styled(props => <MenuItem {...props} />)(() => ({
  display: "flex",
  fontSize: "14px",
  alignItems: "center",
  "& svg, path": {
    fill: "#B5B7B9",
    marginRight: "16px",
    width: "24px"
  }
}));

const StyledActionMenu = styled(props => <Menu {...props} />)(({ theme }) => ({
  "& .MuiList-root": {
    padding: 0
  },
  "& .menu-item-text": {
    fontWeight: "normal"
  },
  "& .MuiMenuItem-root": {
    padding: "12px 16px"
  }
}));

const ActionMenu = ({ menuItems, onClick = () => {} }) => {
  const [elRef, setElRef] = useState(null);
  const open = Boolean(elRef);

  const handleIconClick = e => {
    e.stopPropagation();
    setElRef(e.currentTarget);
  };

  const handleMenuClose = () => {
    setElRef(null);
  };

  return (
    <>
      <IconButton onClick={handleIconClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledActionMenu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        onClose={handleMenuClose}
        anchorEl={elRef}
      >
        {menuItems.length ? (
          menuItems.map((menuItem, menuItemIndex) => (
            <StyledMenuItem
              onClick={() => {
                menuItem.onClick();
                onClick();
                handleMenuClose(); // close the menu once clicked
              }}
              disableRipple
              key={menuItemIndex}
            >
              {menuItem.icon}
              <span className="menu-item-text">{menuItem.name}</span>
            </StyledMenuItem>
          ))
        ) : (
          <></>
        )}
      </StyledActionMenu>
    </>
  );
};

ActionMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      name: PropTypes.string,
      onClick: PropTypes.func
    })
  )
};

ActionMenu.defaultProps = {
  menuItems: []
};

export default ActionMenu;
