export const errorHandler = errors => {
  if (typeof errors === "object" && errors?.toString().includes("TypeError: Failed to fetch")) {
    return "Server under maintenance";
  }
  if (typeof errors === "object" && errors !== null) {
    return Object.values(errors).join(" ");
  }
  if (typeof errors === "string" && errors !== null) {
    return errors;
  }
  return "Error";
};
