import { Box, Switch, Checkbox, Link } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Table } from "components/complex";
import styles from "./styles.module.scss";
import React from "react";
import ActionMenu from "../ActionMenu";
import SeverityCheckCirlces from "./SeverityCheckCirlces";
import ContentContainer from "../ContentContainer";

const ExpandableCell = ({ value }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Box
      sx={{
        fontWeight: "500",
        marginTop: "18px",
        ...(expanded ? { overflow: "auto", marginBottom: "12px" } : { overflow: "none" })
      }}
    >
      {value.length > 1 && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          type="button"
          component="button"
          sx={{ fontSize: "inherit" }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "Hide" : "Details"}
        </Link>
      )}
      <br />
      <Box sx={{ marginTop: "20px", ...(expanded ? { width: 300 } : { width: 150 }) }}>
        {expanded ? "Query Resource:/usr/bin/rm -rf a.txt" : ""}&nbsp;
      </Box>
    </Box>
  );
};

function ToggleDetails() {
  const actionMenuItems = [
    { name: "Edit", icon: <EditIcon />, onClick: () => {} },
    { name: "Delete", icon: <DeleteIcon />, onClick: () => {} }
  ];

  const rows = [
    {
      id: 1,
      alertTrigger: "Podname",
      createdAt: "11-24-22 15:57:57 IST",
      channel: "Jira",
      severity: "High"
    },
    {
      id: 2,
      alertTrigger: "Podname",
      createdAt: "11-24-22 15:57:57 IST",
      channel: "Jira",
      severity: "High"
    },
    {
      id: 3,
      alertTrigger: "Podname",
      createdAt: "11-24-22 15:57:57 IST",
      channel: "Jira",
      severity: "Medium"
    },
    {
      id: 4,
      alertTrigger: "Podname",
      createdAt: "11-24-22 15:57:57 IST",
      channel: "Jira",
      severity: "High"
    },
    {
      id: 5,
      alertTrigger: "Podname",
      createdAt: "11-24-22 15:57:57 IST",
      channel: "Jira",
      severity: "High"
    },
    {
      id: 6,
      alertTrigger: "Podname",
      createdAt: "11-24-22 15:57:57 IST",
      channel: "Jira",
      severity: "High"
    },
    {
      id: 7,
      alertTrigger: "Podname",
      createdAt: "11-24-22 15:57:57 IST",
      channel: "Slack",
      severity: "Low"
    }
  ];
  const columns = [
    {
      field: "",
      headerName: "",
      minWidth: 60,
      maxWidth: 60,
      renderCell: params => <Checkbox />
    },
    {
      field: "severity",
      headerName: "",
      minWidth: 60,
      resizable: false,
      renderCell: params => <SeverityCheckCirlces row={params.row.severity.toLowerCase()} />
    },
    {
      field: "alertTrigger",
      headerName: "Alert Trigger",
      minWidth: 150
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      minWidth: 210
    },
    {
      field: "channel",
      headerName: "Channel",
      minWidth: 140
    },
    {
      field: "enableDisable",
      headerName: "Enable/Disable",
      minWidth: 180,
      renderCell: params => <Switch />
    },
    {
      field: "details",
      headerName: "",
      minWidth: 320,
      renderCell: params => <ExpandableCell value={params.row.severity} />
    },
    {
      field: "menu",
      headerName: "",
      width: 10,
      renderCell: params => <ActionMenu menuItems={actionMenuItems} />
    }
  ];

  const [page, pageSize] = [0, 10];
  return (
    <ContentContainer className={styles.container}>
      <section>
        <Table
          columns={columns}
          rows={rows}
          checkboxVisible={false}
          rowThreshold={0}
          getEstimatedRowHeight={() => 100}
          getRowHeight={() => "auto"}
          page={page || 0}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </section>
    </ContentContainer>
  );
}

export default ToggleDetails;
