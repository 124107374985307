import React from "react";
import styled from "styled-components";

const DropItem = props => {
  if (props?.showNoData) {
    return <p>No data to show!</p>;
  }
  if (props.value === "OR" || props.value === ">" || props.value === "<" || props.value === "=") {
    return (
      <Main
        onClick={() => {
          props.onTap(props.value, props.param);
        }}
      >
        {props.value}
      </Main>
    );
  }
  return (
    <Main
      ref={props?.innerRef}
      onClick={() => {
        props.onTap(props.value, props.param);
      }}
    >
      {props.value}
    </Main>
  );
};

export default DropItem;

const Main = styled.div`
  background: #fff;
  padding: 8px 8px;
  cursor: pointer;
  &:hover {
    background: #e4e4e4;
    color: #000;
  }
`;
