export const toolTipWrapper = {
  maxHeight: "150px",
  opacity: "0.9",
  width: "auto",
  overflowY: "auto",
  overflowX: "hidden auto",
  pointerEvents: "auto",
  background: "#ffffff",
  borderRadius: "20px",
  border: "none"
};
export const legendWrapper = {
  position: "absolute",
  width: "auto",
  height: "60px",
  top: "81%",
  left: "35px",
  right: "0px",
  WebkitTextFillColor: "rgb(0, 0, 0)",
  display: "flex",
  overflow: "hidden auto"
};
