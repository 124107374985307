import React, { useState, useEffect } from "react";
import { Button, Modal, Select } from "components/simple";
import { useSelector, useDispatch } from "react-redux";

import { updateStatusVulnerability } from "store/entities/vulnerabilities/actions";

import { getStatusesOptionsSelector } from "store/entities/vulnerabilities/selectors";

import styles from "./styles.module.scss";

const EditStatusModal = ({ isOpen, onClose, data }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const statusesOptions = useSelector(getStatusesOptionsSelector);

  const successSaveCallback = () => {
    onClose();
  };

  const onSave = () => {
    dispatch(
      updateStatusVulnerability({
        data: { finding_ids: data, status: value?.value },
        callback: successSaveCallback
      })
    );
  };

  useEffect(() => {
    if (!isOpen) {
      setValue("");
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <h3 className={styles.title}>Edit Status</h3>
      <Select
        label="Select Status"
        value={value}
        options={statusesOptions}
        onChange={val => setValue(val)}
      />
      <div className={styles.btnsContainer}>
        <Button variant="outline" onClick={onClose} className={styles.btn}>
          Close
        </Button>
        <Button onClick={onSave} className={styles.btn} disabled={!value}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default EditStatusModal;
