import React from "react";

export default function Issues(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 14.75H17.25L9 0.5L0.75 14.75ZM9.75 12.5H8.25V11H9.75V12.5ZM9.75 9.5H8.25V6.5H9.75V9.5Z"
        fill={props.color}
      />
    </svg>
  );
}
