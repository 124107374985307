export const updatePageIdReducer = (state, action) => {
  state.pageId = action.payload;
};
export const updateRowsPerpageReducer = (state, action) => {
  state.pageSize = action.payload;
};
export const updateSelectedFilterReducer = (state, action) => {
  state.selectedFilter = action.payload;
};
export const updateSelectedOperatorReducer = (state, action) => {
  state.selectedOperator = action.payload;
};
export const updatedSelectedKeyTypeReducer = (state, action) => {
  state.selectedKeyType = action.payload;
};
