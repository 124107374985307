import React, { memo, useEffect, useState } from "react";
import { ActionButton, Button, Icon, Input, Title } from "components/simple";

import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getTagsMini } from "store/entities/tags/actions";
import { tagsMiniSelector } from "store/entities/tags/selectors";
import { Box, Tabs, Tab } from "@mui/material";
import SoarcastParams from "./SoarcastParams";
import {
  actionCreateTokenModal,
  deletePlaybookConfig,
  getApiTokenLists,
  getMinions,
  getPlaybookConfigs,
  getPlaybooks,
  getVariablesMini
} from "store/entities/targets/actions";
import { getLabelsMini } from "store/entities/labels/actions";
import { useParams } from "react-router-dom";
import { getApiTokenSelectors, getPlaybookConfigsSelector } from "store/entities/targets/selectors";

const tabStyle = {
  fontSize: "14px",
  fontFamily: "PoppinsMedium",
  textTransform: "capitalize"
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Soarcast = ({ handleCreateToken, handleDeleteToken }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const tagsList = useSelector(tagsMiniSelector);
  const { tags, configs } = useSelector(getPlaybookConfigsSelector);
  const tokenList = useSelector(getApiTokenSelectors);
  const selectTenant = useSelector(state => state.users.selectTenant);
  const { showCreateBtn } = useSelector(state => state.targets.createTokenModal);

  const [tagTokenName, setTagTokenName] = useState("");
  const [activeTag, setActiveTag] = useState("");
  const [tagsSelected, setTagsSelected] = useState([]);
  const [activeTab, setActiveTab] = useState(false);
  const [tagOptionsOpened, setTagOptionsOpened] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [tagsParams, setTagParams] = useState({});
  const suggestionsList =
    tagsList.filter(item => !tagsSelected?.some(it => it.value.toString() === item?.value)) ?? [];

  const handleAddTag = value => {
    setTagOptionsOpened(false);
    setTagsSelected([...tagsSelected, value]);
    setTagParams({ ...tagsParams, [value.value]: { schedule: {} } });
  };

  const handleTagChoose = (e, newValue) => {
    const tagId = tagsSelected[newValue].value;

    if (editMode) {
      setTagsSelected(state => state.filter((item, ind) => ind !== newValue));
      setActiveTab(false);
      setEditMode(false);

      if (tagsParams[tagId].configId) {
        dispatch(deletePlaybookConfig(tagsParams[tagId].configId));
      }

      return;
    }

    setActiveTag(tagId);
    setActiveTab(newValue);
  };

  const handleEditClick = () => {
    setActiveTab(false);
    setEditMode(!editMode);
  };

  useEffect(() => {
    if (tags?.length) setTagsSelected([...tags]);
    setTagParams(configs);
  }, [configs, tags]);

  useEffect(() => {
    let filter = tokenList?.find(e => e.tag === activeTag);
    setTagTokenName(filter?.name);
  }, [tokenList, activeTag]);

  useEffect(() => {
    dispatch(getTagsMini());
    dispatch(getPlaybooks());
    dispatch(getMinions());
    dispatch(getLabelsMini());
    dispatch(getVariablesMini());
    dispatch(getPlaybookConfigs({ target_id: id }));
  }, []);

  return (
    <div>
      <Title className={styles.title}>Playbooks</Title>
      <section className={styles.soarcastSection}>
        <div className={styles.sectionTitle}>Tags</div>
        <div className={styles.tagsController}>
          {tagsSelected.length > 0 && (
            <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "7px" }}>
              <Tabs
                value={activeTab}
                onChange={handleTagChoose}
                aria-label="scrollable auto tabs example"
              >
                {tagsSelected.map((tag, index) => (
                  <Tab
                    sx={{ ...tabStyle }}
                    className={`${editMode ? styles.editMode : null}`}
                    key={tag.value}
                    label={tag.label}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </Box>
          )}
          <div
            onClick={() => setTagOptionsOpened(!tagOptionsOpened)}
            //  aria-disabled={null}
            className={styles.addBtn}
          >
            <Icon.Plus color="#1E3687" />
          </div>
          {tagsSelected.length > 0 && (
            <div className={styles.editIcon} onClick={handleEditClick}>
              <Icon.Edit color="#CDD2E0" />
            </div>
          )}
        </div>
        {tagOptionsOpened && (
          <div className={`${styles.optionsContainer}`}>
            {suggestionsList.length > 0 ? (
              suggestionsList.map(tag => (
                <div key={tag.value} onClick={() => handleAddTag(tag)} className={`${styles.tag}`}>
                  {tag.label}
                </div>
              ))
            ) : (
              <div className={styles.noOptions}>No Options</div>
            )}
          </div>
        )}
        {activeTab !== null && (
          <>
            {tagsSelected.map((tag, index) => (
              <TabPanel key={tag.value} value={activeTab} index={index}>
                <div className="relative">
                  <SoarcastParams
                    setTagParams={setTagParams}
                    tagId={tag.value}
                    tagParams={tagsParams?.[tag.value]}
                  />
                  {!tagTokenName ? (
                    <div className={styles.tokenContainer}>
                      <Button onClick={() => handleCreateToken({ tag: tag.value })}>
                        Create Token +
                      </Button>
                    </div>
                  ) : (
                    <div className={styles.tokenContainer}>
                      <div className="flex items-end justify-center gap-3 my-2">
                        {/* <Input
                          containerClassName={styles.dynControl}
                          label="Token"
                          value={tagTokenName}
                        /> */}
                        <Box className="flex p-2 border border-blue-500 border-solid rounded">
                          Token : {tagTokenName}
                        </Box>
                        <ActionButton
                          type="delete"
                          onClick={() => handleDeleteToken(tagTokenName)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
            ))}
          </>
        )}
      </section>
    </div>
  );
};

export default memo(Soarcast);
