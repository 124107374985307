import React from "react";
import PropTypes from "prop-types";

export default function Justification(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 0.5H2.25C1.65326 0.5 1.08097 0.737053 0.65901 1.15901C0.237053 1.58097 0 2.15326 0 2.75L0 19.25C0 19.8467 0.237053 20.419 0.65901 20.841C1.08097 21.2629 1.65326 21.5 2.25 21.5H15.75C16.3467 21.5 16.919 21.2629 17.341 20.841C17.7629 20.419 18 19.8467 18 19.25V2.75C18 2.15326 17.7629 1.58097 17.341 1.15901C16.919 0.737053 16.3467 0.5 15.75 0.5ZM2.25 20C2.05109 20 1.86032 19.921 1.71967 19.7803C1.57902 19.6397 1.5 19.4489 1.5 19.25L1.5 2.75C1.5 2.55109 1.57902 2.36032 1.71967 2.21967C1.86032 2.07902 2.05109 2 2.25 2H15.75C15.9489 2 16.1397 2.07902 16.2803 2.21967C16.421 2.36032 16.5 2.55109 16.5 2.75V19.25C16.5 19.4489 16.421 19.6397 16.2803 19.7803C16.1397 19.921 15.9489 20 15.75 20H2.25Z"
        fill={props.color}
      />
      <path
        d="M10.5 15.5H4.5C4.30109 15.5 4.11032 15.579 3.96967 15.7197C3.82902 15.8603 3.75 16.0511 3.75 16.25C3.75 16.4489 3.82902 16.6397 3.96967 16.7803C4.11032 16.921 4.30109 17 4.5 17H10.5C10.6989 17 10.8897 16.921 11.0303 16.7803C11.171 16.6397 11.25 16.4489 11.25 16.25C11.25 16.0511 11.171 15.8603 11.0303 15.7197C10.8897 15.579 10.6989 15.5 10.5 15.5Z"
        fill={props.color}
      />
      <path
        d="M13.5 10.5H4.5C4.30109 10.5 4.11032 10.579 3.96967 10.7197C3.82902 10.8603 3.75 11.0511 3.75 11.25C3.75 11.4489 3.82902 11.6397 3.96967 11.7803C4.11032 11.921 4.30109 12 4.5 12H13.5C13.6989 12 13.8897 11.921 14.0303 11.7803C14.171 11.6397 14.25 11.4489 14.25 11.25C14.25 11.0511 14.171 10.8603 14.0303 10.7197C13.8897 10.579 13.6989 10.5 13.5 10.5Z"
        fill={props.color}
      />
      <path
        d="M13.5 5.5H4.5C4.30109 5.5 4.11032 5.57902 3.96967 5.71967C3.82902 5.86032 3.75 6.05109 3.75 6.25C3.75 6.44891 3.82902 6.63968 3.96967 6.78033C4.11032 6.92098 4.30109 7 4.5 7L13.5 7C13.6989 7 13.8897 6.92098 14.0303 6.78033C14.171 6.63968 14.25 6.44891 14.25 6.25C14.25 6.05109 14.171 5.86032 14.0303 5.71967C13.8897 5.57902 13.6989 5.5 13.5 5.5Z"
        fill={props.color}
      />
    </svg>
  );
}

Justification.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Justification.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 18,
  height: 22
};
