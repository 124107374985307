import { Typography } from "@mui/material";
import { Button, Modal, Select } from "components/simple";
import QuestionMark from "components/simple/Icon/QuestionMark";
import React, { useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";

const WorkloadModal = ({ placeholder }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { control } = useForm();

  const optionsData = [
    {
      value: "generic",
      label: "generic"
    },
    {
      value: "mysql",
      label: "mysql"
    },
    {
      value: "pinot",
      label: "pinot"
    },
    {
      value: "kafka",
      label: "kafka"
    },
    {
      value: "host",
      label: "host"
    }
  ];
  return (
    <Container>
      <span onClick={() => setIsModalOpen(!isModalOpen)}>
        <QuestionMark className="cursor-pointer" />
      </span>

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <TitleContainer>
          <Typography variant="subtitle1">Please confirm the workload type</Typography>
        </TitleContainer>
        <SelectorContainer>
          <Typography variant="overline">IDENTIFY WORKLOAD TYPE</Typography>
          <Controller
            name="workload"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  options={optionsData.map(data => {
                    return {
                      value: data?.value,
                      label: data?.label
                    };
                  })}
                  placeholder={placeholder}
                  value={value}
                  onChange={data => onChange(data)}
                />
              );
            }}
          />
        </SelectorContainer>
        <ButtonContainer>
          <Button onClick={() => setIsModalOpen(!isModalOpen)} className="CancelBtn">
            Cancel
          </Button>
          <Button>Confirm</Button>
        </ButtonContainer>
      </Modal>
    </Container>
  );
};

export default WorkloadModal;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 13px;
`;

const SelectorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  .CancelBtn {
    height: 38px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: #fff;
    padding: 10px 20px 12px;
    width: auto;
    color: black;
  }
  .CancelBtn:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
