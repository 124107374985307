import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Title, Modal, Select } from "components/simple";
import { addModalConnector } from "store/entities/settings/actions";
import { routes } from "router";

import styles from "./styles.module.scss";

const AddConnectorModal = ({ emailBackends }) => {
  const [type, setType] = useState("");
  const history = useHistory();

  const open = useSelector(st => st.settings.addModalConnector);
  const typeList = [
    {
      value: "jiraServer",
      label: "Jira Server"
    },
    {
      value: "jiraCloud",
      label: "Jira Cloud"
    },
    {
      value: "freshservice",
      label: "Freshservice"
    },
    {
      value: "connectwiseprofile",
      label: "ConnectWise"
    },
    {
      value: "emailBackends",
      label: "Email Backend",
      isdisabled: emailBackends
    }
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    return () => setType("");
  }, [open]);

  const close = () => {
    dispatch(addModalConnector(false));
  };

  const onSave = e => {
    e.preventDefault();
    switch (type?.value) {
      case "jiraServer":
        history.push(routes.settingsIntegrationsJiraServer);
        close();
        break;
      case "jiraCloud":
        history.push(routes.settingsIntegrationsJiraCloud);
        close();
        break;
      case "freshservice":
        history.push(routes.settingsIntegrationsFreshservice);
        close();
        break;
      case "emailBackends":
        history.push(routes.settingsIntegrationsEmailsDetails);
        close();
        break;
      case "connectwiseprofile":
        history.push(routes.settingsIntegrationsConnectWise);
        close();
        break;
      default:
        break;
    }
  };

  const inputChangeHandler = val => {
    setType(val);
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Add Connector </Title>
      <div className={styles.content}>
        <form className={styles.form} onSubmit={onSave}>
          <Select
            containerClass={styles.inputContainer}
            onChange={v => inputChangeHandler(v)}
            value={type}
            placeholder="Type"
            label="Type"
            options={typeList}
            inputProps={{
              required: true
            }}
            isOptionDisabled={option => option.isdisabled} // disable an option
            required
          />

          <div className={styles.modalFooter}>
            <Button onClick={close} variant="outline" className={styles.modalButton}>
              Back
            </Button>
            <Button type="submit" className={styles.modalButton}>
              Next
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddConnectorModal;
