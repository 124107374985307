import { Box, Typography } from "@mui/material";
import { Table } from "components/complex";
import React, { useState } from "react";
import NoTableDataSVG from "../../../assets/images/noDataAvailable.svg";

function ComplianceSummary({ data }) {
  const column = [
    {
      headerName: "Assets",
      field: "Assets",
      resizable: true,
      Width: 100,
      flex: 1
    },
    {
      headerName: "Warning",
      field: "warning",
      resizable: true,
      Width: 100,
      flex: 1
    },
    {
      headerName: "Fail",
      field: "failed",
      resizable: true,
      Width: 100,
      flex: 1
    },
    {
      headerName: "Pass",
      field: "passed",
      resizable: true,
      Width: 160,
      flex: 1
    }
  ];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        "& .Fail": {
          color: "red"
        },
        "& .Pass": {
          color: "green"
        }
      }}
    >
      <Table
        disableSelectionOnClick={true}
        checkboxVisible={false}
        rows={data}
        columns={column}
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        customStyles={{ height: "100%", width: "100%" }}
        onPageSizeChange={e => setPageSize(e)}
        onPageChange={e => setPage(e)}
        components={{
          NoRowsOverlay: () => (
            <Box className="flex flex-col items-center justify-center w-full h-full">
              <img src={NoTableDataSVG} alt="" />
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                No data available!
              </Typography>
            </Box>
          ),
          NoResultsOverlay: () => (
            <Box className="flex flex-col items-center justify-center w-full h-full">
              <img src={NoTableDataSVG} alt="" />
              <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                No data available!
              </Typography>
            </Box>
          )
        }}
      />
    </Box>
  );
}

export default ComplianceSummary;
