/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { createLoadingSelector } from "store/entities/loading/selector";
import {
  getOneConnectWise,
  addConnectWise,
  editConnectWise,
  getTicketsConfig,
  showTicketsConfigModal,
  deleteTicketConfig,
  getConnectWiseConfig
} from "store/entities/settings/actions";
import {
  getCurrentConnectWiseSelector,
  getTicketsConfigListSelector
} from "store/entities/settings/selectors";

import { ContentContainer, Title, Icon, Input, Button } from "components/simple";
import TicketConfigModal from "../TicketConfigModal";

import styles from "../service_styles.module.scss";

const ConnectWise = () => {
  const params = useParams();
  const id = params?.id;
  const selectTenant = useSelector(state => state.users.selectTenant);
  const dispatch = useDispatch();

  const isSaveLoading = useSelector(
    s =>
      createLoadingSelector([editConnectWise.type])(s) ||
      createLoadingSelector([addConnectWise.type])(s)
  );
  const type = "connectwiseprofile";
  const ticketsConfigs = useSelector(st => getTicketsConfigListSelector(st, type));

  const [state, setState] = useState({
    url: "",
    company_id: "",
    public_key: "",
    private_key: "",
    client_id: "",
    name: ""
  });

  const editData = useSelector(getCurrentConnectWiseSelector);

  useEffect(() => {
    if (id) {
      dispatch(getConnectWiseConfig({ id }));
      dispatch(getOneConnectWise({ id }));
      dispatch(getTicketsConfig({ id }));
    }
  }, [id, selectTenant]);

  const handleSubmit = e => {
    e.preventDefault();
    if (id) {
      dispatch(editConnectWise({ id, data: state }));
      return;
    }
    dispatch(addConnectWise(state));
  };

  useEffect(() => {
    if (editData && id) {
      setState(editData);
    }
  }, [editData, id]);

  const inputChangeHandler = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  const onAddConfiguration = () => {
    dispatch(showTicketsConfigModal({ show: true, id: null, type }));
  };

  const onEditConfiguration = val => {
    dispatch(showTicketsConfigModal({ show: true, id: val, type }));
  };

  const onDeleteConfiguration = val => {
    dispatch(deleteTicketConfig({ id: val, ticket_profile_id: id }));
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>ConnectWise</Title>
      <div className={styles.content}></div>
      <form onSubmit={handleSubmit}>
        <div className={styles.sub_title}>Main</div>
        <div className={styles.inputsCont}>
          <Input
            value={state.name}
            onChange={e => inputChangeHandler(e.target.value, "name")}
            label="Name"
            required
          />

          <Input
            value={state.url}
            onChange={e => inputChangeHandler(e.target.value, "url")}
            label="Service Desk URL"
            required
          />
          <Input
            value={state.company_id}
            onChange={e => inputChangeHandler(e.target.value, "company_id")}
            label="Company Id"
            type="text"
            required
          />
          <Input
            value={state.public_key}
            onChange={e => inputChangeHandler(e.target.value, "public_key")}
            label="Public Key"
            type="text"
            required
          />
          <Input
            value={state.private_key}
            onChange={e => inputChangeHandler(e.target.value, "private_key")}
            label="Private key"
            type="password"
            autoComplete="new-password"
            required
          />
          <Input
            value={state.client_id}
            onChange={e => inputChangeHandler(e.target.value, "client_id")}
            label="Client Id"
            type="password"
            autoComplete="new-password"
            required
          />
        </div>
        <br />
        {/* ================ */}

        {id ? (
          <>
            <div className={styles.row}>
              <div className={styles.sub_title}>Ticket Configuration</div>
              <Button className={styles.btn} onClick={onAddConfiguration}>
                Add configuration
              </Button>
            </div>
            <div className={styles.inputsCont}>
              {ticketsConfigs?.map(item => {
                return (
                  <div className={styles.box} key={item?.id}>
                    <div className={styles.boxContent}>
                      <div className={styles.name}>{item?.name}</div>
                      <div className={styles.row}>
                        <div className={styles.title}>Company:</div>
                        <div className={styles.description}>{item?.project}</div>
                      </div>
                    </div>
                    <div className={styles.buttonGroup}>
                      <div className={styles.button} onClick={() => onEditConfiguration(item?.id)}>
                        <Icon.Edit />
                      </div>
                      <div
                        className={styles.button}
                        onClick={() => onDeleteConfiguration(item?.id)}
                      >
                        <Icon.Delete />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
        <div className={styles.buttonFooter}>
          <Button type="submit" className={styles.btn} isLoading={isSaveLoading}>
            Save
          </Button>
        </div>
      </form>
      <TicketConfigModal />
    </ContentContainer>
  );
};

ConnectWise.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default ConnectWise;
