import React, { useState } from "react";

import { routes } from "router";
import Menu from "@mui/material/Menu";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { navigate } from "helper/history";
import { useDeleteRegistryMutate } from "store/entities/registry/mutations";
import { addNotification } from "store/entities/notifications/actions";

import DeleteModal from "../Modals/DeleteModal/DeleteModal";

export const RowActions = ({ registryId }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const [isModalShown, setIsModalShown] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRegistry = useDeleteRegistryMutate(
    registryId,
    () => {
      queryClient.invalidateQueries(["getRegistryList"]);
      setIsModalShown(false);
      dispatch(addNotification({ msg: "Registry Deleted Successfully", type: "success" }));
    },
    () => {
      dispatch(addNotification({ msg: "Registry Deletion Failed", type: "error" }));
    }
  );

  const handleEdit = () => {
    navigate(routes.settingsIntegrationsRegistryEdit, { id: registryId });
    handleClose();
  };
  const handleRegistryDelete = e => {
    e.preventDefault();
    deleteRegistry.mutate();
  };

  return (
    <>
      {isModalShown && (
        <DeleteModal
          isOpen={isModalShown}
          onSubmit={handleRegistryDelete}
          onClose={() => setIsModalShown(false)}
          isLoading={isSaveLoading}
        />
      )}
      <div className="cursor-pointer">
        <MoreVertIcon onClick={handleClick} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem
            onClick={() => {
              setIsModalShown(true);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};
