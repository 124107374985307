import React from "react";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { formatDate } from "helper/DateLib";
import { Icon } from "components/simple";
import renderCellExpand from "helper/renderCellExpand";

const baseline = state => state.baseline.baseline;

const baselines = state => state.baseline?.baselines;
const baselinesAssets = state => state.baseline?.baselineAssets;
const groupsMinified = state => state.baseline?.groupsMinified;
const compareBaselines = state => state.baseline?.compareBaselines;

export const getBaselinesSelector = createDraftSafeSelector(baselines, state => state);
export const getBaselineAssetsSelector = createDraftSafeSelector(baselinesAssets, state => {
  if (state?.results?.length) {
    return state?.results[0].result.map((elem, index) => {
      const groupsStr = elem?.groups.join(", ");
      return { ...elem, groups: groupsStr, id: index };
    });
  }
  return [];
});

export const getTableSelector = createDraftSafeSelector(
  state => state,
  state => {
    if (!state?.results?.length) {
      return { total: 0, data: [] };
    }

    const formatData = d => {
      const res = d.map(item => {
        const newItem = {};
        Object.entries(item).forEach(([key, val]) => {
          switch (key) {
            case "scan":
            case "result":
              return;

            case "tickets":
              newItem[key] = {
                opened: val?.opened,
                closed: val?.ongoing + val?.closed
              };
              return;

            case "passed_assets":
            case "failed_assets":
            case "assets_passed":
            case "missing_data_assets":
              newItem[key] = (val?.length).toString();
              return;

            case "last_comment":
              newItem[key] = formatDate(val);
              return;

            case "date":
              newItem[key] = formatDate(val, "YYYY/MM/DD");
              return;

            default:
              newItem[key] = val;
          }
        });
        return newItem;
      });

      return res;
    };

    const result = formatData(state.results);

    return {
      total: state.count,
      data: result
    };
  }
);

const auditChoices = state => state.baseline.auditChoices;
const sources = state => state.baseline.sources;
const ticketsConfig = state => state.tickets?.ticketsConfig;
const assetsMini = state => state.baseline.assetsMini;

const baselineConfigFilters = state => state.baseline.baselineConfigFilters;
const baselineConfigTable = state => state.baseline.baselineConfigTable;

const baselineConfigControl = state => state.baseline.baselineConfigControl;

const labelList = state => state.baseline?.labelList;

export const getLabelListSelector = createDraftSafeSelector(labelList, state => {
  if (!state?.length) {
    return [];
  }

  const res = state.map(item => {
    return {
      value: item.id,
      label: item.name
    };
  });
  return res;
});

export const getbaselineConfigControlSelector = createDraftSafeSelector(
  baselineConfigControl,
  state => {
    if (!state?.results?.length) return {};
    return state.results[0];
  }
);

export const getBaselineDataSelector = createDraftSafeSelector(baseline, state => {
  if (!Object.values(state).length) {
    return null;
  }
  const res = {
    audit_files: state?.audit_files?.length
      ? state?.audit_files?.map(item => ({ value: item, label: item }))
      : [],
    name: state?.name,
    result_value: state?.result_value,
    source: state?.source ? { value: state?.source, label: state?.source } : "",
    label: state?.label,

    display_fields_options: state?.display_fields_options,
    filter_fields_options: state?.filter_fields_options
  };
  return res;
});

export const createList = obj => {
  if (obj) {
    return Object.entries(obj).map(([key, val]) => {
      return { value: key, label: val };
    });
  }
  return [];
};

export const getGroupByListSelect = createDraftSafeSelector(
  baseline,
  (_, display_fields) => display_fields,
  (state, display_fields) => {
    const group_by_fields = state?.group_by_fields;
    if (!group_by_fields) {
      return [];
    }
    const res = display_fields?.length
      ? Object.keys(group_by_fields)
          .filter(key => display_fields.includes(key))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: group_by_fields[key]
            });
          }, {})
      : group_by_fields;
    return createList(res);
  }
);

export const getAuditChoicesMiniSelect = createDraftSafeSelector(
  state => state.baseline.auditChoicesMini,
  state => state
);

export const getAuditChoicesSelect = createDraftSafeSelector(auditChoices, state => {
  if (state.results?.length < 0) {
    return { data: [], columns: [], total: 0 };
  }
  const { results = [], count = 0 } = state;

  const data = results.map(item => ({
    id: item?.id,
    labels_count: item?.labels_count,
    audit_file: item?.audit_file,
    assets_count: item?.assets_count,
    baselines_count: item?.baselines_count,
    history_count: item?.history_count,
    last_seen: item?.last_seen,
    source: item?.source
  }));

  const columns = [
    {
      headerName: "Audit file",
      field: "audit_file",
      resizable: true,
      minWidth: 160,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Source",
      field: "source",
      resizable: true,
      minWidth: 160,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Label",
      field: "labels_count",
      resizable: true,
      minWidth: 160,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Assets",
      field: "assets_count",
      resizable: true,
      minWidth: 100,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Baselines",
      field: "baselines_count",
      resizable: true,
      minWidth: 100,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Last Seen",
      field: "last_seen",
      resizable: true,
      minWidth: 100,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "History",
      field: "history_count",
      resizable: true,
      minWidth: 100,
      flex: 1,
      renderCell: params => <div>{params?.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    }
  ];

  return { data, columns, total: count };
});

export const getSourcesListSelect = createDraftSafeSelector(sources, state => {
  if (!state?.sources?.length) {
    return [];
  }
  const res = state?.sources?.map(item => ({ value: item, label: item }));
  return res;
});

export const getTicketsConfigSelect = createDraftSafeSelector(ticketsConfig, state => {
  if (!state?.length) {
    return [];
  }
  const res = state.map(item => {
    return {
      value: item.id,
      label: item.name
    };
  });
  return res;
});

export const getBaselineConfigFiltersSelect = createDraftSafeSelector(
  baselineConfigFilters,
  state => {
    if (!state) {
      return {
        annotation: [],
        expected_result: [],
        has_tickets: [],
        result: []
      };
    }

    const res = {
      annotation: state.annotation.map(([a, b]) => ({ value: a, label: b })),
      expected_result: state.expected_result.map(([a, b]) => ({ value: a, label: b })),
      has_tickets: state.has_tickets.map(([a, b]) => ({ value: a, label: b })),
      result: state.result.map(([a, b]) => ({ value: a, label: b }))
    };
    return res;
  }
);

function sort(data, sortOrderBy) {
  const itemsById = new Map(data.map(item => [item.field, item]));
  return sortOrderBy.map(field => itemsById.get(field)).filter(item => item);
}

export const defaultDisplayFields = [
  "result",
  "tags",
  "control",
  "description",
  "info",
  "tickets__last_comment",
  "tickets__ids",
  "solution"
];

const createColumns = (obj, dict, display_fields_width) => {
  const res = Object.entries(obj)
    .filter(([key]) => dict?.[key] || key === "id")
    .map(([key]) => {
      const fieldWidth = display_fields_width
        ? display_fields_width?.find(item => item?.field === key)
        : undefined;
      // eslint-disable-next-line no-nested-ternary
      const width = fieldWidth ? fieldWidth?.width : 120;

      return {
        headerName: dict?.[key] || "Null",
        field: key,
        resizable: key !== "group_ids",
        width,
        // flex: 1,
        renderCell: renderCellExpand,
        headerClassName: "super-app-theme--header",
        hide: Boolean(!dict?.[key]),
        sortable: key !== "group_ids",
        ...{
          ...(key === "id"
            ? {
                hide: !dict?.id
              }
            : {})
        },
        ...{
          ...(key === "active"
            ? {
                hide: true
              }
            : {})
        }
      };
    });
  const sortedData = sort(res, ["id", ...Object.keys(dict)]);
  return sortedData;
};

export const tableBuilder = (
  state,
  display_fields,
  groupBy,
  display_fields_width,
  baselineData
) => {
  const isGroupBy = Boolean(groupBy);

  if (!state?.results?.length) {
    return { data: [], total: 0, columns: [] };
  }
  const display_fields_options = baselineData?.display_fields_options;

  const dict = display_fields_options || {};

  const getVisibleFields = () => {
    if (display_fields?.length) {
      let allowed = display_fields;
      if (isGroupBy) {
        allowed = ["group_ids", ...display_fields];
      }
      const filtered = allowed
        .filter(key => allowed.includes(key))
        .reduce((ob, key) => {
          // eslint-disable-next-line no-param-reassign
          ob[key] = dict[key];
          return ob;
        }, {});
      return filtered;
    }
    return dict;
  };

  const formatData = d => {
    const res = d.map(item => {
      const newItem = {};
      Object.entries(item).forEach(([key, val]) => {
        if (key === "tags") {
          newItem[key] = val ? "True" : "False";
          return;
        }
        if (key === "group_ids") {
          newItem[key] = val || [];
          return;
        }
        if (key === "tickets__ids") {
          newItem[key] = val || [];
          return;
        }
        if (Array.isArray(val)) {
          newItem[key] = val.join(",");
          return;
        }
        if (key === "active") {
          newItem[key] = val;
          return;
        }
        if (key === "scored") {
          newItem[key] = val ? "True" : "False";
          return;
        }
        if (typeof val !== "string" && typeof val !== "number") {
          newItem[key] = "";
          return;
        }
        newItem[key] = val;
      });
      return newItem;
    });
    return res;
  };

  const dictSort = getVisibleFields();
  const res = formatData(state.results);

  return {
    data: res,
    total: state?.count,
    columns: createColumns(res[0], dictSort, display_fields_width) || []
  };
};

export const getBaselineConfigTableSelector = createDraftSafeSelector(
  baselineConfigTable,
  (_, display_fields) => display_fields,
  (_, __, groupBy) => groupBy,
  (_, ___, __, display_fields_width) => display_fields_width,
  getBaselineDataSelector,
  (state, display_fields, groupBy, display_fields_width, baselineData) => {
    return tableBuilder(state, display_fields, groupBy, display_fields_width, baselineData);
  }
);

export const getCompareBaselinesSelector = createDraftSafeSelector(compareBaselines, state => {
  if (!state) {
    return { data: [], columns: [] };
  }

  const res = state?.data?.map((item, index) => {
    return { ...item, id: index };
  });

  const columns = state?.headers.map(item => {
    if (item === "Finding") {
      return {
        headerName: "Finding",
        field: "finding",
        resizable: true,
        minWidth: 200,
        flex: 1,
        renderCell: renderCellExpand,
        headerClassName: "super-app-theme--header",
        hide: false,
        sortable: true
      };
    }
    return {
      headerName: item,
      field: item,
      resizable: true,
      minWidth: 100,
      flex: 1,
      renderCell: params => (params.value ? <Icon.Check /> : <Icon.NotCheck />),
      headerClassName: "super-app-theme--header",
      hide: false,
      sortable: true
    };
  });

  return { data: res, columns: [...columns] };
});

export const getAssingAssetsLeftTableSelector = createDraftSafeSelector(assetsMini, state => {
  if (!state?.results?.length) {
    return [];
  }
  return state.results;
});

export const getAssingAssetsRightTableSelector = createDraftSafeSelector(baseline, state => {
  if (!state?.assets?.length) {
    return [];
  }

  const failed = state.failed_assets;
  const res = state.assets.map(asset => {
    return { ...asset, pass: !failed.includes(asset.id) };
  });
  return res;
});

export const getAssingGroupsLeftTableSelector = createDraftSafeSelector(groupsMinified, state => {
  if (!state?.results?.length) {
    return [];
  }
  return state.results;
});

export const getAssingGroupsRightTableSelector = createDraftSafeSelector(baseline, state => {
  if (!state?.groups?.length) {
    return [];
  }

  const failed = state.failed_groups;
  const res = state.groups.map(group => {
    return { ...group, pass: !failed.includes(group.id) };
  });
  return res;
});

export const getBaselineAnalysisMiniSelect = createDraftSafeSelector(
  state => state.baseline.baselineAnalysisMini,
  state => {
    if (!state?.results?.length) {
      return { data: [], total: 0 };
    }

    const res = state.results.map(asset => {
      return {
        ...asset,
        failed_assets: asset.failed_assets.length,
        passed_assets: asset.passed_assets.length,
        missing_data_assets: asset.missing_data_assets.length
      };
    });

    return { data: res, total: state.count };
  }
);

/* Data & Structure --> Add Baselines Start */

/* Data & Structure --> Add Baselines End */
