import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { usePrimaryLayoutTitle } from "screens/MonitorLogs/Context/Context";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getSelectedClusters } from "store/entities/filters/slice";
import SelectedTags from "components/simple/SelectedTags";
import { Button, PopOver, Select } from "components/simple";
import { customSelect, operatorsList, valuesList } from "screens/MonitorLogs/Constants";
import { Controller, useForm } from "react-hook-form";
import { Chip } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import styles from "./styles.module.scss";
import {
  updateSelectedFilter,
  useDatapipelineState,
  updateSelectedOperator,
  updatedSelectedKeyType
} from "store/entities/datapipeline/slice";
export default function DynamicFilterLogs({
  dsl,
  updateLocalDSL,
  onSelected,
  keysList,
  range,
  setPageId,
  dropValues,
  optionsOperators,
  className,
  isListView
}) {
  const { updateLogList, selected, updateSelected } = usePrimaryLayoutTitle();
  const isInitial = useRef(true);
  let heightLevel = 1;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSelected, setAnchorElSelected] = useState(null);
  const [showFields, setShowFields] = useState(false);
  const [addList, setAddList] = useState([]);
  const [updatedList, setUpdatedList] = useState(-1);
  const [selectedKey, setSelectedkey] = useState("");
  const [selectedOprator, setSelectedOperator] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const history = useHistory();
  const selectedClusters = useSelector(getSelectedClusters);
  const selectedFilter = useDatapipelineState("selectedFilter");
  const selectedOperator = useDatapipelineState("selectedOperator");
  const dispatch = useDispatch();

  useEffect(() => {
    if (dsl && dsl.length !== 0) {
      updateSelected(dsl);
      updateLogList(dsl);
      updateLocalDSL(dsl);
    }
  }, [dsl, updateLogList, updateLocalDSL]);
  useEffect(() => {
    updateLogList([]);
    updateLocalDSL([]);
    updateSelected([]);
  }, [selectedClusters]);
  useEffect(() => {
    setAddList([]);
    updateLogList([]);
    updateSelected([]);
    updateLocalDSL([]);
  }, [isListView]);
  // dslFilter
  const addFilter = e => {
    setAnchorEl(e.currentTarget);
    setShowFields(!showFields);
    // updateLocalDSL(filterList);
  };
  const handleClose = () => {
    reset();
    setAnchorEl(null);
    setAnchorElSelected(null);
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isDirty, isValid }
  } = useForm();
  const intOperators = ["gt", "gte", "lt", "lte"];
  const onSubmit = e => {
    var key = e?.logsKeys?.value;
    var operator = e?.logsOperator?.value;
    var value = e?.logsValue?.map(item => item?.value);
    if (window.location.pathname === "/monitors-logging/logs-v2") {
      setAddList(addList => [
        ...addList,
        {
          text: `${e?.logsKeys?.value} ${e?.logsOperator?.value} ${e?.logsValue?.map(
            item => item?.value
          )}`,
          key: e?.logsValue?.map(item => item?.value),
          op: e?.logsOperator?.value
        }
      ]);
    } else {
      setAddList(addList => [
        ...addList,
        {
          text: `${e?.logsKeys?.value} ${e?.logsOperator?.value} ${e?.logsValue?.map(
            item => item?.value
          )}`,
          [key]: {
            [operator]: intOperators?.includes(e?.logsOperator?.value)
              ? Number(value.toString())
              : value
          }
        }
      ]);
    }
    reset();
    setAnchorEl(null);
  };
  useEffect(() => {
    updateLocalDSL(addList);
  }, [addList]);
  const getSelected = (prop, index, e) => {
    const splitKeyData = prop?.text.split(" ")[0];
    const splitOpData = prop?.text.split(" ")[1];
    if (window.location.pathname === "/monitors-logging/logs-v2") {
      const valuesData = prop?.key?.map(item => ({
        value: item,
        label: item
      }));
      const keyData = prop?.text?.split(" ");
      setSelectedkey(keyData[0]);
      setSelectedOperator(prop?.op);
      setSelectedValue(valuesData);
      setUpdatedList(index);
    } else {
      if (!intOperators?.includes(splitOpData)) {
        const splitData = prop?.text?.split(" ");
        const valueData = prop[splitKeyData][splitOpData]?.map(item => ({
          value: item,
          label: item
        }));
        setSelectedkey(splitData[0]);
        setSelectedOperator(splitData[1]);
        setSelectedValue(valueData);
        setUpdatedList(index);
      } else {
        const splitData = prop?.text?.split(" ");
        const valueOperator = [prop[splitKeyData][splitOpData]]?.map(item => ({
          value: item,
          label: item
        }));
        setSelectedkey(splitData[0]);
        setSelectedOperator(splitData[1]);
        setSelectedValue(valueOperator);
        setUpdatedList(index);
      }
    }
    setAnchorElSelected(e.currentTarget);
  };
  const onUpdate = e => {
    if (window.location.pathname === "/monitors-logging/logs-v2") {
      const newList = addList?.map((item, index) =>
        index === updatedList
          ? {
              ...item,
              text: `${selectedKey} ${selectedOprator} ${selectedValue?.map(dtx => dtx?.value)}`,
              key: selectedValue?.map(dtx => dtx?.value),
              op: selectedOprator
            }
          : item
      );
      setAddList(newList);
      setUpdatedList(-1);
    } else {
      const valueOp = selectedValue?.map(itm => itm.value);
      const newList = addList?.map((item, index) =>
        index === updatedList
          ? {
              ...item,
              text: `${selectedKey} ${selectedOprator} ${selectedValue?.map(dtx => dtx?.value)}`,
              [selectedKey]: {
                [selectedOprator]: selectedValue?.map(dtx => dtx?.value)
              },
              [selectedKey]: {
                [selectedOprator]: intOperators?.includes(selectedOprator)
                  ? Number(valueOp?.toString())
                  : selectedValue?.map(dtx => dtx?.value)
              }
            }
          : item
      );
      setAddList(newList);
      setUpdatedList(-1);
    }
    reset();
    setAnchorElSelected(null);
  };
  useEffect(() => {
    setValue("logsKeys", selectedKey);
    setValue("logsOperator", selectedOprator);
    setValue("logsValue", selectedValue);
  }, [selectedKey, selectedOprator, selectedValue]);

  const closeAll = e => {
    e.stopPropagation();
    // updateLogList([],"close_all")
    // clearTagList()
    setAddList([]);
    updateLogList([]);
    if (window.location.pathname === "/monitors-logging/logs-v2") {
      history.replace({ state: {} });
    }
    updateSelected([]);
    updateLocalDSL([]);
    // setPredefined([]);
    setShowDropdown(false);
    setPageId(1);
  };

  useEffect(() => {
    if (!isInitial.current) {
      setTimeout(() => closeAll(), 100);
    } else {
      isInitial.current = false;
    }
  }, []);

  const closeTag = (e, index) => {
    e.stopPropagation();
    // setPredefined([])
    setPageId(1);
    if (selected?.length === 1 && window.location.pathname === "/monitors-logging/logs-v2") {
      history.replace({ state: {} });
    }
    const tmp = selected?.filter((val, i) => val && i !== index);
    if (tmp?.length === 0) {
      //   clearTagList()
      updateLogList([]);
      setAddList([]);
      updateLocalDSL([]);
      updateSelected([]);
    } else {
      setAddList(tmp);
      updateLogList(tmp);
      updateLocalDSL(tmp);
      updateSelected(tmp);
    }

    // updateSelected(tmp)
  };
  useEffect(() => {
    onSelected(selected);
    setPageId(1);
  }, [selected]);

  return (
    <Main className={`z-10 flex ${className}`}>
      <Filter style={{ height: `${heightLevel * 40}px` }}>
        <TagSelected style={{ width: "96%" }} id="selectedTags">
          {selected && (
            <div
              style={{ marginBottom: "2px" }}
              className="relative inline-block float-left py-1 mx-1 text-white rounded-full"
            >
              {selected && (
                <>
                  <SelectedTags
                    list={selected}
                    closeTag={closeTag}
                    getSelected={getSelected}
                    idSelected={"popover2"}
                  />
                </>
              )}
            </div>
          )}
          <div style={{ margin: "2px 0px" }} className="py-1 mx-1">
            <Chip
              size="small"
              icon={<FilterAltIcon fontSize="small" />}
              label="Add filter"
              onClick={e => addFilter(e)}
              aria-describedby="popover1"
            />
            {/* save filter popover */}
            <PopOver
              id="popover1"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              popoverContent={
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex gap-3 my-3 px-4"
                  style={{ width: "400px" }}
                >
                  <div className={styles.selectsGroup}>
                    <h3>Add Filter</h3>
                    <div className={styles.filter_grid}>
                      <div className="mt-2 flex flex-col">
                        <div className="flex flex-row justify-between align-middle w-full h-full">
                          <label className="text-sm w-1/4">Filter</label>
                          <Controller
                            name="logsKeys"
                            {...register("logsKeys", {
                              required: true
                            })}
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <Select
                                name="logsKeys"
                                value={value}
                                placeholder="Select the Filter"
                                options={keysList}
                                onChange={data => {
                                  dispatch(updatedSelectedKeyType(data?.type));
                                  dispatch(updateSelectedFilter(data?.value));
                                  onChange(data);
                                  // setSelectedkey(data?.label);
                                }}
                                containerClass={styles.select}
                                isClearable
                                refreshCustom={true}
                                colourStyles={customSelect}
                              />
                            )}
                          />
                        </div>
                        <div className="flex flex-row justify-between align-middle w-full h-full">
                          <label className="text-sm w-1/4">Operator</label>
                          <Controller
                            name="logsOperator"
                            {...register("logsOperator", {
                              // required: true
                            })}
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <Select
                                name="logsOperator"
                                value={value}
                                placeholder="Select the Operator"
                                options={optionsOperators}
                                onChange={data => {
                                  dispatch(updateSelectedOperator(data?.value));
                                  onChange(data);
                                  // setSelectedOperator(data?.label);
                                }}
                                containerClass={styles.select}
                                isClearable
                                refreshCustom={true}
                                colourStyles={customSelect}
                              />
                            )}
                          />
                        </div>
                        <div className="flex flex-row justify-between align-middle w-full h-full">
                          <label className="text-sm w-1/4">Value</label>
                          <Controller
                            name="logsValue"
                            {...register("logsValue", {
                              required: true
                            })}
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <div className={`${styles.container} ${styles.select}`}>
                                <CreatableSelect
                                  name="logsValue"
                                  value={value}
                                  placeholder={
                                    selectedOperator === "regex"
                                      ? "Enter regex value"
                                      : "Select the Value"
                                  }
                                  options={selectedOperator === "regex" ? [] : dropValues}
                                  onChange={data => {
                                    onChange(data);
                                    // setSelectedValue(data?.label);
                                  }}
                                  isClearable
                                  isMulti
                                  noOptionsMessage={() =>
                                    selectedOperator === "regex" ? null : "No options"
                                  }
                                  refreshCustom={true}
                                  styles={customSelect}
                                  isDisabled={selectedFilter?.length > 0 ? false : true}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-3">
                      <Button
                        type="submit"
                        disabled={!isValid}
                        style={{ height: "30px", borderRadius: "2px" }}
                      >
                        Apply
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant="outline"
                        style={{ height: "30px", borderRadius: "2px" }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              }
            />
            {/* edit filter popover */}
            <PopOver
              id="popover2"
              open={Boolean(anchorElSelected)}
              anchorEl={anchorElSelected}
              onClose={handleClose}
              popoverContent={
                <form
                  onSubmit={handleSubmit(onUpdate)}
                  className="flex gap-3 my-3 px-4"
                  style={{ heigh: "300px", width: "400px" }}
                >
                  <div className={styles.selectsGroup}>
                    <h3>Update Filter</h3>
                    <div className={styles.filter_grid}>
                      <div className="mt-2 flex flex-col">
                        <div className="flex flex-row justify-between align-middle w-full h-full">
                          <label className="text-sm w-1/4">Filter</label>
                          <Controller
                            name="logsKeys"
                            {...register("logsKeys", {
                              required: true
                            })}
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <Select
                                name="logsKeys"
                                value={{ value: selectedKey, label: selectedKey }}
                                placeholder={
                                  selectedOperator === "regex"
                                    ? "Enter regex value"
                                    : "Select the Value"
                                }
                                options={keysList}
                                onChange={data => {
                                  dispatch(updatedSelectedKeyType(data?.type));
                                  dispatch(updateSelectedFilter(data?.value));
                                  onChange(data);
                                  setSelectedkey(data?.value);
                                }}
                                containerClass={styles.select}
                                isClearable
                                refreshCustom={true}
                                colourStyles={customSelect}
                              />
                            )}
                          />
                        </div>
                        <div className="flex flex-row justify-between align-middle w-full h-full">
                          <label className="text-sm w-1/4">Operator</label>
                          <Controller
                            name="logsOperator"
                            {...register("logsOperator", {
                              // required: true
                            })}
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <Select
                                name="logsOperator"
                                value={{ value: selectedOprator, label: selectedOprator }}
                                placeholder="Select the Operator"
                                options={optionsOperators}
                                onChange={data => {
                                  dispatch(updateSelectedOperator(data?.value));
                                  onChange(data);
                                  setSelectedOperator(data?.value);
                                }}
                                containerClass={styles.select}
                                isClearable
                                refreshCustom={true}
                                colourStyles={customSelect}
                              />
                            )}
                          />
                        </div>
                        <div className="flex flex-row justify-between align-middle w-full h-full">
                          <label className="text-sm w-1/4">Value</label>
                          <Controller
                            name="logsValue"
                            {...register("logsValue", {
                              required: true
                            })}
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <div className={`${styles.container} ${styles.select}`}>
                                <CreatableSelect
                                  name="logsValue"
                                  value={selectedValue}
                                  placeholder={
                                    selectedOperator === "regex"
                                      ? "Enter regex value"
                                      : "Select the Value"
                                  }
                                  options={selectedOperator === "regex" ? [] : dropValues}
                                  onChange={data => {
                                    onChange(data);
                                    setSelectedValue(data);
                                  }}
                                  isClearable
                                  isMulti
                                  noOptionsMessage={() =>
                                    selectedOperator === "regex" ? null : "No options"
                                  }
                                  components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null
                                  }}
                                  refreshCustom={true}
                                  styles={customSelect}
                                  isDisabled={selectedFilter?.length > 0 ? false : true}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-3">
                      <Button
                        type="submit"
                        disabled={!isValid}
                        style={{ height: "30px", borderRadius: "2px" }}
                      >
                        Update
                      </Button>
                      <Button
                        onClick={handleClose}
                        variant="outline"
                        style={{ height: "30px", borderRadius: "2px" }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              }
            />
          </div>
        </TagSelected>
        <TagSelected style={{ width: "0%", margin: "5px" }}>
          {selected && selected.length > 0 && (
            <CloseIcon style={{ width: "1.5em", height: "21px" }} onClick={closeAll} />
          )}
        </TagSelected>
      </Filter>
    </Main>
  );
}

export const Main = styled.div`
  font-size: 13px;
  .input-field {
    border: 0;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .css-1a1l6zv-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    font-size: 19px;
  }
`;
export const Filter = styled.div`
  border: 1px solid #dbdff0;
  width: 100%;
  height: auto !important;
  border-radius: 0.25rem;
  cursor: pointer;
  background: #fff;
  border-width: 1px;
`;
export const TagSelected = styled.div`
  display: inline-block;
  float: left;
`;
