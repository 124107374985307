import { Box } from "@mui/material";
import styles from "./widgets.module.scss";
export const tabsList = (
  DiscoverPolicyCount,
  CustomPolicyCount,
  HardeningPolicyCount,
  screenWidth
) => [
  {
    id: 0,
    label: "Discovered",
    content: DiscoverPolicyCount?.map((item, index) => (
      <div
        className={`grid grid-cols-6 text-xs mt-1  ${styles.Labelbutton} `}
        onClick={e => onClickChart(e)}
        key={index}
      >
        <div className="w-3 h-3 rounded col-span-1" style={{ background: item?.color }} />
        <p className="col-span-5 m-0">{item?.name}</p>
      </div>
    ))
  },
  {
    id: 1,
    label: "Custom",
    content: CustomPolicyCount?.map((item, index) => (
      <div
        className={`grid grid-cols-6 text-xs  mt-1 ${styles.Labelbutton} `}
        onClick={e => onClickChart(e)}
        key={index}
      >
        <div className="w-3 h-3 rounded col-span-1" style={{ background: item?.color }} />
        <p className="col-span-5 m-0">{item?.name}</p>
      </div>
    ))
  },
  {
    id: 2,
    label: "Hardening",
    content: HardeningPolicyCount?.map((item, index) => (
      <div
        className={`grid grid-cols-6 text-xs  mt-1 ${styles.Labelbutton} `}
        onClick={e => onClickChart(e)}
        key={index}
      >
        <div className="w-3 h-3 rounded col-span-1" style={{ background: item?.color }} />
        <p className="col-span-5 m-0">{item?.name}</p>
      </div>
    ))
  }
];
export const statusColour = name => {
  return name === "Active"
    ? "#70DF8F"
    : name === "Ignored"
    ? "#88B8FF"
    : name === "InActive"
    ? "#FB8282"
    : name === "Pending"
    ? "#F9E536"
    : name === "Discover"
    ? "#0066FF"
    : name === "Custom"
    ? "#DD70DF"
    : name === "Hardening"
    ? "#7CC5AF"
    : name === "Changed"
    ? "#2379F9"
    : null;
};
export const ActiveTabColor = id => {
  return id === 0 ? "#0066FF" : id === 1 ? "#DD70DF" : id === 2 ? "#7CC5AF" : null;
};
const CountCheck = e => {
  return e ? e : 0;
};
export const DiscoverPolicyCount = graphData => [
  {
    name: `Active: ${CountCheck(graphData?.Active?.Discover)}`,
    color: "#70DF8F"
  },
  {
    name: `Pending: ${CountCheck(graphData?.Pending?.Discover)}`,
    color: "#F9E536"
  },
  {
    name: `Inactive: ${CountCheck(graphData?.InActive?.Discover)}`,
    color: "#FB8282"
  },
  {
    name: `Ignored: ${CountCheck(graphData?.Ignored?.Discover)}`,
    color: "#88B8FF"
  },
  {
    name: `Changed: ${CountCheck(graphData?.Changed?.Discover)}`,
    color: "#2379F9"
  }
];
export const HardeningPolicyCount = graphData => [
  {
    name: `Active: ${CountCheck(graphData?.Active?.Hardening)}`,
    color: "#70DF8F"
  },
  {
    name: `Pending: ${CountCheck(graphData?.Pending?.Hardening)}`,
    color: "#F9E536"
  },
  {
    name: `Inactive: ${CountCheck(graphData?.InActive?.Hardening)}`,
    color: "#FB8282"
  },
  {
    name: `Ignored: ${CountCheck(graphData?.Ignored?.Hardening)}`,
    color: "#88B8FF"
  },
  {
    name: `Changed: ${CountCheck(graphData?.Changed?.Hardening)}`,
    color: "#2379F9"
  }
];
export const CustomPolicyCount = graphData => [
  {
    name: `Active: ${CountCheck(graphData?.Active?.Custom)}`,
    color: "#70DF8F"
  },
  {
    name: `Pending: ${CountCheck(graphData?.Pending?.Custom)}`,
    color: "#F9E536"
  },
  {
    name: `Inactive: ${CountCheck(graphData?.InActive?.Custom)}`,
    color: "#FB8282"
  },
  {
    name: `Ignored: ${CountCheck(graphData?.Ignored?.Custom)}`,
    color: "#88B8FF"
  },
  {
    name: `Changed: ${CountCheck(graphData?.Changed?.Custom)}`,
    color: "#2379F9"
  }
];

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className={`${styles.Tooltip} h-auto px-3 rounded-xl flex justify-center items-center bg-white opacity-90`}
      >
        <p className="w-full">
          {`${payload[0]?.name} : `} <span>{payload[0].value}</span>
        </p>
      </div>
    );
  }

  return null;
};
