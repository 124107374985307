import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";

export default function DrawerComponent({
  children,
  anchor = "right",
  showDrawer,
  setShowDrawer,
  setWidth = "710px",
  borderRadius = "2px",
  setMarginLeft
}) {
  const toggleDrawer = () => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setShowDrawer(v => !v);
  };

  return (
    <div>
      <Drawer
        anchor={anchor}
        open={showDrawer}
        onClose={toggleDrawer()}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          sx: {
            width: `${setWidth}`,
            // {setMargin ? margin: {setMargin} , margin: "0 auto"} ,
            margin: "0 auto",
            marginLeft: `${setMarginLeft ? setMarginLeft : "0"}`,
            borderRadius: `${borderRadius}`
          }
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "0 7px 7px 7px"
          }}
          role="presentation"
          onClick={toggleDrawer()}
          onKeyDown={toggleDrawer()}
        >
          {children}
        </Box>
      </Drawer>
    </div>
  );
}

DrawerComponent.propTypes = {
  children: PropTypes.node.isRequired
};
