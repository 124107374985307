import React from "react";
import PropTypes from "prop-types";

export default function DropDownArrow(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        viewBox={`0 0 ${props.width} ${props.height}`}
        d="M1 1L5 5L9 1"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

DropDownArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

DropDownArrow.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 10,
  height: 6
};
