import React from "react";
import PropTypes from "prop-types";

export default function Dashboard(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82632 10H6.75675C8.68719 10 9.6524 9 9.6524 7V5C9.6524 3 8.68719 2 6.75675 2H4.82632C2.89588 2 1.93066 3 1.93066 5V7C1.93066 9 2.89588 10 4.82632 10Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4088 10H18.3393C20.2697 10 21.2349 9 21.2349 7V5C21.2349 3 20.2697 2 18.3393 2H16.4088C14.4784 2 13.5132 3 13.5132 5V7C13.5132 9 14.4784 10 16.4088 10Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4088 22H18.3393C20.2697 22 21.2349 21 21.2349 19V17C21.2349 15 20.2697 14 18.3393 14H16.4088C14.4784 14 13.5132 15 13.5132 17V19C13.5132 21 14.4784 22 16.4088 22Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.82632 22H6.75675C8.68719 22 9.6524 21 9.6524 19V17C9.6524 15 8.68719 14 6.75675 14H4.82632C2.89588 14 1.93066 15 1.93066 17V19C1.93066 21 2.89588 22 4.82632 22Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Dashboard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Dashboard.defaultProps = {
  className: "",
  style: {},
  color: "#FFF",
  width: 24,
  height: 24
};
