import React, { useState } from "react";

import { uniqueId } from "lodash";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import { Table } from "components/complex";
import { ContentContainer } from "components/simple";
import { customStyle, scanHistoryDataHeaders } from "./utils";
import { useGetImageDetails } from "store/entities/imageDetails/queries";

export const ScanHistory = ({ assetId }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const apiRef = useGridApiRef();

  const {
    data: scanHistoryData = [],
    isLoading: isScanHistoryDataLoading,
    refetch: refetchScanHistoryData
  } = useGetImageDetails("SCAN_HISTORY_IMAGES", assetId);

  return (
    <ContentContainer className="p-4 mt-2">
      <div className="mt-3">
        <Table
          page={page}
          apiRef={apiRef}
          pageSize={pageSize}
          checkboxVisible={false}
          customStyles={customStyle}
          getRowId={row => uniqueId()}
          onPageChange={p => setPage(p)}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          data={scanHistoryData}
          rowCount={scanHistoryData?.length}
          columns={scanHistoryDataHeaders}
        />
      </div>
    </ContentContainer>
  );
};
