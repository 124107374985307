import React, { useState } from "react";

import {
  Button,
  Input,
  Textarea,
  Select,
  Title,
  Tags,
  ActionButton,
  Icon,
  MultyInput
} from "components/simple";
import { SwitchBox } from "./SwitchBox";
import { ScheduleEdit } from "./ScheduleEdit";
import { ExpandBox } from "./ExpandBox";
import { KeyValueEdit } from "./KeyValueEdit";

import { useDynamicFieldsDetails } from "./hooks/DynamicFieldsHook";
import { useSchedule } from "./hooks/ScheduleHook";
import { useKeyValueList } from "./hooks/KeyValueHook";

import styles from "./styles.module.scss";

export const convertOptionsList = optList => {
  if (optList) {
    return optList.map(item => ({
      label: item,
      value: item
    }));
  }
  return undefined;
};

export const valueToOption = (valueMap, name) => {
  const value = valueMap.get(name);
  if (!value) {
    return undefined;
  }

  return {
    value,
    label: value
  };
};

export const multivalueParse = (valueMap, name) => {
  const value = valueMap.get(name);
  if (!value) {
    return [];
  }

  return JSON.parse(value) || [];
};

const multivalueConvert = src => {
  const srcArray = JSON.parse(src);
  return srcArray.map(item => item.value);
};

export const valueToJSON = value => {
  if (!value || value.length === 0) {
    return "";
  }

  return JSON.stringify(value);
};

export function convertDataList(fieldList, src) {
  const res = {};
  fieldList.forEach(field => {
    const value = src.get(field.name);
    if (value) {
      if (field.field_type === "select_multiple") {
        res[field.name] = multivalueConvert(value);
      } else {
        res[field.name] = value;
      }
    }
  });

  return res;
}

const DynamicField = ({ field, valueMap, onChange }) => {
  switch (field.field_type) {
    case "textarea":
      return (
        <Textarea
          className={styles.formInput}
          label={field.description}
          required={field.required}
          value={valueMap.get(field.name)}
          onChange={v => onChange(field.name, v)}
        />
      );

    case "select":
      return (
        <Select
          containerClass={styles.formInput}
          label={field.description}
          required={field.required}
          options={convertOptionsList(field.select_options)}
          value={valueToOption(valueMap, field.name)}
          onChange={v => onChange(field.name, v.value)}
        />
      );

    case "select_multiple":
      return (
        <Tags
          className={styles.formInput}
          label={field.description}
          required={field.required}
          list={convertOptionsList(field.select_options)}
          values={multivalueParse(valueMap, field.name)}
          onChange={v => onChange(field.name, valueToJSON(v))}
        />
      );

    case "array":
      return (
        <MultyInput
          className={styles.formInput}
          label={field.description}
          required={field.required}
          values={field.array}
          onChange={v => onChange(field.name, v)}
        />
      );

    default:
      return (
        <Input
          containerClassName={styles.formInput}
          label={field.description}
          required={field.required}
          value={valueMap.get(field.name)}
          onChange={v => onChange(field.name, v.target.value)}
        />
      );
  }
};

export const Step2 = ({ currType, tagOptions, labelsOptions, scheduleData, onBack, onSave }) => {
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [dynamicFieldsDetails, dynamicFieldsCtrlDetails] = useDynamicFieldsDetails(
    currType?.fields
  );

  const [tags, setTags] = useState([]);
  const [label, setLabel] = useState("");

  const [scheduleEnabled, setScheduleEnabled] = useState(false);
  const [schedule, scheduleCtrl] = useSchedule();

  const [miscExpanded, setMiscExpanded] = useState(false);
  const [miscData, miscDataCtrl] = useKeyValueList();

  const { fields } = React.useMemo(() => {
    const res = [];
    if (currType) {
      currType.fields.forEach(item => {
        res.push(item);
      });
    }

    return { fields: res };
  }, [currType]);

  const validate = () => {
    if (!name) {
      return false;
    }

    if (!dynamicFieldsCtrlDetails.checkRequiredFields()) {
      return false;
    }

    return true;
  };

  const handleNameChange = v => {
    setName(v.target.value);
  };

  const handleNoteChange = v => {
    setNote(v.target.value);
  };

  const handleTagsChange = v => {
    setTags(v);
  };

  const handleSave = () => {
    if (onSave && validate()) {
      const dataList = dynamicFieldsDetails?.map(item => {
        return convertDataList(currType.fields, item);
      });
      let targetData = {
        name,
        note,
        data_list: dataList,
        misc: miscData,
        target_type: currType.id,
        tags: tags.map(tag => tag.value),
        label: label?.value ?? ""
      };

      if (scheduleEnabled) {
        targetData = {
          ...targetData,
          schedule
        };
      }

      onSave(targetData);
    }
  };

  const onAddDynamicFieldsDetails = () => {
    dynamicFieldsCtrlDetails.onAddDynamicFieldsDetails(currType?.fields);
  };

  const handleDeleteDynamicFieldsDetails = i => {
    dynamicFieldsCtrlDetails.handleDeleteDynamicFieldsDetails(i);
  };

  return (
    <div>
      <div className={styles.titleBox}>
        <Title>Create {currType.name} Collector</Title>
        <div className={styles.stepTitle}>Step 2</div>
      </div>
      <div className={styles.form}>
        <Input
          containerClassName={styles.formInput}
          label="Collector name"
          required
          value={name}
          onChange={handleNameChange}
        />
        <Input
          containerClassName={styles.formInput}
          label="Note"
          value={note}
          onChange={handleNoteChange}
        />

        {fields?.length ? (
          <div className={styles.dynControlBox2}>
            {dynamicFieldsDetails?.map((fld, index) => {
              return (
                <div key={index} className={styles.row}>
                  {fields.map((field, idx) => (
                    <DynamicField
                      key={`DynFieldLg_${idx}`}
                      field={field}
                      valueMap={fld}
                      onChange={(n, v) => dynamicFieldsCtrlDetails.setValue(n, v, index)}
                    />
                  ))}
                  {dynamicFieldsDetails?.length > 1 ? (
                    <ActionButton
                      type="delete"
                      onClick={() => handleDeleteDynamicFieldsDetails(index)}
                    />
                  ) : null}
                </div>
              );
            })}
            <div className={styles.addBtnBody} onClick={onAddDynamicFieldsDetails}>
              <div className={styles.addBtnIcon}>
                <Icon.Plus />
              </div>
              <div className={styles.addBtnLabel}>Add</div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.modalFooter}>
        <Button variant="outline" onClick={onBack}>
          Back
        </Button>
        <Button onClick={handleSave} disabled={!validate()}>
          Save
        </Button>
      </div>
    </div>
  );
};
