import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  reportsParams: {
    page: 0,
    pageSize: 20,
    search: "",
    ordering: ""
  },
  reports: [],
  report: {},
  reportPages: {},
  reportPeriods: {}
};

const reducer = createReducer(
  initialState,
  {
    [actions.setReportsParams.type]: (state, action) => ({
      ...state,
      reportsParams: {
        ...state.reportsParams,
        ...action.payload
      }
    }),
    [actions.getReportsSuccess.type]: (state, action) => ({
      ...state,
      reports: action.payload,
      report: {}
    }),
    [actions.getReportSuccess.type]: (state, action) => ({
      ...state,
      report: action.payload
    }),
    [actions.getReportPagesSuccess.type]: (state, action) => ({
      ...state,
      reportPages: action.payload
    }),
    [actions.getReportPeriods.type]: state => ({
      ...state,
      reportPeriods: {}
    }),
    [actions.getReportPeriodsSuccess.type]: (state, action) => ({
      ...state,
      reportPeriods: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
