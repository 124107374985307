import React from "react";
import PropTypes from "prop-types";

export default function Baselines(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.65789 22H14.4492C19.2753 22 21.2057 20 21.2057 15V9C21.2057 4 19.2753 2 14.4492 2H8.65789C3.8318 2 1.90137 4 1.90137 9V15C1.90137 20 3.8318 22 8.65789 22Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.90137 12.7L7.69267 12.68C8.41658 12.68 9.22737 13.25 9.49763 13.95L10.598 16.83C10.8489 17.48 11.2447 17.48 11.4956 16.83L13.706 11.02C13.9183 10.46 14.3141 10.44 14.5843 10.97L15.5881 12.94C15.8874 13.53 16.6595 14.01 17.2966 14.01H21.2154"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Baselines.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Baselines.defaultProps = {
  className: "",
  style: {},
  color: "#fff",
  width: 24,
  height: 24
};
