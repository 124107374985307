import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import styles from "./styles.module.scss";
import { YamlEditor, Modal, Textarea, Button } from "components/simple";
import Loader from "components/simple/Loader";
import { Controller, useForm } from "react-hook-form";
import kubeArmor from "../../assets/kubearmor.png";
import kubernetes from "../../assets/kubernetes.png";
import { Cilium } from "components/simple/Icon/Cilium";
import {
  callUpdatePolicyMutation,
  PolicyDiffYaml,
  UpdatePolicy
} from "store/entities/policies/queries";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { addNotification } from "store/entities/notifications/actions";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateAvailabilityDialogBox({
  setIsModalOpen,
  policyDetail,
  setOpen,
  open,
  refetchPolicies
}) {
  // const [open, setOpen] = useState(false);

  const { data: YamlCode = "", isLoading: yamlCodeLoading } = PolicyDiffYaml(
    policyDetail?.policy_id
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const updateDiscoveredPolicy = UpdatePolicy(() => {
    dispatch(addNotification({ msg: "Policy updated successfully", type: "success" }));
    refetchPolicies();
  });
  const onUpdate = () => {
    // setIsModalOpen(true);
    const policy_id = policyDetail?.policy_id;
    updateDiscoveredPolicy.mutate({ policy_id });
    setOpen(false);
  };

  const yamlCode = `doe: "a deer, a female deer"
  ray: "a drop of golden sun"
  pi: 3.14159
  xmas: true
  french-hens: 3
  calling-birds:
    - huey
    - dewey
    - louie
    - fred
  `;

  const styles = {
    contentText: {
      fontFamily: "monospace !important"
    }
  };

  return (
    <div>
      {/* <Button
        onClick={handleClickOpen}
        className="px-0.5 m-auto text-white"
        style={{ background: "#005AAC", fontSize: "7px", borderRadius: "3px", padding: "3px 5px" }}
      >
        Changes avilable
      </Button> */}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Box sx={{ width: 500, maxWidth: 500, height: 500 }}>
          <AppBar
            sx={{
              position: "relative",
              backgroundColor: "white",
              color: "black",
              boxShadow: "none"
            }}
          >
            <Toolbar sx={{ boxShadow: "none", zIndex: "100" }}>
              <div className={styles.updateDialogContainer}>
                <div>
                  <Box className="font-extrabold text-sm ml-2">
                    <Box>
                      {policyDetail.policy_type === "KubeArmor" ||
                        policyDetail.policy_type === "KubeArmorPolicy" ||
                        (policyDetail.policy_kind === "KubeArmorPolicy" && (
                          <img
                            src={kubeArmor}
                            alt="kubearmor"
                            width="28px"
                            height="28px"
                            style={{ position: "absolute", left: "3px", marginTop: "8px" }}
                          />
                        ))}
                      {policyDetail.policy_type === "KubeArmor Host" ||
                        policyDetail.policy_type === "KubeArmorHostPolicy" ||
                        (policyDetail.policy_kind === "KubeArmorHostPolicy" && (
                          <img
                            src={kubeArmor}
                            alt="kubearmor"
                            width="28px"
                            height="28px"
                            style={{ position: "absolute", left: "3px", marginTop: "8px" }}
                          />
                        ))}
                      {policyDetail.policy_type === "Cilium Network" ||
                        policyDetail.policy_type === "CiliumNetworkPolicy" ||
                        (policyDetail.policy_kind === "CiliumNetworkPolicy" && (
                          <img
                            src={Cilium}
                            alt="cilium"
                            width="28px"
                            height="28px"
                            style={{ position: "absolute", left: "3px", marginTop: "8px" }}
                          />
                        ))}
                      {policyDetail.policy_type === "Kubernetes Network" ||
                        policyDetail.policy_type === "NetworkPolicy" ||
                        (policyDetail.policy_kind === "NetworkPolicy" && (
                          <img
                            src={kubernetes}
                            alt="kubernetes"
                            width="28px"
                            height="28px"
                            style={{ position: "absolute", left: "3px", marginTop: "8px" }}
                          />
                        ))}
                    </Box>
                    <Box className="flex flex-col ml-2">
                      <Box>{`${policyDetail?.name} (v${policyDetail?.version})`} </Box>
                      <Box>
                        <Box className="flex flex-row">
                          <Box className={styles.networkName}>{policyDetail?.type}</Box>
                          <Box className={styles.availableChanges}>
                            {"(" + YamlCode?.timestamp + ")" || null}
                          </Box>
                        </Box>
                        <Box className="flex">
                          <Box className={styles.appliedContent}>
                            <Box className="m-auto">
                              {policyDetail?.created_at && (
                                <Box className="flex">
                                  <AccessTimeIcon
                                    fontSize="14px"
                                    sx={{ color: "#8E8E8E", marginRight: "2px" }}
                                  />
                                  <Box>
                                    Created {moment(new Date(policyDetail?.created_at)).fromNow()}.
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Box className={styles.appliedContent}>
                            <Box className="flex">
                              {policyDetail?.applied_at && (
                                <Box className="flex">
                                  <AccessTimeIcon
                                    fontSize="14px"
                                    sx={{ color: "#8E8E8E", marginRight: "2px" }}
                                  />
                                  <Box>
                                    Applied {moment(new Date(policyDetail?.applied_at)).fromNow()}.
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div className="flex flex-row">
                  {!policyDetail?.pending_available && (
                    <Button className={styles.updateYamlBtn} onClick={onUpdate}>
                      Update
                    </Button>
                  )}
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ marginLeft: "7px", marginRight: "-30px" }}
                  >
                    <CloseIcon sx={{ color: "black" }} />
                  </IconButton>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <div>
            <p className={styles.yamlTitle}>Updated YAML</p>
            <div className={styles.yamlCodeContainer}>
              {yamlCodeLoading ? (
                <Loader />
              ) : (
                <ReactDiffViewer
                  oldValue={YamlCode?.approved_yaml || ""}
                  newValue={YamlCode?.staged_yaml || ""}
                  splitView={false}
                  // hideLineNumbers={true}
                  showDiffOnly={false}
                  disableWordDiff={true}
                  compareMethod={DiffMethod.LINES}
                  styles={styles}
                />
              )}
            </div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
}
