import React, { useState } from "react";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import styles from "./styles.module.scss";
import { YamlEditor, Modal, Textarea } from "components/simple";
import Loader from "components/simple/Loader";
import { Controller, useForm } from "react-hook-form";
import kubeArmor from "../../assets/kubearmor.png";
import kubernetes from "../../assets/kubernetes.png";
import { Cilium } from "components/simple/Icon/Cilium";
import { callUpdatePolicyMutation, PolicyYaml } from "store/entities/policies/queries";
import { Box } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadFile } from "helper/helpers";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "store/entities/notifications/actions";
import { routes } from "router/router";
import { navigate } from "helper/history";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Container, Tooltip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ViewYamlBox({
  policyDetail,
  setClose,
  setShowCommitMessageModal,
  setEditYaml,
  editYaml
}) {
  const [open, setOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setClose(false);
    setEditYaml();
  };
  const onUpdate = () => {
    setIsModalOpen(true);
    setOpen(false);
  };

  const [toggleYAMLEdit, setToggleYAMLEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  // const [showCommitMessageModal, setShowCommitMessageModal] = useState(false);

  const { data: YamlCode = "", isLoading: yamlCodeLoading } = PolicyYaml(policyDetail?.policy_id);
  const yamlCode = `doe: "a deer, a female deer"
  ray: "a drop of golden sun"
  pi: 3.14159
  xmas: true
  french-hens: 3
  calling-birds:
    - huey
    - dewey
    - louie
    - fred
  `;
  const handleDownloadPolicy = e => {
    e.preventDefault();
    const policyName = policyDetail?.name;
    downloadFile(
      YamlCode?.yaml,
      policyName ? `${policyName}.yaml` : "policy.yaml",
      "application/x-yaml"
    );
  };

  const handleChange = e => {
    setEditYaml(e);
  };
  useEffect(() => {
    setEditYaml(YamlCode?.yaml);
  }, []);
  useEffect(() => {
    setDisable(YamlCode?.yaml === editYaml);
  }, [editYaml]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Box sx={{ width: 500, maxWidth: 500, height: 500 }}>
          <AppBar
            sx={{
              position: "relative",
              backgroundColor: "white",
              color: "black",
              boxShadow: "none"
            }}
          >
            <Toolbar
              className={styles.updateDialogContainer}
              sx={{ boxShadow: "none", zIndex: "100" }}
            >
              <div>
                <Box className="font-bold text-sm ml-2">
                  <Box>
                    {policyDetail.policy_type === "KubeArmor" ||
                      policyDetail.policy_type === "KubeArmorPolicy" ||
                      (policyDetail.policy_kind === "KubeArmorPolicy" && (
                        <img
                          src={kubeArmor}
                          alt="kubearmor"
                          width="28px"
                          height="28px"
                          style={{ position: "absolute", left: "3px", marginTop: "8px" }}
                        />
                      ))}
                    {policyDetail.policy_type === "KubeArmor Host" ||
                      policyDetail.policy_type === "KubeArmorHostPolicy" ||
                      (policyDetail.policy_kind === "KubeArmorHostPolicy" && (
                        <img
                          src={kubeArmor}
                          alt="kubearmor"
                          width="28px"
                          height="28px"
                          style={{ position: "absolute", left: "3px", marginTop: "8px" }}
                        />
                      ))}
                    {policyDetail.policy_type === "Cilium Network" ||
                      policyDetail.policy_type === "CiliumNetworkPolicy" ||
                      (policyDetail.policy_kind === "CiliumNetworkPolicy" && (
                        <img
                          src={Cilium}
                          alt="cilium"
                          width="28px"
                          height="28px"
                          style={{ position: "absolute", left: "3px", marginTop: "8px" }}
                        />
                      ))}
                    {policyDetail.policy_type === "Kubernetes Network" ||
                      policyDetail.policy_type === "NetworkPolicy" ||
                      (policyDetail.policy_kind === "NetworkPolicy" && (
                        <img
                          src={kubernetes}
                          alt="kubernetes"
                          width="28px"
                          height="28px"
                          style={{ position: "absolute", left: "3px", marginTop: "8px" }}
                        />
                      ))}
                  </Box>
                  <Box className="flex flex-col ml-2">
                    <Box>
                      {policyDetail?.version === 0
                        ? policyDetail?.name
                        : policyDetail?.name + (" (v" + policyDetail?.version + ")")}
                    </Box>
                    <Box>
                      <Box className="flex flex-row">
                        <Box className={styles.networkName}>{policyDetail?.policy_kind}</Box>
                        <Box className={styles.updateTimestamp}>
                          {YamlCode?.updated_timestamp || null}
                        </Box>
                      </Box>
                      <Box className="flex">
                        <Box className={styles.appliedContent}>
                          <Box className="m-auto">
                            {policyDetail?.created_at && (
                              <Box className="flex">
                                <AccessTimeIcon
                                  fontSize="14px"
                                  sx={{ color: "#8E8E8E", marginRight: "2px" }}
                                />
                                <Box>
                                  Created {moment(new Date(policyDetail?.created_at)).fromNow()}.
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box className={styles.appliedContent}>
                          <Box className="flex">
                            {policyDetail?.applied_at && (
                              <Box className="flex">
                                <AccessTimeIcon
                                  fontSize="14px"
                                  sx={{ color: "#8E8E8E", marginRight: "2px" }}
                                />
                                <Box>
                                  Applied {moment(new Date(policyDetail?.applied_at)).fromNow()}.
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
              <div>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon sx={{ color: "black" }} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <div>
            <div className="flex justify-between">
              <p className={styles.yamlTitle}>YAML</p>
              {!yamlCodeLoading && (
                <section className={styles.action_group}>
                  {toggleYAMLEdit && (
                    <>
                      <IconButton
                        className="ml-auto"
                        onClick={() => {
                          // resetPolicyDetails();
                          handleChange(YamlCode?.yaml);
                          setToggleYAMLEdit(false);
                        }}
                        disableRipple
                      >
                        <CancelIcon fontSize="small" /> Cancel
                      </IconButton>
                      <span>
                        <IconButton
                          disabled={disable}
                          onClick={() => setShowCommitMessageModal()}
                          disableRipple
                        >
                          <SaveIcon fontSize="small" /> Save
                        </IconButton>
                      </span>
                      {/* </Tooltip> */}
                    </>
                  )}
                  {!toggleYAMLEdit && (
                    <span className="ml-auto">
                      <IconButton
                        disabled={
                          policyDetail?.category !== "Custom" && policyDetail?.category !== "custom"
                        }
                        onClick={() => setToggleYAMLEdit(true)}
                        disableRipple
                      >
                        <EditIcon fontSize="small" /> Edit
                      </IconButton>
                    </span>
                  )}
                  <Tooltip
                    placement="top"
                    title={policyDetail?.policy_kind === "NetworkPolicy" ? "Coming Soon" : ""}
                  >
                    <span className="ml-auto">
                      <IconButton
                        onClick={() => {
                          navigate(routes.policyEditor, {
                            selectedPolicy: policyDetail,
                            from: "clone"
                          });
                        }}
                        disabled={
                          policyDetail?.policy_kind !== "KubeArmorPolicy" &&
                          policyDetail?.policy_kind !== "KubeArmorHostPolicy"
                        }
                        disableRipple
                      >
                        <ContentCopyIcon fontSize="small" /> Clone
                      </IconButton>
                    </span>
                  </Tooltip>
                  <span className="ml-auto">
                    <IconButton onClick={handleDownloadPolicy} disabled={false} disableRipple>
                      <DownloadIcon fontSize="small" /> Download
                    </IconButton>
                  </span>
                </section>
              )}
            </div>
            {policyDetail?.category !== "Custom" && policyDetail?.category !== "custom" && (
              <div className="flex justify-center items-center text-sm	px-2">
                <Box
                  className="rounded-full flex justify-center items-center p-0.5 self-start mr-1"
                  sx={{ background: "#FEE4E2" }}
                >
                  <InfoOutlinedIcon fontSize="14px" sx={{ color: "#D92D20" }} />
                </Box>
                <Box>
                  Discovered / Hardening Policies are not editable. To modify, first clone this
                  policy then convert into custom policy
                </Box>
              </div>
            )}
            <div className={styles.yamlCodeContainer}>
              {yamlCodeLoading ? (
                <Loader />
              ) : (
                <YamlEditor
                  editable={toggleYAMLEdit}
                  setCode={e => handleChange(e)}
                  code={YamlCode?.yaml}
                  containerClass={styles.yamlContiner}
                  height="auto"
                />
              )}
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
}
