import React, { useEffect, useState } from "react";
import styles from "../VerifyMFA/styles.module.scss";

import { Button, Input } from "components/simple";
import AccuknoxLogoIcon from "../assets/AccuknoxLogo.png";
import rightTick from "../assets/right.png";
import wrongCross from "../assets/wrong.png";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AuthFooter from "../AuthFooter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Controller, useForm } from "react-hook-form";
import { useResetPasswordMutation, useValidateToken } from "store/entities/auth/mutations";
import { useQuery, navigate } from "helper/history";
import { routes } from "router";
import { usePasswordValidation } from "store/entities/auth/hooks";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    validatePassword,
    lowerCase,
    upperCase,
    number,
    minMax,
    specialCharacter
  } = usePasswordValidation();

  const params = useParams();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: { password: "", confirmPassword: "" },
    mode: "onChange"
  });
  const watchPassword = watch("password");
  const queryParams = useQuery();
  const { mutate: validateToken } = useValidateToken();
  const { mutate: resetPassword, isLoading: isResettingPassword } = useResetPasswordMutation();

  useEffect(() => {
    if (params?.uid || params?.userToken) {
      validateToken(params, {
        onError: data => navigate(routes.loginScreen)
      });
    } else {
      navigate(routes.loginScreen);
    }
  }, [params]);

  const onFormSubmit = formValues => {
    const { password } = formValues;
    resetPassword({ uid: params?.uid, token: params?.userToken, password });
  };

  const renderPasswordValidation = (value, label) => {
    return (
      <div className="flex justify-center items-center gap-[6px]">
        {value ? (
          <img src={rightTick} alt="" width="11px" height="9px" />
        ) : (
          <img src={wrongCross} alt="" width="11px" height="9px" />
        )}
        <p className="text-[#808080] text-[11px]">{label}</p>
      </div>
    );
  };

  const isFormValid = lowerCase && upperCase && number && minMax && specialCharacter && isValid;

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="mt-8">
          <img src={AccuknoxLogoIcon} width="311px" height="64px" alt="" />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.title}>Set new password</div>
          <div className={styles.description}>We are here to help!</div>

          <div className={styles.content}>
            <Controller
              name="password"
              rules={{
                required: true,
                validate: validatePassword
              }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className="relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    placeholder="Enter your password"
                    value={value}
                    onChange={onChange}
                    containerClassName={styles.inputcode}
                    className={`${styles.input}`}
                    autoFocus
                    required
                  />
                  <div className="text-right absolute right-4 -bottom-3 cursor-pointer">
                    <VisibilityIcon onClick={() => setShowPassword(show => !show)} />
                  </div>
                  <div className="flex justify-start items-center gap-3 w-full absolute top-[83px] left-1">
                    {renderPasswordValidation(lowerCase, "lower-case")}
                    {renderPasswordValidation(upperCase, "upper-case")}
                    {renderPasswordValidation(number, "number")}
                    {renderPasswordValidation(minMax, "8-15 characters")}
                    {renderPasswordValidation(specialCharacter, "special character")}
                  </div>
                </div>
              )}
            />
            <Controller
              name="confirmPassword"
              rules={{
                required: "Confirm Password is required",
                validate: value => value === watchPassword || "doesn't match"
              }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className="relative">
                  <Input
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm your password"
                    value={value}
                    onChange={onChange}
                    containerClassName={styles.inputcode}
                    className={`${styles.input}`}
                    required
                  />
                  {Boolean(errors?.confirmPassword?.message) && (
                    <div className="text-right absolute right-11 -bottom-5 flex items-center justify-center gap-1">
                      <p className="text-sm text-gray-400">{errors?.confirmPassword?.message}</p>
                      <CheckCircleRoundedIcon fontSize="24px" className="text-gray-400" />
                    </div>
                  )}
                  <div className="text-right absolute right-4 -bottom-3 cursor-pointer">
                    <VisibilityIcon onClick={() => setShowConfirmPassword(show => !show)} />
                  </div>
                </div>
              )}
            />
            <Button
              className={styles.button}
              type="submit"
              onClick={handleSubmit(onFormSubmit)}
              disabled={!isFormValid || isResettingPassword}
            >
              {isResettingPassword ? "Please wait" : "Confirm"}
            </Button>
          </div>
        </div>
      </form>
      <AuthFooter />
    </div>
  );
};

export default ResetPassword;
