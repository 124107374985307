import React from "react";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { formatDate } from "helper/DateLib";
import renderCellExpand from "helper/renderCellExpand";

const history = state => state.importHistory?.history;
const dataTypesList = state => state.importHistory?.dataTypesList;

const historyColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Data type",
    field: "data_type",
    resizable: true,
    minWidth: 210,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Errors",
    field: "errors",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: params => <div>{params.value?.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Scan date",
    field: "scan_date",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: params => <div>{formatDate(params.value)}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Scan file",
    field: "scan_file",
    resizable: true,
    minWidth: 155,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Assets",
    field: "assets",
    resizable: true,
    minWidth: 110,
    flex: 1,
    renderCell: params => <div>{params.value?.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Tags",
    field: "tags",
    resizable: true,
    minWidth: 200,
    flex: 1,
    renderCell: params => <div>{params.value?.length}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },

  {
    headerName: "Target",
    field: "target",
    resizable: true,
    minWidth: 200,
    flex: 1,
    renderCell: params => <div>{params.value?.name}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },

  {
    headerName: "Label",
    field: "label",
    resizable: true,
    minWidth: 200,
    flex: 1,
    renderCell: params => <div>{params.value?.name}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];
export const historySelector = createDraftSafeSelector(history, state => {
  if (!state?.results?.length) {
    return { columns: historyColumns, data: [], total: 0 };
  }
  const { results, count } = state;
  const data = results?.map(item => {
    return {
      assets: item?.assets,
      audit_files: item?.audit_files,
      data_type: item?.data_type,
      errors: item?.errors,
      id: item?.id,
      label: item?.label,
      scan_date: item?.scan_date,
      scan_file: item?.scan_file,
      tags: item?.tags,
      target: item?.target
    };
  });
  return { columns: historyColumns, data, total: count };
});

export const dataTypesListSelector = createDraftSafeSelector(dataTypesList, state => {
  if (!state?.length) {
    return [];
  }
  const data = state?.map(([key, name]) => {
    return {
      value: key,
      label: name
    };
  });
  return data;
});
