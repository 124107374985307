import React from "react";
import NOdata from "assets/images/nodata.svg";
import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import NoChart from "../Icon/Nochart";
export default function NoChartData({ height }) {
  return (
    <Box className="flex flex-col items-center justify-center  w-full h-full">
      <NoChart width={`80`} height={`80`} />
      <Typography sx={{ fontSize: "17px", fontWeight: "400" }}>No Graph data available!</Typography>
    </Box>
  );
}

NoChartData.defaultProps = {
  height: "auto"
};
