import React from "react";
import PropTypes from "prop-types";

export default function Delete(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.97998C6.33 5.64998 9.68 5.47998 13.02 5.47998C15 5.47998 16.98 5.57998 18.96 5.77998L21 5.97998M15.5 4.97L15.28 3.66C15.12 2.71 15 2 13.31 2L10.69 2C9 2 8.87 2.75 8.72 3.67L8.5 4.97M5.14997 9.13989L5.79998 19.2099C5.90998 20.7799 5.99998 21.9999 8.78998 21.9999H15.21C18 21.9999 18.09 20.7799 18.2 19.2099L18.85 9.13989M13.67 16.5L10.34 16.5M14.5 12.5H9.5"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Delete.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Delete.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 24,
  height: 24
};
