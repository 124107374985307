import React, { useState, useCallback } from "react";

import { useGridApiRef } from "@mui/x-data-grid-pro";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { routes } from "router";
import { findingsTable } from "./utils";
import { Table } from "components/complex";
import { ImagesDetailTable } from "./ImagesDetailsTable";
import { Button, ContentContainer, CustomPagination, Search } from "components/simple";
import { useFindingsSummarizedImagesQuery } from "store/entities/issues/registryScan/queries";

import { Box, Typography } from "@mui/material";
import NoTableData from "components/simple/Icon/NoTableData";

import { navigate } from "helper/history";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

export const Findings = ({ hasImages }) => {
  const [search, setSearch] = useState();
  const [page, setPage] = useState(0);

  const apiRef = useGridApiRef();
  const findingsSummarizedImagesQuery = useFindingsSummarizedImagesQuery(page + 1, search);

  const handleCellClick = (cellValue, event) => {
    event.stopPropagation();
    if (cellValue.field === "__detail_panel_toggle__") {
    }
  };

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <div
        className="pl-12 shadow-md"
        style={{
          borderRadius: "5px",
          marginBottom: "16px",
          border: "1px solid #D9D9D9",
          borderLeft: "5px solid #9CBEFF"
        }}
      >
        <ImagesDetailTable registryId={row?.registryId} repositoryName={row?.repositoryName} />
      </div>
    ),
    []
  );

  const getDetailPanelHeight = useCallback(() => "auto", []);

  return (
    <ContentContainer className="flex flex-col gap-2 p-4">
      <div className="flex justify-between">
        <Search onSearch={setSearch} value={search} />
        <Button onClick={() => navigate(routes.settingsIntegrationsRegistryAdd)}>
          Add Registry
        </Button>
      </div>

      <div>
        <Table
          // autoHeight
          apiRef={apiRef}
          customStyles={findingsTable?.styles}
          loading={findingsSummarizedImagesQuery?.isLoading}
          data={findingsSummarizedImagesQuery?.data?.images}
          columns={findingsTable?.headers}
          page={page}
          rowsPerPageOptions={[20]}
          paginationMode="server"
          onPageChange={p => setPage(p)}
          rowCount={findingsSummarizedImagesQuery?.data?.totalCount || 0}
          pageSize={20}
          // pageSize={findingsSummarizedImagesQuery?.data?.images?.length}
          checkboxVisible={false}
          getRowId={row => row?.id}
          onCellClick={handleCellClick}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          getRowClassName={params => `super-app-theme--rowData`}
          // components={{
          //   DetailPanelExpandIcon: KeyboardArrowRightIcon,
          //   DetailPanelCollapseIcon: KeyboardArrowDownIcon
          // }}
          components={{
            DetailPanelExpandIcon: KeyboardArrowRightIcon,
            DetailPanelCollapseIcon: KeyboardArrowDownIcon,
            NoRowsOverlay: () => (
              <Box className="flex flex-col items-center justify-center  w-full h-full">
                <NoTableData width={`40`} height={`40`} />
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  No data available!
                </Typography>
              </Box>
            ),
            Pagination: CustomPagination
          }}
          componentsProps={{
            pagination: {
              count: FindTotalPage(findingsSummarizedImagesQuery?.data?.totalCount, 20),
              onChange: (event, value) => {
                setPage(value - 1);
              },
              page: page + 1,
              totalCount: findingsSummarizedImagesQuery?.data?.totalCount || 0
            }
          }}
        />
      </div>
    </ContentContainer>
  );
};
