import React, { useState } from "react";

import { Controller } from "react-hook-form";
import { Input, ErrorText } from "components/simple";

export default function SecretKey({ isEdit, control, errors }) {
  const [isFocusedBefore, setIsFocusedBefore] = useState(false);

  return (
    <div className="w-full mb-3.5 mt-3">
      <Controller
        name="secretKey"
        rules={{
          required: { value: !isEdit, message: "Secret Key is required!" },
          pattern: {
            value:
              undefined ||
              "" ||
              "*****" ||
              /(?<![A-Za-z0-9/+=])[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=])/,
            message: "Enter valid Secret Key!"
          }
        }}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Input
            name="secretKey"
            label="Secret Key"
            placeholder="Enter Secret Key"
            required
            value={value}
            onFocus={evt => {
              onChange(isFocusedBefore ? evt.target.value : "");
              setIsFocusedBefore(true);
            }}
            onChange={val => {
              onChange(val);
            }}
          />
        )}
      />
      <div className="m-0.5">
        <ErrorText errorMsg={errors.secretKey && errors.secretKey.message} />
      </div>
    </div>
  );
}
