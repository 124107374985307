import { createDraftSafeSelector } from "@reduxjs/toolkit";

const cloudAccounts = state => state.cloudAccounts.cloudAccounts;
const awsRegions = state => state.cloudAccounts.awsRegions;
const statusModal = state => state.cloudAccounts.statusModal;
const deleteModal = state => state.cloudAccounts.deleteModal;
const editAccountInfo = state => state.cloudAccounts.editAccountInfo;
const setScanScheduleModal = state => state.cloudAccounts.setScanScheduleModal;

export const getCloudAccountsSelector = createDraftSafeSelector(cloudAccounts, state => ({
  total: state.count || 0,
  data: state.results || []
}));

export const getAwsRegionsSelector = createDraftSafeSelector(awsRegions, state => {
  if (state.length) {
    return state.map(item => ({ value: item, label: item }));
  }
  return [];
});

export const getAccountInfo = createDraftSafeSelector(editAccountInfo, state => {
  return state;
});

export const getStatusModal = createDraftSafeSelector(statusModal, state => state);

export const getDeleteModal = createDraftSafeSelector(deleteModal, state => state);

export const getSetScanScheduleModal = createDraftSafeSelector(
  setScanScheduleModal,
  state => state
);
