import { getAccuknoxWorkspace } from "api/api";
import { customGet, customPost } from "api/base";
import { useQuery } from "react-query";
import { getDifferenceTime, getTime } from "./query";
import moment from "moment";

export const callGetAllPolicy = (onSuccess, payload) => {
  const workspaceId = getAccuknoxWorkspace();
  return useQuery(["allPolicies", payload], async () => {
    const response = await customPost("/policymanagement/api/v1/policy/get-all-policies", payload);
    const res = await response.json();
    return res;
  });
};
export const uselistofAlertsClusterData = async (clusterid, range) => {
  const workspaceId = getAccuknoxWorkspace();
  const URL = "/dashboards/v1/telemetry/system/clusters/actions/count";
  let from;
  let to;
  const now = new Date();
  if (typeof range === "string") {
    from = getDifferenceTime(range, now).getTime();
    to = now.getTime();
  }
  if (typeof range === "object") {
    let modifiedPeriod = { ...range };
    if (moment(range.from).diff(range.to, "seconds") < 30) {
      modifiedPeriod.from = moment(range.from).startOf("day");
      modifiedPeriod.to = moment(range.to).endOf("day");
    }
    from = getTime(modifiedPeriod.from);
    to = getTime(modifiedPeriod.to);
  }
  let payload = {
    WorkspaceID: workspaceId?.toString(),
    FromTime: Math.floor(from / 1000),
    ToTime: Math.floor(to / 1000),
    ClusterID: [clusterid?.toString()],
    Namespace: [],
    PodName: []
  };
  const response = await customPost(URL, payload);
  const res = await response.json();
  return res;
};
export const uselistofAlertsNamespaceData = async (Namespaceid, Cluster, range) => {
  const workspaceId = getAccuknoxWorkspace();
  const URL = "/dashboards/v1/telemetry/system/namespaces/count";
  const now = new Date();
  let from;
  let to;
  if (typeof range === "string") {
    from = getDifferenceTime(range, now).getTime();
    to = now.getTime();
  }
  if (typeof range === "object") {
    let modifiedPeriod = { ...range };
    if (moment(range.from).diff(range.to, "seconds") < 30) {
      modifiedPeriod.from = moment(range.from).startOf("day");
      modifiedPeriod.to = moment(range.to).endOf("day");
    }
    from = getTime(modifiedPeriod.from);
    to = getTime(modifiedPeriod.to);
  }
  let payload = {
    WorkspaceID: workspaceId.toString(),
    FromTime: Math.floor(from / 1000),
    ToTime: Math.floor(to / 1000),
    ClusterID: [Cluster?.value?.toString()],
    Namespace: [Namespaceid?.toString()],
    PodName: []
  };

  const response = await customPost(URL, payload);
  const res = await response.json();
  return res;
};
// export const getAutoDiscoveredPolicies = async (
//   workspaceVal,
//   cluster_id,
//   pagePrevious,
//   pageNext,
//   namespace_id = [],
//   type = [],
//   ignore = "",
//   infra = "GKE",
//   label_type = "",
//   node_id,
//   pod_id
// ) => {
//   let filter = {};
//   if (infra === "GKE") {
//     filter = {
//       discover: {
//         used: ignore.includes("Used"),
//         ignore: ignore.includes("Ignore")
//       },
//       cluster_id: cluster_id || [],
//       namespace_id: namespace_id || [],
//       type: type || [],
//       infra: infra,
//       label_type: label_type,
//       node_id: node_id,
//       pod_id: pod_id
//     };
//   } else {
//     filter = {
//       discover: {
//         used: ignore.includes("Used"),
//         ignore: ignore.includes("Ignore")
//       },
//       instance_group_id: cluster_id,
//       instance_id: namespace_id,
//       type: type,
//       infra: infra
//     };
//   }
//   if (label_type === "") {
//     delete filter?.label_type;
//   }
//   const result = await customPost("/policymanagement/api/v1/auto-discover/get-policies", {
//     filter: filter,
//     workspace_id: workspaceVal || 846,
//     page_previous: pagePrevious || 0,
//     page_next: pageNext || 100
//   });
//   return result.json();
// };

export const getClusterDetail = async (clusterId, range) => {
  const URL = "/cm/api/v1/cluster-management/get-cluster-entity-info";
  const payload = {
    cluster_id: clusterId,
    from_time: [],
    to_time: []
  };
  const response = await customPost(URL, payload);
  const res = await response.json();
  return res?.result;
};

export const getPodDetail = async (podid, range) => {
  const URL = "/cm/api/v1/cluster-management/get-pod-meta-data-count";
  const payload = {
    pod_id: podid,
    from_time: [],
    to_time: []
  };
  const response = await customPost(URL, payload);
  const res = await response.json();
  return res?.result;
};

export const getWorkloadListApi = async () => {
  const URL = "/cm/api/v1/cluster-management/workloads";
  const response = await customGet(URL);
  const res = await response.json();
  return res;
};
