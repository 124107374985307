import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "../notifications/actions";

const getFilter = state => {
  return {
    page: state.vulnerabilities.page,
    pageSize: state.vulnerabilities.pageSize,
    ordering: state.vulnerabilities.ordering,
    search: state.vulnerabilities.search,
    params: state.vulnerabilities.temporaryData,
    filters: state.vulnerabilities.filters,
    groupBy: state.vulnerabilities.groupBy,
    data_type: state.vulnerabilities.data_type,
    asset: state.vulnerabilities.asset
  };
};

// Workers //

// GET Vulnerabilities
function* getVulnerabilitiesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getVulnerabilities), payload);
    yield put(actions.getVulnerabilitiesSuccess(response));
  } catch (error) {
    yield put(actions.getVulnerabilitiesFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// GET Vulnerability
function* getVulnerabilityWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getVulnerability), payload);
    yield put(actions.getVulnerabilitySuccess(response));
  } catch (error) {
    yield put(actions.getVulnerabilityFailed(error));
  }
}

// EDIT Vulnerability
function* editVulnerabilityWorker({ payload }) {
  const { id, data } = payload;
  try {
    const response = yield call(makeRequest(service.editVulnerability), data);
    yield put(actions.editVulnerabilitySuccess(response));
    yield put(actions.getVulnerability(id));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.editVulnerabilityFailed(error));
  }
}

// EDIT Finding
function* editFindingWorker({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(makeRequest(service.editFinding), data);
    yield put(actions.editFindingSuccess(response));
    yield put(actions.getVulnerability(data.id));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.editFindingFailed(error));
  }
}

// EDIT Finding asset
function* editFindingAssetWorker({ payload }) {
  const { data, findingId, callback } = payload;
  try {
    const response = yield call(makeRequest(service.editFinding), data);
    yield put(actions.editFindingAssetSuccess(response));
    if (callback) {
      callback();
    } else {
      yield put(actions.getVulnerabilityAssets({ id: findingId, label: payload?.label }));
    }
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.editFindingAssetFailed(error));
  }
}

// GET Finding statuses (options)
function* getFindingStatusesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getFindingStatuses), payload);
    yield put(actions.getFindingStatusesSuccess(response));
  } catch (error) {
    yield put(actions.getFindingStatusesFailed(error));
  }
}

// GET Vulnerability risk factors (options)
function* getVulnerabilityRiskFactorsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getVulnerabilityRiskFactors), payload);
    yield put(actions.getVulnerabilityRiskFactorsSuccess(response));
  } catch (error) {
    yield put(actions.getVulnerabilityRiskFactorsFailed(error));
  }
}

// GET Vulnerability history
function* getVulnerabilityHistoryWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getVulnerabilityHistory), payload);
    yield put(actions.getVulnerabilityHistorySuccess(response));
  } catch (error) {
    yield put(actions.getVulnerabilityHistoryFailed(error));
  }
}

// GET Vulnerability assets
function* getVulnerabilityAssetsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getVulnerabilityAssets), payload);
    yield put(actions.getVulnerabilityAssetsSuccess(response));
  } catch (error) {
    yield put(actions.getVulnerabilityAssetsFailed(error));
  }
}

// Set Vulnerabilities Params
function* setVulnerabilitiesParamsWorker() {
  const {
    page,
    pageSize,
    ordering,
    search,
    params,
    filters,
    groupBy,
    data_type,
    asset
  } = yield select(getFilter);
  yield put(
    actions.getVulnerabilities({
      page,
      pageSize,
      ordering,
      search,
      params,
      filters,
      groupBy,
      data_type,
      asset
    })
  );
}

// GET vulnerability configs
function* getVulnerabilityConfigsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getVulnerabilityConfigs), payload);
    yield put(actions.getVulnerabilityConfigsSuccess(response));
  } catch (error) {
    yield put(actions.getVulnerabilityConfigsFailed(error));
  }
}

function* getVulnerabilityConfigsFiltersDataWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getVulnerabilityConfigsFiltersData), payload);
    yield put(actions.getVulnerabilityConfigsFiltersDataSuccess(response));
  } catch (error) {
    yield put(actions.getVulnerabilityConfigsFiltersDataFailed(error));
  }
}

// EDIT vulnerability configs
function* editVulnerabilityConfigsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editVulnerabilityConfigs), payload);
    yield put(actions.editVulnerabilityConfigsSuccess(response?.name || ""));
    yield put(actions.getVulnerabilityConfigs());
    yield put(addNotification({ msg: "Vulnerability configurations successfully modified" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.editVulnerabilityConfigsFailed(error));
  }
}

// POST vulnerability configs
function* postVulnerabilityConfigsWorker({ payload }) {
  try {
    const { asset, target, ...rest } = payload;
    const data = {
      ...rest,
      asset_id: asset?.value || null,
      target_id: target?.value || null
    };

    const response = yield call(makeRequest(service.postVulnerabilityConfigs), data);
    yield put(actions.postVulnerabilityConfigsSuccess(response));
    yield put(actions.getVulnerabilityConfigs());

    const selectConfig = {
      label: response?.name,
      value: response?.id,
      result: {
        ...payload,
        display_fields_width: response?.table_properties?.display_fields_width ?? []
      }
    };

    const res = {
      page: 0,
      selectConfig,
      temporaryData: selectConfig?.result,
      ordering: "",
      search: "",
      filters: {},
      groupBy: null
    };
    yield put(actions.setVulnerabilitiesParams(res));

    yield put(addNotification({ msg: "Successfully added vulnerability configurations" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.postVulnerabilityConfigsFailed(error));
  }
}

// DELETE vulnerability configs
function* deleteVulnerabilityConfigsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteVulnerabilityConfigs), payload);
    yield put(actions.deletetVulnerabilityConfigsSuccess(response));
    yield put(actions.getVulnerabilityConfigs());
    yield put(addNotification({ msg: "Removed vulnerability configurations successfully" }));
  } catch (error) {
    yield put(actions.deleteVulnerabilityConfigsFailed(error));
  }
}

// ACTIONS ====================================

// GET TAGS
function* getTagsWorker() {
  try {
    const response = yield call(makeRequest(service.getTags));
    yield put(actions.getTagsSuccess(response.results));
  } catch (error) {
    yield put(actions.getTagsFailed(error));
  }
}

// ADD TAG
function* addTagWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addTag), payload);
    yield put(actions.addTagSuccess(response));
    yield put(actions.setVulnerabilitiesParams());
    yield put(actions.actionTagModal({ show: false, data: [] }));
    yield put(addNotification({ msg: "Tag added successfully" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addTagFailed(error));
  }
}

// GET GROUP
function* getGroupsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getGroups), payload);
    yield put(actions.getGroupsSuccess(response));
  } catch (error) {
    yield put(actions.getGroupsFailed(error));
  }
}
// ADD GROUP
function* addGroupWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addGroup), payload);
    yield put(actions.addGroupSuccess(response));
    yield put(actions.setVulnerabilitiesParams());
    yield put(actions.actionGroupModal({ show: false, data: [] }));
    yield put(addNotification({ msg: "Group added successfully" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addGroupFailed(error));
  }
}

// GET TARGET
function* getTargetsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTargets), payload);
    yield put(actions.getTargetsSuccess(response));
  } catch (error) {
    yield put(actions.getTargetsFailed(error));
  }
}

// GET TARGET Types
function* getTargetsTypesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTargetsTypes), payload);
    yield put(actions.getTargetsTypesSuccess(response));
  } catch (error) {
    yield put(actions.getTargetsTypesFailed(error));
  }
}

// ADD TARGET
function* addTargetWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addTarget), payload);
    yield put(actions.addTargetSuccess(response));
    yield put(actions.setVulnerabilitiesParams());
    yield put(actions.actionTargetModal({ show: false, data: [] }));
    yield put(addNotification({ msg: "Target added successfully" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addTargetFailed(error));
  }
}

// IGNORE FINDING
function* ignoreFindingWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.ignoreFinding), payload);
    yield put(actions.ignoreFindingSuccess(response));
    yield put(actions.setVulnerabilitiesParams());
    yield put(addNotification({ msg: "Ignore finding added successfully" }));
  } catch (error) {
    yield put(actions.ignoreFindingFailed(error));
  }
}

// EXPORT
function* exportVulnerabilityWorker({ payload }) {
  try {
    const fields = yield select(getFilter);
    const response = yield call(makeRequest(service.exportVulnerability), {
      fields,
      ...payload
    });

    yield put(actions.exportVulnerabilitySuccess());
    yield put(addNotification({ msg: response }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.exportVulnerabilityFailed(error));
  }
}

// UPD STATUS
function* updateStatusVulnerabilityWorker({ payload }) {
  try {
    const { data, callback } = payload;
    const response = yield call(makeRequest(service.updateStatusVulnerability), data);
    yield put(actions.updateStatusVulnerabilitySuccess(response));
    yield put(actions.setVulnerabilitiesParams());
    callback();
    yield put(addNotification({ msg: "Statuses updated successfully" }));
  } catch (error) {
    yield put(actions.updateStatusVulnerabilityFailed(error));
  }
}

// UPD VULNERABILITY
function* updateVulnerabilityWorker({ payload }) {
  try {
    const { data, callback } = payload;

    const statusData = {
      finding_ids: data?.finding_ids,
      status: data?.status,
      ignored: data?.ignored,
      id: data?.id
    };

    const vulData = {
      id: data?.vulnerability_id,
      description: data?.description,
      solution: data.solution,
      risk_factor: data.risk_factor
    };

    const [status, edit, vulnerability] = yield all([
      call(makeRequest(service.updateStatusVulnerability), statusData),
      call(makeRequest(service.editFinding), statusData),
      call(makeRequest(service.editVulnerability), vulData)
    ]);

    yield all([
      put(actions.editFindingSuccess(edit)),
      put(actions.editVulnerabilitySuccess(vulnerability)),
      put(actions.updateStatusVulnerabilitySuccess(status)),
      put(actions.getVulnerability(data?.id))
    ]);
    callback();
    yield put(addNotification({ msg: "Updated successfully" }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// ACTIONS END ====================================

// Workers End //

// Watchers //

// GET Vulnerabilities
function* watchGetVulnerabilitiesWorker() {
  yield takeEvery(actions.getVulnerabilities, getVulnerabilitiesWorker);
}

// GET one vulnerability
function* watchGetVulnerabilityWorker() {
  yield takeEvery(actions.getVulnerability, getVulnerabilityWorker);
}

// EDIT vulnerability
function* watchEditVulnerabilityWorker() {
  yield takeEvery(actions.editVulnerability, editVulnerabilityWorker);
}

// EDIT finding
function* watchEditFindingWorker() {
  yield takeEvery(actions.editFinding, editFindingWorker);
}

// EDIT finding asset
function* watchEditFindingAssetWorker() {
  yield takeEvery(actions.editFindingAsset, editFindingAssetWorker);
}

// GET vulnerability risk factors (options)
function* watchGetVulnerabilityRiskFactorsWorker() {
  yield takeEvery(actions.getVulnerabilityRiskFactors, getVulnerabilityRiskFactorsWorker);
}

// GET finding statuses (options)
function* watchGetFindingStatusesWorker() {
  yield takeEvery(actions.getFindingStatuses, getFindingStatusesWorker);
}

// GET vulnerability history
function* watchGetVulnerabilityHistoryWorker() {
  yield takeEvery(actions.getVulnerabilityHistory, getVulnerabilityHistoryWorker);
}

// GET vulnerability assets
function* watchGetVulnerabilityAssetsWorker() {
  yield takeEvery(actions.getVulnerabilityAssets, getVulnerabilityAssetsWorker);
}

// GET vulnerability configs
function* watchGetVulnerabilityConfigsWorker() {
  yield takeEvery(actions.getVulnerabilityConfigs, getVulnerabilityConfigsWorker);
}

function* watchGetVulnerabilityConfigsFiltersDataWorker() {
  yield takeEvery(
    actions.getVulnerabilityConfigsFiltersData,
    getVulnerabilityConfigsFiltersDataWorker
  );
}

// EDIT vulnerability configs
function* watchEditVulnerabilityConfigsWorker() {
  yield takeEvery(actions.editVulnerabilityConfigs, editVulnerabilityConfigsWorker);
}

// POST vulnerability configs
function* watchPostVulnerabilityConfigsWorker() {
  yield takeEvery(actions.postVulnerabilityConfigs, postVulnerabilityConfigsWorker);
}

// POST vulnerability configs
function* watchDeleteVulnerabilityConfigsWorker() {
  yield takeEvery(actions.deleteVulnerabilityConfigs, deleteVulnerabilityConfigsWorker);
}

// Set Vulnerabilities Params
function* watchSetVulnerabilitiesParamsWorker() {
  yield takeEvery(actions.setVulnerabilitiesParams, setVulnerabilitiesParamsWorker);
}

// ACTIONS ================

// GET TAGS
function* watchGetTagsWorker() {
  yield takeEvery(actions.getTags, getTagsWorker);
}

// ADD TAG
function* watchAddTagWorker() {
  yield takeEvery(actions.addTag, addTagWorker);
}

// GET GROUP
function* watchGetGroupsWorker() {
  yield takeEvery(actions.getGroups, getGroupsWorker);
}

// ADD GROUP
function* watchAddGroupWorker() {
  yield takeEvery(actions.addGroup, addGroupWorker);
}

// GET TARGET
function* watchGetTargetsWorker() {
  yield takeEvery(actions.getTargets, getTargetsWorker);
}

// GET TARGET TYPES
function* watchGetTargetsTypesWorker() {
  yield takeEvery(actions.getTargetsTypes, getTargetsTypesWorker);
}

// ADD TARGET
function* watchAddTargetWorker() {
  yield takeEvery(actions.addTarget, addTargetWorker);
}

// IGNORE FINDING
function* watchIgnoreFindingWorker() {
  yield takeEvery(actions.ignoreFinding, ignoreFindingWorker);
}

// EXPORT
function* watchExportVulnerabilityWorker() {
  yield takeEvery(actions.exportVulnerability, exportVulnerabilityWorker);
}

// UPD STATUS
function* watchUpdateStatusVulnerabilityyWorker() {
  yield takeEvery(actions.updateStatusVulnerability, updateStatusVulnerabilityWorker);
}

// UPD VULNERABILITY
function* watchUpdateVulnerability() {
  yield takeEvery(actions.updateCurrentVulnerability, updateVulnerabilityWorker);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetVulnerabilitiesWorker),
    fork(watchSetVulnerabilitiesParamsWorker),
    fork(watchGetVulnerabilityConfigsWorker),
    fork(watchGetVulnerabilityConfigsFiltersDataWorker),
    fork(watchEditVulnerabilityConfigsWorker),
    fork(watchPostVulnerabilityConfigsWorker),
    fork(watchGetVulnerabilityWorker),
    fork(watchGetVulnerabilityHistoryWorker),
    fork(watchGetVulnerabilityAssetsWorker),
    fork(watchEditVulnerabilityWorker),
    fork(watchDeleteVulnerabilityConfigsWorker),
    fork(watchEditFindingWorker),
    fork(watchEditFindingAssetWorker),
    fork(watchGetVulnerabilityRiskFactorsWorker),
    fork(watchGetFindingStatusesWorker),
    fork(watchGetTagsWorker),
    fork(watchAddTagWorker),
    fork(watchGetGroupsWorker),
    fork(watchAddGroupWorker),
    fork(watchGetTargetsWorker),
    fork(watchGetTargetsTypesWorker),
    fork(watchAddTargetWorker),
    fork(watchIgnoreFindingWorker),
    fork(watchExportVulnerabilityWorker),
    fork(watchUpdateStatusVulnerabilityyWorker),
    fork(watchUpdateVulnerability)
  ]);
}
