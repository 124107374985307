import React from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";
import styles from "./styles.module.scss";
import { routes } from "router";
import Item from "./Item";
import ItemDropdown from "./ItemDropdown";
import { useBasePath } from "helper/history";

import { Link, useHistory } from "react-router-dom";
import { logout } from "store/entities/users/actions";

import AccuknoxSidebarLogo from "assets/images/AccuknoxSidebarLogo.svg";

const Wrapper = styled("div")(({ theme }) => ({
  zIndex: 10,
  position: "fixed",
  // maxHeight: "100vh",
  height: "100vh",
  // overflowY: "scroll",
  background: theme.sideMenu.background
}));

export default function Sidebar() {
  const history = useHistory();
  const current = useBasePath();

  const dispatch = useDispatch();

  const isActive = r => {
    const currentRoute = current?.split("/")?.[1];
    const route = r.replaceAll("/", "");
    return currentRoute === route;
  };

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <Wrapper>
      <div className={`${styles["sidebar-container"]}`}>
        <Link to={routes.home}>
          <div className={`${styles.logo} ${styles.blue}`}>
            <img src={AccuknoxSidebarLogo} alt="logo" />
          </div>
        </Link>

        <div className={`${styles.sidebarWrapper}`}>
          <div className={styles["items-list"]}>
            <div style={{ overflow: "auto", height: "77vh" }}>
              <Item
                text="Dashboard"
                icon="dashboard2"
                active={isActive(routes.home)}
                onClick={() => history.push(routes.home)}
              />
              {/* <Item
              text="Cluster Manager"
              icon="clusterIcon"
              active={isActive(routes.clusterManagement)}
              onClick={() => history.push(routes.clusterManagement)}
            /> */}
              <ItemDropdown
                text="Inventory"
                icon="inventory"
                onClick={r => history.push(r)}
                list={[
                  {
                    name: "Assets",
                    route: routes.dataStructureAssets
                  },
                  {
                    name: "Cloud Workloads",
                    route: routes.clusterManagement
                  },
                  {
                    name: "Imports",
                    route: routes.dataStructureHistory
                  },
                  {
                    name: "Explorer",
                    route: routes.explorer
                  },
                  {
                    name: "Baselines",
                    route: routes.dataStructureBaselines
                  }
                ]}
              />
              <ItemDropdown
                text="Issues"
                icon="issues"
                onClick={r => history.push(r)}
                list={[
                  {
                    name: "Vulnerabilities",
                    route: routes.vulnerabilities
                  },
                  {
                    name: "Registry Scan",
                    route: routes.registryScan
                  },
                  {
                    name: "Risk-based Prioritization",
                    route: routes.riskBasedPrioritization
                  }
                ]}
              />
              <ItemDropdown
                text="Compliance"
                icon="compliance"
                onClick={r => history.push(r)}
                list={[
                  // {
                  //   name: "CSPM Exec Dashboard",
                  //   route: routes.vulnerabilities
                  // },
                  {
                    name: "Baselines",
                    route: routes.baselines
                  },
                  {
                    name: "CSPM Executive Dashboard",
                    route: routes.CSPM
                  }
                ]}
              />
              <ItemDropdown
                text="Runtime Protection"
                icon="runtime"
                onClick={r => history.push(r)}
                list={[
                  {
                    name: "CWPP Dashboard",
                    route: routes.CwppDashboard
                  },
                  {
                    name: "App Behavior",
                    route: routes.podObservability
                  },
                  {
                    name: "Policies",
                    route: routes.allPolicies
                  }
                  // {
                  //   name: "Auto Discovered Policies",
                  //   route: routes.autoDiscoveredPolicies
                  // },

                  // {
                  //   name: "Policy Editor Tool",
                  //   route: routes.policyEditor
                  // },
                  // {
                  //   name: "Policies Pending Approval",
                  //   route: routes.policiesPendingApproval
                  // }
                ]}
              />
              {/* <Item
                text="Collectors"
                icon="target"
                active={isActive(routes.collectors)}
                onClick={() => history.push(routes.collectors)}
              /> */}
              <ItemDropdown
                text="Remediation"
                icon="remediation"
                onClick={r => history.push(r)}
                list={[
                  // {
                  //   name: "Overview",
                  //   route: routes.overview
                  // },
                  {
                    name: "Ticket Summary",
                    route: routes.tickets
                  }
                ]}
              />

              <ItemDropdown
                text="Monitors / Logging"
                icon="monitor"
                onClick={r => history.push(r)}
                list={[
                  {
                    name: "Logs",
                    route: routes.alertsSummaryLogs
                  },
                  {
                    name: "Triggers",
                    route: routes.alertsSummaryTriggers
                  },
                  {
                    name: "Monitors",
                    route: routes.monitors
                  }
                ]}
              />
              {/* <ItemDropdown
              text="Logs Summary"
              icon="datapipeline"
              onClick={r => history.push(r)}
              list={[
                {
                  name: "Logs",
                  route: routes.alertsSummaryLogs
                },
                {
                  name: "Triggers",
                  route: routes.alertsSummaryTriggers
                }
              ]}
            /> */}

              {/* <ItemDropdown
              text="Data & Structure"
              icon="datastructure"
              onClick={r => history.push(r)}
              list={[
                {
                  name: "Import History",
                  route: routes.dataStructureHistory
                },
                {
                  name: "Datalist",
                  route: routes.dataStructureDatalist
                },
                {
                  name: "Assets",
                  route: routes.dataStructureAssets
                },
                {
                  name: "Baselines",
                  route: routes.dataStructureBaselines
                }
              ]}
            /> */}
              <Item
                text="Reports"
                icon="reports"
                active={isActive(routes.reports)}
                onClick={() => history.push(routes.reports)}
              />
              {/* <Item
              text="Baselines"
              icon="baselines"
              active={isActive(routes.baselines)}
              onClick={() => history.push(routes.baselines)}
            />
            <Item
              text="Tickets"
              icon="tickets"
              active={isActive(routes.tickets)}
              onClick={() => history.push(routes.tickets)}
            />
            
            <Item
              text="Monitors"
              icon="monitors"
              active={isActive(routes.monitors)}
              onClick={() => history.push(routes.monitors)}
            />
             */}
              <Item
                text="Notifications"
                icon="notifications"
                active={isActive(routes.notifications)}
                onClick={() => history.push(routes.notifications)}
              />
              <ItemDropdown
                text="Settings"
                icon="settings2"
                onClick={r => history.push(r)}
                list={[
                  {
                    name: "Cloud Accounts",
                    route: routes.settingsCloudAccounts
                  },
                  {
                    name: "Manage Cluster",
                    route: routes.settingsManageCluster
                  },
                  {
                    name: "User Management",
                    route: routes.settingsUserProfiles
                  },
                  {
                    name: "RBAC",
                    route: routes.settingsRbac
                  },
                  {
                    name: "Integrations",
                    route: routes.settingsIntegrations
                  },
                  // {
                  //   name: "Minions",
                  //   route: routes.settingsMinions
                  // },
                  {
                    name: "Labels",
                    route: routes.settingsLabels
                  },
                  {
                    name: "Tags",
                    route: routes.settingsTags
                  },
                  {
                    name: "Groups",
                    route: routes.settingsGroups
                  },
                  {
                    name: "Ticket Template",
                    route: routes.settingsTicketTemplates
                  }
                ]}
              />
            </div>
            <div style={{ position: "obsolute", bottom: 0 }}>
              <div className={styles.divide}></div>
              {/* <Item
              text="Settings"
              icon="settings"
              active={isActive(routes.settings)}
              onClick={() => history.push(routes.settings)}
            /> */}

              <Item text="Log Out" icon="logout" active={false} onClick={() => onLogout()} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

Sidebar.propTypes = {};

Sidebar.defaultProps = {};
