import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getDynamicSelect } from "store/entities/vulnerabilities/service";

import { ContentContainer, InputSearch, Select, Title, Loader } from "components/simple";

import styles from "./styles.module.scss";

const DataList = ({
  list,
  title,
  searchText,
  name,
  onSearch,
  onClick,
  onSelectChange,
  isLoading,
  header
}) => {
  const [optionsStatus, setOptionsStatus] = useState([]);
  const [optionsIgnored, setOptionsIgnored] = useState([]);
  const selectors = ["status", "ignored"];

  const loadOptionsRequest = async field => {
    try {
      const res = await getDynamicSelect({ field, page: 1 });
      const listOptions = Object.entries(res?.results).map(([key, val]) => {
        return { value: key, label: val };
      });
      if (field === "status") {
        setOptionsStatus(listOptions);
      } else {
        setOptionsIgnored(listOptions);
      }
    } catch (e) {
      // return console.log("err", e);
    }
  };

  useEffect(() => {
    if (name === "assets") {
      selectors.map(s => loadOptionsRequest(s));
    }
  }, []);

  const renderList = () => {
    if (name === "tickets") {
      return (
        <ul>
          {list.map(elem => (
            <li key={elem.id}>
              <Link to={`/tickets/details/${elem.id}`}>{elem.title}</Link>
            </li>
          ))}
        </ul>
      );
    }

    if (name === "assets") {
      return (
        <>
          <div className={`${styles.assetRow} ${styles.assetHead}`}>
            <span>Title</span>
            <span>Status</span>
            <span>Ignored</span>
          </div>
          <ul className={styles.scrollableWithHeader}>
            {list.map(({ ignored, id, asset, status }) => {
              const ignoredString = ignored.toString();
              const ignoredValue = ignoredString
                ? ignoredString[0].toUpperCase() + ignoredString.slice(1)
                : "";
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li key={id} className={styles.assetRow} onClick={() => onClick(asset)}>
                  <div className={styles.text} title={asset.title}>
                    {asset.title}
                  </div>
                  <Select
                    options={optionsStatus}
                    value={{ label: status, value: status }}
                    onChange={o => onSelectChange(o.value, "status", id)}
                    placeholder="Status"
                    className={styles.select}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    menuShouldBlockScroll
                    tableSelect
                  />
                  <Select
                    options={optionsIgnored}
                    value={{
                      label: ignoredValue,
                      value: ignoredValue
                    }}
                    onChange={o => onSelectChange(o.value, "ignored", id)}
                    placeholder="Ignored"
                    className={styles.select}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    menuShouldBlockScroll
                    tableSelect
                  />
                </li>
              );
            })}
          </ul>
        </>
      );
    }

    return (
      <ul>
        {list.map(elem => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li key={elem.id} onClick={() => onClick(elem)}>
            {elem.title}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <ContentContainer>
      <div className={styles.header}>
        <div className={styles.headerSection}>
          <Title>{title}</Title>
          <InputSearch
            value={searchText}
            onChange={val => onSearch(val, name)}
            placeholder="Search"
          />
        </div>
        {header && header}
      </div>

      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <div className={styles.scrollableList}>
          {list.length ? renderList(list) : <div className={styles.noData}>No data</div>}
        </div>
      )}
    </ContentContainer>
  );
};

export default DataList;
