import React from "react";
import PropTypes from "prop-types";
import Loader from "../Loader";
import Status from "../Status";
import Icon from "../Icon";

import styles from "./styles.module.scss";

const Box = ({ className, status, title, name, description, onClick, onDelete, loading }) => {
  const handleDelete = e => {
    e.stopPropagation();
    onDelete();
  };
  return (
    <div className={`${styles.box} ${className}`} onClick={onClick}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.delete} onClick={handleDelete}>
            <Icon.Delete />
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.name}>{name}</div>
          <div className={styles.description}>{description}</div>
          <Status connected={status} />
        </>
      )}
    </div>
  );
};

Box.propTypes = {
  className: PropTypes.string,
  status: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func
};

Box.defaultProps = {
  className: "",
  status: false,
  loading: false,
  name: "",
  title: "",
  description: "",
  onClick: () => {},
  onDelete: () => {}
};

export default Box;
