import * as moment from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

moment.extend(relativeTime);

export const formatDate = (date = new Date(), format = "YYYY-MM-DD") => {
  if (date === null) {
    return null;
  }
  if (date === "-") {
    return date;
  }
  return moment(date).format(format);
};

export const getTimeIntervalFromNow = value => {
  return moment(value).fromNow();
};

export const momentD = value => {
  return moment(value);
};

export const dayBetween = (v1, v2) => {
  const date1 = moment(v1);
  return date1.diff(v2, "day");
};
