import React, { useState } from "react";

import { routes } from "router/router";
import { navigate } from "helper/history";

import { UsersTable, ConfirmationModal, ViewPermissionsModal } from "./utils";
import {
  useGetUsersQuery,
  useDeleteUser,
  useDeactivateUser
} from "store/entities/userProfile/queries";
import ActionMenu from "components/simple/ActionMenu";

export const UsersInWorkspace = ({ roleDetailsMap, roleOptions, labelOptions, groupOptions }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const activeUsers = useGetUsersQuery(1);
  const { mutate: deleteUserMutation } = useDeleteUser();
  const { mutate: deactivateUser, isError } = useDeactivateUser();

  const MenuComponent = ({ user }) => {
    const menuItems = [
      {
        name: "View Permissions",
        onClick: () => {
          setShowPermissionModal(true);
        }
      },
      {
        name: "Edit",
        onClick: () => {
          navigate(routes.settingsUserProfilesDetails, {
            userDetails: user,
            roleOptions: roleOptions,
            labelOptions: labelOptions,
            groupOptions: groupOptions
          });
        }
      },
      {
        name: "Deactivate",
        onClick: () => {
          setDeactivateModal(true);
        }
      },
      {
        name: "Delete",
        onClick: () => {
          setDeleteModal(true);
        }
      }
    ];
    return (
      <ActionMenu
        onClick={() => {
          setSelectedUser(user);
        }}
        menuItems={user?.is_tenant_admin ? menuItems?.splice(0, 2) : menuItems}
      />
    );
  };

  return (
    <>
      {/* view permission modal */}
      {showPermissionModal && (
        <ViewPermissionsModal
          isActive={showPermissionModal}
          handleCancel={() => setShowPermissionModal(false)}
          selectedUserRoleDetails={roleDetailsMap[selectedUser?.role]}
          is_tenant_admin={selectedUser?.is_tenant_admin}
        />
      )}

      {/* deactivate modal */}
      <ConfirmationModal
        isActive={deactivateModal}
        heading="Are you sure you want to deactivate the user from workspace?"
        handleCancel={() => setDeactivateModal(false)}
        handleAction={() => {
          deactivateUser(selectedUser.id);
          setDeactivateModal(false);
        }}
        actionText="Deactivate"
      />

      {/* delete modal */}
      <ConfirmationModal
        isActive={deleteModal}
        heading="Are you sure you want to delete the user from workspace?"
        handleCancel={() => setDeleteModal(false)}
        handleAction={() => {
          deleteUserMutation(selectedUser.id);
          setDeleteModal(false);
          activeUsers?.refetch();
        }}
        actionText="Delete"
      />

      <UsersTable activeTab={0} users={activeUsers} MenuComponent={MenuComponent} />
    </>
  );
};
