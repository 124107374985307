import moment from "moment";

export const CountDataConversion = data => {
  let Alert = { data: [], keys: [] };

  _.forEach(data, function(value, key) {
    const name = Object.keys(value);
    const time = moment.unix(key).format("Do MMM YYYY");
    Alert.data.push({
      name: time,
      ...value
    });
    Alert.keys.push(name);
  });
  const flattenResult = _.flatten(Alert.keys);
  const List = _.uniq(flattenResult);
  return { data: Alert, keys: List };
};
