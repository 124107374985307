/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import AccuknoxLogoIcon from "../assets/AccuknoxLogo.png";
import { CircularProgress, Typography } from "@mui/material";
import { Input, Button } from "components/simple";
import Loader from "components/simple/Loader";
import { routes } from "router/router";
import { navigate } from "helper/history";
import VisibilityIcon from "@mui/icons-material/Visibility";
import rightTick from "../assets/right.png";
import wrongCross from "../assets/wrong.png";
import verified from "../assets/verified.png";
import notValid from "../assets/notValid.png";
import { useVerifyDomainQuery } from "store/entities/auth/queries";
import { useSignUpMutation } from "store/entities/auth/mutations";
import useDebounce from "helper/hooks/useDebounce";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import DoneIcon from "@mui/icons-material/Done";
import { emailRegexPattern } from "store/entities/auth/constants";
import { usePasswordValidation } from "store/entities/auth/hooks";
import { transformSubdomain } from "store/entities/auth/utils";

const SignUp = () => {
  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const {
    validatePassword,
    lowerCase,
    upperCase,
    number,
    minMax,
    specialCharacter
  } = usePasswordValidation();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange"
  });
  const [subDomainValue] = watch(["subdomain"]);
  const debouncedSubDomain = useDebounce(subDomainValue, 300);

  const { data: isSubdomainAvailable, isLoading: isSubdomainLoading } = useVerifyDomainQuery(
    transformSubdomain(debouncedSubDomain)
  );
  const {
    mutate: createUser,
    isLoading: isCreatingUser,
    isSuccess: isCreatingUserSuccess
  } = useSignUpMutation();

  const onSubmit = formValues => {
    const { firstName, lastName, email, password, organizationName, subdomain } = formValues;
    createUser({
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      organisation_name: organizationName,
      subdomain
    });
  };

  const renderAvailability = () => {
    if (subDomainValue) {
      if (isSubdomainLoading) {
        return null;
      }
      if (isSubdomainAvailable === undefined) {
        return null;
      }
      if (isSubdomainAvailable) {
        return (
          <div className="flex justify-center items-center gap-[5px] text-right absolute right-[14px] -bottom-[25px]">
            <img src={verified} alt="" width="23px" height="13px" />
            <p className="text-[#4EA892]">Available</p>
          </div>
        );
      } else {
        return (
          <div className="flex justify-center items-center gap-[5px] text-right absolute right-[14px] -bottom-[25px]">
            <img src={notValid} alt="" width="20px" height="20px" />
            <p className="text-[#F63E3E]">Already Exists</p>
          </div>
        );
      }
    }
    return null;
  };

  const renderPasswordValidation = (value, label) => {
    return (
      <div className="flex justify-center items-center gap-[6px]">
        {value ? (
          <img src={rightTick} alt="" width="11px" height="9px" />
        ) : (
          <img src={wrongCross} alt="" width="11px" height="9px" />
        )}
        <p className="text-[#808080] text-[11px]">{label}</p>
      </div>
    );
  };

  const isFormValid =
    lowerCase &&
    upperCase &&
    number &&
    minMax &&
    specialCharacter &&
    isSubdomainAvailable &&
    isValid;
  return (
    <div className={styles.container}>
      <div className="mt-8">
        <img src={AccuknoxLogoIcon} width="311px" height="64px" alt="Accuknox Logo" />
      </div>
      <div className="flex justify-center items-center">
        <div className={styles.authCard}>
          <Typography variant="h4" color="white" fontWeight={600}>
            Already a part of the Accuknox platform?
          </Typography>
          <Typography variant="body1" color="white">
            We will not take too much of your time. Quickly fill in your login information and get
            going!
          </Typography>
          <div className="w-full text-left">
            <Button onClick={() => navigate(routes.loginScreen)} className={styles.signUpBtn}>
              Sign In
            </Button>
          </div>
        </div>

        <div className={styles.formSignIn}>
          <div className={styles.title}>Sign Up</div>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex justify-between items-center gap-6 mt-4">
              <div className="flex justify-center items-center flex-col">
                <Controller
                  name="firstName"
                  rules={{ required: "First Name is required" }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="First Name"
                      type="text"
                      value={value}
                      onChange={val => onChange(val.target.value)}
                      containerClassName={styles.input}
                      className={styles.input}
                      placeholder="Enter First Name"
                      invalid={errors?.firstName?.message}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="lastName"
                  rules={{ required: "Last Name is required" }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Last Name"
                      type="text"
                      value={value}
                      onChange={val => onChange(val.target.value)}
                      containerClassName={styles.input}
                      className={styles.input}
                      placeholder="Enter Last Name"
                      invalid={errors?.lastName?.message}
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-full">
              <Controller
                name="email"
                rules={{
                  required: "Email Address is required",
                  pattern: {
                    value: emailRegexPattern,
                    message: "Invalid Email!"
                  }
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label="Email Address"
                    type="email"
                    value={value}
                    onChange={val => onChange(val.target.value)}
                    containerClassName={styles.input}
                    className={styles.input}
                    placeholder="Enter Email Address"
                    invalid={errors?.email?.message}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                name="organizationName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label="Organization Name"
                    value={value}
                    onChange={val => onChange(val.target.value)}
                    containerClassName={styles.input}
                    className={styles.input}
                    placeholder="Enter Organization Name"
                    invalid={errors?.organizationName?.message}
                  />
                )}
              />
            </div>
            <div className="w-full relative">
              <Controller
                name="subdomain"
                control={control}
                rules={{
                  pattern: {
                    value: /^[a-z0-9_._-]*$/,
                    message: "Subdomain should not contain special and upper case letter"
                  }
                }}
                DoneIcon
                render={({ field: { onChange, value } }) => (
                  <div className="relative">
                    <Input
                      label="Subdomain"
                      value={value}
                      onChange={val => onChange(val.target.value)}
                      containerClassName={styles.input}
                      className={styles.input}
                      placeholder="Enter Domain Name"
                      invalid={errors?.subdomain?.message}
                    />
                    {renderAvailability()}
                    <div className="flex justify-end items-center w-full absolute top-[72px]">
                      <p className="text-gray-500 font-medium text-sm">
                        {transformSubdomain(value)}
                      </p>
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="relative w-full">
              <Controller
                name="password"
                control={control}
                rules={{
                  required: true,
                  validate: validatePassword
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <Input
                    type={`${showCreatePassword ? "text" : "password"}`}
                    label="Create Password"
                    value={value}
                    ref={ref}
                    onChange={e => onChange(e.target.value)}
                    placeholder="Enter Your Password"
                    className={styles.createPassword}
                    containerClassName={styles.createpassword}
                    invalid={errors?.password?.message}
                  />
                )}
              />
              <div className="text-right absolute right-[14px] -bottom-3 cursor-pointer">
                <VisibilityIcon onClick={() => setShowCreatePassword(show => !show)} />
              </div>
              <div className="flex justify-start items-center gap-[10px] w-full absolute top-[83px]">
                <p className="text-[#808080] text-[11px]">Password must contain: </p>

                {renderPasswordValidation(lowerCase, "lower-case")}
                {renderPasswordValidation(upperCase, "upper-case")}
                {renderPasswordValidation(number, "number")}
                {renderPasswordValidation(minMax, "8-15 characters")}
                {renderPasswordValidation(specialCharacter, "special character")}
              </div>
            </div>
            <div className="w-full text-left mb-7 flex justify-start items-center gap-3 relative">
              <Button
                className={clsx(styles.button, isCreatingUser && "active")}
                type="submit"
                style={{ zIndex: "2" }}
                disabled={!isFormValid || isCreatingUser}
              >
                {isCreatingUserSuccess ? (
                  <div className="flex justify-center items-center gap-3">
                    <DoneIcon size={24} sx={{ color: "#fff" }} />
                    <p className="text-[#FFFFFF] font-[700]">Completed</p>
                  </div>
                ) : isCreatingUser ? (
                  <div className="flex justify-center items-center gap-3">
                    <Loader size={24} style={{ borderColor: "#fff" }} />
                    <p className="text-[#FFFFFF] font-[700]">Please Wait</p>
                  </div>
                ) : (
                  <>
                    <p className="text-[#FFFFFF] font-[700]">Get Started</p>
                  </>
                )}
                <AnimatePresence>
                  {isCreatingUser && (
                    <motion.div
                      key="bgShadow"
                      className="bg-[rgba(255,255,255,0.6)] flex justify-center items-center z-10 absolute right-0"
                      initial={{
                        x: "0",
                        y: "0",
                        height: "100%",
                        width: "95%",
                        zIndex: "0",
                        opacity: 0
                      }}
                      animate={{
                        opacity: !isCreatingUser ? 0 : 1,
                        zIndex: !isCreatingUser ? "0" : "10",
                        width: !isCreatingUser ? "0" : "95%",
                        x: isCreatingUser && "500px",
                        transition: {
                          opacity: { duration: 1 },
                          x: { duration: 20 },
                          type: "tween",
                          stiffness: 300
                        }
                      }}
                      exit={{
                        x: "500px",
                        transition: {
                          x: { duration: 4 }
                        }
                      }}
                    ></motion.div>
                  )}
                </AnimatePresence>
              </Button>

              <motion.p
                className="text-[#808080] opacity-100 font-[400] text-[12px] h-[3rem] flex justify-center items-center mt-[30px]"
                initial={{
                  opacity: 0,
                  zIndex: 0
                }}
                animate={{
                  opacity: !isCreatingUser ? 0 : 1,
                  zIndex: 15,
                  transition: {
                    opacity: { delay: 1 }
                  }
                }}
              >
                User creation usually takes upto a minute!
              </motion.p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
