import { dayBetween } from "../../../helper/DateLib";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Tooltip from "@mui/material/Tooltip";
import { OverflowTip } from "components/simple/OverflowTip/overFlowTip";
export const PRIORITYCOLOR = {
  Highest: "#BB0504",
  High: "#FF435F",
  Medium: "#FF8E50",
  Low: "#FEC051",
  Lowest: "#DADADA"
};

export const Prioritykeys = {
  "1": "Highest",
  "2": "High",
  "3": "Medium",
  "4": "Low",
  "5": "Lowest"
};
export const EmptyPriorityData = {
  Highest: 0,
  High: 0,
  Medium: 0,
  Low: 0,
  Lowest: 0
};
export const PriorityIcon = (priority, tablecol) => {
  let ColumnStyle = tablecol
    ? {
        color: `${PRIORITYCOLOR[priority]}`,
        fontSize: "18px"
      }
    : {
        color: `${PRIORITYCOLOR[priority]}`,
        fontSize: "16px",
        position: "relative"
      };
  if (priority === "Highest") {
    return <KeyboardDoubleArrowUpIcon style={ColumnStyle} />;
  } else if (priority === "High") {
    return <KeyboardArrowUpIcon style={ColumnStyle} />;
  } else if (priority === "Medium") {
    return <DragHandleIcon style={ColumnStyle} />;
  } else if (priority === "Low") {
    return <KeyboardArrowDownIcon style={ColumnStyle} />;
  } else if (priority === "Lowest") {
    return <KeyboardDoubleArrowDownIcon style={ColumnStyle} />;
  }
};
export const TopTicketsColumns = [
  {
    headerName: "TicketNumber",
    field: "desk_key",
    resizable: false,
    sortable: false,
    renderCell: params => {
      return (
        <OverflowTip placement="left-start">
          <span>{params?.row?.desk_key}</span>
        </OverflowTip>
      );
    },
    Width: 100,
    flex: 0.7
  },
  {
    headerName: "Summary",
    field: "title",
    resizable: false,
    Width: 200,
    sortable: false,
    renderCell: params => {
      return (
        <OverflowTip placement="left-start">
          <span>{params?.row?.title}</span>
        </OverflowTip>
      );
    },
    flex: 1.5
  },
  {
    headerName: "Priority",
    field: "priority",
    resizable: false,
    sortable: false,
    Width: 30,
    flex: 0.5,
    renderCell: params => {
      return (
        <div className="flex items-center">
          <Tooltip
            className="flex items-center"
            placement="left-start"
            title={Prioritykeys[params?.row?.priority]}
          >
            {PriorityIcon(Prioritykeys[params?.row?.priority])}
          </Tooltip>
        </div>
      );
    }
  },
  {
    headerName: "Age",
    field: "date",
    resizable: false,
    sortable: false,
    Width: 20,
    flex: 0.5,
    renderCell: params => {
      return <div>{`${params?.row?.date} D`}</div>;
    }
  }
];
export const priorityValue = (priorityVal, priorityOption) => {
  let priorityValue = priorityOption?.filter(e => e?.label === priorityVal);
  return priorityValue[0].value;
};
export const ConvertCapitalizeText = e => {
  return e.charAt(0).toUpperCase() + e?.slice(1);
};

export const PriorityOption = [
  { label: "Highest", value: "1" },
  { label: "High", value: "2" },
  { label: "Medium", value: "3" },
  { label: "Low", value: "4" },
  { label: "Lowest", value: "5" }
];

export const convertPriorityLabel = list => {
  const altObj = Object.fromEntries(
    Object.entries(list).map(([key, value]) => [`${Prioritykeys[key]}`, value])
  );
  const TotalPriority = Object.values(altObj)?.reduce((total, count) => {
    return total + count;
  }, 0);
  return { priorityList: { ...EmptyPriorityData, ...altObj }, Total: TotalPriority };
};

export const convertOpenTicketDetails = ticket => {
  let tickets = ticket?.sort((a, b) => {
    return new Date(a?.created_at) - new Date(b?.created_at);
  });
  let TranformDetails = tickets?.map(tDetail => ({
    ...tDetail,
    date: dayBetween(new Date(), tDetail?.created_at)
  }));
  let sortyPriority = PriorityOption?.map(p =>
    TranformDetails?.filter(t => p?.value === t?.priority)
  ).flat();
  return sortyPriority;
};
