/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, remove } from "api/base";
import { formatDate } from "helper/DateLib";

const generatorFilterUrl = obj => {
  if (!obj) {
    return "";
  }
  let url = "";

  Object.entries(obj).forEach(([key, val]) => {
    if (key === "date") {
      const after = val[0] ? formatDate(val[0]) : "";
      const before = val[1] ? formatDate(val[1]) : "";
      url += `&scan_date_before=${before}&scan_date_after=${after}`;
    }
    if (key === "label") {
      url += `&label_id=${val?.value || ""}`;
    }
    if (key === "dataTypes") {
      url += `&data_type=${val?.value || ""}`;
    }
  });
  return url;
};

export const getImportHistory = ({ page, pageSize, search, ordering, filters }) => {
  const filt = generatorFilterUrl(filters);
  return get(
    `${API}/scans?page=${page + 1 ||
      1}&page_size=${pageSize}&search=${search}&ordering=${ordering}${filt}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const deleteImportHistory = id => {
  return remove(
    `${API}/scans/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const addTag = fields => {
  return post(`${API}/scans/add-tag`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getDataTypes = () => {
  return get(`${API}/scans/data-types`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const runReport = () => {
  return post(`${API}/scans/run`, JSON.stringify({}), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getDetailsAsset = ({ id }) => {
  return get(`${API}/scans/${id}/assets-details`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
