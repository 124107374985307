import React from "react";
import Box from "@mui/material/Box";
import { Table } from "components/complex";
import CustomTableComponent from "./CustomTableSeverityComponent";

const rows = [
  {
    id: 1,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "Critical"
  },
  {
    id: 2,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "Low"
  },
  {
    id: 3,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "Critical"
  },
  {
    id: 4,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "Medium"
  },
  {
    id: 5,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "High"
  },
  {
    id: 6,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "Low"
  },
  {
    id: 7,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "High"
  },
  {
    id: 8,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "Low"
  },
  {
    id: 9,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "Medium"
  },
  {
    id: 10,
    flowVerdict: "DROPPED",
    timeStamps: "11-16-22 21:23 IST",
    flowSummary: "ICMPv6 143(0)",
    flowSource: "--",
    flowDestination: "--",
    severity: "Critical"
  }
];

const columns = [
  {
    field: "boxes",
    headerName: "",
    width: 2,
    sortable: false,
    renderCell: params => <CustomTableComponent row={params.row.severity.toLowerCase()} />
  },
  {
    field: "timeStamps",
    headerName: "Timestamps",
    width: 150
  },
  {
    field: "flowVerdict",
    headerName: "Flow Verdict",
    width: 150
  },
  {
    field: "flowSummary",
    headerName: "Flow Summary",
    sortable: true,
    width: 160
  },
  {
    field: "flowSource",
    headerName: "Flow Source",
    sortable: true,
    width: 160
  },
  {
    field: "flowDestination",
    headerName: "Flow Destination",
    sortable: true,
    width: 160
  },
  {
    field: "severity",
    headerName: "Severity",
    sortable: true,
    width: 160
  }
];

function CustomTable({onRowClick}) {
  return (
    <>
      <Box sx={{ height: 920, width: "100%" }}>
        <Table columns={columns} rows={rows} checkboxVisible={false} pagination sortingMod onRowClick={onRowClick} />
      </Box>
    </>
  );
}
export default CustomTable;
