import { useQuery } from "react-query";
import { getQRMFA, verifyDomain } from "./service";
import { useDispatch } from "react-redux";
import { addNotification } from "../notifications/actions";

export const useQRMFAQuery = () => {
  const userId = localStorage.getItem("userId");
  const accessToken = localStorage.getItem("accessToken");
  return useQuery(["Authentication", "getQRMFA"], () => getQRMFA(userId, accessToken));
};

export const useVerifyDomainQuery = subDomain => {
  const dispatch = useDispatch();

  return useQuery(
    ["auth", "verify-sub-domain", subDomain],
    () => verifyDomain({ domain: subDomain }),
    {
      enabled: Boolean(subDomain),
      select: data => data?.is_available ?? false,
      onError: data => {
        dispatch(
          addNotification({
            msg: "There was an error while verifying the subdomain",
            type: "error"
          })
        );
      }
    }
  );
};
