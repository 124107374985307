import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

// Set Monitors params
export const setMonitorsParams = createAction(constant.SET_MONITORS_PARAMS);

// Get Monitors
export const getMonitors = createAction(constant.GET_MONITORS);
export const getMonitorsSuccess = createAction(constant.GET_MONITORS_SUCCESS);
export const getMonitorsFailed = createAction(constant.GET_MONITORS_FAILED);

// Get Monitor
export const getMonitor = createAction(constant.GET_MONITOR);
export const getMonitorSuccess = createAction(constant.GET_MONITOR_SUCCESS);
export const getMonitorFailed = createAction(constant.GET_MONITOR_FAILED);

// Create MONITOR
export const createMonitor = createAction(constant.CREATE_MONITOR);
export const createMonitorSuccess = createAction(constant.CREATE_MONITOR_SUCCESS);
export const createMonitorFailed = createAction(constant.CREATE_MONITOR_FAILED);

// Delete MONITOR
export const deleteMonitor = createAction(constant.DELETE_MONITOR);
export const deleteMonitorSuccess = createAction(constant.DELETE_MONITOR_SUCCESS);
export const deleteMonitorFailed = createAction(constant.DELETE_MONITOR_FAILED);

// Get Monitor Mini
export const getMonitorsMini = createAction(constant.GET_MONITORS_MINI);
export const getMonitorsMiniSuccess = createAction(constant.GET_MONITORS_MINI_SUCCESS);
export const getMonitorsMiniFailed = createAction(constant.GET_MONITORS_MINI_FAILED);

// Set Monitor Config Params
export const setMonitorConfigParams = createAction(constant.SET_MONITOR_CONFIG_PARAMS);
// UPD Monitor Config Params
export const updMonitorConfigParams = createAction(constant.UPD_MONITOR_CONFIG_PARAMS);
export const clearMonitorConfigParams = createAction(constant.CLEAR_MONITOR_CONFIG_PARAMS);

// getMonitorConfigFilters
export const getMonitorConfigFilters = createAction(constant.GET_MONITOR_CONFIG_FILTERS);
export const getMonitorConfigFiltersSuccess = createAction(
  constant.GET_MONITOR_CONFIG_FILTERS_SUCCESS
);
export const getMonitorConfigFiltersFailed = createAction(
  constant.GET_MONITOR_CONFIG_FILTERS_FAILED
);

// Edit Monitor
export const editMonitor = createAction(constant.EDIT_MONITOR);
export const editMonitorSuccess = createAction(constant.EDIT_MONITOR_SUCCESS);
export const editMonitorFailed = createAction(constant.EDIT_MONITOR_FAILED);

// Duplicate Monitor
export const duplicateMonitor = createAction(constant.DUPLICATE_MONITOR);
export const duplicateMonitorSuccess = createAction(constant.DUPLICATE_MONITOR_SUCCESS);
export const duplicateMonitorFailed = createAction(constant.DUPLICATE_MONITOR_FAILED);

export const getMonitorConfigTable = createAction(constant.GET_MONITOR_CONFIG_TABLE);
export const getMonitorConfigTableSuccess = createAction(constant.GET_MONITOR_CONFIG_TABLE_SUCCESS);
export const getMonitorConfigTableFailed = createAction(constant.GET_MONITOR_CONFIG_TABLE_FAILED);

// add-control
export const addControlMonitorConfigurationTable = createAction(
  constant.ADD_CONTROL_MONITOR_CONFIG_TABLE
);
export const addControlMonitorConfigurationTableSuccess = createAction(
  constant.ADD_CONTROL_MONITOR_CONFIG_TABLE_SUCCESS
);
export const addControlMonitorConfigurationTableFailed = createAction(
  constant.ADD_CONTROL_MONITOR_CONFIG_TABLE_FAILED
);

// delete-control
export const deleteControlMonitorConfigurationTable = createAction(
  constant.DELETE_CONTROL_MONITOR_CONFIG_TABLE
);
export const deleteControlMonitorConfigurationTableSuccess = createAction(
  constant.DELETE_CONTROL_MONITOR_CONFIG_TABLE_SUCCESS
);
export const deleteControlMonitorConfigurationTableFailed = createAction(
  constant.DELETE_CONTROL_MONITOR_CONFIG_TABLE_FAILED
);

// Create monitor row
export const createMonitorRow = createAction(constant.CREATE_MONITOR_ROW);
export const createMonitorRowSuccess = createAction(constant.CREATE_MONITOR_ROW_SUCCESS);
export const createMonitorRowFailed = createAction(constant.CREATE_MONITOR_ROW_FAILED);

// Delete monitor row
export const deleteMonitorRow = createAction(constant.DELETE_MONITOR_ROW);
export const deleteMonitorRowSuccess = createAction(constant.DELETE_MONITOR_ROW_SUCCESS);
export const deleteMonitorRowFailed = createAction(constant.DELETE_MONITOR_ROW_FAILED);

// Set  Assign-hosts params
export const setAssignHostsParams = createAction(constant.SET_ASSIGN_HOSTS_PARAMS);

// Set  Assign-groups params
export const setAssignGroupsParams = createAction(constant.SET_ASSIGN_GROUPS_PARAMS);

// Set Monitor History Params
export const setMonitorHistoryParams = createAction(constant.SET_MONITOR_HISTORY_PARAMS);

// Get Monitor History
export const getMonitorHistory = createAction(constant.GET_MONITOR_HISTORY);
export const getMonitorHistorySuccess = createAction(constant.GET_MONITOR_HISTORY_SUCCESS);
export const getMonitorHistoryFailed = createAction(constant.GET_MONITOR_HISTORY_FAILED);

// Get Assets-mini
export const getMonitorAssetsMini = createAction(constant.GET_ASSETS_MINI);
export const getMonitorAssetsMiniSuccess = createAction(constant.GET_ASSETS_MINI_SUCCESS);
export const getMonitorAssetsMiniFailed = createAction(constant.GET_ASSETS_MINI_FAILED);

// Get groups
export const getMonitorGroups = createAction(constant.GET_MONITOR_GROUPS);
export const getMonitorGroupsSuccess = createAction(constant.GET_MONITOR_GROUPS_SUCCESS);
export const getMonitorGroupsFailed = createAction(constant.GET_MONITOR_GROUPS_FAILED);

// Add Monitor asset
export const addMonitorAsset = createAction(constant.ADD_MONITOR_ASSET);
export const addMonitorAssetSuccess = createAction(constant.ADD_MONITOR_ASSET_SUCCESS);
export const addMonitorAssetFailed = createAction(constant.ADD_MONITOR_ASSET_FAILED);

// Delete Monitor asset
export const deleteMonitorAsset = createAction(constant.DELETE_MONITOR_ASSET);
export const deleteMonitorAssetSuccess = createAction(constant.DELETE_MONITOR_ASSET_SUCCESS);
export const deleteMonitorAssetFailed = createAction(constant.DELETE_MONITOR_ASSET_FAILED);

// Add Monitor group
export const addMonitorGroup = createAction(constant.ADD_MONITOR_GROUP);
export const addMonitorGroupSuccess = createAction(constant.ADD_MONITOR_GROUP_SUCCESS);
export const addMonitorGroupFailed = createAction(constant.ADD_MONITOR_GROUP_FAILED);

// Delete Monitor group
export const deleteMonitorGroup = createAction(constant.DELETE_MONITOR_GROUP);
export const deleteMonitorGroupSuccess = createAction(constant.DELETE_MONITOR_GROUP_SUCCESS);
export const deleteMonitorGroupFailed = createAction(constant.DELETE_MONITOR_GROUP_FAILED);
