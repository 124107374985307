import React, { useEffect, useState } from "react";
import SpinnerLoader from "./SpinnerLoader";
import SuccessLoader from "./SuccessLoader";
import ErrorLoader from "./ErrorLoader";
import { INVALID_YAML, INVALID_YAML1, VALID_YAML, VALID_YAML1 } from "./constants";

const ValidationLoader = ({ validationValue, isValid, type }) => {
  const [showSpinner, setShowSpinner] = useState();

  useEffect(() => {
    setShowSpinner(true);
    const timeoutId = setTimeout(() => {
      setShowSpinner(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [validationValue]);

  if (showSpinner) {
    return (
      <>
        <SpinnerLoader />
        <p className="text-xs font-medium text-gray-800">Validating</p>
      </>
    );
  }
  return isValid ? (
    <>
      <SuccessLoader />
      <p className="text-xs font-medium text-gray-800">
        {type === "PolicyEditorTool" ? VALID_YAML : VALID_YAML1}
      </p>
    </>
  ) : (
    <>
      <ErrorLoader />
      <p className="text-xs font-medium text-gray-800">
        {type === "PolicyEditorTool" ? INVALID_YAML : INVALID_YAML1}

        {/* { INVALID_YAML1} */}
      </p>
    </>
  );
};

export default ValidationLoader;
