import React from "react";

import { Divider, IconButton } from "@mui/material";
import { ActionButton, Button, Icon, Input, Select, Switch } from "components/simple";

import styles from "../styles.module.scss";

export const DetailsModalForm = ({
  data,
  status,
  value,
  options,
  ticketConfigOptions,
  selectTicketConfig,
  handleTicketConfig,
  handleSelect,
  handleSwitch,
  handleSubmit,
  handleMore,
  handleAction,
  toTickets,
  groupIds
}) => {
  return (
    <form className={styles["modal-details__form"]}>
      <div className={styles["modal-details__form-action"]}>
        <span>Expected Result: </span>
        <Select
          value={status}
          onChange={handleSelect}
          options={options}
          containerClass={styles["modal-details__form-action-field"]}
        />
      </div>
      <div className={styles["modal-details__form-action"]}>
        <span>Tickets: </span>
        <Input
          readOnly
          value={data?.tickets?.ids?.length || 0}
          containerClassName={styles["modal-details__form-action-field"]}
        />
        <IconButton
          onClick={toTickets}
          className={styles["modal-details__form-action-redirect"]}
          disableRipple
          disableTouchRipple
          disableFocusRipple
        >
          <Icon.RedirectArrow />
        </IconButton>
      </div>
      <Divider className={styles["modal-divider-sm"]} />
      {!!groupIds?.length && (
        <div className={styles["modal-details__form-action"]}>
          <span>Apply Actual to Group: </span>
          <div className={styles["modal-details__form-action-switch"]}>
            <Switch
              value={value}
              onChange={handleSwitch}
              className={styles["modal-details__form-switch-item"]}
            />
          </div>
        </div>
      )}
      <Divider className={styles["modal-divider-sm"]} />
      <div className={styles["modal-details__form-action-btn"]}>
        <Button onClick={handleSubmit} className={styles["modal-details__form-action-btn-save"]}>
          Apply
        </Button>

        <Divider className={styles["modal-divider-sm"]} />
        {ticketConfigOptions?.length > 0 && (
          <>
            <div className={styles["modal-details__form-action-container"]}>
              <Select
                containerClass={styles.select}
                onChange={handleTicketConfig}
                value={selectTicketConfig}
                placeholder="Ticket Configuration"
                options={ticketConfigOptions}
                isClearable
              />
              <ActionButton
                type="createTicket"
                onClick={handleAction}
                className={styles.actionButton}
              />
            </div>
            <Divider className={styles["modal-divider-sm"]} />
          </>
        )}
        <Button onClick={handleMore} className={styles["modal-details__form-action-btn-more"]}>
          Show more
        </Button>
      </div>
    </form>
  );
};
