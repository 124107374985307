/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, patch, remove } from "api/base";
import { formatDate } from "helper/DateLib";

const generatorFilterUrl = obj => {
  if (!obj) {
    return "";
  }
  let url = "";
  Object.entries(obj).forEach(([key, val]) => {
    if (key === "last_seen" || key === "date_discovered" || key === "present_on_date") {
      if (key === "last_seen") {
        const last_seen_after = val[0] ? formatDate(val[0]) : "";
        const last_seen_before = val[1] ? formatDate(val[1]) : "";
        url += `&last_seen_before=${last_seen_before}&last_seen_after=${last_seen_after}`;
      }
      if (key === "date_discovered") {
        const date_discovered_after = val[0] ? formatDate(val[0]) : "";
        const date_discovered_before = val[1] ? formatDate(val[1]) : "";
        url += `&date_discovered_before=${date_discovered_before}&date_discovered_after=${date_discovered_after}`;
      }
      if (key === "present_on_date") {
        const present_on_date_after = val[0] ? formatDate(val[0]) : "";
        const present_on_date_before = val[1] ? formatDate(val[1]) : "";
        url += `&present_on_date_before=${present_on_date_before}&present_on_date_after=${present_on_date_after}`;
      }
    } else {
      url += `&${key}=${val?.value}`;
    }
  });
  return url;
};

export const getVulnerabilities = fields => {
  const { page, pageSize, ordering, search, params, filters, groupBy, data_type, asset } = fields;

  let url = "";

  if (params?.target?.value || params?.target?.id) {
    url += `&target_id=${params?.target?.value || params?.target?.id}`;
  }

  if (asset?.value || asset?.id) {
    url += `&asset_id=${asset?.value || asset?.id}`;
  }

  if (data_type) {
    url += `&data_type=${data_type}`;
  }

  if (groupBy?.value) {
    url += `&group_by=${groupBy?.value}`;
  }

  const filterUrl = generatorFilterUrl(filters);

  return get(
    `${API}/findings?page=${page + 1 || 1}&ordering=${ordering}&search=${search ??
      ""}&page_size=${pageSize}${url}${filterUrl}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getSubVulnerabilitiesTable = fields => {
  const {
    temporaryData,
    filters,
    groupBy,
    data_type,
    asset,
    groupByValue,
    search,
    pageSize,
    page
  } = fields;
  let url = "";

  if (data_type) {
    url += `&data_type=${data_type}`;
  }
  if (temporaryData?.target?.value || temporaryData?.target?.id) {
    url += `&target_id=${temporaryData?.target?.value || temporaryData?.target?.id}`;
  }
  if (asset?.value || asset?.id) {
    url += `&asset_id=${asset?.value || asset?.id}`;
  }
  if (search) {
    url += `&search=${search}`;
  }

  if (groupByValue) {
    url += `&${groupBy?.value}=${groupByValue}`;
  }

  const filterUrl = generatorFilterUrl(filters);

  return get(
    `${API}/findings/collapsed-data?group_by=${groupBy?.value}${url}${filterUrl}&page_size=${pageSize}&page=${page}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getVulnerabilityConfigs = () => {
  return get(`${API}/vulnerability-configs`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getVulnerabilityConfigsFiltersData = fields => {
  const url = fields?.data_type ? `?data_type=${fields?.data_type}` : "";

  return get(`${API}/vulnerability-configs/filters-data${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const editVulnerabilityConfigs = fields => {
  const { id, ...rest } = fields;
  return patch(`${API}/vulnerability-configs/${id}`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const postVulnerabilityConfigs = fields => {
  return post(`${API}/vulnerability-configs`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteVulnerabilityConfigs = ({ id }) => {
  return remove(
    `${API}/vulnerability-configs/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getVulnerability = id => {
  return get(`${API}/findings/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const editVulnerability = fields => {
  const { id, ...rest } = fields;
  return patch(`${API}/vulnerabilities/${id}`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editFinding = fields => {
  const { id, ...rest } = fields;
  return patch(`${API}/findings/${id}`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getVulnerabilityHistory = id => {
  return get(`${API}/findings/${id}/history`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getVulnerabilityAssets = fields => {
  const id = fields?.id;
  const label = fields?.label;
  const url = label ? `?label_id=${label}` : "";
  return get(`${API}/findings/${id}/same-vuln-assets${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getDynamicSelect = async fields => {
  const params = fields?.params;
  const field = fields?.field;
  const search = fields?.search || "";
  const page = fields?.page || "";

  const data_type = fields?.data_type;
  const asset = fields?.asset;

  let url = "";

  if (params?.target?.value || params?.target?.id) {
    url += `&target_id=${params?.target?.value || params?.target?.id}`;
  }

  if (data_type) {
    url += `&data_type=${data_type}`;
  }
  if (asset?.value || asset?.id) {
    url += `&asset_id=${asset?.value || asset?.id}`;
  }
  if (search) {
    url += `&field-search=${search}`;
  } else {
    url += `&page=${page}`;
  }

  return get(`${API}/findings/filter-options?field=${field}${url}`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};

export const getVulnerabilityRiskFactors = () => {
  return get(`${API}/vulnerabilities/risk-factors`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

// ACTIONS
export const getTags = () => {
  return get(`${API}/tags?page_size=2000`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addTag = fields => {
  return post(`${API}/findings/add-tag`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getGroups = () => {
  return get(`${API}/groups`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addGroup = fields => {
  return post(`${API}/findings/add-to-group`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getTargets = () => {
  return get(`${API}/targets`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTargetsTypes = () => {
  return get(`${API}/targets/data-types`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getFindingStatuses = () => {
  return get(`${API}/findings/statuses`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getOneTargetTypes = id => {
  return get(`${API}/target-types/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};

export const addTarget = fields => {
  return post(`${API}/findings/add-to-target`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const ignoreFinding = fields => {
  const finding_ids = fields;
  return post(`${API}/findings/ignore`, JSON.stringify({ finding_ids }), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const exportVulnerability = ({ fields, displayFields, ids }) => {
  const { ordering, search, params, filters, groupBy, data_type, asset } = fields;
  let url = "";

  if (data_type) {
    url += `&data_type=${data_type}`;
  }

  if (params?.target?.value || params?.target?.id) {
    url += `&target_id=${params?.target?.value || params?.target?.id}`;
  }

  if (asset?.value || asset?.id) {
    url += `&asset_id=${asset?.value || asset?.id}`;
  }

  if (params?.target) {
    url += `&target_id=${params?.target}`;
  }
  if (groupBy?.value) {
    url += `&group_by=${groupBy?.value}`;
  }
  if (displayFields?.length) {
    let res = "";
    displayFields?.forEach(item => {
      res += `&display_fields=${item}`;
    });
    url += res;
  }

  const filterUrl = generatorFilterUrl(filters);

  if (ids?.length) {
    return post(
      `${API}/findings/export`,
      JSON.stringify({
        ids,
        display_fields: displayFields,
        data_type
      }),
      {
        ...contentTypeJson(),
        ...authorization(),
        ...authorizationTenant()
      }
    );
  }

  return get(
    `${API}/findings/export?ordering=${ordering}&search=${search ?? ""}${url}${filterUrl}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const updateStatusVulnerability = fields => {
  return post(`${API}/findings/update-status`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};
