import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { addNotification } from "../notifications/actions";
import { errorHandler } from "helper/errorHandler";

const getGroupsParams = state => {
  return {
    page: state.groups.groupsParams.page,
    pageSize: state.groups.groupsParams.pageSize,
    ordering: state.groups.groupsParams.ordering,
    search: state.groups.groupsParams.search
  };
};

const getGroupAssetsParams = state => {
  return {
    page: state.groups.groupAssetsParams.page,
    pageSize: state.groups.groupAssetsParams.pageSize,
    ordering: state.groups.groupAssetsParams.ordering,
    search: state.groups.groupAssetsParams.search,
    groupId: state.groups.groupAssetsParams.groupId
  };
};

// Workers //

// Set Groups params
function* setGroupsParamsWorker() {
  const { page, pageSize, ordering, search } = yield select(getGroupsParams);
  yield put(
    actions.getGroups({
      page,
      pageSize,
      ordering,
      search
    })
  );
}

// Set Group-assets params
function* setGroupAssetsParamsWorker() {
  const { page, pageSize, ordering, search, groupId } = yield select(getGroupAssetsParams);
  yield put(
    actions.getGroupAssets({
      page,
      pageSize,
      ordering,
      search,
      groupId
    })
  );
}

// Get Group assets
function* getGroupAssetsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getGroupAssets), payload);
    yield put(actions.getGroupAssetsSuccess(response));
  } catch (error) {
    yield put(actions.getGroupAssetsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Groups
function* getGroupsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getGroups), payload);
    yield put(actions.getGroupsSuccess(response));
  } catch (error) {
    yield put(actions.getGroupsFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Group
function* getGroupWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getGroup), payload);
    yield put(actions.getGroupSuccess(response));
  } catch (error) {
    yield put(actions.getGroupFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Create Group
function* createGroupWorker({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield call(makeRequest(service.createGroup), data);
    yield put(actions.createGroupSuccess(response));
    yield put(actions.setGroupsParams());
    yield put(
      addNotification({
        msg: `Group created successfully`
      })
    );
    callback();
  } catch (error) {
    yield put(actions.createGroupFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Update Group
function* updateGroupWorker({ payload }) {
  const { callback, ...rest } = payload;
  try {
    const response = yield call(makeRequest(service.updateGroup), rest);
    yield put(actions.updateGroupSuccess(response));
    yield put(
      addNotification({
        msg: `Group updated successfully`
      })
    );
    yield put(actions.setGroupAssetsParams());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(actions.updateGroupFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete Groups
function* deleteGroupsWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteGroup), id)));
    yield put(actions.deleteGroupSuccess(response));
    yield put(actions.setGroupsParams());
    yield put(
      addNotification({
        msg: `${ids?.length > 1 ? "Groups" : "Group"} successfully deleted`
      })
    );
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.deleteGroupFailed(error));
  }
}

// Get Groups Mini
function* getGroupsMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getGroupsMini), payload);
    yield put(actions.getGroupsMiniSuccess(response));
  } catch (error) {
    yield put(actions.getGroupsMiniFailed(error));
  }
}

// Workers End //

// Watchers //

// Set Groups params
function* watchSetGroupsParamsWorker() {
  yield takeEvery(actions.setGroupsParams, setGroupsParamsWorker);
}

// Set Group-assets params
function* watchSetGroupAssetsParamsWorker() {
  yield takeEvery(actions.setGroupAssetsParams, setGroupAssetsParamsWorker);
}

// Get Group assets
function* watchGetGroupAssetsWorker() {
  yield takeEvery(actions.getGroupAssets, getGroupAssetsWorker);
}

// Get Groups
function* watchGetGroupsWorker() {
  yield takeEvery(actions.getGroups, getGroupsWorker);
}

// Get Group
function* watchGetGroupWorker() {
  yield takeEvery(actions.getGroup, getGroupWorker);
}

// Create Group
function* watchCreateGroupWorker() {
  yield takeEvery(actions.createGroup, createGroupWorker);
}

// Update Group
function* watchUpdateGroupWorker() {
  yield takeEvery(actions.updateGroup, updateGroupWorker);
}

// Delete Group
function* watchDeleteGroupsWorker() {
  yield takeEvery(actions.deleteGroup, deleteGroupsWorker);
}

// Get Groups Mini
function* watchGetGroupsMiniWorker() {
  yield takeEvery(actions.getGroupsMini, getGroupsMiniWorker);
}

// Watchers End //

export default function* rootSaga() {
  yield all([
    fork(watchGetGroupsWorker),
    fork(watchGetGroupsMiniWorker),
    fork(watchGetGroupWorker),
    fork(watchCreateGroupWorker),
    fork(watchUpdateGroupWorker),
    fork(watchDeleteGroupsWorker),
    fork(watchSetGroupsParamsWorker),
    fork(watchSetGroupAssetsParamsWorker),
    fork(watchGetGroupAssetsWorker)
  ]);
}
