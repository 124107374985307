export const useSplunkIntegrationURL = () => `/v2/integrations/splunk`;

export const useSplunkIntegrationURLV3 = () => `/v3/integrations/splunk`;

export const useSplunkAppIntegrationURL = () => `/workflowapp/splunkAppChannel`;

export const getSlackIntegrationURL = () => `/v2/integrations/slack`;

export const getSlackIntegrationURLV3 = () => `/v3/integrations/slack`;

export const getJiraAccountConfiguration = () => `/datapipelineapi/jiraAuth`;

export const getJiraIntegrationURL = () => `/v2/integrations/jira`;

export const getJiraIntegrationURLV3 = () => `/v3/integrations/jira`;

export const getElasticSearchIntegrationURL = () => `/v2/integrations/elasticsearch`;

export const getRsyslogIntegrationURL = () => `/v2/integrations/rsyslog`;

export const getRsyslogIntegrationURLV3 = () => `/v3/integrations/rsyslog`;

export const getCloudwatchIntegrationURL = () => `/v2/integrations/cloudwatchlogs`;

export const getCloudwatchIntegrationURLV3 = () => `/v3/integrations/cloudwatchlogs`;

export const getChannelIntegrationList = () => `/datapipelineapi/integrationlist`;

export const getChannelIntegrationListV3 = () => `/v3/integrations/getintegrations`;
// Fetch Channel List
export const getFetchChannelList = () => `/datapipelineapi/fetchchannel`;

export const getChannelDetails = () => `/datapipelineapi/channelintegrationdetails`;

export const getChannelDetailsV3 = () => `/v3/integrations/getintegrationdetails`;

export const getEditSplunkChannel = () => `/datapipelineapi/editsplunkchannel`;

export const getEditSlackChannel = () => `/datapipelineapi/editslackchannel`;

export const getEditCloudwatchChannel = () => `/datapipelineapi/editCloudwatchChannel`;

export const getDeleteChannel = () => `/datapipelineapi/deleteChannelIntegration`;

export const getDeleteChannelV3 = () => `/v3/integrations/deleteintegration`;

export const getslacktest = () => `/datapipelineapi/sendTestMessageToSlack`;

export const getsplunktest = () => `/datapipelineapi/sendTestMessageToSplunk`;

export const getCloudWatchtest = () => `/datapipelineapi/sendTestMessageToCloudwatch`;

export const getJiratest = () => `/datapipelineapi/TestJiraTicketCreation`;

export const getElasticTest = () => `/datapipelineapi/sendTestMessageToElkStack`;

export const getRsyslogTest = () => `/datapipelineapi/sendTestMessageToRsyslog`;
