/* eslint-disable no-shadow */
import { getAccuknoxWorkspace } from "api/api";
import { getUserId } from "store/entities/auth/utils";
import { customPost } from "api/base";
import { customDelete } from "api/base";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { addNotification } from "../notifications/actions";
import {
  COMBINATION_DELETE_MSG,
  COMBINATION_IGNORE_MSG,
  CUSTOM_DELETE_MSG,
  CUSTOM_IGNORE_MSG,
  DISCOVERED_HARDEN_DELETE_MSG,
  DISCOVERED_HARDEN_IGNORE_MSG,
  DISCOVERED_HARDEN_ONLY_IGNORE_MSG
} from "./constants";

export const useGetYamlMutation = () => {
  return useMutation(async pID => {
    const workspaceId = getAccuknoxWorkspace();
    const payload = {
      workspace_id: workspaceId,
      policy_id: pID
    };
    const response = await customPost(
      "/policymanagement/api/v2/rule/get-policy-json-yaml",
      payload
    );
    const res = await response.json();
    return res;
  });
};

export const useApplyPolicyMutation = () => {
  return useMutation(
    async obj => {
      const workspaceId = getAccuknoxWorkspace();
      const payload = {
        workspace_id: workspaceId,
        ...obj
      };
      const res = await customPost("/policymanagement/api/v1/auto-discover/add-policy", payload);
      // const res = await response?.json();
      return res;
    },
    {
      onError: err => {
        // console.error(err, "err");
      }
    }
  );
};

export const useGetIsPolicyPushedMutation = policyId => {
  return useMutation(async () => {
    const response = await customPost(
      `/policymanagement/api/v1/policy/is-pushed-policy?policy_id=${policyId}`
    );
    const res = await response.json();
    return res;
  });
};

export const useIgnorePolicyMutation = () => {
  return useMutation(
    async obj => {
      const payload = {
        ...obj
      };
      const res = customPost(
        "/policymanagement/api/v1/auto-discover/enable-policies-ignore-flag",
        payload
      );
      // const res = await response.json();
      return res;
    },
    {
      onError: err => {
        // console.error(err, "err");
      }
    }
  );
};
export const UpdatePolicy = (onSuccess = () => {}, policy_id, workspaceID) => {
  return useMutation(
    async ({ policy_id, workspaceId }) => {
      const payload = {
        id: policy_id,
        updated_by: workspaceId.toString()
      };
      const response = await customPost("/policymanagement/v2/policy/discovered/update", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess
    }
  );
};
export const useEditPolicyMutation = (
  policy_id,
  label_type,
  onSuccess = () => {},
  onError = () => {}
) => {
  const workspaceId = getAccuknoxWorkspace();

  return useMutation(
    async ({ description, label_id, policy_yaml, comment }) => {
      const payload = {
        description,
        workspace_id: workspaceId,
        policy_id,
        created_by: "30d4caa0-0c48-4d2c-874d-0edb1a950a43",
        label_type,
        policy_yaml,
        comment,
        label_id
      };
      const response = await customPost("/policymanagement/api/v1/policy/update-policy", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess,
      onError
    }
  );
};
export const workspaceID = getAccuknoxWorkspace();

export const useChangePolicyStatusMutation = (onSuccess = () => {}) => {
  return useMutation(
    async ({ policy_id, status }) => {
      const payload = {
        workspace_id: getAccuknoxWorkspace(),
        policy_id,
        status,
        updated_by: getUserId()
      };
      const response = await customPost(
        "/policymanagement/api/v2/policy/change-status-policy",
        payload
      );
      const res = await response.json;
      return res;
    },
    {
      onSuccess: () => {
        onSuccess();
      }
    }
  );
};

export const useAddTagsToPoliciesMutation = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ policy_id, tags, newTags }) => {
      const payload = {
        created_by: getUserId(),
        policy_id,
        tag_id: [...tags],
        tag_name: [...newTags],
        workspace_id: getAccuknoxWorkspace()
      };
      const response = await customPost("/labelmanagement/api/v2/tag/add-tags", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Adding tags failed", type: "error" }));
      }
    }
  );
};

export const useDeletePolicyMutation = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ policy_id }) => {
      const payload = { workspace_id: getAccuknoxWorkspace(), policy_id, updated_by: getUserId() };
      const response = await customPost("/policymanagement/api/v2/policy/delete-policy", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Deleting failed", type: "error" }));
      }
    }
  );
};

export const useMultipleDeletePolicyMutation = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ policy_id }) => {
      const payload = { policy_id, updated_by: getUserId() };
      const response = await customDelete("/policymanagement/v2/policy", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: response => {
        if (response?.message === DISCOVERED_HARDEN_DELETE_MSG) {
          dispatch(
            addNotification({
              msg: DISCOVERED_HARDEN_DELETE_MSG,
              type: "error"
            })
          );
        } else if (response?.message === COMBINATION_DELETE_MSG) {
          dispatch(addNotification({ msg: CUSTOM_DELETE_MSG, type: "success" }));
          setTimeout(() => {
            dispatch(
              addNotification({
                msg: DISCOVERED_HARDEN_DELETE_MSG,
                type: "error"
              })
            );
          }, 4000);
        } else if (response?.message === CUSTOM_DELETE_MSG) {
          dispatch(addNotification({ msg: CUSTOM_DELETE_MSG, type: "success" }));
        }
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Deleting failed", type: "error" }));
      }
    }
  );
};

export const usePolicyIgnoreMutation = (onSuccess = () => {}, onError = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ policy_id }) => {
      const payload = { policy_id, updated_by: getUserId() };
      const response = await customPost("/policymanagement/v2/policy/ignore", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: response => {
        if (response?.message === COMBINATION_IGNORE_MSG) {
          dispatch(
            addNotification({
              msg: DISCOVERED_HARDEN_IGNORE_MSG,
              type: "success"
            })
          );
          setTimeout(() => {
            dispatch(
              addNotification({
                msg: CUSTOM_IGNORE_MSG,
                type: "error"
              })
            );
          }, 4000);
        } else if (response?.message === DISCOVERED_HARDEN_ONLY_IGNORE_MSG) {
          dispatch(
            addNotification({
              msg: DISCOVERED_HARDEN_ONLY_IGNORE_MSG,
              type: "success"
            })
          );
        }

        onSuccess();
      },
      onError: response => {
        onError();
        if (response.status === 502) {
          dispatch(addNotification({ msg: CUSTOM_IGNORE_MSG, type: "error" }));
        } else {
          dispatch(addNotification({ msg: "Ignore failed", type: "error" }));
        }
      }
    }
  );
};

export const usePolicyStatusMutation = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ policy_id, status }) => {
      const payload = { id: policy_id, status: status, updated_by: getUserId() };
      const response = await customPost("/policymanagement/v2/policy/status", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Status update failed", type: "error" }));
      }
    }
  );
};

export const usePolicyApplyMutation = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ policy_id }) => {
      const payload = { policy_id, updated_by: getUserId() };
      const response = await customPost("/policymanagement/v2/policy/apply", payload);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Apply failed", type: "error" }));
      }
    }
  );
};

export const useDeleteTagsMutation = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ policy_id, tag_id }) => {
      const payload = {
        workspace_id: getAccuknoxWorkspace(),
        policy_id,
        tag_id: [tag_id]
      };
      const response = await customPost(
        "/labelmanagement/api/v2/tag/delete-tag-with-policy",
        payload
      );
      const res = await response.json();
      return res;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Deleting tags failed", type: "error" }));
      }
    }
  );
};

export const useCreatePolicyMutation = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    ["createPolicy"],
    async payload => {
      const response = await customPost("/policymanagement/api/v1/policy/create-policy", payload);
      const result = await response.json();

      return result;
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        dispatch(addNotification({ msg: "Creatng Policy failed", type: "error" }));
      }
    }
  );
};
