export const API = `${window.__env__.REACT_APP_CSPM_URL}/api/v1`;
export const ACCUKNOX_API = `${window.__env__.REACT_APP_CWPP_URL}`;

export const authorization = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
};

export const accuknoxAuth = () => {
  return {
    authorization: `Bearer ${localStorage.getItem("accuknoxToken")}`
  };
};

export const getAccuknoxWorkspace = () => {
  const tenantID = sessionStorage.getItem("tenant")
    ? JSON.parse(sessionStorage.getItem("tenant"))
    : JSON.parse(localStorage.getItem("tenant"));
  return Number(tenantID?.value);
};

export const getTenantId = () => {
  const tenantID = sessionStorage.getItem("tenant")
    ? JSON.parse(sessionStorage.getItem("tenant"))
    : JSON.parse(localStorage.getItem("tenant"));
  return {
    "X-Tenant-Id": `${tenantID?.value}`
  };
};

export const authorizationTenant = () => {
  const tenantID = sessionStorage.getItem("tenant")
    ? JSON.parse(sessionStorage.getItem("tenant"))
    : JSON.parse(localStorage.getItem("tenant"));

  return {
    "Tenant-Id": `${tenantID?.value}`
  };
};

export const contentTypeJson = () => {
  return { "Content-Type": "application/json" };
};
