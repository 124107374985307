/* eslint-disable camelcase */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { ContentContainer, Title, Box, Button } from "components/simple";
import {
  getJiraCloudSelector,
  getJiraServerSelector,
  getFreshserviceSelector,
  getEmailBackendsSelector,
  getConnectWiseSelector
} from "store/entities/settings/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";
import {
  getJiraServer,
  getJiraCloud,
  getFreshservice,
  getEmailProfile,
  getConnectWise,
  addModalConnector,
  deleteJiraServer,
  deleteJiraCloud,
  deleteFreshservice,
  deleteEmailProfile,
  deleteConnectWise
} from "store/entities/settings/actions";

import NoDataAvailable from "../../../../assets/images/nodata.svg";
import AddConnectorModal from "./AddConnectorModal";
import { routes } from "router";

import styles from "./styles.module.scss";
import NoTableRowData from "components/complex/Table/NoTableRowData";

const Integrations = ({ history }) => {
  const selectTenant = useSelector(state => state.users.selectTenant);
  const dispatch = useDispatch();

  const jiraCloud = useSelector(getJiraCloudSelector);
  const jiraServer = useSelector(getJiraServerSelector);
  const freshservice = useSelector(getFreshserviceSelector);
  const emailBackends = useSelector(getEmailBackendsSelector);
  const connectWise = useSelector(getConnectWiseSelector);

  useEffect(() => {
    dispatch(getJiraServer());
    dispatch(getJiraCloud());
    dispatch(getFreshservice());
    dispatch(getEmailProfile());
    dispatch(getConnectWise());
  }, [selectTenant]);

  const isLoadingJiraServer = useSelector(state =>
    createLoadingSelector([getJiraServer.type])(state)
  );
  const isLoadingJiraCloud = useSelector(state =>
    createLoadingSelector([getJiraCloud.type])(state)
  );
  const isLoadingFreshservice = useSelector(state =>
    createLoadingSelector([getFreshservice.type])(state)
  );
  const isLoadingEmailBackends = useSelector(state =>
    createLoadingSelector([getEmailProfile.type])(state)
  );
  const isLoadingConnectWise = useSelector(state =>
    createLoadingSelector([getConnectWise.type])(state)
  );

  const goToJiraServer = id => {
    if (id) {
      history.push(`${routes.settingsIntegrationsJiraServer}/${id}`);
    } else {
      history.push(routes.settingsIntegrationsJiraServer);
    }
  };

  const goToJiraCloud = id => {
    if (id) {
      history.push(`${routes.settingsIntegrationsJiraCloud}/${id}`);
    } else {
      history.push(routes.settingsIntegrationsJiraCloud);
    }
  };

  const goToFreshservice = id => {
    if (id) {
      history.push(`${routes.settingsIntegrationsFreshservice}/${id}`);
    } else {
      history.push(routes.settingsIntegrationsFreshservice);
    }
  };

  const goToConnectWise = id => {
    if (id) {
      history.push(`${routes.settingsIntegrationsConnectWise}/${id}`);
    } else {
      history.push(routes.settingsIntegrationsConnectWise);
    }
  };

  const goToEmailBackends = id => {
    if (id) {
      history.push(`${routes.settingsIntegrationsEmailsDetails}/${id}`);
    }
  };

  const onDelete = (type, id) => {
    switch (type) {
      case "jiraServer":
        dispatch(deleteJiraServer({ id }));
        break;
      case "jiraCloud":
        dispatch(deleteJiraCloud({ id }));
        break;
      case "freshservice":
        dispatch(deleteFreshservice({ id }));
        break;
      case "emailBackends":
        dispatch(deleteEmailProfile());
        break;
      case "connectwiseprofile":
        dispatch(deleteConnectWise({ id }));
        break;
      default:
        break;
    }
  };

  const onAddConnector = () => {
    dispatch(addModalConnector(true));
  };

  return (
    <>
      <ContentContainer className={styles.container}>
        <div className={styles.head}>
          <Title>Ticketing Backends</Title>
          <Button icon="plus" onClick={() => onAddConnector()}>
            Add connector
          </Button>
        </div>
        <div className={styles.content}>
          {jiraServer.length > 0
            ? jiraServer?.map(item => (
                <Box
                  status={item?.connected}
                  key={item?.id}
                  title="Jira Server"
                  name={item?.name}
                  description={`Last sync: ${item?.last_sync}`}
                  onClick={() => goToJiraServer(item?.id)}
                  onDelete={() => onDelete("jiraServer", item?.id)}
                  loading={isLoadingJiraServer}
                />
              ))
            : null}
          {jiraCloud.length > 0
            ? jiraCloud?.map(item => (
                <Box
                  status={item?.connected}
                  key={item?.id}
                  title="Jira Cloud"
                  name={item?.name}
                  description={`Last sync: ${item?.last_sync}`}
                  onClick={() => goToJiraCloud(item?.id)}
                  onDelete={() => onDelete("jiraCloud", item?.id)}
                  loading={isLoadingJiraCloud}
                />
              ))
            : null}
          {freshservice.length > 0
            ? freshservice?.map(item => (
                <Box
                  status={item?.connected}
                  key={item?.id}
                  title="Freshservice"
                  name={item?.name}
                  description={`Last sync: ${item?.last_sync}`}
                  onClick={() => goToFreshservice(item?.id)}
                  onDelete={() => onDelete("freshservice", item?.id)}
                  loading={isLoadingFreshservice}
                />
              ))
            : null}
          {connectWise.length > 0
            ? connectWise?.map(item => (
                <Box
                  status={item?.connected}
                  key={item?.id}
                  title="ConnectWise"
                  name={item?.name}
                  description={`Last sync: ${item?.last_sync}`}
                  onClick={() => goToConnectWise(item?.id)}
                  onDelete={() => onDelete("connectwiseprofile", item?.id)}
                  loading={isLoadingConnectWise}
                />
              ))
            : null}
          {jiraServer.length === 0 &&
          jiraCloud.length === 0 &&
          freshservice.length === 0 &&
          connectWise.length === 0 ? (
            <>
              <div></div>
              <NoTableRowData />
            </>
          ) : null}
        </div>
      </ContentContainer>
      {emailBackends ? (
        <ContentContainer className={styles.container}>
          <Title>Email Backends</Title>
          <div className={styles.content}>
            <Box
              status
              title="Email"
              name={emailBackends?.name}
              description={`Last sync: ${emailBackends?.last_sync}`}
              onClick={() => goToEmailBackends(emailBackends?.id)}
              onDelete={() => onDelete("emailBackends", emailBackends?.id)}
              loading={isLoadingEmailBackends}
            />
          </div>
        </ContentContainer>
      ) : null}
      <AddConnectorModal emailBackends={Boolean(emailBackends)} />
    </>
  );
};

Integrations.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default Integrations;
