import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_POLICY_TEMPLATE, DEFAULT_POLICY_YAML, K8S, VM } from "./constants";
import {
  updateActiveCardReducer,
  updateSelectedClusterReducer,
  updateSelectedNamespaceReducer,
  updateSelectedInstanceGroupReducer,
  updateSelectedInstanceReducer,
  updateIsEditingPolicyMetadataReducer,
  handlePolicyMetadataFormSubmitReducer,
  handleAddRuleReducer,
  updateIsEditingProcessRuleReducer,
  removeRuleFromPolicyJSONReducer,
  updateShowProcessMandateParentPathReducer,
  handleProcessRuleFormSubmitReducer,
  updateProcessRuleDataReducer,
  updateFileRuleDataReducer,
  updateShowFileMandateParentPathReducer,
  updateIsEditingFileRuleReducer,
  updateIsEditingNetworkRuleReducer,
  updateNetworkRuleDataReducer,
  updateShowNetworkMandateParentPathReducer,
  updatePolicyJSONReducer,
  deleteIndividualRuleReducer,
  handleFileRuleFormSubmitReducer,
  handleNetworkRuleFormSubmitReducer,
  updateCurrentPolicyReducer,
  resetStateReducer,
  updateUnknownPolicyValuesReducer,
  updateShowRulesPaletteReducer,
  updateShowProcessRuleCardReducer,
  updateShowFileRuleCardReducer,
  updateShowNetworkRuleCardReducer,
  updateSelectedEntityReducer,
  updateUnsupportedRulesReducer
} from "./reducers";
import { useSelector } from "react-redux";

export const KUBEARMOR_POLICY_EDITOR_SLICE = "kubeArmorPolicyEditor";
export const KUBEARMOR_POLICY_EDITOR_INITIAL_STATE = {
  selectedEntity: K8S,
  selectedCluster: null,
  selectedNamespace: null,
  selectedInstanceGroup: null,
  selectedInstance: null,
  policyJSON: DEFAULT_POLICY_TEMPLATE,
  policyYAML: DEFAULT_POLICY_YAML,
  isValidYAML: null,
  activeCard: null,
  isCreatingPolicy: false,
  isUpdatingPolicy: false,
  currentPolicy: {},
  unknownPolicyValues: {},
  unsupportedRules: [],
  isEditingPolicyMetadata: true,
  showRulesPaletteCard: false,
  processRule: {
    // isAddingProcessRule
    isEditingProcessRule: true,
    processRuleData: [],
    showProcessRuleCard: false,
    showProcessMandateParentPath: false
  },
  fileRule: {
    // isAddingFileRule
    fileRuleData: [],
    isEditingFileRule: true,
    showFileRuleCard: false,
    showFileMandateParentPath: false
  },
  // isAddingNetworkRule
  networkRule: {
    networkRuleData: [],
    isEditingNetworkRule: true,
    showNetworkRuleCard: false,
    showNetworkMandateParentPath: false
  }
};

const kubeArmorPolicyEditorSlice = createSlice({
  name: KUBEARMOR_POLICY_EDITOR_SLICE,
  initialState: KUBEARMOR_POLICY_EDITOR_INITIAL_STATE,
  reducers: {
    updatePolicyJSON: updatePolicyJSONReducer,
    updateSelectedEntity: updateSelectedEntityReducer,
    updateSelectedCluster: updateSelectedClusterReducer,
    updateSelectedNamespace: updateSelectedNamespaceReducer,
    updateSelectedInstanceGroup: updateSelectedInstanceGroupReducer,
    updateSelectedInstance: updateSelectedInstanceReducer,
    updateActiveCard: updateActiveCardReducer,
    updateCurrentPolicy: updateCurrentPolicyReducer,
    updateUnknownPolicyValues: updateUnknownPolicyValuesReducer,
    updateUnsupportedRules: updateUnsupportedRulesReducer,
    updateIsEditingPolicyMetadata: updateIsEditingPolicyMetadataReducer,
    handlePolicyMetadataFormSubmit: handlePolicyMetadataFormSubmitReducer,
    updateShowRulesPalette: updateShowRulesPaletteReducer,
    handleAddRule: handleAddRuleReducer,
    updateIsEditingProcessRule: updateIsEditingProcessRuleReducer,
    updateProcessRuleData: updateProcessRuleDataReducer,
    updateShowProcessRuleCard: updateShowProcessRuleCardReducer,
    updateShowProcessMandateParentPath: updateShowProcessMandateParentPathReducer,
    removeRuleFromPolicyJSON: removeRuleFromPolicyJSONReducer,
    handleProcessRuleFormSubmit: handleProcessRuleFormSubmitReducer,
    updateIsEditingFileRule: updateIsEditingFileRuleReducer,
    updateFileRuleData: updateFileRuleDataReducer,
    updateShowFileRuleCard: updateShowFileRuleCardReducer,
    updateShowFileMandateParentPath: updateShowFileMandateParentPathReducer,
    handleFileRuleFormSubmit: handleFileRuleFormSubmitReducer,
    updateIsEditingNetworkRule: updateIsEditingNetworkRuleReducer,
    updateNetworkRuleData: updateNetworkRuleDataReducer,
    updateShowNetworkRuleCard: updateShowNetworkRuleCardReducer,
    updateShowNetworkMandateParentPath: updateShowNetworkMandateParentPathReducer,
    handleNetworkRuleFormSubmit: handleNetworkRuleFormSubmitReducer,
    deleteIndividualRule: deleteIndividualRuleReducer,
    resetState: resetStateReducer
  }
});

export const useKubeArmorPolicyEditorState = key => {
  const value = useSelector(state => state[KUBEARMOR_POLICY_EDITOR_SLICE][key]);
  return value;
};

export const {
  updatePolicyJSON,
  updateSelectedEntity,
  updateSelectedCluster,
  updateSelectedNamespace,
  updateSelectedInstanceGroup,
  updateSelectedInstance,
  updateActiveCard,
  updateCurrentPolicy,
  updateUnknownPolicyValues,
  updateUnsupportedRules,
  updateIsEditingPolicyMetadata,
  handlePolicyMetadataFormSubmit,
  updateShowRulesPalette,
  handleAddRule,
  updateIsEditingProcessRule,
  updateProcessRuleData,
  updateShowProcessRuleCard,
  updateShowProcessMandateParentPath,
  removeRuleFromPolicyJSON,
  handleProcessRuleFormSubmit,
  updateIsEditingFileRule,
  updateFileRuleData,
  updateShowFileRuleCard,
  updateShowFileMandateParentPath,
  handleFileRuleFormSubmit,
  updateIsEditingNetworkRule,
  updateNetworkRuleData,
  updateShowNetworkRuleCard,
  updateShowNetworkMandateParentPath,
  handleNetworkRuleFormSubmit,
  deleteIndividualRule,
  resetState
} = kubeArmorPolicyEditorSlice.actions;

export default kubeArmorPolicyEditorSlice.reducer;
