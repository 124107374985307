import React from "react";
import { Handle, Position } from "reactflow";
import Icon from "../Images/command.svg";
import collapse from "../Images/collapse.svg";
import styles from "./styles.module.scss";
import { formatDate } from "../Utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import { useObservabilityContext } from "../Context";

export function SecondaryHeadNode({ data }) {
  const { showCommandDetails, setShowCommandDetails } = useObservabilityContext();
  return (
    <div className={`cursor-pointer ${styles.secondaryNode}`}>
      <Handle isConnectable={false} id="12" type="target" position={Position.Right} />
      <div className={styles.img} data-tip={data.label} data-for={data.label}>
        <img src={Icon} alt="pod" />
      </div>
      {/* {data.toolTip && <div className={styles.customToolTipTop}>{data?.nw_type?.includes('bind') ? data?.pod_name : data.ip}</div>} */}
      {showCommandDetails && data.freeze && (
        <>
          <div className="absolute text-left flex flex-col w-96 p-2 bg-white !drop-shadow-lg z-10">
            <div className="flex border-b-2 items-center border-indigo-200 justify-between">
              <div className="flex items-center">
                <img src={Icon} alt="" />
                <h3 className="pl-2 flex sourceCommand">
                  <strong className="mr-2 text-black">Commands: </strong>
                  {data.source}
                </h3>
              </div>
              <div>
                <CloseIcon
                  size={20}
                  onClick={e => {
                    e.stopPropagation();
                    setShowCommandDetails(false);
                  }}
                />
              </div>
            </div>
            <div className={styles.desc}>
              <p className="flex flex-col w-auto px-2 py-1 text-[8px] break-all">
                <span>
                  <strong>Namespace : </strong>
                  {data.namespace}
                </span>
                <span>
                  <strong>Cluster : </strong>
                  {data.cluster_name}{" "}
                </span>
                <span>
                  <strong>Command execution in pod : </strong>
                  {data.source}{" "}
                </span>
                <span>
                  <strong>Container : </strong>
                  {data.container_name}{" "}
                </span>
                <span>
                  <strong>Last Updated Time : </strong>
                  {formatDate(data.updated_time)}
                </span>
                <span>
                  <strong>Label : </strong>
                  {data.labels}
                </span>
                <span>
                  <strong>Occurrence : </strong>
                  {data.count}{" "}
                </span>
              </p>
            </div>
          </div>
        </>
      )}
      <Handle isConnectable={false} type="source" position="top" id="a" />
      <Handle isConnectable={false} type="source" position="right" id="b" />
      <Handle isConnectable={false} type="source" position="bottom" id="c" />
      <Handle isConnectable={false} type="source" position="left" id="d" />
      {data?.child?.map(e => {
        return <SecondaryNode data={e} />;
      })}
    </div>
  );
}

export function SecondaryNode({ data }) {
  return (
    <div className={`cursor-pointer ${styles.secondaryNode}`}>
      <div
        onClick={() => {
          // eslint-disable-next-line no-param-reassign
          data.previous = true;
        }}
        className={styles.expIcon}
      >
        <img className="w-4 h-4" src={collapse} alt="" />
      </div>
      <Handle isConnectable={false} id={data.id} type="source" position={Position.Right} />
      <Handle isConnectable={false} type="source" position="top" id="a" />
      <Handle isConnectable={false} type="source" position="right" id="b" />
      <Handle isConnectable={false} type="source" position="bottom" id="c" />
      <Handle isConnectable={false} type="source" position="left" id="d" />
    </div>
  );
}
