import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Title, Modal, Select, Icon } from "components/simple";
import { styled } from "@mui/material";
import { addTarget, getTargets } from "store/entities/vulnerabilities/actions";
import { getTargetsVulnerabilitiesListSelect } from "store/entities/vulnerabilities/selectors";
import { getOneTargetTypes } from "store/entities/vulnerabilities/service";

import styles from "./styles.module.scss";

const ButtonAddRow = styled("div")(({ theme }) => {
  return {
    width: "24px",
    height: "24px",
    borderRadius: "5px",
    cursor: "pointer",
    flexShrink: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    marginLeft: "10px",
    fontSize: "20px",
    marginBottom: "8px"
  };
});

const ExistingTarget = ({ open, onBack = () => null, close, displayFieldsList = [], data }) => {
  const [state, setState] = useState({
    name: "",
    rows: [{ displayField: [], mapFields: "" }],
    fields: []
  });
  const dispatch = useDispatch();
  const targetsList = useSelector(getTargetsVulnerabilitiesListSelect);

  useEffect(() => {
    dispatch(getTargets());
  }, []);

  const validation = () => {
    let valid = true;
    if (!state?.name) {
      valid = false;
    }
    const mapping = state?.rows?.every(item => {
      return item.mapFields?.value && item.displayField?.value;
    });
    if (!mapping) {
      valid = false;
    }
    return valid;
  };

  const onSave = () => {
    if (validation()) {
      const mapping = state?.rows?.map(item => {
        return [item.mapFields?.value, item.displayField?.value];
      });
      const res = {
        id: state?.name?.value,
        mapping,
        ids: data
      };
      dispatch(addTarget(res));
    }
  };

  const handleChange = value => {
    getOneTargetTypes(value?.target_type).then(res => {
      setState({
        ...state,
        name: value,
        rows: [{ displayField: [], mapFields: "" }],
        fields: res?.fields?.map(item => ({ value: item.id, label: item.name }))
      });
    });
  };

  const handleChangeSelect = (val, key, i) => {
    const res = state.rows?.map((item, index) => {
      if (i === index) {
        return { ...item, [key]: val };
      }
      return item;
    });
    setState({ ...state, rows: res });
  };

  const onAddRow = () => {
    setState({ ...state, rows: [...state.rows, { displayField: [], mapFields: "" }] });
  };

  const onDeleteRow = i => {
    const res = state.rows?.filter((_, index) => index !== i);
    setState({ ...state, rows: res });
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Create an existing target</Title>
      <div className={styles.content}>
        <div className={styles.form}>
          <Select
            containerClass={styles.select}
            onChange={val => handleChange(val, "name")}
            value={state.name}
            placeholder="Select"
            label="Name"
            options={targetsList}
          />

          {state.rows?.map((item, index) => {
            return (
              <div className={styles.row} key={index}>
                <Select
                  containerClass={styles.select}
                  onChange={val => handleChangeSelect(val, "displayField", index)}
                  value={item.displayField}
                  placeholder="Select"
                  label="Display field"
                  options={displayFieldsList}
                />
                <Select
                  containerClass={styles.select}
                  onChange={val => handleChangeSelect(val, "mapFields", index)}
                  value={item.mapFields}
                  placeholder="Select"
                  label="Map fields to target"
                  options={state?.fields || []}
                />
                <ButtonAddRow type="button" onClick={() => onAddRow()}>
                  +
                </ButtonAddRow>
                {state.rows.length > 1 && (
                  <div className={styles.buttonDelete} onClick={() => onDeleteRow(index)}>
                    <Icon.Close className={styles.close} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={onBack} variant="outline" className={styles.modalButton}>
          Back
        </Button>
        <Button onClick={onSave} className={styles.modalButton}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default ExistingTarget;
