import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Title, Input, Button, ContentContainer, Select } from "components/simple";
import { useForm, Controller } from "react-hook-form";
import RsyslogIcon from "../../assets/img/rsyslog.svg";
import { Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import ErrorText from "components/simple/ErrorText";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";
import { EDIT_RSYSLOG_LINK } from "screens/ChannelIntegration/constants";
import DeleteModal from "./DeleteModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getAccuknoxWorkspace } from "api/api";
import {
  useDeleteChannel,
  useRsyslogIntegration,
  useRsyslogUpdate
} from "store/entities/channels/mutation";

function RsyslogIntegration() {
  const [integrationName, setIntegrationName] = useState("");
  const [server, setServer] = useState("");
  const [port, setPort] = useState("");
  const [transport, setTransport] = useState("tcp");
  const [isOpen, setIsOpen] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: "onChange" });
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const useRsyslog = useRsyslogIntegration(data => {
    if (data?.response == "Success") {
      dispatch(addNotification({ msg: "Rsyslog Test Successful!", type: "success" }));
      setDisableSave(true);
    } else {
      setDisableSave(false);
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });
  const handletest = () => {
    const option = "test";
    useRsyslog.mutate({
      integrationName,
      server,
      port,
      transport,
      option
    });
  };
  // edit stuffs
  const getIntegrationName = location?.state?.IntegrationName;
  const getServerAddress = location?.state?.ServerAddress;
  const getPort = location?.state?.Port;
  const getTransport = location?.state?.Transport;

  useEffect(() => {
    if (location?.pathname === EDIT_RSYSLOG_LINK) {
      setIntegrationName(getIntegrationName);
      setServer(getServerAddress);
      setPort(getPort);
      setTransport(getTransport);
    }
  }, []);

  useEffect(() => {
    if (integrationName || server || port || transport) {
      setValue("integrationName", integrationName);
      setValue("serverAddress", server);
      setValue("PORT", port);
      setValue("trans", transport);
    }
  }, [integrationName, server, port, transport]);
  const isNotUndefined = val => typeof val !== "undefined";
  let shouldUpdate =
    (isNotUndefined(integrationName) ||
      isNotUndefined(server) ||
      isNotUndefined(port) ||
      isNotUndefined(transport)) &&
    (integrationName !== getIntegrationName ||
      server !== getServerAddress ||
      port !== getPort ||
      transport !== getTransport);

  useEffect(() => {
    shouldUpdate = integrationName !== getIntegrationName || server !== getServerAddress;
    // eslint-disable-next-line no-unused-expressions
    port !== getPort || transport !== getTransport;
  }, [server, integrationName, port, transport]);
  const rsyslogIntegration = useRsyslogIntegration(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Rsyslog Integration Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });
  const rsyslogUpdate = useRsyslogUpdate(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Rsyslog Updated Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });
  const onSubmit = async e => {
    if (location?.pathname === EDIT_RSYSLOG_LINK) {
      try {
        const ID = location?.state?.id;
        rsyslogUpdate.mutate({
          ID,
          integrationName,
          server,
          port,
          transport
        });
      } catch (err) {
        // console.log(err)
      }
    } else {
      try {
        const option = "save";
        rsyslogIntegration.mutate({
          integrationName,
          server,
          port,
          transport,
          option
        });
      } catch (err) {
        // console.log(err)
      }
    }
  };
  const tranOptions = [
    { label: "tcp", value: 1 },
    { label: "udp", value: 2 }
  ];
  const closeHandler = () => {
    setIsOpen(false);
  };
  const deleteRsyslogChannel = useDeleteChannel(data => {
    history.push("/settings/integrations/list");
    dispatch(addNotification({ msg: "Status: Deleted Rsyslog Integration", type: "success" }));
  });
  const handleDelete = async () => {
    const ID = location?.state?.id;
    const channeId = 5;
    deleteRsyslogChannel.mutate({
      channeId,
      ID
    });
  };

  return (
    <ContentContainer className={styles.container}>
      <DeleteModal
        isModalOpen={isOpen}
        onCloseModal={() => closeHandler()}
        onSubmit={() => handleDelete()}
      />
      <div className="flex py-5 justify-between" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex gap-1">
          <img src={RsyslogIcon} alt="icon" width="75px" />
          <Title>Rsyslog</Title>
        </div>
        <div className="flex justify-center items-center gap-5">
          {location?.pathname === EDIT_RSYSLOG_LINK && (
            <div
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => setIsOpen(true)}
            >
              <DeleteOutlineIcon />
              Delete Channel
            </div>
          )}
        </div>
      </div>
      <div className="py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex flex-row gap-5">
          <div className="w-3/5">
            <Controller
              name="integrationName"
              {...register("integrationName", {
                required: "This Field Cannot be empty",
                maxLength: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="integrationName"
                  label="Integration Name"
                  placeholder="Enter a name for integration"
                  required
                  value={integrationName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setIntegrationName(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.integrationName && errors.integrationName.message} />
            </div>
          </div>
          <div className="w-3/5">
            <Controller
              name="serverAddress"
              {...register("serverAddress", {
                required: "Server address is required",
                pattern: {
                  value: /^((https?:\/\/)|(www.))|(?:([a-zA-Z]+)(\.[a-zA-Z]+)|(\d+\.\d+.\d+.\d+))/,
                  message: "Please enter a valid address"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="serverAddress"
                  label="Server Address"
                  placeholder="Eg., rsyslog.mydomain.com"
                  required
                  value={server}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setServer(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.serverAddress && errors.serverAddress.message} />
            </div>
          </div>
          <div className="w-3/5">
            <Controller
              name="PORT"
              {...register("PORT", {
                required: "Port is required",
                pattern: {
                  value: /^[0-9]{1,6}$/,
                  message: "Please enter a valid one."
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="PORT"
                  label="Port"
                  placeholder="E.g., 514"
                  required
                  value={port}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setPort(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.PORT && errors.PORT.message} />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-0 pb-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="w-1/3 mt-4">
          <Typography className={styles.typo}>Transport</Typography>
          <Controller
            name="trans"
            {...register("trans", {
              required: "Transport is Required"
            })}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <div style={{ paddingTop: "10px" }}>
                <Select
                  name="trans"
                  value={{ label: transport, value: transport }}
                  onChange={option => {
                    onChange(option);
                    setTransport(option?.label);
                  }}
                  placeholder="Select the transport"
                  options={tranOptions}
                  containerClass={styles["action-group__filters-option"]}
                />
              </div>
            )}
          />
          <div className="m-0.5">
            <ErrorText errorMsg={errors.trans && errors.trans.message} />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between pt-6 pb-4">
        <Button onClick={handleSubmit(handletest)} disabled={!isDirty || !isValid}>
          Test
        </Button>
        <div className="flex">
          <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={location?.pathname === EDIT_RSYSLOG_LINK ? !shouldUpdate : !isValid}
            type="submit"
          >
            Save
          </Button>
        </div>
      </div>
    </ContentContainer>
  );
}

export default RsyslogIntegration;
