import React from "react";
import PropTypes from "prop-types";

export default function Notifications(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0199 20.53C9.68987 20.53 7.35987 20.16 5.14987 19.42C4.30987 19.13 3.66987 18.54 3.38987 17.77C3.09987 17 3.19987 16.15 3.65987 15.39L4.80987 13.48C5.04987 13.08 5.26987 12.28 5.26987 11.81V8.92004C5.26987 5.20004 8.29987 2.17004 12.0199 2.17004C15.7399 2.17004 18.7699 5.20004 18.7699 8.92004V11.81C18.7699 12.27 18.9899 13.08 19.2299 13.49L20.3699 15.39C20.7999 16.11 20.8799 16.98 20.5899 17.77C20.2999 18.56 19.6699 19.16 18.8799 19.42C16.6799 20.16 14.3499 20.53 12.0199 20.53ZM12.0199 3.67004C9.12987 3.67004 6.76987 6.02004 6.76987 8.92004V11.81C6.76987 12.54 6.46987 13.62 6.09987 14.25L4.94987 16.16C4.72987 16.53 4.66987 16.92 4.79987 17.25C4.91987 17.59 5.21987 17.85 5.62987 17.99C9.80987 19.39 14.2399 19.39 18.4199 17.99C18.7799 17.87 19.0599 17.6 19.1899 17.24C19.3199 16.88 19.2899 16.49 19.0899 16.16L17.9399 14.25C17.5599 13.6 17.2699 12.53 17.2699 11.8V8.92004C17.2699 6.02004 14.9199 3.67004 12.0199 3.67004Z"
        fill={props.color}
      />
      <path
        d="M13.8801 3.94005C13.8101 3.94005 13.7401 3.93005 13.6701 3.91005C13.3801 3.83005 13.1001 3.77005 12.8301 3.73005C11.9801 3.62005 11.1601 3.68005 10.3901 3.91005C10.1101 4.00005 9.81011 3.91005 9.62011 3.70005C9.43011 3.49005 9.37011 3.19005 9.48011 2.92005C9.89011 1.87005 10.8901 1.18005 12.0301 1.18005C13.1701 1.18005 14.1701 1.86005 14.5801 2.92005C14.6801 3.19005 14.6301 3.49005 14.4401 3.70005C14.2901 3.86005 14.0801 3.94005 13.8801 3.94005Z"
        fill={props.color}
      />
      <path
        d="M12.02 22.8101C11.03 22.8101 10.07 22.4101 9.37002 21.7101C8.67002 21.0101 8.27002 20.0501 8.27002 19.0601H9.77002C9.77002 19.6501 10.01 20.2301 10.43 20.6501C10.85 21.0701 11.43 21.3101 12.02 21.3101C13.26 21.3101 14.27 20.3001 14.27 19.0601H15.77C15.77 21.1301 14.09 22.8101 12.02 22.8101Z"
        fill={props.color}
      />
    </svg>
  );
}

Notifications.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Notifications.defaultProps = {
  className: "",
  style: {},
  color: "#fff",
  width: 24,
  height: 24
};
