import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { Icon } from "components/simple";
import { FormLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import QuestionTooltip from "../QuestionTooltip";

import styles from "./styles.module.scss";
import Search from "../Icon/Search";

const TagsSelector = ({
  label,
  values,
  list,
  onChange,
  className,
  required,
  tooltip,
  isDisabled,
  showAddIcon
}) => {
  const selectedList = values?.filter(item => item) ?? [];
  const suggestionsList =
    list
      ?.filter(item => item)
      ?.filter(item => !selectedList?.some(it => it.value.toString() === item?.value)) ?? [];

  const [isOpen, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredList, setFilteredList] = useState();

  const containerRef = useRef(null);

  const onOpenMenu = () => {
    setOpen(!isOpen);
  };

  const onAddTag = id => {
    const target = suggestionsList.find(tag => tag.value === id);
    onChange([...selectedList, target]);
  };

  const handleCheckboxChange = () => {
    onChange([...selectedList, ...filteredList]);
    setSearchInput("");
  };

  const handleSearch = e => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    setFilteredList(suggestionsList);
  }, []);

  useEffect(() => {
    setFilteredList(suggestionsList);
  }, [values]);

  useEffect(() => {
    setFilteredList(
      suggestionsList.filter(item => {
        return item.value.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
      })
    );
  }, [searchInput]);

  const onRemoveTag = id => {
    const newSelectedList = selectedList.filter(tag => tag.value !== id);
    onChange(newSelectedList);
  };

  useEffect(() => {
    // Close menu on outside click
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.wrapper} ${className} ${required ? styles.required : ""}`}>
      {label && (
        <div className={styles.labelCont}>
          <FormLabel className={styles.label}>{label}</FormLabel>
          {tooltip && <QuestionTooltip text={tooltip} />}
        </div>
      )}
      <div ref={containerRef}>
        <div className={styles.container}>
          {selectedList?.map(tag => (
            <div
              key={tag.value}
              onClick={() => onRemoveTag(tag.value)}
              className={`${styles.tag} ${styles.selected}`}
            >
              {tag.label}
              <Icon.Close className={styles.removeBtn} color="#2B3858" width="18" height="18" />
            </div>
          ))}
          {showAddIcon && (
            <div
              onClick={!isDisabled ? onOpenMenu : () => null}
              aria-disabled={isDisabled}
              className={styles.addBtn}
            >
              <Icon.Plus color="#2B3858" />
            </div>
          )}
        </div>

        {isOpen && (
          <div
            className={
              (list.length !== 0 && values.length !== list.length) || list.length === 0
                ? `${styles.modalholder} ${styles.modal} ${styles.tagscontainer} ${
                    list.length === 0 ? styles.less : ""
                  }`
                : `${styles.displaynone}`
            }
          >
            {suggestionsList.length > 0 ? (
              <div className={`${styles.search}`}>
                <input
                  id="auditSearch"
                  type="text"
                  autoComplete="off"
                  className={`${styles.searchbar}`}
                  placeholder="Search"
                  style={{ height: "26px", width: "200px" }}
                  value={searchInput}
                  onChange={e => handleSearch(e)}
                />
                <Search />
              </div>
            ) : null}
            {suggestionsList.length > 0 ? (
              <div className={`${styles.selectall}`}>
                <span>Select All</span>
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            ) : null}

            <div>
              {filteredList.length > 0
                ? filteredList.map(tag => (
                    <div className={`${styles.tagholder}`}>
                      <div key={tag.value} className={`${styles.tag} ${styles.lol}`}>
                        {tag.label}
                      </div>
                      <div
                        onClick={() => onAddTag(tag.value)}
                        aria-disabled={isDisabled}
                        className={styles.addBtn}
                      >
                        <Icon.Plus color="#2B3858" />
                      </div>
                    </div>
                  ))
                : null}
            </div>
            {list.length === 0 ? <div className={styles.noOptions}>No Options</div> : null}
          </div>
        )}
      </div>
    </div>
  );
};

TagsSelector.propTypes = {
  label: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  isDisabled: PropTypes.bool,
  showAddIcon: PropTypes.bool
};

TagsSelector.defaultProps = {
  label: "",
  onChange: () => {},
  className: "",
  tooltip: "",
  isDisabled: false,
  showAddIcon: true
};

export default TagsSelector;
