import React, { useMemo, useCallback } from "react";
import ReactDOMServer from "react-dom/server";

import { FormLabel } from "@mui/material";
import QuestionTooltip from "../QuestionTooltip";
import SimpleMdeReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import styles from "./styles.module.scss";

export const TextEditor = ({
  id,
  label,
  value,
  handleChange,
  optionsList,
  containerClass,
  onFocus,
  tooltip,
  required,
  isPreviewOn
}) => {
  const onChange = v => {
    handleChange(v);
  };

  const events = useMemo(() => {
    return {
      focus: () => onFocus(id)
    };
  }, []);

  const getMdeInstance = useCallback(e => {
    if (isPreviewOn) {
      e?.toolbarElements?.preview.click();
    }
  }, []);

  const previewRender = useCallback(v => {
    // eslint-disable-next-line react/no-children-prop
    return ReactDOMServer.renderToString(<ReactMarkdown remarkPlugins={[gfm]}>{v}</ReactMarkdown>);
  }, []);

  const options = useMemo(() => {
    return {
      blockStyles: {
        bold: "__",
        italic: "_"
      },
      insertTexts: {
        horizontalRule: ["", "\n\n-----\n\n"],
        image: ["![](http://", ")"],
        link: ["[", "](http://)"],
        table: [
          "",
          "\n\n| Column 1 | Column 2 | Column 3 |\n| -------- | -------- | -------- |\n| Text     | Text      | Text     |\n\n"
        ]
      },
      showIcons: ["table"],
      indentWithTabs: false,
      lineWrapping: false,
      parsingConfig: {
        allowAtxHeaderWithoutSpace: true,
        strikethrough: false,
        underscoresBreakWords: true
      },
      placeholder: "Type here...",
      promptURLs: true,
      spellChecker: false,
      status: false,
      styleSelectedText: false,
      tabSize: 4,
      sideBySideFullscreen: false,
      autofocus: true,
      id,
      forceSync: true,
      syncSideBySidePreviewScroll: false,
      previewRender
    };
  }, []);

  return (
    <div className={containerClass}>
      {label && (
        <div className={styles.labelCont}>
          <FormLabel className={styles.label}>{label}</FormLabel>
          {tooltip && <QuestionTooltip text={tooltip} />}
        </div>
      )}
      <SimpleMdeReact
        value={value}
        onChange={onChange}
        events={events}
        options={{
          ...options,
          ...optionsList
        }}
        getMdeInstance={getMdeInstance}
      />
      <input
        tabIndex={-1}
        autoComplete="off"
        style={{ opacity: 0, height: 0, position: "absolute" }}
        value={value}
        onChange={_ => onChange(_)}
        required={required}
      />
    </div>
  );
};
