import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

// Get User Profile
export const getUserProfile = createAction(constant.GET_USER_PROFILE);
export const getUserProfileSuccess = createAction(constant.GET_USER_PROFILE_SUCCESS);
export const getUserProfileFailed = createAction(constant.GET_USER_PROFILE_FAILED);

// Get Tenants
export const getTenants = createAction(constant.GET_TENANTS);
export const getTenantsSuccess = createAction(constant.GET_TENANTS_SUCCESS);
export const getTenantsFailed = createAction(constant.GET_TENANTS_FAILED);

export const setTenant = createAction(constant.SET_TENANT);

// Login
export const login = createAction(constant.LOG_IN);
export const loginSuccess = createAction(constant.LOG_IN_SUCCESS);
export const loginFailed = createAction(constant.LOG_IN_FAILED);

// Signup
export const signup = createAction(constant.SIGNUP);
export const signupSuccess = createAction(constant.SIGNUP_SUCCESS);
export const signupFailed = createAction(constant.SIGNUP_FAILED);

// Forgot Password
export const forgotPassword = createAction(constant.FORGOT_PASSWORD);
export const forgotPasswordSuccess = createAction(constant.FORGOT_PASSWORD_SUCCESS);
export const forgotPasswordFailed = createAction(constant.FORGOT_PASSWORD_FAILED);

// Activate
export const activate = createAction(constant.ACTIVATE);
export const activateSuccess = createAction(constant.ACTIVATE_SUCCESS);
export const activateFailed = createAction(constant.ACTIVATE_FAILED);

// Update Profile
export const updateProfile = createAction(constant.UPDATE_PROFILE);
export const updateProfileSuccess = createAction(constant.UPDATE_PROFILE_SUCCESS);
export const updateProfileFailed = createAction(constant.UPDATE_PROFILE_FAILED);

// Change Password
export const changePassword = createAction(constant.CHANGE_PASSWORD);
export const changePasswordSuccess = createAction(constant.CHANGE_PASSWORD_SUCCESS);
export const changePasswordFailed = createAction(constant.CHANGE_PASSWORD_FAILED);

export const openChangePasswordModal = createAction(constant.OPEN_CHANGE_PASSWORD_MODAL);
export const closeChangePasswordModal = createAction(constant.CLOSE_CHANGE_PASSWORD_MODAL);

// Logout
export const logout = createAction(constant.LOGOUT);
export const logoutSuccess = createAction(constant.LOGOUT_SUCCESS);

// Refresh
export const refresh = createAction(constant.REFRESH);
export const refreshSuccess = createAction(constant.REFRESH_SUCCESS);

// VERIFICATION
export const verification = createAction(constant.VERIFICATION);
export const verificationSuccess = createAction(constant.VERIFICATION_SUCCESS);
export const verificationFailed = createAction(constant.VERIFICATION_FAILED);

// Email Notification Subscriptions
export const emailNotificationSubscriptions = createAction(
  constant.EMAIL_NOTIFICATION_SUBSCRIPTIONS
);
export const emailNotificationSubscriptionsSuccess = createAction(
  constant.EMAIL_NOTIFICATION_SUBSCRIPTIONS_SUCCESS
);
export const emailNotificationSubscriptionsFailed = createAction(
  constant.EMAIL_NOTIFICATION_SUBSCRIPTIONS_FAILED
);

// MFA
export const mfaModal = createAction(constant.MFA_MODAL);
export const MFASuccessModal = createAction(constant.MFA_MODAL_SUCCESS);

export const addMFA = createAction(constant.ADD_MFA);
export const addMFASuccess = createAction(constant.ADD_MFA_SUCCESS);
export const addMFAFailed = createAction(constant.ADD_MFA_FAILED);

export const removeMFA = createAction(constant.REMOVE_MFA);
export const removeMFASuccess = createAction(constant.REMOVE_MFA_SUCCESS);
export const removeMFAFailed = createAction(constant.REMOVE_MFA_FAILED);
