import { useMutation } from "react-query";
import {
  applyPolicy,
  deletePolicy,
  downloadPolicies,
  storePolicy,
  storeTags,
  updatePolicy
} from "./service";
import { useDispatch } from "react-redux";
import { addNotification } from "../../notifications/actions";
import { downloadFile } from "helper/helpers";

export const useStoreTagsMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => storeTags(payload), {
    onError: error => {
      dispatch(addNotification({ msg: "There was an error while creating tags!", type: "error" }));
    }
  });
};

export const useStorePolicyMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => storePolicy(payload), {
    onError: () => {
      dispatch(
        addNotification({ type: "error", msg: "There was an error while saving the policy" })
      );
    },
    onSuccess: response => {
      if (response?.code === "200" && response?.message === "Success") {
        dispatch(addNotification({ type: "success", msg: "Policy saved to workspace" }));
      } else {
        dispatch(addNotification({ type: "error", msg: response?.message }));
      }
    }
  });
};

export const useUpdatePolicyMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => updatePolicy(payload), {
    onError: () => {
      dispatch(
        addNotification({ type: "error", msg: "There was an error while updating the policy" })
      );
    },
    onSuccess: () => {
      dispatch(addNotification({ type: "success", msg: "Policy updated" }));
    }
  });
};

export const useDeletePolicyMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => deletePolicy(payload), {
    onError: () => {
      dispatch(
        addNotification({ type: "error", msg: "There was an error while deleting the policy" })
      );
    },
    onSuccess: () => {
      dispatch(addNotification({ type: "success", msg: "Policy deleted" }));
    }
  });
};

export const useApplyPolicyMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => applyPolicy(payload), {
    onError: async error => {
      const errorData = await error.json();
      dispatch(addNotification({ msg: errorData.message, type: "error" }));
    }
  });
};

export const useDownloadPoliciesZipMutation = () => {
  const dispatch = useDispatch();

  return useMutation(payload => downloadPolicies(payload), {
    onSuccess: blob => {
      downloadFile(blob, "policies.zip", "application/zip");
      dispatch(addNotification({ type: "success", msg: "Downloading policies" }));
    }
  });
};
