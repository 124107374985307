// Get User Profile
export const GET_USER_PROFILE = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";

// Get Tenants
export const GET_TENANTS = "GET_TENANTS_REQUEST";
export const GET_TENANTS_SUCCESS = "GET_TENANTS_SUCCESS";
export const GET_TENANTS_FAILED = "GET_TENANTS_FAILED";

export const SET_TENANT = "SET_TENANT";

// Login Data
export const LOGIN_DATA = "LOGIN_DATA_REQUEST";
export const LOGIN_DATA_SUCCESS = "LOGIN_DATA_SUCCESS";
export const LOGIN_DATA_FAILED = "LOGIN_DATA_FAILED";

// Logout
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// Login
export const LOG_IN = "LOG_IN_REQUEST";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAILED = "LOG_IN_FAILED";

// Signup
export const SIGNUP = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";

// FORGOT PASSWORD
export const FORGOT_PASSWORD = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

// Activate
export const ACTIVATE = "ACTIVATE_REQUEST";
export const ACTIVATE_SUCCESS = "ACTIVATE_SUCCESS";
export const ACTIVATE_FAILED = "ACTIVATE_FAILED";

// Update Profile
export const UPDATE_PROFILE = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

// Change Password
export const CHANGE_PASSWORD = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const OPEN_CHANGE_PASSWORD_MODAL = "OPEN_CHANGE_PASSWORD_MODAL";
export const CLOSE_CHANGE_PASSWORD_MODAL = "CLOSE_CHANGE_PASSWORD_MODAL";

// Refresh
export const REFRESH = "REFRESH_REQUEST";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAILED = "REFRESH_FAILED";

// VERIFICATION
export const VERIFICATION = "VERIFICATION_REQUEST";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_FAILED = "VERIFICATION_FAILED";

// Email Notification Subscriptions
export const EMAIL_NOTIFICATION_SUBSCRIPTIONS = "EMAIL_NOTIFICATION_SUBSCRIPTIONS_REQUEST";
export const EMAIL_NOTIFICATION_SUBSCRIPTIONS_SUCCESS = "EMAIL_NOTIFICATION_SUBSCRIPTIONS_SUCCESS";
export const EMAIL_NOTIFICATION_SUBSCRIPTIONS_FAILED = "EMAIL_NOTIFICATION_SUBSCRIPTIONS_FAILED";

// MFA
export const MFA_MODAL = "MFA_MODAL";
export const MFA_MODAL_SUCCESS = "MFA_MODAL_SUCCESS";

export const ADD_MFA = "ADD_MFA_REQUEST";
export const ADD_MFA_SUCCESS = "ADD_MFA_SUCCESS";
export const ADD_MFA_FAILED = "ADD_MFA_FAILED";

export const REMOVE_MFA = "REMOVE_MFA_REQUEST";
export const REMOVE_MFA_SUCCESS = "REMOVE_MFA_SUCCESS";
export const REMOVE_MFA_FAILED = "REMOVE_MFA_FAILED";
