import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  tickets: {},
  ticketsParams: {
    page: 0,
    pageSize: 20,
    ordering: "",
    search: "",
    filterBy: "",
    priority: "",
    type: "",
    status: "",
    configuration: "",
    range: ""
  },
  statusList: null,
  ticket: {},
  ticketsConfig: {},
  vulnerabilityTickets: [],
  baselineTickets: [],
  ticketsPreview: null,
  ticketComments: [],
  ticketsRelatedTypes: []
};

const reducer = createReducer(
  initialState,
  {
    [actions.setTicketsParams.type]: (state, action) => ({
      ...state,
      ticketsParams: {
        ...state.ticketsParams,
        ...action.payload
      }
    }),
    [actions.getTicketsSuccess.type]: (state, action) => ({
      ...state,
      ticket: {},
      tickets: action.payload
    }),
    [actions.getTicketSuccess.type]: (state, action) => ({
      ...state,
      ticket: action.payload
    }),
    [actions.getTicketsConfigSuccess.type]: (state, action) => ({
      ...state,
      ticketsConfig: action.payload
    }),
    [actions.getVulnerabilityTicketsSuccess.type]: (state, action) => ({
      ...state,
      vulnerabilityTickets: [...action.payload]
    }),
    [actions.getBaselineTicketsSuccess.type]: (state, action) => ({
      ...state,
      baselineTickets: [...action.payload]
    }),
    [actions.clearTicketsPreview.type]: state => ({
      ...state,
      ticketsPreview: []
    }),
    [actions.getTicketsPreview.type]: state => ({
      ...state,
      ticketsPreview: []
    }),
    [actions.getTicketsPreviewSuccess.type]: (state, action) => ({
      ...state,
      ticketsPreview: [...action.payload]
    }),
    [actions.getTicketCommentsSuccess.type]: (state, action) => ({
      ...state,
      ticketComments: action.payload
    }),
    [actions.getTicketsRelatedTypesSuccess.type]: (state, action) => ({
      ...state,
      ticketsRelatedTypes: action.payload
    }),
    [actions.getTicketsStatusListSuccess.type]: (state, action) => ({
      ...state,
      statusList: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
