import React from "react";

const Info = ({ color = "#3065C6", size = 17 }) => {
  return (
    <svg
      width={String(size)}
      height={String(size)}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99968 0.833984C2.69967 0.833984 0.833008 2.70065 0.833008 5.00065C0.833008 7.30065 2.69967 9.16732 4.99968 9.16732C7.29968 9.16732 9.16634 7.30065 9.16634 5.00065C9.16634 2.70065 7.29968 0.833984 4.99968 0.833984ZM5.41634 7.08399H4.58301V4.58399H5.41634V7.08399ZM5.41634 3.75065H4.58301V2.91732H5.41634V3.75065Z"
        fill={color}
      ></path>
    </svg>
  );
};

export default Info;
