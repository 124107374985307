import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { Button, Title, Modal, Select, Input, Checkbox, Switch, Icon } from "components/simple";
import Loader from "components/simple/Loader";

import { createLoadingSelector } from "store/entities/loading/selector";

import {
  showTicketsConfigModal,
  addTicketConfig,
  editTicketConfig,
  getTicketConfig,
  getTemplateListMini,
  getStatusForTicketConfig
} from "store/entities/settings/actions";

import {
  getTicketConfigSelector,
  getTemplateListMiniSelect,
  jiraAllSelect,
  freshserviceConfigSelect,
  connectWiseConfigSelect,
  statusSelect
} from "store/entities/settings/selectors";

import styles from "./styles.module.scss";
import { routes } from "router";

const TicketConfigModal = () => {
  const params = useParams();

  const integration_id = params?.id;
  const [state, setState] = useState({
    name: "",
    template: "",
    project: "",
    issue_type: "",
    //
    priorities: {
      Unknown: "",
      Informational: "",
      Low: "",
      Medium: "",
      High: "",
      Critical: ""
    },
    ticket_profile_type: "",
    auto_maintain_tickets: false,
    update_closed_tickets: false,
    commentAnalysisList: [
      {
        regex: "",
        status: ""
      }
    ]
  });

  const [remapPriority, setRemapPriority] = useState(true);
  const [commentAnalysis, setCommentAnalysis] = useState(false);
  const { show, id, type } = useSelector(st => st.settings.showTicketsConfigModal);

  const editData = useSelector(st => getTicketConfigSelector(st, type));
  const templateListMini = useSelector(getTemplateListMiniSelect);
  const status = useSelector(statusSelect);

  const isLoading = useSelector(
    st =>
      createLoadingSelector([getTicketConfig.type])(st) ||
      // createLoadingSelector([getTemplateListMini.type])(st) ||
      createLoadingSelector([getStatusForTicketConfig.type])(st)
  );

  const jiraAll = useSelector(jiraAllSelect);
  const freshserviceConfig = useSelector(freshserviceConfigSelect);
  const connectWiseConfig = useSelector(connectWiseConfigSelect);

  const listStatus = status || [];
  const lists = useMemo(() => {
    if (type === "jiraprofile" || type === "jiracloudprofile") {
      return jiraAll;
    }
    if (type === "freshserviceprofile") {
      return freshserviceConfig;
    }
    if (type === "connectwiseprofile") {
      return connectWiseConfig;
    }
  }, [type]);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((editData && id && type === "jiraprofile") || type === "jiracloudprofile") {
      var arr = _.flatten(Object?.values(jiraAll?.issue_types || { null: "null" }));
      let tmp = _.find(arr, { id: editData?.issue_type });
      setState({
        ...editData,
        issue_type: { value: tmp?.id, label: tmp?.name }
      });
      // setState(editData);
      if (editData?.commentAnalysisList?.length) {
        setCommentAnalysis(true);
      }
    }
    if (editData && id && type === "freshserviceprofile") {
      setState({
        ...editData,
        issue_type: { value: editData.issue_type, label: editData.issue_type }
      });
    }
  }, [editData]);

  useEffect(() => {
    if (id && show) {
      dispatch(getTicketConfig({ id }));
    }
    if (show) {
      dispatch(getTemplateListMini());
      dispatch(getStatusForTicketConfig());
      setRemapPriority(true);
      setCommentAnalysis(false);
    }
    return () =>
      setState({
        name: "",
        template: "",
        project: "",
        issue_type: "",
        priorities: {
          Unknown: "",
          Informational: "",
          Low: "",
          Medium: "",
          High: "",
          Critical: ""
        },
        auto_maintain_tickets: false,
        update_closed_tickets: false,
        ticket_profile_type: "",
        commentAnalysisList: [
          {
            regex: "",
            status: ""
          }
        ]
      });
  }, [show]);

  const close = () => {
    dispatch(showTicketsConfigModal({ show: false, id: null, type: null }));
  };

  const onSave = e => {
    e.preventDefault();
    const priorities = {};
    Object.entries(state?.priorities).forEach(([key, val]) => {
      priorities[key] = val?.value || "";
    });
    const res = {
      name: state?.name,
      issue_type: state?.issue_type?.value,
      ticket_profile_type: type,
      ticket_profile_id: integration_id,
      auto_maintain_tickets: state?.auto_maintain_tickets,
      update_closed_tickets: state?.update_closed_tickets,
      template: state?.template?.value,
      priorities: {},
      comment_analysis: []
    };
    if (type !== "freshserviceprofile") {
      res.project = state?.project?.value;
    }
    if (remapPriority) {
      res.priorities = priorities;
    }
    if (commentAnalysis) {
      res.comment_analysis = state?.commentAnalysisList?.map(item => ({
        ...item,
        regex: item?.regex,
        status: item?.status?.value
      }));
    }
    if (id) {
      dispatch(editTicketConfig({ id, data: res }));
      return;
    }
    dispatch(addTicketConfig(res));
  };

  const inputChangeHandler = (val, key) => {
    setState({ ...state, [key]: val });
  };

  const handleChangePriority = (val, key) => {
    setState({
      ...state,
      priorities: {
        ...state?.priorities,
        [key]: val
      }
    });
  };

  const handleCommentAnalysic = (val, key, index) => {
    const res = state?.commentAnalysisList?.map((item, i) => {
      const cur = item;
      if (i === index) {
        cur[key] = val;
      }
      return item;
    });
    setState({
      ...state,
      commentAnalysisList: res
    });
  };

  const addComment = () => {
    setState({
      ...state,
      commentAnalysisList: [
        ...state?.commentAnalysisList,
        {
          regex: "",
          status: ""
        }
      ]
    });
  };

  const removeCommentAnalysic = index => {
    const res = state?.commentAnalysisList?.filter((item, i) => i !== index);
    setState({
      ...state,
      commentAnalysisList: res
    });
  };

  const listPriority = lists?.priorities;

  const renderCommentAnalysic = () => {
    return state?.commentAnalysisList?.map((item, index) => {
      return (
        <div className={styles.row} key={index}>
          <Input
            type="text"
            placeholder="Regex"
            label="Regex"
            required
            value={item?.regex}
            onChange={val => handleCommentAnalysic(val.target.value, "regex", index)}
            containerClassName={styles.searchContainer}
          />
          <Select
            containerClass={styles.inputContainer}
            onChange={val => handleCommentAnalysic(val, "status", index)}
            value={item?.status}
            placeholder="Status"
            label="Status"
            options={listStatus}
            inputProps={{
              required: true
            }}
            isClearable
            required
          />
          {state?.commentAnalysisList?.length > 1 ? (
            <div className={styles.removeBtn} onClick={() => removeCommentAnalysic(index)}>
              <Icon.Close color="#d83e3c" width="16" height="16" />
            </div>
          ) : null}
        </div>
      );
    });
  };

  const onTemplateMenuOpen = () => {
    dispatch(getTemplateListMini());
  };
  const [issueType, setIssueType] = useState([]);
  useEffect(() => {
    if (type === "jiraprofile" || type === "jiracloudprofile") {
      if (jiraAll?.projects?.length) {
        const arr = [...Object.entries(jiraAll?.issue_types)];
        const issue = arr?.filter(e => e[0] === state?.project?.value);
        const res = _.flatten(issue);
        setIssueType(
          res[1]?.map(e => {
            return { value: e.id, label: e.name };
          })
        );
      }
    } else if (type === "freshserviceprofile") {
      setIssueType(freshserviceConfig.issue_types);
    }
  }, [state.project, type]);
  return (
    <Modal
      isOpen={show}
      toggle={close}
      sx={{
        height: "100%",
        maxHeight: "90%",
        overflow: "auto"
      }}
    >
      <Title>Configuration ticket</Title>
      {isLoading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <div className={styles.content}>
          <form className={styles.form} onSubmit={onSave}>
            <Input
              type="text"
              placeholder="Configuration name"
              label="Configuration name"
              required
              value={state?.name}
              onChange={val => inputChangeHandler(val.target.value, "name")}
              containerClassName={styles.searchContainer}
            />
            <div className={styles.inline}>
              <Select
                onMenuOpen={() => onTemplateMenuOpen()}
                containerClass={styles.inputContainer}
                onChange={v => inputChangeHandler(v, "template")}
                value={state?.template}
                placeholder="Default template"
                label="Default template"
                options={templateListMini}
                inputProps={{
                  required: true
                }}
                required
              />
              <div className={styles.create}>
                <Link
                  to={routes.settingsTicketTemplatesDetails}
                  target="_blank"
                  className={styles.add}
                >
                  <span className={styles.icon}>
                    <Icon.Plus color="#d83e3c" />
                  </span>
                </Link>
              </div>
            </div>
            {type === "jiracloudprofile" ? (
              <Select
                containerClass={styles.inputContainer}
                onChange={v => inputChangeHandler(v, "project")}
                value={state?.project}
                placeholder={"Project"}
                label={"Project"}
                options={lists?.projects}
                inputProps={{
                  required: true
                }}
                required
              />
            ) : null}
            {type === "connectwiseprofile" ? (
              <Select
                containerClass={styles.inputContainer}
                onChange={v => inputChangeHandler(v, "project")}
                value={state?.project}
                placeholder={"Companies"}
                label={"Companies"}
                options={lists?.projects}
                inputProps={{
                  required: true
                }}
                required
              />
            ) : null}
            {type === "jiraprofile" || type === "jiracloudprofile" ? (
              <div
                className={`${!Object.keys(state?.project ? state?.project : [])?.length > 0 &&
                  "opacity-50 cursor-not-allowed"}`}
              >
                <Select
                  isDisabled={
                    !Object.keys(state?.project ? state?.project : [])?.length > 0 &&
                    type !== "freshserviceprofile"
                  }
                  containerClass={styles.inputContainer}
                  onChange={v => inputChangeHandler(v, "issue_type")}
                  value={state?.issue_type}
                  placeholder="Issue type"
                  label="Issue type"
                  options={issueType}
                  inputProps={{
                    required: true
                  }}
                  required
                />
              </div>
            ) : null}
            {type === "freshserviceprofile" ? (
              <Select
                containerClass={styles.inputContainer}
                onChange={v => inputChangeHandler(v, "issue_type")}
                value={state?.issue_type}
                placeholder="Issue type"
                label="Issue type"
                options={issueType}
                inputProps={{
                  required: true
                }}
                required
              />
            ) : null}
            <Checkbox
              value={state?.auto_maintain_tickets}
              className={styles.checkbox}
              onChange={v => inputChangeHandler(v, "auto_maintain_tickets")}
              label="Auto maintain tickets"
            />

            <Checkbox
              value={state?.update_closed_tickets}
              className={styles.checkbox}
              onChange={v => inputChangeHandler(v, "update_closed_tickets")}
              label="Keep syncing closed tickets"
            />

            <div className={styles.sub_title_row}>
              <div className={styles.sub_title}>Remap priority</div>
              <Switch
                value={remapPriority}
                className={styles.switch}
                onChange={v => setRemapPriority(v)}
              />
            </div>
            {remapPriority && (
              <div className={styles.section}>
                <div className={styles.row}>
                  <div className={styles.priorityName}>Unknown</div>
                  <Select
                    containerClass={styles.inputContainer}
                    onChange={v => handleChangePriority(v, "Unknown")}
                    value={state?.priorities?.Unknown}
                    placeholder="Priority"
                    label="Priority"
                    options={listPriority}
                    inputProps={{
                      required: true
                    }}
                    isClearable
                    required
                  />
                </div>

                <div className={styles.row}>
                  <div className={styles.priorityName}>Informational</div>
                  <Select
                    containerClass={styles.inputContainer}
                    onChange={v => handleChangePriority(v, "Informational")}
                    value={state?.priorities?.Informational}
                    placeholder="Priority"
                    label="Priority"
                    options={listPriority}
                    inputProps={{
                      required: true
                    }}
                    isClearable
                    required
                  />
                </div>

                <div className={styles.row}>
                  <div className={styles.priorityName}>Low</div>
                  <Select
                    containerClass={styles.inputContainer}
                    onChange={v => handleChangePriority(v, "Low")}
                    value={state?.priorities?.Low}
                    placeholder="Priority"
                    label="Priority"
                    options={listPriority}
                    inputProps={{
                      required: true
                    }}
                    isClearable
                    required
                  />
                </div>

                <div className={styles.row}>
                  <div className={styles.priorityName}>Medium</div>
                  <Select
                    containerClass={styles.inputContainer}
                    onChange={v => handleChangePriority(v, "Medium")}
                    value={state?.priorities?.Medium}
                    placeholder="Priority"
                    label="Priority"
                    options={listPriority}
                    inputProps={{
                      required: true
                    }}
                    isClearable
                    required
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.priorityName}>High</div>
                  <Select
                    containerClass={styles.inputContainer}
                    onChange={v => handleChangePriority(v, "High")}
                    value={state?.priorities?.High}
                    placeholder="Priority"
                    label="Priority"
                    options={listPriority}
                    inputProps={{
                      required: true
                    }}
                    isClearable
                    required
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.priorityName}>Critical</div>
                  <Select
                    containerClass={styles.inputContainer}
                    onChange={v => handleChangePriority(v, "Critical")}
                    value={state?.priorities?.Critical}
                    placeholder="Priority"
                    label="Priority"
                    options={listPriority}
                    inputProps={{
                      required: true
                    }}
                    isClearable
                    required
                  />
                </div>
              </div>
            )}

            {/*  */}
            <div className={styles.sub_title_row}>
              <div className={styles.sub_title}>Comment analysis</div>
              <Switch
                value={commentAnalysis}
                className={styles.switch}
                onChange={v => setCommentAnalysis(v)}
              />
            </div>
            {commentAnalysis && (
              <div className={styles.section}>
                {renderCommentAnalysic()}

                <div className={styles.add} onClick={() => addComment()}>
                  <div className={styles.icon}>
                    <Icon.Plus color="#d83e3c" />
                  </div>
                  Add comment analysis
                </div>
              </div>
            )}
            {/*   */}

            <div className={styles.modalFooter}>
              <Button onClick={close} variant="outline" className={styles.modalButton}>
                Back
              </Button>
              <Button type="submit" className={styles.modalButton}>
                Save
              </Button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};

export default TicketConfigModal;
