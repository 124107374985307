import React from "react";
import styles from "../VerifyMFA/styles.module.scss";

import { Button, Input } from "components/simple";
import AccuknoxLogoIcon from "../assets/AccuknoxLogo.png";
import AuthFooter from "../AuthFooter";
import { Controller, useForm } from "react-hook-form";
import { useForgotPasswordMutation } from "store/entities/auth/mutations";
import { emailRegexPattern } from "store/entities/auth/constants";

const ForgotPassword = () => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset: resetForm
  } = useForm({
    defaultValues: { email: "" },
    mode: "onChange"
  });

  const {
    mutate: triggerForgotPasswordEmail,
    isLoading: isTriggeringEmail
  } = useForgotPasswordMutation();

  const onFormSubmit = formValues => {
    triggerForgotPasswordEmail(formValues, {
      onSuccess: () => {
        resetForm();
      }
    });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className="mt-8">
          <img src={AccuknoxLogoIcon} width="311px" height="64px" alt="" />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.title}>Forgot your password?</div>
          <div className={styles.description}>We are here to help!</div>

          <div className={styles.content}>
            <div>
              <Controller
                name="username"
                rules={{ required: "Email is required", pattern: emailRegexPattern }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label="Email Address"
                    placeholder="Enter your email address"
                    value={value}
                    onChange={onChange}
                    invalid={errors?.email?.message}
                    containerClassName={styles.inputcode}
                    className={`${styles.input}`}
                    autoFocus
                    required
                  />
                )}
              />
            </div>
            <Button
              className={styles.button}
              type="submit"
              onClick={handleSubmit(onFormSubmit)}
              disabled={!isValid || isTriggeringEmail}
            >
              {isTriggeringEmail ? "Please wait" : "Confirm"}
            </Button>
          </div>
        </div>
      </form>
      <AuthFooter />
    </div>
  );
};

export default ForgotPassword;
