import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { VULNERABILITY_TYPES } from "./constants";
import {
  updateDrawerVulnerabilityReducer,
  updateSelectedVulnerabilityTypeReducer,
  updateVulnerabilityTablePageReducer
} from "./reducers";

const RISK_BASED_PRIORTIZATION_SLICE = "riskBasedPriortization";
const RISK_BASED_PRIORTIZATION_INITIAL_STATE = {
  selectedVulnerabilityType: VULNERABILITY_TYPES[0],
  vulnerabilityTablePage: 0,
  drawerVulnerability: null
};

const riskBasedPriortizationSlice = createSlice({
  name: RISK_BASED_PRIORTIZATION_SLICE,
  initialState: RISK_BASED_PRIORTIZATION_INITIAL_STATE,
  reducers: {
    updateSelectedVulnerabilityType: updateSelectedVulnerabilityTypeReducer,
    updateVulnerabilityTablePage: updateVulnerabilityTablePageReducer,
    updateDrawerVulnerability: updateDrawerVulnerabilityReducer
  }
});

export const useRiskBasedPrioritizationState = key => {
  const value = useSelector(state => state[RISK_BASED_PRIORTIZATION_SLICE][key]);
  return value;
};

export const {
  updateSelectedVulnerabilityType,
  updateVulnerabilityTablePage,
  updateDrawerVulnerability
} = riskBasedPriortizationSlice.actions;
export default riskBasedPriortizationSlice.reducer;
