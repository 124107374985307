import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  auditFiles: [],
  auditFile: {
    labels: [],
    assets: [],
    baselines: [],
    history: []
  }
};
const reducer = createReducer(
  initialState,
  {
    [actions.getAuditFilesSuccess]: (state, action) => ({
      ...state,
      auditFiles: action.payload
    }),

    [actions.getAuditFileLabelsSuccess]: (state, action) => ({
      ...state,
      auditFile: {
        ...state.auditFile,
        labels: action.payload
      }
    }),

    [actions.getAuditFileAssetsSuccess]: (state, action) => ({
      ...state,
      auditFile: {
        ...state.auditFile,
        assets: action.payload
      }
    }),

    [actions.getAuditFileBaselinesSuccess]: (state, action) => ({
      ...state,
      auditFile: {
        ...state.auditFile,
        baselines: action.payload
      }
    }),

    [actions.getAuditFileHistorySuccess]: (state, action) => ({
      ...state,
      auditFile: {
        ...state.auditFile,
        history: action.payload
      }
    })
  },
  [], // required
  state => state // required
);

export default reducer;
