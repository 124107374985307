import React, { useCallback } from "react";
import { useStore, getStraightPath, getBezierPath } from "reactflow";
import styles from "./styles.module.scss";
import { getEdgeParams } from "../Utils/utils";

function SecondaryEdgeConnection({ id, source, target, style, label, data, markerEnd }) {
  const sourceNode = useStore(useCallback(store => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback(store => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
    source === target,
    data?.bindId
  );
  if (source === target) {
    const [edgePath] = getBezierPath({
      sourceX: sx,
      sourceY: sy,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
      targetX: tx,
      targetY: ty,
      curvature: 1
    });
    return (
      <g>
        <path id={id} d={edgePath} markerEnd={markerEnd} style={style} />
        <path id={id} d={edgePath} stroke="#0000" fill="none" strokeWidth="10px" />
      </g>
    );
  }
  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty
  });
  return (
    <g>
      <marker
        id="arrow_green_ingress"
        viewBox="0 -5 10 10"
        refX={100}
        refY="0"
        markerWidth="5"
        markerHeight="5"
        orient="auto-start-reverse"
      >
        <path d="M0,-5L10,0L0,5" stroke="#14FF00" fill="#14FF00"></path>
      </marker>
      <marker
        id="arrow_green_egress"
        viewBox="0 -5 10 10"
        refX={60}
        refY="0"
        markerWidth="5"
        markerHeight="5"
        orient="auto"
      >
        <path d="M0,-5L10,0L0,5" stroke="#14FF00" fill="#14FF00"></path>
      </marker>
      <path
        id={id}
        d={edgePath}
        markerStart={label.includes("ingress") && "url(#arrow_green_ingress)"}
        markerEnd={label.includes("egress") && "url(#arrow_green_egress)"}
        style={style}
      />
      <path id={id} d={edgePath} stroke="#0000" strokeWidth="10px" />
      <text dy={-5} className={`${sx < tx ? styles.labelClockwise : styles.labelAnticlockwise}`}>
        <textPath
          href={`#${id}`}
          style={{ fontSize: "12px" }}
          startOffset="50%"
          textAnchor="middle"
        >
          {label.slice(0, 3)}
        </textPath>
      </text>
    </g>
  );
}

export default SecondaryEdgeConnection;
