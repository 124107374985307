export const withSuffix = day => {
  switch (day) {
    case 1:
    case 21:
    case 31:
      return `${day}st`;
    case 2:
    case 22:
      return `${day}nd`;
    case 3:
    case 23:
      return `${day}rd`;
    case 26:
      return `${day}ix`;
    default:
      return `${day}th`;
  }
};
