import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

import { defaultDisplayFields } from "./selectors";

const initialState = {
  page: 0,
  pageSize: 20,
  ordering: "",
  search: "",
  filter: "",
  label: "",
  baselines: {},
  baseline: {},
  baselineTagData: {},
  baselineAssets: [],
  baselineAnalysisMini: [],
  baselineFailedAssetsParams: {
    page: 0,
    ordering: "",
    search: "",
    pageSize: 1
  },
  auditChoices: [],
  auditChoicesMini: [],
  sources: [],
  baselineConfigParams: {
    page: 0,
    ordering: "",
    search: "",
    filters: null,
    pageSize: 20,
    orderModel: [],
    selectTicketConfig: "",
    groupBy: "",
    baselineConfigTemporaryData: null
  },

  baselineConfigFilters: null,
  baselineConfigTable: null,
  baselineConfigControl: {},
  baselineHistoryParams: {
    page: 0,
    ordering: "",
    search: "",
    pageSize: 20
  },
  auditChoicesParams: {
    page: 0,
    ordering: "",
    search: "",
    source: "",
    label: "",
    audit_file: "",
    filter: null,
    pageSize: 20
  },
  compareBaselines: null,
  assetsMini: [],
  assignHostsParams: {
    search: "",
    label: "",
    baseline_id: ""
  },
  groups: [],
  groupsMinified: [],
  assignGroupsParams: {
    search: ""
  },
  labelList: []
};

const reducer = createReducer(
  initialState,
  {
    [actions.getLabelListSuccess.type]: (state, action) => ({
      ...state,
      labelList: action.payload
    }),
    [actions.setBaselineParams.type]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [actions.setBaselineAssetsParams.type]: (state, action) => ({
      ...state,
      baselineFailedAssetsParams: {
        ...state?.baselineFailedAssetsParams,
        ...action.payload
      }
    }),
    [actions.setBaselineConfigParams.type]: (state, action) => ({
      ...state,
      baselineConfigParams: {
        ...state?.baselineConfigParams,
        ...action.payload
      }
    }),
    [actions.setAuditChoicesParams.type]: (state, action) => ({
      ...state,
      auditChoicesParams: {
        ...state?.auditChoicesParams,
        ...action.payload
      }
    }),
    [actions.updBaselineConfigParams.type]: (state, action) => ({
      ...state,
      baselineConfigParams: {
        ...state?.baselineConfigParams,
        ...action.payload
      }
    }),
    [actions.setBaselineHistoryParams.type]: (state, action) => ({
      ...state,
      baselineHistoryParams: {
        ...state?.baselineHistoryParams,
        ...action.payload
      }
    }),
    [actions.getBaselineConfigFiltersSuccess.type]: (state, action) => ({
      ...state,
      baselineConfigFilters: action.payload
    }),
    [actions.getBaselineConfigTableSuccess.type]: (state, action) => ({
      ...state,
      baselineConfigTable: action.payload
    }),

    [actions.getBaselineSuccess.type]: (state, action) => ({
      ...state,
      baseline: action.payload,
      baselineConfigParams: {
        ...state?.baselineConfigParams,
        baselineConfigTemporaryData: {
          display_fields: action.payload?.display_fields || defaultDisplayFields,
          filter_fields: action.payload?.filter_fields || [],
          display_fields_width: action.payload?.table_properties?.display_fields_width || [],
          filters: null
        }
      }
    }),
    [actions.getBaselines.type]: state => ({
      ...state,
      baselines: {},
      baselineConfigTable: null,
      baselineConfigParams: {
        page: 0,
        ordering: "",
        search: "",
        filters: null,
        pageSize: 20,
        orderModel: [],
        selectTicketConfig: "",
        groupBy: "",
        baselineConfigTemporaryData: null
      }
    }),
    [actions.getBaselinesSuccess.type]: (state, action) => ({
      ...state,
      baselines: action.payload
    }),
    [actions.getBaselineAssetsSuccess.type]: (state, action) => ({
      ...state,
      baselineAssets: action.payload
    }),
    [actions.getBaselineAnalysisMiniSuccess.type]: (state, action) => ({
      ...state,
      baselineAnalysisMini: action.payload
    }),
    [actions.getAuditChoices.type]: state => ({
      ...state,
      auditChoices: []
    }),
    [actions.getAuditChoicesSuccess.type]: (state, action) => ({
      ...state,
      auditChoices: action.payload
    }),
    [actions.getAuditChoicesMiniSuccess.type]: (state, action) => ({
      ...state,
      auditChoicesMini: action.payload
    }),
    [actions.setAssignHostsParams.type]: (state, action) => ({
      ...state,
      assignHostsParams: {
        ...state?.assignHostsParams,
        ...action.payload
      }
    }),
    [actions.setAssignGroupsParams.type]: (state, action) => ({
      ...state,
      assignGroupsParams: {
        ...state?.assignGroupsParams,
        ...action.payload
      }
    }),
    [actions.getBaselineAssetsMiniSuccess.type]: (state, action) => ({
      ...state,
      assetsMini: action.payload
    }),
    [actions.getBaselineGroupsSuccess.type]: (state, action) => ({
      ...state,
      groups: action.payload
    }),
    [actions.getBaselineGroupsMiniSuccess.type]: (state, action) => ({
      ...state,
      groupsMinified: action.payload
    }),
    [actions.getBaselineSourcesSuccess.type]: (state, action) => ({
      ...state,
      sources: action.payload
    }),
    [actions.getCompareBaselines.type]: state => ({
      ...state,
      compareBaselines: null
    }),
    [actions.getCompareBaselinesSuccess.type]: (state, action) => ({
      ...state,
      compareBaselines: action.payload
    }),
    [actions.getBaselineControl.type]: state => ({
      ...state,
      baselineConfigControl: {}
    }),
    [actions.getBaselineControlSuccess.type]: (state, action) => ({
      ...state,
      baselineConfigControl: action.payload
    }),
    [actions.getBaselineTagById.type]: state => ({
      ...state,
      baselineTagData: {}
    }),
    [actions.getBaselineTagByIdSuccess.type]: (state, action) => ({
      ...state,
      baselineTagData: { ...action.payload }
    })
  },
  [], // required
  state => state // required
);

export default reducer;
