import React from "react";

import { Modal, Title, Input, Button } from "components/simple";

import styles from "./styles.module.scss";
import { AssetSelect } from "helper/AsyncSelects";

function AddGroupModal({ onChange, state, onCloseModal, isCreateLoading, onSubmit, isModalOpen }) {
  return (
    <Modal isOpen={isModalOpen} toggle={onCloseModal}>
      <Title className={styles.title}>Create group</Title>
      <form onSubmit={onSubmit}>
        <Input
          value={state.name}
          onChange={e => onChange(e.target.value, "name")}
          label="Name"
          className={styles.input}
          required
        />
        <AssetSelect
          containerClass={styles.select}
          onChange={val => onChange(val, "assets")}
          label="Assets"
          value={state.assets}
          isClearable
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          cacheOptions
          required
        />
        <div className={styles.btnsRow}>
          <Button variant="outline" onClick={onCloseModal} className={styles.btn}>
            Cancel
          </Button>
          <Button type="submit" className={styles.btn} isLoading={isCreateLoading}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default AddGroupModal;
