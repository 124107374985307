import { CLUSTER, POD } from "../constant";
import { uselistofAlertsClusterData, uselistofAlertsNamespaceData } from "../service/api";
import _ from "lodash";

export const TransformCluster = async (data, api, param1, param2, setData, setLoading) => {
  let promise = data?.map(async res => {
    return api(res[param1]).then(resd => {
      return resd;
    });
  });
  Promise.all(promise).then(function(response) {
    data?.map((x, i) => {
      response?.map(y => {
        if (x[param1] === y[param2]) {
          let dataforCluster = {
            ...x,
            AlertsCount: y?.AlertsCount,
            LabelCount: y?.LabelCount,
            NamespaceCount: y?.NamespaceCount,
            NodeCount: y?.NodeCount,
            PodCount: y?.PodCount,
            PolicyCount: y?.PolicyCount
          };
          setData(e => [...e, dataforCluster]);
        }
      });
    });
    setLoading(false);
  });
};

export const AddId = data => {
  return data?.map((e, i) => {
    e.id = i;
    return e;
  });
};
export const splitName = e => {
  const arr = e.split(":");
  return arr[1];
};

export const filterUnSelected = (data, select) => {
  let TransformFilter = select?.map(e => ({
    name: splitName(e?.text),
    type: e?.key
  }));
  if (select?.length) {
    let tmp1 = TransformFilter?.map(e => e?.name);
    let tmp2 = data.filter(val => !tmp1.includes(val));
    return tmp2;
  } else {
    return data;
  }
};
export const AlertsDetails = async (
  list,
  selectedView,
  setGraphData,
  range,
  setClusterAlerts,
  selectedCluster
) => {
  if (selectedView === CLUSTER) {
    const promise = list?.map(async clusters => {
      const data2 = await uselistofAlertsClusterData(clusters?.ID, range);
      const values = Object.values(data2?.response?.Alerts);
      let totalCount = values?.reduce((total, count) => {
        return total + count;
      }, 0);
      return {
        ClusterID: clusters?.ID,
        TotalAlerts: totalCount
      };
    });
    Promise.all(promise).then(response => {
      const a3 = response.map(t1 => ({
        ...t1,
        ...list.find(t2 => t2?.id === t1?.ClusterID)
      }));
      setClusterAlerts(response);
      setGraphData(a3);
    });
  } else if (selectedView === POD) {
    let filterPod = _.uniqBy(list, "namespace_id");
    const promise = filterPod?.map(async namespace => {
      const data2 = await uselistofAlertsNamespaceData(
        namespace?.namespace,
        selectedCluster,
        range
      );
      const values =
        data2?.response?.Namespaces &&
        Object.values(data2?.response?.Namespaces[0]?.Alerts?.Actions);
      let totalCount = values?.reduce((total, count) => {
        return total + count;
      }, 0);
      return {
        ID: namespace?.namespace_id,
        TotalAlerts: totalCount || 0
      };
    });
    Promise.all(promise).then(response => {
      const a3 = list?.map(t1 => ({
        ...t1,
        ...response.find(t2 => t2?.ID === t1?.namespace_id)
      }));
      setGraphData(a3);
    });
  }
};
export const TransformPod = (data, page, api, param, setPods, setLoading) => {
  let pages = page * 10;
  setLoading(true);
  let promise = data?.slice(pages, pages + 10)?.map(async res => {
    return api(res[param]).then(response => {
      return response;
    });
  });
  Promise.all(promise).then(response => {
    const result = data?.map(async x => {
      response?.map(y => {
        if (x[param] === y[param]) {
          x = {
            ...x,
            LabelCount: y?.LabelCount,
            ContainerCount: y?.ContainerCount,
            PolicyCount: y?.PolicyCount,
            AlertsCount: y?.AlertsCount
          };
        }
      });
      return x;
    });
    Promise.all(result).then(res => {
      setPods(res);
      setLoading(false);
    });
  });
};
