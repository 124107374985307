import React from "react";
import { Button, Modal, Select } from "components/simple";

import styles from "./styles.module.scss";

const EditModal = ({ value, options, title, isOpen, toggle, onChange, onSubmit }) => {
  const saveHandler = () => {
    toggle();
    onSubmit();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <h3 className={styles.title}>{`Edit ${title}`}</h3>
      <Select
        label={`Select ${title}`}
        value={value}
        options={options}
        onChange={val => onChange(val, title)}
      />
      <div className={styles.btnsContainer}>
        <Button variant="outline" onClick={toggle} className={styles.btn}>
          Close
        </Button>
        <Button onClick={saveHandler} className={styles.btn}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default EditModal;
