/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import { DataSource, Integrations } from "./Tabs";
import { routes } from "router/router";
import styles from "./styles.module.scss";
import { useBasePath } from "helper/history";
import Registry from "./Tabs/Registry/Registry";
import { Tabs, Button } from "components/simple";
import AddRegistry from "./Tabs/Registry/AddRegistry";
import EditRegistry from "./Tabs/Registry/EditRegistry";
import ChannelIntegration from "screens/ChannelIntegration";
import { INTEGRATION_LINK } from "../ChannelIntegration/constants/index";

const IntegrationTabs = ({ history }) => {
  const current = useBasePath();
  const getActive = () => {
    switch (current) {
      case routes.settingsIntegrations:
        return 0;
      case routes.settingsIntegrationsCspm:
        return 1;
      case routes.settingsIntegrationsS3DataSource:
        return 3;
      case routes.settingsIntegrationsRegistry:
        return 2;
    }
  };

  const activeTab = getActive();
  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        return history.push(routes.settingsIntegrations);
      case 1:
        return history.push(routes.settingsIntegrationsCspm);
      case 2:
        return history.push(routes.settingsIntegrationsRegistry);
      case 3:
        return history.push(routes.settingsIntegrationsS3DataSource);
      default:
        return history.push(routes.settingsIntegrations);
    }
  };

  const tabs = [
    {
      id: 0,
      label: `CWPP`,
      content: null
    },
    {
      id: 1,
      label: `CSPM`,
      content: null
    },
    {
      id: 2,
      label: `Registry`,
      content: null
    },
    {
      id: 3,
      label: "S3 Data Source",
      content: null
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.containerBtn}>
        <Tabs tabs={tabs} value={activeTab} handleChange={handleChange} />
        {activeTab === 0 ? (
          <Button className={styles.integrationBtn} onClick={() => history?.push(INTEGRATION_LINK)}>
            View Integration List
          </Button>
        ) : (
          <></>
        )}
      </div>

      <Route exact path={routes.settingsIntegrations} component={ChannelIntegration} />
      <Route exact path={routes.settingsIntegrationsCspm} component={Integrations} />
      <Route exact path={routes.settingsIntegrationsRegistry} component={Registry} />
      <Route exact path={routes.settingsIntegrationsRegistryAdd} component={AddRegistry} />
      <Route exact path={routes.settingsIntegrationsRegistryEdit} component={EditRegistry} />
      <Route ecact path={routes.settingsIntegrationsS3DataSource} component={DataSource} />
    </div>
  );
};

IntegrationTabs.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default IntegrationTabs;
