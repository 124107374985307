import React, { memo } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Icon } from "components/simple";

import styles from "./styles.module.scss";

const modalStyles = {
  height: "100%",
  width: "100%",
  display: "block"
};

export const SubModal = memo(({ data, isOpen, onClose, title }) => {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        style={modalStyles}
        aria-describedby="submodal-description"
      >
        <Box className={styles.content}>
          <IconButton disableRipple disableTouchRipple disableFocusRipple onClick={onClose}>
            <Icon.Close />
          </IconButton>
          <Typography id="modal-modal-description" component="h4">
            {title}
          </Typography>
          {!!data?.length && (
            <Typography component="ul">
              Categories:
              {data?.map(item => (
                <Typography key={item} component="li">
                  {item}
                </Typography>
              ))}
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
});
