import React from "react";

import { Table } from "components/complex";
import { imageDetailsTable } from "./utils";
import { useFindingsAccordianImagesQuery } from "store/entities/issues/registryScan/queries";

export const ImagesDetailTable = ({ registryId, repositoryName }) => {
  const findingsAccordianQuery = useFindingsAccordianImagesQuery({ registryId, repositoryName });

  return (
    <Table
      autoHeight
      hideFooter
      // headerHeight={40}
      checkboxVisible={false}
      data={findingsAccordianQuery?.data}
      columns={imageDetailsTable?.headers}
      customStyles={imageDetailsTable?.styles}
      loading={findingsAccordianQuery?.isLoading}
    />
  );
};
