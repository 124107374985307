import { useDispatch } from "react-redux";
import store from "../store";
import { addNotification } from "store/entities/notifications/actions";

const getMessage = async response => {
  let message = "";
  if (response.status === 401) {
    try {
      const responseErrors = await response.json();
      message = { ...responseErrors };
    } catch (err) {
      message = {};
    }

    return message;
  }

  try {
    const responseErrors = await response.json();
    // eslint-disable-next-line no-prototype-builtins
    if (responseErrors.hasOwnProperty("non_field_error")) {
      message = { non_field_errors: responseErrors.non_field_error };
      // eslint-disable-next-line no-prototype-builtins
    } else if (responseErrors.hasOwnProperty("detail")) {
      message = { non_field_errors: responseErrors.detail };
      // eslint-disable-next-line no-prototype-builtins
    } else if (responseErrors.hasOwnProperty("error")) {
      message = { non_field_errors: responseErrors.error };
    } else {
      return responseErrors;
    }
  } catch (error) {
    message = { non_field_errors: response.statusText };
  }

  return message;
};

export const checkStatus = async response => {
  const { status } = response;

  if (status !== 200 && status !== 201 && status !== 202 && status !== 204) {
    const message = await getMessage(response);
    throw message;
    // return { message };
  }
  try {
    if (status !== 204) {
      // eslint-disable-next-line no-return-await
      return await response.json();
    }
  } catch (e) {
    return {};
  }
  return {};
};

export const checkAccuknoxAPIStatus = response => {
  if (!response.ok) {
    throw response;
  }
  return response;
};
