import React from "react";
import { getGroupsMini } from "store/entities/groups/service";

import { SelectAsync } from "components/simple";

const GroupSelect = ({ onInputChange = () => null, page = 0, ...rest }) => {
  const request = async (search, pageNum) => {
    try {
      const res = await getGroupsMini({
        search,
        page: pageNum,
        pageSize: 20
      }).then(r => r.json());
      const listOptions = res?.results?.map(item => ({
        value: item?.id,
        label: item?.name
      }));
      return { options: listOptions, next: res?.next };
    } catch (e) {
      // return console.log("err", e);
    }
  };
  const loadOptions = async (searchText, prevOptions, { page: pageNum }) => {
    const { options, next } = await request(searchText, pageNum);
    const hasMore = !searchText ? Boolean(next) : false;
    return {
      options,
      hasMore,
      additional: {
        page: pageNum + 1
      }
    };
  };
  return (
    <SelectAsync
      {...rest}
      loadOptions={loadOptions}
      onInputChange={onInputChange}
      additional={{
        page
      }}
    />
  );
};

export default GroupSelect;
