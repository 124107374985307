import React, { useEffect } from "react";
import styled from "styled-components";
// import OutsideClickHandler from 'react-outside-click-handler';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import AddIcon from "@mui/icons-material/Add";
import KeyboardIcon from "@mui/icons-material/Keyboard";
// import Typography from '../Typography';
// import { BiPlus } from 'react-icons/bi';
// import { MdKeyboard } from 'react-icons/md';
// import { usePrimaryLayoutTitle } from '../../contexts/primaryLayoutTitle';
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import ButtonLink from "components/simple/ButtonLink";
import { CLUSTER, CONTAINER, POD } from "../constant";
import * as routes from "router/routes";

export default function RightClickMenu({
  setShowRightMenu,
  setShowWorkLoadModal,
  setShowViewPolicy,
  setShowAddLabel,
  setShowAddPolicy,
  xPos,
  yPos,
  setTabNumber,
  isContextMenuVisible,
  selectedview,
  podId,
  nodeId,
  podOnClick,
  nodeOnClick,
  isListView,
  showContextMenuAbove,
  setShowContextMenuAbove,
  setShowContextMenuLeft,
  showContextMenuLeft,
  hybridViewClusterId,
  hybridViewClusterName,
  hybridViewNamespaceName,
  hybridViewNamespaceId,
  selectedPodNamespaceOptions,
  labelTypeForAddPolicy,
  selectedNodeId,
  selectedClusterId,
  throughCluster,
  selectedCluster,
  addPolicyPermission,
  selectedPod,
  selectedNamespace,
  setRightSlideMenu
}) {
  //   const { setMainEntity } = usePrimaryLayoutTitle();
  const history = useHistory();
  useEffect(() => {
    const listener = event => {
      if (event.code === "KeyA") {
        event.preventDefault();
        setShowRightMenu(false);
        history.push({
          state: {
            type: "K8s",
            podId: podId,
            selectedCluster,
            selectedNamespace,
            from: "ClusterManager"
          },
          pathname: routes.createPolicyCluster
        });
      }
      if (event.code === "KeyL") {
        event.preventDefault();
        setShowRightMenu(false);
        setShowAddLabel(true);
      }
      if (event.code === "KeyW") {
        event.preventDefault();
        setShowRightMenu(false);
        setShowWorkLoadModal(true);
      }
    };
    if (isContextMenuVisible) {
      window.addEventListener("keydown", listener);

      return () => {
        window.removeEventListener("keydown", listener);
      };
    } else {
      return () => {
        window.removeEventListener("keydown", listener);
      };
    }
  }, [isContextMenuVisible]);
  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowRightMenu(false);
        setShowContextMenuAbove({ showAbove: false });
        setShowContextMenuLeft({ showLeft: false });
      }}
    >
      <Container
        // style={{ position: 'absolute', top: `${yPos}`, left: `${xPos}` }}
        style={{
          zIndex: 40,
          width: "225px",
          position: "fixed",
          top: `${showContextMenuAbove?.showAbove ? "auto" : yPos}`,
          bottom: `${
            showContextMenuAbove?.showAbove
              ? `${showContextMenuAbove?.newYPosFromTable + 20}px`
              : "auto"
          }`,
          left: `${showContextMenuLeft?.showLeft && !isListView ? "auto" : xPos}`,
          right: `${
            showContextMenuLeft?.showLeft && !isListView
              ? `${showContextMenuLeft?.newXPosFromTable - 20}px`
              : "auto"
          }`
        }}
        showContextMenuAbove={showContextMenuAbove?.showAbove}
        showContextMenuLeft={showContextMenuLeft?.showLeft}
      >
        {addPolicyPermission && selectedview !== CONTAINER && (
          <div className="flex justify-between">
            <div
              onClick={() => {
                setShowRightMenu(false);
                // setShowAddPolicy(true);
                // history.replace({
                //   pathname: "/cluster-management",
                //   state: {
                //     // selectedview: selectedview,
                //     // selectedClusterId: selectedClusterId,
                //     // throughCluster: throughCluster,
                //     // selectedNodeId: selectedNodeId,
                //     // selectedCluster: selectedCluster
                //   }
                // });
                history.push({
                  state: {
                    type: "K8s",
                    podId: podId,
                    selectedCluster,
                    selectedNamespace,
                    from: "ClusterManager"
                    // hybridViewNamespaceName: hybridViewNamespaceName,
                    // hybridViewNamespaceId: hybridViewNamespaceId,
                    // selectedPodNamespaceOptions: selectedPodNamespaceOptions,
                    // labelTypeForAddPolicy: labelTypeForAddPolicy
                  },
                  pathname: routes.createPolicyCluster
                });
              }}
              className=" flex items-center py-1"
            >
              <AddIcon className="inline-block" style={{ fill: "#001EBA" }} />
              <ButtonLink buttonLable="Add Policies" className="inline-block ml-1" />
            </div>
            <div className="flex items-center ">
              <KeyboardIcon style={{ fill: "#B5B7B9" }} fontSize="16" />
              <span style={{ opacity: "0.5", marginBottom: "3px" }}>
                <Typography className="" variant="b2">
                  a
                </Typography>
              </span>
            </div>
          </div>
        )}
        {selectedview !== CONTAINER && (
          <div className="flex justify-between">
            <div
              onClick={() => {
                setShowRightMenu(false);
                setShowAddLabel(true);
              }}
              className="flex items-center py-1"
            >
              <AddIcon className="inline-block" style={{ fill: "#001EBA" }} />
              <ButtonLink buttonLable="Add Label" className="inline-block ml-1" />
            </div>
            <div className="flex items-center pr-1 ">
              <KeyboardIcon style={{ fill: "#B5B7B9" }} fontSize="16" />
              <span style={{ opacity: "0.5" }}>
                <Typography className="" variant="b2">
                  l
                </Typography>
              </span>
            </div>
          </div>
        )}

        {/* <div className="flex justify-between">
          <div
            onClick={() => {
              setShowRightMenu(false);
              setShowWorkLoadModal(true);
            }}
            className="flex items-center py-1"
          >
            <BiPlus className="inline-block" color="#001EBA" />
            <Typography
              className="inline-block ml-1"
              variant="b2"
              title="Add Workload Type"
              color="#001EBA"
              fontWeight="600"
            ></Typography>
          </div>
          <div className="flex items-center ">
            <MdKeyboard fill="#B5B7B9" fontSize="16" />
            <span style={{ opacity: '0.5', marginBottom: '1px' }}>
              <Typography className="" variant="b2" title="w" />
            </span>
          </div>
        </div> */}
        {selectedview !== CONTAINER && (
          <>
            {podId?.length < 2 && nodeId?.length < 2 && (
              <>
                <hr color="#E4E4E4" className="my-1"></hr>
                {selectedview === CLUSTER && !isListView && (
                  <div
                    onClick={() => {
                      setShowRightMenu(false);
                      podOnClick();
                    }}
                    className="flex"
                  >
                    <ButtonLink className="py-3" fontWeight="600" buttonLable="View Pods" />
                  </div>
                )}
                {selectedview === CLUSTER && !isListView && (
                  <div
                    onClick={() => {
                      setShowRightMenu(false);
                      nodeOnClick();
                    }}
                  >
                    <ButtonLink className="py-3" fontWeight="600" buttonLable="View Nodes" />
                  </div>
                )}
                <div
                  onClick={() => {
                    setShowRightMenu(false);
                    setTabNumber(0);
                    // setMainEntity('Cluster');
                    setShowViewPolicy(true);
                  }}
                  className="flex"
                >
                  <ButtonLink
                    className="py-3"
                    fontWeight="600"
                    buttonLable="View Policies"
                    // onClick={() => setRightSlideMenu(true)}
                    onClick={() =>
                      history.push({
                        pathname: routes.allPolicies,
                        state: {
                          cluster: [
                            {
                              label: selectedCluster?.label,
                              value: selectedCluster?.id || selectedCluster?.value
                            }
                          ],
                          namespace: [
                            {
                              label: selectedNamespace?.label,
                              value: selectedNamespace?.id || selectedNamespace?.value
                            }
                          ]
                        }
                      })
                    }
                  />
                </div>
                {selectedview === POD && selectedNamespace?.label !== "kube-system" && (
                  <div
                    onClick={() =>
                      history.push({
                        state: {
                          selectedCluster,
                          selectedPod,
                          selectedNamespace
                        },
                        pathname: routes.podObservability
                      })
                    }
                  >
                    <ButtonLink
                      className="py-3"
                      fontWeight="600"
                      buttonLable="View Application Behaviour"
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </ClickAwayListener>
  );
}

const Container = styled.div`
  cursor: pointer;
  *:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 8px 10px;
  &:before {
    position: absolute;
    top: ${props => (props.showContextMenuAbove ? "auto" : "-10px")};
    left: ${props => (props.showContextMenuLeft ? "auto" : "10px")};
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    z-index: 2;
    bottom: ${props => (props.showContextMenuAbove ? "-10px" : "auto")};
    right: ${props => (props.showContextMenuLeft ? "10px" : "auto")};
    transform: ${props => (props.showContextMenuAbove ? "rotate(180deg)" : "none")};
  }
  &:after {
    position: absolute;
    top: -10px;
    left: 10px;
    content: "";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    z-index: 1;
  }
`;
