import { ContentContainer } from "components/simple";
import React from "react";

import styles from "./styles.module.scss";

const AssetTypeBlock = ({ title, amount }) => {
  return (
    <ContentContainer className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.amount}>{amount}</div>
    </ContentContainer>
  );
};

export default AssetTypeBlock;
