import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  groups: {},
  group: {},
  groupsMini: {},
  groupsParams: {
    page: 0,
    pageSize: 20,
    search: "",
    ordering: ""
  },
  groupAssets: {},
  groupAssetsParams: {
    page: 0,
    pageSize: 20,
    search: "",
    ordering: "",
    groupId: ""
  }
};

const reducer = createReducer(
  initialState,
  {
    [actions.setGroupsParams.type]: (state, action) => ({
      ...state,
      groupsParams: {
        ...state.groupsParams,
        ...action.payload
      }
    }),
    [actions.setGroupAssetsParams.type]: (state, action) => ({
      ...state,
      groupAssetsParams: {
        ...state.groupAssetsParams,
        ...action.payload
      }
    }),
    [actions.getGroupsSuccess.type]: (state, action) => ({
      ...state,
      groups: action.payload
    }),
    [actions.getGroupAssetsSuccess.type]: (state, action) => ({
      ...state,
      groupAssets: action.payload
    }),
    [actions.getGroupSuccess.type]: (state, action) => ({
      ...state,
      group: action.payload
    }),
    [actions.updateGroupSuccess.type]: (state, action) => ({
      ...state,
      group: action.payload
    }),
    [actions.getGroupsMiniSuccess.type]: (state, action) => ({
      ...state,
      groupsMini: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
