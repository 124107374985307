import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { formatDate } from "helper/DateLib";

import renderCellExpand from "helper/renderCellExpand";

const vulnerabilities = state => state.vulnerabilities?.vulnerabilities;
const vulnerability = state => state.vulnerabilities?.vulnerability;
const severityOptions = state => state.vulnerabilities?.severityOptions;
const statusesOptions = state => state.vulnerabilities?.statusesOptions;
const vulnerabilityHistory = state => state.vulnerabilities?.history;
const vulnerabilityAssets = state => state.vulnerabilities?.assets;
const vulnerabilityConfigs = state => state.vulnerabilities?.vulnerabilityConfigs;
const vulnerabilityConfigsFilterData = state =>
  state.vulnerabilities?.vulnerabilityConfigsFilterData;

const tagsVulnerabilities = state => state.vulnerabilities?.tags;
const groupsVulnerabilities = state => state.vulnerabilities?.groups;
const targetsVulnerabilities = state => state.vulnerabilities?.targets;
const targetsTypeVulnerabilities = state => state.vulnerabilities?.targetsType;

const ticketsConfig = state => state.tickets?.ticketsConfig;

export const defaultDisplayFieldsList = [
  "last_seen",
  "vulnerability__name",
  "status",
  "tickets",
  "ignored",
  "vulnerability__data_type",
  "vulnerability__exploit_available",
  "asset__title"
];

export const temporaryDataDefault = {
  filter_fields: ["risk_factor", "ignored", "status", "has_tickets", "group_id", "scan_id"],
  display_fields: defaultDisplayFieldsList
};

function sort(data, sortOrderBy) {
  const itemsById = new Map(data.map(item => [item.field, item]));
  return sortOrderBy.map(field => itemsById.get(field)).filter(item => item);
}

const createColumns = (obj, dict, display_fields_width) => {
  const res = Object.entries(obj)
    .filter(([key]) => dict?.[key] || key === "id")
    .map(([key]) => {
      const fieldWidth = display_fields_width
        ? display_fields_width?.find(item => item?.field === key)
        : undefined;
      // eslint-disable-next-line no-nested-ternary
      const width = fieldWidth ? fieldWidth?.width : key === "vulnerability__name" ? 450 : 120;

      return {
        headerName: dict?.[key] || "Null",
        field: key,
        resizable: key !== "group_ids",
        width,
        renderCell: renderCellExpand,
        headerClassName: "super-app-theme--header",
        hide: Boolean(!dict?.[key]),
        sortable: true,
        ...{
          ...(key === "id"
            ? {
                hide: !dict?.id
              }
            : {})
        },

        ...{
          ...(key === "last_seen"
            ? {
                valueGetter: ({ row }) => {
                  return formatDate(row.last_seen);
                }
              }
            : {})
        },
        ...{
          ...(key === "date_discovered"
            ? {
                valueGetter: ({ row }) => {
                  return formatDate(row.date_discovered);
                }
              }
            : {})
        },
        ...{
          ...(key === "group_ids"
            ? {
                valueGetter: ({ row }) => {
                  return row.group_ids?.length || 0;
                }
              }
            : {})
        }
      };
    });
  const sortedData = sort(res, ["id", ...Object.keys(dict)]);
  return sortedData;
};

export const tableBuilder = (state, display_fields, groupBy, display_fields_width) => {
  const isGroupBy = Boolean(groupBy);

  if (!state?.results?.length) {
    return { total: 0, count: 0, columns: [], data: [] };
  }

  const formatData = d => {
    const res = d.map(item => {
      const newItem = {};
      Object.entries(item).forEach(([key, val]) => {
        if (key === "tickets") {
          newItem[key] = val?.length;
          return;
        }
        if (key === "group_ids") {
          newItem[key] = val || [];
          return;
        }
        if (Array.isArray(val)) {
          newItem[key] = val.join(",");
          return;
        }
        if (key === "active") {
          newItem[key] = val;
          return;
        }
        if (typeof val === "boolean") {
          newItem[key] = val ? "True" : "False";
          return;
        }
        if (typeof val !== "string" && typeof val !== "number") {
          newItem[key] = "";
          return;
        }
        newItem[key] = val;
      });
      return newItem;
    });

    return res;
  };

  const dict = state?.display_fields || {};

  const defaultDisplayFields = display_fields?.length ? display_fields : defaultDisplayFieldsList;

  const getVisibleFields = () => {
    if (defaultDisplayFields?.length) {
      let allowed = defaultDisplayFields;
      if (isGroupBy) {
        allowed = ["group_ids", ...defaultDisplayFields];
      }
      const filtered = allowed
        .filter(key => allowed.includes(key))
        .reduce((ob, key) => {
          // eslint-disable-next-line no-param-reassign
          ob[key] = dict[key];
          return ob;
        }, {});
      return filtered;
    }
    return dict;
  };
  const dictSort = getVisibleFields();
  const result = formatData(state.results);
  return {
    total: state.count,
    count: state.count,
    columns: createColumns(result[0], dictSort, display_fields_width),
    data: result
  };
};

export const getVulnerabilitiesSelector = createDraftSafeSelector(
  vulnerabilities,
  (_, display_fields) => display_fields,
  (_, __, groupBy) => groupBy,
  (_, ___, __, display_fields_width) => display_fields_width,
  (state, display_fields, groupBy, display_fields_width) => {
    return tableBuilder(state, display_fields, groupBy, display_fields_width);
  }
);

export const vulnerabilityConfigsSelector = createDraftSafeSelector(vulnerabilityConfigs, state => {
  if (!state?.results?.length) {
    return [];
  }

  const res = state.results.map(item => {
    return {
      value: item.id,
      label: item.name,
      result: { ...item, display_fields_width: item?.table_properties?.display_fields_width ?? [] }
    };
  });
  return res;
});

export const createList = obj => {
  if (obj) {
    return Object.entries(obj).map(([key, val]) => {
      return { value: key, label: val };
    });
  }
  return [];
};

export const vulnerabilityConfigsFiltersDataSelector = createDraftSafeSelector(
  vulnerabilityConfigsFilterData,
  state => {
    const data_types = createList(state?.data_types);
    const filter_fields = createList(state?.filter_fields);
    const display_fields = createList(state?.display_fields);
    const res = {
      data_types_list: data_types,
      filter_fields_list: filter_fields,
      display_fields_list: display_fields,
      dictList: state?.filter_fields
    };
    return res;
  }
);

export const getTicketsConfigSelect = createDraftSafeSelector(ticketsConfig, state => {
  if (!state?.length) {
    return [];
  }
  const res = state.map(item => {
    return {
      value: item.id,
      label: item.name
    };
  });
  return res;
});

export const getGroupByListSelect = createDraftSafeSelector(
  vulnerabilities,
  (_, display_fields) => display_fields,
  (state, display_fields) => {
    const group_by_fields = state?.group_by_fields;
    if (!group_by_fields) {
      return [];
    }
    const res = display_fields?.length
      ? Object.keys(group_by_fields)
          .filter(key => display_fields.includes(key))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: group_by_fields[key]
            });
          }, {})
      : group_by_fields;

    if (display_fields.includes("asset__title")) {
      res.asset__id = "Asset";
    }
    return createList(res);
  }
);

export const getVulnerabilitySelector = createDraftSafeSelector(vulnerability, state => state);
export const getVulnerabilityHistorySelector = createDraftSafeSelector(
  vulnerabilityHistory,
  state => state
);

export const getVulnerabilityAssetsSelector = createDraftSafeSelector(
  vulnerabilityAssets,
  state => state
);

export const getSeverityOptionsSelector = createDraftSafeSelector(severityOptions, state => {
  if (!state?.risk_factors?.length) {
    return [];
  }

  const res = state.risk_factors.map(item => {
    return {
      value: item[0],
      label: item[1]
    };
  });
  return res;
});

export const getStatusesOptionsSelector = createDraftSafeSelector(statusesOptions, state => {
  if (!state?.statuses?.length) {
    return [];
  }

  const res = state.statuses.map(item => {
    return {
      value: item[0],
      label: item[1]
    };
  });
  return res;
});

export const getDisplayFieldsListSelect = createDraftSafeSelector(vulnerabilities, state => {
  const dist = state?.display_fields || {};
  if (!dist) {
    return [];
  }

  return createList(dist);
});

export const getGroupsVulnerabilitiesListSelect = createDraftSafeSelector(
  groupsVulnerabilities,
  state => {
    if (!state?.results?.length) {
      return [];
    }

    const res = state?.results.map(item => {
      return {
        value: item.id,
        label: item.name
      };
    });

    return res;
  }
);

export const getTagsVulnerabilitiesListSelect = createDraftSafeSelector(
  tagsVulnerabilities,
  state => {
    if (!state?.length) {
      return [];
    }
    const res = state?.map(item => {
      return {
        value: item.id,
        label: item.value
      };
    });
    return res;
  }
);

export const getTargetsVulnerabilitiesListSelect = createDraftSafeSelector(
  targetsVulnerabilities,
  state => {
    if (!state?.results?.length) {
      return [];
    }
    const res = state?.results.map(item => {
      return {
        value: item.id,
        label: item.name,
        target_type: item.target_type
      };
    });
    return res;
  }
);

export const getTargetsTypeVulnerabilitiesListSelect = createDraftSafeSelector(
  targetsTypeVulnerabilities,
  state => {
    if (!state.length) {
      return [];
    }
    const res = state?.map(item => {
      return {
        value: item.id,
        label: item.name,
        fields: item?.fields.map(it => ({ value: it.id, label: it.name }))
      };
    });

    return res;
  }
);
