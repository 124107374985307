import React from "react";

const ShowPass = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-1" y="-4" width="24" height="24" fill="#858C94" />
    </svg>
  );
};

export default ShowPass;
