import React from "react";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

const tags = state => state.tags.tags;
const tagsMini = state => state.tags?.tagsMini;

export const tagsMiniSelector = createDraftSafeSelector(tagsMini, state => {
  if (!state?.results?.length) {
    return [];
  }
  const { results } = state;
  const data = results?.map(item => {
    return {
      value: item?.id,
      label: item?.value
    };
  });
  return data;
});

const tagsTableColumns = () => [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Name",
    field: "value",
    resizable: true,
    minWidth: 600,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const getTagsSelector = createDraftSafeSelector(tags, tagsTableColumns, (state, columns) => {
  if (!state?.results?.length) {
    return { columns, data: [], total: 0 };
  }

  const { results, count } = state;
  return { columns, data: results, total: count };
});
