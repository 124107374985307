import React, { useState, useEffect } from "react";
import { Widget } from "components/simple/Charts/widgets";
import {
  ImageSeverities,
  Remediationsdata,
  imageSecurities,
  NameSpaceAlertsdata,
  PolicyCountdata,
  Remediations,
  TopfivepodCount,
  TOPFIVE_PODS,
  Mockdata1,
  Mockdata2
} from "./mockdata";
import { SingleProgressBar, TransformText } from "components/simple/Charts/singleProgressbar";
import { StackedBar } from "screens/Issues/RegistryScan/components/common";
import { Box, Typography } from "@mui/material";
// import { useImageRiskAssessment, useImageSecurityIssues, useRemediations } from "./service/query";
import DateRangePicker from "components/simple/DateRangePicker/DateRangePicker-2";
import { Button, ContentContainer, Select, Switch } from "components/simple";
import {
  Bar,
  //   BarChart,
  //   CartesianGrid,
  //   Label,
  //   LabelList,
  //   Legend,
  ResponsiveContainer
  //   Tooltip,
  //   XAxis,
  //   YAxis
} from "recharts";
import LoopIcon from "@mui/icons-material/Loop";
import { imageSeverities } from "screens/Issues/RegistryScan/mockData";
import { DoubleRadiusPieChart } from "components/simple/Charts/DoubleRadiusPiechart";
// import { DoubleRadiusPieChart } from "../../components/simple/Charts/DoubleRadiusPiechart";
import {
  fetchClustersList,
  fetchNamespaceList,
  useClustersList,
  useImageRiskAssessment,
  useImageSecurityIssues,
  usePodCount,
  usePolicyCount,
  useRemidationCount
} from "./service/query";
import { CountDataConversion } from "helper/dataTransformation";
import { navigate } from "helper/history";
import { routes } from "router/router";
import _ from "lodash";
import Loader from "components/simple/Loader";
import { COLORS } from "screens/RuntimeSecurity/CWPP/constants";
import RechartPieChart, {
  CustomTooltip
} from "../../components/simple/Charts/Recharts/RechartPiechart";
import styles from "./styles.module.scss";
import { getAccuknoxWorkspace } from "api/api";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedClusters, setSelectedClusters } from "store/entities/filters/slice";
import { useHistory } from "react-router-dom";
import { getSingleClusters, setSingleClusters } from "store/entities/filters/singleFilters/slice";
import useEffectAfterMount from "helper/hooks/useEffectAfterMount";
import NoChart from "components/simple/Icon/Nochart";
import moment from "moment";
import { getTimeRange } from "helper/getTimeRange";
import { getDifferenceTime } from "screens/RuntimeSecurity/CWPP/service";
import Barchart from "components/simple/Charts/Barchart";

export const CustomBarChartTooltip = ({ active, payload, label }) => {
  if (payload?.length) {
    return (
      <div
        style={{ backgroundColor: "#272727" }}
        className="flex-row items-center justify-center w-auto h-auto p-3 shadow-2xl rounded-xl shadow-blue-500/50"
      >
        <div className="text-sm text-white">{label}</div>
        <div className="flex-row items-center justify-center w-full text-sm">
          {payload?.map(data => {
            return (
              <div className="text-white ">
                {`${data?.name} : `} <span>{data?.value}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const legendWrapperStyle = {
  position: "absolute",
  fontSize: "12px",
  width: "auto",
  height: "45px",
  top: "81%",
  left: "30px",
  right: "0px",
  WebkitTextFillColor: "rgb(0, 0, 0)",
  display: "flex",
  overflow: "hidden auto"
};
export const toolTipWrapper = {
  display: "flex",
  maxHeight: "150px",
  maxWidth: "15rem",
  opacity: "0.9",
  //   width: "auto",
  overflowY: "auto",
  overflowX: "auto",
  pointerEvents: "auto"
};
export const REMEDIATIONS = ["#9EED60", "#FEBD47", "#576AC9", "#EB5959", "#FF7F82"];
export const SEVERITY_COLORS = {
  critical: "#BB0504",
  high: "#FF435F",
  medium: "#FF8E50",
  low: "#FEC051",
  unknown: "#DADADA",
  no_issue: "#2CAA00"
};
export const CnappDashboard = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.users.accessToken);

  const timeRange = [
    { value: "Last 12 hours", id: 1 },
    { value: "Last 24 hours", id: 2 },
    { value: "Last 2 days", id: 3 },
    { value: "Last 7 days", id: 4 }
    // { value: "Last 30 days", id: 5 },
    // { value: "Last 60 days", id: 6 }
  ];
  const tootipStyle = {
    display: "flex",
    maxHeight: "150px",
    maxWidth: "20rem",
    opacity: "0.9",
    //   width: "auto",
    overflowY: "auto",
    overflowX: "auto",
    pointerEvents: "auto"
  };
  const singleSelectedClusters = useSelector(getSingleClusters);
  const selectedClusters = useSelector(getSelectedClusters);
  // const [singleSelectedClusters, setSelectedClusters] = useState([]);
  const [complianceAlerts, setComplianceAlert] = useState({ data: [], tags: [] });
  const [remediation, setRemediation] = useState([]);
  const [Timerange, setTimeRange] = useState(timeRange[2]?.value);
  const [nameSpaceAlerts, setNameSpaceAlerts] = useState([]);
  const [alertsOperation, setAlertsOperation] = useState({ data: [], keys: [] });
  const [alertPolicy, setAlertPolicy] = useState({ data: [], keys: [] });
  const [created, setCreated] = useState(false);
  const onChangeRange = range => setTimeRange(range);
  const workspaceId = getAccuknoxWorkspace();
  const history = useHistory();
  const {
    data: imagelistData = {},
    isLoading: isImageListLoading,
    refetch: refetchImageSecurity
  } = useImageSecurityIssues(workspaceId, Timerange);
  const {
    data: imageRiskData = {},
    isLoading: isImageRiskLoading,
    refetch: refetchRiskAssessment
  } = useImageRiskAssessment(workspaceId, Timerange);
  const {
    data: clusterData = {},
    isLoading: isClusterDataisLoading,
    isFetching: isClusterDataisFetching,
    isSuccess: isClusterDataisSuccess,
    refetch: refetchClusterCount
  } = fetchClustersList(workspaceId, Timerange, singleSelectedClusters);
  const {
    data: namespaceData = {},
    isLoading: isNamespaceDataisLoading,
    isFetching: isNamespaceDataisFetching,
    isSuccess: isNamespaceDataisSuccess,
    refetch: refetchNameSpaceCount
  } = fetchNamespaceList(workspaceId, Timerange, singleSelectedClusters);
  const {
    data: policyCountData = {},
    isLoading: isPolicyDataisLoading,
    isFetching: isPolicyDataisFetching,
    isSuccess: isPolicyDataisSuccess,
    refetch: refetchPolicyCount
  } = usePolicyCount(workspaceId, Timerange, singleSelectedClusters);
  const {
    data: podList = {},
    isLoading: isPodDataLoading,
    isFetching: isPodDataisFetching,
    isSuccess: isPodDataisSuccess,
    refetch: refetchPodData
  } = usePodCount(workspaceId, singleSelectedClusters, Timerange);
  const {
    data: clusters = {},
    isSuccess: isClusterIdSuccess,
    refetch: refetchClusters
  } = useClustersList(workspaceId, accessToken);
  const {
    data: remidationData = {},
    isLoading: RemidationLoading,
    isFetching: isRemidationisFetching,
    refetch: refetchRemediationCount
  } = useRemidationCount(Timerange, workspaceId, created);

  useEffect(() => {
    if (!RemidationLoading) {
      const remidation = _.isEmpty(remidationData)
        ? []
        : Object.keys(remidationData)?.map((e, i) => {
            return {
              name: e,
              value: remidationData[e],
              color: REMEDIATIONS[i]
            };
          });
      setRemediation(remidation);
    }
  }, [JSON.stringify(remidationData), RemidationLoading]);
  useEffect(() => {
    if (isClusterIdSuccess) {
      const filterCluster = clusters?.options?.filter(c =>
        singleSelectedClusters?.some(fcluster => c?.value === fcluster?.value)
      );
      if (!filterCluster?.length) {
        dispatch(setSingleClusters([clusters?.options?.[0]]));
        // dispatch(setSelectedClusters([clusters?.options?.[0]]));
      }
    }
  }, [JSON.stringify(clusters)]);

  const CombineCount = (array, name, key, response) => {
    if (array?.length) {
      array[0].mitre += response;
      array[0].count += response;
    } else {
      array.push({
        name: name,
        nist: key,
        nist: response,
        count: response
      });
    }
  };
  useEffect(() => {
    let nist_Tags = [];
    let mitre_Tags = [];
    let pci_Tags = [];
    let cis_Tags = [];
    let TagsList = [];
    if (isClusterDataisSuccess) {
      Object.keys(clusterData?.response?.Alerts?.Tags)?.map(key => {
        TagsList.push(key.split(",").flat());
        if (key.includes("nist") || key.includes("NIST") || key.includes("Nist")) {
          CombineCount(nist_Tags, "NIST", key, clusterData?.response?.Alerts?.Tags[key]);
        }
        if (key.includes("mitre") || key.includes("MITRE") || key.includes("Mitre")) {
          CombineCount(mitre_Tags, "MITRE", key, clusterData?.response?.Alerts?.Tags[key]);
        }
        if (key.includes("pci") || key.includes("PCI") || key.includes("Pci")) {
          CombineCount(pci_Tags, "PCI", key, clusterData?.response?.Alerts?.Tags[key]);
        }
        if (key.includes("cis") || key.includes("CIS") || key.includes("Cis")) {
          CombineCount(cis_Tags, "CIS", key, clusterData?.response?.Alerts?.Tags[key]);
        }
      });
      const tagsFlat = _.flatten(TagsList);
      const tagsFinal = _.uniq(tagsFlat);
      if (!nist_Tags.length) {
        nist_Tags.push({
          name: "NIST",
          mitre: 0,
          count: 0
        });
      }
      if (!mitre_Tags.length) {
        mitre_Tags.push({
          name: "MITRE",
          mitre: 0,
          count: 0
        });
      }
      if (!pci_Tags.length) {
        pci_Tags.push({
          name: "PCI",
          mitre: 0,
          count: 0
        });
      }
      if (!cis_Tags.length) {
        cis_Tags.push({
          name: "CIS",
          mitre: 0,
          count: 0
        });
      }
      setComplianceAlert({
        data: [...nist_Tags, ...mitre_Tags, ...cis_Tags, ...pci_Tags],
        tags: tagsFinal
      });
    }
    const policyData = CountDataConversion(clusterData?.response?.Alerts?.Policies);
    const OperationData = CountDataConversion(clusterData?.response?.Alerts?.Operations);
    setAlertPolicy(policyData);
    setAlertsOperation(OperationData);
  }, [JSON.stringify(clusterData?.response)]);
  useEffect(() => {
    if (isNamespaceDataisSuccess) {
      const namespacealerts = namespaceData?.response?.Namespaces?.map((e, i) => {
        return {
          name: e.NamespaceName,
          value: e.Alerts.Actions.Blocked + e.Alerts.Actions.Audit,
          color: COLORS[i]
        };
      });
      const short = namespacealerts
        ?.sort((a, b) => parseFloat(b?.value) - parseFloat(a?.value))
        .slice(0, 9);
      setNameSpaceAlerts(namespacealerts);
    }
  }, [JSON.stringify(namespaceData)]);
  //widget1
  const RedirectFindingVul = () => {
    if (!isImageRiskLoading && imageRiskData?.severity_assessment?.total_vulnerabilities) {
      navigate(routes.registryScan, { Tag: 0 });
    }
  };
  //widget1
  const RedirectScanQueVul = () => {
    if (!isImageListLoading && imagelistData?.severity_issues?.total_vulnerabilities) {
      navigate(routes.registryScan, { Tag: 1 });
    }
  };
  //widget 5,7,8,9
  const RedirectLogs = (params, key, type) => {
    if (isClusterDataisSuccess && alertsOperation?.data?.data?.length) {
      params.cluster = singleSelectedClusters;
      history.push({
        pathname: routes.alertsSummaryLogs,
        state: { data: params, key: key, type: type }
      });
    }
  };
  // widget 6

  const redirectRemidation = params => {
    const currentTime = new Date();
    let from;
    let to;
    if (typeof Timerange === "string") {
      from = moment(getDifferenceTime(Timerange, currentTime).getTime()).format(
        "YYYY-MM-DD hh:mm:ss A Z"
      );
      to = moment().format("YYYY-MM-DD hh:mm:ss A Z");
    }
    if (typeof Timerange === "object") {
      let modifiedPeriod = { ...Timerange };
      if (moment(Timerange.from).diff(Timerange.to, "seconds") < 30) {
        modifiedPeriod.from = moment(Timerange.from).startOf("day");
        modifiedPeriod.to = moment(Timerange.to).endOf("day");
      }
      from = moment(modifiedPeriod.from).format("YYYY-MM-DD hh:mm:ss A Z");
      to = moment(modifiedPeriod.to).format("YYYY-MM-DD hh:mm:ss A Z");
    }
    const TimeTransform = [from.toString(), to.toString()];
    if (!RemidationLoading && _.sumBy(remediation, "value") && params !== "Total") {
      if (created) {
        navigate(routes.tickets, {
          time: TimeTransform,
          type: { value: TransformText(params?.name), label: TransformText(params?.name) }
        });
      } else {
        navigate(routes.tickets, {
          type: { value: TransformText(params?.name), label: TransformText(params?.name) }
        });
      }
    } else if (params === "Total") {
      navigate(
        routes.tickets,
        created && {
          time: TimeTransform
        }
      );
    }
  };
  const RedirectPolicyScreen = () => {
    if (!isPolicyDataisLoading && policyCountData?.TotalPoliciesCount) {
      navigate(routes.allPolicies);
    }
  };
  const refreshData = () => {
    refetchImageSecurity();
    refetchRiskAssessment();
    refetchClusterCount();
    refetchNameSpaceCount();
    refetchPolicyCount();
    refetchPodData();
    refetchClusters();
    refetchRemediationCount();
  };

  const demoOnClick = e => {
    RedirectLogs();
  };
  const handleFilter = (value, filterName) => {
    if (filterName === "cluster") {
      dispatch(setSingleClusters([value]));
      // dispatch(setSelectedClusters([value]));
    }
  };
  const nameSpaceColors = nameSpaceAlerts?.map(value => value.color);
  const data2 = podList?.result?.map(pod => ({ [pod.name]: pod?.ingress + pod?.egress }));
  const data3 = podList?.result?.map((pod, i) => ({ [pod.name]: COLORS[i] }));
  const newObj = data2 && Object.assign({}, ...data2);
  const newObj2 = data3 && Object.assign({}, ...data3);
  const sum = (arr, type) =>
    arr?.reduce((accumulator, object) => {
      return accumulator + object[`${type}`];
    }, 0);
  const handleProgressClick = (e, type) => {
    if (type && e) {
      navigate(routes.podObservability);
    }
  };

  useEffectAfterMount(() => {
    if (accessToken) {
      refreshData();
    }
  }, [accessToken]);

  return (
    <div className="h-full">
      <Box className="flex justify-between">
        <Box className="py-3">
          <div className="my-auto text-2xl font-medium leading-none ">CNAPP Dashboard</div>
        </Box>
        <Box
          className="flex justify-end w-1/2 p-2"
          sx={{
            borderRadius: 1
          }}
        >
          <Select
            className="mr-2"
            value={singleSelectedClusters}
            onChange={v => handleFilter(v, "cluster")}
            placeholder="select Cluster"
            // defaultValue={{ label: "K8s", value: "GKE" }}
            options={clusters?.options}
            containerClass={styles["action-group__filter-option"]}
            hideSelectedOptions={false}
            // isMulti
          />
          <Button
            onClick={() => refreshData()}
            variant="outline"
            className={`${styles["refreshbtn"]} py-1`}
          >
            Refresh <LoopIcon />
          </Button>
          <DateRangePicker
            // activateDatePicker={false}
            onChange={onChangeRange}
            items={timeRange}
          />
        </Box>
      </Box>

      <div className="grid grid-cols-3 gap-4">
        <Widget
          isHeader={imageRiskData?.severity_assessment?.total_vulnerabilities}
          title="Image Risk Assessment"
          header="Total Vulnerabilities"
          onClickHeader={() => RedirectFindingVul()}
          totalCount={imageRiskData?.severity_assessment?.total_vulnerabilities || 0}
          isHeaderClickable
        >
          {isImageRiskLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : imageRiskData?.severity_assessment?.total_vulnerabilities ? (
            <SingleProgressBar
              onClickOfProgress={() => {}}
              data={imageRiskData?.severity_assessment?.severities}
              totalCount={imageRiskData?.severity_assessment?.total_vulnerabilities || 0}
              isDataLoading={isImageRiskLoading}
              severityColors={SEVERITY_COLORS}
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <NoChart width={`50`} height={`50`} />
              <span className="mt-2 text-sm">No Graph data available!</span>
            </div>
          )}
        </Widget>
        <Widget
          title="Image Security Issues"
          header="Total Images"
          isHeader={imagelistData?.severity_issues?.total_vulnerabilities}
          onClickHeader={() => RedirectScanQueVul()}
          totalCount={imagelistData?.severity_issues?.total_vulnerabilities || 0}
          isHeaderClickable
        >
          {isImageListLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : imagelistData?.severity_issues?.total_vulnerabilities ? (
            <div className="flex flex-col">
              <StackedBar
                data={imagelistData?.severity_issues?.severities}
                dataLength={
                  imagelistData?.severity_issues?.total_vulnerabilities
                    ? Object.keys(imagelistData?.severity_issues?.severities)?.length
                    : 0
                }
                height={56}
                severitycolor={SEVERITY_COLORS}
              />
              <div className="flex justify-between pt-6">
                <div className="flex gap-1">
                  <Typography>Total Malware:</Typography>
                  <Typography fontWeight={600} sx={{ color: SEVERITY_COLORS.critical }}>
                    {imagelistData?.severity_issues?.total_malware || 0}
                  </Typography>
                </div>
                <div className="flex gap-1">
                  <Typography className="">Total Sensitive Data:</Typography>
                  <Typography fontWeight={600} sx={{ color: SEVERITY_COLORS.high }}>
                    {imagelistData?.severity_issues?.total_sensitive_data || 0}
                  </Typography>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <NoChart width={`50`} height={`50`} />
              <span className="mt-2 text-sm">No Graph data available!</span>
            </div>
          )}
        </Widget>
        <Widget
          isHeader={policyCountData?.TotalPoliciesCount}
          title="Runtime Policies Assessment"
          header="Total Policies"
          onClickHeader={() => RedirectPolicyScreen()}
          totalCount={policyCountData?.TotalPoliciesCount}
          isHeaderClickable
        >
          {isPolicyDataisLoading || isPolicyDataisFetching ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : policyCountData?.TotalPoliciesCount ? (
            <DoubleRadiusPieChart
              graphData={policyCountData}
              onClickChart={() => RedirectPolicyScreen()}
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <NoChart width={`50`} height={`50`} />
              <span className="mt-2 text-sm">No Graph data available!</span>
            </div>
          )}
        </Widget>
        <Widget
          isNetworkHeader={sum(podList?.result, "egress") + sum(podList?.result, "ingress")}
          title="Top 5 Pods with Highest Egress and Ingress connections"
          EgressCount={sum(podList?.result, "egress") || 0}
          ingressCount={sum(podList?.result, "ingress") || 0}
          onClickofNetwork={handleProgressClick}
        >
          {isPodDataLoading || isPodDataisFetching ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : podList?.result?.length ? (
            <SingleProgressBar
              data={newObj}
              totalCount={sum(podList?.result, "egress") + sum(podList?.result, "ingress")}
              severityColors={newObj2}
              isProgressClicked
              isDataLoading={false}
              onClickOfProgress={handleProgressClick}
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <NoChart width={`50`} height={`50`} />
              <span className="mt-2 text-sm">No Graph data available!</span>
            </div>
          )}
        </Widget>
        <Widget
          isHeader={_.sumBy(nameSpaceAlerts, "value")}
          title="Top 10 Namespace Specific Alerts"
          header="Total Alerts"
          onClickHeader={() => RedirectLogs()}
          totalCount={_.sumBy(nameSpaceAlerts, "value") || 0}
          isHeaderClickable
        >
          {isNamespaceDataisLoading || isNamespaceDataisFetching ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : nameSpaceAlerts?.length ? (
            // <PieChart data={nameSpaceAlerts} />
            <RechartPieChart
              data={nameSpaceAlerts}
              onClickofChart={data => RedirectLogs(data, "NamespaceName", "NamespaceName")}
              color={nameSpaceColors}
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <NoChart width={`50`} height={`50`} />
              <span className="mt-2 text-sm">No Graph data available!</span>
            </div>
          )}
        </Widget>
        <Widget
          isHeader={_.sumBy(remediation, "value")}
          title="Remediations"
          header="Total Tickets "
          onClickHeader={data => redirectRemidation("Total")}
          totalCount={_.sumBy(remediation, "value") || 0}
          switchCom={true}
          created={created}
          setCreated={setCreated}
          isHeaderClickable
        >
          {RemidationLoading || isRemidationisFetching ? (
            <div className="flex items-center justify-center w-full h-full">
              <Loader />
            </div>
          ) : _.sumBy(remediation, "value") ? (
            <RechartPieChart
              onClickofChart={data => redirectRemidation(data)}
              data={remediation}
              color={REMEDIATIONS}
            />
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <NoChart width={`50`} height={`50`} />
              <span className="mt-2 text-sm">No Graph data available!</span>
            </div>
          )}
        </Widget>
        <ContentContainer className="flex flex-col w-full gap-4 p-4">
          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
            Runtime Alerts: Network, Process, File
          </Typography>
          <ResponsiveContainer width="100%" height="100%">
            {isClusterDataisLoading || isClusterDataisFetching ? (
              <div className="flex items-center justify-center w-full h-full">
                <Loader />
              </div>
            ) : clusterData?.response?.Alerts?.Operations &&
              !_.isEmpty(clusterData?.response?.Alerts?.Operations) ? (
              <Barchart
                data={alertsOperation?.data?.data}
                onClick={data =>
                  RedirectLogs(data?.activePayload[0]?.payload, "Operation", "Operation")
                }
                children={
                  <>
                    <Bar dataKey="File" barSize={10} fill="#9B72F3" />
                    <Bar dataKey="Process" barSize={10} fill="#72F3A6" />
                    <Bar dataKey="Network" barSize={10} fill="#F37272" />
                  </>
                }
                LegendStyle={legendWrapperStyle}
                height={`50`}
              />
            ) : (
              // <CartesianGrid strokeDasharray="3 3" />
              // <XAxis tick={{ fontSize: 10 }} dataKey="name" />
              // <YAxis tick={{ fontSize: 10 }} />
              // <Tooltip cursor={{ fill: "#ffffff" }} />
              // <Legend wrapperStyle={legendWrapperStyle} />
              // <Bar dataKey="File" barSize={10} fill="#9B72F3" />
              // <Bar dataKey="Process" barSize={10} fill="#72F3A6" />
              // <Bar dataKey="Network" barSize={10} fill="#F37272" />
              // {/* </BarChart> */}
              <div className="flex flex-col items-center justify-center w-full h-full">
                <NoChart width={`50`} height={`50`} />
                <span className="mt-2 text-sm">No Graph data available!</span>
              </div>
            )}
          </ResponsiveContainer>
        </ContentContainer>
        <ContentContainer className="flex flex-col w-full gap-4 p-4">
          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Top 10 Alerts by Policy</Typography>
          <ResponsiveContainer width="95%" height="100%">
            {isClusterDataisLoading || isClusterDataisFetching ? (
              <div className="flex items-center justify-center w-full h-full">
                <Loader />
              </div>
            ) : clusterData?.response?.Alerts?.Policies &&
              !_.isEmpty(clusterData?.response?.Alerts?.Policies) ? (
              <Barchart
                data={alertPolicy?.data?.data}
                onClick={data => RedirectLogs(data?.activePayload[0]?.payload, "policy", "policy")}
                children={alertPolicy?.keys?.map((e, i) => {
                  return <Bar dataKey={e} stackId="a" fill={COLORS[i]} />;
                })}
                LegendStyle={legendWrapperStyle}
                height={`50`}
                toolTipStyle={toolTipWrapper}
              />
            ) : (
              //   {/* <CartesianGrid />
              //   <XAxis tick={{ fontSize: 10 }} dataKey="name" />
              //   <YAxis tick={{ fontSize: 10 }} />
              //   <Tooltip cursor={{ fill: "#ffffff" }} />
              //   <Legend wrapperStyle={legendWrapperStyle} />
              //   {alertPolicy?.keys?.map((e, i) => {
              //     return <Bar dataKey={e} stackId="a" fill={COLORS[i]} />;
              //   })}
              // // </BarChart> */}
              <div className="flex flex-col items-center justify-center w-full h-full">
                <NoChart width={`50`} height={`50`} />
                <span className="mt-2 text-sm">No Graph data available!</span>
              </div>
            )}
          </ResponsiveContainer>
        </ContentContainer>
        <ContentContainer className="flex flex-col w-full gap-4 p-4">
          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Compliance Alerts</Typography>
          <ResponsiveContainer width="95%" height={200} viewBox="0 0 363 222">
            {isClusterDataisLoading || isClusterDataisFetching ? (
              <div className="flex items-center justify-center w-full h-full">
                <Loader />
              </div>
            ) : clusterData?.response?.Alerts?.Policies &&
              !_.isEmpty(clusterData?.response?.Alerts?.Policies) ? (
              <Barchart
                data={complianceAlerts?.data}
                yaxis={complianceAlerts.tags}
                onClick={data => RedirectLogs(data?.activePayload[0]?.payload, "Tags", "cnapptags")}
                children={<Bar barSize={30} dataKey="count" fill="#9B72F3" />}
              />
            ) : (
              //   {/* <CartesianGrid strokeDasharray="3 3" />
              //   <XAxis tick={{ fontSize: 10 }} dataKey="name" />
              //   <YAxis tick={{ fontSize: 10 }} />
              //   <Tooltip cursor={{ fill: "#ffffff" }} />
              //   {/* <Legend /> */}
              //   {/* <Bar barSize={30} dataKey="count" fill="#9B72F3" />
              // </Barchart> */} */}
              <div className="flex flex-col items-center justify-center w-full h-full">
                <NoChart width={`50`} height={`50`} />
                <span className="mt-2 text-sm">No Graph data available!</span>
              </div>
            )}
          </ResponsiveContainer>
        </ContentContainer>
      </div>
    </div>
  );
};
