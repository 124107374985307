import { useQuery } from "react-query";
import { getCategories, getPermissions, getRoles } from "./service";

export const useRolesQuery = (page, pageSize) => {
  return useQuery(["settings", "rbac", "getRoles", page + 1, pageSize], () =>
    getRoles(page + 1, pageSize)
  );
};

export const useCategoriesQuery = () => {
  return useQuery(["settings", "rbac", "getCategories"], getCategories, {
    select: response => {
      const data = [{ id: "*", name: "Full Access" }, ...response];
      return data;
    }
  });
};

export const usePermissionsQuery = (category, searchValue) => {
  return useQuery(["settings", "rbac", "getPermissions"], getPermissions, {
    select: response => {
      let data = [];
      const selectAllPermission = { id: "*", value: "*", label: "Select All" };

      const allPermissions = response?.map(permission => ({
        id: permission?.id,
        value: permission?.permission_id,
        label: permission?.permission_name,
        category: permission?.category
      }));

      if (category && allPermissions?.length) {
        if (category?.id === "*") {
          data = [...allPermissions];
        } else {
          data = allPermissions?.filter(p => p?.category === category?.name);
        }
        if (searchValue) {
          data = data?.filter(d => d?.label?.toLowerCase()?.includes(searchValue?.toLowerCase()));
        }
      }
      return data?.length ? [selectAllPermission, ...data] : [];
    }
  });
};
