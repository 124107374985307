import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Title, Input, Button, ContentContainer, Select, ErrorText } from "components/simple";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useForm, Controller } from "react-hook-form";
import SplunkIcon from "../../assets/img/splunk.svg";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { helpSplunk } from "screens/ChannelIntegration/constants";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { getAccuknoxWorkspace } from "api/api";
import { useSplunkIntegration, useSplunkTest } from "store/entities/channels/mutation";

function SplunkIntegration() {
  const [integrationName, setIntegrationName] = useState("");
  const [splunkUrl, setSplunkUrl] = useState("");
  const [token, setToken] = useState("");
  const [index, setIndex] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [source, setSource] = useState("");
  const [splunkType, setSplunkType] = useState("Splunk enterprise");
  const [disableSave, setDisableSave] = useState(true);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: "onChange" });
  const history = useHistory();
  const workspaceVal = getAccuknoxWorkspace();

  const splunkTest = useSplunkIntegration(data => {
    if (data?.response == "Success") {
      dispatch(addNotification({ msg: "Splunk Test Successful!", type: "success" }));
      setDisableSave(true);
    } else {
      setDisableSave(false);
      dispatch(addNotification({ msg: data?.response, type: "error" }));
    }
  });

  const handleTest = async e => {
    try {
      const Secretkey = (Math.random() + 1).toString(36).substring(7);
      const EncryptedURL = CryptoJS.AES.encrypt(splunkUrl, Secretkey).toString();
      const splunk_url = EncryptedURL.concat(",", Secretkey);
      const EncryptedToken = CryptoJS.AES.encrypt(token, Secretkey).toString();
      const splunk_token = EncryptedToken.concat(",", Secretkey);
      const channelId = 2;
      const splunk_source = source;
      const splunk_sourceType = sourceType;
      const source_index = index;
      const option = "test";
      splunkTest?.mutate({
        channelId,
        integrationName,
        splunk_url,
        splunk_token,
        splunk_source,
        splunk_sourceType,
        source_index,
        option
      });
    } catch (err) {
      // console.log(err)
    }
  };
  const splunkIntegration = useSplunkIntegration(data => {
    if (data?.response === "Success") {
      dispatch(addNotification({ msg: "Splunk Integration Successful", type: "success" }));
      history.push("/settings/integrations/list");
    }
  });
  const onSubmit = async e => {
    try {
      const Secretkey = (Math.random() + 1).toString(36).substring(7);
      const EncryptedURL = CryptoJS.AES.encrypt(splunkUrl, Secretkey).toString();
      const splunk_url = EncryptedURL.concat(",", Secretkey);
      const EncryptedToken = CryptoJS.AES.encrypt(token, Secretkey).toString();
      const splunk_token = EncryptedToken.concat(",", Secretkey);
      const channelId = 2;
      const splunk_source = source;
      const splunk_sourceType = sourceType;
      const source_index = index;
      const option = "save";
      splunkIntegration.mutate({
        channelId,
        integrationName,
        splunk_url,
        splunk_token,
        splunk_source,
        splunk_sourceType,
        source_index,
        option
      });
    } catch (err) {
      // console.log(err);
    }
  };
  return (
    <ContentContainer className={styles.container}>
      <div className="flex justify-between py-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="flex gap-1">
          <img src={SplunkIcon} alt="icon" />
          <Title>Splunk</Title>
        </div>
        <div
          onClick={() => window.open(helpSplunk, "_blank", "noopener,noreferrer")}
          className="flex text-blue-700 font-medium cursor-pointer"
        >
          Help <HelpOutlineIcon className={styles.help_icon} />
        </div>
      </div>

      <div className="py-5">
        <div className="flex flex-row gap-5">
          <div className="w-3/6">
            <Controller
              name="integrationName"
              {...register("integrationName", {
                required: "Integration Name Required",
                pattern: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="integrationName"
                  label="Integration Name"
                  placeholder="Enter the Integration Name"
                  required
                  value={integrationName}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setIntegrationName(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.integrationName && errors.integrationName.message} />
            </div>
          </div>
          <div className="w-full">
            <Controller
              name="splunkUrl"
              {...register("splunkUrl", {
                required: "Invalid Splunk URL, Please enter a valid URL",
                pattern: {
                  value: /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/,
                  message: "Enter valid Url."
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="splunkUrl"
                  label="Splunk HTTP event collector URL"
                  placeholder="https://mysplunkserver.example.com:8086/server/collector"
                  required
                  value={splunkUrl}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setSplunkUrl(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.splunkUrl && errors.splunkUrl.message} />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-0 pb-5" style={{ borderBottom: "0.5px solid #dfdfdf" }}>
        <div className="py-5 flex flex-row gap-5">
          <div className="w-3/6">
            <Controller
              name="index"
              {...register("index", {
                required: "Index Required",
                pattern: {
                  value: 20,
                  message: "Maximum length for this field is 20 characters"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="index"
                  label="Index"
                  placeholder="Enter the Index"
                  required
                  value={index}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setIndex(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              <ErrorText errorMsg={errors.index && errors.index.message} />
            </div>
          </div>
          <div className="w-full">
            <Controller
              name="tokenField"
              {...register("tokenField", {
                required: "Token Required",
                maxLength: {
                  value: 200,
                  message: "Maximum length for this field is 200 characters"
                },
                pattern: {
                  value: /^$|^\S+.*/,
                  message: "Empty spaces at the beginning is not accepted"
                }
              })}
              defaultValue=""
              control={control}
              render={({ field: { onChange }, value, ref }) => (
                <Input
                  name="tokenField"
                  label="Token"
                  placeholder="12345678-1234-1234-5678"
                  required
                  value={token}
                  onChange={evt => {
                    onChange(evt.target.value);
                    setToken(evt.target.value);
                  }}
                />
              )}
            />
            <div className="m-0.5">
              {errors.tokenField && <ErrorText errorMsg={errors.tokenField.message} />}
            </div>
          </div>
        </div>
        <div className="pt-0 pb-5">
          <div className=" py-5 flex flex-row gap-5">
            <div className="w-1/3">
              <Controller
                name="sourceName"
                {...register("sourceName", {
                  required: "Source Required",
                  pattern: {
                    value: 20,
                    message: "Maximum length for this field is 20 characters"
                  }
                })}
                defaultValue=""
                control={control}
                render={({ field: { onChange }, value, ref }) => (
                  <Input
                    name="sourceName"
                    label="Source"
                    placeholder="Enter the source of Log"
                    required
                    value={source}
                    onChange={evt => {
                      onChange(evt.target.value);
                      setSource(evt.target.value);
                    }}
                  />
                )}
              />
              <div className="m-0.5">
                <ErrorText errorMsg={errors.sourceName && errors.sourceName.message} />
              </div>
            </div>
            <div className="w-1/3">
              <Controller
                name="sourceType"
                {...register("sourceType", {
                  required: false,
                  maxLength: {
                    value: 20,
                    message: "Maximum length for this field is 20 characters"
                  },
                  pattern: {
                    value: /^$|^\S+.*/,
                    message: "Empty spaces at the beginning is not accepted"
                  }
                })}
                defaultValue=""
                control={control}
                render={({ field: { onChange }, value, ref }) => (
                  <Input
                    name="sourceType"
                    label="Source Type"
                    placeholder="Enter the Source Type"
                    value={sourceType}
                    onChange={evt => {
                      onChange(evt.target.value);
                      setSourceType(evt.target.value);
                    }}
                  />
                )}
              />
              <div className="m-0.5">
                <ErrorText errorMsg={errors.sourceType && errors.sourceType.message} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between pt-6 pb-4">
        <Button onClick={handleSubmit(handleTest)} disabled={!isDirty || !isValid}>
          Test
        </Button>
        <div className="flex">
          <Button className="mr-4" variant="outline" onClick={() => history.goBack()}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} disabled={!isDirty || !isValid} type="submit">
            Save
          </Button>
        </div>
      </div>
    </ContentContainer>
  );
}

export default SplunkIntegration;
