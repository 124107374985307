import { Box, Typography, Popover } from "@mui/material";
import { ContentContainer, Button, Title, Modal } from "components/simple";
import Loader from "components/simple/Loader";
import React, { useState } from "react";
import styles from "./styles.module.scss";
import CommitIcon from "@mui/icons-material/Commit";
import { DefaultExpandAccordion } from "components/simple/DefaultexpandAccordion";
import DifferJsonView from "components/simple/DifferJsonView";
import { CommentMessage } from "components/simple/CommentMessagePopover/CommentMessagePopover";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PolicyStatus from "components/simple/policyStatus";
import { useLocation } from "react-router-dom";
import {
  callReviewChangePolicyApi,
  useApprovePolicyApi,
  useGetListofPoliciesChanges,
  useGetUsersQuery,
  usePolicyReviewChanges,
  useReviewChangePolicyApi
} from "store/entities/policies/queries";
import { useDispatch } from "react-redux";
import { addNotification } from "store/entities/notifications/actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { StatusButton } from "components/simple/PolicyStatusToggle/PolicyStatusToggle";
import { uniq } from "lodash";
import CircleIcon from "@mui/icons-material/Circle";
import { navigate } from "helper/history";
import { routes } from "router/router";
import moment from "moment";
import { useQueryClient } from "react-query";

const PendingPolicyDetail = () => {
  const dispatch = useDispatch();
  const [showDropDown, setShowDropDown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const selectedPolicy = location?.state?.selectedPolicy;
  const getUsers = useGetUsersQuery();
  const [showRejectPolicyModal, setShowRejectPolicyModal] = useState(false);
  const [showSubmit, setShowSubmit] = useState("");
  const [commitText, setCommitText] = useState("");
  const queryClient = useQueryClient();

  const callApproval = usePolicyReviewChanges(() => {
    dispatch(addNotification({ msg: "Policy Change has been approved", type: "success" }));
    navigate(routes.allPolicies);
  });
  const callReview = useReviewChangePolicyApi(() => {
    dispatch(addNotification({ msg: "Policy has been Reviewed", type: "success" }));
  });
  const callReject = usePolicyReviewChanges(() => {
    dispatch(addNotification({ msg: "Policy has been Rejected", type: "success" }));
  });

  const {
    data: listofPolicyChanges = [],
    isLoading: listofPolicyisLoading
  } = useGetListofPoliciesChanges(data => {
    const userIds = uniq(
      data?.map(versions => {
        const updates = versions?.list_of_updates?.map(d => d?.updated_by);
        return updates;
      })
    ).flat();
  }, selectedPolicy?.policy_id);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const approveChangesCallBack = (finalreview, reviewCommit, pid) => {
    callApproval.mutate({ policyId: pid, finalreview, prBody: reviewCommit });
  };
  const reviewChangesCallBack = (finalreview, reviewCommit, pid) => {
    callReview.mutate({ finalreview, prBody: reviewCommit, policyId: pid });
  };
  const reviewPolicyChangesCallBack = (finalreview, reviewCommit, pid) => {
    callReject.mutate({ policyId: pid, finalreview, prBody: reviewCommit });
  };
  const getUserNameFromId = id => {
    return id === window.__env__.REACT_APP_ACCUKNOX_DISCOVERY_ENGINE_ID
      ? "AccuKnox Discovery Engine"
      : getUsers?.data?.shortId[id] || getUsers?.data?.longId[id] || "Unknown";
  };

  return (
    <>
      <ContentContainer className={`${styles.container} mb-4`}>
        <div className="flex justify-between">
          <Title>History</Title>
          <div className={styles["actionbtn-group"]}>
            {// ["Pending", "", "Inactive", "Active"].includes(
            listofPolicyChanges?.[0]?.list_of_updates?.[0]?.pending_available && (
              // )
              <div onClick={() => setShowDropDown(!showDropDown)}>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Review Changes
                </Button>
                <Popover
                  id="demo-customized-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{
                    "& .MuiPaper-root": {
                      minWidth: 600,
                      padding: "20px"
                    }
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <PolicyStatus
                    updateModelView={handleClose}
                    reviewPolicyChangesCallBack={reviewPolicyChangesCallBack}
                    reviewChangesCallBack={reviewChangesCallBack}
                    approveChangesCallBack={approveChangesCallBack}
                    selectedPolicy={selectedPolicy}
                    setShowRejectPolicyModal={setShowRejectPolicyModal}
                    showSubmit={showSubmit}
                    setShowSubmit={setShowSubmit}
                    commitText={commitText}
                    setCommitText={setCommitText}
                  />
                </Popover>
              </div>
            )}
          </div>
        </div>
      </ContentContainer>{" "}
      <ContentContainer className={styles.container}>
        <div className={styles.header}>
          <div className="flex">
            <Box sx={{ fontWeight: "600", fontSize: "20px", marginRight: "5px" }}>
              {selectedPolicy?.policy_name + "(v" + selectedPolicy?.version + ")"}
            </Box>

            {selectedPolicy?.status === "Active" && (
              <div className="flex m-auto">
                <CircleIcon
                  style={{
                    fill: "#12B76A",
                    fontSize: "14px",
                    paddingRight: "5px",
                    marginTop: "4px"
                  }}
                />{" "}
                Active
              </div>
            )}
            {selectedPolicy?.status === "Inactive" && (
              <div className="flex m-auto">
                <CircleIcon
                  style={{
                    fill: "#B71212",
                    fontSize: "14px",
                    paddingRight: "5px",
                    marginTop: "4px"
                  }}
                />
                Inactive
              </div>
            )}
          </div>
          {/* <div> */}
          {/* {selectedPolicy?.status === "Active" ||
            (selectedPolicy?.status === "Inactive" && (
              <StatusButton status={selectedPolicy?.status}>
                {selectedPolicy?.status}
                {selectedPolicy?.status === "Active" ? <CheckCircleIcon /> : <HighlightOffIcon />}
              </StatusButton>
            ))} */}
          {/* </div> */}
        </div>
        <div>
          {listofPolicyisLoading || getUsers?.isLoading ? (
            <div className="flex justify-center items-center" style={{ height: "80vh" }}>
              <Loader />
            </div>
          ) : (
            <div>
              {listofPolicyChanges?.map((d, i) => (
                <div className={styles.outer} key={i}>
                  <div className={styles.container}>
                    <div className={styles.icon}>
                      <CommitIcon />
                    </div>
                    <div className={styles.innercontainer}>
                      <Typography
                        variant="h6"
                        sx={{ lineHeight: 1.2, marginBottom: "16px", fontSize: "16px" }}
                      >{`Version ${d?.version}`}</Typography>
                      {d?.list_of_updates?.length
                        ? d?.list_of_updates?.map((val, index) => (
                            <Box sx={{ alignItems: "center" }} key={index}>
                              <div className={styles.linehorizontal}></div>
                              <DefaultExpandAccordion
                                Accordionkey={val?.policy_version_id}
                                Summary={
                                  <div className="flex justify-between w-full">
                                    <Box>
                                      <Typography version="h6" sx={{ paddingLeft: "10px" }}>{`${
                                        d?.version !== 1 ? "Updated" : "Created"
                                      } by ${getUserNameFromId(val?.updated_by)} at ${moment(
                                        val?.updated_at
                                      ).format("DD-MM-YYYY hh:mm:ss a")}`}</Typography>{" "}
                                      <Box>
                                        <Typography version="h6" sx={{ paddingLeft: "10px" }}>
                                          {val?.commit_msg}
                                        </Typography>
                                      </Box>
                                    </Box>

                                    <Box>
                                      {val?.approved_by && (
                                        <Box className="flex text-sm font-medium	">
                                          <CheckCircleIcon
                                            className="text-base mr-1"
                                            color="success"
                                          />
                                          <Box>Approved</Box>
                                        </Box>
                                      )}
                                      {val?.rejected_by && (
                                        <Box className="flex text-sm font-medium	">
                                          <CancelIcon className="text-base mr-1" color="error" />
                                          <Box>Rejected</Box>
                                        </Box>
                                      )}
                                      {val?.status === "Pending" && (
                                        <Box className="flex text-sm font-medium	">
                                          <ErrorIcon className="text-base mr-1" color="warning" />
                                          <Box>Pending</Box>
                                        </Box>
                                      )}
                                    </Box>
                                  </div>
                                }
                                Details={
                                  <DifferJsonView
                                    policyId={selectedPolicy?.policy_id}
                                    policyVersionId={val?.policy_version_id}
                                    isNeedUserInfo={val?.approved_msg || val?.reject_msg}
                                    userDetail={
                                      <CommentMessage
                                        ownerName={getUserNameFromId(val?.updated_by)}
                                        date={val?.updated_at || " "}
                                        message={val?.approved_msg || val?.reject_msg}
                                        policyDetails={val}
                                      />
                                    }
                                  />
                                }
                                summaryBorder="1px solid #DBDFF0"
                                summaryRadius="7px"
                                accBottom="14px"
                                iconSize="1rem"
                              />
                            </Box>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Modal isOpen={showRejectPolicyModal} toggle={() => setShowRejectPolicyModal(false)}>
            <Box className="flex">
              <Box>
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
                  <path
                    d="M27.9998 24.0002V28.0002M27.9998 32.0002H28.0098M26.2898 18.8602L17.8198 33.0002C17.6451 33.3026 17.5527 33.6455 17.5518 33.9947C17.5508 34.3439 17.6413 34.6873 17.8142 34.9907C17.9871 35.2941 18.2365 35.547 18.5375 35.7241C18.8385 35.9012 19.1806 35.9964 19.5298 36.0002H36.4698C36.819 35.9964 37.1611 35.9012 37.4621 35.7241C37.7631 35.547 38.0124 35.2941 38.1854 34.9907C38.3583 34.6873 38.4488 34.3439 38.4478 33.9947C38.4468 33.6455 38.3544 33.3026 38.1798 33.0002L29.7098 18.8602C29.5315 18.5663 29.2805 18.3233 28.981 18.1547C28.6814 17.9861 28.3435 17.8975 27.9998 17.8975C27.656 17.8975 27.3181 17.9861 27.0186 18.1547C26.7191 18.3233 26.468 18.5663 26.2898 18.8602Z"
                    stroke="#DC6803"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="4"
                    y="4"
                    width="48"
                    height="48"
                    rx="24"
                    stroke="#FFFAEB"
                    stroke-width="8"
                  />
                </svg>
              </Box>

              <Box className="mt-3">
                <Title>Reject this Policy</Title>
                {selectedPolicy?.version === 0 ? (
                  <p>
                    Rejecting this policy will permanently delete this policy, As this is the very
                    first version of this policy.
                  </p>
                ) : (
                  <p>Rejecting these changes will not result in the policy being updated.</p>
                )}
                <p>Are you sure that you want to reject this policy changes?</p>
                <div className="flex justify-between">
                  <Button
                    onClick={() => {
                      setShowRejectPolicyModal(false);
                      setShowSubmit("");
                      setCommitText("");
                    }}
                    variant="outline"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      reviewPolicyChangesCallBack(showSubmit, commitText, selectedPolicy?.id);
                      setShowSubmit("");
                      setCommitText("");
                      setShowRejectPolicyModal(false);
                      selectedPolicy?.version === 0 && navigate(routes.allPolicies);
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </Box>
            </Box>
          </Modal>
        </div>
      </ContentContainer>
    </>
  );
};

export default PendingPolicyDetail;
