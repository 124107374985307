import { Button as Btn, Menu, MenuItem, styled } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";

export const StatusButton = styled(Btn)(({ status }) => {
  const buttonBg = () => {
    if (status === "Pending") {
      return "#FDC77C";
    }
    if (status === "Active") {
      return "#15AC6E";
    }
    return "#F55353";
  };
  return {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 12px",
    height: "30px",
    width: "96px",
    border: "none",
    background: buttonBg(),
    borderRadius: "4px",
    color: status === "Pending" ? "#272727" : "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
    "&:hover": {
      background: buttonBg()
    },
    "& svg": {
      width: "16px"
    }
  };
});

const StyledMenu = styled(props => <Menu {...props} />)(() => ({
  "& .MuiPaper-root": {
    width: "96px"
  },
  "& .MuiList-root": {
    padding: 0,
    fontSize: "14px"
  },
  "& .MuiMenuItem-root": {
    fontSize: "14px"
  }
}));

const PolicyStatusToggle = ({ status = "Pending", onChange, onClick }) => {
  const [buttonRef, setButtonRef] = useState(null);
  const open = Boolean(buttonRef);
  const POLICY_STATUSES = ["Active", "Inactive"];

  const handleStatusButtonClick = e => {
    e.stopPropagation();
    if (status === "Pending") return;
    setButtonRef(e.currentTarget);
    onChange();
  };

  const handleMenuClose = () => {
    setButtonRef(null);
  };

  const handleMenuItemClick = () => {
    setButtonRef(null);
    onClick();
  };

  return (
    <div>
      <StatusButton
        onClick={handleStatusButtonClick}
        disabled={status === "Pending"}
        status={status}
      >
        {status} {status !== "Pending" ? <KeyboardArrowDownIcon /> : null}
      </StatusButton>
      <StyledMenu open={open} onClose={handleMenuClose} anchorEl={buttonRef}>
        {POLICY_STATUSES.filter(option => option !== status)?.map((option, optionIndex) => (
          <MenuItem onClick={e => handleMenuItemClick(e, option)} key={optionIndex}>
            {option}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

PolicyStatusToggle.propTypes = {
  status: PropTypes.oneOf(["Pending", "Active", "Inactive"]),
  onChange: PropTypes.func,
  onClick: PropTypes.func
};

PolicyStatusToggle.defaultProps = {
  status: "Pending",
  onChange: () => {},
  onClick: () => {}
};

export default PolicyStatusToggle;
