import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

export const setDataListsParams = createAction(constant.SET_PARAMS_DATALISTS);
export const updDataListsParams = createAction(constant.UPD_PARAMS_DATALISTS);

// Change groupby datatype
export const changeGroupByDataType = createAction(constant.CHANGE_GROUP_BY_DATA_TYPE);

// Set panel state
export const setPanelState = createAction(constant.SET_PANEL_STATE);

// Get DataLists
export const getDataLists = createAction(constant.GET_DATALISTS);
export const getDataListsSuccess = createAction(constant.GET_DATALISTS_SUCCESS);
export const getDataListsFailed = createAction(constant.GET_DATALISTS_FAILED);

// Get DataLists
export const getDataListsDataType = createAction(constant.GET_DATALISTS_DATATYPE);
export const getDataListsDataTypeSuccess = createAction(constant.GET_DATALISTS_DATATYPE_SUCCESS);
export const getDataListsDataTypeFailed = createAction(constant.GET_DATALISTS_DATATYPE_FAILED);

// GET datalist configs
export const getDataListConfigs = createAction(constant.GET_DATALIST_CONFIGS);
export const getDataListConfigsSuccess = createAction(constant.GET_DATALIST_CONFIGS_SUCCESS);
export const getDataListConfigsFailed = createAction(constant.GET_DATALIST_CONFIGS_FAILED);

export const getDataListConfigsFiltersData = createAction(
  constant.GET_DATALIST_CONFIGS_FILTERS_DATA
);
export const getDataListConfigsFiltersDataSuccess = createAction(
  constant.GET_DATALIST_CONFIGS_FILTERS_DATA_SUCCESS
);
export const getDataListConfigsFiltersDataFailed = createAction(
  constant.GET_DATALIST_CONFIGS_FILTERS_DATA_FAILED
);

// EDIT datalist configs
export const editDataListConfigs = createAction(constant.EDIT_DATALIST_CONFIGS);
export const editDataListConfigsSuccess = createAction(constant.EDIT_DATALIST_CONFIGS_SUCCESS);
export const editDataListConfigsFailed = createAction(constant.EDIT_DATALIST_CONFIGS_FAILED);

// POST datalist configs
export const postDataListConfigs = createAction(constant.POST_DATALIST_CONFIGS);
export const postDataListConfigsSuccess = createAction(constant.POST_DATALIST_CONFIGS_SUCCESS);
export const postDataListConfigsFailed = createAction(constant.POST_DATALIST_CONFIGS_FAILED);

// delete datalist configs
export const deleteDataListConfigs = createAction(constant.DELETE_DATALIST_CONFIGS);
export const deletetDataListConfigsSuccess = createAction(constant.DELETE_DATALIST_CONFIGS_SUCCESS);
export const deleteDataListConfigsFailed = createAction(constant.DELETE_DATALIST_CONFIGS_FAILED);

// MODAL ACTIONS
export const actionTargetModal = createAction(constant.TARGET_MODAL);
export const actionGroupModal = createAction(constant.GROUP_MODAL);

// ACTIONS

// GET GROUP
export const getGroups = createAction(constant.GET_TO_GROUP);
export const getGroupsSuccess = createAction(constant.GET_TO_GROUP_SUCCESS);
export const getGroupsFailed = createAction(constant.GET_TO_GROUP_FAILED);

// ADD GROUP
export const addGroup = createAction(constant.ADD_TO_GROUP);
export const addGroupSuccess = createAction(constant.ADD_TO_GROUP_SUCCESS);
export const addGroupFailed = createAction(constant.ADD_TO_GROUP_FAILED);

// GET TARGET
export const getTargets = createAction(constant.GET_TO_TARGET);
export const getTargetsSuccess = createAction(constant.GET_TO_TARGET_SUCCESS);
export const getTargetsFailed = createAction(constant.GET_TO_TARGET_FAILED);

// GET TARGET TYPES
export const getTargetsTypes = createAction(constant.GET_TO_TARGET_TYPES);
export const getTargetsTypesSuccess = createAction(constant.GET_TO_TARGET__TYPES_SUCCESS);
export const getTargetsTypesFailed = createAction(constant.GET_TO_TARGET__TYPES_FAILED);

// ADD TARGET
export const addTarget = createAction(constant.ADD_TO_TARGET);
export const addTargetSuccess = createAction(constant.ADD_TO_TARGET_SUCCESS);
export const addTargetFailed = createAction(constant.ADD_TO_TARGET_FAILED);

// EXPORT
export const exportDataList = createAction(constant.EXPORT_DATALIST);
export const exportDataListSuccess = createAction(constant.EXPORT_DATALIST_SUCCESS);
export const exportDataListFailed = createAction(constant.EXPORT_DATALIST_FAILED);
