export const styledTable = {
  height: 710,
  width: 1,
  paddingTop: 0,
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "none !important",
    borderTop: "none !important"
  },
  "& .MuiDataGrid-pinnedColumnHeaders": {
    boxShadow: "none !important"
  },
  "& .MuiDataGrid-pinnedColumns": {
    boxShadow: "none !important",
    "& .super-app-theme--row": {
      borderTopLeftRadius: "10px !important",
      borderBottomLeftRadius: "10px !important"
    }
  },
  "& .super-app-theme--header": {
    backgroundColor: "#fff"
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .super-app-theme--row": {
      borderBottom: "none !important",
      borderBottomRightRadius: "10px",
      borderTopRightRadius: "10px"
    }
  },
  "& .super-app-theme--row": {
    backgroundColor: "#fff",
    border: "1px solid #E7E6F8",
    boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
    marginBottom: "5px",
    "&:last-child": {
      marginBottom: 0
    }
  }
};
