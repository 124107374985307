import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  tagsParams: {
    page: 0,
    pageSize: 20,
    search: ""
  },
  tags: {},
  tag: {},
  tagsMini: {}
};

const reducer = createReducer(
  initialState,
  {
    [actions.setTagsParams.type]: (state, action) => ({
      ...state,
      tagsParams: {
        ...state.tagsParams,
        ...action.payload
      }
    }),
    [actions.getTagsSuccess.type]: (state, action) => ({
      ...state,
      tags: action.payload
    }),
    [actions.getTagSuccess.type]: (state, action) => ({
      ...state,
      tag: action.payload
    }),
    [actions.getTagsMiniSuccess.type]: (state, action) => ({
      ...state,
      tagsMini: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
