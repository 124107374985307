import { Box, Chip, Typography } from "@mui/material";
import { ErrorText, Modal, Select, Tabs, Title, Button, Input } from "components/simple";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getChannelID } from "screens/MonitorLogs/helper";
import { getSelectedComponentType, setSelectedComponentType } from "store/entities/filters/slice";
import styles from "../../styles.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import { useForm, Controller } from "react-hook-form";
import { useEditTriggers, useGetAllFilters } from "store/entities/datapipeline/queries";
import { useQueryClient } from "react-query";
import useChannelList from "screens/ChannelIntegration/pages/hooks/listChannel";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useChannelIntegrationState } from "store/entities/channels/slice";
import { addNotification } from "store/entities/notifications/actions";
import { getChannelIdToName } from "../../helper";

function EditTrigger({
  data,
  selectedFilters,
  showEditTrigger,
  setShowEditTrigger,
  updateLocalDSL
}) {
  const [trigger_name, setTriggerName] = useState("");
  const [frequency, setFrequencyName] = useState("");
  const [filterQuery, setFilterQueryName] = useState("");
  const [channel_id, setChannelName] = useState("");
  const [expFilterArr, setExpFilterArr] = useState([]);
  const [expFilterArr2, setExpFilterArr2] = useState([]);
  const [priority, setPriority] = useState("");
  const [cacheData, setCacheData] = useState("");
  const pathName = window.location.pathname;
  const [priorityOptions] = useState(["High", "Medium", "Low"]);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const selectedComponentType = useSelector(getSelectedComponentType);
  const { isFetching } = useChannelList();
  const channelData = useChannelIntegrationState("channelData");
  const updatedChannellist = _.uniqBy(channelData?.dataList, obj => obj["ChannelID"]);
  const handleFilter = (value, filterName) => {
    if (filterName === "component") {
      dispatch(setSelectedComponentType(value));
    }
  };
  useEffect(() => {
    if (trigger_name || frequency || channel_id || priority) {
      setValue("triggerName", trigger_name);
      setValue("frequencyName", frequency);
      setValue("channelName", channel_id);
      setValue("priority", priority);
    }
  }, [trigger_name, frequency, channel_id, priority]);
  const { data: preDefinedData } = useGetAllFilters(
    data => {
      return data;
    },
    "PREDEFINED",
    selectedComponentType?.label
  );
  const queryClient = useQueryClient();
  const closeableTag = expFilterArr.map(e => e.text);
  const closedTag = closeableTag.toString();
  const filterFromLogs = selectedFilters?.map(e => e?.text);
  const selectedFilterFromLogs = filterFromLogs?.toString();
  useEffect(() => {
    setTriggerName(data?.TriggerName);
    setFrequencyName({ label: data?.Frequency, value: data?.Frequency });
    if (data?.ChannelID === 1) {
      setChannelName({ label: "Slack", value: "Slack" });
    } else if (data?.ChannelID === 2) {
      setChannelName({ label: "Splunk", value: "Splunk" });
    } else if (data?.ChannelID === 3) {
      setChannelName({ label: "Cloudwatch", value: "Cloudwatch" });
    } else if (data?.ChannelID === 4) {
      setChannelName({ label: "Elastic", value: "Elastic" });
    } else if (data?.ChannelID === 5) {
      setChannelName({ label: "Rsyslog", value: "Rsyslog" });
    } else if (data?.ChannelID === 6) {
      setChannelName({ label: "Jira", value: "Jira" });
    }

    // setChannelName({ label: data?.CHANNEL_NAME, value: data?.CHANNEL_NAME });
    setFilterQueryName(data?.FilterQuery);
    setPriority({ label: data?.Priority, value: data?.Priority });
    const filterArr = [];
    const splitArr = data?.FilterQuery?.split(",");
    splitArr?.map(val => {
      if (val != "") {
        filterArr.push({ text: val });
      }
    });
    if (selectedFilters?.length >= 1) {
      setExpFilterArr(selectedFilters);
    } else {
      setExpFilterArr(filterArr);
    }
    setCacheData(data);
  }, [data, updateLocalDSL]);
  const isNotUndefined = val => typeof val !== "undefined";
  let shouldUpdate =
    (isNotUndefined(trigger_name) ||
      isNotUndefined(frequency) ||
      isNotUndefined(priority) ||
      isNotUndefined(closedTag) ||
      isNotUndefined(channel_id)) &&
    (trigger_name !== cacheData?.TriggerName ||
      frequency !== cacheData?.Frequency ||
      priority !== cacheData?.Priority ||
      channel_id !== cacheData?.CHANNEL_ID ||
      closedTag !== cacheData?.FilterQuery);
  useEffect(() => {
    shouldUpdate = trigger_name !== cacheData?.TriggerName || frequency !== cacheData?.Frequency;
    priority !== cacheData?.Priority ||
      channel_id !== cacheData?.CHANNEL_ID ||
      closedTag !== cacheData?.FilterQuery;
  }, [trigger_name, frequency, priority, channel_id, closedTag]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm();
  const getEditTrigger = useEditTriggers(data => {
    setShowEditTrigger();
    setExpFilterArr2([]);
    dispatch(addNotification({ msg: "Trigger Updated!", type: "success" }));
    queryClient.invalidateQueries("fetchTrigersData");
    pathName == "/monitors-logging/logs" ? history?.push("/monitors-logging/triggers") : null;
  });
  const onSubmit = () => {
    let temp = expFilterArr2.map(e => e.text);
    let temp2 = temp.toString();
    let filter_query = "";
    if (temp2.length > 0) {
      filter_query = [].concat(closedTag, temp2);
    } else if (pathName == "/monitors-logging/triggers") {
      filter_query = closedTag;
    } else {
      filter_query = selectedFilterFromLogs;
    }
    let channelID = getChannelID(channel_id?.label) ? getChannelID(channel_id?.label) : channel_id;
    const selectedComponentsFilter = selectedComponentType?.label;
    const filterid = data?.FilterID;
    const trigger_id = data?.ID;
    const frequency_data = frequency?.label;
    const priority_data = priority?.label;
    getEditTrigger.mutate({
      trigger_name,
      channelID,
      filter_query,
      selectedComponentsFilter,
      filterid,
      trigger_id,
      filter_query,
      frequency_data,
      priority_data
    });
    reset();
  };
  const [frequencyOptions] = useState([
    "Runtime as it happens",
    "Once a Day",
    "Once a Week",
    "Once a Month"
  ]);
  const closeTag = (e, index) => {
    e.stopPropagation();
    const newData = expFilterArr?.filter((item, idx) => idx !== index);
    setExpFilterArr(newData);
  };
  let history = useHistory();
  const handlepredefinedfilter = e => {
    const filterArr = [];
    const splitArr = e.text.split(",");
    splitArr?.map(val => {
      if (val != "") {
        filterArr.push({ text: val });
      }
    });
    setExpFilterArr2(filterArr);
  };
  const handleTabChange = (event, newValue) => {
    event.stopPropagation();
    setActiveTab(newValue);
  };
  const tabsList = [
    {
      id: "1",
      label: "Search filter data",
      content: (
        <>
          <div className="flex mt-3">
            <div className="flex flex-col w-full relative">
              <Typography variant="body2" color="#384044">
                Selected Filter
              </Typography>
              <div className={styles.filterBox}>
                {expFilterArr?.map(text => (
                  <Chip
                    label={text["text"]}
                    color="info"
                    style={{ margin: "0px 2px", height: "27px" }}
                  />
                ))}
              </div>
            </div>
            {pathName == "/monitors-logging/triggers" ? (
              <div>
                <EditIcon
                  size={22}
                  className="absolute right-16 mt-9 cursor-pointer"
                  onClick={() =>
                    history.push({
                      pathname: "/monitors-logging/logs",
                      state: { dslFilter: data, referrer: pathName }
                    })
                  }
                  data-tip
                  data-for="editFilter"
                />
              </div>
            ) : null}
          </div>
        </>
      )
    },
    {
      id: "2",
      label: "Predefined queries",
      content: (
        <div>
          <div className="flex gap-5">
            <Select
              value={selectedComponentType}
              onChange={v => handleFilter(v, "component")}
              placeholder="Component"
              defaultValue={{ label: "KubeArmor", value: 1 }}
              options={[
                { label: "KubeArmor", value: 1 },
                { label: "Cilium", value: 2 }
              ]}
              containerClass={styles["action-group__filter-option"]}
              hideSelectedOptions={false}
            />
            <div className="w-1/3">
              <Controller
                {...register("componentName", {})}
                control={control}
                defaultValue="Kubearmor"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Select Query"
                    options={preDefinedData?.apiResponse?.map(item => ({
                      value: item?.Filter_id,
                      key: item.Name,
                      label: item.Name,
                      text: item.Filter_query,
                      type: "log"
                    }))}
                    value={value}
                    onChange={value => {
                      onChange(value);
                      handlepredefinedfilter(value);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col mt-3">
            <Typography>Selected Filter</Typography>
            <div className={`${styles.filterBox} h-20`}>
              {expFilterArr2?.map(text => (
                <Chip
                  label={text["text"]}
                  color="info"
                  style={{ margin: "0px 2px", height: "27px" }}
                />
              ))}
            </div>
          </div>
        </div>
      )
    }
  ];

  return (
    <Modal isOpen={showEditTrigger} toggle={() => setShowEditTrigger()}>
      <Title className={styles.title}>Edit trigger</Title>
      <div className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="triggerName"
            {...register("triggerName", {
              required: "Trigger name cannot be empty"
            })}
            defaultValue=""
            control={control}
            render={({ field: { onChange }, value, ref }) => (
              <Input
                name="triggerName"
                label="Name"
                placeholder="Enter a Name"
                required
                value={trigger_name}
                onChange={evt => {
                  onChange(evt.target.value);
                  setTriggerName(evt.target.value);
                }}
              />
            )}
          />
          <ErrorText errorMsg={errors.triggerName && errors.triggerName.message} />
          <div className="mt-3 flex w-8/12">
            <div className="mr-5 w-1/2">
              <Typography variant="body2" color="#384044">
                When to initiate this trigger
              </Typography>
              <Controller
                {...register("frequencyName", {
                  required: "Frequency name cannot be empty"
                })}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Search..."
                    options={frequencyOptions.map(item => ({
                      value: item,
                      label: item
                    }))}
                    value={frequency}
                    onChange={value => {
                      onChange(value);
                      setFrequencyName(value);
                    }}
                  />
                )}
              />
              <ErrorText errorMsg={errors.frequencyName && errors.frequencyName.message} />
            </div>

            <div className="priority-trigger w-1/2">
              <Typography variant="body2" color="#384044">
                Define Threat Level
              </Typography>
              <Controller
                {...register("priority", {
                  required: "Priority cannot be empty"
                })}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder="Search..."
                    options={priorityOptions.map(item => ({
                      value: item,
                      label: item
                    }))}
                    value={priority}
                    onChange={value => {
                      onChange(value);
                      setPriority(value);
                    }}
                  />
                )}
              />
              <ErrorText errorMsg={errors.priority && errors.priority.message} />
            </div>
          </div>
          <Tabs tabs={tabsList} value={activeTab} handleChange={handleTabChange} />
          <div className="mt-3 w-1/3 flex flex-col">
            <Typography variant="body2" color="#384044">
              Notification channel
            </Typography>
            <Controller
              {...register("channelName", {
                required: "Channel name cannot be empty"
              })}
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Select
                  className="cursor-pointer"
                  placeholder="Search..."
                  options={updatedChannellist.map(item => ({
                    value: item?.ChannelID,
                    label: getChannelIdToName(item?.ChannelID)
                  }))}
                  value={channel_id}
                  onChange={value => {
                    onChange(value);
                    setChannelName(value);
                  }}
                />
              )}
            />
            <ErrorText errorMsg={errors.channelName && errors.channelName.message} />
          </div>
          <div className="footer w-full flex justify-end mt-3">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              className={styles.btn}
              disabled={!shouldUpdate}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default EditTrigger;
