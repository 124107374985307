import React, { useState, useEffect } from "react";

import { Title, Modal } from "components/simple";
import { Table } from "components/complex";
import { detailsHistoryColumns } from "../../../../tableColumns";

import { getMonitorHistoryDatalist } from "store/entities/monitors/service";

import styles from "./styles.module.scss";

const DetailsHistoryModal = ({ open, data, close }) => {
  const [state, setState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const request = () => {
    setIsLoading(true);
    getMonitorHistoryDatalist({ id: data?.id })
      .then(r => r.json())
      .then(res => {
        const result = res.map((item, index) => {
          return {
            id: index,
            ...item
          };
        });
        setState(result);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      request();
    }
    return () => setState([]);
  }, [open]);

  const customStyles = {
    height: 510,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      borderRadius: "10px",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important"
      }
    }
  };

  const tableConfig = {
    checkboxVisible: false,
    paginationVisible: false,
    hideFooter: false,
    sortingMode: false
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>{data.title}</Title>
      <div className={styles.content}>
        <Table
          data={isLoading ? [] : state}
          columns={detailsHistoryColumns}
          loading={isLoading}
          customStyles={customStyles}
          {...tableConfig}
        />
      </div>
    </Modal>
  );
};

export default DetailsHistoryModal;
