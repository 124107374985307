import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Button, ContentContainer, Modal, RefreshInterval, Search, Title } from "components/simple";
import OutlinedCard from "components/simple/Card/Card";
import styles from "./styles.module.scss";
// import { useGetOnboardedClusters } from "store/entities/onboarding/queries";
import { useGetOnboardedClusters } from "store/entities/onboarding/queries";
import { useDeboardClusters } from "store/entities/onboarding/mutations";
import AccuknoxCluster from "components/simple/Icon/AccuknoxCluster";
import { navigate } from "helper/history";
import { routes } from "router";
import PropTypes from "prop-types";
import { RefreshTime } from "../../../Inventory/ClusterManagement/constants";
// import {useDeboardClusters} from "store/entities/onboarding/mutations"

const ManageOnboardClusters = ({ history }) => {
  const [selectedData, setSelectedData] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchedCluster, setSearchedCluster] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState();

  const deboardingCluster = useDeboardClusters();

  const { data: clusterData = [], refetch: refetchOnboardedClusters } = useGetOnboardedClusters(
    inputValue
  );

  const deleteHandler = data => {
    const deboardClusterPayload = {
      cluster_id: data
    };

    deboardingCluster.mutate(deboardClusterPayload, {
      onSuccess: () => {
        refetchOnboardedClusters();
        setSelectedData(0);
      }
    });

    setIsModalOpen(false);
  };

  const selectedClusterFilter = () => {
    const selectedClusterResult = clusterData?.filter(val => val.ID === selectedData);

    return setSelectedCluster(selectedClusterResult);
  };

  useEffect(() => {
    selectedClusterFilter();
  }, [selectedData]);

  return (
    <ContentContainer className={styles.container}>
      <Container>
        <div className={styles.header}>
          <div> </div>
          <Title>List of Onboarded Clusters</Title>
          <div className="flex justify-between items-center gap-[20px]">
            <Button
              className={styles.integrationBtn}
              onClick={() => history?.push(routes.settingsManageClusterOnboard)}
            >
              Onboard Now
            </Button>
          </div>
        </div>
        <ManageOnboardContainer>
          <Search value={inputValue} onSearch={e => setInputValue(e)} />
        </ManageOnboardContainer>
        <ClusterCardContainer>
          {searchedCluster?.length < 1 ? (
            <>
              {clusterData?.map(data => {
                return (
                  <div
                    key={data?.ID}
                    onClick={() => {
                      setSelectedData(data?.ID);
                    }}
                  >
                    <OutlinedCard
                      isSelected={data?.ID === selectedData}
                      icon={<AccuknoxCluster />}
                      sx={{
                        display: "flex",
                        justifyContent: "flexStart",
                        alignItems: "center",
                        padding: "10px",
                        gap: "8px",
                        borderRadius: "15px"
                      }}
                    >
                      <h3>{data?.ClusterName}</h3>
                      <p>{data?.Location}</p>
                    </OutlinedCard>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {searchedCluster?.map(data => {
                return (
                  <div key={data?.ID} onClick={() => setSelectedData(data?.ID)}>
                    <OutlinedCard isSelected={data?.ID === selectedData} icon={<AccuknoxCluster />}>
                      <h3>{data?.ClusterName}</h3>
                      <p>{data?.Location}</p>
                    </OutlinedCard>
                  </div>
                );
              })}
            </>
          )}
        </ClusterCardContainer>
        {isModalOpen && (
          <>
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
              <div className={styles.headingContainer}>
                <Title>Confirmation</Title>
              </div>
              <div className={styles.contentContainer}>
                <p>Are you sure you want to delete this Cluster?</p>
                <div className={styles.btnContainer}>
                  <Button className={styles.cancelBtn} onClick={() => setIsModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      deleteHandler(selectedData);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Modal>
          </>
        )}
        <div className="buttonContainer">
          {selectedData !== 0 && (
            <>
              <Button className="deleteBtn" onClick={() => setIsModalOpen(!isModalOpen)}>
                Delete
              </Button>
            </>
          )}
          <Button
            className="nextBtn"
            onClick={() => navigate(routes.settingsManageClusterScrollSpy, selectedCluster?.[0])}
            disabled={!selectedData}
          >
            Next
          </Button>
        </div>
      </Container>
    </ContentContainer>
  );
};

export default ManageOnboardClusters;

ManageOnboardClusters.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;

    .nextBtn {
      height: 43px;
    }

    .deleteBtn {
      border: 1px solid #d9d9d9;
      background: #fff;
      color: #474748;
      height: 43px;
    }

    .deleteBtn:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

const ManageOnboardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 40px 0 30px 0;
`;

const ClusterCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;
