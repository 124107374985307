import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

import { overview, home } from "router/routes";

const UnauthorizedOnlyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return localStorage.getItem("token") ? (
          // <Redirect
          //   to={{
          //     pathname: overview,
          //     state: { from: props.location }
          //   }}
          // />
          <Redirect
            to={{
              pathname: home,
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

UnauthorizedOnlyRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.any]).isRequired,
  location: PropTypes.shape({})
};

UnauthorizedOnlyRoute.defaultProps = {
  location: {}
};

export default UnauthorizedOnlyRoute;
