import { getAccuknoxWorkspace } from "api/api";
import { customPost } from "api/base";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { addNotification } from "store/entities/notifications/actions";

export const useAllPolicyApi = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  const workspace_id = getAccuknoxWorkspace();
  return useMutation(
    async ({ Cluster, Node, Namespace, Pod, Infra, type }) => {
      let filter = {
        cluster_id: [],
        namespace_id: [],
        kind: [],
        infra: "GKE",
        node_id: [],
        pod_id: []
      };
      if (Infra === "GKE") {
        filter = {
          cluster_id: Cluster || [],
          namespace_id: Namespace,
          kind: type,
          infra: Infra,
          node_id: Node,
          pod_id: Pod,
          status: []
        };
      } else {
        filter = {
          instance_group_id: Cluster,
          instance_id: Namespace,
          kind: type,
          infra: Infra,
          status: []
        };
      }
      // if (label_type === "") {
      //   delete filter?.label_type;
      // }
      const result = await customPost("/policymanagement/api/v1/policy/get-all-policies", {
        filter: filter,
        workspace_id: workspace_id,
        page_previous: 0,
        page_next: 100
      });
      return result.json();
    },
    {
      onSuccess: e => {},
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Approve", type: "error" }));
      }
    }
  );
};

export const useADPolicyApi = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  const workspace_id = getAccuknoxWorkspace();
  return useMutation(
    async ({ Cluster, Node, Namespace, Pod, Infra, type }) => {
      let filter = {
        cluster_id: [],
        namespace_id: [],
        kind: [],
        infra: "GKE",
        node_id: [],
        pod_id: [],
        status: [],
        discover: {
          used: false,
          ignore: false
        }
      };

      const result = await customPost("/policymanagement/api/v1/auto-discover/get-policies", {
        filter: filter,
        workspace_id: workspace_id,
        page_previous: 0,
        page_next: 100
      });
      return result.json();
    },
    {
      onSuccess: e => {
        // console.log(e, "success--------2");
      },
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Approve", type: "error" }));
      }
    }
  );
};
export const useStorePostureMutation = () => {
  const workspace_id = getAccuknoxWorkspace();
  const dispatch = useDispatch();
  return useMutation(
    async ({ namespace_id, process, network }) => {
      const payload = {
        namespace_id,
        workspace_id: workspace_id,
        kubearmor_file_posture: process,
        kubearmor_network_posture: network
      };
      const response = await customPost("/cm/api/v1/cluster-management/store-posture", payload);
      const result = await response.json();

      return result;
    },
    {
      onSuccess: () => {
        dispatch(addNotification({ msg: "Updated Successfully", type: "success" }));
      },
      onError: data => {
        dispatch(addNotification({ msg: data?.error_message, type: "error" }));
      }
    }
  );
};
