import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ActionButton from "../ActionButton";

import styles from "./styles.module.scss";

const InputSearch = ({
  className,
  value,
  label,
  placeholder,
  required,
  disabled,
  invalid,
  onBlur,
  onChange,
  ...inputProps
}) => {
  const [isOpen, setOpen] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const toggler = () => {
    setOpen(!isOpen);
  };

  const handleChange = val => {
    onChange(val);
  };

  const handleBlur = params => {
    // eslint-disable-next-line
    onBlur && onBlur(params);
  };

  return (
    <Box
      component="form"
      sx={{ display: "flex", alignItems: "flex-end" }}
      className={styles.container}
    >
      {label && <InputLabel htmlFor="input-with-icon-adornment">{label}</InputLabel>}
      <Input
        inputRef={inputRef}
        value={value}
        required={required}
        onChange={e => handleChange(e.target.value)}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        className={`${styles.input} ${isOpen ? styles.open : ""}`}
        inputProps={inputProps}
      />
      <ActionButton type="search" onClick={toggler} className={styles.btn} />
    </Box>
  );
};

InputSearch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  invalid: PropTypes.string
};

InputSearch.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  disabled: false,
  required: false,
  onChange: () => {},
  onBlur: () => {},
  className: "",
  invalid: ""
};

export default InputSearch;
