import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { post, get, remove, put } from "api/base";

export const getCloudAccounts = params => {
  const paramsObj = {
    page: params?.page + 1 || 1,
    ordering: params?.ordering ?? "",
    search: params?.search ?? "",
    page_size: params?.pageSize ?? 20
  };
  const searchParams = new URLSearchParams(paramsObj);

  return get(`${API}/clouds?${searchParams.toString()}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getAwsRegions = () => {
  return get(`${API}/regions`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addAzureAccount = data => {
  return post(`${API}/azure-cloud-create`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getAzureAccount = id => {
  return get(`${API}/azure-cloud-create/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addGoogleCloudAccount = data => {
  return post(`${API}/google-cloud-create`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getGCPAccount = id => {
  return get(`${API}/google-cloud-create/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addAwsCloudManual = data => {
  return post(`${API}/aws-cloud-manual-setup`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getAWSManualAccount = id => {
  return get(`${API}/aws-cloud-manual-setup/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addAwsCloudAccessKey = data => {
  return post(`${API}/aws-cloud-access-key`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getAWSAccessAccount = id => {
  return get(`${API}/aws-cloud-access-key/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const changeEnabledStatus = ({ id, status }) => {
  return post(`${API}/clouds/${id}/enabled-status`, JSON.stringify({ status }), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const runAccountScan = id => {
  return post(`${API}/clouds/${id}/run-scan`, null, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const updateAzureAccount = ({ id, data }) => {
  return put(`${API}/azure-cloud-create/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const updateGCPAccount = ({ id, data }) => {
  return put(`${API}/google-cloud-create/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const updateAWSAccess = ({ id, data }) => {
  return put(`${API}/aws-cloud-access-key/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const updateAWSManual = ({ id, data }) => {
  return put(`${API}/aws-cloud-manual-setup/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteCloudAccount = id => {
  return remove(
    `${API}/clouds/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};
