/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { assignAssetsLeftTableColumns, assignAssetsRightTableColumns } from "../../../tableColumns";

import {
  getAssingAssetsLeftTableSelector,
  getAssingAssetsRightTableSelector
} from "store/entities/monitors/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import {
  addMonitorAsset,
  deleteMonitorAsset,
  setAssignHostsParams,
  getMonitorAssetsMini,
  getMonitor
} from "store/entities/monitors/actions";

import AssignTables from "../AssignedTables";

const AssignedHosts = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [searchVal, setSearchVal] = useState({
    leftTable: "",
    rightTable: ""
  });

  const selectTenant = useSelector(state => state.users.selectTenant);

  const isLoadingGetMonitor = useSelector(state => createLoadingSelector([getMonitor.type])(state));
  const isLoadingGetAssets = useSelector(state =>
    createLoadingSelector([getMonitorAssetsMini.type])(state)
  );
  const isLoadingAddAsset = useSelector(state =>
    createLoadingSelector([addMonitorAsset.type])(state)
  );

  const [monitorAssets, setMonitorAssets] = useState([]);

  const dataLeftTable = useSelector(getAssingAssetsLeftTableSelector);
  const dataRightTable = useSelector(getAssingAssetsRightTableSelector);

  const monitorId = params?.id;

  // get default request
  const getRequest = () => {
    dispatch(
      setAssignHostsParams({
        label: "",
        search: "",
        monitor_id: monitorId
      })
    );
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant]);

  useEffect(() => {
    setMonitorAssets(dataRightTable);
  }, [dataRightTable]);

  // Search
  const submitLeftTableSearch = text => {
    dispatch(
      setAssignHostsParams({
        search: text
      })
    );
  };

  const submitRightTableSearch = text => {
    if (text === "") setMonitorAssets(dataRightTable);
    const newList = dataRightTable.filter(elem =>
      elem.title.toLowerCase().includes(text.toLowerCase())
    );
    setMonitorAssets(newList);
  };

  const handleSearch = (val, name) => {
    setSearchVal({
      ...searchVal,
      [name]: val
    });
  };
  //

  const onCellClick = p => {
    const { field, id: uid } = p;

    if (field === "delete") {
      dispatch(deleteMonitorAsset({ id: monitorId, data: { id: uid } }));
    }
  };

  const addAssetHandler = list => {
    dispatch(
      addMonitorAsset({
        id: monitorId,
        data: {
          ids: [...list]
        }
      })
    );
  };

  return (
    <AssignTables
      columnsLeftTable={assignAssetsLeftTableColumns}
      columnsRightTable={assignAssetsRightTableColumns}
      dataLeftTable={dataLeftTable}
      dataRightTable={monitorAssets}
      isLoadingLeftTable={isLoadingGetAssets}
      isLoadingOnAdd={isLoadingAddAsset}
      isLoadingRightTable={isLoadingGetMonitor}
      titleLeftTable="Assigned hosts"
      onCellClick={onCellClick}
      onSearch={handleSearch}
      onSubmitLeftTableSearch={submitLeftTableSearch}
      onSubmitRightTableSearch={submitRightTableSearch}
      onAdd={addAssetHandler}
      searchVal={searchVal}
    />
  );
};

AssignedHosts.propTypes = {};

export default AssignedHosts;
