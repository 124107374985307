import React, { useEffect, useRef } from "react";
import Panzoom from "@panzoom/panzoom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import LightTooltip from "components/simple/LightTooltip";
import styled from "styled-components";
import { useNetworkPolicyEditorState } from "store/entities/policyEditor/NetworkPolicyEditor/slice";
import { useProcessUploadNetworkPolicyYAML } from "store/entities/policyEditor/NetworkPolicyEditor/hooks";
import { EGRESS, INGRESS } from "store/entities/policyEditor/NetworkPolicyEditor/constants";
import PolicyMetadataCard from "../PolicyMetadataCard";
import RulesPalette from "../RulesPalette";
import IPBlockRuleCard from "../IPBlockRuleCard";
import PodSelectorRuleCard from "../PodSelectorRuleCard";
import NamespaceSelectorRuleCard from "../NamespaceSelectorRuleCard";
import UnsupportedRuleCard from "screens/PolicyEditor/common/components/UnsupportedRuleCard";
import { useLocation } from "react-router-dom";
import { navigate } from "helper/history";
import { routes } from "router";

const EditorCanvas = () => {
  const canvasRef = useRef();
  const mainContentRef = useRef();
  const zoomInref = useRef();
  const zoomOutRef = useRef();
  const resetRef = useRef();
  const location = useLocation();

  const { processYAML: processNetworkPolicyYAML } = useProcessUploadNetworkPolicyYAML();

  const showRulesPaletteCard = useNetworkPolicyEditorState("showRulesPaletteCard");
  const showIngressIPBlockRuleCard = useNetworkPolicyEditorState(
    "ipBlockRule",
    "showRuleCard",
    INGRESS
  );
  const showEgressIPBlockRuleCard = useNetworkPolicyEditorState(
    "ipBlockRule",
    "showRuleCard",
    EGRESS
  );
  const showIngressPodSelectorRuleCard = useNetworkPolicyEditorState(
    "podSelectorRule",
    "showRuleCard",
    INGRESS
  );
  const showEgressPodSelectorRuleCard = useNetworkPolicyEditorState(
    "podSelectorRule",
    "showRuleCard",
    EGRESS
  );
  const showIngressNamespaceSelectorRuleCard = useNetworkPolicyEditorState(
    "namespaceSelectorRule",
    "showRuleCard",
    INGRESS
  );
  const showEgressNamespaceSelectorRuleCard = useNetworkPolicyEditorState(
    "namespaceSelectorRule",
    "showRuleCard",
    EGRESS
  );
  const unsupportedRules = useNetworkPolicyEditorState("unsupportedRules");

  useEffect(() => {
    const panzoom = Panzoom(mainContentRef.current, {
      minScale: 0.5,
      maxScale: 5,
      cursor: "grab",
      canvas: true
    });

    canvasRef.current.addEventListener("wheel", e => {
      if (!e.target.closest(".panzoom-react-select__menu")) {
        panzoom.zoomWithWheel(e, {
          step: 0.1
        });
      }
    });
    zoomInref.current.addEventListener("click", panzoom.zoomIn);
    zoomOutRef.current.addEventListener("click", panzoom.zoomOut);
    resetRef.current.addEventListener("click", panzoom.reset);

    // Removes all PanZoom-related event listeners during unmounting
    return () => panzoom.destroy();
  }, []);

  // Update component state when policy YAML is set by the KubeArmor policy editor tab
  const policyYAMLFromKubeArmorPolicyEditor = location?.state?.policyYAMLFromKubeArmorPolicyEditor;

  useEffect(() => {
    if (policyYAMLFromKubeArmorPolicyEditor) {
      processNetworkPolicyYAML(policyYAMLFromKubeArmorPolicyEditor);
      // Clean up location.state as soon as the policy YAML is processedq
      navigate(routes.policyEditor, {});
    }
  }, [policyYAMLFromKubeArmorPolicyEditor]);

  return (
    <EditorCanvasContainer>
      <div ref={canvasRef} className="h-full min-w-full w-fit">
        <div ref={mainContentRef} className="flex gap-8">
          {showRulesPaletteCard && <RulesPalette />}

          {/* Ingress Rules */}
          <div className="flex flex-col gap-5">
            {showIngressIPBlockRuleCard && <IPBlockRuleCard trafficType={INGRESS} />}
            {showIngressPodSelectorRuleCard && <PodSelectorRuleCard trafficType={INGRESS} />}
            {showIngressNamespaceSelectorRuleCard && (
              <NamespaceSelectorRuleCard trafficType={INGRESS} />
            )}
            {Boolean(unsupportedRules[INGRESS]?.length) &&
              unsupportedRules[INGRESS]?.map?.(rule => (
                <UnsupportedRuleCard key={rule} rule={rule} />
              ))}
          </div>

          <PolicyMetadataCard />

          {/* Egress Rules */}
          <div className="flex flex-col gap-5">
            {showEgressIPBlockRuleCard && <IPBlockRuleCard trafficType={EGRESS} />}
            {showEgressPodSelectorRuleCard && <PodSelectorRuleCard trafficType={EGRESS} />}
            {showEgressNamespaceSelectorRuleCard && (
              <NamespaceSelectorRuleCard trafficType={EGRESS} />
            )}
            {Boolean(unsupportedRules[EGRESS]?.length) &&
              unsupportedRules[EGRESS]?.map?.(rule => (
                <UnsupportedRuleCard key={rule} rule={rule} />
              ))}
          </div>
        </div>
      </div>

      {/* Zoom/Pan Button Groups */}
      <div className="absolute bottom-2 right-2 flex gap-1">
        <div className="bg-white flex gap-3 p-2 rounded-xl">
          <LightTooltip title="Zoom in" placement="top" arrow>
            <span className="cursor-pointer" ref={zoomInref}>
              <AddIcon sx={{ color: "primary.main", fontSize: 20 }} />
            </span>
          </LightTooltip>
          <LightTooltip title="Zoom out" placement="top" arrow>
            <span className="cursor-pointer" ref={zoomOutRef}>
              <RemoveIcon sx={{ color: "primary.main", fontSize: 20 }} />
            </span>
          </LightTooltip>
          <LightTooltip title="Fit to screen" placement="top" arrow>
            <span className="cursor-pointer" ref={resetRef}>
              <FullscreenIcon sx={{ color: "primary.main", fontSize: 20 }} />
            </span>
          </LightTooltip>
        </div>
      </div>
    </EditorCanvasContainer>
  );
};

const EditorCanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-image: radial-gradient(circle at center center, #a0aec0 3%, #f7fafc 3%);
  background-size: 60px 60px;
  border-radius: 15px;
  padding: 8px;
`;

export default EditorCanvas;
