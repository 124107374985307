import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Tabs } from "components/simple";

import { Layers } from "./Tabs/Layers";
import { Overview } from "./Tabs/Overview";
import { Resources } from "./Tabs/Resources";
import { ScanHistory } from "./Tabs/ScanHistory";
import { SensitiveData } from "./Tabs/SensitiveData";
import { Vulnerabilities } from "./Tabs/Vulnerabilities";

export const ImageDetails = () => {
  const location = useLocation();
  const { imageName, assetId } = location?.state;

  const [activeTab, setActiveTab] = useState(0);

  const tabsList = [
    { id: 0, label: "Overview", content: <Overview assetId={assetId} /> },
    { id: 1, label: "Vulnerabilities", content: <Vulnerabilities assetId={assetId} /> },
    { id: 2, label: "Layers", content: <Layers assetId={assetId} /> },
    { id: 3, label: "Resources", content: <Resources assetId={assetId} /> },
    { id: 4, label: "Sensitive Data", content: <SensitiveData assetId={assetId} /> },
    { id: 5, label: "Scan History", content: <ScanHistory assetId={assetId} /> }
  ];

  const handleTabChange = (event, newValue) => {
    event.stopPropagation();
    setActiveTab(newValue);
  };

  return (
    <div className="h-full">
      <div style={{ margin: "8px 0", color: "#0045F5", fontWeight: 500 }}>{imageName}</div>
      <Tabs tabs={tabsList} value={activeTab} handleChange={handleTabChange} />
    </div>
  );
};
