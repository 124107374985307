import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { updateChannelListReducer, updateChannelFilterReducer } from "./reducers";
export const CHANNEL_INTEGRATIONS = "channelIntegrations";
export const CHANNEL_INTEGRATIONS_INITIAL_STATE = {
  channelData: [],
  channelFilter: []
};

const channeIntegrationSlice = createSlice({
  name: CHANNEL_INTEGRATIONS,
  initialState: CHANNEL_INTEGRATIONS_INITIAL_STATE,
  reducers: {
    updateChannelList: updateChannelListReducer,
    updateChannelFilter: updateChannelFilterReducer
  }
});

export const useChannelIntegrationState = key => {
  const value = useSelector(state => state[CHANNEL_INTEGRATIONS][key]);
  return value;
};

export const { updateChannelList, updateChannelFilter } = channeIntegrationSlice.actions;

export default channeIntegrationSlice.reducer;
