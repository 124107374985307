import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useVerifyEmail } from "store/entities/auth/mutations";
import { navigate } from "helper/history";
import { routes } from "router";
import { Loader } from "components/simple";

const VerifyEmail = () => {
  const params = useParams();
  const { mutate: verifyEmail } = useVerifyEmail();

  useEffect(() => {
    if (params?.uid || params?.userToken) {
      verifyEmail(params, { onSuccess: data => {}, onError: data => navigate(routes.loginScreen) });
    } else {
      navigate(routes.loginScreen);
    }
  }, [params]);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default VerifyEmail;
