import { Box } from "@mui/material";
import React from "react";

function CustomTableSeverityComponent({ row }) {
  let severityColor = "";
  function handleSeverityColor() {
    if (["Critical", 1, 2, "1", "2", "CRITICAL"]?.includes(row)) {
      severityColor = "#ac0a0a";
    } else if (["High", 3, 4, "4", "3", "HIGH"]?.includes(row)) {
      severityColor = "#f47d57";
    } else if (["Medium", 5, 6, "5", "6", "MEDIUM"]?.includes(row)) {
      severityColor = "#e6e958";
    } else if (["Low", 7, 8, "7", "8", "LOW"]?.includes(row)) {
      severityColor = "#249d6f";
    } else if (["Informational", 9, 10, "9", "10", "INFORMATIONAL"]?.includes(row)) {
      severityColor = "#118ab2";
    } else {
      severityColor = "#118ab2";
    }
  }
  handleSeverityColor();
  return (
    <>
      <Box sx={{ width: 7, height: 35, background: severityColor, borderRadius: 1, ml: -1 }} />
    </>
  );
}

export default CustomTableSeverityComponent;
