import React from "react";
import PropTypes from "prop-types";

export default function MinusInCircle(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.42004 2 1.92004 6.5 1.92004 12C1.92004 17.5 6.42004 22 11.92 22Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.92004 12H15.92"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

MinusInCircle.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

MinusInCircle.defaultProps = {
  className: "",
  style: {},
  color: "#2B3858",
  width: 24,
  height: 24
};
