import { getAccuknoxWorkspace } from "api/api";
import {
  customGet,
  customPost,
  customPut,
  deleteFormRequest,
  getFormRequest,
  patchFormRequest,
  postFormRequest
} from "api/base";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { addNotification } from "store/entities/notifications/actions";
import { useDispatch } from "react-redux";

export const useGetUsersQuery = status => {
  return useQuery(
    ["getUsers", status],

    async () => {
      const response = await getFormRequest(`/user-profiles?status=${status}`);
      const res = await response.json();
      return res;
    },
    {
      refetchOnWindowFocus: false
    }
  );
};

export const useDeactivateUser = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async uId => {
      const response = await postFormRequest(`/user-profiles/${uId}/deactivate`);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userDeactivateProfile");
        queryClient.invalidateQueries("userProfile");
        queryClient.invalidateQueries("getUsers");
        dispatch(addNotification({ msg: "User deactivated Successfully", type: "success" }));
      }
    },
    {
      onError: () => {
        dispatch(addNotification({ msg: "Failed to deactivate user", type: "error" }));
      }
    }
  );
};
export const useActivateUser = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async uId => {
      const response = await postFormRequest(`/user-profiles/${uId}/activate`);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userDeactivateProfile");
        queryClient.invalidateQueries("userProfile");
        queryClient.invalidateQueries("getUsers");
        dispatch(addNotification({ msg: "User activated Successfully", type: "success" }));
      }
    },
    {
      onError: () => {
        dispatch(addNotification({ msg: "Failed to activate user", type: "error" }));
      }
    }
  );
};
export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async uId => {
      const response = await deleteFormRequest(`/user-profiles/${uId}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUsers");
        dispatch(addNotification({ msg: "User Deleted Successfully.", type: "success" }));
      }
    }
  );
};
export const useResendInvite = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    async uId => {
      const response = await postFormRequest(`/user-profiles/${uId}/resend-invitation`);
      const res = await response.json();
      return res;
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries("userPendingProfile");
        dispatch(addNotification({ msg: "Your Invite is on it's way to user", type: "success" }));
      }
    }
  );
};

export const useUpdateUserfromWs = (onSuccess = () => {}) => {
  return useMutation(
    async ({uId,  updateDetail }) => {
      const response = await patchFormRequest(`/user-profiles/${uId}`, updateDetail);
      const res = await response.json();
      return res;
    },
    {
      onSuccess: () => {
        onSuccess();
      }
    }
  );
};
