import { useMutation } from "react-query";
import { updateVulnerabilityStatus } from "./service";
import { useDispatch } from "react-redux";
import { addNotification } from "../../notifications/actions";
import { useVulnerabilitiesQuery } from "./queries";
import { useGetImageVulnerabilities } from "store/entities/imageDetails/queries";

export const useUpdateVulnerabilityStatusMutation = selectedImageName => {
  const dispatch = useDispatch();
  const { refetch: refetchVulnerabilities } =
    selectedImageName === ""
      ? useVulnerabilitiesQuery()
      : useGetImageVulnerabilities(selectedImageName);

  return useMutation(payload => updateVulnerabilityStatus(payload), {
    onSuccess: () => {
      dispatch(addNotification({ msg: "Vulnerability status updated", type: "success" }));
    },
    onSettled: () => {
      refetchVulnerabilities();
    }
  });
};
