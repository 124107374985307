import React from "react";
import PropTypes from "prop-types";

export default function Monitors(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      className={props.className}
      style={props.style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7619 14.0844C15.3507 16.3567 12.8325 17.8696 9.96094 17.8696C5.54266 17.8696 1.96094 14.2879 1.96094 9.86963C1.96094 5.45135 5.54266 1.86963 9.96094 1.86963C12.6334 1.86963 14.9999 3.18009 16.4527 5.19347C16.5765 5.4262 16.8405 5.5882 17.1235 5.5882C17.5332 5.5882 17.8654 5.25606 17.8654 4.84635C17.8781 4.65133 17.8109 4.48057 17.6954 4.35219C15.9724 1.94121 13.1502 0.369629 9.96094 0.369629C4.71423 0.369629 0.460938 4.62292 0.460938 9.86963C0.460938 15.1163 4.71423 19.3696 9.96094 19.3696C13.386 19.3696 16.3877 17.5571 18.0592 14.8388C18.1043 14.7835 18.1415 14.7217 18.1694 14.6552L18.1723 14.6501L18.1716 14.6497C18.2072 14.5627 18.2268 14.4676 18.2268 14.368C18.2555 13.9324 17.8863 13.6162 17.4664 13.6162C17.1479 13.6162 16.8751 13.8098 16.7619 14.0844ZM13.8448 6.74462C12.9284 5.60155 11.5204 4.86963 9.94141 4.86963C7.17998 4.86963 4.94141 7.1082 4.94141 9.86963C4.94141 12.6311 7.17998 14.8696 9.94141 14.8696C11.7822 14.8696 13.3907 13.8749 14.2585 12.3936L14.2531 12.3904C14.3504 12.2656 14.4083 12.1086 14.4083 11.9381C14.4084 11.5001 14.0785 11.2015 13.6717 11.2015C13.3466 11.2015 13.0346 11.4122 12.9225 11.7045C12.3062 12.7037 11.2016 13.3696 9.94141 13.3696C8.00841 13.3696 6.44141 11.8026 6.44141 9.86963C6.44141 7.93663 8.00841 6.36963 9.94141 6.36963C10.9642 6.36963 11.8846 6.80838 12.5246 7.50795L13.8448 6.74462ZM19.1048 12.3696C19.1048 12.7793 18.7434 13.1115 18.3337 13.1115C17.924 13.1115 17.554 12.7718 17.5919 12.3696C17.5919 11.9599 17.924 11.6278 18.3337 11.6278C18.7434 11.6278 19.1328 11.9398 19.1048 12.3696Z"
        fill={props.color}
      />
      <circle cx="10.0493" cy="9.86966" r="1.04544" fill={props.color} />
      <path
        d="M20.6914 2.69189L10.0472 9.81688"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

Monitors.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Monitors.defaultProps = {
  className: "",
  style: {},
  color: "#fff",
  width: 22,
  height: 20
};
