/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { routes } from "router";

import {
  getAsset,
  setAssetTag,
  setAssetBaseline,
  getAssetDataListTypes,
  getAssetDataListType
} from "store/entities/assets/actions";

import { assetDetailsSelector } from "store/entities/assets/selectors";

import { createLoadingSelector } from "store/entities/loading/selector";

import { Title, ContentContainer, PieChart } from "components/simple";
import Loader from "components/simple/Loader";

import DetailsBox from "./DetailsBox";
import AddTagModal from "./AddTagModal";
import DataListModal from "./DataListModal";

import AddBaselineModal from "./AddBaselineModal";

import styles from "./styles.module.scss";
import Explorer from "./Explorer";
import Findings from "./Findings";

const AssetsDetails = ({ history }) => {
  const dispatch = useDispatch();

  const [openModalTag, setOpenModalTag] = useState(false);
  const [openModalBaseline, setOpenModalBaseline] = useState(false);
  const [openModalDataList, setOpenModalDataList] = useState(false);

  const params = useParams();
  const id = params?.id;

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getAsset.type])(state));
  const isDataListTypeLoading = useSelector(state =>
    createLoadingSelector([getAssetDataListType.type])(state)
  );

  const { vulnerabilities, baselines, details, tags, compliances } = useSelector(
    assetDetailsSelector
  );
  const assetDataListType = useSelector(state => state.assets.assetDataListType);

  useEffect(() => {
    dispatch(getAsset({ id }));
    dispatch(getAssetDataListTypes(id));
  }, [id, selectTenant]);

  const closeModalTag = () => {
    setOpenModalTag(false);
  };

  const closeModalBaseline = () => {
    setOpenModalBaseline(false);
  };

  const onAddTags = res => {
    dispatch(setAssetTag({ id, data: { ids: res?.map(item => item?.value) } }));
    closeModalTag();
  };

  const onAddBaseline = res => {
    dispatch(setAssetBaseline({ id, data: { ids: res?.map(item => item?.value) } }));
    closeModalBaseline();
  };

  const closeModalDataList = () => {
    setOpenModalDataList(false);
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.grid}>
            <DetailsBox data={details} />

            <ContentContainer className={styles.wrapper}>
              <div>
                <Title>Vulnerabilities</Title>
                <br />
                <PieChart
                  data={vulnerabilities}
                  className={styles.chart}
                  onClick={v => {
                    const val = vulnerabilities[v]?.key;
                    const res = { risk_factor: { value: val, label: val } };
                    history.push(
                      `${routes.vulnerabilities}?filters=${JSON.stringify(
                        res
                      )}&asset=${JSON.stringify({ label: details?.name, value: id })}`
                    );
                  }}
                />
              </div>
              <div>
                <Title>Compliance</Title>
                <br />
                <PieChart
                  data={compliances}
                  className={styles.chart}
                  onClick={v => history.push(`${routes.baselines}?filter=${compliances[v]?.key}`)}
                />
              </div>
            </ContentContainer>
          </div>
          <Explorer />
          <Findings />

          <AddTagModal
            open={openModalTag}
            close={closeModalTag}
            onAddFiles={onAddTags}
            data={tags}
          />

          <AddBaselineModal
            open={openModalBaseline}
            close={closeModalBaseline}
            onAddFiles={onAddBaseline}
            data={baselines}
          />

          <DataListModal
            open={openModalDataList}
            close={closeModalDataList}
            data={assetDataListType}
            isLoading={isDataListTypeLoading}
          />
        </>
      )}
    </div>
  );
};

AssetsDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default AssetsDetails;
