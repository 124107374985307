import React from "react";

import { Modal, Title, Button } from "components/simple";
import { AssetSelect } from "helper/AsyncSelects";

import styles from "./styles.module.scss";

function AddAssetModal({ onChange, state, onCloseModal, isLoading, onSubmit, isModalOpen }) {
  return (
    <Modal isOpen={isModalOpen} toggle={onCloseModal}>
      <Title className={styles.title}>Assign Asset</Title>
      <form onSubmit={onSubmit}>
        <AssetSelect
          containerClass={styles.select}
          onChange={val => onChange(val)}
          label="Assets"
          value={state}
          isClearable
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          cacheOptions
          required
        />
        <div className={styles.btnsRow}>
          <Button variant="outline" onClick={onCloseModal} className={styles.btn}>
            Cancel
          </Button>
          <Button type="submit" className={styles.btn} isLoading={isLoading}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default AddAssetModal;
