/* eslint-disable camelcase */
import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { goBack } from "helper/history";
import styles from "./styles.module.scss";
import { editUserProfiles, addUserProfiles } from "store/entities/settings/actions";
import { ContentContainer, Title, Button, Input, Tags, Checkbox, Select } from "components/simple";

const UserProfilesDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const userDetails = location?.state?.userDetails;
  const roleOptions = location?.state?.roleOptions;
  const labelOptions = location?.state?.labelOptions;
  const groupOptions = location?.state?.groupOptions;

  // const permissions = useSelector(permissionsSelect);

  const [state, setState] = useState({
    email: userDetails?.email || "",
    role: userDetails?.is_tenant_admin
      ? { label: "Admin", value: roleOptions?.filter(role => role.label === "Admin")?.[0]?.value }
      : {
          label: userDetails?.role,
          value: roleOptions?.filter(role => role.label === userDetails?.role)?.[0]?.value
        } || {},
    first_name: userDetails?.first_name || "",
    last_name: userDetails?.last_name || "",
    labels:
      userDetails?.labels.map(item => {
        return {
          label: labelOptions?.filter(label => label?.value === item)?.[0]?.label,
          value: item
        };
      }) || [],
    groups:
      userDetails?.groups.map(item => {
        return {
          label: groupOptions?.filter(group => group?.value === item)?.[0]?.label,
          value: item
        };
      }) || [],
    mfa: false
  });

  const onSave = e => {
    e.preventDefault();
    const res = {
      user: {
        email: state?.email,
        first_name: state?.first_name,
        last_name: state?.last_name
      },
      role_id: state?.role?.value,
      label_ids: state?.labels?.map(item => item?.value),
      group_ids: state?.groups?.map(item => item?.value),
      mfa: state?.mfa
    };
    if (userDetails) {
      dispatch(editUserProfiles({ id: userDetails?.id, data: { ...res } }));
      return;
    }
    dispatch(addUserProfiles(res));
  };

  const inputChangeHandler = (val, key) => {
    setState({ ...state, [key]: val });
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>User profile details</Title>
      <form className={styles.form} onSubmit={onSave}>
        <div className={styles.content}>
          <Input
            value={state.email}
            containerClassName={styles.inputContainer}
            label="Email"
            type="email"
            required
            autoComplete="off"
            onChange={val => inputChangeHandler(val.target.value, "email")}
          />

          <Input
            value={state.first_name}
            containerClassName={styles.inputContainer}
            label="First name"
            type="text"
            required
            onChange={val => inputChangeHandler(val.target.value, "first_name")}
          />

          <Input
            value={state.last_name}
            containerClassName={styles.inputContainer}
            label="Last name"
            type="text"
            required
            onChange={val => inputChangeHandler(val.target.value, "last_name")}
          />

          <Select
            containerClass={styles.select}
            onChange={v => inputChangeHandler(v, "role")}
            value={state.role}
            placeholder="Role"
            label="Role"
            options={roleOptions}
            isClearable
            required
            hideSelectedOptions={true}
          />

          {/* <Select
            containerClass={styles.select}
            onChange={v => inputChangeHandler(v, "permissions")}
            value={state.permissions}
            placeholder="Permissions"
            label="Permissions"
            options={permissions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable={false}
          /> */}

          <Tags
            values={state.labels}
            list={labelOptions}
            onChange={val => inputChangeHandler(val, "labels")}
            label="Labels"
          />
          <Tags
            values={state.groups}
            list={groupOptions}
            onChange={val => inputChangeHandler(val, "groups")}
            label="Groups"
          />
        </div>
        <div>
          <Checkbox
            value={state.mfa}
            onChange={val => inputChangeHandler(val, "mfa")}
            label="MFA"
            className={styles.checkbox}
            disabled={!state.mfa}
            checked
            sx={{ opacity: 0.5 }}
          />
        </div>

        <div className={styles.buttonGroup}>
          <Button type="submit" className={styles.button}>
            Save
          </Button>
          <Button onClick={() => goBack()} variant="outline" className={styles.button}>
            Cancel
          </Button>
        </div>
      </form>
    </ContentContainer>
  );
};

UserProfilesDetails.propTypes = {};

export default UserProfilesDetails;
