import React from "react";
import NoTableData from "components/simple/Icon/NoTableData";

export default function NoTableRowData({ style, showSVGImage = true, children }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        ...style
      }}
    >
     {showSVGImage ? <NoTableData width={`40`} height={`40`} /> : children}
     <p style={{ fontSize: "14px", fontWeight: "400" }}>No data available!</p>
    </div>
  );
};