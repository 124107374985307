import React from "react";

export const Cilium = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="1.22 0.98 64 64" width="64" height="64">
        <path
          d="M39.077 22.837h-11.79l-5.92 10.246 5.895 10.143h11.817l5.895-10.143zm-1.57 17.61H28.83l-4.325-7.39 4.325-7.466h8.676l4.325 7.466z"
          fill="#373737"
        />
        <path d="M41.857 33.083l-4.35-7.57H28.83l-4.325 7.57 4.325 7.363h8.676z" fill="#e82429" />
        <path
          d="M39.077 44.616h-11.79l-5.92 10.22L27.26 64.98h11.817l5.895-10.143zm-1.57 17.686H28.83l-4.325-7.39 4.325-7.466h8.676l4.325 7.466z"
          fill="#373737"
        />
        <path d="M41.857 54.837l-4.35-7.466H28.83l-4.325 7.466 4.325 7.466h8.676z" fill="#6289c6" />
        <path
          d="M39.077.98h-11.79l-5.92 10.246 5.92 10.143h11.817l5.895-10.143zm-1.57 17.61H28.83l-4.325-7.39 4.325-7.543h8.676l4.325 7.466z"
          fill="#373737"
        />
        <path d="M41.857 11.226l-4.35-7.57H28.83l-4.325 7.57 4.325 7.363h8.676z" fill="#f9c515" />
        <path
          d="M58.256 33.804H46.543L40.647 44.05l5.895 10.143H58.36l5.895-10.143zm-1.57 17.635H48l-4.325-7.39L48 36.584h8.676l4.325 7.466z"
          fill="#373737"
        />
        <path d="M61.114 44.05l-4.428-7.57h-8.573l-4.325 7.57 4.325 7.39h8.573z" fill="#8061a9" />
        <path
          d="M58.256 11.947H46.543L40.647 22.1l5.895 10.143H58.36L64.255 22.1zm-1.57 17.635H48l-4.325-7.39L48 14.727h8.676l4.325 7.466z"
          fill="#373737"
        />
        <path d="M61.114 22.193l-4.428-7.57h-8.573l-4.325 7.57 4.325 7.39h8.573z" fill="#f17423" />
        <path
          d="M19.794 33.804H8.08L2.185 44.05 8.08 54.193h11.817l5.895-10.143zm-1.57 17.635H9.65l-4.325-7.39 4.325-7.466h8.676l4.325 7.466z"
          fill="#373737"
        />
        <path d="M22.652 44.05l-4.428-7.57H9.65l-4.325 7.57 4.325 7.39h8.573z" fill="#97c93d" />
        <path
          d="M19.794 11.947H8.08L2.185 22.1 8.08 32.233h11.817L25.793 22.1zm-1.57 17.635H9.65l-4.325-7.39 4.325-7.466h8.676l4.325 7.466z"
          fill="#373737"
        />
        <path d="M22.652 22.193l-4.428-7.57H9.65l-4.325 7.57 4.325 7.39h8.573z" fill="#cadd72" />
      </svg>
    </div>
  );
};
