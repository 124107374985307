import React from "react";
import renderCellExpand from "helper/renderCellExpand";

import { Icon } from "components/simple";

export const baselineColumns = [
  {
    headerName: "Name",
    field: "name",
    resizable: true,
    minWidth: 255,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Source",
    field: "source",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Asset failed",
    field: "failed_assets",
    resizable: true,
    minWidth: 140,
    flex: 1,
    renderCell: params => (
      <div style={{ color: "#d83e3c", cursor: "pointer" }}>{params.value || ""}</div>
    ),
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Asset passed",
    field: "assets_passed",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: params => <div style={{ color: "#36BA6B" }}>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "No data",
    field: "missing_data_assets",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Tickets",
    field: "tickets",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => (
      <div>
        <span style={{ color: "#d83e3c" }}>{params.value.closed}</span>/
        <span style={{ color: "#36BA6B" }}>{params.value.opened}</span>
      </div>
    ),
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Last comment",
    field: "last_comment",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  }
];

export const failedAssetsColumns = [
  {
    headerName: "Hosts",
    field: "asset",
    resizable: true,
    minWidth: 125,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Control ID",
    field: "control_id",
    resizable: true,
    minWidth: 125,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Groups",
    field: "groups",
    resizable: true,
    minWidth: 125,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Finding",
    field: "control",
    resizable: true,
    minWidth: 580,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Expected value",
    field: "expected_result",
    resizable: true,
    minWidth: 165,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Result",
    field: "result",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  }
];

export const historyTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Scan data",
    field: "date",
    resizable: true,
    minWidth: 420,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Asset failed",
    field: "failed_assets",
    resizable: true,
    minWidth: 200,
    flex: 1,
    renderCell: params => <div style={{ color: "#D83E3C" }}>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Asset passed",
    field: "passed_assets",
    resizable: true,
    minWidth: 200,
    flex: 1,
    renderCell: params => <div style={{ color: "#36BA6B" }}>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "No data",
    field: "missing_data_assets",
    resizable: true,
    minWidth: 150,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const assignAssetsLeftTableColumns = [
  {
    headerName: "ID",
    field: "id",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Host name",
    field: "title",
    resizable: true,
    minWidth: 340,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const assignAssetsRightTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Host Page",
    field: "title",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Pass",
    field: "pass",
    resizable: true,
    minWidth: 90,
    flex: 1,
    renderCell: params => (params.value ? <Icon.Check /> : <Icon.NotCheck />),
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Check failed findings",
    field: "failed_findings",
    resizable: true,
    minWidth: 250,
    flex: 1,
    renderCell: () => <div style={{ color: "#379EFF", cursor: "pointer" }}>Go to failed</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "",
    field: "delete",
    resizable: true,
    minWidth: 40,
    flex: 1,
    renderCell: () => <Icon.Delete color="#2b3858" style={{ cursor: "pointer" }} />,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const assignGroupsLeftTableColumns = [
  {
    headerName: "ID",
    field: "id",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Host name",
    field: "name",
    resizable: true,
    minWidth: 340,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const assignGroupsRightTableColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Host Page",
    field: "name",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Pass",
    field: "pass",
    resizable: true,
    minWidth: 90,
    flex: 1,
    renderCell: params => (params.value ? <Icon.Check /> : <Icon.NotCheck />),
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Check failed findings",
    field: "failed_findings",
    resizable: true,
    minWidth: 250,
    flex: 1,
    renderCell: () => <div style={{ color: "#379EFF", cursor: "pointer" }}>Go to failed</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "",
    field: "delete",
    resizable: true,
    minWidth: 40,
    flex: 1,
    renderCell: () => <Icon.Delete color="#2b3858" style={{ cursor: "pointer" }} />,
    headerClassName: "super-app-theme--header",
    hide: false
  }
];
