import { CLUSTER, CONTAINER, NODE, POD, PROCESS, VMGROUP, VMINSTANCE } from "../constant";

const filterNodeName = (obj, node) => {
  let filter = node?.filter(n => n?.ID === obj?.NodeID);
  return filter[0]?.NodeName;
};
export const heightDifferenceForContextMenu = (pageX, pageY, innerHeight, innerWidth) => {
  const posX = pageX - 10;
  const posY = pageY + 15;
  const maxHeight = innerHeight;
  const maxWidth = innerWidth;
  const differenceInHeightInPct = Math.round(((maxHeight - posY) / maxHeight) * 100);
  const differenceInWidthInPct = Math.round(((maxWidth - posX) / maxWidth) * 100);
  return [posX, posY, differenceInHeightInPct, differenceInWidthInPct];
};

export const FindTotalPage = (total, RowCount) => {
  if (total % RowCount) {
    return Math.floor(total / RowCount) + 1;
  } else if (total % RowCount === 0) {
    return Math.floor(total / RowCount);
  }
};
export const graphSummaryData = (obj, entity) => {
  let summary = {};
  if (entity === NODE) {
    summary = {
      name: obj.name,
      labels: obj.LabelCount,
      "No of Pods": obj.PodCount,
      "No of Policies": obj.PolicyCount,
      containers: obj.ContainerCount,
      alerts: obj.AlertsCount,
      location: obj.Location
    };
  } else if (entity === CLUSTER) {
    summary = {
      name: obj.name,
      Entity_id: obj.ID,
      // labels: obj.labels,
      "No of Nodes": obj?.NodeCount,
      "No of Pods": obj?.PodCount,
      "No of Policies": obj?.PolicyCount,
      location: obj.Location
    };
  } else if (entity === POD) {
    summary = {
      name: obj.name,
      Entity_id: obj.ID,
      namespace: obj.namespace,
      // nodename: null,
      labels: obj.labels,
      // containers:obj.no_of_containers,
      "No of policies": obj.policies,
      alerts: obj.no_of_alerts,
      location: obj.Location
    };
  } else if (entity === CONTAINER) {
    summary = {
      name: obj.name,
      // id: obj.id,
      image: obj.image,
      port: obj.protocol_port,
      service: obj.name_of_services
      //  labels: obj.labels,
    };
  } else if (entity === VMGROUP) {
    summary = {
      name: obj.Group_Name,
      id: obj.ID,
      "No of policies": obj.Policycount,
      "No. of VM": obj.Vmcount,
      Status: obj.Status ? "Active" : "Inactive"
      // protocol_port:obj.protocol_port,
      // service:obj.service,
      // status:obj.status,
      // container_id:obj.container_id,
      // image:obj.image
    };
  } else if (entity === VMINSTANCE) {
    summary = {
      name: obj.Instance_Name,
      id: obj.ID,
      "Internal Ip": obj.Internal_Ip,
      "External Ip": obj.External_Ip,
      Status: obj.Status ? "Active" : "Inactive"
    };
  } else if (entity === PROCESS) {
    summary = {
      name: obj.name,
      path: obj.path,
      type: obj.type,
      Status: obj.Status ? "Active" : "Inactive"
    };
  }
  return summary;
};

export const EntitySummary = (
  rightClickEntity,
  entitySelctedDetail,
  setSummaryData,
  isEntityDetailsLoading,
  summaryData,
  containers,
  clearRightClick
) => {
  if (rightClickEntity?.entity_type === CLUSTER) {
    if (entitySelctedDetail?.cluster_id === rightClickEntity?.ID) {
      let newSummary = {
        Entity_id: entitySelctedDetail?.cluster_id,
        name: summaryData.name,
        "No of Nodes": entitySelctedDetail?.NodeCount,
        "No of Pods": entitySelctedDetail?.PodCount,
        "No of Policies": entitySelctedDetail?.PolicyCount,
        location: summaryData.location
      };
      setSummaryData(newSummary);
    }
  } else if (rightClickEntity?.entity_type === POD) {
    if (entitySelctedDetail?.ID === rightClickEntity?.ID) {
      let newSummary = {
        Entity_id: entitySelctedDetail?.ID,
        name: rightClickEntity.name,
        namespace: rightClickEntity.namespace,
        // nodename: filterNodeName(rightClickEntity, nodeData),
        labels: entitySelctedDetail.LabelCount,
        containers: entitySelctedDetail.ContainerCount,
        "No of policies": entitySelctedDetail.PolicyCount,
        alerts: entitySelctedDetail.AlertsCount,
        location: rightClickEntity.Location
      };
      setSummaryData(newSummary);
      containers?.on("click", clearRightClick);
    }
  }
  // else if (!isEntityDetailsLoading && type === 'vmgroup') {
  //   if (selectedPod?.GroupID === rightClickEntity?.ID) {
  //     let newSummary = {
  //       Entity_id: selectedPod?.GroupID,
  //       name: rightClickEntity['Group_Name'],
  //       id: rightClickEntity['ID'],
  //       'No of policies': selectedPod['Policycount'],
  //       'No. of VM': selectedPod['Vmcount'],
  //       Status: rightClickEntity['Status'] ? 'Active' : 'Inactive',
  //     };
  //     let tmp = {
  //       value: rightClickEntity['ID'],
  //       label: rightClickEntity['Group_Name'],
  //       InstanceCount: selectedPod['Vmcount'],
  //     };
  //     setSelectedInstanceGroup(tmp);
  //     setSummaryData(newSummary);
  //   }
  // }
};

export const searchOptionList = (selectedFilter, listofSearchOption) => {
  let K8filter;
  if (selectedFilter === "K8s Cluster") {
    const ClusterFilter = listofSearchOption?.list_of_cluster_data?.map(e => {
      return {
        label: e?.cluster_name,
        value: e?.cluster_id,
        entity_type: e?.entity_type
      };
    });
    if (ClusterFilter?.length > 0) {
      K8filter = [{ label: "Cluster", options: ClusterFilter }];
    } else {
      K8filter = [];
    }
  } else if (selectedFilter === "VM Instances") {
    const InstanceGroupFilter = listofSearchOption?.ListOfInstanceGroupData?.map(e => {
      return {
        label: e?.instance_group_name,
        value: e?.instance_group_id,
        entity_type: e?.entity_type
      };
    });
    const InstanceFilter = listofSearchOption?.ListOfInstanceData?.map(e => {
      return {
        label: e?.instance_name,
        value: e?.instance_id,
        instance_group_id: e?.instance_group_id,
        instance_group_name: e?.instance_group_name,
        entity_type: e?.entity_type
      };
    });
    if (InstanceGroupFilter?.length > 0 && InstanceFilter?.length > 0) {
      K8filter = [
        { label: "Instance Group", options: InstanceGroupFilter },
        { label: "Instance", options: InstanceFilter }
      ];
    } else if (InstanceGroupFilter?.length === undefined && InstanceFilter?.length > 0) {
      K8filter = [{ label: "Instance", options: InstanceFilter }];
    } else if (InstanceGroupFilter?.length > 0 && InstanceFilter?.length === undefined) {
      K8filter = [{ label: "Instance Group", options: InstanceGroupFilter }];
    } else {
      K8filter = [];
    }
  }
  return K8filter;
};
