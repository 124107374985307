export const stylesTableSelect = {
  control: provided => {
    return {
      ...provided,
      minHeight: "40px",
      border: "none",
      boxShadow: "none",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "initial",
      flexWrap: "nowrap",
      color: "#2b3858"
    };
  },

  menuPortal: provided => ({ ...provided, zIndex: 9999 }),

  menu: provided => {
    return {
      ...provided,
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      boxShadow: "none"
    };
  },

  menuList: provided => {
    return {
      ...provided,
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      padding: "8px",
      border: "1px solid #d9dfea",
      zIndex: 10000
    };
  },

  option: (provided, { isDisabled, isFocused, isSelected }) => {
    return {
      ...provided,
      cursor: isDisabled ? "not-allowed" : "pointer",
      backgroundColor: isFocused || isSelected ? "#f4f5fa" : "#ffffff",
      color: "#2b3858",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: isSelected ? "600" : "normal",
      borderRadius: "7px",
      paddingLeft: "10px",
      marginBottom: "1px",

      ":active": {
        backgroundColor: "#f4f5fa"
      }
    };
  },

  input: styles => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "600",
    color: "#2b3858"
  }),

  singleValue: styles => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    position: "absolute",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "600",
    color: "#2b3858"
  }),

  placeholder: styles => ({
    ...styles,
    fontFamily: "PoppinsMedium",
    fontSize: "14px",
    lineHeight: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#2B3858",
    margin: "0"
  }),

  valueContainer: styles => ({
    ...styles,
    minWidth: "60px",
    padding: "0",
    overflowY: "auto",
    maxHeight: "100px",
    flex: "initial"
  }),

  indicatorSeparator: () => ({
    backgroundColor: "transparent",
    paddingLeft: "20px"
  }),

  noOptionsMessage: styles => ({
    ...styles,
    fontFamily: "PoppinsMedium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "inherit"
  }),

  dropdownIndicator: (styles, state) => {
    const { menuIsOpen, required } = state.selectProps;
    let color = "#2b3858";
    if (menuIsOpen || required) {
      color = "#021040";
    }

    return {
      ...styles,
      transition: "0.5s",
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
      marginRight: "14px",

      path: {
        stroke: color
      }
    };
  }
};
