import * as React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "@mui/material/Switch";
import { styled } from "@mui/material";

const StyledForm = styled(FormControlLabel)(({ theme }) => ({
  ".MuiFormControlLabel-label": {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 400,
    color: "#a30909"
  },
  "& > span": {
    padding: "5px"
  },
  "& > span.Mui-checked": {
    color: theme.palette.error
  }
}));

const Switch = ({ label, value, disabled, onChange, className, ...rest }) => {
  const handleChange = e => {
    onChange(e.target.checked);
  };

  return (
    <StyledForm
      {...rest}
      className={className}
      control={
        <MuiSwitch
          color="error"
          checked={value}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          size="small"
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

Switch.defaultProps = {
  value: false,
  label: "",
  disabled: false,
  onChange: () => {},
  className: ""
};

export default Switch;