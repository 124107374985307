import { useDispatch } from "react-redux";
import { customPost, postFormData } from "api/base";
import { addNotification } from "../notifications/actions";
import {
  getChannelDetails,
  getCloudWatchtest,
  getJiraAccountConfiguration,
  getslacktest,
  getsplunktest,
  getElasticTest,
  getRsyslogTest,
  getDeleteChannel,
  getJiraIntegrationURL,
  getRsyslogIntegrationURL,
  getElasticSearchIntegrationURL,
  getJiraIntegrationURLV3,
  getRsyslogIntegrationURLV3,
  getChannelDetailsV3,
  getDeleteChannelV3,
  useSplunkIntegrationURLV3,
  getCloudwatchIntegrationURLV3,
  getSlackIntegrationURLV3
} from "screens/ChannelIntegration/channelUrl";
import { useQuery, useMutation } from "react-query";
import { clean } from "screens/MonitorLogs/helper";
export const useSlackTest = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ Integration_name, Slack_Webhook_url, Slack_sender_name, Slack_channel_name }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        IntegrationName: Integration_name,
        HookUrl: Slack_Webhook_url,
        SenderName: Slack_sender_name,
        ChannelName: Slack_channel_name
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const response = await postFormData(getslacktest(), form);
      return response.json();
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data?.json();
        dispatch(addNotification({ msg: res?.apiResponse, type: "error" }));
      }
    }
  );
};

export const useSplunkTest = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      splunk_webhook_url,
      splunk_token,
      splunk_source,
      splunk_source_type,
      splunk_index
    }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        Splunk_Webhook_url: splunk_webhook_url,
        Splunk_token: splunk_token,
        Splunk_source: splunk_source,
        Splunk_source_type: splunk_source_type,
        Splunk_index: splunk_index
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const response = await postFormData(getsplunktest(), form);
      return response.json();
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data?.json();
        dispatch(addNotification({ msg: res?.apiResponse, type: "error" }));
      }
    }
  );
};

export const useAWSCloudTest = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ Access_key, Secret_key, region }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        Aws_access_key_id: Access_key,
        Aws_secret_access_key: Secret_key,
        Region: region
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const response = await postFormData(getCloudWatchtest(), form);
      return response.json();
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data?.json();
        if (res?.apiResponse?.Message_) {
          dispatch(
            addNotification({
              msg: res?.apiResponse?.Message_,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg:
                Object.keys(res?.apiResponse)?.length > 0
                  ? res?.apiResponse
                  : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useJiraTest = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ Site, User_ID, User_Email, token }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        Address: Site,
        UserID: User_ID,
        UserEmail: User_Email,
        Token: token
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const response = await postFormData(getJiraAccountConfiguration(), form);
      return response.json();
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data?.json();
        dispatch(addNotification({ msg: res?.apiResponse, type: "error" }));
      }
    }
  );
};

export const useElasticTest = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ API_URL, USER_NAME, PASSWORD_KEY }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        HOST_URL: API_URL,
        USERNAME: USER_NAME,
        PASSWORD: PASSWORD_KEY
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const response = await postFormData(getElasticTest(), form);
      return response.json();
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data?.json();
        dispatch(
          addNotification({
            msg:
              Object.keys(res?.apiResponse)?.length === 1
                ? res?.apiResponse
                : "Something went wrong!",
            type: "error"
          })
        );
      }
    }
  );
};

export const useRsyslogTest = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ integrationName, server_address, port, transport }) => {
      const payload = {
        IntegrationName: integrationName,
        ServerAddress: server_address,
        Port: port,
        Transport: transport
      };
      const response = await customPost(getRsyslogTest(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data?.json();
        dispatch(addNotification({ msg: res?.apiResponse, type: "error" }));
      }
    }
  );
};

export const useGetChannelDetails = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ action_id, channel_id }) => {
      const payload = {
        ID: action_id,
        ChannelID: channel_id
      };
      const response = await customPost(getChannelDetailsV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data?.json();
        dispatch(
          addNotification({
            msg: res?.Error?.message,
            type: "error"
          })
        );
      },
      refetchOnWindowFocus: false,
      retry: false
      // enabled: false
    }
  );
};

// delete channels

export const useDeleteChannel = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ channeId, ID }) => {
      const payload = {
        ChannelID: channeId,
        ID: ID
      };
      const response = await customPost(getDeleteChannelV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useDeleteJira = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ workspaceVal, getIntegrationName }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        TENANT_ID: workspaceVal,
        CHANNEL_ID: 6,
        INTEGRATION_NAME: getIntegrationName,
        Option: "Delete"
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const result = await postFormData(getJiraIntegrationURL(), form);
      return result;
    },
    {
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Delete Jira", type: "error" }));
      }
    }
  );
};
export const useDeleteRsyslog = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ workspaceVal, getIntegrationName }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        TENANT_ID: workspaceVal,
        CHANNEL_ID: 5,
        INTEGRATION_NAME: getIntegrationName,
        OPTION: "Delete"
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const result = await postFormData(getRsyslogIntegrationURL(), form);
      return result;
    },
    {
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Delete Rsyslog", type: "error" }));
      }
    }
  );
};
export const useDeleteElastic = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ workspaceVal, getIntegrationName }) => {
      let formData = {};
      const form = new FormData();
      formData = {
        ...formData,
        TENANT_ID: workspaceVal,
        CHANNEL_ID: 4,
        INTEGRATION_NAME: getIntegrationName,
        Option: "Delete"
      };
      Object.keys(clean(formData)).forEach(key => form.append(key, formData[key]));
      const result = await postFormData(getElasticSearchIntegrationURL(), form);
      return result;
    },
    {
      onSuccess,
      onError: () => {
        dispatch(addNotification({ msg: "Failed to Delete Elastic", type: "error" }));
      }
    }
  );
};

// save channels

export const useJirIntegration = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      integrationName,
      summary,
      EncryptedJiraUrl,
      projectId,
      issueType,
      email,
      tokenEncrypted,
      userId,
      option
    }) => {
      const payload = {
        IntegrationName: integrationName,
        IssueSummary: summary,
        Site: EncryptedJiraUrl,
        Project: projectId,
        IssueType: issueType,
        UserEmail: email,
        Token: tokenEncrypted,
        UserID: userId,
        ChannelID: 6,
        Option: option
      };
      const response = await customPost(getJiraIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useJirUpdate = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      ID,
      integrationName,
      summary,
      EncryptedJiraUrl,
      projectId,
      issueType,
      email,
      tokenEncrypted,
      userId
    }) => {
      const payload = {
        ID: ID,
        IntegrationName: integrationName,
        IssueSummary: summary,
        Site: EncryptedJiraUrl,
        Project: projectId,
        IssueType: issueType,
        UserEmail: email,
        Token: tokenEncrypted,
        UserID: userId,
        ChannelID: 6,
        Option: "update"
      };
      const response = await customPost(getJiraIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useRsyslogIntegration = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ integrationName, server, port, transport, option }) => {
      const payload = {
        IntegrationName: integrationName,
        ChannelID: 5,
        ServerAddress: server,
        Port: Number(port),
        Transport: transport,
        Option: option
      };
      const response = await customPost(getRsyslogIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useRsyslogUpdate = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ ID, integrationName, server, port, transport }) => {
      const payload = {
        ID: ID,
        IntegrationName: integrationName,
        ChannelID: 5,
        ServerAddress: server,
        Port: Number(port),
        Transport: transport,
        Option: "update"
      };
      const response = await customPost(getRsyslogIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useSplunkIntegration = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      channelId,
      integrationName,
      splunk_url,
      splunk_token,
      splunk_source,
      splunk_sourceType,
      source_index,
      option
    }) => {
      const payload = {
        ChannelID: channelId,
        IntegrationName: integrationName,
        Url: splunk_url,
        Token: splunk_token,
        Source: splunk_source,
        SourceType: splunk_sourceType,
        Index: source_index,
        Option: option
      };
      const response = await customPost(useSplunkIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useEditSplunkIntegration = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({
      ID,
      channelId,
      integrationName,
      splunk_url,
      splunk_token,
      splunk_source,
      splunk_sourceType,
      source_index,
      option
    }) => {
      const payload = {
        ID: ID,
        ChannelID: channelId,
        IntegrationName: integrationName,
        Url: splunk_url,
        Token: splunk_token,
        Source: splunk_source,
        SourceType: splunk_sourceType,
        Index: source_index,
        Option: option
      };
      const response = await customPost(useSplunkIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useAwsCloudIntegration = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ integrationName, accessKey, secretKey, region, logGroupName, option }) => {
      const payload = {
        IntegrationName: integrationName,
        AccessKey: accessKey,
        SecretKey: secretKey,
        Region: region,
        LogGroupName: logGroupName,
        ChannelID: 3,
        Option: option
      };
      const response = await customPost(getCloudwatchIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useEditAwsCloudIntegration = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ ID, integrationName, accessKey, secretKey, region, logGroupName }) => {
      const payload = {
        ID: ID,
        IntegrationName: integrationName,
        AccessKey: accessKey,
        SecretKey: secretKey,
        Region: region,
        LogGroupName: logGroupName,
        ChannelID: 3,
        Option: "update"
      };
      const response = await customPost(getCloudwatchIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useSlackIntegration = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ integrationName, senderName, webhookUrl, channelName, Option, title }) => {
      const payload = {
        ChannelID: 1,
        IntegrationName: integrationName,
        SenderName: senderName,
        WebhookUrl: webhookUrl,
        ChannelName: channelName,
        Option: Option,
        Title: title
      };
      const response = await customPost(getSlackIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};

export const useEditSlackIntegration = (onSuccess = () => {}) => {
  const dispatch = useDispatch();
  return useMutation(
    async ({ ID, integrationName, senderName, webhookUrl, channelName, title }) => {
      const payload = {
        ID: ID,
        ChannelID: 1,
        IntegrationName: integrationName,
        SenderName: senderName,
        WebhookUrl: webhookUrl,
        ChannelName: channelName,
        Option: "update",
        Title: title
      };
      const response = await customPost(getSlackIntegrationURLV3(), payload);
      const res = await response.json();
      return res;
    },
    {
      select: data => {
        return data;
      },
      onSuccess,
      onError: async data => {
        const res = await data.json();
        if (res?.Error?.message) {
          dispatch(
            addNotification({
              msg: res?.Error?.message,
              type: "error"
            })
          );
        } else {
          dispatch(
            addNotification({
              msg: Object.keys(res?.Error)?.length > 0 ? res?.Error : "Something went wrong!",
              type: "error"
            })
          );
        }
      }
    }
  );
};
