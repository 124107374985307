/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getHistoryTableSelector } from "store/entities/monitors/selectors";
import { createLoadingSelector } from "store/entities/loading/selector";
import { getMonitorHistory, setMonitorHistoryParams } from "store/entities/monitors/actions";
import { historyTableColumns } from "../../../tableColumns";

import { Table } from "components/complex";
import { ContentContainer, Search } from "components/simple";
import DetailsHistoryModal from "./DetailsHistoryModal";

import styles from "../../../styles.module.scss";

const History = ({ id }) => {
  const dispatch = useDispatch();

  const [selectionModel, setSelectionModel] = useState([]);

  const [detailsOpen, setDetailsOpen] = useState(false);

  const prevSelectionModel = React.useRef(selectionModel);
  const { page, pageSize } = useSelector(state => state.monitors.monitorHistoryParams);
  const { data, total } = useSelector(getHistoryTableSelector);

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getMonitorHistory.type])(state));

  // Next page
  const onPageChange = newPage => {
    prevSelectionModel.current = selectionModel;
    dispatch(
      setMonitorHistoryParams({
        page: newPage
      })
    );
  };

  // get default request
  const getRequest = () => {
    dispatch(
      setMonitorHistoryParams({
        id,
        page: 0,
        ordering: "",
        search: "",
        filters: {},
        pageSize: 20
      })
    );
  };

  useEffect(() => {
    getRequest();
  }, [selectTenant, id]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  // Search

  const handleSearch = v => {
    dispatch(
      setMonitorHistoryParams({
        page: 0,
        search: v
      })
    );
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(
          setMonitorHistoryParams({
            ordering: field
          })
        );
      } else {
        dispatch(
          setMonitorHistoryParams({
            ordering: `-${field}`
          })
        );
      }
    } else {
      dispatch(
        setMonitorHistoryParams({
          ordering: ""
        })
      );
    }
  };

  const setPageSize = v => {
    dispatch(
      setMonitorHistoryParams({
        pageSize: v
      })
    );
  };

  const onCellClick = v => {
    const { row, id: uid } = v;
    setDetailsOpen({ id: uid, title: row?.scan?.scan_file });
  };

  const customStyles = {
    height: 710,
    width: 1,
    padding: "30px",
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      borderRadius: "10px",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important"
      }
    }
  };

  const tableConfig = {
    checkboxVisible: false,
    hideSelectedRowCount: true
  };

  return (
    <ContentContainer className={styles.container}>
      <div className={styles.controls}>
        <Search onSearch={handleSearch} />
      </div>
      <Table
        data={isLoading ? [] : data}
        columns={historyTableColumns}
        loading={isLoading}
        onSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel);
        }}
        page={page || 0}
        selectionModel={selectionModel}
        onSortModelChange={model => handleSort(model)}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowCount={total}
        paginationMode="server"
        onPageChange={onPageChange}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
        onCellClick={e => onCellClick(e)}
        customStyles={customStyles}
        {...tableConfig}
      />
      <DetailsHistoryModal
        open={Boolean(detailsOpen)}
        data={detailsOpen}
        close={() => setDetailsOpen(false)}
      />
    </ContentContainer>
  );
};

History.propTypes = {};

export default History;
