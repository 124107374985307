import { ContentContainer, Select, Title } from "components/simple";
import React, { useState } from "react";
import { ToggleView } from "screens/Inventory/ClusterManagement/Components/ToggleView";
import { OBSERVABILITY_DASHBOARD_TITLE, TIME_RANGE } from "./constant/constant";
import NestedFlow from "./NestedFlow";
import ObservabilityDash from "./ObservabilityDash";
import styles from "./styles.module.scss";
import { useToggle } from "../../Inventory/ClusterManagement/hooks/useToggle";
import ListView from "./ListView/ListView";
import { useObservabilityContext } from "./Context";
import AppBehaviorModal from "./components/AppBehaviorModal/AppBehaviorModal";
import { useLocation } from "react-router-dom";
import DateRangePickerComponent from "components/simple/DateRangePicker/DateRangePicker-2";
import DynamicFilter from "components/complex/DynamicFilter/CustomFilter";
import { usePrimaryLayoutTitle } from "screens/MonitorLogs/Context/Context";
import { useGetKeysList, useGetValuesList } from "store/entities/appBehavior/queries";
import { useDatapipelineState } from "store/entities/datapipeline/slice";
import { useEffect } from "react";

const AppBehavior = () => {
  const {
    clusterFilterData,
    podFilterData,
    namespaceFilterData,
    getTotalCounts,
    selectedCluster,
    setSelectedCluster,
    selectedNamespace,
    setSelectedNamespace,
    selectedPod,
    setSelectedPod,
    showModal,
    datePicker,
    activeTab,
    setDatePicker
  } = useObservabilityContext();
  const { isListView, actions: toggleActions } = useToggle();
  const { logdDslTag } = usePrimaryLayoutTitle();
  // const [totalRecords, setTotalRecords] = useState(processListData?.data?.total_records);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [dropValues, setDropVals] = useState([]);
  const [dataKeys, setDatakey] = useState([]);
  const [localDSL, setLocalDSL] = useState(logdDslTag);
  const onSelectedFilter = filters => setSelectedFilters(filters);
  const updateLocalDSL = function(val) {
    setLocalDSL(val);
  };
  const onChangeRange = range => setDatePicker(range);
  const opertationName = operation => {
    if (operation === 1 && isListView) {
      return "file";
    } else if (operation === 2) {
      return "process";
    } else {
      return "network";
    }
  };
  const { data: getKeys } = useGetKeysList(data => {
    setDatakey(data);
  }, opertationName(activeTab));

  const selectedKey = useDatapipelineState("selectedFilter");
  const selectedKeyType = useDatapipelineState("selectedKeyType");
  const { data: getValues } = useGetValuesList(
    data => {
      setDropVals(data);
    },
    selectedCluster?.value,
    selectedPod?.label,
    opertationName(activeTab),
    selectedKey,
    datePicker
  );
  const optionsString = [
    { value: "eq", label: "equal to" },
    { value: "neq", label: "not equal to" },
    { value: "regex", label: "regex" }
  ];
  const optionsInt = [
    { value: "gt", label: "greater than" },
    { value: "gte", label: "greater than equal to" },
    { value: "lt", label: "less than" },
    { value: "lte", label: "less than equal to" }
  ];
  return (
    <>
      {/* {!location?.state?.selectedCluster && showModal && <AppBehaviorModal />} */}
      <ContentContainer className={styles.container}>
        <Title className={styles.title}>App Behavior</Title>
        <div className="flex flex-col w-full">
          <div className="w-full mb-2 flex justify-between">
            <div className={styles.filterRow}>
              <Select
                containerClass={styles.select}
                onChange={v => {
                  setSelectedCluster(v);
                  setSelectedNamespace();
                  setSelectedPod();
                }}
                value={selectedCluster}
                placeholder="Filter by: Cluster"
                options={clusterFilterData}
                isClearable={false}
              />
              <Select
                containerClass={styles.select}
                onChange={v => {
                  setSelectedNamespace(v);
                  setSelectedPod();
                }}
                value={selectedNamespace}
                placeholder="Filter by: Namespace"
                options={
                  namespaceFilterData?.data
                    ? namespaceFilterData?.data
                        ?.filter(each => each.namespace !== "kube-system")
                        ?.map(e => ({ value: e.ID, label: e.namespace }))
                    : []
                }
                isClearable
              />
              <Select
                containerClass={styles.select}
                onChange={v => setSelectedPod(v)}
                value={selectedPod}
                placeholder="Filter by: Pod"
                options={
                  podFilterData?.data
                    ? podFilterData?.data.map(e => ({ value: e.ID, label: e.PodName }))
                    : []
                }
                isClearable
              />
            </div>
            <div className="flex w-1/3 gap-5 items-end justify-end">
              <div>
                <ToggleView
                  isListView={isListView}
                  actions={toggleActions}
                  toolTipTitle="Visualize your App Behavior at the Network level"
                />
              </div>
              <DateRangePickerComponent onChange={onChangeRange} items={TIME_RANGE} />
            </div>
          </div>
        </div>
      </ContentContainer>
      {!isListView && (
        <div className="flex flex-col mb-4">
          <ObservabilityDash title={OBSERVABILITY_DASHBOARD_TITLE} content={getTotalCounts} />
        </div>
      )}
      <ContentContainer className={styles.container}>
        <DynamicFilter
          dsl={localDSL}
          keysList={dataKeys}
          updateLocalDSL={updateLocalDSL}
          onSelected={onSelectedFilter}
          setPageId={() => {}}
          dropValues={dropValues}
          optionsOperators={selectedKeyType === "string" ? optionsString : optionsInt}
          className={isListView ? "" : "pb-4"}
          isListView={isListView}
        />
        {isListView ? <ListView /> : <NestedFlow />}
      </ContentContainer>
    </>
  );
};

export default AppBehavior;
