import { useQuery } from "helper/history";
import React from "react";
import { EditAWSAccess, EditAWSManual } from "./EditAWSAccount";
import EditAzureAccount from "./EditAzureAccount";
import EditGCPAccount from "./EditGCPAccount";

const EditAccount = () => {
  const query = useQuery();
  const cloudType = query.get("cloud_type");

  const renderForm = () => {
    switch (cloudType) {
      case "azure":
        return <EditAzureAccount />;
      case "gcp":
        return <EditGCPAccount />;
      case "aws":
        return <EditAWSAccess />;
      case "aws_assume":
        return <EditAWSManual />;
      default:
        return null;
    }
  };

  return <div>{renderForm()}</div>;
};

export default EditAccount;
