/* eslint-disable camelcase */
import React, { useState } from "react";
import { navigate } from "helper/history";
import { routes } from "router";

import styles from "./styles.module.scss";
import { Table } from "components/complex";
import { ContentContainer, Title, Button, Modal, CustomPagination } from "components/simple";
import { Divider, Typography } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import {
  useCategoriesQuery,
  usePermissionsQuery,
  useRolesQuery
} from "store/entities/rbac/queries";
import { useDeleteRoleMutation } from "store/entities/rbac/mutations";
import { FindTotalPage } from "screens/Inventory/ClusterManagement/helpers/heightDifferenceForContextMenu";

const Rbac = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [roleToBeDeleted, setRoleToBeDeleted] = useState(null);

  const { data, isLoading, refetch: refetchRoles } = useRolesQuery(page, pageSize);
  const { mutate: deleteRole } = useDeleteRoleMutation();

  // NOTE: This is a workaround to pre-fetch the permissions and categories, so that by the time the user clicks on the edit button, the data is cached & already available on the add/edit page (This is to avoid a bug where the permissions/categories shows undefined). Please DO NOT remove the below lines.
  const permissionsQuery = usePermissionsQuery();
  const categoriesQuery = useCategoriesQuery();

  const rbacTableColumns = [
    {
      headerName: "Role",
      field: "name",
      resizable: true,
      sortable: false,
      minWidth: 400,
      flex: 1,
      renderCell: params => <div>{params.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Permissions",
      field: "permissions_count",
      resizable: true,
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: params => <div>{params.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "Users",
      field: "users_count",
      resizable: true,
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: params => <div>{params.value}</div>,
      headerClassName: "super-app-theme--header",
      hide: false
    },
    {
      headerName: "",
      field: "is_default",
      resizable: false,
      sortable: true,
      maxWidth: 200,
      flex: 1,
      align: "right",
      renderCell: params =>
        !params?.row?.is_default ? (
          <div className="flex items-center justify-center gap-3 mx-auto">
            <span
              className="flex items-center justify-center gap-1 cursor-pointer"
              onClick={() => {
                navigate(`${routes.settingsRbacRoleEdit}/${params?.row?.id}`, {
                  role: params?.row
                });
              }}
            >
              <ModeEditOutlineOutlinedIcon
                sx={{
                  color: "grey.500"
                }}
              />
              <Typography
                sx={{
                  color: "grey.500"
                }}
              >
                Edit
              </Typography>
            </span>
            <span
              className="flex items-center justify-center gap-1 cursor-pointer"
              onClick={() => {
                setShowDeleteModal(true);
                setRoleToBeDeleted(params?.row);
              }}
            >
              <DeleteOutlineOutlinedIcon
                sx={{
                  color: "grey.500"
                }}
              />
              <Typography
                sx={{
                  color: "grey.500"
                }}
              >
                Delete
              </Typography>
            </span>
          </div>
        ) : (
          <></>
        ),
      headerClassName: "super-app-theme--header",
      hide: false
    }
  ];

  const customStyles = {
    height: 710,
    width: 1,
    padding: "30px",
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important"
    },
    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "none !important"
    },
    "& .MuiDataGrid-pinnedColumns": {
      boxShadow: "none !important",
      "& .super-app-theme--row": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .super-app-theme--row": {
        borderBottom: "none !important",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px"
      }
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      border: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      }
    }
  };

  const tableConfig = {
    hideSelectedRowCount: true,
    checkboxVisible: false
  };

  const handleAddRole = () => {
    navigate(routes.settingsRbacRoleAdd);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setRoleToBeDeleted(null);
  };

  const handleDeleteRole = () => {
    deleteRole(roleToBeDeleted?.id, {
      onSuccess: () => {
        setRoleToBeDeleted(null);
        setShowDeleteModal(false);
        refetchRoles();
      }
    });
  };

  return (
    <ContentContainer>
      <div className={styles.controls}>
        <Title>RBAC</Title>
        <div className={styles.btnsContainer}>
          <Button className={styles.btn} onClick={handleAddRole} icon="plus">
            Add role
          </Button>
        </div>
      </div>
      <Table
        data={data?.results}
        columns={rbacTableColumns}
        loading={isLoading}
        rowCount={data?.count}
        page={page}
        // onPageChange={setPage}
        // rowsPerPageOptions={[10, 20, 50]}
        pageSize={pageSize}
        // onPageSizeChange={setPageSize}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
        paginationMode="server"
        customStyles={customStyles}
        components={{
          Pagination: CustomPagination
        }}
        componentsProps={{
          pagination: {
            count: FindTotalPage(data?.count, pageSize),
            onChange: (event, value) => {
              setPage(value - 1);
            },
            page: page + 1,
            totalCount: data?.count || 0,
            selected: pageSize,
            perPageChange: (event, value) => {
              setPageSize(event?.target?.value);
            }
          }
        }}
        {...tableConfig}
      />
      {showDeleteModal && (
        <Modal isOpen={showDeleteModal} toggle={handleCloseDeleteModal} width={600}>
          <div className="flex flex-col gap-4">
            <Typography fontSize={22} fontWeight={600}>
              Confirmation
            </Typography>
            <Divider orientation="horizontal" flexItem />
            <Typography>Are you sure you want to delete this role?</Typography>
            <div className="flex justify-between mt-2">
              <Button variant="outline" onClick={handleCloseDeleteModal}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleDeleteRole}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </ContentContainer>
  );
};

export default Rbac;
