import React from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import CustomDetailPanelToggle from "../components/ExpandMoreIcon";
import styles from "../ListView/styles.module.scss";
export const OBSERVABILITY_DASHBOARD_TITLE = [
  { title: "Total Allowed Process" },
  { title: "Total Blocked Process" },
  { title: "Total Counts of File Data" },
  { title: "Total Counts of Incoming Connection" },
  { title: "Total Counts of Outgoing Connection" },
  { title: "Total Counts of Process Data" }
];

export const TIME_RANGE = [
  { value: "All", id: 0 },
  { value: "Last 5 min", id: 1 },
  { value: "Last 15 min", id: 2 },
  { value: "Last 30 min", id: 3 },
  { value: "Last 1 hour", id: 4 },
  { value: "Last 3 hours", id: 5 },
  { value: "Last 6 hours", id: 6 },
  { value: "Last 12 hours", id: 7 },
  { value: "Last 24 hours", id: 8 },
  { value: "Last 2 days", id: 9 },
  { value: "Last 7 days", id: 10 },
  { value: "Last 30 days", id: 11 },
  { value: "Last 60 days", id: 12 }
];

export const processListColumn = [
  {
    headerName: "Last Update",
    field: "action",
    resizable: true,
    minWidth: 160,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Process",
    field: "source",
    resizable: true,
    minWidth: 100,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "File Path Accessed",
    field: "destination",
    resizable: true,
    minWidth: 50,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Container",
    field: "container_name",
    resizable: true,
    minWidth: 100,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Occurrence",
    field: "occurance_name",
    resizable: true,
    minWidth: 100,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Status",
    field: "status",
    resizable: true,
    minWidth: 30,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    width: 100,
    cellClassName: styles.eachCell,
    renderCell: params => (
      <div className="text-blue-500 cursor-pointer">
        {params.value ? "Hide" : "Details"}
        <CustomDetailPanelToggle {...params} />
      </div>
    )
  }
];

export const fileListColumn = [
  {
    headerName: "Last Update",
    field: "action",
    resizable: true,
    minWidth: 160,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Process",
    field: "source",
    resizable: true,
    minWidth: 100,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "File Path Accessed",
    field: "destination",
    resizable: true,
    minWidth: 50,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Container",
    field: "container_name",
    resizable: true,
    minWidth: 100,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Occurrence",
    field: "occurance_name",
    resizable: true,
    minWidth: 100,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Status",
    field: "status",
    resizable: true,
    minWidth: 30,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    width: 100,
    cellClassName: styles.eachCell,
    renderCell: params => (
      <div className="text-blue-500 cursor-pointer">
        {params.value ? "Hide" : "Details"}
        <CustomDetailPanelToggle {...params} />
      </div>
    )
  }
];

export const networkListColumn = [
  {
    headerName: "Last Update",
    field: "action",
    resizable: true,
    minWidth: 160,
    flex: 1,
    renderCell: params => <div>{params?.value}</div>,
    cellClassName: styles.eachCell,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Source Command",
    field: "source",
    resizable: true,
    minWidth: 100,
    flex: 1,
    renderCell: params => <div>{params?.value}</div>,
    cellClassName: styles.eachCell,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Network Flow",
    field: "network_flow",
    resizable: true,
    minWidth: 50,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => (
      <div className="flex justify-between items-center">
        {params?.value}
        {params?.value === "egress" ? (
          <TrendingUpIcon sx={{ paddingLeft: "5px" }} color="primary" />
        ) : (
          <TrendingDownIcon sx={{ paddingLeft: "5px" }} color="primary" />
        )}
      </div>
    ),
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Dest. POD/SVC/IP",
    field: "pod_svc",
    resizable: true,
    minWidth: 100,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Port",
    field: "port",
    resizable: true,
    minWidth: 100,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Container",
    field: "container",
    resizable: true,
    minWidth: 30,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Occurrence",
    field: "occurance",
    resizable: true,
    minWidth: 30,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Status",
    field: "status",
    resizable: true,
    minWidth: 30,
    flex: 1,
    cellClassName: styles.eachCell,
    renderCell: params => <div>{params?.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    width: 100,
    cellClassName: styles.eachCell,
    renderCell: params => (
      <div className="text-blue-500 cursor-pointer">
        {params.value ? "Hide" : "Details"}
        <CustomDetailPanelToggle {...params} />
      </div>
    )
  }
];
