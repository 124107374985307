import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { customPost, get, post } from "api/base";
import { DATE_FROM, DATE_TO } from "./constants";
export const getDashboardDetails = async workspace_id => {
  const response = await customPost("/vulnerabilityservice/api/v1/dashboard/get-dashboard", {
    workspace_id
  });
  const data = await response.json();
  return data;
};

export const getImageRisks = async () => {
  const DATA_TYPE = "SEVERITY_ASSESSMENT";
  const LIMIT = 10;
  const response = await get(
    `${API}/dashboard?data_type=${DATA_TYPE}&date_from=${DATE_FROM}&date_to=${DATE_TO}&limit=${LIMIT}`,
    {
      ...contentTypeJson(),
      ...authorization(),
      ...authorizationTenant()
    }
  );
  const data = await response.json();
  return data;
};

export const getImageSeverityIssue = async () => {
  const DATA_TYPE = "SEVERITY_ISSUES";
  const LIMIT = 10;
  const response = await get(
    `${API}/dashboard?data_type=${DATA_TYPE}&date_from=${DATE_FROM}&date_to=${DATE_TO}&limit=${LIMIT}`,
    {
      ...contentTypeJson(),
      ...authorization(),
      ...authorizationTenant()
    }
  );
  const data = await response.json();
  return data;
};

export const getImagesList = async requestBody => {
  const response = await customPost(
    "/vulnerabilityservice/api/v1/dashboard/get-list-of-images",
    requestBody
  );
  const data = await response.json();
  return data;
};

export const getFindingsSummarizedImages = async page => {
  const DATA_TYPE = "SUMMARIZED_LIST";
  const response = await get(`${API}/screen?data_type=${DATA_TYPE}`, {
    ...contentTypeJson(),
    ...authorization(),
    ...authorizationTenant()
  });
  const data = await response.json();
  return data;
};

export const getFindingsAccordianImages = async ({ registryId, repositoryName }) => {
  const DATA_TYPE = "LISTING_IMAGES";
  const response = await get(
    `${API}/screen?data_type=${DATA_TYPE}&registry=${registryId}&repository_name=${repositoryName}`,
    {
      ...contentTypeJson(),
      ...authorization(),
      ...authorizationTenant()
    }
  );
  const data = await response.json();
  return data;
};
