/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";

import { getEmailProfileSelector } from "store/entities/settings/selectors";
import {
  getEmailProfile,
  editEmailProfile,
  addEmailProfile,
  deleteEmailProfile
} from "store/entities/settings/actions";
import { createLoadingSelector } from "store/entities/loading/selector";

import { ContentContainer, Input, Checkbox, Title, Button } from "components/simple";

import styles from "./styles.module.scss";

const EmailsDetails = () => {
  const initialState = {
    name: "",
    host: "",
    port: "",
    userName: "",
    useTsl: false,
    useSsl: false,
    formEmail: "",
    secret: ""
  };
  const [state, setState] = useState({ ...initialState });
  // const isLoading = useSelector(s => createLoadingSelector([getEmailProfile.type])(s));
  const isSaveLoading = useSelector(
    s =>
      createLoadingSelector([addEmailProfile.type])(s) ||
      createLoadingSelector([editEmailProfile.type])(s)
  );
  const isDeleteLoading = useSelector(s => createLoadingSelector([deleteEmailProfile.type])(s));
  const editData = useSelector(getEmailProfileSelector);

  const selectTenant = useSelector(s => s.users.selectTenant);
  const dispatch = useDispatch();

  const params = useParams();
  const id = params?.id;

  useEffect(() => {
    if (id) dispatch(getEmailProfile());
  }, [id, selectTenant]);

  useEffect(() => {
    if (editData && id) {
      setState(editData);
    }
  }, [editData, id]);

  const inputChangeHandler = (val, name) => {
    setState({
      ...state,
      [name]: val
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const res = {
      name: state?.name,
      host: state?.host,
      port: state?.port,
      username: state?.userName,
      use_tls: state?.useTsl,
      use_ssl: state?.useSsl,
      from_email: state?.formEmail,
      secret: state?.secret
    };
    if (id) {
      dispatch(editEmailProfile(res));
      return;
    }
    dispatch(addEmailProfile(res));
  };

  const handleDelete = () => {
    dispatch(deleteEmailProfile());
  };

  return (
    <ContentContainer className={styles.container}>
      <Title>Emails details</Title>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputsCont}>
          <Input
            value={state.name}
            onChange={e => inputChangeHandler(e.target.value, "name")}
            label="Name"
            required
          />
          <Input
            value={state.host}
            onChange={e => inputChangeHandler(e.target.value, "host")}
            label="Host"
            required
          />
          <Input
            value={state.port}
            onChange={e => inputChangeHandler(e.target.value, "port")}
            label="Port"
            type="number"
            required
          />
        </div>
        <Checkbox
          value={Boolean(state.useTsl)}
          onChange={val => inputChangeHandler(val, "useTsl")}
          label="Use TLS"
          className={styles.checkbox}
        />
        <Checkbox
          value={Boolean(state.useSsl)}
          onChange={val => inputChangeHandler(val, "useSsl")}
          label="Use SSL"
          className={styles.checkbox}
        />
        <div className={styles.inputsCont}>
          <Input
            value={state.userName}
            onChange={e => inputChangeHandler(e.target.value, "userName")}
            label="Username"
            required
          />
          <Input
            value={state.formEmail}
            onChange={e => inputChangeHandler(e.target.value, "formEmail")}
            label="From Email"
            type="email"
            required
          />
          <Input
            value={state.secret}
            onChange={e => inputChangeHandler(e.target.value, "secret")}
            label="Secret"
            type="password"
            autoComplete="new-password"
            required
          />
        </div>
        <div className={styles.btnsCont}>
          <Button type="submit" isLoading={isSaveLoading} className={styles.btn}>
            Save
          </Button>
          <Button
            variant="outline"
            isLoading={isDeleteLoading}
            className={styles.btn}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </form>
    </ContentContainer>
  );
};

EmailsDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default EmailsDetails;
