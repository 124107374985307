import { Button, Input, Textarea } from "components/simple";
import FileUploader from "components/simple/FileUploader";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { addGoogleCloudAccount } from "store/entities/cloudAccounts/actions";
import StepsToGet from "../StepsToGet";
import styles from "./styles.module.scss";

const steps = {
  dragAndDrop: {
    title: "Drag and Drop",
    steps: [
      "Log into your Google Cloud console and navigate to IAM Admin > Service Accounts.",
      'Click on "Create Service Account".',
      'Enter "AccuKnox" in the "Service account name", then enter "Accuknox API Access" in the description.',
      "Click on Continue.",
      "Select the role: Project > Viewer and click Continue.",
      'Click on "Create Key".',
      'Leave the default JSON selected and click "Create".',
      "Save the provided JSON file",
      "Copy the value of JSON in private key field box"
    ]
  }
};

const GoogleCloudForm = ({ onBackHandler, onCancelHandler, label, tag }) => {
  const dispatch = useDispatch();
  const [getSteps, setGetSteps] = useState("");
  const [state, setState] = useState({
    projectID: "",
    clientEmail: "",
    privateKey: "",
    file: null
  });
  const onSave = e => {
    e.preventDefault();
    const data = {
      file: state?.file,
      project_id: state?.projectID,
      client_email: state?.clientEmail,
      private_key: state?.privateKey,
      label: label?.value,
      tag: tag?.value
    };
    dispatch(addGoogleCloudAccount(data));
  };

  const closeSteps = useCallback(() => {
    setGetSteps("");
  }, [setGetSteps]);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  const onFileUpload = e => {
    setState({ ...state, file: e.target.files[0] });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSave}>
        <div>
          <span className={styles.showSteps} onClick={() => setGetSteps("dragAndDrop")}>
            Show steps
          </span>
          {/* <FileUploader onChange={onFileUpload} value={state.file} accept=".json" /> */}
        </div>
        <Input
          type="text"
          label="Project ID"
          placeholder="Enter Your GCP Project ID"
          value={state.projectID}
          onChange={val => handleChange(val.target.value, "projectID")}
          containerClassName={styles.searchContainer}
          className={styles.input}
        />
        <Input
          type="email"
          label="Client Email"
          placeholder="Enter Your Client Email"
          value={state.clientEmail}
          onChange={val => handleChange(val.target.value, "clientEmail")}
          containerClassName={styles.searchContainer}
          className={styles.input}
        />
        <Textarea
          label="Private Key"
          placeholder="Enter Your Private Key in JSON "
          value={state.privateKey}
          onChange={val => handleChange(val, "privateKey")}
          className={styles.input}
        />
        <div className={styles.btnContainer}>
          <Button variant="outline" onClick={onBackHandler}>
            Back
          </Button>
          <Button variant="outline" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Connect</Button>
        </div>
      </form>
      {getSteps && (
        <div className={styles.stepsContainer}>
          <StepsToGet steps={steps[getSteps]} onClose={closeSteps} />
        </div>
      )}
    </div>
  );
};

export default GoogleCloudForm;
