import React from "react";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { formatDate } from "helper/DateLib";
import renderCellExpand from "helper/renderCellExpand";

import { getSeverityOptionsSelector } from "store/entities/vulnerabilities/selectors";
import { getLabelListSelector } from "store/entities/labels/selectors";
import { groupsMiniListSelector } from "store/entities/groups/selectors";
import { withSuffix } from "helper/convertNumOfDay";

const reports = state => state.reports.reports;
const report = state => state.reports.report;
const reportPages = state => state.reports.reportPages;
const reportPeriods = state => state.reports.reportPeriods;

const reportConfigsColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "email",
    field: "email",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "pages",
    field: "pages",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "Name",
    field: "name",
    resizable: true,
    minWidth: 210,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Day of Week",
    field: "day_of_week",
    resizable: true,
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Day of Month",
    field: "day_of_the_month",
    resizable: true,
    minWidth: 140,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Period",
    field: "period",
    resizable: true,
    minWidth: 140,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Last Report",
    field: "last_report",
    resizable: true,
    minWidth: 155,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "View",
    field: "view",
    resizable: true,
    minWidth: 200,
    flex: 1,
    renderCell: props => (
      <div
        style={{
          color: "#D83E3C",
          textDecoration: "underline",
          fontSize: "14px",
          cursor: "pointer"
        }}
      >
        {props.value}
      </div>
    ),
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

const reportHistoryColumns = [
  {
    headerName: "id",
    field: "id",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "path",
    field: "path",
    resizable: true,
    minWidth: 10,
    flex: 1,
    renderCell: params => <div>{params.value}</div>,
    headerClassName: "super-app-theme--header",
    hide: true
  },
  {
    headerName: "User",
    field: "email",
    resizable: true,
    minWidth: 300,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "Date",
    field: "date",
    resizable: true,
    minWidth: 160,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "File",
    field: "file",
    resizable: true,
    minWidth: 320,
    flex: 1,
    renderCell: renderCellExpand,
    headerClassName: "super-app-theme--header",
    hide: false
  },
  {
    headerName: "View",
    field: "view",
    resizable: true,
    minWidth: 220,
    flex: 1,
    renderCell: props => (
      <div
        style={{
          color: "#D83E3C",
          textDecoration: "underline",
          fontSize: "14px",
          cursor: "pointer"
        }}
      >
        {props.value}
      </div>
    ),
    headerClassName: "super-app-theme--header",
    hide: false
  }
];

export const reportsSelector = createDraftSafeSelector(reports, state => {
  if (!state?.results?.length) {
    return { columns: reportConfigsColumns, data: [], total: 0 };
  }
  const { results, count } = state;
  const data = results.reduce((acc, i) => {
    const validDate = i?.last_report ? formatDate(i?.last_report) : "-";
    return [
      ...acc,
      {
        id: i.id,
        name: i.name,
        day_of_week: i.day_of_week,
        day_of_the_month: i.day_of_the_month,
        period: i.period,
        last_report: validDate,
        view: "Download config",
        email: i.email,
        pages: i.pages.toString()
      }
    ];
  }, []);
  return { columns: reportConfigsColumns, data, total: count };
});

const months = () => [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const monthsOptionsSelector = createDraftSafeSelector(months, state => {
  const res = state.map(item => {
    return {
      value: item,
      label: item
    };
  });
  return res;
});

const daysOfWeek = () => [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];
export const daysOfWeekOptionsSelector = createDraftSafeSelector(daysOfWeek, state => {
  const res = state.map((item, index) => {
    return {
      value: index.toString(),
      label: item
    };
  });
  return res;
});

const daysOfMonth = () => Array(31).fill("");
export const daysOfMonthOptionsSelector = createDraftSafeSelector(daysOfMonth, state => {
  const res = state.map((_, index) => {
    return {
      value: (index + 1).toString(),
      label: (index + 1).toString()
    };
  });
  return res;
});

export const convertedDaysOfMonthOptionsSelector = createDraftSafeSelector(daysOfMonth, state => {
  const res = state.map((_, index) => {
    return {
      value: index + 1,
      label: withSuffix(index + 1)
    };
  });
  return res;
});

export const reportPagesOptionsSelector = createDraftSafeSelector(reportPages, state => {
  const res = Object.entries(state).map(([value, label]) => {
    return {
      value: value.toString(),
      label
    };
  });
  return res;
});

export const reportPeriodsOptionsSelector = createDraftSafeSelector(reportPeriods, state => {
  const res = Object.entries(state).map(([value, label]) => {
    return {
      value: value.toString(),
      label
    };
  });
  return res;
});

const findTagOptions = (options, values) =>
  options?.filter(option => {
    const res = values?.find(v => v.toString() === option.value);
    return res?.toString();
  });

export const reportSelector = createDraftSafeSelector(
  report,
  getSeverityOptionsSelector,
  getLabelListSelector,
  monthsOptionsSelector,
  daysOfWeekOptionsSelector,
  daysOfMonthOptionsSelector,
  reportPagesOptionsSelector,
  groupsMiniListSelector,
  reportPeriodsOptionsSelector,
  (
    reportData,
    risksOptions,
    labelsOptions,
    monthsOptions,
    daysOfWeekOptions,
    daysOfMonthOptions,
    pagesOptions,
    groupsMiniList
  ) => {
    if (Object.getOwnPropertyNames(reportData).length === 0) {
      return {};
    }
    const {
      risks,
      month,
      labels,
      day_of_the_month,
      day_of_week,
      pages,
      baselines,
      groups,
      period
    } = reportData;
    const risksSelected = findTagOptions(risksOptions, risks);
    const pagesSelected = findTagOptions(pagesOptions, pages);
    const daysOfWeekSelected = findTagOptions(daysOfWeekOptions, day_of_week);
    const daysOfMonthSelected = findTagOptions(daysOfMonthOptions, day_of_the_month);
    const baselinesSelected = baselines?.map(elem => ({ value: elem.id, label: elem.name }));
    const groupsSelected = groupsMiniList.filter(option => {
      return groups?.find(it => it === option.value);
    });
    const monthSelected = monthsOptions?.find(o => o.value === month);
    const labelSelected = labelsOptions.filter(option => {
      return labels?.find(it => it === option.value);
    });

    const res = {
      ...reportData,
      groups: groupsSelected,
      risks: risksSelected,
      month: monthSelected,
      labels: labelSelected,
      day_of_week: daysOfWeekSelected,
      day_of_the_month: daysOfMonthSelected,
      pages: pagesSelected,
      baselines: baselinesSelected,
      period: period?.toString() ?? period
    };

    return res;
  }
);

export const reportsHistorySelector = createDraftSafeSelector(report, state => {
  if (!state?.history) {
    return { columns: reportHistoryColumns, data: [] };
  }
  const data = state.history.map((elem, index) => {
    const pathArr = elem.report.split("/");
    return {
      id: index,
      email: elem.email,
      file: pathArr[pathArr.length - 1],
      path: elem.report,
      date: formatDate(elem.sent),
      view: "View report"
    };
  });
  return { columns: reportHistoryColumns, data, name: state.name };
});
